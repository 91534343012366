import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';
import { common } from '../../../utils/agGrid/options';
import getAsTreeData from '../../../utils/agGrid/common/getAsTreeData';
import { BalanceDetailGridRow, BalanceDetailRow } from './columnDefs';
export interface BalanceDetail {
  accountId: string;
  accountLabel: string;
  asset: string;
  openingBalance: string;
  spotMovement: string;
  exchangeFees: string;
  clearingFees: string;
  otherFees: string;
  assetMovement: string;
  realizedPAndL: string;
  futuresDelivery: string;
  variationMargin: string;
  fundingAmount: string;
  closingBalance: string;
  totalEquity: string;
  tmeBalance: string;
  purchasingPower: string;
  initialMargin: string;
  maintenanceMargin: string;
  pendingBalance: string;
  usdValue: string;
  totalExcessDeficit: string;
  settlementBalance: string;
  settlementDetail: string;
  settlementTime: string;
  currenexBalance: string;
  fd: string;
  symbol: string;
}

interface BalanceDetailsGridProps {
  data: BalanceDetailRow[];
  colDefs: ColDef[];
  isJest?: boolean;
  loading: boolean;
}

const gridOptions: GridOptions = {
  ...common,
  autoSizeStrategy: { type: 'fitCellContents' },
};

export const gridName = 'balance-details';
const BalanceDetailsGrid: React.FC<BalanceDetailsGridProps> = ({
  data,
  colDefs,
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const flattened: BalanceDetailGridRow[] = useMemo(
    () => getAsTreeData(data, '_subRows', 'accountLabel'),
    [data],
  );

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: 'Account Label',
      minWidth: 280,
      cellRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  const getDataPath = useCallback(
    (data: BalanceDetailGridRow) => data?.path || [],
    [],
  );

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={flattened}
          columnDefs={columnDefs}
          autoGroupColumnDef={autoGroupColumnDef}
          autoSizeStrategy={{ type: 'fitCellContents' }}
          gridOptions={gridOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
          treeData
          getDataPath={getDataPath}
        />
      )}
    </div>
  );
};

export default BalanceDetailsGrid;
