import get from 'lodash/get';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions, ReducerMap } from 'redux-actions';
import { CAR } from '../ts/models/CAR.model';
import { RootState } from '../ts/types/store';
import {
  FetchCarListResponse,
  FetchCarResponse,
} from '../ts/api/customer_account_reference';

/** Action Names */
export const GET_CUSTOMER_ACCOUNT_REFERENCE = 'GET_CUSTOMER_ACCOUNT_REFERENCE';
export const DELETE_CUSTOMER_ACCOUNT_REFERENCE =
  'DELETE_CUSTOMER_ACCOUNT_REFERENCE';
export const GET_CUSTOMER_ACCOUNT_REFERENCE_LIST =
  'GET_CUSTOMER_ACCOUNT_REFERENCE_LIST';
export const UPDATE_CUSTOMER_ACCOUNT_REFERENCE =
  'UPDATE_CUSTOMER_ACCOUNT_REFERENCE';
export const LINK_CAR_TO_PARTICIPANT = 'LINK_CAR_TO_PARTICIPANT';
export const UNLINK_CAR_TO_PARTICIPANT = 'UNLINK_CAR_TO_PARTICIPANT';

/** Routines */
export const fetchCar = createRoutine(GET_CUSTOMER_ACCOUNT_REFERENCE);
export const fetchCarList = createRoutine(
  'GET_CUSTOMER_ACCOUNT_REFERENCE_LIST',
);
export const createCar = createRoutine('CREATE_CUSTOMER_ACCOUNT_REFERENCE');
export const deleteCar = createRoutine(DELETE_CUSTOMER_ACCOUNT_REFERENCE);
export const updateCar = createRoutine(UPDATE_CUSTOMER_ACCOUNT_REFERENCE);
export const linkCarToParticipant = createRoutine(LINK_CAR_TO_PARTICIPANT);
export const unlinkCarToParticipant = createRoutine(UNLINK_CAR_TO_PARTICIPANT);

/** Promises */
export const createCarPromiseCreator = promisifyRoutine(createCar);
export const updateCarPromiseCreator = promisifyRoutine(updateCar);
export const deleteCarPromiseCreator = promisifyRoutine(deleteCar);
export const linkCarToParticipantPromise =
  promisifyRoutine(linkCarToParticipant);
export const unlinkCarToParticipantPromise = promisifyRoutine(
  unlinkCarToParticipant,
);

/** Initial State */
export type CarManagementState = {
  cars: CAR[];
  carList: CAR[];
};

const initialState: CarManagementState = {
  cars: [],
  carList: [],
};

/** Reducer */
// TODO - Remove explicit any
export default handleActions<CarManagementState, any>(
  {
    [fetchCar.SUCCESS]: (
      state,
      { payload }: { payload: FetchCarResponse },
    ) => ({
      ...state,
      cars: [payload],
    }),
    [fetchCarList.SUCCESS]: (
      state,
      { payload }: { payload: FetchCarListResponse },
    ) => ({
      ...state,
      carList: payload,
    }),
    [createCar.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [updateCar.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [linkCarToParticipant.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  } as ReducerMap<CarManagementState, CarManagementState>,
  initialState,
);

/** Selectors */
export const getPreTradeRiskState = (state: RootState) =>
  get(state, 'carManagement', {});

export const getCars = createSelector([getPreTradeRiskState], (state) =>
  get(state, 'cars', []),
);
export const getCarList = createSelector([getPreTradeRiskState], (state) =>
  get(state, 'carList', []),
);

export const getCarListSelectOptions = createSelector([getCarList], (cars) =>
  cars?.map((car: CAR) => ({
    key: car.customerAccountReference,
    value: car.customerAccountReference,
    text: car.customerAccountReference,
  })),
);
