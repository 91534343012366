import { ColDef } from 'ag-grid-community';
import { formatTimeLocalWithDoW } from '../../../../utils/time';
import moment from 'moment';
import Task from '../../../../ts/models/Task.model';
import { DEFAULT_DATE_FILTER_OPS } from '../../../../utils/agGrid/options';

// To the Task model, add attributes used for external filters
// Ag Grid can only filter on fields that are set as ColDefs
// So we add two hidden fields to the Task model.
export type TaskLogGridRow = Task & {
  type?: string;
  showFrequentTasks?: string;
};

export const getColDefs = (names: string[] = []): ColDef<TaskLogGridRow>[] => [
  {
    field: 'time',
    headerName: 'Time Received',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
    valueFormatter: ({ value }) =>
      value ? formatTimeLocalWithDoW(value, true) : '-',
  },
  {
    field: 'name',
    headerName: 'Name',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'status',
    headerName: 'Status',
    filter: 'agTextColumnFilter',
    floatingFilter: false,
    suppressHeaderFilterButton: true,
  },
  // BE uses type attribute to filter by status
  {
    field: 'type',
    hide: true,
    filter: 'agTextColumnFilter',
    suppressColumnsToolPanel: true,
  },
  {
    field: 'showFrequentTasks',
    hide: true,
    filter: 'agTextColumnFilter',
    suppressColumnsToolPanel: true,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    valueFormatter: ({ value }) =>
      `${moment
        .duration(value || 0, 'seconds')
        .asSeconds()
        .toFixed(3)} seconds`,
    sortable: false,
  },
];
