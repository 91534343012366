import React from 'react';
import { ColDef } from 'ag-grid-community';
import { Employee } from '../../../ts/models/User.model';
import { Label } from 'semantic-ui-react';
import { formatTimeLocal } from '../../../utils/time';
import get from 'lodash/get';
import SelectFilter from 'utils/agGrid/components/SelectFilter';

export const employeeStatus = [
  {
    key: '',
    value: '',
    text: 'All',
  },
  {
    key: 'false',
    value: 'false',
    text: 'Active',
  },
  {
    key: 'true',
    value: 'true',
    text: 'Disabled',
  },
];

const columnDefs: ColDef<Employee & { id: string }>[] = [
  {
    headerName: 'Email',
    field: 'email',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Created Date',
    field: 'createdAt',
    valueFormatter: ({ value }) => formatTimeLocal(value),
  },
  {
    headerName: 'User Id',
    field: 'id',
    valueFormatter: ({ data }) => get(data, 'userId', ''),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
    },
  },
  {
    headerName: 'Auth Id',
    field: 'authId',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Status',
    field: 'disabled',
    filter: SelectFilter,
    valueFormatter: ({ data }) => {
      return data?.disabled ? 'Disabled' : 'Active';
    },
    filterParams: {
      placeholder: 'Select Status',
      options: Object.values(employeeStatus).map((s) => ({
        key: s.key,
        value: s.value,
        text: s.text,
      })),
    },
    cellRenderer: ({ data }: { data: Employee }) => (
      <Label
        color={!data?.disabled ? 'green' : 'red'}
        content={!data?.disabled ? 'Active' : 'Disabled'}
      />
    ),
  },
];

export default columnDefs;
