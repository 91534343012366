import { format, formatISO } from 'date-fns';

// Format example: January 8th 2025, 8:15:00 PM
export const formatUnixTsLocal = (time: number): string => {
  return Number.isFinite(time)
    ? format(new Date(time), 'MMMM do yyyy, h:mm:ss a')
    : '';
};

// Get today's date in YYYY-MM-DD format
export const todaysDateIso8601 = (): string => {
  return formatISO(new Date(), { representation: 'date' });
};
