import React from 'react';
import { ColDef } from 'ag-grid-community';
import { formatTimeLocal } from '../../../utils/time';
import { DEFAULT_DATE_FILTER_OPS } from '../../../utils/agGrid/options';
import { Label, SemanticCOLORS, Icon } from 'semantic-ui-react';
import onDownloadRaw from '../../../utils/downloadXML';
import SelectFilter from 'utils/agGrid/components/SelectFilter';
import { capitalizeCamelCase } from 'utils/methods';
import Member from 'ts/models/Member.model';
import get from 'lodash/get';
import {
  CGMFile,
  PCSFile,
  FileStatus,
  FileType,
} from 'ts/models/InboundFile.model';

const COLOR_BY_STATUS: { [key: string]: SemanticCOLORS } = {
  rejected: 'red',
  processed: 'green',
  failed: 'red',
};

const fileTypeValues: FileType[] = ['cgm', 'pcs'];
const fileStatusValues: FileStatus[] = [
  'processed',
  'rejected',
  'failed',
  'parsed',
];

const inboundFilesGridColDefs = (
  memberOptions: { key: string; text: string | undefined; value: string }[],
  onOpenParsed?: (report: CGMFile | PCSFile) => void,
): ColDef<CGMFile | PCSFile>[] => [
  {
    headerName: 'Date Received',
    field: 'receivedTime',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
    valueFormatter: ({ value }) => formatTimeLocal(value),
  },
  {
    headerName: 'Date Processed',
    field: 'processedTime',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
    valueFormatter: ({ value }) => formatTimeLocal(value),
  },
  {
    headerName: 'Filename',
    field: 'fileName',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Status',
    field: 'status',
    filter: SelectFilter,
    filterParams: {
      placeholder: 'Select Status',
      options: Object.values(fileStatusValues).map((s) => ({
        key: s,
        value: s,
        text: capitalizeCamelCase(s),
      })),
    },
    cellRenderer: ({ data }: { data: CGMFile | PCSFile }) => (
      <Label
        className="capitalised"
        color={COLOR_BY_STATUS[data.status?.toLowerCase()]}
        content={data.status}
      />
    ),
  },
  {
    headerName: 'Member',
    field: 'memberId',
    valueFormatter: ({ data }: { data?: Member }) =>
      get(data, 'memberName', ''),
    filter: SelectFilter,
    filterParams: {
      placeholder: 'Select Member',
      options: memberOptions,
    },
  },
  {
    headerName: 'Firm Code',
    field: 'firmCode',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'File Type',
    field: 'type',
    filter: SelectFilter,
    filterParams: {
      placeholder: 'Select File Type',
      options: Object.values(fileTypeValues).map((s) => ({
        key: s,
        value: s,
        text: s.toUpperCase(),
      })),
    },
  },
  {
    headerName: 'Raw',
    floatingFilter: false,
    colId: 'raw',
    cellRenderer: ({ data }: { data: CGMFile | PCSFile }) => (
      <span>
        {
          <Icon
            link
            onClick={() => onDownloadRaw(data?.rawText, `${data?.fileName}`)}
            color="grey"
            name="arrow alternate circle right outline"
          />
        }
      </span>
    ),
  },
  {
    headerName: 'Parsed',
    floatingFilter: false,
    colId: 'parsed',
    cellRenderer: ({ data }: { data: CGMFile | PCSFile }) => (
      <span>
        {
          <Icon
            link
            onClick={() => onOpenParsed?.(data)}
            color="grey"
            name="arrow alternate circle right outline"
            data-testid="inbound-files-icon-parsed"
          />
        }
      </span>
    ),
  },
];

export default inboundFilesGridColDefs;
