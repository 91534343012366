import {
  GridOptions,
  CellClickedEvent,
  GridApi,
  IRowNode,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import get from 'lodash/get';
import { useMemo } from 'react';
import { GRID_ACTION } from '../components/Actions';

export const DEFAULT_ACTION_COL_ID = 'action';

export type EditCallbackArgs<T> = {
  api: GridApi<T>;
  node: IRowNode<T>;
  original: T;
};
export type EditableGridCallback<T> = (args: EditCallbackArgs<T>) => void;

interface Props<T> {
  options: GridOptions;
  onUpdate?: EditableGridCallback<T>;
  onDelete?: EditableGridCallback<T>;
  actionColId?: string;
}

const useEditableGrid = <T>({
  options,
  onUpdate,
  onDelete,
  actionColId = DEFAULT_ACTION_COL_ID,
}: Props<T>) => {
  const editOptions: GridOptions = useMemo(
    () => ({
      editType: 'fullRow',
      defaultColDef: {
        // Make sure users can't involuntarily apply changes by pressing enter
        suppressKeyboardEvent: () => true,
      },
      onRowEditingStarted: (params) => {
        params.api.refreshCells({
          columns: [actionColId],
          rowNodes: [params.node],
          force: true,
        });
      },
      onRowEditingStopped: (params) => {
        params.api.refreshCells({
          columns: [actionColId],
          rowNodes: [params.node],
          force: true,
        });
      },
      onCellClicked({ event, column, node, api }: CellClickedEvent) {
        // Make sure event comes from HTML Button
        if (!(event?.target instanceof HTMLButtonElement)) {
          return;
        }
        // Check if the click comes from the column defined as the Actions column
        if (
          column &&
          // Fix type inference
          'colId' in column &&
          column.colId === actionColId &&
          event.target.dataset.action
        ) {
          const action = event.target.dataset.action;

          if (node && node.rowIndex !== null && action === GRID_ACTION.EDIT) {
            const firstColumnkey = get(api.getDisplayedCenterColumns(), [
              '0',
              'colId',
            ]);
            if (firstColumnkey) {
              api.startEditingCell({
                rowIndex: node.rowIndex,
                // gets the first columnKey
                colKey: firstColumnkey,
              });
            }
          }

          if (action === GRID_ACTION.DELETE) {
            const original = node?.data ? { ...node?.data } : {};
            if (onDelete) {
              onDelete({ api, node, original });
            }
          }

          if (action === GRID_ACTION.UPDATE) {
            const original = node?.data ? { ...node?.data } : {};

            if (onUpdate) {
              // We stop editing before calling on update so the node has the latest value
              // false means we keep the changes after stopping the edit
              api.stopEditing(false);
              onUpdate({ api, node, original });
            }
          }

          if (action === GRID_ACTION.CANCEL) {
            api.stopEditing(true);
          }
        }
      },
    }),
    [actionColId, onDelete, onUpdate],
  );

  const gridOptions = useMemo(
    () => ({ ...options, ...editOptions }),
    [editOptions, options],
  );
  return { gridOptions };
};

export default useEditableGrid;
