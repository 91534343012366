import { ColDef } from 'ag-grid-community';
import { Exchange } from '../../../ts/models/Exchange.model';
import { GRID_ACTIONS_COL_DEF } from '../../../utils/agGrid/components/Actions';

const columnDefs: ColDef<Exchange>[] = [
  {
    field: 'name',
    headerName: 'Exchange Name',
    cellEditor: 'agTextCellEditor',
    editable: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'exchangeCode',
    headerName: 'Exchange Code',
    cellEditor: 'agTextCellEditor',
    editable: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'micCode',
    headerName: 'Exchange MIC Code',
    cellEditor: 'agTextCellEditor',
    editable: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'spanIdentifier',
    headerName: 'Exchange SPAN Identifier',
    cellEditor: 'agTextCellEditor',
    editable: true,
    filter: 'agTextColumnFilter',
  },
  GRID_ACTIONS_COL_DEF,
];

export default columnDefs;
