import get from 'lodash/get';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';

export const CGM = 'cgm';
export const PCS = 'pcs';

//* actions */
export const fetchFcmReports = createRoutine('FCM_FILES');
export const fetchFcmReportsPromise = promisifyRoutine(fetchFcmReports);

//* Initial State */
const initialState = {
  cgmCount: 0,
  cgmFiles: [],
  pcsCount: 0,
  pcsFiles: [],
  allFiles: [],
};

export default handleActions(
  {
    [fetchFcmReports.TRIGGER]: () => initialState,
    [fetchFcmReports.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      allFiles: [
        ...get(payload, 'cgmFiles', []).map((file) => ({ ...file, type: CGM })),
        ...get(payload, 'pcsFiles', []).map((file) => ({ ...file, type: PCS })),
      ],
    }),
  },
  {},
);

//* Selectors */
export const getFcmReportsState = (state) => get(state, 'fcmReports', {});
export const getFcmCgmReports = createSelector([getFcmReportsState], (state) =>
  get(state, 'cgmFiles', []),
);
export const getFcmPcsReports = createSelector([getFcmReportsState], (state) =>
  get(state, 'pcsFiles', []),
);
export const getFCMReportCounts = (state, type) =>
  get(state, `fcmReports.${type}Count`, 0);
export const getAllFcmReports = createSelector([getFcmReportsState], (state) =>
  get(state, 'allFiles', []),
);
// This selector supports multi-valued filtering (E.g. filtering by member only and not PCS/CGM
// Which isn't currently supported but still works for selecting reports
export const getFcmReports = (state, types = []) => [
  ...(types.includes(CGM) ? getFcmCgmReports(state) : []),
  ...(types.includes(PCS) ? getFcmPcsReports(state) : []),
];
