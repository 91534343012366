import React, { useCallback, useRef } from 'react';
import { GridReadyEvent, IServerSideDatasource } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { commonPaginationOptions } from 'utils/agGrid/options';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import FutureTrade from 'ts/models/FutureTrade.model';
import { FuturesTradesResponse } from 'ts/api/futures_trades';
import { ServerSideQuery } from 'utils/agGrid/types';
import { getSelectedAccountId } from 'reducers/accountHistoryReducer';
import { useAppSelector } from 'hooks/useAppSelector';
import { fetchTradeJournalsPromise } from 'reducers/balancesReducer';
import styled from 'styled-components';

interface FuturestradesGridProps {
  isJest?: boolean;
}

const StyledAgGridContainer = styled.div`
  min-height: 350px;
  .ag-root-wrapper {
    height: 100% !important;
  }
`;

export const FUTURES_TRADES_GRID_NAME = 'futures-trades';

const FuturesTradesGrid: React.FC<FuturestradesGridProps> = ({ isJest }) => {
  const gridRef = useRef<AgGridReactType>(null);
  const dispatch = useAppDispatch();
  const accountId: string = useAppSelector(getSelectedAccountId);
  const colDefs = columnDefs;

  const defaultColDef = {
    floatingFilter: false,
    suppressHeaderFilterButton: true,
  };

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: FUTURES_TRADES_GRID_NAME,
  });

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success }) => {
          const body = parseAGRequest(request, { transformAttrs: true });
          const reqBody: ServerSideQuery = {
            ...body,
            filter: [
              ...(body?.filter ? body.filter : []),
              { attr: 'account_id', op: 'eq', value: accountId },
              {
                attr: 'trade_type',
                op: 'eq',
                value: ['futures', 'delivery', 'fcm_delivery'],
              },
            ],
          };
          fetchTradeJournalsPromise(reqBody, dispatch).then(
            (response: FuturesTradesResponse) => {
              success({
                rowData: response?.tradeJournals,
              });
            },
          );
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [accountId, dispatch],
  );

  return (
    <StyledAgGridContainer className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact<FutureTrade>
          defaultColDef={defaultColDef}
          ref={gridRef}
          columnDefs={colDefs}
          gridOptions={commonPaginationOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
          rowModelType="serverSide"
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
        />
      )}
    </StyledAgGridContainer>
  );
};

export default FuturesTradesGrid;
