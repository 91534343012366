import React, {
  Component,
  Fragment,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, flatten, union, uniqBy, get, isNil } from 'lodash';
import { Header, Segment, Dropdown, Button } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import {
  createLoadingSelector,
  withFilters,
  datetimeOnchange,
  inputOnChange,
  filteredArrayToAttrValue,
} from 'erisxkit/client';
import ExternalTable from '../../common/table/ExternalTable';
import closingPricesMetadata from '../../metadata/closingPricesMetadata';
import {
  closingPrices,
  updateClosingPrice,
} from '../../actions/manualEntryActions';
import UpdateClosingPrice, {
  RequiredFields,
} from '../../components/ManualEntries/UpdateClosingPrice';
import {
  getClosingPrices,
  getClosingPricesCount,
} from '../../reducers/manualEntriesReducer';
import { getContracts, contractSymbols } from '../../reducers/contractsReducer';
import { getSelectorAsOptions, getExchangesAsOptions } from '../../selectors';
import DetailsHeader from '../../common/components/DetailsHeader';
import {
  CONTRACT_SYMBOLS_API_ENDPOINT,
  FUTURES_PRODUCTS_API_ENDPOINT,
  SPOT_PRODUCTS_API_ENDPOINT,
} from '../../api';
import { quickHttpPost } from '../../utils/quickHttp';
import cogoToast from 'cogo-toast';
import { withRouter } from 'react-router-dom';

const Table = withFilters(ExternalTable);

export const dividedOptions = (categories = []) =>
  flatten(
    categories.map((category) =>
      !isEmpty(category) && !category.hide
        ? union(
            [
              {
                key: category.key,
                as: () => (
                  <Dropdown.Header
                    key={category.key}
                    content={category.header}
                  />
                ),
              },
            ],
            [
              {
                key: `${category.key}-divider`,
                as: () => <Dropdown.Divider key={`${category.key}-divider`} />,
              },
            ],
            category.options,
          )
        : [],
    ),
  );

const tableFilters = (
  clearingSymbolOptions,
  contractSymbolOptions,
  spotProductOptions,
  futuresProductOptions,
  exchangeOptions,
  setProductSymbol,
  setExchangeMicCode,
) => [
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'Date',
    name: 'date',
    eqid: 'date',
    eqdefaultValue: '',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: false,
    inputProps: { placeholder: 'Select Date' },
    closeOnSelect: true,
    onChange: datetimeOnchange('date', 'eq'),
  },
  {
    placeholder: 'Select Exchange',
    component: Dropdown,
    name: 'exchange_mic_code',
    id: 'exchange_mic_code',
    label: 'Exchange',
    onChange:
      (onChange) =>
      (e, { name, value }) => {
        setExchangeMicCode(value);
        onChange(name, value, 'match');
      },
    options: exchangeOptions,
    selection: true,
    clearable: true,
  },
  {
    placeholder: 'Select a product',
    component: Dropdown,
    name: 'product_symbol',
    id: 'product_symbol',
    label: 'Product',
    clearable: true,
    selection: true,
    onChange:
      (onChange) =>
      (e, { name, value }) => {
        setProductSymbol(value);
        onChange(name, value, 'match');
      },
    options: dividedOptions([
      {
        header: 'Spot Products',
        options: spotProductOptions,
        key: 'spotFilter',
      },
      {
        header: 'Futures Products',
        options: futuresProductOptions,
        key: 'futuresFilter',
      },
    ]),
  },
  {
    placeholder: 'Select an exchange symbol',
    component: Dropdown,
    name: 'contract_symbol',
    id: 'contract_symbol',
    label: 'Exchange Symbol',
    onChange: inputOnChange,
    options: contractSymbolOptions,
    selection: true,
    search: true,
    clearable: true,
  },
  {
    placeholder: 'Select a contract',
    component: Dropdown,
    name: 'clearing_symbol',
    id: 'clearing_symbol',
    label: 'Contract',
    onChange: inputOnChange,
    options: clearingSymbolOptions,
    selection: true,
    search: true,
    clearable: true,
  },
  {
    placeholder: 'Select a settlement type',
    component: Dropdown,
    name: 'settlement_price_type',
    id: 'settlement_price_type',
    label: 'Settlement Type',
    onChange: inputOnChange,
    options: [
      { key: 'midDay', value: 'mid_day', text: 'Mid Day' },
      { key: 'adHoc', value: 'ad_hoc', text: 'Ad Hoc' },
      { key: 'endOfDay', value: 'end_of_day', text: 'End Of Day' },
      { key: 'final', value: 'final', text: 'Final' },
    ],
    selection: true,
    search: true,
    clearable: true,
  },
];

const mapStateToProps = (state) => ({
  closingPricesList: getClosingPrices(state),
  closingPricesCount: getClosingPricesCount(state),
  closingPricesLoading: createLoadingSelector(['CLOSING_PRICES'])(state),
  contractSymbolsLoading: createLoadingSelector(['CONTRACT_SYMBOLS'])(state),
  contracts: getContracts(state),
  exchangeOptions: getExchangesAsOptions(state),
});

const mapDispatchToProps = {
  closingPrices,
  updateClosingPrice,
  contractSymbols,
};

const ClosingPricesContainer = (props) => {
  // Current contract symbol value
  const [contractSymbol, setContractSymbol] = useState();
  // Products dropdown values
  const [spotProductOptions, setSpotProductOptions] = useState();
  const [futuresProductOptions, setFuturesProductOptions] = useState();
  const spotProductOptionsClean = useRef();
  const futuresProductOptionsClean = useRef();
  // Selected Product dropdown value
  const [productSymbol, setProductSymbol] = useState();
  // Contracts dropdown values
  const [clearingSymbolOptions, setClearingSymbolOptions] = useState();
  // Exchange Symbol dropdown values
  const [contractSymbolOptions, setContractSymbolOptions] = useState();
  // Selected Exchange dropdown value
  const [exchangeMicCode, setExchangeMicCode] = useState();
  // Values for input submission (top component)
  const [values, setValues] = useState({});
  // Form validation
  const isValid = useMemo(() => {
    let valid = true;
    for (const field of RequiredFields) {
      const fieldValue = get(values, field);
      if (isNil(fieldValue) || fieldValue === '') {
        valid = false;
      }
    }
    return valid;
  }, [values]);

  useEffect(() => {
    if (!exchangeMicCode) {
      setSpotProductOptions(spotProductOptionsClean.current);
      setFuturesProductOptions(futuresProductOptionsClean.current);
    }
  }, [exchangeMicCode]);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    if (!searchParams.has('product_symbol')) {
      setProductSymbol(undefined);
      // Handle clearing Exchange Symbol and Contract dropdowns when Clear button pressed
      setClearingSymbolOptions([]);
      setContractSymbolOptions([]);
    } else if (searchParams.has('product_symbol')) {
      // Handle filling out Product to populate Exchange Symbol and Contract dropdowns
      setProductSymbol(searchParams.get('product_symbol'));
    }

    if (!searchParams.has('exchange_mic_code')) {
      setExchangeMicCode(undefined);
    } else if (searchParams.has('exchange_mic_code')) {
      setExchangeMicCode(searchParams.get('exchange_mic_code'));
    }
  }, [props.location.search]);

  useEffect(() => {
    let payload;
    if (!exchangeMicCode) {
      payload = undefined;
    } else if (exchangeMicCode) {
      payload = {
        filter: [
          { attr: 'exchange_mic_code', op: 'eq', value: exchangeMicCode },
        ],
      };
    }
    quickHttpPost(FUTURES_PRODUCTS_API_ENDPOINT, payload)
      .then((response) => {
        const filteredFuturesProducts = response?.futuresProducts
          ?.map((c) => c?.symbol ?? '')
          ?.filter((c) => c !== '');
        const computed = uniqBy(filteredFuturesProducts).map((p) => ({
          key: p,
          text: p,
          value: p,
        }));
        if (payload === undefined) {
          futuresProductOptionsClean.current = computed;
        }
        setFuturesProductOptions(computed);
      })
      .catch((e) => cogoToast.error('Could not fetch futures products.'));
    quickHttpPost(SPOT_PRODUCTS_API_ENDPOINT, payload)
      .then((response) => {
        const filteredSpotProducts = response?.spotProducts
          ?.map((c) => c?.symbol ?? '')
          ?.filter((c) => c !== '');
        const computed = uniqBy(filteredSpotProducts).map((p) => ({
          key: p,
          text: p,
          value: p,
        }));
        if (payload === undefined) {
          spotProductOptionsClean.current = computed;
        }
        setSpotProductOptions(computed);
      })
      .catch((e) => cogoToast.error('Could not fetch spot products.'));
  }, [exchangeMicCode]);

  useEffect(() => {
    const fetchContracts = async () => {
      if (!productSymbol) {
        setClearingSymbolOptions([]);
        setContractSymbolOptions([]);
        return;
      }
      try {
        const response = await quickHttpPost(CONTRACT_SYMBOLS_API_ENDPOINT, {
          filter: [{ attr: 'product_symbol', value: productSymbol, op: 'eq' }],
        });
        const filteredClearingSymbols = response?.contracts
          ?.map((c) => c?.clearingSymbol ?? '')
          ?.filter((c) => c !== '');
        setClearingSymbolOptions(
          uniqBy(filteredClearingSymbols).map((c) => ({
            key: c,
            text: c,
            value: c,
          })),
        );
        const filteredExchangeSymbols = response?.contracts
          ?.map((c) => c?.symbol ?? '')
          ?.filter((c) => c !== '');
        setContractSymbolOptions(
          uniqBy(filteredExchangeSymbols).map((c) => ({
            key: c,
            text: c,
            value: c,
          })),
        );
      } catch (e) {
        cogoToast.error('Could not fetch contracts.');
      }
    };
    fetchContracts();
  }, [productSymbol]);

  const handleChange = useCallback(
    (e, { name, value }) => {
      setValues({
        ...values,
        [name]: value,
      });
    },
    [values],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      props.updateClosingPrice(values);
      setContractSymbol('');
    },
    [values, props.updateClosingPrice],
  );

  const fetchData = useCallback(
    (state) => {
      props.closingPrices({
        limit: state.pageSize,
        offset: state.page * state.pageSize,
        filter: filteredArrayToAttrValue(state.filtered),
      });
    },
    [props.closingPrices],
  );

  const {
    closingPricesList,
    closingPricesCount,
    closingPricesLoading,
    contracts,
    contractSymbolsLoading,
    exchangeOptions,
  } = props;

  return (
    <Fragment>
      <Segment>
        <Header as="h2">Closing Prices</Header>
        <UpdateClosingPrice
          data={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
        {contractSymbol && (
          <DetailsHeader
            item={contracts.find((each) => each.symbol === contractSymbol)}
            className="contract-details"
            compact
          />
        )}
        <Button
          type="button"
          id="SubmitClosingPrices"
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Submit
        </Button>
      </Segment>
      <Segment>
        <Header as="h3">History</Header>
        <Table
          title="closingPrices"
          data={closingPricesList}
          metadata={closingPricesMetadata(contractSymbolOptions)}
          onFetchData={fetchData}
          count={closingPricesCount}
          loading={closingPricesLoading || contractSymbolsLoading}
          noDataText="No closing prices found."
          minRows={10}
          filters={tableFilters(
            clearingSymbolOptions,
            contractSymbolOptions,
            spotProductOptions,
            futuresProductOptions,
            exchangeOptions,
            setProductSymbol,
            setExchangeMicCode,
          )}
        />
      </Segment>
    </Fragment>
  );
};

ClosingPricesContainer.propTypes = {
  closingPrices: PropTypes.func.isRequired,
  updateClosingPrice: PropTypes.func.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  closingPricesList: PropTypes.arrayOf(
    PropTypes.shape({
      baseAssetType: PropTypes.string,
      quotedAssetType: PropTypes.string,
      price: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
  exchangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  contractSymbolsLoading: PropTypes.bool,
  closingPricesLoading: PropTypes.bool,
  closingPricesCount: PropTypes.number,
};

ClosingPricesContainer.defaultProps = {
  contractSymbolsLoading: false,
  closingPricesLoading: false,
  closingPricesList: [],
  closingPricesCount: 0,
  exchangeOptions: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClosingPricesContainer));
