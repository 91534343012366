import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useMemo, useRef } from 'react';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import inboundFilesGridColDefs from './columnDefs';
import { commonPaginationOptions } from 'utils/agGrid/options';
import { showModal, hideModal } from 'erisxkit/client';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
} from 'ag-grid-community';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { ServerSideQuery } from 'utils/agGrid/types';
import { fetchFcmReportsPromise } from 'reducers/fcmReportsReducer';
import get from 'lodash/get';
import { PARSED_REPORT } from 'constants/modalTypes';
import { useAppSelector } from 'hooks/useAppSelector';
import { getMemberCollection } from 'reducers/membersReducer';
import Member from 'ts/models/Member.model';
import { CGMFile, PCSFile } from 'ts/models/InboundFile.model';

export const INBOUND_FILES_GRID_NAME = 'inbound-files';

type FetchFcmReportsRequest = ServerSideQuery & {
  returnRaw: string;
};

type FetchFcmReportsResponse = {
  cgmCount: number;
  cgmFiles: Omit<CGMFile, 'type'>[];
  pcsCount: number;
  pcsFiles: Omit<PCSFile, 'type'>[];
};

export const transformRowData = (
  response: FetchFcmReportsResponse,
): (CGMFile | PCSFile)[] => {
  return [
    ...get(response, 'cgmFiles', []).map((file: Omit<CGMFile, 'type'>) => ({
      ...file,
      type: 'cgm',
    })),
    ...get(response, 'pcsFiles', []).map((file: Omit<PCSFile, 'type'>) => ({
      ...file,
      type: 'pcs',
    })),
  ];
};

const gridOptions: GridOptions = {
  ...commonPaginationOptions,
  autoSizeStrategy: { type: 'fitCellContents' },
};

const InboundFilesGrid = () => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const fcmMembers = useAppSelector(getMemberCollection) as Member[];

  const memberOptions = useMemo(
    () =>
      Object.values(fcmMembers).map((m) => ({
        key: m.memberId,
        text: m.name,
        value: m.memberId,
      })),
    [fcmMembers],
  );

  const onOpenParsed = useCallback(
    (report: CGMFile | PCSFile) => {
      dispatch(
        showModal(PARSED_REPORT, {
          report,
          onClose: () => dispatch(hideModal(PARSED_REPORT)),
          size: 'large',
        }),
      );
    },
    [dispatch],
  );

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } = useAgGridCommon({
    gridName: INBOUND_FILES_GRID_NAME,
    gridRef
  });

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success }) => {
          const parsed = parseAGRequest(request, { transformAttrs: true });
          const body: FetchFcmReportsRequest = {
            ...parsed,
            returnRaw: 'true',
          };
          fetchFcmReportsPromise(body, dispatch).then((response) => {
            success({
              rowData: transformRowData(response),
            });
          });
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  const columnDefs = useMemo(
    () => inboundFilesGridColDefs(memberOptions, onOpenParsed),
    [onOpenParsed, memberOptions],
  );

  return (
    <>
      {gridVisible ? (
        <div className="ag-theme-quartz">
          {gridButtons}
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            onGridReady={onGridReady}
          />
        </div>
      ) : null}
    </>
  );
};

export default InboundFilesGrid;
