import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import * as actions from '../reducers/usersReducer';
import {
  UPDATE_EMPLOYEE_SUCCESS,
  fetchEmployees,
} from '../reducers/employeesReducer';
import api, * as urls from '../api';
import { createSaga } from '../utils/createSaga';
import * as userTypes from '../constants/userTypes';
import HTTP_METHODS from '../constants/httpMethods';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from 'ts/enums/HTTPMethod.enum';

function* users(arg) {
  const { page } = arg.payload;
  try {
    yield put(actions.fetchUsers.request());
    const data = yield call(api.post, urls.USERS_API_ENDPOINT, arg.payload);
    yield put(actions.fetchUsers.success({ ...data, page, req: arg.payload }));
  } catch (e) {
    yield put(actions.fetchUsers.failure(e.response));
  }
}

function* employees(arg) {
  const { page } = arg.payload;
  try {
    yield put(fetchEmployees.request());
    const data = yield call(api.post, urls.USERS_API_ENDPOINT, arg.payload);
    yield put(fetchEmployees.success({ ...data, page, req: arg.payload }));
  } catch (e) {
    yield put(fetchEmployees.failure(e.response));
  }
}

function* currentUser() {
  try {
    yield put(actions.fetchCurrentUser.request());
    const user = yield call(api.post, urls.CURRENT_USER_API_ENDPOINT);
    yield put(actions.fetchCurrentUser.success(user));
  } catch (e) {
    yield put(actions.fetchCurrentUser.failure(e.response));
  }
}

function* createMember(arg) {
  try {
    let newUser;
    let actionType;
    if (arg.payload.type === userTypes.member) {
      newUser = yield call(
        api.post,
        urls.CREATE_MEMBER_API_ENDPOINT,
        arg.payload.payload,
      );
      actionType = actions.CREATE_MEMBER_SUCCESS;
    }
    if (arg.payload.type === userTypes.employee) {
      newUser = yield call(
        api.post,
        urls.CREATE_EMPLOYEE_API_ENDPOINT,
        arg.payload.payload,
      );
      actionType = actions.CREATE_EMPLOYEE_SUCCESS;
    }
    yield put({
      type: actionType,
      data: { [newUser.userId]: newUser },
    });
  } catch (e) {
    yield put({ type: actions.CREATE_MEMBER_FAILED, payload: e.response });
  }
}

function* updateUser(arg) {
  const hideToast = cogoToast.loading('Updating user details...', {
    hideAfter: 0,
  });
  try {
    let actionType = actions.UPDATE_USER_SUCCESS;
    const newUser = yield call(
      api.post,
      urls.UPDATE_USER_API_ENDPOINT,
      arg.payload,
    );
    if (arg.payload.type === userTypes.employee) {
      actionType = UPDATE_EMPLOYEE_SUCCESS;
    }
    yield put({
      type: actionType,
      data: { [newUser.userId]: newUser },
      req: arg.payload,
    });
    hideToast();
    cogoToast.success('User details updated!');
  } catch (e) {
    hideToast();
    yield put({ type: actions.UPDATE_USER_FAILED, payload: e.response });
  }
}

function* addClearingAccount(arg) {
  const hideToast = cogoToast.loading('Adding clearing account to user...', {
    hideAfter: 0,
  });
  try {
    yield put(actions.addClearingAccountToMember.request());
    const newUser = yield call(
      api.post,
      urls.ADD_CLEARING_ACCOUNT_API_ENDPOINT,
      arg.payload,
    );
    yield put(
      actions.addClearingAccountToMember.success({ [newUser.userId]: newUser }),
    );
    hideToast();
    yield cogoToast.success(
      `Clearing account added to user with ID ${newUser.userId}.`,
    );
  } catch (e) {
    hideToast();
    yield put(actions.addClearingAccountToMember.failure(e.response));
  }
}

function* removeClearingAccount(arg) {
  const hideToast = cogoToast.loading(
    'Removing clearing account from user...',
    { hideAfter: 0 },
  );
  try {
    yield put(actions.removeClearingAccountFromMember.request());
    const newUser = yield call(
      api.post,
      urls.REMOVE_CLEARING_ACCOUNT_API_ENDPOINT,
      arg.payload,
    );
    yield put(
      actions.removeClearingAccountFromMember.success({
        [newUser.userId]: newUser,
      }),
    );
    hideToast();
    cogoToast.success(
      `Clearing account removed from user with ID ${newUser.userId}.`,
    );
  } catch (e) {
    hideToast();
    yield put(actions.addClearingAccountToMember.failure(e.response));
  }
}

function* memberStates() {
  try {
    const states = yield call(api.post, urls.MEMBER_STATES_API_ENDPOINT);
    yield put({ type: actions.MEMBER_STATES_SUCCESS, payload: states });
  } catch (e) {
    yield put({ type: actions.MEMBER_STATES_FAILED, payload: e.response });
  }
}

function* employeeRolesSaga() {
  try {
    const roles = yield call(api.post, urls.EMPLOYEE_ROLES_API_ENDPOINT);
    yield put(actions.fetchEmployeeRoles.success(roles));
  } catch (e) {
    yield put(actions.fetchEmployeeRoles.failure(e.response));
  }
}

function* memberSecurityQuestions(arg) {
  try {
    yield put(actions.fetchMemberSecurityQuestions.request());
    const questions = yield call(
      api.post,
      urls.MEMBER_SECURITY_QUESTIONS_API_ENDPOINT,
      { userId: arg.payload },
    );
    yield put(
      actions.fetchMemberSecurityQuestions.success({
        questions,
        userId: arg.payload,
      }),
    );
  } catch (e) {
    yield put(actions.fetchMemberSecurityQuestions.failure(e.response));
  }
}

function* memberComplianceQuestions(arg) {
  try {
    yield put(actions.fetchMemberComplianceQuestions.request());
    const questions = yield call(
      api.post,
      urls.MEMBER_COMPLIANCE_QUESTIONS_API_ENDPOINT,
      { userId: arg.payload },
    );
    yield put(
      actions.fetchMemberComplianceQuestions.success({
        questions,
        userId: arg.payload,
      }),
    );
  } catch (e) {
    yield put(actions.fetchMemberComplianceQuestions.failure(e.response));
  }
}

function* memberImages(arg) {
  try {
    yield put(actions.fetchMemberImages.request());
    const images = yield call(api.post, urls.MEMBER_IMAGES_API_ENDPOINT, {
      userId: arg.payload,
    });
    yield put(
      actions.fetchMemberImages.success({
        ...images,
        userId: arg.payload,
      }),
    );
  } catch (e) {
    yield put(actions.fetchMemberImages.failure(e.response));
  }
}

function* routeSubmittingOrdersToApiSaga({ payload }) {
  try {
    yield put(actions.routeSubmittingOrdersToApi.request());
    yield call(
      api.post,
      urls.ROUTE_SUBMITTING_ORDERS_TO_API_API_ENDPOINT,
      payload,
    );
    yield put(actions.routeSubmittingOrdersToApi.success());
  } catch (e) {
    yield put(actions.routeSubmittingOrdersToApi.failure(e.response));
  }
}

export default function* watch() {
  yield takeLatest(actions.fetchCurrentUser.TRIGGER, currentUser);
  yield takeLatest(
    actions.fetchMemberComplianceQuestions.TRIGGER,
    memberComplianceQuestions,
  );
  yield takeLatest(actions.fetchMemberImages.TRIGGER, memberImages);
  yield takeLatest(
    actions.fetchMemberSecurityQuestions.TRIGGER,
    memberSecurityQuestions,
  );
  yield takeEvery(
    actions.addClearingAccountToMember.TRIGGER,
    addClearingAccount,
  );
  yield takeEvery(
    actions.removeClearingAccountFromMember.TRIGGER,
    removeClearingAccount,
  );

  yield takeEvery(actions.fetchUsers.TRIGGER, users);
  yield takeEvery(fetchEmployees.TRIGGER, employees);
  yield takeLatest(actions.CREATE_MEMBER_REQUEST, createMember);
  // Old update user
  yield takeLatest(actions.UPDATE_USER_REQUEST, updateUser);
  // New Update User
  yield takeLatest(
    actions.updateUserRoutine,
    generateSaga(actions.updateUserRoutine, {
      loading: 'Updating user details...',
      success: 'User details updated!',
    }),
  );
  yield takeLatest(actions.MEMBER_STATES_REQUEST, memberStates);
  yield takeLatest(actions.fetchEmployeeRoles.TRIGGER, employeeRolesSaga);
  yield takeLatest(
    actions.memberSupportingDoc.TRIGGER,
    createSaga(actions.memberSupportingDoc, actions.MEMBER_SUPPORTING_DOC),
  );
  yield takeLatest(
    actions.fetchMemberUserPermissions.TRIGGER,
    createSaga(
      actions.fetchMemberUserPermissions,
      actions.MEMBER_USER_PERMISSIONS,
    ),
  );
  yield takeLatest(
    actions.fetchMemberUserTypes.TRIGGER,
    createSaga(
      actions.fetchMemberUserTypes,
      actions.fetchMemberUserTypes._PREFIX,
    ),
  );
  yield takeLatest(
    actions.fetchPii.TRIGGER,
    createSaga(actions.fetchPii, actions.PII),
  );
  yield takeLatest(
    actions.routeSubmittingOrdersToApi.TRIGGER,
    routeSubmittingOrdersToApiSaga,
  );
  yield takeLatest(
    actions.fetchMemberUserNotes.TRIGGER,
    createSaga(actions.fetchMemberUserNotes, actions.MEMBER_USER_NOTES),
  );
  yield takeLatest(
    actions.addNoteToMemberUser.TRIGGER,
    createSaga(actions.addNoteToMemberUser, actions.ADD_NOTE_TO_MEMBER_USER),
  );
  yield takeLatest(
    actions.editNoteOnMemberUser.TRIGGER,
    createSaga(actions.editNoteOnMemberUser, actions.EDIT_NOTE_ON_MEMBER_USER),
  );
  yield takeLatest(
    actions.resetMf.TRIGGER,
    createSaga(
      actions.resetMf,
      actions.RESET_MF,
      'Resetting users 2FA...',
      '2FA successfully reset.',
    ),
  );
  yield takeLatest(
    actions.fetchMemberUserDocuments,
    createSaga(
      actions.fetchMemberUserDocuments,
      actions.fetchMemberUserDocuments._PREFIX,
      '',
      '',
      {},
      {},
      {
        method: HTTP_METHODS.GET,
      },
    ),
  );
  yield takeLatest(
    actions.createMemberUser.TRIGGER,
    createSaga(
      actions.createMemberUser,
      actions.createMemberUser._PREFIX,
      'Creating Member User...',
      'Member User created.',
    ),
  );
  yield takeLatest(
    actions.createEmployee,
    generateSaga(actions.createEmployee, {
      method: HTTPMethod.POST,
      loading: 'Creating Employee...',
      success: 'Employe created successfully',
      prefix: actions.CREATE_EMPLOYEE_PREFIX,
    }),
  );
}
