import { AdvancedFilterModel } from 'ag-grid-community';
import { FilterModel, IServerSideGetRowsRequest } from 'ag-grid-community';
import {
  SupportedAgGridFilterOp,
  ServerSideFilterOp,
  ServerSideFilter,
  isSupportedAdvancedFilterModel,
  SupportedAdvancedFilterModel,
  SupportedDateFilterModel,
  isDateFilterModel,
} from '../types';
import { ColumnAdvancedFilterModel } from 'ag-grid-community';
import { isJoinedFilterModel } from '../types';
import { SetFilterModel } from 'ag-grid-community';

export const DEFAULT_FILTER_OP: ServerSideFilterOp = 'eq';

export const AG_GRID_TO_SERVER_FILTER_OP_MAP: Record<
  SupportedAgGridFilterOp,
  ServerSideFilterOp
> = {
  equals: 'eq',
  notEqual: 'ne',
  contains: 'contain',
  greaterThan: 'gt',
  greaterThanOrEqual: 'gte',
  lessThan: 'lt',
  lessThanOrEqual: 'lte',
  // Filters that may not match 100%
  blank: 'exists',
  notBlank: 'exists',
  endsWith: 'ne',
  notContains: 'ne',
  startsWith: 'ne',
  false: 'eq',
  true: 'ne',
  empty: 'exists',
  inRange: 'gt',
};
const getFilterOp = (
  type: SupportedAgGridFilterOp | null | undefined,
): ServerSideFilterOp => {
  if (!type) return DEFAULT_FILTER_OP;
  const op = AG_GRID_TO_SERVER_FILTER_OP_MAP[type];
  if (!op) return DEFAULT_FILTER_OP;
  return op;
};

/**
 * @param colId - Id of the column that's being filtered
 * @param filterModel - Filter model that can be either joined or simple filter
 * @param filterList - list of the server side filters that is being constructed
 * @description Receives a filter model and the current list of already parsed ServerSideFilter
 * and recursively adds the parsed filterModel into the filterList
 */
const flattenJoinedFilters = (
  colId: string,
  filter: AdvancedFilterModel | FilterModel | SupportedDateFilterModel,
  filterList: ServerSideFilter[],
) => {
  // Advanced Filter can be joined or ColumnAdvanced Filter
  if (isJoinedFilterModel(filter)) {
    // If joined, it has conditions list which has AdvancedFilterModels
    // that can be also joined filters, so we recursively flatten them out
    filter.conditions.forEach((condition) => {
      flattenJoinedFilters(colId, condition, filterList);
    });
  } else {
    // AdvancedFilter
    const filters = parseNonJoinedFilter(colId, filter);
    if (filters) filterList.push(...filters);
  }
};

/**
 * @param colId - Id of the column that's being filtered
 * @param filterModel - Non-joined date filtermodel
 * @returns List of ServerSideFilters compatible with our nomenclature
 * @description Parses a DateFilterModel into a ServerSideFilter. Specific logic for this
 * is required due to the unique nature of date filtering (different type definitions)
 */
export const parseDateFilterModel = (
  colId: string,
  filterModel: SupportedDateFilterModel,
): ServerSideFilter[] => {
  const filters: ServerSideFilter[] = [];
  if (isDateFilterModel(filterModel)) {
    const { dateFrom, dateTo, type } = filterModel;
    const isRange = type === 'inRange';
    if (dateFrom) {
      filters.push({
        attr: colId,
        op: isRange ? 'gte' : getFilterOp(type),
        value: new Date(dateFrom)?.toISOString(),
      });
    }
    if (dateTo) {
      filters.push({
        attr: colId,
        op: isRange ? 'lte' : getFilterOp(type),
        value: new Date(dateTo)?.toISOString(),
      });
    }
  }

  return filters;
};

/**
 * @param colId - Id of the column that's being filtered
 * @param filterModel - Non-joined filtermodel
 * @description Receives a non joined filter (aka simple filter) and parses it
 * @returns List of ServerSideFilters compatible with our nomenclature
 */
export const parseNonJoinedFilter = (
  colId: string,
  filterModel:
    | ColumnAdvancedFilterModel
    | FilterModel
    | SupportedDateFilterModel
    | SetFilterModel,
): ServerSideFilter[] | undefined => {
  switch (filterModel.filterType) {
    case 'set': {
      const { values } = filterModel as SetFilterModel;
      if (!values?.length || values.length === 0) return [];
      return [
        {
          attr: colId,
          op: 'eq',
          value: values.filter((value): value is string => value !== null),
        },
      ];
    }
    case 'text':
    case 'number':
    case 'object': {
      const { type, filter } = filterModel as SupportedAdvancedFilterModel;
      if (filter)
        return [
          {
            attr: colId,
            op: getFilterOp(type),
            value: filter,
          },
        ];
      break;
    }
    case 'date':
    case 'dateString':
      return parseDateFilterModel(
        colId,
        filterModel as SupportedDateFilterModel,
      );

    default:
      return [];
  }
};

/**
 * @param gridFilters - Filter model as received from the getRows params
 * @returns Array of server side filters compatible with our nomenclature
 */
export const parseAGFilters = (
  gridFilters: AdvancedFilterModel | FilterModel | null,
): ServerSideFilter[] => {
  const serverSideFilters: ServerSideFilter[] = [];
  if (!gridFilters) return serverSideFilters;
  // Iterate for each active filter
  Object.entries(gridFilters).forEach(([colId, filterModel]) => {
    // If filter is joined, recursively flatten filters
    if (isJoinedFilterModel(filterModel)) {
      flattenJoinedFilters(colId, filterModel, serverSideFilters);
    } else {
      // If filter is a non-joined, non-boolean filter
      if (isSupportedAdvancedFilterModel(filterModel)) {
        const filters = parseNonJoinedFilter(colId, filterModel);
        if (filters) serverSideFilters.push(...filters);
      }
    }
  });
  return serverSideFilters;
};
