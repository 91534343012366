import React, { useCallback, useEffect, useMemo } from 'react';
import { getFormValues, change } from 'redux-form';
import {
  Checkbox,
  CheckboxProps,
  Divider,
  Dropdown,
  DropdownProps,
} from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { MEMBER_PERSONAL_FORM_NAME } from './PersonalInformation';
import {
  FETCH_EXCHANGE_LIST,
  fetchExchanges,
  getExchangeList,
} from '../../../reducers/exchangeReducer';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { RootState } from '../../../ts/types/store';
import { Exchange } from '../../../ts/models/Exchange.model';

const AllowedExchanges = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) =>
    createLoadingSelector([FETCH_EXCHANGE_LIST])(state),
  );
  const exchangeList: Exchange[] = useAppSelector(getExchangeList);

  useEffect(() => {
    dispatch(fetchExchanges());
  }, [dispatch]);

  const values = useAppSelector((state: RootState) =>
    getFormValues(MEMBER_PERSONAL_FORM_NAME)(state),
  );

  const memberIsAllowedMarginFutures = useMemo(
    () => Boolean(values?.allowFuturesMargin),
    [values?.allowFuturesMargin],
  );
  const currentAllowedExchanges: string[] = useMemo(
    () => values?.allowedExchanges || [],
    [values?.allowedExchanges],
  );


  return memberIsAllowedMarginFutures ? (
    <div className="xwizard-form-group">
      <h4>Allowed Exchanges</h4>
      <Divider />
      <Dropdown
        placeholder="Allowed Exchanges"
        fluid
        multiple
        selection
        disabled={loading}
        loading={loading}
        style={{ width: '550px' }}
        value={currentAllowedExchanges}
        search
        onChange={(
          _event: React.SyntheticEvent<HTMLElement>,
          { value }: DropdownProps,
        ) => {
          dispatch(
            change(MEMBER_PERSONAL_FORM_NAME, 'allowedExchanges', value),
          );
        }}
        options={exchangeList.map((e) => ({
          key: e.name,
          value: e.micCode,
          text: e.name,
        }))}
      />
    </div>
  ) : null;
};

export default AllowedExchanges;
