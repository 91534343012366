import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';
import getColumnDefs from './columnDefs';
import FinanceAccount from '../../../ts/models/FinanceAccount.model';
import { common, mergeGridOpts } from 'utils/agGrid/options';
import { GridOptions } from 'ag-grid-community';

interface FinanceAccountsGridProps {
  data: FinanceAccount[];
  onSelectAccount: (accountId: string) => void;
  isJest?: boolean;
}

const gridOptions: GridOptions = mergeGridOpts(common, {
  rowStyle: { cursor: 'pointer' },
});
export const gridName = 'finance-accounts';

const FinanceAccountsGrid: React.FC<FinanceAccountsGridProps> = ({
  data,
  isJest,
  onSelectAccount,
}) => {
  const gridRef = useRef<AgGridReact>(null);

  const columnDefs = useMemo(
    () => getColumnDefs(onSelectAccount),
    [onSelectAccount],
  );

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [initialGridSettings?.columnSizing]);

  const onRowClicked = useCallback(
    ({ data }: { data: FinanceAccount }) => {
      const accountId = data?.accountId;
      if (accountId) {
        onSelectAccount(accountId);
      }
    },
    [onSelectAccount],
  );

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
          onRowClicked={onRowClicked}
        />
      )}
    </div>
  );
};

export default FinanceAccountsGrid;
