import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  renderDropdown,
  renderField,
  renderRadio,
  rules,
} from 'erisxkit/client';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { BANK, FEE, WALLET } from '../constants/accountTypes';
import { providerTypes, SILVERGATE_SEN } from '../constants/providerTypes';
import SubExchangeSelectionContainer from '../common/containers/SubExchangeSelectionContainer';

const CreateFinanceAccount = ({
  assetType,
  assetTypeOptions,
  change,
  initialize,
  provider,
  selectedAccount,
  typeOptions,
  type,
  unusedWalletsOptions,
  unusedWallets,
}) => {
  initialize({ ...selectedAccount }, { keepDirty: true });
  const handleTypeChange = (value) => {
    // converting accountType to type because that's what fixtures expect
    change('type', value);
    if (value === WALLET) {
      change('provider', 'bitgo');
    }
  };

  const handleAssetTypeChange = (value) => {
    if (type !== BANK) {
      unusedWallets(value);
    }
  };

  return (
    <Fragment>
      <Form.Group>
        <Field
          name="accountType"
          component={renderDropdown}
          label="Type"
          placeholder="eg. Bank, Wallet ..."
          options={typeOptions}
          onChange={handleTypeChange}
          required
          validate={[rules.required]}
        />
        <Field
          component={SubExchangeSelectionContainer}
          name="subExchangeId"
          label="SubExchange"
          placeholder="Select SubExchange"
        />
      </Form.Group>
      {type === WALLET && (
        <Fragment>
          <h4 className="dividing">Wallet Type:</h4>
          <Form.Group inline>
            <Field name="walletType" component={renderRadio} label="Hot" />
            <Field name="walletType" component={renderRadio} label="Warm" />
            <Field name="walletType" component={renderRadio} label="Cold" />
          </Form.Group>
        </Fragment>
      )}
      {type === BANK || type === WALLET ? (
        <section className="xwizard-form-group">
          <h4 className="dividing">Asset Type and Provider Details</h4>
          <Form.Group widths="equal">
            <Field
              name="assetType"
              component={renderDropdown}
              label="Asset Type"
              placeholder="eg. BTC, ETH ..."
              options={assetTypeOptions}
              onChange={handleAssetTypeChange}
              required
              validate={[rules.required]}
            />
            <Field
              name="providerAccountId"
              component={type === BANK ? renderField : renderDropdown}
              disabled={!assetType}
              label="Provider Account Id"
              placeholder="Provider Account Id"
              options={unusedWalletsOptions}
              required
              validate={[rules.required]}
            />
            <Field
              name="provider"
              component={type === BANK ? renderDropdown : renderField}
              label="Provider"
              options={providerTypes}
              required
              validate={[rules.required]}
            />
          </Form.Group>
        </section>
      ) : (
        ''
      )}
      {type === BANK && (
        <section className="xwizard-form-group">
          <h4 className="dividing">Bank Deposit Details</h4>
          {provider !== providerTypes[SILVERGATE_SEN].value && (
            <Field
              label="Routing Number"
              component={renderField}
              name="routingNumber"
            />
          )}
          <Field
            label="Recipient Name"
            component={renderField}
            name="recipientName"
            placeholder="eg. Customer Non-Segregated Account"
          />
          <Field
            label="Recipient Address"
            component={renderField}
            name="recipientAddress"
            placeholder="eg. 123 Main Street, Suite 2017, Chicago IL 60606 USA"
          />
          <Field
            label="Recipient Bank Name"
            component={renderField}
            name="recipientBank"
            placeholder="eg. Bank of USA"
          />
          <Field
            label="Bank Address"
            component={renderField}
            name="bankAddress"
            placeholder="eg. 1 Financial Lane, Beverly Hills CA 90210 USA"
          />
        </section>
      )}
      {type === FEE ? (
        <Field name="feeType" component={renderField} label="Fee Type" />
      ) : (
        ''
      )}
    </Fragment>
  );
};

CreateFinanceAccount.propTypes = {
  assetType: PropTypes.string,
  assetTypeOptions: PropTypes.array,
  change: PropTypes.func,
  initialize: PropTypes.func,
  provider: PropTypes.string,
  selectedAccount: PropTypes.object,
  type: PropTypes.string,
  typeOptions: PropTypes.array,
  unusedWallets: PropTypes.func,
  unusedWalletsOptions: PropTypes.array,
};

CreateFinanceAccount.defaultProps = {
  assetType: '',
  assetTypeOptions: [],
  change: () => {},
  initialize: () => {},
  provider: '',
  providerOptions: [],
  selectedAccount: {},
  type: '',
  typeOptions: [],
  unusedWallets: () => {},
  unusedWalletsOptions: [],
};

export default reduxForm({
  form: 'create_finance_account',
})(CreateFinanceAccount);
