import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useMemo, useRef } from 'react';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs, { MATCH_FILTER_FIELDS, MemberGridRow } from './columnDefs';
import { commonPaginationOptions, mergeGridOpts } from 'utils/agGrid/options';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { GridReadyEvent, IServerSideDatasource } from 'ag-grid-community';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { ServerSideQuery } from 'utils/agGrid/types';
import { fetchMembersPromise, selectMember } from 'reducers/membersReducer';
import useExternalFilter from 'utils/agGrid/hooks/useExternalFilter';
import { FilterWrapper, Wrapper } from './MembersGrid.styles';
import Member from 'ts/models/Member.model';
import history from 'constants/history';
import ExternalFilters from './ExternalFilters';

const opts = mergeGridOpts(commonPaginationOptions, {
  rowStyle: { cursor: 'pointer' },
});

export const MEMBERS_GRID_NAME = 'members';

type FetchMembersRequest = ServerSideQuery & {
  includeName: string;
};

type MembersGridProps = { isJest?: boolean };

const MembersGrid = ({ isJest }: MembersGridProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: MEMBERS_GRID_NAME,
  });

  const onGridReadyCallback = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success }) => {
          const parsed = parseAGRequest(request, { transformAttrs: true });
          const gridFilters = parsed?.filter || [];

          const body: FetchMembersRequest = {
            ...parsed,
            filter: gridFilters.map((filter) => {
              if (MATCH_FILTER_FIELDS.includes(filter.attr)) {
                return { ...filter, op: 'match' };
              }
              return filter;
            }),
            includeName: 'true',
          };
          fetchMembersPromise(body, dispatch).then((response) => {
            success({
              rowData: response?.members,
            });
          });
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  const options = useMemo(
    () => ({ ...opts, onGridReady: onGridReadyCallback }),
    [onGridReadyCallback],
  );

  const onRowClicked = useCallback(
    ({ data }: { data: Member }) => {
      const memberId = data?.memberId;
      if (memberId) {
        dispatch(selectMember(memberId));
        history.push(`/members/${memberId}/details`);
      }
    },
    [dispatch],
  );

  const externalFilterProps = useExternalFilter<MemberGridRow>({
    gridRef,
    options,
  });

  return (
    <>
      {gridVisible ? (
        <Wrapper>
          <FilterWrapper>
            <ExternalFilters {...externalFilterProps} />
          </FilterWrapper>
          <div className="ag-theme-quartz">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              gridOptions={externalFilterProps.gridOptions}
              onStateUpdated={onStateUpdated}
              initialState={initialGridSettings}
              rowModelType="serverSide"
              onRowClicked={onRowClicked}
            />
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};

export default MembersGrid;
