import { AgGridReact } from 'ag-grid-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GridReadyEvent, IServerSideDatasource } from 'ag-grid-community';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { commonPaginationOptions, mergeGridOpts } from 'utils/agGrid/options';
import { useAppDispatch } from 'hooks/useAppDispatch';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import {
  EMPLOYEES,
  fetchEmployeesPromiseCreator,
  selectEmployee,
} from 'reducers/employeesReducer';
import { ServerSideQuery } from 'utils/agGrid/types';
import { employee } from 'constants/userTypes';
import history from 'constants/history';
import { Employee } from 'ts/models/User.model';
import { createLoadingSelector, hideModal, showModal } from 'erisxkit/client';
import {
  createEmployeePromise,
  createMember,
  USERS,
} from 'reducers/usersReducer';
import { useAppSelector } from 'hooks/useAppSelector';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import get from 'lodash/get';
import { getFormValues } from 'redux-form';
import { CREATE_USER_FORM } from 'containers/MemberUsers/CreateMember';
import { CREATE_MEMBER } from 'constants/modalTypes';
import * as userTypes from 'constants/userTypes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .add-button {
    width: 170px;
    align-self: flex-end;
    margin-bottom: 0px !important;
  }
  > div {
    margin: 10px 5px 10px 5px;
  }
`;
interface EmployeesGridProps {
  isJest?: boolean;
}
type FetchEmployeesRequest = ServerSideQuery & {
  includeName: string;
};

const gridOptions = mergeGridOpts(commonPaginationOptions, {
  defaultColDef: { floatingFilter: false },
  rowStyle: { cursor: 'pointer' },
});

export const EMPLOYEES_GRID_NAME = 'employees';

const EmployeesGrid = ({ isJest }: EmployeesGridProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } =
    useAgGridCommon({
      isJest,
      gridName: EMPLOYEES_GRID_NAME,
      gridRef,
    });

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success }) => {
          const body = parseAGRequest(request, { transformAttrs: true });
          const reqBody: FetchEmployeesRequest = {
            ...body,
            filter: [
              ...(body?.filter ? body.filter : []),
              { attr: 'type', op: 'eq', value: employee },
            ],
            includeName: 'true',
          };
          fetchEmployeesPromiseCreator(reqBody, dispatch).then((response) => {
            success({
              rowData: response?.users,
            });
          });
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  const onRowClicked = useCallback(({ data }: { data: Employee }) => {
    if (data?.userId) {
      selectEmployee(data.userId);
      history.push(`/employees/${data.userId}`);
    }
  }, []);

  const createEmployee = useCallback(
    (payload: { memberUserType: null; email: string }) => {
      createEmployeePromise(payload, dispatch).then(() => {
        if (gridRef?.current) {
          gridRef.current.api.refreshServerSide({ purge: true });
        }
      });
    },
    [dispatch, gridRef],
  );
  const onCreateClick = useCallback(() => {
    dispatch(
      showModal(CREATE_MEMBER, {
        hideModal: hideModal,
        onApply: createEmployee,
        type: userTypes.employee,
        getValuesFromSelector: true,
      }),
    );
  }, [dispatch, createEmployee]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible ? (
        <>
          <Wrapper>
            <Button
              onClick={onCreateClick}
              className="add-button"
              size="small"
              floated="right"
            >
              <Icon name="add" /> Create Employee
            </Button>
            {gridButtons}
          </Wrapper>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            onRowClicked={onRowClicked}
          />
        </>
      ) : null}
    </div>
  );
};

export default EmployeesGrid;
