import { ColDef } from 'ag-grid-community';

const columnDefs: ColDef[] = [
  {
    field: 'taskId',
    headerName: 'Task ID',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'state',
    headerName: 'State',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'value',
    headerName: 'Filename',
    filter: 'agMultiColumnFilter',
    valueFormatter: (d) => d?.data?.value || '-',
  },
];

export default columnDefs;
