import React from 'react';
import { Button } from 'semantic-ui-react';
import { camelCase } from 'change-case-object';
import { formatTimeLocal } from '../utils/time';
import parseSymbol from 'utils/parseSymbol';

const baseMarginRatesMetadataCommonEdit = (
  row,
  onEdit,
  setTempOverrideMarginValue,
  setTempCommentValue,
  edit,
  arrOnEdit,
  fieldEdited,
  onFocusOut,
  currentField,
) => {
  return edit ? (
    <div style={{ display: 'flex' }}>
      {!!arrOnEdit.find(
        (x) =>
          x.exchangeMicCode === row?.exchangeMicCode &&
          x.product === row?.product &&
          x.expirationFront === row?.expirationFront &&
          x.expirationBack === row?.expirationBack,
      ) && fieldEdited === currentField ? (
        <input
          type={currentField === 'override_margin_rate_usd' ? 'number' : 'text'}
          style={{ width: '72px' }}
          value={row[camelCase(currentField)] || ''}
          onChange={({ target = {} }) => {
            if (currentField === 'override_margin_rate_usd') {
              setTempOverrideMarginValue(row, target.value);
            }
            if (currentField === 'comment') {
              setTempCommentValue(row, target.value);
            }
          }}
          onBlur={() => {
            onFocusOut();
          }}
        />
      ) : (
        <span style={{ minWidth: '72px' }}>{row[camelCase(currentField)]}</span>
      )}
      <hr className="hr-vertical"></hr>
      <Button
        className="link edit"
        content="Edit"
        onClick={() => {
          onEdit(row, currentField);
        }}
      />
    </div>
  ) : (
    <div>{row[camelCase(currentField)]}</div>
  );
};

const baseMarginRatesMetadata = (
  onEdit,
  setTempOverrideMarginValue,
  setTempCommentValue,
  edit = false,
  arrOnEdit = [],
  fieldEdited,
  onFocusOut,
) => [
  {
    Header: 'Created',
    id: 'tail_risk_created_at',
    accessor: (row) => formatTimeLocal(row.tailRiskCreatedAt),
  },
  {
    Header: 'Exchange',
    id: 'exchangeMicCode',
    accessor: ({ frontContract: symbol }) =>
      parseSymbol(symbol).exchangeMicCode,
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
  },
  {
    Header: 'Expiration 1',
    id: 'expiration_front',
    accessor: (row) => row.frontContract + ' - ' + row.expirationFront,
  },
  {
    Header: 'Expiration 2',
    id: 'expiration_back',
    accessor: (row) =>
      `${row?.backContract || row.frontContract} - ${row.expirationBack}`,
  },
  {
    Header: 'Reference Price',
    id: 'ref_price',
    accessor: 'refPrice',
  },
  {
    Header: 'Contract Size',
    id: 'contract_size',
    accessor: 'contractSize',
  },
  {
    Header: 'Spot Tail Risk',
    id: 'spot_risk',
    accessor: 'spotRisk',
  },
  {
    Header: 'Cash Basis Adjustment',
    id: 'cash_basis_adjustment',
    accessor: 'cashBasisAdjustment',
  },
  {
    Header: 'Calendar Adjustment',
    id: 'calendar_adjustment',
    accessor: 'calendarAdjustment',
  },
  {
    Header: 'Funding Adjustment',
    id: 'funding_adjustment',
    accessor: 'fundingAdjustment',
  },
  {
    Header: 'Spread Charge',
    id: 'spread_charge',
    accessor: 'spreadCharge',
  },
  {
    Header: 'Base Margin Rate',
    id: 'base_margin_rate',
    accessor: 'baseMarginRate',
  },
  {
    Header: 'Base Margin Rate $',
    id: 'base_margin_rate_usd',
    accessor: 'baseMarginRateUsd',
  },
  {
    Header: 'Base Margin Rate $ Override',
    id: 'override_margin_rate_usd',
    accessor: (row) => {
      return baseMarginRatesMetadataCommonEdit(
        row,
        onEdit,
        setTempOverrideMarginValue,
        setTempCommentValue,
        edit,
        arrOnEdit,
        fieldEdited,
        onFocusOut,
        'override_margin_rate_usd',
      );
    },
  },
  {
    Header: 'Comments',
    id: 'comment',
    accessor: (row) => {
      return baseMarginRatesMetadataCommonEdit(
        row,
        onEdit,
        setTempOverrideMarginValue,
        setTempCommentValue,
        edit,
        arrOnEdit,
        fieldEdited,
        onFocusOut,
        'comment',
      );
    },
  },
];

export default baseMarginRatesMetadata;
