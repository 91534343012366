import { ColDefField, FilterModel, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SupportedAgGridFilterOp } from '../types';
import get from 'lodash/get';
import { FilterChangedEvent } from 'ag-grid-community';
import { GridReadyEvent } from 'ag-grid-community';
import { clearGridFilters } from '../options';
import isEmpty from 'lodash/isEmpty';

const DEFAULT_DEBOUNCE_TIME = 500;
const DEFAULT_LOCAL_VALUE = {};

type SetFilterValue = number | string | boolean;
export type FilterValue = SetFilterValue | Array<SetFilterValue>;
export type ExternalFilterValues<T> = Partial<
  Record<ColDefField<T>, FilterValue>
>;

interface SetExternalFilterArgs<T> {
  colId: ColDefField<T> | string;
  value: FilterValue;
  op?: SupportedAgGridFilterOp;
  debounce?: boolean;
}
export interface ISetExternalFilter<T> {
  (args: SetExternalFilterArgs<T>): void;
}

export interface ISetFilterFromEvent<T> {
  (
    e: React.ChangeEvent<HTMLInputElement>,
    opts?: Partial<SetExternalFilterArgs<T>>,
  ): void;
}

export interface IGetFilterValue {
  (colId: string): FilterValue;
}
/**
 * @param gridRef - A ref to the AG Grid Instance
 * @param onGridReadyCallback - Additional callback to be triggered onGridReady.
 * @returns Object containing function to set external filters (either directly, from an event, or checkbox)
 *  a function to get the value of a filter and a onGridReady callback that MUST be passed to the AgGridReact instance
 */

type UseExternalFilterOpts = {
  gridRef: React.RefObject<AgGridReact>;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  options?: GridOptions;
};
export type UseExternalFilterResult = ReturnType<typeof useExternalFilter>;

const useExternalFilter = <T = unknown>({
  onGridReadyCallback,
  options = {},
  gridRef,
}: UseExternalFilterOpts) => {
  // State object to keep track of the value of each external filter
  // Allowing to show the value on the component immediately, regardless of debouncing
  const [localFilterValues, setLocalValue] =
    useState<ExternalFilterValues<T>>(DEFAULT_LOCAL_VALUE);
  // Keep a local stateful copy of the Grid's filter model that can be linked to React components
  const [filterModel, setFilterModel] = useState<FilterModel | null>(null);

  const updateLocalFilterModel = useCallback(
    (event: FilterChangedEvent) => {
      if (event.api && event.api.getFilterModel()) {
        const newFilterModel = event.api.getFilterModel();
        setFilterModel(newFilterModel);
        if (!newFilterModel || isEmpty(newFilterModel)) {
          setLocalValue(DEFAULT_LOCAL_VALUE);
        }
      }
    },
    [setFilterModel],
  );
  // Keep track of the Grid's ready status. Can only be updated from outside the hook
  const [gridReady, setGridReady] = useState(false);
  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      setGridReady(true);
      if (onGridReadyCallback) {
        onGridReadyCallback(params);
      }
    },
    [onGridReadyCallback],
  );

  const gridOptions = useMemo(
    () => ({
      ...options,
      onGridReady: (params: GridReadyEvent) => {
        setGridReady(true);
        if (options && options.onGridReady) {
          options?.onGridReady(params);
        } else if (onGridReadyCallback) {
          onGridReadyCallback(params);
        }
      },
    }),
    [options, onGridReadyCallback],
  );

  useEffect(() => {
    const api = gridRef?.current?.api;
    if (api) {
      api.addEventListener('filterChanged', updateLocalFilterModel);
    }
    return () => {
      if (api && !api.isDestroyed()) {
        api?.removeEventListener('filterChanged', updateLocalFilterModel);
      }
    };
  }, [gridReady, gridRef, updateLocalFilterModel]);

  /**
   * @description Updates the filter model of the grid
   * @param colId - colId representing a field of a ColDef
   * @param value - value to filter colId for
   * @param op - filter operation to apply
   */
  const changeHandler = useCallback(
    (
      colId: string,
      value: SetFilterValue | Array<SetFilterValue>,
      op: SupportedAgGridFilterOp = 'contains',
    ) => {
      const filterModel: FilterModel = {
        [colId]: { type: op, filter: value },
      };
      if (gridRef.current) {
        const currentFilters = gridRef.current.api.getFilterModel();
        gridRef.current.api.setFilterModel({
          ...currentFilters,
          ...filterModel,
        });
        gridRef.current.api.onFilterChanged();
      }
    },
    [gridRef],
  );
  const debouncedChangeHandler = useMemo(
    () =>
      debounce(({ colId, value, op }: SetExternalFilterArgs<T>) => {
        changeHandler(colId, value, op);
      }, DEFAULT_DEBOUNCE_TIME),
    [changeHandler],
  );

  /**
   *  @description Clears all grid filters
   */
  const clearFilters = useCallback(
    () => clearGridFilters(gridRef?.current?.api),
    [gridRef],
  );

  /**
   * @param colId - colId representing a field of a ColDef
   * @param value - value to filter colId for
   * @param op - filter operation to apply
   * @param debounce - if true debounces the callback
   */
  const setExternalFilter = useCallback(
    ({ colId, value, op, debounce }: SetExternalFilterArgs<T>) => {
      // Update local value so filter components reflect new values immediately
      setLocalValue({
        ...localFilterValues,
        [colId]: value,
      } as ExternalFilterValues<T>);
      // Debounce the updating of the grid's filter model to prevent multiple calls
      debouncedChangeHandler({ colId, value, op, debounce });
    },
    [debouncedChangeHandler, localFilterValues],
  );

  const setFilterFromEvent = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      opts?: Partial<SetExternalFilterArgs<T>>,
    ) => {
      if (e?.target) {
        const { name: colId, value } = e.target;
        setExternalFilter({
          colId,
          value,
          ...opts,
        } as SetExternalFilterArgs<T>);
      }
    },
    [setExternalFilter],
  );

  const setExternalBooleanFilter = useCallback(
    ({ colId, value, debounce }: SetExternalFilterArgs<T>) => {
      if (!colId) return;
      setExternalFilter({
        colId,
        value: value ? 'true' : 'false',
        op: 'equals',
        debounce,
      });
    },
    [setExternalFilter],
  );

  const hasFiltersApplied = useMemo(() => {
    return !isEmpty(filterModel) || !isEmpty(localFilterValues);
  }, [filterModel, localFilterValues]);

  /**
   * @param colId - colId representing a field of a ColDef
   * @description returns the value of colId inside the local filtermodel
   */
  const getFilterValue = useCallback(
    (colId: string) => get(localFilterValues, colId, ''),
    [localFilterValues],
  );

  /**
   * @param colId - colId representing a field of a ColDef
   * @description since boolean filter values are modeled as strings
   *              this callback casts them into a bool
   */
  const getBooleanFilterValue = useCallback(
    (colId: string) => getFilterValue(colId) === 'true',
    [getFilterValue],
  );

  return {
    // Set grid filter callbacks
    setExternalFilter,
    setFilterFromEvent,
    setExternalBooleanFilter,
    // Get filter value to populate filter component
    getFilterValue,
    getBooleanFilterValue,
    // onGridReady callback necessary for external filters to work
    onGridReady,
    // Utils
    clearFilters,
    hasFiltersApplied,
    gridOptions,
  };
};

export default useExternalFilter;
