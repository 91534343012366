import React, { PureComponent, Fragment } from 'react';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { getFormInitialValues } from 'redux-form';
import PropTypes from 'prop-types';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import { titleCase } from 'change-case';
import { Checkbox } from 'semantic-ui-react';
import { getAssetTypesAsOptions } from '../../selectors';
import { assetTypes } from '../../actions/utilActions';
import {
  createFuturesProduct,
  futuresProducts,
  updateFuturesProduct,
  getFuturesProductsByExchange,
} from '../../reducers/futuresProductReducer';
import {
  createFuturesContract,
  futuresContracts,
  getFuturesContracts,
  getFuturesContractsCount,
  updateContract,
} from '../../reducers/contractsReducer';
import futuresProductsMetadata from '../../metadata/futuresProductsMetadata';
import futuresContractsMetadata from '../../metadata/futuresContractsMetadata';
import {
  CREATE_FUTURES_CONTRACT,
  CREATE_FUTURES_PRODUCT,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../../constants/modalTypes';
import ProductsWithNestedContracts from '../../common/components/ProductsWithNestedContracts';
import { shallowObjectDiff } from '../../utils/methods';
import { FUTURES } from '../../constants/productTypes';

const HeaderDiv = styled.div`
  display: block;
  height: 55px;
`;

const SubHeaderDiv = styled.div`
  width: 200px;
  float: right;
`;

const mapDispatchToProps = {
  assetTypes,
  futuresContracts,
  futuresProducts,
  createFuturesContract,
  createFuturesProduct,
  updateContract,
  updateFuturesProduct,
  showModal,
  hideModal,
};

const mapStateToProps = (state) => ({
  assetTypesOptions: getAssetTypesAsOptions(state),
  futuresContractsList: getFuturesContracts(state),
  futuresContractsCount: getFuturesContractsCount(state),
  futuresContractsLoading: createLoadingSelector(['FUTURES_PRODUCTS'])(state),
  futuresProductsList: getFuturesProductsByExchange(state),
  futuresProductsLoading: createLoadingSelector(['FUTURES_PRODUCTS'])(state),
  futuresContractInitialValues: getFormInitialValues('CreateFuturesContract')(
    state,
  ),
  futuresProductInitialValues: getFormInitialValues('CreateFuturesProduct')(
    state,
  ),
});

class FuturesProductsContainer extends PureComponent {
  state = {
    expanded: {},
    expandedSymbol: '',
    expandedExchangeMicCode: '',
    showExpiredContracts: false,
    showDelistedProducts: false,
  };
  componentDidMount = () => {
    this.props.assetTypes();
    this.props.futuresProducts({
      filter: [
        {
          attr: 'delisted_at',
          op: 'exists',
          value: this.state.showDelistedProducts.toString(),
        },
      ],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showDelistedProducts !== this.state.showDelistedProducts) {
      const filter = [
        {
          attr: 'delisted_at',
          op: 'exists',
          value: this.state.showDelistedProducts.toString(),
        },
      ];
      this.props.futuresProducts({ filter });
    }
  }

  onFetchData = (state) => {
    const filter = [
      { attr: 'product_symbol', op: 'eq', value: this.state.expandedSymbol },
      {
        attr: 'exchange_mic_code',
        op: 'eq',
        value: this.state.expandedExchangeMicCode,
      },
    ];
    if (!this.state.showExpiredContracts) {
      filter.push({
        attr: 'expiration_time',
        op: 'gte',
        value: moment().subtract(7, 'days').format(),
      });
    }
    this.props.futuresContracts({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
    });
  };

  confirmAction =
    (documentType) =>
    (payload = {}) => {
      const {
        modalType,
        hideModal,
        showModal,
        futuresContractInitialValues,
        futuresProductInitialValues,
      } = this.props;
      const actionType = payload.type || 'create';
      const formPayload = omit(payload, ['type', 'actionType']);
      showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
        header: `Confirm ${titleCase(actionType)} Futures ${titleCase(documentType)}`,
        msg: `${titleCase(actionType)} futures ${documentType} with the following details?`,
        hideModal,
        closeOnEscape: true,
        onConfirm: () => {
          if (actionType === 'create') {
            if (documentType === 'contract') {
              this.props.createFuturesContract(formPayload);
            } else {
              this.props.createFuturesProduct(formPayload);
            }
          } else if (documentType === 'contract') {
            this.props.updateContract({
              contractSymbol: futuresContractInitialValues.symbol,
              update: formPayload,
            });
          } else {
            const update = shallowObjectDiff(
              formPayload,
              futuresProductInitialValues,
            );
            this.props.updateFuturesProduct({
              productSymbol: futuresProductInitialValues.symbol,
              exchangeMicCode: formPayload.exchangeMicCode,
              update,
            });
          }
          hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
          hideModal(modalType);
        },
        payload,
        action: `${titleCase(actionType)} futures ${documentType}`,
      });
    };

  render = () => (
    <Fragment>
      <HeaderDiv>
        <SubHeaderDiv>
          <Checkbox
            className="show-expired w200"
            name="chkExpired"
            width={'200px'}
            label="Show expired contracts"
            checked={this.state.showExpiredContracts}
            onChange={() =>
              this.setState({
                showExpiredContracts: !this.state.showExpiredContracts,
                expanded: {},
                expandedSymbol: '',
              })
            }
          />
          <Checkbox
            className="show-delisted w200"
            label="Show delisted products"
            checked={this.state.showDelistedProducts}
            width={200}
            onChange={() =>
              this.setState({
                ...this.state,
                showDelistedProducts: !this.state.showDelistedProducts,
              })
            }
          />
        </SubHeaderDiv>
      </HeaderDiv>
      <ProductsWithNestedContracts
        contractModalProps={{
          confirmAction: this.confirmAction('contract'),
          modalType: CREATE_FUTURES_CONTRACT,
        }}
        productModalProps={{
          assetTypesOptions: this.props.assetTypesOptions,
          confirmAction: this.confirmAction('product'),
          modalType: CREATE_FUTURES_PRODUCT,
        }}
        filterContractsBySymbol={this.filterContractsBySymbol}
        hideModal={this.props.hideModal}
        tableProps={{
          metadata: futuresProductsMetadata,
          data: this.props.futuresProductsList,
          loading: this.props.futuresProductsLoading,
          expanded: this.state.expanded,
          onExpandedChange: (
            newExpanded,
            index,
            event,
            {
              isExpanded,
              row, // use nesting level, not contract code
            },
          ) => {
            if (!isExpanded) {
              this.props.futuresContracts({
                attr: 'product_symbol',
                op: 'eq',
                value: row.symbol,
              });
            }
            this.setState({
              expanded: { [index]: newExpanded[index] !== false },
              expandedSymbol: row.symbol,
              expandedExchangeMicCode: row?.exchangeMicCode,
            });
          },
        }}
        showModal={this.props.showModal}
        subtableProps={{
          onFetchData: this.onFetchData,
          count: this.props.futuresContractsCount,
          data: this.props.futuresContractsList,
          loading: this.props.futuresContractsLoading,
          metadata: futuresContractsMetadata({
            showModal: this.props.showModal,
            modalType: CREATE_FUTURES_CONTRACT,
            modalProps: {
              hideModal: this.props.hideModal,
              type: 'update',
              confirmAction: this.confirmAction('contract'),
            },
          }),
        }}
        type={FUTURES}
      />
    </Fragment>
  );
}

FuturesProductsContainer.propTypes = {
  assetTypes: PropTypes.func.isRequired,
  assetTypesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  createFuturesContract: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  futuresContracts: PropTypes.func.isRequired,
  futuresContractsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  futuresContractsLoading: PropTypes.bool,
  futuresProductsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  futuresProducts: PropTypes.func.isRequired,
  createFuturesProduct: PropTypes.func.isRequired,
  futuresProductsLoading: PropTypes.bool,
  updateContract: PropTypes.func.isRequired,
  updateFuturesProduct: PropTypes.func.isRequired,
};

FuturesProductsContainer.defaultProps = {
  assetTypesOptions: [],
  futuresContractsList: [],
  futuresContractsLoading: false,
  futuresProductsList: [],
  futuresProductsLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuturesProductsContainer);
