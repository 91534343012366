import ApprovalReqsContainer from '../containers/ApprovalReqsContainer';
import ReconciliationIndexContainer from '../containers/ReconciliationIndexContainer';
import ClearingMemberAccountsContainer from '../containers/ClearingMemberAccounts/ClearingMemberAccountsContainer';
import AccountHistoryContainer from '../containers/ClearingMemberAccounts/AccountHistoryContainer';
import ClearingMemberAccountDetails from '../containers/ClearingMemberAccounts/ClearingMemberAccountDetails';
import ReconciliationContainer from '../containers/ReconciliationContainer';
import ReconciliationHistoryContainer from '../containers/ReconciliationHistoryContainer';
import MemberUserContainer from '../containers/MemberUsers/MemberUserIndexContainer';
import MemberUserDetailsContainer from '../containers/MemberUsers/MemberUserDetailsContainer';
import MemberDetailsContainer from '../containers/Members/MemberDetailsContainer';
import ManualEntryContainer from '../containers/ManualEntries/ManualEntryContainer';
import AddCollateralPerAccountWrapper from '../components/ClearingMemberAccounts/AddCollateralPerAccountWrapper';
import SystemMessages from '../containers/SystemLogs/SystemMessages';
import TradesContainer from '../containers/TradesContainer';
import ManualOnboardingContainer from '../containers/ManualOnboardingContainer';
import CreateAssetTypeContainer from '../containers/Products/CreateAssetTypeContainer';
import EmployeeIndexContainer from '../containers/Employees/EmployeeIndexContainer';
import FinanceAccountIndexContainer from '../containers/FinanceAccounts/FinanceAccountIndexContainer';
import FinanceAccountDetailsContainer from '../containers/FinanceAccounts/FinanceAccountDetailsContainer';
import CoreFilesContainer from '../containers/CoreFilesContainer';
import AboutContainer from '../containers/AboutContainer';
import ProfileContainer from '../containers/ProfileContainer';
import ConfigurationContainer from '../containers/Configuration/ConfigurationContainer';
import TransactionTabsContainer from '../containers/Transactions/TransactionTabsContainer';
import PositionsTabsContainer from '../containers/Transactions/PositionsTabsContainer';
import SettlementServiceContainer from '../containers/Transactions/SettlementServiceContainer';
import JournalsTabsContainer from '../containers/Transactions/JournalsTabsContainer';
import FundsSegregationBalancesContainer from '../containers/FundsSegregationBalancesContainer';

import ProductsContainer from '../containers/Products/ProductsContainer';
import Home from '../components/Home';
import BalancesIndexContainer from '../containers/Balances/BalancesIndexContainer';
import SurveillanceContainer from '../containers/Surveillance/SurveillanceContainer';
import MemberIndexContainer from '../containers/Members/MemberIndexContainer';
import CreateMemberContainer from '../containers/Members/CreateMember/CreateMemberContainer';
import RiskEngineContainer from '../containers/RiskEngine/RiskEngineContainer';
import RiskDetailContainer from '../containers/RiskEngine/RiskDetailContainer';
import RiskAlertsContainer from '../containers/RiskAlerts/RiskAlertsContainer';
import RiskAlertDetailsContainer from '../containers/RiskAlerts/RiskAlertDetailsContainer';
import OnboardingWorkflowContainer from '../containers/MemberUsers/OnboardingWorkflowContainer';
import MemberUserOnboardingContainer from '../containers/MemberUsers/MemberUserOnboardingContainer';
import PromotionsContainer from '../containers/Promotions/Promotions';
import ClientTransfers from '../containers/ClientTransfers';
import MarginRatesContainer from '../containers/MarginRates/MarginRatesContainer';
import Reports from '../containers/Reports';
import ClearingInitialMarginRatesContainer from '../containers/ClearingInitialMarginRates/ClearingInitialMarginRatesContainer';
import RiskConfigurations from '../components/RiskConfiguration/RiskConfigurations';
import PositionTransfer from '../containers/Transactions/PositionTransfer';

export const PROMOTIONS_PATH = '/promotions'
export const APPROVALS_PATH = '/approvals'
export const REPORTS_PATH = '/reports'
export const POSITIONS_PATH = '/positions'

const routes = [
  {
    path: '/home',
    layout: Home,
    exact: true,
  },
  {
    path: APPROVALS_PATH,
    layout: ApprovalReqsContainer,
    exact: true,
  },
  {
    path: '/profile',
    layout: ProfileContainer,
    exact: true,
  },
  {
    path: '/onboarding',
    layout: OnboardingWorkflowContainer,
    exact: true,
  },
  {
    path: PROMOTIONS_PATH,
    altPath: APPROVALS_PATH,
    layout: PromotionsContainer,
    exact: true,
  },
  {
    path: '/onboarding/:memberUserId',
    layout: MemberUserOnboardingContainer,
    exact: true,
  },
  {
    path: '/reconciliation',
    layout: ReconciliationIndexContainer,
    exact: true,
  },
  {
    path: '/reconciliation/:accountId',
    layout: ReconciliationHistoryContainer,
    breadcrumb: 'Reconciliation History',
    exact: true,
  },
  {
    path: '/reconciliation/:accountId/reconcile',
    layout: ReconciliationContainer,
    breadcrumb: 'Reconcile',
    exact: true,
  },
  {
    path: '/reconciliation/:accountId/:reconciliationUUID/reconcile',
    layout: ReconciliationContainer,
    breadcrumb: 'Reconciliation',
    exact: true,
  },
  {
    path: '/finance_accounts',
    layout: FinanceAccountIndexContainer,
    breadcrumb: 'Finance Accounts',
    exact: true,
  },
  {
    path: '/finance_accounts/:accountId',
    layout: FinanceAccountDetailsContainer,
    breadcrumb: 'Finance Account Details',
    exact: true,
  },
  {
    path: '/clearing_accounts',
    layout: ClearingMemberAccountsContainer,
    breadcrumb: 'Clearing Accounts',
    exact: true,
  },
  {
    path: '/clearing_accounts/:accountId',
    layout: ClearingMemberAccountDetails,
    breadcrumb: 'Clearing Member Account Details',
    exact: true,
  },
  {
    path: '/clearing_accounts/:accountId/history',
    layout: AccountHistoryContainer,
    breadcrumb: 'Transaction History',
    exact: true,
  },
  {
    path: '/member_users',
    layout: MemberUserContainer,
    exact: true,
  },
  {
    path: '/member_users/:userId/settings/onboard',
    layout: ManualOnboardingContainer,
    breadcrumb: 'Manual Onboarding',
    exact: true,
  },
  {
    path: '/member_users/:userId',
    layout: MemberUserDetailsContainer,
    breadcrumb: 'Member User Details',
    exact: true,
  },
  {
    path: '/members',
    layout: MemberIndexContainer,
    breadcrumb: 'Members',
    exact: true,
  },
  {
    path: '/members/create_member',
    layout: CreateMemberContainer,
    breadcrumb: 'Create New Member',
    exact: true,
  },
  {
    path: '/members/:memberId/update_member',
    layout: CreateMemberContainer,
    breadcrumb: 'Update Member',
    exact: true,
  },
  {
    path: '/members/:memberId/details',
    layout: MemberDetailsContainer,
    breadcrumb: 'Member Details',
    exact: true,
  },
  {
    path: '/employees',
    layout: EmployeeIndexContainer,
    exact: true,
  },
  {
    path: '/employees/:userId',
    layout: MemberUserDetailsContainer,
    exact: true,
  },
  {
    path: '/alerts',
    layout: SystemMessages,
    exact: true,
  },
  {
    path: '/trades',
    layout: TradesContainer,
    exact: true,
  },
  {
    path: '/requests',
    layout: TransactionTabsContainer,
    breadcrumb: 'Request Log',
    exact: true,
  },
  {
    path: '/settlement_service',
    layout: SettlementServiceContainer,
    exact: true,
  },
  {
    path: POSITIONS_PATH,
    layout: PositionsTabsContainer,
    breadcrumb: 'Positions Log',
    exact: true,
  },
  {
    path: '/position_transfer',
    layout: PositionTransfer,
    breadcrumb: 'Position Transfer',
    exact: true,
  },
  {
    path: '/journals',
    layout: JournalsTabsContainer,
    breadcrumb: 'Journals Log',
    exact: true,
  },
  {
    path: '/manual_entries',
    layout: ManualEntryContainer,
    breadcrumb: 'Manual Entry',
    exact: true,
  },
  {
    path: '/create_asset_type',
    layout: CreateAssetTypeContainer,
    exact: true,
  },
  {
    path: '/core_files',
    layout: CoreFilesContainer,
    exact: true,
  },
  {
    path: '/surveillance',
    layout: SurveillanceContainer,
    exact: true,
  },
  {
    path: '/risk',
    layout: RiskEngineContainer,
    exact: true,
    breadcrumb: 'Risk Management',
  },
  {
    path: '/risk/:snapshotId/:accountNumber/detail',
    layout: RiskDetailContainer,
    exact: true,
    breadcrumb: 'Risk Detail',
  },
  {
    path: '/risk_alert',
    layout: RiskAlertsContainer,
    exact: true,
    breadcrumb: 'Risk Management',
  },
  {
    path: '/risk_alert/:alertId/detail',
    layout: RiskAlertDetailsContainer,
    exact: true,
    breadcrumb: 'Alert Detail',
  },
  {
    path: '/margin_rates',
    layout: MarginRatesContainer,
    exact: true,
    breadcrumb: 'Risk Management',
  },
  {
    path: '/configuration',
    layout: ConfigurationContainer,
    exact: true,
    breadcrumb: 'System Configuration',
  },
  {
    path: '/products',
    layout: ProductsContainer,
    breadcrumb: 'Products',
  },
  {
    path: '/about',
    layout: AboutContainer,
    exact: true,
  },
  {
    path: '/designated_funds',
    layout: FundsSegregationBalancesContainer,
    breadcrumb: 'All',
    exact: true,
  },
  {
    path: '/balances',
    layout: BalancesIndexContainer,
    breadcrumb: 'Balances',
    exact: true,
  },
  {
    path: '/balances/:accountId/collateral/add_collateral',
    layout: AddCollateralPerAccountWrapper,
    breadcrumb: 'Add Collateral',
    exact: true,
  },
  {
    path: '/balances/:accountId',
    layout: ClearingMemberAccountDetails,
    breadcrumb: 'Balance Details',
    exact: true,
  },
  {
    path: '/balances/:accountId/history',
    layout: AccountHistoryContainer,
    breadcrumb: 'Transaction History',
    exact: true,
  },
  {
    path: '/client_transfers',
    layout: ClientTransfers,
    exact: true,
  },
  {
    path: REPORTS_PATH,
    layout: Reports,
    exact: true,
    altPath: POSITIONS_PATH,
  },
  {
    path: '/margin_requirements',
    layout: ClearingInitialMarginRatesContainer,
  },
  {
    path: '/risk_configurations',
    layout: RiskConfigurations,
  },
];

export default routes;
