import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import {
  createLoadingSelector,
  withFilters,
  filteredArrayToAttrValue,
  sortedArrayToAttrValue,
  datetimeOnchange,
  inputOnChange,
  getItemsCount,
  getItemsPaged,
} from 'erisxkit/client';
import PropTypes from 'prop-types';
import Table from '../../common/table/ExternalTable';
import { rejectedJournals } from '../../reducers/journalsReducer';
import { REJECTED_JOURNALS } from '../../constants/actionTypes';
import rejectedJournalsMetadata from '../../metadata/rejectedJournalsMetadata';
import AgGridWrapper from '../../common/table/agGrid/AgGridWrapper';
import RejectedJournalsGrid, {
  REJECTED_JOURNALS_GRID_NAME,
} from '../../components/agGrid/Journals/RejectedJournalsGrid';

const slice = ['journals', 'rejected'];

const mapStateToProps = (state) => ({
  rejectedJournalsList: getItemsPaged(slice)(state),
  count: getItemsCount(slice)(state),
  loading: createLoadingSelector([REJECTED_JOURNALS])(state),
});

const mapDispatchToProps = {
  rejectedJournals,
};

const TableWithFilter = withFilters(Table);

const rejectedJournalsFilters = [
  {
    component: Input,
    id: 'uuid',
    label: 'UUID',
    name: 'uuid',
    onChange: inputOnChange,
    placeholder: 'Enter UUID',
  },
  {
    clearable: true,
    closeOnSelect: true,
    component: Datetime,
    id: 'time',
    label: 'Time',
    inputProps: { placeholder: 'Time journal was rejected' },
    name: 'time',
    onChange: datetimeOnchange('time', 'gte'),
  },
  {
    clearable: true,
    component: Dropdown,
    defaultValue: [],
    id: 'tx-type',
    label: 'Transaction Type',
    multiple: true,
    name: 'txType',
    onChange: inputOnChange,
    options: ['reversal', 'closeout'].map((name) => ({
      key: name,
      value: name,
      text: name,
    })),
    placeholder: 'Select type(s)...',
    search: true,
    selection: true,
  },
];

class RejectedJournalsContainer extends PureComponent {
  fetchData = (state) => {
    let filter = [];
    let sort = [];
    if (state.filtered.length !== 0) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }
    if (state.sorted.length !== 0) {
      sort = sortedArrayToAttrValue(state.sorted);
    }
    this.props.rejectedJournals({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
      sort,
    });
  };

  render = () => (
    <AgGridWrapper
      gridName={REJECTED_JOURNALS_GRID_NAME}
      agGridComponent={<RejectedJournalsGrid isJest={this.props.isJest} />}
      reactTableComponent={
        <TableWithFilter
          metadata={rejectedJournalsMetadata}
          data={this.props.rejectedJournalsList}
          filters={rejectedJournalsFilters}
          loading={this.props.loading}
          noDataText="No rejected journals found."
          onFetchData={this.fetchData}
          sortable
          title="rejected-journals"
        />
      }
    />
  );
}

RejectedJournalsContainer.propTypes = {
  loading: PropTypes.bool,
  rejectedJournals: PropTypes.func.isRequired,
  rejectedJournalsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

RejectedJournalsContainer.defaultProps = {
  loading: false,
  rejectedJournalsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RejectedJournalsContainer);
