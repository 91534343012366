import Auth from 'services/Auth';
import history from '../constants/history';

type LoginContainerProps = {
  auth: Auth;
};

const LoginContainer = ({ auth }: LoginContainerProps) => {
  if (!auth.isAuthenticated()) {
    auth.login();
  } else {
    history.push('/home');
  }
  return null;
};

export default LoginContainer;
