import {
  APPROVALS_PATH,
  POSITIONS_PATH,
  PROMOTIONS_PATH,
  REPORTS_PATH,
} from './routes';

const PATH_TO_ALT_PATH = {
  [PROMOTIONS_PATH]: APPROVALS_PATH,
  [REPORTS_PATH]: POSITIONS_PATH,
};

export default PATH_TO_ALT_PATH;
