import React from 'react';
import { Icon } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';

export default ({ showModal, modalType, modalProps }) =>
  ({ delivery, symbol }) => [
    {
      Header: `${symbol} Contracts`,
      columns: [
        {
          id: 'cycle',
          Header: 'Cycle',
          accessor: 'cycle',
        },
        {
          id: 'clearingSymbol',
          Header: 'Clearing Code',
          accessor: 'clearingSymbol',
        },
        {
          id: 'internalSymbol',
          Header: 'Exchange Code',
          accessor: 'internalSymbol',
        },
        {
          id: 'firstTradeDate',
          Header: 'First Trade Date',
          accessor: ({ firstTradeDate }) => formatTimeLocal(firstTradeDate),
        },
        {
          id: 'expirationTime',
          Header: 'Maturity Date and Time',
          accessor: ({ expirationTime }) => formatTimeLocal(expirationTime),
        },
        {
          id: 'lastTradeDate',
          Header: 'Last Trade Date',
          accessor: ({ lastTradeDate }) => formatTimeLocal(lastTradeDate),
        },
        {
          id: 'listingDate',
          Header: 'Listing Date',
          accessor: ({ listingDate }) => formatTimeLocal(listingDate),
        },
        {
          id: 'delistingDate',
          Header: 'Delisting Date',
          accessor: ({ delistingDate }) => formatTimeLocal(delistingDate),
        },
        {
          id: 'upper',
          Header: 'Upper Boundary',
          accessor: 'upper',
        },
        {
          id: 'lower',
          Header: 'Lower Boundary',
          accessor: 'lower',
        },
        {
          id: 'description',
          Header: 'Description',
          accessor: 'description',
        },
        {
          id: 'spanId',
          Header: 'Span Id',
          accessor: 'spanId',
        },
        {
          id: 'edit',
          Header: '',
          maxWidth: 30,
          resizable: false,
          Cell: ({ original }) => (
            <Icon
              id="editContract"
              name="edit"
              link
              onClick={() =>
                showModal(modalType, {
                  symbol,
                  type: 'update',
                  ...modalProps,
                  ...original,
                  productDelivery: delivery,
                })
              }
            />
          ),
        },
      ],
    },
  ];
