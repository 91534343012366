import _ from 'lodash';
import {
  arrayToObject,
  createCount,
  createPages,
  createCurrentPage,
  createById,
} from 'erisxkit/client';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { handleActions } from 'redux-actions';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import {
  fetchDesignatedBalanceDetails,
  fetchMultiAccountBalances,
  positions,
  positionSummary,
  positionsAggregated,
  clearPositions,
  positionTransferTypes,
  positionTransfers,
  clearPositionTransfers,
  fetchBalances,
} from '../actions/accountsActions';
import { FUNDS_SEGREGATION_BALANCES } from '../constants/actionTypes';
import { getSelectedAccountIdBySlice } from '../selectors';
import { createCollateralWithdrawalJournal } from '../reducers/collateralReducer';
import { getFuturesContracts } from '../reducers/contractsReducer';
import { getSelectedAccount } from '../reducers/accountHistoryReducer';

export const TRADE_JOURNALS = 'TRADE_JOURNALS';
export const MOVEMENTS = 'MOVEMENTS';
export const fetchMovements = createRoutine(MOVEMENTS);
export const fetchTradeJournals = createRoutine(TRADE_JOURNALS);
export const fetchMovementsPromise = promisifyRoutine(fetchMovements);
export const fetchTradeJournalsPromise = promisifyRoutine(fetchTradeJournals);

const initialState = {
  byId: {},
  rolledUpById: {},
};

export const fundsSegregationBalances = createRoutine(
  FUNDS_SEGREGATION_BALANCES,
);

export function designatedBalanceDetails(state = initialState, action) {
  switch (action.type) {
    case fetchDesignatedBalanceDetails.SUCCESS: {
      if (action.payload.rollup) {
        return {
          ...state,
          rolledUpById: {
            ...state.rolledUpById.byId,
            ..._.get(action, ['payload', 'details'], []).reduce((obj, item) => {
              const details = _.sortBy(item.details, ['fd', 'assetType']);
              obj[item.accountId] = details;
              return obj;
            }, {}),
          },
        };
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          // very specific reduce for how this payload is structured.
          ..._.get(action, ['payload', 'details'], []).reduce((obj, item) => {
            const details = _.sortBy(item.details, ['fd', 'assetType']);
            obj[item.accountId] = details;
            return obj;
          }, {}),
        },
      };
    }
    default:
      return state;
  }
}

export const balanceDetails = handleActions(
  {
    [fetchBalances.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload?.accountId]: _.sortBy(_.get(payload, 'balances', []), [
          'fd',
          'assetType',
        ]),
      },
      rolledUpById: {
        ...state.rolledUpById,
        [payload?.accountId]: _.sortBy(_.get(payload, 'balances', []), [
          'fd',
          'assetType',
        ]),
      },
    }),
  },
  initialState,
);

export function totalBalances(state = initialState, action) {
  switch (action.type) {
    case fetchMultiAccountBalances.SUCCESS: {
      return {
        byId: {
          ...state.byId,
          ...arrayToObject(action.payload, 'accountId'),
        },
      };
    }
    default:
      return state;
  }
}

export const addTotalsAndContractsToPositions = (contracts) =>
  contracts.map((contract) => {
    const positionsWithContract = contract.positions.map((position) => ({
      ...position,
      contractSymbol: contract.contractSymbol,
      productSymbol: contract.productSymbol,
      contractCode: contract.contractCode,
      expirationTime: contract.expirationTime,
    }));
    const totalLong = _.sumBy(contract.positions, (pos) => {
      if (pos.qty > 0) {
        return Math.abs(pos.qty);
      }
      return 0;
    });

    const totalShort = _.sumBy(contract.positions, (pos) => {
      if (pos.qty < 0) {
        return Math.abs(pos.qty);
      }
      return 0;
    });

    return {
      ...contract,
      positions: positionsWithContract,
      totalLong,
      totalShort,
    };
  });

export const accountPositions = handleActions(
  {
    [clearPositions.TRIGGER]: () => ({ byId: {} }),
    [positions.SUCCESS]: (state, { payload }) => ({
      byId: {
        ...state.byId,
        // spread old positions with new ones by account ID, using lodash uniqBy,
        //  making sure each position is not deep equal to each other.
        [payload.accountId]: _.uniqBy(
          [
            ...addTotalsAndContractsToPositions(
              _.get(payload, 'positions', []),
            ),
            ..._.get(state, ['byId', payload.accountId], []),
          ],
          'contractSymbol',
        ),
      },
    }),
    [createCollateralWithdrawalJournal.SUCCESS]: (state, { payload }) => ({
      byId: {
        [payload.accountId]: _.get(state, ['byId', payload.accountId], []).map(
          (positionsBySymbol) => ({
            contractSymbol: positionsBySymbol.contractSymbol,
            positions: positionsBySymbol.positions.filter(
              ({ positionId }) => payload.positions.indexOf(positionId) === -1,
            ),
          }),
        ),
      },
    }),
  },
  { byId: {} },
);

export const accountPositionSummary = handleActions(
  {
    [positionSummary.SUCCESS]: (state, { payload }) => payload,
  },
  {},
);

export const positionsTransferTypes = handleActions(
  {
    [positionTransferTypes.SUCCESS]: (state, { payload }) => payload,
  },
  [],
);

export const positionsTransfers = handleActions(
  {
    [clearPositionTransfers.TRIGGER]: () => ({ count: 0, positions: [] }),
    [positionTransfers.SUCCESS]: (state, { payload }) => payload,
  },
  [],
);

export const aggregatedPositions = handleActions(
  {
    [positionsAggregated.SUCCESS]: (state = { byId: {} }, { payload }) => ({
      byId: {
        ...state.byId,
        ..._.groupBy(payload.positions, 'accountId'),
      },
    }),
  },
  {},
);

export const fundsSegregationBalancesReducer = handleActions(
  {
    [fundsSegregationBalances.SUCCESS]: (state, { payload }) => payload,
  },
  {},
);

export default combineReducers({
  balanceDetails,
  totalBalances,
  accountPositions,
  accountPositionSummary,
  positionsTransferTypes,
  positionsTransfers,
  aggregatedPositions,
  fundsSegregationBalances: fundsSegregationBalancesReducer,
  movements: combineReducers({
    byId: createById(fetchMovements, 'movements', 'uuid'),
    count: createCount(fetchMovements),
    pages: createPages(fetchMovements, 'movements', 'uuid'),
    currentPage: createCurrentPage(fetchMovements),
  }),
  tradeJournals: combineReducers({
    byId: createById(fetchTradeJournals, 'tradeJournals', 'tradeId'),
    count: createCount(fetchTradeJournals),
    pages: createPages(fetchTradeJournals, 'tradeJournals', 'tradeId'),
    currentPage: createCurrentPage(fetchTradeJournals),
  }),
});

/* Selectors */
export const getBalanceById =
  ({ id, accountLabel }) =>
  (state) =>
    _.get(state, ['balances', 'balanceDetails', 'byId', id], []).map((d) => ({
      ...d,
      id,
      accountLabel,
    }));
export const getActiveBalanceDetails = (state) =>
  _.get(
    state,
    [
      'balances',
      'balanceDetails',
      'byId',
      getSelectedAccountIdBySlice('accounts')(state),
    ],
    [],
  );
export const getRolledupActiveBalanceDetails = (state) =>
  _.get(
    state,
    [
      'balances',
      'balanceDetails',
      'rolledUpById',
      getSelectedAccountIdBySlice('accounts')(state),
    ],
    [],
  );
export const getActiveDesignatedBalanceDetails = createSelector(
  [getActiveBalanceDetails],
  (allDetails = []) => allDetails,
);

export const getUniqueRollupAccountIds = (allDetails) =>
  _.uniq(_.flatten(allDetails.map((d) => d.rollupAccountIds.map((id) => id))));
export const getAllDesignatedBalanceDetails = createSelector(
  [getRolledupActiveBalanceDetails, (state) => state, getSelectedAccount],
  (allDetails = [], state, selectedAccount) => {
    const detailsWithSubrows = allDetails.map((d) => ({
      ...d,
      _subRows: _.flatten(
        _.get(selectedAccount, 'descendants', []).map((des) => ({
          id: des.accountId,
          accountLabel: des.label,
          fd: d.fd,
          assetType: d.assetType,
          ..._.find(
            getBalanceById({ id: des.accountId, accountLabel: des.label })(
              state,
            ),
            {
              assetType: d.assetType,
              fd: d.fd,
            },
          ),
          ...(des.descendants && {
            _subRows: des.descendants.map((des2) => ({
              id: des2.accountId,
              accountLabel: des2.label,
              fd: d.fd,
              assetType: d.assetType,
              ..._.find(
                getBalanceById({
                  id: des2.accountId,
                  accountLabel: des2.label,
                })(state),
                { assetType: d.assetType, fd: d.fd },
              ),
            })),
          }),
        })),
      ),
    }));

    return detailsWithSubrows;
  },
);

export const getTotalUSDValues = (state) =>
  _.get(state, ['balances', 'totalBalances', 'byId'], []);
export const getAllPositions = (state) =>
  _.get(state, ['balances', 'accountPositions', 'byId'], {});
export const getPositions = createSelector(
  [getAllPositions, getSelectedAccountIdBySlice('accounts')],
  (allPositions, selectedAccountId) =>
    _.get(allPositions, selectedAccountId, []),
);
export const getAggregatedPositions = (state) =>
  _.get(
    state,
    [
      'balances',
      'aggregatedPositions',
      'byId',
      getSelectedAccountIdBySlice('accounts')(state),
    ],
    [],
  );

// FIXME: this works but isn't a great way to do this.
export const getPositionsByContraAccountId = createSelector(
  [getPositions],
  (allPositions) => {
    const output = [];

    allPositions.forEach((cur) => {
      Object.entries(_.groupBy(cur.positions, 'contraAccountId')).forEach(
        (obj) => {
          output.push({
            contraAccountId: obj[0],
            contraAccountLabel: _.get(obj[1][0], 'contraAccountLabel'),
            positions: obj[1],
            ..._.omit(cur, 'positions'),
          });
        },
      );
    });
    return output;
  },
);

export const positionsListDataRecursive = ({
  productCode,
  contractCode,
  maturityDate,
  accountLabel,
  cgmNumber,
  summary,
  accountView,
  cgmView,
}) => {
  const {
    startOfDayGross,
    startOfDayNet,
    topDay,
    final,
    variationMargin,
    assetType,
  } = summary;
  return {
    productCode,
    contractCode,
    maturityDate,
    account: accountLabel,
    cgm: cgmNumber,
    gLong: startOfDayGross?.long,
    gShort: startOfDayGross?.short,
    gNet: startOfDayGross?.long - startOfDayGross?.short,
    nLong: startOfDayNet?.long,
    nShort: startOfDayNet?.short,
    nNet: startOfDayNet?.long - startOfDayNet?.short,
    tLong: topDay?.long,
    tShort: topDay?.short,
    tNet: topDay?.long - topDay?.short,
    fLong: final?.long,
    fShort: final?.short,
    fNet: final?.long - final?.short,
    variationMargin,
    assetType,
    subRows: (cgmView || accountView)?.map((x) =>
      positionsListDataRecursive(x),
    ),
  };
};

export const positionsListDataTransforming = (contractViewPositionsList) => {
  const positionsListTransformed = contractViewPositionsList?.map((x) =>
    positionsListDataRecursive(x),
  );
  return positionsListTransformed || [];
};

export const getPositionsSummary = (state) => {
  const data = _.get(state, ['balances', 'accountPositionSummary'], {});
  return positionsListDataTransforming(data.contractView);
};

// filter out contracts that have no positions
export const getValidPositions = createSelector(
  [getPositions],
  (allPositions) =>
    allPositions.filter((contract) => contract.positions.length),
);

// get object containing only valid positions by account ID
export const getAllValidPositions = createSelector(
  [getAllPositions],
  (allPositions) =>
    _.chain(allPositions)
      .forIn((positionsByAccountId, key) =>
        Object.assign(allPositions, {
          [key]: positionsByAccountId.filter(
            ({ positions }) => positions.length,
          ),
        }),
      )
      .pickBy((byId) => byId.length)
      .value(),
);

export const getValidPositionsWithContracts = createSelector(
  [getValidPositions, getFuturesContracts],
  (allPositions, futuresContracts) => {
    allPositions.forEach((position) => {
      const { contractCode, productSymbol } =
        _.find(
          futuresContracts,
          (contract) => position.contractSymbol === contract.symbol,
        ) || {};
      Object.assign(position, {
        contractCode,
        productCode: productSymbol,
      });
    });

    return allPositions;
  },
);

export const getFundsSegregationBalances = (state) =>
  _.get(state, ['balances', 'fundsSegregationBalances'], {});

const getBalances = (state) => _.get(state, ['balances'], {});

export const getPositionsTransferTypes = createSelector(
  [getBalances],
  (balances) =>
    _.get(
      balances,
      ['positionsTransferTypes', 'positionTransferTypes'],
      [],
    ).filter(
      // internal_position_transfer should not be visible to the GUI
      (t) => t.type !== 'internal_position_transfer',
    ),
);

export const getPositionsTransfers = createSelector([getBalances], (balances) =>
  _.get(balances, ['positionsTransfers'], []),
);
