import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createLoadingSelector,
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  getItemsCount,
  showModal,
  hideModal,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import riskAlertDetailMetadata from '../../metadata/riskAlertsMetadata';
import { fetchRiskAlerts, RISK_ALERTS } from '../../reducers/riskReducer';
import history from '../../constants/history';
import XTable7 from '../../common/table/XTable7';
import { get, isArray } from 'lodash';
import AgGridWrapper from 'common/table/agGrid/AgGridWrapper';
import RiskAlertsGrid, {
  RISK_ALERTS_GRID_NAME,
} from 'components/agGrid/RiskAlerts/RiskAlertsGrid';

const slice = ['risk', 'riskAlert'];

const mapStateToProps = (state = {}) => ({
  riskLoading: createLoadingSelector([RISK_ALERTS])(state),
  count: getItemsCount(slice)(state),
  risk: get(state, ['risk', 'riskAlert', 'riskAlerts']),
});

const mapDispatchToProps = {
  fetchRiskAlerts,
  showModal,
  hideModal,
};

const getFilterOp = (columns, id) => {
  const column = columns.find((c) => c.id === id);
  return column.filterOp || DEFAULT_FILTER_OP;
};

const SECONDS_TO_NANO = '000000000';

const RiskAlertsContainer = (props) => {
  const { risk, membersLoading } = props;
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, filters, sortBy, columns }) => {
      let filter = [];
      let sort = [];
      if (filters.length !== 0) {
        filter = filteredArrayToAttrValue(
          filters.map((each) => ({
            ...each,
            op: getFilterOp(columns, each.id),
          })),
        );
      }
      const filterWithMaxMin = filter.reduce((acc, curr) => {
        if (isArray(curr.value)) {
          return acc.concat([
            ...(curr.value[0]
              ? [
                  {
                    ...curr,
                    op: 'gte',
                    value: `${curr.value[0]}${SECONDS_TO_NANO}`,
                  },
                ]
              : []),
            ...(curr.value[1]
              ? [
                  {
                    ...curr,
                    op: 'lte',
                    value: `${curr.value[1]}${SECONDS_TO_NANO}`,
                  },
                ]
              : []),
          ]);
        }
        return acc.concat([curr]);
      }, []);
      if (sortBy.length !== 0) {
        sort = sortedArrayToAttrValue(sortBy);
      }
      props.fetchRiskAlerts({
        limit: pageSize,
        page: pageIndex,
        offset: pageSize * pageIndex,
        filter: filterWithMaxMin,
        sort,
        with_latest_comment: true,
        with_latest_status: true,
      });
    },
    [],
  );

  const clearSelectedMember = () => {
    props.selectMember('');
  };

  const selectMember = ({ memberId }) => {
    props.selectMember(memberId);
    history.push(`/members/${memberId}/details`);
  };

  return (
    <Fragment>
      <Header as="h1" dividing>
        Risk Alerts
      </Header>
      {
        <AgGridWrapper
          gridName={RISK_ALERTS_GRID_NAME}
          agGridComponent={<RiskAlertsGrid />}
          reactTableComponent={
            <XTable7
              metadata={riskAlertDetailMetadata}
              data={risk || []}
              fetchData={fetchData}
              loading={membersLoading}
              selectMember={selectMember}
              clearSelectedMember={clearSelectedMember}
              count={props.count}
              height="68vh"
            />
          }
        />
      }
    </Fragment>
  );
};

RiskAlertsContainer.propTypes = {
  count: PropTypes.number,
  fetchRiskAlerts: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.any),
  membersLoading: PropTypes.bool,
  selectMember: PropTypes.func.isRequired,
};

RiskAlertsContainer.defaultProps = {
  count: 0,
  members: [],
  membersLoading: false,
  selectMember: () => {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RiskAlertsContainer);
