import React, { useCallback, useRef } from 'react';
import { GridReadyEvent, IServerSideDatasource } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { commonPaginationOptions } from 'utils/agGrid/options';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import AccountHistory from 'ts/models/AccountHistory.model';
import { AccountHistoryResponse } from 'ts/api/account_history';
import { ServerSideQuery } from 'utils/agGrid/types';
import {
  getSelectedAccountId,
  fetchRequestHistoryPromise,
} from 'reducers/accountHistoryReducer';
import { useAppSelector } from 'hooks/useAppSelector';

interface AccountHistoryGridProps {
  isJest?: boolean;
}

type CustomParams = {
  accountIds?: string;
  state?: string[];
};

export const ACCOUNT_HISTORY_GRID_NAME = 'account-history';

const getAssetType = (
  data: ServerSideQuery,
): { asset_type: string } | undefined => {
  const assetTypeFilter = data?.filter?.find(
    (item) => item.attr === 'assetType',
  );

  if (assetTypeFilter && typeof assetTypeFilter.value === 'string') {
    return { asset_type: assetTypeFilter.value };
  }
};

const AccountHistoryGrid: React.FC<AccountHistoryGridProps> = ({ isJest }) => {
  const gridRef = useRef<AgGridReactType>(null);
  const dispatch = useAppDispatch();
  const accountId: string = useAppSelector(getSelectedAccountId);
  const colDefs = columnDefs;

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: ACCOUNT_HISTORY_GRID_NAME,
  });

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success }) => {
          const body = parseAGRequest(request);
          const assetType = getAssetType(body);
          //This BE endpoint doesn't support the next attributes: filter, page, sort.
          const reqBody: ServerSideQuery | CustomParams = {
            limit: body.limit,
            state: ['pending', 'rejected'],
            accountIds: accountId,
            offset: body.offset,
            ...(assetType && { asset_type: assetType.asset_type }),
          };
          fetchRequestHistoryPromise(reqBody, dispatch).then(
            (response: AccountHistoryResponse) => {
              success({
                rowData: response?.history,
              });
            },
          );
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [accountId, dispatch],
  );

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact<AccountHistory>
          ref={gridRef}
          columnDefs={colDefs}
          gridOptions={commonPaginationOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
          rowModelType="serverSide"
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
        />
      )}
    </div>
  );
};

export default AccountHistoryGrid;
