import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridOptions } from 'ag-grid-community';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import { Button } from 'semantic-ui-react';
import { useAgGridCommon } from '../../../../common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import FuturesPositions from '../../../../ts/models/FuturesPositions.model';
import { FuturesPositionsResponse } from '../../../../ts/api/futures_positions';
import { ServerSideQuery } from '../../../../utils/agGrid/types';
import { getSelectedAccountId } from '../../../../reducers/accountHistoryReducer';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import {
  fetchMarginDeliveryPromise,
  dataTransformFuturesPositionMarginDeliveryAgGrid,
} from '../../../../reducers/riskReducer';
import styled from 'styled-components';

interface FuturesPositionsGridProps {
  isJest?: boolean;
}

const StyledAgGridContainer = styled.div`
  min-height: 350px;
  .ag-root-wrapper {
    height: 100% !important;
  }
`;

const defaultGridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  defaultColDef: {
    resizable: true,
    sortable: false,
    floatingFilter: false,
  },
};

export const FUTURES_POSITIONS_GRID_NAME = 'futures-positions';

const FuturesPositionsGrid: React.FC<FuturesPositionsGridProps> = ({
  isJest,
}) => {
  const gridRef = useRef<AgGridReactType>(null);
  const [rowData, setRowData] = useState<FuturesPositions[]>();
  const dispatch = useAppDispatch();
  const accountId: string = useAppSelector(getSelectedAccountId);
  const colDefs = columnDefs;
  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: FUTURES_POSITIONS_GRID_NAME,
  });

  const onGridReady = useCallback(() => {
    const reqBody: ServerSideQuery = {
      filter: [{ attr: 'account_id', op: 'eq', value: accountId }],
    };
    fetchMarginDeliveryPromise(reqBody, dispatch).then(
      (response: FuturesPositionsResponse) => {
        const data = dataTransformFuturesPositionMarginDeliveryAgGrid(
          response?.contractView,
        );
        setRowData(data);
      },
    );
  }, [accountId, dispatch]);

  const groupDisplayType = 'multipleColumns';

  const autoGroupColumnDef = useMemo(() => {
    return {
      cellRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  const onBtnExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv({
      fileName: 'Futures Positions',
    });
  }, []);

  return (
    <StyledAgGridContainer className="ag-theme-quartz">
      {gridVisible && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0',
            }}
          >
            <Button
              icon="download"
              content="Export to CSV"
              onClick={onBtnExport}
            />
          </div>
          <AgGridReact<FuturesPositions>
            suppressExcelExport={true}
            ref={gridRef}
            columnDefs={colDefs}
            gridOptions={defaultGridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowData={rowData}
            onGridReady={onGridReady}
            groupDisplayType={groupDisplayType}
            autoGroupColumnDef={autoGroupColumnDef}
            groupAllowUnbalanced
          />
        </>
      )}
    </StyledAgGridContainer>
  );
};

export default FuturesPositionsGrid;
