import {
  ColumnAdvancedFilterModel,
  IDoesFilterPassParams,
} from 'ag-grid-community';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

export const getSelectFilterTestId = (colId?: string) =>
  `ag-grid-select-filter${colId ? '-' + colId : ''}`;

export type SelectFilterProps = CustomFilterProps & {
  options: { key: string; value: string; text: string }[];
  placeholder?: string;
};

const SelectFilter = ({
  model,
  onModelChange,
  getValue,
  colDef,
  options = [],
  placeholder = 'Select',
  api,
}: SelectFilterProps) => {
  const doesFilterPass = useCallback(
    ({ node }: IDoesFilterPassParams) => {
      // TODO: Support more filtering criteria
      return getValue(node) === model;
    },
    [getValue, model],
  );
  useGridFilter({ doesFilterPass });

  const dataTestId = useMemo(
    () => getSelectFilterTestId(colDef?.field),
    [colDef],
  );

  return (
    <div className="ag-simple-filter-body-wrapper allow-overflow">
      <Dropdown
        placeholder={placeholder}
        data-testid={dataTestId}
        fluid
        search
        selection
        options={options}
        clearable
        onChange={(_event: SyntheticEvent, { value }: DropdownProps) => {
          const colId = colDef?.field;
          const rowModelType = api.getGridOption('rowModelType');
          if (colId && rowModelType === 'serverSide') {
            // TODO: Support other filter types
            const filterModel: ColumnAdvancedFilterModel = {
              filterType: 'text',
              colId,
              type: 'equals',
              filter: String(value),
            };
            onModelChange(value === '' ? null : filterModel);
          } else {
            onModelChange(value === '' ? null : value);
          }
        }}
      />
    </div>
  );
};

export default SelectFilter;
