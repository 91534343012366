export const DEFAULT_EXCHANGE = 'XCBD';

/**
 * @description Receives the internalSymbol of a contract and returns an object
 *              with its values parsed
 * @param input - string corresponding to internalSymbol of a contract
 * @param defaultExchange - exchangeMicCode to use if none is defined in input
 */
const parseSymbol = (
  input: string,
  defaultExchange = DEFAULT_EXCHANGE,
): { exchangeMicCode: string; contractSymbol: string } => {
  if (!input || typeof input !== 'string') {
    return { exchangeMicCode: '', contractSymbol: '' };
  }
  const dashIndex = input.indexOf('-');

  if (dashIndex === -1) {
    return { exchangeMicCode: defaultExchange, contractSymbol: input };
  } else {
    return {
      exchangeMicCode: input.substring(0, dashIndex),
      contractSymbol: input.substring(dashIndex + 1),
    };
  }
};

export default parseSymbol;
