import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import { showModal } from 'erisxkit/client';
import {
  CellClickedEvent,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import User from 'ts/models/User.model';
import getColumnDefs, { ACTION_COLUMNS, MemberUserGridRow } from './columnDefs';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { commonPaginationOptions, mergeGridOpts } from 'utils/agGrid/options';
import {
  fetchMemberUserTypes,
  fetchUsersPromiseCreator,
  getMemoizedMemberUserTypes,
  getMemoizedUserPermissions,
  selectUser,
  updateUserPromise,
} from 'reducers/usersReducer';
import { useAppSelector } from 'hooks/useAppSelector';
import { ServerSideQuery } from 'utils/agGrid/types';
import { getApiCredentialPermissions } from 'reducers/apiCredentialsReducer';
import history from 'constants/history';
import * as userTypes from 'constants/userTypes';
import { CREATE_MEMBER } from 'constants/modalTypes';

type FetchUsersRequest = ServerSideQuery & {
  includeName: 'true' | 'false';
};
export const MEMBER_USERS_GRID_NAME = 'memberUsers';

const gridOptions = mergeGridOpts(commonPaginationOptions, {
  rowStyle: { cursor: 'pointer' },
  blockLoadDebounceMillis: 700,
});

type MemberUserGridContext = {
  memberUserTypes: { value: string; text: string }[];
  userIds?: string[];
};
interface MemberUserGridProps {
  isJest?: boolean;
  userIds?: string[];
}
const MemberUserGrid = ({ isJest, userIds }: MemberUserGridProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const apiCredentialsPermissions = useAppSelector(getApiCredentialPermissions);
  const memberUserTypes = useAppSelector(getMemoizedMemberUserTypes);
  const userPermissions = useAppSelector(getMemoizedUserPermissions);

  const memoizedContext: MemberUserGridContext = useMemo(() => {
    return {
      memberUserTypes,
      userIds,
    };
  }, [memberUserTypes, userIds]);

  useEffect(() => {
    dispatch(fetchMemberUserTypes());
  }, [dispatch]);

  const toggleDisable = useCallback(
    (data: User, api: GridApi<User>) => {
      updateUserPromise(
        {
          userId: data.userId,
          update: { disabled: !data.disabled },
        },
        dispatch,
      ).then(() => {
        api.refreshServerSide({ purge: true });
      });
    },
    [dispatch],
  );
  const cloneMemberUser = useCallback(
    (data: User) => {
      dispatch(
        showModal(CREATE_MEMBER, {
          userPermissions,
          apiCredentialsPermissions,
          type: userTypes.memberUser,
          memberUserTypes,
          cloneMember: data,
        }),
      );
    },
    [apiCredentialsPermissions, dispatch, memberUserTypes, userPermissions],
  );

  const columnDefs = useMemo(
    () => getColumnDefs({ toggleDisable, cloneMemberUser }),
    [toggleDisable, cloneMemberUser],
  );

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } =
    useAgGridCommon({
      isJest,
      gridName: MEMBER_USERS_GRID_NAME,
      gridRef,
    });

  const memoizedDataSourceContext: IServerSideDatasource = useMemo(
    () => ({
      getRows: ({
        request,
        success,
        context,
      }: IServerSideGetRowsParams<
        MemberUserGridRow,
        MemberUserGridContext
      >) => {
        const { memberUserTypes, userIds } = context;
        if (isEmpty(memberUserTypes)) {
          return;
        }
        const parsed = parseAGRequest(request, { transformAttrs: true });
        const filter = parsed?.filter ? [...parsed.filter] : [];
        filter.push({
          attr: 'type',
          op: 'eq',
          value: memberUserTypes.map((el: { value: string }) => el.value),
        });
        if (userIds) {
          filter.push({
            attr: 'user_id',
            op: 'eq',
            value: userIds,
          });
        }
        const body: FetchUsersRequest = {
          ...parsed,
          filter,
          includeName: 'true',
        };
        fetchUsersPromiseCreator(body, dispatch).then((response) => {
          success({
            rowData: response?.users,
          });
        });
      },
    }),
    [dispatch],
  );

  const onCellClicked = useCallback(
    ({ data, colDef }: CellClickedEvent<MemberUserGridRow>) => {
      const colId = colDef.colId || '';
      if (ACTION_COLUMNS.includes(colId)) {
        return;
      }
      const userId = data?.userId;
      if (userId) {
        dispatch(selectUser(userId));
        history.push(`/member_users/${userId}`);
      }
    },
    [dispatch],
  );

  return (
    <div className="ag-theme-quartz">
      {gridVisible ? (
        <>
          {gridButtons}
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            onCellClicked={onCellClicked}
            serverSideDatasource={memoizedDataSourceContext}
            context={memoizedContext}
          />
        </>
      ) : null}
    </div>
  );
};

export default MemberUserGrid;
