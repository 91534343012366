import { useCallback } from 'react';
import { createLoadingSelector, hideModal, showModal } from 'erisxkit/client';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  DELETE_EXCHANGE,
  deleteExchangePromise,
  fetchExchanges,
  updateExchangePromise,
} from 'reducers/exchangeReducer';
import { GenericModalProps } from 'common/GenericModal';
import { RootState } from 'ts/types/store';
import { GENERIC_MODAL } from 'constants/modalTypes';
import { Exchange } from 'ts/models/Exchange.model';
import { EditableGridCallback } from 'utils/agGrid/hooks/useEditableGrid';
import { shallowObjectDiff } from 'utils/methods';

const useExchangeCallbacks = () => {
  const dispatch = useAppDispatch();

  const onDelete: EditableGridCallback<Exchange> = useCallback(
    ({ node }) => {
      const exchange = node.data;

      if (exchange) {
        const handleDelete = async () => {
          try {
            if (exchange) {
              await deleteExchangePromise(
                { urlParams: { micCode: exchange.micCode } },
                dispatch,
              );
              dispatch(hideModal());
              dispatch(fetchExchanges());
            }
          } catch (error) {
            dispatch(hideModal());
          }
        };
        dispatch(
          showModal(GENERIC_MODAL, {
            header: 'Delete Exchange',
            content: `This will remove exchange: ${exchange?.name || '-'}. Do you want to proceed?`,
            loadingSelector: (state: RootState) =>
              createLoadingSelector([DELETE_EXCHANGE])(state),
            cancelProps: {
              text: 'Cancel',
              onClick: () => dispatch(hideModal()),
            },
            confirmProps: {
              text: 'Delete',
              onClick: handleDelete,
              danger: true,
            },
          } as GenericModalProps),
        );
      }
    },
    [dispatch],
  );

  const onUpdate: EditableGridCallback<Exchange> = useCallback(
    ({ node, original }) => {
      const exchange = node?.data;
      const diff = shallowObjectDiff(exchange, original);
      if (diff) {
        updateExchangePromise(
          { update: diff, urlParams: { micCode: original.micCode } },
          dispatch,
        ).then(() => {
          dispatch(fetchExchanges());
        });
      }
    },
    [dispatch],
  );
  return { onDelete, onUpdate };
};

export default useExchangeCallbacks;
