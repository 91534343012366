// customer accounts
export const OWNERSHIP = 'ownership';
// wallet accounts
export const WALLET = 'wallet';
// BankAccount accounts
export const BANK = 'bank';
// CustodianAccounts, includes :wallet & bank
export const CUSTODIAN = 'custodian';
// includes :fee only (for now)
export const HOUSE = 'house';
// includes custodian and fee accounts (for now) <-- DEPRECATED, don't use.
export const FINANCE = 'finance';
// includes every account
export const ALL = 'all';
// broker account
export const BROKER = 'broker';
// fee account
export const FEE = 'fee';
// dco account
export const DCO = 'dco';
// buffer account
export const BUFFER = 'buffer';
// clearing accounts
export const CLEARING = 'clearing';

// Ancestors/Descendants labels
export const MASTER = 'master';
export const PARENT = 'parent';

export const accountTypeOptions = [BANK, FEE, WALLET, DCO, BUFFER, BROKER];

export const POSITION_LEDGER_TYPE = 'position'
