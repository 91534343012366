import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CellClickedEvent,
  GridReadyEvent,
  IServerSideDatasource,
} from 'ag-grid-community';
import isEmpty from 'lodash/isEmpty';
import { AgGridReact } from 'ag-grid-react';
import { showModal } from 'erisxkit/client';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { commonPaginationOptions, mergeGridOpts } from 'utils/agGrid/options';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { PendingApprovalReqs } from 'ts/models/PendingApprovalReqs.model';
import { PendingApprovalReqsResponse } from 'ts/api/pending_approval_reqs';
import { fetchApprovalReqsPromise } from 'actions/approvalReqsActions';
import { ServerSideQuery } from 'utils/agGrid/types';
import { selectApproval } from 'actions/approvalReqsActions';
import { APPROVE_REJECT } from 'constants/modalTypes';
import ExternalFilters from './ExternalFilters';
import useExternalFilter from 'utils/agGrid/hooks/useExternalFilter';

interface PendingApprovalReqsGridProps {
  isJest?: boolean;
}

export const PENDING_APPROVAL_REQS_GRID_NAME = 'pending-approval_reqs';

const PendingApprovalReqsGrid: React.FC<PendingApprovalReqsGridProps> = ({
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: PENDING_APPROVAL_REQS_GRID_NAME,
  });
  const approve = useCallback(
    (approvalReq: PendingApprovalReqs) => {
      dispatch(selectApproval(approvalReq.uuid));
      dispatch(
        showModal(APPROVE_REJECT, {
          selectedApprovalReq: approvalReq,
          onSubmit: () => {
            //Refresh AgGrid
            gridRef.current!.api.refreshServerSide({
              route: undefined,
              purge: false,
            });
          },
        }),
      );
    },
    [dispatch],
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success, context }) => {
          const body = parseAGRequest(request, { transformAttrs: true });
          // Base filters from the grid
          const gridFilters = body?.filter || [];
          // Filters required for this screen
          const filters = gridFilters.concat([
            {
              attr: 'sub_type',
              op: 'ne',
              value: [
                'promotion',
                'AppliedMarginRates',
                'MarginCallRequirements',
              ],
            },
            { attr: 'state', op: 'eq', value: 'pending' },
            {
              attr: 'member_transfer_final_approval',
              op: 'eq',
              value: 'false',
            },
          ]);
          if (context?.dateFrom) {
            filters.push({
              attr: 'time',
              op: 'gte',
              value: context?.dateFrom,
            });
          }
          if (context?.dateTo) {
            filters.push({
              attr: 'time',
              op: 'lte',
              value: context?.dateTo,
            });
          }
          const reqBody: ServerSideQuery = {
            ...body,
            filter: filters,
          };
          fetchApprovalReqsPromise(reqBody, dispatch).then(
            (response: PendingApprovalReqsResponse) => {
              success({
                rowData: response?.approvalReqs,
              });
            },
          );
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  const [externalFilters, setExternalFilters] = useState({});
  const context = useMemo(() => externalFilters, [externalFilters]);

  const onDateChange = useCallback(
    (value: Record<string, string>) => {
      setExternalFilters({ ...externalFilters, ...value });
    },
    [externalFilters],
  );
  const clearDateFilters = useCallback(() => {
    setExternalFilters({});
  }, []);

  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshServerSide({ purge: true });
    }
  }, [externalFilters]);

  const onCellClicked = useCallback(
    (event: CellClickedEvent<PendingApprovalReqs>) => {
      const { data, colDef } = event;
      if (data) {
        const columnField = colDef.field;
        if (columnField !== 'initiatorId') {
          approve(data);
        }
      }
    },
    [approve],
  );

  const options = useMemo(
    () => mergeGridOpts(commonPaginationOptions, { onGridReady }),
    [onGridReady],
  );

  const {
    setFilterFromEvent,
    setExternalFilter,
    gridOptions,
    clearFilters,
    getFilterValue,
    hasFiltersApplied,
  } = useExternalFilter({
    gridRef,
    options,
  });

  const onClear = useCallback(() => {
    clearFilters();
    clearDateFilters();
  }, [clearDateFilters, clearFilters]);

  const clearDisabled = useMemo(() => {
    const hasExternalFilters = !isEmpty(externalFilters);
    return !hasFiltersApplied && !hasExternalFilters;
  }, [externalFilters, hasFiltersApplied]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <>
          <ExternalFilters
            onChange={onDateChange}
            onClear={onClear}
            context={context}
            setFilterFromEvent={setFilterFromEvent}
            setExternalFilter={setExternalFilter}
            getFilterValue={getFilterValue}
            clearDisabled={clearDisabled}
          />
          <AgGridReact<PendingApprovalReqs>
            ref={gridRef}
            columnDefs={columnDefs}
            context={context}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            groupDisplayType={'groupRows'}
            onCellClicked={onCellClicked}
          />
        </>
      )}
    </div>
  );
};

export default PendingApprovalReqsGrid;
