import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import { common } from 'utils/agGrid/options';
interface ReconciliationGridProps {
  data: ReconciliationDataType[];
  colDefs: ColDef[];
  isJest?: boolean;
  viewReconciliations: (id: string) => void;
}

export interface ReconciliationDataType {
  firmCode: string;
  accountCode: string;
  accountId: string;
  accountName: string;
  subExchangeLabel: string;
  provider: string;
  providerAccountId: string;
  fundsDesignation: string;
  assetType: string;
  amount: string;
  balances: object;
  categories: string[];
  name: string;
  type: string;
}

export const gridName = 'reconciliation';

const ReconciliationGrid: React.FC<ReconciliationGridProps> = ({
  data,
  colDefs,
  isJest,
  viewReconciliations,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<ReconciliationDataType[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data]);

  const onRowClicked = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    //We can select just 1 row at time in this table.
    viewReconciliations(selectedRows[0]?.accountId || '');
  }, []);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={common}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
          rowSelection={{
            mode: 'singleRow',
          }}
          onRowClicked={onRowClicked}
        />
      )}
    </div>
  );
};

export default ReconciliationGrid;
