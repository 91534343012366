import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef } from 'react';
import { GridReadyEvent, IServerSideDatasource } from 'ag-grid-community';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import { commonPaginationOptions, mergeGridOpts } from 'utils/agGrid/options';
import { useAppDispatch } from 'hooks/useAppDispatch';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { fetchRiskPromise } from 'reducers/riskReducer';
import columnDefs from './columnDefs';

interface RiskEngineGridProps {
  isJest?: boolean;
}

const gridOptions = mergeGridOpts(commonPaginationOptions, {
  defaultColDef: { floatingFilter: false },
  maintainColumnOrder: true,
});

export const RISK_ENGINE_GRID_NAME = 'riskEngine';

const RiskEngineGrid = ({ isJest }: RiskEngineGridProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } =
    useAgGridCommon({
      isJest,
      gridName: RISK_ENGINE_GRID_NAME,
      gridRef,
    });

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success }) => {
          const body = parseAGRequest(request, { transformAttrs: true });
          fetchRiskPromise(body, dispatch).then((response) => {
            success({
              rowData: response?.riskAccountSummaries,
            });
          });
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  return (
    <div className="ag-theme-quartz">
      {gridVisible ? (
        <>
          {gridButtons}
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            onGridReady={onGridReady}
          />
        </>
      ) : null}
    </div>
  );
};

export default RiskEngineGrid;
