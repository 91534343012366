import React, { Fragment, PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import { Form, Modal, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { titleCase } from 'change-case';
import FuturesContractFields from '../../common/forms/FuturesContract';
import cycleOptions from '../../constants/cycle';

class CreateFuturesContract extends PureComponent {
  componentDidMount = () => {
    const {
      cycle,
      description,
      exchangeMicCode,
      productSymbol,
      clearingSymbol,
      internalSymbol,
      symbol,
      expirationTime,
      firstTradeDate,
      lastTradeDate,
      listingDate,
      delistingDate,
      initialize,
      pristine,
      type,
      upper,
      lower,
      spanId,
    } = this.props;

    // set initial form values tclearo given fields
    if (pristine) {
      const initialFields = {
        description,
        exchangeMicCode,
        productSymbol,
        type,
        cycle,
        clearingSymbol,
        internalSymbol,
        symbol,
        expirationTime,
        firstTradeDate,
        lastTradeDate,
        listingDate,
        delistingDate,
        spanId,
      };
      if (type === 'update') {
        initialFields.upper = upper;
        initialFields.lower = lower;
      }
      initialize({ ...initialFields }, { keepDirty: true });
    }
  };

  render = () => {
    const {
      confirmAction,
      handleSubmit,
      hideModal,
      pristine,
      productDelivery,
      type,
      valid,
    } = this.props;

    return (
      <Fragment>
        <Modal.Header>
          {titleCase(type)} Futures Contract{' '}
          <Button
            className="pull-right"
            circular
            size="mini"
            icon="times"
            onClick={() => hideModal()}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit(confirmAction)}>
            <FuturesContractFields
              cycleOptions={cycleOptions}
              productDelivery={productDelivery}
            />
            <Modal.Actions className="modal-form-actions">
              <Form.Button onClick={() => hideModal()}>Close</Form.Button>
              <Form.Button primary disabled={!valid || pristine}>
                {titleCase(type)} futures contract
              </Form.Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Fragment>
    );
  };
}

CreateFuturesContract.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  description: PropTypes.string,
  exchangeMicCode: PropTypes.string,
  internalSymbol: PropTypes.string,
  firstTradeDate: PropTypes.string,
  lastTradeDate: PropTypes.string,
  listingDate: PropTypes.string,
  delistingDate: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  cycle: PropTypes.string,
  productSymbol: PropTypes.string,
  clearingSymbol: PropTypes.string,
  expirationTime: PropTypes.string,
  initialize: PropTypes.func.isRequired,
  lower: PropTypes.string,
  pristine: PropTypes.bool,
  productDelivery: PropTypes.string,
  symbol: PropTypes.string,
  type: PropTypes.string,
  upper: PropTypes.string,
  valid: PropTypes.bool,
  spanId: PropTypes.string,
};

CreateFuturesContract.defaultProps = {
  description: '',
  valid: false,
  cycle: '',
  exchangeMicCode: '',
  internalSymbol: '',
  firstTradeDate: '',
  lastTradeDate: '',
  listingDate: '',
  delistingDate: '',
  productSymbol: '',
  clearingSymbol: '',
  expirationTime: '',
  lower: '',
  pristine: false,
  productDelivery: '',
  symbol: '',
  type: 'create',
  upper: '',
  spanId: '',
};

export default reduxForm({
  form: 'CreateFuturesContract',
})(CreateFuturesContract);
