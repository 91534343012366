import React, { useMemo } from 'react';
import { Button, Dropdown, Form, Input } from 'semantic-ui-react';
import { UseExternalFilterResult } from 'utils/agGrid/hooks/useExternalFilter';
import Datetime from 'react-datetime';
import { txTypeValues } from './columnDefs';

type Props = Pick<
  UseExternalFilterResult,
  | 'setExternalFilter'
  | 'getFilterValue'
  | 'setFilterFromEvent'
  | 'hasFiltersApplied'
> & {
  onDateChange: (value: Record<string, string>) => void;
  context: Record<string, string> | null;
  clearFilters: () => void;
};

const ExternalFilters = ({
  setExternalFilter,
  getFilterValue,
  setFilterFromEvent,
  hasFiltersApplied,
  onDateChange,
  context,
  clearFilters,
}: Props) => {
  const txTypes = useMemo(
    () =>
      txTypeValues.map((name) => ({
        key: name,
        value: name,
        text: name,
      })),
    [],
  );
  return (
    <Form>
      <Form.Group style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Form.Field
          label="UUID"
          control={Input}
          name="uuid"
          placeholder="UUID"
          value={getFilterValue('uuid')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'contains' });
          }}
        />
        <Form.Field
          control={Datetime}
          className="ui input datetime"
          label="Time"
          name="time"
          id="time"
          defaultValue=""
          dateFormat="YYYY-MM-DD"
          timeFormat
          value={context?.time}
          inputProps={{ placeholder: 'Time journal was rejected' }}
          onChange={(time) => {
            onDateChange({ time: time?.format() });
          }}
          closeOnSelect
        />
        <div className="field">
          <label>Transaction Type</label>
          <Dropdown
            value={getFilterValue('txType')}
            name="txType"
            placeholder={'Click to select Type'}
            onChange={(_e, { name: colId, value }) => {
              if (value) setExternalFilter({ colId, value, op: 'equals' });
            }}
            selection
            labeled
            options={txTypes}
          />
        </div>
        <Button
          color="red"
          disabled={!hasFiltersApplied && context?.time === undefined}
          id="clear-filters"
          content="Clear"
          onClick={clearFilters}
          style={{ height: '38px' }}
        />
      </Form.Group>
    </Form>
  );
};

export default ExternalFilters;
