type KeyGenerator<T> = keyof T | ((item: T) => string);

/**
 * Transforms an array of objects into an object, assigning each element of the array to
 * a specific key
 * @template T - The type of the objects in the array.
 * @param {T[]} array - The array of objects to transform.
 * @param {KeyGenerator<T>} keyGenerator - A key generator, which can be a string (key of T) or a callback function that takes an item and returns a string.
 * @returns {{ [key: string]: T }} - The transformed object.
 */
const transformArrayToObject = <T extends Record<string, unknown>>(
  array: T[],
  keyGenerator: KeyGenerator<T>,
): { [key: string]: T } => {
  if (!Array.isArray(array)) {
    return {};
  }
  return array.reduce((acc: { [key: string]: T }, item: T) => {
    let key;
    if (typeof keyGenerator === 'string') {
      key = item[keyGenerator] ? String(item[keyGenerator]) : '';
    } else if (typeof keyGenerator === 'function') {
      key = keyGenerator(item);
    } else {
      key = '';
    }
    if (key) {
      acc[key] = item;
    }
    return acc;
  }, {});
};

export default transformArrayToObject;
