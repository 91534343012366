import { formatUSD } from '../../../utils/methods';
import { formatTimeLocal } from '../../../utils/time';
import { MarginRequirements } from './MarginRequirementsGrid';

const columnDefs = [
  {
    headerName: 'Time',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatTimeLocal(data?.time),
  },
  {
    headerName: 'Trade Date',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatTimeLocal(data?.tradeDate),
  },
  {
    field: 'type',
    headerName: 'Type',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'firmCode',
    headerName: 'Firm',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'accountCode',
    headerName: 'Account',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Account Type',
    field: 'accountType',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Origin',
    field: 'origin',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Applied Requirement',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.marginRequirement),
  },
  {
    headerName: 'Comment',
    field: 'overrideComment',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Span Margin',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.spanMarginRequirement),
  },
  {
    headerName: 'Span Margin Override',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.spanMarginRequirementOverride),
  },
  {
    headerName: 'Guaranty Fund',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.guarantyFund),
  },
  {
    headerName: 'Guaranty Fund Override',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.guarantyFundOverride),
  },
  {
    headerName: 'Concentration Add On',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.concentrationAddOn),
  },
  {
    headerName: 'Concentration Add On Override',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.concentrationAddOnOverride),
  },
  {
    headerName: 'Stress Add On',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.stressAddOn),
  },
  {
    headerName: 'Stress Add On Override',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.stressAddOnOverride),
  },
  {
    headerName: 'Cover 2 Add On',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.cover_2AddOn),
  },
  {
    headerName: 'Cover 2 Add On Override',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.cover_2AddOnOverride),
  },
  {
    headerName: 'Discretionary Add On',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.discretionaryAddOn),
  },
  {
    headerName: 'Discretionary Add On Override',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: MarginRequirements }) =>
      formatUSD(data?.discretionaryAddOnOverride),
  },
];

export default columnDefs;
