import React, { Fragment } from 'react';
import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { formatTimeLocal, formatDateLocal } from '../../../../utils/time';
import AccountHistory from '../../../../ts/models/AccountHistory.model';
import Big from 'bignumber.js';
import { Icon, Label, SemanticCOLORS } from 'semantic-ui-react';

enum TransactionType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  Buy = 'buy',
  Sell = 'sell',
}

const iconColorForState = (
  state: string,
  type?: string,
): SemanticCOLORS | undefined => {
  const colorMap: { [key: string]: SemanticCOLORS } = {
    posted: 'green',
    pending: 'yellow',
    rejected: 'red',
    admin_rejected: 'red',
  };

  const color = colorMap[state];

  if (
    !color &&
    type &&
    (['green', 'yellow', 'red', 'blue'] as SemanticCOLORS[]).includes(
      type as SemanticCOLORS,
    )
  ) {
    return type as SemanticCOLORS;
  }

  return color || undefined;
};

const iconForType = (type?: string) => {
  switch (type) {
    case TransactionType.Deposit:
      return 'arrow down';
    case TransactionType.Withdrawal:
      return 'arrow up';
    case TransactionType.Buy:
    case TransactionType.Sell:
      return 'exchange';
    default:
      return undefined;
  }
};

const columnDefs: ColDef<AccountHistory>[] = [
  {
    headerName: 'Time',
    field: 'time',
    valueFormatter: ({ data }) => formatTimeLocal(get(data, 'time', '')),
    width: 225,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Report Date',
    field: 'reportDate',
    valueFormatter: ({ data }) =>
      formatDateLocal(get(data, ['postingSummary', '0', 'reportDate'])),
    width: 225,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Asset',
    field: 'assetType',
    valueFormatter: ({ data }) => get(data, 'product', ''),
    cellClass: 'mono',
    filter: 'agMultiColumnFilter',
    sortable: false,
  },
  {
    headerName: 'Type',
    field: 'type',
    cellRenderer: ({ data }: { data?: AccountHistory }) => (
      <Fragment>
        <Icon
          name={iconForType(data?.txType)}
          color={iconColorForState(data?.state || '', data?.txType)}
        />
        {data?.txType &&
          data?.txType?.charAt(0).toUpperCase() + data?.txType?.slice(1)}
      </Fragment>
    ),
    cellClass: 'mono',
    width: 300,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Quantity',
    field: 'quantity',
    width: 250,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Amount',
    field: 'amount',
    width: 250,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Price',
    field: 'price',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Notional',
    field: 'notional',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Clearing Fee',
    field: 'clearingFee',
    width: 200,
    valueFormatter: ({ data }) =>
      `${data?.clearingFee || 0} ${data?.feeType || ''}`,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Exchange Fee',
    field: 'exchangeFee',
    width: 200,
    valueFormatter: ({ data }) =>
      `${data?.exchangeFee || 0} ${data?.feeType || ''}`,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Fee',
    field: 'otherFees',
    width: 200,
    valueFormatter: ({ data }) =>
      `${new Big(data?.otherFees || 0).toFixed()} ${data?.feeType || ''}`,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Total',
    field: 'total',
    width: 200,
    valueFormatter: ({ data }) =>
      new Big(data?.amount || 0)
        .plus(new Big(data?.notional || 0))
        .plus(
          new Big(data?.exchangeFee || 0)
            .plus(new Big(data?.clearingFee || 0))
            .plus(new Big(data?.otherFees || 0)),
        )
        .toFixed(),
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 200,
    cellRenderer: ({ data }: { data?: AccountHistory }) => (
      <Label
        color={iconColorForState(data?.state || '', 'green')}
        content={data?.state}
      />
    ),
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
];

export default columnDefs;
