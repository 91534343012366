import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { columns } from 'erisxkit/client';
import { formatTimeLocal, formatDateLocal } from '../../../../utils/time';
import FutureTrade from '../../../../ts/models/FutureTrade.model';
import Big from 'bignumber.js';

const columnDefs: ColDef<FutureTrade>[] = [
  {
    headerName: 'Trade Date',
    field: 'date',
    valueFormatter: ({ data }: { data?: FutureTrade }) =>
      data?.reportDate
        ? formatDateLocal(get(data, 'reportDate', ''))
        : formatDateLocal(get(data, 'date', '')),
    width: 200,
  },
  {
    headerName: 'Exchange',
    field: 'exchangeMicCode',
  },
  {
    headerName: 'Product',
    field: 'productSymbol',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Contract',
    field: 'clearingSymbol',
  },
  {
    headerName: 'Member Name',
    field: 'memberName',
  },
  {
    headerName: 'Account',
    field: 'accountLabel',
    width: columns.ACCOUNT_NAME,
  },
  {
    headerName: 'Trade Type',
    valueFormatter: ({ data }: { data?: FutureTrade }) =>
      data?.tradeType || data?.trdType || '',
    field: 'tradeType',
  },
  {
    headerName: 'Quantity',
    field: 'qty',
  },
  {
    headerName: 'Price',
    field: 'px',
  },
  {
    headerName: 'Reserved Margin',
    valueFormatter: ({ data }: { data?: FutureTrade }) =>
      data?.initialMargin || '',
    field: 'initialMargin',
  },
  {
    headerName: 'Execution Time',
    field: 'execTime',
    width: 205,
    valueFormatter: ({ data }: { data?: FutureTrade }) =>
      formatTimeLocal(data?.time),
  },
  {
    headerName: 'Report Time',
    field: 'reportTime',
    width: 205,
    valueFormatter: ({ data }: { data?: FutureTrade }) =>
      formatTimeLocal(data?.reportTime),
  },
  {
    headerName: 'Aggressor',
    field: 'aggressor',
  },
  {
    headerName: 'Exchange Fee',
    field: 'exchange',
    valueFormatter: ({ data }: { data?: FutureTrade }) =>
      `${data?.exchangeFee || data?.exchangeFees || ''} ${data?.feeType || ''}`,
  },
  {
    headerName: 'Clearing Fee',
    field: 'clearingFee',
    valueFormatter: ({ data }: { data?: FutureTrade }) => {
      const feeClearing = data?.clearingFee || data?.clearingFees || 0;
      const feeClearingTotal = new Big(feeClearing)
        .plus(data?.deliveryFee || 0)
        .toFixed();
      return `${feeClearingTotal} ${data?.feeType || ''}`;
    },
  },
  {
    headerName: 'Spread ID',
    field: 'secondaryExecId',
  },
  {
    headerName: 'Submitter',
    field: 'submitter',
  },
  {
    headerName: 'Trade Id',
    field: 'tradeId',
  },
  {
    headerName: 'CAR',
    field: 'customerAccountRef',
  },
  {
    headerName: 'CTI',
    field: 'cti',
  },
  {
    headerName: 'Origin',
    field: 'origin',
  },
];

export default columnDefs;
