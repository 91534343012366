import { UserSelectionContainer } from 'common/containers';
import React from 'react';
import Datetime from 'react-datetime';
import { Button, Form, Input } from 'semantic-ui-react';
import {
  IGetFilterValue,
  ISetExternalFilter,
  ISetFilterFromEvent,
} from 'utils/agGrid/hooks/useExternalFilter';

type Props<T> = {
  onChange: (value: Record<string, string>) => void;
  onClear: () => void;
  context: Record<string, string> | null;
  getFilterValue: IGetFilterValue;
  setFilterFromEvent: ISetFilterFromEvent<T>;
  setExternalFilter: ISetExternalFilter<T>;
  clearDisabled: boolean;
};
const ExternalFilters = <T,>({
  onChange,
  onClear,
  context,
  setFilterFromEvent,
  getFilterValue,
  setExternalFilter,
  clearDisabled,
}: Props<T>) => {
  return (
    <Form>
      <Form.Group style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Form.Field
          control={Datetime}
          className="ui input datetime"
          label="Begin Date/Time"
          name="beginTime"
          id="beginTime"
          defaultValue=""
          dateFormat="YYYY-MM-DD"
          timeFormat
          value={context?.dateFrom}
          inputProps={{ placeholder: 'Filter Begin Time' }}
          onChange={(time) => {
            onChange({ dateFrom: time?.format() });
          }}
          closeOnSelect
        />
        <Form.Field
          control={Datetime}
          className="ui input datetime"
          label="End Date/Time"
          name="endTime"
          id="endTime"
          defaultValue=""
          dateFormat="YYYY-MM-DD"
          timeFormat
          value={context?.dateTo}
          inputProps={{ placeholder: 'Filter End Time' }}
          onChange={(time) => {
            onChange({ dateTo: time.format() });
          }}
          closeOnSelect
        />
        <Form.Field
          label="Description"
          control={Input}
          name="description"
          placeholder="Description"
          value={getFilterValue('description')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'contains' });
          }}
        />
        <Form.Field
          label="Initiator ID"
          control={UserSelectionContainer}
          name="initiatorId"
          placeholder="Initiator ID"
          input={{
            value: getFilterValue('initiatorId'),
            onChange: (value: string | undefined) => {
              setExternalFilter({
                colId: 'initiatorId',
                value: value || '',
                op: 'equals',
              });
            },
          }}
        />
        <Button
          color="red"
          disabled={clearDisabled}
          id="clear-filters"
          content="Clear"
          onClick={onClear}
          style={{ height: '38px' }}
        />
      </Form.Group>
    </Form>
  );
};

export default ExternalFilters;
