import { takeLatest } from 'redux-saga/effects';
import {
  EXCHANGE_MIC_CODE_PREFIX,
  EXCHANGE_PREFIX,
  createExchange,
  deleteExchange,
  fetchExchanges,
  updateExchange,
} from '../reducers/exchangeReducer';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';

export default function* watch() {
  yield takeLatest(
    fetchExchanges,
    generateSaga(fetchExchanges, {
      method: HTTPMethod.GET,
      prefix: EXCHANGE_PREFIX,
    }),
  );
  yield takeLatest(
    createExchange,
    generateSaga(createExchange, {
      method: HTTPMethod.PUT,
      loading: 'Adding new exchange...',
      success: 'Exchange added successfully',
      prefix: EXCHANGE_PREFIX,
    }),
  );
  yield takeLatest(
    deleteExchange,
    generateSaga(deleteExchange, {
      method: HTTPMethod.DELETE,
      loading: 'Removing exchange...',
      success: 'Exchange removed successfully',
      prefix: EXCHANGE_MIC_CODE_PREFIX,
    }),
  );
  yield takeLatest(
    updateExchange,
    generateSaga(updateExchange, {
      method: HTTPMethod.POST,
      loading: 'Updating exchange...',
      success: 'Exchange updated successfully',
      prefix: EXCHANGE_MIC_CODE_PREFIX,
    }),
  );
}
