import React, { useEffect, useRef, useState } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';

export interface BankAccount {
  accountNumber: string;
  assetType: string;
  bankName: string;
  createdAt: string;
  hashId: string;
  id: string;
  isPlaidAccount: boolean;
  isSenAccount: boolean;
  isSignetAccount: boolean;
  label: string;
  memberId: string;
  routingNumber: string;
  state: string;
  type: string;
  usableAt: string;
}

interface BankAccountGridProps {
  data: BankAccount[];
  colDefs: ColDef[];
  isJest?: boolean;
}

const defaultGridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  defaultColDef: {
    sortable: true,
    width: 200,
  },
};

export const gridName = 'bank-account';

const BankAccountGrid: React.FC<BankAccountGridProps> = ({
  data,
  colDefs,
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<BankAccount[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={defaultGridOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
        />
      )}
    </div>
  );
};

export default BankAccountGrid;
