import React, { useMemo } from 'react';
import { Button, Dropdown, Form, Input } from 'semantic-ui-react';
import { UseExternalFilterResult } from 'utils/agGrid/hooks/useExternalFilter';
import * as memberStatuses from 'constants/statuses';

type Props = Pick<
  UseExternalFilterResult,
  | 'setExternalFilter'
  | 'getFilterValue'
  | 'setFilterFromEvent'
  | 'hasFiltersApplied'
  | 'clearFilters'
>;

const ExternalFilters = ({
  setExternalFilter,
  getFilterValue,
  setFilterFromEvent,
  hasFiltersApplied,
  clearFilters,
}: Props) => {
  const statuses = useMemo(
    () =>
      Object.values(memberStatuses).map((s) => ({
        key: s.code,
        value: s.code,
        text: s.name,
      })),
    [],
  );
  return (
    <Form>
      <Form.Group style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Form.Field
          label="Firm Code"
          control={Input}
          name="firmCode"
          placeholder="Firm Code"
          value={getFilterValue('firmCode')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'contains' });
          }}
        />
        <Form.Field
          label="Name"
          control={Input}
          name="name"
          placeholder="Name"
          value={getFilterValue('name')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'contains' });
          }}
        />
        <Form.Field
          label="E-Market Organization Name"
          control={Input}
          name="emarketOrganizationName"
          placeholder="E-Market Organization Name"
          value={getFilterValue('emarketOrganizationName')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'contains' });
          }}
        />
        <Form.Field
          label="State"
          control={Input}
          name="locationState"
          placeholder="State"
          value={getFilterValue('locationState')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'match' });
          }}
        />
        <Form.Field
          label="Country"
          control={Input}
          name="country"
          placeholder="Country"
          value={getFilterValue('country')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'match' });
          }}
        />
        <Form.Field
          label="Member ID"
          control={Input}
          name="id"
          placeholder="Member ID"
          value={getFilterValue('id')}
          onChange={(e) => {
            setFilterFromEvent(e, { op: 'equals' });
          }}
        />
        <div className="field">
          <label>Status</label>
          <Dropdown
            value={getFilterValue('status')}
            name="status"
            placeholder={'Click to select Status'}
            onChange={(_e, { name: colId, value }) => {
              if (value) setExternalFilter({ colId, value, op: 'equals' });
            }}
            selection
            labeled
            options={statuses}
          />
        </div>
        <Button
          color="red"
          disabled={!hasFiltersApplied}
          id="clear-filters"
          content="Clear"
          onClick={clearFilters}
          style={{ height: '38px' }}
        />
      </Form.Group>
    </Form>
  );
};

export default ExternalFilters;
