import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { combineReducers } from 'redux';
import {
  createById,
  createCount,
  createPages,
  createCurrentPage,
} from 'erisxkit/client';
import { REJECTED_JOURNALS, CLOSEOUT_JOURNALS } from '../constants/actionTypes';

export const rejectedJournals = createRoutine(REJECTED_JOURNALS);
export const closeoutJournals = createRoutine(CLOSEOUT_JOURNALS);
export const rejectedJournalsPromise = promisifyRoutine(rejectedJournals);
const slice = 'journals';
const id = 'uuid';
const closeoutJournalsSlice = 'closeoutJournals';

export const rejected = combineReducers({
  byId: createById(rejectedJournals, slice, id),
  count: createCount(rejectedJournals),
  pages: createPages(rejectedJournals, slice, id),
  currentPage: createCurrentPage(rejectedJournals),
});

export const closeout = combineReducers({
  byId: createById(closeoutJournals, closeoutJournalsSlice, id),
  count: createCount(closeoutJournals),
  pages: createPages(closeoutJournals, closeoutJournalsSlice, id),
  currentPage: createCurrentPage(closeoutJournals),
});

export default combineReducers({ rejected, closeout });
