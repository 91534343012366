function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export var preserveArrayBrackets = function preserveArrayBrackets(fn) {
  return function (input, options) {
    return fn(input, _objectSpread({
      stripRegexp: /[^A-Z0-9[\]]+/gi
    }, options));
  };
};
export var preserveSpecificKeys = function preserveSpecificKeys(fn, keys) {
  var condition = typeof keys === "function" ? keys : function (input) {
    return keys.includes(input);
  };
  return function (input, options) {
    return condition(input, options) ? input : fn(input, options);
  };
};