import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { formatTimeLocalWithDoW } from '../../../../utils/time';
import { ReconciliationHistoryDataType } from './ReconciliationHistoryGrid';

const columnDefs: ColDef[] = [
  {
    field: 'endTime',
    headerName: 'Ending Time',
    filter: 'agMultiColumnFilter',
    valueFormatter: ({ data = '' }) => {
      const value = get(data, 'endTime', '');
      return formatTimeLocalWithDoW(value, true);
    },
    minWidth: 200,
  },
  {
    field: 'state',
    headerName: 'State',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }: { data?: ReconciliationHistoryDataType }) => {
      const state = get(data, 'state', '');
      if (state == '0') {
        return 'Pending';
      } else if (state == '10') {
        return 'Posting';
      }
      return 'Posted';
    },
  },
  {
    field: 'startTime',
    headerName: 'Starting Time',
    filter: 'agMultiColumnFilter',
    valueFormatter: ({ data = '' }) => {
      const value = get(data, 'startTime', '');
      return formatTimeLocalWithDoW(value, true);
    },
    minWidth: 200,
  },
  {
    field: 'endExternalBalance',
    headerName: 'Ending External Balance',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'endInternalBalance',
    filter: 'agMultiColumnFilter',
    headerName: 'Ending Internal Balance',
  },
];

export default columnDefs;
