import React, { Fragment } from 'react';
import { map } from 'lodash';
import { Button, Header, Icon, Segment, Table } from 'semantic-ui-react';
import { XTable, showModal, hideModal } from 'erisxkit/client';
import { useDispatch } from 'react-redux';
import uboMetadata from '../../../metadata/uboMetadata';
import {
  ADD_UBO,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../../../constants/modalTypes';
import {
  addUltimateBeneficialOwner,
  updateUltimateBeneficialOwner,
  removeUltimateBeneficialOwner,
} from '../../../reducers/membersReducer';
import { shallowObjectDiff } from '../../../utils/methods';
import AgGridWrapper from '../../../common/table/agGrid/AgGridWrapper';
import UBOGrid, {
  gridName,
} from '../../../components/agGrid/MemberDetails/UboGrid/UBOGrid';
import getColumnDefs from '../../../components/agGrid/MemberDetails/UboGrid/columnDefs';

export const MAXIMUM_UBOS = 10;
export const MINIMUM_OWNERSHIP = 25;

// takes an object and combines all the address values to one field
const combineAddress = ({
  address1,
  address2,
  city,
  locationState,
  postalCode,
  country,
}) =>
  `${address1 || ''} ${address2 || ''} ${city || ''}, ${locationState || ''} ${postalCode || ''} ${country || ''}`;

const convertObjectToKeyValueArray = (obj) =>
  map(obj, (value, label) => ({ label, value }));

export const RemoveModalComponent = ({ row }) => (
  <Fragment>
    <section className="flex-column text-center">
      <Header as="h2">
        Are you sure you want to remove the following entry?
        <Header.Subheader>This action cannot be undone.</Header.Subheader>
      </Header>
    </section>
    <Segment>
      <Table
        basic="very"
        collapsing
        tableData={convertObjectToKeyValueArray(combineAddress(row))}
      >
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="right">
              <h4>Name:</h4>
            </Table.Cell>
            <Table.Cell>{row.name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="right">
              <h4>Address:</h4>
            </Table.Cell>
            <Table.Cell>{combineAddress(row)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="right">
              <h4>Ownership:</h4>
            </Table.Cell>
            <Table.Cell>{row.percentageOwned}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="right">
              <h4>Documentation:</h4>
            </Table.Cell>
            <Table.Cell>{row.documentsReceived ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="right">
              <h4>Controller:</h4>
            </Table.Cell>
            <Table.Cell>{row.controller}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  </Fragment>
);

const UBOContainer = ({
  memberDetailsView,
  updateMember,
  addUbo,
  ubos = [],
  memberId,
  isJest,
}) => {
  const dispatch = useDispatch();
  const addNew = () => {
    dispatch(
      showModal(ADD_UBO, {
        type: 'create',
        initialValues: { ubo: [{}] },
        hideModal: () => dispatch(hideModal()),
        confirm: (data) => {
          dispatch(addUltimateBeneficialOwner({ memberId, ...data }));
          dispatch(hideModal());
        },
      }),
    );
  };
  const edit = (row) => {
    dispatch(
      showModal(ADD_UBO, {
        type: 'update',
        initialValues: { ubo: [row] },
        hideModal: () => dispatch(hideModal()),
        confirm: (data) => {
          // in an update, there's only one ubo you can update at one time.
          // the form still gives back an array so have to pick the first item.
          const ubo = data.ubo[0];
          const update = {
            ownerId: ubo.id,
            ...shallowObjectDiff(ubo, row),
          };
          dispatch(
            updateUltimateBeneficialOwner({
              memberId,
              update,
            }),
          );
          dispatch(hideModal());
        },
      }),
    );
  };

  const remove = (row) => {
    dispatch(
      showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
        header: 'Delete UBO',
        closeOnDimmerClick: true,
        closeOnEscape: true,
        Content: <RemoveModalComponent row={row} />,
        hideModal: () => dispatch(hideModal()),
        onConfirm: () => {
          dispatch(
            removeUltimateBeneficialOwner({ memberId, ownerId: row.id }),
          );
          dispatch(hideModal());
        },
      }),
    );
  };

  return (
    <Fragment>
      {memberDetailsView ? (
        <div className="flex-row">
          <Header as="h3">Ultimate Beneficial Owners</Header>
          <Button className="link edit" content="Edit" onClick={addUbo} />
        </div>
      ) : (
        <div className="flex-row space-between">
          <h4>Ultimate Beneficial Owners</h4>
          <Button
            onClick={addNew}
            className="add-button"
            disabled={ubos.length >= MAXIMUM_UBOS}
          >
            <Icon name="add circle" /> Add New UBO
          </Button>
        </div>
      )}
      <AgGridWrapper
        gridName={gridName}
        agGridComponent={
          <UBOGrid
            isJest={!!isJest}
            data={ubos}
            colDefs={getColumnDefs(memberDetailsView, edit, remove)}
          />
        }
        reactTableComponent={
          <XTable
            data={ubos}
            columns={uboMetadata({ memberDetailsView, edit, remove })}
            showPagination={false}
            title="ubo"
            minRows={3}
          />
        }
      />
    </Fragment>
  );
};

export default UBOContainer;
