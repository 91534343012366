import { ColDef } from 'ag-grid-community';
import { formatTimeLocalWithDoW } from '../../../../utils/time';
import FuturesPositions from '../../../../ts/models/FuturesPositions.model';

const columnDefs: ColDef<FuturesPositions>[] = [
  {
    headerName: 'Exchange',
    field: 'exchangeMicCode',
    width: 225,
    rowGroup: true,
    hide: true,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Product Code',
    field: 'productSymbol',
    width: 225,
    rowGroup: true,
    hide: true,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Contract Code',
    field: 'contractSymbol',
    width: 225,
    rowGroup: true,
    hide: true,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Account',
    field: 'accountLabel',
    width: 225,
    rowGroup: true,
    hide: true,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'CGM',
    field: 'cgmNumber',
    width: 225,
    rowGroup: true,
    hide: true,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Maturity Date',
    field: 'maturityDate',
    valueFormatter: ({ data }: { data?: FuturesPositions }) =>
      formatTimeLocalWithDoW(data?.maturityDate, true),
    width: 225,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Total Long',
    field: 'longQty',
    width: 225,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Total Short',
    field: 'shortQty',
    width: 225,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
];

export default columnDefs;
