import React from 'react';
import { ColDef } from 'ag-grid-community';
import { DEFAULT_DATE_FILTER_OPS } from 'utils/agGrid/options';
import { NavLink } from 'react-router-dom';
import { RiskAlert } from 'ts/models/RiskAlerts.model';
import { NANO_TO_MILLI_FACTOR } from 'metadata/riskOverviewMetadata';
import { formatUnixTsLocal } from 'utils/dateTime';

const riskAlertsGridColDefs: ColDef<RiskAlert>[] = [
  {
    headerName: 'Alert ID',
    field: 'alertId',
    filter: 'agTextColumnFilter',
    cellRenderer: ({ data }: { data: RiskAlert }) => (
      <NavLink to={`/risk_alert/${data.alertId}/detail`}>
        {' '}
        {data.alertId}{' '}
      </NavLink>
    ),
  },
  {
    headerName: 'Transaction Time',
    field: 'transactionTime',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
    valueFormatter: ({ value }) => {
      const time = value?.toString();
      const mils = parseInt(
        time?.substring(0, time?.length - NANO_TO_MILLI_FACTOR),
      );
      return formatUnixTsLocal(mils);
    },
  },
  {
    headerName: 'Account',
    field: 'accountNumber',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Alert Type',
    field: 'type',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Alert Status',
    field: 'status',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Alert Severity',
    field: 'severity',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Description',
    field: 'description',
    filter: 'agTextColumnFilter',
    initialWidth: 450,
    wrapText: true,
    autoHeight: true,
  },
  {
    headerName: 'Comment',
    field: 'comment',
    filter: 'agTextColumnFilter',
  },
];

export default riskAlertsGridColDefs;
