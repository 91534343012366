import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import get from 'lodash/get';

interface customProps {
  alphanumeric?: boolean | undefined;
  validateName: string;
}

const InputValidatorContainer = (
  props: React.InputHTMLAttributes<HTMLInputElement> & customProps,
) => {
  const [error, setError] = useState({});
  const { alphanumeric, value, validateName, minLength, maxLength, required } =
    props;
  const handleValidations = useCallback(
    (field: string, fieldValue: string, required: boolean) => {
      const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(String(value || ''));
      let errorMessage = '';
      if (
        alphanumeric &&
        !isAlphanumeric &&
        !(fieldValue?.length === 0 && !required)
      ) {
        errorMessage = 'Must be alphanumeric. ';
      }

      if (
        minLength &&
        fieldValue?.length < minLength &&
        !(fieldValue?.length === 0 && !required)
      ) {
        errorMessage = `Must be at least ${minLength} characters. `;
      }

      if (
        maxLength &&
        fieldValue?.length > maxLength &&
        !(fieldValue?.length === 0 && !required)
      ) {
        errorMessage = `Must be no more than ${maxLength} characters. `;
      }
      if (fieldValue === '' && required) {
        errorMessage = `Required. `;
      }
      setError({
        ...error,
        [field]: errorMessage.trim() || null,
      });
    },
    [alphanumeric, error, maxLength, minLength, value],
  );

  return (
    <div className="field">
      <Form.Field
        control={Input}
        {...props}
        onBlur={() => {
          handleValidations(
            validateName,
            String(value || ''),
            required || false,
          );
        }}
        error={get(error, validateName, null)}
      />
      {get(error, validateName, null) ? (
        <div className="ui pointing above prompt label">
          {get(error, validateName, null)}
        </div>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default InputValidatorContainer;
