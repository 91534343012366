import React from 'react';
import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { BankAccount } from './BankAccountGrid';
import { Button, Dropdown, Icon, Label } from 'semantic-ui-react';
import { formatTimeLocal } from '../../../../utils/time';
import { iconColorForState } from '../../../../utils/methods';

const getColumnDefs = (
  remove: (data: BankAccount | undefined) => void,
  edit: (data: BankAccount | undefined) => void,
): ColDef[] => [
  {
    headerName: 'Label',
    field: 'label',
    valueGetter: ({ data }: { data?: BankAccount }) =>
      get(data, 'label', '(No label)'),
    cellClass: ({ data }: { data?: BankAccount }) =>
      data?.label ? '' : 'italic',
  },
  {
    field: 'bankName',
    headerName: 'Bank Name',
  },
  {
    headerName: 'Account Number',
    field: 'accountNumber',
    valueGetter: ({ data }: { data?: BankAccount }) => data?.accountNumber,
  },
  {
    headerName: 'Routing Number',
    field: 'routingNumber',
    valueGetter: ({ data }: { data?: BankAccount }) => data?.routingNumber,
  },
  {
    headerName: 'Additional SSI',
    field: 'additionalSsi',
    valueGetter: ({ data }: { data?: BankAccount }) =>
      get(data, 'additionalSsi', '-'),
    minWidth: 200,
  },
  {
    headerName: 'Date Added',
    field: 'createdAt',
    width: 300,
    valueGetter: ({ data }: { data?: BankAccount }) =>
      formatTimeLocal(data?.createdAt),
  },
  {
    headerName: 'Is Plaid',
    field: 'isPlaidAccount',
    valueGetter: ({ data }: { data?: BankAccount }) =>
      `${get(data, 'isPlaidAccount', 'false')}`,
  },
  {
    headerName: 'Status',
    field: 'state',
    cellRenderer: ({ data }: { data?: BankAccount }) => (
      <Label color={iconColorForState(data?.state)} content={data?.state} />
    ),
    cellClass: 'pull-right',
    resizable: false,
  },
  {
    headerName: 'Actions',
    field: 'more',
    width: 300,
    cellRenderer: ({ data }: { data?: BankAccount }) => (
      <>
        <Button compact onClick={() => edit(data)}>
          <Icon
            name="pencil"
            color="blue"
            className="margin-left-10"
            style={{ cursor: 'pointer' }}
          />
          Edit SSI
        </Button>
        <Button compact onClick={() => remove(data)}>
          <Icon
            name="delete"
            color="red"
            className="margin-left-10"
            style={{ cursor: 'pointer' }}
          />
          Delete SSI
        </Button>
      </>
    ),
  },
];

export default getColumnDefs;
