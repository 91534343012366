import React, { useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { createLoadingSelector } from 'erisxkit/client';
import { commonPaginationOptions } from 'utils/agGrid/options';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { Exchange } from 'ts/models/Exchange.model';
import {
  FETCH_EXCHANGE_LIST,
  fetchExchanges,
  getExchangeList,
} from 'reducers/exchangeReducer';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'ts/types/store';
import useEditableGrid from 'utils/agGrid/hooks/useEditableGrid';
import useExchangeCallbacks from './useExchangeCallbacks';

interface AGReportsProps {
  isJest?: boolean;
}

export const EXCHANGES_GRID_NAME = 'exchanges';

const ExchangesGrid: React.FC<AGReportsProps> = ({ isJest }) => {
  const gridRef = useRef<AgGridReact>(null);

  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) =>
    createLoadingSelector([FETCH_EXCHANGE_LIST])(state),
  );
  const rowData = useAppSelector(getExchangeList);
  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: EXCHANGES_GRID_NAME,
  });

  const { onDelete, onUpdate } = useExchangeCallbacks();

  const { gridOptions } = useEditableGrid<Exchange>({
    options: commonPaginationOptions,
    onUpdate,
    onDelete,
  });

  useEffect(() => {
    dispatch(fetchExchanges());
  }, [dispatch]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <>
          <AgGridReact<Exchange>
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default ExchangesGrid;
