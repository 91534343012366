import React, { Fragment } from 'react';
import { Header, Form, Segment, Select, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import FloatInputContainer from '../../common/containers/FloatInputContainer';
import ProductContractSelection from '../../common/containers/ProductContractSelection';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';
import InputValidatorContainer from '../../common/containers/InputValidatorContainer';
import origin from '../../constants/origin';

const ButtonContainer = styled.div`
  height: 30px;
`;

const SegmentOverflow = styled(Segment)`
  overflow: auto;
`;

const betweenYesterdayAndToday = (date) =>
  date.isBetween(Datetime.moment().subtract(2, 'day'), Datetime.moment());

const validExecutionTime = (tradeDate, executionTime) =>
  executionTime.isBetween(
    Datetime.moment(tradeDate).subtract(1, 'day'),
    Datetime.moment(),
  );

const YesNo = [
  { text: 'Yes', value: 'Y' },
  { text: 'No', value: 'N' },
];

const CTIs = [
  { text: 'MEMBER_FOR_OWN_ACCOUNT', value: '1' },
  { text: 'PROPIETARY_FIRM_TRADING', value: '2' },
  { text: 'MEMBER_FOR_ANOTHER_MEMBER', value: '3' },
  { text: 'ALL_OTHER', value: '4' },
];

const validEnableButton = (data) => {
  return !!(
    data.contractSymbol &&
    data.executionTime &&
    data.trdLinkId &&
    data.px &&
    data.qty &&
    data.tradeDate &&
    data.trdType &&
    data.buySide.accountId &&
    data.buySide.tradeId &&
    (data.buySide.multiLeg !== 'Y' || data.buySide.secondaryExecId) &&
    data.buySide.origin &&
    data.buySide.cti &&
    data.buySide.traderId &&
    data.buySide.traderId.length >= 3 &&
    data.buySide.traderId.length <= 18 &&
    data.buySide.clOrdId &&
    data.buySide.customerAccountRef &&
    data.sellSide.accountId &&
    data.sellSide.tradeId &&
    (data.sellSide.multiLeg !== 'Y' || data.sellSide.secondaryExecId) &&
    data.sellSide.origin &&
    data.sellSide.cti &&
    data.sellSide.traderId &&
    data.sellSide.traderId.length >= 3 &&
    data.sellSide.traderId.length <= 18 &&
    data.sellSide.clOrdId &&
    data.sellSide.customerAccountRef
  );
};

const TradeEntry = ({ contractsLoading, data, handleChange, handleSubmit }) => {
  return (
    <Fragment>
      <Header as="h2" dividing>
        Trade Entry
      </Header>
      <Form onSubmit={handleSubmit}>
        <label className="italic">
          Note: trade date and execution time are restricted to yesterday and
          today. The execution time must also be after the trade date.
        </label>
        <Form.Group>
          <Form.Field
            control={Datetime}
            className="ui input datetime"
            label="Trade Date"
            name="tradeDate"
            id="trade-date"
            defaultValue={Datetime.moment(data.tradeDate)}
            required
            dateFormat="MM/DD/YYYY"
            timeFormat={false}
            inputProps={{ placeholder: 'MM/DD/YYYY' }}
            onChange={(currentDate) =>
              handleChange(null, {
                name: 'tradeDate',
                value: Datetime.moment(currentDate._d).format('YYYY-MM-DD'),
              })
            }
            isValidDate={(current) => betweenYesterdayAndToday(current)}
            closeOnSelect
          />
          <Form.Field
            control={Datetime}
            className="ui input datetime"
            label="Execution Time"
            name="executionTime"
            id="execution-time"
            defaultValue={Datetime.moment(data.executionTime)}
            required
            inputProps={{ placeholder: 'MM/DD/YYYY HH:MI AM' }}
            onChange={(currentDate) =>
              handleChange(null, {
                name: 'executionTime',
                value: Datetime.moment(currentDate._d).format(),
              })
            }
            isValidDate={(current) =>
              validExecutionTime(data.tradeDate, current)
            }
            closeOnSelect
          />
        </Form.Group>
        <ProductContractSelection
          contractName="contractSymbol"
          showDetails
          onChange={handleChange}
          required
          showExchangeDropdown
        />
        <Form.Group>
          <InputValidatorContainer
            required
            label="Quantity"
            name="qty"
            id="quantity"
            onChange={handleChange}
            value={data.qty}
          />
          <FloatInputContainer
            required
            label="Price"
            name="px"
            id="price"
            placeholder="0.00"
            onChange={handleChange}
            value={data.px}
            maxDecimals={2}
          />
          <Form.Dropdown
            label="Trade Type"
            required
            name="trdType"
            id="trd-type"
            search
            selection
            placeholder="Trade type"
            options={[
              { text: 'Regular', value: 'REGULAR' },
              { text: 'Block', value: 'BLOCK' },
              { text: 'Correction', value: 'CORRECTION' },
              { text: 'ADP', value: 'ADP' },
            ]}
            onChange={handleChange}
            value={data.trdType}
            clearable
          />
          <InputValidatorContainer
            label="Trade Link ID"
            name="trdLinkId"
            id="trade-link-id"
            onChange={handleChange}
            value={data.trdLinkId}
            required
            validateName="buySide.trdLinkId"
            alphanumeric
          />
        </Form.Group>
        <Segment.Group horizontal>
          <SegmentOverflow>
            <Header as="h1">Buy Side</Header>
            <Form.Group>
              <AccountSelectionContainer
                required
                label="Account"
                name="buy/accountId"
                id="buy-account-id"
                onChange={handleChange}
              />
              <InputValidatorContainer
                clearable
                label={'CGM'}
                name="buy/cgm"
                id="buy-cgm"
                onChange={handleChange}
                validateName="buySide.cgm"
                alphanumeric
              />
              <InputValidatorContainer
                fluid
                label="Customer Account Ref"
                name="buy/customerAccountRef"
                id="buy-customer-account-ref"
                onChange={handleChange}
                value={data.buySide.customerAccountRef}
                required
                validateName="buySide.customerAccountRef"
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Fee Type"
                readOnly
                name="buy/feeType"
                id="buy-fee-type"
                loading={contractsLoading}
                value={data.buySide.feeType}
              />

              <FloatInputContainer
                fluid
                label="Clearing Fee"
                name="buy/clearingFee"
                id="buy-clearing-fee"
                placeholder="0.00"
                onChange={handleChange}
                value={data.buySide.clearingFee}
                maxDecimals={2}
              />

              <FloatInputContainer
                fluid
                label="Exchange Fee"
                name="buy/exchangeFee"
                id="buy-exchange-fee"
                placeholder="0.00"
                onChange={handleChange}
                value={data.buySide.exchangeFee}
                maxDecimals={2}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                clearable
                label={'Origin'}
                name="buy/origin"
                id="buy-origin"
                onChange={handleChange}
                options={origin.map((option) => ({
                  key: option.value,
                  value: option.value,
                  text: `${option.name} (${option.value})`,
                }))}
                required
              />
              <Form.Field
                control={Select}
                clearable
                label={'CTI'}
                name="buy/cti"
                id="buy-cti"
                onChange={handleChange}
                options={CTIs}
                required
              />
              <InputValidatorContainer
                clearable
                label={'Trader ID'}
                name="buy/traderId"
                id="buy-trader-id"
                onChange={handleChange}
                required
                minLength={3}
                maxLength={18}
                value={data.buySide.traderId}
                validateName="buySide.traderId"
                alphanumeric
              />
              <InputValidatorContainer
                clearable
                label={'Exchange Member ID'}
                name="buy/exchangeMemberId"
                id="buy-exchange-member-id"
                onChange={handleChange}
                minLength={3}
                maxLength={18}
                value={data.buySide.exchangeMemberId}
                validateName="buySide.exchangeMemberId"
                alphanumeric
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                fluid
                label="Client Order ID"
                name="buy/clOrdId"
                id="buy-client-order-id"
                onChange={handleChange}
                value={data.buySide.clOrdId}
                required
              />
              <InputValidatorContainer
                label="Trade ID"
                name="buy/tradeId"
                id="buy-trade-id"
                onChange={handleChange}
                value={data?.buySide?.tradeId}
                required
                validateName="buySide.tradeId"
                alphanumeric
              />
              <Form.Field
                control={Select}
                clearable
                label={'Multi Leg'}
                name="buy/multiLeg"
                id="buy-multi-leg"
                onChange={handleChange}
                options={YesNo}
              />
              <InputValidatorContainer
                label="Secondary Exec ID"
                name="buy/secondaryExecId"
                id="buy-secondary-exec-id"
                onChange={handleChange}
                value={data?.buySide?.secondaryExecId}
                required={data.buySide.multiLeg === 'Y'}
                validateName="buySide.secondaryExecId"
                alphanumeric
              />
            </Form.Group>
          </SegmentOverflow>
          <SegmentOverflow>
            <Header as="h1">Sell Side</Header>
            <Form.Group>
              <AccountSelectionContainer
                required
                label="Account"
                name="sell/accountId"
                id="sell-account-id"
                onChange={handleChange}
              />
              <InputValidatorContainer
                clearable
                label={'CGM'}
                name="sell/cgm"
                id="sell-cgm"
                onChange={handleChange}
                validateName="sellSide.cgm"
                alphanumeric
              />
              <InputValidatorContainer
                fluid
                label="Customer Account Ref"
                name="sell/customerAccountRef"
                id="sell-customer-account-ref"
                onChange={handleChange}
                value={data.sellSide.customerAccountRef}
                validateName="sellSide.customerAccountRef"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Fee Type"
                readOnly
                name="sell/feeType"
                id="sell-fee-type"
                loading={contractsLoading}
                onChange={handleChange}
                value={data.sellSide.feeType}
              />

              <FloatInputContainer
                fluid
                label="Clearing Fee"
                name="sell/clearingFee"
                id="sell-clearing-fee"
                placeholder="0.00"
                onChange={handleChange}
                value={data.sellSide.clearingFee}
                maxDecimals={2}
              />
              <FloatInputContainer
                fluid
                label="Exchange Fee"
                name="sell/exchangeFee"
                id="sell-exchange-fee"
                placeholder="0.00"
                onChange={handleChange}
                value={data.sellSide.exchangeFee}
                maxDecimals={2}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                clearable
                label={'Origin'}
                name="sell/origin"
                id="sell-origin"
                onChange={handleChange}
                required
                options={origin.map((option) => ({
                  key: option.value,
                  value: option.value,
                  text: `${option.name} (${option.value})`,
                }))}
              />
              <Form.Field
                control={Select}
                clearable
                label={'CTI'}
                name="sell/cti"
                id="sell-cti"
                onChange={handleChange}
                options={CTIs}
                required
              />
              <InputValidatorContainer
                clearable
                label={'Trader ID'}
                name="sell/traderId"
                id="sell-trader-id"
                onChange={handleChange}
                required
                minLength={3}
                maxLength={18}
                value={data.sellSide.traderId}
                validateName="sellSide.traderId"
              />
              <InputValidatorContainer
                clearable
                label={'Exchange Member ID'}
                name="sell/exchangeMemberId"
                id="sell-exchange-member-id"
                onChange={handleChange}
                minLength={3}
                maxLength={18}
                value={data.sellSide.exchangeMemberId}
                validateName="sellSide.exchangeMemberId"
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                fluid
                label="Client Order ID"
                name="sell/clOrdId"
                id="sell-client-order-id"
                onChange={handleChange}
                value={data.sellSide.clOrdId}
                required
              />
              <InputValidatorContainer
                label="Trade ID"
                name="sell/tradeId"
                id="sell-trade-id"
                onChange={handleChange}
                value={data?.sellSide?.tradeId}
                required
                validateName="sellSide.tradeId"
                alphanumeric
              />
              <Form.Field
                control={Select}
                clearable
                label={'Multi Leg'}
                name="sell/multiLeg"
                id="sell-multi-leg"
                onChange={handleChange}
                options={YesNo}
              />
              <InputValidatorContainer
                label="Secondary Exec ID"
                name="sell/secondaryExecId"
                id="sell-secondary-exec-id"
                onChange={handleChange}
                value={data?.sellSide?.secondaryExecId}
                required={data.sellSide.multiLeg === 'Y'}
                validateName="sellSide.secondaryExecId"
                alphanumeric
              />
            </Form.Group>
          </SegmentOverflow>
        </Segment.Group>
        <ButtonContainer>
          <Form.Button
            floated="right"
            type="submit"
            disabled={!validEnableButton(data)}
            onSubmit={handleSubmit}
          >
            Submit
          </Form.Button>
        </ButtonContainer>
      </Form>
    </Fragment>
  );
};

TradeEntry.propTypes = {
  contractsLoading: PropTypes.bool,
  contractOptions: PropTypes.node,
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ),
  futuresContractOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  spotProductOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tradeEntryCgmsBuySide: PropTypes.array,
  tradeEntryCgmsSellSide: PropTypes.array,
};

TradeEntry.defaultProps = {
  contractsLoading: false,
  contractOptions: [],
  data: {},
  futuresContractOptions: [],
  spotProductOptions: [],
  tradeEntryCgmsBuySide: [],
  tradeEntryCgmsSellSide: [],
};

export default TradeEntry;
