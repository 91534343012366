import { isEmpty, union, flatten, find } from 'lodash';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Dropdown, Checkbox } from 'semantic-ui-react';
import {
  spotProducts,
  getSpotProducts,
} from '../../reducers/spotProductsReducer';
import {
  futuresProducts,
  getFuturesProducts,
} from '../../reducers/futuresProductReducer';
import { getSelectorAsOptions } from '../../selectors';
import { getContracts, contractSymbols } from '../../reducers/contractsReducer';
import DetailsHeader from '../components/DetailsHeader';
import moment from 'moment';
import {
  FETCH_EXCHANGE_LIST,
  fetchExchanges,
  getExchangeList,
  getExchangeOptions,
} from 'reducers/exchangeReducer';

export const dividedOptions = (categories = []) =>
  flatten(
    categories.map((category) =>
      !isEmpty(category) && !category.hide
        ? union(
            [
              {
                key: category.key,
                as: () => (
                  <Dropdown.Header
                    key={category.key}
                    content={category.header}
                  />
                ),
              },
            ],
            [
              {
                key: `${category.key}-divider`,
                as: () => <Dropdown.Divider key={`${category.key}-divider`} />,
              },
            ],
            category.options,
          )
        : [],
    ),
  );

const PRODUCT_FIELD_NAME = 'product';
const DEFAULT_CONTRACT_NAME = 'contractSymbol';
export const EXCHANGE_FIELD_NAME = 'exchangeMicCode';

const mapStateToProps = (state = {}) => ({
  contractsLoading: createLoadingSelector([
    'FUTURES_CONTRACTS',
    'SPOT_PRODUCTS',
  ])(state),
  futuresProductsList: getFuturesProducts(state),
  spotProductsOptions: getSelectorAsOptions(getSpotProducts, {
    key: 'symbol',
    value: 'symbol',
    text: 'symbol',
  })(state),
  contracts: getContracts(state),
  exchangesLoading: createLoadingSelector([FETCH_EXCHANGE_LIST])(state),
  exchangeOptions: getExchangeOptions(state),
});

const mapDispatchToProps = {
  spotProducts,
  futuresProducts,
  contractSymbols,
  fetchExchanges,
};

const renderContractDetails = (contract) => (
  <DetailsHeader item={contract} className="contract-details" compact />
);
class ProductContractSelection extends PureComponent {
  state = {
    exchange: '',
    product: '',
    contract: {},
    showExpiredContracts: false,
  };
  componentDidMount = () => {
    const {
      fetchExchanges,
      spotProducts,
      futuresProducts,
      showExchangeDropdown,
    } = this.props;
    if (showExchangeDropdown) {
      fetchExchanges();
    } else {
      spotProducts();
      futuresProducts();
    }
  };

  getFutureProductsAsOptions = () => {
    const { subExchangeId, futuresProductsList } = this.props;
    const filteredProducts = subExchangeId
      ? futuresProductsList.filter(
          (each) => each.subExchangeId === subExchangeId,
        )
      : futuresProductsList;
    return filteredProducts.map((each) => ({
      key: each.symbol,
      text: each.symbol,
      value: each.symbol,
    }));
  };

  productSelected = (e, { value }) => {
    const {
      contracts,
      onChange,
      contractSymbols,
      spotProductsOptions,
      futuresProductsList,
      showExchangeDropdown,
    } = this.props;
    let selContract;

    const filter = [{ attr: 'product_symbol', op: 'match', value }];
    if (showExchangeDropdown) {
      filter.push({
        attr: 'exchange_mic_code',
        op: 'eq',
        value: this.state.exchange,
      });
    }

    if (
      !this.state.showExpiredContracts &&
      futuresProductsList.some((fp) => fp.symbol === value)
    ) {
      filter.push({
        attr: 'expiration_time',
        op: 'gte',
        value: moment().subtract(7, 'days').format(),
      });
    }
    contractSymbols({ filter });

    // if there's only one contract for a product, select it.
    const filteredContracts = contracts.filter(
      (contract) => contract.productSymbol === value,
    );
    if (filteredContracts.length === 1) {
      selContract = filteredContracts[0];
      onChange(e, {
        name: this.props.contractName || 'contractSymbol',
        value: selContract.symbol,
      });
    } else {
      onChange(e, {
        name: this.props.contractName || 'contractSymbol',
        value: '',
      });
    }

    if (this.props.productName) {
      onChange(e, { name: this.props.productName, value });
    }

    this.setState({
      product: value,
      contract: selContract,
    });
  };

  contractSelected = (e, { name, value }) => {
    const { onChange, contracts } = this.props;
    const contract = find(contracts, { symbol: value });
    this.setState({
      contract,
    });
    onChange(e, { name, value, product: this?.state?.product });
  };

  clearProduct = (e) => {
    const { onChange } = this.props;
    onChange(e, {
      name: PRODUCT_FIELD_NAME,
      value: '',
    });
    this.setState({
      product: '',
    });
  };

  clearContract = (e) => {
    const { onChange, contractName } = this.props;
    onChange(e, {
      name: contractName || DEFAULT_CONTRACT_NAME,
      value: '',
    });
    this.setState({
      contract: {},
    });
  };

  clearProductContract = (e) => {
    this.clearProduct(e);
    this.clearContract(e);
  };

  exchangeSelected = (e, { value }) => {
    const { onChange, spotProducts, futuresProducts } = this.props;
    const filter = [{ attr: 'exchange_mic_code', op: 'eq', value }];
    // Clear Product and Contract because they are exchange-specific
    this.clearProductContract(e);

    if (value === '') {
      // Clear contract selection
      this.setState({
        exchange: '',
      });
    } else {
      spotProducts({ filter });
      futuresProducts({ filter });
      onChange(e, {
        name: EXCHANGE_FIELD_NAME,
        value,
      });
      this.setState({ exchange: value });
    }
  };

  handleSearchChange = (e, { searchQuery, name }) => {
    const filter = [
      { attr: 'contract_code', op: 'contain', value: searchQuery },
    ];

    if (!this.state.showExpiredContracts) {
      filter.push({
        attr: 'expiration_time',
        op: 'gte',
        value: moment().subtract(7, 'days').format(),
      });
    }

    this.props.contractSymbols({ filter });
  };

  isProductDropdownDisabled = () => {
    const { showExchangeDropdown, disabled, exchangesLoading } = this.props;
    if (showExchangeDropdown) {
      return disabled || exchangesLoading || !this.state.exchange;
    }
    return disabled;
  };

  isContractDropdownDisabled = () => {
    const { showExchangeDropdown, exchangesLoading } = this.props;
    if (showExchangeDropdown) {
      return !this.state.product || exchangesLoading;
    }
    return !this.state.product;
  };

  render() {
    const {
      contractsLoading,
      contracts,
      subExchangeId,
      hideSpot,
      hideFutures,
      spotProductsOptions,
      futuresProductsOptions,
      required,
      disabled,
      exchangesLoading,
      showExchangeDropdown,
    } = this.props;

    const contractOptions = contracts
      .filter((contract) => contract.productSymbol === this.state.product)
      .map((c) => ({
        key: c.symbol,
        text: c.contractCode || c.symbol,
        value: c.symbol,
      }));

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Form.Group>
          {showExchangeDropdown && (
            <Form.Field
              disabled={exchangesLoading}
              clearable
              control={Form.Select}
              id={EXCHANGE_FIELD_NAME}
              name={EXCHANGE_FIELD_NAME}
              label="Exchange"
              options={this.props.exchangeOptions}
              noResultsMessage="No exchanges found"
              required={required}
              onChange={this.exchangeSelected}
              placeholder="Select an exchange"
            />
          )}
          <Form.Field
            disabled={this.isProductDropdownDisabled()}
            clearable
            control={Form.Select}
            id="product"
            label="Product"
            loading={contractsLoading}
            options={dividedOptions([
              {
                header: 'Spot Products',
                hide: hideSpot,
                options: spotProductsOptions,
                key: 'spot',
              },
              {
                header: 'Futures Products',
                hide: hideFutures,
                options: this.getFutureProductsAsOptions(),
                key: 'futures',
              },
            ])}
            name={PRODUCT_FIELD_NAME}
            noResultsMessage="No products found."
            onChange={this.productSelected}
            placeholder="Select a product."
            search
            selectOnBlur={false}
            required={required}
            upward={this.props.upward}
            value={this.state.product}
          />
          <Form.Field
            onSearchChange={this.handleSearchChange}
            disabled={this.isContractDropdownDisabled()}
            clearable
            control={Form.Select}
            id="contract"
            label="Contract"
            loading={contractsLoading}
            options={contractOptions}
            name={this.props.contractName || DEFAULT_CONTRACT_NAME}
            noResultsMessage="No contracts found."
            onChange={this.contractSelected}
            value={this.state.contract && this.state.contract.symbol}
            placeholder="Select a contract."
            selectOnBlur={false}
            search
            required={required}
            upward={this.props.upward}
          />
        </Form.Group>
        <Checkbox
          className="show-expired"
          label="Show expired contracts"
          checked={this.state.showExpiredContracts}
          onChange={() =>
            this.setState({
              showExpiredContracts: !this.state.showExpiredContracts,
            })
          }
        />
        {!isEmpty(this.state.contract) && this.props.showDetails && (
          <>
            {renderContractDetails(this.state.contract)}
            <br />
          </>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductContractSelection);
