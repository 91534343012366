import React, { Fragment, useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import XTab from '../../common/tabs/XTab';
import AchContainer from './AchContainer';
import Jurisdictions from './Jurisdictions';
import TrustThresholds from '../../components/TrustTresholds/TrustThresholds';
import useTrustTresholdsEnabled from '../../components/TrustTresholds/useTrustTresholdsEnabled';
import Exchanges from './Exchanges/Exchanges';

const getPanes = (trustThresholdsEnabled = false) => {
  const panes = [
    { url: 'ach', menuItem: 'ACH Banking', render: () => <AchContainer /> },
    {
      url: 'jurisdictions',
      menuItem: 'Jurisdictions',
      render: () => <Jurisdictions />,
    },
    {
      url: 'exchanges',
      menuItem: 'Exchanges',
      render: () => <Exchanges />,
    },
  ];

  if (trustThresholdsEnabled)
    panes.push({
      url: 'trust-thresholds',
      menuItem: 'TRUST Thresholds',
      render: () => <TrustThresholds />,
    });

  return panes;
};

const ConfigurationContainer = () => {
  const trustTresholdsEnabled = useTrustTresholdsEnabled();
  const panes = useMemo(
    () => getPanes(trustTresholdsEnabled),
    [trustTresholdsEnabled],
  );

  return (
    <Fragment>
      <Header as="h1" dividing>
        System Configuration
      </Header>
      <XTab panes={panes} />
    </Fragment>
  );
};

export default ConfigurationContainer;
