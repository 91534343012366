import {
  DateAdvancedFilterModel,
  DateStringAdvancedFilterModel,
  FilterModel,
  JoinAdvancedFilterModel,
} from 'ag-grid-community';
import { DateFilterModel } from 'ag-grid-community';
import {
  ColumnAdvancedFilterModel,
  BooleanAdvancedFilterModel,
  AdvancedFilterModel,
  TextAdvancedFilterModelType,
  ScalarAdvancedFilterModelType,
  BooleanAdvancedFilterModelType,
} from 'ag-grid-community';
import { ISimpleFilterModelType } from 'ag-grid-community/dist/lib/filter/provided/simpleFilter';
import isNil from 'lodash/isNil';

export type ServerSideFilterOp =
  | 'eq'
  | 'ne'
  | 'match'
  | 'contain'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'exists';

export type SupportedDateFilterModel =
  | DateFilterModel
  | DateAdvancedFilterModel
  | DateStringAdvancedFilterModel;

export const isDateFilterModel = (
  value: SupportedDateFilterModel,
): value is DateFilterModel => {
  if (!isNil(value)) {
    return 'dateFrom' in value || 'dateTo' in value;
  }
  return false;
};

export type SupportedAdvancedFilterModel = Exclude<
  ColumnAdvancedFilterModel,
  BooleanAdvancedFilterModel
>;

export type SupportedFilterType = SupportedAdvancedFilterModel extends {
  filterType: infer T;
}
  ? T
  : never;
const SUPPORTED_FILTER_TYPES = [
  'text',
  'number',
  'date',
  'dateString',
  'object',
  'set',
];

export const isSupportedAdvancedFilterModel = (
  filterModel: AdvancedFilterModel | null,
): filterModel is SupportedAdvancedFilterModel =>
  filterModel !== null &&
  SUPPORTED_FILTER_TYPES.includes(filterModel.filterType);

export type SupportedAgGridFilterOp =
  | TextAdvancedFilterModelType
  | ScalarAdvancedFilterModelType
  | BooleanAdvancedFilterModelType
  | ISimpleFilterModelType;

export type ServerSideFilter = {
  attr: string;
  op: ServerSideFilterOp;
  value: string | string[] | number | number[] | boolean;
};

export type ServerSideSortDirection = 'asc' | 'desc';

export type ServerSideSort = {
  attr: string;
  value: ServerSideSortDirection;
};

export type ServerSidePagination = {
  offset: number;
  limit: number;
  page?: number;
};

export type ServerSideQuery = {
  filter?: ServerSideFilter[];
  sort?: ServerSideSort[];
} & Partial<ServerSidePagination>;
export const isJoinedFilterModel = (
  filter: AdvancedFilterModel | FilterModel | null,
): filter is JoinAdvancedFilterModel => {
  return filter !== null && 'conditions' in filter;
};
