import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GridReadyEvent, IServerSideDatasource } from 'ag-grid-community';
import styled from 'styled-components';
import { Checkbox, Dropdown, Button, Form } from 'semantic-ui-react';
import { AgGridReact } from 'ag-grid-react';
import { showModal, createLoadingSelector } from 'erisxkit/client';
import Datetime from 'react-datetime';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useExternalFilter from 'utils/agGrid/hooks/useExternalFilter';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import {
  fetchTaskInfo,
  fetchTasksPromise,
  getTaskNames,
} from 'reducers/taskLogReducer';
import { commonPaginationOptions } from 'utils/agGrid/options';
import { getColDefs, TaskLogGridRow } from './TaskLogGridMetadata';
import { FetchTasksResponse } from 'ts/api/tasks';
import { useAppSelector } from 'hooks/useAppSelector';
import { TASK_LOG } from 'constants/modalTypes';
import { RootState } from 'ts/types/store';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import { ServerSideQuery } from 'utils/agGrid/types';

const Fields = styled.div`
  display: flex;
  gap: 2rem;
`;

const STATUS_OPTIONS = [
  { key: '1', text: 'Success', value: 'success' },
  { key: '2', text: 'Failed', value: 'fail' },
];

interface AGReportsProps {
  isJest?: boolean;
}
export const TASK_LOG_GRID_NAME = 'task-log';

const TaskLogGrid: React.FC<AGReportsProps> = ({ isJest }) => {
  const dispatch = useAppDispatch();
  const taskNames: string[] = useAppSelector(getTaskNames);
  const taskLogLoading = useAppSelector((state: RootState) =>
    createLoadingSelector(['TASK_LOG'])(state),
  );
  const tasks = useMemo(
    () =>
      taskNames &&
      taskNames?.map((name) => ({ key: name, value: name, text: name })),
    [taskNames],
  );
  const gridRef = useRef<AgGridReact>(null);

  const colDefs = useMemo(() => getColDefs(taskNames), [taskNames]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: TASK_LOG_GRID_NAME,
  });

  //If the filter is false, we need to delete the filter because this specific endpoint requires
  //not to send "false" for showFrequentTask if not set.
  const cleanShowFrequentTasksFilters = (
    body: ServerSideQuery,
  ): ServerSideQuery => {
    const filteredFilters =
      body?.filter?.filter(
        (f) => !(f.attr === 'show_frequent_tasks' && f.value === 'false'),
      ) || [];

    return {
      ...body,
      ...(filteredFilters.length > 0 ? { filter: filteredFilters } : {}),
    };
  };
  const [externalFilters, setExternalFilters] = useState({});
  const context: Record<string, string | string[]> = useMemo(
    () => externalFilters,
    [externalFilters],
  );

  const onGridReadyCallback = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success, context }) => {
          const body = parseAGRequest(request, { transformAttrs: true });
          const bodyFiltered = cleanShowFrequentTasksFilters(body);
          // Base filters from the grid
          const filters = bodyFiltered?.filter || [];
          if (context?.name && context.name.length) {
            filters.push({
              attr: 'name',
              op: 'contain',
              value: context?.name,
            });
          }
          if (context?.dateFrom) {
            filters.push({
              attr: 'time',
              op: 'gte',
              value: context?.dateFrom,
            });
          }
          if (context?.dateTo) {
            filters.push({
              attr: 'time',
              op: 'lte',
              value: context?.dateTo,
            });
          }
          const reqBody: ServerSideQuery = {
            ...body,
            filter: filters,
          };
          fetchTasksPromise(reqBody, dispatch).then(
            (response: FetchTasksResponse) => {
              success({
                rowData: response?.tasks,
              });
            },
          );
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  const onFilterChange = useCallback(
    (value: Record<string, string>) => {
      setExternalFilters({ ...externalFilters, ...value });
    },
    [externalFilters],
  );

  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshServerSide({ purge: true });
    }
  }, [externalFilters]);

  const {
    setExternalFilter,
    setExternalBooleanFilter,
    getFilterValue,
    clearFilters,
    hasFiltersApplied,
    getBooleanFilterValue,
    onGridReady,
  } = useExternalFilter({ gridRef, onGridReadyCallback });

  const onTaskSelected = useCallback(
    (task: TaskLogGridRow) => {
      dispatch(
        showModal(TASK_LOG, {
          selectedTaskId: task.taskId,
          name: task.name,
          closeOnEscape: true,
          loading: taskLogLoading,
        }),
      );
    },
    [dispatch, taskLogLoading],
  );
  const clearDateFilters = useCallback(() => {
    setExternalFilters({});
  }, []);

  const onClear = useCallback(() => {
    clearFilters();
    clearDateFilters();
  }, [clearDateFilters, clearFilters]);

  const clearDisabled = useMemo(() => {
    const hasExternalFilters = !isEmpty(externalFilters);
    return !hasFiltersApplied && !hasExternalFilters;
  }, [externalFilters, hasFiltersApplied]);

  useEffect(() => {
    dispatch(fetchTaskInfo());
  }, [dispatch]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <>
          <Form>
            <Form.Group style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Form.Field
                control={Datetime}
                className="ui input datetime"
                label="Begin Date/Time"
                name="beginTime"
                id="beginTime"
                defaultValue=""
                dateFormat="YYYY-MM-DD"
                timeFormat
                value={context?.dateFrom}
                inputProps={{ placeholder: 'Filter Begin Time' }}
                onChange={(time) => {
                  onFilterChange({ dateFrom: time?.format() });
                }}
                closeOnSelect
              />
              <Form.Field
                control={Datetime}
                className="ui input datetime"
                label="End Date/Time"
                name="endTime"
                id="endTime"
                defaultValue=""
                dateFormat="YYYY-MM-DD"
                timeFormat
                value={context?.dateTo}
                inputProps={{ placeholder: 'Filter End Time' }}
                onChange={(time) => {
                  onFilterChange({ dateTo: time.format() });
                }}
                closeOnSelect
              />
              <div className="field">
                <label>Task Name</label>
                <Dropdown
                  value={context?.name || []}
                  placeholder="Click to select Name"
                  onChange={(e, { value }) => {
                    if (value) {
                      onFilterChange({ name: value });
                    }
                  }}
                  name="name"
                  selection
                  multiple
                  labeled
                  options={tasks}
                />
              </div>
              <div className="field">
                <label>Status</label>
                <Dropdown
                  value={getFilterValue('type')}
                  placeholder="Click to select Status"
                  onChange={(e, { name: colId, value }) => {
                    if (value)
                      setExternalFilter({ colId, value, op: 'equals' });
                  }}
                  name="type"
                  selection
                  labeled
                  options={STATUS_OPTIONS}
                />
              </div>
              <div className="field">
                <label>Show Frequent Tasks</label>
                <Checkbox
                  name="showFrequentTasks"
                  checked={getBooleanFilterValue('showFrequentTasks')}
                  onChange={(e, { name: colId, checked: value }) => {
                    if (colId !== undefined && gridRef.current) {
                      if (isBoolean(value)) {
                        setExternalBooleanFilter({ colId, value });
                      } else {
                        // This is only necessary because this specific endpoint requires
                        // not to send "false" for showFrequentTask if not set. Hence we remove it
                        gridRef.current.api.destroyFilter(colId);
                      }
                    }
                  }}
                />
              </div>
              <Button
                color="red"
                disabled={clearDisabled}
                id="clear-filters"
                content="Clear"
                onClick={onClear}
                style={{ height: '38px', marginTop: '24px' }}
              />
            </Form.Group>
          </Form>
          <AgGridReact<TaskLogGridRow>
            ref={gridRef}
            columnDefs={colDefs}
            gridOptions={commonPaginationOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            context={context}
            onRowClicked={({ data }) => {
              if (data) {
                onTaskSelected(data);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export default TaskLogGrid;
