import { get } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import moment from 'moment';
import DeliveryModal from '../../components/Balances/DeliveryModal';
import {
  getActiveTaskLog,
  triggerTask,
  fetchTaskLog,
} from '../../reducers/taskLogReducer';
import { CONTRACT_DELIVERY } from '../../constants/tasks';
import { TASK_LOG } from '../../constants/actionTypes';
import { hideModal } from 'erisxkit/client';
import { formatTimeLocalNumeric } from '../../utils/time';
import { TRIGGER, START, LOG, SUCCESS, FAIL } from '../../constants/taskTypes';

const mapStateToProps = (state) => ({
  taskLogs: getActiveTaskLog(state),
  taskLogsLoading: createLoadingSelector([TASK_LOG])(state),
});

const mapDispatchToProps = {
  fetchTaskLog,
  hideModal,
  triggerTask,
};

class DeliveryModalContainer extends PureComponent {
  state = { dryRun: true, popupOpen: false };

  componentDidMount = () => {
    this.runDelivery();
  };

  downloadTaskLog = () => {
    fileDownload(
      this.props.taskLogs.reduce((a, row) => {
        const time = formatTimeLocalNumeric(row.time);
        switch (row.type) {
          case TRIGGER:
            return `${a}[ ${time} ]: Triggered by ${row.from} on ${row.host} with args: ${get(row, 'args', []).join(' ')}\n`;
          case START:
            return `${a}[ ${time} ]: ${row.name} started on ${row.host}\n`;
          case LOG:
            // handle empty message row; used for spacing
            return `${a}[ ${time} ]: ${row.message || ''}\n`;
          case SUCCESS:
            return `${a}[ ${time} ]: Success\n`;
          case FAIL:
            return `${a}[ ${time} ]: ${row.reason ? `:${row.reason}` : ''}\n`;
          default:
            return a;
        }
      }, ''),
      `delivery_${this.props.productCode}_${this.props.contractCode}_${moment().format()}.txt`,
    );
  };

  runDelivery = () => {
    const { productCode, clearingSymbol, exchangeMicCode } = this.props;
    this.props.triggerTask({
      name: CONTRACT_DELIVERY,
      options: `--product-code "${productCode.replace(/"/g, '\\"')}" --clearing-symbol "${clearingSymbol?.replace(/"/g, '\\"')}"  --exchange-mic-code "${exchangeMicCode?.replace(/"/g, '\\"')}" ${!this.state.dryRun ? ' --no-dry-run' : ''}`,
    });
  };

  render = () => (
    <DeliveryModal
      confirmRunDelivery={() => {
        this.setState({ dryRun: false }, () => this.runDelivery());
      }}
      downloadTaskLog={this.downloadTaskLog}
      dryRun={this.state.dryRun}
      handlePopupClose={() => this.setState({ popupOpen: false })}
      handlePopupOpen={() => this.setState({ popupOpen: true })}
      hideModal={this.props.hideModal}
      loading={this.props.taskLogsLoading}
      popupOpen={this.state.popupOpen}
      taskLogs={this.props.taskLogs}
    />
  );
}

DeliveryModalContainer.propTypes = {
  contractCode: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  productCode: PropTypes.string,
  taskLogs: PropTypes.arrayOf(PropTypes.object),
  taskLogsLoading: PropTypes.bool,
  triggerTask: PropTypes.func.isRequired,
};

DeliveryModalContainer.defaultProps = {
  contractCode: '',
  productCode: '',
  taskLogs: [],
  taskLogsLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryModalContainer);
