import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Filters = styled.div`
  display: flex;
  margin-bottom: 1rem;
  column-count: 3;
  column-gap: 2rem;
`;

export const FilterWrapper = styled.div`
  padding: 0 !important;
  margin: 0 0 0 6px !important;

  .fields {
    flex-wrap: wrap;
  }
`;
