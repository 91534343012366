import React from 'react';
import { NavLink } from 'react-router-dom';
import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { formatTimeLocal } from '../../../utils/time';
import { PendingApprovalReqs } from '../../../ts/models/PendingApprovalReqs.model';
import { DEFAULT_DATE_FILTER_OPS } from '../../../utils/agGrid/options';
import { formatMoneyDescriptionRows } from '../../../utils/methods';
import UserInputSelector from '../../../common/components/UserInputSelector';
import { CustomCellRendererProps } from 'ag-grid-react';

const columnDefs: ColDef<PendingApprovalReqs>[] = [
  {
    headerName: 'State',
    field: 'state',
    valueFormatter: ({ data }: { data?: PendingApprovalReqs }) =>
      (data?.state &&
        data.state.charAt(0).toUpperCase() + data.state.slice(1)) ||
      '',
    width: 225,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
  },
  {
    headerName: 'Time',
    field: 'time',
    valueFormatter: ({ data }: { data?: PendingApprovalReqs }) =>
      formatTimeLocal(get(data, 'time', '')),
    width: 225,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
  },
  {
    headerName: 'Initiator',
    field: 'initiatorId',
    cellRenderer: ({ data }: { data?: PendingApprovalReqs }) => {
      const userType = get(data, 'initiatorType', '');
      const userTypeURL =
        userType === 'member_user' ? 'member_users' : 'employees';
      return data?.initiator ? (
        <NavLink target="_blank" to={`/${userTypeURL}/${data?.initiatorId}`}>
          {data?.initiator}
        </NavLink>
      ) : (
        <span>{data?.initiator}</span>
      );
    },
    cellClass: 'mono',
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
  {
    headerName: 'Description',
    field: 'description',
    valueFormatter: ({ data }: { data?: PendingApprovalReqs }) =>
      formatMoneyDescriptionRows(get(data, 'description', '')),
    cellClass: 'mono',
    filter: 'agTextColumnFilter',
    floatingFilter: false,
    width: 300,
  },
  {
    headerName: 'Required Approvals',
    field: 'reqApprovals',
    width: 250,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
  },
  {
    headerName: 'Approvals',
    field: 'approvals',
    cellRenderer: (params: CustomCellRendererProps) => (
      <span
        data-cy={`approval-btn-index-${get(params, ['node', 'rowIndex'], 0)}`}
      >
        {get(params, ['data', 'approvals'], [])
          .map((p: { email: string }) => p.email)
          .join('; ')}
      </span>
    ),
    width: 200,
  },
  {
    headerName: 'Approve',
    cellRenderer: () => (
      <i className="grey arrow alternate circle right outline link large icon" />
    ),
    width: 200,
  },
];

export default columnDefs;
