import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';

export const fetchApprovalLogs = createRoutine(actionTypes.APPROVAL_LOGS);
export const fetchApprovalReqs = createRoutine(actionTypes.APPROVAL_REQS);
export const approve = createRoutine(actionTypes.APPROVE);
export const approvePromiseCreator = promisifyRoutine(approve);
export const fetchApprovalReqsPromise = promisifyRoutine(fetchApprovalReqs);

export const fetchApprovalReqPayload = (uuid) => ({
  type: actionTypes.APPROVAL_REQ_PAYLOAD_REQUEST,
  payload: uuid,
});

/**
 * @param {string} uuid: select a uuid for approval
 */
export const selectApproval = (uuid) => ({
  type: actionTypes.SELECT_APPROVAL,
  uuid,
});
