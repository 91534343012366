import React from 'react';
import { calculateFinalPositionTransfer } from '../utils/methods';
import get from 'lodash/get';
import parseSymbol from 'utils/parseSymbol';
const ConfirmPositionTransferMetadata = () => {
  return [
    {
      id: 'main',
      Header: '',
      columns: [
        {
          Header: 'Firm',
          accessor: 'firm',
          width: 120,
        },
        {
          Header: 'Account',
          accessor: 'account',
          width: 160,
        },
        {
          Header: 'CGM',
          accessor: 'cgm',
          width: 100,
        },
        {
          Header: 'Transfer Type',
          accessor: 'transferType',
          width: 190,
        },
        {
          Header: 'Price',
          accessor: 'price',
          width: 100,
        },
        {
          Header: 'Product',
          accessor: 'productSymbol',
          width: 150,
        },
        {
          Header: 'Contract',
          accessor: (original) => {
            const symbol = get(original, 'contractSymbol');
            const { contractSymbol } = parseSymbol(symbol);
            return contractSymbol;
          },
          width: 150,
        },
      ],
    },
    {
      id: 'current',
      Header: 'Current',
      columns: [
        {
          Header: 'Long',
          accessor: 'currentLongQty',
          width: 100,
        },
        {
          Header: 'Short',
          accessor: 'currentShortQty',
          width: 100,
        },
      ],
    },
    {
      id: 'pending',
      Header: 'Pending',
      columns: [
        {
          Header: 'Long',
          accessor: 'pendingLongQty',
          width: 100,
        },
        {
          Header: 'Short',
          accessor: 'pendingShortQty',
          width: 100,
        },
      ],
    },
    {
      id: 'transfer',
      Header: 'Transfer',
      columns: [
        {
          Header: 'Long',
          accessor: 'longTransfer',
          width: 120,
        },
        {
          Header: 'Short',
          accessor: 'shortTransfer',
          width: 100,
        },
      ],
    },
    {
      id: 'final',
      Header: 'Final',
      columns: [
        {
          Header: 'Long',
          accessor: 'longFinal',
          Cell: ({ row }) => (
            <span className="full-width">
              {calculateFinalPositionTransfer(
                row.original.currentLongQty,
                row.original.pendingLongQty,
                row.original.longTransfer,
              )}
            </span>
          ),
          width: 100,
        },
        {
          Header: 'Short',
          accessor: 'shortFinal',
          width: 100,
          Cell: ({ row }) => (
            <span className="full-width">
              {calculateFinalPositionTransfer(
                row.original.currentShortQty,
                row.original.pendingShortQty,
                row.original.shortTransfer,
              )}
            </span>
          ),
        },
      ],
    },
  ];
};

export default ConfirmPositionTransferMetadata;
