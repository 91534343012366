import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import PropTypes from 'prop-types';
import { storeUser } from '../actions/authActions';
import {
  fetchCurrentUserPromiseCreator,
  getCurrentUserCategories,
  getCurrentUser,
} from '../reducers/usersReducer';
import history from '../constants/history';
import { isAdmin, isAuthorized } from '../utils/methods';
import EnvPromise from '../config/env';
import { storeEnv } from '../reducers/envReducer';
import { ledgers, switchLedger } from '../reducers/ledgersReducer';
import LoadingComponent from '../common/components/LoadingComponent';
import { getActiveRoute } from '../selectors';
import { assetTypes } from '../actions/utilActions';
import PATH_TO_ALT_PATH from 'routes/pathToAltPath';

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators({ fetchCurrentUserPromiseCreator }, dispatch),
  ...bindActionCreators(
    {
      ledgers,
      storeUser,
      storeEnv,
      switchLedger,
      assetTypes,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  userCategories: getCurrentUserCategories(state),
  location: getActiveRoute(state),
});

// This component checks if the user in the redux store
// has a value, and if not it gets the token from
// localstorage and dispatches the action to store
// the user in the redux state.  This component doesn't render or return anything.
class InitialDataContainer extends Component {
  state = { loading: false };
  componentWillMount = () => {
    const {
      auth,
      activeLedgerId,
      ledgers,
      location,
      user,
      storeUser,
      fetchCurrentUserPromiseCreator,
      assetTypes,
    } = this.props;

    if (auth) {
      !auth.isAuthenticated() && auth.login();
      if (!auth.interval) {
        auth.startInterval();
      }
    }

    if (_.isEmpty(user.userId)) {
      this.setState({ loading: true });
      try {
        const auth0User = jwtDecode(auth.getSession().accessToken);
        storeUser(auth0User);
      } catch (e) {
        // log the user out if the id_token is not valid.
        console.error('Invalid Jwt token');
        auth.login();
      }
      fetchCurrentUserPromiseCreator().then((successPayload) => {
        if (successPayload) {
          // set localStorage item, default to null so that global column widths still work
          if (
            localStorage.getItem('erisx_user') !==
            _.get(successPayload, 'userId', null)
          ) {
            localStorage.setItem(
              'erisx_user',
              _.get(successPayload, 'userId', null),
            );
          }
          this.setState({ loading: false });
          if (
            !isAdmin(successPayload.categories) ||
            !isAuthorized(
              successPayload.uiViews,
              this.props.location.pathname,
              PATH_TO_ALT_PATH[this.props.location.pathname],
            )
          ) {
            history.push('/restricted');
          }
        } else {
          this.setState({ loading: false });
        }
      });
    }

    // fetch ledgers
    ledgers();
    assetTypes();
    // switch to ledger from localStorage. Clears on logout
    const cachedLedgerId = localStorage.getItem('ledger_id');
    if (!_.isEmpty(cachedLedgerId)) {
      this.props.switchLedger(cachedLedgerId);
    }
    EnvPromise.then((env) => this.props.storeEnv(env));
  };
  render = () =>
    this.state.loading || !this.props.user.userId ? (
      <LoadingComponent />
    ) : (
      this.props.children
    );
}

InitialDataContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  fetchCurrentUserPromiseCreator: PropTypes.func.isRequired,
  ledgers: PropTypes.func.isRequired,
  storeEnv: PropTypes.func.isRequired,
  storeUser: PropTypes.func.isRequired,
  switchLedger: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitialDataContainer);
