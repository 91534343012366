import React from 'react';
import { ColDef, GridApi } from 'ag-grid-community';
import { Icon, Label } from 'semantic-ui-react';
import { getColorFromUserState } from 'erisxkit/client';
import * as USER_STATES from '../../../constants/userStates';
import User from '../../../ts/models/User.model';
import { concatStrings } from '../../../utils/methods';
import { formatTimeLocal } from '../../../utils/time';
import { NavLink } from 'react-router-dom';
import SelectFilter from '../../../utils/agGrid/components/SelectFilter';
import MemberNameFilter from './MemberNameFilter';
import { CLONE_MEMBER_USER_COLUMN } from '../../../metadata/userIndexMetadata';
import get from 'lodash/get';

type MemberGridColDefArgs = {
  toggleDisable: (data: User, api: GridApi<User>) => void;
  cloneMemberUser: (data: User) => void;
};
const TOGGLE_MEMBER_COL_ID = 'toggleActive';
const MEMBER_LINK_COL_ID = 'memberLink';
export const ACTION_COLUMNS = [
  TOGGLE_MEMBER_COL_ID,
  MEMBER_LINK_COL_ID,
  CLONE_MEMBER_USER_COLUMN,
];

export type MemberUserGridRow = User & { name: string; id: string };

const getColumnDefs = ({
  toggleDisable,
  cloneMemberUser,
}: MemberGridColDefArgs): ColDef<MemberUserGridRow>[] => [
  {
    headerName: 'Member Name',
    field: 'name',
    sortable: false,
    filter: MemberNameFilter,
    cellRenderer: ({ data }: { data: User }) =>
      concatStrings([data?.firstName, data?.middleName, data?.lastName]),
    valueGetter: ({ data }) =>
      concatStrings([data?.firstName, data?.middleName, data?.lastName]),
  },
  {
    headerName: 'Email',
    field: 'email',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Member',
    colId: MEMBER_LINK_COL_ID,
    autoHeight: true,
    cellRenderer: ({ data }: { data: User }) => {
      const members = data?.memberLinks || [];
      return members.map((m) => (
        <NavLink
          target="_blank"
          className="flex"
          key={m.memberId}
          to={`/members/${m.memberId}/details`}
        >
          {m.memberLabel}
        </NavLink>
      ));
    },
    valueGetter: ({ data }) =>
      data?.memberLinks?.map((m) => m.memberLabel).join(', ') || '',
  },
  {
    headerName: 'Created Date',
    field: 'createdAt',
    valueFormatter: ({ value }) => formatTimeLocal(value),
  },
  {
    headerName: 'User Id',
    field: 'id',
    valueFormatter: ({ data }) => get(data, 'userId', ''),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
    },
  },
  {
    headerName: 'Auth Id',
    field: 'authId',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'State',
    field: 'state',
    filter: SelectFilter,
    cellStyle: { textAlign: 'center' },
    filterParams: {
      placeholder: 'Select State',
      options: Object.values(USER_STATES).map((s) => ({
        key: s,
        value: s,
        text: s,
      })),
    },
    cellRenderer: ({ data }: { data: User }) => (
      <Label
        content={data?.state || 'pending_pii'}
        color={getColorFromUserState(data?.state)}
      />
    ),
  },
  {
    headerName: 'Status',
    field: 'disabled',
    filter: 'agSetColumnFilter',
    cellStyle: { textAlign: 'center' },
    filterParams: {
      values: [true, false],
      valueFormatter: ({ value }: { value: boolean }) => {
        return value ? 'Disabled' : 'Active';
      },
    },
    cellRenderer: ({ data }: { data: User }) => (
      <Label
        color={!data?.disabled ? 'green' : 'red'}
        content={!data?.disabled ? 'Active' : 'Disabled'}
      />
    ),
  },
  {
    headerName: '',
    colId: TOGGLE_MEMBER_COL_ID,
    width: 60,
    suppressColumnsToolPanel: true,
    cellStyle: { textAlign: 'center' },
    cellRenderer: ({ data, api }: { data: User; api: GridApi<User> }) => (
      <Icon
        data-testid={`toggle-member-status-${data.userId}`}
        onClick={() => toggleDisable(data, api)}
        link
        name={data.disabled ? 'check' : 'eye slash'}
      />
    ),
  },
  {
    headerName: 'Clone',
    colId: CLONE_MEMBER_USER_COLUMN,
    width: 120,
    suppressColumnsToolPanel: true,
    cellStyle: { textAlign: 'center' },
    cellRenderer: ({ data }: { data: User }) => (
      <Icon
        onClick={() => cloneMemberUser(data)}
        link
        name={'clone'}
        data-testid={`clone-member-${data.userId}`}
      />
    ),
  },
  /**Filtering only columns */
  {
    field: 'firstName',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'middleName',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'lastName',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: 'agTextColumnFilter',
  },
];

export default getColumnDefs;
