import React from 'react';
import { NavLink } from 'react-router-dom';
import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { formatTimeLocal } from '../../../utils/time';
import {
  RejectedJournals,
  TxType,
} from '../../../ts/models/RejectedJournals.model';
import { DEFAULT_DATE_FILTER_OPS } from '../../../utils/agGrid/options';

export const txTypeValues: TxType[] = ['reversal', 'closeout'];

const columnDefs: ColDef<RejectedJournals>[] = [
  {
    headerName: 'Time',
    field: 'time',
    valueFormatter: ({ data }: { data?: RejectedJournals }) =>
      formatTimeLocal(get(data, 'time', '')),
    width: 225,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
    floatingFilter: false,
  },
  {
    headerName: 'Account',
    field: 'accountId',
    cellRenderer: ({ data }: { data?: RejectedJournals }) =>
      data?.accountId ? (
        <NavLink
          target="_blank"
          className="mono"
          to={`/$clearing_accounts/${data?.accountId}`}
        >
          {data?.accountLabel}
        </NavLink>
      ) : (
        <span>{data?.accountLabel}</span>
      ),
    cellClass: 'mono',
    sortable: false,
  },
  {
    headerName: 'UUID',
    field: 'uuid',
    cellClass: 'mono',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
    },
    width: 300,
    floatingFilter: false,
  },
  {
    headerName: 'Product',
    field: 'product',
    width: 250,
    filter: false,
  },
  {
    headerName: 'Quantity',
    field: 'quantity',
    valueFormatter: ({ data }: { data?: RejectedJournals }) =>
      get(data, 'quantity', '-'),
    cellClass: ({ data }: { data?: RejectedJournals }) =>
      data?.quantity ? '' : 'grey',
    width: 200,
    filter: false,
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 400,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Transaction Type',
    field: 'txType',
    width: 200,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
];

export default columnDefs;
