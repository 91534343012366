import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { Exchange } from '../ts/models/Exchange.model';
import { RootState } from '../ts/types/store';

type FetchExchangesResponse = Exchange[];

/** Prefixes */
export const EXCHANGE_PREFIX = 'EXCHANGE';
export const EXCHANGE_MIC_CODE_PREFIX = 'EXCHANGE_MIC_CODE';

/** Actions */
export const FETCH_EXCHANGE_LIST = 'FETCH_EXCHANGE_LIST';
export const CREATE_EXCHANGE = 'CREATE_EXCHANGE';
export const DELETE_EXCHANGE = 'DELETE_EXCHANGE';
export const UPDATE_EXCHANGE = 'UPDATE_EXCHANGE';

/** Routines */
export const fetchExchanges = createRoutine(FETCH_EXCHANGE_LIST);
export const createExchange = createRoutine(CREATE_EXCHANGE);
export const deleteExchange = createRoutine(DELETE_EXCHANGE);
export const updateExchange = createRoutine(UPDATE_EXCHANGE);

/** Promisified Routines */
export const fetchExchangesPromise = promisifyRoutine(fetchExchanges);
export const createExchangePromise = promisifyRoutine(createExchange);
export const deleteExchangePromise = promisifyRoutine(deleteExchange);
export const updateExchangePromise = promisifyRoutine(updateExchange);

/** Initial State */
type ExchangeState = {
  list: Exchange[];
};

const initialState: ExchangeState = {
  list: [],
};

/** Reducer */
export default handleActions<ExchangeState, FetchExchangesResponse>(
  {
    [fetchExchanges.SUCCESS]: (state, { payload }) => ({
      ...state,
      list: payload,
    }),
  },
  initialState,
);

export const getDomain = (state: RootState) => state?.exchange;
export const getExchangeList = createSelector([getDomain], (state) =>
  get(state, 'list', []),
);
export const getExchangeOptions = createSelector(
  [getExchangeList],
  (exchanges) =>
    exchanges.map((e) => ({
      text: e.name,
      value: e.micCode,
      key: e.micCode,
    })),
);
