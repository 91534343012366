import { arrayToObject } from 'erisxkit/client';
import { createRoutine } from 'redux-saga-routines';
import { handleActions, combineActions } from 'redux-actions';
import { get, chain, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import * as actionTypes from '../constants/actionTypes';
import transformArrayToObject from 'utils/arrayToObject';

//* Routines  */
export const futuresProducts = createRoutine(actionTypes.FUTURES_PRODUCTS);
export const createFuturesProduct = createRoutine(
  actionTypes.CREATE_FUTURES_PRODUCT,
);
export const updateFuturesProduct = createRoutine(
  actionTypes.UPDATE_FUTURES_PRODUCT,
);

export const getExchangeProductKey = (p) =>
  p?.symbol && p?.exchangeMicCode ? `${p.exchangeMicCode}-${p?.symbol}` : '';

//* Reducer  */
export default handleActions(
  {
    [futuresProducts.SUCCESS]: (state, { payload }) => {
      const products = get(payload, 'futuresProducts', []);
      return {
        ...state,
        ...payload,
        futuresProducts: arrayToObject(products, 'symbol'),
        productsByExchange: transformArrayToObject(
          products,
          getExchangeProductKey,
        ),

      };
    },
    [combineActions(
      createFuturesProduct.SUCCESS,
      updateFuturesProduct.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      futuresProducts: {
        ...state.futuresProducts,
        [payload.symbol]: payload,
      },
      productsByExchange: {
        ...state.productsByExchange,
        ...(getExchangeProductKey(payload)
          ? { [getExchangeProductKey(payload)]: payload }
          : {}),
      },
    }),
  },
  {},
);

//* Selectors  */
export const getFuturesProducts = (state) =>
  chain(state).get(['futuresProducts', 'futuresProducts'], {}).values().value();
export const getFuturesProductsByExchange = (state) =>
  chain(state)
    .get(['futuresProducts', 'productsByExchange'], {})
    .values()
    .sortBy('symbol')
    .value();

export const getFuturesProductSymbolsAsOptions = createSelector(
  [getFuturesProducts],
  (futuresProductsList) =>
    futuresProductsList.map((product) => ({
      key: product.symbol,
      text: product.symbol,
      description: product.description,
      value: product.symbol,
    })),
);
