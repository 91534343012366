import Big from 'bignumber.js';
import { formatTimeLocal, formatDateLocal } from '../utils/time';
import parseSymbol from 'utils/parseSymbol';

const appliedMarginRatesMetadata = (tradeDate, approvalCompletedTime) => [
  {
    Header: 'Effective Trade Date',
    id: 'tradeDate',
    accessor: (row) => formatDateLocal(tradeDate),
  },
  {
    Header: 'Approval Date',
    id: 'approvalCompletedTime',
    accessor: (row) => formatTimeLocal(approvalCompletedTime),
  },
  {
    Header: 'Exchange',
    id: 'exchangeMicCode',
    accessor: ({ frontContract: symbol }) =>
      parseSymbol(symbol).exchangeMicCode,
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
  },
  {
    Header: 'Profile Name',
    id: 'margin_profile',
    accessor: 'marginProfile',
  },
  {
    Header: 'Contract 1',
    id: 'front_contract',
    accessor: ({ frontContract: symbol }) => parseSymbol(symbol).contractSymbol,
  },
  {
    Header: 'Contract 2',
    id: 'back_contract',
    accessor: ({ backContract: symbol }) => parseSymbol(symbol).contractSymbol,
  },
  {
    Header: 'Override Margin Rate USD',
    id: 'override_margin_rate_usd',
    accessor: 'overrideMarginRateUsd',
  },
  {
    Header: 'Reference Price',
    id: 'reference_price',
    accessor: 'referencePrice',
  },
  {
    Header: 'Contract Size',
    id: 'contract_size',
    accessor: 'contractSize',
  },
  {
    Header: 'New Initial Margin',
    id: 'initial_margin',
    accessor: 'initialMargin',
    className: 'text-right mono',
  },
  {
    Header: 'Previous Initial Margin',
    id: 'prev_initial_margin',
    accessor: 'prevInitialMargin',
    className: 'text-right mono',
  },
  {
    Header: 'Initial Margin Change',
    id: 'initial_margin_change',
    accessor: (row) =>
      row.prevInitialMargin && row.initialMargin
        ? Big(row.initialMargin).minus(Big(row.prevInitialMargin)).toString()
        : '',
    className: 'text-right mono',
  },
  {
    Header: 'New Maintenance Margin',
    id: 'maintenance_margin',
    accessor: 'maintenanceMargin',
    className: 'text-right mono',
  },
  {
    Header: 'Previous Maintenance Margin',
    id: 'prev_maintenance_margin',
    accessor: 'prevMaintenanceMargin',
    className: 'text-right mono',
  },
  {
    Header: 'Maintenance Margin Change',
    id: 'maintenance_margin_change',
    accessor: (row) =>
      row.prevMaintenanceMargin && row.maintenanceMargin
        ? Big(row.maintenanceMargin)
            .minus(Big(row.prevMaintenanceMargin))
            .toString()
        : '',
    className: 'text-right mono',
  },
];

export default appliedMarginRatesMetadata;
