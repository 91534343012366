import React from 'react';
import { ColDef } from 'ag-grid-community';
import { UBO } from './UBOGrid';
import { Button } from 'semantic-ui-react';

const getColumnDefs = (
  memberDetailsView: boolean,
  edit: (data: UBO | undefined) => void,
  remove: (data: UBO | undefined) => void,
): ColDef[] => [
  {
    headerName: 'Name',
    field: 'name',
    width: 300,
  },
  {
    headerName: 'Address',
    field: 'address',
    valueGetter: ({ data }: { data?: UBO }) =>
      `${data?.address1 || ''} ${data?.address2 || ''} ${data?.city || ''}, ${data?.locationState || ''} ${data?.postalCode || ''} ${data?.country || ''}`,
    width: 500,
  },
  {
    headerName: 'Ownership',
    field: 'percentageOwned',
    flex: 1,
  },
  {
    headerName: 'Documentation',
    field: 'documentation',
    valueGetter: ({ data }: { data?: UBO }) =>
      data?.documentsReceived ? 'Yes' : 'No',
    flex: 1,
  },
  {
    headerName: 'Controller',
    field: 'controller',
    flex: 1,
  },
  {
    headerName: '',
    field: 'actions',
    hide: !!memberDetailsView,
    flex: 1,
    cellRenderer: ({ data }: { data?: UBO }) => (
      <div className="flex-row space-between">
        <Button className="link" content="Edit" onClick={() => edit(data)} />
        <Button
          className="link"
          content="Remove"
          onClick={() => remove(data)}
        />
      </div>
    ),
  },
];

export default getColumnDefs;
