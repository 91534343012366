import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  AgEventListener,
  FilterModel,
  IDoesFilterPassParams,
} from 'ag-grid-community';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import isString from 'lodash/isString';
import { Icon, Input, InputOnChangeData } from 'semantic-ui-react';
import { SupportedAgGridFilterOp } from '../types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

const DEBOUNCE_MS = 400;

export const getInputFilterTestId = (colId: string) =>
  `ag-grid-input-filter${colId ? '-' + colId : ''}`;

type InputFilterProps = CustomFilterProps & {
  field?: string;
  op?: SupportedAgGridFilterOp;
  placeholder?: string;
};

const InputFilter = ({
  getValue,
  model,
  onModelChange,
  op = 'contains',
  api,
  colDef,
  field,
  placeholder = 'Search...',
}: InputFilterProps) => {
  const [localValue, setLocalValue] = useState('');

  const doesFilterPass = useCallback(
    ({ node }: IDoesFilterPassParams) => {
      const value = getValue(node);
      // TODO - support types other than strings
      if (isString(model) && isString(value)) {
        return value.toLowerCase().includes(model.toLowerCase());
      }
      return false;
    },
    [getValue, model],
  );

  useGridFilter({ doesFilterPass });

  const colId = useMemo(
    () => field || colDef?.field || '',
    [colDef?.field, field],
  );

  const dataTestId = useMemo(() => getInputFilterTestId(colId), [colId]);

  const setFilterValue = useCallback(
    (value: string) => {
      const rowModelType = api.getGridOption('rowModelType');
      if (colId && rowModelType === 'serverSide') {
        // TODO: Support other filter types
        const filterModel: FilterModel = {
          [colId]: {
            filterType: 'text',
            type: op,
            filter: String(value),
          },
        };
        const currentFilters = api.getFilterModel();
        const newFilterModel = {
          ...currentFilters,
          ...filterModel,
        };

        // No longer filtering by colId - remove filter from filter model
        if (value === '') {
          delete newFilterModel[colId];
        }
        api.setFilterModel(newFilterModel);
        api.onFilterChanged();
      } else {
        onModelChange(value === '' ? null : value);
      }
    },
    [api, colId, onModelChange, op],
  );

  const debouncedHandler = useMemo(
    () =>
      debounce((value: string) => {
        setFilterValue(value);
      }, DEBOUNCE_MS),
    [setFilterValue],
  );

  const onChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData,
  ) => {
    setLocalValue(value);
    debouncedHandler(value);
  };

  const setValue = (value: string) => {
    setLocalValue(value);
    debouncedHandler(value);
  };

  const onFilterChanged: AgEventListener = useCallback(
    (params) => {
      if ('api' in params) {
        const newFilterModel = api.getFilterModel();
        if (!newFilterModel || isEmpty(newFilterModel)) {
          setLocalValue('');
        }
      }
    },
    [api],
  );

  /** Listen to changes on filterChange in order to keep input value
   * consistent if grid filter model is updated from outside Input Filter
   * e.g.: clear all filters context menu option */
  useEffect(() => {
    api?.addEventListener('filterChanged', onFilterChanged);
    return () => {
      if (api && !api.isDestroyed()) {
        api?.removeEventListener('filterChanged', onFilterChanged);
      }
    };
  }, [api, onFilterChanged]);

  return (
    <Input
      icon={
        <Icon
          name={localValue ? 'close' : 'search'}
          disabled={localValue === ''}
          link
          onClick={() => setValue('')}
          data-testid={`${dataTestId}-${localValue ? 'close' : 'search'}`}
        />
      }
      type="text"
      value={localValue}
      placeholder={placeholder}
      onChange={onChange}
      fluid
      data-testid={dataTestId}
    />
  );
};

export default InputFilter;
