import { SortModelItem } from 'ag-grid-community';
import { ServerSideSort } from '../types';
import isArray from 'lodash/isArray';

/**
 * @param sortModel - SortModelItem as received on getRow's request object
 * @returns List of ServerSideSort compatible with our nomenclature
 */
export const parseAGSort = (sortModel: SortModelItem[]): ServerSideSort[] => {
  const serverSideSort: ServerSideSort[] = [];
  if (!sortModel || !isArray(sortModel)) return serverSideSort;
  return sortModel.map((s) => ({ attr: s.colId, value: s.sort }));
};

export default parseAGSort;
