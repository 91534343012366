import React, { useCallback } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { showModal, hideModal, createLoadingSelector } from 'erisxkit/client';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { GENERIC_MODAL } from '../../../constants/modalTypes';
import { GenericModalProps } from '../../../common/GenericModal';
import AddExchangeForm, { ADD_EXCHANGE_FORM_NAME } from './AddExchangeForm';
import { RootState } from '../../../ts/types/store';
import { getFormValues } from 'redux-form';
import { Exchange } from '../../../ts/models/Exchange.model';
import {
  CREATE_EXCHANGE,
  createExchangePromise,
  fetchExchanges,
} from '../../../reducers/exchangeReducer';

const StyledButton = styled(Button)`
  width: 200px;
  align-self: end;
`;

const AddExchangeButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const onCreate = useCallback(
    async (values: Exchange) => {
      try {
        await createExchangePromise(values, dispatch);
        dispatch(hideModal());
        dispatch(fetchExchanges());
      } catch (error) {
        dispatch(hideModal());
      }
    },
    [dispatch],
  );

  return (
    <StyledButton
      onClick={() =>
        dispatch(
          showModal(GENERIC_MODAL, {
            header: 'Add new Exchange',
            content: <AddExchangeForm />,
            loadingSelector: (state: RootState) =>
              createLoadingSelector([CREATE_EXCHANGE])(state),
            stateSelector: (state: RootState) =>
              getFormValues(ADD_EXCHANGE_FORM_NAME)(state),
            cancelProps: {
              text: 'Cancel',
              onClick: () => dispatch(hideModal()),
            },
            confirmProps: {
              text: 'Add Exchange',
              onClick: onCreate,
            },
          } as GenericModalProps),
        )
      }
      className="add-button"
    >
      <Icon name="add circle" /> Add New Exchange
    </StyledButton>
  );
};

export default AddExchangeButton;
