import Big from 'bignumber.js';
import get from 'lodash/get';
import { formatTimeLocal, formatDateLocal } from '../../../../utils/time';
import { CalculatedMargin } from './CalculatedMarginGrid';
import parseSymbol from 'utils/parseSymbol';

const getColumnDefs = (tradeDate: string, approvalCompletedTime: string) => [
  {
    field: 'tradeDate',
    headerName: 'Effective Trade Date',
    filter: 'agMultiColumnFilter',
    valueGetter: () => formatDateLocal(tradeDate),
  },
  {
    field: 'approvalCompletedTime',
    headerName: 'Approval Date',
    filter: 'agMultiColumnFilter',
    valueGetter: () => formatTimeLocal(approvalCompletedTime),
  },
  {
    headerName: 'Exchange',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }) => {
      const frontContract = get(data, 'frontContract');
      return parseSymbol(frontContract).exchangeMicCode;
    },
  },
  {
    field: 'product',
    headerName: 'Product',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'marginProfile',
    headerName: 'Profile Name',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Contract 1',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }) => {
      const frontContract = get(data, 'frontContract');
      return parseSymbol(frontContract).contractSymbol;
    },
  },
  {
    headerName: 'Contract 2',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }) => {
      const backContract = get(data, 'backContract');
      return parseSymbol(backContract).contractSymbol;
    },
  },
  {
    headerName: 'Override Margin Rate USD',
    field: 'overrideMarginRateUsd',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Reference Price',
    field: 'referencePrice',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Contract Size',
    field: 'contractSize',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'New Initial Margin',
    field: 'initialMargin',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Previous Initial Margin',
    field: 'prevInitialMargin',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Initial Margin Change',
    valueGetter: ({ data }: { data?: CalculatedMargin }) => {
      return data?.prevInitialMargin && data?.initialMargin
        ? new Big(data.initialMargin)
            .minus(new Big(data.prevInitialMargin))
            .toString()
        : '';
    },
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'New Maintenance Margin',
    field: 'maintenanceMargin',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Previous Maintenance Margin',
    field: 'prevMaintenanceMargin',
    filter: 'agMultiColumnFilter',
  },
  {
    headerName: 'Maintenance Margin Change',
    valueGetter: ({ data }: { data?: CalculatedMargin }) =>
      data?.prevMaintenanceMargin && data?.maintenanceMargin
        ? new Big(data.maintenanceMargin)
            .minus(new Big(data.prevMaintenanceMargin))
            .toString()
        : '',
    filter: 'agMultiColumnFilter',
  },
];

export default getColumnDefs;
