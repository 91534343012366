import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { formatTimeLocal } from '../../../../utils/time';
import Movements from '../../../../ts/models/Movements.model';
import { DEFAULT_DATE_FILTER_OPS } from '../../../../utils/agGrid/options';

const columnDefs: ColDef<Movements>[] = [
  {
    headerName: 'Time',
    field: 'time',
    valueFormatter: ({ data }: { data?: Movements }) =>
      formatTimeLocal(get(data, 'time', '')),
    width: 225,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
  },
  {
    headerName: 'Journal Id',
    field: 'journalId',
    width: 225,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Report Date',
    field: 'reportDate',
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Asset Type',
    field: 'assetType',
    width: 250,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Product',
    field: 'productSymbol',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Contract',
    field: 'contractSymbol',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Journal Type',
    field: 'type',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Posting Key',
    field: 'key',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Funds Designation',
    field: 'fundsDesignation',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
  {
    headerName: 'Amount',
    field: 'amount',
    width: 200,
    floatingFilter: false,
    suppressHeaderFilterButton: true,
    sortable: false,
  },
];

export default columnDefs;
