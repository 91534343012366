import React from 'react';
import { get } from 'lodash';
import { Form } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import { withFilters, inputOnChange, datetimeOnchange } from 'erisxkit/client';
import ExternalTable from '../common/table/ExternalTable';
import UserSelectionContainer from '../common/containers/UserSelectionContainer';
import AgGridWrapper from '../common/table/agGrid/AgGridWrapper';
import PendingApprovalReqsGrid, {
  PENDING_APPROVAL_REQS_GRID_NAME,
} from './agGrid/Approvals/PendingApprovalReqsGrid';

const TableWithFilter = withFilters(ExternalTable);
const filters = [
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'Begin Date/Time',
    name: 'min-time',
    id: 'min-time',
    defaultValue: '',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: true,
    inputProps: { placeholder: 'Filter Begin Time' },
    closeOnSelect: true,
    onChange: datetimeOnchange('min-time', 'gte'),
  },
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'End Date/Time',
    name: 'max-time',
    id: 'max-time',
    defaultValue: '',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: true,
    inputProps: { placeholder: 'Filter End Time' },
    closeOnSelect: true,
    onChange: datetimeOnchange('max-time', 'lte'),
  },
  {
    component: UserSelectionContainer,
    name: 'initiator_id',
    id: 'initiator_id',
    label: 'Initiator Id',
    onChange: inputOnChange,
  },
  {
    placeholder: 'Description',
    component: Form.Input,
    name: 'description',
    id: 'description',
    label: 'Description',
    onChange: inputOnChange,
  },
];

const ApprovalReqs = ({
  data = [],
  metadata = [],
  approve,
  onFetchData,
  count,
  loading,
  approvalKey,
}) => {
  return approvalKey === 'pending' ? (
    <AgGridWrapper
      gridName={PENDING_APPROVAL_REQS_GRID_NAME}
      agGridComponent={<PendingApprovalReqsGrid />}
      reactTableComponent={
        <TableWithFilter
          title="approvalReqs"
          className="-striped"
          pageSize={20}
          onFetchData={onFetchData}
          count={count}
          noDataText="No approval requests were found."
          columns={metadata}
          data={data}
          filters={filters}
          getTdProps={(state, rowInfo, column) => ({
            className: 'pointer',
            onClick: () => {
              if (column.id !== 'initiatorId') {
                approve(get(rowInfo, 'original', {}));
              }
            },
          })}
          loading={loading}
        />
      }
    />
  ) : (
    <TableWithFilter
      title="approvalReqs"
      className="-striped"
      pageSize={20}
      onFetchData={onFetchData}
      count={count}
      noDataText="No approval requests were found."
      columns={metadata}
      data={data}
      filters={filters}
      getTdProps={(state, rowInfo, column) => ({
        className: 'pointer',
        onClick: () => {
          if (column.id !== 'initiatorId') {
            approve(get(rowInfo, 'original', {}));
          }
        },
      })}
      loading={loading}
    />
  );
};

export default ApprovalReqs;
