import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Select } from 'semantic-ui-react';
import { getExchangesAsOptions } from '../../selectors';
import {
  fetchExchanges,
  FETCH_EXCHANGE_LIST,
} from '../../reducers/exchangeReducer';

const ExchangeSelectionContainer = ({
  name,
  id,
  input,
  label,
  placeholder,
  required,
}) => {
  const dispatch = useDispatch();
  const exchangesOptions = useSelector(getExchangesAsOptions);
  const exchangesLoading = useSelector(
    createLoadingSelector([FETCH_EXCHANGE_LIST]),
  );

  useEffect(() => {
    dispatch(fetchExchanges());
  }, []);

  const handleChange = (e, { value }) => {
    _.get(input, ['onChange'], () => {})(value);
  };

  return (
    <Form.Field
      control={Select}
      loading={exchangesLoading}
      label={input.label || label}
      search
      name={input.name || name}
      id={input.id || id}
      placeholder={placeholder}
      options={exchangesOptions}
      required={required}
      clearable
      onChange={handleChange}
      value={_.get(input, ['value'])}
    />
  );
};

ExchangeSelectionContainer.propTypes = {
  label: PropTypes.string,
  fetchExchanges: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.objectOf(PropTypes.any),
  required: PropTypes.bool,
  exchangesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  exchangesLoading: PropTypes.bool.isRequired,
};

ExchangeSelectionContainer.defaultProps = {
  input: {},
  name: 'exchangeId',
  placeholder: 'Select Exchange...',
  label: 'Exchange',
  required: false,
  exchangesOptions: [],
  fetchExchanges: () => {},
  exchangesLoading: false,
  id: '',
};

export default ExchangeSelectionContainer;
