import {
  ColumnMenuTab,
  GetContextMenuItems,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import merge from 'lodash/merge';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_MENU_TABS: ColumnMenuTab[] = [
  'filterMenuTab',
  'generalMenuTab',
  'columnsMenuTab',
];

// TO DO: Check if we can infer fiter type and populate
// filterOption accordingly
export const DEFAULT_DATE_FILTER_OPS = [
  'inRange',
  'lessThan',
  'greaterThan',
  'equals',
];
const DEFAULT_FILTER_OPS = [
  'contains',
  'equals',
  'notEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual',
  'inRange',
];
const DEFAULT_FILTER_BUTTONS: string[] = [];

export const clearGridFilters = (api?: GridApi) => {
  if (api) {
    api.setFilterModel(null);
    api.onFilterChanged();
  }
};

const getContextMenuItems: GetContextMenuItems = ({ defaultItems, api }) => {
  return defaultItems
    ? [
        ...defaultItems,
        'separator',
        {
          name: 'Refresh Data',
          icon: '<i aria-hidden="true" class="icon refresh" style="color:#b0b2b3"></i>',
          action: () => {
            if (api) {
              const rowModelType = api.getGridOption('rowModelType');
              // TODO: Add ClientSide check
              if (rowModelType === 'serverSide')
                api.refreshServerSide({ purge: true });
            }
          },
        },
        'separator',
        {
          name: 'Clear All Filters',
          action: () => clearGridFilters(api),
          icon: '<i class="ag-icon ag-icon-cancel"></i>',
        },
      ]
    : [];
};

export const common: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  getContextMenuItems,
  // Fix popup menu height cut-off issue
  popupParent: document?.querySelector('body') || null,
  defaultColDef: {
    resizable: true,
    sortable: true,
    menuTabs: DEFAULT_MENU_TABS,
    floatingFilter: true,
    filterParams: {
      closeOnApply: true,
      buttons: DEFAULT_FILTER_BUTTONS,
      // Not all AG Grid filter ops are supported by our BE
      filterOptions: DEFAULT_FILTER_OPS,
      // BE does not currently support OR condition for a single columns
      suppressAndOrCondition: true,
    },
  },
};
export const pagination: GridOptions = {
  pagination: true,
  paginationPageSizeSelector: [20, 40, 60, 80, 100],
  paginationPageSize: DEFAULT_PAGE_SIZE,
  cacheBlockSize: DEFAULT_PAGE_SIZE,
  onPaginationChanged({ newPageSize, api }) {
    // If there's a page change, update cacheBlockSize (rows we fetch)
    // so it matches newPageSize
    if (newPageSize) {
      const pageSize = api.paginationGetPageSize();
      api.setGridOption('cacheBlockSize', pageSize);
      api.refreshServerSide({ purge: true });
    }
  },
};

export const commonPaginationOptions: GridOptions = {
  ...common,
  ...pagination,
};

export const mergeGridOpts = (
  original: GridOptions,
  overrides: GridOptions,
): GridOptions => merge({}, original, overrides);
