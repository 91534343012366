import React from 'react';
import NavLink from 'react-router-dom/NavLink';
import { ColDef } from 'ag-grid-community';
import { format } from 'erisxkit/client';
import Big from 'bignumber.js';
import { trim } from 'lodash';
import {
  NANO_TO_MILLI_FACTOR,
  formatDateField,
} from '../../../metadata/riskOverviewMetadata';
import RiskAccountSummary from '../../../ts/models/Risk/RiskAccountSummary.model';

const columnDefs: ColDef<RiskAccountSummary>[] = [
  {
    headerName: 'Transaction Time',
    field: 'transactionTime',
    valueFormatter: ({ value }) => {
      const time = value?.toString();
      // times in backend are in nano seconds, we need to convert those to milliseconds
      const mils = parseInt(
        time?.substring(0, time.length - NANO_TO_MILLI_FACTOR),
      );

      return formatDateField(mils);
    },
  },
  {
    headerName: 'Member',
    field: 'member',
  },
  {
    headerName: 'TCS Account',
    cellRenderer: ({ data }: { data: RiskAccountSummary }) =>
      data?.tcsAccountId ? (
        <NavLink
          target="_blank"
          className="text-right mono"
          to={`/balances/${trim(data?.tcsAccountId || '')}`}
        >
          {data?.tcsAccount}
        </NavLink>
      ) : (
        ''
      ),
  },
  {
    headerName: 'TME Account',
    cellRenderer: ({ data }: { data: RiskAccountSummary }) =>
      data?.tmeAccount && data?.snapshotId ? (
        <NavLink
          target="_blank"
          className="text-right mono"
          to={`/risk/${data?.snapshotId?.toString()}/${trim(
            data?.tmeAccount || '',
          )}/detail`}
        >
          {data?.tmeAccount}
        </NavLink>
      ) : (
        ''
      ),
  },
  {
    headerName: 'Margin Exposure Limit',
    field: 'marginExposureLimit',
  },
  {
    headerName: 'Total Balance',
    valueFormatter: ({ data }) => format(data?.totalBalance),
  },
  {
    headerName: 'Pay/Collect',
    valueGetter: ({ data }) => {
      const varBig = Big(data?.variationMargin || 0);
      const cdfBig = Big(data?.cdfFundingAmount || 0);
      const sum = varBig.plus(cdfBig);
      return sum.isNaN() ? '-' : format(sum.toString());
    },
  },
  {
    headerName: 'Span Margin',
    field: 'totalSpanRequirement',
    valueFormatter: ({ data }) => format(data?.totalSpanRequirement),
  },
  {
    headerName: 'Span Spread',
    field: 'totalSpreadRequirement',
    valueFormatter: ({ data }) => format(data?.totalSpreadRequirement),
  },
  {
    headerName: 'Total Equity',
    field: 'totalEquity',
    valueFormatter: ({ data }) => format(data?.totalEquity),
  },
  {
    headerName: 'Margin Purchasing Power',
    field: 'marginPurchasingPower',
    valueFormatter: ({ data }) => format(data?.marginPurchasingPower),
  },
  {
    headerName: 'Margin Excess Deficit',
    field: 'marginExcessDeficit',
    valueFormatter: ({ data }) => format(data?.marginExcessDeficit),
  },
  {
    headerName: 'Long $ Exposure',
    field: 'longExposure',
    valueFormatter: ({ data }) => format(data?.longExposure),
  },
  {
    headerName: 'Short $ Exposure',
    field: 'shortExposure',
    valueFormatter: ({ data }) => format(data?.shortExposure),
  },
  {
    headerName: 'Net $ Exposure',
    field: 'netExposure',
    valueFormatter: ({ data }) => format(data?.netExposure),
  },
  {
    headerName: 'Gross $ Exposure',
    field: 'grossExposure',
    valueFormatter: ({ data }) => format(data?.grossExposure),
  },
  {
    headerName: 'Snapshot Type',
    field: 'snapshotType',
  },
  {
    headerName: 'Snapshot ID',
    field: 'snapshotId',
  },
  {
    headerName: 'Inserted At',
    valueFormatter: ({ data }) => formatDateField(data?.insertedAt),
  },
  {
    headerName: 'Gross Stress Exposure',
    field: 'grossStressExposure',
  },
  {
    headerName: 'Stress Scenario',
    field: 'stressScenario',
  },
  {
    headerName: 'Net Stress Exposure',
    field: 'netStressExposure',
  },
  {
    headerName: 'Stress Exposure Limit',
    field: 'stressExposureLimit',
  },
  {
    headerName: 'Risk Profile',
    field: 'riskProfile',
  },
  {
    headerName: 'Margin Profile',
    field: 'marginProfile',
  },
];

export default columnDefs;
