import { ColDef } from 'ag-grid-community';

const columnDefs: ColDef[] = [
  {
    field: 'name',
    headerName: 'Application Name',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'version',
    headerName: 'Version',
    filter: 'agTextColumnFilter',
  },
];

export default columnDefs;
