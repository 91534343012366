import React, { useEffect, useRef, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import { common, mergeGridOpts } from 'utils/agGrid/options';

export interface CalculatedMargin {
  tradeDate: string;
  approvalCompletedTime: string;
  product: string;
  marginProfile: string;
  frontContract: string;
  backContract: string;
  overrideMarginRateUsd: string;
  referencePrice: string;
  contractSize: string;
  spreadsMaxMaintenanceMarginRate: string;
  prevInitialMargin: string;
  initialMargin: string;
  maintenanceMargin: string;
  prevMaintenanceMargin: string;
}

interface CalculatedMarginFileGridProps {
  data: CalculatedMargin[];
  colDefs: ColDef[];
  loading?: boolean;
  isJest?: boolean;
}

const gridOptions = mergeGridOpts(common, {
  autoSizeStrategy: { type: 'fitCellContents' },
});

export const gridName = 'calculated-margin';

const CalculatedMarginGrid: React.FC<CalculatedMarginFileGridProps> = ({
  data,
  loading,
  colDefs,
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<CalculatedMargin[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } =
    useAgGridCommon({
      isJest,
      gridName,
      gridRef,
    });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data, initialGridSettings?.columnSizing]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <>
          {gridButtons}
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default CalculatedMarginGrid;
