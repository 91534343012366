import React from 'react';
import { ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import { Icon } from 'semantic-ui-react';
import {
  fundsDesignation as designations,
  SEG,
  MEMBER_PROPERTY,
  NON_SEG,
  COLLATERAL,
} from 'erisxkit/client';
import FinanceAccount from '../../../ts/models/FinanceAccount.model';

const getColumnDefs = (
  onSelectAccount: (accountId: string) => void,
): ColGroupDef<FinanceAccount>[] => [
  {
    headerName: 'Identity',
    children: [
      {
        headerName: 'Firm Code',
        field: 'firmCode',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Account Code',
        field: 'accountCode',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Account Name',
        field: 'name',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Subexchange',
        field: 'subExchangeLabel',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Provider',
        field: 'provider',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Provider Account Id',
        field: 'providerAccountId',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Funds Designation',
        field: 'fundsDesignation',
        filter: 'agMultiColumnFilter',
        valueFormatter: ({ data }: ValueFormatterParams<FinanceAccount>) => {
          const fundsDesignation = data?.fundsDesignation;
          switch (fundsDesignation) {
            case designations[SEG].value:
              return designations[SEG].text;
            case designations[MEMBER_PROPERTY].value:
              return designations[MEMBER_PROPERTY].text;
            case designations[NON_SEG].value:
              return designations[NON_SEG].text;
            case designations[COLLATERAL].value:
              return designations[COLLATERAL].text;
            default:
              return fundsDesignation;
          }
        },
      },
    ],
  },
  {
    headerName: 'Balance',
    children: [
      {
        headerName: 'Asset Type',
        field: 'assetType',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Amount',
        field: 'amount',
        filter: 'agMultiColumnFilter',
      },
      {
        headerName: 'Actions',
        cellRenderer: ({ data }: ValueFormatterParams<FinanceAccount>) => {
          return (
            <span>
              {
                <Icon
                  link
                  title="View Reconciliation History"
                  onClick={() => {
                    onSelectAccount(data?.accountId || '');
                  }}
                  color="grey"
                  name="arrow alternate circle right outline"
                />
              }
            </span>
          );
        },
      },
    ],
  },
];

export default getColumnDefs;
