var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __reExport = (target, mod, secondTarget) => (__copyProps(target, mod, "default"), secondTarget && __copyProps(secondTarget, mod, "default"));

// packages/ag-grid-enterprise/src/main.ts
var main_exports = {};
__export(main_exports, {
  AdvancedFilterModule: () => AdvancedFilterModule,
  AgDialog: () => AgDialog,
  AgGroupComponent: () => AgGroupComponent,
  AgGroupComponentSelector: () => AgGroupComponentSelector,
  AgMenuItemComponent: () => AgMenuItemComponent,
  AgMenuItemRenderer: () => AgMenuItemRenderer,
  AgMenuList: () => AgMenuList,
  AgMenuPanel: () => AgMenuPanel,
  AgPanel: () => AgPanel,
  AgPrimaryCols: () => AgPrimaryCols,
  AgRichSelect: () => AgRichSelect,
  ClipboardModule: () => ClipboardModule,
  ColumnsToolPanelModule: () => ColumnsToolPanelModule,
  EnterpriseCoreModule: () => EnterpriseCoreModule,
  ExcelExportModule: () => ExcelExportModule,
  FiltersToolPanelModule: () => FiltersToolPanelModule,
  GridChartsModule: () => GridChartsModule,
  GroupCellRenderer: () => GroupCellRenderer,
  GroupCellRendererCtrl: () => GroupCellRendererCtrl,
  LicenseManager: () => GridLicenseManager,
  MasterDetailModule: () => MasterDetailModule,
  MenuModule: () => MenuModule,
  MultiFilter: () => MultiFilter,
  MultiFilterModule: () => MultiFilterModule,
  PillDragComp: () => PillDragComp,
  PillDropZonePanel: () => PillDropZonePanel,
  PivotDropZonePanel: () => PivotDropZonePanel,
  RangeSelectionModule: () => RangeSelectionModule,
  RichSelectModule: () => RichSelectModule,
  RowGroupDropZonePanel: () => RowGroupDropZonePanel,
  RowGroupingModule: () => RowGroupingModule,
  ServerSideRowModelModule: () => ServerSideRowModelModule,
  SetFilter: () => SetFilter,
  SetFilterModule: () => SetFilterModule,
  SideBarModule: () => SideBarModule,
  SparklinesModule: () => SparklinesModule,
  StatusBarModule: () => StatusBarModule,
  TabbedLayout: () => TabbedLayout,
  ToolPanelColDefService: () => ToolPanelColDefService,
  ValuesDropZonePanel: () => ValuesDropZonePanel,
  ViewportRowModelModule: () => ViewportRowModelModule,
  VirtualList: () => VirtualList,
  VirtualListDragFeature: () => VirtualListDragFeature,
  agCharts: () => agCharts,
  exportMultipleSheetsAsExcel: () => exportMultipleSheetsAsExcel,
  getMultipleSheetsAsExcel: () => getMultipleSheetsAsExcel
});
import { ClientSideRowModelModule } from "ag-grid-community";
import { CommunityFeaturesModule, ModuleRegistry as ModuleRegistry2 } from "ag-grid-community";
import { CsvExportModule as CsvExportModule2 } from "ag-grid-community";
import { InfiniteRowModelModule } from "ag-grid-community";

// enterprise-modules/advanced-filter/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames2, _FilterCoreModule, _defineModule as _defineModule2 } from "ag-grid-community";

// enterprise-modules/core/src/agGridEnterpriseModule.ts
import { ModuleNames, _defineModule } from "ag-grid-community";

// enterprise-modules/core/src/license/gridLicenseManager.ts
import { BeanStub, _getDocument } from "ag-grid-community";

// enterprise-modules/core/src/license/shared/licenseManager.ts
import { _missingOrEmpty } from "ag-grid-community";

// enterprise-modules/core/src/license/shared/md5.ts
var MD5 = class {
  constructor() {
    this.ieCompatibility = false;
  }
  init() {
    this.ieCompatibility = this.md5("hello") != "5d41402abc4b2a76b9719d911017c592";
  }
  md5cycle(x, k) {
    let a = x[0], b = x[1], c = x[2], d = x[3];
    a = this.ff(a, b, c, d, k[0], 7, -680876936);
    d = this.ff(d, a, b, c, k[1], 12, -389564586);
    c = this.ff(c, d, a, b, k[2], 17, 606105819);
    b = this.ff(b, c, d, a, k[3], 22, -1044525330);
    a = this.ff(a, b, c, d, k[4], 7, -176418897);
    d = this.ff(d, a, b, c, k[5], 12, 1200080426);
    c = this.ff(c, d, a, b, k[6], 17, -1473231341);
    b = this.ff(b, c, d, a, k[7], 22, -45705983);
    a = this.ff(a, b, c, d, k[8], 7, 1770035416);
    d = this.ff(d, a, b, c, k[9], 12, -1958414417);
    c = this.ff(c, d, a, b, k[10], 17, -42063);
    b = this.ff(b, c, d, a, k[11], 22, -1990404162);
    a = this.ff(a, b, c, d, k[12], 7, 1804603682);
    d = this.ff(d, a, b, c, k[13], 12, -40341101);
    c = this.ff(c, d, a, b, k[14], 17, -1502002290);
    b = this.ff(b, c, d, a, k[15], 22, 1236535329);
    a = this.gg(a, b, c, d, k[1], 5, -165796510);
    d = this.gg(d, a, b, c, k[6], 9, -1069501632);
    c = this.gg(c, d, a, b, k[11], 14, 643717713);
    b = this.gg(b, c, d, a, k[0], 20, -373897302);
    a = this.gg(a, b, c, d, k[5], 5, -701558691);
    d = this.gg(d, a, b, c, k[10], 9, 38016083);
    c = this.gg(c, d, a, b, k[15], 14, -660478335);
    b = this.gg(b, c, d, a, k[4], 20, -405537848);
    a = this.gg(a, b, c, d, k[9], 5, 568446438);
    d = this.gg(d, a, b, c, k[14], 9, -1019803690);
    c = this.gg(c, d, a, b, k[3], 14, -187363961);
    b = this.gg(b, c, d, a, k[8], 20, 1163531501);
    a = this.gg(a, b, c, d, k[13], 5, -1444681467);
    d = this.gg(d, a, b, c, k[2], 9, -51403784);
    c = this.gg(c, d, a, b, k[7], 14, 1735328473);
    b = this.gg(b, c, d, a, k[12], 20, -1926607734);
    a = this.hh(a, b, c, d, k[5], 4, -378558);
    d = this.hh(d, a, b, c, k[8], 11, -2022574463);
    c = this.hh(c, d, a, b, k[11], 16, 1839030562);
    b = this.hh(b, c, d, a, k[14], 23, -35309556);
    a = this.hh(a, b, c, d, k[1], 4, -1530992060);
    d = this.hh(d, a, b, c, k[4], 11, 1272893353);
    c = this.hh(c, d, a, b, k[7], 16, -155497632);
    b = this.hh(b, c, d, a, k[10], 23, -1094730640);
    a = this.hh(a, b, c, d, k[13], 4, 681279174);
    d = this.hh(d, a, b, c, k[0], 11, -358537222);
    c = this.hh(c, d, a, b, k[3], 16, -722521979);
    b = this.hh(b, c, d, a, k[6], 23, 76029189);
    a = this.hh(a, b, c, d, k[9], 4, -640364487);
    d = this.hh(d, a, b, c, k[12], 11, -421815835);
    c = this.hh(c, d, a, b, k[15], 16, 530742520);
    b = this.hh(b, c, d, a, k[2], 23, -995338651);
    a = this.ii(a, b, c, d, k[0], 6, -198630844);
    d = this.ii(d, a, b, c, k[7], 10, 1126891415);
    c = this.ii(c, d, a, b, k[14], 15, -1416354905);
    b = this.ii(b, c, d, a, k[5], 21, -57434055);
    a = this.ii(a, b, c, d, k[12], 6, 1700485571);
    d = this.ii(d, a, b, c, k[3], 10, -1894986606);
    c = this.ii(c, d, a, b, k[10], 15, -1051523);
    b = this.ii(b, c, d, a, k[1], 21, -2054922799);
    a = this.ii(a, b, c, d, k[8], 6, 1873313359);
    d = this.ii(d, a, b, c, k[15], 10, -30611744);
    c = this.ii(c, d, a, b, k[6], 15, -1560198380);
    b = this.ii(b, c, d, a, k[13], 21, 1309151649);
    a = this.ii(a, b, c, d, k[4], 6, -145523070);
    d = this.ii(d, a, b, c, k[11], 10, -1120210379);
    c = this.ii(c, d, a, b, k[2], 15, 718787259);
    b = this.ii(b, c, d, a, k[9], 21, -343485551);
    x[0] = this.add32(a, x[0]);
    x[1] = this.add32(b, x[1]);
    x[2] = this.add32(c, x[2]);
    x[3] = this.add32(d, x[3]);
  }
  cmn(q, a, b, x, s, t) {
    a = this.add32(this.add32(a, q), this.add32(x, t));
    return this.add32(a << s | a >>> 32 - s, b);
  }
  ff(a, b, c, d, x, s, t) {
    return this.cmn(b & c | ~b & d, a, b, x, s, t);
  }
  gg(a, b, c, d, x, s, t) {
    return this.cmn(b & d | c & ~d, a, b, x, s, t);
  }
  hh(a, b, c, d, x, s, t) {
    return this.cmn(b ^ c ^ d, a, b, x, s, t);
  }
  ii(a, b, c, d, x, s, t) {
    return this.cmn(c ^ (b | ~d), a, b, x, s, t);
  }
  md51(s) {
    const n = s.length;
    const state = [1732584193, -271733879, -1732584194, 271733878];
    let i;
    for (i = 64; i <= s.length; i += 64) {
      this.md5cycle(state, this.md5blk(s.substring(i - 64, i)));
    }
    s = s.substring(i - 64);
    const tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (i = 0; i < s.length; i++) {
      tail[i >> 2] |= s.charCodeAt(i) << (i % 4 << 3);
    }
    tail[i >> 2] |= 128 << (i % 4 << 3);
    if (i > 55) {
      this.md5cycle(state, tail);
      for (i = 0; i < 16; i++) {
        tail[i] = 0;
      }
    }
    tail[14] = n * 8;
    this.md5cycle(state, tail);
    return state;
  }
  /* there needs to be support for Unicode here, * unless we pretend that we can redefine the MD-5
   * algorithm for multi-byte characters (perhaps by adding every four 16-bit characters and
   * shortening the sum to 32 bits). Otherwise I suthis.ggest performing MD-5 as if every character
   * was two bytes--e.g., 0040 0025 = @%--but then how will an ordinary MD-5 sum be matched?
   * There is no way to standardize text to something like UTF-8 before transformation; speed cost is
   * utterly prohibitive. The JavaScript standard itself needs to look at this: it should start
   * providing access to strings as preformed UTF-8 8-bit unsigned value arrays.
   */
  md5blk(s) {
    const md5blks = [];
    for (let i = 0; i < 64; i += 4) {
      md5blks[i >> 2] = s.charCodeAt(i) + (s.charCodeAt(i + 1) << 8) + (s.charCodeAt(i + 2) << 16) + (s.charCodeAt(i + 3) << 24);
    }
    return md5blks;
  }
  rhex(n) {
    const hex_chr = "0123456789abcdef".split("");
    let s = "", j = 0;
    for (; j < 4; j++) {
      s += hex_chr[n >> j * 8 + 4 & 15] + hex_chr[n >> j * 8 & 15];
    }
    return s;
  }
  hex(x) {
    for (let i = 0; i < x.length; i++) {
      x[i] = this.rhex(x[i]);
    }
    return x.join("");
  }
  md5(s) {
    return this.hex(this.md51(s));
  }
  add32(a, b) {
    return this.ieCompatibility ? this.add32Compat(a, b) : this.add32Std(a, b);
  }
  /* this function is much faster, so if possible we use it. Some IEs are the only ones I know of that
   need the idiotic second function, generated by an if clause.  */
  add32Std(a, b) {
    return a + b & 4294967295;
  }
  add32Compat(x, y) {
    const lsw = (x & 65535) + (y & 65535), msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return msw << 16 | lsw & 65535;
  }
};

// enterprise-modules/core/src/license/shared/licenseManager.ts
var LICENSE_TYPES = {
  "01": "GRID",
  "02": "CHARTS",
  "0102": "BOTH"
};
var LICENSING_HELP_URL = "https://ag-grid.com/licensing/";
var _LicenseManager = class _LicenseManager {
  constructor(document2) {
    this.watermarkMessage = void 0;
    this.totalMessageLength = 124;
    this.document = document2;
    this.md5 = new MD5();
    this.md5.init();
  }
  validateLicense() {
    const licenseDetails = this.getLicenseDetails(_LicenseManager.licenseKey);
    const currentLicenseName = `AG Grid ${licenseDetails.currentLicenseType === "BOTH" ? "and AG Charts " : ""}Enterprise`;
    const suppliedLicenseName = licenseDetails.suppliedLicenseType === void 0 ? "" : `AG ${licenseDetails.suppliedLicenseType === "BOTH" ? "Grid and AG Charts" : licenseDetails.suppliedLicenseType === "GRID" ? "Grid" : "Charts"} Enterprise`;
    if (licenseDetails.missing) {
      if (!this.isWebsiteUrl() || this.isForceWatermark()) {
        this.outputMissingLicenseKey(currentLicenseName);
      }
    } else if (licenseDetails.expired) {
      const gridReleaseDate = _LicenseManager.getGridReleaseDate();
      const formattedReleaseDate = _LicenseManager.formatDate(gridReleaseDate);
      this.outputExpiredKey(licenseDetails.expiry, formattedReleaseDate, currentLicenseName, suppliedLicenseName);
    } else if (!licenseDetails.valid) {
      this.outputInvalidLicenseKey(
        !!licenseDetails.incorrectLicenseType,
        currentLicenseName,
        suppliedLicenseName
      );
    } else if (licenseDetails.isTrial && licenseDetails.trialExpired) {
      this.outputExpiredTrialKey(licenseDetails.expiry, currentLicenseName, suppliedLicenseName);
    }
  }
  static extractExpiry(license) {
    const restrictionHashed = license.substring(license.lastIndexOf("_") + 1, license.length);
    return new Date(parseInt(_LicenseManager.decode(restrictionHashed), 10));
  }
  static extractLicenseComponents(licenseKey) {
    let cleanedLicenseKey = licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, "");
    cleanedLicenseKey = cleanedLicenseKey.replace(/\r?\n|\r/g, "");
    if (licenseKey.length <= 32) {
      return { md5: null, license: licenseKey, version: null, isTrial: null };
    }
    const hashStart = cleanedLicenseKey.length - 32;
    const md5 = cleanedLicenseKey.substring(hashStart);
    const license = cleanedLicenseKey.substring(0, hashStart);
    const [version, isTrial, type] = _LicenseManager.extractBracketedInformation(cleanedLicenseKey);
    return { md5, license, version, isTrial, type };
  }
  getLicenseDetails(licenseKey) {
    const currentLicenseType = _LicenseManager.chartsLicenseManager ? "BOTH" : "GRID";
    if (_missingOrEmpty(licenseKey)) {
      return {
        licenseKey,
        valid: false,
        missing: true,
        currentLicenseType
      };
    }
    const gridReleaseDate = _LicenseManager.getGridReleaseDate();
    const { md5, license, version, isTrial, type } = _LicenseManager.extractLicenseComponents(licenseKey);
    let valid = md5 === this.md5.md5(license) && licenseKey.indexOf("For_Trialing_ag-Grid_Only") === -1;
    let trialExpired = void 0;
    let expired = void 0;
    let expiry = null;
    let incorrectLicenseType = false;
    let suppliedLicenseType = void 0;
    function handleTrial() {
      const now = /* @__PURE__ */ new Date();
      trialExpired = expiry < now;
      expired = void 0;
    }
    if (valid) {
      expiry = _LicenseManager.extractExpiry(license);
      valid = !isNaN(expiry.getTime());
      if (valid) {
        expired = gridReleaseDate > expiry;
        switch (version) {
          case "legacy":
          case "2": {
            if (isTrial) {
              handleTrial();
            }
            break;
          }
          case "3": {
            if (_missingOrEmpty(type)) {
              valid = false;
            } else {
              suppliedLicenseType = type;
              if (type !== LICENSE_TYPES["01"] && type !== LICENSE_TYPES["0102"] || currentLicenseType === "BOTH" && suppliedLicenseType !== "BOTH") {
                valid = false;
                incorrectLicenseType = true;
              } else if (isTrial) {
                handleTrial();
              }
            }
          }
        }
      }
    }
    if (!valid) {
      return {
        licenseKey,
        valid,
        incorrectLicenseType,
        currentLicenseType,
        suppliedLicenseType
      };
    }
    return {
      licenseKey,
      valid,
      expiry: _LicenseManager.formatDate(expiry),
      expired,
      version,
      isTrial,
      trialExpired,
      incorrectLicenseType,
      currentLicenseType,
      suppliedLicenseType
    };
  }
  isDisplayWatermark() {
    return this.isForceWatermark() || !this.isLocalhost() && !this.isWebsiteUrl() && !_missingOrEmpty(this.watermarkMessage);
  }
  getWatermarkMessage() {
    return this.watermarkMessage || "";
  }
  getHostname() {
    const win = this.document.defaultView || window;
    const loc = win.location;
    const { hostname = "" } = loc;
    return hostname;
  }
  isForceWatermark() {
    const win = this.document.defaultView || window;
    const loc = win.location;
    const { pathname } = loc;
    return pathname ? pathname.indexOf("forceWatermark") !== -1 : false;
  }
  isWebsiteUrl() {
    const hostname = this.getHostname();
    return hostname.match(/^((?:[\w-]+\.)?ag-grid\.com)$/) !== null;
  }
  isLocalhost() {
    const hostname = this.getHostname();
    return hostname.match(/^(?:127\.0\.0\.1|localhost)$/) !== null;
  }
  static formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  }
  static getGridReleaseDate() {
    return new Date(parseInt(_LicenseManager.decode(_LicenseManager.RELEASE_INFORMATION), 10));
  }
  static decode(input) {
    const keystr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let t = "";
    let n, r, i;
    let s, o, u, a;
    let f = 0;
    const e = input.replace(/[^A-Za-z0-9+/=]/g, "");
    while (f < e.length) {
      s = keystr.indexOf(e.charAt(f++));
      o = keystr.indexOf(e.charAt(f++));
      u = keystr.indexOf(e.charAt(f++));
      a = keystr.indexOf(e.charAt(f++));
      n = s << 2 | o >> 4;
      r = (o & 15) << 4 | u >> 2;
      i = (u & 3) << 6 | a;
      t = t + String.fromCharCode(n);
      if (u != 64) {
        t = t + String.fromCharCode(r);
      }
      if (a != 64) {
        t = t + String.fromCharCode(i);
      }
    }
    t = _LicenseManager.utf8_decode(t);
    return t;
  }
  static utf8_decode(input) {
    input = input.replace(/rn/g, "n");
    let t = "";
    for (let n = 0; n < input.length; n++) {
      const r = input.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode(r >> 6 | 192);
        t += String.fromCharCode(r & 63 | 128);
      } else {
        t += String.fromCharCode(r >> 12 | 224);
        t += String.fromCharCode(r >> 6 & 63 | 128);
        t += String.fromCharCode(r & 63 | 128);
      }
    }
    return t;
  }
  static setChartsLicenseManager(dependantLicenseManager) {
    this.chartsLicenseManager = dependantLicenseManager;
  }
  static setLicenseKey(licenseKey) {
    this.licenseKey = licenseKey;
    if (this.chartsLicenseManager) {
      this.chartsLicenseManager.setLicenseKey(licenseKey, true);
    }
  }
  static extractBracketedInformation(licenseKey) {
    if (!licenseKey.includes("[")) {
      return ["legacy", false, void 0];
    }
    const matches = licenseKey.match(/\[(.*?)\]/g).map((match) => match.replace("[", "").replace("]", ""));
    if (!matches || matches.length === 0) {
      return ["legacy", false, void 0];
    }
    const isTrial = matches.filter((match) => match === "TRIAL").length === 1;
    const rawVersion = matches.filter((match) => match.indexOf("v") === 0)[0];
    const version = rawVersion ? rawVersion.replace("v", "") : "legacy";
    const type = LICENSE_TYPES[matches.filter((match) => LICENSE_TYPES[match])[0]];
    return [version, isTrial, type];
  }
  centerPadAndOutput(input) {
    const paddingRequired = this.totalMessageLength - input.length;
    console.error(input.padStart(paddingRequired / 2 + input.length, "*").padEnd(this.totalMessageLength, "*"));
  }
  padAndOutput(input, padding = "*", terminateWithPadding = "") {
    console.error(
      input.padEnd(this.totalMessageLength - terminateWithPadding.length, padding) + terminateWithPadding
    );
  }
  outputInvalidLicenseKey(incorrectLicenseType, currentLicenseName, suppliedLicenseName) {
    if (incorrectLicenseType) {
      this.centerPadAndOutput("");
      this.centerPadAndOutput(` ${currentLicenseName} License `);
      this.centerPadAndOutput(" Incompatible License Key ");
      this.padAndOutput(
        `* Your license key is for ${suppliedLicenseName} only and does not cover you for ${currentLicenseName}.`,
        " ",
        "*"
      );
      this.padAndOutput(`* To troubleshoot your license key visit ${LICENSING_HELP_URL}.`, " ", "*");
      this.centerPadAndOutput("");
      this.centerPadAndOutput("");
    } else {
      this.centerPadAndOutput("");
      this.centerPadAndOutput(` ${currentLicenseName} License `);
      this.centerPadAndOutput(" Invalid License Key ");
      this.padAndOutput(`* Your license key is not valid.`, " ", "*");
      this.padAndOutput(`* To troubleshoot your license key visit ${LICENSING_HELP_URL}.`, " ", "*");
      this.centerPadAndOutput("");
      this.centerPadAndOutput("");
    }
    this.watermarkMessage = "Invalid License";
  }
  outputExpiredTrialKey(formattedExpiryDate, currentLicenseName, suppliedLicenseName) {
    this.centerPadAndOutput("");
    this.centerPadAndOutput(` ${currentLicenseName} License `);
    this.centerPadAndOutput(" Trial Period Expired. ");
    this.padAndOutput(
      `* Your trial only license for ${suppliedLicenseName} expired on ${formattedExpiryDate}.`,
      " ",
      "*"
    );
    this.padAndOutput("* Please email info@ag-grid.com to purchase a license.", " ", "*");
    this.centerPadAndOutput("");
    this.centerPadAndOutput("");
    this.watermarkMessage = "Trial Period Expired";
  }
  outputMissingLicenseKey(currentLicenseName) {
    this.centerPadAndOutput("");
    this.centerPadAndOutput(` ${currentLicenseName} License `);
    this.centerPadAndOutput(" License Key Not Found ");
    this.padAndOutput(`* All ${currentLicenseName} features are unlocked for trial.`, " ", "*");
    this.padAndOutput(
      "* If you want to hide the watermark please email info@ag-grid.com for a trial license key.",
      " ",
      "*"
    );
    this.centerPadAndOutput("");
    this.centerPadAndOutput("");
    this.watermarkMessage = "For Trial Use Only";
  }
  outputExpiredKey(formattedExpiryDate, formattedReleaseDate, currentLicenseName, suppliedLicenseName) {
    this.centerPadAndOutput("");
    this.centerPadAndOutput(` ${currentLicenseName} License `);
    this.centerPadAndOutput(" Incompatible Software Version ");
    this.padAndOutput(
      `* Your license key works with versions of ${suppliedLicenseName} released before ${formattedExpiryDate}.`,
      " ",
      "*"
    );
    this.padAndOutput(`* The version you are trying to use was released on ${formattedReleaseDate}.`, " ", "*");
    this.padAndOutput("* Please contact info@ag-grid.com to renew your license key.", " ", "*");
    this.centerPadAndOutput("");
    this.centerPadAndOutput("");
    this.watermarkMessage = "License Expired";
  }
};
_LicenseManager.RELEASE_INFORMATION = "MTcyNzA3NDUwMzgwNQ==";
var LicenseManager = _LicenseManager;

// enterprise-modules/core/src/license/watermark.ts
import { Component, RefPlaceholder } from "ag-grid-community";
var AgWatermark = class extends Component {
  constructor() {
    super(
      /* html*/
      `<div class="ag-watermark">
                <div data-ref="eLicenseTextRef" class="ag-watermark-text"></div>
            </div>`
    );
    this.eLicenseTextRef = RefPlaceholder;
  }
  wireBeans(beans) {
    this.licenseManager = beans.licenseManager;
  }
  postConstruct() {
    const show = this.shouldDisplayWatermark();
    this.setDisplayed(show);
    if (show) {
      this.eLicenseTextRef.innerText = this.licenseManager.getWatermarkMessage();
      window.setTimeout(() => this.addCssClass("ag-opacity-zero"), 0);
      window.setTimeout(() => this.setDisplayed(false), 5e3);
    }
  }
  shouldDisplayWatermark() {
    return this.licenseManager.isDisplayWatermark();
  }
};
var AgWatermarkSelector = {
  selector: "AG-WATERMARK",
  component: AgWatermark
};

// enterprise-modules/core/src/license/gridLicenseManager.ts
var GridLicenseManager = class extends BeanStub {
  constructor() {
    super(...arguments);
    this.beanName = "licenseManager";
  }
  postConstruct() {
    this.validateLicense();
  }
  validateLicense() {
    this.licenseManager = new LicenseManager(_getDocument(this.gos));
    this.licenseManager.validateLicense();
  }
  static getLicenseDetails(licenseKey) {
    return new LicenseManager(null).getLicenseDetails(licenseKey);
  }
  getWatermarkSelector() {
    return AgWatermarkSelector;
  }
  isDisplayWatermark() {
    return this.licenseManager.isDisplayWatermark();
  }
  getWatermarkMessage() {
    return this.licenseManager.getWatermarkMessage();
  }
  static setLicenseKey(licenseKey) {
    LicenseManager.setLicenseKey(licenseKey);
  }
  static setChartsLicenseManager(chartsLicenseManager) {
    LicenseManager.setChartsLicenseManager(chartsLicenseManager);
  }
};

// enterprise-modules/core/src/version.ts
var VERSION = "32.3.0";

// enterprise-modules/core/src/widgets/agMenuItemRenderer.ts
import {
  Component as Component2,
  _createIconNoSpan,
  _isNodeOrElement,
  _loadTemplate,
  _setAriaExpanded,
  _warnOnce
} from "ag-grid-community";
var AgMenuItemRenderer = class extends Component2 {
  constructor() {
    super();
    this.setTemplate(
      /* html */
      `<div></div>`
    );
  }
  init(params) {
    this.params = params;
    this.cssClassPrefix = this.params.cssClassPrefix ?? "ag-menu-option";
    this.addIcon();
    this.addName();
    this.addShortcut();
    this.addSubMenu();
  }
  configureDefaults() {
    return true;
  }
  addIcon() {
    if (this.params.isCompact) {
      return;
    }
    const iconWrapper = _loadTemplate(
      /* html */
      `<span data-ref="eIcon" class="${this.getClassName("part")} ${this.getClassName("icon")}" role="presentation"></span>`
    );
    const { checked, icon } = this.params;
    if (checked) {
      iconWrapper.appendChild(_createIconNoSpan("check", this.gos));
    } else if (icon) {
      if (_isNodeOrElement(icon)) {
        iconWrapper.appendChild(icon);
      } else if (typeof icon === "string") {
        iconWrapper.innerHTML = icon;
      } else {
        _warnOnce("menu item icon must be DOM node or string");
      }
    }
    this.getGui().appendChild(iconWrapper);
  }
  addName() {
    const name = _loadTemplate(
      /* html */
      `<span data-ref="eName" class="${this.getClassName("part")} ${this.getClassName("text")}">${this.params.name || ""}</span>`
    );
    this.getGui().appendChild(name);
  }
  addShortcut() {
    if (this.params.isCompact) {
      return;
    }
    const shortcut = _loadTemplate(
      /* html */
      `<span data-ref="eShortcut" class="${this.getClassName("part")} ${this.getClassName("shortcut")}">${this.params.shortcut || ""}</span>`
    );
    this.getGui().appendChild(shortcut);
  }
  addSubMenu() {
    const pointer = _loadTemplate(
      /* html */
      `<span data-ref="ePopupPointer" class="${this.getClassName("part")} ${this.getClassName("popup-pointer")}"></span>`
    );
    const eGui = this.getGui();
    if (this.params.subMenu) {
      const iconName = this.gos.get("enableRtl") ? "smallLeft" : "smallRight";
      _setAriaExpanded(eGui, false);
      pointer.appendChild(_createIconNoSpan(iconName, this.gos));
    }
    eGui.appendChild(pointer);
  }
  getClassName(suffix) {
    return `${this.cssClassPrefix}-${suffix}`;
  }
  destroy() {
    super.destroy();
  }
};

// enterprise-modules/core/src/agGridEnterpriseModule.ts
var EnterpriseCoreModule = _defineModule({
  version: VERSION,
  moduleName: ModuleNames.EnterpriseCoreModule,
  beans: [GridLicenseManager],
  userComponents: [
    {
      name: "agMenuItem",
      classImp: AgMenuItemRenderer
    }
  ]
});

// enterprise-modules/core/src/widgets/agGroupComponent.ts
import {
  AgCheckboxSelector,
  AgToggleButton,
  Component as Component3,
  KeyCode,
  RefPlaceholder as RefPlaceholder2,
  _createIcon,
  _setAriaExpanded as _setAriaExpanded2,
  _setDisplayed
} from "ag-grid-community";
function getAgGroupComponentTemplate(params) {
  const cssIdentifier = params.cssIdentifier || "default";
  const direction = params.direction || "vertical";
  return (
    /* html */
    `
        <div class="ag-group ag-${cssIdentifier}-group" role="presentation">
            <div data-ref="eToolbar" class="ag-group-toolbar ag-${cssIdentifier}-group-toolbar">
                <ag-checkbox data-ref="cbGroupEnabled"></ag-checkbox>
            </div>
            <div data-ref="eContainer" class="ag-group-container ag-group-container-${direction} ag-${cssIdentifier}-group-container"></div>
        </div>
    `
  );
}
var AgGroupComponent = class extends Component3 {
  constructor(params = {}) {
    super(getAgGroupComponentTemplate(params), [AgCheckboxSelector]);
    this.params = params;
    this.suppressEnabledCheckbox = true;
    this.suppressToggleExpandOnEnableChange = false;
    this.eToolbar = RefPlaceholder2;
    this.cbGroupEnabled = RefPlaceholder2;
    this.eContainer = RefPlaceholder2;
    const {
      enabled,
      items,
      suppressEnabledCheckbox,
      expanded,
      suppressToggleExpandOnEnableChange,
      useToggle: toggleMode
    } = params;
    this.cssIdentifier = params.cssIdentifier || "default";
    this.enabled = enabled != null ? enabled : true;
    this.items = items || [];
    this.useToggle = toggleMode ?? false;
    this.alignItems = params.alignItems || "center";
    this.expanded = expanded == null ? true : expanded;
    if (suppressEnabledCheckbox != null) {
      this.suppressEnabledCheckbox = suppressEnabledCheckbox;
    }
    if (suppressToggleExpandOnEnableChange != null) {
      this.suppressToggleExpandOnEnableChange = suppressToggleExpandOnEnableChange;
    }
  }
  postConstruct() {
    this.setupTitleBar();
    if (this.items.length) {
      const initialItems = this.items;
      this.items = [];
      this.addItems(initialItems);
    }
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.cbGroupEnabled.setLabel(localeTextFunc("enabled", "Enabled"));
    if (this.enabled) {
      this.setEnabled(this.enabled, void 0, true);
    }
    this.setAlignItems(this.alignItems);
    const { onEnableChange, onExpandedChange, suppressOpenCloseIcons } = this.params;
    this.hideEnabledCheckbox(this.suppressEnabledCheckbox);
    this.hideOpenCloseIcons(suppressOpenCloseIcons ?? false);
    this.refreshChildDisplay();
    _setDisplayed(this.eContainer, this.expanded);
    this.cbGroupEnabled.onValueChange((newSelection) => {
      this.setEnabled(newSelection, true, this.suppressToggleExpandOnEnableChange);
      this.dispatchEnableChangeEvent(newSelection);
    });
    if (onEnableChange != null) {
      this.onEnableChange(onEnableChange);
    }
    if (onExpandedChange != null) {
      this.onExpandedChange(onExpandedChange);
    }
  }
  refreshChildDisplay() {
    _setDisplayed(this.eToolbar, this.expanded && !this.suppressEnabledCheckbox);
    this.eTitleBar?.refreshOnExpand(this.expanded);
  }
  isExpanded() {
    return this.expanded;
  }
  setAlignItems(alignment) {
    if (this.alignItems !== alignment) {
      this.removeCssClass(`ag-group-item-alignment-${this.alignItems}`);
    }
    this.alignItems = alignment;
    const newCls = `ag-group-item-alignment-${this.alignItems}`;
    this.addCssClass(newCls);
    return this;
  }
  toggleGroupExpand(expanded, silent) {
    if (this.eTitleBar?.isSuppressCollapse() && !this.useToggle) {
      expanded = true;
      silent = true;
    } else {
      expanded = expanded != null ? expanded : !this.expanded;
      if (this.expanded === expanded) {
        return this;
      }
    }
    this.expanded = expanded;
    this.refreshChildDisplay();
    _setDisplayed(this.eContainer, expanded);
    if (!silent) {
      this.dispatchLocalEvent({
        type: expanded ? "expanded" : "collapsed"
      });
    }
    return this;
  }
  addItems(items) {
    items.forEach((item) => this.addItem(item));
  }
  prependItem(item) {
    this.insertItem(item, true);
  }
  addItem(item) {
    this.insertItem(item, false);
  }
  insertItem(item, prepend) {
    const container = this.eContainer;
    const el = item instanceof Component3 ? item.getGui() : item;
    el.classList.add("ag-group-item", `ag-${this.cssIdentifier}-group-item`);
    if (prepend) {
      container.insertAdjacentElement("afterbegin", el);
      this.items.unshift(el);
    } else {
      container.appendChild(el);
      this.items.push(el);
    }
  }
  hideItem(hide, index) {
    const itemToHide = this.items[index];
    _setDisplayed(itemToHide, !hide);
  }
  getItemIndex(item) {
    const el = item instanceof Component3 ? item.getGui() : item;
    return this.items.indexOf(el);
  }
  setTitle(title) {
    this.eTitleBar?.setTitle(title);
    return this;
  }
  addTitleBarWidget(el) {
    this.eTitleBar?.addWidget(el);
    return this;
  }
  addCssClassToTitleBar(cssClass) {
    this.eTitleBar?.addCssClass(cssClass);
  }
  dispatchEnableChangeEvent(enabled) {
    const event = {
      type: "enableChange",
      enabled
    };
    this.dispatchLocalEvent(event);
  }
  setEnabled(enabled, skipToggle, skipExpand) {
    this.enabled = enabled;
    this.refreshDisabledStyles();
    if (!skipExpand) {
      this.toggleGroupExpand(enabled);
    }
    if (!skipToggle) {
      this.cbGroupEnabled.setValue(enabled);
      this.eToggle?.setValue(enabled);
    }
    return this;
  }
  isEnabled() {
    return this.enabled;
  }
  onEnableChange(callbackFn) {
    this.addManagedListeners(this, { enableChange: (event) => callbackFn(event.enabled) });
    return this;
  }
  onExpandedChange(callbackFn) {
    this.addManagedListeners(this, {
      expanded: () => callbackFn(true),
      collapsed: () => callbackFn(false)
    });
    return this;
  }
  hideEnabledCheckbox(hide) {
    this.suppressEnabledCheckbox = hide;
    this.refreshChildDisplay();
    this.refreshDisabledStyles();
    return this;
  }
  hideOpenCloseIcons(hide) {
    this.eTitleBar?.hideOpenCloseIcons(hide);
    return this;
  }
  refreshDisabledStyles() {
    const disabled = !this.enabled;
    this.eContainer.classList.toggle("ag-disabled", disabled);
    this.eTitleBar?.refreshDisabledStyles(this.suppressEnabledCheckbox && disabled);
    this.eContainer.classList.toggle("ag-disabled-group-container", disabled);
  }
  setupTitleBar() {
    const titleBar = this.useToggle ? this.createToggleTitleBar() : this.createDefaultTitleBar();
    this.eToolbar.insertAdjacentElement("beforebegin", titleBar.getGui());
  }
  createDefaultTitleBar() {
    const titleBar = this.createManagedBean(new DefaultTitleBar(this.params));
    this.eTitleBar = titleBar;
    titleBar.refreshOnExpand(this.expanded);
    this.addManagedListeners(titleBar, {
      expandedChanged: (event) => this.toggleGroupExpand(event.expanded)
    });
    return titleBar;
  }
  createToggleTitleBar() {
    const eToggle = this.createManagedBean(
      new AgToggleButton({
        value: this.enabled,
        label: this.params.title,
        labelAlignment: "left",
        labelWidth: "flex",
        onValueChange: (enabled) => {
          this.setEnabled(enabled, true);
          this.dispatchEnableChangeEvent(enabled);
        }
      })
    );
    eToggle.addCssClass("ag-group-title-bar");
    eToggle.addCssClass(`ag-${this.params.cssIdentifier ?? "default"}-group-title-bar ag-unselectable`);
    this.eToggle = eToggle;
    this.toggleGroupExpand(this.enabled);
    return eToggle;
  }
};
var TITLE_BAR_DISABLED_CLASS = "ag-disabled-group-title-bar";
function getDefaultTitleBarTemplate(params) {
  const cssIdentifier = params.cssIdentifier ?? "default";
  const role = params.suppressKeyboardNavigation ? "presentation" : "role";
  return (
    /* html */
    `
        <div class="ag-group-title-bar ag-${cssIdentifier}-group-title-bar ag-unselectable" role="${role}">
            <span class="ag-group-title-bar-icon ag-${cssIdentifier}-group-title-bar-icon" data-ref="eGroupOpenedIcon" role="presentation"></span>
            <span class="ag-group-title-bar-icon ag-${cssIdentifier}-group-title-bar-icon" data-ref="eGroupClosedIcon" role="presentation"></span>
            <span data-ref="eTitle" class="ag-group-title ag-${cssIdentifier}-group-title"></span>
        </div>
    `
  );
}
var DefaultTitleBar = class extends Component3 {
  constructor(params = {}) {
    super(getDefaultTitleBarTemplate(params));
    this.suppressOpenCloseIcons = false;
    this.suppressKeyboardNavigation = false;
    this.eGroupOpenedIcon = RefPlaceholder2;
    this.eGroupClosedIcon = RefPlaceholder2;
    this.eTitle = RefPlaceholder2;
    const { title, suppressOpenCloseIcons, suppressKeyboardNavigation } = params;
    if (!!title && title.length > 0) {
      this.title = title;
    }
    if (suppressOpenCloseIcons != null) {
      this.suppressOpenCloseIcons = suppressOpenCloseIcons;
    }
    this.suppressKeyboardNavigation = suppressKeyboardNavigation ?? false;
  }
  postConstruct() {
    this.setTitle(this.title);
    this.hideOpenCloseIcons(this.suppressOpenCloseIcons);
    this.setupExpandContract();
  }
  setupExpandContract() {
    this.eGroupClosedIcon.appendChild(_createIcon("columnSelectClosed", this.gos, null));
    this.eGroupOpenedIcon.appendChild(_createIcon("columnSelectOpen", this.gos, null));
    this.addManagedElementListeners(this.getGui(), {
      click: () => this.dispatchExpandChanged(),
      keydown: (e) => {
        switch (e.key) {
          case KeyCode.ENTER:
          case KeyCode.SPACE:
            e.preventDefault();
            this.dispatchExpandChanged();
            break;
          case KeyCode.RIGHT:
          case KeyCode.LEFT:
            e.preventDefault();
            this.dispatchExpandChanged(e.key === KeyCode.RIGHT);
            break;
        }
      }
    });
  }
  refreshOnExpand(expanded) {
    this.refreshAriaStatus(expanded);
    this.refreshOpenCloseIcons(expanded);
  }
  refreshAriaStatus(expanded) {
    if (!this.suppressOpenCloseIcons) {
      _setAriaExpanded2(this.getGui(), expanded);
    }
  }
  refreshOpenCloseIcons(expanded) {
    const showIcon = !this.suppressOpenCloseIcons;
    _setDisplayed(this.eGroupOpenedIcon, showIcon && expanded);
    _setDisplayed(this.eGroupClosedIcon, showIcon && !expanded);
  }
  isSuppressCollapse() {
    return this.suppressOpenCloseIcons;
  }
  dispatchExpandChanged(expanded) {
    const event = {
      type: "expandedChanged",
      expanded
    };
    this.dispatchLocalEvent(event);
  }
  setTitle(title) {
    const eGui = this.getGui();
    const hasTitle = !!title && title.length > 0;
    title = hasTitle ? title : void 0;
    this.eTitle.textContent = title ?? "";
    _setDisplayed(eGui, hasTitle);
    if (title !== this.title) {
      this.title = title;
    }
    const disabled = eGui.classList.contains(TITLE_BAR_DISABLED_CLASS);
    this.refreshDisabledStyles(disabled);
    return this;
  }
  addWidget(el) {
    this.getGui().appendChild(el);
    return this;
  }
  hideOpenCloseIcons(hide) {
    this.suppressOpenCloseIcons = hide;
    if (hide) {
      this.dispatchExpandChanged(true);
    }
    return this;
  }
  refreshDisabledStyles(disabled) {
    const eGui = this.getGui();
    if (disabled) {
      eGui.classList.add(TITLE_BAR_DISABLED_CLASS);
      eGui.removeAttribute("tabindex");
    } else {
      eGui.classList.remove(TITLE_BAR_DISABLED_CLASS);
      if (typeof this.title === "string" && !this.suppressKeyboardNavigation) {
        eGui.setAttribute("tabindex", "0");
      } else {
        eGui.removeAttribute("tabindex");
      }
    }
  }
};
var AgGroupComponentSelector = {
  selector: "AG-GROUP-COMPONENT",
  component: AgGroupComponent
};

// enterprise-modules/core/src/widgets/agRichSelect.ts
import {
  AgInputTextFieldSelector,
  AgPickerField,
  KeyCode as KeyCode5,
  RefPlaceholder as RefPlaceholder5,
  _bindCellRendererToHtmlElement as _bindCellRendererToHtmlElement2,
  _clearElement as _clearElement2,
  _createIconNoSpan as _createIconNoSpan2,
  _debounce,
  _escapeString as _escapeString2,
  _exists as _exists2,
  _fuzzySuggestions,
  _getActiveDomElement as _getActiveDomElement2,
  _isEventFromPrintableCharacter,
  _isVisible,
  _setAriaActiveDescendant as _setAriaActiveDescendant2,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid2
} from "ag-grid-community";

// enterprise-modules/core/src/widgets/AgPillContainer.ts
import {
  Component as Component5,
  KeyCode as KeyCode2,
  _clearElement,
  _getActiveDomElement,
  _getDocument as _getDocument2,
  _setAriaPosInSet,
  _setAriaSetSize
} from "ag-grid-community";

// enterprise-modules/core/src/widgets/agPill.ts
import { RefPlaceholder as RefPlaceholder3 } from "ag-grid-community";
import { Component as Component4 } from "ag-grid-community";
var AgPill = class extends Component4 {
  constructor(config) {
    super(
      /* html */
      `
            <div class="ag-pill" role="option">
                <span class="ag-pill-text" data-ref="eText"></span>
                <span class="ag-button ag-pill-button" data-ref="eButton" role="presentation"></span>
            </div>
        `
    );
    this.config = config;
    this.eText = RefPlaceholder3;
    this.eButton = RefPlaceholder3;
  }
  postConstruct() {
    const { config, eButton } = this;
    const { onKeyDown, onButtonClick } = config;
    this.getGui().setAttribute("tabindex", String(this.gos.get("tabIndex")));
    this.addGuiEventListener("focus", () => {
      this.eButton.focus();
    });
    if (onKeyDown) {
      this.addGuiEventListener("keydown", onKeyDown);
    }
    if (onButtonClick) {
      this.addManagedElementListeners(eButton, {
        click: onButtonClick
      });
    }
  }
  toggleCloseButtonClass(className, force) {
    this.eButton.classList.toggle(className, force);
  }
  setText(text) {
    this.eText.textContent = text;
  }
  getText() {
    return this.eText.textContent;
  }
};

// enterprise-modules/core/src/widgets/AgPillContainer.ts
var AgPillContainer = class extends Component5 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-pill-container" role="listbox"></div>
            `
    );
    this.pills = [];
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
  }
  init(params) {
    this.params = params;
    this.refresh();
  }
  refresh() {
    this.clearPills();
    const { params, onPillKeyDown } = this;
    const values = params.getValue();
    if (!Array.isArray(values)) {
      return;
    }
    const len = values.length;
    for (let i = 0; i < len; i++) {
      const value = values[i];
      const pill = this.createBean(
        new AgPill({
          onButtonClick: () => this.onPillButtonClick(pill),
          onKeyDown: onPillKeyDown.bind(this)
        })
      );
      const pillGui = pill.getGui();
      _setAriaPosInSet(pillGui, i + 1);
      _setAriaSetSize(pillGui, len);
      if (params.onPillMouseDown) {
        pill.addGuiEventListener("mousedown", params.onPillMouseDown);
      }
      if (params.announceItemFocus) {
        pill.addGuiEventListener("focus", params.announceItemFocus);
      }
      pill.setText(value);
      pill.toggleCloseButtonClass("ag-icon-cancel", true);
      this.appendChild(pillGui);
      this.pills.push(pill);
    }
  }
  onNavigationKeyDown(e) {
    const { key } = e;
    if (!this.pills.length || key !== KeyCode2.LEFT && key !== KeyCode2.RIGHT) {
      return;
    }
    e.preventDefault();
    const activeEl = _getActiveDomElement(this.gos);
    const eGui = this.getGui();
    const { params, focusService } = this;
    if (eGui.contains(activeEl)) {
      const nextFocusableEl = focusService.findNextFocusableElement(eGui, false, key === KeyCode2.LEFT);
      if (nextFocusableEl) {
        nextFocusableEl.focus();
      } else if (params.eWrapper) {
        params.eWrapper.focus();
      }
    } else {
      const focusableElements = focusService.findFocusableElements(eGui);
      if (focusableElements.length > 0) {
        focusableElements[key === KeyCode2.RIGHT ? 0 : focusableElements.length - 1].focus();
      }
    }
  }
  clearPills() {
    const eGui = this.getGui();
    if (eGui.contains(_getActiveDomElement(this.gos)) && this.params.eWrapper) {
      this.params.eWrapper.focus();
    }
    _clearElement(eGui);
    this.destroyBeans(this.pills);
    this.pills = [];
  }
  onPillButtonClick(pill) {
    this.deletePill(pill);
  }
  onPillKeyDown(e) {
    const key = e.key;
    if (key !== KeyCode2.DELETE && key !== KeyCode2.BACKSPACE) {
      return;
    }
    e.preventDefault();
    const eDoc = _getDocument2(this.gos);
    const pillIndex = this.pills.findIndex((pill2) => pill2.getGui().contains(eDoc.activeElement));
    if (pillIndex === -1) {
      return;
    }
    const pill = this.pills[pillIndex];
    if (pill) {
      this.deletePill(pill, pillIndex);
    }
  }
  deletePill(pill, restoreFocusToIndex) {
    const value = pill.getText();
    const values = (this.params.getValue() || []).filter((val) => val !== value);
    this.params.setValue(values);
    if (!values.length && this.params.eWrapper) {
      this.params.eWrapper.focus();
    } else if (restoreFocusToIndex != null) {
      const pill2 = this.pills[Math.min(restoreFocusToIndex, this.pills.length - 1)];
      if (pill2) {
        pill2.getFocusableElement().focus();
      }
    }
  }
  destroy() {
    this.clearPills();
    super.destroy();
  }
};

// enterprise-modules/core/src/widgets/agRichSelectList.ts
import {
  KeyCode as KeyCode4,
  _getDocument as _getDocument4,
  _setAriaActiveDescendant,
  _setAriaControls,
  _setAriaLabel as _setAriaLabel2
} from "ag-grid-community";

// enterprise-modules/core/src/widgets/agRichSelectRow.ts
import {
  Component as Component6,
  _bindCellRendererToHtmlElement,
  _escapeString,
  _exists,
  _getDocument as _getDocument3,
  _setAriaSelected
} from "ag-grid-community";
var RichSelectRow = class extends Component6 {
  constructor(params) {
    super(
      /* html */
      `<div class="ag-rich-select-row" role="presentation"></div>`
    );
    this.params = params;
  }
  wireBeans(beans) {
    this.userComponentFactory = beans.userComponentFactory;
  }
  setState(value) {
    let formattedValue = "";
    const { params } = this;
    if (params.valueFormatter) {
      formattedValue = params.valueFormatter(value);
    }
    const rendererSuccessful = this.populateWithRenderer(value, formattedValue);
    if (!rendererSuccessful) {
      this.populateWithoutRenderer(value, formattedValue);
    }
    this.value = value;
  }
  highlightString(matchString) {
    const { parsedValue } = this;
    if (this.params.cellRenderer || !_exists(parsedValue)) {
      return;
    }
    let hasMatch = _exists(matchString);
    if (hasMatch) {
      const index = parsedValue?.toLocaleLowerCase().indexOf(matchString.toLocaleLowerCase());
      if (index >= 0) {
        const highlightEndIndex = index + matchString.length;
        const startPart = _escapeString(parsedValue.slice(0, index), true);
        const highlightedPart = _escapeString(parsedValue.slice(index, highlightEndIndex), true);
        const endPart = _escapeString(parsedValue.slice(highlightEndIndex));
        this.renderValueWithoutRenderer(
          /* html */
          `${startPart}<span class="ag-rich-select-row-text-highlight">${highlightedPart}</span>${endPart}`
        );
      } else {
        hasMatch = false;
      }
    }
    if (!hasMatch) {
      this.renderValueWithoutRenderer(parsedValue);
    }
  }
  updateSelected(selected) {
    const eGui = this.getGui();
    _setAriaSelected(eGui.parentElement, selected);
    this.addOrRemoveCssClass("ag-rich-select-row-selected", selected);
  }
  getValue() {
    return this.value;
  }
  toggleHighlighted(highlighted) {
    this.addOrRemoveCssClass("ag-rich-select-row-highlighted", highlighted);
  }
  populateWithoutRenderer(value, valueFormatted) {
    const eDocument = _getDocument3(this.gos);
    const eGui = this.getGui();
    const span = eDocument.createElement("span");
    span.style.overflow = "hidden";
    span.style.textOverflow = "ellipsis";
    const parsedValue = _escapeString(_exists(valueFormatted) ? valueFormatted : value, true);
    this.parsedValue = _exists(parsedValue) ? parsedValue : null;
    eGui.appendChild(span);
    this.renderValueWithoutRenderer(parsedValue);
    this.setTooltip({
      newTooltipText: this.parsedValue,
      shouldDisplayTooltip: () => span.scrollWidth > span.clientWidth
    });
  }
  renderValueWithoutRenderer(value) {
    const span = this.getGui().querySelector("span");
    if (!span) {
      return;
    }
    span.innerHTML = _exists(value) ? value : "&nbsp;";
  }
  populateWithRenderer(value, valueFormatted) {
    let cellRendererPromise;
    let userCompDetails;
    if (this.params.cellRenderer) {
      const richSelect = this.getParentComponent()?.getParentComponent();
      userCompDetails = this.userComponentFactory.getEditorRendererDetails(this.params, {
        value,
        valueFormatted,
        getValue: () => richSelect?.getValue(),
        setValue: (value2) => {
          richSelect?.setValue(value2, true);
        },
        setTooltip: (value2, shouldDisplayTooltip) => {
          this.setTooltip({ newTooltipText: value2, shouldDisplayTooltip });
        }
      });
    }
    if (userCompDetails) {
      cellRendererPromise = userCompDetails.newAgStackInstance();
    }
    if (cellRendererPromise) {
      _bindCellRendererToHtmlElement(cellRendererPromise, this.getGui());
    }
    if (cellRendererPromise) {
      cellRendererPromise.then((childComponent) => {
        this.addDestroyFunc(() => {
          this.destroyBean(childComponent);
        });
      });
      return true;
    }
    return false;
  }
};

// enterprise-modules/core/src/widgets/virtualList.ts
import {
  KeyCode as KeyCode3,
  RefPlaceholder as RefPlaceholder4,
  TabGuardComp,
  _getAriaPosInSet,
  _setAriaLabel,
  _setAriaPosInSet as _setAriaPosInSet2,
  _setAriaRole,
  _setAriaSetSize as _setAriaSetSize2,
  _stopPropagationForAgGrid,
  _waitUntil,
  _warnOnce as _warnOnce2
} from "ag-grid-community";
function getVirtualListTemplate(cssIdentifier) {
  return (
    /* html */
    `<div class="ag-virtual-list-viewport ag-${cssIdentifier}-virtual-list-viewport" role="presentation">
            <div class="ag-virtual-list-container ag-${cssIdentifier}-virtual-list-container" data-ref="eContainer"></div>
        </div>`
  );
}
var VirtualList = class extends TabGuardComp {
  constructor(params) {
    super(getVirtualListTemplate(params?.cssIdentifier || "default"));
    this.renderedRows = /* @__PURE__ */ new Map();
    this.rowHeight = 20;
    this.pageSize = -1;
    this.isScrolling = false;
    this.isHeightFromTheme = true;
    this.eContainer = RefPlaceholder4;
    this.awaitStableCallbacks = [];
    const { cssIdentifier = "default", ariaRole = "listbox", listName } = params || {};
    this.cssIdentifier = cssIdentifier;
    this.ariaRole = ariaRole;
    this.listName = listName;
  }
  wireBeans(beans) {
    this.resizeObserverService = beans.resizeObserverService;
    this.animationFrameService = beans.animationFrameService;
    this.environment = beans.environment;
  }
  postConstruct() {
    this.addScrollListener();
    this.rowHeight = this.getItemHeight();
    this.addResizeObserver();
    this.initialiseTabGuard({
      onFocusIn: (e) => this.onFocusIn(e),
      onFocusOut: (e) => this.onFocusOut(e),
      focusInnerElement: (fromBottom) => this.focusInnerElement(fromBottom),
      onTabKeyDown: (e) => this.onTabKeyDown(e),
      handleKeyDown: (e) => this.handleKeyDown(e)
    });
    this.setAriaProperties();
    this.addManagedEventListeners({ gridStylesChanged: this.onGridStylesChanged.bind(this) });
  }
  onGridStylesChanged(e) {
    if (e.listItemHeightChanged) {
      this.rowHeight = this.getItemHeight();
      this.refresh();
    }
  }
  setAriaProperties() {
    const translate = this.localeService.getLocaleTextFunc();
    const listName = translate("ariaDefaultListName", this.listName || "List");
    const ariaEl = this.eContainer;
    _setAriaRole(ariaEl, this.ariaRole);
    _setAriaLabel(ariaEl, listName);
  }
  addResizeObserver() {
    const listener = () => this.animationFrameService.requestAnimationFrame(() => this.drawVirtualRows());
    const destroyObserver = this.resizeObserverService.observeResize(this.getGui(), listener);
    this.addDestroyFunc(destroyObserver);
  }
  focusInnerElement(fromBottom) {
    this.focusRow(fromBottom ? this.model.getRowCount() - 1 : 0);
  }
  onFocusIn(e) {
    const target = e.target;
    if (target.classList.contains("ag-virtual-list-item")) {
      this.lastFocusedRowIndex = _getAriaPosInSet(target) - 1;
    }
  }
  onFocusOut(e) {
    if (!this.getFocusableElement().contains(e.relatedTarget)) {
      this.lastFocusedRowIndex = null;
    }
  }
  handleKeyDown(e) {
    switch (e.key) {
      case KeyCode3.UP:
      case KeyCode3.DOWN:
        if (this.navigate(e.key === KeyCode3.UP)) {
          e.preventDefault();
        }
        break;
      case KeyCode3.PAGE_HOME:
      case KeyCode3.PAGE_END:
      case KeyCode3.PAGE_UP:
      case KeyCode3.PAGE_DOWN:
        if (this.navigateToPage(e.key) !== null) {
          e.preventDefault();
        }
        break;
    }
  }
  onTabKeyDown(e) {
    _stopPropagationForAgGrid(e);
    this.forceFocusOutOfContainer(e.shiftKey);
  }
  navigate(up) {
    if (this.lastFocusedRowIndex == null) {
      return false;
    }
    const nextRow = this.lastFocusedRowIndex + (up ? -1 : 1);
    if (nextRow < 0 || nextRow >= this.model.getRowCount()) {
      return false;
    }
    this.focusRow(nextRow);
    return true;
  }
  navigateToPage(key, fromItem = "focused") {
    let hasFocus = false;
    if (fromItem === "focused") {
      fromItem = this.getLastFocusedRow();
      hasFocus = true;
    }
    const rowCount = this.model.getRowCount() - 1;
    let newIndex = -1;
    if (key === KeyCode3.PAGE_HOME) {
      newIndex = 0;
    } else if (key === KeyCode3.PAGE_END) {
      newIndex = rowCount;
    } else if (key === KeyCode3.PAGE_DOWN) {
      newIndex = Math.min(fromItem + this.pageSize, rowCount);
    } else if (key === KeyCode3.PAGE_UP) {
      newIndex = Math.max(fromItem - this.pageSize, 0);
    }
    if (newIndex === -1) {
      return null;
    }
    if (hasFocus) {
      this.focusRow(newIndex);
    } else {
      this.ensureIndexVisible(newIndex);
    }
    return newIndex;
  }
  getLastFocusedRow() {
    return this.lastFocusedRowIndex;
  }
  focusRow(rowNumber) {
    if (this.isScrolling) {
      return;
    }
    this.isScrolling = true;
    this.ensureIndexVisible(rowNumber);
    this.animationFrameService.requestAnimationFrame(() => {
      this.isScrolling = false;
      if (!this.isAlive()) {
        return;
      }
      const renderedRow = this.renderedRows.get(rowNumber);
      if (renderedRow) {
        renderedRow.eDiv.focus();
      }
    });
  }
  getComponentAt(rowIndex) {
    const comp = this.renderedRows.get(rowIndex);
    return comp && comp.rowComponent;
  }
  forEachRenderedRow(func) {
    this.renderedRows.forEach((value, key) => func(value.rowComponent, key));
  }
  getItemHeight() {
    if (!this.isHeightFromTheme) {
      return this.rowHeight;
    }
    return this.environment.getDefaultListItemHeight();
  }
  /**
   * Returns true if the view had to be scrolled, otherwise, false.
   */
  ensureIndexVisible(index, scrollPartialIntoView = true) {
    const lastRow = this.model.getRowCount();
    if (typeof index !== "number" || index < 0 || index >= lastRow) {
      _warnOnce2("invalid row index for ensureIndexVisible: ", index);
      return false;
    }
    const rowTopPixel = index * this.rowHeight;
    const rowBottomPixel = rowTopPixel + this.rowHeight;
    const eGui = this.getGui();
    const viewportTopPixel = eGui.scrollTop;
    const viewportHeight = eGui.offsetHeight;
    const viewportBottomPixel = viewportTopPixel + viewportHeight;
    const diff = scrollPartialIntoView ? 0 : this.rowHeight;
    const viewportScrolledPastRow = viewportTopPixel > rowTopPixel + diff;
    const viewportScrolledBeforeRow = viewportBottomPixel < rowBottomPixel - diff;
    if (viewportScrolledPastRow) {
      eGui.scrollTop = rowTopPixel;
      return true;
    }
    if (viewportScrolledBeforeRow) {
      const newScrollPosition = rowBottomPixel - viewportHeight;
      eGui.scrollTop = newScrollPosition;
      return true;
    }
    return false;
  }
  setComponentCreator(componentCreator) {
    this.componentCreator = componentCreator;
  }
  setComponentUpdater(componentUpdater) {
    this.componentUpdater = componentUpdater;
  }
  getRowHeight() {
    return this.rowHeight;
  }
  getScrollTop() {
    return this.getGui().scrollTop;
  }
  setRowHeight(rowHeight) {
    this.isHeightFromTheme = false;
    this.rowHeight = rowHeight;
    this.refresh();
  }
  refresh(softRefresh) {
    if (this.model == null || !this.isAlive()) {
      return;
    }
    const rowCount = this.model.getRowCount();
    this.eContainer.style.height = `${rowCount * this.rowHeight}px`;
    this.awaitStable(() => {
      if (!this.isAlive()) {
        return;
      }
      if (this.canSoftRefresh(softRefresh)) {
        this.drawVirtualRows(true);
      } else {
        this.clearVirtualRows();
        this.drawVirtualRows();
      }
    });
  }
  awaitStable(callback) {
    this.awaitStableCallbacks.push(callback);
    if (this.awaitStableCallbacks.length > 1) {
      return;
    }
    const rowCount = this.model.getRowCount();
    _waitUntil(
      () => this.eContainer.clientHeight >= rowCount * this.rowHeight,
      () => {
        const callbacks = this.awaitStableCallbacks;
        this.awaitStableCallbacks = [];
        callbacks.forEach((c) => c());
      }
    );
  }
  canSoftRefresh(softRefresh) {
    return !!(softRefresh && this.renderedRows.size && typeof this.model.areRowsEqual === "function" && this.componentUpdater);
  }
  clearVirtualRows() {
    this.renderedRows.forEach((_, rowIndex) => this.removeRow(rowIndex));
  }
  drawVirtualRows(softRefresh) {
    if (!this.isAlive() || !this.model) {
      return;
    }
    const gui = this.getGui();
    const topPixel = gui.scrollTop;
    const bottomPixel = topPixel + gui.offsetHeight;
    const firstRow = Math.floor(topPixel / this.rowHeight);
    const lastRow = Math.floor(bottomPixel / this.rowHeight);
    this.pageSize = Math.floor((bottomPixel - topPixel) / this.rowHeight);
    this.ensureRowsRendered(firstRow, lastRow, softRefresh);
  }
  ensureRowsRendered(start, finish, softRefresh) {
    this.renderedRows.forEach((_, rowIndex) => {
      if ((rowIndex < start || rowIndex > finish) && rowIndex !== this.lastFocusedRowIndex) {
        this.removeRow(rowIndex);
      }
    });
    if (softRefresh) {
      this.refreshRows();
    }
    for (let rowIndex = start; rowIndex <= finish; rowIndex++) {
      if (this.renderedRows.has(rowIndex)) {
        continue;
      }
      if (rowIndex < this.model.getRowCount()) {
        this.insertRow(rowIndex);
      }
    }
  }
  insertRow(rowIndex) {
    const value = this.model.getRow(rowIndex);
    const eDiv = document.createElement("div");
    eDiv.classList.add("ag-virtual-list-item", `ag-${this.cssIdentifier}-virtual-list-item`);
    _setAriaRole(eDiv, this.ariaRole === "tree" ? "treeitem" : "option");
    _setAriaSetSize2(eDiv, this.model.getRowCount());
    _setAriaPosInSet2(eDiv, rowIndex + 1);
    eDiv.setAttribute("tabindex", "-1");
    eDiv.style.height = `${this.rowHeight}px`;
    eDiv.style.top = `${this.rowHeight * rowIndex}px`;
    const rowComponent = this.componentCreator(value, eDiv);
    rowComponent.addGuiEventListener("focusin", () => this.lastFocusedRowIndex = rowIndex);
    eDiv.appendChild(rowComponent.getGui());
    if (this.renderedRows.has(rowIndex - 1)) {
      this.renderedRows.get(rowIndex - 1).eDiv.insertAdjacentElement("afterend", eDiv);
    } else if (this.renderedRows.has(rowIndex + 1)) {
      this.renderedRows.get(rowIndex + 1).eDiv.insertAdjacentElement("beforebegin", eDiv);
    } else {
      this.eContainer.appendChild(eDiv);
    }
    this.renderedRows.set(rowIndex, { rowComponent, eDiv, value });
  }
  removeRow(rowIndex) {
    const component = this.renderedRows.get(rowIndex);
    this.eContainer.removeChild(component.eDiv);
    this.destroyBean(component.rowComponent);
    this.renderedRows.delete(rowIndex);
  }
  refreshRows() {
    const rowCount = this.model.getRowCount();
    this.renderedRows.forEach((row, rowIndex) => {
      if (rowIndex >= rowCount) {
        this.removeRow(rowIndex);
      } else {
        const newValue = this.model.getRow(rowIndex);
        if (this.model.areRowsEqual?.(row.value, newValue)) {
          this.componentUpdater(newValue, row.rowComponent);
        } else {
          this.removeRow(rowIndex);
        }
      }
    });
  }
  addScrollListener() {
    this.addGuiEventListener("scroll", () => this.drawVirtualRows(), { passive: true });
  }
  setModel(model) {
    this.model = model;
  }
  getAriaElement() {
    return this.eContainer;
  }
  destroy() {
    if (!this.isAlive()) {
      return;
    }
    this.clearVirtualRows();
    this.awaitStableCallbacks.length = 0;
    super.destroy();
  }
};

// enterprise-modules/core/src/widgets/agRichSelectList.ts
var LIST_COMPONENT_NAME = "ag-rich-select-list";
var ROW_COMPONENT_NAME = "ag-rich-select-row";
var AgRichSelectList = class extends VirtualList {
  constructor(params, richSelectWrapper, getSearchString2) {
    super({ cssIdentifier: "rich-select" });
    this.params = params;
    this.richSelectWrapper = richSelectWrapper;
    this.getSearchString = getSearchString2;
    this.lastRowHovered = -1;
    this.selectedItems = /* @__PURE__ */ new Set();
    this.params = params;
    this.setComponentCreator(this.createRowComponent.bind(this));
    this.setComponentUpdater(() => {
    });
  }
  postConstruct() {
    super.postConstruct();
    this.createLoadingElement();
    const { cellRowHeight, pickerAriaLabelKey, pickerAriaLabelValue } = this.params;
    if (cellRowHeight) {
      this.setRowHeight(cellRowHeight);
    }
    const eGui = this.getGui();
    const eListAriaEl = this.getAriaElement();
    this.addManagedListeners(eGui, {
      mousemove: this.onMouseMove.bind(this),
      mouseout: this.onMouseOut.bind(this),
      mousedown: this.onMouseDown.bind(this),
      click: this.onClick.bind(this)
    });
    eGui.classList.add(LIST_COMPONENT_NAME);
    const listId = `${LIST_COMPONENT_NAME}-${this.getCompId()}`;
    eListAriaEl.setAttribute("id", listId);
    const translate = this.localeService.getLocaleTextFunc();
    const ariaLabel = translate(pickerAriaLabelKey, pickerAriaLabelValue);
    _setAriaLabel2(eListAriaEl, ariaLabel);
    _setAriaControls(this.richSelectWrapper, eListAriaEl);
  }
  navigateToPage(key) {
    const newIndex = super.navigateToPage(key, this.lastRowHovered);
    if (newIndex != null) {
      this.animationFrameService.requestAnimationFrame(() => {
        if (!this.isAlive()) {
          return null;
        }
        this.highlightIndex(newIndex);
      });
    }
    return newIndex;
  }
  drawVirtualRows(softRefresh) {
    super.drawVirtualRows(softRefresh);
    this.refreshSelectedItems();
  }
  highlightFilterMatch(searchString) {
    this.forEachRenderedRow((cmp) => {
      cmp.highlightString(searchString);
    });
  }
  onNavigationKeyDown(key, announceItem) {
    this.animationFrameService.requestAnimationFrame(() => {
      if (!this.currentList || !this.isAlive()) {
        return;
      }
      const len = this.currentList.length;
      const oldIndex = this.lastRowHovered;
      const diff = key === KeyCode4.DOWN ? 1 : -1;
      const newIndex = Math.min(Math.max(oldIndex === -1 ? 0 : oldIndex + diff, 0), len - 1);
      this.highlightIndex(newIndex);
      announceItem();
    });
  }
  selectValue(value) {
    if (!this.currentList) {
      if (this.eLoading) {
        this.appendChild(this.eLoading);
      }
      return false;
    }
    if (this.eLoading?.offsetParent) {
      this.eLoading.parentElement?.removeChild(this.eLoading);
    }
    if (value == null) {
      return false;
    }
    const selectedPositions = this.getIndicesForValues(value);
    const refresh = selectedPositions.length > 0;
    if (refresh) {
      this.refresh();
      this.ensureIndexVisible(selectedPositions[0]);
      this.refresh(true);
    }
    this.selectListItems(Array.isArray(value) ? value : [value]);
    return refresh;
  }
  selectListItems(values, append = false) {
    if (!append) {
      this.selectedItems.clear();
    }
    for (let i = 0; i < values.length; i++) {
      const currentItem = values[i];
      if (this.selectedItems.has(currentItem)) {
        continue;
      }
      this.selectedItems.add(currentItem);
    }
    this.refreshSelectedItems();
  }
  getCurrentList() {
    return this.currentList;
  }
  setCurrentList(list) {
    this.currentList = list;
    this.setModel({
      getRowCount: () => list.length,
      getRow: (index) => list[index],
      areRowsEqual: (oldRow, newRow) => oldRow === newRow
    });
  }
  getSelectedItems() {
    return this.selectedItems;
  }
  getLastItemHovered() {
    return this.currentList[this.lastRowHovered];
  }
  highlightIndex(index, preventUnnecessaryScroll) {
    if (!this.currentList) {
      return;
    }
    if (index < 0 || index >= this.currentList.length) {
      this.lastRowHovered = -1;
    } else {
      this.lastRowHovered = index;
      const wasScrolled = this.ensureIndexVisible(index, !preventUnnecessaryScroll);
      if (wasScrolled && !preventUnnecessaryScroll) {
        this.refresh(true);
      }
    }
    this.forEachRenderedRow((cmp, idx) => {
      const highlighted = index === idx;
      cmp.toggleHighlighted(highlighted);
      if (highlighted) {
        const idForParent = `${ROW_COMPONENT_NAME}-${cmp.getCompId()}`;
        _setAriaActiveDescendant(this.richSelectWrapper, idForParent);
        this.richSelectWrapper.setAttribute("data-active-option", idForParent);
      }
    });
  }
  getIndicesForValues(values) {
    const { currentList } = this;
    if (!currentList || currentList.length === 0 || values == null) {
      return [];
    }
    if (!Array.isArray(values)) {
      values = [values];
    }
    if (values.length === 0) {
      return [];
    }
    const positions = [];
    for (let i = 0; i < values.length; i++) {
      const idx = currentList.indexOf(values[i]);
      if (idx >= 0) {
        positions.push(idx);
      }
    }
    return positions;
  }
  toggleListItemSelection(value) {
    if (this.selectedItems.has(value)) {
      this.selectedItems.delete(value);
    } else {
      this.selectedItems.add(value);
    }
    this.refreshSelectedItems();
    this.dispatchValueSelected();
  }
  refreshSelectedItems() {
    this.forEachRenderedRow((cmp) => {
      const selected = this.selectedItems.has(cmp.getValue());
      cmp.updateSelected(selected);
    });
  }
  createLoadingElement() {
    const eDocument = _getDocument4(this.gos);
    const translate = this.localeService.getLocaleTextFunc();
    const el = eDocument.createElement("div");
    el.classList.add("ag-loading-text");
    el.innerText = translate("loadingOoo", "Loading...");
    this.eLoading = el;
  }
  createRowComponent(value, listItemElement) {
    const row = new RichSelectRow(this.params);
    listItemElement.setAttribute("id", `${ROW_COMPONENT_NAME}-${row.getCompId()}`);
    row.setParentComponent(this);
    this.createBean(row);
    row.setState(value);
    const { highlightMatch, searchType = "fuzzy" } = this.params;
    if (highlightMatch && searchType !== "fuzzy") {
      row.highlightString(this.getSearchString());
    }
    return row;
  }
  getRowForMouseEvent(e) {
    const eGui = this.getGui();
    const rect = eGui.getBoundingClientRect();
    const scrollTop = this.getScrollTop();
    const mouseY = e.clientY - rect.top + scrollTop;
    return Math.floor(mouseY / this.getRowHeight());
  }
  onMouseMove(e) {
    const row = this.getRowForMouseEvent(e);
    if (row !== -1 && row != this.lastRowHovered) {
      this.lastRowHovered = row;
      this.highlightIndex(row, true);
    }
  }
  onMouseDown(e) {
    e.preventDefault();
  }
  onMouseOut(e) {
    if (!this.getGui().contains(e.relatedTarget)) {
      this.highlightIndex(-1);
    }
  }
  onClick(e) {
    const { multiSelect } = this.params;
    if (!this.currentList) {
      return;
    }
    const row = this.getRowForMouseEvent(e);
    const item = this.currentList[row];
    if (multiSelect) {
      this.toggleListItemSelection(item);
    } else {
      this.selectListItems([item]);
      this.dispatchValueSelected();
    }
  }
  dispatchValueSelected() {
    this.dispatchLocalEvent({
      type: "richSelectListRowSelected",
      fromEnterKey: false,
      value: this.selectedItems
    });
  }
  destroy() {
    super.destroy();
    this.eLoading = void 0;
  }
};

// enterprise-modules/core/src/widgets/agRichSelect.ts
var AgRichSelect = class extends AgPickerField {
  constructor(config) {
    super({
      pickerAriaLabelKey: "ariaLabelRichSelectField",
      pickerAriaLabelValue: "Rich Select Field",
      pickerType: "ag-list",
      className: "ag-rich-select",
      pickerIcon: "smallDown",
      ariaRole: "combobox",
      template: config?.template ?? /* html */
      `
            <div class="ag-picker-field" role="presentation">
                <div data-ref="eLabel"></div>
                <div data-ref="eWrapper" class="ag-wrapper ag-picker-field-wrapper ag-rich-select-value ag-picker-collapsed">
                    <span data-ref="eDisplayField" class="ag-picker-field-display"></span>
                    <ag-input-text-field data-ref="eInput" class="ag-rich-select-field-input"></ag-input-text-field>
                    <span data-ref="eDeselect" class="ag-rich-select-deselect-button ag-picker-field-icon" role="presentation"></span>
                    <span data-ref="eIcon" class="ag-picker-field-icon" aria-hidden="true"></span>
                </div>
            </div>`,
      agComponents: [AgInputTextFieldSelector],
      modalPicker: false,
      ...config,
      // maxPickerHeight needs to be set after expanding `config`
      maxPickerHeight: config?.maxPickerHeight ?? "calc(var(--ag-row-height) * 6.5)"
    });
    this.searchString = "";
    this.searchStringCreator = null;
    this.eInput = RefPlaceholder5;
    this.eDeselect = RefPlaceholder5;
    this.skipWrapperAnnouncement = false;
    const { value, valueList, searchStringCreator } = config || {};
    if (value !== void 0) {
      this.value = value;
    }
    if (valueList != null) {
      this.values = valueList;
    }
    if (searchStringCreator) {
      this.searchStringCreator = searchStringCreator;
    }
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.userComponentFactory = beans.userComponentFactory;
    this.ariaAnnouncementService = beans.ariaAnnouncementService;
  }
  postConstruct() {
    super.postConstruct();
    this.createListComponent();
    this.eDeselect.appendChild(_createIconNoSpan2("cancel", this.gos));
    const { allowTyping, placeholder, suppressDeselectAll } = this.config;
    this.eDeselect.classList.add("ag-hidden");
    if (allowTyping) {
      this.eInput.setAutoComplete(false).setInputPlaceholder(placeholder);
      this.eDisplayField.classList.add("ag-hidden");
    } else {
      this.eInput.setDisplayed(false);
    }
    this.setupAriaProperties();
    const { searchDebounceDelay = 300 } = this.config;
    this.clearSearchString = _debounce(this.clearSearchString, searchDebounceDelay);
    this.renderSelectedValue();
    if (allowTyping) {
      this.eInput.onValueChange((value) => this.searchTextFromString(value));
    }
    this.addManagedElementListeners(this.eWrapper, { focus: this.onWrapperFocus.bind(this) });
    this.addManagedElementListeners(this.eWrapper, { focusout: this.onWrapperFocusOut.bind(this) });
    if (!suppressDeselectAll) {
      this.addManagedElementListeners(this.eDeselect, {
        mousedown: this.onDeselectAllMouseDown.bind(this),
        click: this.onDeselectAllClick.bind(this)
      });
    }
  }
  setupAriaProperties() {
    const { eWrapper, gos, localeService } = this;
    eWrapper.tabIndex = gos.get("tabIndex");
    const translate = localeService.getLocaleTextFunc();
    this.ariaDeleteSelection = translate("ariaLabelRichSelectDeleteSelection", "Press DELETE to deselect item");
    this.ariaDeselectAllItems = translate(
      "ariaLabelRichSelectDeselectAllItems",
      "Press DELETE to deselect all items"
    );
    this.ariaToggleSelection = translate("ariaLabelRichSelectToggleSelection", "Press SPACE to toggle selection");
  }
  createListComponent() {
    this.listComponent = this.createBean(new AgRichSelectList(this.config, this.eWrapper, () => this.searchString));
    this.listComponent.setParentComponent(this);
    this.addManagedListeners(this.listComponent, {
      richSelectListRowSelected: (e) => {
        this.onListValueSelected(e.value, e.fromEnterKey);
      }
    });
  }
  renderSelectedValue() {
    const { value, eDisplayField, config } = this;
    const {
      allowTyping,
      cellRenderer,
      initialInputValue,
      multiSelect,
      suppressDeselectAll,
      suppressMultiSelectPillRenderer
    } = config;
    const valueFormatted = this.config.valueFormatter ? this.config.valueFormatter(value) : value;
    if (allowTyping) {
      this.eInput.setValue(initialInputValue ?? valueFormatted);
      return;
    }
    if (multiSelect && !suppressDeselectAll) {
      const isEmpty = value == null || Array.isArray(value) && value.length === 0;
      this.eDeselect.classList.toggle("ag-hidden", isEmpty);
    }
    let userCompDetails;
    if (multiSelect && !suppressMultiSelectPillRenderer) {
      this.createOrUpdatePillContainer(eDisplayField);
      return;
    }
    if (cellRenderer) {
      userCompDetails = this.userComponentFactory.getEditorRendererDetails(config, {
        value,
        valueFormatted,
        getValue: () => this.getValue(),
        setValue: (value2) => {
          this.setValue(value2, true);
        },
        setTooltip: (value2, shouldDisplayTooltip) => {
          this.setTooltip({ newTooltipText: value2, shouldDisplayTooltip });
        }
      });
    }
    let userCompDetailsPromise;
    if (userCompDetails) {
      userCompDetailsPromise = userCompDetails.newAgStackInstance();
    }
    if (userCompDetailsPromise) {
      _clearElement2(eDisplayField);
      _bindCellRendererToHtmlElement2(userCompDetailsPromise, eDisplayField);
      userCompDetailsPromise.then((renderer) => {
        this.addDestroyFunc(() => this.destroyBean(renderer));
      });
    } else {
      if (_exists2(this.value)) {
        eDisplayField.innerText = valueFormatted;
        eDisplayField.classList.remove("ag-display-as-placeholder");
      } else {
        const { placeholder } = config;
        if (_exists2(placeholder)) {
          eDisplayField.innerHTML = `${_escapeString2(placeholder)}`;
          eDisplayField.classList.add("ag-display-as-placeholder");
        } else {
          _clearElement2(eDisplayField);
        }
      }
      this.setTooltip({
        newTooltipText: valueFormatted ?? null,
        shouldDisplayTooltip: () => this.eDisplayField.scrollWidth > this.eDisplayField.clientWidth
      });
    }
  }
  createPickerComponent() {
    const { values } = this;
    if (values) {
      this.setValueList({ valueList: values });
    }
    return this.listComponent;
  }
  setSearchStringCreator(searchStringFn) {
    this.searchStringCreator = searchStringFn;
  }
  setValueList(params) {
    const { valueList, refresh } = params;
    if (!this.listComponent || this.listComponent.getCurrentList() === valueList) {
      return;
    }
    this.listComponent.setCurrentList(valueList);
    if (refresh) {
      if (!this.values) {
        this.values = valueList;
        if (this.isPickerDisplayed) {
          const hasRefreshed = this.listComponent.selectValue(this.value);
          if (!hasRefreshed) {
            this.listComponent.refresh();
          }
        }
      } else {
        this.listComponent.refresh(true);
      }
      this.alignPickerToComponent();
    }
  }
  showPicker() {
    super.showPicker();
    const { listComponent, value } = this;
    if (!listComponent) {
      return;
    }
    let idx = null;
    if (this.value != null) {
      listComponent.selectValue(this.value);
      idx = listComponent.getIndicesForValues(Array.isArray(value) ? value : [value])[0];
    }
    if (idx != null) {
      listComponent.highlightIndex(idx);
    } else {
      listComponent.refresh();
    }
    this.displayOrHidePicker();
  }
  beforeHidePicker() {
    super.beforeHidePicker();
  }
  createOrUpdatePillContainer(container) {
    if (!this.pillContainer) {
      const pillContainer = this.pillContainer = this.createBean(new AgPillContainer());
      this.addDestroyFunc(() => {
        this.destroyBean(this.pillContainer);
        this.pillContainer = null;
      });
      _clearElement2(container);
      container.appendChild(pillContainer.getGui());
      pillContainer.init({
        eWrapper: this.eWrapper,
        onPillMouseDown: (e) => {
          e.stopImmediatePropagation();
        },
        announceItemFocus: () => {
          this.announceAriaValue(this.ariaDeleteSelection);
        },
        getValue: () => this.getValue(),
        setValue: (value) => this.setValue(value, true)
      });
    }
    this.doWhileBlockingAnnouncement(() => this.pillContainer?.refresh());
  }
  doWhileBlockingAnnouncement(func) {
    this.skipWrapperAnnouncement = true;
    func();
    this.skipWrapperAnnouncement = false;
  }
  onWrapperFocus() {
    const { eInput, config } = this;
    const { allowTyping, multiSelect, suppressDeselectAll } = config;
    if (allowTyping) {
      const focusableEl = eInput.getFocusableElement();
      focusableEl.focus();
      focusableEl.select();
    } else if (multiSelect && !suppressDeselectAll && !this.skipWrapperAnnouncement) {
      this.announceAriaValue(this.ariaDeselectAllItems);
    }
  }
  onWrapperFocusOut(e) {
    if (!this.eWrapper.contains(e.relatedTarget)) {
      this.hidePicker();
    }
  }
  onDeselectAllMouseDown(e) {
    e.stopImmediatePropagation();
  }
  onDeselectAllClick() {
    this.setValue([], true);
  }
  buildSearchStringFromKeyboardEvent(searchKey) {
    let { key } = searchKey;
    if (key === KeyCode5.BACKSPACE) {
      this.searchString = this.searchString.slice(0, -1);
      key = "";
    } else if (!_isEventFromPrintableCharacter(searchKey)) {
      return;
    }
    searchKey.preventDefault();
    this.searchTextFromCharacter(key);
  }
  searchTextFromCharacter(char) {
    this.searchString += char;
    this.runSearch();
    this.clearSearchString();
  }
  searchTextFromString(str) {
    if (str == null) {
      str = "";
    }
    this.searchString = str;
    this.runSearch();
  }
  buildSearchStrings(values) {
    const { valueFormatter = (value) => value } = this.config;
    let searchStrings;
    if (typeof values[0] === "number" || typeof values[0] === "string") {
      searchStrings = values.map((v) => valueFormatter(v));
    } else if (typeof values[0] === "object" && this.searchStringCreator) {
      searchStrings = this.searchStringCreator(values);
    }
    return searchStrings;
  }
  filterListModel(filteredValues) {
    const { filterList } = this.config;
    if (!filterList) {
      return;
    }
    this.setValueList({ valueList: filteredValues, refresh: true });
  }
  runSearch() {
    if (!this.listComponent) {
      return;
    }
    const { values } = this;
    const searchStrings = this.buildSearchStrings(values);
    if (!searchStrings) {
      this.listComponent.highlightIndex(-1);
      return;
    }
    const { suggestions, filteredValues } = this.getSuggestionsAndFilteredValues(this.searchString, searchStrings);
    const { filterList, highlightMatch, searchType = "fuzzy" } = this.config;
    const filterValueLen = filteredValues.length;
    const shouldFilter = !!(filterList && this.searchString !== "");
    this.filterListModel(shouldFilter ? filteredValues : values);
    if (suggestions.length) {
      const topSuggestionIndex = shouldFilter ? 0 : searchStrings.indexOf(suggestions[0]);
      this.listComponent?.highlightIndex(topSuggestionIndex);
    } else {
      this.listComponent?.highlightIndex(-1);
      if (!shouldFilter || filterValueLen) {
        this.listComponent?.ensureIndexVisible(0);
      } else if (shouldFilter) {
        this.getAriaElement().removeAttribute("data-active-option");
        const eListAriaEl = this.listComponent?.getAriaElement();
        if (eListAriaEl) {
          _setAriaActiveDescendant2(eListAriaEl, null);
        }
      }
    }
    if (highlightMatch && searchType !== "fuzzy") {
      this.listComponent?.highlightFilterMatch(this.searchString);
    }
    this.displayOrHidePicker();
  }
  getSuggestionsAndFilteredValues(searchValue, valueList) {
    let suggestions = [];
    const filteredValues = [];
    if (!searchValue.length) {
      return { suggestions, filteredValues };
    }
    const { searchType = "fuzzy", filterList } = this.config;
    if (searchType === "fuzzy") {
      const fuzzySearchResult = _fuzzySuggestions({
        inputValue: searchValue,
        allSuggestions: valueList,
        hideIrrelevant: true,
        addSequentialWeight: true
      });
      suggestions = fuzzySearchResult.values;
      const indices = fuzzySearchResult.indices;
      if (filterList && indices.length) {
        for (let i = 0; i < indices.length; i++) {
          filteredValues.push(this.values[indices[i]]);
        }
      }
    } else {
      suggestions = valueList.filter((val, idx) => {
        const currentValue = val.toLocaleLowerCase();
        const valueToMatch = this.searchString.toLocaleLowerCase();
        const isMatch = searchType === "match" ? currentValue.startsWith(valueToMatch) : currentValue.indexOf(valueToMatch) !== -1;
        if (filterList && isMatch) {
          filteredValues.push(this.values[idx]);
        }
        return isMatch;
      });
    }
    return { suggestions, filteredValues };
  }
  displayOrHidePicker() {
    if (!this.listComponent) {
      return;
    }
    const eListGui = this.listComponent.getGui();
    const list = this.listComponent.getCurrentList();
    const toggleValue = list ? list.length === 0 : false;
    eListGui.classList.toggle("ag-hidden", toggleValue);
  }
  clearSearchString() {
    this.searchString = "";
  }
  setValue(value, silent, fromPicker, skipRendering) {
    if (this.value === value) {
      return this;
    }
    const isArray = Array.isArray(value);
    if (value != null) {
      if (!isArray) {
        const list = this.listComponent?.getCurrentList();
        const index = list ? list.indexOf(value) : -1;
        if (index === -1) {
          return this;
        }
      }
      if (!fromPicker) {
        this.listComponent?.selectValue(value);
      }
    }
    super.setValue(value, silent);
    if (!skipRendering) {
      this.renderSelectedValue();
    }
    return this;
  }
  onNavigationKeyDown(event, key, announceItem) {
    event.preventDefault();
    const isDown = key === KeyCode5.DOWN;
    if (!this.isPickerDisplayed && isDown) {
      this.showPicker();
      return;
    }
    this.listComponent?.onNavigationKeyDown(key, announceItem);
  }
  onEnterKeyDown(e) {
    if (!this.isPickerDisplayed) {
      return;
    }
    e.preventDefault();
    if (this.listComponent?.getCurrentList()) {
      const lastRowHovered = this.listComponent.getLastItemHovered();
      if (this.config.multiSelect || !lastRowHovered) {
        this.dispatchPickerEventAndHidePicker(this.value, true);
      } else {
        this.onListValueSelected(/* @__PURE__ */ new Set([lastRowHovered]), true);
      }
    }
  }
  onDeleteKeyDown(e) {
    const { eWrapper, gos } = this;
    const activeEl = _getActiveDomElement2(gos);
    if (activeEl === eWrapper) {
      e.preventDefault();
      this.setValue([], true);
    }
  }
  onTabKeyDown() {
    const { config, isPickerDisplayed, listComponent } = this;
    const { multiSelect } = config;
    if (!isPickerDisplayed || !listComponent) {
      return;
    }
    if (multiSelect) {
      const values = this.getValueFromSet(listComponent.getSelectedItems());
      if (values) {
        this.setValue(values, false, true, true);
      }
    } else {
      this.setValue(listComponent.getLastItemHovered(), false, true);
    }
    this.hidePicker();
  }
  getValueFromSet(valueSet) {
    const { multiSelect } = this.config;
    let newValue = null;
    for (const value of valueSet) {
      if (valueSet.size === 1 && !multiSelect) {
        newValue = value;
        break;
      }
      if (!newValue) {
        newValue = [];
      }
      newValue.push(value);
    }
    if (Array.isArray(newValue)) {
      newValue.sort();
    }
    return newValue;
  }
  onListValueSelected(valueSet, fromEnterKey) {
    const newValue = this.getValueFromSet(valueSet);
    this.setValue(newValue, false, true);
    if (!this.config.multiSelect) {
      this.dispatchPickerEventAndHidePicker(newValue, fromEnterKey);
    }
  }
  dispatchPickerEventAndHidePicker(value, fromEnterKey) {
    const event = {
      type: "fieldPickerValueSelected",
      fromEnterKey,
      value
    };
    this.dispatchLocalEvent(event);
    this.hidePicker();
  }
  getFocusableElement() {
    const { allowTyping } = this.config;
    if (allowTyping) {
      return this.eInput.getFocusableElement();
    }
    return super.getFocusableElement();
  }
  onKeyDown(e) {
    const { key } = e;
    const { isPickerDisplayed, config, listComponent, pickerComponent } = this;
    const { allowTyping, multiSelect, suppressDeselectAll } = config;
    switch (key) {
      case KeyCode5.LEFT:
      case KeyCode5.RIGHT:
        if (!allowTyping || this.pillContainer) {
          e.preventDefault();
          if (this.pillContainer) {
            this.listComponent?.highlightIndex(-1);
            this.pillContainer.onNavigationKeyDown(e);
          }
        }
        break;
      case KeyCode5.PAGE_HOME:
      case KeyCode5.PAGE_END:
        if (allowTyping) {
          e.preventDefault();
          const inputEl = this.eInput.getInputElement();
          const target = key === KeyCode5.PAGE_HOME ? 0 : inputEl.value.length;
          inputEl.setSelectionRange(target, target);
          break;
        }
      case KeyCode5.PAGE_UP:
      case KeyCode5.PAGE_DOWN:
        e.preventDefault();
        if (pickerComponent) {
          listComponent?.navigateToPage(key);
        }
        break;
      case KeyCode5.DOWN:
      case KeyCode5.UP:
        this.onNavigationKeyDown(e, key, () => {
          if (multiSelect) {
            this.doWhileBlockingAnnouncement(() => this.eWrapper.focus());
            this.announceAriaValue(this.ariaToggleSelection);
          }
        });
        break;
      case KeyCode5.ESCAPE:
        if (isPickerDisplayed) {
          if (_isVisible(this.listComponent.getGui())) {
            e.preventDefault();
            _stopPropagationForAgGrid2(e);
          }
          this.hidePicker();
        }
        break;
      case KeyCode5.ENTER:
        this.onEnterKeyDown(e);
        break;
      case KeyCode5.SPACE:
        if (isPickerDisplayed && multiSelect && listComponent) {
          e.preventDefault();
          const lastItemHovered = listComponent.getLastItemHovered();
          if (lastItemHovered) {
            listComponent.toggleListItemSelection(lastItemHovered);
          }
        }
        break;
      case KeyCode5.TAB:
        this.onTabKeyDown();
        break;
      case KeyCode5.DELETE:
        if (multiSelect && !suppressDeselectAll) {
          this.onDeleteKeyDown(e);
        }
        break;
      default:
        if (!allowTyping) {
          this.buildSearchStringFromKeyboardEvent(e);
        }
    }
  }
  announceAriaValue(value) {
    this.ariaAnnouncementService.announceValue(value, "richSelect");
  }
  destroy() {
    if (this.listComponent) {
      this.listComponent = this.destroyBean(this.listComponent);
    }
    super.destroy();
  }
};

// enterprise-modules/core/src/widgets/pillDragComp.ts
import {
  Component as Component7,
  KeyCode as KeyCode6,
  RefPlaceholder as RefPlaceholder6,
  TouchListener,
  _createIconNoSpan as _createIconNoSpan3,
  _escapeString as _escapeString3,
  _setAriaLabel as _setAriaLabel3,
  _setDisplayed as _setDisplayed2
} from "ag-grid-community";
var PillDragComp = class extends Component7 {
  constructor(dragSourceDropTarget, ghost, horizontal, template, agComponents) {
    super();
    this.dragSourceDropTarget = dragSourceDropTarget;
    this.ghost = ghost;
    this.horizontal = horizontal;
    this.template = template;
    this.agComponents = agComponents;
    this.eText = RefPlaceholder6;
    this.eDragHandle = RefPlaceholder6;
    this.eButton = RefPlaceholder6;
  }
  wireBeans(beans) {
    this.dragAndDropService = beans.dragAndDropService;
  }
  postConstruct() {
    this.setTemplate(
      this.template ?? /* html */
      `
            <span role="option">
              <span data-ref="eDragHandle" class="ag-drag-handle ag-column-drop-cell-drag-handle" role="presentation"></span>
              <span data-ref="eText" class="ag-column-drop-cell-text" aria-hidden="true"></span>
              <span data-ref="eButton" class="ag-column-drop-cell-button" role="presentation"></span>
            </span>`,
      this.agComponents
    );
    const eGui = this.getGui();
    this.addElementClasses(eGui);
    this.addElementClasses(this.eDragHandle, "drag-handle");
    this.addElementClasses(this.eText, "text");
    this.addElementClasses(this.eButton, "button");
    this.eDragHandle.appendChild(_createIconNoSpan3("columnDrag", this.gos));
    this.eButton.appendChild(_createIconNoSpan3("cancel", this.gos));
    this.setupComponents();
    if (!this.ghost && this.isDraggable()) {
      this.addDragSource();
    }
    this.setupAria();
    this.setupTooltip();
    this.activateTabIndex();
    this.refreshDraggable();
  }
  isDraggable() {
    return true;
  }
  refreshDraggable() {
    this.eDragHandle.classList.toggle("ag-column-select-column-readonly", !this.isDraggable());
  }
  setupAria() {
    const translate = this.localeService.getLocaleTextFunc();
    const ariaInstructions = [this.getAriaDisplayName()];
    this.addAdditionalAriaInstructions(ariaInstructions, translate);
    _setAriaLabel3(this.getGui(), ariaInstructions.join(". "));
  }
  addAdditionalAriaInstructions(ariaInstructions, translate) {
    if (this.isRemovable()) {
      const deleteAria = translate("ariaDropZoneColumnComponentDescription", "Press DELETE to remove");
      ariaInstructions.push(deleteAria);
    }
  }
  setupTooltip() {
    const refresh = () => {
      const newTooltipText = this.getTooltip();
      this.setTooltip({ newTooltipText });
    };
    refresh();
    this.addManagedEventListeners({ newColumnsLoaded: refresh });
  }
  getDragSourceId() {
    return void 0;
  }
  getDefaultIconName() {
    return "notAllowed";
  }
  addDragSource() {
    const { dragAndDropService, eDragHandle } = this;
    const getDragItem = this.createGetDragItem();
    const defaultIconName = this.getDefaultIconName();
    const dragSource = {
      type: this.getDragSourceType(),
      sourceId: this.getDragSourceId(),
      eElement: eDragHandle,
      getDefaultIconName: () => defaultIconName,
      getDragItem,
      dragItemName: this.getDisplayName()
    };
    dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => dragAndDropService.removeDragSource(dragSource));
  }
  setupComponents() {
    this.setTextValue();
    this.setupRemove();
    if (this.ghost) {
      this.addCssClass("ag-column-drop-cell-ghost");
    }
  }
  isRemovable() {
    return true;
  }
  refreshRemove() {
    _setDisplayed2(this.eButton, this.isRemovable());
  }
  setupRemove() {
    this.refreshRemove();
    const agEvent = { type: "columnRemove" };
    this.addGuiEventListener("keydown", (e) => this.onKeyDown(e));
    this.addManagedElementListeners(this.eButton, {
      click: (mouseEvent) => {
        this.dispatchLocalEvent(agEvent);
        mouseEvent.stopPropagation();
      }
    });
    const touchListener = new TouchListener(this.eButton);
    this.addManagedListeners(touchListener, {
      tap: () => this.dispatchLocalEvent(agEvent)
    });
    this.addDestroyFunc(touchListener.destroy.bind(touchListener));
  }
  onKeyDown(e) {
    const isDelete = e.key === KeyCode6.DELETE;
    if (isDelete) {
      if (this.isRemovable()) {
        e.preventDefault();
        this.dispatchLocalEvent({ type: "columnRemove" });
      }
    }
  }
  getDisplayValue() {
    return this.getDisplayName();
  }
  setTextValue() {
    const displayValue = this.getDisplayValue();
    const displayValueSanitised = _escapeString3(displayValue);
    this.eText.innerHTML = displayValueSanitised;
  }
  addElementClasses(el, suffix) {
    suffix = suffix ? `-${suffix}` : "";
    const direction = this.horizontal ? "horizontal" : "vertical";
    el.classList.add(`ag-column-drop-cell${suffix}`, `ag-column-drop-${direction}-cell${suffix}`);
  }
  destroy() {
    super.destroy();
    this.dragSourceDropTarget = null;
  }
};

// enterprise-modules/core/src/widgets/pillDropZonePanel.ts
import {
  Component as Component8,
  KeyCode as KeyCode7,
  ManagedFocusFeature,
  PositionableFeature,
  _areEqual,
  _clearElement as _clearElement3,
  _createIconNoSpan as _createIconNoSpan4,
  _existsAndNotEmpty,
  _getActiveDomElement as _getActiveDomElement3,
  _includes,
  _insertArrayIntoArray,
  _last,
  _setAriaHidden,
  _setAriaLabel as _setAriaLabel4,
  _setAriaPosInSet as _setAriaPosInSet3,
  _setAriaRole as _setAriaRole2,
  _setAriaSetSize as _setAriaSetSize3
} from "ag-grid-community";
var PillDropZonePanel = class extends Component8 {
  constructor(horizontal) {
    super(
      /* html */
      `<div class="ag-unselectable" role="presentation"></div>`
    );
    this.horizontal = horizontal;
    this.state = "notDragging";
    this.guiDestroyFunctions = [];
    this.childPillComponents = [];
    this.resizeEnabled = false;
    this.addElementClasses(this.getGui());
    this.ePillDropList = document.createElement("div");
    this.addElementClasses(this.ePillDropList, "list");
    _setAriaRole2(this.ePillDropList, "listbox");
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.dragAndDropService = beans.dragAndDropService;
  }
  isHorizontal() {
    return this.horizontal;
  }
  toggleResizable(resizable) {
    this.positionableFeature.setResizable(resizable ? { bottom: true } : false);
    this.resizeEnabled = resizable;
  }
  isSourceEventFromTarget(draggingEvent) {
    const { dropZoneTarget, dragSource } = draggingEvent;
    return dropZoneTarget.contains(dragSource.eElement);
  }
  destroy() {
    this.destroyGui();
    super.destroy();
  }
  destroyGui() {
    this.guiDestroyFunctions.forEach((func) => func());
    this.guiDestroyFunctions.length = 0;
    this.childPillComponents.length = 0;
    _clearElement3(this.getGui());
    _clearElement3(this.ePillDropList);
  }
  init(params) {
    this.params = params ?? {};
    this.createManagedBean(
      new ManagedFocusFeature(this.getFocusableElement(), {
        onTabKeyDown: this.onTabKeyDown.bind(this),
        handleKeyDown: this.onKeyDown.bind(this)
      })
    );
    this.setupDropTarget();
    this.positionableFeature = new PositionableFeature(this.getGui());
    this.createManagedBean(this.positionableFeature);
    this.refreshGui();
    _setAriaLabel4(this.ePillDropList, this.getAriaLabel());
  }
  onTabKeyDown(e) {
    const focusableElements = this.focusService.findFocusableElements(this.getFocusableElement(), null, true);
    const len = focusableElements.length;
    if (len === 0) {
      return;
    }
    const { shiftKey } = e;
    const activeEl = _getActiveDomElement3(this.gos);
    const isFirstFocused = activeEl === focusableElements[0];
    const isLastFocused = activeEl === _last(focusableElements);
    const shouldAllowDefaultTab = len === 1 || isFirstFocused && shiftKey || isLastFocused && !shiftKey;
    if (!shouldAllowDefaultTab) {
      focusableElements[shiftKey ? 0 : len - 1].focus();
    }
  }
  onKeyDown(e) {
    const { key } = e;
    const isVertical = !this.horizontal;
    let isNext = key === KeyCode7.DOWN;
    let isPrevious = key === KeyCode7.UP;
    if (!isVertical) {
      const isRtl = this.gos.get("enableRtl");
      isNext = !isRtl && key === KeyCode7.RIGHT || isRtl && key === KeyCode7.LEFT;
      isPrevious = !isRtl && key === KeyCode7.LEFT || isRtl && key === KeyCode7.RIGHT;
    }
    if (!isNext && !isPrevious) {
      return;
    }
    const el = this.focusService.findNextFocusableElement(this.getFocusableElement(), false, isPrevious);
    if (el) {
      e.preventDefault();
      el.focus();
    }
  }
  addElementClasses(el, suffix) {
    suffix = suffix ? `-${suffix}` : "";
    const direction = this.horizontal ? "horizontal" : "vertical";
    el.classList.add(`ag-column-drop${suffix}`, `ag-column-drop-${direction}${suffix}`);
  }
  setupDropTarget() {
    this.dropTarget = {
      getContainer: this.getGui.bind(this),
      getIconName: this.getIconName.bind(this),
      onDragging: this.onDragging.bind(this),
      onDragEnter: this.onDragEnter.bind(this),
      onDragLeave: this.onDragLeave.bind(this),
      onDragStop: this.onDragStop.bind(this),
      onDragCancel: this.onDragCancel.bind(this),
      isInterestedIn: this.isInterestedIn.bind(this)
    };
    this.dragAndDropService.addDropTarget(this.dropTarget);
  }
  minimumAllowedNewInsertIndex() {
    return 0;
  }
  checkInsertIndex(draggingEvent) {
    const newIndex = this.getNewInsertIndex(draggingEvent);
    if (newIndex < 0) {
      return false;
    }
    const minimumAllowedIndex = this.minimumAllowedNewInsertIndex();
    const newAdjustedIndex = Math.max(minimumAllowedIndex, newIndex);
    const changed = newAdjustedIndex !== this.insertIndex;
    if (changed) {
      this.insertIndex = newAdjustedIndex;
    }
    return changed;
  }
  getNewInsertIndex(draggingEvent) {
    const mouseEvent = draggingEvent.event;
    const mouseLocation = this.horizontal ? mouseEvent.clientX : mouseEvent.clientY;
    const boundsList = this.childPillComponents.map((comp) => comp.getGui().getBoundingClientRect());
    const hoveredIndex = boundsList.findIndex(
      (rect) => this.horizontal ? rect.right > mouseLocation && rect.left < mouseLocation : rect.top < mouseLocation && rect.bottom > mouseLocation
    );
    if (hoveredIndex === -1) {
      const enableRtl = this.gos.get("enableRtl");
      const isLast = boundsList.every((rect) => mouseLocation > (this.horizontal ? rect.right : rect.bottom));
      if (isLast) {
        return enableRtl && this.horizontal ? 0 : this.childPillComponents.length;
      }
      const isFirst = boundsList.every((rect) => mouseLocation < (this.horizontal ? rect.left : rect.top));
      if (isFirst) {
        return enableRtl && this.horizontal ? this.childPillComponents.length : 0;
      }
      return this.insertIndex;
    }
    if (this.insertIndex <= hoveredIndex) {
      return hoveredIndex + 1;
    }
    return hoveredIndex;
  }
  checkDragStartedBySelf(draggingEvent) {
    if (this.state !== "notDragging") {
      return;
    }
    this.state = "rearrangeItems";
    this.potentialDndItems = this.getItems(draggingEvent.dragSource.getDragItem());
    this.refreshGui();
    this.checkInsertIndex(draggingEvent);
    this.refreshGui();
  }
  onDragging(draggingEvent) {
    this.checkDragStartedBySelf(draggingEvent);
    if (this.checkInsertIndex(draggingEvent)) {
      this.refreshGui();
    }
  }
  handleDragEnterEnd(draggingEvent) {
  }
  onDragEnter(draggingEvent) {
    const dragItems = this.getItems(draggingEvent.dragSource.getDragItem());
    this.state = "newItemsIn";
    const goodDragItems = dragItems.filter((item) => this.isItemDroppable(item, draggingEvent));
    const alreadyPresent = goodDragItems.every(
      (item) => this.childPillComponents.map((cmp) => cmp.getItem()).indexOf(item) !== -1
    );
    if (goodDragItems.length === 0) {
      return;
    }
    this.potentialDndItems = goodDragItems;
    if (alreadyPresent) {
      this.state = "notDragging";
      return;
    }
    this.handleDragEnterEnd(draggingEvent);
    this.checkInsertIndex(draggingEvent);
    this.refreshGui();
  }
  isPotentialDndItems() {
    return _existsAndNotEmpty(this.potentialDndItems);
  }
  handleDragLeaveEnd(draggingEvent) {
  }
  onDragLeave(draggingEvent) {
    if (this.state === "rearrangeItems") {
      const items = this.getItems(draggingEvent.dragSource.getDragItem());
      this.removeItems(items);
    }
    if (this.isPotentialDndItems()) {
      this.handleDragLeaveEnd(draggingEvent);
      this.potentialDndItems = [];
      this.refreshGui();
    }
    this.state = "notDragging";
  }
  onDragCancel(draggingEvent) {
    if (this.isPotentialDndItems()) {
      if (this.state === "newItemsIn") {
        this.handleDragLeaveEnd(draggingEvent);
      }
      this.potentialDndItems = [];
      this.refreshGui();
    }
    this.state = "notDragging";
  }
  onDragStop() {
    if (this.isPotentialDndItems()) {
      if (this.state === "newItemsIn") {
        this.addItems(this.potentialDndItems);
      } else {
        this.rearrangeItems(this.potentialDndItems);
      }
      this.potentialDndItems = [];
      this.refreshGui();
    }
    this.state = "notDragging";
  }
  removeItems(itemsToRemove) {
    const newItemList = this.getExistingItems().filter((item) => !_includes(itemsToRemove, item));
    this.updateItems(newItemList);
  }
  addItems(itemsToAdd) {
    if (!itemsToAdd) {
      return;
    }
    const newItemList = this.getExistingItems().slice();
    const itemsToAddNoDuplicates = itemsToAdd.filter((item) => newItemList.indexOf(item) < 0);
    _insertArrayIntoArray(newItemList, itemsToAddNoDuplicates, this.insertIndex);
    this.updateItems(newItemList);
  }
  addItem(item) {
    this.insertIndex = this.getExistingItems().length;
    this.addItems([item]);
    this.refreshGui();
  }
  rearrangeItems(itemsToAdd) {
    const newItemList = this.getNonGhostItems().slice();
    _insertArrayIntoArray(newItemList, itemsToAdd, this.insertIndex);
    if (_areEqual(newItemList, this.getExistingItems())) {
      return false;
    }
    this.updateItems(newItemList);
    return true;
  }
  refreshGui() {
    const scrollTop = this.ePillDropList.scrollTop;
    const resizeEnabled = this.resizeEnabled;
    const focusedIndex = this.getFocusedItem();
    let alternateElement = this.focusService.findNextFocusableElement();
    if (!alternateElement) {
      alternateElement = this.focusService.findNextFocusableElement(void 0, false, true);
    }
    this.toggleResizable(false);
    this.destroyGui();
    this.addIconAndTitleToGui();
    this.addEmptyMessageToGui();
    this.addItemsToGui();
    if (!this.isHorizontal()) {
      this.ePillDropList.scrollTop = scrollTop;
    }
    if (resizeEnabled) {
      this.toggleResizable(resizeEnabled);
    }
    if (this.focusService.isKeyboardMode()) {
      this.restoreFocus(focusedIndex, alternateElement);
    }
  }
  getFocusedItem() {
    const eGui = this.getGui();
    const activeElement = _getActiveDomElement3(this.gos);
    if (!eGui.contains(activeElement)) {
      return -1;
    }
    const items = Array.from(eGui.querySelectorAll(".ag-column-drop-cell"));
    return items.indexOf(activeElement);
  }
  restoreFocus(index, alternateElement) {
    const eGui = this.getGui();
    const items = Array.from(eGui.querySelectorAll(".ag-column-drop-cell"));
    if (index === -1) {
      return;
    }
    if (items.length === 0) {
      alternateElement.focus();
    }
    const indexToFocus = Math.min(items.length - 1, index);
    const el = items[indexToFocus];
    if (el) {
      el.focus();
    }
  }
  focusList(fromBottom) {
    const index = fromBottom ? this.childPillComponents.length - 1 : 0;
    this.restoreFocus(index, this.getFocusableElement());
  }
  getNonGhostItems() {
    const existingItems = this.getExistingItems();
    if (this.isPotentialDndItems()) {
      return existingItems.filter((item) => !_includes(this.potentialDndItems, item));
    }
    return existingItems;
  }
  addItemsToGui() {
    const nonGhostItems = this.getNonGhostItems();
    const itemsToAddToGui = nonGhostItems.map((item) => this.createItemComponent(item, false));
    if (this.isPotentialDndItems()) {
      const dndItems = this.potentialDndItems.map((item) => this.createItemComponent(item, true));
      if (this.insertIndex >= itemsToAddToGui.length) {
        itemsToAddToGui.push(...dndItems);
      } else {
        itemsToAddToGui.splice(this.insertIndex, 0, ...dndItems);
      }
    }
    this.appendChild(this.ePillDropList);
    itemsToAddToGui.forEach((itemComponent, index) => {
      if (index > 0) {
        this.addArrow(this.ePillDropList);
      }
      this.ePillDropList.appendChild(itemComponent.getGui());
    });
    this.addAriaLabelsToComponents();
  }
  addAriaLabelsToComponents() {
    this.childPillComponents.forEach((comp, idx) => {
      const eGui = comp.getGui();
      _setAriaPosInSet3(eGui, idx + 1);
      _setAriaSetSize3(eGui, this.childPillComponents.length);
    });
  }
  createItemComponent(item, ghost) {
    const itemComponent = this.createPillComponent(item, this.dropTarget, ghost, this.horizontal);
    itemComponent.addEventListener("columnRemove", this.removeItems.bind(this, [item]));
    this.createBean(itemComponent);
    this.guiDestroyFunctions.push(() => this.destroyBean(itemComponent));
    if (!ghost) {
      this.childPillComponents.push(itemComponent);
    }
    return itemComponent;
  }
  addIconAndTitleToGui() {
    const { title, icon: eGroupIcon } = this.params;
    if (!title || !eGroupIcon) {
      return;
    }
    const eTitleBar = document.createElement("div");
    _setAriaHidden(eTitleBar, true);
    this.addElementClasses(eTitleBar, "title-bar");
    this.addElementClasses(eGroupIcon, "icon");
    this.addOrRemoveCssClass("ag-column-drop-empty", this.isExistingItemsEmpty());
    eTitleBar.appendChild(eGroupIcon);
    if (!this.horizontal) {
      const eTitle = document.createElement("span");
      this.addElementClasses(eTitle, "title");
      eTitle.innerHTML = title;
      eTitleBar.appendChild(eTitle);
    }
    this.appendChild(eTitleBar);
  }
  isExistingItemsEmpty() {
    return this.getExistingItems().length === 0;
  }
  addEmptyMessageToGui() {
    const { emptyMessage } = this.params;
    if (!emptyMessage || !this.isExistingItemsEmpty() || this.isPotentialDndItems()) {
      return;
    }
    const eMessage = document.createElement("span");
    eMessage.innerHTML = emptyMessage;
    this.addElementClasses(eMessage, "empty-message");
    this.ePillDropList.appendChild(eMessage);
  }
  addArrow(eParent) {
    if (this.horizontal) {
      const enableRtl = this.gos.get("enableRtl");
      const icon = _createIconNoSpan4(enableRtl ? "smallLeft" : "smallRight", this.gos);
      this.addElementClasses(icon, "cell-separator");
      eParent.appendChild(icon);
    }
  }
};

// enterprise-modules/core/src/widgets/agDialog.ts
import {
  Component as Component10,
  TabGuardFeature,
  _createIconNoSpan as _createIconNoSpan6,
  _setDisplayed as _setDisplayed4
} from "ag-grid-community";

// enterprise-modules/core/src/widgets/agPanel.ts
import {
  Component as Component9,
  PositionableFeature as PositionableFeature2,
  RefPlaceholder as RefPlaceholder7,
  _createIconNoSpan as _createIconNoSpan5,
  _getActiveDomElement as _getActiveDomElement4,
  _getInnerHeight,
  _getInnerWidth,
  _isVisible as _isVisible2,
  _setDisplayed as _setDisplayed3
} from "ag-grid-community";
function getTemplate(config) {
  const cssIdentifier = config.cssIdentifier || "default";
  return (
    /* html */
    `<div class="ag-panel ag-${cssIdentifier}-panel" tabindex="-1">
        <div data-ref="eTitleBar" class="ag-panel-title-bar ag-${cssIdentifier}-panel-title-bar ag-unselectable">
            <span data-ref="eTitle" class="ag-panel-title-bar-title ag-${cssIdentifier}-panel-title-bar-title"></span>
            <div data-ref="eTitleBarButtons" class="ag-panel-title-bar-buttons ag-${cssIdentifier}-panel-title-bar-buttons"></div>
        </div>
        <div data-ref="eContentWrapper" class="ag-panel-content-wrapper ag-${cssIdentifier}-panel-content-wrapper"></div>
    </div>`
  );
}
var _AgPanel = class _AgPanel extends Component9 {
  constructor(config) {
    super(getTemplate(config));
    this.config = config;
    this.closable = true;
    this.eContentWrapper = RefPlaceholder7;
    this.eTitleBar = RefPlaceholder7;
    this.eTitleBarButtons = RefPlaceholder7;
    this.eTitle = RefPlaceholder7;
  }
  postConstruct() {
    const {
      component,
      closable,
      hideTitleBar,
      title,
      minWidth = 250,
      width,
      minHeight = 250,
      height,
      centered,
      popup,
      x,
      y
    } = this.config;
    this.positionableFeature = new PositionableFeature2(this.getGui(), {
      minWidth,
      width,
      minHeight,
      height,
      centered,
      x,
      y,
      popup,
      calculateTopBuffer: () => this.positionableFeature.getHeight() - this.getBodyHeight()
    });
    this.createManagedBean(this.positionableFeature);
    const eGui = this.getGui();
    if (component) {
      this.setBodyComponent(component);
    }
    if (!hideTitleBar) {
      if (title) {
        this.setTitle(title);
      }
      this.setClosable(closable != null ? closable : this.closable);
    } else {
      _setDisplayed3(this.eTitleBar, false);
    }
    this.addManagedElementListeners(this.eTitleBar, {
      mousedown: (e) => {
        if (eGui.contains(e.relatedTarget) || eGui.contains(_getActiveDomElement4(this.gos)) || this.eTitleBarButtons.contains(e.target)) {
          e.preventDefault();
          return;
        }
        const focusEl = this.eContentWrapper.querySelector(
          "button, [href], input, select, textarea, [tabindex]"
        );
        if (focusEl) {
          focusEl.focus();
        }
      }
    });
    if (popup && this.positionableFeature.isPositioned()) {
      return;
    }
    if (this.renderComponent) {
      this.renderComponent();
    }
    this.positionableFeature.initialisePosition();
    this.eContentWrapper.style.height = "0";
  }
  renderComponent() {
    const eGui = this.getGui();
    eGui.focus();
    this.close = () => {
      eGui.parentElement.removeChild(eGui);
      this.destroy();
    };
  }
  getHeight() {
    return this.positionableFeature.getHeight();
  }
  setHeight(height) {
    this.positionableFeature.setHeight(height);
  }
  getWidth() {
    return this.positionableFeature.getWidth();
  }
  setWidth(width) {
    this.positionableFeature.setWidth(width);
  }
  setClosable(closable) {
    if (closable !== this.closable) {
      this.closable = closable;
    }
    if (closable) {
      const closeButtonComp = this.closeButtonComp = new Component9(_AgPanel.CLOSE_BTN_TEMPLATE);
      this.createBean(closeButtonComp);
      const eGui = closeButtonComp.getGui();
      const child = _createIconNoSpan5("close", this.gos);
      child.classList.add("ag-panel-title-bar-button-icon");
      eGui.appendChild(child);
      this.addTitleBarButton(closeButtonComp);
      closeButtonComp.addManagedElementListeners(eGui, { click: this.onBtClose.bind(this) });
    } else if (this.closeButtonComp) {
      const eGui = this.closeButtonComp.getGui();
      eGui.parentElement.removeChild(eGui);
      this.closeButtonComp = this.destroyBean(this.closeButtonComp);
    }
  }
  setBodyComponent(bodyComponent) {
    bodyComponent.setParentComponent(this);
    this.eContentWrapper.appendChild(bodyComponent.getGui());
  }
  addTitleBarButton(button, position) {
    const eTitleBarButtons = this.eTitleBarButtons;
    const buttons = eTitleBarButtons.children;
    const len = buttons.length;
    if (position == null) {
      position = len;
    }
    position = Math.max(0, Math.min(position, len));
    button.addCssClass("ag-panel-title-bar-button");
    const eGui = button.getGui();
    if (position === 0) {
      eTitleBarButtons.insertAdjacentElement("afterbegin", eGui);
    } else if (position === len) {
      eTitleBarButtons.insertAdjacentElement("beforeend", eGui);
    } else {
      buttons[position - 1].insertAdjacentElement("afterend", eGui);
    }
    button.setParentComponent(this);
  }
  getBodyHeight() {
    return _getInnerHeight(this.eContentWrapper);
  }
  getBodyWidth() {
    return _getInnerWidth(this.eContentWrapper);
  }
  setTitle(title) {
    this.eTitle.innerText = title;
  }
  // called when user hits the 'x' in the top right
  onBtClose() {
    this.close();
  }
  destroy() {
    if (this.closeButtonComp) {
      this.closeButtonComp = this.destroyBean(this.closeButtonComp);
    }
    const eGui = this.getGui();
    if (eGui && _isVisible2(eGui)) {
      this.close();
    }
    super.destroy();
  }
};
_AgPanel.CLOSE_BTN_TEMPLATE = /* html */
`<div class="ag-button"></div>`;
var AgPanel = _AgPanel;

// enterprise-modules/core/src/widgets/agDialog.ts
var AgDialog = class extends AgPanel {
  constructor(config) {
    super({ ...config, popup: true });
    this.isMaximizable = false;
    this.isMaximized = false;
    this.maximizeListeners = [];
    this.resizeListenerDestroy = null;
    this.lastPosition = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.focusService = beans.focusService;
  }
  postConstruct() {
    const eGui = this.getGui();
    const { movable, resizable, maximizable, modal, postProcessPopupParams } = this.config;
    this.addCssClass("ag-dialog");
    super.postConstruct();
    if (postProcessPopupParams) {
      const { type, eventSource, column, mouseEvent, rowNode } = postProcessPopupParams;
      this.popupService.callPostProcessPopup(type, eGui, eventSource, mouseEvent, column, rowNode);
    }
    this.tabGuardFeature = this.createManagedBean(new TabGuardFeature(this));
    this.tabGuardFeature.initialiseTabGuard({
      isFocusableContainer: true,
      onFocusIn: () => {
        this.popupService.bringPopupToFront(eGui);
      },
      onTabKeyDown: (e) => {
        if (modal) {
          return;
        }
        const backwards = e.shiftKey;
        const nextFocusableElement = this.focusService.findNextFocusableElement(eGui, false, backwards);
        if (!nextFocusableElement || this.tabGuardFeature.getTabGuardCtrl().isTabGuard(nextFocusableElement)) {
          if (this.focusService.focusNextGridCoreContainer(backwards)) {
            e.preventDefault();
          }
        }
      }
    });
    if (movable) {
      this.setMovable(movable);
    }
    if (maximizable) {
      this.setMaximizable(maximizable);
    }
    if (resizable) {
      this.setResizable(resizable);
    }
    if (!this.config.modal) {
      const { focusService } = this;
      focusService.addFocusableContainer(this);
      this.addDestroyFunc(() => focusService.removeFocusableContainer(this));
    }
  }
  setAllowFocus(allowFocus) {
    this.tabGuardFeature.getTabGuardCtrl().setAllowFocus(allowFocus);
  }
  renderComponent() {
    const eGui = this.getGui();
    const { alwaysOnTop, modal, title, afterGuiAttached } = this.config;
    const translate = this.localeService.getLocaleTextFunc();
    const addPopupRes = this.popupService.addPopup({
      modal,
      eChild: eGui,
      closeOnEsc: true,
      closedCallback: this.onClosed.bind(this),
      alwaysOnTop,
      ariaLabel: title || translate("ariaLabelDialog", "Dialog"),
      afterGuiAttached
    });
    if (addPopupRes) {
      this.close = addPopupRes.hideFunc;
    }
  }
  onClosed(event) {
    this.destroy();
    this.config.closedCallback?.(event);
  }
  toggleMaximize() {
    const position = this.positionableFeature.getPosition();
    if (this.isMaximized) {
      const { x, y, width, height } = this.lastPosition;
      this.setWidth(width);
      this.setHeight(height);
      this.positionableFeature.offsetElement(x, y);
    } else {
      this.lastPosition.width = this.getWidth();
      this.lastPosition.height = this.getHeight();
      this.lastPosition.x = position.x;
      this.lastPosition.y = position.y;
      this.positionableFeature.offsetElement(0, 0);
      this.setHeight("100%");
      this.setWidth("100%");
    }
    this.isMaximized = !this.isMaximized;
    this.refreshMaximizeIcon();
  }
  refreshMaximizeIcon() {
    _setDisplayed4(this.maximizeIcon, !this.isMaximized);
    _setDisplayed4(this.minimizeIcon, this.isMaximized);
  }
  clearMaximizebleListeners() {
    if (this.maximizeListeners.length) {
      this.maximizeListeners.forEach((destroyListener) => destroyListener());
      this.maximizeListeners.length = 0;
    }
    if (this.resizeListenerDestroy) {
      this.resizeListenerDestroy();
      this.resizeListenerDestroy = null;
    }
  }
  destroy() {
    this.maximizeButtonComp = this.destroyBean(this.maximizeButtonComp);
    this.clearMaximizebleListeners();
    super.destroy();
  }
  setResizable(resizable) {
    this.positionableFeature.setResizable(resizable);
  }
  setMovable(movable) {
    this.positionableFeature.setMovable(movable, this.eTitleBar);
  }
  setMaximizable(maximizable) {
    if (!maximizable) {
      this.clearMaximizebleListeners();
      if (this.maximizeButtonComp) {
        this.destroyBean(this.maximizeButtonComp);
        this.maximizeButtonComp = this.maximizeIcon = this.minimizeIcon = void 0;
      }
      return;
    }
    const eTitleBar = this.eTitleBar;
    if (!eTitleBar || maximizable === this.isMaximizable) {
      return;
    }
    const maximizeButtonComp = this.buildMaximizeAndMinimizeElements();
    this.refreshMaximizeIcon();
    maximizeButtonComp.addManagedElementListeners(maximizeButtonComp.getGui(), {
      click: this.toggleMaximize.bind(this)
    });
    this.addTitleBarButton(maximizeButtonComp, 0);
    this.maximizeListeners.push(
      ...this.addManagedElementListeners(eTitleBar, {
        dblclick: this.toggleMaximize.bind(this)
      })
    );
    [this.resizeListenerDestroy] = this.addManagedListeners(this.positionableFeature, {
      resize: () => {
        this.isMaximized = false;
        this.refreshMaximizeIcon();
      }
    });
  }
  buildMaximizeAndMinimizeElements() {
    const maximizeButtonComp = this.maximizeButtonComp = this.createBean(
      new Component10(
        /* html */
        `<div class="ag-dialog-button"></span>`
      )
    );
    const eGui = maximizeButtonComp.getGui();
    this.maximizeIcon = _createIconNoSpan6("maximize", this.gos);
    eGui.appendChild(this.maximizeIcon);
    this.maximizeIcon.classList.add("ag-panel-title-bar-button-icon");
    this.minimizeIcon = _createIconNoSpan6("minimize", this.gos);
    eGui.appendChild(this.minimizeIcon);
    this.minimizeIcon.classList.add("ag-panel-title-bar-button-icon");
    return maximizeButtonComp;
  }
};

// enterprise-modules/core/src/widgets/agMenuItemComponent.ts
import {
  BeanStub as BeanStub2,
  KeyCode as KeyCode10,
  TooltipFeature,
  _loadTemplate as _loadTemplate3,
  _setAriaDisabled,
  _setAriaExpanded as _setAriaExpanded3,
  _setAriaLevel,
  _setAriaRole as _setAriaRole3
} from "ag-grid-community";

// enterprise-modules/core/src/widgets/agMenuList.ts
import {
  AgPromise,
  KeyCode as KeyCode8,
  TabGuardComp as TabGuardComp2,
  _last as _last2,
  _loadTemplate as _loadTemplate2,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid3,
  _warnOnce as _warnOnce3
} from "ag-grid-community";
var AgMenuList = class extends TabGuardComp2 {
  constructor(level = 0, params) {
    super(
      /* html */
      `<div class="ag-menu-list" role="tree"></div>`
    );
    this.level = level;
    this.menuItems = [];
    this.params = params ?? {
      column: null,
      node: null,
      value: null
    };
  }
  postConstruct() {
    this.initialiseTabGuard({
      onTabKeyDown: (e) => this.onTabKeyDown(e),
      handleKeyDown: (e) => this.handleKeyDown(e),
      onFocusIn: (e) => this.handleFocusIn(e),
      onFocusOut: (e) => this.handleFocusOut(e)
    });
  }
  onTabKeyDown(e) {
    const parent = this.getParentComponent();
    const parentGui = parent && parent.getGui();
    const isManaged = parentGui && parentGui.classList.contains("ag-focus-managed");
    if (!isManaged) {
      e.preventDefault();
    }
    if (e.shiftKey) {
      this.closeIfIsChild(e);
    }
  }
  handleKeyDown(e) {
    switch (e.key) {
      case KeyCode8.UP:
      case KeyCode8.RIGHT:
      case KeyCode8.DOWN:
      case KeyCode8.LEFT:
        e.preventDefault();
        this.handleNavKey(e.key);
        break;
      case KeyCode8.ESCAPE:
        if (this.closeIfIsChild()) {
          _stopPropagationForAgGrid3(e);
        }
        break;
    }
  }
  handleFocusIn(e) {
    const oldFocusedElement = e.relatedTarget;
    if (!this.tabGuardFeature.getTabGuardCtrl().isTabGuard(oldFocusedElement) && (this.getGui().contains(oldFocusedElement) || this.activeMenuItem?.getSubMenuGui()?.contains(oldFocusedElement))) {
      return;
    }
    if (this.activeMenuItem) {
      this.activeMenuItem.activate();
    } else {
      this.activateFirstItem();
    }
  }
  handleFocusOut(e) {
    const newFocusedElement = e.relatedTarget;
    if (!this.activeMenuItem || this.getGui().contains(newFocusedElement) || this.activeMenuItem.getSubMenuGui()?.contains(newFocusedElement)) {
      return;
    }
    if (!this.activeMenuItem.isSubMenuOpening()) {
      this.activeMenuItem.deactivate();
    }
  }
  clearActiveItem() {
    if (this.activeMenuItem) {
      this.activeMenuItem.deactivate();
      this.activeMenuItem = null;
    }
  }
  addMenuItems(menuItems) {
    if (menuItems == null) {
      return;
    }
    AgPromise.all(
      menuItems.map((menuItemOrString) => {
        if (menuItemOrString === "separator") {
          return AgPromise.resolve({ eGui: this.createSeparator() });
        } else if (typeof menuItemOrString === "string") {
          _warnOnce3(`unrecognised menu item ${menuItemOrString}`);
          return AgPromise.resolve({ eGui: null });
        } else {
          return this.addItem(menuItemOrString);
        }
      })
    ).then((elements) => {
      (elements ?? []).forEach((element) => {
        if (element?.eGui) {
          this.appendChild(element.eGui);
          if (element.comp) {
            this.menuItems.push(element.comp);
          }
        }
      });
    });
  }
  addItem(menuItemDef) {
    const menuItem = this.createManagedBean(new AgMenuItemComponent());
    return menuItem.init({
      menuItemDef,
      isAnotherSubMenuOpen: () => this.menuItems.some((m) => m.isSubMenuOpen()),
      level: this.level,
      contextParams: this.params
    }).then(() => {
      menuItem.setParentComponent(this);
      this.addManagedListeners(menuItem, {
        closeMenu: (event) => {
          this.dispatchLocalEvent(event);
        },
        menuItemActivated: (event) => {
          if (this.activeMenuItem && this.activeMenuItem !== event.menuItem) {
            this.activeMenuItem.deactivate();
          }
          this.activeMenuItem = event.menuItem;
        }
      });
      return {
        comp: menuItem,
        eGui: menuItem.getGui()
      };
    });
  }
  activateFirstItem() {
    const item = this.menuItems.filter((currentItem) => !currentItem.isDisabled())[0];
    if (!item) {
      return;
    }
    item.activate();
  }
  createSeparator() {
    const separatorHtml = (
      /* html */
      `
            <div class="ag-menu-separator" aria-hidden="true">
                <div class="ag-menu-separator-part"></div>
                <div class="ag-menu-separator-part"></div>
                <div class="ag-menu-separator-part"></div>
                <div class="ag-menu-separator-part"></div>
            </div>`
    );
    return _loadTemplate2(separatorHtml);
  }
  handleNavKey(key) {
    switch (key) {
      case KeyCode8.UP:
      case KeyCode8.DOWN: {
        const nextItem = this.findNextItem(key === KeyCode8.UP);
        if (nextItem && nextItem !== this.activeMenuItem) {
          nextItem.activate();
        }
        return;
      }
    }
    const left = this.gos.get("enableRtl") ? KeyCode8.RIGHT : KeyCode8.LEFT;
    if (key === left) {
      this.closeIfIsChild();
    } else {
      this.openChild();
    }
  }
  closeIfIsChild(e) {
    const parentItem = this.getParentComponent();
    if (parentItem && parentItem instanceof AgMenuItemComponent) {
      if (e) {
        e.preventDefault();
      }
      parentItem.closeSubMenu();
      parentItem.getGui().focus();
      return true;
    }
    return false;
  }
  openChild() {
    if (this.activeMenuItem) {
      this.activeMenuItem.openSubMenu(true);
    }
  }
  findNextItem(up) {
    const items = this.menuItems.filter((item) => !item.isDisabled());
    if (!items.length) {
      return;
    }
    if (!this.activeMenuItem) {
      return up ? _last2(items) : items[0];
    }
    if (up) {
      items.reverse();
    }
    let nextItem;
    let foundCurrent = false;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!foundCurrent) {
        if (item === this.activeMenuItem) {
          foundCurrent = true;
        }
        continue;
      }
      nextItem = item;
      break;
    }
    if (foundCurrent && !nextItem) {
      return items[0];
    }
    return nextItem || this.activeMenuItem;
  }
  destroy() {
    this.clearActiveItem();
    super.destroy();
  }
};

// enterprise-modules/core/src/widgets/agMenuPanel.ts
import { KeyCode as KeyCode9, TabGuardComp as TabGuardComp3 } from "ag-grid-community";
var AgMenuPanel = class extends TabGuardComp3 {
  constructor(wrappedComponent) {
    super();
    this.setTemplateFromElement(wrappedComponent.getGui(), void 0, void 0, true);
  }
  postConstruct() {
    this.initialiseTabGuard({
      onTabKeyDown: (e) => this.onTabKeyDown(e),
      handleKeyDown: (e) => this.handleKeyDown(e)
    });
  }
  handleKeyDown(e) {
    if (e.key === KeyCode9.ESCAPE) {
      this.closePanel();
    }
  }
  onTabKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }
    this.closePanel();
    e.preventDefault();
  }
  closePanel() {
    const menuItem = this.parentComponent;
    menuItem.closeSubMenu();
    setTimeout(() => menuItem.getGui().focus(), 0);
  }
};

// enterprise-modules/core/src/widgets/agMenuItemComponent.ts
var AgMenuItemComponent = class extends BeanStub2 {
  constructor() {
    super(...arguments);
    this.ACTIVATION_DELAY = 80;
    this.isActive = false;
    this.subMenuIsOpen = false;
    this.subMenuIsOpening = false;
    this.suppressRootStyles = true;
    this.suppressAria = true;
    this.suppressFocus = true;
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.userComponentFactory = beans.userComponentFactory;
  }
  init(params) {
    const { menuItemDef, isAnotherSubMenuOpen, level, childComponent, contextParams } = params;
    this.params = params.menuItemDef;
    this.level = level;
    this.isAnotherSubMenuOpen = isAnotherSubMenuOpen;
    this.childComponent = childComponent;
    this.contextParams = contextParams;
    this.cssClassPrefix = this.params.menuItemParams?.cssClassPrefix ?? "ag-menu-option";
    const compDetails = this.userComponentFactory.getMenuItemCompDetails(this.params, {
      ...menuItemDef,
      level,
      isAnotherSubMenuOpen,
      openSubMenu: (activateFirstItem) => this.openSubMenu(activateFirstItem),
      closeSubMenu: () => this.closeSubMenu(),
      closeMenu: (event) => this.closeMenu(event),
      updateTooltip: (tooltip, shouldDisplayTooltip) => this.refreshTooltip(tooltip, shouldDisplayTooltip),
      onItemActivated: () => this.onItemActivated()
    });
    return compDetails.newAgStackInstance().then((comp) => {
      this.menuItemComp = comp;
      const configureDefaults = comp.configureDefaults?.();
      if (configureDefaults) {
        this.configureDefaults(configureDefaults === true ? void 0 : configureDefaults);
      }
    });
  }
  addListeners(eGui, params) {
    if (!params?.suppressClick) {
      this.addManagedElementListeners(eGui, { click: (e) => this.onItemSelected(e) });
    }
    if (!params?.suppressKeyboardSelect) {
      this.addManagedElementListeners(eGui, {
        keydown: (e) => {
          if (e.key === KeyCode10.ENTER || e.key === KeyCode10.SPACE) {
            e.preventDefault();
            this.onItemSelected(e);
          }
        }
      });
    }
    if (!params?.suppressMouseDown) {
      this.addManagedElementListeners(eGui, {
        mousedown: (e) => {
          e.stopPropagation();
          e.preventDefault();
        }
      });
    }
    if (!params?.suppressMouseOver) {
      this.addManagedElementListeners(eGui, {
        mouseenter: () => this.onMouseEnter(),
        mouseleave: () => this.onMouseLeave()
      });
    }
  }
  isDisabled() {
    return !!this.params.disabled;
  }
  openSubMenu(activateFirstItem = false, event) {
    this.closeSubMenu();
    if (!this.params.subMenu) {
      return;
    }
    this.subMenuIsOpening = true;
    const ePopup = _loadTemplate3(
      /* html */
      `<div class="ag-menu" role="presentation"></div>`
    );
    this.eSubMenuGui = ePopup;
    let destroySubMenu;
    let afterGuiAttached = () => {
      this.subMenuIsOpening = false;
    };
    if (this.childComponent) {
      const menuPanel = this.createBean(new AgMenuPanel(this.childComponent));
      menuPanel.setParentComponent(this);
      const subMenuGui = menuPanel.getGui();
      const mouseEvent = "mouseenter";
      const mouseEnterListener = () => this.cancelDeactivate();
      subMenuGui.addEventListener(mouseEvent, mouseEnterListener);
      destroySubMenu = () => subMenuGui.removeEventListener(mouseEvent, mouseEnterListener);
      ePopup.appendChild(subMenuGui);
      if (this.childComponent.afterGuiAttached) {
        afterGuiAttached = () => {
          this.childComponent.afterGuiAttached();
          this.subMenuIsOpening = false;
        };
      }
    } else if (this.params.subMenu) {
      const childMenu = this.createBean(new AgMenuList(this.level + 1, this.contextParams));
      childMenu.setParentComponent(this);
      childMenu.addMenuItems(this.params.subMenu);
      ePopup.appendChild(childMenu.getGui());
      this.addManagedListeners(childMenu, { closeMenu: (e) => this.dispatchLocalEvent(e) });
      childMenu.addGuiEventListener("mouseenter", () => this.cancelDeactivate());
      destroySubMenu = () => this.destroyBean(childMenu);
      if (activateFirstItem) {
        afterGuiAttached = () => {
          childMenu.activateFirstItem();
          this.subMenuIsOpening = false;
        };
      }
    }
    const { popupService } = this;
    const positionCallback = () => {
      const eventSource = this.eGui;
      popupService.positionPopupForMenu({
        eventSource,
        ePopup
      });
      const { column, node } = this.contextParams;
      popupService.callPostProcessPopup(
        "subMenu",
        ePopup,
        eventSource,
        event instanceof MouseEvent ? event : void 0,
        column,
        node
      );
    };
    const translate = this.localeService.getLocaleTextFunc();
    const addPopupRes = popupService.addPopup({
      modal: true,
      eChild: ePopup,
      positionCallback,
      anchorToElement: this.eGui,
      ariaLabel: translate("ariaLabelSubMenu", "SubMenu"),
      afterGuiAttached
    });
    this.subMenuIsOpen = true;
    this.setAriaExpanded(true);
    this.hideSubMenu = () => {
      if (addPopupRes) {
        addPopupRes.hideFunc();
      }
      this.subMenuIsOpen = false;
      this.setAriaExpanded(false);
      destroySubMenu();
      this.menuItemComp.setExpanded?.(false);
      this.eSubMenuGui = void 0;
    };
    this.menuItemComp.setExpanded?.(true);
  }
  setAriaExpanded(expanded) {
    if (!this.suppressAria) {
      _setAriaExpanded3(this.eGui, expanded);
    }
  }
  closeSubMenu() {
    if (!this.hideSubMenu) {
      return;
    }
    this.hideSubMenu();
    this.hideSubMenu = null;
    this.setAriaExpanded(false);
  }
  isSubMenuOpen() {
    return this.subMenuIsOpen;
  }
  isSubMenuOpening() {
    return this.subMenuIsOpening;
  }
  activate(openSubMenu) {
    this.cancelActivate();
    if (this.params.disabled) {
      return;
    }
    this.isActive = true;
    if (!this.suppressRootStyles) {
      this.eGui.classList.add(`${this.cssClassPrefix}-active`);
    }
    this.menuItemComp.setActive?.(true);
    if (!this.suppressFocus) {
      this.eGui.focus({ preventScroll: true });
    }
    if (openSubMenu && this.params.subMenu) {
      window.setTimeout(() => {
        if (this.isAlive() && this.isActive) {
          this.openSubMenu();
        }
      }, 300);
    }
    this.onItemActivated();
  }
  deactivate() {
    this.cancelDeactivate();
    if (!this.suppressRootStyles) {
      this.eGui.classList.remove(`${this.cssClassPrefix}-active`);
    }
    this.menuItemComp.setActive?.(false);
    this.isActive = false;
    if (this.subMenuIsOpen) {
      this.hideSubMenu();
    }
  }
  getGui() {
    return this.menuItemComp.getGui();
  }
  getParentComponent() {
    return this.parentComponent;
  }
  setParentComponent(component) {
    this.parentComponent = component;
  }
  getSubMenuGui() {
    return this.eSubMenuGui;
  }
  onItemSelected(event) {
    this.menuItemComp.select?.();
    if (this.params.action) {
      this.getFrameworkOverrides().wrapOutgoing(
        () => this.params.action(
          this.gos.addGridCommonParams({
            ...this.contextParams
          })
        )
      );
    } else {
      this.openSubMenu(event && event.type === "keydown", event);
    }
    if (this.params.subMenu && !this.params.action || this.params.suppressCloseOnSelect) {
      return;
    }
    this.closeMenu(event);
  }
  closeMenu(event) {
    const e = {
      type: "closeMenu"
    };
    if (event) {
      if (event instanceof MouseEvent) {
        e.mouseEvent = event;
      } else {
        e.keyboardEvent = event;
      }
    }
    this.dispatchLocalEvent(e);
  }
  onItemActivated() {
    const event = {
      type: "menuItemActivated",
      menuItem: this
    };
    this.dispatchLocalEvent(event);
  }
  cancelActivate() {
    if (this.activateTimeoutId) {
      window.clearTimeout(this.activateTimeoutId);
      this.activateTimeoutId = 0;
    }
  }
  cancelDeactivate() {
    if (this.deactivateTimeoutId) {
      window.clearTimeout(this.deactivateTimeoutId);
      this.deactivateTimeoutId = 0;
    }
  }
  onMouseEnter() {
    this.cancelDeactivate();
    if (this.isAnotherSubMenuOpen()) {
      this.activateTimeoutId = window.setTimeout(() => this.activate(true), this.ACTIVATION_DELAY);
    } else {
      this.activate(true);
    }
  }
  onMouseLeave() {
    this.cancelActivate();
    if (this.isSubMenuOpen()) {
      this.deactivateTimeoutId = window.setTimeout(() => this.deactivate(), this.ACTIVATION_DELAY);
    } else {
      this.deactivate();
    }
  }
  configureDefaults(params) {
    if (!this.menuItemComp) {
      setTimeout(() => this.configureDefaults(params));
      return;
    }
    let eGui = this.menuItemComp.getGui();
    const rootElement = this.menuItemComp.getRootElement?.();
    if (rootElement) {
      if (!params?.suppressRootStyles) {
        eGui.classList.add("ag-menu-option-custom");
      }
      eGui = rootElement;
    }
    this.eGui = eGui;
    this.suppressRootStyles = !!params?.suppressRootStyles;
    if (!this.suppressRootStyles) {
      eGui.classList.add(this.cssClassPrefix);
      this.params.cssClasses?.forEach((it) => eGui.classList.add(it));
      if (this.params.disabled) {
        eGui.classList.add(`${this.cssClassPrefix}-disabled`);
      }
    }
    if (!params?.suppressTooltip) {
      this.refreshTooltip(this.params.tooltip);
    }
    this.suppressAria = !!params?.suppressAria;
    if (!this.suppressAria) {
      _setAriaRole3(eGui, "treeitem");
      _setAriaLevel(eGui, this.level + 1);
      if (this.params.disabled) {
        _setAriaDisabled(eGui, true);
      }
    }
    if (!params?.suppressTabIndex) {
      eGui.setAttribute("tabindex", "-1");
    }
    if (!this.params.disabled) {
      this.addListeners(eGui, params);
    }
    this.suppressFocus = !!params?.suppressFocus;
  }
  refreshTooltip(tooltip, shouldDisplayTooltip) {
    this.tooltip = tooltip;
    if (this.tooltipFeature) {
      this.tooltipFeature = this.destroyBean(this.tooltipFeature);
    }
    if (!tooltip || !this.menuItemComp) {
      return;
    }
    this.tooltipFeature = this.createBean(
      new TooltipFeature({
        getGui: () => this.getGui(),
        getTooltipValue: () => this.tooltip,
        getLocation: () => "menu",
        shouldDisplayTooltip
      })
    );
  }
  destroy() {
    if (this.tooltipFeature) {
      this.tooltipFeature = this.destroyBean(this.tooltipFeature);
    }
    this.menuItemComp?.destroy?.();
    super.destroy();
  }
};

// enterprise-modules/core/src/features/virtualListDragFeature.ts
import { AutoScrollService, BeanStub as BeanStub3, _radioCssClass } from "ag-grid-community";
var LIST_ITEM_HOVERED = "ag-list-item-hovered";
var VirtualListDragFeature = class extends BeanStub3 {
  constructor(comp, virtualList, params) {
    super();
    this.comp = comp;
    this.virtualList = virtualList;
    this.params = params;
    this.currentDragValue = null;
    this.lastHoveredListItem = null;
  }
  wireBeans(beans) {
    this.dragAndDropService = beans.dragAndDropService;
  }
  postConstruct() {
    this.addManagedListeners(this.params.eventSource, {
      [this.params.listItemDragStartEvent]: this.listItemDragStart.bind(this),
      [this.params.listItemDragEndEvent]: this.listItemDragEnd.bind(this)
    });
    this.createDropTarget();
    this.createAutoScrollService();
  }
  listItemDragStart(event) {
    this.currentDragValue = this.params.getCurrentDragValue(event);
    this.moveBlocked = this.params.isMoveBlocked(this.currentDragValue);
  }
  listItemDragEnd() {
    window.setTimeout(() => {
      this.currentDragValue = null;
      this.moveBlocked = false;
    }, 10);
  }
  createDropTarget() {
    const dropTarget = {
      isInterestedIn: (type) => type === this.params.dragSourceType,
      getIconName: () => this.moveBlocked ? "pinned" : "move",
      getContainer: () => this.comp.getGui(),
      onDragging: (e) => this.onDragging(e),
      onDragStop: () => this.onDragStop(),
      onDragLeave: () => this.onDragLeave(),
      onDragCancel: () => this.onDragCancel()
    };
    this.dragAndDropService.addDropTarget(dropTarget);
  }
  createAutoScrollService() {
    const virtualListGui = this.virtualList.getGui();
    this.autoScrollService = new AutoScrollService({
      scrollContainer: virtualListGui,
      scrollAxis: "y",
      getVerticalPosition: () => virtualListGui.scrollTop,
      setVerticalPosition: (position) => virtualListGui.scrollTop = position
    });
  }
  onDragging(e) {
    if (!this.currentDragValue || this.moveBlocked) {
      return;
    }
    const hoveredListItem = this.getListDragItem(e);
    const comp = this.virtualList.getComponentAt(hoveredListItem.rowIndex);
    if (!comp) {
      return;
    }
    const el = comp.getGui().parentElement;
    if (this.lastHoveredListItem && this.lastHoveredListItem.rowIndex === hoveredListItem.rowIndex && this.lastHoveredListItem.position === hoveredListItem.position) {
      return;
    }
    this.autoScrollService.check(e.event);
    this.clearHoveredItems();
    this.lastHoveredListItem = hoveredListItem;
    _radioCssClass(el, LIST_ITEM_HOVERED);
    _radioCssClass(el, `ag-item-highlight-${hoveredListItem.position}`);
  }
  getListDragItem(e) {
    const virtualListGui = this.virtualList.getGui();
    const paddingTop = parseFloat(window.getComputedStyle(virtualListGui).paddingTop);
    const rowHeight = this.virtualList.getRowHeight();
    const scrollTop = this.virtualList.getScrollTop();
    const rowIndex = Math.max(0, (e.y - paddingTop + scrollTop) / rowHeight);
    const maxLen = this.params.getNumRows(this.comp) - 1;
    const normalizedRowIndex = Math.min(maxLen, rowIndex) | 0;
    return {
      rowIndex: normalizedRowIndex,
      position: Math.round(rowIndex) > rowIndex || rowIndex > maxLen ? "bottom" : "top",
      component: this.virtualList.getComponentAt(normalizedRowIndex)
    };
  }
  onDragStop() {
    if (this.moveBlocked) {
      return;
    }
    this.params.moveItem(this.currentDragValue, this.lastHoveredListItem);
    this.clearDragProperties();
  }
  onDragCancel() {
    this.clearDragProperties();
  }
  onDragLeave() {
    this.clearDragProperties();
  }
  clearDragProperties() {
    this.clearHoveredItems();
    this.autoScrollService.ensureCleared();
  }
  clearHoveredItems() {
    const virtualListGui = this.virtualList.getGui();
    virtualListGui.querySelectorAll(`.${LIST_ITEM_HOVERED}`).forEach((el) => {
      [LIST_ITEM_HOVERED, "ag-item-highlight-top", "ag-item-highlight-bottom"].forEach((cls) => {
        el.classList.remove(cls);
      });
    });
    this.lastHoveredListItem = null;
  }
};

// enterprise-modules/core/src/widgets/tabbedLayout.ts
import {
  KeyCode as KeyCode11,
  RefPlaceholder as RefPlaceholder8,
  TabGuardComp as TabGuardComp4,
  _clearElement as _clearElement4,
  _createIconNoSpan as _createIconNoSpan7,
  _getActiveDomElement as _getActiveDomElement5,
  _getDocument as _getDocument6,
  _setAriaLabel as _setAriaLabel5,
  _setAriaRole as _setAriaRole4
} from "ag-grid-community";
function getTabbedLayoutTemplate(cssClass) {
  return (
    /* html */
    `<div class="ag-tabs ${cssClass}">
        <div data-ref="eHeader"></div>
        <div data-ref="eBody" role="presentation" class="ag-tabs-body ${cssClass ? `${cssClass}-body` : ""}"></div>
    </div>`
  );
}
var TabbedLayout = class extends TabGuardComp4 {
  constructor(params) {
    super(getTabbedLayoutTemplate(params.cssClass));
    this.eHeader = RefPlaceholder8;
    this.eBody = RefPlaceholder8;
    this.items = [];
    this.tabbedItemScrollMap = /* @__PURE__ */ new Map();
    this.params = params;
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.setupHeader();
    if (this.params.items) {
      this.params.items.forEach((item) => this.addItem(item));
    }
    this.initialiseTabGuard({
      onTabKeyDown: this.onTabKeyDown.bind(this),
      handleKeyDown: this.handleKeyDown.bind(this),
      focusInnerElement: this.focusInnerElement.bind(this),
      focusTrapActive: true
    });
    this.addDestroyFunc(() => this.activeItem?.tabbedItem?.afterDetachedCallback?.());
  }
  setupHeader() {
    const { enableCloseButton, cssClass } = this.params;
    const addCssClasses = (el, suffix) => {
      el.classList.add(`ag-tabs-${suffix}`);
      if (cssClass) {
        el.classList.add(`${cssClass}-${suffix}`);
      }
    };
    if (enableCloseButton) {
      this.setupCloseButton(addCssClasses);
      this.eTabHeader = _getDocument6(this.gos).createElement("div");
      addCssClasses(this.eHeader, "header-wrapper");
      _setAriaRole4(this.eHeader, "presentation");
      this.eHeader.appendChild(this.eTabHeader);
    } else {
      this.eTabHeader = this.eHeader;
    }
    _setAriaRole4(this.eTabHeader, "tablist");
    addCssClasses(this.eTabHeader, "header");
  }
  setupCloseButton(addCssClasses) {
    const eDocument = _getDocument6(this.gos);
    const eCloseButton = eDocument.createElement("button");
    addCssClasses(eCloseButton, "close-button");
    const eIcon = _createIconNoSpan7("close", this.gos, void 0, true);
    _setAriaLabel5(eCloseButton, this.params.closeButtonAriaLabel);
    eCloseButton.appendChild(eIcon);
    this.addManagedElementListeners(eCloseButton, { click: () => this.params.onCloseClicked?.() });
    const eCloseButtonWrapper = eDocument.createElement("div");
    addCssClasses(eCloseButtonWrapper, "close-button-wrapper");
    _setAriaRole4(eCloseButtonWrapper, "presentation");
    eCloseButtonWrapper.appendChild(eCloseButton);
    this.eHeader.appendChild(eCloseButtonWrapper);
    this.eCloseButton = eCloseButton;
  }
  handleKeyDown(e) {
    switch (e.key) {
      case KeyCode11.RIGHT:
      case KeyCode11.LEFT: {
        if (!this.eTabHeader.contains(_getActiveDomElement5(this.gos))) {
          return;
        }
        const isRightKey = e.key === KeyCode11.RIGHT;
        const isRtl = this.gos.get("enableRtl");
        const currentPosition = this.items.indexOf(this.activeItem);
        const nextPosition = isRightKey !== isRtl ? Math.min(currentPosition + 1, this.items.length - 1) : Math.max(currentPosition - 1, 0);
        if (currentPosition === nextPosition) {
          return;
        }
        e.preventDefault();
        const nextItem = this.items[nextPosition];
        this.showItemWrapper(nextItem);
        nextItem.eHeaderButton.focus();
        break;
      }
      case KeyCode11.UP:
      case KeyCode11.DOWN:
        e.stopPropagation();
        break;
    }
  }
  onTabKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }
    const { focusService, eHeader, eBody, activeItem, params } = this;
    const { suppressTrapFocus, enableCloseButton } = params;
    const activeElement = _getActiveDomElement5(this.gos);
    const target = e.target;
    const backwards = e.shiftKey;
    if (eHeader.contains(activeElement)) {
      e.preventDefault();
      if (enableCloseButton && backwards && !this.eCloseButton?.contains(activeElement)) {
        this.eCloseButton?.focus();
      } else if (suppressTrapFocus && backwards) {
        this.focusService.findFocusableElementBeforeTabGuard(_getDocument6(this.gos).body, target)?.focus();
      } else {
        this.focusBody(e.shiftKey);
      }
      return;
    }
    let nextEl = null;
    if (focusService.isTargetUnderManagedComponent(eBody, target)) {
      if (backwards) {
        nextEl = this.focusService.findFocusableElementBeforeTabGuard(eBody, target);
      }
      if (!nextEl && !suppressTrapFocus) {
        nextEl = activeItem.eHeaderButton;
      }
    }
    if (!nextEl && eBody.contains(activeElement)) {
      nextEl = focusService.findNextFocusableElement(eBody, false, backwards);
      if (!nextEl) {
        if (suppressTrapFocus && !backwards) {
          this.forceFocusOutOfContainer(backwards);
        } else if (enableCloseButton && !backwards) {
          e.preventDefault();
          this.eCloseButton?.focus();
        } else {
          e.preventDefault();
          this.focusHeader();
        }
        return;
      }
    }
    if (nextEl) {
      e.preventDefault();
      nextEl.focus();
    }
  }
  focusInnerElement(fromBottom) {
    if (fromBottom) {
      this.focusBody(true);
    } else {
      this.focusHeader();
    }
  }
  focusHeader(preventScroll) {
    this.activeItem.eHeaderButton.focus({ preventScroll });
  }
  focusBody(fromBottom) {
    this.focusService.focusInto(this.eBody, fromBottom);
  }
  setAfterAttachedParams(params) {
    this.afterAttachedParams = params;
  }
  showFirstItem() {
    if (this.items.length > 0) {
      this.showItemWrapper(this.items[0]);
    }
  }
  addItem(item) {
    const eHeaderButton = document.createElement("span");
    _setAriaRole4(eHeaderButton, "tab");
    eHeaderButton.setAttribute("tabindex", "-1");
    eHeaderButton.appendChild(item.title);
    eHeaderButton.classList.add("ag-tab");
    this.eTabHeader.appendChild(eHeaderButton);
    _setAriaLabel5(eHeaderButton, item.titleLabel);
    const wrapper = {
      tabbedItem: item,
      eHeaderButton
    };
    this.items.push(wrapper);
    eHeaderButton.addEventListener("click", this.showItemWrapper.bind(this, wrapper));
  }
  showItem(tabbedItem) {
    const itemWrapper = this.items.find((wrapper) => wrapper.tabbedItem === tabbedItem);
    if (itemWrapper) {
      this.showItemWrapper(itemWrapper);
    }
  }
  showItemWrapper(wrapper) {
    const { tabbedItem, eHeaderButton } = wrapper;
    this.params.onItemClicked?.({ item: tabbedItem });
    if (this.activeItem === wrapper) {
      this.params.onActiveItemClicked?.();
      return;
    }
    if (this.lastScrollListener) {
      this.lastScrollListener = this.lastScrollListener();
    }
    _clearElement4(this.eBody);
    tabbedItem.bodyPromise.then((body) => {
      this.eBody.appendChild(body);
      const onlyUnmanaged = !this.focusService.isKeyboardMode();
      if (!this.params.suppressFocusBodyOnOpen) {
        this.focusService.focusInto(this.eBody, false, onlyUnmanaged);
      }
      if (tabbedItem.afterAttachedCallback) {
        tabbedItem.afterAttachedCallback(this.afterAttachedParams);
      }
      if (this.params.keepScrollPosition) {
        const scrollableContainer = tabbedItem.getScrollableContainer && tabbedItem.getScrollableContainer() || body;
        [this.lastScrollListener] = this.addManagedElementListeners(scrollableContainer, {
          scroll: () => {
            this.tabbedItemScrollMap.set(tabbedItem.name, scrollableContainer.scrollTop);
          }
        });
        const scrollPosition = this.tabbedItemScrollMap.get(tabbedItem.name);
        if (scrollPosition !== void 0) {
          setTimeout(() => {
            scrollableContainer.scrollTop = scrollPosition;
          }, 0);
        }
      }
    });
    if (this.activeItem) {
      this.activeItem.eHeaderButton.classList.remove("ag-tab-selected");
      this.activeItem.tabbedItem.afterDetachedCallback?.();
    }
    eHeaderButton.classList.add("ag-tab-selected");
    this.activeItem = wrapper;
  }
};

// enterprise-modules/core/src/rendering/groupCellRenderer.ts
import { Component as Component11, RefPlaceholder as RefPlaceholder9, _setAriaRole as _setAriaRole5, _setDisplayed as _setDisplayed5 } from "ag-grid-community";

// enterprise-modules/core/src/rendering/groupCellRendererCtrl.ts
import {
  BeanStub as BeanStub4,
  CheckboxSelectionComponent,
  KeyCode as KeyCode12,
  RowDragComp,
  _cloneObject,
  _createIconNoSpan as _createIconNoSpan8,
  _getGrandTotalRow,
  _isElementInEventPath,
  _isStopPropagationForAgGrid,
  _missing,
  _removeAriaExpanded,
  _setAriaExpanded as _setAriaExpanded4,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid4,
  _warnOnce as _warnOnce4
} from "ag-grid-community";
var GroupCellRendererCtrl = class extends BeanStub4 {
  wireBeans(beans) {
    this.expressionService = beans.expressionService;
    this.valueService = beans.valueService;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.userComponentFactory = beans.userComponentFactory;
    this.ctrlsService = beans.ctrlsService;
    this.funcColsService = beans.funcColsService;
  }
  init(comp, eGui, eCheckbox, eExpanded, eContracted, compClass, params) {
    this.params = params;
    this.eGui = eGui;
    this.eCheckbox = eCheckbox;
    this.eExpanded = eExpanded;
    this.eContracted = eContracted;
    this.comp = comp;
    this.compClass = compClass;
    const { node, colDef } = params;
    const topLevelFooter = this.isTopLevelFooter();
    if (!topLevelFooter) {
      const embeddedRowMismatch = this.isEmbeddedRowMismatch();
      if (embeddedRowMismatch) {
        return;
      }
      if (node.footer && this.gos.get("groupHideOpenParents")) {
        const showRowGroup = colDef && colDef.showRowGroup;
        const rowGroupColumnId = node.rowGroupColumn && node.rowGroupColumn.getColId();
        if (showRowGroup !== rowGroupColumnId) {
          return;
        }
      }
    }
    this.setupShowingValueForOpenedParent();
    this.findDisplayedGroupNode();
    if (!topLevelFooter) {
      const showingFooterTotal = params.node.footer && params.node.rowGroupIndex === this.funcColsService.getRowGroupColumns().findIndex((c) => c.getColId() === params.colDef?.showRowGroup);
      const isAlwaysShowing = this.gos.get("groupDisplayType") != "multipleColumns" || this.gos.get("treeData");
      const showOpenGroupValue = isAlwaysShowing || this.gos.get("showOpenedGroup") && !params.node.footer && (!params.node.group || params.node.rowGroupIndex != null && params.node.rowGroupIndex > this.funcColsService.getRowGroupColumns().findIndex((c) => c.getColId() === params.colDef?.showRowGroup));
      const leafWithValues = !node.group && (this.params.colDef?.field || this.params.colDef?.valueGetter);
      const isExpandable = this.isExpandable();
      const showPivotModeLeafValue = this.columnModel.isPivotMode() && node.leafGroup && node.rowGroupColumn?.getColId() === params.column?.getColDef().showRowGroup;
      const canSkipRenderingCell = !this.showingValueForOpenedParent && !isExpandable && !leafWithValues && !showOpenGroupValue && !showingFooterTotal && !showPivotModeLeafValue;
      if (canSkipRenderingCell) {
        return;
      }
    }
    this.addExpandAndContract();
    this.addFullWidthRowDraggerIfNeeded();
    this.addCheckboxIfNeeded();
    this.addValueElement();
    this.setupIndent();
    this.refreshAriaExpanded();
  }
  getCellAriaRole() {
    const colDefAriaRole = this.params.colDef?.cellAriaRole;
    const columnColDefAriaRole = this.params.column?.getColDef().cellAriaRole;
    return colDefAriaRole || columnColDefAriaRole || "gridcell";
  }
  destroy() {
    super.destroy();
    this.expandListener = null;
  }
  refreshAriaExpanded() {
    const { node, eGridCell } = this.params;
    if (this.expandListener) {
      this.expandListener = this.expandListener();
    }
    if (!this.isExpandable()) {
      _removeAriaExpanded(eGridCell);
      return;
    }
    const listener = () => {
      _setAriaExpanded4(eGridCell, this.showingValueForOpenedParent || !!node.expanded);
    };
    [this.expandListener] = this.addManagedListeners(node, { expandedChanged: listener }) || null;
    listener();
  }
  isTopLevelFooter() {
    const totalRow = _getGrandTotalRow(this.gos);
    if (!totalRow) {
      return false;
    }
    if (this.params.value != null || this.params.node.level != -1) {
      return false;
    }
    const colDef = this.params.colDef;
    const doingFullWidth = colDef == null;
    if (doingFullWidth) {
      return true;
    }
    if (colDef.showRowGroup === true) {
      return true;
    }
    const rowGroupCols = this.funcColsService.getRowGroupColumns();
    if (!rowGroupCols || rowGroupCols.length === 0) {
      return true;
    }
    const firstRowGroupCol = rowGroupCols[0];
    return firstRowGroupCol.getId() === colDef.showRowGroup;
  }
  // if we are doing embedded full width rows, we only show the renderer when
  // in the body, or if pinning in the pinned section, or if pinning and RTL,
  // in the right section. otherwise we would have the cell repeated in each section.
  isEmbeddedRowMismatch() {
    if (!this.params.fullWidth || !this.gos.get("embedFullWidthRows")) {
      return false;
    }
    const pinnedLeftCell = this.params.pinned === "left";
    const pinnedRightCell = this.params.pinned === "right";
    const bodyCell = !pinnedLeftCell && !pinnedRightCell;
    if (this.gos.get("enableRtl")) {
      if (this.visibleColsService.isPinningLeft()) {
        return !pinnedRightCell;
      }
      return !bodyCell;
    }
    if (this.visibleColsService.isPinningLeft()) {
      return !pinnedLeftCell;
    }
    return !bodyCell;
  }
  findDisplayedGroupNode() {
    const column = this.params.column;
    const rowNode = this.params.node;
    if (this.showingValueForOpenedParent) {
      let pointer = rowNode.parent;
      while (pointer != null) {
        if (pointer.rowGroupColumn && column.isRowGroupDisplayed(pointer.rowGroupColumn.getId())) {
          this.displayedGroupNode = pointer;
          break;
        }
        pointer = pointer.parent;
      }
    }
    if (_missing(this.displayedGroupNode)) {
      this.displayedGroupNode = rowNode;
    }
  }
  setupShowingValueForOpenedParent() {
    const rowNode = this.params.node;
    const column = this.params.column;
    if (!this.gos.get("groupHideOpenParents")) {
      this.showingValueForOpenedParent = false;
      return;
    }
    if (!rowNode.groupData) {
      this.showingValueForOpenedParent = false;
      return;
    }
    const showingGroupNode = rowNode.rowGroupColumn != null;
    if (showingGroupNode) {
      const keyOfGroupingColumn = rowNode.rowGroupColumn.getId();
      const configuredToShowThisGroupLevel = column.isRowGroupDisplayed(keyOfGroupingColumn);
      if (configuredToShowThisGroupLevel) {
        this.showingValueForOpenedParent = false;
        return;
      }
    }
    const valPresent = rowNode.groupData[column.getId()] != null;
    this.showingValueForOpenedParent = valPresent;
  }
  addValueElement() {
    if (this.displayedGroupNode.footer) {
      this.addFooterValue();
    } else {
      this.addGroupValue();
      this.addChildCount();
    }
  }
  addGroupValue() {
    const paramsAdjusted = this.adjustParamsWithDetailsFromRelatedColumn();
    const innerCompDetails = this.getInnerCompDetails(paramsAdjusted);
    const { valueFormatted, value } = paramsAdjusted;
    let valueWhenNoRenderer = valueFormatted;
    if (valueWhenNoRenderer == null) {
      const isGroupColForNode = this.displayedGroupNode.rowGroupColumn && this.params.column?.isRowGroupDisplayed(this.displayedGroupNode.rowGroupColumn.getId());
      if (this.displayedGroupNode.key === "" && this.displayedGroupNode.group && isGroupColForNode) {
        const localeTextFunc = this.localeService.getLocaleTextFunc();
        valueWhenNoRenderer = localeTextFunc("blanks", "(Blanks)");
      } else {
        valueWhenNoRenderer = value ?? null;
      }
    }
    this.comp.setInnerRenderer(innerCompDetails, valueWhenNoRenderer);
  }
  adjustParamsWithDetailsFromRelatedColumn() {
    const relatedColumn = this.displayedGroupNode.rowGroupColumn;
    const column = this.params.column;
    if (!relatedColumn) {
      return this.params;
    }
    const notFullWidth = column != null;
    if (notFullWidth) {
      const showingThisRowGroup = column.isRowGroupDisplayed(relatedColumn.getId());
      if (!showingThisRowGroup) {
        return this.params;
      }
    }
    const params = this.params;
    const { value, node } = this.params;
    const valueFormatted = this.valueService.formatValue(relatedColumn, node, value);
    const paramsAdjusted = {
      ...params,
      valueFormatted
    };
    return paramsAdjusted;
  }
  addFooterValue() {
    let footerValueGetter = this.params.totalValueGetter;
    if (!footerValueGetter) {
      const legacyGetter = this.params.footerValueGetter;
      if (legacyGetter) {
        footerValueGetter = legacyGetter;
        _warnOnce4("As of v31.3, footerValueGetter is deprecated. Use `totalValueGetter` instead.");
      }
    }
    let footerValue = "";
    if (footerValueGetter) {
      const paramsClone = _cloneObject(this.params);
      paramsClone.value = this.params.value;
      if (typeof footerValueGetter === "function") {
        footerValue = footerValueGetter(paramsClone);
      } else if (typeof footerValueGetter === "string") {
        footerValue = this.expressionService.evaluate(footerValueGetter, paramsClone);
      } else {
        _warnOnce4("footerValueGetter should be either a function or a string (expression)");
      }
    } else {
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      const footerTotalPrefix = localeTextFunc("footerTotal", "Total");
      footerValue = footerTotalPrefix + " " + (this.params.value != null ? this.params.value : "");
    }
    const innerCompDetails = this.getInnerCompDetails(this.params);
    this.comp.setInnerRenderer(innerCompDetails, footerValue);
  }
  getInnerCompDetails(params) {
    if (params.fullWidth) {
      return this.userComponentFactory.getFullWidthGroupRowInnerCellRenderer(
        this.gos.get("groupRowRendererParams"),
        params
      );
    }
    const innerCompDetails = this.userComponentFactory.getInnerRendererDetails(params, params);
    const isGroupRowRenderer = (details) => details && details.componentClass == this.compClass;
    if (innerCompDetails && !isGroupRowRenderer(innerCompDetails)) {
      return innerCompDetails;
    }
    const relatedColumn = this.displayedGroupNode.rowGroupColumn;
    const relatedColDef = relatedColumn ? relatedColumn.getColDef() : void 0;
    if (!relatedColDef) {
      return;
    }
    const relatedCompDetails = this.userComponentFactory.getCellRendererDetails(relatedColDef, params);
    if (relatedCompDetails && !isGroupRowRenderer(relatedCompDetails)) {
      return relatedCompDetails;
    }
    if (isGroupRowRenderer(relatedCompDetails) && relatedColDef.cellRendererParams && relatedColDef.cellRendererParams.innerRenderer) {
      const res = this.userComponentFactory.getInnerRendererDetails(relatedColDef.cellRendererParams, params);
      return res;
    }
  }
  addChildCount() {
    if (this.params.suppressCount) {
      return;
    }
    this.addManagedListeners(this.displayedGroupNode, {
      allChildrenCountChanged: this.updateChildCount.bind(this)
    });
    this.updateChildCount();
  }
  updateChildCount() {
    const allChildrenCount = this.displayedGroupNode.allChildrenCount;
    const showingGroupForThisNode = this.isShowRowGroupForThisRow();
    const showCount = showingGroupForThisNode && allChildrenCount != null && allChildrenCount >= 0;
    const countString = showCount ? `(${allChildrenCount})` : ``;
    this.comp.setChildCount(countString);
  }
  isShowRowGroupForThisRow() {
    if (this.gos.get("treeData")) {
      return true;
    }
    const rowGroupColumn = this.displayedGroupNode.rowGroupColumn;
    if (!rowGroupColumn) {
      return false;
    }
    const column = this.params.column;
    const thisColumnIsInterested = column == null || column.isRowGroupDisplayed(rowGroupColumn.getId());
    return thisColumnIsInterested;
  }
  addExpandAndContract() {
    const params = this.params;
    const setupIcon = (iconName, element) => {
      const icon = _createIconNoSpan8(iconName, this.gos, null);
      if (icon) {
        element.appendChild(icon);
        this.addDestroyFunc(() => element.removeChild(icon));
      }
    };
    setupIcon("groupExpanded", this.eExpanded);
    setupIcon("groupContracted", this.eContracted);
    const eGroupCell = params.eGridCell;
    const isDoubleClickEdit = this.params.column?.isCellEditable(params.node) && this.gos.get("enableGroupEdit");
    if (!isDoubleClickEdit && this.isExpandable() && !params.suppressDoubleClickExpand) {
      this.addManagedListeners(eGroupCell, { dblclick: this.onCellDblClicked.bind(this) });
    }
    this.addManagedListeners(this.eExpanded, { click: this.onExpandClicked.bind(this) });
    this.addManagedListeners(this.eContracted, { click: this.onExpandClicked.bind(this) });
    this.addManagedListeners(eGroupCell, { keydown: this.onKeyDown.bind(this) });
    this.addManagedListeners(params.node, { expandedChanged: this.showExpandAndContractIcons.bind(this) });
    this.showExpandAndContractIcons();
    const expandableChangedListener = this.onRowNodeIsExpandableChanged.bind(this);
    this.addManagedListeners(this.displayedGroupNode, {
      allChildrenCountChanged: expandableChangedListener,
      masterChanged: expandableChangedListener,
      groupChanged: expandableChangedListener,
      hasChildrenChanged: expandableChangedListener
    });
  }
  onExpandClicked(mouseEvent) {
    if (_isStopPropagationForAgGrid(mouseEvent)) {
      return;
    }
    _stopPropagationForAgGrid4(mouseEvent);
    this.onExpandOrContract(mouseEvent);
  }
  onExpandOrContract(e) {
    const rowNode = this.displayedGroupNode;
    const nextExpandState = !rowNode.expanded;
    if (!nextExpandState && rowNode.sticky) {
      this.scrollToStickyNode(rowNode);
    }
    rowNode.setExpanded(nextExpandState, e);
  }
  scrollToStickyNode(rowNode) {
    const gridBodyCtrl = this.ctrlsService.getGridBodyCtrl();
    const scrollFeature = gridBodyCtrl.getScrollFeature();
    scrollFeature.setVerticalScrollPosition(rowNode.rowTop - rowNode.stickyRowTop);
  }
  isExpandable() {
    if (this.showingValueForOpenedParent) {
      return true;
    }
    const rowNode = this.displayedGroupNode;
    const reducedLeafNode = this.columnModel.isPivotMode() && rowNode.leafGroup;
    const expandableGroup = rowNode.isExpandable() && !rowNode.footer && !reducedLeafNode;
    if (!expandableGroup) {
      return false;
    }
    const column = this.params.column;
    const displayingForOneColumnOnly = column != null && typeof column.getColDef().showRowGroup === "string";
    if (displayingForOneColumnOnly) {
      const showing = this.isShowRowGroupForThisRow();
      return showing;
    }
    return true;
  }
  showExpandAndContractIcons() {
    const { params, displayedGroupNode: displayedGroup, columnModel } = this;
    const { node } = params;
    const isExpandable = this.isExpandable();
    if (isExpandable) {
      const expanded = this.showingValueForOpenedParent ? true : node.expanded;
      this.comp.setExpandedDisplayed(expanded);
      this.comp.setContractedDisplayed(!expanded);
    } else {
      this.comp.setExpandedDisplayed(false);
      this.comp.setContractedDisplayed(false);
    }
    const pivotMode = columnModel.isPivotMode();
    const pivotModeAndLeafGroup = pivotMode && displayedGroup.leafGroup;
    const addExpandableCss = isExpandable && !pivotModeAndLeafGroup;
    const isTotalFooterNode = node.footer && node.level === -1;
    this.comp.addOrRemoveCssClass("ag-cell-expandable", addExpandableCss);
    this.comp.addOrRemoveCssClass("ag-row-group", addExpandableCss);
    if (pivotMode) {
      this.comp.addOrRemoveCssClass("ag-pivot-leaf-group", !!pivotModeAndLeafGroup);
    } else if (!isTotalFooterNode) {
      this.comp.addOrRemoveCssClass("ag-row-group-leaf-indent", !addExpandableCss);
    }
  }
  onRowNodeIsExpandableChanged() {
    this.showExpandAndContractIcons();
    this.setIndent();
    this.refreshAriaExpanded();
  }
  setupIndent() {
    const node = this.params.node;
    const suppressPadding = this.params.suppressPadding;
    if (!suppressPadding) {
      this.addManagedListeners(node, { uiLevelChanged: this.setIndent.bind(this) });
      this.setIndent();
    }
  }
  setIndent() {
    if (this.gos.get("groupHideOpenParents")) {
      return;
    }
    const params = this.params;
    const rowNode = params.node;
    const fullWithRow = !!params.colDef;
    const treeData = this.gos.get("treeData");
    const manyDimensionThisColumn = !fullWithRow || treeData || params.colDef.showRowGroup === true;
    const paddingCount = manyDimensionThisColumn ? rowNode.uiLevel : 0;
    if (this.indentClass) {
      this.comp.addOrRemoveCssClass(this.indentClass, false);
    }
    this.indentClass = "ag-row-group-indent-" + paddingCount;
    this.comp.addOrRemoveCssClass(this.indentClass, true);
    this.eGui.style.setProperty("--ag-indentation-level", String(paddingCount));
  }
  addFullWidthRowDraggerIfNeeded() {
    if (!this.params.fullWidth || !this.params.rowDrag) {
      return;
    }
    const rowDragComp = new RowDragComp(() => this.params.value, this.params.node);
    this.createManagedBean(rowDragComp);
    this.eGui.insertAdjacentElement("afterbegin", rowDragComp.getGui());
  }
  isUserWantsSelected() {
    const paramsCheckbox = this.params.checkbox;
    return typeof paramsCheckbox === "function" || paramsCheckbox === true;
  }
  addCheckboxIfNeeded() {
    const rowNode = this.displayedGroupNode;
    const checkboxNeeded = this.isUserWantsSelected() && // footers cannot be selected
    !rowNode.footer && // pinned rows cannot be selected
    !rowNode.rowPinned && // details cannot be selected
    !rowNode.detail;
    if (checkboxNeeded) {
      const cbSelectionComponent = new CheckboxSelectionComponent();
      this.createBean(cbSelectionComponent);
      cbSelectionComponent.init({
        rowNode: this.params.node,
        // when groupHideOpenParents = true and group expanded, we want the checkbox to refer to leaf node state (not group node state)
        column: this.params.column,
        overrides: {
          isVisible: this.params.checkbox,
          callbackParams: this.params,
          removeHidden: true
        }
      });
      this.eCheckbox.appendChild(cbSelectionComponent.getGui());
      this.addDestroyFunc(() => {
        this.eCheckbox.removeChild(cbSelectionComponent.getGui());
        this.destroyBean(cbSelectionComponent);
      });
    }
    this.comp.setCheckboxVisible(checkboxNeeded);
  }
  onKeyDown(event) {
    const isEnterKey = event.key === KeyCode12.ENTER;
    if (!isEnterKey || this.params.suppressEnterExpand) {
      return;
    }
    const cellEditable = this.params.column && this.params.column.isCellEditable(this.params.node);
    if (cellEditable) {
      return;
    }
    this.onExpandOrContract(event);
  }
  onCellDblClicked(mouseEvent) {
    if (_isStopPropagationForAgGrid(mouseEvent)) {
      return;
    }
    const targetIsExpandIcon = _isElementInEventPath(this.eExpanded, mouseEvent) || _isElementInEventPath(this.eContracted, mouseEvent);
    if (!targetIsExpandIcon) {
      this.onExpandOrContract(mouseEvent);
    }
  }
};

// enterprise-modules/core/src/rendering/groupCellRenderer.ts
var groupTemplate = (
  /* html */
  `<span class="ag-cell-wrapper">
        <span class="ag-group-expanded" data-ref="eExpanded"></span>
        <span class="ag-group-contracted" data-ref="eContracted"></span>
        <span class="ag-group-checkbox ag-invisible" data-ref="eCheckbox"></span>
        <span class="ag-group-value" data-ref="eValue"></span>
        <span class="ag-group-child-count" data-ref="eChildCount"></span>
    </span>`
);
var GroupCellRenderer = class extends Component11 {
  constructor() {
    super(groupTemplate);
    this.eExpanded = RefPlaceholder9;
    this.eContracted = RefPlaceholder9;
    this.eCheckbox = RefPlaceholder9;
    this.eValue = RefPlaceholder9;
    this.eChildCount = RefPlaceholder9;
  }
  init(params) {
    const compProxy = {
      setInnerRenderer: (compDetails, valueToDisplay) => this.setRenderDetails(compDetails, valueToDisplay),
      setChildCount: (count) => this.eChildCount.textContent = count,
      addOrRemoveCssClass: (cssClass, value) => this.addOrRemoveCssClass(cssClass, value),
      setContractedDisplayed: (expanded) => _setDisplayed5(this.eContracted, expanded),
      setExpandedDisplayed: (expanded) => _setDisplayed5(this.eExpanded, expanded),
      setCheckboxVisible: (visible) => this.eCheckbox.classList.toggle("ag-invisible", !visible)
    };
    const ctrl = this.createManagedBean(new GroupCellRendererCtrl());
    const fullWidth = !params.colDef;
    const eGui = this.getGui();
    ctrl.init(compProxy, eGui, this.eCheckbox, this.eExpanded, this.eContracted, this.constructor, params);
    if (fullWidth) {
      _setAriaRole5(eGui, ctrl.getCellAriaRole());
    }
  }
  setRenderDetails(compDetails, valueToDisplay) {
    if (compDetails) {
      const componentPromise = compDetails.newAgStackInstance();
      if (componentPromise == null) {
        return;
      }
      componentPromise.then((comp) => {
        if (!comp) {
          return;
        }
        const destroyComp = () => this.destroyBean(comp);
        if (this.isAlive()) {
          this.eValue.appendChild(comp.getGui());
          this.addDestroyFunc(destroyComp);
        } else {
          destroyComp();
        }
      });
    } else {
      this.eValue.innerText = valueToDisplay;
    }
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to have public here instead of private or protected
  destroy() {
    this.destroyBean(this.innerCellRenderer);
    super.destroy();
  }
  refresh() {
    return false;
  }
};

// enterprise-modules/advanced-filter/dist/package/main.esm.mjs
import { BeanStub as BeanStub5, _exists as _exists3, _parseDateTimeFromString, _serialiseDate, _toStringOrNull } from "ag-grid-community";
import { BeanStub as BeanStub52, _exists as _exists7, _isClientSideRowModel, _isServerSideRowModel, _warnOnce as _warnOnce5 } from "ag-grid-community";
import { BeanStub as BeanStub42, _getAbsoluteHeight, _getAbsoluteWidth, _removeFromParent as _removeFromParent2 } from "ag-grid-community";
import { Component as Component32, RefPlaceholder as RefPlaceholder32, _createIconNoSpan as _createIconNoSpan9, _makeNull as _makeNull2, _setDisabled } from "ag-grid-community";
import {
  AgInputTextFieldSelector as AgInputTextFieldSelector2,
  Component as Component22,
  KeyCode as KeyCode22,
  RefPlaceholder as RefPlaceholder22,
  _isNothingFocused as _isNothingFocused2,
  _makeNull
} from "ag-grid-community";
import { KeyCode as KeyCode13, PopupComponent, RefPlaceholder as RefPlaceholder10, _exists as _exists32, _fuzzySuggestions as _fuzzySuggestions2 } from "ag-grid-community";
import { Component as Component12, _escapeString as _escapeString4, _exists as _exists22 } from "ag-grid-community";
import {
  Component as Component42,
  KeyCode as KeyCode32,
  _clearElement as _clearElement5,
  _getActiveDomElement as _getActiveDomElement6,
  _setAriaColIndex,
  _setAriaColSpan,
  _setAriaRole as _setAriaRole6,
  _setAriaRowIndex,
  _setDisplayed as _setDisplayed6
} from "ag-grid-community";
import { Component as Component92, RefPlaceholder as RefPlaceholder72, TooltipFeature as TooltipFeature3, _exists as _exists6, _setDisabled as _setDisabled2 } from "ag-grid-community";
import { BeanStub as BeanStub22, DragSourceType } from "ag-grid-community";
import { Component as Component52, RefPlaceholder as RefPlaceholder42, TooltipFeature as TooltipFeature2, _setAriaLabel as _setAriaLabel22, _setAriaLevel as _setAriaLevel2 } from "ag-grid-community";
import {
  AgInputTextFieldSelector as AgInputTextFieldSelector22,
  _setAriaLabel as _setAriaLabel6,
  _setAriaLabelledBy,
  _setDisplayed as _setDisplayed22,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid5
} from "ag-grid-community";
import {
  BeanStub as BeanStub32,
  KeyCode as KeyCode42,
  _getActiveDomElement as _getActiveDomElement22,
  _isStopPropagationForAgGrid as _isStopPropagationForAgGrid2,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid22
} from "ag-grid-community";
import {
  DragSourceType as DragSourceType2,
  KeyCode as KeyCode62,
  RefPlaceholder as RefPlaceholder62,
  TabGuardComp as TabGuardComp5,
  TooltipFeature as TooltipFeature22,
  _createIconNoSpan as _createIconNoSpan22,
  _removeAriaExpanded as _removeAriaExpanded2,
  _setAriaDisabled as _setAriaDisabled2,
  _setAriaExpanded as _setAriaExpanded5,
  _setAriaLabel as _setAriaLabel52,
  _setAriaLevel as _setAriaLevel22,
  _setDisplayed as _setDisplayed42,
  _setVisible,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid52
} from "ag-grid-community";
import { Component as Component62, _exists as _exists4, _removeFromParent } from "ag-grid-community";
import {
  AgInputDateField,
  AgInputNumberField,
  AgInputTextField,
  Component as Component72,
  KeyCode as KeyCode52,
  RefPlaceholder as RefPlaceholder52,
  _exists as _exists5,
  _setAriaDescribedBy,
  _setAriaLabel as _setAriaLabel32,
  _setDisplayed as _setDisplayed32,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid32
} from "ag-grid-community";
import { Component as Component82 } from "ag-grid-community";
import {
  AgInputTextFieldSelector as AgInputTextFieldSelector3,
  _setAriaLabel as _setAriaLabel42,
  _setAriaLabelledBy as _setAriaLabelledBy2,
  _stopPropagationForAgGrid as _stopPropagationForAgGrid42
} from "ag-grid-community";
function getAdvancedFilterModel(beans) {
  return beans.filterManager?.getAdvancedFilterModel() ?? null;
}
function setAdvancedFilterModel(beans, advancedFilterModel) {
  beans.filterManager?.setAdvancedFilterModel(advancedFilterModel);
}
function showAdvancedFilterBuilder(beans) {
  beans.filterManager?.toggleAdvancedFilterBuilder(true, "api");
}
function hideAdvancedFilterBuilder(beans) {
  beans.filterManager?.toggleAdvancedFilterBuilder(false, "api");
}
var ADVANCED_FILTER_LOCALE_TEXT = {
  ariaAdvancedFilterBuilderItem: (variableValues) => `${variableValues[0]}. Level ${variableValues[1]}. Press ENTER to edit`,
  ariaAdvancedFilterBuilderItemValidation: (variableValues) => `${variableValues[0]}. Level ${variableValues[1]}. ${variableValues[2]} Press ENTER to edit`,
  ariaAdvancedFilterBuilderList: "Advanced Filter Builder List",
  ariaAdvancedFilterBuilderFilterItem: "Filter Condition",
  ariaAdvancedFilterBuilderGroupItem: "Filter Group",
  ariaAdvancedFilterBuilderColumn: "Column",
  ariaAdvancedFilterBuilderOption: "Option",
  ariaAdvancedFilterBuilderValue: "Value",
  ariaAdvancedFilterBuilderJoinOperator: "Join Operator",
  ariaAdvancedFilterInput: "Advanced Filter Input",
  ariaLabelAdvancedFilterAutocomplete: "Advanced Filter Autocomplete",
  advancedFilterContains: "contains",
  advancedFilterNotContains: "does not contain",
  advancedFilterTextEquals: "equals",
  advancedFilterTextNotEqual: "does not equal",
  advancedFilterStartsWith: "begins with",
  advancedFilterEndsWith: "ends with",
  advancedFilterBlank: "is blank",
  advancedFilterNotBlank: "is not blank",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "is true",
  advancedFilterFalse: "is false",
  advancedFilterAnd: "AND",
  advancedFilterOr: "OR",
  advancedFilterApply: "Apply",
  advancedFilterBuilder: "Builder",
  advancedFilterValidationMissingColumn: "Column is missing",
  advancedFilterValidationMissingOption: "Option is missing",
  advancedFilterValidationMissingValue: "Value is missing",
  advancedFilterValidationInvalidColumn: "Column not found",
  advancedFilterValidationInvalidOption: "Option not found",
  advancedFilterValidationMissingQuote: "Value is missing an end quote",
  advancedFilterValidationNotANumber: "Value is not a number",
  advancedFilterValidationInvalidDate: "Value is not a valid date",
  advancedFilterValidationMissingCondition: "Condition is missing",
  advancedFilterValidationJoinOperatorMismatch: "Join operators within a condition must be the same",
  advancedFilterValidationInvalidJoinOperator: "Join operator not found",
  advancedFilterValidationMissingEndBracket: "Missing end bracket",
  advancedFilterValidationExtraEndBracket: "Too many end brackets",
  advancedFilterValidationMessage: (variableValues) => `Expression has an error. ${variableValues[0]} - ${variableValues[1]}.`,
  advancedFilterValidationMessageAtEnd: (variableValues) => `Expression has an error. ${variableValues[0]} at end of expression.`,
  advancedFilterBuilderTitle: "Advanced Filter",
  advancedFilterBuilderApply: "Apply",
  advancedFilterBuilderCancel: "Cancel",
  advancedFilterBuilderAddButtonTooltip: "Add Filter or Group",
  advancedFilterBuilderRemoveButtonTooltip: "Remove",
  advancedFilterBuilderMoveUpButtonTooltip: "Move Up",
  advancedFilterBuilderMoveDownButtonTooltip: "Move Down",
  advancedFilterBuilderAddJoin: "Add Group",
  advancedFilterBuilderAddCondition: "Add Filter",
  advancedFilterBuilderSelectColumn: "Select a column",
  advancedFilterBuilderSelectOption: "Select an option",
  advancedFilterBuilderEnterValue: "Enter a value...",
  advancedFilterBuilderValidationAlreadyApplied: "Current filter already applied.",
  advancedFilterBuilderValidationIncomplete: "Not all conditions are complete.",
  advancedFilterBuilderValidationSelectColumn: "Must select a column.",
  advancedFilterBuilderValidationSelectOption: "Must select an option.",
  advancedFilterBuilderValidationEnterValue: "Must enter a value."
};
function getSearchString(value, position, endPosition) {
  if (!value) {
    return "";
  }
  const numChars = endPosition - position;
  return numChars ? value.slice(0, value.length - numChars) : value;
}
function updateExpression(expression, startPosition, endPosition, updatedValuePart, appendSpace, appendQuote, empty) {
  const secondPartStartPosition = endPosition + (!expression.length || empty ? 0 : 1);
  let positionOffset = 0;
  if (appendSpace) {
    if (expression[secondPartStartPosition] === " ") {
      positionOffset = 1;
    } else {
      updatedValuePart += " ";
      if (appendQuote) {
        updatedValuePart += `"`;
      }
    }
  }
  const updatedValue = expression.slice(0, startPosition) + updatedValuePart + expression.slice(secondPartStartPosition);
  return { updatedValue, updatedPosition: startPosition + updatedValuePart.length + positionOffset };
}
function findStartPosition(expression, position, endPosition) {
  let startPosition = position;
  while (startPosition < endPosition) {
    const char = expression[startPosition];
    if (char !== " ") {
      break;
    }
    startPosition++;
  }
  return startPosition;
}
function findEndPosition(expression, position, includeCloseBracket, isStartPositionUnknown) {
  let endPosition = position;
  let isEmpty = false;
  while (endPosition < expression.length) {
    const char = expression[endPosition];
    if (char === "(") {
      if (isStartPositionUnknown && expression[endPosition - 1] === " ") {
        isEmpty = true;
      } else {
        endPosition = endPosition - 1;
      }
      break;
    } else if (char === " " || includeCloseBracket && char === ")") {
      endPosition = endPosition - 1;
      break;
    }
    endPosition++;
  }
  return { endPosition, isEmpty };
}
function checkAndUpdateExpression(params, userValue, displayValue, endPosition) {
  if (displayValue !== userValue) {
    params.expression = updateExpression(
      params.expression,
      endPosition - userValue.length + 1,
      endPosition,
      displayValue
    ).updatedValue;
  }
}
function escapeQuotes(value) {
  return value.replace(/(['"])/, "\\$1");
}
var ColumnParser = class {
  constructor(params, startPosition) {
    this.params = params;
    this.startPosition = startPosition;
    this.type = "column";
    this.valid = true;
    this.hasStartChar = false;
    this.hasEndChar = false;
    this.colName = "";
  }
  parse(char, position) {
    if (char === ColFilterExpressionParser.COL_START_CHAR && !this.colName) {
      this.hasStartChar = true;
    } else if (char === ColFilterExpressionParser.COL_END_CHAR && this.hasStartChar) {
      const isMatch = this.parseColumn(false, position);
      if (isMatch) {
        this.hasEndChar = true;
        return false;
      } else {
        this.colName += char;
      }
    } else {
      this.colName += char;
    }
    return void 0;
  }
  getDisplayValue() {
    return (this.hasStartChar ? ColFilterExpressionParser.COL_START_CHAR : "") + this.colName + (this.hasEndChar ? ColFilterExpressionParser.COL_END_CHAR : "");
  }
  getColId() {
    return this.colId;
  }
  complete(position) {
    this.parseColumn(true, position);
  }
  getValidationError() {
    return this.valid ? null : {
      message: this.params.advancedFilterExpressionService.translate(
        "advancedFilterValidationInvalidColumn"
      ),
      startPosition: this.startPosition,
      endPosition: this.endPosition ?? this.params.expression.length - 1
    };
  }
  parseColumn(fromComplete, endPosition) {
    this.endPosition = endPosition;
    const colValue = this.params.advancedFilterExpressionService.getColId(this.colName);
    if (colValue && this.hasStartChar) {
      this.colId = colValue.colId;
      checkAndUpdateExpression(this.params, this.colName, colValue.columnName, endPosition - 1);
      this.colName = colValue.columnName;
      this.column = this.params.columnModel.getColDefCol(this.colId);
      if (this.column) {
        this.baseCellDataType = this.params.dataTypeService?.getBaseDataType(this.column) ?? "text";
        return true;
      }
    }
    if (fromComplete) {
      this.valid = false;
    }
    this.baseCellDataType = "text";
    return false;
  }
};
var OperatorParser = class {
  constructor(params, startPosition, baseCellDataType) {
    this.params = params;
    this.startPosition = startPosition;
    this.baseCellDataType = baseCellDataType;
    this.type = "operator";
    this.valid = true;
    this.expectedNumOperands = 0;
    this.operator = "";
  }
  parse(char, position) {
    if (char === " " || char === ")") {
      const isMatch = this.parseOperator(false, position - 1);
      if (isMatch) {
        return true;
      } else {
        this.operator += char;
      }
    } else {
      this.operator += char;
    }
    return void 0;
  }
  complete(position) {
    this.parseOperator(true, position);
  }
  getValidationError() {
    return this.valid ? null : {
      message: this.params.advancedFilterExpressionService.translate(
        "advancedFilterValidationInvalidOption"
      ),
      startPosition: this.startPosition,
      endPosition: this.endPosition ?? this.params.expression.length - 1
    };
  }
  getDisplayValue() {
    return this.operator;
  }
  getOperatorKey() {
    return this.parsedOperator;
  }
  parseOperator(fromComplete, endPosition) {
    const operatorForType = this.params.advancedFilterExpressionService.getDataTypeExpressionOperator(
      this.baseCellDataType
    );
    const parsedOperator = operatorForType.findOperator(this.operator);
    this.endPosition = endPosition;
    if (parsedOperator) {
      this.parsedOperator = parsedOperator;
      const operator = operatorForType.operators[parsedOperator];
      this.expectedNumOperands = operator.numOperands;
      const operatorDisplayValue = operator.displayValue;
      checkAndUpdateExpression(this.params, this.operator, operatorDisplayValue, endPosition);
      this.operator = operatorDisplayValue;
      return true;
    }
    const isPartialMatch = parsedOperator === null;
    if (fromComplete || !isPartialMatch) {
      this.valid = false;
    }
    return false;
  }
};
var OperandParser = class {
  constructor(params, startPosition, baseCellDataType, column) {
    this.params = params;
    this.startPosition = startPosition;
    this.baseCellDataType = baseCellDataType;
    this.column = column;
    this.type = "operand";
    this.valid = true;
    this.operand = "";
    this.validationMessage = null;
  }
  parse(char, position) {
    if (char === " ") {
      if (this.quotes) {
        this.operand += char;
      } else {
        this.parseOperand(false, position);
        return true;
      }
    } else if (char === ")") {
      if (this.baseCellDataType === "number" || !this.quotes) {
        this.parseOperand(false, position - 1);
        return true;
      } else {
        this.operand += char;
      }
    } else if (!this.operand && !this.quotes && (char === `'` || char === `"`)) {
      this.quotes = char;
    } else if (this.quotes && char === this.quotes) {
      this.parseOperand(false, position);
      return false;
    } else {
      this.operand += char;
    }
    return void 0;
  }
  complete(position) {
    this.parseOperand(true, position);
  }
  getValidationError() {
    return this.validationMessage ? {
      message: this.validationMessage,
      startPosition: this.startPosition,
      endPosition: this.endPosition ?? this.params.expression.length - 1
    } : null;
  }
  getRawValue() {
    return this.operand;
  }
  getModelValue() {
    return this.modelValue;
  }
  parseOperand(fromComplete, position) {
    const { advancedFilterExpressionService } = this.params;
    this.endPosition = position;
    this.modelValue = this.operand;
    if (fromComplete && this.quotes) {
      this.valid = false;
      this.validationMessage = advancedFilterExpressionService.translate("advancedFilterValidationMissingQuote");
    } else if (this.modelValue === "") {
      this.valid = false;
      this.validationMessage = advancedFilterExpressionService.translate("advancedFilterValidationMissingValue");
    } else {
      const modelValue = advancedFilterExpressionService.getOperandModelValue(
        this.operand,
        this.baseCellDataType,
        this.column
      );
      if (modelValue != null) {
        this.modelValue = modelValue;
      }
      switch (this.baseCellDataType) {
        case "number":
          if (this.quotes || isNaN(this.modelValue)) {
            this.valid = false;
            this.validationMessage = advancedFilterExpressionService.translate(
              "advancedFilterValidationNotANumber"
            );
          }
          break;
        case "date":
        case "dateString":
          if (modelValue == null) {
            this.valid = false;
            this.validationMessage = advancedFilterExpressionService.translate(
              "advancedFilterValidationInvalidDate"
            );
          }
          break;
      }
    }
  }
};
var ColFilterExpressionParser = class {
  constructor(params, startPosition) {
    this.params = params;
    this.startPosition = startPosition;
    this.isAwaiting = true;
  }
  parseExpression() {
    let i = this.startPosition;
    const { expression } = this.params;
    while (i < expression.length) {
      const char = expression[i];
      if (char === " " && this.isAwaiting) {
      } else {
        this.isAwaiting = false;
        if (!this.parser) {
          let parser;
          if (!this.columnParser) {
            this.columnParser = new ColumnParser(this.params, i);
            parser = this.columnParser;
          } else if (!this.operatorParser) {
            this.operatorParser = new OperatorParser(this.params, i, this.columnParser.baseCellDataType);
            parser = this.operatorParser;
          } else {
            this.operandParser = new OperandParser(
              this.params,
              i,
              this.columnParser.baseCellDataType,
              this.columnParser.column
            );
            parser = this.operandParser;
          }
          this.parser = parser;
        }
        const hasCompletedOnPrevChar = this.parser.parse(char, i);
        if (hasCompletedOnPrevChar != null) {
          if (this.isComplete()) {
            return this.returnEndPosition(hasCompletedOnPrevChar ? i - 1 : i, true);
          }
          this.parser = void 0;
          this.isAwaiting = true;
        }
      }
      i++;
    }
    this.parser?.complete?.(i - 1);
    return this.returnEndPosition(i);
  }
  isValid() {
    return this.isComplete() && this.columnParser.valid && this.operatorParser.valid && (!this.operandParser || this.operandParser.valid);
  }
  getValidationError() {
    const validationError = this.columnParser?.getValidationError() ?? this.operatorParser?.getValidationError() ?? this.operandParser?.getValidationError();
    if (validationError) {
      return validationError;
    }
    const endPosition = this.params.expression.length;
    let translateKey;
    if (!this.columnParser) {
      translateKey = "advancedFilterValidationMissingColumn";
    } else if (!this.operatorParser) {
      translateKey = "advancedFilterValidationMissingOption";
    } else if (this.operatorParser.expectedNumOperands && !this.operandParser) {
      translateKey = "advancedFilterValidationMissingValue";
    }
    if (translateKey) {
      return {
        message: this.params.advancedFilterExpressionService.translate(translateKey),
        startPosition: endPosition,
        endPosition
      };
    }
    return null;
  }
  getFunctionString(params) {
    return this.getFunctionCommon(params, (operandIndex, operatorIndex, colId, evaluatorParamsIndex) => {
      const escapedColId = escapeQuotes(colId);
      const operand = operandIndex == null ? "" : `, params.operands[${operandIndex}]`;
      return `params.operators[${operatorIndex}].evaluator(expressionProxy.getValue('${escapedColId}', node), node, params.evaluatorParams[${evaluatorParamsIndex}]${operand})`;
    });
  }
  getFunctionParsed(params) {
    return this.getFunctionCommon(params, (operandIndex, operatorIndex, colId, evaluatorParamsIndex) => {
      return (expressionProxy, node, p) => p.operators[operatorIndex].evaluator(
        expressionProxy.getValue(colId, node),
        node,
        p.evaluatorParams[evaluatorParamsIndex],
        operandIndex == null ? void 0 : p.operands[operandIndex]
      );
    });
  }
  getAutocompleteListParams(position) {
    if (this.isColumnPosition(position)) {
      return this.getColumnAutocompleteListParams(position);
    }
    if (this.isOperatorPosition(position)) {
      return this.getOperatorAutocompleteListParams(position);
    }
    if (this.isBeyondEndPosition(position)) {
      return void 0;
    }
    return { enabled: false };
  }
  updateExpression(position, updateEntry, type) {
    const { expression } = this.params;
    if (this.isColumnPosition(position)) {
      return updateExpression(
        this.params.expression,
        this.startPosition,
        this.columnParser?.getColId() ? this.columnParser.endPosition : findEndPosition(expression, position).endPosition,
        this.params.advancedFilterExpressionService.getColumnValue(updateEntry),
        true
      );
    } else if (this.isOperatorPosition(position)) {
      const baseCellDataType = this.getBaseCellDataTypeFromOperatorAutocompleteType(type);
      const hasOperand = this.hasOperand(baseCellDataType, updateEntry.key);
      const doesOperandNeedQuotes = hasOperand && this.doesOperandNeedQuotes(baseCellDataType);
      let update;
      if (this.operatorParser?.startPosition != null && position < this.operatorParser.startPosition) {
        update = updateExpression(
          expression,
          position,
          position,
          updateEntry.displayValue ?? updateEntry.key,
          hasOperand,
          doesOperandNeedQuotes
        );
      } else {
        let endPosition;
        let empty = false;
        if (this.operatorParser?.getOperatorKey()) {
          endPosition = this.operatorParser.endPosition;
        } else {
          const { endPosition: calculatedEndPosition, isEmpty } = findEndPosition(
            expression,
            position,
            true,
            true
          );
          endPosition = calculatedEndPosition;
          empty = isEmpty;
        }
        update = updateExpression(
          expression,
          findStartPosition(expression, this.columnParser.endPosition + 1, endPosition),
          endPosition,
          updateEntry.displayValue ?? updateEntry.key,
          hasOperand,
          doesOperandNeedQuotes,
          empty
        );
      }
      return { ...update, hideAutocomplete: !hasOperand };
    }
    return null;
  }
  getModel() {
    const colId = this.columnParser.getColId();
    const model = {
      filterType: this.columnParser.baseCellDataType,
      colId,
      type: this.operatorParser.getOperatorKey()
    };
    if (this.operatorParser.expectedNumOperands) {
      model.filter = this.operandParser.getModelValue();
    }
    return model;
  }
  getFunctionCommon(params, processFunc) {
    const colId = this.columnParser.getColId();
    const operator = this.operatorParser?.getOperatorKey();
    const { operators, evaluatorParams, operands } = params;
    const operatorForColumn = this.params.advancedFilterExpressionService.getExpressionOperator(
      this.columnParser.baseCellDataType,
      operator
    );
    const operatorIndex = this.addToListAndGetIndex(operators, operatorForColumn);
    const evaluatorParamsForColumn = this.params.advancedFilterExpressionService.getExpressionEvaluatorParams(colId);
    const evaluatorParamsIndex = this.addToListAndGetIndex(evaluatorParams, evaluatorParamsForColumn);
    const operandIndex = this.operatorParser?.expectedNumOperands === 0 ? void 0 : this.addToListAndGetIndex(operands, this.getOperandValue());
    return processFunc(operandIndex, operatorIndex, colId, evaluatorParamsIndex);
  }
  getOperandValue() {
    let operand = this.operandParser.getRawValue();
    const { baseCellDataType, column } = this.columnParser;
    switch (baseCellDataType) {
      case "number":
        operand = Number(operand);
        break;
      case "date":
      case "dateString":
        operand = this.params.valueService.parseValue(column, null, operand, void 0);
        break;
    }
    if (baseCellDataType === "dateString") {
      const { dataTypeService } = this.params;
      return dataTypeService ? dataTypeService.getDateParserFunction(column)(operand) : operand;
    }
    return operand;
  }
  isComplete() {
    return !!(this.operatorParser && (!this.operatorParser.expectedNumOperands || this.operatorParser.expectedNumOperands && this.operandParser));
  }
  isColumnPosition(position) {
    return !this.columnParser || this.columnParser.endPosition == null || position <= this.columnParser.endPosition + 1;
  }
  isOperatorPosition(position) {
    return !this.operatorParser || this.operatorParser.endPosition == null || position <= this.operatorParser.endPosition + 1;
  }
  isBeyondEndPosition(position) {
    return this.isComplete() && this.endPosition != null && position > this.endPosition + 1 && this.endPosition + 1 < this.params.expression.length;
  }
  returnEndPosition(returnPosition, treatAsEnd) {
    this.endPosition = treatAsEnd ? returnPosition : returnPosition - 1;
    return returnPosition;
  }
  getColumnAutocompleteListParams(position) {
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(
      this.params.advancedFilterExpressionService.getColumnAutocompleteEntries(),
      "column",
      this.getColumnSearchString(position)
    );
  }
  getColumnSearchString(position) {
    const columnName = this.columnParser?.getDisplayValue() ?? "";
    const searchString = getSearchString(
      columnName,
      position,
      this.columnParser?.endPosition == null ? this.params.expression.length : this.columnParser.endPosition + 1
    );
    const containsStartChar = this.columnParser?.hasStartChar && searchString.length > 0;
    const containsEndChar = this.columnParser?.hasEndChar && searchString.length === columnName.length + 2;
    if (containsStartChar) {
      return searchString.slice(1, containsEndChar ? -1 : void 0);
    }
    return searchString;
  }
  getOperatorAutocompleteListParams(position) {
    const column = this.columnParser?.column;
    if (!column) {
      return { enabled: false };
    }
    const baseCellDataType = this.columnParser.baseCellDataType;
    const searchString = this.operatorParser?.startPosition != null && position < this.operatorParser.startPosition ? "" : getSearchString(
      this.operatorParser?.getDisplayValue() ?? "",
      position,
      this.operatorParser?.endPosition == null ? this.params.expression.length : this.operatorParser.endPosition + 1
    );
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(
      this.params.advancedFilterExpressionService.getOperatorAutocompleteEntries(column, baseCellDataType),
      `operator-${baseCellDataType}`,
      searchString
    );
  }
  getBaseCellDataTypeFromOperatorAutocompleteType(type) {
    return type?.replace("operator-", "");
  }
  hasOperand(baseCellDataType, operator) {
    return !baseCellDataType || !operator || (this.params.advancedFilterExpressionService.getExpressionOperator(baseCellDataType, operator)?.numOperands ?? 0) > 0;
  }
  doesOperandNeedQuotes(baseCellDataType) {
    return baseCellDataType !== "number";
  }
  addToListAndGetIndex(list, value) {
    const index = list.length;
    list.push(value);
    return index;
  }
};
ColFilterExpressionParser.COL_START_CHAR = "[";
ColFilterExpressionParser.COL_END_CHAR = "]";
function findMatch(searchValue, values, getDisplayValue) {
  let partialMatch = false;
  const searchValueLowerCase = searchValue.toLocaleLowerCase();
  const partialSearchValue = searchValueLowerCase + " ";
  const parsedValue = Object.entries(values).find(([, value]) => {
    const displayValueLowerCase = getDisplayValue(value).toLocaleLowerCase();
    if (displayValueLowerCase.startsWith(partialSearchValue)) {
      partialMatch = true;
    }
    return displayValueLowerCase === searchValueLowerCase;
  });
  if (parsedValue) {
    return parsedValue[0];
  } else if (partialMatch) {
    return null;
  } else {
    return void 0;
  }
}
function getEntries(operators, activeOperatorKeys) {
  const keys = activeOperatorKeys ?? Object.keys(operators);
  return keys.map((key) => ({
    key,
    displayValue: operators[key].displayValue
  }));
}
var TextFilterExpressionOperators = class {
  constructor(params) {
    this.params = params;
    this.initOperators();
  }
  getEntries(activeOperators) {
    return getEntries(this.operators, activeOperators);
  }
  findOperator(displayValue) {
    return findMatch(displayValue, this.operators, ({ displayValue: displayValue2 }) => displayValue2);
  }
  initOperators() {
    const { translate } = this.params;
    this.operators = {
      contains: {
        displayValue: translate("advancedFilterContains"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v.includes(o)),
        numOperands: 1
      },
      notContains: {
        displayValue: translate("advancedFilterNotContains"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, true, (v, o) => !v.includes(o)),
        numOperands: 1
      },
      equals: {
        displayValue: translate("advancedFilterTextEquals"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v === o),
        numOperands: 1
      },
      notEqual: {
        displayValue: translate("advancedFilterTextNotEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, true, (v, o) => v != o),
        numOperands: 1
      },
      startsWith: {
        displayValue: translate("advancedFilterStartsWith"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v.startsWith(o)),
        numOperands: 1
      },
      endsWith: {
        displayValue: translate("advancedFilterEndsWith"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v.endsWith(o)),
        numOperands: 1
      },
      blank: {
        displayValue: translate("advancedFilterBlank"),
        evaluator: (value) => value == null || typeof value === "string" && value.trim().length === 0,
        numOperands: 0
      },
      notBlank: {
        displayValue: translate("advancedFilterNotBlank"),
        evaluator: (value) => value != null && (typeof value !== "string" || value.trim().length > 0),
        numOperands: 0
      }
    };
  }
  evaluateExpression(value, node, params, operand, nullsMatch, expression) {
    if (value == null) {
      return nullsMatch;
    }
    return params.caseSensitive ? expression(params.valueConverter(value, node), operand) : expression(params.valueConverter(value, node).toLocaleLowerCase(), operand.toLocaleLowerCase());
  }
};
var ScalarFilterExpressionOperators = class {
  constructor(params) {
    this.params = params;
    this.initOperators();
  }
  getEntries(activeOperators) {
    return getEntries(this.operators, activeOperators);
  }
  findOperator(displayValue) {
    return findMatch(displayValue, this.operators, ({ displayValue: displayValue2 }) => displayValue2);
  }
  initOperators() {
    const { translate, equals } = this.params;
    this.operators = {
      equals: {
        displayValue: translate("advancedFilterEquals"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(
          value,
          node,
          params,
          operand1,
          !!params.includeBlanksInEquals,
          equals
        ),
        numOperands: 1
      },
      notEqual: {
        displayValue: translate("advancedFilterNotEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(
          value,
          node,
          params,
          operand1,
          !!params.includeBlanksInEquals,
          (v, o) => !equals(v, o)
        ),
        numOperands: 1
      },
      greaterThan: {
        displayValue: translate("advancedFilterGreaterThan"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(
          value,
          node,
          params,
          operand1,
          !!params.includeBlanksInGreaterThan,
          (v, o) => v > o
        ),
        numOperands: 1
      },
      greaterThanOrEqual: {
        displayValue: translate("advancedFilterGreaterThanOrEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(
          value,
          node,
          params,
          operand1,
          !!params.includeBlanksInGreaterThan,
          (v, o) => v >= o
        ),
        numOperands: 1
      },
      lessThan: {
        displayValue: translate("advancedFilterLessThan"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(
          value,
          node,
          params,
          operand1,
          !!params.includeBlanksInLessThan,
          (v, o) => v < o
        ),
        numOperands: 1
      },
      lessThanOrEqual: {
        displayValue: translate("advancedFilterLessThanOrEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(
          value,
          node,
          params,
          operand1,
          !!params.includeBlanksInLessThan,
          (v, o) => v <= o
        ),
        numOperands: 1
      },
      blank: {
        displayValue: translate("advancedFilterBlank"),
        evaluator: (value) => value == null,
        numOperands: 0
      },
      notBlank: {
        displayValue: translate("advancedFilterNotBlank"),
        evaluator: (value) => value != null,
        numOperands: 0
      }
    };
  }
  evaluateSingleOperandExpression(value, node, params, operand, nullsMatch, expression) {
    if (value == null) {
      return nullsMatch;
    }
    return expression(params.valueConverter(value, node), operand);
  }
};
var BooleanFilterExpressionOperators = class {
  constructor(params) {
    this.params = params;
    this.initOperators();
  }
  getEntries(activeOperators) {
    return getEntries(this.operators, activeOperators);
  }
  findOperator(displayValue) {
    return findMatch(displayValue, this.operators, ({ displayValue: displayValue2 }) => displayValue2);
  }
  initOperators() {
    const { translate } = this.params;
    this.operators = {
      true: {
        displayValue: translate("advancedFilterTrue"),
        evaluator: (value) => !!value,
        numOperands: 0
      },
      false: {
        displayValue: translate("advancedFilterFalse"),
        evaluator: (value) => value === false,
        numOperands: 0
      },
      blank: {
        displayValue: translate("advancedFilterBlank"),
        evaluator: (value) => value == null,
        numOperands: 0
      },
      notBlank: {
        displayValue: translate("advancedFilterNotBlank"),
        evaluator: (value) => value != null,
        numOperands: 0
      }
    };
  }
};
var AdvancedFilterExpressionService = class extends BeanStub5 {
  constructor() {
    super(...arguments);
    this.beanName = "advancedFilterExpressionService";
    this.columnNameToIdMap = {};
    this.columnAutocompleteEntries = null;
    this.expressionEvaluatorParams = {};
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.dataTypeService = beans.dataTypeService;
  }
  postConstruct() {
    this.expressionJoinOperators = this.generateExpressionJoinOperators();
    this.expressionOperators = this.generateExpressionOperators();
  }
  parseJoinOperator(model) {
    const { type } = model;
    return this.expressionJoinOperators[type] ?? type;
  }
  getColumnDisplayValue(model) {
    const { colId } = model;
    const columnEntries = this.getColumnAutocompleteEntries();
    const columnEntry = columnEntries.find(({ key }) => key === colId);
    let columnName;
    if (columnEntry) {
      columnName = columnEntry.displayValue;
      this.columnNameToIdMap[columnName.toLocaleUpperCase()] = { colId, columnName };
    } else {
      columnName = colId;
    }
    return columnName;
  }
  getOperatorDisplayValue(model) {
    return this.getExpressionOperator(model.filterType, model.type)?.displayValue ?? model.type;
  }
  getOperandModelValue(operand, baseCellDataType, column) {
    switch (baseCellDataType) {
      case "number":
        return _exists3(operand) ? Number(operand) : null;
      case "date":
        return _serialiseDate(this.valueService.parseValue(column, null, operand, void 0), false);
      case "dateString": {
        const parsedDateString = this.valueService.parseValue(column, null, operand, void 0);
        return this.dataTypeService ? _serialiseDate(
          this.dataTypeService.getDateParserFunction(column)(parsedDateString) ?? null,
          false
        ) : parsedDateString;
      }
    }
    return operand;
  }
  getOperandDisplayValue(model, skipFormatting) {
    const { colId, filter } = model;
    const column = this.columnModel.getColDefCol(colId);
    let operand = "";
    if (filter != null) {
      let operand1;
      switch (model.filterType) {
        case "number":
          operand1 = _toStringOrNull(filter) ?? "";
          break;
        case "date": {
          const dateValue = _parseDateTimeFromString(filter);
          operand1 = column ? this.valueService.formatValue(column, null, dateValue) : null;
          break;
        }
        case "dateString": {
          let dateStringStringValue;
          if (this.dataTypeService) {
            const dateStringDateValue = _parseDateTimeFromString(filter);
            dateStringStringValue = column ? this.dataTypeService?.getDateFormatterFunction(column)(dateStringDateValue ?? void 0) : null;
          } else {
            dateStringStringValue = filter;
          }
          operand1 = column ? this.valueService.formatValue(column, null, dateStringStringValue) : null;
          break;
        }
      }
      if (model.filterType !== "number") {
        operand1 = operand1 ?? _toStringOrNull(filter) ?? "";
        if (!skipFormatting) {
          operand1 = `"${operand1}"`;
        }
      }
      operand = skipFormatting ? operand1 : ` ${operand1}`;
    }
    return operand;
  }
  parseColumnFilterModel(model) {
    const columnName = this.getColumnDisplayValue(model) ?? "";
    const operator = this.getOperatorDisplayValue(model) ?? "";
    const operands = this.getOperandDisplayValue(model);
    return `[${columnName}] ${operator}${operands}`;
  }
  updateAutocompleteCache(updateEntry, type) {
    if (type === "column") {
      const { key: colId, displayValue } = updateEntry;
      this.columnNameToIdMap[updateEntry.displayValue.toLocaleUpperCase()] = {
        colId,
        columnName: displayValue
      };
    }
  }
  translate(key, variableValues) {
    let defaultValue = ADVANCED_FILTER_LOCALE_TEXT[key];
    if (typeof defaultValue === "function") {
      defaultValue = defaultValue(variableValues);
    }
    return this.localeService.getLocaleTextFunc()(key, defaultValue, variableValues);
  }
  generateAutocompleteListParams(entries, type, searchString) {
    return {
      enabled: true,
      type,
      searchString,
      entries
    };
  }
  getColumnAutocompleteEntries() {
    if (this.columnAutocompleteEntries) {
      return this.columnAutocompleteEntries;
    }
    const columns = this.columnModel.getColDefCols() ?? [];
    const entries = [];
    const includeHiddenColumns = this.gos.get("includeHiddenColumnsInAdvancedFilter");
    columns.forEach((column) => {
      if (column.getColDef().filter && (includeHiddenColumns || column.isVisible() || column.isRowGroupActive())) {
        entries.push({
          key: column.getColId(),
          displayValue: this.columnNameService.getDisplayNameForColumn(column, "advancedFilter")
        });
      }
    });
    entries.sort((a, b) => {
      const aValue = a.displayValue ?? "";
      const bValue = b.displayValue ?? "";
      if (aValue < bValue) {
        return -1;
      } else if (bValue > aValue) {
        return 1;
      }
      return 0;
    });
    return entries;
  }
  getOperatorAutocompleteEntries(column, baseCellDataType) {
    const activeOperators = this.getActiveOperators(column);
    return this.getDataTypeExpressionOperator(baseCellDataType).getEntries(activeOperators);
  }
  getJoinOperatorAutocompleteEntries() {
    return Object.entries(this.expressionJoinOperators).map(([key, displayValue]) => ({ key, displayValue }));
  }
  getDefaultAutocompleteListParams(searchString) {
    return this.generateAutocompleteListParams(this.getColumnAutocompleteEntries(), "column", searchString);
  }
  getDataTypeExpressionOperator(baseCellDataType) {
    return this.expressionOperators[baseCellDataType];
  }
  getExpressionOperator(baseCellDataType, operator) {
    return this.getDataTypeExpressionOperator(baseCellDataType)?.operators?.[operator];
  }
  getExpressionJoinOperators() {
    return this.expressionJoinOperators;
  }
  getColId(columnName) {
    const upperCaseColumnName = columnName.toLocaleUpperCase();
    const cachedColId = this.columnNameToIdMap[upperCaseColumnName];
    if (cachedColId) {
      return cachedColId;
    }
    const columnAutocompleteEntries = this.getColumnAutocompleteEntries();
    const colEntry = columnAutocompleteEntries.find(
      ({ displayValue }) => displayValue.toLocaleUpperCase() === upperCaseColumnName
    );
    if (colEntry) {
      const { key: colId, displayValue } = colEntry;
      const colValue = { colId, columnName: displayValue };
      this.columnNameToIdMap[upperCaseColumnName] = colValue;
      return colValue;
    }
    return null;
  }
  getExpressionEvaluatorParams(colId) {
    let params = this.expressionEvaluatorParams[colId];
    if (params) {
      return params;
    }
    const column = this.columnModel.getColDefCol(colId);
    if (!column) {
      return { valueConverter: (v) => v };
    }
    const baseCellDataType = this.dataTypeService?.getBaseDataType(column);
    switch (baseCellDataType) {
      case "dateString":
        params = {
          valueConverter: this.dataTypeService?.getDateParserFunction(column) ?? ((v) => v)
        };
        break;
      case "object":
        if (column.getColDef().filterValueGetter) {
          params = { valueConverter: (v) => v };
        } else {
          params = {
            valueConverter: (value, node) => this.valueService.formatValue(column, node, value) ?? (typeof value.toString === "function" ? value.toString() : "")
          };
        }
        break;
      case "text":
      case void 0:
        params = { valueConverter: (v) => _toStringOrNull(v) };
        break;
      default:
        params = { valueConverter: (v) => v };
        break;
    }
    const { filterParams } = column.getColDef();
    if (filterParams) {
      ["caseSensitive", "includeBlanksInEquals", "includeBlanksInLessThan", "includeBlanksInGreaterThan"].forEach(
        (param) => {
          const paramValue = filterParams[param];
          if (paramValue) {
            params[param] = paramValue;
          }
        }
      );
    }
    this.expressionEvaluatorParams[colId] = params;
    return params;
  }
  getColumnDetails(colId) {
    const column = this.columnModel.getColDefCol(colId) ?? void 0;
    const baseCellDataType = (column ? this.dataTypeService?.getBaseDataType(column) : void 0) ?? "text";
    return { column, baseCellDataType };
  }
  generateExpressionOperators() {
    const translate = (key, variableValues) => this.translate(key, variableValues);
    return {
      text: new TextFilterExpressionOperators({ translate }),
      boolean: new BooleanFilterExpressionOperators({ translate }),
      object: new TextFilterExpressionOperators({ translate }),
      number: new ScalarFilterExpressionOperators({ translate, equals: (v, o) => v === o }),
      date: new ScalarFilterExpressionOperators({
        translate,
        equals: (v, o) => v.getTime() === o.getTime()
      }),
      dateString: new ScalarFilterExpressionOperators({
        translate,
        equals: (v, o) => v.getTime() === o.getTime()
      })
    };
  }
  getColumnValue({ displayValue }) {
    return `${ColFilterExpressionParser.COL_START_CHAR}${displayValue}${ColFilterExpressionParser.COL_END_CHAR}`;
  }
  generateExpressionJoinOperators() {
    return {
      AND: this.translate("advancedFilterAnd"),
      OR: this.translate("advancedFilterOr")
    };
  }
  getActiveOperators(column) {
    const filterOptions = column.getColDef().filterParams?.filterOptions;
    if (!filterOptions) {
      return void 0;
    }
    const isValid = filterOptions.every((filterOption) => typeof filterOption === "string");
    return isValid ? filterOptions : void 0;
  }
  resetColumnCaches() {
    this.columnAutocompleteEntries = null;
    this.columnNameToIdMap = {};
    this.expressionEvaluatorParams = {};
  }
};
var AgAutocompleteRow = class extends Component12 {
  constructor() {
    super(
      /* html */
      `
        <div class="ag-autocomplete-row" role="presentation">
            <div class="ag-autocomplete-row-label"></div>
        </div>`
    );
    this.hasHighlighting = false;
  }
  setState(value, selected) {
    this.value = value;
    this.render();
    this.updateSelected(selected);
  }
  updateSelected(selected) {
    this.addOrRemoveCssClass("ag-autocomplete-row-selected", selected);
  }
  setSearchString(searchString) {
    let keepHighlighting = false;
    if (_exists22(searchString)) {
      const index = this.value?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase());
      if (index >= 0) {
        keepHighlighting = true;
        this.hasHighlighting = true;
        const highlightEndIndex = index + searchString.length;
        const startPart = _escapeString4(this.value.slice(0, index));
        const highlightedPart = _escapeString4(this.value.slice(index, highlightEndIndex));
        const endPart = _escapeString4(this.value.slice(highlightEndIndex));
        this.getGui().lastElementChild.innerHTML = `${startPart}<b>${highlightedPart}</b>${endPart}`;
      }
    }
    if (!keepHighlighting && this.hasHighlighting) {
      this.hasHighlighting = false;
      this.render();
    }
  }
  render() {
    this.getGui().lastElementChild.innerHTML = _escapeString4(this.value) ?? "&nbsp;";
  }
};
var AgAutocompleteList = class extends PopupComponent {
  constructor(params) {
    super(
      /* html */
      `<div class="ag-autocomplete-list-popup">
            <div data-ref="eList" class="ag-autocomplete-list"></div>
        <div>`
    );
    this.params = params;
    this.eList = RefPlaceholder10;
    this.searchString = "";
  }
  destroy() {
    super.destroy();
  }
  postConstruct() {
    this.autocompleteEntries = this.params.autocompleteEntries;
    this.virtualList = this.createManagedBean(new VirtualList({ cssIdentifier: "autocomplete" }));
    this.virtualList.setComponentCreator(this.createRowComponent.bind(this));
    this.eList.appendChild(this.virtualList.getGui());
    this.virtualList.setModel({
      getRowCount: () => this.autocompleteEntries.length,
      getRow: (index) => this.autocompleteEntries[index]
    });
    const virtualListGui = this.virtualList.getGui();
    this.addManagedListeners(virtualListGui, {
      click: () => this.params.onConfirmed(),
      mousemove: this.onMouseMove.bind(this),
      mousedown: (e) => e.preventDefault()
    });
    this.setSelectedValue(0);
  }
  onNavigationKeyDown(event, key) {
    event.preventDefault();
    const oldIndex = this.autocompleteEntries.indexOf(this.selectedValue);
    const newIndex = key === KeyCode13.UP ? oldIndex - 1 : oldIndex + 1;
    this.checkSetSelectedValue(newIndex);
  }
  setSearch(searchString) {
    this.searchString = searchString;
    if (_exists32(searchString)) {
      this.runSearch();
    } else {
      this.autocompleteEntries = this.params.autocompleteEntries;
      this.virtualList.refresh();
      this.checkSetSelectedValue(0);
    }
    this.updateSearchInList();
  }
  runContainsSearch(searchString, searchStrings) {
    let topMatch;
    let topMatchStartsWithSearchString = false;
    const lowerCaseSearchString = searchString.toLocaleLowerCase();
    const allMatches = searchStrings.filter((string) => {
      const lowerCaseString = string.toLocaleLowerCase();
      const index = lowerCaseString.indexOf(lowerCaseSearchString);
      const startsWithSearchString = index === 0;
      const isMatch = index >= 0;
      if (isMatch && (!topMatch || !topMatchStartsWithSearchString && startsWithSearchString || topMatchStartsWithSearchString === startsWithSearchString && string.length < topMatch.length)) {
        topMatch = string;
        topMatchStartsWithSearchString = startsWithSearchString;
      }
      return isMatch;
    });
    if (!topMatch && allMatches.length) {
      topMatch = allMatches[0];
    }
    return { topMatch, allMatches };
  }
  runSearch() {
    const { autocompleteEntries } = this.params;
    const searchStrings = autocompleteEntries.map((v) => v.displayValue ?? v.key);
    let matchingStrings;
    let topSuggestion;
    if (this.params.useFuzzySearch) {
      matchingStrings = _fuzzySuggestions2({
        inputValue: this.searchString,
        allSuggestions: searchStrings,
        hideIrrelevant: true,
        addSequentialWeight: true
      }).values;
      topSuggestion = matchingStrings.length ? matchingStrings[0] : void 0;
    } else {
      const containsMatches = this.runContainsSearch(this.searchString, searchStrings);
      matchingStrings = containsMatches.allMatches;
      topSuggestion = containsMatches.topMatch;
    }
    let filteredEntries = autocompleteEntries.filter(
      ({ key, displayValue }) => matchingStrings.includes(displayValue ?? key)
    );
    if (!filteredEntries.length && this.selectedValue && this.params?.forceLastSelection?.(this.selectedValue, this.searchString)) {
      filteredEntries = [this.selectedValue];
    }
    this.autocompleteEntries = filteredEntries;
    this.virtualList.refresh();
    if (!topSuggestion) {
      return;
    }
    const topSuggestionIndex = matchingStrings.indexOf(topSuggestion);
    this.checkSetSelectedValue(topSuggestionIndex);
  }
  updateSearchInList() {
    this.virtualList.forEachRenderedRow((row) => row.setSearchString(this.searchString));
  }
  checkSetSelectedValue(index) {
    if (index >= 0 && index < this.autocompleteEntries.length) {
      this.setSelectedValue(index);
    }
  }
  setSelectedValue(index) {
    const value = this.autocompleteEntries[index];
    if (this.selectedValue === value) {
      return;
    }
    this.selectedValue = value;
    this.virtualList.ensureIndexVisible(index);
    this.virtualList.forEachRenderedRow((cmp, idx) => {
      cmp.updateSelected(index === idx);
    });
  }
  createRowComponent(value) {
    const row = new AgAutocompleteRow();
    this.createBean(row);
    row.setState(value.displayValue ?? value.key, value === this.selectedValue);
    return row;
  }
  onMouseMove(mouseEvent) {
    const rect = this.virtualList.getGui().getBoundingClientRect();
    const scrollTop = this.virtualList.getScrollTop();
    const mouseY = mouseEvent.clientY - rect.top + scrollTop;
    const row = Math.floor(mouseY / this.virtualList.getRowHeight());
    this.checkSetSelectedValue(row);
  }
  afterGuiAttached() {
    this.virtualList.refresh();
  }
  getSelectedValue() {
    if (!this.autocompleteEntries.length) {
      return null;
    }
    return this.selectedValue ?? null;
  }
};
var AgAutocomplete = class extends Component22 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-autocomplete" role="presentation">
                <ag-input-text-field data-ref="eAutocompleteInput"></ag-input-text-field>
            </div>`,
      [AgInputTextFieldSelector2]
    );
    this.eAutocompleteInput = RefPlaceholder22;
    this.isListOpen = false;
    this.lastPosition = 0;
    this.valid = true;
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
  }
  postConstruct() {
    this.eAutocompleteInput.onValueChange((value) => this.onValueChanged(value));
    this.eAutocompleteInput.getInputElement().setAttribute("autocomplete", "off");
    this.addGuiEventListener("keydown", this.onKeyDown.bind(this));
    this.addGuiEventListener("click", this.updatePositionAndList.bind(this));
    this.addDestroyFunc(() => {
      this.destroyBean(this.autocompleteList);
    });
    this.addGuiEventListener("focusout", () => this.onFocusOut());
  }
  onValueChanged(value) {
    const parsedValue = _makeNull(value);
    this.updateValue(parsedValue);
    this.updateAutocompleteList(parsedValue);
  }
  updateValue(value) {
    this.updateLastPosition();
    this.dispatchLocalEvent({
      type: "eventValueChanged",
      value
    });
    this.validate(value);
  }
  updateAutocompleteList(value) {
    const autocompleteListParams = this.listGenerator?.(value, this.lastPosition) ?? { enabled: false };
    if (!autocompleteListParams.type || autocompleteListParams.type !== this.autocompleteListParams?.type) {
      if (this.isListOpen) {
        this.closeList();
      }
    }
    this.autocompleteListParams = autocompleteListParams;
    if (this.autocompleteListParams?.enabled) {
      if (!this.isListOpen) {
        this.openList();
      }
      const { searchString } = this.autocompleteListParams;
      this.autocompleteList.setSearch(searchString ?? "");
    } else {
      if (this.isListOpen) {
        this.closeList();
      }
    }
  }
  onKeyDown(event) {
    const key = event.key;
    this.updateLastPosition();
    switch (key) {
      case KeyCode22.ENTER:
        this.onEnterKeyDown(event);
        break;
      case KeyCode22.TAB:
        this.onTabKeyDown(event);
        break;
      case KeyCode22.DOWN:
      case KeyCode22.UP:
        this.onUpDownKeyDown(event, key);
        break;
      case KeyCode22.LEFT:
      case KeyCode22.RIGHT:
      case KeyCode22.PAGE_HOME:
      case KeyCode22.PAGE_END:
        setTimeout(() => {
          this.updatePositionAndList();
        });
        break;
      case KeyCode22.ESCAPE:
        this.onEscapeKeyDown(event);
        break;
      case KeyCode22.SPACE:
        if (event.ctrlKey && !this.isListOpen) {
          event.preventDefault();
          this.forceOpenList();
        }
        break;
    }
  }
  confirmSelection() {
    const selectedValue = this.autocompleteList?.getSelectedValue();
    if (selectedValue) {
      this.closeList();
      this.dispatchLocalEvent({
        type: "eventOptionSelected",
        value: this.getValue(),
        position: this.lastPosition,
        updateEntry: selectedValue,
        autocompleteType: this.autocompleteListParams.type
      });
    }
  }
  onTabKeyDown(event) {
    if (this.isListOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.confirmSelection();
    }
  }
  onEnterKeyDown(event) {
    event.preventDefault();
    if (this.isListOpen) {
      this.confirmSelection();
    } else {
      this.onCompleted();
    }
  }
  onUpDownKeyDown(event, key) {
    event.preventDefault();
    if (!this.isListOpen) {
      this.forceOpenList();
    } else {
      this.autocompleteList?.onNavigationKeyDown(event, key);
    }
  }
  onEscapeKeyDown(event) {
    if (this.isListOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.closeList();
      this.setCaret(this.lastPosition, true);
    }
  }
  onFocusOut() {
    if (this.isListOpen) {
      this.closeList();
    }
  }
  updatePositionAndList() {
    this.updateLastPosition();
    this.updateAutocompleteList(this.eAutocompleteInput.getValue() ?? null);
  }
  setCaret(position, setFocus) {
    if (setFocus && _isNothingFocused2(this.gos)) {
      this.eAutocompleteInput.getFocusableElement().focus();
    }
    const eInput = this.eAutocompleteInput.getInputElement();
    eInput.setSelectionRange(position, position);
    if (position === eInput.value.length) {
      eInput.scrollLeft = eInput.scrollWidth;
    }
  }
  forceOpenList() {
    this.onValueChanged(this.eAutocompleteInput.getValue());
  }
  updateLastPosition() {
    this.lastPosition = this.eAutocompleteInput.getInputElement().selectionStart ?? 0;
  }
  validate(value) {
    if (!this.validator) {
      return;
    }
    this.validationMessage = this.validator(value);
    this.eAutocompleteInput.getInputElement().setCustomValidity(this.validationMessage ?? "");
    this.valid = !this.validationMessage;
    this.dispatchLocalEvent({
      type: "eventValidChanged",
      isValid: this.valid,
      validationMessage: this.validationMessage
    });
  }
  openList() {
    this.isListOpen = true;
    this.autocompleteList = this.createBean(
      new AgAutocompleteList({
        autocompleteEntries: this.autocompleteListParams.entries,
        onConfirmed: () => this.confirmSelection(),
        forceLastSelection: this.forceLastSelection
      })
    );
    const ePopupGui = this.autocompleteList.getGui();
    const positionParams = {
      ePopup: ePopupGui,
      type: "autocomplete",
      eventSource: this.getGui(),
      position: "under",
      alignSide: this.gos.get("enableRtl") ? "right" : "left",
      keepWithinBounds: true
    };
    const addPopupRes = this.popupService.addPopup({
      eChild: ePopupGui,
      anchorToElement: this.getGui(),
      positionCallback: () => this.popupService.positionPopupByComponent(positionParams),
      ariaLabel: this.listAriaLabel
    });
    this.hidePopup = addPopupRes.hideFunc;
    this.autocompleteList.afterGuiAttached();
  }
  closeList() {
    this.isListOpen = false;
    this.hidePopup();
    this.destroyBean(this.autocompleteList);
    this.autocompleteList = null;
  }
  onCompleted() {
    if (this.isListOpen) {
      this.closeList();
    }
    this.dispatchLocalEvent({
      type: "eventValueConfirmed",
      value: this.getValue(),
      isValid: this.isValid()
    });
  }
  getValue() {
    return _makeNull(this.eAutocompleteInput.getValue());
  }
  setInputPlaceholder(placeholder) {
    this.eAutocompleteInput.setInputPlaceholder(placeholder);
    return this;
  }
  setInputAriaLabel(label) {
    this.eAutocompleteInput.setInputAriaLabel(label);
    return this;
  }
  setListAriaLabel(label) {
    this.listAriaLabel = label;
    return this;
  }
  setListGenerator(listGenerator) {
    this.listGenerator = listGenerator;
    return this;
  }
  setValidator(validator) {
    this.validator = validator;
    return this;
  }
  isValid() {
    return this.valid;
  }
  setValue(params) {
    const { value, position, silent, updateListOnlyIfOpen, restoreFocus } = params;
    this.eAutocompleteInput.setValue(value, true);
    this.setCaret(position ?? this.lastPosition, restoreFocus);
    if (!silent) {
      this.updateValue(value);
    }
    if (!updateListOnlyIfOpen || this.isListOpen) {
      this.updateAutocompleteList(value);
    }
  }
  setForceLastSelection(forceLastSelection) {
    this.forceLastSelection = forceLastSelection;
    return this;
  }
  setInputDisabled(disabled) {
    this.eAutocompleteInput.setDisabled(disabled);
    return this;
  }
};
var AgAutocompleteSelector = {
  selector: "AG-AUTOCOMPLETE",
  component: AgAutocomplete
};
var AdvancedFilterComp = class extends Component32 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-advanced-filter" role="presentation" tabindex="-1">
                <ag-autocomplete data-ref="eAutocomplete"></ag-autocomplete>
                <button class="ag-button ag-standard-button ag-advanced-filter-apply-button" data-ref="eApplyFilterButton"></button>
                <button class="ag-advanced-filter-builder-button" data-ref="eBuilderFilterButton">
                    <span data-ref="eBuilderFilterButtonIcon" aria-hidden="true"></span>
                    <span class="ag-advanced-filter-builder-button-label" data-ref="eBuilderFilterButtonLabel"></span>
                </button>
            </div>`,
      [AgAutocompleteSelector]
    );
    this.eAutocomplete = RefPlaceholder32;
    this.eApplyFilterButton = RefPlaceholder32;
    this.eBuilderFilterButton = RefPlaceholder32;
    this.eBuilderFilterButtonIcon = RefPlaceholder32;
    this.eBuilderFilterButtonLabel = RefPlaceholder32;
    this.expressionParser = null;
    this.isApplyDisabled = true;
    this.builderOpen = false;
  }
  wireBeans(beans) {
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
    this.advancedFilterService = beans.advancedFilterService;
    this.filterManager = beans.filterManager;
  }
  postConstruct() {
    this.eAutocomplete.setListGenerator((_value, position) => this.generateAutocompleteListParams(position)).setValidator(() => this.validateValue()).setForceLastSelection(
      (lastSelection, searchString) => this.forceLastSelection(lastSelection, searchString)
    ).setInputAriaLabel(this.advancedFilterExpressionService.translate("ariaAdvancedFilterInput")).setListAriaLabel(this.advancedFilterExpressionService.translate("ariaLabelAdvancedFilterAutocomplete"));
    this.refresh();
    this.addManagedListeners(this.eAutocomplete, {
      eventValueChanged: ({ value }) => this.onValueChanged(value),
      eventValueConfirmed: ({ isValid }) => this.onValueConfirmed(isValid),
      eventOptionSelected: ({ position, updateEntry, autocompleteType }) => this.onOptionSelected(position, updateEntry, autocompleteType),
      eventValidChanged: ({ isValid, validationMessage }) => this.onValidChanged(isValid, validationMessage)
    });
    this.setupApplyButton();
    this.setupBuilderButton();
  }
  refresh() {
    const expression = this.advancedFilterService.getExpressionDisplayValue();
    this.eAutocomplete.setValue({
      value: expression ?? "",
      position: expression?.length,
      updateListOnlyIfOpen: true
    });
  }
  setInputDisabled(disabled) {
    this.eAutocomplete.setInputDisabled(disabled);
    _setDisabled(this.eApplyFilterButton, disabled || this.isApplyDisabled);
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "advancedFilter";
    return res;
  }
  setupApplyButton() {
    this.eApplyFilterButton.innerText = this.advancedFilterExpressionService.translate("advancedFilterApply");
    this.activateTabIndex([this.eApplyFilterButton]);
    this.addManagedElementListeners(this.eApplyFilterButton, {
      click: () => this.onValueConfirmed(this.eAutocomplete.isValid())
    });
    _setDisabled(this.eApplyFilterButton, this.isApplyDisabled);
  }
  setupBuilderButton() {
    this.eBuilderFilterButtonIcon.appendChild(_createIconNoSpan9("advancedFilterBuilder", this.gos));
    this.eBuilderFilterButtonLabel.innerText = this.advancedFilterExpressionService.translate("advancedFilterBuilder");
    this.activateTabIndex([this.eBuilderFilterButton]);
    this.addManagedElementListeners(this.eBuilderFilterButton, { click: () => this.openBuilder() });
    this.addManagedListeners(this.advancedFilterService.getCtrl(), {
      advancedFilterBuilderClosed: () => this.closeBuilder()
    });
  }
  onValueChanged(value) {
    value = _makeNull2(value);
    this.advancedFilterService.setExpressionDisplayValue(value);
    this.expressionParser = this.advancedFilterService.createExpressionParser(value);
    const updatedExpression = this.expressionParser?.parseExpression();
    if (updatedExpression && updatedExpression !== value) {
      this.eAutocomplete.setValue({ value: updatedExpression, silent: true, restoreFocus: true });
    }
  }
  onValueConfirmed(isValid) {
    if (!isValid || this.isApplyDisabled) {
      return;
    }
    _setDisabled(this.eApplyFilterButton, true);
    this.advancedFilterService.applyExpression();
    this.filterManager?.onFilterChanged({ source: "advancedFilter" });
  }
  onOptionSelected(position, updateEntry, type) {
    const { updatedValue, updatedPosition, hideAutocomplete } = this.updateExpression(position, updateEntry, type);
    this.eAutocomplete.setValue({
      value: updatedValue,
      position: updatedPosition,
      updateListOnlyIfOpen: hideAutocomplete,
      restoreFocus: true
    });
  }
  validateValue() {
    return this.expressionParser?.isValid() ? null : this.expressionParser?.getValidationMessage() ?? null;
  }
  onValidChanged(isValid, validationMessage) {
    this.isApplyDisabled = !isValid || this.advancedFilterService.isCurrentExpressionApplied();
    _setDisabled(this.eApplyFilterButton, this.isApplyDisabled);
    this.setTooltip({
      newTooltipText: validationMessage,
      showDelayOverride: 1e3
    });
  }
  generateAutocompleteListParams(position) {
    return this.expressionParser ? this.expressionParser.getAutocompleteListParams(position) : this.advancedFilterExpressionService.getDefaultAutocompleteListParams("");
  }
  updateExpression(position, updateEntry, type) {
    this.advancedFilterExpressionService.updateAutocompleteCache(updateEntry, type);
    return this.expressionParser?.updateExpression(position, updateEntry, type) ?? this.advancedFilterService.getDefaultExpression(updateEntry);
  }
  forceLastSelection({ key, displayValue }, searchString) {
    return !!searchString.toLocaleLowerCase().match(`^${(displayValue ?? key).toLocaleLowerCase()}\\s*$`);
  }
  openBuilder() {
    if (this.builderOpen) {
      return;
    }
    this.builderOpen = true;
    _setDisabled(this.eBuilderFilterButton, true);
    this.advancedFilterService.getCtrl().toggleFilterBuilder({ source: "ui", eventSource: this.eBuilderFilterButton });
  }
  closeBuilder() {
    if (!this.builderOpen) {
      return;
    }
    this.builderOpen = false;
    _setDisabled(this.eBuilderFilterButton, false);
    this.eBuilderFilterButton.focus();
  }
};
var AdvancedFilterHeaderComp = class extends Component42 {
  constructor(enabled) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-header" role="row">
            </div>`
    );
    this.enabled = enabled;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.focusService = beans.focusService;
    this.headerNavigationService = beans.headerNavigationService;
  }
  postConstruct() {
    this.setupAdvancedFilter(this.enabled);
    this.addDestroyFunc(() => this.destroyBean(this.eAdvancedFilter));
    const heightListener = () => {
      if (this.enabled) {
        this.setEnabledHeight();
      }
    };
    this.addManagedEventListeners({
      gridColumnsChanged: () => this.onGridColumnsChanged(),
      columnHeaderHeightChanged: heightListener,
      gridStylesChanged: heightListener
    });
    this.addManagedPropertyListener("headerHeight", heightListener);
    this.addManagedPropertyListener("floatingFiltersHeight", heightListener);
    this.addGuiEventListener("keydown", (event) => this.onKeyDown(event));
    this.addGuiEventListener("focusout", (event) => {
      if (!this.getFocusableElement().contains(event.relatedTarget)) {
        this.focusService.clearAdvancedFilterColumn();
      }
    });
  }
  getFocusableElement() {
    return this.eAdvancedFilter?.getGui() ?? this.getGui();
  }
  setEnabled(enabled) {
    if (enabled === this.enabled) {
      return;
    }
    this.setupAdvancedFilter(enabled);
  }
  refresh() {
    this.eAdvancedFilter?.refresh();
  }
  getHeight() {
    return this.height;
  }
  setInputDisabled(disabled) {
    this.eAdvancedFilter?.setInputDisabled(disabled);
  }
  setupAdvancedFilter(enabled) {
    const eGui = this.getGui();
    if (enabled) {
      this.eAdvancedFilter = this.createBean(new AdvancedFilterComp());
      const eAdvancedFilterGui = this.eAdvancedFilter.getGui();
      this.eAdvancedFilter.addCssClass("ag-advanced-filter-header-cell");
      this.setEnabledHeight();
      this.setAriaRowIndex();
      _setAriaRole6(eAdvancedFilterGui, "gridcell");
      _setAriaColIndex(eAdvancedFilterGui, 1);
      this.setAriaColumnCount(eAdvancedFilterGui);
      eGui.appendChild(eAdvancedFilterGui);
    } else {
      _clearElement5(eGui);
      this.destroyBean(this.eAdvancedFilter);
      this.height = 0;
    }
    _setDisplayed6(eGui, enabled);
    this.enabled = enabled;
  }
  setEnabledHeight() {
    const eGui = this.getGui();
    this.height = this.columnModel.getFloatingFiltersHeight();
    const height = `${this.height}px`;
    eGui.style.height = height;
    eGui.style.minHeight = height;
  }
  setAriaColumnCount(eAdvancedFilterGui) {
    _setAriaColSpan(eAdvancedFilterGui, this.columnModel.getCols().length);
  }
  setAriaRowIndex() {
    _setAriaRowIndex(this.getGui(), this.headerNavigationService.getHeaderRowCount());
  }
  onGridColumnsChanged() {
    if (!this.eAdvancedFilter) {
      return;
    }
    this.setAriaColumnCount(this.eAdvancedFilter.getGui());
    this.setAriaRowIndex();
  }
  onKeyDown(event) {
    switch (event.key) {
      case KeyCode32.ENTER: {
        if (this.hasFocus()) {
          if (this.focusService.focusInto(this.getFocusableElement())) {
            event.preventDefault();
          }
        }
        break;
      }
      case KeyCode32.ESCAPE:
        if (!this.hasFocus()) {
          this.getFocusableElement().focus();
        }
        break;
      case KeyCode32.UP:
        this.navigateUpDown(true, event);
        break;
      case KeyCode32.DOWN:
        this.navigateUpDown(false, event);
        break;
      case KeyCode32.TAB:
        if (this.hasFocus()) {
          this.navigateLeftRight(event);
        } else {
          const nextFocusableEl = this.focusService.findNextFocusableElement(
            this.getFocusableElement(),
            null,
            event.shiftKey
          );
          if (nextFocusableEl) {
            event.preventDefault();
            nextFocusableEl.focus();
          } else {
            this.navigateLeftRight(event);
          }
        }
        break;
    }
  }
  navigateUpDown(backwards, event) {
    if (this.hasFocus()) {
      if (this.focusService.focusNextFromAdvancedFilter(backwards)) {
        event.preventDefault();
      }
    }
  }
  navigateLeftRight(event) {
    if (event.shiftKey ? this.focusService.focusLastHeader() : this.focusService.focusNextFromAdvancedFilter(false, true)) {
      event.preventDefault();
    }
  }
  hasFocus() {
    return _getActiveDomElement6(this.gos) === this.getFocusableElement();
  }
};
var AdvancedFilterBuilderDragFeature = class extends BeanStub22 {
  constructor(comp, virtualList) {
    super();
    this.comp = comp;
    this.virtualList = virtualList;
  }
  postConstruct() {
    this.createManagedBean(
      new VirtualListDragFeature(this.comp, this.virtualList, {
        dragSourceType: DragSourceType.AdvancedFilterBuilder,
        listItemDragStartEvent: "advancedFilterBuilderDragStarted",
        listItemDragEndEvent: "advancedFilterBuilderDragEnded",
        eventSource: this,
        getCurrentDragValue: (listItemDragStartEvent) => this.getCurrentDragValue(listItemDragStartEvent),
        isMoveBlocked: () => false,
        getNumRows: (comp) => comp.getNumItems(),
        moveItem: (currentDragValue, lastHoveredListItem) => this.moveItem(currentDragValue, lastHoveredListItem)
      })
    );
  }
  getCurrentDragValue(listItemDragStartEvent) {
    return listItemDragStartEvent.item;
  }
  moveItem(currentDragValue, lastHoveredListItem) {
    this.comp.moveItem(currentDragValue, lastHoveredListItem);
  }
};
var AddDropdownComp = class extends AgRichSelect {
  constructor(params) {
    super({
      ...params,
      template: (
        /* html */
        `
                <div class="ag-picker-field" role="presentation">
                    <div data-ref="eLabel"></div>
                    <div data-ref="eWrapper" class="ag-wrapper ag-picker-collapsed">
                        <div data-ref="eDisplayField" class="ag-picker-field-display"></div>
                        <ag-input-text-field data-ref="eInput" class="ag-rich-select-field-input"></ag-input-text-field>
                        <span data-ref="eDeselect" class="ag-rich-select-deselect-button ag-picker-field-icon" role="presentation"></span>
                        <div data-ref="eIcon" class="ag-picker-field-icon" aria-hidden="true"></div>
                    </div>
                </div>`
      ),
      agComponents: [AgInputTextFieldSelector22]
    });
    this.params = params;
  }
  showPicker() {
    setTimeout(() => super.showPicker());
  }
  hidePicker() {
    setTimeout(() => super.hidePicker());
  }
  postConstruct() {
    super.postConstruct();
    const { wrapperClassName, ariaLabel } = this.params;
    _setDisplayed22(this.eDisplayField, false);
    if (wrapperClassName) {
      this.eWrapper.classList.add(wrapperClassName);
    }
    _setAriaLabelledBy(this.eWrapper, "");
    _setAriaLabel6(this.eWrapper, ariaLabel);
  }
  onEnterKeyDown(event) {
    _stopPropagationForAgGrid5(event);
    if (this.isPickerDisplayed) {
      super.onEnterKeyDown(event);
    } else {
      event.preventDefault();
      this.showPicker();
    }
  }
};
var AdvancedFilterBuilderItemNavigationFeature = class extends BeanStub32 {
  constructor(eGui, focusWrapper, eFocusableComp) {
    super();
    this.eGui = eGui;
    this.focusWrapper = focusWrapper;
    this.eFocusableComp = eFocusableComp;
  }
  postConstruct() {
    this.addManagedElementListeners(this.eGui, {
      keydown: (event) => {
        switch (event.key) {
          case KeyCode42.TAB:
            if (!event.defaultPrevented) {
              _stopPropagationForAgGrid22(event);
            }
            break;
          case KeyCode42.UP:
          case KeyCode42.DOWN:
            _stopPropagationForAgGrid22(event);
            break;
          case KeyCode42.ESCAPE:
            if (_isStopPropagationForAgGrid2(event)) {
              return;
            }
            if (this.eGui.contains(_getActiveDomElement22(this.gos))) {
              event.preventDefault();
              _stopPropagationForAgGrid22(event);
              this.focusWrapper.focus();
            }
            break;
        }
      }
    });
    const highlightClass = "ag-advanced-filter-builder-virtual-list-item-highlight";
    this.addManagedListeners(this.focusWrapper, {
      keydown: (event) => {
        switch (event.key) {
          case KeyCode42.ENTER:
            if (_isStopPropagationForAgGrid2(event)) {
              return;
            }
            if (_getActiveDomElement22(this.gos) === this.focusWrapper) {
              event.preventDefault();
              _stopPropagationForAgGrid22(event);
              this.eFocusableComp.getFocusableElement().focus();
            }
            break;
        }
      },
      focusin: () => {
        this.focusWrapper.classList.add(highlightClass);
      },
      focusout: (event) => {
        if (!this.focusWrapper.contains(event.relatedTarget)) {
          this.focusWrapper.classList.remove(highlightClass);
        }
      }
    });
  }
};
function getAdvancedFilterBuilderAddButtonParams(translate, maxPickerWidth) {
  return {
    pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderAddField",
    pickerAriaLabelValue: "Advanced Filter Builder Add Field",
    pickerType: "ag-list",
    valueList: [
      {
        key: "condition",
        displayValue: translate("advancedFilterBuilderAddCondition")
      },
      {
        key: "join",
        displayValue: translate("advancedFilterBuilderAddJoin")
      }
    ],
    valueFormatter: (value) => value == null ? null : value.displayValue ?? value.key,
    pickerIcon: "advancedFilterBuilderAdd",
    maxPickerWidth: `${maxPickerWidth ?? 120}px`,
    wrapperClassName: "ag-advanced-filter-builder-item-button",
    ariaLabel: translate("advancedFilterBuilderAddButtonTooltip")
  };
}
var AdvancedFilterBuilderItemAddComp = class extends Component52 {
  constructor(item, focusWrapper) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-wrapper" role="presentation">
                <div data-ref="eItem" class="ag-advanced-filter-builder-item" role="presentation">
                    <div class="ag-advanced-filter-builder-item-tree-lines" aria-hidden="true">
                        <div class="ag-advanced-filter-builder-item-tree-line-vertical-top ag-advanced-filter-builder-item-tree-line-horizontal"></div>
                    </div>
                </div>
            </div>
        `
    );
    this.item = item;
    this.focusWrapper = focusWrapper;
    this.eItem = RefPlaceholder42;
  }
  wireBeans(beans) {
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
  }
  postConstruct() {
    _setAriaLevel2(this.focusWrapper, 2);
    const addButtonParams = getAdvancedFilterBuilderAddButtonParams(
      (key) => this.advancedFilterExpressionService.translate(key),
      this.gos.get("advancedFilterBuilderParams")?.addSelectWidth
    );
    const eAddButton = this.createManagedBean(new AddDropdownComp(addButtonParams));
    this.addManagedListeners(eAddButton, {
      fieldPickerValueSelected: ({ value }) => {
        this.dispatchLocalEvent({
          type: "advancedFilterBuilderAdded",
          item: this.item,
          isJoin: value.key === "join"
        });
      }
    });
    this.eItem.appendChild(eAddButton.getGui());
    this.createManagedBean(
      new TooltipFeature2({
        getGui: () => eAddButton.getGui(),
        getLocation: () => "advancedFilter",
        getTooltipValue: () => this.advancedFilterExpressionService.translate("advancedFilterBuilderAddButtonTooltip")
      })
    );
    this.createManagedBean(
      new AdvancedFilterBuilderItemNavigationFeature(this.getGui(), this.focusWrapper, eAddButton)
    );
    _setAriaLabel22(
      this.focusWrapper,
      this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderItem", [
        this.advancedFilterExpressionService.translate("advancedFilterBuilderAddButtonTooltip"),
        `${this.item.level + 1}`
      ])
    );
  }
  afterAdd() {
  }
};
var ConditionPillWrapperComp = class extends Component62 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-condition" role="presentation"></div>
        `
    );
    this.validationMessage = null;
  }
  wireBeans(beans) {
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
  }
  init(params) {
    const { item, createPill } = params;
    this.item = item;
    this.createPill = createPill;
    this.filterModel = item.filterModel;
    this.setupColumnCondition(this.filterModel);
    this.validate();
    this.addDestroyFunc(() => this.destroyBeans([this.eColumnPill, this.eOperatorPill, this.eOperandPill]));
  }
  getDragName() {
    return this.filterModel.colId ? this.advancedFilterExpressionService.parseColumnFilterModel(this.filterModel) : this.getDefaultColumnDisplayValue();
  }
  getAriaLabel() {
    return `${this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderFilterItem")} ${this.getDragName()}`;
  }
  getValidationMessage() {
    return this.validationMessage;
  }
  getFocusableElement() {
    return this.eColumnPill.getFocusableElement();
  }
  setupColumnCondition(filterModel) {
    const columnDetails = this.advancedFilterExpressionService.getColumnDetails(filterModel.colId);
    this.baseCellDataType = columnDetails.baseCellDataType;
    this.column = columnDetails.column;
    this.numOperands = this.getNumOperands(this.getOperatorKey());
    this.eColumnPill = this.createPill({
      key: this.getColumnKey(),
      displayValue: this.getColumnDisplayValue() ?? this.getDefaultColumnDisplayValue(),
      cssClass: "ag-advanced-filter-builder-column-pill",
      isSelect: true,
      getEditorParams: () => ({ values: this.advancedFilterExpressionService.getColumnAutocompleteEntries() }),
      update: (key) => this.setColumnKey(key),
      pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderColumnSelectField",
      pickerAriaLabelValue: "Advanced Filter Builder Column Select Field",
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderColumn")
    });
    this.getGui().appendChild(this.eColumnPill.getGui());
    if (_exists4(this.getColumnKey())) {
      this.createOperatorPill();
      if (this.hasOperand()) {
        this.createOperandPill();
      }
    }
  }
  createOperatorPill() {
    this.eOperatorPill = this.createPill({
      key: this.getOperatorKey(),
      displayValue: this.getOperatorDisplayValue() ?? this.getDefaultOptionSelectValue(),
      cssClass: "ag-advanced-filter-builder-option-pill",
      isSelect: true,
      getEditorParams: () => ({ values: this.getOperatorAutocompleteEntries() }),
      update: (key) => this.setOperatorKey(key),
      pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderOptionSelectField",
      pickerAriaLabelValue: "Advanced Filter Builder Option Select Field",
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderOption")
    });
    this.eColumnPill.getGui().insertAdjacentElement("afterend", this.eOperatorPill.getGui());
  }
  createOperandPill() {
    const key = this.getOperandDisplayValue() ?? "";
    this.eOperandPill = this.createPill({
      key,
      displayValue: key,
      baseCellDataType: this.baseCellDataType,
      cssClass: "ag-advanced-filter-builder-value-pill",
      isSelect: false,
      update: (key2) => this.setOperand(key2),
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderValue")
    });
    this.getGui().appendChild(this.eOperandPill.getGui());
  }
  getColumnKey() {
    return this.filterModel.colId;
  }
  getColumnDisplayValue() {
    return this.advancedFilterExpressionService.getColumnDisplayValue(this.filterModel);
  }
  getOperatorKey() {
    return this.filterModel.type;
  }
  getOperatorDisplayValue() {
    return this.advancedFilterExpressionService.getOperatorDisplayValue(this.filterModel);
  }
  getOperandDisplayValue() {
    return this.advancedFilterExpressionService.getOperandDisplayValue(this.filterModel, true);
  }
  hasOperand() {
    return this.numOperands > 0;
  }
  getOperatorAutocompleteEntries() {
    return this.column ? this.advancedFilterExpressionService.getOperatorAutocompleteEntries(this.column, this.baseCellDataType) : [];
  }
  setColumnKey(colId) {
    if (!this.eOperatorPill) {
      this.createOperatorPill();
    }
    const newColumnDetails = this.advancedFilterExpressionService.getColumnDetails(colId);
    this.column = newColumnDetails.column;
    const newBaseCellDataType = newColumnDetails.baseCellDataType;
    if (this.baseCellDataType !== newBaseCellDataType) {
      this.baseCellDataType = newBaseCellDataType;
      this.setOperatorKey(void 0);
      if (this.eOperatorPill) {
        _removeFromParent(this.eOperatorPill.getGui());
        this.destroyBean(this.eOperatorPill);
        this.createOperatorPill();
      }
      this.validate();
    }
    this.filterModel.colId = colId;
    this.filterModel.filterType = this.baseCellDataType;
  }
  setOperatorKey(operator) {
    const newNumOperands = this.getNumOperands(operator);
    if (newNumOperands !== this.numOperands) {
      this.numOperands = newNumOperands;
      if (newNumOperands === 0) {
        this.destroyOperandPill();
      } else {
        this.createOperandPill();
        if (this.baseCellDataType !== "number") {
          this.setOperand("");
        }
      }
    }
    this.filterModel.type = operator;
    this.validate();
  }
  setOperand(operand) {
    let parsedOperand = operand;
    if (this.column) {
      parsedOperand = this.advancedFilterExpressionService.getOperandModelValue(
        operand,
        this.baseCellDataType,
        this.column
      ) ?? "";
    }
    this.filterModel.filter = parsedOperand;
    this.validate();
  }
  getNumOperands(operator) {
    return this.advancedFilterExpressionService.getExpressionOperator(this.baseCellDataType, operator)?.numOperands ?? 0;
  }
  destroyOperandPill() {
    delete this.filterModel.filter;
    this.getGui().removeChild(this.eOperandPill.getGui());
    this.destroyBean(this.eOperandPill);
    this.eOperandPill = void 0;
  }
  validate() {
    let validationMessage = null;
    if (!_exists4(this.getColumnKey())) {
      validationMessage = this.advancedFilterExpressionService.translate(
        "advancedFilterBuilderValidationSelectColumn"
      );
    } else if (!_exists4(this.getOperatorKey())) {
      validationMessage = this.advancedFilterExpressionService.translate(
        "advancedFilterBuilderValidationSelectOption"
      );
    } else if (this.numOperands > 0 && !_exists4(this.getOperandDisplayValue())) {
      validationMessage = this.advancedFilterExpressionService.translate(
        "advancedFilterBuilderValidationEnterValue"
      );
    }
    this.item.valid = !validationMessage;
    if (validationMessage !== this.validationMessage) {
      this.validationMessage = validationMessage;
      this.dispatchLocalEvent({
        type: "advancedFilterBuilderValidChanged"
      });
    }
  }
  getDefaultColumnDisplayValue() {
    return this.advancedFilterExpressionService.translate("advancedFilterBuilderSelectColumn");
  }
  getDefaultOptionSelectValue() {
    return this.advancedFilterExpressionService.translate("advancedFilterBuilderSelectOption");
  }
};
var InputPillComp = class extends Component72 {
  constructor(params) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-pill-wrapper" role="presentation">
                <div data-ref="ePill" class="ag-advanced-filter-builder-pill" role="button">
                    <span data-ref="eLabel" class="ag-advanced-filter-builder-pill-display"></span>
                </div>
            </div>
        `
    );
    this.params = params;
    this.ePill = RefPlaceholder52;
    this.eLabel = RefPlaceholder52;
    this.value = params.value;
  }
  wireBeans(beans) {
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
  }
  postConstruct() {
    const { cssClass, ariaLabel } = this.params;
    this.ePill.classList.add(cssClass);
    this.activateTabIndex([this.ePill]);
    this.eLabel.id = `${this.getCompId()}`;
    _setAriaDescribedBy(this.ePill, this.eLabel.id);
    _setAriaLabel32(this.ePill, ariaLabel);
    this.renderValue();
    this.addManagedListeners(this.ePill, {
      click: (event) => {
        event.preventDefault();
        this.showEditor();
      },
      keydown: (event) => {
        switch (event.key) {
          case KeyCode52.ENTER:
            event.preventDefault();
            _stopPropagationForAgGrid32(event);
            this.showEditor();
            break;
        }
      }
    });
    this.addDestroyFunc(() => this.destroyBean(this.eEditor));
  }
  getFocusableElement() {
    return this.ePill;
  }
  showEditor() {
    if (this.eEditor) {
      return;
    }
    _setDisplayed32(this.ePill, false);
    this.eEditor = this.createEditorComp(this.params.type);
    this.eEditor.setValue(this.value);
    const eEditorGui = this.eEditor.getGui();
    this.eEditor.addManagedElementListeners(eEditorGui, {
      keydown: (event) => {
        switch (event.key) {
          case KeyCode52.ENTER:
            event.preventDefault();
            _stopPropagationForAgGrid32(event);
            this.updateValue(true);
            break;
          case KeyCode52.ESCAPE:
            event.preventDefault();
            _stopPropagationForAgGrid32(event);
            this.hideEditor(true);
            break;
        }
      },
      focusout: () => this.updateValue(false)
    });
    this.getGui().appendChild(eEditorGui);
    this.eEditor.getFocusableElement().focus();
  }
  createEditorComp(type) {
    let comp;
    switch (type) {
      case "text":
        comp = new AgInputTextField();
        break;
      case "number":
        comp = new AgInputNumberField();
        break;
      case "date":
        comp = new AgInputDateField();
        break;
    }
    return this.createBean(comp);
  }
  hideEditor(keepFocus) {
    const { eEditor } = this;
    if (!eEditor) {
      return;
    }
    this.eEditor = void 0;
    this.getGui().removeChild(eEditor.getGui());
    this.destroyBean(eEditor);
    _setDisplayed32(this.ePill, true);
    if (keepFocus) {
      this.ePill.focus();
    }
  }
  renderValue() {
    let value;
    this.eLabel.classList.remove(
      "ag-advanced-filter-builder-value-empty",
      "ag-advanced-filter-builder-value-number",
      "ag-advanced-filter-builder-value-text"
    );
    if (!_exists5(this.value)) {
      value = this.advancedFilterExpressionService.translate("advancedFilterBuilderEnterValue");
      this.eLabel.classList.add("ag-advanced-filter-builder-value-empty");
    } else if (this.params.type === "number") {
      value = this.value;
      this.eLabel.classList.add("ag-advanced-filter-builder-value-number");
    } else {
      value = `"${this.value}"`;
      this.eLabel.classList.add("ag-advanced-filter-builder-value-text");
    }
    this.eLabel.innerText = value;
  }
  updateValue(keepFocus) {
    if (!this.eEditor) {
      return;
    }
    const value = this.eEditor.getValue() ?? "";
    this.dispatchLocalEvent({
      type: "fieldValueChanged",
      value
    });
    this.value = value;
    this.renderValue();
    this.hideEditor(keepFocus);
  }
};
var JoinPillWrapperComp = class extends Component82 {
  wireBeans(beans) {
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
  }
  constructor() {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-condition" role="presentation"></div>
        `
    );
  }
  init(params) {
    const { item, createPill } = params;
    const filterModel = item.filterModel;
    this.filterModel = filterModel;
    this.ePill = createPill({
      key: filterModel.type,
      displayValue: this.advancedFilterExpressionService.parseJoinOperator(filterModel),
      cssClass: "ag-advanced-filter-builder-join-pill",
      isSelect: true,
      getEditorParams: () => ({
        values: this.advancedFilterExpressionService.getJoinOperatorAutocompleteEntries()
      }),
      update: (key) => filterModel.type = key,
      pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderJoinSelectField",
      pickerAriaLabelValue: "Advanced Filter Builder Join Operator Select Field",
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderJoinOperator")
    });
    this.getGui().appendChild(this.ePill.getGui());
    this.addDestroyFunc(() => this.destroyBean(this.ePill));
  }
  getDragName() {
    return this.advancedFilterExpressionService.parseJoinOperator(this.filterModel);
  }
  getAriaLabel() {
    return `${this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderGroupItem")} ${this.getDragName()}`;
  }
  getValidationMessage() {
    return null;
  }
  getFocusableElement() {
    return this.ePill.getFocusableElement();
  }
};
var SelectPillComp = class extends AgRichSelect {
  constructor(params) {
    super({
      ...params,
      template: (
        /* html */
        `
                <div class="ag-picker-field ag-advanced-filter-builder-pill-wrapper" role="presentation">
                    <div data-ref="eLabel"></div>
                    <div data-ref="eWrapper" class="ag-wrapper ag-advanced-filter-builder-pill ag-picker-collapsed">
                        <div data-ref="eDisplayField" class="ag-picker-field-display ag-advanced-filter-builder-pill-display"></div>
                        <ag-input-text-field data-ref="eInput" class="ag-rich-select-field-input"></ag-input-text-field>
                        <span data-ref="eDeselect" class="ag-rich-select-deselect-button ag-picker-field-icon" role="presentation"></span>
                        <div data-ref="eIcon" class="ag-picker-field-icon" aria-hidden="true"></div>
                    </div>
                </div>`
      ),
      agComponents: [AgInputTextFieldSelector3]
    });
    this.params = params;
  }
  getFocusableElement() {
    return this.eWrapper;
  }
  showPicker() {
    setTimeout(() => super.showPicker());
  }
  hidePicker() {
    setTimeout(() => super.hidePicker());
  }
  postConstruct() {
    super.postConstruct();
    const { wrapperClassName, ariaLabel } = this.params;
    this.eWrapper.classList.add(wrapperClassName);
    _setAriaLabelledBy2(this.eWrapper, "");
    _setAriaLabel42(this.eWrapper, ariaLabel);
  }
  createPickerComponent() {
    if (!this.values) {
      const { values } = this.params.getEditorParams();
      this.values = values;
      const key = this.value.key;
      const value = values.find((value2) => value2.key === key) ?? {
        key,
        displayValue: this.value.displayValue
      };
      this.value = value;
    }
    return super.createPickerComponent();
  }
  onEnterKeyDown(event) {
    _stopPropagationForAgGrid42(event);
    if (this.isPickerDisplayed) {
      super.onEnterKeyDown(event);
    } else {
      event.preventDefault();
      this.showPicker();
    }
  }
};
var AdvancedFilterBuilderItemComp = class extends TabGuardComp5 {
  constructor(item, dragFeature, focusWrapper) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-wrapper" role="presentation">
                <div class="ag-advanced-filter-builder-item" role="presentation">
                    <div data-ref="eTreeLines" class="ag-advanced-filter-builder-item-tree-lines" aria-hidden="true"></div>
                    <span data-ref="eDragHandle" class="ag-drag-handle" aria-hidden="true"></span>
                    <span data-ref="eValidation" class="ag-advanced-filter-builder-item-button ag-advanced-filter-builder-invalid" aria-hidden="true"></span>
                </div>
                <div data-ref="eButtons" class="ag-advanced-filter-builder-item-buttons">
                    <span data-ref="eMoveUpButton" class="ag-advanced-filter-builder-item-button" role="button"></span>
                    <span data-ref="eMoveDownButton" class="ag-advanced-filter-builder-item-button" role="button"></span>
                    <div data-ref="eAddButton" role="presentation"></div>
                    <span data-ref="eRemoveButton" class="ag-advanced-filter-builder-item-button" role="button"></span>
                </div>
            </div>
        `
    );
    this.item = item;
    this.dragFeature = dragFeature;
    this.focusWrapper = focusWrapper;
    this.eTreeLines = RefPlaceholder62;
    this.eDragHandle = RefPlaceholder62;
    this.eButtons = RefPlaceholder62;
    this.eValidation = RefPlaceholder62;
    this.eMoveUpButton = RefPlaceholder62;
    this.eMoveDownButton = RefPlaceholder62;
    this.eAddButton = RefPlaceholder62;
    this.eRemoveButton = RefPlaceholder62;
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
  }
  wireBeans(beans) {
    this.dragAndDropService = beans.dragAndDropService;
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
  }
  postConstruct() {
    const { filterModel, level, showMove } = this.item;
    const isJoin = filterModel.filterType === "join";
    this.ePillWrapper = this.createManagedBean(isJoin ? new JoinPillWrapperComp() : new ConditionPillWrapperComp());
    this.ePillWrapper.init({ item: this.item, createPill: (params) => this.createPill(params) });
    this.eDragHandle.insertAdjacentElement("afterend", this.ePillWrapper.getGui());
    if (level === 0) {
      const eTreeLine = document.createElement("div");
      eTreeLine.classList.add("ag-advanced-filter-builder-item-tree-line-vertical-bottom");
      eTreeLine.classList.add("ag-advanced-filter-builder-item-tree-line-root");
      this.eTreeLines.appendChild(eTreeLine);
      _setDisplayed42(this.eDragHandle, false);
      _setDisplayed42(this.eButtons, false);
      _setAriaExpanded5(this.focusWrapper, true);
    } else {
      this.setupTreeLines(level);
      this.eDragHandle.appendChild(_createIconNoSpan22("advancedFilterBuilderDrag", this.gos));
      this.setupValidation();
      this.setupMoveButtons(showMove);
      this.setupAddButton();
      this.setupRemoveButton();
      this.setupDragging();
      this.updateAriaExpanded();
    }
    _setAriaLevel22(this.focusWrapper, level + 1);
    this.initialiseTabGuard({});
    this.createManagedBean(
      new AdvancedFilterBuilderItemNavigationFeature(this.getGui(), this.focusWrapper, this.ePillWrapper)
    );
    this.updateAriaLabel();
    this.addManagedListeners(this.ePillWrapper, {
      advancedFilterBuilderValueChanged: () => this.dispatchLocalEvent({
        type: "advancedFilterBuilderValueChanged"
      }),
      advancedFilterBuilderValidChanged: () => this.updateValidity()
    });
  }
  setState(params) {
    const { level } = this.item;
    if (level === 0) {
      return;
    }
    const { showMove } = this.item;
    const { disableMoveUp, disableMoveDown, treeLines, showStartTreeLine } = params;
    this.updateTreeLines(treeLines, showStartTreeLine);
    this.updateAriaExpanded();
    if (showMove) {
      this.moveUpDisabled = !!disableMoveUp;
      this.moveDownDisabled = !!disableMoveDown;
      this.eMoveUpButton.classList.toggle("ag-advanced-filter-builder-item-button-disabled", disableMoveUp);
      this.eMoveDownButton.classList.toggle("ag-advanced-filter-builder-item-button-disabled", disableMoveDown);
      _setAriaDisabled2(this.eMoveUpButton, !!disableMoveUp);
      _setAriaDisabled2(this.eMoveDownButton, !!disableMoveDown);
      this.moveUpTooltipFeature.refreshToolTip();
      this.moveDownTooltipFeature.refreshToolTip();
    }
  }
  focusMoveButton(backwards) {
    (backwards ? this.eMoveUpButton : this.eMoveDownButton).focus();
  }
  afterAdd() {
    this.ePillWrapper.getFocusableElement().focus();
  }
  setupTreeLines(level) {
    for (let i = 0; i < level; i++) {
      const eTreeLine = document.createElement("div");
      this.eTreeLines.appendChild(eTreeLine);
    }
  }
  updateTreeLines(treeLines, showStartTreeLine) {
    const lastTreeLineIndex = treeLines.length - 1;
    const { children } = this.eTreeLines;
    for (let i = 0; i < lastTreeLineIndex; i++) {
      const eTreeLine2 = children.item(i);
      if (eTreeLine2) {
        eTreeLine2.classList.toggle("ag-advanced-filter-builder-item-tree-line-vertical", !treeLines[i]);
      }
    }
    const eTreeLine = children.item(lastTreeLineIndex);
    if (eTreeLine) {
      eTreeLine.classList.add("ag-advanced-filter-builder-item-tree-line-horizontal");
      const isLastChild = treeLines[lastTreeLineIndex];
      eTreeLine.classList.toggle("ag-advanced-filter-builder-item-tree-line-vertical-top", isLastChild);
      eTreeLine.classList.toggle("ag-advanced-filter-builder-item-tree-line-vertical", !isLastChild);
    }
    this.eDragHandle.classList.toggle(
      "ag-advanced-filter-builder-item-tree-line-vertical-bottom",
      showStartTreeLine
    );
  }
  setupValidation() {
    this.eValidation.appendChild(_createIconNoSpan22("advancedFilterBuilderInvalid", this.gos));
    this.validationTooltipFeature = this.createManagedBean(
      new TooltipFeature22({
        getGui: () => this.eValidation,
        getLocation: () => "advancedFilter",
        getTooltipValue: () => this.ePillWrapper.getValidationMessage(),
        getTooltipShowDelayOverride: () => 1e3
      })
    );
    this.updateValidity();
  }
  setupAddButton() {
    const addButtonParams = getAdvancedFilterBuilderAddButtonParams(
      (key) => this.advancedFilterExpressionService.translate(key),
      this.gos.get("advancedFilterBuilderParams")?.addSelectWidth
    );
    const eAddButton = this.createManagedBean(new AddDropdownComp(addButtonParams));
    this.addManagedListeners(eAddButton, {
      fieldPickerValueSelected: ({ value }) => this.dispatchLocalEvent({
        type: "advancedFilterBuilderAdded",
        item: this.item,
        isJoin: value.key === "join"
      })
    });
    this.eAddButton.appendChild(eAddButton.getGui());
    this.createManagedBean(
      new TooltipFeature22({
        getGui: () => this.eAddButton,
        getLocation: () => "advancedFilter",
        getTooltipValue: () => this.advancedFilterExpressionService.translate("advancedFilterBuilderAddButtonTooltip")
      })
    );
  }
  setupRemoveButton() {
    this.eRemoveButton.appendChild(_createIconNoSpan22("advancedFilterBuilderRemove", this.gos));
    this.addManagedListeners(this.eRemoveButton, {
      click: () => this.removeItem(),
      keydown: (event) => {
        switch (event.key) {
          case KeyCode62.ENTER:
            event.preventDefault();
            _stopPropagationForAgGrid52(event);
            this.removeItem();
            break;
        }
      }
    });
    this.createManagedBean(
      new TooltipFeature22({
        getGui: () => this.eRemoveButton,
        getLocation: () => "advancedFilter",
        getTooltipValue: () => this.advancedFilterExpressionService.translate("advancedFilterBuilderRemoveButtonTooltip")
      })
    );
    _setAriaLabel52(
      this.eRemoveButton,
      this.advancedFilterExpressionService.translate("advancedFilterBuilderRemoveButtonTooltip")
    );
    this.activateTabIndex([this.eRemoveButton]);
  }
  setupMoveButtons(showMove) {
    if (showMove) {
      this.eMoveUpButton.appendChild(_createIconNoSpan22("advancedFilterBuilderMoveUp", this.gos));
      this.addManagedListeners(this.eMoveUpButton, {
        click: () => this.moveItem(true),
        keydown: (event) => {
          switch (event.key) {
            case KeyCode62.ENTER:
              event.preventDefault();
              _stopPropagationForAgGrid52(event);
              this.moveItem(true);
              break;
          }
        }
      });
      this.moveUpTooltipFeature = this.createManagedBean(
        new TooltipFeature22({
          getGui: () => this.eMoveUpButton,
          getLocation: () => "advancedFilter",
          getTooltipValue: () => this.moveUpDisabled ? null : this.advancedFilterExpressionService.translate(
            "advancedFilterBuilderMoveUpButtonTooltip"
          )
        })
      );
      _setAriaLabel52(
        this.eMoveUpButton,
        this.advancedFilterExpressionService.translate("advancedFilterBuilderMoveUpButtonTooltip")
      );
      this.eMoveDownButton.appendChild(_createIconNoSpan22("advancedFilterBuilderMoveDown", this.gos));
      this.addManagedListeners(this.eMoveDownButton, {
        click: () => this.moveItem(false),
        keydown: (event) => {
          switch (event.key) {
            case KeyCode62.ENTER:
              event.preventDefault();
              _stopPropagationForAgGrid52(event);
              this.moveItem(false);
              break;
          }
        }
      });
      this.moveDownTooltipFeature = this.createManagedBean(
        new TooltipFeature22({
          getGui: () => this.eMoveDownButton,
          getLocation: () => "advancedFilter",
          getTooltipValue: () => this.moveDownDisabled ? null : this.advancedFilterExpressionService.translate(
            "advancedFilterBuilderMoveDownButtonTooltip"
          )
        })
      );
      _setAriaLabel52(
        this.eMoveDownButton,
        this.advancedFilterExpressionService.translate("advancedFilterBuilderMoveDownButtonTooltip")
      );
      this.activateTabIndex([this.eMoveUpButton, this.eMoveDownButton]);
    } else {
      _setDisplayed42(this.eMoveUpButton, false);
      _setDisplayed42(this.eMoveDownButton, false);
    }
  }
  updateValidity() {
    _setVisible(this.eValidation, !this.item.valid);
    this.validationTooltipFeature.refreshToolTip();
    this.updateAriaLabel();
  }
  createPill(params) {
    const { key, displayValue, cssClass, update, ariaLabel } = params;
    const onUpdated = (key2) => {
      if (key2 == null) {
        return;
      }
      update(key2);
      this.dispatchLocalEvent({
        type: "advancedFilterBuilderValueChanged"
      });
    };
    if (params.isSelect) {
      const { getEditorParams, pickerAriaLabelKey, pickerAriaLabelValue } = params;
      const advancedFilterBuilderParams = this.gos.get("advancedFilterBuilderParams");
      const minPickerWidth = `${advancedFilterBuilderParams?.pillSelectMinWidth ?? 140}px`;
      const maxPickerWidth = `${advancedFilterBuilderParams?.pillSelectMaxWidth ?? 200}px`;
      const comp = this.createBean(
        new SelectPillComp({
          pickerAriaLabelKey,
          pickerAriaLabelValue,
          pickerType: "ag-list",
          value: {
            key,
            displayValue
          },
          valueFormatter: (value) => value == null ? null : value.displayValue ?? value.key,
          variableWidth: true,
          minPickerWidth,
          maxPickerWidth,
          getEditorParams,
          wrapperClassName: cssClass,
          ariaLabel
        })
      );
      this.addManagedListeners(comp, {
        fieldPickerValueSelected: ({ value }) => onUpdated(value?.key)
      });
      return comp;
    } else {
      const comp = this.createBean(
        new InputPillComp({
          value: displayValue,
          cssClass,
          type: this.getInputType(params.baseCellDataType),
          ariaLabel
        })
      );
      this.addManagedListeners(comp, { fieldValueChanged: ({ value }) => onUpdated(value) });
      return comp;
    }
  }
  getInputType(baseCellDataType) {
    switch (baseCellDataType) {
      case "text":
      case "object":
      case "boolean":
        return "text";
      case "number":
        return "number";
      case "date":
      case "dateString":
        return "date";
    }
  }
  setupDragging() {
    const dragSource = {
      type: DragSourceType2.AdvancedFilterBuilder,
      eElement: this.eDragHandle,
      dragItemName: () => this.ePillWrapper.getDragName(),
      getDefaultIconName: () => "notAllowed",
      getDragItem: () => ({}),
      onDragStarted: () => this.dragFeature.dispatchLocalEvent({
        type: "advancedFilterBuilderDragStarted",
        item: this.item
      }),
      onDragStopped: () => this.dragFeature.dispatchLocalEvent({
        type: "advancedFilterBuilderDragEnded"
      })
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  updateAriaLabel() {
    const wrapperLabel = this.ePillWrapper.getAriaLabel();
    const level = `${this.item.level + 1}`;
    const validationMessage = this.ePillWrapper.getValidationMessage();
    let ariaLabel;
    if (validationMessage) {
      ariaLabel = this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderItemValidation", [
        wrapperLabel,
        level,
        validationMessage
      ]);
    } else {
      ariaLabel = this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderItem", [
        wrapperLabel,
        level
      ]);
    }
    _setAriaLabel52(this.focusWrapper, ariaLabel);
  }
  updateAriaExpanded() {
    _removeAriaExpanded2(this.focusWrapper);
    const { filterModel } = this.item;
    if (filterModel?.filterType === "join" && filterModel.conditions.length) {
      _setAriaExpanded5(this.focusWrapper, true);
    }
  }
  removeItem() {
    this.dispatchLocalEvent({
      type: "advancedFilterBuilderRemoved",
      item: this.item
    });
  }
  moveItem(backwards) {
    this.dispatchLocalEvent({
      type: "advancedFilterBuilderMoved",
      item: this.item,
      backwards
    });
  }
};
var AdvancedFilterBuilderComp = class extends Component92 {
  constructor() {
    super(
      /* html */
      `
            <div role="presentation" class="ag-advanced-filter-builder" tabindex="-1">
                <div role="presentation" class="ag-advanced-filter-builder-list" data-ref="eList"></div>
                <div role="presentation" class="ag-advanced-filter-builder-button-panel">
                    <button class="ag-button ag-standard-button ag-advanced-filter-builder-apply-button" data-ref="eApplyFilterButton"></button>
                    <button class="ag-button ag-standard-button ag-advanced-filter-builder-cancel-button" data-ref="eCancelFilterButton"></button>
                </div>
            </div>`
    );
    this.eList = RefPlaceholder72;
    this.eApplyFilterButton = RefPlaceholder72;
    this.eCancelFilterButton = RefPlaceholder72;
    this.validationMessage = null;
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.advancedFilterService = beans.advancedFilterService;
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
  }
  postConstruct() {
    const { showMoveButtons } = this.gos.get("advancedFilterBuilderParams") ?? {};
    this.showMove = !!showMoveButtons;
    this.addManagedPropertyListener("advancedFilterBuilderParams", ({ currentValue }) => {
      this.showMove = !!currentValue?.showMoveButtons;
      this.refreshList(false);
    });
    this.filterModel = this.setupFilterModel();
    this.setupVirtualList();
    this.dragFeature = this.createManagedBean(new AdvancedFilterBuilderDragFeature(this, this.virtualList));
    this.setupButtons();
  }
  refresh() {
    let indexToFocus = this.virtualList.getLastFocusedRow();
    this.setupFilterModel();
    this.validateItems();
    this.refreshList(false);
    if (indexToFocus != null) {
      if (!this.virtualList.getComponentAt(indexToFocus)) {
        indexToFocus = 0;
      }
      this.virtualList.focusRow(indexToFocus);
    }
  }
  getNumItems() {
    return this.items.length;
  }
  moveItem(item, destination) {
    if (!destination || !item) {
      return;
    }
    this.moveItemToIndex(item, destination.rowIndex, destination.position);
  }
  afterGuiAttached() {
    this.virtualList.awaitStable(() => this.virtualList.focusRow(0));
  }
  setupVirtualList() {
    this.virtualList = this.createManagedBean(
      new VirtualList({
        cssIdentifier: "advanced-filter-builder",
        ariaRole: "tree",
        listName: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderList")
      })
    );
    this.virtualList.setComponentCreator(this.createItemComponent.bind(this));
    this.virtualList.setComponentUpdater(this.updateItemComponent.bind(this));
    this.virtualList.setRowHeight(40);
    this.eList.appendChild(this.virtualList.getGui());
    this.virtualList.setModel({
      getRowCount: () => this.items.length,
      getRow: (index) => this.items[index],
      areRowsEqual: (oldRow, newRow) => oldRow === newRow
    });
    this.buildList();
    this.virtualList.refresh();
  }
  setupButtons() {
    this.eApplyFilterButton.innerText = this.advancedFilterExpressionService.translate("advancedFilterBuilderApply");
    this.activateTabIndex([this.eApplyFilterButton]);
    this.addManagedElementListeners(this.eApplyFilterButton, {
      click: () => {
        this.advancedFilterService.setModel(this.filterModel);
        this.filterManager?.onFilterChanged({ source: "advancedFilter" });
        this.close();
      }
    });
    this.validationTooltipFeature = this.createManagedBean(
      new TooltipFeature3({
        getGui: () => this.eApplyFilterButton,
        getLocation: () => "advancedFilter",
        getTooltipValue: () => this.validationMessage,
        getTooltipShowDelayOverride: () => 1e3
      })
    );
    this.validate();
    const mouseListener = (isEnter) => this.addOrRemoveCssClass("ag-advanced-filter-builder-validation", isEnter);
    this.addManagedListeners(this.eApplyFilterButton, {
      mouseenter: () => mouseListener(true),
      mouseleave: () => mouseListener(false)
    });
    this.eCancelFilterButton.innerText = this.advancedFilterExpressionService.translate("advancedFilterBuilderCancel");
    this.activateTabIndex([this.eCancelFilterButton]);
    this.addManagedElementListeners(this.eCancelFilterButton, { click: () => this.close() });
  }
  removeItemFromParent(item) {
    const sourceParentIndex = item.parent.conditions.indexOf(item.filterModel);
    item.parent.conditions.splice(sourceParentIndex, 1);
    return sourceParentIndex;
  }
  moveItemToIndex(item, destinationRowIndex, destinationPosition) {
    const destinationItem = this.items[destinationRowIndex];
    const destinationIsParent = destinationItem.filterModel?.filterType === "join" && destinationPosition === "bottom";
    const destinationParent = destinationIsParent ? destinationItem.filterModel : destinationItem.parent;
    if (!destinationParent) {
      return;
    }
    if (this.isChildOrSelf(destinationParent, item.filterModel) || destinationItem === item) {
      return;
    }
    this.removeItemFromParent(item);
    let destinationParentIndex;
    if (destinationIsParent) {
      destinationParentIndex = 0;
    } else {
      destinationParentIndex = destinationParent.conditions.indexOf(destinationItem.filterModel);
      if (destinationParentIndex === -1) {
        destinationParentIndex = destinationParent.conditions.length;
      } else if (destinationPosition === "bottom") {
        destinationParentIndex += 1;
      }
    }
    destinationParent.conditions.splice(destinationParentIndex, 0, item.filterModel);
    this.refreshList(false);
  }
  isChildOrSelf(modelToCheck, potentialParentModel) {
    return modelToCheck === potentialParentModel || potentialParentModel.filterType === "join" && potentialParentModel.conditions.some((condition) => this.isChildOrSelf(modelToCheck, condition));
  }
  setupFilterModel() {
    const filterModel = this.formatFilterModel(this.advancedFilterService.getModel());
    this.stringifiedModel = JSON.stringify(filterModel);
    return filterModel;
  }
  formatFilterModel(filterModel) {
    filterModel = filterModel ?? {
      filterType: "join",
      type: "AND",
      conditions: []
    };
    if (filterModel.filterType !== "join") {
      filterModel = {
        filterType: "join",
        type: "AND",
        conditions: [filterModel]
      };
    }
    return filterModel;
  }
  buildList() {
    const parseFilterModel = (filterModel, items, level, parent) => {
      items.push({ filterModel, level, parent, valid: true, showMove: this.showMove });
      if (filterModel.filterType === "join") {
        filterModel.conditions.forEach(
          (childFilterModel) => parseFilterModel(childFilterModel, items, level + 1, filterModel)
        );
        if (level === 0) {
          items.push({ filterModel: null, level: level + 1, parent: filterModel, valid: true });
        }
      }
    };
    this.items = [];
    parseFilterModel(this.filterModel, this.items, 0);
  }
  refreshList(softRefresh) {
    if (!softRefresh) {
      const invalidModels = [];
      this.items.forEach((item) => {
        if (!item.valid) {
          invalidModels.push(item.filterModel);
        }
      });
      this.buildList();
      if (invalidModels.length) {
        this.items.forEach((item) => {
          if (item.filterModel && invalidModels.includes(item.filterModel)) {
            item.valid = false;
          }
        });
      }
    }
    this.virtualList.refresh(softRefresh);
    this.validate();
  }
  updateItemComponent(item, comp) {
    const index = this.items.indexOf(item);
    const populateTreeLines = (filterModel2, treeLines2) => {
      const parentItem = this.items.find((itemToCheck) => itemToCheck.filterModel === filterModel2);
      const parentFilterModel = parentItem?.parent;
      if (parentFilterModel) {
        const { conditions } = parentFilterModel;
        populateTreeLines(parentFilterModel, treeLines2);
        treeLines2.push(conditions[conditions.length - 1] === filterModel2);
      }
    };
    const treeLines = [];
    const { filterModel } = item;
    if (filterModel) {
      populateTreeLines(filterModel, treeLines);
      treeLines[0] = false;
    }
    const showStartTreeLine = filterModel?.filterType === "join" && !!filterModel.conditions.length;
    comp.setState({
      disableMoveUp: index === 1,
      disableMoveDown: !this.canMoveDown(item, index),
      treeLines,
      showStartTreeLine
    });
  }
  createItemComponent(item, focusWrapper) {
    const itemComp = this.createBean(
      item.filterModel ? new AdvancedFilterBuilderItemComp(item, this.dragFeature, focusWrapper) : new AdvancedFilterBuilderItemAddComp(item, focusWrapper)
    );
    itemComp.addManagedListeners(itemComp, {
      advancedFilterBuilderRemoved: ({ item: item2 }) => this.removeItem(item2),
      advancedFilterBuilderValueChanged: () => this.validate(),
      advancedFilterBuilderAdded: ({ item: item2, isJoin }) => this.addItem(item2, isJoin),
      advancedFilterBuilderMoved: ({ item: item2, backwards }) => this.moveItemUpDown(item2, backwards)
    });
    if (itemComp instanceof AdvancedFilterBuilderItemComp) {
      this.updateItemComponent(item, itemComp);
    }
    return itemComp;
  }
  addItem(item, isJoin) {
    const { parent: itemParent, level, filterModel: itemFilterModel } = item;
    const itemIsJoin = itemFilterModel?.filterType === "join";
    const filterModel = isJoin ? {
      filterType: "join",
      type: "AND",
      conditions: []
    } : {};
    const parent = itemIsJoin ? itemFilterModel : itemParent;
    let insertIndex = itemIsJoin ? 0 : parent.conditions.indexOf(itemFilterModel);
    if (insertIndex >= 0) {
      if (!itemIsJoin) {
        insertIndex += 1;
      }
      parent.conditions.splice(insertIndex, 0, filterModel);
    } else {
      parent.conditions.push(filterModel);
    }
    let index = this.items.indexOf(item);
    const softRefresh = index >= 0;
    if (softRefresh) {
      if (item.filterModel) {
        index++;
      }
      const newItems = [
        {
          filterModel,
          level: itemIsJoin ? level + 1 : level,
          parent,
          valid: isJoin,
          showMove: this.showMove
        }
      ];
      this.items.splice(index, 0, ...newItems);
    }
    this.refreshList(softRefresh);
    if (softRefresh) {
      this.virtualList.getComponentAt(index)?.afterAdd();
    }
  }
  removeItem(item) {
    const parent = item.parent;
    const { filterModel } = item;
    const parentIndex = parent.conditions.indexOf(filterModel);
    parent.conditions.splice(parentIndex, 1);
    const isJoin = item.filterModel?.filterType === "join";
    const index = this.items.indexOf(item);
    const softRefresh = !isJoin && index >= 0;
    if (softRefresh) {
      this.items.splice(index, 1);
    }
    this.refreshList(softRefresh);
    if (index >= 0) {
      this.virtualList.focusRow(index);
    }
  }
  moveItemUpDown(item, backwards) {
    const itemIndex = this.items.indexOf(item);
    const destinationIndex = backwards ? itemIndex - 1 : itemIndex + 1;
    if (destinationIndex === 0 || !backwards && !this.canMoveDown(item, itemIndex)) {
      return;
    }
    const destinationItem = this.items[destinationIndex];
    const indexInParent = this.removeItemFromParent(item);
    const { level, filterModel, parent } = item;
    const {
      level: destinationLevel,
      filterModel: destinationFilterModel,
      parent: destinationParent
    } = destinationItem;
    if (backwards) {
      if (destinationLevel === level && destinationFilterModel.filterType === "join") {
        destinationFilterModel.conditions.push(filterModel);
      } else if (destinationLevel <= level) {
        const destinationIndex2 = destinationParent.conditions.indexOf(destinationFilterModel);
        destinationParent.conditions.splice(destinationIndex2, 0, filterModel);
      } else {
        const newParentItem = parent.conditions[indexInParent - 1];
        newParentItem.conditions.push(filterModel);
      }
    } else {
      if (destinationLevel === level) {
        if (destinationFilterModel.filterType === "join") {
          destinationFilterModel.conditions.splice(0, 0, filterModel);
        } else {
          const destinationIndex2 = destinationParent.conditions.indexOf(destinationFilterModel);
          destinationParent.conditions.splice(destinationIndex2 + 1, 0, filterModel);
        }
      } else {
        if (indexInParent < parent.conditions.length) {
          parent.conditions.splice(indexInParent + 1, 0, filterModel);
        } else {
          const parentItem = this.items.find((itemToCheck) => itemToCheck.filterModel === parent);
          const destinationIndex2 = parentItem.parent.conditions.indexOf(parentItem.filterModel) + 1;
          parentItem.parent.conditions.splice(destinationIndex2, 0, filterModel);
        }
      }
    }
    this.refreshList(false);
    const newIndex = this.items.findIndex(
      ({ filterModel: filterModelToCheck }) => filterModelToCheck === filterModel
    );
    if (newIndex >= 0) {
      const comp = this.virtualList.getComponentAt(newIndex);
      if (comp instanceof AdvancedFilterBuilderItemComp) {
        comp.focusMoveButton(backwards);
      }
    }
  }
  canMoveDown(item, index) {
    return !(item.level === 1 && index === this.items.length - 2 || item.level === 1 && item.parent.conditions[item.parent.conditions.length - 1] === item.filterModel);
  }
  close() {
    this.advancedFilterService.getCtrl().toggleFilterBuilder({ source: "ui" });
  }
  validate() {
    let disableApply = !this.items.every(({ valid }) => valid);
    if (!disableApply) {
      disableApply = JSON.stringify(this.filterModel) === this.stringifiedModel;
      if (disableApply) {
        this.validationMessage = this.advancedFilterExpressionService.translate(
          "advancedFilterBuilderValidationAlreadyApplied"
        );
      } else {
        this.validationMessage = null;
      }
    } else {
      this.validationMessage = this.advancedFilterExpressionService.translate(
        "advancedFilterBuilderValidationIncomplete"
      );
    }
    _setDisabled2(this.eApplyFilterButton, disableApply);
    this.validationTooltipFeature.refreshToolTip();
  }
  validateItems() {
    const clearOperator = (filterModel) => {
      filterModel.type = void 0;
    };
    const clearOperand = (filterModel) => {
      delete filterModel.filter;
    };
    this.items.forEach((item) => {
      if (!item.valid || !item.filterModel || item.filterModel.filterType === "join") {
        return;
      }
      const { filterModel } = item;
      const { colId } = filterModel;
      const hasColumn = this.advancedFilterExpressionService.getColumnAutocompleteEntries().find(({ key }) => key === colId);
      const columnDetails = this.advancedFilterExpressionService.getColumnDetails(filterModel.colId);
      if (!hasColumn || !columnDetails.column) {
        item.valid = false;
        filterModel.colId = void 0;
        clearOperator(filterModel);
        clearOperand(filterModel);
        return;
      }
      const operatorForType = this.advancedFilterExpressionService.getDataTypeExpressionOperator(
        columnDetails.baseCellDataType
      );
      const operator = operatorForType.operators[filterModel.type];
      if (!operator) {
        item.valid = false;
        clearOperator(filterModel);
        clearOperand(filterModel);
        return;
      }
      if (operator.numOperands > 0 && !_exists6(filterModel.filter)) {
        item.valid = false;
        return;
      }
    });
  }
};
var AdvancedFilterCtrl = class extends BeanStub42 {
  constructor(enabled) {
    super();
    this.enabled = enabled;
  }
  wireBeans(beans) {
    this.ctrlsService = beans.ctrlsService;
    this.popupService = beans.popupService;
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
    this.environment = beans.environment;
  }
  postConstruct() {
    this.hasAdvancedFilterParent = !!this.gos.get("advancedFilterParent");
    this.ctrlsService.whenReady(this, () => this.setAdvancedFilterComp());
    this.addManagedEventListeners({
      advancedFilterEnabledChanged: ({ enabled }) => this.onEnabledChanged(enabled)
    });
    this.addManagedPropertyListener("advancedFilterParent", () => this.updateComps());
    this.addDestroyFunc(() => {
      this.destroyAdvancedFilterComp();
      this.destroyBean(this.eBuilderComp);
      if (this.eBuilderDialog && this.eBuilderDialog.isAlive()) {
        this.destroyBean(this.eBuilderDialog);
      }
    });
  }
  setupHeaderComp(eCompToInsertBefore) {
    if (this.eHeaderComp) {
      this.eHeaderComp?.getGui().remove();
      this.destroyBean(this.eHeaderComp);
    }
    this.eHeaderComp = this.createManagedBean(
      new AdvancedFilterHeaderComp(this.enabled && !this.hasAdvancedFilterParent)
    );
    eCompToInsertBefore.insertAdjacentElement("beforebegin", this.eHeaderComp.getGui());
  }
  focusHeaderComp() {
    if (this.eHeaderComp) {
      this.eHeaderComp.getFocusableElement().focus();
      return true;
    }
    return false;
  }
  refreshComp() {
    this.eFilterComp?.refresh();
    this.eHeaderComp?.refresh();
  }
  refreshBuilderComp() {
    this.eBuilderComp?.refresh();
  }
  getHeaderHeight() {
    return this.eHeaderComp?.getHeight() ?? 0;
  }
  setInputDisabled(disabled) {
    this.eFilterComp?.setInputDisabled(disabled);
    this.eHeaderComp?.setInputDisabled(disabled);
  }
  toggleFilterBuilder(params) {
    const { source, force, eventSource } = params;
    if (force && this.eBuilderDialog || force === false && !this.eBuilderDialog) {
      return;
    }
    if (this.eBuilderDialog) {
      this.builderDestroySource = source;
      this.destroyBean(this.eBuilderDialog);
      return;
    }
    this.setInputDisabled(true);
    const { width, height, minWidth } = this.getBuilderDialogSize();
    this.eBuilderComp = this.createBean(new AdvancedFilterBuilderComp());
    this.eBuilderDialog = this.createBean(
      new AgDialog({
        title: this.advancedFilterExpressionService.translate("advancedFilterBuilderTitle"),
        component: this.eBuilderComp,
        width,
        height,
        resizable: true,
        movable: true,
        maximizable: true,
        centered: true,
        closable: true,
        minWidth,
        afterGuiAttached: () => this.eBuilderComp?.afterGuiAttached(),
        postProcessPopupParams: {
          type: "advancedFilterBuilder",
          eventSource
        }
      })
    );
    this.dispatchFilterBuilderVisibleChangedEvent(source, true);
    this.eBuilderDialog.addEventListener("destroyed", () => {
      this.destroyBean(this.eBuilderComp);
      this.eBuilderComp = void 0;
      this.eBuilderDialog = void 0;
      this.setInputDisabled(false);
      this.dispatchLocalEvent({
        type: "advancedFilterBuilderClosed"
      });
      this.dispatchFilterBuilderVisibleChangedEvent(this.builderDestroySource ?? "ui", false);
      this.builderDestroySource = void 0;
    });
  }
  dispatchFilterBuilderVisibleChangedEvent(source, visible) {
    this.eventService.dispatchEvent({
      type: "advancedFilterBuilderVisibleChanged",
      source,
      visible
    });
  }
  getBuilderDialogSize() {
    const minWidth = this.gos.get("advancedFilterBuilderParams")?.minWidth ?? 500;
    const popupParent = this.popupService.getPopupParent();
    const maxWidth = Math.round(_getAbsoluteWidth(popupParent)) - 2;
    const maxHeight = Math.round(_getAbsoluteHeight(popupParent) * 0.75) - 2;
    const width = Math.min(Math.max(600, minWidth), maxWidth);
    const height = Math.min(600, maxHeight);
    return { width, height, minWidth };
  }
  onEnabledChanged(enabled) {
    this.enabled = enabled;
    this.updateComps();
  }
  updateComps() {
    this.setAdvancedFilterComp();
    this.setHeaderCompEnabled();
    this.eventService.dispatchEvent({
      type: "headerHeightChanged"
    });
  }
  setAdvancedFilterComp() {
    this.destroyAdvancedFilterComp();
    if (!this.enabled) {
      return;
    }
    const advancedFilterParent = this.gos.get("advancedFilterParent");
    this.hasAdvancedFilterParent = !!advancedFilterParent;
    if (advancedFilterParent) {
      const eAdvancedFilterComp = this.createBean(new AdvancedFilterComp());
      const eAdvancedFilterCompGui = eAdvancedFilterComp.getGui();
      this.environment.applyThemeClasses(eAdvancedFilterCompGui);
      eAdvancedFilterCompGui.classList.add(this.gos.get("enableRtl") ? "ag-rtl" : "ag-ltr");
      advancedFilterParent.appendChild(eAdvancedFilterCompGui);
      this.eFilterComp = eAdvancedFilterComp;
    }
  }
  setHeaderCompEnabled() {
    this.eHeaderComp?.setEnabled(this.enabled && !this.hasAdvancedFilterParent);
  }
  destroyAdvancedFilterComp() {
    if (this.eFilterComp) {
      _removeFromParent2(this.eFilterComp.getGui());
      this.destroyBean(this.eFilterComp);
    }
  }
};
var OperatorParser2 = class {
  constructor(params) {
    this.params = params;
    this.operators = [];
    this.operatorStartPositions = [];
    this.operatorEndPositions = [];
    this.activeOperator = 0;
    this.validationError = null;
  }
  parseExpression(i) {
    this.operators.push("");
    this.operatorStartPositions.push(i);
    this.operatorEndPositions.push(void 0);
    const { expression } = this.params;
    while (i < expression.length) {
      const char = expression[i];
      if (char === " ") {
        const isComplete = this.parseOperator(i - 1);
        if (isComplete) {
          this.activeOperator++;
          return i - 1;
        } else {
          this.operators[this.activeOperator] += char;
        }
      } else {
        this.operators[this.activeOperator] += char;
      }
      i++;
    }
    this.parseOperator(i - 1);
    return i;
  }
  isValid() {
    return !this.validationError && (!this.operators.length || !!this.parsedOperator);
  }
  getValidationError() {
    return this.validationError;
  }
  getFunction() {
    return this.parsedOperator === "OR" ? "||" : "&&";
  }
  getModel() {
    return this.parsedOperator === "OR" ? "OR" : "AND";
  }
  getAutocompleteListParams(position, operatorIndex) {
    let searchString;
    if (operatorIndex == null) {
      searchString = "";
    } else {
      const operator = this.operators[operatorIndex];
      const operatorEndPosition = this.operatorEndPositions[operatorIndex];
      searchString = getSearchString(
        operator,
        position,
        operatorEndPosition == null ? this.params.expression.length : operatorEndPosition + 1
      );
    }
    let entries = this.params.advancedFilterExpressionService.getJoinOperatorAutocompleteEntries();
    if (operatorIndex || operatorIndex == null && this.activeOperator) {
      entries = entries.filter(({ key }) => key === this.parsedOperator);
    }
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(
      entries,
      "join",
      searchString
    );
  }
  updateExpression(position, updateEntry, operatorIndex) {
    let { expression } = this.params;
    const updatedValuePart = updateEntry.displayValue ?? updateEntry.key;
    if (operatorIndex === 0) {
      for (let i = this.operatorEndPositions.length - 1; i > 0; i--) {
        const operatorEndPosition = this.operatorEndPositions[i];
        if (operatorEndPosition == null) {
          continue;
        }
        expression = updateExpression(
          expression,
          this.operatorStartPositions[i],
          operatorEndPosition,
          updatedValuePart
        ).updatedValue;
      }
    }
    const startPosition = this.operatorStartPositions.length > operatorIndex ? this.operatorStartPositions[operatorIndex] : position;
    const endPosition = (this.operatorEndPositions.length > operatorIndex ? this.operatorEndPositions[operatorIndex] : void 0) ?? findEndPosition(expression, position, true).endPosition;
    return updateExpression(expression, startPosition, endPosition, updatedValuePart, true);
  }
  getNumOperators() {
    return this.operators.length;
  }
  getLastOperatorEndPosition() {
    return this.operatorEndPositions[this.operatorEndPositions.length - 1];
  }
  parseOperator(endPosition) {
    const operator = this.operators.length > this.activeOperator ? this.operators[this.activeOperator] : "";
    const joinOperators = this.params.advancedFilterExpressionService.getExpressionJoinOperators();
    const parsedValue = findMatch(operator, joinOperators, (v) => v);
    if (parsedValue) {
      this.operatorEndPositions[this.activeOperator] = endPosition;
      const displayValue = joinOperators[parsedValue];
      if (this.activeOperator) {
        if (parsedValue !== this.parsedOperator) {
          if (!this.validationError) {
            this.validationError = {
              message: this.params.advancedFilterExpressionService.translate(
                "advancedFilterValidationJoinOperatorMismatch"
              ),
              startPosition: endPosition - operator.length + 1,
              endPosition
            };
          }
          return false;
        }
      } else {
        this.parsedOperator = parsedValue;
      }
      if (operator !== displayValue) {
        checkAndUpdateExpression(this.params, operator, displayValue, endPosition);
        this.operators[this.activeOperator] = displayValue;
      }
      return true;
    } else if (parsedValue === null) {
      return false;
    } else {
      if (!this.validationError) {
        this.validationError = {
          message: this.params.advancedFilterExpressionService.translate(
            "advancedFilterValidationInvalidJoinOperator"
          ),
          startPosition: endPosition - operator.length + 1,
          endPosition
        };
      }
      return true;
    }
  }
};
var JoinFilterExpressionParser = class _JoinFilterExpressionParser {
  constructor(params, startPosition) {
    this.params = params;
    this.startPosition = startPosition;
    this.expectingExpression = true;
    this.expectingOperator = false;
    this.expressionParsers = [];
    this.operatorParser = new OperatorParser2(this.params);
    this.missingEndBracket = false;
    this.extraEndBracket = false;
  }
  parseExpression() {
    let i = this.startPosition;
    const { expression } = this.params;
    while (i < expression.length) {
      const char = expression[i];
      if (char === "(" && !this.expectingOperator) {
        const nestedParser = new _JoinFilterExpressionParser(this.params, i + 1);
        i = nestedParser.parseExpression();
        this.expressionParsers.push(nestedParser);
        this.expectingExpression = false;
        this.expectingOperator = true;
      } else if (char === ")") {
        this.endPosition = i - 1;
        if (this.startPosition === 0) {
          this.extraEndBracket = true;
        }
        return i;
      } else if (char === " ") {
      } else if (this.expectingExpression) {
        const nestedParser = new ColFilterExpressionParser(this.params, i);
        i = nestedParser.parseExpression();
        this.expressionParsers.push(nestedParser);
        this.expectingExpression = false;
        this.expectingOperator = true;
      } else if (this.expectingOperator) {
        i = this.operatorParser.parseExpression(i);
        this.expectingOperator = false;
        this.expectingExpression = true;
      }
      i++;
    }
    if (this.startPosition > 0) {
      this.missingEndBracket = true;
    }
    return i;
  }
  isValid() {
    return !this.missingEndBracket && !this.extraEndBracket && this.expressionParsers.length === this.operatorParser.getNumOperators() + 1 && this.operatorParser.isValid() && this.expressionParsers.every((expressionParser) => expressionParser.isValid());
  }
  getValidationError() {
    const operatorError = this.operatorParser.getValidationError();
    for (let i = 0; i < this.expressionParsers.length; i++) {
      const expressionError = this.expressionParsers[i].getValidationError();
      if (expressionError) {
        return operatorError && operatorError.startPosition < expressionError.startPosition ? operatorError : expressionError;
      }
    }
    if (operatorError) {
      return operatorError;
    }
    if (this.extraEndBracket) {
      return {
        message: this.params.advancedFilterExpressionService.translate(
          "advancedFilterValidationExtraEndBracket"
        ),
        startPosition: this.endPosition + 1,
        endPosition: this.endPosition + 1
      };
    }
    let translateKey;
    if (this.expressionParsers.length === this.operatorParser.getNumOperators()) {
      translateKey = "advancedFilterValidationMissingCondition";
    } else if (this.missingEndBracket) {
      translateKey = "advancedFilterValidationMissingEndBracket";
    }
    if (translateKey) {
      return {
        message: this.params.advancedFilterExpressionService.translate(translateKey),
        startPosition: this.params.expression.length,
        endPosition: this.params.expression.length
      };
    }
    return null;
  }
  getFunctionString(params) {
    const hasMultipleExpressions = this.expressionParsers.length > 1;
    const expression = this.expressionParsers.map((expressionParser) => expressionParser.getFunctionString(params)).join(` ${this.operatorParser.getFunction()} `);
    return hasMultipleExpressions ? `(${expression})` : expression;
  }
  getFunctionParsed(params) {
    const operator = this.operatorParser.getFunction();
    const funcs = this.expressionParsers.map((expressionParser) => expressionParser.getFunctionParsed(params));
    const arrayFunc = operator === "&&" ? "every" : "some";
    return (expressionProxy, node, p) => funcs[arrayFunc]((func) => func(expressionProxy, node, p));
  }
  getAutocompleteListParams(position) {
    if (this.endPosition != null && position > this.endPosition + 1) {
      return void 0;
    }
    if (!this.expressionParsers.length) {
      return this.getColumnAutocompleteListParams();
    }
    const expressionParserIndex = this.getExpressionParserIndex(position);
    if (expressionParserIndex == null) {
      if (this.params.expression[position] === "(") {
        return { enabled: false };
      }
      return this.getColumnAutocompleteListParams();
    }
    const expressionParser = this.expressionParsers[expressionParserIndex];
    const autocompleteType = expressionParser.getAutocompleteListParams(position);
    if (!autocompleteType) {
      if (expressionParserIndex < this.expressionParsers.length - 1) {
        return this.operatorParser.getAutocompleteListParams(position, expressionParserIndex);
      }
      if (this.expressionParsers.length === this.operatorParser.getNumOperators()) {
        const operatorEndPosition = this.operatorParser.getLastOperatorEndPosition();
        return operatorEndPosition == null || position <= operatorEndPosition + 1 ? this.operatorParser.getAutocompleteListParams(position, this.operatorParser.getNumOperators() - 1) : this.getColumnAutocompleteListParams();
      }
      if (this.params.expression[position - 1] === ")") {
        return { enabled: false };
      }
      return this.operatorParser.getAutocompleteListParams(position);
    }
    return autocompleteType;
  }
  updateExpression(position, updateEntry, type) {
    const expression = this.params.expression;
    const expressionParserIndex = this.getExpressionParserIndex(position);
    if (expressionParserIndex == null) {
      const updatedValuePart = type === "column" ? this.params.advancedFilterExpressionService.getColumnValue(updateEntry) : updateEntry.displayValue ?? updateEntry.key;
      return updateExpression(expression, this.startPosition, this.startPosition, updatedValuePart, true);
    }
    const expressionParser = this.expressionParsers[expressionParserIndex];
    const updatedExpression = expressionParser.updateExpression(position, updateEntry, type);
    if (updatedExpression == null) {
      if (type === "column") {
        return updateExpression(
          expression,
          position,
          expression.length - 1,
          this.params.advancedFilterExpressionService.getColumnValue(updateEntry),
          true
        );
      } else if (this.endPosition != null && position > this.endPosition + 1) {
        return null;
      } else {
        return this.operatorParser.updateExpression(position, updateEntry, expressionParserIndex);
      }
    }
    return updatedExpression;
  }
  getModel() {
    if (this.expressionParsers.length > 1) {
      return {
        filterType: "join",
        type: this.operatorParser.getModel(),
        conditions: this.expressionParsers.map((parser) => parser.getModel())
      };
    } else {
      return this.expressionParsers[0].getModel();
    }
  }
  getColumnAutocompleteListParams() {
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(
      this.params.advancedFilterExpressionService.getColumnAutocompleteEntries(),
      "column",
      ""
    );
  }
  getExpressionParserIndex(position) {
    let expressionParserIndex;
    for (let i = 0; i < this.expressionParsers.length; i++) {
      const expressionParserToCheck = this.expressionParsers[i];
      if (expressionParserToCheck.startPosition > position) {
        break;
      }
      expressionParserIndex = i;
    }
    return expressionParserIndex;
  }
};
var FilterExpressionParser = class {
  constructor(params) {
    this.params = params;
    this.valid = false;
  }
  parseExpression() {
    this.joinExpressionParser = new JoinFilterExpressionParser(this.params, 0);
    const i = this.joinExpressionParser.parseExpression();
    this.valid = i >= this.params.expression.length - 1 && this.joinExpressionParser.isValid();
    return this.params.expression;
  }
  isValid() {
    return this.valid;
  }
  getValidationMessage() {
    const error = this.joinExpressionParser.getValidationError();
    if (!error) {
      return null;
    }
    const { message, startPosition, endPosition } = error;
    return startPosition < this.params.expression.length ? this.params.advancedFilterExpressionService.translate("advancedFilterValidationMessage", [
      message,
      this.params.expression.slice(startPosition, endPosition + 1).trim()
    ]) : this.params.advancedFilterExpressionService.translate("advancedFilterValidationMessageAtEnd", [message]);
  }
  getFunctionString() {
    const params = this.createFunctionParams();
    return {
      functionString: `return ${this.joinExpressionParser.getFunctionString(params)};`,
      params
    };
  }
  getFunctionParsed() {
    const params = this.createFunctionParams();
    return {
      expressionFunction: this.joinExpressionParser.getFunctionParsed(params),
      params
    };
  }
  getAutocompleteListParams(position) {
    return this.joinExpressionParser.getAutocompleteListParams(position) ?? { enabled: false };
  }
  updateExpression(position, updateEntry, type) {
    return this.joinExpressionParser.updateExpression(position, updateEntry, type);
  }
  getModel() {
    return this.isValid() ? this.joinExpressionParser.getModel() : null;
  }
  createFunctionParams() {
    return {
      operands: [],
      operators: [],
      evaluatorParams: []
    };
  }
};
var AdvancedFilterService = class extends BeanStub52 {
  constructor() {
    super(...arguments);
    this.beanName = "advancedFilterService";
    this.appliedExpression = null;
    this.expression = null;
    this.isValid = true;
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.columnModel = beans.columnModel;
    this.dataTypeService = beans.dataTypeService;
    this.advancedFilterExpressionService = beans.advancedFilterExpressionService;
  }
  postConstruct() {
    this.setEnabled(this.gos.get("enableAdvancedFilter"), true);
    this.ctrl = this.createManagedBean(new AdvancedFilterCtrl(this.enabled));
    this.expressionProxy = {
      getValue: (colId, node) => {
        const column = this.columnModel.getColDefCol(colId);
        return column ? this.valueService.getValue(column, node, true) : void 0;
      }
    };
    this.addManagedPropertyListener("enableAdvancedFilter", (event) => this.setEnabled(!!event.currentValue));
    this.addManagedEventListeners({
      newColumnsLoaded: (event) => this.onNewColumnsLoaded(event)
    });
    this.addManagedPropertyListener("includeHiddenColumnsInAdvancedFilter", () => this.updateValidity());
  }
  isEnabled() {
    return this.enabled;
  }
  isFilterPresent() {
    return !!this.expressionFunction;
  }
  doesFilterPass(node) {
    return this.expressionFunction(this.expressionProxy, node, this.expressionParams);
  }
  getModel() {
    const expressionParser = this.createExpressionParser(this.appliedExpression);
    expressionParser?.parseExpression();
    return expressionParser?.getModel() ?? null;
  }
  setModel(model) {
    const parseModel = (model2, isFirstParent) => {
      if (model2.filterType === "join") {
        const operator = this.advancedFilterExpressionService.parseJoinOperator(model2);
        const expression2 = model2.conditions.map((condition) => parseModel(condition)).filter((condition) => _exists7(condition)).join(` ${operator} `);
        return isFirstParent || model2.conditions.length <= 1 ? expression2 : `(${expression2})`;
      } else {
        return this.advancedFilterExpressionService.parseColumnFilterModel(model2);
      }
    };
    const expression = model ? parseModel(model, true) : null;
    this.setExpressionDisplayValue(expression);
    this.applyExpression();
    this.ctrl.refreshComp();
    this.ctrl.refreshBuilderComp();
  }
  getExpressionDisplayValue() {
    return this.expression;
  }
  setExpressionDisplayValue(expression) {
    this.expression = expression;
  }
  isCurrentExpressionApplied() {
    return this.appliedExpression === this.expression;
  }
  createExpressionParser(expression) {
    if (!expression) {
      return null;
    }
    return new FilterExpressionParser({
      expression,
      columnModel: this.columnModel,
      dataTypeService: this.dataTypeService,
      valueService: this.valueService,
      advancedFilterExpressionService: this.advancedFilterExpressionService
    });
  }
  getDefaultExpression(updateEntry) {
    const updatedValue = this.advancedFilterExpressionService.getColumnValue(updateEntry) + " ";
    return {
      updatedValue,
      updatedPosition: updatedValue.length
    };
  }
  isHeaderActive() {
    return !this.gos.get("advancedFilterParent");
  }
  getCtrl() {
    return this.ctrl;
  }
  setEnabled(enabled, silent) {
    const previousValue = this.enabled;
    const isValidRowModel = _isClientSideRowModel(this.gos) || _isServerSideRowModel(this.gos);
    if (enabled && !isValidRowModel) {
      _warnOnce5("Advanced Filter is only supported with the Client-Side Row Model or Server-Side Row Model.");
    }
    this.enabled = enabled && isValidRowModel;
    if (!silent && this.enabled !== previousValue) {
      this.eventService.dispatchEvent({
        type: "advancedFilterEnabledChanged",
        enabled: this.enabled
      });
    }
  }
  applyExpression() {
    const expressionParser = this.createExpressionParser(this.expression);
    expressionParser?.parseExpression();
    this.applyExpressionFromParser(expressionParser);
  }
  applyExpressionFromParser(expressionParser) {
    this.isValid = !expressionParser || expressionParser.isValid();
    if (!expressionParser || !this.isValid) {
      this.expressionFunction = null;
      this.expressionParams = null;
      this.appliedExpression = null;
      return;
    }
    const { expressionFunction, params } = this.getFunction(expressionParser);
    this.expressionFunction = expressionFunction;
    this.expressionParams = params;
    this.appliedExpression = this.expression;
  }
  getFunction(expressionParser) {
    if (this.gos.get("suppressAdvancedFilterEval")) {
      return expressionParser.getFunctionParsed();
    } else {
      const { functionString, params } = expressionParser.getFunctionString();
      return {
        expressionFunction: new Function(
          "expressionProxy",
          "node",
          "params",
          functionString
        ),
        params
      };
    }
  }
  updateValidity() {
    this.advancedFilterExpressionService.resetColumnCaches();
    const expressionParser = this.createExpressionParser(this.expression);
    expressionParser?.parseExpression();
    const isValid = !expressionParser || expressionParser.isValid();
    const updatedValidity = isValid !== this.isValid;
    this.applyExpressionFromParser(expressionParser);
    this.ctrl.refreshComp();
    this.ctrl.refreshBuilderComp();
    return updatedValidity;
  }
  onNewColumnsLoaded(event) {
    if (event.source !== "gridInitializing" || !this.dataTypeService?.isPendingInference()) {
      return;
    }
    this.ctrl.setInputDisabled(true);
    const [destroyFunc] = this.addManagedEventListeners({
      dataTypesInferred: () => {
        destroyFunc?.();
        this.ctrl.setInputDisabled(false);
      }
    });
  }
};
var VERSION2 = "32.3.0";
var AdvancedFilterCoreModule = _defineModule2({
  version: VERSION2,
  moduleName: `${ModuleNames2.AdvancedFilterModule}-core`,
  beans: [AdvancedFilterService, AdvancedFilterExpressionService],
  dependantModules: [EnterpriseCoreModule, _FilterCoreModule]
});
var AdvancedFilterApiModule = _defineModule2({
  version: VERSION2,
  moduleName: `${ModuleNames2.AdvancedFilterModule}-api`,
  apiFunctions: {
    getAdvancedFilterModel,
    setAdvancedFilterModel,
    showAdvancedFilterBuilder,
    hideAdvancedFilterBuilder
  },
  dependantModules: [AdvancedFilterCoreModule]
});
var AdvancedFilterModule = _defineModule2({
  version: VERSION2,
  moduleName: ModuleNames2.AdvancedFilterModule,
  dependantModules: [AdvancedFilterCoreModule, AdvancedFilterApiModule]
});

// enterprise-modules/charts/dist/package/main.esm.mjs
var main_esm_exports = {};
__export(main_esm_exports, {
  GridChartsModule: () => GridChartsModule,
  agCharts: () => agCharts
});
import { AgCharts as AgCharts2, time } from "ag-charts-community";
import { ModuleNames as ModuleNames22, _defineModule as _defineModule4 } from "ag-grid-community";

// enterprise-modules/range-selection/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames3, _defineModule as _defineModule3 } from "ag-grid-community";
import {
  AutoScrollService as AutoScrollService2,
  BeanStub as BeanStub6,
  CellCtrl,
  _areEqual as _areEqual2,
  _exists as _exists8,
  _existsAndNotEmpty as _existsAndNotEmpty2,
  _getCtrlForEventTarget,
  _getSuppressMultiRanges,
  _includes as _includes2,
  _isCellSelectionEnabled,
  _isDomLayout,
  _isUsingNewCellSelectionAPI,
  _last as _last3,
  _makeNull as _makeNull3,
  _missing as _missing2,
  _shallowCompare,
  _warnOnce as _warnOnce6
} from "ag-grid-community";
import { BeanStub as BeanStub23, SelectionHandleType as SelectionHandleType4 } from "ag-grid-community";
import { SelectionHandleType as SelectionHandleType2, _getFillHandle, _last as _last32, _toStringOrNull as _toStringOrNull2, _warnOnce as _warnOnce22 } from "ag-grid-community";
import { Component as Component13, SelectionHandleType, _isVisible as _isVisible3, _last as _last22, _setDisplayed as _setDisplayed7 } from "ag-grid-community";
import { CellRangeType, SelectionHandleType as SelectionHandleType3, _last as _last4 } from "ag-grid-community";
function getCellRanges(beans) {
  return beans.rangeService?.getCellRanges() ?? null;
}
function addCellRange(beans, params) {
  beans.rangeService?.addCellRange(params);
}
function clearRangeSelection(beans) {
  beans.rangeService?.removeAllCellRanges();
}
var RangeService = class extends BeanStub6 {
  constructor() {
    super(...arguments);
    this.beanName = "rangeService";
    this.cellRanges = [];
    this.bodyScrollListener = this.onBodyScroll.bind(this);
    this.dragging = false;
    this.intersectionRange = false;
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.dragService = beans.dragService;
    this.columnModel = beans.columnModel;
    this.visibleColsService = beans.visibleColsService;
    this.cellNavigationService = beans.cellNavigationService;
    this.pinnedRowModel = beans.pinnedRowModel;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.cellPositionUtils = beans.cellPositionUtils;
    this.ctrlsService = beans.ctrlsService;
    this.valueService = beans.valueService;
  }
  postConstruct() {
    const onColumnsChanged = this.onColumnsChanged.bind(this);
    const removeAllCellRanges = () => this.removeAllCellRanges();
    const refreshLastRangeStart = this.refreshLastRangeStart.bind(this);
    this.addManagedEventListeners({
      newColumnsLoaded: onColumnsChanged,
      columnVisible: onColumnsChanged,
      columnValueChanged: onColumnsChanged,
      columnPivotModeChanged: removeAllCellRanges,
      columnRowGroupChanged: removeAllCellRanges,
      columnPivotChanged: removeAllCellRanges,
      columnGroupOpened: refreshLastRangeStart,
      columnMoved: refreshLastRangeStart,
      columnPinned: refreshLastRangeStart
    });
    this.ctrlsService.whenReady(this, (p) => {
      const gridBodyCtrl = p.gridBodyCtrl;
      this.autoScrollService = new AutoScrollService2({
        scrollContainer: gridBodyCtrl.getBodyViewportElement(),
        scrollAxis: "xy",
        getVerticalPosition: () => gridBodyCtrl.getScrollFeature().getVScrollPosition().top,
        setVerticalPosition: (position) => gridBodyCtrl.getScrollFeature().setVerticalScrollPosition(position),
        getHorizontalPosition: () => gridBodyCtrl.getScrollFeature().getHScrollPosition().left,
        setHorizontalPosition: (position) => gridBodyCtrl.getScrollFeature().setHorizontalScrollPosition(position),
        shouldSkipVerticalScroll: () => !_isDomLayout(this.gos, "normal"),
        shouldSkipHorizontalScroll: () => !gridBodyCtrl.getScrollFeature().isHorizontalScrollShowing()
      });
    });
  }
  // Called for both columns loaded & column visibility events
  onColumnsChanged() {
    this.refreshLastRangeStart();
    const allColumns = this.visibleColsService.getAllCols();
    this.cellRanges.forEach((cellRange) => {
      const beforeCols = cellRange.columns;
      cellRange.columns = cellRange.columns.filter(
        (col) => col.isVisible() && allColumns.indexOf(col) !== -1
      );
      const colsInRangeChanged = !_areEqual2(beforeCols, cellRange.columns);
      if (colsInRangeChanged) {
        this.dispatchChangedEvent(false, true, cellRange.id);
      }
    });
    const countBefore = this.cellRanges.length;
    this.cellRanges = this.cellRanges.filter((range) => range.columns.length > 0);
    if (countBefore > this.cellRanges.length) {
      this.dispatchChangedEvent(false, true);
    }
  }
  refreshLastRangeStart() {
    const lastRange = _last3(this.cellRanges);
    if (!lastRange) {
      return;
    }
    this.refreshRangeStart(lastRange);
  }
  isContiguousRange(cellRange) {
    const rangeColumns = cellRange.columns;
    if (!rangeColumns.length) {
      return false;
    }
    const allColumns = this.visibleColsService.getAllCols();
    const allPositions = rangeColumns.map((c) => allColumns.indexOf(c)).sort((a, b) => a - b);
    return _last3(allPositions) - allPositions[0] + 1 === rangeColumns.length;
  }
  getRangeStartRow(cellRange) {
    if (cellRange.startRow && cellRange.endRow) {
      return this.rowPositionUtils.before(cellRange.startRow, cellRange.endRow) ? cellRange.startRow : cellRange.endRow;
    }
    const rowPinned = this.pinnedRowModel.getPinnedTopRowCount() > 0 ? "top" : null;
    return { rowIndex: 0, rowPinned };
  }
  getRangeEndRow(cellRange) {
    if (cellRange.startRow && cellRange.endRow) {
      return this.rowPositionUtils.before(cellRange.startRow, cellRange.endRow) ? cellRange.endRow : cellRange.startRow;
    }
    const pinnedBottomRowCount = this.pinnedRowModel.getPinnedBottomRowCount();
    const pinnedBottom = pinnedBottomRowCount > 0;
    if (pinnedBottom) {
      return {
        rowIndex: pinnedBottomRowCount - 1,
        rowPinned: "bottom"
      };
    }
    return {
      rowIndex: this.rowModel.getRowCount() - 1,
      rowPinned: null
    };
  }
  setRangeToCell(cell, appendRange = false) {
    if (!_isCellSelectionEnabled(this.gos)) {
      return;
    }
    const columns = this.calculateColumnsBetween(cell.column, cell.column);
    if (!columns) {
      return;
    }
    const suppressMultiRangeSelections = _getSuppressMultiRanges(this.gos);
    if (suppressMultiRangeSelections || !appendRange || _missing2(this.cellRanges)) {
      this.removeAllCellRanges(true);
    }
    const rowForCell = {
      rowIndex: cell.rowIndex,
      rowPinned: cell.rowPinned
    };
    const cellRange = {
      startRow: rowForCell,
      endRow: rowForCell,
      columns,
      startColumn: cell.column
    };
    this.cellRanges.push(cellRange);
    this.setNewestRangeStartCell(cell);
    this.onDragStop();
    this.dispatchChangedEvent(true, true);
  }
  extendLatestRangeToCell(cellPosition) {
    if (this.isEmpty() || !this.newestRangeStartCell) {
      return;
    }
    const cellRange = _last3(this.cellRanges);
    this.updateRangeEnd(cellRange, cellPosition);
  }
  updateRangeEnd(cellRange, cellPosition, silent = false) {
    const endColumn = cellPosition.column;
    const colsToAdd = this.calculateColumnsBetween(cellRange.startColumn, endColumn);
    if (!colsToAdd || this.isLastCellOfRange(cellRange, cellPosition)) {
      return;
    }
    cellRange.columns = colsToAdd;
    cellRange.endRow = { rowIndex: cellPosition.rowIndex, rowPinned: cellPosition.rowPinned };
    if (!silent) {
      this.dispatchChangedEvent(true, true, cellRange.id);
    }
  }
  refreshRangeStart(cellRange) {
    const { startColumn, columns } = cellRange;
    const moveColInCellRange = (colToMove, moveToFront) => {
      const otherCols = cellRange.columns.filter((col) => col !== colToMove);
      if (colToMove) {
        cellRange.startColumn = colToMove;
        cellRange.columns = moveToFront ? [colToMove, ...otherCols] : [...otherCols, colToMove];
      } else {
        cellRange.columns = otherCols;
      }
    };
    const { left, right } = this.getRangeEdgeColumns(cellRange);
    const shouldMoveLeftCol = startColumn === columns[0] && startColumn !== left;
    if (shouldMoveLeftCol) {
      moveColInCellRange(left, true);
      return;
    }
    const shouldMoveRightCol = startColumn === _last3(columns) && startColumn === right;
    if (shouldMoveRightCol) {
      moveColInCellRange(right, false);
      return;
    }
  }
  getRangeEdgeColumns(cellRange) {
    const allColumns = this.visibleColsService.getAllCols();
    const allIndices = cellRange.columns.map((c) => allColumns.indexOf(c)).filter((i) => i > -1).sort((a, b) => a - b);
    return {
      left: allColumns[allIndices[0]],
      right: allColumns[_last3(allIndices)]
    };
  }
  // returns true if successful, false if not successful
  extendLatestRangeInDirection(event) {
    if (this.isEmpty() || !this.newestRangeStartCell) {
      return;
    }
    const key = event.key;
    const ctrlKey = event.ctrlKey || event.metaKey;
    const lastRange = _last3(this.cellRanges);
    const startCell = this.newestRangeStartCell;
    const firstCol = lastRange.columns[0];
    const lastCol = _last3(lastRange.columns);
    const endCellIndex = lastRange.endRow.rowIndex;
    const endCellFloating = lastRange.endRow.rowPinned;
    const endCellColumn = startCell.column === firstCol ? lastCol : firstCol;
    const endCell = { column: endCellColumn, rowIndex: endCellIndex, rowPinned: endCellFloating };
    const newEndCell = this.cellNavigationService.getNextCellToFocus(key, endCell, ctrlKey);
    if (!newEndCell) {
      return;
    }
    this.setCellRange({
      rowStartIndex: startCell.rowIndex,
      rowStartPinned: startCell.rowPinned,
      rowEndIndex: newEndCell.rowIndex,
      rowEndPinned: newEndCell.rowPinned,
      columnStart: startCell.column,
      columnEnd: newEndCell.column
    });
    return newEndCell;
  }
  setCellRange(params) {
    if (!_isCellSelectionEnabled(this.gos)) {
      return;
    }
    this.removeAllCellRanges(true);
    this.addCellRange(params);
  }
  setCellRanges(cellRanges) {
    if (_shallowCompare(this.cellRanges, cellRanges)) {
      return;
    }
    this.removeAllCellRanges(true);
    cellRanges.forEach((newRange) => {
      if (newRange.columns && newRange.startRow) {
        this.setNewestRangeStartCell({
          rowIndex: newRange.startRow.rowIndex,
          rowPinned: newRange.startRow.rowPinned,
          column: newRange.columns[0]
        });
      }
      this.cellRanges.push(newRange);
    });
    this.dispatchChangedEvent(false, true);
  }
  setNewestRangeStartCell(position) {
    this.newestRangeStartCell = position;
  }
  clearCellRangeCellValues(params) {
    let { cellRanges } = params;
    const { cellEventSource = "rangeService", dispatchWrapperEvents, wrapperEventSource = "deleteKey" } = params;
    if (dispatchWrapperEvents) {
      this.eventService.dispatchEvent({
        type: "cellSelectionDeleteStart",
        source: wrapperEventSource
      });
      this.eventService.dispatchEvent({
        type: "rangeDeleteStart",
        source: wrapperEventSource
      });
    }
    if (!cellRanges) {
      cellRanges = this.cellRanges;
    }
    cellRanges.forEach((cellRange) => {
      this.forEachRowInRange(cellRange, (rowPosition) => {
        const rowNode = this.rowPositionUtils.getRowNode(rowPosition);
        if (!rowNode) {
          return;
        }
        for (let i = 0; i < cellRange.columns.length; i++) {
          const column = this.columnModel.getCol(cellRange.columns[i]);
          if (!column || !column.isCellEditable(rowNode)) {
            continue;
          }
          const emptyValue = this.valueService.getDeleteValue(column, rowNode);
          rowNode.setDataValue(column, emptyValue, cellEventSource);
        }
      });
    });
    if (dispatchWrapperEvents) {
      this.eventService.dispatchEvent({
        type: "cellSelectionDeleteEnd",
        source: wrapperEventSource
      });
      this.eventService.dispatchEvent({
        type: "rangeDeleteEnd",
        source: wrapperEventSource
      });
    }
  }
  createCellRangeFromCellRangeParams(params) {
    return this.createPartialCellRangeFromRangeParams(params, false);
  }
  // Range service can't normally support a range without columns, but charts can
  createPartialCellRangeFromRangeParams(params, allowEmptyColumns) {
    let columns;
    let startsOnTheRight = false;
    if (params.columns) {
      columns = params.columns.map((c) => this.columnModel.getCol(c)).filter((c) => c);
    } else {
      const columnStart = this.columnModel.getCol(params.columnStart);
      const columnEnd = this.columnModel.getCol(params.columnEnd);
      if (!columnStart || !columnEnd) {
        return;
      }
      columns = this.calculateColumnsBetween(columnStart, columnEnd);
      if (columns && columns.length) {
        startsOnTheRight = columns[0] !== columnStart;
      }
    }
    if (!columns || !allowEmptyColumns && columns.length === 0) {
      return;
    }
    const startRow = params.rowStartIndex != null ? {
      rowIndex: params.rowStartIndex,
      rowPinned: params.rowStartPinned || null
    } : void 0;
    const endRow = params.rowEndIndex != null ? {
      rowIndex: params.rowEndIndex,
      rowPinned: params.rowEndPinned || null
    } : void 0;
    return {
      startRow,
      endRow,
      columns,
      startColumn: startsOnTheRight ? _last3(columns) : columns[0]
    };
  }
  addCellRange(params) {
    const gos = this.gos;
    if (!_isCellSelectionEnabled(gos)) {
      return;
    }
    if (_isUsingNewCellSelectionAPI(gos) && _getSuppressMultiRanges(gos) && this.cellRanges.length > 0) {
      return _warnOnce6("cannot add multiple ranges when `cellSelection.suppressMultiRanges = true`");
    }
    const newRange = this.createCellRangeFromCellRangeParams(params);
    if (newRange) {
      if (newRange.startRow) {
        this.setNewestRangeStartCell({
          rowIndex: newRange.startRow.rowIndex,
          rowPinned: newRange.startRow.rowPinned,
          column: newRange.startColumn
        });
      }
      this.cellRanges.push(newRange);
      this.dispatchChangedEvent(false, true, newRange.id);
    }
  }
  getCellRanges() {
    return this.cellRanges;
  }
  isEmpty() {
    return this.cellRanges.length === 0;
  }
  isMoreThanOneCell() {
    const len = this.cellRanges.length;
    if (len === 0) {
      return false;
    }
    if (len > 1) {
      return true;
    }
    const range = this.cellRanges[0];
    const startRow = this.getRangeStartRow(range);
    const endRow = this.getRangeEndRow(range);
    return startRow.rowPinned !== endRow.rowPinned || startRow.rowIndex !== endRow.rowIndex || range.columns.length !== 1;
  }
  areAllRangesAbleToMerge() {
    const rowToColumnMap = /* @__PURE__ */ new Map();
    const len = this.cellRanges.length;
    if (len <= 1)
      return true;
    this.cellRanges.forEach((range) => {
      this.forEachRowInRange(range, (row) => {
        const rowName = `${row.rowPinned || "normal"}_${row.rowIndex}`;
        const columns = rowToColumnMap.get(rowName);
        const currentRangeColIds = range.columns.map((col) => col.getId());
        if (columns) {
          const filteredColumns = currentRangeColIds.filter((col) => columns.indexOf(col) === -1);
          columns.push(...filteredColumns);
        } else {
          rowToColumnMap.set(rowName, currentRangeColIds);
        }
      });
    });
    let columnsString;
    for (const val of rowToColumnMap.values()) {
      const currentValString = val.sort().join();
      if (columnsString === void 0) {
        columnsString = currentValString;
        continue;
      }
      if (columnsString !== currentValString) {
        return false;
      }
    }
    return true;
  }
  forEachRowInRange(cellRange, callback) {
    const topRow = this.getRangeStartRow(cellRange);
    const bottomRow = this.getRangeEndRow(cellRange);
    let currentRow = topRow;
    while (currentRow) {
      callback(currentRow);
      if (this.rowPositionUtils.sameRow(currentRow, bottomRow)) {
        break;
      }
      currentRow = this.cellNavigationService.getRowBelow(currentRow);
    }
  }
  removeAllCellRanges(silent) {
    if (this.isEmpty()) {
      return;
    }
    this.onDragStop();
    this.cellRanges.length = 0;
    if (!silent) {
      this.dispatchChangedEvent(false, true);
    }
  }
  // as the user is dragging outside of the panel, the div starts to scroll, which in turn
  // means we are selection more (or less) cells, but the mouse isn't moving, so we recalculate
  // the selection my mimicking a new mouse event
  onBodyScroll() {
    if (this.dragging && this.lastMouseEvent) {
      this.onDragging(this.lastMouseEvent);
    }
  }
  isCellInAnyRange(cell) {
    return this.getCellRangeCount(cell) > 0;
  }
  isCellInSpecificRange(cell, range) {
    const columnInRange = range.columns !== null && _includes2(range.columns, cell.column);
    const rowInRange = this.isRowInRange(cell.rowIndex, cell.rowPinned, range);
    return columnInRange && rowInRange;
  }
  isLastCellOfRange(cellRange, cell) {
    const { startRow, endRow } = cellRange;
    const lastRow = this.rowPositionUtils.before(startRow, endRow) ? endRow : startRow;
    const isLastRow = cell.rowIndex === lastRow.rowIndex && cell.rowPinned === lastRow.rowPinned;
    const rangeFirstIndexColumn = cellRange.columns[0];
    const rangeLastIndexColumn = _last3(cellRange.columns);
    const lastRangeColumn = cellRange.startColumn === rangeFirstIndexColumn ? rangeLastIndexColumn : rangeFirstIndexColumn;
    const isLastColumn = cell.column === lastRangeColumn;
    return isLastColumn && isLastRow;
  }
  isBottomRightCell(cellRange, cell) {
    const allColumns = this.visibleColsService.getAllCols();
    const allPositions = cellRange.columns.map((c) => allColumns.indexOf(c)).sort((a, b) => a - b);
    const { startRow, endRow } = cellRange;
    const lastRow = this.rowPositionUtils.before(startRow, endRow) ? endRow : startRow;
    const isRightColumn = allColumns.indexOf(cell.column) === _last3(allPositions);
    const isLastRow = cell.rowIndex === lastRow.rowIndex && _makeNull3(cell.rowPinned) === _makeNull3(lastRow.rowPinned);
    return isRightColumn && isLastRow;
  }
  // returns the number of ranges this cell is in
  getCellRangeCount(cell) {
    if (this.isEmpty()) {
      return 0;
    }
    return this.cellRanges.filter((cellRange) => this.isCellInSpecificRange(cell, cellRange)).length;
  }
  isRowInRange(rowIndex, rowPinned, cellRange) {
    const firstRow = this.getRangeStartRow(cellRange);
    const lastRow = this.getRangeEndRow(cellRange);
    const thisRow = { rowIndex, rowPinned: rowPinned || null };
    const equalsFirstRow = thisRow.rowIndex === firstRow.rowIndex && thisRow.rowPinned == firstRow.rowPinned;
    const equalsLastRow = thisRow.rowIndex === lastRow.rowIndex && thisRow.rowPinned == lastRow.rowPinned;
    if (equalsFirstRow || equalsLastRow) {
      return true;
    }
    const afterFirstRow = !this.rowPositionUtils.before(thisRow, firstRow);
    const beforeLastRow = this.rowPositionUtils.before(thisRow, lastRow);
    return afterFirstRow && beforeLastRow;
  }
  getDraggingRange() {
    return this.draggingRange;
  }
  onDragStart(mouseEvent) {
    if (!_isCellSelectionEnabled(this.gos)) {
      return;
    }
    const { ctrlKey, metaKey, shiftKey } = mouseEvent;
    const isMultiKey = ctrlKey || metaKey;
    const allowMulti = !_getSuppressMultiRanges(this.gos);
    const isMultiSelect = allowMulti ? isMultiKey : false;
    const extendRange = shiftKey && _existsAndNotEmpty2(this.cellRanges);
    if (!isMultiSelect && (!extendRange || _exists8(_last3(this.cellRanges).type))) {
      this.removeAllCellRanges(true);
    }
    const startTarget = this.dragService.getStartTarget();
    if (startTarget) {
      this.updateValuesOnMove(startTarget);
    }
    if (!this.lastCellHovered) {
      return;
    }
    this.dragging = true;
    this.lastMouseEvent = mouseEvent;
    this.intersectionRange = isMultiSelect && this.getCellRangeCount(this.lastCellHovered) > 1;
    if (!extendRange) {
      this.setNewestRangeStartCell(this.lastCellHovered);
    }
    if (this.cellRanges.length > 0) {
      this.draggingRange = _last3(this.cellRanges);
    } else {
      const mouseRowPosition = {
        rowIndex: this.lastCellHovered.rowIndex,
        rowPinned: this.lastCellHovered.rowPinned
      };
      this.draggingRange = {
        startRow: mouseRowPosition,
        endRow: mouseRowPosition,
        columns: [this.lastCellHovered.column],
        startColumn: this.newestRangeStartCell.column
      };
      this.cellRanges.push(this.draggingRange);
    }
    this.ctrlsService.getGridBodyCtrl().addScrollEventListener(this.bodyScrollListener);
    this.dispatchChangedEvent(true, false, this.draggingRange.id);
  }
  intersectLastRange(fromMouseClick) {
    if (fromMouseClick && this.dragging) {
      return;
    }
    if (_getSuppressMultiRanges(this.gos)) {
      return;
    }
    if (this.isEmpty()) {
      return;
    }
    const rowPosUtils = this.rowPositionUtils;
    const lastRange = _last3(this.cellRanges);
    const intersectionStartRow = this.getRangeStartRow(lastRange);
    const intersectionEndRow = this.getRangeEndRow(lastRange);
    const newRanges = [];
    this.cellRanges.slice(0, -1).forEach((range) => {
      const startRow = this.getRangeStartRow(range);
      const endRow = this.getRangeEndRow(range);
      const cols = range.columns;
      const intersectCols = cols.filter((col) => lastRange.columns.indexOf(col) === -1);
      if (intersectCols.length === cols.length) {
        newRanges.push(range);
        return;
      }
      if (rowPosUtils.before(intersectionEndRow, startRow) || rowPosUtils.before(endRow, intersectionStartRow)) {
        newRanges.push(range);
        return;
      }
      const rangeCountBefore = newRanges.length;
      if (rowPosUtils.before(startRow, intersectionStartRow)) {
        const top = {
          columns: [...cols],
          startColumn: lastRange.startColumn,
          startRow: { ...startRow },
          endRow: this.cellNavigationService.getRowAbove(intersectionStartRow)
        };
        newRanges.push(top);
      }
      if (intersectCols.length > 0) {
        const middle = {
          columns: intersectCols,
          startColumn: _includes2(intersectCols, lastRange.startColumn) ? lastRange.startColumn : intersectCols[0],
          startRow: this.rowMax([{ ...intersectionStartRow }, { ...startRow }]),
          endRow: this.rowMin([{ ...intersectionEndRow }, { ...endRow }])
        };
        newRanges.push(middle);
      }
      if (rowPosUtils.before(intersectionEndRow, endRow)) {
        newRanges.push({
          columns: [...cols],
          startColumn: lastRange.startColumn,
          startRow: this.cellNavigationService.getRowBelow(intersectionEndRow),
          endRow: { ...endRow }
        });
      }
      if (newRanges.length - rangeCountBefore === 1) {
        newRanges[newRanges.length - 1].id = range.id;
      }
    });
    this.cellRanges = newRanges;
    if (fromMouseClick) {
      this.dispatchChangedEvent(false, true);
    }
  }
  rowMax(rows) {
    let max;
    rows.forEach((row) => {
      if (max === void 0 || this.rowPositionUtils.before(max, row)) {
        max = row;
      }
    });
    return max;
  }
  rowMin(rows) {
    let min;
    rows.forEach((row) => {
      if (min === void 0 || this.rowPositionUtils.before(row, min)) {
        min = row;
      }
    });
    return min;
  }
  updateValuesOnMove(eventTarget) {
    const cellCtrl = _getCtrlForEventTarget(this.gos, eventTarget, CellCtrl.DOM_DATA_KEY_CELL_CTRL);
    const cell = cellCtrl?.getCellPosition();
    this.cellHasChanged = false;
    if (!cell || this.lastCellHovered && this.cellPositionUtils.equals(cell, this.lastCellHovered)) {
      return;
    }
    if (cellCtrl?.isEditing()) {
      this.dragService.cancelDrag(eventTarget);
      return;
    }
    if (this.lastCellHovered) {
      this.cellHasChanged = true;
    }
    this.lastCellHovered = cell;
  }
  onDragging(mouseEvent) {
    if (!this.dragging || !mouseEvent) {
      return;
    }
    this.updateValuesOnMove(mouseEvent.target);
    this.lastMouseEvent = mouseEvent;
    const cellPosition = this.lastCellHovered;
    const isMouseAndStartInPinned = (position) => cellPosition && cellPosition.rowPinned === position && this.newestRangeStartCell.rowPinned === position;
    const skipVerticalScroll = isMouseAndStartInPinned("top") || isMouseAndStartInPinned("bottom");
    this.autoScrollService.check(mouseEvent, skipVerticalScroll);
    if (!this.cellHasChanged) {
      return;
    }
    const columns = this.calculateColumnsBetween(
      this.newestRangeStartCell.column,
      cellPosition.column
    );
    if (!columns) {
      return;
    }
    this.draggingRange.endRow = {
      rowIndex: cellPosition.rowIndex,
      rowPinned: cellPosition.rowPinned
    };
    this.draggingRange.columns = columns;
    this.dispatchChangedEvent(false, false, this.draggingRange.id);
  }
  onDragStop() {
    if (!this.dragging) {
      return;
    }
    const { id } = this.draggingRange;
    this.autoScrollService.ensureCleared();
    this.ctrlsService.getGridBodyCtrl().removeScrollEventListener(this.bodyScrollListener);
    this.lastMouseEvent = null;
    this.dragging = false;
    this.draggingRange = void 0;
    this.lastCellHovered = void 0;
    if (this.intersectionRange) {
      this.intersectionRange = false;
      this.intersectLastRange();
    }
    this.dispatchChangedEvent(false, true, id);
  }
  dispatchChangedEvent(started, finished, id) {
    this.eventService.dispatchEvent({
      type: "cellSelectionChanged",
      started,
      finished,
      id
    });
    this.eventService.dispatchEvent({
      type: "rangeSelectionChanged",
      started,
      finished,
      id
    });
  }
  calculateColumnsBetween(columnFrom, columnTo) {
    const allColumns = this.visibleColsService.getAllCols();
    const isSameColumn = columnFrom === columnTo;
    const fromIndex = allColumns.indexOf(columnFrom);
    const logMissing = (column) => _warnOnce6(`column ${column.getId()} is not visible`);
    if (fromIndex < 0) {
      logMissing(columnFrom);
      return;
    }
    const toIndex = isSameColumn ? fromIndex : allColumns.indexOf(columnTo);
    if (toIndex < 0) {
      logMissing(columnTo);
      return;
    }
    if (isSameColumn) {
      return [columnFrom];
    }
    const firstIndex = Math.min(fromIndex, toIndex);
    const lastIndex = firstIndex === fromIndex ? toIndex : fromIndex;
    const columns = [];
    for (let i = firstIndex; i <= lastIndex; i++) {
      columns.push(allColumns[i]);
    }
    return columns;
  }
};
var AbstractSelectionHandle = class extends Component13 {
  constructor() {
    super(...arguments);
    this.changedCalculatedValues = false;
    this.dragging = false;
    this.shouldDestroyOnEndDragging = false;
  }
  wireBeans(beans) {
    this.dragService = beans.dragService;
    this.rangeService = beans.rangeService;
    this.mouseEventService = beans.mouseEventService;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.cellPositionUtils = beans.cellPositionUtils;
    this.ctrlsService = beans.ctrlsService;
  }
  postConstruct() {
    this.dragService.addDragSource({
      dragStartPixels: 0,
      eElement: this.getGui(),
      onDragStart: this.onDragStart.bind(this),
      onDragging: (e) => {
        this.dragging = true;
        this.rangeService.autoScrollService.check(e);
        if (this.changedCalculatedValues) {
          this.onDrag(e);
          this.changedCalculatedValues = false;
        }
      },
      onDragStop: (e) => {
        this.dragging = false;
        this.onDragEnd(e);
        this.clearDragProperties();
        if (this.shouldDestroyOnEndDragging) {
          this.destroy();
        }
      },
      onDragCancel: () => {
        this.dragging = false;
        this.onDragCancel();
        this.clearDragProperties();
      }
    });
    this.addManagedElementListeners(this.getGui(), { mousedown: this.preventRangeExtension.bind(this) });
  }
  isDragging() {
    return this.dragging;
  }
  getCellCtrl() {
    return this.cellCtrl;
  }
  setCellCtrl(cellComp) {
    this.cellCtrl = cellComp;
  }
  getCellRange() {
    return this.cellRange;
  }
  setCellRange(range) {
    this.cellRange = range;
  }
  getRangeStartRow() {
    return this.rangeStartRow;
  }
  setRangeStartRow(row) {
    this.rangeStartRow = row;
  }
  getRangeEndRow() {
    return this.rangeEndRow;
  }
  setRangeEndRow(row) {
    this.rangeEndRow = row;
  }
  getLastCellHovered() {
    return this.lastCellHovered;
  }
  preventRangeExtension(e) {
    e.stopPropagation();
  }
  onDragStart(e) {
    [this.cellHoverListener] = this.addManagedElementListeners(this.ctrlsService.get("gridCtrl").getGui(), {
      mousemove: this.updateValuesOnMove.bind(this)
    });
    document.body.classList.add(this.getDraggingCssClass());
  }
  getDraggingCssClass() {
    return `ag-dragging-${this.type === SelectionHandleType.FILL ? "fill" : "range"}-handle`;
  }
  updateValuesOnMove(e) {
    const cell = this.mouseEventService.getCellPositionForEvent(e);
    if (!cell || this.lastCellHovered && this.cellPositionUtils.equals(cell, this.lastCellHovered)) {
      return;
    }
    this.lastCellHovered = cell;
    this.changedCalculatedValues = true;
  }
  clearDragProperties() {
    this.clearValues();
    this.rangeService.autoScrollService.ensureCleared();
    document.body.classList.remove(this.getDraggingCssClass());
  }
  getType() {
    return this.type;
  }
  refresh(cellCtrl) {
    const oldCellComp = this.getCellCtrl();
    const eGui = this.getGui();
    const cellRange = _last22(this.rangeService.getCellRanges());
    const start = cellRange.startRow;
    const end = cellRange.endRow;
    if (start && end) {
      const isBefore = this.rowPositionUtils.before(end, start);
      if (isBefore) {
        this.setRangeStartRow(end);
        this.setRangeEndRow(start);
      } else {
        this.setRangeStartRow(start);
        this.setRangeEndRow(end);
      }
    }
    if (oldCellComp !== cellCtrl || !_isVisible3(eGui)) {
      this.setCellCtrl(cellCtrl);
      const eParentOfValue = cellCtrl.getComp().getParentOfValue();
      if (eParentOfValue) {
        eParentOfValue.appendChild(eGui);
      }
    }
    this.setCellRange(cellRange);
  }
  clearValues() {
    this.lastCellHovered = void 0;
    this.removeListeners();
  }
  removeListeners() {
    if (this.cellHoverListener) {
      this.cellHoverListener();
      this.cellHoverListener = void 0;
    }
  }
  destroy() {
    if (!this.shouldDestroyOnEndDragging && this.isDragging()) {
      _setDisplayed7(this.getGui(), false);
      this.shouldDestroyOnEndDragging = true;
      return;
    }
    this.shouldDestroyOnEndDragging = false;
    super.destroy();
    this.removeListeners();
    const eGui = this.getGui();
    if (eGui.parentElement) {
      eGui.parentElement.removeChild(eGui);
    }
  }
};
function findLineByLeastSquares(values) {
  const len = values.length;
  let maxDecimals = 0;
  if (len <= 1) {
    return values;
  }
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    const splitExponent = value.toString().split("e-");
    if (splitExponent.length > 1) {
      maxDecimals = Math.max(maxDecimals, parseInt(splitExponent[1], 10));
      continue;
    }
    if (Math.floor(value) === value) {
      continue;
    }
    maxDecimals = Math.max(maxDecimals, value.toString().split(".")[1].length);
  }
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  let y = 0;
  for (let x = 0; x < len; x++) {
    y = values[x];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
  }
  const m = (len * sum_xy - sum_x * sum_y) / (len * sum_xx - sum_x * sum_x);
  const b = sum_y / len - m * sum_x / len;
  const result = [];
  for (let x = 0; x <= len; x++) {
    result.push(parseFloat((x * m + b).toFixed(maxDecimals)));
  }
  return result;
}
var AgFillHandle = class extends AbstractSelectionHandle {
  constructor() {
    super(
      /* html */
      `<div class="ag-fill-handle"></div>`
    );
    this.markedCells = [];
    this.cellValues = [];
    this.isUp = false;
    this.isLeft = false;
    this.isReduce = false;
    this.type = SelectionHandleType2.FILL;
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.valueService = beans.valueService;
    this.navigationService = beans.navigationService;
    this.cellNavigationService = beans.cellNavigationService;
    this.visibleColsService = beans.visibleColsService;
  }
  updateValuesOnMove(e) {
    super.updateValuesOnMove(e);
    if (!this.initialXY) {
      this.initialXY = this.mouseEventService.getNormalisedPosition(e);
    }
    const { x, y } = this.initialXY;
    const { x: newX, y: newY } = this.mouseEventService.getNormalisedPosition(e);
    const diffX = Math.abs(x - newX);
    const diffY = Math.abs(y - newY);
    const allowedDirection = this.getFillHandleDirection();
    let direction;
    if (allowedDirection === "xy") {
      direction = diffX > diffY ? "x" : "y";
    } else {
      direction = allowedDirection;
    }
    if (direction !== this.dragAxis) {
      this.dragAxis = direction;
      this.changedCalculatedValues = true;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDrag(_e) {
    if (!this.initialPosition) {
      const cellCtrl = this.getCellCtrl();
      if (!cellCtrl) {
        return;
      }
      this.initialPosition = cellCtrl.getCellPosition();
    }
    const lastCellHovered = this.getLastCellHovered();
    if (lastCellHovered) {
      this.markPathFrom(this.initialPosition, lastCellHovered);
    }
  }
  onDragEnd(e) {
    this.initialXY = null;
    if (!this.markedCells.length) {
      return;
    }
    const isX = this.dragAxis === "x";
    const initialRange = this.getCellRange();
    const colLen = initialRange.columns.length;
    const rangeStartRow = this.getRangeStartRow();
    const rangeEndRow = this.getRangeEndRow();
    let finalRange;
    if (!this.isUp && !this.isLeft) {
      finalRange = this.rangeService.createCellRangeFromCellRangeParams({
        rowStartIndex: rangeStartRow.rowIndex,
        rowStartPinned: rangeStartRow.rowPinned,
        columnStart: initialRange.columns[0],
        rowEndIndex: isX ? rangeEndRow.rowIndex : this.lastCellMarked.rowIndex,
        rowEndPinned: isX ? rangeEndRow.rowPinned : this.lastCellMarked.rowPinned,
        columnEnd: isX ? this.lastCellMarked.column : initialRange.columns[colLen - 1]
      });
    } else {
      const startRow = isX ? rangeStartRow : this.lastCellMarked;
      finalRange = this.rangeService.createCellRangeFromCellRangeParams({
        rowStartIndex: startRow.rowIndex,
        rowStartPinned: startRow.rowPinned,
        columnStart: isX ? this.lastCellMarked.column : initialRange.columns[0],
        rowEndIndex: rangeEndRow.rowIndex,
        rowEndPinned: rangeEndRow.rowPinned,
        columnEnd: initialRange.columns[colLen - 1]
      });
    }
    if (finalRange) {
      this.eventService.dispatchEvent({
        type: "fillStart"
      });
      this.handleValueChanged(initialRange, finalRange, e);
      this.rangeService.setCellRanges([finalRange]);
      this.eventService.dispatchEvent({
        type: "fillEnd",
        initialRange,
        finalRange
      });
    }
  }
  onDragCancel() {
    this.initialXY = null;
    if (!this.markedCells.length) {
      return;
    }
    this.clearMarkedPath();
  }
  getFillHandleDirection() {
    const direction = _getFillHandle(this.gos)?.direction;
    if (!direction) {
      return "xy";
    }
    if (direction !== "x" && direction !== "y" && direction !== "xy") {
      _warnOnce22(`valid values for fillHandleDirection are 'x', 'y' and 'xy'. Default to 'xy'.`);
      return "xy";
    }
    return direction;
  }
  handleValueChanged(initialRange, finalRange, e) {
    const initialRangeEndRow = this.rangeService.getRangeEndRow(initialRange);
    const initialRangeStartRow = this.rangeService.getRangeStartRow(initialRange);
    const finalRangeEndRow = this.rangeService.getRangeEndRow(finalRange);
    const finalRangeStartRow = this.rangeService.getRangeStartRow(finalRange);
    const isVertical = this.dragAxis === "y";
    if (this.isReduce && !_getFillHandle(this.gos)?.suppressClearOnFillReduction) {
      const columns = isVertical ? initialRange.columns : initialRange.columns.filter((col) => finalRange.columns.indexOf(col) < 0);
      const startRow = isVertical ? this.cellNavigationService.getRowBelow(finalRangeEndRow) : finalRangeStartRow;
      if (startRow) {
        this.clearCellsInRange(startRow, initialRangeEndRow, columns);
      }
      return;
    }
    const values = [];
    const initialValues = [];
    const initialNonAggregatedValues = [];
    const initialFormattedValues = [];
    let withinInitialRange = true;
    let idx = 0;
    const resetValues = () => {
      values.length = 0;
      initialValues.length = 0;
      initialNonAggregatedValues.length = 0;
      initialFormattedValues.length = 0;
      idx = 0;
    };
    const iterateAcrossCells = (column, columns) => {
      let currentRow = this.isUp ? initialRangeEndRow : initialRangeStartRow;
      let finished = false;
      if (isVertical) {
        withinInitialRange = true;
        resetValues();
      }
      while (!finished && currentRow) {
        const rowNode = this.rowPositionUtils.getRowNode(currentRow);
        if (!rowNode) {
          break;
        }
        if (isVertical && column) {
          fillValues(values, column, rowNode, () => {
            return !this.rowPositionUtils.sameRow(
              currentRow,
              this.isUp ? initialRangeStartRow : initialRangeEndRow
            );
          });
        } else if (columns) {
          withinInitialRange = true;
          resetValues();
          columns.forEach(
            (col) => fillValues(
              values,
              col,
              rowNode,
              () => col !== (this.isLeft ? initialRange.columns[0] : _last32(initialRange.columns))
            )
          );
        }
        finished = this.rowPositionUtils.sameRow(currentRow, this.isUp ? finalRangeStartRow : finalRangeEndRow);
        currentRow = this.isUp ? this.cellNavigationService.getRowAbove(currentRow) : this.cellNavigationService.getRowBelow(currentRow);
      }
    };
    const fillValues = (currentValues, col, rowNode, updateInitialSet) => {
      let currentValue;
      let skipValue = false;
      if (withinInitialRange) {
        currentValue = this.valueService.getValue(col, rowNode);
        initialValues.push(currentValue);
        initialNonAggregatedValues.push(this.valueService.getValue(col, rowNode, void 0, true));
        initialFormattedValues.push(this.valueService.formatValue(col, rowNode, currentValue));
        withinInitialRange = updateInitialSet();
      } else {
        const { value, fromUserFunction, sourceCol, sourceRowNode } = this.processValues({
          event: e,
          values: currentValues,
          initialValues,
          initialNonAggregatedValues,
          initialFormattedValues,
          col,
          rowNode,
          idx: idx++
        });
        currentValue = value;
        if (col.isCellEditable(rowNode)) {
          const cellValue = this.valueService.getValue(col, rowNode);
          if (!fromUserFunction) {
            if (sourceCol && sourceCol.getColDef()?.useValueFormatterForExport !== false) {
              currentValue = this.valueService.formatValue(sourceCol, sourceRowNode, currentValue) ?? currentValue;
            }
            if (col.getColDef().useValueParserForImport !== false) {
              currentValue = this.valueService.parseValue(
                col,
                rowNode,
                // if no sourceCol, then currentValue is a number
                sourceCol ? currentValue : _toStringOrNull2(currentValue),
                cellValue
              );
            }
          }
          if (!fromUserFunction || cellValue !== currentValue) {
            rowNode.setDataValue(col, currentValue, "rangeService");
          } else {
            skipValue = true;
          }
        }
      }
      if (!skipValue) {
        currentValues.push({
          value: currentValue,
          column: col,
          rowNode
        });
      }
    };
    if (isVertical) {
      initialRange.columns.forEach((col) => {
        iterateAcrossCells(col);
      });
    } else {
      const columns = this.isLeft ? [...finalRange.columns].reverse() : finalRange.columns;
      iterateAcrossCells(void 0, columns);
    }
  }
  clearCellsInRange(startRow, endRow, columns) {
    const cellRange = {
      startRow,
      endRow,
      columns,
      startColumn: columns[0]
    };
    this.rangeService.clearCellRangeCellValues({ cellRanges: [cellRange] });
  }
  processValues(params) {
    const { event, values, initialValues, initialNonAggregatedValues, initialFormattedValues, col, rowNode, idx } = params;
    const userFillOperation = _getFillHandle(this.gos)?.setFillValue;
    const isVertical = this.dragAxis === "y";
    let direction;
    if (isVertical) {
      direction = this.isUp ? "up" : "down";
    } else {
      direction = this.isLeft ? "left" : "right";
    }
    if (userFillOperation) {
      const params2 = this.gos.addGridCommonParams({
        event,
        values: values.map(({ value }) => value),
        initialValues,
        initialNonAggregatedValues,
        initialFormattedValues,
        currentIndex: idx,
        currentCellValue: this.valueService.getValue(col, rowNode),
        direction,
        column: col,
        rowNode
      });
      const userResult = userFillOperation(params2);
      if (userResult !== false) {
        return { value: userResult, fromUserFunction: true };
      }
    }
    const allNumbers = !values.some(({ value }) => {
      const asFloat = parseFloat(value);
      return isNaN(asFloat) || asFloat.toString() !== value.toString();
    });
    if (event.altKey || !allNumbers) {
      if (allNumbers && initialValues.length === 1) {
        const multiplier = this.isUp || this.isLeft ? -1 : 1;
        return { value: parseFloat(_last32(values).value) + 1 * multiplier, fromUserFunction: false };
      }
      const { value, column: sourceCol, rowNode: sourceRowNode } = values[idx % values.length];
      return { value, fromUserFunction: false, sourceCol, sourceRowNode };
    }
    return {
      value: _last32(findLineByLeastSquares(values.map(({ value }) => Number(value)))),
      fromUserFunction: false
    };
  }
  clearValues() {
    this.clearMarkedPath();
    this.clearCellValues();
    this.lastCellMarked = void 0;
    super.clearValues();
  }
  clearMarkedPath() {
    this.markedCells.forEach((cell) => {
      if (!cell.isAlive()) {
        return;
      }
      const comp = cell.getComp();
      comp.addOrRemoveCssClass("ag-selection-fill-top", false);
      comp.addOrRemoveCssClass("ag-selection-fill-right", false);
      comp.addOrRemoveCssClass("ag-selection-fill-bottom", false);
      comp.addOrRemoveCssClass("ag-selection-fill-left", false);
    });
    this.markedCells.length = 0;
    this.isUp = false;
    this.isLeft = false;
    this.isReduce = false;
  }
  clearCellValues() {
    this.cellValues.length = 0;
  }
  markPathFrom(initialPosition, currentPosition) {
    this.clearMarkedPath();
    this.clearCellValues();
    if (this.dragAxis === "y") {
      if (this.rowPositionUtils.sameRow(currentPosition, initialPosition)) {
        return;
      }
      const isBefore = this.rowPositionUtils.before(currentPosition, initialPosition);
      const rangeStartRow = this.getRangeStartRow();
      const rangeEndRow = this.getRangeEndRow();
      if (isBefore && (currentPosition.rowPinned == rangeStartRow.rowPinned && currentPosition.rowIndex >= rangeStartRow.rowIndex || rangeStartRow.rowPinned != rangeEndRow.rowPinned && currentPosition.rowPinned == rangeEndRow.rowPinned && currentPosition.rowIndex <= rangeEndRow.rowIndex)) {
        this.reduceVertical(initialPosition, currentPosition);
        this.isReduce = true;
      } else {
        this.extendVertical(initialPosition, currentPosition, isBefore);
        this.isReduce = false;
      }
    } else {
      const initialColumn = initialPosition.column;
      const currentColumn = currentPosition.column;
      if (initialColumn === currentColumn) {
        return;
      }
      const displayedColumns = this.visibleColsService.getAllCols();
      const initialIndex = displayedColumns.indexOf(initialColumn);
      const currentIndex = displayedColumns.indexOf(currentColumn);
      if (currentIndex <= initialIndex && currentIndex >= displayedColumns.indexOf(this.getCellRange().columns[0])) {
        this.reduceHorizontal(initialPosition, currentPosition);
        this.isReduce = true;
      } else {
        this.extendHorizontal(initialPosition, currentPosition, currentIndex < initialIndex);
        this.isReduce = false;
      }
    }
    this.lastCellMarked = currentPosition;
  }
  extendVertical(initialPosition, endPosition, isMovingUp) {
    const { navigationService, rangeService } = this;
    let row = initialPosition;
    do {
      const cellRange = this.getCellRange();
      const colLen = cellRange.columns.length;
      for (let i = 0; i < colLen; i++) {
        const column = cellRange.columns[i];
        const rowPos = { rowIndex: row.rowIndex, rowPinned: row.rowPinned };
        const cellPos = { ...rowPos, column };
        const cellInRange = rangeService.isCellInSpecificRange(cellPos, cellRange);
        const isInitialRow = this.rowPositionUtils.sameRow(row, initialPosition);
        if (isMovingUp) {
          this.isUp = true;
        }
        if (!isInitialRow) {
          const cell = navigationService.getCellByPosition(cellPos);
          if (cell) {
            this.markedCells.push(cell);
            const cellCtrl = cell.getComp();
            if (!cellInRange) {
              cellCtrl.addOrRemoveCssClass("ag-selection-fill-left", i === 0);
              cellCtrl.addOrRemoveCssClass("ag-selection-fill-right", i === colLen - 1);
            }
            cellCtrl.addOrRemoveCssClass(
              isMovingUp ? "ag-selection-fill-top" : "ag-selection-fill-bottom",
              this.rowPositionUtils.sameRow(row, endPosition)
            );
          }
        }
      }
      if (this.rowPositionUtils.sameRow(row, endPosition)) {
        break;
      }
    } while (
      // tslint:disable-next-line
      row = isMovingUp ? this.cellNavigationService.getRowAbove(row) : this.cellNavigationService.getRowBelow(row)
    );
  }
  reduceVertical(initialPosition, endPosition) {
    let row = initialPosition;
    do {
      const cellRange = this.getCellRange();
      const colLen = cellRange.columns.length;
      const isLastRow = this.rowPositionUtils.sameRow(row, endPosition);
      for (let i = 0; i < colLen; i++) {
        const rowPos = { rowIndex: row.rowIndex, rowPinned: row.rowPinned };
        const celPos = { ...rowPos, column: cellRange.columns[i] };
        const cell = this.navigationService.getCellByPosition(celPos);
        if (cell) {
          this.markedCells.push(cell);
          const cellComp = cell.getComp();
          cellComp.addOrRemoveCssClass(
            "ag-selection-fill-bottom",
            this.rowPositionUtils.sameRow(row, endPosition)
          );
        }
      }
      if (isLastRow) {
        break;
      }
    } while (row = this.cellNavigationService.getRowAbove(row));
  }
  extendHorizontal(initialPosition, endPosition, isMovingLeft) {
    const allCols = this.visibleColsService.getAllCols();
    const startCol = allCols.indexOf(isMovingLeft ? endPosition.column : initialPosition.column);
    const endCol = allCols.indexOf(
      isMovingLeft ? this.getCellRange().columns[0] : endPosition.column
    );
    const offset = isMovingLeft ? 0 : 1;
    const colsToMark = allCols.slice(startCol + offset, endCol + offset);
    const rangeStartRow = this.getRangeStartRow();
    const rangeEndRow = this.getRangeEndRow();
    colsToMark.forEach((column) => {
      let row = rangeStartRow;
      let isLastRow = false;
      do {
        isLastRow = this.rowPositionUtils.sameRow(row, rangeEndRow);
        const cell = this.navigationService.getCellByPosition({
          rowIndex: row.rowIndex,
          rowPinned: row.rowPinned,
          column
        });
        if (cell) {
          this.markedCells.push(cell);
          const cellComp = cell.getComp();
          cellComp.addOrRemoveCssClass(
            "ag-selection-fill-top",
            this.rowPositionUtils.sameRow(row, rangeStartRow)
          );
          cellComp.addOrRemoveCssClass(
            "ag-selection-fill-bottom",
            this.rowPositionUtils.sameRow(row, rangeEndRow)
          );
          if (isMovingLeft) {
            this.isLeft = true;
            cellComp.addOrRemoveCssClass("ag-selection-fill-left", column === colsToMark[0]);
          } else {
            cellComp.addOrRemoveCssClass("ag-selection-fill-right", column === _last32(colsToMark));
          }
        }
        row = this.cellNavigationService.getRowBelow(row);
      } while (!isLastRow);
    });
  }
  reduceHorizontal(initialPosition, endPosition) {
    const allCols = this.visibleColsService.getAllCols();
    const startCol = allCols.indexOf(endPosition.column);
    const endCol = allCols.indexOf(initialPosition.column);
    const colsToMark = allCols.slice(startCol, endCol);
    const rangeStartRow = this.getRangeStartRow();
    const rangeEndRow = this.getRangeEndRow();
    colsToMark.forEach((column) => {
      let row = rangeStartRow;
      let isLastRow = false;
      do {
        isLastRow = this.rowPositionUtils.sameRow(row, rangeEndRow);
        const cell = this.navigationService.getCellByPosition({
          rowIndex: row.rowIndex,
          rowPinned: row.rowPinned,
          column
        });
        if (cell) {
          this.markedCells.push(cell);
          const cellComp = cell.getComp();
          cellComp.addOrRemoveCssClass("ag-selection-fill-right", column === colsToMark[0]);
        }
        row = this.cellNavigationService.getRowBelow(row);
      } while (!isLastRow);
    });
  }
  refresh(cellCtrl) {
    const cellRange = this.rangeService.getCellRanges()[0];
    const isColumnRange = !cellRange.startRow || !cellRange.endRow;
    if (isColumnRange) {
      this.destroy();
      return;
    }
    super.refresh(cellCtrl);
  }
};
var AgRangeHandle = class extends AbstractSelectionHandle {
  constructor() {
    super(
      /* html */
      `<div class="ag-range-handle"></div>`
    );
    this.type = SelectionHandleType3.RANGE;
    this.rangeFixed = false;
  }
  onDrag(e) {
    const lastCellHovered = this.getLastCellHovered();
    if (!lastCellHovered) {
      return;
    }
    const cellRanges = this.rangeService.getCellRanges();
    const lastRange = _last4(cellRanges);
    if (!this.rangeFixed) {
      this.fixRangeStartEnd(lastRange);
      this.rangeFixed = true;
    }
    this.endPosition = {
      rowIndex: lastCellHovered.rowIndex,
      rowPinned: lastCellHovered.rowPinned,
      column: lastCellHovered.column
    };
    if (cellRanges.length === 2 && cellRanges[0].type === CellRangeType.DIMENSION && lastRange.type === CellRangeType.VALUE) {
      const rowChanged = !this.rowPositionUtils.sameRow(
        this.endPosition,
        this.rangeService.getRangeEndRow(lastRange)
      );
      if (rowChanged) {
        this.rangeService.updateRangeEnd(
          cellRanges[0],
          {
            ...this.endPosition,
            column: cellRanges[0].columns[0]
          },
          true
        );
      }
    }
    this.rangeService.extendLatestRangeToCell(this.endPosition);
  }
  onDragEnd(e) {
    const cellRange = _last4(this.rangeService.getCellRanges());
    this.fixRangeStartEnd(cellRange);
    this.rangeFixed = false;
  }
  onDragCancel() {
    this.rangeFixed = false;
  }
  fixRangeStartEnd(cellRange) {
    const startRow = this.rangeService.getRangeStartRow(cellRange);
    const endRow = this.rangeService.getRangeEndRow(cellRange);
    const column = cellRange.columns[0];
    cellRange.startRow = startRow;
    cellRange.endRow = endRow;
    cellRange.startColumn = column;
  }
};
var SelectionHandleFactory = class extends BeanStub23 {
  constructor() {
    super(...arguments);
    this.beanName = "selectionHandleFactory";
  }
  createSelectionHandle(type) {
    return this.createBean(type === SelectionHandleType4.RANGE ? new AgRangeHandle() : new AgFillHandle());
  }
};
var VERSION3 = "32.3.0";
var RangeSelectionCoreModule = _defineModule3({
  version: VERSION3,
  moduleName: `${ModuleNames3.RangeSelectionModule}-core`,
  beans: [RangeService, SelectionHandleFactory],
  dependantModules: [EnterpriseCoreModule]
});
var RangeSelectionApiModule = _defineModule3({
  version: VERSION3,
  moduleName: `${ModuleNames3.RangeSelectionModule}-api`,
  apiFunctions: {
    getCellRanges,
    addCellRange,
    clearRangeSelection,
    clearCellSelection: clearRangeSelection
  },
  dependantModules: [RangeSelectionCoreModule]
});
var RangeSelectionModule = _defineModule3({
  version: VERSION3,
  moduleName: ModuleNames3.RangeSelectionModule,
  dependantModules: [RangeSelectionCoreModule, RangeSelectionApiModule]
});

// enterprise-modules/charts/dist/package/main.esm.mjs
import { BeanStub as BeanStub24, TabGuardComp as TabGuardComp6 } from "ag-grid-community";
import { Component as Component63 } from "ag-grid-community";
import { ChartMappings } from "ag-grid-community";
import { BeanStub as BeanStub7, _removeFromParent as _removeFromParent3 } from "ag-grid-community";
import { AgInputNumberFieldSelector, Component as Component14 } from "ag-grid-community";
import { AgCheckboxSelector as AgCheckboxSelector2, Component as Component33 } from "ag-grid-community";
import { AgPickerField as AgPickerField2, _getDocument as _getDocument7 } from "ag-grid-community";
import { _Util as _Util3 } from "ag-charts-community";
import { Component as Component23, KeyCode as KeyCode14, RefPlaceholder as RefPlaceholder23, _exists as _exists9, _setDisplayed as _setDisplayed8 } from "ag-grid-community";
import { _Util as _Util2 } from "ag-charts-community";
import { AgInputTextField as AgInputTextField2, RefPlaceholder as RefPlaceholder11 } from "ag-grid-community";
import { _Util } from "ag-charts-community";
import { AgCheckboxSelector as AgCheckboxSelector22, Component as Component43 } from "ag-grid-community";
import { AgAbstractLabel, AgInputNumberFieldSelector as AgInputNumberFieldSelector2, RefPlaceholder as RefPlaceholder33 } from "ag-grid-community";
import { AgAbstractInputField } from "ag-grid-community";
import { AgCheckboxSelector as AgCheckboxSelector3, Component as Component53, RefPlaceholder as RefPlaceholder43 } from "ag-grid-community";
import { BeanStub as BeanStub33, Component as Component73, RefPlaceholder as RefPlaceholder53, _createIconNoSpan as _createIconNoSpan10, _isNothingFocused as _isNothingFocused3 } from "ag-grid-community";
import { BeanStub as BeanStub43, _includes as _includes3, _isClientSideRowModel as _isClientSideRowModel2, _warnOnce as _warnOnce7 } from "ag-grid-community";
import { BeanStub as BeanStub53, _warnOnce as _warnOnce23 } from "ag-grid-community";
import { BeanStub as BeanStub62 } from "ag-grid-community";
import { BeanStub as BeanStub15, _warnOnce as _warnOnce14 } from "ag-grid-community";
import { VERSION as CHARTS_VERSION, _ModuleSupport as _ModuleSupport4 } from "ag-charts-community";
import {
  Component as Component40,
  RefPlaceholder as RefPlaceholder19,
  _clearElement as _clearElement42,
  _getAbsoluteHeight as _getAbsoluteHeight2,
  _getAbsoluteWidth as _getAbsoluteWidth2,
  _includes as _includes7,
  _mergeDeep,
  _removeFromParent as _removeFromParent7,
  _setDisplayed as _setDisplayed52,
  _warnOnce as _warnOnce13
} from "ag-grid-community";
import { BeanStub as BeanStub11, _warnOnce as _warnOnce8 } from "ag-grid-community";
import { _ModuleSupport as _ModuleSupport2, _Theme as _Theme2 } from "ag-charts-community";
import { _includes as _includes22, _warnOnce as _warnOnce32 } from "ag-grid-community";
import { _Theme } from "ag-charts-community";
import { BeanStub as BeanStub10, CellRangeType as CellRangeType2, _includes as _includes4 } from "ag-grid-community";
import {
  BeanStub as BeanStub72,
  ModuleNames as ModuleNames4,
  _includes as _includes32,
  _isClientSideRowModel as _isClientSideRowModel22,
  _isServerSideRowModel as _isServerSideRowModel2,
  _last as _last5,
  _values,
  _warnOnce as _warnOnce42
} from "ag-grid-community";
import { BeanStub as BeanStub8, _missingOrEmpty as _missingOrEmpty2, _warnOnce as _warnOnce52 } from "ag-grid-community";
import { BeanStub as BeanStub9, _warnOnce as _warnOnce62 } from "ag-grid-community";
import { _warnOnce as _warnOnce72 } from "ag-grid-community";
import { _ModuleSupport } from "ag-charts-community";
import { AgCharts, _ModuleSupport as _ModuleSupport3, _Theme as _Theme3 } from "ag-charts-community";
import { _includes as _includes5 } from "ag-grid-community";
import { _Util as _Util4 } from "ag-charts-community";
import { GROUP_AUTO_COLUMN_ID } from "ag-grid-community";
import { AgPromise as AgPromise2, Component as Component39, _warnOnce as _warnOnce12 } from "ag-grid-community";
import { Component as Component83, RefPlaceholder as RefPlaceholder63, _clearElement as _clearElement6, _createIconNoSpan as _createIconNoSpan23, _getDocument as _getDocument22 } from "ag-grid-community";
import { AgPromise as AgPromise3, Component as Component38 } from "ag-grid-community";
import { AgToggleButton as AgToggleButton3, Component as Component132, _getDocument as _getDocument32, _setDisplayed as _setDisplayed23, _warnOnce as _warnOnce9 } from "ag-grid-community";
import { AgSelect as AgSelect3, AgToggleButton as AgToggleButton2 } from "ag-grid-community";
import { AgSelect as AgSelect2, Component as Component102 } from "ag-grid-community";
import {
  AgSelect,
  Component as Component93,
  DragSourceType as DragSourceType3,
  _escapeString as _escapeString5,
  _getActiveDomElement as _getActiveDomElement7,
  _removeFromParent as _removeFromParent22
} from "ag-grid-community";
import { AgSelect as AgSelect4, ChartMappings as ChartMappings2, Component as Component112, RefPlaceholder as RefPlaceholder73 } from "ag-grid-community";
import { BeanStub as BeanStub12 } from "ag-grid-community";
import { AgCheckbox, AgSelect as AgSelect5, Component as Component122, _areEqual as _areEqual3, _clearElement as _clearElement22 } from "ag-grid-community";
import { AgToggleButton as AgToggleButton22 } from "ag-grid-community";
import { Component as Component34, _warnOnce as _warnOnce10 } from "ag-grid-community";
import {
  AgCheckbox as AgCheckbox2,
  AgSelectSelector as AgSelectSelector2,
  Component as Component17,
  RefPlaceholder as RefPlaceholder102,
  _removeFromParent as _removeFromParent4,
  _setDisplayed as _setDisplayed33
} from "ag-grid-community";
import {
  AgAbstractLabel as AgAbstractLabel2,
  AgInputNumberFieldSelector as AgInputNumberFieldSelector3,
  RefPlaceholder as RefPlaceholder82,
  _exists as _exists23,
  _getActiveDomElement as _getActiveDomElement23,
  _setFixedWidth
} from "ag-grid-community";
import {
  AgSelectSelector,
  Component as Component142,
  RefPlaceholder as RefPlaceholder92,
  _capitalise,
  _includes as _includes6,
  _removeFromParent as _removeFromParent32
} from "ag-grid-community";
import { Component as Component15 } from "ag-grid-community";
import { Component as Component16 } from "ag-grid-community";
import { AgSelect as AgSelect6, Component as Component18, RefPlaceholder as RefPlaceholder112 } from "ag-grid-community";
import { Component as Component21, RefPlaceholder as RefPlaceholder13 } from "ag-grid-community";
import { Component as Component19 } from "ag-grid-community";
import { Component as Component20, RefPlaceholder as RefPlaceholder12 } from "ag-grid-community";
import { BeanStub as BeanStub13 } from "ag-grid-community";
import { AgCheckbox as AgCheckbox3, AgSelect as AgSelect7, Component as Component222, RefPlaceholder as RefPlaceholder14 } from "ag-grid-community";
import {
  AgSelect as AgSelect8,
  AgToggleButton as AgToggleButton4,
  Component as Component31,
  RefPlaceholder as RefPlaceholder16,
  _errorOnce,
  _removeFromParent as _removeFromParent6
} from "ag-grid-community";
import { Component as Component232 } from "ag-grid-community";
import { Component as Component24 } from "ag-grid-community";
import { Component as Component25 } from "ag-grid-community";
import { AgSelectSelector as AgSelectSelector3, Component as Component26 } from "ag-grid-community";
import { AgSelectSelector as AgSelectSelector4, Component as Component27, RefPlaceholder as RefPlaceholder15, _removeFromParent as _removeFromParent5 } from "ag-grid-community";
import { Component as Component28 } from "ag-grid-community";
import { Component as Component29 } from "ag-grid-community";
import { Component as Component30 } from "ag-grid-community";
import { Component as Component332, RefPlaceholder as RefPlaceholder17 } from "ag-grid-community";
import { AgInputTextField as AgInputTextField22, Component as Component322 } from "ag-grid-community";
import {
  Component as Component37,
  RefPlaceholder as RefPlaceholder18,
  _clearElement as _clearElement32,
  _createIconNoSpan as _createIconNoSpan32,
  _getAbsoluteWidth as _getAbsoluteWidth3,
  _radioCssClass as _radioCssClass2,
  _setDisplayed as _setDisplayed43,
  _shallowCompare as _shallowCompare2
} from "ag-grid-community";
import { Component as Component36, KeyCode as KeyCode23, _setAriaLabel as _setAriaLabel7, _warnOnce as _warnOnce11 } from "ag-grid-community";
__reExport(main_esm_exports, ag_charts_community_star);
import { _Scene } from "ag-charts-community";
import { _Scene as _Scene3 } from "ag-charts-community";
import { Component as Component35, _errorOnce as _errorOnce2 } from "ag-grid-community";
import { _Scene as _Scene2 } from "ag-charts-community";
import { _Scene as _Scene4 } from "ag-charts-community";
import { _Scene as _Scene5 } from "ag-charts-community";
import { _Scene as _Scene6 } from "ag-charts-community";
import { _Scene as _Scene7 } from "ag-charts-community";
import { _Scene as _Scene8 } from "ag-charts-community";
import { _Scene as _Scene9 } from "ag-charts-community";
import { _Scene as _Scene10 } from "ag-charts-community";
import { _Scene as _Scene11 } from "ag-charts-community";
import { _Scene as _Scene13 } from "ag-charts-community";
import { _Scene as _Scene12 } from "ag-charts-community";
import { _Scene as _Scene14 } from "ag-charts-community";
import { _Scene as _Scene15 } from "ag-charts-community";
import { _Scene as _Scene16 } from "ag-charts-community";
import { _Scene as _Scene17 } from "ag-charts-community";
import { _Scene as _Scene18, _Theme as _Theme4, _Util as _Util5 } from "ag-charts-community";
import { _Scene as _Scene19, _Theme as _Theme5 } from "ag-charts-community";
import { _Scene as _Scene20 } from "ag-charts-community";
import { _Scene as _Scene21, _Theme as _Theme6, _Util as _Util6 } from "ag-charts-community";
import { _Scene as _Scene22, _Theme as _Theme7 } from "ag-charts-community";
import { _Scene as _Scene23 } from "ag-charts-community";
import { _Scene as _Scene24 } from "ag-charts-community";
import { BeanStub as BeanStub14, _errorOnce as _errorOnce3 } from "ag-grid-community";
import * as ag_charts_community_star from "ag-charts-community";
var SERIES_TYPES = {
  area: {
    isCartesian: true,
    canInvert: true
  },
  bar: {
    isCartesian: true,
    canInvert: true
  },
  histogram: {
    isCartesian: true
  },
  line: {
    isCartesian: true,
    canInvert: true
  },
  pie: {
    isPie: true
  },
  donut: {
    isPie: true,
    canInvert: true
  },
  scatter: {
    isCartesian: true
  },
  bubble: {
    isCartesian: true
  },
  "radial-column": {
    isPolar: true,
    isEnterprise: true,
    isRadial: true,
    canInvert: true
  },
  "radial-bar": {
    isPolar: true,
    isEnterprise: true,
    isRadial: true,
    canInvert: true
  },
  "radar-line": {
    isPolar: true,
    isEnterprise: true,
    canInvert: true
  },
  "radar-area": {
    isPolar: true,
    isEnterprise: true,
    canInvert: true
  },
  nightingale: {
    isPolar: true,
    isEnterprise: true,
    canInvert: true
  },
  "range-bar": {
    isCartesian: true,
    isEnterprise: true,
    canSwitchDirection: true
  },
  "range-area": {
    isCartesian: true,
    isEnterprise: true
  },
  "box-plot": {
    isCartesian: true,
    isEnterprise: true,
    canSwitchDirection: true
  },
  treemap: {
    isEnterprise: true,
    isHierarchical: true
  },
  sunburst: {
    isEnterprise: true,
    isHierarchical: true
  },
  heatmap: {
    isCartesian: true,
    isEnterprise: true
  },
  waterfall: {
    isCartesian: true,
    isEnterprise: true,
    canSwitchDirection: true
  }
};
function isSeriesType(seriesType) {
  return !!SERIES_TYPES[seriesType];
}
function isComboChart(chartType) {
  return ChartMappings.COMBO_CHART_TYPES.includes(chartType);
}
function doesSeriesHaveProperty(seriesType, prop) {
  return !!SERIES_TYPES[seriesType]?.[prop];
}
function isEnterpriseChartType(chartType) {
  return doesSeriesHaveProperty(getSeriesType(chartType), "isEnterprise");
}
var stackedChartTypes = /* @__PURE__ */ new Set(["stackedColumn", "normalizedColumn", "stackedBar", "normalizedBar"]);
function isStacked(chartType) {
  return stackedChartTypes.has(chartType);
}
function isCartesian(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isCartesian");
}
function isPolar(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isPolar");
}
function isRadial(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isRadial");
}
function isHierarchical(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isHierarchical");
}
function getCanonicalChartType(chartType) {
  return chartType === "doughnut" ? "donut" : chartType;
}
function getSeriesTypeIfExists(chartType) {
  return ChartMappings.CHART_TYPE_TO_SERIES_TYPE[chartType];
}
function getSeriesType(chartType) {
  return getSeriesTypeIfExists(chartType) ?? "line";
}
function isPieChartSeries(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isPie");
}
function canOnlyHaveSingleSeries(chartType) {
  return chartType === "pie" || chartType === "waterfall" || chartType === "histogram";
}
function getMaxNumCategories(chartType) {
  return isHierarchical(getSeriesType(chartType)) ? void 0 : 1;
}
function getMaxNumSeries(chartType) {
  if (isHierarchical(getSeriesType(chartType))) {
    return 2;
  } else if (canOnlyHaveSingleSeries(chartType)) {
    return 1;
  } else {
    return void 0;
  }
}
function supportsInvertedCategorySeries(chartType) {
  return !isComboChart(chartType) && doesSeriesHaveProperty(getSeriesType(chartType), "canInvert");
}
function canSwitchDirection(chartType) {
  return doesSeriesHaveProperty(getSeriesType(chartType), "canSwitchDirection");
}
function getFullChartNameTranslationKey(chartType) {
  switch (chartType) {
    case "groupedColumn":
    case "stackedColumn":
    case "normalizedColumn":
    case "groupedBar":
    case "stackedBar":
    case "normalizedBar":
    case "stackedArea":
    case "normalizedArea":
      return `${chartType}Full`;
    case "doughnut":
      return "donut";
    case "areaColumnCombo":
      return "AreaColumnCombo";
    default:
      return chartType;
  }
}
var ChartPanelFeature = class extends BeanStub7 {
  constructor(chartController, eGui, cssClass, createPanels) {
    super();
    this.chartController = chartController;
    this.eGui = eGui;
    this.cssClass = cssClass;
    this.createPanels = createPanels;
    this.panels = [];
  }
  postConstruct() {
    this.addManagedListeners(this.chartController, {
      chartUpdated: () => this.refreshPanels(true),
      chartApiUpdate: () => this.refreshPanels(false)
    });
  }
  addComponent(component) {
    this.createBean(component);
    this.panels.push(component);
    component.addCssClass(this.cssClass);
    this.eGui.appendChild(component.getGui());
  }
  refreshPanels(reuse) {
    const chartType = this.chartController.getChartType();
    const isGrouping = this.chartController.isGrouping();
    const seriesType = getSeriesType(chartType);
    if (reuse && chartType === this.chartType && isGrouping === this.isGrouping) {
      return;
    }
    this.destroyPanels();
    this.createPanels(chartType, seriesType);
    this.chartType = chartType;
    this.isGrouping = isGrouping;
  }
  destroyPanels() {
    this.panels.forEach((panel) => {
      _removeFromParent3(panel.getGui());
      this.destroyBean(panel);
    });
    this.panels = [];
  }
  destroy() {
    this.destroyPanels();
    super.destroy();
  }
};
var AnimationPanel = class extends Component14 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const animationGroupParams = this.chartMenuParamsFactory.addEnableParams(
      "animation.enabled",
      {
        cssIdentifier: "charts-advanced-settings-top-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("animation"),
        suppressEnabledCheckbox: true,
        useToggle: true
      }
    );
    const animationHeightInputParams = this.chartMenuParamsFactory.getDefaultNumberInputParams(
      "animation.duration",
      "durationMillis",
      {
        min: 0
      }
    );
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="animationGroup">
                <ag-input-number-field data-ref="animationHeightInput"></ag-input>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgInputNumberFieldSelector],
      {
        animationGroup: animationGroupParams,
        animationHeightInput: animationHeightInputParams
      }
    );
  }
};
var AgColorInput = class extends AgInputTextField2 {
  constructor() {
    super({
      template: (
        /* html */
        `
            <div role="presentation" class="ag-color-input">
                <div data-ref="eLabel" class="ag-input-field-label"></div>
                <div data-ref="eWrapper" class="ag-wrapper ag-input-wrapper" role="presentation">
                    <input data-ref="eInput" class="ag-input-field-input">
                    <div data-ref="eColor" class="ag-color-input-color"></div>
                </div>
            </div>`
      )
    });
    this.eColor = RefPlaceholder11;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  setColor(color) {
    const rgbaColor = color.toRgbaString();
    this.setValue(_Util.Color.fromString(rgbaColor).toHexString().toUpperCase(), true);
    this.eColor.style.backgroundColor = rgbaColor;
  }
  setValue(value, silent) {
    const isValid = _Util.Color.validColorString(value ?? "");
    this.eInput.setCustomValidity(isValid ? "" : this.chartTranslationService.translate("invalidColor"));
    super.setValue(value, silent);
    if (isValid && !silent) {
      this.dispatchLocalEvent({ type: "colorChanged" });
    }
    return this;
  }
  onColorChanged(callback) {
    this.addManagedListeners(this, { colorChanged: () => callback(_Util.Color.fromString(this.value)) });
  }
};
var AgColorInputSelector = {
  selector: "AG-COLOR-INPUT",
  component: AgColorInput
};
var _AgColorPanel = class _AgColorPanel2 extends Component23 {
  constructor(config) {
    super(
      /* html */
      `<div class="ag-color-panel" tabindex="-1">
            <div data-ref="spectrumColor" class="ag-spectrum-color">
                <div class="ag-spectrum-sat ag-spectrum-fill">
                    <div data-ref="spectrumVal" class="ag-spectrum-val ag-spectrum-fill">
                        <div data-ref="spectrumDragger" class="ag-spectrum-dragger"></div>
                    </div>
                </div>
            </div>
            <div class="ag-spectrum-tools">
                <div data-ref="spectrumHue" class="ag-spectrum-hue ag-spectrum-tool">
                    <div class="ag-spectrum-hue-background"></div>
                    <div data-ref="spectrumHueSlider" class="ag-spectrum-slider"></div>
                </div>
                <div data-ref="spectrumAlpha" class="ag-spectrum-alpha ag-spectrum-tool">
                    <div class="ag-spectrum-alpha-background"></div>
                    <div data-ref="spectrumAlphaSlider" class="ag-spectrum-slider"></div>
                </div>
                <ag-color-input data-ref="colorInput"></ag-color-input>
                <div data-ref="recentColors" class="ag-recent-colors"></div>
            </div>
        </div>`,
      [AgColorInputSelector]
    );
    this.H = 1;
    this.S = 1;
    this.B = 1;
    this.A = 1;
    this.isSpectrumDragging = false;
    this.isSpectrumHueDragging = false;
    this.isSpectrumAlphaDragging = false;
    this.colorChanged = false;
    this.spectrumColor = RefPlaceholder23;
    this.spectrumVal = RefPlaceholder23;
    this.spectrumDragger = RefPlaceholder23;
    this.spectrumHue = RefPlaceholder23;
    this.spectrumHueSlider = RefPlaceholder23;
    this.spectrumAlpha = RefPlaceholder23;
    this.spectrumAlphaSlider = RefPlaceholder23;
    this.colorInput = RefPlaceholder23;
    this.recentColors = RefPlaceholder23;
    this.picker = config.picker;
  }
  postConstruct() {
    this.initTabIndex();
    this.initRecentColors();
    this.addGuiEventListener("focus", () => this.spectrumColor.focus());
    this.addGuiEventListener("keydown", (e) => {
      if (e.key === KeyCode14.ENTER && !e.defaultPrevented) {
        this.destroy();
      }
    });
    this.addManagedListeners(this.spectrumColor, { keydown: (e) => this.moveDragger(e) });
    this.addManagedListeners(this.spectrumAlphaSlider, { keydown: (e) => this.moveAlphaSlider(e) });
    this.addManagedListeners(this.spectrumHueSlider, { keydown: (e) => this.moveHueSlider(e) });
    this.addManagedListeners(this.spectrumVal, { mousedown: this.onSpectrumDraggerDown.bind(this) });
    this.addManagedListeners(this.spectrumHue, { mousedown: this.onSpectrumHueDown.bind(this) });
    this.addManagedListeners(this.spectrumAlpha, { mousedown: this.onSpectrumAlphaDown.bind(this) });
    this.addGuiEventListener("mousemove", (e) => {
      this.onSpectrumDraggerMove(e);
      this.onSpectrumHueMove(e);
      this.onSpectrumAlphaMove(e);
    });
    this.addManagedListeners(document, { mouseup: this.onMouseUp.bind(this) });
    this.colorInput.onColorChanged(this.setColor.bind(this));
    this.addManagedListeners(this.recentColors, {
      click: this.onRecentColorClick.bind(this),
      keydown: (e) => {
        if (e.key === KeyCode14.ENTER || e.key === KeyCode14.SPACE) {
          e.preventDefault();
          this.onRecentColorClick(e);
        }
      }
    });
  }
  initTabIndex() {
    const tabIndex = this.tabIndex = this.gos.get("tabIndex").toString();
    this.spectrumColor.setAttribute("tabindex", tabIndex);
    this.spectrumHueSlider.setAttribute("tabindex", tabIndex);
    this.spectrumAlphaSlider.setAttribute("tabindex", tabIndex);
  }
  refreshSpectrumRect() {
    return this.spectrumValRect = this.spectrumVal.getBoundingClientRect();
  }
  refreshHueRect() {
    return this.spectrumHueRect = this.spectrumHue.getBoundingClientRect();
  }
  refreshAlphaRect() {
    return this.spectrumAlphaRect = this.spectrumAlpha.getBoundingClientRect();
  }
  onSpectrumDraggerDown(e) {
    e.preventDefault();
    this.refreshSpectrumRect();
    this.isSpectrumDragging = true;
    this.moveDragger(e);
  }
  onSpectrumDraggerMove(e) {
    if (this.isSpectrumDragging) {
      this.moveDragger(e);
    }
  }
  onSpectrumHueDown(e) {
    this.refreshHueRect();
    this.isSpectrumHueDragging = true;
    this.moveHueSlider(e);
  }
  onSpectrumHueMove(e) {
    if (this.isSpectrumHueDragging) {
      this.moveHueSlider(e);
    }
  }
  onSpectrumAlphaDown(e) {
    this.refreshAlphaRect();
    this.isSpectrumAlphaDragging = true;
    this.moveAlphaSlider(e);
  }
  onSpectrumAlphaMove(e) {
    if (this.isSpectrumAlphaDragging) {
      this.moveAlphaSlider(e);
    }
  }
  onMouseUp() {
    this.isSpectrumDragging = false;
    this.isSpectrumHueDragging = false;
    this.isSpectrumAlphaDragging = false;
  }
  moveDragger(e) {
    const valRect = this.spectrumValRect;
    if (!valRect) {
      return;
    }
    let x;
    let y;
    if (e instanceof MouseEvent) {
      x = e.clientX - valRect.left;
      y = e.clientY - valRect.top;
    } else {
      const isLeft = e.key === KeyCode14.LEFT;
      const isRight = e.key === KeyCode14.RIGHT;
      const isUp = e.key === KeyCode14.UP;
      const isDown = e.key === KeyCode14.DOWN;
      const isVertical = isUp || isDown;
      const isHorizontal = isLeft || isRight;
      if (!isVertical && !isHorizontal) {
        return;
      }
      e.preventDefault();
      const { x: currentX, y: currentY } = this.getSpectrumValue();
      x = currentX + (isHorizontal ? isLeft ? -5 : 5 : 0);
      y = currentY + (isVertical ? isUp ? -5 : 5 : 0);
    }
    x = Math.max(x, 0);
    x = Math.min(x, valRect.width);
    y = Math.max(y, 0);
    y = Math.min(y, valRect.height);
    this.setSpectrumValue(x / valRect.width, 1 - y / valRect.height);
  }
  moveHueSlider(e) {
    const rect = this.spectrumHueRect;
    if (!rect) {
      return;
    }
    const x = this.moveSlider(this.spectrumHueSlider, e);
    if (x == null) {
      return;
    }
    this.H = 1 - x / rect.width;
    this.update();
  }
  moveAlphaSlider(e) {
    const rect = this.spectrumAlphaRect;
    if (!rect) {
      return;
    }
    const x = this.moveSlider(this.spectrumAlphaSlider, e);
    if (x == null) {
      return;
    }
    this.A = x / rect.width;
    this.update();
  }
  moveSlider(slider, e) {
    const sliderRect = slider.getBoundingClientRect();
    const parentRect = slider.parentElement?.getBoundingClientRect();
    if (!slider || !parentRect) {
      return null;
    }
    const offset = sliderRect.width / 2;
    let x;
    if (e instanceof MouseEvent) {
      x = Math.floor(e.clientX - parentRect.left);
    } else {
      const isLeft = e.key === KeyCode14.LEFT;
      const isRight = e.key === KeyCode14.RIGHT;
      if (!isLeft && !isRight) {
        return null;
      }
      e.preventDefault();
      const diff = isLeft ? -5 : 5;
      x = parseFloat(slider.style.left) + offset + diff;
    }
    x = Math.max(x, 0);
    x = Math.min(x, parentRect.width);
    slider.style.left = x - offset + "px";
    return x;
  }
  update(suppressColorInputUpdate) {
    const hue = this.H * 360;
    const color = _Util2.Color.fromHSB(hue, this.S, this.B, this.A);
    const rgbaColor = color.toRgbaString();
    const colorWithoutAlpha = _Util2.Color.fromHSB(hue, this.S, this.B);
    const rgbaColorWithoutAlpha = colorWithoutAlpha.toRgbaString();
    const spectrumColor = _Util2.Color.fromHSB(hue, 1, 1);
    const spectrumRgbaColor = spectrumColor.toRgbaString();
    const colorPicker = this.picker;
    const existingColor = _Util2.Color.fromString(colorPicker.getValue());
    if (existingColor.toRgbaString() !== rgbaColor) {
      this.colorChanged = true;
    }
    colorPicker.setValue(rgbaColor);
    this.spectrumColor.style.backgroundColor = spectrumRgbaColor;
    this.spectrumDragger.style.backgroundColor = rgbaColorWithoutAlpha;
    this.spectrumHueSlider.style.backgroundColor = spectrumRgbaColor;
    this.spectrumAlpha.style.setProperty(
      "--ag-internal-spectrum-alpha-color-from",
      _Util2.Color.fromHSB(hue, this.S, this.B, 0).toRgbaString()
    );
    this.spectrumAlpha.style.setProperty("--ag-internal-spectrum-alpha-color-to", rgbaColorWithoutAlpha);
    this.spectrumAlpha.style.setProperty("--ag-internal-spectrum-alpha-color", rgbaColor);
    if (!suppressColorInputUpdate) {
      this.colorInput.setColor(color);
    }
  }
  /**
   * @param saturation In the [0, 1] interval.
   * @param brightness In the [0, 1] interval.
   */
  setSpectrumValue(saturation, brightness, suppressColorInputUpdate) {
    const valRect = this.spectrumValRect || this.refreshSpectrumRect();
    if (valRect == null) {
      return;
    }
    const dragger = this.spectrumDragger;
    const draggerRect = dragger.getBoundingClientRect();
    saturation = Math.max(0, saturation);
    saturation = Math.min(1, saturation);
    brightness = Math.max(0, brightness);
    brightness = Math.min(1, brightness);
    this.S = saturation;
    this.B = brightness;
    dragger.style.left = saturation * valRect.width - draggerRect.width / 2 + "px";
    dragger.style.top = (1 - brightness) * valRect.height - draggerRect.height / 2 + "px";
    this.update(suppressColorInputUpdate);
  }
  getSpectrumValue() {
    const dragger = this.spectrumDragger;
    const draggerRect = dragger.getBoundingClientRect();
    const x = parseFloat(dragger.style.left) + draggerRect.width / 2;
    const y = parseFloat(dragger.style.top) + draggerRect.height / 2;
    return { x, y };
  }
  initRecentColors() {
    const recentColors = _AgColorPanel2.recentColors;
    const innerHtml = recentColors.map((color, index) => {
      return (
        /* html */
        `<div class="ag-recent-color" id=${index} style="background-color: ${color}; width: 15px; height: 15px;" recent-color="${color}" tabIndex="${this.tabIndex}"></div>`
      );
    });
    this.recentColors.innerHTML = innerHtml.join("");
    _setDisplayed8(this.recentColors, !!recentColors.length);
  }
  setValue(val) {
    const color = _Util2.Color.fromString(val);
    this.setColor(color, true);
  }
  setColor(color, updateColorInput) {
    const [h, s, b] = color.toHSB();
    this.H = (isNaN(h) ? 0 : h) / 360;
    this.A = color.a;
    const spectrumHueRect = this.spectrumHueRect || this.refreshHueRect();
    const spectrumAlphaRect = this.spectrumAlphaRect || this.refreshAlphaRect();
    this.spectrumHueSlider.style.left = `${(this.H - 1) * -spectrumHueRect.width - this.spectrumHueSlider.getBoundingClientRect().width / 2}px`;
    this.spectrumAlphaSlider.style.left = `${this.A * spectrumAlphaRect.width - this.spectrumAlphaSlider.getBoundingClientRect().width / 2}px`;
    this.setSpectrumValue(s, b, !updateColorInput);
  }
  onRecentColorClick(e) {
    const target = e.target;
    if (!_exists9(target.id)) {
      return;
    }
    const id = parseInt(target.id, 10);
    this.setValue(_AgColorPanel2.recentColors[id]);
    this.destroy();
  }
  addRecentColor() {
    const color = _Util2.Color.fromHSB(this.H * 360, this.S, this.B, this.A);
    const rgbaColor = color.toRgbaString();
    let recentColors = _AgColorPanel2.recentColors;
    if (!this.colorChanged || recentColors[0] === rgbaColor) {
      return;
    }
    recentColors = recentColors.filter((currentColor) => currentColor != rgbaColor);
    recentColors = [rgbaColor].concat(recentColors);
    if (recentColors.length > _AgColorPanel2.maxRecentColors) {
      recentColors = recentColors.slice(0, _AgColorPanel2.maxRecentColors);
    }
    _AgColorPanel2.recentColors = recentColors;
  }
  destroy() {
    this.addRecentColor();
    super.destroy();
  }
};
_AgColorPanel.maxRecentColors = 8;
_AgColorPanel.recentColors = [];
var AgColorPanel = _AgColorPanel;
var AgColorPicker = class extends AgPickerField2 {
  constructor(config) {
    super({
      pickerAriaLabelKey: "ariaLabelColorPicker",
      pickerAriaLabelValue: "Color Picker",
      pickerType: "ag-list",
      className: "ag-color-picker",
      pickerIcon: "smallDown",
      ...config
    });
  }
  postConstruct() {
    const eDocument = _getDocument7(this.gos);
    this.eDisplayFieldColor = eDocument.createElement("span");
    this.eDisplayFieldColor.classList.add("ag-color-picker-color");
    this.eDisplayFieldText = eDocument.createElement("span");
    this.eDisplayFieldText.classList.add("ag-color-picker-value");
    this.eDisplayField.appendChild(this.eDisplayFieldColor);
    this.eDisplayField.appendChild(this.eDisplayFieldText);
    super.postConstruct();
    if (this.value) {
      this.setValue(this.value);
    }
  }
  createPickerComponent() {
    const eGuiRect = this.eWrapper.getBoundingClientRect();
    const parentRect = this.popupService.getParentRect();
    const colorDialog = this.createBean(
      new AgDialog({
        closable: false,
        modal: true,
        hideTitleBar: true,
        minWidth: 190,
        width: 190,
        height: 250,
        x: eGuiRect.right - parentRect.left - 190,
        y: eGuiRect.top - parentRect.top - 250 - (this.config.pickerGap ?? 0),
        postProcessPopupParams: {
          type: "colorPicker",
          eventSource: this.eWrapper
        }
      })
    );
    return colorDialog;
  }
  renderAndPositionPicker() {
    const pickerComponent = this.pickerComponent;
    const colorPanel = this.createBean(new AgColorPanel({ picker: this }));
    pickerComponent.addCssClass("ag-color-dialog");
    colorPanel.addDestroyFunc(() => {
      if (pickerComponent.isAlive()) {
        this.destroyBean(pickerComponent);
      }
    });
    pickerComponent.setParentComponent(this);
    pickerComponent.setBodyComponent(colorPanel);
    colorPanel.setValue(this.getValue());
    colorPanel.getGui().focus();
    pickerComponent.addDestroyFunc(() => {
      if (!this.isDestroyingPicker) {
        this.beforeHidePicker();
        this.isDestroyingPicker = true;
        if (colorPanel.isAlive()) {
          this.destroyBean(colorPanel);
        }
        if (this.isAlive()) {
          this.getFocusableElement().focus();
        }
      } else {
        this.isDestroyingPicker = false;
      }
    });
    return () => this.pickerComponent?.close();
  }
  setValue(color) {
    if (this.value === color) {
      return this;
    }
    this.eDisplayFieldColor.style.backgroundColor = color;
    this.eDisplayFieldText.textContent = _Util3.Color.fromString(color).toHexString().toUpperCase();
    return super.setValue(color);
  }
  getValue() {
    return this.value;
  }
};
var AgColorPickerSelector = {
  selector: "AG-COLOR-PICKER",
  component: AgColorPicker
};
var CrosshairPanel = class extends Component33 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const crosshairGroupParams = this.chartMenuParamsFactory.addEnableParams(
      "crosshair.enabled",
      {
        cssIdentifier: "charts-advanced-settings-top-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("crosshair"),
        suppressEnabledCheckbox: true,
        useToggle: true
      }
    );
    const crosshairLabelCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "crosshair.label.enabled",
      "crosshairLabel"
    );
    const crosshairSnapCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "crosshair.snap",
      "crosshairSnap"
    );
    const crosshairStrokeColorPickerParams = this.chartMenuParamsFactory.getDefaultColorPickerParams(
      "crosshair.stroke",
      "color"
    );
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="crosshairGroup">
                <ag-checkbox data-ref="crosshairLabelCheckbox"></ag-checkbox>
                <ag-checkbox data-ref="crosshairSnapCheckbox"></ag-checkbox>
                <ag-color-picker data-ref="crosshairStrokeColorPicker"></ag-color-picker>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgCheckboxSelector2, AgColorPickerSelector],
      {
        crosshairGroup: crosshairGroupParams,
        crosshairLabelCheckbox: crosshairLabelCheckboxParams,
        crosshairSnapCheckbox: crosshairSnapCheckboxParams,
        crosshairStrokeColorPicker: crosshairStrokeColorPickerParams
      }
    );
  }
};
var AgInputRange = class extends AgAbstractInputField {
  constructor(config) {
    super(config, "ag-range-field", "range");
  }
  postConstruct() {
    super.postConstruct();
    const { min, max, step } = this.config;
    if (min != null) {
      this.setMinValue(min);
    }
    if (max != null) {
      this.setMaxValue(max);
    }
    this.setStep(step || 1);
  }
  addInputListeners() {
    this.addManagedElementListeners(this.eInput, {
      input: (e) => {
        const value = e.target.value;
        this.setValue(value);
      }
    });
  }
  setMinValue(value) {
    this.min = value;
    this.eInput.setAttribute("min", value.toString());
    return this;
  }
  setMaxValue(value) {
    this.max = value;
    this.eInput.setAttribute("max", value.toString());
    return this;
  }
  setStep(value) {
    this.eInput.setAttribute("step", value.toString());
    return this;
  }
  setValue(value, silent) {
    if (this.min != null) {
      value = Math.max(parseFloat(value), this.min).toString();
    }
    if (this.max != null) {
      value = Math.min(parseFloat(value), this.max).toString();
    }
    const ret = super.setValue(value, silent);
    this.eInput.value = value;
    return ret;
  }
};
var AgInputRangeSelector = {
  selector: "AG-INPUT-RANGE",
  component: AgInputRange
};
var AgSlider = class extends AgAbstractLabel {
  constructor(config) {
    super(
      config,
      /* html */
      `<div class="ag-slider">
            <label data-ref="eLabel"></label>
            <div class="ag-wrapper ag-slider-wrapper">
                <ag-input-range data-ref="eSlider"></ag-input-range>
                <ag-input-number-field data-ref="eText"></ag-input-number-field>
            </div>
        </div>`,
      [AgInputRangeSelector, AgInputNumberFieldSelector2]
    );
    this.eLabel = RefPlaceholder33;
    this.eSlider = RefPlaceholder33;
    this.eText = RefPlaceholder33;
    this.labelAlignment = "top";
  }
  postConstruct() {
    super.postConstruct();
    this.eSlider.addCssClass("ag-slider-field");
    const { minValue, maxValue, textFieldWidth, step, value, onValueChange } = this.config;
    if (minValue != null) {
      this.setMinValue(minValue);
    }
    if (maxValue != null) {
      this.setMaxValue(maxValue);
    }
    if (textFieldWidth != null) {
      this.setTextFieldWidth(textFieldWidth);
    }
    if (step != null) {
      this.setStep(step);
    }
    if (value != null) {
      this.setValue(value);
    }
    if (onValueChange != null) {
      this.onValueChange(onValueChange);
    }
  }
  onValueChange(callbackFn) {
    this.addManagedListeners(this.eText, {
      fieldValueChanged: () => {
        const textValue = parseFloat(this.eText.getValue());
        this.eSlider.setValue(textValue.toString(), true);
        callbackFn(textValue || 0);
      }
    });
    this.addManagedListeners(this.eSlider, {
      fieldValueChanged: () => {
        const sliderValue = this.eSlider.getValue();
        this.eText.setValue(sliderValue, true);
        callbackFn(parseFloat(sliderValue));
      }
    });
    return this;
  }
  setSliderWidth(width) {
    this.eSlider.setWidth(width);
    return this;
  }
  setTextFieldWidth(width) {
    this.eText.setWidth(width);
    return this;
  }
  setMinValue(minValue) {
    this.eSlider.setMinValue(minValue);
    this.eText.setMin(minValue);
    return this;
  }
  setMaxValue(maxValue) {
    this.eSlider.setMaxValue(maxValue);
    this.eText.setMax(maxValue);
    return this;
  }
  getValue() {
    return this.eText.getValue();
  }
  setValue(value, silent) {
    if (this.getValue() === value) {
      return this;
    }
    this.eText.setValue(value, true);
    this.eSlider.setValue(value, true);
    if (!silent) {
      this.dispatchLocalEvent({ type: "fieldValueChanged" });
    }
    return this;
  }
  setStep(step) {
    this.eSlider.setStep(step);
    this.eText.setStep(step);
    return this;
  }
};
var AgSliderSelector = {
  selector: "AG-SLIDER",
  component: AgSlider
};
var NavigatorPanel = class extends Component43 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const navigatorGroupParams = this.chartMenuParamsFactory.addEnableParams(
      "navigator.enabled",
      {
        cssIdentifier: "charts-advanced-settings-top-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("navigator"),
        suppressEnabledCheckbox: true,
        useToggle: true
      }
    );
    const navigatorHeightSliderParams = this.chartMenuParamsFactory.getDefaultSliderParams(
      "navigator.height",
      "height",
      60
    );
    navigatorHeightSliderParams.minValue = 10;
    const navigatorMiniChartCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "navigator.miniChart.enabled",
      "miniChart"
    );
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="navigatorGroup">
                <ag-slider data-ref="navigatorHeightSlider"></ag-slider>
                <ag-checkbox data-ref="navigatorMiniChartCheckbox"></ag-checkbox>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgSliderSelector, AgCheckboxSelector22],
      {
        navigatorGroup: navigatorGroupParams,
        navigatorHeightSlider: navigatorHeightSliderParams,
        navigatorMiniChartCheckbox: navigatorMiniChartCheckboxParams
      }
    );
  }
};
var ZoomPanel = class extends Component53 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
    this.zoomScrollingStepInput = RefPlaceholder43;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const zoomGroupParams = this.chartMenuParamsFactory.addEnableParams("zoom.enabled", {
      cssIdentifier: "charts-advanced-settings-top-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("zoom"),
      suppressEnabledCheckbox: true,
      useToggle: true
    });
    const zoomScrollingCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "zoom.enableScrolling",
      "scrollingZoom"
    );
    const zoomScrollingStepSliderParams = this.chartMenuParamsFactory.getDefaultSliderParams(
      "zoom.scrollingStep",
      "scrollingStep",
      1
    );
    zoomScrollingStepSliderParams.step = 0.01;
    zoomScrollingStepSliderParams.minValue = zoomScrollingStepSliderParams.step;
    const zoomSelectingCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "zoom.enableSelecting",
      "selectingZoom"
    );
    zoomScrollingCheckboxParams.onValueChange = /* @__PURE__ */ ((onValueChange) => (value) => {
      if (!onValueChange)
        return;
      onValueChange(value);
      this.zoomScrollingStepInput.setDisabled(!value);
    })(zoomScrollingCheckboxParams.onValueChange);
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="zoomGroup">
                <ag-checkbox data-ref="zoomSelectingCheckbox"></ag-checkbox>
                <ag-checkbox data-ref="zoomScrollingCheckbox"></ag-checkbox>
                <ag-slider data-ref="zoomScrollingStepInput"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgCheckboxSelector3, AgSliderSelector],
      {
        zoomGroup: zoomGroupParams,
        zoomScrollingCheckbox: zoomScrollingCheckboxParams,
        zoomScrollingStepInput: zoomScrollingStepSliderParams,
        zoomSelectingCheckbox: zoomSelectingCheckboxParams
      }
    );
    this.zoomScrollingStepInput.setDisabled(!zoomScrollingCheckboxParams.value);
  }
};
var INTERACTIVITY_GROUPS = ["navigator", "zoom", "animation", "crosshair"];
var AdvancedSettingsPanel = class extends Component63 {
  constructor(chartMenuContext) {
    super(
      /* html */
      `<div class="ag-chart-advanced-settings-wrapper"></div>`
    );
    this.chartMenuContext = chartMenuContext;
  }
  postConstruct() {
    this.chartPanelFeature = this.createManagedBean(
      new ChartPanelFeature(
        this.chartMenuContext.chartController,
        this.getGui(),
        "ag-chart-advanced-settings-section",
        (chartType, seriesType) => this.createPanels(chartType, seriesType)
      )
    );
    this.chartPanelFeature.refreshPanels();
  }
  createPanels(chartType, seriesType) {
    INTERACTIVITY_GROUPS.forEach((group) => {
      if (!this.isGroupPanelShownForSeries(group, seriesType)) {
        return;
      }
      const comp = this.createPanel(group);
      this.chartPanelFeature.addComponent(comp);
    });
  }
  isGroupPanelShownForSeries(group, seriesType) {
    return group === "animation" || isCartesian(seriesType);
  }
  createPanel(group) {
    const { chartMenuParamsFactory, chartAxisMenuParamsFactory } = this.chartMenuContext;
    switch (group) {
      case "navigator":
        return new NavigatorPanel(chartMenuParamsFactory);
      case "zoom":
        return new ZoomPanel(chartMenuParamsFactory);
      case "animation":
        return new AnimationPanel(chartMenuParamsFactory);
      case "crosshair":
        return new CrosshairPanel(chartAxisMenuParamsFactory);
    }
  }
};
var AdvancedSettingsMenuFactory = class extends BeanStub24 {
  constructor() {
    super(...arguments);
    this.beanName = "advancedSettingsMenuFactory";
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.chartTranslationService = beans.chartTranslationService;
  }
  showMenu(chartMenuContext, eventSource) {
    this.hideMenu();
    const menu = this.createBean(new AdvancedSettingsMenu(chartMenuContext));
    this.activeDialog = this.createBean(
      new AgDialog({
        title: this.chartTranslationService.translate("advancedSettings"),
        component: menu,
        width: 300,
        height: 400,
        resizable: true,
        movable: true,
        centered: true,
        closable: true,
        afterGuiAttached: () => {
          this.focusService.findFocusableElements(menu.getGui())[0]?.focus();
        },
        closedCallback: () => {
          this.activeMenu = this.destroyBean(this.activeMenu);
          this.activeDialog = void 0;
          eventSource?.focus({ preventScroll: true });
        }
      })
    );
    this.activeMenu = menu;
  }
  hideMenu() {
    if (this.activeDialog) {
      this.destroyBean(this.activeDialog);
    }
  }
  destroy() {
    this.activeMenu = this.destroyBean(this.activeMenu);
    this.activeDialog = this.destroyBean(this.activeDialog);
    super.destroy();
  }
};
var AdvancedSettingsMenu = class extends TabGuardComp6 {
  constructor(chartMenuContext) {
    super(
      /* html */
      `<div class="ag-chart-advanced-settings"></div>`
    );
    this.chartMenuContext = chartMenuContext;
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.advancedSettingsPanel = this.createManagedBean(new AdvancedSettingsPanel(this.chartMenuContext));
    this.getGui().appendChild(this.advancedSettingsPanel.getGui());
    this.initialiseTabGuard({
      onTabKeyDown: this.onTabKeyDown.bind(this),
      focusTrapActive: true
    });
  }
  onTabKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
    const backwards = e.shiftKey;
    const panelGui = this.advancedSettingsPanel.getGui();
    const nextEl = this.focusService.findNextFocusableElement(panelGui, false, backwards);
    if (nextEl) {
      nextEl.focus();
    } else {
      const focusableElements = this.focusService.findFocusableElements(panelGui);
      if (focusableElements.length) {
        focusableElements[backwards ? focusableElements.length - 1 : 0].focus();
      }
    }
  }
};
var ChartMenuListFactory = class extends BeanStub33 {
  constructor() {
    super(...arguments);
    this.beanName = "chartMenuListFactory";
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.chartMenuService = beans.chartMenuService;
    this.chartTranslationService = beans.chartTranslationService;
  }
  showMenuList(params) {
    const { eventSource, showMenu, chartMenuContext } = params;
    const areChartToolPanelsEnabled = this.chartMenuService.doChartToolPanelsExist(
      chartMenuContext.chartController
    );
    const menuItems = this.mapWithStockItems(
      this.getMenuItems(chartMenuContext.chartController, areChartToolPanelsEnabled),
      chartMenuContext,
      showMenu,
      eventSource,
      areChartToolPanelsEnabled
    );
    if (!menuItems.length) {
      return;
    }
    const chartMenuList = this.createBean(new ChartMenuList(menuItems));
    this.activeChartMenuList = chartMenuList;
    let multiplier = -1;
    let alignSide = "left";
    if (this.gos.get("enableRtl")) {
      multiplier = 1;
      alignSide = "right";
    }
    const eGui = chartMenuList.getGui();
    this.popupService.addPopup({
      modal: true,
      eChild: eGui,
      closeOnEsc: true,
      closedCallback: () => {
        this.destroyBean(chartMenuList);
        this.activeChartMenuList = void 0;
        if (_isNothingFocused3(this.gos)) {
          eventSource.focus({ preventScroll: true });
        }
      },
      afterGuiAttached: (params2) => chartMenuList.afterGuiAttached(params2),
      positionCallback: () => {
        {
          this.popupService.positionPopupByComponent({
            type: "chartMenu",
            eventSource,
            ePopup: eGui,
            alignSide,
            nudgeX: 4 * multiplier,
            nudgeY: 4,
            position: "under",
            keepWithinBounds: true
          });
        }
      },
      ariaLabel: "Chart Menu"
    });
  }
  getMenuItems(chartController, areChartToolPanelsEnabled) {
    const defaultItems = [
      ...areChartToolPanelsEnabled ? ["chartEdit"] : [],
      ...chartController.isEnterprise() ? ["chartAdvancedSettings"] : [],
      chartController.isChartLinked() ? "chartUnlink" : "chartLink",
      "chartDownload"
    ];
    const chartMenuItems = this.gos.get("chartMenuItems");
    if (!chartMenuItems) {
      return defaultItems;
    } else if (Array.isArray(chartMenuItems)) {
      return chartMenuItems;
    } else {
      return chartMenuItems(
        this.gos.addGridCommonParams({
          defaultItems
        })
      );
    }
  }
  mapWithStockItems(originalList, chartMenuContext, showMenu, eventSource, areChartToolPanelsEnabled) {
    if (!originalList) {
      return [];
    }
    const resultList = [];
    originalList.forEach((menuItemOrString) => {
      let result;
      if (typeof menuItemOrString === "string") {
        result = this.getStockMenuItem(
          menuItemOrString,
          chartMenuContext,
          showMenu,
          eventSource,
          areChartToolPanelsEnabled
        );
      } else {
        result = { ...menuItemOrString };
      }
      if (!result) {
        return;
      }
      const { subMenu } = result;
      if (Array.isArray(subMenu)) {
        result.subMenu = this.mapWithStockItems(
          subMenu,
          chartMenuContext,
          showMenu,
          eventSource,
          areChartToolPanelsEnabled
        );
      }
      resultList.push(result);
    });
    return resultList;
  }
  getStockMenuItem(key, chartMenuContext, showMenu, eventSource, areChartToolPanelsEnabled) {
    switch (key) {
      case "chartEdit":
        return areChartToolPanelsEnabled ? this.createMenuItem(
          this.chartTranslationService.translate("chartEdit"),
          "chartsMenuEdit",
          showMenu
        ) : null;
      case "chartAdvancedSettings":
        return this.createMenuItem(
          this.chartTranslationService.translate("chartAdvancedSettings"),
          "chartsMenuAdvancedSettings",
          () => this.chartMenuService.openAdvancedSettings(chartMenuContext, eventSource)
        );
      case "chartUnlink":
        return chartMenuContext.chartController.isChartLinked() ? this.createMenuItem(
          this.chartTranslationService.translate("chartUnlink"),
          "unlinked",
          () => this.chartMenuService.toggleLinked(chartMenuContext)
        ) : null;
      case "chartLink":
        return !chartMenuContext.chartController.isChartLinked() ? this.createMenuItem(
          this.chartTranslationService.translate("chartLink"),
          "linked",
          () => this.chartMenuService.toggleLinked(chartMenuContext)
        ) : null;
      case "chartDownload":
        return this.createMenuItem(
          this.chartTranslationService.translate("chartDownload"),
          "save",
          () => this.chartMenuService.downloadChart(chartMenuContext)
        );
    }
    return null;
  }
  createMenuItem(name, iconName, action) {
    return {
      name,
      icon: _createIconNoSpan10(iconName, this.gos, null),
      action
    };
  }
  destroy() {
    this.destroyBean(this.activeChartMenuList);
    super.destroy();
  }
};
var ChartMenuList = class extends Component73 {
  constructor(menuItems) {
    super(
      /* html */
      `
            <div data-ref="eChartsMenu" role="presentation" class="ag-menu ag-chart-menu-popup"></div>
        `
    );
    this.menuItems = menuItems;
    this.eChartsMenu = RefPlaceholder53;
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.mainMenuList = this.createManagedBean(new AgMenuList(0));
    this.mainMenuList.addMenuItems(this.menuItems);
    this.mainMenuList.addEventListener("closeMenu", this.onHidePopup.bind(this));
    this.eChartsMenu.appendChild(this.mainMenuList.getGui());
  }
  onHidePopup() {
    this.hidePopupFunc?.();
  }
  afterGuiAttached({ hidePopup }) {
    if (hidePopup) {
      this.hidePopupFunc = hidePopup;
      this.addDestroyFunc(hidePopup);
    }
    this.focusService.focusInto(this.mainMenuList.getGui());
  }
};
var ChartCrossFilterService = class _ChartCrossFilterService extends BeanStub43 {
  constructor() {
    super(...arguments);
    this.beanName = "chartCrossFilterService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.valueService = beans.valueService;
    this.filterManager = beans.filterManager;
    if (_isClientSideRowModel2(this.gos)) {
      this.clientSideRowModel = beans.rowModel;
    }
  }
  filter(event, reset = false) {
    const filterModel = this.filterManager?.getFilterModel() ?? {};
    if (reset) {
      this.resetFilters(filterModel);
      return;
    }
    const colId = _ChartCrossFilterService.extractFilterColId(event);
    if (this.isValidColumnFilter(colId)) {
      this.updateFilters(filterModel, event, colId);
    } else {
      _warnOnce7(
        "cross filtering requires a 'agSetColumnFilter' or 'agMultiColumnFilter' to be defined on the column with id: '" + colId + "'"
      );
    }
  }
  resetFilters(filterModel) {
    const filtersExist = Object.keys(filterModel).length > 0;
    if (filtersExist) {
      this.filterManager?.setFilterModel(null);
      this.filterManager?.onFilterChanged({ source: "api" });
    }
  }
  updateFilters(filterModel, event, colId) {
    const dataKey = _ChartCrossFilterService.extractFilterColId(event);
    const rawValue = event.datum[dataKey];
    if (rawValue === void 0) {
      return;
    }
    const selectedValue = rawValue.toString();
    if (event.event.metaKey || event.event.ctrlKey) {
      const existingGridValues = this.getCurrentGridValuesForCategory(colId);
      const valueAlreadyExists = _includes3(existingGridValues, selectedValue);
      let updatedValues;
      if (valueAlreadyExists) {
        updatedValues = existingGridValues.filter((v) => v !== selectedValue);
      } else {
        updatedValues = existingGridValues;
        updatedValues.push(selectedValue);
      }
      filterModel[colId] = this.getUpdatedFilterModel(colId, updatedValues);
    } else {
      const updatedValues = [selectedValue];
      filterModel = { [colId]: this.getUpdatedFilterModel(colId, updatedValues) };
    }
    this.filterManager?.setFilterModel(filterModel);
  }
  getUpdatedFilterModel(colId, updatedValues) {
    const columnFilterType = this.getColumnFilterType(colId);
    if (columnFilterType === "agMultiColumnFilter") {
      return { filterType: "multi", filterModels: [null, { filterType: "set", values: updatedValues }] };
    }
    return { filterType: "set", values: updatedValues };
  }
  getCurrentGridValuesForCategory(colId) {
    const filteredValues = [];
    const column = this.getColumnById(colId);
    this.clientSideRowModel?.forEachNodeAfterFilter((rowNode) => {
      if (column && !rowNode.group) {
        const value = this.valueService.getValue(column, rowNode) + "";
        if (!filteredValues.includes(value)) {
          filteredValues.push(value);
        }
      }
    });
    return filteredValues;
  }
  static extractFilterColId(event) {
    return event.xKey || event.calloutLabelKey;
  }
  isValidColumnFilter(colId) {
    if (colId.indexOf("-filtered-out")) {
      colId = colId.replace("-filtered-out", "");
    }
    const filterType = this.getColumnFilterType(colId);
    if (typeof filterType === "boolean") {
      return filterType;
    }
    return _includes3(["agSetColumnFilter", "agMultiColumnFilter"], filterType);
  }
  getColumnFilterType(colId) {
    const gridColumn = this.getColumnById(colId);
    if (gridColumn) {
      const colDef = gridColumn.getColDef();
      return colDef.filter;
    }
  }
  getColumnById(colId) {
    return this.columnModel.getCol(colId);
  }
};
var CHART_TOOLBAR_ALLOW_LIST = [
  "chartUnlink",
  "chartLink",
  "chartDownload",
  "chartMenu"
];
var CHART_TOOL_PANEL_MENU_OPTIONS = {
  settings: "chartSettings",
  data: "chartData",
  format: "chartFormat"
};
var ChartMenuService = class extends BeanStub53 {
  constructor() {
    super(...arguments);
    this.beanName = "chartMenuService";
  }
  wireBeans(beans) {
    this.advancedSettingsMenuFactory = beans.advancedSettingsMenuFactory;
  }
  downloadChart(chartMenuContext, dimensions, fileName, fileFormat) {
    chartMenuContext.chartController.getChartProxy().downloadChart(dimensions, fileName, fileFormat);
  }
  toggleLinked(chartMenuContext) {
    chartMenuContext.chartController.detachChartRange();
  }
  openAdvancedSettings(chartMenuContext, eventSource) {
    this.advancedSettingsMenuFactory.showMenu(chartMenuContext, eventSource);
  }
  hideAdvancedSettings() {
    this.advancedSettingsMenuFactory.hideMenu();
  }
  getChartToolbarOptions() {
    const defaultChartToolbarOptions = ["chartMenu"];
    const toolbarItemsFunc = this.gos.getCallback("getChartToolbarItems");
    const params = {
      defaultItems: defaultChartToolbarOptions
    };
    return toolbarItemsFunc ? toolbarItemsFunc(params).filter((option) => {
      if (!CHART_TOOLBAR_ALLOW_LIST.includes(option)) {
        _warnOnce23(`'${option}' is not a valid Chart Toolbar Option`);
        return false;
      }
      return true;
    }) : defaultChartToolbarOptions;
  }
  getChartToolPanels(chartController) {
    const chartToolPanelsDef = this.gos.get("chartToolPanelsDef");
    const panelsOverride = chartToolPanelsDef?.panels?.map((panel) => {
      const menuOption = CHART_TOOL_PANEL_MENU_OPTIONS[panel];
      if (!menuOption) {
        _warnOnce23(`Invalid panel in chartToolPanelsDef.panels: '${panel}'`);
      }
      return menuOption;
    }).filter((panel) => Boolean(panel));
    let panels = panelsOverride ?? Object.values(CHART_TOOL_PANEL_MENU_OPTIONS);
    if (chartController.isPivotChart()) {
      panels = panels.filter((panel) => panel !== "chartData");
    }
    const defaultToolPanel = chartToolPanelsDef?.defaultToolPanel;
    const defaultPanel = defaultToolPanel && CHART_TOOL_PANEL_MENU_OPTIONS[defaultToolPanel] || panels[0];
    return {
      panels,
      defaultPanel
    };
  }
  doesChartToolbarExist() {
    const chartToolbarOptions = this.getChartToolbarOptions();
    return chartToolbarOptions.length > 0;
  }
  doChartToolPanelsExist(chartController) {
    const { panels } = this.getChartToolPanels(chartController);
    return panels.length > 0;
  }
};
var _ChartTranslationService = class _ChartTranslationService2 extends BeanStub62 {
  constructor() {
    super(...arguments);
    this.beanName = "chartTranslationService";
  }
  translate(toTranslate) {
    const translate = this.localeService.getLocaleTextFunc();
    const defaultTranslation = _ChartTranslationService2.DEFAULT_TRANSLATIONS[toTranslate];
    return translate(toTranslate, defaultTranslation);
  }
};
_ChartTranslationService.DEFAULT_TRANSLATIONS = {
  pivotChartTitle: "Pivot Chart",
  rangeChartTitle: "Range Chart",
  settings: "Chart",
  data: "Set Up",
  format: "Customize",
  categories: "Categories",
  defaultCategory: "(None)",
  series: "Series",
  switchCategorySeries: "Switch Category / Series",
  categoryValues: "Category Values",
  seriesLabels: "Series Labels",
  aggregate: "Aggregate",
  xyValues: "X Y Values",
  paired: "Paired Mode",
  axis: "Axis",
  xAxis: "Horizontal Axis",
  yAxis: "Vertical Axis",
  polarAxis: "Polar Axis",
  radiusAxis: "Radius Axis",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Animation",
  crosshair: "Crosshair",
  color: "Color",
  thickness: "Thickness",
  preferredLength: "Preferred Length",
  xType: "X Type",
  axisType: "Axis Type",
  automatic: "Automatic",
  category: "Category",
  number: "Number",
  time: "Time",
  timeFormat: "Time Format",
  autoRotate: "Auto Rotate",
  labelRotation: "Rotation",
  circle: "Circle",
  polygon: "Polygon",
  square: "Square",
  cross: "Cross",
  diamond: "Diamond",
  plus: "Plus",
  triangle: "Triangle",
  heart: "Heart",
  orientation: "Orientation",
  fixed: "Fixed",
  parallel: "Parallel",
  perpendicular: "Perpendicular",
  radiusAxisPosition: "Position",
  ticks: "Ticks",
  gridLines: "Grid Lines",
  width: "Width",
  height: "Height",
  length: "Length",
  padding: "Padding",
  spacing: "Spacing",
  chartStyle: "Chart Style",
  title: "Title",
  chartTitles: "Titles",
  chartTitle: "Chart Title",
  chartSubtitle: "Subtitle",
  horizontalAxisTitle: "Horizontal Axis Title",
  verticalAxisTitle: "Vertical Axis Title",
  polarAxisTitle: "Polar Axis Title",
  titlePlaceholder: "Chart Title",
  background: "Background",
  font: "Font",
  weight: "Weight",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  labels: "Labels",
  calloutLabels: "Callout Labels",
  sectorLabels: "Sector Labels",
  positionRatio: "Position Ratio",
  size: "Size",
  shape: "Shape",
  minSize: "Minimum Size",
  maxSize: "Maximum Size",
  legend: "Legend",
  position: "Position",
  markerSize: "Marker Size",
  markerStroke: "Marker Stroke",
  markerPadding: "Marker Padding",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "Horizontal Spacing",
  layoutVerticalSpacing: "Vertical Spacing",
  strokeWidth: "Stroke Width",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Markers",
  shadow: "Shadow",
  blur: "Blur",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Line Width",
  lineDash: "Line Dash",
  lineDashOffset: "Dash Offset",
  scrollingZoom: "Scrolling",
  scrollingStep: "Scrolling Step",
  selectingZoom: "Selecting",
  durationMillis: "Duration (ms)",
  crosshairLabel: "Label",
  crosshairSnap: "Snap to Node",
  normal: "Normal",
  bold: "Bold",
  italic: "Italic",
  boldItalic: "Bold Italic",
  predefined: "Predefined",
  fillOpacity: "Fill Opacity",
  strokeColor: "Line Color",
  strokeOpacity: "Line Opacity",
  miniChart: "Mini-Chart",
  histogramBinCount: "Bin count",
  connectorLine: "Connector Line",
  seriesItems: "Series Items",
  seriesItemType: "Item Type",
  seriesItemPositive: "Positive",
  seriesItemNegative: "Negative",
  seriesItemLabels: "Item Labels",
  columnGroup: "Column",
  barGroup: "Bar",
  pieGroup: "Pie",
  lineGroup: "Line",
  scatterGroup: "X Y (Scatter)",
  areaGroup: "Area",
  polarGroup: "Polar",
  statisticalGroup: "Statistical",
  hierarchicalGroup: "Hierarchical",
  specializedGroup: "Specialized",
  combinationGroup: "Combination",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "Pie",
  donutTooltip: "Donut",
  lineTooltip: "Line",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "Bubble",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radial Column",
  radialBarTooltip: "Radial Bar",
  radarLineTooltip: "Radar Line",
  radarAreaTooltip: "Radar Area",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Range Bar",
  rangeAreaTooltip: "Range Area",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Treemap",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Waterfall",
  heatmapTooltip: "Heatmap",
  columnLineComboTooltip: "Column & Line",
  areaColumnComboTooltip: "Area & Column",
  customComboTooltip: "Custom Combination",
  innerRadius: "Inner Radius",
  startAngle: "Start Angle",
  endAngle: "End Angle",
  reverseDirection: "Reverse Direction",
  groupPadding: "Group Padding",
  seriesPadding: "Series Padding",
  tile: "Tile",
  whisker: "Whisker",
  cap: "Cap",
  capLengthRatio: "Length Ratio",
  labelPlacement: "Placement",
  inside: "Inside",
  outside: "Outside",
  noDataToChart: "No data available to be charted.",
  pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Linked to Grid",
  chartUnlinkToolbarTooltip: "Unlinked from Grid",
  chartDownloadToolbarTooltip: "Download Chart",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Edit Chart",
  chartAdvancedSettings: "Advanced Settings",
  chartLink: "Link to Grid",
  chartUnlink: "Unlink from Grid",
  chartDownload: "Download Chart",
  histogramFrequency: "Frequency",
  seriesChartType: "Series Chart Type",
  seriesType: "Series Type",
  secondaryAxis: "Secondary Axis",
  seriesAdd: "Add a series",
  categoryAdd: "Add a category",
  bar: "Bar",
  column: "Column",
  histogram: "Histogram",
  advancedSettings: "Advanced Settings",
  direction: "Direction",
  horizontal: "Horizontal",
  vertical: "Vertical",
  seriesGroupType: "Group Type",
  groupedSeriesGroupType: "Grouped",
  stackedSeriesGroupType: "Stacked",
  normalizedSeriesGroupType: "100% Stacked",
  legendEnabled: "Enabled",
  invalidColor: "Color value is invalid",
  groupedColumnFull: "Grouped Column",
  stackedColumnFull: "Stacked Column",
  normalizedColumnFull: "100% Stacked Column",
  groupedBarFull: "Grouped Bar",
  stackedBarFull: "Stacked Bar",
  normalizedBarFull: "100% Stacked Bar",
  stackedAreaFull: "Stacked Area",
  normalizedAreaFull: "100% Stacked Area",
  customCombo: "Custom Combination",
  // menu items
  groupedColumn: "Grouped",
  stackedColumn: "Stacked",
  pie: "Pie",
  donut: "Donut",
  line: "Line",
  scatter: "Scatter",
  bubble: "Bubble",
  area: "Area",
  stackedArea: "Stacked",
  radarLine: "Radar Line",
  radarArea: "Radar Area",
  nightingale: "Nightingale",
  radialColumn: "Radial Column",
  radialBar: "Radial Bar",
  boxPlot: "Box Plot",
  rangeBar: "Range Bar",
  rangeArea: "Range Area",
  treemap: "Treemap",
  sunburst: "Sunburst",
  waterfall: "Waterfall",
  heatmap: "Heatmap",
  columnLineCombo: "Column & Line",
  AreaColumnCombo: "Area & Column",
  // aria
  ariaChartMenuClose: "Close Chart Edit Menu",
  ariaChartSelected: "Selected",
  // group
  group: "Group",
  // time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM",
  // agg funcs
  sum: "Sum",
  first: "First",
  last: "Last",
  min: "Min",
  max: "Max",
  count: "Count",
  avg: "Average"
};
var ChartTranslationService = _ChartTranslationService;
var VERSION4 = "32.3.0";
var ALL_AXIS_TYPES = ["number", "category", "grouped-category", "log", "time"];
function getLegacyAxisType(chartType) {
  switch (chartType) {
    case "bar":
    case "stackedBar":
    case "normalizedBar":
      return ["number", "category"];
    case "groupedBar":
      return ["number", "grouped-category"];
    case "column":
    case "stackedColumn":
    case "normalizedColumn":
    case "line":
    case "area":
    case "stackedArea":
    case "normalizedArea":
    case "histogram":
      return ["category", "number"];
    case "groupedColumn":
      return ["grouped-category", "number"];
    case "scatter":
    case "bubble":
      return ["number", "number"];
    default:
      return void 0;
  }
}
function get(source, expression, defaultValue) {
  if (source == null) {
    return defaultValue;
  }
  const keys = expression.split(".");
  let objectToRead = source;
  while (keys.length > 1) {
    objectToRead = objectToRead[keys.shift()];
    if (objectToRead == null) {
      return defaultValue;
    }
  }
  const value = objectToRead[keys[0]];
  return value != null ? value : defaultValue;
}
function set(target, expression, value) {
  if (target == null) {
    return;
  }
  const keys = expression.split(".");
  let objectToUpdate = target;
  keys.forEach((key, i) => {
    if (!objectToUpdate[key]) {
      objectToUpdate[key] = {};
    }
    if (i < keys.length - 1) {
      objectToUpdate = objectToUpdate[key];
    }
  });
  objectToUpdate[keys[keys.length - 1]] = value;
}
function createAgChartTheme(chartProxyParams, proxy, isEnterprise, chartThemeDefaults, updatedOverrides) {
  const { chartOptionsToRestore, chartPaletteToRestore, chartThemeToRestore } = chartProxyParams;
  const themeName = getSelectedTheme(chartProxyParams);
  const stockTheme = isStockTheme(themeName);
  const rootTheme = stockTheme ? { baseTheme: themeName } : lookupCustomChartTheme(chartProxyParams, themeName) ?? {};
  const gridOptionsThemeOverrides = chartProxyParams.getGridOptionsChartThemeOverrides();
  const apiThemeOverrides = chartProxyParams.apiChartThemeOverrides;
  const standaloneChartType = getSeriesType(chartProxyParams.chartType);
  const crossFilteringOverrides = chartProxyParams.crossFiltering ? createCrossFilterThemeOverrides(proxy, chartProxyParams, standaloneChartType) : void 0;
  const isTitleEnabled = () => {
    const isTitleEnabled2 = (obj) => {
      if (!obj) {
        return false;
      }
      return Object.keys(obj).some((key) => get(obj[key], "title.enabled", false));
    };
    return isTitleEnabled2(gridOptionsThemeOverrides) || isTitleEnabled2(apiThemeOverrides);
  };
  const overrides = [
    stockTheme ? inbuiltStockThemeOverrides(chartProxyParams, isEnterprise, isTitleEnabled()) : void 0,
    pieAndDonutOverrides(),
    chartThemeDefaults,
    crossFilteringOverrides,
    gridOptionsThemeOverrides,
    apiThemeOverrides,
    { ...chartOptionsToRestore ?? {} },
    updatedOverrides
  ];
  const theme = overrides.filter((v) => !!v).reduce(
    (r, n) => ({
      baseTheme: r,
      overrides: n
    }),
    rootTheme
  );
  if (chartPaletteToRestore && themeName === chartThemeToRestore) {
    const rootThemePalette = _Theme.getChartTheme(rootTheme).palette;
    if (!isIdenticalPalette(chartPaletteToRestore, rootThemePalette)) {
      theme.palette = chartPaletteToRestore;
    }
  }
  return theme;
}
function isIdenticalPalette(paletteA, paletteB) {
  const arrayCompare = (arrA, arrB) => {
    if (arrA === arrB)
      return true;
    if (arrA?.length !== arrB?.length)
      return false;
    return arrA?.every((v, i) => v === arrB?.[i]) ?? false;
  };
  return arrayCompare(paletteA.fills, paletteB.fills) && arrayCompare(paletteA.strokes, paletteB.strokes);
}
function isStockTheme(themeName) {
  return _includes22(Object.keys(_Theme.themes), themeName);
}
function createCrossFilterThemeOverrides(proxy, chartProxyParams, seriesType) {
  const legend = {
    listeners: {
      legendItemClick: (e) => {
        const chart = proxy.getChart();
        chart.series.forEach((s) => {
          s.toggleSeriesItem(e.itemId, e.enabled);
          s.toggleSeriesItem(`${e.itemId}-filtered-out`, e.enabled);
        });
      }
    }
  };
  return {
    [seriesType]: {
      tooltip: {
        delay: 500
      },
      legend,
      listeners: {
        click: (e) => chartProxyParams.crossFilterCallback(e, true)
      }
    }
  };
}
var STATIC_INBUILT_STOCK_THEME_AXES_OVERRIDES = ALL_AXIS_TYPES.reduce(
  (r, n) => ({ ...r, [n]: { title: { _enabledFromTheme: true } } }),
  {}
);
function inbuiltStockThemeOverrides(params, isEnterprise, titleEnabled) {
  const extraPadding = params.getExtraPaddingDirections();
  return {
    common: {
      ...isEnterprise ? { animation: { duration: 500 } } : void 0,
      axes: STATIC_INBUILT_STOCK_THEME_AXES_OVERRIDES,
      padding: {
        // don't add extra padding when a title is present!
        top: !titleEnabled && extraPadding.includes("top") ? 40 : 20,
        right: extraPadding.includes("right") ? 30 : 20,
        bottom: extraPadding.includes("bottom") ? 40 : 20,
        left: extraPadding.includes("left") ? 30 : 20
      }
    }
  };
}
function pieAndDonutOverrides() {
  return {
    pie: {
      series: {
        title: { _enabledFromTheme: true },
        calloutLabel: { _enabledFromTheme: true },
        sectorLabel: {
          enabled: false,
          _enabledFromTheme: true
        }
      }
    },
    donut: {
      series: {
        title: { _enabledFromTheme: true },
        calloutLabel: { _enabledFromTheme: true },
        sectorLabel: {
          enabled: false,
          _enabledFromTheme: true
        }
      }
    }
  };
}
function getSelectedTheme(chartProxyParams) {
  let chartThemeName = chartProxyParams.getChartThemeName();
  const availableThemes = chartProxyParams.getChartThemes();
  if (!_includes22(availableThemes, chartThemeName)) {
    chartThemeName = availableThemes[0];
  }
  return chartThemeName;
}
function lookupCustomChartTheme(chartProxyParams, name) {
  const { customChartThemes } = chartProxyParams;
  const customChartTheme = customChartThemes && customChartThemes[name];
  if (!customChartTheme) {
    _warnOnce32(
      `no stock theme exists with the name '${name}' and no custom chart theme with that name was supplied to 'customChartThemes'`
    );
  }
  return customChartTheme;
}
var ChartDatasource = class _ChartDatasource extends BeanStub72 {
  wireBeans(beans) {
    this.sortController = beans.sortController;
    this.gridRowModel = beans.rowModel;
    this.columnModel = beans.columnModel;
    this.valueService = beans.valueService;
    this.pivotResultColsService = beans.pivotResultColsService;
    this.rowNodeSorter = beans.rowNodeSorter;
    this.aggregationStage = beans.aggregationStage;
  }
  getData(params) {
    if (params.crossFiltering) {
      if (params.grouping) {
        _warnOnce42("crossing filtering with row grouping is not supported.");
        return { chartData: [], columnNames: {} };
      }
      if (!_isClientSideRowModel22(this.gos)) {
        _warnOnce42("crossing filtering is only supported in the client side row model.");
        return { chartData: [], columnNames: {} };
      }
    }
    const isServerSide = _isServerSideRowModel2(this.gos);
    if (isServerSide && params.pivoting) {
      this.updatePivotKeysForSSRM();
    }
    const result = this.extractRowsFromGridRowModel(params);
    result.chartData = this.aggregateRowsByDimension(params, result.chartData);
    return result;
  }
  extractRowsFromGridRowModel(params) {
    const { crossFiltering, startRow, endRow, valueCols, dimensionCols, grouping } = params;
    let extractedRowData = [];
    const columnNames = {};
    const groupNodeIndexes = {};
    const groupsToRemove = {};
    let filteredNodes = {};
    let allRowNodes = [];
    let numRows;
    if (crossFiltering) {
      filteredNodes = this.getFilteredRowNodes();
      allRowNodes = this.getAllRowNodes();
      numRows = allRowNodes.length;
    } else {
      const modelLastRow = this.gridRowModel.getRowCount() - 1;
      const hasNoRange = startRow === endRow && startRow === 0 && dimensionCols.length === 0 && valueCols.length === 0;
      if (hasNoRange) {
        numRows = 0;
      } else {
        const rangeLastRow = endRow >= 0 ? Math.min(endRow, modelLastRow) : modelLastRow;
        numRows = rangeLastRow - startRow + 1;
      }
    }
    if (numRows > 0) {
      valueCols.forEach((col) => {
        let columnNamesArr = [];
        const pivotKeys = col.getColDef().pivotKeys;
        if (pivotKeys) {
          columnNamesArr = pivotKeys.slice();
        }
        const headerName = col.getColDef().headerName;
        if (headerName) {
          columnNamesArr.push(headerName);
        }
        if (columnNamesArr.length > 0) {
          columnNames[col.getId()] = columnNamesArr;
        }
      });
    }
    let numRemovedNodes = 0;
    let id = 0;
    for (let i = 0; i < numRows; i++) {
      const rowNode = crossFiltering ? allRowNodes[i] : this.gridRowModel.getRow(i + startRow);
      if (rowNode.footer || rowNode.detail) {
        numRemovedNodes++;
        continue;
      }
      const data = {};
      dimensionCols.forEach((col) => {
        const colId = col.colId;
        const column = this.columnModel.getCol(colId);
        if (column) {
          const valueObject = this.valueService.getValue(column, rowNode);
          if (grouping) {
            const valueString = valueObject?.toString ? String(valueObject.toString()) : "";
            const labels2 = _ChartDatasource.getGroupLabels(rowNode, valueString);
            data[colId] = {
              labels: labels2,
              // this is needed so that standalone can handle animations properly when data updates
              id: id++,
              toString: function() {
                return this.labels.filter((l) => !!l).reverse().join(" - ");
              }
            };
            if (rowNode.group) {
              groupNodeIndexes[labels2.toString()] = i - numRemovedNodes;
            }
            const groupKey = labels2.slice(1, labels2.length).toString();
            if (groupKey) {
              groupsToRemove[groupKey] = groupNodeIndexes[groupKey];
            }
          } else {
            data[colId] = valueObject;
          }
        } else {
          data[ChartDataModel.DEFAULT_CATEGORY] = i + 1;
        }
      });
      valueCols.forEach((col) => {
        const colId = col.getColId();
        if (crossFiltering) {
          const filteredOutColId = colId + "-filtered-out";
          const value = this.valueService.getValue(col, rowNode);
          const actualValue = value != null && typeof value.toNumber === "function" ? value.toNumber() : value;
          if (filteredNodes[rowNode.id]) {
            data[colId] = actualValue;
            data[filteredOutColId] = params.aggFunc || params.isScatter ? void 0 : 0;
          } else {
            data[colId] = params.aggFunc || params.isScatter ? void 0 : 0;
            data[filteredOutColId] = actualValue;
          }
        } else {
          let value = this.valueService.getValue(col, rowNode);
          if (value && Object.prototype.hasOwnProperty.call(value, "toString")) {
            value = parseFloat(value.toString());
          }
          data[colId] = value != null && typeof value.toNumber === "function" ? value.toNumber() : value;
        }
      });
      extractedRowData.push(data);
    }
    let groupChartData;
    if (grouping) {
      const groupIndexesToRemove = _values(groupsToRemove);
      const allData = extractedRowData;
      extractedRowData = [];
      groupChartData = [];
      for (let i = 0; i < allData.length; i++) {
        (_includes32(groupIndexesToRemove, i) ? groupChartData : extractedRowData).push(allData[i]);
      }
    }
    return { chartData: extractedRowData, columnNames, groupChartData };
  }
  aggregateRowsByDimension(params, dataFromGrid) {
    const dimensionCols = params.dimensionCols;
    if (!params.aggFunc || dimensionCols.length === 0) {
      return dataFromGrid;
    }
    const lastCol = _last5(dimensionCols);
    const lastColId = lastCol && lastCol.colId;
    const map = {};
    const dataAggregated = [];
    dataFromGrid.forEach((data) => {
      let currentMap = map;
      dimensionCols.forEach((col) => {
        const colId = col.colId;
        const key = data[colId];
        if (colId === lastColId) {
          let groupItem = currentMap[key];
          if (!groupItem) {
            groupItem = { __children: [] };
            dimensionCols.forEach((dimCol) => {
              const dimColId = dimCol.colId;
              groupItem[dimColId] = data[dimColId];
            });
            currentMap[key] = groupItem;
            dataAggregated.push(groupItem);
          }
          groupItem.__children.push(data);
        } else {
          if (!currentMap[key]) {
            currentMap[key] = {};
          }
          currentMap = currentMap[key];
        }
      });
    });
    if (this.gos.assertModuleRegistered(ModuleNames4.RowGroupingModule, "Charting Aggregation")) {
      const aggStage = this.aggregationStage;
      dataAggregated.forEach(
        (groupItem) => params.valueCols.forEach((col) => {
          if (params.crossFiltering) {
            params.valueCols.forEach((valueCol) => {
              const colId = valueCol.getColId();
              const dataToAgg = groupItem.__children.filter((child) => typeof child[colId] !== "undefined").map((child) => child[colId]);
              const aggResult = aggStage.aggregateValues(dataToAgg, params.aggFunc);
              groupItem[valueCol.getId()] = aggResult && typeof aggResult.value !== "undefined" ? aggResult.value : aggResult;
              const filteredOutColId = `${colId}-filtered-out`;
              const dataToAggFiltered = groupItem.__children.filter((child) => typeof child[filteredOutColId] !== "undefined").map((child) => child[filteredOutColId]);
              const aggResultFiltered = aggStage.aggregateValues(dataToAggFiltered, params.aggFunc);
              groupItem[filteredOutColId] = aggResultFiltered && typeof aggResultFiltered.value !== "undefined" ? aggResultFiltered.value : aggResultFiltered;
            });
          } else {
            const dataToAgg = groupItem.__children.map((child) => child[col.getId()]);
            const aggResult = aggStage.aggregateValues(dataToAgg, params.aggFunc);
            groupItem[col.getId()] = aggResult && typeof aggResult.value !== "undefined" ? aggResult.value : aggResult;
          }
        })
      );
    }
    return dataAggregated;
  }
  updatePivotKeysForSSRM() {
    const secondaryColumns = this.pivotResultColsService.getPivotResultCols()?.list;
    if (!secondaryColumns) {
      return;
    }
    const pivotKeySeparator = this.extractPivotKeySeparator(secondaryColumns);
    secondaryColumns.forEach((col) => {
      if (pivotKeySeparator === "") {
        col.getColDef().pivotKeys = [];
      } else {
        const keys = col.getColId().split(pivotKeySeparator);
        col.getColDef().pivotKeys = keys.slice(0, keys.length - 1);
      }
    });
  }
  extractPivotKeySeparator(secondaryColumns) {
    if (secondaryColumns.length === 0) {
      return "";
    }
    const extractSeparator = (columnGroup, childId) => {
      const groupId = columnGroup.getGroupId();
      if (!columnGroup.getParent()) {
        return childId.split(groupId)[1][0];
      }
      return extractSeparator(columnGroup.getParent(), groupId);
    };
    const firstSecondaryCol = secondaryColumns[0];
    if (firstSecondaryCol.getParent() == null) {
      return "";
    }
    return extractSeparator(firstSecondaryCol.getParent(), firstSecondaryCol.getColId());
  }
  static getGroupLabels(rowNode, initialLabel) {
    const labels2 = [initialLabel];
    while (rowNode && rowNode.level !== 0) {
      rowNode = rowNode.parent;
      if (rowNode) {
        labels2.push(rowNode.key);
      }
    }
    return labels2;
  }
  getFilteredRowNodes() {
    const filteredNodes = {};
    this.gridRowModel.forEachNodeAfterFilterAndSort((rowNode) => {
      filteredNodes[rowNode.id] = rowNode;
    });
    return filteredNodes;
  }
  getAllRowNodes() {
    const allRowNodes = [];
    this.gridRowModel.forEachNode((rowNode) => {
      allRowNodes.push(rowNode);
    });
    return this.sortRowNodes(allRowNodes);
  }
  sortRowNodes(rowNodes) {
    const sortOptions = this.sortController.getSortOptions();
    const noSort = !sortOptions || sortOptions.length == 0;
    if (noSort)
      return rowNodes;
    return this.rowNodeSorter.doFullSort(rowNodes, sortOptions);
  }
};
var ChartColumnService = class extends BeanStub8 {
  constructor() {
    super(...arguments);
    this.beanName = "chartColumnService";
    this.valueColsWithoutSeriesType = /* @__PURE__ */ new Set();
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.showRowGroupColsService = beans.showRowGroupColsService;
    this.columnNameService = beans.columnNameService;
    this.visibleColsService = beans.visibleColsService;
    this.funcColsService = beans.funcColsService;
    this.valueService = beans.valueService;
    this.rowPositionUtils = beans.rowPositionUtils;
  }
  postConstruct() {
    const clearValueCols = () => this.valueColsWithoutSeriesType.clear();
    this.addManagedEventListeners({
      newColumnsLoaded: clearValueCols,
      rowDataUpdated: clearValueCols
    });
  }
  getColumn(colId) {
    return this.columnModel.getColDefCol(colId);
  }
  getAllDisplayedColumns() {
    return this.visibleColsService.getAllCols();
  }
  getColDisplayName(col, includePath) {
    const headerLocation = "chart";
    const columnDisplayName = this.columnNameService.getDisplayNameForColumn(col, headerLocation);
    if (includePath) {
      const displayNames = [columnDisplayName];
      const getDisplayName = (colGroup) => {
        if (!colGroup) {
          return;
        }
        const colGroupName = this.columnNameService.getDisplayNameForColumnGroup(colGroup, headerLocation);
        if (!_missingOrEmpty2(colGroupName)) {
          displayNames.unshift(colGroupName);
          getDisplayName(colGroup.getParent());
        }
      };
      getDisplayName(col.getParent());
      return displayNames.join(" - ");
    }
    return columnDisplayName;
  }
  getRowGroupColumns() {
    return this.funcColsService.getRowGroupColumns();
  }
  getGroupDisplayColumns() {
    return this.showRowGroupColsService?.getShowRowGroupCols() ?? [];
  }
  isPivotMode() {
    return this.columnModel.isPivotMode();
  }
  isPivotActive() {
    return this.columnModel.isPivotActive();
  }
  getChartColumns() {
    const gridCols = this.columnModel.getCols();
    const dimensionCols = /* @__PURE__ */ new Set();
    const valueCols = /* @__PURE__ */ new Set();
    gridCols.forEach((col) => {
      const colDef = col.getColDef();
      const chartDataType = colDef.chartDataType;
      if (chartDataType) {
        switch (chartDataType) {
          case "category":
          case "time":
            dimensionCols.add(col);
            return;
          case "series":
            valueCols.add(col);
            return;
          case "excluded":
            return;
          default:
            _warnOnce52(
              `unexpected chartDataType value '${chartDataType}' supplied, instead use 'category', 'series' or 'excluded'`
            );
            break;
        }
      }
      if (colDef.colId === "ag-Grid-AutoColumn") {
        dimensionCols.add(col);
        return;
      }
      if (!col.isPrimary()) {
        valueCols.add(col);
        return;
      }
      (this.isInferredValueCol(col) ? valueCols : dimensionCols).add(col);
    });
    return { dimensionCols, valueCols };
  }
  isInferredValueCol(col) {
    const colId = col.getColId();
    if (colId === "ag-Grid-AutoColumn") {
      return false;
    }
    const row = this.rowPositionUtils.getRowNode({ rowIndex: 0, rowPinned: null });
    if (!row) {
      return this.valueColsWithoutSeriesType.has(colId);
    }
    let cellValue = this.valueService.getValue(col, row);
    if (cellValue == null) {
      cellValue = this.extractLeafData(row, col);
    }
    if (cellValue != null && typeof cellValue.toNumber === "function") {
      cellValue = cellValue.toNumber();
    }
    const isNumber5 = typeof cellValue === "number";
    if (isNumber5) {
      this.valueColsWithoutSeriesType.add(colId);
    }
    return isNumber5;
  }
  extractLeafData(row, col) {
    if (!row.allLeafChildren) {
      return null;
    }
    for (let i = 0; i < row.allLeafChildren.length; i++) {
      const childRow = row.allLeafChildren[i];
      const value = this.valueService.getValue(col, childRow);
      if (value != null) {
        return value;
      }
    }
    return null;
  }
  destroy() {
    this.valueColsWithoutSeriesType.clear();
    super.destroy();
  }
};
var _ComboChartModel = class _ComboChartModel2 extends BeanStub9 {
  constructor(chartDataModel) {
    super();
    this.suppressComboChartWarnings = false;
    this.chartDataModel = chartDataModel;
    this.seriesChartTypes = chartDataModel.params.seriesChartTypes ?? [];
  }
  postConstruct() {
    this.initComboCharts();
  }
  update(seriesChartTypes) {
    this.seriesChartTypes = seriesChartTypes ?? this.seriesChartTypes;
    this.initComboCharts();
    this.updateSeriesChartTypes();
  }
  initComboCharts() {
    const seriesChartTypesExist = this.seriesChartTypes && this.seriesChartTypes.length > 0;
    const customCombo = this.chartDataModel.chartType === "customCombo" || seriesChartTypesExist;
    if (customCombo) {
      this.chartDataModel.chartType = "customCombo";
      this.savedCustomSeriesChartTypes = this.seriesChartTypes || [];
    }
  }
  updateSeriesChartTypes() {
    if (!this.chartDataModel.isComboChart()) {
      return;
    }
    this.seriesChartTypes = this.seriesChartTypes.map((seriesChartType) => {
      const primaryOnly = ["groupedColumn", "stackedColumn", "stackedArea"].includes(seriesChartType.chartType);
      seriesChartType.secondaryAxis = primaryOnly ? false : seriesChartType.secondaryAxis;
      return seriesChartType;
    });
    if (this.chartDataModel.chartType === "customCombo") {
      this.updateSeriesChartTypesForCustomCombo();
      return;
    }
    this.updateChartSeriesTypesForBuiltInCombos();
  }
  updateSeriesChartTypesForCustomCombo() {
    const seriesChartTypesSupplied = this.seriesChartTypes && this.seriesChartTypes.length > 0;
    if (!seriesChartTypesSupplied && !this.suppressComboChartWarnings) {
      _warnOnce62(`'seriesChartTypes' are required when the 'customCombo' chart type is specified.`);
    }
    this.seriesChartTypes = this.seriesChartTypes.map((s) => {
      if (!_ComboChartModel2.SUPPORTED_COMBO_CHART_TYPES.includes(s.chartType)) {
        _warnOnce62(
          `invalid chartType '${s.chartType}' supplied in 'seriesChartTypes', converting to 'line' instead.`
        );
        s.chartType = "line";
      }
      return s;
    });
    const getSeriesChartType = (valueCol) => {
      if (!this.savedCustomSeriesChartTypes || this.savedCustomSeriesChartTypes.length === 0) {
        this.savedCustomSeriesChartTypes = this.seriesChartTypes;
      }
      const providedSeriesChartType = this.savedCustomSeriesChartTypes.find((s) => s.colId === valueCol.colId);
      if (!providedSeriesChartType) {
        if (valueCol.selected && !this.suppressComboChartWarnings) {
          _warnOnce62(`no 'seriesChartType' found for colId = '${valueCol.colId}', defaulting to 'line'.`);
        }
        return {
          colId: valueCol.colId,
          chartType: "line",
          secondaryAxis: false
        };
      }
      return providedSeriesChartType;
    };
    const updatedSeriesChartTypes = this.chartDataModel.valueColState.map(getSeriesChartType);
    this.seriesChartTypes = updatedSeriesChartTypes;
    this.savedCustomSeriesChartTypes = updatedSeriesChartTypes;
    this.suppressComboChartWarnings = true;
  }
  updateChartSeriesTypesForBuiltInCombos() {
    const { chartType, valueColState } = this.chartDataModel;
    const primaryChartType = chartType === "columnLineCombo" ? "groupedColumn" : "stackedArea";
    const secondaryChartType = chartType === "columnLineCombo" ? "line" : "groupedColumn";
    const selectedCols = valueColState.filter((cs) => cs.selected);
    const lineIndex = Math.ceil(selectedCols.length / 2);
    this.seriesChartTypes = selectedCols.map((valueCol, i) => {
      const seriesType = i >= lineIndex ? secondaryChartType : primaryChartType;
      return { colId: valueCol.colId, chartType: seriesType, secondaryAxis: false };
    });
  }
};
_ComboChartModel.SUPPORTED_COMBO_CHART_TYPES = ["line", "groupedColumn", "stackedColumn", "area", "stackedArea"];
var ComboChartModel = _ComboChartModel;
var _ChartDataModel = class _ChartDataModel2 extends BeanStub10 {
  constructor(params) {
    super();
    this.unlinked = false;
    this.chartData = [];
    this.valueColState = [];
    this.dimensionColState = [];
    this.columnNames = {};
    this.crossFiltering = false;
    this.grouping = false;
    this.params = params;
    this.chartId = params.chartId;
    this.setParams(params);
  }
  wireBeans(beans) {
    this.rangeService = beans.rangeService;
    this.chartTranslationService = beans.chartTranslationService;
  }
  setParams(params) {
    const {
      chartType,
      pivotChart,
      chartThemeName,
      switchCategorySeries,
      aggFunc,
      cellRange,
      suppressChartRanges,
      unlinkChart,
      crossFiltering,
      seriesGroupType
    } = params;
    this.chartType = chartType;
    this.pivotChart = pivotChart ?? false;
    this.chartThemeName = chartThemeName;
    this.switchCategorySeries = !!switchCategorySeries;
    this.aggFunc = aggFunc;
    this.referenceCellRange = cellRange;
    this.suppliedCellRange = cellRange;
    this.suppressChartRanges = suppressChartRanges ?? false;
    this.unlinked = !!unlinkChart;
    this.crossFiltering = !!crossFiltering;
    this.seriesGroupType = seriesGroupType;
  }
  postConstruct() {
    this.datasource = this.createManagedBean(new ChartDatasource());
    this.chartColumnService = this.createManagedBean(new ChartColumnService());
    this.comboChartModel = this.createManagedBean(new ComboChartModel(this));
    this.updateCellRanges({ setColsFromRange: true });
    this.updateData();
  }
  updateModel(params) {
    const { cellRange, seriesChartTypes } = params;
    if (cellRange !== this.suppliedCellRange) {
      this.dimensionCellRange = void 0;
      this.valueCellRange = void 0;
    }
    this.setParams(params);
    this.updateSelectedDimensions(cellRange?.columns);
    this.updateCellRanges({ setColsFromRange: true });
    const shouldUpdateComboModel = this.isComboChart() || seriesChartTypes;
    if (shouldUpdateComboModel) {
      this.comboChartModel.update(seriesChartTypes);
    }
    if (!this.unlinked) {
      this.updateData();
    }
  }
  updateCellRanges(params) {
    const { updatedColState, resetOrder, maintainColState, setColsFromRange } = params ?? {};
    if (this.valueCellRange) {
      this.referenceCellRange = this.valueCellRange;
    }
    const { dimensionCols, valueCols } = this.chartColumnService.getChartColumns();
    const allColsFromRanges = this.getAllColumnsFromRanges();
    if (updatedColState) {
      this.updateColumnState(updatedColState, resetOrder);
    }
    this.setDimensionCellRange(dimensionCols, allColsFromRanges, updatedColState);
    this.setValueCellRange(valueCols, allColsFromRanges, setColsFromRange);
    if (!updatedColState && !maintainColState) {
      this.resetColumnState();
      this.syncDimensionCellRange();
    }
    this.comboChartModel.updateSeriesChartTypes();
  }
  updateData() {
    const { startRow, endRow } = this.getRowIndexes();
    if (this.pivotChart) {
      this.resetColumnState();
    }
    this.grouping = this.isGrouping();
    const params = {
      aggFunc: this.aggFunc,
      dimensionCols: this.getSelectedDimensions(),
      grouping: this.grouping,
      pivoting: this.isPivotActive(),
      crossFiltering: this.crossFiltering,
      valueCols: this.getSelectedValueCols(),
      startRow,
      endRow,
      isScatter: _includes4(["scatter", "bubble"], this.chartType)
    };
    const { chartData, columnNames, groupChartData } = this.datasource.getData(params);
    this.chartData = chartData;
    this.groupChartData = groupChartData;
    this.columnNames = columnNames;
    this.categoryAxisType = void 0;
  }
  isGrouping() {
    const usingTreeData = this.gos.get("treeData");
    const groupedCols = usingTreeData ? null : this.chartColumnService.getRowGroupColumns();
    const isGroupActive = usingTreeData || groupedCols && groupedCols.length > 0;
    const colIds = this.getSelectedDimensions().map(({ colId }) => colId);
    const displayedGroupCols = this.chartColumnService.getGroupDisplayColumns();
    const groupDimensionSelected = displayedGroupCols.map((col) => col.getColId()).some((id) => colIds.includes(id));
    return !!isGroupActive && groupDimensionSelected;
  }
  getSelectedValueCols() {
    return this.valueColState.filter((cs) => cs.selected).map((cs) => cs.column);
  }
  getSelectedDimensions() {
    return this.dimensionColState.filter((cs) => cs.selected);
  }
  getColDisplayName(col, includePath) {
    return this.chartColumnService.getColDisplayName(col, includePath);
  }
  isPivotMode() {
    return this.chartColumnService.isPivotMode();
  }
  getChartDataType(colId) {
    const column = this.chartColumnService.getColumn(colId);
    return column ? column.getColDef().chartDataType : void 0;
  }
  isPivotActive() {
    return this.chartColumnService.isPivotActive();
  }
  createCellRange(type, ...columns) {
    return {
      id: this.chartId,
      // set range ID to match chart ID so we can identify changes to the ranges for this chart
      startRow: this.referenceCellRange.startRow,
      endRow: this.referenceCellRange.endRow,
      columns,
      startColumn: type === CellRangeType2.DIMENSION || this.referenceCellRange.startColumn == null ? columns[0] : this.referenceCellRange.startColumn,
      type
    };
  }
  getAllColumnsFromRanges() {
    if (this.pivotChart) {
      return new Set(this.chartColumnService.getAllDisplayedColumns());
    }
    const columns = this.dimensionCellRange || this.valueCellRange ? [] : this.referenceCellRange.columns;
    if (this.dimensionCellRange) {
      columns.push(...this.dimensionCellRange.columns);
    }
    if (this.valueCellRange) {
      columns.push(...this.valueCellRange.columns);
    }
    return new Set(columns);
  }
  getRowIndexes() {
    let startRow = 0, endRow = 0;
    const { rangeService, valueCellRange, dimensionCellRange } = this;
    const cellRange = valueCellRange || dimensionCellRange;
    if (rangeService && cellRange) {
      startRow = rangeService.getRangeStartRow(cellRange).rowIndex;
      const endRowPosition = rangeService.getRangeEndRow(cellRange);
      endRow = endRowPosition.rowPinned === "bottom" ? -1 : endRowPosition.rowIndex;
    }
    return { startRow, endRow };
  }
  resetColumnState() {
    const { dimensionCols, valueCols } = this.chartColumnService.getChartColumns();
    const allCols = this.getAllColumnsFromRanges();
    const isInitialising = this.valueColState.length < 1;
    this.dimensionColState = [];
    this.valueColState = [];
    const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
    let hasSelectedDimension = false;
    let order = 1;
    const aggFuncDimension = this.suppliedCellRange.columns[0];
    dimensionCols.forEach((column) => {
      const isAutoGroupCol = column.getColId() === "ag-Grid-AutoColumn";
      let selected = false;
      if (this.crossFiltering && this.aggFunc) {
        if (aggFuncDimension.getColId() === column.getColId()) {
          selected = true;
        }
      } else {
        selected = isAutoGroupCol ? true : (!hasSelectedDimension || supportsMultipleDimensions) && allCols.has(column);
      }
      this.dimensionColState.push({
        column,
        colId: column.getColId(),
        displayName: this.getColDisplayName(column),
        selected,
        order: order++
      });
      if (selected) {
        hasSelectedDimension = true;
      }
    });
    const defaultCategory = {
      colId: _ChartDataModel2.DEFAULT_CATEGORY,
      displayName: this.chartTranslationService.translate("defaultCategory"),
      selected: !hasSelectedDimension,
      // if no dimensions in range select the default
      order: 0
    };
    this.dimensionColState.unshift(defaultCategory);
    const valueColumnsFromReferenceRange = this.referenceCellRange.columns.filter(
      (c) => valueCols.has(c)
    );
    valueCols.forEach((column) => {
      if (isInitialising && _includes4(this.referenceCellRange.columns, column)) {
        column = valueColumnsFromReferenceRange.shift();
      }
      this.valueColState.push({
        column,
        colId: column.getColId(),
        displayName: this.getColDisplayName(column),
        selected: allCols.has(column),
        order: order++
      });
    });
  }
  updateColumnState(updatedCol, resetOrder) {
    const idsMatch = (cs) => cs.colId === updatedCol.colId;
    const { dimensionColState, valueColState } = this;
    const matchedDimensionColState = dimensionColState.find(idsMatch);
    const matchedValueColState = valueColState.find(idsMatch);
    if (matchedDimensionColState) {
      const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
      if (!supportsMultipleDimensions) {
        const selectedColumnState = updatedCol.selected ? matchedDimensionColState : dimensionColState.filter((cs) => cs !== matchedDimensionColState).find(({ selected }) => selected);
        dimensionColState.forEach((cs) => cs.selected = cs === selectedColumnState);
      } else {
        matchedDimensionColState.selected = updatedCol.selected;
      }
    } else if (matchedValueColState) {
      matchedValueColState.selected = updatedCol.selected;
    }
    const allColumns = [...dimensionColState, ...valueColState];
    const orderedColIds = [];
    if (!resetOrder) {
      allColumns.forEach((col, i) => {
        if (i === updatedCol.order) {
          orderedColIds.push(updatedCol.colId);
        }
        if (col.colId !== updatedCol.colId) {
          orderedColIds.push(col.colId);
        }
      });
      allColumns.forEach((col) => {
        const order = orderedColIds.indexOf(col.colId);
        col.order = order >= 0 ? orderedColIds.indexOf(col.colId) : allColumns.length - 1;
      });
    }
    this.reorderColState();
  }
  reorderColState() {
    const ascColStateOrder = (a, b) => a.order - b.order;
    this.dimensionColState.sort(ascColStateOrder);
    this.valueColState.sort(ascColStateOrder);
  }
  setDimensionCellRange(dimensionCols, colsInRange, updatedColState) {
    this.dimensionCellRange = void 0;
    const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
    if (!updatedColState && !this.dimensionColState.length) {
      const selectedCols = new Array();
      dimensionCols.forEach((col) => {
        if (selectedCols.length > 0 && !supportsMultipleDimensions || !colsInRange.has(col)) {
          return;
        }
        selectedCols.push(col);
      });
      if (selectedCols.length > 0) {
        this.dimensionCellRange = this.createCellRange(CellRangeType2.DIMENSION, ...selectedCols);
      }
      return;
    }
    let selectedDimensionColStates = updatedColState ? [updatedColState] : [];
    if (this.crossFiltering && this.aggFunc) {
      const aggFuncDimension = this.suppliedCellRange.columns[0];
      selectedDimensionColStates = this.dimensionColState.filter(
        (cs) => cs.colId === aggFuncDimension.getColId()
      );
    } else if (supportsMultipleDimensions || selectedDimensionColStates.length === 0 || selectedDimensionColStates.some(({ column }) => !column || !dimensionCols.has(column))) {
      selectedDimensionColStates = this.dimensionColState.filter((cs) => cs.selected);
    }
    const isDefaultCategory = selectedDimensionColStates.length === 1 ? selectedDimensionColStates[0].colId === _ChartDataModel2.DEFAULT_CATEGORY : false;
    const selectedColumns = selectedDimensionColStates.map(({ column }) => column).filter((value) => value != null);
    if (selectedColumns.length > 0 && !isDefaultCategory) {
      this.dimensionCellRange = this.createCellRange(CellRangeType2.DIMENSION, ...selectedColumns);
    }
  }
  setValueCellRange(valueCols, colsInRange, setColsFromRange) {
    this.valueCellRange = void 0;
    const selectedValueCols = [];
    const maxSelection = getMaxNumSeries(this.chartType);
    let numSelected = 0;
    valueCols.forEach((col) => {
      if (setColsFromRange) {
        if ((maxSelection == null || numSelected < maxSelection) && colsInRange.has(col)) {
          selectedValueCols.push(col);
          numSelected++;
        }
      } else {
        if (this.valueColState.some((colState) => colState.selected && colState.colId === col.getColId())) {
          selectedValueCols.push(col);
        }
      }
    });
    if (selectedValueCols.length > 0) {
      let orderedColIds = [];
      if (this.valueColState.length > 0) {
        orderedColIds = this.valueColState.map((c) => c.colId);
      } else {
        colsInRange.forEach((c) => orderedColIds.push(c.getColId()));
      }
      selectedValueCols.sort((a, b) => orderedColIds.indexOf(a.getColId()) - orderedColIds.indexOf(b.getColId()));
      this.valueCellRange = this.createCellRange(CellRangeType2.VALUE, ...selectedValueCols);
    }
  }
  resetCellRanges(dimension, value) {
    if (!dimension && !value) {
      return;
    }
    const { dimensionCols, valueCols } = this.chartColumnService.getChartColumns();
    const allColsFromRanges = this.getAllColumnsFromRanges();
    if (dimension) {
      this.setDimensionCellRange(dimensionCols, allColsFromRanges);
    }
    if (value) {
      this.setValueCellRange(valueCols, allColsFromRanges);
    }
  }
  updateSelectedDimensions(columns) {
    const colIdSet = new Set(columns.map((column) => column.getColId()));
    const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
    if (!supportsMultipleDimensions) {
      const foundColState = this.dimensionColState.find((colState) => colIdSet.has(colState.colId)) || this.dimensionColState[0];
      const selectedColumnId = foundColState.colId;
      this.dimensionColState = this.dimensionColState.map((colState) => ({
        ...colState,
        selected: colState.colId === selectedColumnId
      }));
    } else {
      const foundColStates = this.dimensionColState.filter((colState) => colIdSet.has(colState.colId));
      const selectedColumnIds = new Set(foundColStates.map((colState) => colState.colId));
      this.dimensionColState = this.dimensionColState.map((colState) => ({
        ...colState,
        selected: selectedColumnIds.has(colState.colId)
      }));
    }
  }
  syncDimensionCellRange() {
    const selectedDimensions = this.getSelectedDimensions();
    if (selectedDimensions.length === 0)
      return;
    const selectedCols = selectedDimensions.map(({ column }) => column).filter((value) => value != null);
    if (selectedCols.length > 0) {
      this.dimensionCellRange = this.createCellRange(CellRangeType2.DIMENSION, ...selectedCols);
    }
  }
  isComboChart(chartType) {
    return isComboChart(chartType ?? this.chartType);
  }
};
_ChartDataModel.DEFAULT_CATEGORY = "AG-GRID-DEFAULT-CATEGORY";
var ChartDataModel = _ChartDataModel;
var validateIfDefined = (validationFn) => {
  return (value) => {
    if (value == void 0)
      return true;
    return validationFn(value);
  };
};
var isString = (value) => typeof value === "string";
var isBoolean = (value) => typeof value === "boolean";
var isValidSeriesChartType = (value) => typeof value === "object";
var createWarnMessage = (property, expectedType) => (value) => `AG Grid - unable to update chart as invalid params supplied:  \`${property}: ${value}\`, expected ${expectedType}.`;
var createEnterpriseMessage = (feature) => {
  const url = "https://www.ag-grid.com/javascript-data-grid/integrated-charts-installation/";
  return `${feature} is not supported in AG Charts Community (either 'ag-grid-charts-enterprise' or '@ag-grid-enterprise/charts-enterprise' hasn't been loaded). See ${url} for more details.`;
};
var _ChartParamsValidator = class _ChartParamsValidator2 {
  static isEnterprise() {
    return _ModuleSupport.enterpriseModule.isEnterprise;
  }
  static isValidChartType(value) {
    return !!getSeriesTypeIfExists(value) || isComboChart(value);
  }
  static isLegacyChartType(value) {
    return _ChartParamsValidator2.legacyChartTypes.includes(value);
  }
  static validateUpdateParams(params) {
    const paramsToValidate = params;
    switch (paramsToValidate.type) {
      case "rangeChartUpdate":
        return _ChartParamsValidator2.validateUpdateRangeChartParams(params);
      case "pivotChartUpdate":
        return _ChartParamsValidator2.validateUpdatePivotChartParams(params);
      case "crossFilterChartUpdate":
        return _ChartParamsValidator2.validateUpdateCrossFilterChartParams(
          params
        );
      default:
        _warnOnce72(
          `Invalid value supplied for 'type': ${params.type}. It must be either 'rangeChartUpdate', 'pivotChartUpdate', or 'crossFilterChartUpdate'.`
        );
        return false;
    }
  }
  static validateCreateParams(params) {
    return validateProperties(params, [
      _ChartParamsValidator2.enterpriseChartTypeValidation,
      _ChartParamsValidator2.switchCategorySeriesValidation
    ]);
  }
  static validateUpdateRangeChartParams(params) {
    const validations = [
      ..._ChartParamsValidator2.commonUpdateValidations,
      _ChartParamsValidator2.enterpriseChartTypeValidation,
      ..._ChartParamsValidator2.cellRangeValidations,
      {
        property: "seriesChartTypes",
        validationFn: (value) => value === void 0 || Array.isArray(value) && value.every(isValidSeriesChartType),
        warnMessage: createWarnMessage("seriesChartTypes", "Array of SeriesChartType")
      }
    ];
    return validateProperties(
      params,
      validations,
      [
        ..._ChartParamsValidator2.baseUpdateChartParams,
        "cellRange",
        "suppressChartRanges",
        "switchCategorySeries",
        "aggFunc",
        "seriesChartTypes",
        "seriesGroupType"
      ],
      "UpdateRangeChartParams"
    );
  }
  static validateUpdatePivotChartParams(params) {
    const validations = [..._ChartParamsValidator2.commonUpdateValidations];
    return validateProperties(
      params,
      validations,
      [..._ChartParamsValidator2.baseUpdateChartParams],
      "UpdatePivotChartParams"
    );
  }
  static validateUpdateCrossFilterChartParams(params) {
    const validations = [
      ..._ChartParamsValidator2.commonUpdateValidations,
      ..._ChartParamsValidator2.cellRangeValidations
    ];
    return validateProperties(
      params,
      validations,
      [..._ChartParamsValidator2.baseUpdateChartParams, "cellRange", "suppressChartRanges", "aggFunc"],
      "UpdateCrossFilterChartParams"
    );
  }
};
_ChartParamsValidator.legacyChartTypes = ["doughnut"];
_ChartParamsValidator.baseUpdateChartParams = [
  "type",
  "chartId",
  "chartType",
  "chartThemeName",
  "chartThemeOverrides",
  "unlinkChart"
];
_ChartParamsValidator.validateChartType = validateIfDefined((chartType) => {
  if (_ChartParamsValidator.isValidChartType(chartType))
    return true;
  if (_ChartParamsValidator.isLegacyChartType(chartType)) {
    const renamedChartType = getCanonicalChartType(chartType);
    _warnOnce72(`The chart type '${chartType}' has been deprecated. Please use '${renamedChartType}' instead.`);
    return renamedChartType;
  }
  return false;
});
_ChartParamsValidator.validateAgChartThemeOverrides = validateIfDefined((themeOverrides) => {
  return typeof themeOverrides === "object";
});
_ChartParamsValidator.validateChartParamsCellRange = validateIfDefined((cellRange) => {
  return typeof cellRange === "object";
});
_ChartParamsValidator.validateAggFunc = validateIfDefined((aggFunc) => {
  return typeof aggFunc === "string" || typeof aggFunc === "function";
});
_ChartParamsValidator.enterpriseChartTypeValidation = {
  property: "chartType",
  validationFn: validateIfDefined(
    (chartType) => _ChartParamsValidator.isEnterprise() || !chartType || !isEnterpriseChartType(chartType)
  ),
  warnMessage: (chartType) => createEnterpriseMessage(`The '${chartType}' chart type`)
};
_ChartParamsValidator.switchCategorySeriesValidation = {
  property: "switchCategorySeries",
  validationFn: validateIfDefined((switchCategorySeries) => {
    if (!switchCategorySeries || _ChartParamsValidator.isEnterprise()) {
      return true;
    }
    return void 0;
  }),
  warnMessage: () => createEnterpriseMessage(`'switchCategorySeries' has been ignored as it`),
  warnIfFixed: true
};
_ChartParamsValidator.commonUpdateValidations = [
  { property: "chartId", validationFn: isString, warnMessage: createWarnMessage("chartId", "string") },
  {
    property: "chartType",
    validationFn: _ChartParamsValidator.validateChartType,
    warnMessage: createWarnMessage("chartType", "ChartType")
  },
  {
    property: "chartThemeName",
    validationFn: isString,
    warnMessage: createWarnMessage("chartThemeName", "string")
  },
  {
    property: "chartThemeOverrides",
    validationFn: _ChartParamsValidator.validateAgChartThemeOverrides,
    warnMessage: createWarnMessage("chartThemeOverrides", "AgChartThemeOverrides")
  },
  { property: "unlinkChart", validationFn: isBoolean, warnMessage: createWarnMessage("unlinkChart", "boolean") }
];
_ChartParamsValidator.cellRangeValidations = [
  {
    property: "cellRange",
    validationFn: _ChartParamsValidator.validateChartParamsCellRange,
    warnMessage: createWarnMessage("cellRange", "ChartParamsCellRange")
  },
  {
    property: "suppressChartRanges",
    validationFn: isBoolean,
    warnMessage: createWarnMessage("suppressChartRanges", "boolean")
  },
  {
    property: "aggFunc",
    validationFn: _ChartParamsValidator.validateAggFunc,
    warnMessage: createWarnMessage("aggFunc", "string or IAggFunc")
  },
  _ChartParamsValidator.switchCategorySeriesValidation
];
var ChartParamsValidator = _ChartParamsValidator;
function validateProperties(params, validations, validPropertyNames, paramsType) {
  let validatedProperties = void 0;
  for (const validation of validations) {
    const { property, validationFn, warnMessage, warnIfFixed } = validation;
    if (property in params) {
      const value = params[property];
      const validationResult = validationFn(value);
      if (validationResult === true)
        continue;
      if (validationResult === false) {
        _warnOnce72(warnMessage(value));
        return false;
      }
      validatedProperties = validatedProperties || { ...params };
      validatedProperties[property] = validationResult;
      if (warnIfFixed) {
        _warnOnce72(warnMessage(value));
      }
    }
  }
  if (validPropertyNames) {
    for (const property in params) {
      if (!validPropertyNames.includes(property)) {
        _warnOnce72(`Unexpected property supplied. ${paramsType} does not contain: \`${property}\`.`);
        return false;
      }
    }
  }
  if (validatedProperties)
    return validatedProperties;
  return true;
}
var DEFAULT_THEMES = ["ag-default", "ag-material", "ag-sheets", "ag-polychroma", "ag-vivid"];
var ChartController = class extends BeanStub11 {
  constructor(model) {
    super();
    this.model = model;
    this.isEnterprise = () => _ModuleSupport2.enterpriseModule.isEnterprise;
  }
  wireBeans(beans) {
    this.rangeService = beans.rangeService;
  }
  postConstruct() {
    this.setChartRange();
    if (this.model.unlinked) {
      if (this.rangeService) {
        this.rangeService.setCellRanges([]);
      }
    }
    const listener = this.updateForGridChange.bind(this, {});
    this.addManagedEventListeners({
      cellSelectionChanged: (event) => {
        if (event.id && event.id === this.model.chartId) {
          this.updateForRangeChange();
        }
      },
      columnMoved: listener,
      columnPinned: listener,
      columnVisible: listener,
      columnRowGroupChanged: listener,
      modelUpdated: listener,
      cellValueChanged: this.updateForDataChange.bind(this)
    });
  }
  update(params) {
    if (!this.validUpdateType(params))
      return false;
    const validationResult = ChartParamsValidator.validateUpdateParams(params);
    if (!validationResult)
      return false;
    const validParams = validationResult === true ? params : validationResult;
    this.applyValidatedChartParams(validParams);
    return true;
  }
  applyValidatedChartParams(params) {
    const { chartId, chartType, chartThemeName, unlinkChart } = params;
    const common = {
      chartId,
      pivotChart: this.model.pivotChart,
      chartType: chartType ?? this.model.chartType,
      chartThemeName: chartThemeName ?? this.model.chartThemeName,
      unlinkChart: unlinkChart ?? this.model.unlinked,
      cellRange: this.model.suppliedCellRange,
      switchCategorySeries: this.model.switchCategorySeries,
      aggFunc: this.model.aggFunc,
      seriesChartTypes: void 0,
      suppressChartRanges: false,
      crossFiltering: false
    };
    const chartModelParams = { ...common };
    switch (params.type) {
      case "rangeChartUpdate":
        chartModelParams.cellRange = this.createCellRange(params) ?? this.model.suppliedCellRange;
        chartModelParams.switchCategorySeries = params.switchCategorySeries ?? this.model.switchCategorySeries;
        chartModelParams.aggFunc = params.aggFunc ?? this.model.aggFunc;
        chartModelParams.seriesChartTypes = params.seriesChartTypes;
        chartModelParams.suppressChartRanges = params.suppressChartRanges ?? this.model.suppressChartRanges;
        chartModelParams.seriesGroupType = params.seriesGroupType ?? this.model.seriesGroupType;
        break;
      case "crossFilterChartUpdate":
        chartModelParams.cellRange = this.createCellRange(params) ?? this.model.suppliedCellRange;
        chartModelParams.switchCategorySeries = false;
        chartModelParams.aggFunc = params.aggFunc ?? this.model.aggFunc;
        chartModelParams.crossFiltering = true;
        chartModelParams.suppressChartRanges = params.suppressChartRanges ?? this.model.suppressChartRanges;
        break;
      case "pivotChartUpdate":
        chartModelParams.switchCategorySeries = false;
        break;
    }
    this.model.updateModel(chartModelParams);
    const removeChartCellRanges = chartModelParams.unlinkChart || chartModelParams.suppressChartRanges;
    removeChartCellRanges ? this.rangeService?.setCellRanges([]) : this.setChartRange();
  }
  updateForGridChange(params) {
    if (this.model.unlinked) {
      return;
    }
    const { maintainColState, setColsFromRange } = params ?? {};
    this.model.updateCellRanges({ maintainColState, setColsFromRange });
    this.model.updateData();
    this.setChartRange();
  }
  updateForDataChange() {
    if (this.model.unlinked) {
      return;
    }
    this.model.updateData();
    this.raiseChartModelUpdateEvent();
  }
  updateForRangeChange() {
    this.updateForGridChange({ setColsFromRange: true });
    this.raiseChartRangeSelectionChangedEvent();
  }
  updateForPanelChange(params) {
    this.model.updateCellRanges(params);
    this.model.updateData();
    if (params.skipAnimation) {
      this.getChartProxy().getChartRef().skipAnimations();
    }
    this.setChartRange();
    this.raiseChartRangeSelectionChangedEvent();
  }
  updateThemeOverrides(updatedOverrides) {
    this.chartProxy.updateThemeOverrides(updatedOverrides);
  }
  getChartUpdateParams(updatedOverrides) {
    const selectedCols = this.getSelectedValueColState();
    const fields = selectedCols.map((c) => ({ colId: c.colId, displayName: c.displayName }));
    const data = this.getChartData();
    const selectedDimensions = this.getSelectedDimensions();
    const params = {
      data,
      groupData: this.model.groupChartData,
      grouping: this.isGrouping(),
      categories: selectedDimensions.map((selectedDimension) => ({
        id: selectedDimension.colId,
        name: selectedDimension.displayName,
        chartDataType: this.model.categoryAxisType ?? this.model.getChartDataType(selectedDimension.colId)
      })),
      fields,
      chartId: this.getChartId(),
      getCrossFilteringContext: () => ({ lastSelectedChartId: "xxx" }),
      //this.params.crossFilteringContext, //TODO
      seriesChartTypes: this.getSeriesChartTypes(),
      updatedOverrides,
      seriesGroupType: this.model.seriesGroupType
    };
    return this.isCategorySeriesSwitched() ? this.invertCategorySeriesParams(params) : params;
  }
  invertCategorySeriesParams(params) {
    const [category] = params.categories;
    const categories = [{ id: ChartDataModel.DEFAULT_CATEGORY, name: "" }];
    const fields = params.data.map((value, index) => {
      const categoryKey = `${category.id}:${index}`;
      const categoryValue = value[category.id];
      const seriesLabel = categoryValue == null ? "" : String(categoryValue);
      return { colId: categoryKey, displayName: seriesLabel };
    });
    const data = params.fields.map((field) => {
      const row = {
        [ChartDataModel.DEFAULT_CATEGORY]: field.displayName
      };
      for (const [index, value] of params.data.entries()) {
        const categoryKey = `${category.id}:${index}`;
        const seriesLabelValue = value[field.colId];
        row[categoryKey] = seriesLabelValue;
      }
      return row;
    });
    return {
      ...params,
      categories,
      fields,
      data
    };
  }
  getChartModel() {
    const modelType = this.model.pivotChart ? "pivot" : "range";
    const seriesChartTypes = this.isComboChart() ? this.model.comboChartModel.seriesChartTypes : void 0;
    return {
      modelType,
      chartId: this.model.chartId,
      chartType: this.model.chartType,
      chartThemeName: this.getChartThemeName(),
      chartOptions: this.chartProxy.getChartThemeOverrides(),
      chartPalette: this.chartProxy.getChartPalette(),
      cellRange: this.getCellRangeParams(),
      switchCategorySeries: this.model.switchCategorySeries,
      suppressChartRanges: this.model.suppressChartRanges,
      aggFunc: this.model.aggFunc,
      unlinkChart: this.model.unlinked,
      seriesChartTypes,
      seriesGroupType: this.model.seriesGroupType
    };
  }
  getChartId() {
    return this.model.chartId;
  }
  getChartData() {
    return this.model.chartData;
  }
  getChartType() {
    return this.model.chartType;
  }
  setChartType(chartType) {
    this.updateMultiSeriesAndCategory(this.model.chartType, chartType);
    this.model.chartType = chartType;
    this.model.comboChartModel.updateSeriesChartTypes();
    this.model.switchCategorySeries = false;
    this.model.categoryAxisType = void 0;
    this.model.seriesGroupType = void 0;
    this.raiseChartModelUpdateEvent();
    this.raiseChartOptionsChangedEvent();
  }
  isCategorySeriesSwitched() {
    return this.model.switchCategorySeries && !this.model.isGrouping();
  }
  switchCategorySeries(inverted) {
    if (!supportsInvertedCategorySeries(this.getChartType()))
      return;
    this.model.switchCategorySeries = inverted;
    this.raiseChartModelUpdateEvent();
  }
  getAggFunc() {
    return this.model.aggFunc;
  }
  setAggFunc(value, silent) {
    if (this.model.aggFunc === value)
      return;
    this.model.aggFunc = value;
    if (silent)
      return;
    this.model.updateData();
    this.raiseChartModelUpdateEvent();
  }
  updateMultiSeriesAndCategory(previousChartType, chartType) {
    const updateForMax = (columns, maxNum) => {
      let numSelected = 0;
      for (const colState of columns) {
        if (!colState.selected)
          continue;
        if (numSelected >= maxNum) {
          colState.selected = false;
        } else {
          numSelected++;
        }
      }
      if (numSelected === 0) {
        columns[0].selected = true;
      }
    };
    const maxNumDimensions = getMaxNumCategories(chartType);
    const maxNumSeries = getMaxNumSeries(chartType);
    const updateDimensionColState = maxNumDimensions != null && (getMaxNumCategories(previousChartType) ?? 100) > (maxNumDimensions ?? 100);
    const updateValueColState = maxNumSeries != null && (getMaxNumSeries(previousChartType) ?? 100) > (maxNumSeries ?? 100);
    if (updateDimensionColState) {
      updateForMax(this.model.dimensionColState, maxNumDimensions);
    }
    if (updateValueColState) {
      updateForMax(this.model.valueColState, maxNumSeries);
    }
    if (updateDimensionColState || updateValueColState) {
      this.model.resetCellRanges(updateDimensionColState, updateValueColState);
      this.setChartRange(true);
    }
  }
  setChartThemeName(chartThemeName, silent) {
    this.model.chartThemeName = chartThemeName;
    if (!silent) {
      this.raiseChartModelUpdateEvent();
      this.raiseChartOptionsChangedEvent();
    }
  }
  getChartThemeName() {
    return this.model.chartThemeName;
  }
  isPivotChart() {
    return this.model.pivotChart;
  }
  isPivotMode() {
    return this.model.isPivotMode();
  }
  isGrouping() {
    return this.model.isGrouping();
  }
  isCrossFilterChart() {
    return this.model.crossFiltering;
  }
  getThemeNames() {
    return this.gos.get("chartThemes") || DEFAULT_THEMES;
  }
  getThemes() {
    const themeNames = this.getThemeNames();
    return themeNames.map((themeName) => {
      const stockTheme = isStockTheme(themeName);
      const theme = stockTheme ? themeName : this.chartProxy.lookupCustomChartTheme(themeName);
      return _Theme2.getChartTheme(theme);
    });
  }
  getPalettes() {
    const themes = this.getThemes();
    return themes.map((theme) => {
      return theme.palette;
    });
  }
  getThemeTemplateParameters() {
    const themes = this.getThemes();
    return themes.map((theme) => {
      return theme.getTemplateParameters();
    });
  }
  getValueColState() {
    return this.model.valueColState.map(this.displayNameMapper.bind(this));
  }
  getSelectedValueColState() {
    return this.getValueColState().filter((cs) => cs.selected);
  }
  getSelectedDimensions() {
    return this.model.getSelectedDimensions();
  }
  displayNameMapper(col) {
    const { column } = col;
    if (column) {
      col.displayName = this.model.getColDisplayName(column, this.model.isPivotMode());
    } else {
      const columnNames = this.model.columnNames[col.colId];
      col.displayName = columnNames ? columnNames.join(" - ") : this.model.getColDisplayName(column);
    }
    return col;
  }
  getColStateForMenu() {
    return { dimensionCols: this.model.dimensionColState, valueCols: this.getValueColState() };
  }
  setChartRange(silent = false) {
    if (this.rangeService && !this.model.suppressChartRanges && !this.model.unlinked) {
      this.rangeService.setCellRanges(this.getCellRanges());
    }
    if (!silent) {
      this.raiseChartModelUpdateEvent();
    }
  }
  detachChartRange() {
    this.model.unlinked = !this.model.unlinked;
    if (this.model.unlinked) {
      if (this.rangeService) {
        this.rangeService.setCellRanges([]);
      }
    } else {
      this.updateForGridChange();
    }
    this.dispatchLocalEvent({ type: "chartLinkedChanged" });
  }
  setChartProxy(chartProxy) {
    this.chartProxy = chartProxy;
  }
  getChartProxy() {
    return this.chartProxy;
  }
  isActiveXYChart() {
    return ["scatter", "bubble"].includes(this.getChartType());
  }
  isChartLinked() {
    return !this.model.unlinked;
  }
  customComboExists() {
    const savedCustomSeriesChartTypes = this.model.comboChartModel.savedCustomSeriesChartTypes;
    return savedCustomSeriesChartTypes && savedCustomSeriesChartTypes.length > 0;
  }
  getSeriesChartTypes() {
    return this.model.comboChartModel.seriesChartTypes;
  }
  isComboChart(chartType) {
    return this.model.isComboChart(chartType);
  }
  updateSeriesChartType(colId, chartType, secondaryAxis) {
    const seriesChartType = this.model.comboChartModel.seriesChartTypes.find((s) => s.colId === colId);
    if (seriesChartType) {
      const updateChartType = this.model.chartType !== "customCombo";
      if (updateChartType) {
        this.model.chartType = "customCombo";
      }
      const prevSeriesChartType = seriesChartType.chartType;
      if (chartType != null) {
        seriesChartType.chartType = chartType;
      }
      if (secondaryAxis != null) {
        seriesChartType.secondaryAxis = secondaryAxis;
      }
      this.model.comboChartModel.savedCustomSeriesChartTypes = this.model.comboChartModel.seriesChartTypes;
      this.model.comboChartModel.updateSeriesChartTypes();
      this.updateForDataChange();
      if (updateChartType) {
        this.dispatchLocalEvent({
          type: "chartTypeChanged"
        });
      }
      if (prevSeriesChartType !== chartType) {
        this.dispatchLocalEvent({
          type: "chartSeriesChartTypeChanged"
        });
      }
      this.raiseChartOptionsChangedEvent();
    }
  }
  getActiveSeriesChartTypes() {
    const selectedColIds = this.getSelectedValueColState().map((c) => c.colId);
    return this.getSeriesChartTypes().filter((s) => selectedColIds.includes(s.colId));
  }
  getChartSeriesTypes(chartType) {
    const targetChartType = chartType ?? this.getChartType();
    return this.isComboChart(targetChartType) ? ["line", "bar", "area"] : [getSeriesType(targetChartType)];
  }
  getChartSeriesType() {
    const seriesChartTypes = this.getSeriesChartTypes();
    if (seriesChartTypes.length === 0) {
      return "bar";
    }
    const ct = seriesChartTypes[0].chartType;
    if (ct === "columnLineCombo") {
      return "bar";
    }
    if (ct === "areaColumnCombo") {
      return "area";
    }
    return getSeriesType(ct);
  }
  getCellRanges() {
    return [this.model.dimensionCellRange, this.model.valueCellRange].filter((r) => r);
  }
  createCellRange(params) {
    return params.cellRange && this.rangeService?.createPartialCellRangeFromRangeParams(params.cellRange, true);
  }
  validUpdateType(params) {
    if (!params.type) {
      _warnOnce8(
        `Unable to update chart as the 'type' is missing. It must be either 'rangeChartUpdate', 'pivotChartUpdate', or 'crossFilterChartUpdate'.`
      );
      return false;
    }
    const chartTypeMap = {
      "Range Chart": () => !this.isPivotChart() && !this.isCrossFilterChart(),
      "Pivot Chart": () => this.isPivotChart(),
      "Cross Filter Chart": () => this.isCrossFilterChart()
    };
    const currentChartType = Object.keys(chartTypeMap).find((type) => chartTypeMap[type]()) ?? "Range Chart";
    const valid = params.type === `${currentChartType[0].toLowerCase()}${currentChartType.slice(1).replace(/ /g, "")}Update`;
    if (!valid) {
      _warnOnce8(
        `Unable to update chart as a '${params.type}' update type is not permitted on a ${currentChartType}.`
      );
    }
    return valid;
  }
  getCellRangeParams() {
    const cellRanges = this.getCellRanges();
    const firstCellRange = cellRanges[0];
    const startRow = firstCellRange && firstCellRange.startRow || null;
    const endRow = firstCellRange && firstCellRange.endRow || null;
    return {
      rowStartIndex: startRow && startRow.rowIndex,
      rowStartPinned: startRow && startRow.rowPinned,
      rowEndIndex: endRow && endRow.rowIndex,
      rowEndPinned: endRow && endRow.rowPinned,
      columns: cellRanges.reduce(
        (columns, value) => columns.concat(value.columns.map((c) => c.getId())),
        []
      )
    };
  }
  setCategoryAxisType(categoryAxisType) {
    this.model.categoryAxisType = categoryAxisType;
    this.raiseChartModelUpdateEvent();
  }
  getSeriesGroupType() {
    return this.model.seriesGroupType ?? this.chartProxy.getSeriesGroupType();
  }
  setSeriesGroupType(seriesGroupType) {
    this.model.seriesGroupType = seriesGroupType;
    this.raiseChartModelUpdateEvent();
  }
  raiseChartModelUpdateEvent() {
    this.dispatchLocalEvent({ type: "chartModelUpdate" });
  }
  raiseChartUpdatedEvent() {
    this.dispatchLocalEvent({ type: "chartUpdated" });
  }
  raiseChartApiUpdateEvent() {
    this.dispatchLocalEvent({ type: "chartApiUpdate" });
  }
  raiseChartOptionsChangedEvent() {
    const { chartId, chartType } = this.getChartModel();
    this.eventService.dispatchEvent({
      type: "chartOptionsChanged",
      chartId,
      chartType,
      chartThemeName: this.getChartThemeName(),
      chartOptions: this.chartProxy.getChartThemeOverrides()
    });
  }
  raiseChartRangeSelectionChangedEvent() {
    this.eventService.dispatchEvent({
      type: "chartRangeSelectionChanged",
      id: this.model.chartId,
      chartId: this.model.chartId,
      cellRange: this.getCellRangeParams()
    });
  }
  destroy() {
    super.destroy();
    if (this.rangeService) {
      this.rangeService.setCellRanges([]);
    }
  }
};
function deproxy(chartOrProxy) {
  if (chartOrProxy.chart != null) {
    return chartOrProxy.chart;
  }
  return chartOrProxy;
}
var ChartProxy = class {
  constructor(chartProxyParams) {
    this.chartProxyParams = chartProxyParams;
    this.clearThemeOverrides = false;
    this.isEnterpriseCharts = _ModuleSupport3.enterpriseModule.isEnterprise;
    this.chart = chartProxyParams.chartInstance;
    this.chartType = chartProxyParams.chartType;
    this.crossFiltering = chartProxyParams.crossFiltering;
    this.crossFilterCallback = chartProxyParams.crossFilterCallback;
    this.standaloneChartType = getSeriesType(this.chartType);
    if (this.chart == null) {
      this.chart = AgCharts.create(this.getCommonChartOptions());
    } else {
      this.clearThemeOverrides = true;
    }
  }
  crossFilteringReset() {
  }
  update(params) {
    this.getChartRef().update(this.getUpdateOptions(params, this.getCommonChartOptions(params.updatedOverrides)));
  }
  updateThemeOverrides(themeOverrides) {
    this.getChartRef().updateDelta({ theme: { overrides: themeOverrides } });
  }
  getChart() {
    return deproxy(this.chart);
  }
  getChartRef() {
    return this.chart;
  }
  downloadChart(dimensions, fileName, fileFormat) {
    const { chart } = this;
    const rawChart = deproxy(chart);
    const imageFileName = fileName || (rawChart.title ? rawChart.title.text : "chart");
    const { width, height } = dimensions || {};
    chart.download({ width, height, fileName: imageFileName, fileFormat });
  }
  getChartImageDataURL(type) {
    return this.getChart().getCanvasDataURL(type);
  }
  getChartOptions() {
    return this.chart.getOptions();
  }
  getChartThemeOverrides() {
    const chartOptionsTheme = this.getChartOptions().theme;
    return chartOptionsTheme.overrides ?? {};
  }
  getChartPalette() {
    return _Theme3.getChartTheme(this.getChartOptions().theme).palette;
  }
  setPaired(paired) {
    const seriesType = getSeriesType(this.chartProxyParams.chartType);
    this.chart.updateDelta({ theme: { overrides: { [seriesType]: { paired } } } });
  }
  isPaired() {
    const seriesType = getSeriesType(this.chartProxyParams.chartType);
    return get(this.getChartThemeOverrides(), `${seriesType}.paired`, true);
  }
  lookupCustomChartTheme(themeName) {
    return lookupCustomChartTheme(this.chartProxyParams, themeName);
  }
  getSeriesGroupType() {
    return void 0;
  }
  transformCategoryData(data, categoryKey) {
    return data.map((d, index) => {
      const value = d[categoryKey];
      const valueString = value && value.toString ? value.toString() : "";
      const datum = { ...d };
      datum[categoryKey] = { id: index, value, toString: () => valueString };
      return datum;
    });
  }
  getCommonChartOptions(updatedOverrides) {
    const existingOptions = this.clearThemeOverrides ? {} : this.chart?.getOptions() ?? {};
    const formattingPanelOverrides = this.chart != null ? this.getActiveFormattingPanelOverrides() : void 0;
    this.clearThemeOverrides = false;
    const theme = createAgChartTheme(
      this.chartProxyParams,
      this,
      this.isEnterpriseCharts,
      this.getChartThemeDefaults(),
      updatedOverrides ?? formattingPanelOverrides
    );
    const newOptions = {
      ...existingOptions,
      mode: "integrated"
    };
    newOptions.theme = theme;
    newOptions.container = this.chartProxyParams.parentElement;
    return newOptions;
  }
  getChartThemeDefaults() {
    const seriesOverrides = this.getSeriesChartThemeDefaults();
    const seriesChartOptions = seriesOverrides ? {
      [this.standaloneChartType]: seriesOverrides
    } : {};
    const crosshair = {
      enabled: true,
      snap: true,
      label: {
        enabled: false
      }
    };
    const common = this.isEnterpriseCharts ? {
      zoom: {
        enabled: true
      },
      animation: {
        enabled: true,
        duration: 500
      },
      axes: {
        number: { crosshair },
        category: { crosshair },
        log: { crosshair },
        time: { crosshair }
      }
    } : {};
    common.minHeight = 0;
    common.minWidth = 0;
    common.navigator = {
      enabled: false
    };
    return {
      common,
      ...seriesChartOptions
    };
  }
  getSeriesChartThemeDefaults() {
    return void 0;
  }
  getActiveFormattingPanelOverrides() {
    if (this.clearThemeOverrides) {
      return {};
    }
    const inUseTheme = this.chart?.getOptions().theme;
    return inUseTheme?.overrides ?? {};
  }
  destroy({ keepChartInstance = false } = {}) {
    if (keepChartInstance) {
      this.chart.resetAnimations();
      return this.chart;
    }
    this.destroyChart();
  }
  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }
};
var CartesianChartProxy = class extends ChartProxy {
  constructor(params) {
    super(params);
    this.crossFilteringAllPoints = /* @__PURE__ */ new Set();
    this.crossFilteringSelectedPoints = [];
  }
  getUpdateOptions(params, commonChartOptions) {
    const axes = this.getAxes(params, commonChartOptions);
    return {
      ...commonChartOptions,
      data: this.getData(params, axes),
      axes,
      series: this.getSeries(params)
    };
  }
  getData(params, axes) {
    const supportsCrossFiltering = ["area", "line"].includes(this.standaloneChartType);
    return this.crossFiltering && supportsCrossFiltering ? this.getCrossFilterData(params) : this.getDataTransformedData(params, axes);
  }
  getDataTransformedData(params, axes) {
    const xAxisType = axes[0].type;
    const { categories, data } = params;
    const [category] = categories;
    switch (xAxisType) {
      case "category":
        return this.transformCategoryData(data, category.id);
      case "time":
        return this.transformTimeData(data, category.id);
      default:
        return data;
    }
  }
  getXAxisType(params) {
    if (params.grouping) {
      return "grouped-category";
    } else if (this.isXAxisOfType(params, "time", (value) => value instanceof Date)) {
      return "time";
    } else if (this.isXAxisOfType(params, "number")) {
      return "number";
    }
    return "category";
  }
  isXAxisOfType(params, type, isInstance) {
    const [category] = params.categories;
    if (category?.chartDataType) {
      return category.chartDataType === type;
    }
    if (!isInstance) {
      return false;
    }
    const testDatum = params.data[0];
    if (!testDatum) {
      return false;
    }
    return isInstance(testDatum[category.id]);
  }
  transformTimeData(data, categoryKey) {
    const firstValue = data[0]?.[categoryKey];
    if (firstValue instanceof Date) {
      return data;
    }
    return data.map((datum) => {
      const value = datum[categoryKey];
      return typeof value === "string" ? {
        ...datum,
        [categoryKey]: new Date(value)
      } : datum;
    });
  }
  crossFilteringReset() {
    this.crossFilteringSelectedPoints = [];
    this.crossFilteringAllPoints.clear();
  }
  crossFilteringPointSelected(point) {
    return this.crossFilteringSelectedPoints.length == 0 || this.crossFilteringSelectedPoints.includes(point);
  }
  crossFilteringDeselectedPoints() {
    return this.crossFilteringSelectedPoints.length > 0 && this.crossFilteringAllPoints.size !== this.crossFilteringSelectedPoints.length;
  }
  extractLineAreaCrossFilterSeries(series, params) {
    const [category] = params.categories;
    const getYKey = (yKey) => {
      if (this.standaloneChartType === "area") {
        const lastSelectedChartId = params.getCrossFilteringContext().lastSelectedChartId;
        return lastSelectedChartId === params.chartId ? yKey + "-total" : yKey;
      }
      return yKey + "-total";
    };
    return series.map((s) => {
      s.yKey = getYKey(s.yKey);
      s.listeners = {
        nodeClick: (e) => {
          const value = e.datum[s.xKey];
          const multiSelection = e.event.metaKey || e.event.ctrlKey;
          this.crossFilteringAddSelectedPoint(multiSelection, value);
          this.crossFilterCallback(e);
        }
      };
      s.marker = {
        itemStyler: (p) => {
          const value = p.datum[category.id];
          return {
            fill: p.highlighted ? "yellow" : p.fill,
            size: p.highlighted ? 14 : this.crossFilteringPointSelected(value) ? 8 : 0
          };
        }
      };
      if (this.standaloneChartType === "area") {
        s.fillOpacity = this.crossFilteringDeselectedPoints() ? 0.3 : 1;
      }
      if (this.standaloneChartType === "line") {
        s.strokeOpacity = this.crossFilteringDeselectedPoints() ? 0.3 : 1;
      }
      return s;
    });
  }
  getCrossFilterData(params) {
    this.crossFilteringAllPoints.clear();
    const [category] = params.categories;
    const colId = params.fields[0].colId;
    const filteredOutColId = `${colId}-filtered-out`;
    const lastSelectedChartId = params.getCrossFilteringContext().lastSelectedChartId;
    return params.data.map((d) => {
      const value = d[category.id];
      this.crossFilteringAllPoints.add(value);
      const pointSelected = this.crossFilteringPointSelected(value);
      if (this.standaloneChartType === "area" && lastSelectedChartId === params.chartId) {
        d[`${colId}-total`] = pointSelected ? d[colId] : d[colId] + d[filteredOutColId];
      }
      if (this.standaloneChartType === "line") {
        d[`${colId}-total`] = pointSelected ? d[colId] : d[colId] + d[filteredOutColId];
      }
      return d;
    });
  }
  crossFilteringAddSelectedPoint(multiSelection, value) {
    multiSelection ? this.crossFilteringSelectedPoints.push(value) : this.crossFilteringSelectedPoints = [value];
  }
  isHorizontal(commonChartOptions) {
    const seriesType = this.standaloneChartType;
    if (seriesType !== "waterfall" && seriesType !== "box-plot" && seriesType !== "range-bar") {
      return false;
    }
    const theme = commonChartOptions.theme;
    const isHorizontal = (theme2) => {
      const direction = theme2?.overrides?.[seriesType]?.series?.direction;
      if (direction != null) {
        return direction === "horizontal";
      }
      if (typeof theme2?.baseTheme === "object") {
        return isHorizontal(theme2.baseTheme);
      }
      return false;
    };
    return isHorizontal(theme);
  }
};
var AreaChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    const axes = [
      {
        type: this.getXAxisType(params),
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
    if (this.isNormalised()) {
      const numberAxis = axes[1];
      numberAxis.label = { ...numberAxis.label, formatter: (params2) => Math.round(params2.value) + "%" };
    }
    return axes;
  }
  getSeries(params) {
    const [category] = params.categories;
    const series = params.fields.map(
      (f) => ({
        type: this.standaloneChartType,
        xKey: category.id,
        xName: category.name,
        yKey: f.colId,
        yName: f.displayName,
        normalizedTo: this.chartType === "normalizedArea" ? 100 : void 0,
        stacked: ["normalizedArea", "stackedArea"].includes(this.chartType)
      })
    );
    return this.crossFiltering ? this.extractLineAreaCrossFilterSeries(series, params) : series;
  }
  isNormalised() {
    return !this.crossFiltering && this.chartType === "normalizedArea";
  }
};
function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgba(${r}, ${g}, ${b})`;
}
function changeOpacity(fills, alpha) {
  return fills.map((fill) => {
    const c = _Util4.Color.fromString(fill);
    return new _Util4.Color(c.r, c.g, c.b, alpha).toHexString();
  });
}
var HORIZONTAL_CHART_TYPES = /* @__PURE__ */ new Set(["bar", "groupedBar", "stackedBar", "normalizedBar"]);
var BarChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    const axes = [
      {
        type: this.getXAxisType(params),
        position: this.isHorizontal() ? "left" : "bottom"
      },
      {
        type: "number",
        position: this.isHorizontal() ? "bottom" : "left"
      }
    ];
    if (this.isNormalised()) {
      const numberAxis = axes[1];
      numberAxis.label = { ...numberAxis.label, formatter: (params2) => Math.round(params2.value) + "%" };
    }
    return axes;
  }
  getSeries(params) {
    const [category] = params.categories;
    const series = params.fields.map(
      (f) => ({
        type: this.standaloneChartType,
        direction: this.isHorizontal() ? "horizontal" : "vertical",
        stacked: this.crossFiltering || isStacked(this.chartType),
        normalizedTo: this.isNormalised() ? 100 : void 0,
        xKey: category.id,
        xName: category.name,
        yKey: f.colId,
        yName: f.displayName
      })
    );
    return this.crossFiltering ? this.extractCrossFilterSeries(series) : series;
  }
  extractCrossFilterSeries(series) {
    const palette = this.getChartPalette();
    const updatePrimarySeries = (seriesOptions, index) => {
      return {
        ...seriesOptions,
        highlightStyle: { item: { fill: void 0 } },
        fill: palette?.fills?.[index],
        stroke: palette?.strokes?.[index],
        listeners: {
          nodeClick: this.crossFilterCallback
        }
      };
    };
    const updateFilteredOutSeries = (seriesOptions) => {
      const yKey = seriesOptions.yKey + "-filtered-out";
      return {
        ...seriesOptions,
        yKey,
        fill: hexToRGBA(seriesOptions.fill, "0.3"),
        stroke: hexToRGBA(seriesOptions.stroke, "0.3"),
        showInLegend: false
      };
    };
    const allSeries = [];
    for (let i = 0; i < series.length; i++) {
      const originalSeries = series[i];
      allSeries.push(updatePrimarySeries(originalSeries, i));
      allSeries.push(updateFilteredOutSeries(updatePrimarySeries(originalSeries, i)));
    }
    return allSeries;
  }
  isNormalised() {
    const normalisedCharts = ["normalizedColumn", "normalizedBar"];
    return !this.crossFiltering && _includes5(normalisedCharts, this.chartType);
  }
  isHorizontal() {
    return HORIZONTAL_CHART_TYPES.has(this.chartType);
  }
};
var HistogramChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getSeries(params) {
    const firstField = params.fields[0];
    return [
      {
        type: this.standaloneChartType,
        xKey: firstField.colId,
        xName: firstField.displayName,
        yName: this.chartProxyParams.translate("histogramFrequency"),
        areaPlot: false
        // only constant width is supported via integrated charts
      }
    ];
  }
  getAxes(_params) {
    return [
      {
        type: "number",
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
  }
};
var LineChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    return [
      {
        type: this.getXAxisType(params),
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
  }
  getSeries(params) {
    const [category] = params.categories;
    const series = params.fields.map(
      (f) => ({
        type: this.standaloneChartType,
        xKey: category.id,
        xName: category.name,
        yKey: f.colId,
        yName: f.displayName
      })
    );
    return this.crossFiltering ? this.extractLineAreaCrossFilterSeries(series, params) : series;
  }
};
var ScatterChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(_params) {
    return [
      {
        type: "number",
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
  }
  getSeries(params) {
    const [category] = params.categories;
    const paired = this.isPaired();
    const seriesDefinitions = this.getSeriesDefinitions(params.fields, paired);
    const labelFieldDefinition = category.id === ChartDataModel.DEFAULT_CATEGORY ? void 0 : category;
    const series = seriesDefinitions.map((seriesDefinition) => {
      if (seriesDefinition?.sizeField) {
        const opts2 = {
          type: "bubble",
          xKey: seriesDefinition.xField.colId,
          xName: seriesDefinition.xField.displayName ?? void 0,
          yKey: seriesDefinition.yField.colId,
          yName: seriesDefinition.yField.displayName ?? void 0,
          title: `${seriesDefinition.yField.displayName} vs ${seriesDefinition.xField.displayName}`,
          sizeKey: seriesDefinition.sizeField.colId,
          sizeName: seriesDefinition.sizeField.displayName ?? "",
          labelKey: labelFieldDefinition ? labelFieldDefinition.id : seriesDefinition.yField.colId,
          labelName: labelFieldDefinition ? labelFieldDefinition.name : void 0
        };
        return opts2;
      }
      const opts = {
        type: "scatter",
        xKey: seriesDefinition.xField.colId,
        xName: seriesDefinition.xField.displayName ?? void 0,
        yKey: seriesDefinition.yField.colId,
        yName: seriesDefinition.yField.displayName ?? void 0,
        title: `${seriesDefinition.yField.displayName} vs ${seriesDefinition.xField.displayName}`,
        labelKey: labelFieldDefinition ? labelFieldDefinition.id : seriesDefinition.yField.colId,
        labelName: labelFieldDefinition ? labelFieldDefinition.name : void 0
      };
      return opts;
    });
    return this.crossFiltering ? this.extractCrossFilterSeries(series, params) : series;
  }
  extractCrossFilterSeries(series, params) {
    const { data } = params;
    const palette = this.getChartPalette();
    const filteredOutKey = (key) => `${key}-filtered-out`;
    const calcMarkerDomain = (data2, sizeKey) => {
      const markerDomain = [Infinity, -Infinity];
      if (sizeKey != null) {
        for (const datum of data2) {
          const value = datum[sizeKey] ?? datum[filteredOutKey(sizeKey)];
          if (value < markerDomain[0]) {
            markerDomain[0] = value;
          }
          if (value > markerDomain[1]) {
            markerDomain[1] = value;
          }
        }
      }
      if (markerDomain[0] <= markerDomain[1]) {
        return markerDomain;
      }
      return void 0;
    };
    const updatePrimarySeries = (series2, idx) => {
      const fill = palette?.fills?.[idx];
      const stroke = palette?.strokes?.[idx];
      let markerDomain = void 0;
      if (series2.type === "bubble") {
        const { sizeKey } = series2;
        markerDomain = calcMarkerDomain(data, sizeKey);
      }
      return {
        ...series2,
        fill,
        stroke,
        domain: markerDomain,
        highlightStyle: { item: { fill: "yellow" } },
        listeners: {
          ...series2.listeners,
          nodeClick: this.crossFilterCallback
        }
      };
    };
    const updateFilteredOutSeries = (series2) => {
      const { yKey, xKey } = series2;
      let alteredSizeKey = {};
      if (series2.type === "bubble") {
        alteredSizeKey = { sizeKey: filteredOutKey(series2.sizeKey) };
      }
      return {
        ...series2,
        ...alteredSizeKey,
        yKey: filteredOutKey(yKey),
        xKey: filteredOutKey(xKey),
        fillOpacity: 0.3,
        strokeOpacity: 0.3,
        showInLegend: false,
        listeners: {
          ...series2.listeners,
          nodeClick: (e) => {
            const value = e.datum[filteredOutKey(xKey)];
            const filterableEvent = {
              ...e,
              xKey,
              datum: { ...e.datum, [xKey]: value }
            };
            this.crossFilterCallback(filterableEvent);
          }
        }
      };
    };
    const updatedSeries = series.map(updatePrimarySeries);
    return [...updatedSeries, ...updatedSeries.map(updateFilteredOutSeries)];
  }
  getSeriesDefinitions(fields, paired) {
    if (fields.length < 2) {
      return [];
    }
    const isBubbleChart = this.chartType === "bubble";
    if (paired) {
      if (isBubbleChart) {
        return fields.map(
          (currentXField, i) => i % 3 === 0 ? {
            xField: currentXField,
            yField: fields[i + 1],
            sizeField: fields[i + 2]
          } : null
        ).filter((x) => x && x.yField && x.sizeField);
      }
      return fields.map(
        (currentXField, i) => i % 2 === 0 ? {
          xField: currentXField,
          yField: fields[i + 1]
        } : null
      ).filter((x) => x && x.yField);
    }
    const xField = fields[0];
    if (isBubbleChart) {
      return fields.map(
        (yField, i) => i % 2 === 1 ? {
          xField,
          yField,
          sizeField: fields[i + 1]
        } : null
      ).filter((x) => x && x.sizeField);
    }
    return fields.filter((value, i) => i > 0).map((yField) => ({ xField, yField }));
  }
};
var WaterfallChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params, commonChartOptions) {
    return [
      {
        type: this.getXAxisType(params),
        position: this.isHorizontal(commonChartOptions) ? "left" : "bottom"
      },
      {
        type: "number",
        position: this.isHorizontal(commonChartOptions) ? "bottom" : "left"
      }
    ];
  }
  getSeries(params) {
    const [category] = params.categories;
    const [firstField] = params.fields;
    const firstSeries = {
      type: this.standaloneChartType,
      xKey: category.id,
      xName: category.name,
      yKey: firstField.colId,
      yName: firstField.displayName ?? void 0
    };
    return [firstSeries];
  }
};
var ComboChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    const fields = params ? params.fields : [];
    const fieldsMap = new Map(fields.map((f) => [f.colId, f]));
    const { primaryYKeys, secondaryYKeys } = this.getYKeys(fields, params.seriesChartTypes);
    const axes = [
      {
        type: this.getXAxisType(params),
        position: "bottom"
      }
    ];
    if (primaryYKeys.length > 0) {
      axes.push({
        type: "number",
        keys: primaryYKeys,
        position: "left"
      });
    }
    if (secondaryYKeys.length > 0) {
      secondaryYKeys.forEach((secondaryYKey) => {
        const field = fieldsMap.get(secondaryYKey);
        const secondaryAxisIsVisible = field && field.colId === secondaryYKey;
        if (!secondaryAxisIsVisible) {
          return;
        }
        const secondaryAxisOptions = {
          type: "number",
          keys: [secondaryYKey],
          position: "right"
        };
        axes.push(secondaryAxisOptions);
      });
    }
    return axes;
  }
  getSeries(params) {
    const { fields, seriesChartTypes } = params;
    const [category] = params.categories;
    return fields.map((field) => {
      const seriesChartType = seriesChartTypes.find((s) => s.colId === field.colId);
      if (seriesChartType) {
        const chartType = seriesChartType.chartType;
        const grouped = ["groupedColumn", "groupedBar"].includes(chartType);
        const groupedOpts = grouped ? { grouped: true } : {};
        return {
          type: getSeriesType(chartType),
          xKey: category.id,
          yKey: field.colId,
          yName: field.displayName,
          stacked: ["stackedArea", "stackedColumn"].includes(chartType),
          ...groupedOpts
        };
      }
    });
  }
  getYKeys(fields, seriesChartTypes) {
    const primaryYKeys = [];
    const secondaryYKeys = [];
    fields.forEach((field) => {
      const colId = field.colId;
      const seriesChartType = seriesChartTypes.find((s) => s.colId === colId);
      if (seriesChartType) {
        seriesChartType.secondaryAxis ? secondaryYKeys.push(colId) : primaryYKeys.push(colId);
      }
    });
    return { primaryYKeys, secondaryYKeys };
  }
};
var CATEGORY_LABEL_KEY = "AG-GRID-DEFAULT-LABEL-KEY";
function createCategoryHierarchy(data, categoryKeys) {
  const hierarchy = buildNestedHierarchy(data, getItemDepth, getItemCategoryLabel);
  return formatCategoryHierarchy(hierarchy);
  function getItemDepth(item) {
    return categoryKeys.length;
  }
  function getItemCategoryLabel(item, categoryIndex) {
    const categoryKey = categoryKeys[categoryIndex];
    const categoryValue = item[categoryKey];
    return getCategoryLabel(categoryValue);
  }
  function getCategoryLabel(value) {
    if (value == null)
      return null;
    return String(value);
  }
}
function createAutoGroupHierarchy(data, getItemLabels) {
  const hierarchy = buildNestedHierarchy(data, getItemDepth, getItemGroupLabel);
  return formatCategoryHierarchy(hierarchy);
  function getItemDepth(item) {
    return getItemLabels(item)?.length ?? 0;
  }
  function getItemGroupLabel(item, groupIndex) {
    const labels2 = getItemLabels(item);
    if (!labels2)
      return null;
    const labelIndex = labels2.length - 1 - groupIndex;
    return labels2[labelIndex];
  }
}
function formatCategoryHierarchy(hierarchy, key = null, isChild) {
  const { depth, rootValues, value, children: inputChildren } = hierarchy;
  if (rootValues) {
    return rootValues.map((item) => ({ [CATEGORY_LABEL_KEY]: key, ...item }));
  } else if (depth === 0) {
    return [{ [CATEGORY_LABEL_KEY]: key, ...value }];
  }
  const children = [];
  for (const [childKey, childHierarchy] of inputChildren.entries()) {
    children.push(...formatCategoryHierarchy(childHierarchy, childKey, true));
  }
  return isChild ? [
    {
      [CATEGORY_LABEL_KEY]: key,
      children,
      ...value ?? {}
    }
  ] : children;
}
function buildNestedHierarchy(data, getItemDepth, getItemGroupKey) {
  const hierarchy = { depth: 0, children: /* @__PURE__ */ new Map() };
  data.forEach((item) => {
    const itemDepth = getItemDepth(item);
    createNestedItemHierarchy(item, itemDepth, getItemGroupKey, 0, hierarchy);
  });
  return hierarchy;
  function createNestedItemHierarchy(item, itemDepth, getItemGroupKey2, currentDepth, hierarchy2) {
    if (currentDepth === itemDepth) {
      if (currentDepth === 0) {
        if (!hierarchy2.rootValues) {
          hierarchy2.rootValues = [];
        }
        hierarchy2.rootValues.push(item);
      } else {
        hierarchy2.value = item;
      }
      return hierarchy2;
    } else {
      const key = getItemGroupKey2(item, currentDepth);
      const existingChildHierarchy = hierarchy2.children.get(key);
      const childHierarchy = createNestedItemHierarchy(
        item,
        itemDepth,
        getItemGroupKey2,
        currentDepth + 1,
        existingChildHierarchy || { depth: 0, children: /* @__PURE__ */ new Map() }
      );
      hierarchy2.children.set(key, childHierarchy);
      hierarchy2.depth = Math.max(1 + childHierarchy.depth, hierarchy2.depth);
      return hierarchy2;
    }
  }
}
var HierarchicalChartProxy = class extends ChartProxy {
  constructor(chartProxyParams) {
    super(chartProxyParams);
  }
  getUpdateOptions(params, commonChartOptions) {
    const { fields } = params;
    const [sizeField, colorField] = fields;
    return {
      ...commonChartOptions,
      series: this.getSeries(sizeField, colorField),
      data: this.getData(params, sizeField, colorField)
    };
  }
  getSeriesChartThemeDefaults() {
    return {
      gradientLegend: {
        gradient: {
          preferredLength: 200
        }
      }
    };
  }
  getSeries(sizeField, colorField) {
    return [
      {
        type: this.standaloneChartType,
        labelKey: CATEGORY_LABEL_KEY,
        // Size and color fields are inferred from the range data
        sizeKey: sizeField?.colId,
        sizeName: sizeField?.displayName ?? void 0,
        colorKey: colorField?.colId,
        colorName: colorField?.displayName ?? void 0
      }
    ];
  }
  getData(params, sizeField, colorField) {
    const { categories, data, groupData, grouping: isGrouped } = params;
    if (isGrouped) {
      const processedData = colorField ? data.concat(
        groupData?.map((groupDatum) => {
          const newDatum = { ...groupDatum };
          delete newDatum[sizeField.colId];
          return newDatum;
        }) ?? []
      ) : data;
      return createAutoGroupHierarchy(processedData, (item) => item[GROUP_AUTO_COLUMN_ID]?.labels ?? null);
    } else {
      const categoryKeys = categories.map(({ id }) => id);
      return createCategoryHierarchy(data, categoryKeys);
    }
  }
};
function calculateOffsets(offset) {
  const outerRadiusOffset = offset.currentOffset;
  offset.currentOffset -= offset.offsetAmount;
  const innerRadiusOffset = offset.currentOffset;
  offset.currentOffset -= offset.offsetAmount;
  return { outerRadiusOffset, innerRadiusOffset };
}
var PieChartProxy = class extends ChartProxy {
  constructor(params) {
    super(params);
  }
  getUpdateOptions(params, commonChartOptions) {
    return {
      ...commonChartOptions,
      data: this.crossFiltering ? this.getCrossFilterData(params) : params.data,
      series: this.getSeries(params)
    };
  }
  getSeries(params) {
    const [category] = params.categories;
    const numFields = params.fields.length;
    const offset = {
      currentOffset: 0,
      offsetAmount: numFields > 1 ? 20 : 40
    };
    const series = this.getFields(params).map(
      (f) => {
        const options = {
          type: this.standaloneChartType,
          angleKey: f.colId,
          angleName: f.displayName,
          sectorLabelKey: f.colId,
          calloutLabelName: category.name,
          calloutLabelKey: category.id
        };
        if (this.chartType === "donut" || this.chartType === "doughnut") {
          const { outerRadiusOffset, innerRadiusOffset } = calculateOffsets(offset);
          const title = f.displayName ? {
            title: { text: f.displayName, showInLegend: numFields > 1 }
          } : void 0;
          return {
            ...options,
            type: "donut",
            outerRadiusOffset,
            innerRadiusOffset,
            ...title,
            calloutLine: {
              colors: this.getChartPalette()?.strokes
            }
          };
        }
        return options;
      }
    );
    return this.crossFiltering ? this.extractCrossFilterSeries(series) : series;
  }
  getCrossFilterData(params) {
    const colId = params.fields[0].colId;
    const filteredOutColId = `${colId}-filtered-out`;
    return params.data.map((d) => {
      const total = d[colId] + d[filteredOutColId];
      d[`${colId}-total`] = total;
      d[filteredOutColId] = 1;
      d[colId] = d[colId] / total;
      return d;
    });
  }
  extractCrossFilterSeries(series) {
    const palette = this.getChartPalette();
    const primaryOptions = (seriesOptions) => {
      return {
        ...seriesOptions,
        legendItemKey: seriesOptions.calloutLabelKey,
        calloutLabel: { enabled: false },
        // hide labels on primary series
        highlightStyle: { item: { fill: void 0 } },
        radiusKey: seriesOptions.angleKey,
        angleKey: seriesOptions.angleKey + "-total",
        radiusMin: 0,
        radiusMax: 1,
        listeners: {
          nodeClick: this.crossFilterCallback
        }
      };
    };
    const filteredOutOptions = (seriesOptions, angleKey2) => {
      return {
        ...primaryOpts,
        radiusKey: angleKey2 + "-filtered-out",
        fills: changeOpacity(seriesOptions.fills ?? palette?.fills ?? [], 0.3),
        strokes: changeOpacity(seriesOptions.strokes ?? palette?.strokes ?? [], 0.3),
        showInLegend: false
      };
    };
    const primarySeries = series[0];
    const angleKey = primarySeries.angleKey;
    const primaryOpts = primaryOptions(primarySeries);
    return [filteredOutOptions(primaryOptions(primarySeries), angleKey), primaryOpts];
  }
  getFields(params) {
    return this.chartType === "pie" ? params.fields.slice(0, 1) : params.fields;
  }
};
var PolarChartProxy = class extends ChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(_) {
    const radialBar = this.standaloneChartType === "radial-bar";
    return [
      { type: radialBar ? "angle-number" : "angle-category" },
      { type: radialBar ? "radius-category" : "radius-number" }
    ];
  }
  getSeries(params) {
    const { fields, categories, seriesGroupType } = params;
    const [category] = categories;
    const radialBar = this.standaloneChartType === "radial-bar";
    const seriesGroupTypeOptions = this.getSeriesGroupTypeOptions(seriesGroupType);
    return fields.map((f) => ({
      type: this.standaloneChartType,
      angleKey: radialBar ? f.colId : category.id,
      angleName: radialBar ? f.displayName ?? void 0 : category.name,
      radiusKey: radialBar ? category.id : f.colId,
      radiusName: radialBar ? category.name : f.displayName ?? void 0,
      ...seriesGroupTypeOptions
    }));
  }
  getSeriesGroupType() {
    const standaloneChartType = this.standaloneChartType;
    if (!["nightingale", "radial-bar", "radial-column"].includes(standaloneChartType)) {
      return void 0;
    }
    const firstSeriesProperties = this.getChart().series?.[0]?.properties.toJson();
    const getStackedValue = () => firstSeriesProperties.normalizedTo ? "normalized" : "stacked";
    if (standaloneChartType === "nightingale") {
      return firstSeriesProperties.grouped ? "grouped" : getStackedValue();
    } else {
      return firstSeriesProperties.stacked ? getStackedValue() : "grouped";
    }
  }
  getUpdateOptions(params, commonChartOptions) {
    const axes = this.getAxes(params);
    return {
      ...commonChartOptions,
      data: this.getData(params, axes),
      axes,
      series: this.getSeries(params)
    };
  }
  getData(params, axes) {
    const isCategoryAxis = axes.some((axis) => axis.type === "angle-category" || axis.type === "radius-category");
    if (isCategoryAxis) {
      const [category] = params.categories;
      return this.transformCategoryData(params.data, category.id);
    } else {
      return params.data;
    }
  }
  getSeriesGroupTypeOptions(seriesGroupType) {
    if (!seriesGroupType) {
      return {};
    }
    return {
      grouped: seriesGroupType === "grouped" || void 0,
      stacked: seriesGroupType !== "grouped" || void 0,
      normalizedTo: seriesGroupType === "normalized" ? 100 : void 0
    };
  }
};
function flatMap(items, iteratee) {
  return items.reduce((acc, item, index, array) => acc.concat(iteratee(item, index, array)), new Array());
}
var HEATMAP_CATEGORY_KEY = "AG-GRID-DEFAULT-HEATMAP-CATEGORY-KEY";
var HEATMAP_SERIES_KEY = "AG-GRID-DEFAULT-HEATMAP-SERIES-KEY";
var HEATMAP_VALUE_KEY = "AG-GRID-DEFAULT-HEATMAP-VALUE-KEY";
var HeatmapChartProxy = class extends ChartProxy {
  constructor(params) {
    super(params);
  }
  getUpdateOptions(params, commonChartOptions) {
    const xSeriesKey = HEATMAP_SERIES_KEY;
    const xValueKey = HEATMAP_VALUE_KEY;
    const yKey = HEATMAP_CATEGORY_KEY;
    return {
      ...commonChartOptions,
      series: this.getSeries(params, xSeriesKey, xValueKey, yKey),
      data: this.getData(params, xSeriesKey, xValueKey, yKey)
    };
  }
  getSeries(params, xSeriesKey, xValueKey, yKey) {
    const [category] = params.categories;
    return [
      {
        type: this.standaloneChartType,
        // The axis keys reference synthetic fields based on the category values and series column names
        yKey,
        xKey: xSeriesKey,
        // The color key references a synthetic field based on the series column value for a specific cell
        colorKey: xValueKey,
        yName: category.name,
        // We don't know how to label the 'x' series, as it is a synthetic series created from the set of all input columns
        // In future releases we may want to consider inferring the series label from column groupings etc
        xName: void 0,
        colorName: void 0
      }
    ];
  }
  getData(params, xSeriesKey, xValueKey, yKey) {
    const [category] = params.categories;
    return flatMap(params.data, (datum, index) => {
      const value = datum[category.id];
      const valueString = value == null ? "" : String(value);
      const yValue = { id: index, value, toString: () => valueString };
      return params.fields.map(({ colId, displayName }) => ({
        ...datum,
        [xSeriesKey]: displayName,
        [xValueKey]: datum[colId],
        [yKey]: yValue
      }));
    });
  }
  getSeriesChartThemeDefaults() {
    return {
      gradientLegend: {
        gradient: {
          preferredLength: 200
        }
      },
      series: {
        tooltip: {
          renderer: renderHeatmapTooltip
        }
      }
    };
  }
};
function renderHeatmapTooltip(params) {
  const { xKey, yKey, colorKey, yName, datum } = params;
  const table = [
    { label: yName, value: datum[yKey] },
    { label: datum[xKey], value: colorKey && datum[colorKey] }
  ];
  const html = table.map(({ label, value }) => `<b>${sanitizeHtml(String(label))}:</b> ${sanitizeHtml(String(value))}`).join("<br>");
  return {
    title: "",
    content: html
  };
}
function sanitizeHtml(input) {
  const ESCAPED_CHARS = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;"
  };
  const characterClass = `[${Object.keys(ESCAPED_CHARS).join("")}]`;
  const pattern = new RegExp(characterClass, "g");
  return input.replace(pattern, (char) => ESCAPED_CHARS[char]);
}
var StatisticalChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params, commonChartOptions) {
    return [
      {
        type: this.getXAxisType(params),
        position: this.isHorizontal(commonChartOptions) ? "left" : "bottom"
      },
      {
        type: "number",
        position: this.isHorizontal(commonChartOptions) ? "bottom" : "left"
      }
    ];
  }
  computeSeriesStatistics(params, computeStatsFn) {
    const { data, fields } = params;
    const [category] = params.categories;
    const categoryKey = category.id || ChartDataModel.DEFAULT_CATEGORY;
    const groupedData = this.groupDataByCategory(categoryKey, data);
    return Array.from(groupedData).map(([categoryValue, categoryData]) => {
      const categoryResult = { [category.id]: categoryValue };
      fields.forEach((field, seriesIndex) => {
        const seriesValues = categoryData.map((datum) => datum[field.colId]).filter((value) => typeof value === "number" && !isNaN(value));
        Object.entries(computeStatsFn(seriesValues)).forEach(([statKey, value]) => {
          const propertyKey = `${statKey}:${seriesIndex}`;
          categoryResult[propertyKey] = seriesValues.length > 0 ? value : null;
        });
      });
      return categoryResult;
    });
  }
  groupDataByCategory(categoryKey, data) {
    const getCategory = (datum) => {
      if (categoryKey === ChartDataModel.DEFAULT_CATEGORY) {
        return 1;
      }
      const categoryValue = datum[categoryKey];
      if (categoryValue === null || categoryValue === void 0) {
        return "";
      }
      return categoryValue instanceof Date ? categoryValue.getTime() : categoryValue;
    };
    return data.reduce((acc, datum) => {
      const category = getCategory(datum);
      const existingCategoryData = acc.get(category);
      if (existingCategoryData) {
        existingCategoryData.push(datum);
      } else {
        acc.set(category, [datum]);
      }
      return acc;
    }, /* @__PURE__ */ new Map());
  }
};
var BoxPlotChartProxy = class extends StatisticalChartProxy {
  constructor(params) {
    super(params);
  }
  getSeries(params) {
    const [category] = params.categories;
    return params.fields.map((field, seriesIndex) => ({
      type: this.standaloneChartType,
      // xKey/xName refer to category buckets
      xKey: category.id,
      xName: category.name,
      // yName is used to label the series
      yName: field.displayName ?? void 0,
      // custom field labels shown in the tooltip
      minName: "Min",
      q1Name: "Q1",
      medianName: "Median",
      q3Name: "Q3",
      maxName: "Max",
      // generated 'synthetic fields' from getData()
      minKey: `min:${seriesIndex}`,
      q1Key: `q1:${seriesIndex}`,
      medianKey: `median:${seriesIndex}`,
      q3Key: `q3:${seriesIndex}`,
      maxKey: `max:${seriesIndex}`
    }));
  }
  getData(params) {
    return this.computeSeriesStatistics(params, (seriesValues) => {
      const sortedValues = seriesValues.sort((a, b) => a - b);
      return {
        min: sortedValues[0],
        q1: this.quantile(sortedValues, 0.25),
        median: this.quantile(sortedValues, 0.5),
        q3: this.quantile(sortedValues, 0.75),
        max: sortedValues[sortedValues.length - 1]
      };
    });
  }
  quantile(sortedValues, q) {
    const position = (sortedValues.length - 1) * q;
    const indexBelow = Math.floor(position);
    const aboveValue = position - indexBelow;
    if (sortedValues[indexBelow + 1] !== void 0) {
      return sortedValues[indexBelow] + aboveValue * (sortedValues[indexBelow + 1] - sortedValues[indexBelow]);
    }
    return sortedValues[indexBelow];
  }
};
var RangeChartProxy = class extends StatisticalChartProxy {
  constructor(params) {
    super(params);
  }
  getSeries(params) {
    const [category] = params.categories;
    return params.fields.map((field, seriesIndex) => ({
      type: this.standaloneChartType,
      // xKey/xName refer to category buckets
      xKey: category.id,
      xName: category.name,
      // yName is used to label the series
      yName: field.displayName ?? void 0,
      // custom field labels shown in the tooltip
      yLowName: "Min",
      yHighName: "Max",
      // generated 'synthetic fields' from getData()
      yLowKey: `min:${seriesIndex}`,
      yHighKey: `max:${seriesIndex}`
    }));
  }
  getData(params) {
    return this.computeSeriesStatistics(params, (seriesValues) => {
      return {
        min: Math.min(...seriesValues),
        max: Math.max(...seriesValues)
      };
    });
  }
};
var ChartToolbar = class extends Component83 {
  constructor() {
    super(
      /* html */
      `<div class="ag-chart-menu" data-ref="eMenu"></div>`
    );
    this.eMenu = RefPlaceholder63;
    this.buttonListenersDestroyFuncs = [];
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  updateParams(params) {
    const { buttons } = params;
    this.createButtons(buttons);
  }
  createButtons(buttons) {
    this.buttonListenersDestroyFuncs.forEach((func) => func?.());
    this.buttonListenersDestroyFuncs = [];
    const menuEl = this.eMenu;
    _clearElement6(menuEl);
    buttons.forEach((buttonConfig) => {
      const { buttonName, iconName, callback } = buttonConfig;
      const buttonEl = this.createButton(iconName);
      const tooltipTitle = this.chartTranslationService.translate(
        buttonName + "ToolbarTooltip"
      );
      if (tooltipTitle && buttonEl instanceof HTMLElement) {
        buttonEl.title = tooltipTitle;
      }
      this.buttonListenersDestroyFuncs.push(
        ...this.addManagedElementListeners(buttonEl, {
          click: (event) => callback(event.currentTarget)
        })
      );
      menuEl.appendChild(buttonEl);
    });
  }
  createButton(iconName) {
    const buttonEl = _createIconNoSpan23(iconName, this.gos, void 0, true);
    buttonEl.classList.add("ag-chart-menu-icon");
    const wrapperEl = _getDocument22(this.gos).createElement("button");
    wrapperEl.appendChild(buttonEl);
    wrapperEl.classList.add("ag-chart-menu-toolbar-button");
    return wrapperEl;
  }
  destroy() {
    this.buttonListenersDestroyFuncs = [];
    super.destroy();
  }
};
var AgPillSelect = class extends Component93 {
  constructor(config) {
    super(
      /* html */
      `<div class="ag-pill-select" role="presentation"></div>`
    );
    this.config = config ?? {};
    const { selectedValueList, valueFormatter, valueList } = this.config;
    this.selectedValues = selectedValueList ?? [];
    this.valueList = valueList ?? [];
    this.valueFormatter = valueFormatter ?? ((value) => _escapeString5(value));
  }
  postConstruct() {
    const { ariaLabel, onValuesChange, dragSourceId } = this.config;
    this.dropZonePanel = this.createManagedBean(
      new PillSelectDropZonePanel(
        {
          getValues: () => this.selectedValues,
          setValues: (values) => this.updateValues(values),
          isDraggable: () => this.selectedValues.length > 1
        },
        (value) => this.valueFormatter(value),
        ariaLabel,
        dragSourceId
      )
    );
    const eGui = this.getGui();
    eGui.appendChild(this.dropZonePanel.getGui());
    this.initSelect();
    if (onValuesChange != null) {
      this.onValuesChange = onValuesChange;
    }
  }
  setValues(valueList, selectedValues) {
    const { added, removed, updated } = this.getChanges(this.valueList, valueList);
    let refreshSelect = false;
    if (added.length || removed.length || updated.length) {
      refreshSelect = true;
    }
    this.valueList = valueList;
    this.updateValues(selectedValues, refreshSelect, true);
    return this;
  }
  setValueFormatter(valueFormatter) {
    this.valueFormatter = valueFormatter;
    return this;
  }
  initSelect() {
    const options = this.createSelectOptions();
    if (!options.length) {
      return false;
    }
    const { selectPlaceholder: placeholder } = this.config;
    this.eSelect = this.createBean(
      new AgSelect({
        options,
        placeholder,
        onValueChange: (value) => this.addValue(value),
        pickerIcon: "chartsMenuAdd"
      })
    );
    this.getGui().appendChild(this.eSelect.getGui());
    return true;
  }
  createSelectOptions() {
    const options = [];
    const { maxSelection } = this.config;
    if (maxSelection && this.selectedValues.length >= maxSelection) {
      return options;
    }
    this.valueList.forEach((value) => {
      if (!this.selectedValues.includes(value)) {
        options.push({ value, text: this.valueFormatter(value) });
      }
    });
    return options;
  }
  addValue(value) {
    this.dropZonePanel.addItem(value);
  }
  updateValues(values, forceRefreshSelect, silent) {
    const previousSelectedValues = this.selectedValues;
    this.selectedValues = values;
    const changes = this.getChanges(previousSelectedValues, values);
    const refreshSelect = forceRefreshSelect || changes.added.length || changes.removed.length;
    const activeElement = _getActiveDomElement7(this.gos);
    const selectHasFocus = this.eSelect?.getGui().contains(activeElement);
    const dropZoneHasFocus = this.dropZonePanel?.getGui().contains(activeElement);
    if (!silent) {
      this.onValuesChange?.(changes);
    }
    const emptyRefreshedSelect = refreshSelect ? !this.refreshSelect() : false;
    this.dropZonePanel.refreshGui();
    if (refreshSelect && selectHasFocus) {
      if (emptyRefreshedSelect) {
        this.dropZonePanel.focusList(true);
      } else {
        this.eSelect?.getFocusableElement().focus();
      }
    }
    if (dropZoneHasFocus && !values.length) {
      this.eSelect?.getFocusableElement().focus();
    }
  }
  getChanges(previousSelectedValues, newSelectedValues) {
    const added = newSelectedValues.filter((value) => !previousSelectedValues.includes(value));
    const removed = previousSelectedValues.filter((value) => !newSelectedValues.includes(value));
    const updated = newSelectedValues.filter((value, index) => previousSelectedValues[index] !== value);
    return { added, removed, updated, selected: newSelectedValues };
  }
  refreshSelect() {
    if (!this.eSelect) {
      return this.initSelect();
    }
    const options = this.createSelectOptions();
    if (!options.length) {
      _removeFromParent22(this.eSelect.getGui());
      this.eSelect = this.destroyBean(this.eSelect);
      return false;
    }
    this.eSelect.clearOptions().addOptions(options).setValue(void 0, true);
    return true;
  }
  destroy() {
    this.destroyBean(this.eSelect);
    super.destroy();
  }
};
var PillSelectDragComp = class extends PillDragComp {
  constructor(value, dragSourceDropTarget, ghost, valueFormatter, draggable, sourceId) {
    super(dragSourceDropTarget, ghost, false);
    this.value = value;
    this.valueFormatter = valueFormatter;
    this.draggable = draggable;
    this.sourceId = sourceId;
  }
  getItem() {
    return this.value;
  }
  getDisplayName() {
    return this.valueFormatter(this.value);
  }
  getAriaDisplayName() {
    return this.getDisplayName();
  }
  getTooltip() {
    return void 0;
  }
  createGetDragItem() {
    return () => ({
      value: this.value
    });
  }
  getDragSourceType() {
    return DragSourceType3.ChartPanel;
  }
  getDragSourceId() {
    return this.sourceId;
  }
  isDraggable() {
    return this.draggable;
  }
};
var PillSelectDropZonePanel = class extends PillDropZonePanel {
  constructor(model, valueFormatter, ariaLabel, sourceId) {
    super(false);
    this.model = model;
    this.valueFormatter = valueFormatter;
    this.ariaLabel = ariaLabel;
    this.sourceId = sourceId;
  }
  postConstruct() {
    super.init();
  }
  isItemDroppable(item, draggingEvent) {
    return this.isSourceEventFromTarget(draggingEvent) || this.sourceId != null && this.sourceId === draggingEvent.dragSource.sourceId;
  }
  updateItems(items) {
    this.model.setValues(items);
  }
  getExistingItems() {
    return this.model.getValues();
  }
  getIconName() {
    return this.isPotentialDndItems() ? "move" : "notAllowed";
  }
  getAriaLabel() {
    return this.ariaLabel;
  }
  createPillComponent(item, dropTarget, ghost) {
    return new PillSelectDragComp(
      item,
      dropTarget,
      ghost,
      this.valueFormatter,
      this.model.isDraggable(),
      this.sourceId
    );
  }
  getItems(dragItem) {
    return [dragItem.value];
  }
  isInterestedIn(type) {
    return type === DragSourceType3.ChartPanel;
  }
};
var DragDataPanel = class extends Component102 {
  constructor(chartController, allowMultipleSelection, maxSelection, template) {
    super(template);
    this.chartController = chartController;
    this.allowMultipleSelection = allowMultipleSelection;
    this.maxSelection = maxSelection;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  addItem(eItem) {
    this.groupComp.addItem(eItem);
  }
  createGroup(columns, valueFormatter, selectLabelKey, dragSourceId, skipAnimation) {
    if (this.allowMultipleSelection) {
      const selectedValueList = columns.filter((col) => col.selected);
      this.valuePillSelect = this.groupComp.createManagedBean(
        new AgPillSelect({
          valueList: columns,
          selectedValueList,
          valueFormatter,
          selectPlaceholder: this.chartTranslationService.translate(selectLabelKey),
          dragSourceId,
          onValuesChange: (params) => this.onValueChange(params),
          maxSelection: this.maxSelection
        })
      );
      this.groupComp.addItem(this.valuePillSelect);
    } else {
      const params = this.createValueSelectParams(columns);
      params.onValueChange = (updatedColState) => {
        columns.forEach((col) => {
          col.selected = false;
        });
        updatedColState.selected = true;
        if (updatedColState.colId === ChartDataModel.DEFAULT_CATEGORY) {
          this.chartController.setAggFunc(void 0, true);
        }
        this.chartController.updateForPanelChange({ updatedColState, skipAnimation: skipAnimation?.() });
      };
      this.valueSelect = this.groupComp.createManagedBean(new AgSelect2(params));
      this.groupComp.addItem(this.valueSelect);
    }
  }
  refreshValueSelect(columns) {
    if (!this.valueSelect) {
      return;
    }
    const { options, value } = this.createValueSelectParams(columns);
    this.valueSelect.clearOptions().addOptions(options).setValue(value, true);
  }
  createValueSelectParams(columns) {
    let selectedValue;
    const options = columns.map((value) => {
      const text = value.displayName ?? "";
      if (value.selected) {
        selectedValue = value;
      }
      return {
        value,
        text
      };
    });
    return {
      options,
      value: selectedValue
    };
  }
  onValueChange({ added, updated, removed, selected }) {
    let updatedColState;
    let resetOrder;
    const updateOrder = () => {
      selected.forEach((col, index) => {
        col.order = index;
      });
      resetOrder = true;
    };
    if (added.length) {
      updatedColState = added[0];
      updatedColState.selected = true;
      updateOrder();
    } else if (removed.length) {
      updatedColState = removed[0];
      updatedColState.selected = false;
    } else if (updated.length) {
      updateOrder();
      updatedColState = updated[0];
    }
    if (updatedColState) {
      this.chartController.updateForPanelChange({ updatedColState, resetOrder });
    }
  }
  destroy() {
    this.valuePillSelect = void 0;
    this.valueSelect = void 0;
    super.destroy();
  }
};
var DEFAULT_AGG_FUNC = "sum";
var CategoriesDataPanel = class extends DragDataPanel {
  constructor(chartController, title, allowMultipleSelection, dimensionCols, isOpen) {
    const maxSelection = void 0;
    super(
      chartController,
      allowMultipleSelection,
      maxSelection,
      /* html */
      `<div id="categoriesGroup"></div>`
    );
    this.title = title;
    this.dimensionCols = dimensionCols;
    this.isOpen = isOpen;
  }
  postConstruct() {
    this.groupComp = this.createBean(
      new AgGroupComponent({
        title: this.title,
        enabled: true,
        suppressEnabledCheckbox: true,
        suppressOpenCloseIcons: false,
        cssIdentifier: "charts-data",
        expanded: this.isOpen
      })
    );
    this.createGroup(
      this.dimensionCols,
      (col) => col.displayName ?? "",
      "categoryAdd",
      "categorySelect",
      () => !this.chartController.getAggFunc()
    );
    this.createAggFuncControls(this.dimensionCols);
    this.getGui().appendChild(this.groupComp.getGui());
  }
  refresh(dimensionCols) {
    this.valuePillSelect?.setValues(
      dimensionCols,
      dimensionCols.filter((col) => col.selected)
    );
    this.refreshValueSelect(dimensionCols);
    this.refreshAggFuncControls(dimensionCols, this.chartController.getAggFunc());
  }
  createAggFuncControls(dimensionCols) {
    const aggFunc = this.chartController.getAggFunc();
    this.groupComp.addItem(
      this.aggFuncToggle = this.createBean(
        new AgToggleButton2({
          label: this.chartTranslationService.translate("aggregate"),
          labelAlignment: "left",
          labelWidth: "flex",
          inputWidth: "flex",
          value: aggFunc != void 0,
          onValueChange: (value) => {
            const aggFunc2 = value ? DEFAULT_AGG_FUNC : void 0;
            this.chartController.setAggFunc(aggFunc2);
            this.aggFuncSelect?.setValue(aggFunc2, true);
            this.aggFuncSelect?.setDisplayed(aggFunc2 != void 0);
          }
        })
      )
    );
    this.groupComp.addItem(
      this.aggFuncSelect = this.createBean(
        new AgSelect3({
          options: [
            { value: "sum", text: this.chartTranslationService.translate("sum") },
            { value: "first", text: this.chartTranslationService.translate("first") },
            { value: "last", text: this.chartTranslationService.translate("last") },
            { value: "min", text: this.chartTranslationService.translate("min") },
            { value: "max", text: this.chartTranslationService.translate("max") },
            { value: "count", text: this.chartTranslationService.translate("count") },
            { value: "avg", text: this.chartTranslationService.translate("avg") }
          ],
          value: typeof aggFunc === "string" ? aggFunc : void 0,
          onValueChange: (value) => {
            this.chartController.setAggFunc(value);
          }
        })
      )
    );
    this.refreshAggFuncControls(dimensionCols, aggFunc);
  }
  refreshAggFuncControls(dimensionCols, aggFunc) {
    const selectedDimensions = dimensionCols.filter((col) => col.selected);
    const supportsAggregation = selectedDimensions.some((col) => col.colId !== ChartDataModel.DEFAULT_CATEGORY);
    this.aggFuncToggle?.setValue(aggFunc != void 0);
    this.aggFuncSelect?.setValue(typeof aggFunc === "string" ? aggFunc : void 0, true);
    this.aggFuncToggle?.setDisplayed(supportsAggregation);
    this.aggFuncSelect?.setDisplayed(supportsAggregation && aggFunc != void 0);
  }
  clearAggFuncControls() {
    this.aggFuncToggle = this.destroyBean(this.aggFuncToggle);
    this.aggFuncSelect = this.destroyBean(this.aggFuncSelect);
  }
  destroy() {
    this.clearAggFuncControls();
    this.groupComp = this.destroyBean(this.groupComp);
    super.destroy();
  }
};
var ChartMenuParamsFactory = class extends BeanStub12 {
  constructor(chartOptionsProxy) {
    super();
    this.chartOptionsProxy = chartOptionsProxy;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  getDefaultColorPickerParams(expression, labelKey, options) {
    return this.addValueParams(
      expression,
      {
        label: this.chartTranslationService.translate(labelKey ?? "color"),
        labelWidth: "flex",
        inputWidth: "flex",
        labelAlignment: "top",
        pickerGap: 6
      },
      options
    );
  }
  getDefaultNumberInputParams(expression, labelKey, options) {
    return this.addValueParams(
      expression,
      {
        label: this.chartTranslationService.translate(labelKey),
        labelAlignment: "top",
        labelWidth: "flex",
        inputWidth: "flex",
        precision: options?.precision,
        step: options?.step,
        min: options?.min,
        max: options?.max
      },
      {
        parseInputValue: (value) => {
          const numberValue = Number(value);
          return isNaN(numberValue) ? void 0 : numberValue;
        },
        formatInputValue: (value) => {
          return value == null ? "" : `${value}`;
        }
      }
    );
  }
  getDefaultSliderParams(expression, labelKey, defaultMaxValue, isArray) {
    let value = this.chartOptionsProxy.getValue(expression) ?? 0;
    if (isArray && Array.isArray(value)) {
      value = value[0];
    }
    const params = this.getDefaultSliderParamsWithoutValueParams(value, labelKey, defaultMaxValue);
    params.onValueChange = (value2) => this.chartOptionsProxy.setValue(expression, isArray ? [value2] : value2);
    return params;
  }
  getDefaultSliderParamsWithoutValueParams(value, labelKey, defaultMaxValue) {
    return {
      label: this.chartTranslationService.translate(labelKey),
      minValue: 0,
      maxValue: Math.max(value, defaultMaxValue),
      textFieldWidth: 45,
      value: `${value}`
    };
  }
  getDefaultCheckboxParams(expression, labelKey, options) {
    const value = this.chartOptionsProxy.getValue(expression);
    const params = {
      label: this.chartTranslationService.translate(labelKey),
      value,
      readOnly: options?.readOnly,
      passive: options?.passive
    };
    params.onValueChange = (value2) => {
      this.chartOptionsProxy.setValue(expression, typeof value2 === "boolean" ? value2 : void 0);
    };
    return params;
  }
  getDefaultSelectParams(expression, labelKey, dropdownOptions) {
    return this.getDefaultSelectParamsWithoutValueParams(
      labelKey,
      dropdownOptions,
      this.chartOptionsProxy.getValue(expression),
      (value) => {
        this.chartOptionsProxy.setValue(expression, value);
      }
    );
  }
  getDefaultSelectParamsWithoutValueParams(labelKey, options, value, onValueChange) {
    return {
      label: this.chartTranslationService.translate(labelKey),
      labelAlignment: "top",
      options,
      pickerGap: 6,
      value,
      onValueChange
    };
  }
  getDefaultFontPanelParams(expression, labelKey) {
    const keyMapper = (key) => `${expression}.${key}`;
    return this.addEnableParams(keyMapper("enabled"), {
      name: this.chartTranslationService.translate(labelKey),
      suppressEnabledCheckbox: false,
      chartMenuParamsFactory: this,
      keyMapper
    });
  }
  addValueParams(expression, params, options) {
    const optionsValue = this.chartOptionsProxy.getValue(expression);
    params.value = options?.formatInputValue ? options.formatInputValue(optionsValue) : optionsValue;
    params.onValueChange = (value) => {
      const optionsValue2 = options?.parseInputValue ? options.parseInputValue(value) : value;
      this.chartOptionsProxy.setValue(expression, optionsValue2);
    };
    return params;
  }
  addEnableParams(expression, params) {
    params.enabled = this.chartOptionsProxy.getValue(expression) ?? false;
    params.onEnableChange = (value) => this.chartOptionsProxy.setValue(expression, value);
    return params;
  }
  getChartOptions() {
    return this.chartOptionsProxy;
  }
};
var ChartSpecificDataPanel = class extends Component112 {
  constructor(chartMenuContext, isOpen) {
    super();
    this.chartMenuContext = chartMenuContext;
    this.isOpen = isOpen;
    this.chartSpecificGroup = RefPlaceholder73;
    this.hasContent = false;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
    this.chartService = beans.chartService;
  }
  postConstruct() {
    const title = this.getTitle();
    const chartSpecificGroupParams = {
      title,
      enabled: true,
      suppressEnabledCheckbox: true,
      suppressOpenCloseIcons: false,
      cssIdentifier: "charts-data",
      expanded: this.isOpen,
      items: [...this.createDirectionSelect(), this.createGroupTypeSelect()]
    };
    this.setTemplate(
      /* html */
      `
            <div id="chartSpecificGroup">
                <ag-group-component data-ref="chartSpecificGroup"></ag-group-component>
            </div>`,
      [AgGroupComponentSelector],
      {
        chartSpecificGroup: chartSpecificGroupParams
      }
    );
    this.setDisplayed(this.hasContent);
  }
  refresh() {
    this.hasContent = false;
    this.chartSpecificGroup.setTitle(this.getTitle());
    this.updateDirectionSelect();
    this.updateGroupTypeSelect();
    this.setDisplayed(this.hasContent);
  }
  getTitle() {
    const chartType = this.chartMenuContext.chartController.getChartType();
    return this.chartTranslationService.translate(getFullChartNameTranslationKey(chartType));
  }
  createDirectionSelect() {
    if (!this.chartService.isEnterprise()) {
      return [];
    }
    const { chartOptionsService, chartController } = this.chartMenuContext;
    const chartOptionsSeriesProxy = chartOptionsService.getSeriesOptionsProxy(
      () => getSeriesType(chartController.getChartType())
    );
    const chartSeriesMenuParamsFactory = this.createManagedBean(
      new ChartMenuParamsFactory(chartOptionsSeriesProxy)
    );
    const options = ["horizontal", "vertical"].map((value) => ({
      value,
      text: this.chartTranslationService.translate(value)
    }));
    const params = chartSeriesMenuParamsFactory.getDefaultSelectParams("direction", "direction", options);
    const onValueChange = params.onValueChange;
    params.onValueChange = (value) => {
      onValueChange(value);
      chartController.raiseChartModelUpdateEvent();
    };
    this.directionSelect = this.createManagedBean(new AgSelect4(params));
    this.updateDirectionSelect();
    return [this.directionSelect];
  }
  updateDirectionSelect() {
    const isDisplayed = canSwitchDirection(this.chartMenuContext.chartController.getChartType());
    this.updateDisplayed(this.directionSelect, isDisplayed);
  }
  createGroupTypeSelect() {
    const { chartController, chartMenuParamsFactory } = this.chartMenuContext;
    this.groupTypeSelect = this.createManagedBean(
      new AgSelect4(
        chartMenuParamsFactory.getDefaultSelectParamsWithoutValueParams(
          "seriesGroupType",
          ChartMappings2.SERIES_GROUP_TYPES.map((value) => ({
            value,
            text: this.chartTranslationService.translate(`${value}SeriesGroupType`)
          })),
          chartController.getSeriesGroupType(),
          (value) => chartController.setSeriesGroupType(value)
        )
      )
    );
    this.updateGroupTypeSelect();
    return this.groupTypeSelect;
  }
  updateGroupTypeSelect() {
    const isDisplayed = ["radialColumn", "radialBar", "nightingale"].includes(
      this.chartMenuContext.chartController.getChartType()
    );
    this.updateDisplayed(this.groupTypeSelect, isDisplayed);
  }
  updateDisplayed(select, isDisplayed) {
    select?.setDisplayed(isDisplayed);
    if (select) {
      this.hasContent = this.hasContent || isDisplayed;
    }
  }
};
var SeriesChartTypePanel = class extends Component122 {
  constructor(chartController, columns, isOpen) {
    super(
      /* html */
      `<div id="seriesChartTypeGroup"></div>`
    );
    this.chartController = chartController;
    this.columns = columns;
    this.isOpen = isOpen;
    this.selectedColIds = [];
    this.chartTypeComps = /* @__PURE__ */ new Map();
    this.secondaryAxisComps = /* @__PURE__ */ new Map();
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    this.createSeriesChartTypeGroup(this.columns);
  }
  refresh(columns) {
    if (!_areEqual3(this.getValidColIds(columns), this.selectedColIds)) {
      this.recreate(columns);
    } else {
      this.refreshComps();
    }
  }
  recreate(columns) {
    this.isOpen = this.seriesChartTypeGroupComp.isExpanded();
    _clearElement22(this.getGui());
    this.destroyBean(this.seriesChartTypeGroupComp);
    this.columns = columns;
    this.selectedColIds = [];
    this.clearComps();
    this.postConstruct();
  }
  getValidColIds(columns) {
    const seriesChartTypes = this.chartController.getSeriesChartTypes();
    return columns.filter((col) => col.selected && !!seriesChartTypes.filter((s) => s.colId === col.colId)[0]).map(({ colId }) => colId);
  }
  createSeriesChartTypeGroup(columns) {
    this.seriesChartTypeGroupComp = this.createBean(
      new AgGroupComponent({
        title: this.chartTranslationService.translate("seriesChartType"),
        enabled: true,
        suppressEnabledCheckbox: true,
        suppressOpenCloseIcons: false,
        cssIdentifier: "charts-data",
        expanded: this.isOpen
      })
    );
    const seriesChartTypes = this.chartController.getSeriesChartTypes();
    columns.forEach((col) => {
      if (!col.selected) {
        return;
      }
      const seriesChartType = seriesChartTypes.filter((s) => s.colId === col.colId)[0];
      if (!seriesChartType) {
        return;
      }
      this.selectedColIds.push(col.colId);
      const seriesItemGroup = this.seriesChartTypeGroupComp.createManagedBean(
        new AgGroupComponent({
          title: col.displayName,
          enabled: true,
          suppressEnabledCheckbox: true,
          suppressOpenCloseIcons: true,
          cssIdentifier: "charts-format-sub-level"
        })
      );
      const isSecondaryAxisDisabled = (chartType) => ["groupedColumn", "stackedColumn", "stackedArea"].includes(chartType);
      const secondaryAxisComp = this.seriesChartTypeGroupComp.createManagedBean(
        new AgCheckbox({
          label: this.chartTranslationService.translate("secondaryAxis"),
          labelWidth: "flex",
          disabled: isSecondaryAxisDisabled(seriesChartType.chartType),
          value: !!seriesChartType.secondaryAxis,
          onValueChange: (enabled) => this.chartController.updateSeriesChartType(col.colId, void 0, enabled)
        })
      );
      seriesItemGroup.addItem(secondaryAxisComp);
      const options = ["line", "area", "stackedArea", "groupedColumn", "stackedColumn"].map(
        (value) => ({
          value,
          text: this.chartTranslationService.translate(getFullChartNameTranslationKey(value))
        })
      );
      const chartTypeComp = seriesItemGroup.createManagedBean(
        new AgSelect5({
          options,
          value: seriesChartType.chartType,
          onValueChange: (chartType) => this.chartController.updateSeriesChartType(col.colId, chartType)
        })
      );
      seriesItemGroup.addItem(chartTypeComp);
      this.seriesChartTypeGroupComp.addItem(seriesItemGroup);
      this.chartTypeComps.set(col.colId, chartTypeComp);
      this.secondaryAxisComps.set(col.colId, secondaryAxisComp);
    });
    this.getGui().appendChild(this.seriesChartTypeGroupComp.getGui());
  }
  refreshComps() {
    const seriesChartTypes = this.chartController.getSeriesChartTypes();
    this.selectedColIds.forEach((colId) => {
      const seriesChartType = seriesChartTypes.find((chartType) => chartType.colId === colId);
      if (!seriesChartType) {
        return;
      }
      const chartTypeComp = this.chartTypeComps.get(colId);
      const secondaryAxisComp = this.secondaryAxisComps.get(colId);
      chartTypeComp?.setValue(seriesChartType.chartType);
      secondaryAxisComp?.setValue(!!seriesChartType.secondaryAxis);
      secondaryAxisComp?.setDisabled(this.isSecondaryAxisDisabled(seriesChartType.chartType));
    });
  }
  clearComps() {
    this.chartTypeComps.clear();
    this.secondaryAxisComps.clear();
  }
  isSecondaryAxisDisabled(chartType) {
    return ["groupedColumn", "stackedColumn", "stackedArea"].includes(chartType);
  }
  destroy() {
    this.clearComps();
    this.seriesChartTypeGroupComp = this.destroyBean(this.seriesChartTypeGroupComp);
    super.destroy();
  }
};
var SeriesDataPanel = class extends DragDataPanel {
  constructor(chartController, chartOptionsService, title, allowMultipleSelect, maxSelection, valueCols, isOpen) {
    super(
      chartController,
      allowMultipleSelect,
      maxSelection,
      /* html */
      `<div id="seriesGroup"></div>`
    );
    this.chartOptionsService = chartOptionsService;
    this.title = title;
    this.valueCols = valueCols;
    this.isOpen = isOpen;
  }
  postConstruct() {
    this.groupComp = this.createBean(
      new AgGroupComponent({
        title: this.title,
        enabled: true,
        suppressEnabledCheckbox: true,
        suppressOpenCloseIcons: false,
        cssIdentifier: "charts-data",
        expanded: this.isOpen
      })
    );
    if (this.chartController.isActiveXYChart()) {
      const pairedModeToggle = this.groupComp.createManagedBean(
        new AgToggleButton22({
          label: this.chartTranslationService.translate("paired"),
          labelAlignment: "left",
          labelWidth: "flex",
          inputWidth: "flex",
          value: this.chartOptionsService.getPairedMode(),
          onValueChange: (newValue) => {
            this.chartOptionsService.setPairedMode(!!newValue);
            this.chartController.updateForGridChange({ maintainColState: true });
          }
        })
      );
      this.groupComp.addItem(pairedModeToggle);
    }
    this.createGroup(this.valueCols, this.generateGetSeriesLabel(this.valueCols), "seriesAdd", "seriesSelect");
    this.getGui().appendChild(this.groupComp.getGui());
  }
  refresh(valueCols) {
    this.valuePillSelect?.setValueFormatter(this.generateGetSeriesLabel(valueCols));
    this.valuePillSelect?.setValues(
      valueCols,
      valueCols.filter((col) => col.selected)
    );
    this.refreshValueSelect(valueCols);
  }
  generateGetSeriesLabel(valueCols) {
    if (!this.chartController.isActiveXYChart()) {
      return (col) => col.displayName ?? "";
    }
    const selectedCols = valueCols.filter((col) => col.selected);
    const isBubble = this.chartController.getChartType() === "bubble";
    const isInPairedMode = this.chartOptionsService.getPairedMode();
    const indexToAxisLabel = /* @__PURE__ */ new Map();
    indexToAxisLabel.set(0, "X");
    indexToAxisLabel.set(1, "Y");
    indexToAxisLabel.set(2, "size");
    return (col) => {
      const escapedLabel = col.displayName ?? "";
      if (!col.selected) {
        return escapedLabel;
      }
      const index = selectedCols.indexOf(col);
      if (index === -1) {
        return escapedLabel;
      }
      let axisLabel;
      if (isInPairedMode) {
        axisLabel = indexToAxisLabel.get(index % (isBubble ? 3 : 2));
      } else {
        if (index === 0) {
          axisLabel = "X";
        } else {
          axisLabel = isBubble && index % 2 === 0 ? "size" : "Y";
        }
      }
      return `${escapedLabel} (${axisLabel})`;
    };
  }
  destroy() {
    this.groupComp = this.destroyBean(this.groupComp);
    super.destroy();
  }
};
var DefaultDataPanelDef = {
  groups: [
    { type: "categories", isOpen: true },
    { type: "series", isOpen: true },
    { type: "seriesChartType", isOpen: true },
    { type: "chartSpecific", isOpen: true }
  ]
};
var ChartDataPanel = class extends Component132 {
  constructor(chartMenuContext) {
    super(
      /* html */
      `<div class="ag-chart-data-wrapper ag-scrollable-container"></div>`
    );
    this.chartMenuContext = chartMenuContext;
    this.isSwitchCategorySeriesToggled = false;
    this.restoreSwitchCategorySeriesToggleFocus = false;
    this.panels = [];
    this.chartController = chartMenuContext.chartController;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
    this.chartService = beans.chartService;
  }
  postConstruct() {
    this.createSwitchCategorySeriesToggle();
    this.isSwitchCategorySeriesToggled = this.chartController.isCategorySeriesSwitched();
    this.updatePanels();
    const listener = this.updatePanels.bind(this);
    this.addManagedListeners(this.chartController, {
      chartModelUpdate: listener,
      chartApiUpdate: listener
    });
  }
  destroy() {
    this.clearPanelComponents();
    super.destroy();
  }
  updatePanels() {
    const currentChartType = this.chartType;
    const isSwitchCategorySeriesToggledCurrent = this.isSwitchCategorySeriesToggled;
    const { dimensionCols, valueCols } = this.chartController.getColStateForMenu();
    this.chartType = this.chartController.getChartType();
    this.isSwitchCategorySeriesToggled = this.chartController.isCategorySeriesSwitched();
    const hasChangedSwitchCategorySeries = this.isSwitchCategorySeriesToggled !== isSwitchCategorySeriesToggledCurrent;
    if (this.canRefresh(currentChartType, this.chartType) && !hasChangedSwitchCategorySeries) {
      this.categoriesDataPanel?.refresh(dimensionCols);
      this.seriesDataPanel?.refresh(valueCols);
      this.seriesChartTypePanel?.refresh(valueCols);
      this.chartSpecificPanel?.refresh();
    } else {
      this.recreatePanels(dimensionCols, valueCols);
    }
    const isSwitchCategorySeriesDisplayed = supportsInvertedCategorySeries(this.chartType) && this.chartService.isEnterprise() && !this.chartController.isGrouping();
    _setDisplayed23(this.switchCategorySeriesToggle.getGui(), isSwitchCategorySeriesDisplayed);
    if (hasChangedSwitchCategorySeries) {
      this.switchCategorySeriesToggle?.setValue(this.chartController.isCategorySeriesSwitched());
    }
    if (this.restoreSwitchCategorySeriesToggleFocus) {
      this.restoreSwitchCategorySeriesToggleFocus = false;
      if (isSwitchCategorySeriesDisplayed) {
        this.switchCategorySeriesToggle.getFocusableElement().focus();
      }
    }
  }
  canRefresh(oldChartType, newChartType) {
    if (oldChartType === void 0)
      return false;
    if (oldChartType === newChartType) {
      return true;
    }
    const isCombo = (chartType) => ["columnLineCombo", "areaColumnCombo", "customCombo"].includes(chartType);
    if (isCombo(oldChartType) && isCombo(newChartType)) {
      return true;
    }
    return false;
  }
  recreatePanels(dimensionCols, valueCols) {
    this.clearPanelComponents();
    const { chartType } = this;
    if (!chartType)
      return;
    const isCategorySeriesSwitched = this.chartController.isCategorySeriesSwitched();
    this.getDataPanelDef().groups?.forEach(({ type, isOpen }) => {
      if (type === (isCategorySeriesSwitched ? "series" : "categories")) {
        this.categoriesDataPanel = this.createBean(
          new CategoriesDataPanel(
            this.chartController,
            this.getCategoryGroupTitle(isCategorySeriesSwitched),
            this.getCategoryGroupMultipleSelect(chartType, isCategorySeriesSwitched),
            dimensionCols,
            isOpen
          )
        );
        this.panels.push(this.categoriesDataPanel);
      } else if (type === (isCategorySeriesSwitched ? "categories" : "series")) {
        this.seriesDataPanel = this.createBean(
          new SeriesDataPanel(
            this.chartController,
            this.chartMenuContext.chartOptionsService,
            this.getSeriesGroupTitle(isCategorySeriesSwitched),
            this.getSeriesGroupMultipleSelect(chartType, isCategorySeriesSwitched),
            this.getSeriesGroupMaxSelection(chartType, isCategorySeriesSwitched),
            valueCols,
            isOpen
          )
        );
        this.panels.push(this.seriesDataPanel);
      } else if (type === "seriesChartType") {
        if (this.chartController.isComboChart()) {
          this.seriesChartTypePanel = this.createBean(
            new SeriesChartTypePanel(this.chartController, valueCols, isOpen)
          );
          this.panels.push(this.seriesChartTypePanel);
        }
      } else if (type === "chartSpecific") {
        this.chartSpecificPanel = this.createBean(new ChartSpecificDataPanel(this.chartMenuContext, isOpen));
        this.panels.push(this.chartSpecificPanel);
      } else {
        _warnOnce9(`Invalid charts data panel group name supplied: '${type}'`);
      }
    });
    (isCategorySeriesSwitched ? this.categoriesDataPanel : this.seriesDataPanel)?.addItem(
      this.switchCategorySeriesToggle.getGui()
    );
    this.addPanelComponents();
  }
  addPanelComponents() {
    if (!this.panels.length) {
      return;
    }
    const eDocument = _getDocument32(this.gos);
    const fragment = eDocument.createDocumentFragment();
    for (const panel of this.panels) {
      panel.addCssClass("ag-chart-data-section");
      fragment.appendChild(panel.getGui());
    }
    this.getGui().appendChild(fragment);
  }
  clearPanelComponents() {
    const eGui = this.getGui();
    this.panels.forEach((panel) => {
      eGui.removeChild(panel.getGui());
      this.destroyBean(panel);
    });
    this.panels = [];
  }
  getDataPanelDef() {
    return this.gos.get("chartToolPanelsDef")?.dataPanel ?? DefaultDataPanelDef;
  }
  getCategoryGroupTitle(isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return this.chartTranslationService.translate("seriesLabels");
    return this.chartTranslationService.translate(this.chartController.isActiveXYChart() ? "labels" : "categories");
  }
  getCategoryGroupMultipleSelect(chartType, isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return false;
    return getMaxNumCategories(chartType) !== 1;
  }
  getSeriesGroupTitle(isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return this.chartTranslationService.translate("categoryValues");
    return this.chartTranslationService.translate(this.chartController.isActiveXYChart() ? "xyValues" : "series");
  }
  getSeriesGroupMultipleSelect(chartType, isCategorySeriesSwitched) {
    return this.getSeriesGroupMaxSelection(chartType, isCategorySeriesSwitched) !== 1;
  }
  getSeriesGroupMaxSelection(chartType, isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return void 0;
    return getMaxNumSeries(chartType);
  }
  createSwitchCategorySeriesToggle() {
    this.switchCategorySeriesToggle = this.createManagedBean(
      new AgToggleButton3({
        label: this.chartTranslationService.translate("switchCategorySeries"),
        labelAlignment: "left",
        labelWidth: "flex",
        inputWidth: "flex",
        value: this.chartController.isCategorySeriesSwitched(),
        onValueChange: (value) => {
          this.restoreSwitchCategorySeriesToggleFocus = true;
          this.chartController.switchCategorySeries(value);
        }
      })
    );
  }
};
var AgAngleSelect = class extends AgAbstractLabel2 {
  constructor(config) {
    super(
      config,
      /* html */
      `<div class="ag-angle-select">
            <div data-ref="eLabel"></div>
            <div class="ag-wrapper ag-angle-select-wrapper">
                <div class="ag-angle-select-field">
                    <div data-ref="eParentCircle" class="ag-angle-select-parent-circle">
                        <div data-ref="eChildCircle" class="ag-angle-select-child-circle"></div>
                    </div>
                </div>
                <ag-input-number-field data-ref="eAngleValue"></ag-input-number-field>
            </div>
        </div>`,
      [AgInputNumberFieldSelector3]
    );
    this.eLabel = RefPlaceholder82;
    this.eParentCircle = RefPlaceholder82;
    this.eChildCircle = RefPlaceholder82;
    this.eAngleValue = RefPlaceholder82;
    this.radius = 0;
    this.offsetX = 0;
    this.offsetY = 0;
  }
  wireBeans(beans) {
    this.dragService = beans.dragService;
  }
  postConstruct() {
    super.postConstruct();
    const { value, onValueChange } = this.config;
    if (value != null) {
      this.setValue(value, void 0, true);
    }
    if (onValueChange != null) {
      this.onValueChange(onValueChange);
    }
    this.dragListener = {
      eElement: this.eParentCircle,
      dragStartPixels: 0,
      onDragStart: () => {
        this.parentCircleRect = this.eParentCircle.getBoundingClientRect();
      },
      onDragging: (e) => this.calculateAngleDrag(e),
      onDragStop: () => {
      }
    };
    this.dragService.addDragSource(this.dragListener);
    this.eAngleValue.setLabel("").setLabelWidth(5).setInputWidth(45).setMin(0).setMax(360).setValue(`${this.degrees}`).onValueChange((value2) => {
      if (value2 == null || value2 === "") {
        value2 = "0";
      }
      value2 = this.eAngleValue.normalizeValue(value2);
      let floatValue = parseFloat(value2);
      if (floatValue > 180) {
        floatValue = floatValue - 360;
      }
      this.setValue(floatValue);
    });
    this.updateNumberInput();
    if (_exists23(this.getValue())) {
      this.eAngleValue.setValue(this.normalizeNegativeValue(this.getValue()).toString());
    }
    this.addManagedListeners(this, {
      fieldValueChanged: () => {
        if (this.eAngleValue.getInputElement().contains(_getActiveDomElement23(this.gos))) {
          return;
        }
        this.updateNumberInput();
      }
    });
  }
  updateNumberInput() {
    const normalizedValue = this.normalizeNegativeValue(this.getValue());
    this.eAngleValue.setValue(normalizedValue.toString());
  }
  positionChildCircle(radians) {
    const rect = this.parentCircleRect || { width: 24, height: 24 };
    const eChildCircle = this.eChildCircle;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    eChildCircle.style.left = `${centerX + Math.cos(radians) * 8}px`;
    eChildCircle.style.top = `${centerY + Math.sin(radians) * 8}px`;
  }
  calculatePolar() {
    const x = this.offsetX;
    const y = this.offsetY;
    const radians = Math.atan2(y, x);
    this.degrees = this.toDegrees(radians);
    this.radius = Math.sqrt(x * x + y * y);
    this.positionChildCircle(radians);
  }
  calculateCartesian() {
    const radians = this.toRadians(this.getValue());
    const radius = this.getRadius();
    this.setOffsetX(Math.cos(radians) * radius).setOffsetY(Math.sin(radians) * radius);
  }
  setOffsetX(offset) {
    if (this.offsetX !== offset) {
      this.offsetX = offset;
      this.calculatePolar();
    }
    return this;
  }
  setOffsetY(offset) {
    if (this.offsetY !== offset) {
      this.offsetY = offset;
      this.calculatePolar();
    }
    return this;
  }
  calculateAngleDrag(e) {
    const rect = this.parentCircleRect;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const dx = x - centerX;
    const dy = y - centerY;
    const radians = Math.atan2(dy, dx);
    this.setValue(radians, true);
  }
  toDegrees(radians) {
    return radians / Math.PI * 180;
  }
  toRadians(degrees) {
    return degrees / 180 * Math.PI;
  }
  normalizeNegativeValue(degrees) {
    return degrees < 0 ? 360 + degrees : degrees;
  }
  normalizeAngle180(radians) {
    radians %= Math.PI * 2;
    if (radians < -Math.PI) {
      radians += Math.PI * 2;
    } else if (radians >= Math.PI) {
      radians -= Math.PI * 2;
    }
    return radians;
  }
  getRadius() {
    return this.radius;
  }
  setRadius(r) {
    if (this.radius === r) {
      return this;
    }
    this.radius = r;
    this.calculateCartesian();
    return this;
  }
  onValueChange(callbackFn) {
    this.addManagedListeners(this, {
      fieldValueChanged: () => {
        callbackFn(this.degrees);
      }
    });
    return this;
  }
  getValue(radians) {
    return radians ? this.toRadians(this.degrees) : this.degrees;
  }
  setValue(degrees, radians, silent) {
    let radiansValue;
    if (!radians) {
      radiansValue = this.normalizeAngle180(this.toRadians(degrees));
    } else {
      radiansValue = degrees;
    }
    degrees = this.toDegrees(radiansValue);
    if (this.degrees !== degrees) {
      this.degrees = Math.floor(degrees);
      this.calculateCartesian();
      this.positionChildCircle(radiansValue);
      if (!silent) {
        this.dispatchLocalEvent({ type: "fieldValueChanged" });
      }
    }
    return this;
  }
  setWidth(width) {
    _setFixedWidth(this.getGui(), width);
    return this;
  }
  setDisabled(disabled) {
    super.setDisabled(disabled);
    this.eAngleValue.setDisabled(disabled);
    return this;
  }
  destroy() {
    this.dragService.removeDragSource(this.dragListener);
    super.destroy();
  }
};
var FontPanel = class extends Component142 {
  constructor(params) {
    super();
    this.params = params;
    this.fontGroup = RefPlaceholder92;
    this.activeComps = [];
    this.chartOptions = params.chartMenuParamsFactory.getChartOptions();
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const {
      cssIdentifier = "charts-format-sub-level",
      name: title,
      enabled,
      onEnableChange,
      suppressEnabledCheckbox,
      chartMenuParamsFactory,
      keyMapper
    } = this.params;
    const fontGroupParams = {
      cssIdentifier,
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title,
      enabled,
      suppressEnabledCheckbox: true,
      onEnableChange: (enabled2) => {
        if (onEnableChange) {
          onEnableChange(enabled2);
        }
      },
      useToggle: !suppressEnabledCheckbox
    };
    this.setTemplate(
      /* html */
      `<div class="ag-font-panel">
        <ag-group-component data-ref="fontGroup">
            <ag-select data-ref="familySelect"></ag-select>
            <ag-select data-ref="weightStyleSelect"></ag-select>
            <div class="ag-charts-font-size-color">
                <ag-select data-ref="sizeSelect"></ag-select>
                <ag-color-picker data-ref="colorPicker"></ag-color-picker>
            </div>
        </ag-group-component>
    </div>`,
      [AgGroupComponentSelector, AgSelectSelector, AgColorPickerSelector],
      {
        fontGroup: fontGroupParams,
        familySelect: this.getFamilySelectParams(),
        weightStyleSelect: this.getWeightStyleSelectParams(),
        sizeSelect: this.getSizeSelectParams(),
        colorPicker: chartMenuParamsFactory.getDefaultColorPickerParams(keyMapper("color"))
      }
    );
    this.addOrRemoveCssClass("ag-font-panel-no-header", !title);
  }
  addItem(comp, prepend) {
    if (prepend) {
      this.fontGroup.prependItem(comp);
    } else {
      this.fontGroup.addItem(comp);
    }
    this.activeComps.push(comp);
  }
  setEnabled(enabled) {
    this.fontGroup.setEnabled(enabled);
  }
  getFamilySelectParams() {
    const families = [
      "Arial, sans-serif",
      "Aria Black, sans-serif",
      "Book Antiqua,  serif",
      "Charcoal, sans-serif",
      "Comic Sans MS, cursive",
      "Courier, monospace",
      "Courier New, monospace",
      "Gadget, sans-serif",
      "Geneva, sans-serif",
      "Helvetica, sans-serif",
      "Impact, sans-serif",
      "Lucida Console, monospace",
      "Lucida Grande, sans-serif",
      "Lucida Sans Unicode,  sans-serif",
      "Monaco, monospace",
      "Palatino Linotype, serif",
      "Palatino, serif",
      "Times New Roman, serif",
      "Times, serif",
      "Verdana, sans-serif"
    ];
    const family = this.getInitialFontValue("fontFamily");
    let initialValue = families[0];
    if (family) {
      const lowerCaseValues = families.map((f) => f.toLowerCase());
      const valueIndex = lowerCaseValues.indexOf(family.toLowerCase());
      if (valueIndex >= 0) {
        initialValue = families[valueIndex];
      } else {
        const capitalisedFontValue = _capitalise(family);
        families.push(capitalisedFontValue);
        initialValue = capitalisedFontValue;
      }
    }
    const options = families.sort().map((value) => ({ value, text: value }));
    return this.params.chartMenuParamsFactory.getDefaultSelectParamsWithoutValueParams(
      "font",
      options,
      `${initialValue}`,
      (newValue) => this.setFont({ fontFamily: newValue })
    );
  }
  getSizeSelectParams() {
    const sizes = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36];
    const size = this.getInitialFontValue("fontSize");
    if (!_includes6(sizes, size)) {
      sizes.push(size);
    }
    const options = sizes.sort((a, b) => a - b).map((value) => ({ value: `${value}`, text: `${value}` }));
    return this.params.chartMenuParamsFactory.getDefaultSelectParamsWithoutValueParams(
      "size",
      options,
      `${size}`,
      (newValue) => this.setFont({ fontSize: parseInt(newValue, 10) })
    );
  }
  getWeightStyleSelectParams() {
    const weight = this.getInitialFontValue("fontWeight") ?? "normal";
    const style = this.getInitialFontValue("fontStyle") ?? "normal";
    const weightStyles = [
      { name: "normal", weight: "normal", style: "normal" },
      { name: "bold", weight: "bold", style: "normal" },
      { name: "italic", weight: "normal", style: "italic" },
      { name: "boldItalic", weight: "bold", style: "italic" }
    ];
    let selectedOption = weightStyles.find((x) => x.weight === weight && x.style === style);
    if (!selectedOption) {
      selectedOption = { name: "predefined", weight, style };
      weightStyles.unshift(selectedOption);
    }
    const options = weightStyles.map((ws) => ({
      value: ws.name,
      text: this.chartTranslationService.translate(ws.name)
    }));
    return this.params.chartMenuParamsFactory.getDefaultSelectParamsWithoutValueParams(
      "weight",
      options,
      selectedOption.name,
      (newValue) => {
        const selectedWeightStyle = weightStyles.find((x) => x.name === newValue);
        this.setFont({ fontWeight: selectedWeightStyle.weight, fontStyle: selectedWeightStyle.style });
      }
    );
  }
  destroyActiveComps() {
    this.activeComps.forEach((comp) => {
      _removeFromParent32(comp.getGui());
      this.destroyBean(comp);
    });
  }
  destroy() {
    this.destroyActiveComps();
    super.destroy();
  }
  setFont(font) {
    const { keyMapper } = this.params;
    Object.entries(font).forEach(([fontKey, value]) => {
      if (value) {
        this.chartOptions.setValue(keyMapper(fontKey), value);
      }
    });
  }
  getInitialFontValue(fontKey) {
    const { keyMapper } = this.params;
    return this.chartOptions.getValue(keyMapper(fontKey));
  }
};
var AxisTicksPanel = class extends Component15 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const { chartMenuUtils } = this;
    const axisTicksGroupParams = chartMenuUtils.addEnableParams("tick.enabled", {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("ticks"),
      suppressEnabledCheckbox: true,
      useToggle: true
    });
    const axisTicksColorPickerParams = chartMenuUtils.getDefaultColorPickerParams("tick.stroke");
    const axisTicksWidthSliderParams = chartMenuUtils.getDefaultSliderParams("tick.width", "width", 10);
    const axisTicksSizeSliderParams = chartMenuUtils.getDefaultSliderParams("tick.size", "length", 30);
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="axisTicksGroup">
                <ag-color-picker data-ref="axisTicksColorPicker"></ag-color-picker>
                <ag-slider data-ref="axisTicksWidthSlider"></ag-slider>
                <ag-slider data-ref="axisTicksSizeSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgColorPickerSelector, AgSliderSelector],
      {
        axisTicksGroup: axisTicksGroupParams,
        axisTicksColorPicker: axisTicksColorPickerParams,
        axisTicksWidthSlider: axisTicksWidthSliderParams,
        axisTicksSizeSlider: axisTicksSizeSliderParams
      }
    );
  }
};
var GridLinePanel = class extends Component16 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.chartOptions = chartMenuUtils.getChartOptions();
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const gridLineGroupParams = this.chartMenuUtils.addEnableParams("gridLine.enabled", {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("gridLines"),
      suppressEnabledCheckbox: true,
      useToggle: true
    });
    const gridLineColorPickerParams = this.getGridLineColorPickerParams("color");
    const gridLineWidthSliderParams = this.getGridLineWidthSliderParams("thickness");
    const gridLineLineDashSliderParams = this.getGridLineDashSliderParams("lineDash");
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="gridLineGroup">
                <ag-color-picker data-ref="gridLineColorPicker"></ag-color-picker>
                <ag-slider data-ref="gridLineWidthSlider"></ag-slider>
                <ag-slider data-ref="gridLineLineDashSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgColorPickerSelector, AgSliderSelector],
      {
        gridLineGroup: gridLineGroupParams,
        gridLineColorPicker: gridLineColorPickerParams,
        gridLineWidthSlider: gridLineWidthSliderParams,
        gridLineLineDashSlider: gridLineLineDashSliderParams
      }
    );
  }
  getGridLineColorPickerParams(labelKey) {
    return this.chartMenuUtils.getDefaultColorPickerParams("gridLine.style", labelKey, {
      formatInputValue: (value) => {
        return value?.[0]?.stroke;
      },
      parseInputValue: (value) => {
        const styles = this.chartOptions.getValue("gridLine.style") ?? [];
        if (styles.length === 0)
          return [{ stroke: value, lineDash: [] }];
        return [{ ...styles[0], stroke: value }];
      }
    });
  }
  getGridLineWidthSliderParams(labelKey) {
    return this.chartMenuUtils.getDefaultSliderParams("gridLine.width", labelKey, 10);
  }
  getGridLineDashSliderParams(labelKey) {
    const initialStyles = this.chartOptions.getValue("gridLine.style");
    const initialValue = initialStyles?.[0]?.lineDash?.[0];
    const params = this.chartMenuUtils.getDefaultSliderParamsWithoutValueParams(initialValue ?? 0, labelKey, 30);
    params.onValueChange = (value) => {
      const stroke = this.chartOptions.getValue("gridLine.style.0.stroke");
      this.chartOptions.setValue("gridLine.style", [
        { lineDash: [value], stroke }
      ]);
    };
    return params;
  }
};
var DEFAULT_TIME_AXIS_FORMAT = "%d %B %Y";
var CartesianAxisPanel = class extends Component17 {
  constructor(axisType, options) {
    super();
    this.axisType = axisType;
    this.options = options;
    this.axisGroup = RefPlaceholder102;
    this.axisTypeSelect = RefPlaceholder102;
    this.axisPositionSelect = RefPlaceholder102;
    this.axisTimeFormatSelect = RefPlaceholder102;
    this.activePanels = [];
    this.axisLabelUpdateFuncs = [];
    const { chartOptionsService, seriesType } = options;
    this.chartOptionsSeriesProxy = chartOptionsService.getSeriesOptionsProxy(() => seriesType);
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const {
      isExpandedOnInit: expanded,
      chartOptionsService,
      chartController,
      registerGroupComponent
    } = this.options;
    const labelKey = this.axisType;
    const axisGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.translate(labelKey),
      expanded,
      suppressEnabledCheckbox: true
    };
    const chartAxisOptionsProxy = chartOptionsService.getCartesianAxisOptionsProxy(this.axisType);
    const chartAxisOptions = this.createManagedBean(new ChartMenuParamsFactory(chartAxisOptionsProxy));
    const chartAxisThemeOverrides = this.createManagedBean(
      new ChartMenuParamsFactory(chartOptionsService.getCartesianAxisThemeOverridesProxy(this.axisType))
    );
    const axisTypeSelectParams = this.getAxisTypeSelectParams(
      chartAxisOptions,
      chartOptionsService.getCartesianAxisAppliedThemeOverridesProxy(this.axisType)
    );
    const axisPositionSelectParams = this.getAxisPositionSelectParams(chartAxisOptions);
    const axisTimeFormatSelectParams = this.getAxisTimeFormatSelectParams(chartAxisOptions);
    const axisColorInputParams = this.getAxisColorInputParams(chartAxisThemeOverrides);
    const axisLineWidthSliderParams = this.getAxisLineWidthSliderParams(chartAxisThemeOverrides);
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="axisGroup">
                <ag-select data-ref="axisTypeSelect"></ag-select>
                <ag-select data-ref="axisTimeFormatSelect"></ag-select>
                <ag-select data-ref="axisPositionSelect"></ag-select>
                <ag-color-picker data-ref="axisColorInput"></ag-color-picker>
                <ag-slider data-ref="axisLineWidthSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgSelectSelector2, AgColorPickerSelector, AgSliderSelector],
      {
        axisGroup: axisGroupParams,
        axisTypeSelect: axisTypeSelectParams ?? void 0,
        axisPositionSelect: axisPositionSelectParams ?? void 0,
        axisTimeFormatSelect: axisTimeFormatSelectParams ?? void 0,
        axisColorInput: axisColorInputParams,
        axisLineWidthSlider: axisLineWidthSliderParams
      }
    );
    registerGroupComponent(this.axisGroup);
    this.axisTypeSelect.setDisplayed(!!axisTypeSelectParams.options?.length);
    if (!axisPositionSelectParams)
      this.removeTemplateComponent(this.axisPositionSelect);
    const updateTimeFormatVisibility = () => {
      const isTimeAxis = chartAxisOptionsProxy.getValue("type") === "time";
      _setDisplayed33(this.axisTimeFormatSelect.getGui(), isTimeAxis);
    };
    if (!axisTimeFormatSelectParams) {
      this.removeTemplateComponent(this.axisTimeFormatSelect);
    } else {
      updateTimeFormatVisibility();
      this.addManagedEventListeners({
        chartOptionsChanged: () => {
          updateTimeFormatVisibility();
        }
      });
    }
    this.initGridLines(chartAxisThemeOverrides);
    this.initAxisTicks(chartAxisThemeOverrides);
    this.initAxisLabels(chartAxisThemeOverrides);
    const updateAxisLabelRotations = () => this.axisLabelUpdateFuncs.forEach((func) => func());
    this.addManagedListeners(chartController, {
      chartUpdated: updateAxisLabelRotations,
      chartModelUpdate: () => setTimeout(() => {
        if (!this.isAlive()) {
          return;
        }
        this.refreshAxisTypeSelect(chartAxisOptions);
        updateTimeFormatVisibility();
      })
    });
  }
  getAxisTypeSelectParams(chartAxisOptions, chartAxisAppliedThemeOverrides) {
    const chartOptions = chartAxisOptions.getChartOptions();
    const axisTypeSelectOptions = this.getAxisTypeSelectOptions();
    const params = chartAxisOptions.getDefaultSelectParams("type", "axisType", axisTypeSelectOptions);
    params.onValueChange = (value) => {
      const previousAxisType = chartOptions.getValue("type");
      if (value === previousAxisType)
        return;
      const previousAxisThemeOverrides = chartAxisAppliedThemeOverrides.getValue("*");
      const previousAxisIsTimeAxis = previousAxisType === "time";
      const updatedAxisIsTimeAxis = value === "time";
      const updatedLabelFormat = previousAxisIsTimeAxis !== updatedAxisIsTimeAxis ? updatedAxisIsTimeAxis ? DEFAULT_TIME_AXIS_FORMAT : void 0 : null;
      this.options.chartOptionsService.setCartesianCategoryAxisType(this.axisType, value);
      if (updatedLabelFormat !== null) {
        const existingLabel = chartOptions.getValue("label") ?? {};
        chartOptions.setValue("label", {
          ...existingLabel,
          format: updatedLabelFormat
        });
      }
      chartAxisAppliedThemeOverrides.setValue("*", previousAxisThemeOverrides);
    };
    return params;
  }
  refreshAxisTypeSelect(chartAxisOptions) {
    const options = this.getAxisTypeSelectOptions();
    const hasOptions = !!options.length;
    this.axisTypeSelect.setDisplayed(hasOptions);
    if (!hasOptions) {
      return;
    }
    this.axisTypeSelect.clearOptions().addOptions(options).setValue(chartAxisOptions.getChartOptions().getValue("type"));
  }
  getAxisTypeSelectOptions() {
    const { chartController } = this.options;
    const chartType = chartController.getChartType();
    const supportsNumericalAxis = () => {
      const testDatum = chartController.getChartData()[0];
      if (!testDatum) {
        return false;
      }
      return chartController.getSelectedDimensions().every((col) => !isNaN(parseFloat(testDatum[col.colId])));
    };
    if (["heatmap", "histogram", "boxPlot", "rangeBar", "scatter", "bubble"].includes(chartType) || chartController.isGrouping() || !this.isCategoryAxis() || chartController.isCategorySeriesSwitched() || !supportsNumericalAxis()) {
      return [];
    }
    return ["category", "number", "time"].map((value) => ({
      value,
      text: this.translate(value)
    }));
  }
  isCategoryAxis() {
    const isHorizontal = this.chartOptionsSeriesProxy.getValue("direction") === "horizontal";
    return isHorizontal && this.axisType === "yAxis" || !isHorizontal && this.axisType === "xAxis";
  }
  getAxisPositionSelectParams(chartAxisOptions) {
    const axisPositionSelectOptions = ((chartType, axisType) => {
      switch (chartType) {
        case "heatmap":
          return null;
        default:
          switch (axisType) {
            case "xAxis":
              return [
                { value: "top", text: this.translate("top") },
                { value: "bottom", text: this.translate("bottom") }
              ];
            case "yAxis":
              return [
                { value: "left", text: this.translate("left") },
                { value: "right", text: this.translate("right") }
              ];
          }
      }
    })(this.options.chartController.getChartType(), this.axisType);
    if (!axisPositionSelectOptions)
      return null;
    return chartAxisOptions.getDefaultSelectParams("position", "position", axisPositionSelectOptions);
  }
  getAxisTimeFormatSelectParams(chartAxisOptions) {
    if (!this.isCategoryAxis()) {
      return null;
    }
    const axisTimeFormatSelectOptions = [
      { value: "%d/%m/%Y", text: this.translate("timeFormatSlashesDDMMYYYY") },
      { value: "%m/%d/%Y", text: this.translate("timeFormatSlashesMMDDYYYY") },
      { value: "%d/%m/%y", text: this.translate("timeFormatSlashesDDMMYY") },
      { value: "%m/%d/%y", text: this.translate("timeFormatSlashesMMDDYY") },
      { value: "%d.%e.%y", text: this.translate("timeFormatDotsDDMYY") },
      { value: "%e.%d.%y", text: this.translate("timeFormatDotsMDDYY") },
      { value: "%Y-%m-%d", text: this.translate("timeFormatDashesYYYYMMDD") },
      { value: "%d %B %Y", text: this.translate("timeFormatSpacesDDMMMMYYYY") },
      { value: "%H:%M:%S", text: this.translate("timeFormatHHMMSS") },
      { value: "%I:%M:%S %p", text: this.translate("timeFormatHHMMSSAmPm") }
    ];
    return chartAxisOptions.getDefaultSelectParams("label.format", "timeFormat", axisTimeFormatSelectOptions);
  }
  getAxisColorInputParams(chartAxisThemeOverrides) {
    return chartAxisThemeOverrides.getDefaultColorPickerParams("line.stroke");
  }
  getAxisLineWidthSliderParams(chartAxisThemeOverrides) {
    const chartOptions = chartAxisThemeOverrides.getChartOptions();
    const getAxisLineWidth = () => {
      const isAxisLineEnabled = chartOptions.getValue("line.enabled");
      if (!isAxisLineEnabled)
        return null;
      return chartOptions.getValue("line.width");
    };
    const setAxisLineWidth = (value) => {
      chartOptions.setValues([
        { expression: "line.enabled", value: value != null },
        { expression: "line.width", value: value ?? 0 }
      ]);
    };
    const axisLineWidthSliderParams = chartAxisThemeOverrides.getDefaultSliderParamsWithoutValueParams(
      getAxisLineWidth() ?? 0,
      "thickness",
      10
    );
    axisLineWidthSliderParams.onValueChange = (newValue) => {
      setAxisLineWidth(newValue === 0 ? null : newValue);
    };
    return axisLineWidthSliderParams;
  }
  initGridLines(chartAxisThemeOverrides) {
    const chartType = this.options.chartController.getChartType();
    if (chartType === "heatmap") {
      return;
    }
    const gridLineComp = this.createBean(new GridLinePanel(chartAxisThemeOverrides));
    this.axisGroup.addItem(gridLineComp);
    this.activePanels.push(gridLineComp);
  }
  initAxisTicks(chartAxisThemeOverrides) {
    if (!this.hasConfigurableAxisTicks())
      return;
    const axisTicksComp = this.createBean(new AxisTicksPanel(chartAxisThemeOverrides));
    this.axisGroup.addItem(axisTicksComp);
    this.activePanels.push(axisTicksComp);
  }
  hasConfigurableAxisTicks() {
    const chartType = this.options.chartController.getChartType();
    switch (chartType) {
      case "radarLine":
      case "radarArea":
      case "rangeBar":
      case "boxPlot":
      case "waterfall":
        return false;
      default:
        return true;
    }
  }
  initAxisLabels(chartAxisThemeOverrides) {
    const params = {
      name: this.translate("labels"),
      enabled: true,
      suppressEnabledCheckbox: true,
      chartMenuParamsFactory: chartAxisThemeOverrides,
      keyMapper: (key) => `label.${key}`
    };
    const labelPanelComp = this.createBean(new FontPanel(params));
    this.axisGroup.addItem(labelPanelComp);
    this.activePanels.push(labelPanelComp);
    this.addAdditionalLabelComps(labelPanelComp, chartAxisThemeOverrides);
  }
  addAdditionalLabelComps(labelPanelComp, chartAxisThemeOverrides) {
    this.addLabelPadding(labelPanelComp, chartAxisThemeOverrides);
    const rotationComp = this.createRotationWidget("labelRotation", chartAxisThemeOverrides);
    const autoRotateCb = this.initLabelRotation(rotationComp, chartAxisThemeOverrides);
    labelPanelComp.addItem(autoRotateCb);
    labelPanelComp.addItem(rotationComp);
  }
  initLabelRotation(rotationComp, chartAxisThemeOverrides) {
    const chartOptions = chartAxisThemeOverrides.getChartOptions();
    const getLabelRotationValue = () => {
      return chartOptions.getValue("label.rotation");
    };
    const getLabelAutoRotateValue = () => {
      return chartOptions.getValue("label.autoRotate");
    };
    const updateAutoRotate = (autoRotate2) => {
      if (autoRotate2)
        this.prevRotation = getLabelRotationValue();
      chartOptions.setValues([
        { expression: "label.autoRotate", value: autoRotate2 },
        // Clear the rotation option when activating auto-rotate, reinstate the previous value when deactivating
        { expression: "label.rotation", value: autoRotate2 ? void 0 : this.prevRotation }
      ]);
      rotationComp.setDisplayed(!autoRotate2);
    };
    const rotation = getLabelRotationValue();
    const autoRotate = typeof rotation === "number" ? false : getLabelAutoRotateValue();
    const autoRotateCheckbox = this.createBean(
      new AgCheckbox2({
        label: this.translate("autoRotate"),
        value: autoRotate,
        onValueChange: updateAutoRotate
      })
    );
    rotationComp.setDisplayed(!autoRotate);
    return autoRotateCheckbox;
  }
  createRotationWidget(labelKey, chartAxisThemeOverrides) {
    const chartOptions = chartAxisThemeOverrides.getChartOptions();
    const getLabelRotationValue = () => {
      return chartOptions.getValue("label.rotation");
    };
    const setLabelRotationValue = (value) => {
      return chartOptions.setValue("label.rotation", value);
    };
    const degreesSymbol = String.fromCharCode(176);
    const label = `${this.chartTranslationService.translate(labelKey)} ${degreesSymbol}`;
    const angleSelect = new AgAngleSelect({
      label,
      labelWidth: "flex",
      value: getLabelRotationValue() ?? 0,
      onValueChange: setLabelRotationValue
    });
    this.axisLabelUpdateFuncs.push(() => {
      angleSelect.setValue(getLabelRotationValue() ?? 0);
    });
    return this.createBean(angleSelect);
  }
  addLabelPadding(labelPanelComp, chartAxisThemeOverrides) {
    const labelPaddingSlider = this.createBean(
      new AgSlider(chartAxisThemeOverrides.getDefaultSliderParams("label.padding", "padding", 30))
    );
    labelPanelComp.addItem(labelPaddingSlider);
  }
  translate(key) {
    return this.chartTranslationService.translate(key);
  }
  removeTemplateComponent(component) {
    _removeFromParent4(component.getGui());
    this.destroyBean(component);
  }
  destroyActivePanels() {
    this.activePanels.forEach((panel) => {
      _removeFromParent4(panel.getGui());
      this.destroyBean(panel);
    });
  }
  destroy() {
    this.destroyActivePanels();
    super.destroy();
  }
};
var PolarAxisPanel = class extends Component18 {
  constructor(options) {
    super();
    this.options = options;
    this.axisGroup = RefPlaceholder112;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const { isExpandedOnInit: expanded, chartAxisMenuParamsFactory, registerGroupComponent } = this.options;
    const axisGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.translate("polarAxis"),
      expanded,
      suppressEnabledCheckbox: true
    };
    const axisColorInputParams = chartAxisMenuParamsFactory.getDefaultColorPickerParams("line.stroke");
    const axisLineWidthSliderParams = chartAxisMenuParamsFactory.getDefaultSliderParams(
      "line.width",
      "thickness",
      10
    );
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="axisGroup">
                <ag-color-picker data-ref="axisColorInput"></ag-color-picker>
                <ag-slider data-ref="axisLineWidthSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgColorPickerSelector, AgSliderSelector],
      {
        axisGroup: axisGroupParams,
        axisColorInput: axisColorInputParams,
        axisLineWidthSlider: axisLineWidthSliderParams
      }
    );
    registerGroupComponent(this.axisGroup);
    this.initAxis();
    this.initAxisLabels();
    this.initRadiusAxis();
  }
  initAxis() {
    const chartType = this.options.chartController.getChartType();
    const hasConfigurableAxisShape = ["radarLine", "radarArea"].includes(chartType);
    if (hasConfigurableAxisShape) {
      const options = [
        { value: "circle", text: this.translate("circle") },
        { value: "polygon", text: this.translate("polygon") }
      ];
      this.axisGroup.addItem(
        this.createSelect({
          labelKey: "shape",
          options,
          property: "shape"
        })
      );
    }
    if (chartType !== "pie") {
      this.axisGroup.addItem(
        this.createSlider({
          labelKey: "innerRadius",
          defaultMaxValue: 1,
          property: "innerRadiusRatio"
        })
      );
    }
  }
  initAxisLabels() {
    const params = {
      name: this.translate("labels"),
      enabled: true,
      suppressEnabledCheckbox: true,
      chartMenuParamsFactory: this.options.chartAxisMenuParamsFactory,
      keyMapper: (key) => `label.${key}`
    };
    const labelPanelComp = this.createManagedBean(new FontPanel(params));
    const labelOrientationComp = this.createOrientationWidget();
    labelPanelComp.addItem(labelOrientationComp);
    this.axisGroup.addItem(labelPanelComp);
  }
  createOrientationWidget() {
    const options = [
      { value: "fixed", text: this.translate("fixed") },
      { value: "parallel", text: this.translate("parallel") },
      { value: "perpendicular", text: this.translate("perpendicular") }
    ];
    return this.createSelect({
      labelKey: "orientation",
      options,
      property: "label.orientation"
    });
  }
  initRadiusAxis() {
    const chartSeriesType = getSeriesType(this.options.chartController.getChartType());
    if (!isRadial(chartSeriesType))
      return;
    const items = [
      this.createSlider({
        labelKey: "groupPadding",
        defaultMaxValue: 1,
        property: "paddingInner"
      }),
      this.createSlider({
        labelKey: "seriesPadding",
        defaultMaxValue: 1,
        property: "groupPaddingInner"
      })
    ];
    const paddingPanelComp = this.createManagedBean(
      new AgGroupComponent({
        cssIdentifier: "charts-format-sub-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        enabled: true,
        suppressEnabledCheckbox: true,
        title: this.translate("padding"),
        items
      })
    ).hideEnabledCheckbox(true).hideOpenCloseIcons(true);
    this.axisGroup.addItem(paddingPanelComp);
  }
  createSlider(config) {
    const { labelKey, defaultMaxValue, step = 0.05, property } = config;
    const params = this.options.chartAxisMenuParamsFactory.getDefaultSliderParams(
      property,
      labelKey,
      defaultMaxValue
    );
    params.step = step;
    return this.createManagedBean(new AgSlider(params));
  }
  createSelect(config) {
    const { labelKey, options, property } = config;
    return this.createManagedBean(
      new AgSelect6(this.options.chartAxisMenuParamsFactory.getDefaultSelectParams(property, labelKey, options))
    );
  }
  translate(key) {
    return this.chartTranslationService.translate(key);
  }
};
var BackgroundPanel = class extends Component19 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const chartBackgroundGroupParams = this.chartMenuUtils.addEnableParams(
      "background.visible",
      {
        cssIdentifier: "charts-format-sub-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("background"),
        suppressEnabledCheckbox: true,
        useToggle: true
      }
    );
    const colorPickerParams = this.chartMenuUtils.getDefaultColorPickerParams("background.fill");
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="chartBackgroundGroup">
                <ag-color-picker data-ref="colorPicker"></ag-color-picker>
            </ag-group-component>
        <div>`,
      [AgGroupComponentSelector, AgColorPickerSelector],
      {
        chartBackgroundGroup: chartBackgroundGroupParams,
        colorPicker: colorPickerParams
      }
    );
  }
};
var PaddingPanel = class extends Component20 {
  constructor(chartMenuUtils, chartController) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.chartController = chartController;
    this.paddingTopSlider = RefPlaceholder12;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const chartPaddingGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("padding"),
      suppressEnabledCheckbox: true
    };
    const getSliderParams = (property) => this.chartMenuUtils.getDefaultSliderParams("padding." + property, property, 200);
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="chartPaddingGroup">
                <ag-slider data-ref="paddingTopSlider"></ag-slider>
                <ag-slider data-ref="paddingRightSlider"></ag-slider>
                <ag-slider data-ref="paddingBottomSlider"></ag-slider>
                <ag-slider data-ref="paddingLeftSlider"></ag-slider>
            </ag-group-component>
        <div>`,
      [AgGroupComponentSelector, AgSliderSelector],
      {
        chartPaddingGroup: chartPaddingGroupParams,
        paddingTopSlider: getSliderParams("top"),
        paddingRightSlider: getSliderParams("right"),
        paddingBottomSlider: getSliderParams("bottom"),
        paddingLeftSlider: getSliderParams("left")
      }
    );
    this.addManagedEventListeners({
      chartOptionsChanged: (e) => {
        this.updateTopPadding(e.chartOptions);
      }
    });
  }
  updateTopPadding(chartOptions) {
    const topPadding = [...this.chartController.getChartSeriesTypes(), "common"].map((seriesType) => chartOptions[seriesType]?.padding?.top).find((value) => value != null);
    if (topPadding != null) {
      this.paddingTopSlider.setValue(`${topPadding}`);
    }
  }
};
var ChartPanel = class extends Component21 {
  constructor(options) {
    super();
    this.options = options;
    this.chartGroup = RefPlaceholder13;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const {
      chartController,
      chartMenuParamsFactory,
      isExpandedOnInit: expanded,
      registerGroupComponent
    } = this.options;
    const chartGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("chartStyle"),
      expanded,
      suppressEnabledCheckbox: true,
      items: [
        this.createManagedBean(new PaddingPanel(chartMenuParamsFactory, chartController)),
        this.createManagedBean(new BackgroundPanel(chartMenuParamsFactory))
      ]
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="chartGroup"></ag-group-component>
        </div>`,
      [AgGroupComponentSelector],
      { chartGroup: chartGroupParams }
    );
    registerGroupComponent(this.chartGroup);
  }
};
var GroupExpansionFeature = class extends BeanStub13 {
  constructor(groupContainer) {
    super();
    this.groupContainer = groupContainer;
    this.id = 0;
    this.groupComponents = /* @__PURE__ */ new Map();
  }
  addGroupComponent(groupComponent) {
    const id = this.id++;
    this.groupComponents.set(id, groupComponent);
    if (groupComponent.isExpanded()) {
      this.expandedGroupComponent = id;
    }
    groupComponent.onExpandedChange((expanded) => {
      if (expanded) {
        const previouslyExpandedGroupComponent = this.expandedGroupComponent;
        this.expandedGroupComponent = id;
        if (previouslyExpandedGroupComponent != null) {
          const groupComponentGui = groupComponent.getGui();
          const groupPositionInViewport = groupComponentGui.offsetTop - this.groupContainer.parentElement.scrollTop;
          this.groupComponents.get(previouslyExpandedGroupComponent)?.toggleGroupExpand(false, true);
          let newScrollTop = groupComponentGui.offsetTop - groupPositionInViewport;
          if (newScrollTop < 0) {
            newScrollTop = 0;
          }
          if (newScrollTop !== this.groupContainer.parentElement.scrollTop) {
            this.groupContainer.parentElement.scrollTop = newScrollTop;
          }
        }
      } else {
        this.expandedGroupComponent = void 0;
      }
    });
  }
  destroy() {
    this.groupComponents.clear();
    super.destroy();
  }
};
var LegendPanel = class extends Component222 {
  constructor(options) {
    super();
    this.options = options;
    this.legendGroup = RefPlaceholder14;
    this.isGradient = ["treemap", "sunburst", "heatmap"].includes(options.seriesType);
    this.key = this.isGradient ? "gradientLegend" : "legend";
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const { chartMenuParamsFactory, isExpandedOnInit: expanded, registerGroupComponent } = this.options;
    const positionSelect = this.createManagedBean(
      new AgSelect7(
        chartMenuParamsFactory.getDefaultSelectParams(
          `${this.key}.position`,
          "position",
          ["top", "right", "bottom", "left"].map((position) => ({
            value: position,
            text: this.chartTranslationService.translate(position)
          }))
        )
      )
    );
    const enabledGroup = this.createManagedBean(
      new AgGroupComponent(
        chartMenuParamsFactory.addEnableParams(`${this.key}.enabled`, {
          cssIdentifier: "charts-format-sub-level",
          direction: "vertical",
          suppressOpenCloseIcons: true,
          title: this.chartTranslationService.translate("legendEnabled"),
          suppressEnabledCheckbox: true,
          useToggle: true,
          items: [
            this.createLabelPanel(chartMenuParamsFactory),
            positionSelect,
            ...this.getItems(chartMenuParamsFactory)
          ]
        })
      )
    );
    const legendGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("legend"),
      suppressEnabledCheckbox: true,
      expanded,
      items: [enabledGroup]
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="legendGroup">
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector],
      {
        legendGroup: legendGroupParams
      }
    );
    registerGroupComponent(this.legendGroup);
  }
  getItems(chartMenuParamsFactory) {
    const createSlider = (expression, labelKey, defaultMaxValue) => this.createManagedBean(
      new AgSlider(
        chartMenuParamsFactory.getDefaultSliderParams(
          `${this.key}.${expression}`,
          labelKey,
          defaultMaxValue
        )
      )
    );
    if (this.isGradient) {
      return [
        this.createManagedBean(
          new AgCheckbox3(
            chartMenuParamsFactory.addValueParams("gradientLegend.reverseOrder", {
              label: this.chartTranslationService.translate("reverseDirection"),
              labelWidth: "flex"
            })
          )
        ),
        createSlider("gradient.thickness", "thickness", 40),
        createSlider("gradient.preferredLength", "preferredLength", 300),
        createSlider("spacing", "spacing", 200)
      ];
    }
    return [
      createSlider("spacing", "spacing", 200),
      createSlider("item.marker.size", "markerSize", 40),
      createSlider("item.marker.strokeWidth", "markerStroke", 10),
      createSlider("item.marker.padding", "itemSpacing", 20),
      createSlider("item.paddingX", "layoutHorizontalSpacing", 50),
      createSlider("item.paddingY", "layoutVerticalSpacing", 50)
    ];
  }
  createLabelPanel(chartMenuParamsFactory) {
    const rootKey = this.isGradient ? "gradientLegend.scale.label" : "legend.item.label";
    const params = {
      enabled: true,
      suppressEnabledCheckbox: true,
      chartMenuParamsFactory,
      keyMapper: (key) => `${rootKey}.${key}`,
      cssIdentifier: "charts-format-sub-level-no-header"
    };
    return this.createManagedBean(new FontPanel(params));
  }
};
var CalloutPanel = class extends Component232 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const calloutGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("callout"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="calloutGroup">
                <ag-slider data-ref="calloutLengthSlider"></ag-slider>
                <ag-slider data-ref="calloutStrokeWidthSlider"></ag-slider>
                <ag-slider data-ref="labelOffsetSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgSliderSelector],
      {
        calloutGroup: calloutGroupParams,
        calloutLengthSlider: this.chartMenuUtils.getDefaultSliderParams("calloutLine.length", "length", 40),
        calloutStrokeWidthSlider: this.chartMenuUtils.getDefaultSliderParams(
          "calloutLine.strokeWidth",
          "strokeWidth",
          10
        ),
        labelOffsetSlider: this.chartMenuUtils.getDefaultSliderParams("calloutLabel.offset", "offset", 30)
      }
    );
  }
};
var CapsPanel = class extends Component24 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const capsGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("cap"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    const capLengthRatioSliderParams = this.chartMenuUtils.getDefaultSliderParams(
      "cap.lengthRatio",
      "capLengthRatio",
      1
    );
    capLengthRatioSliderParams.step = 0.05;
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="capsGroup">
                <ag-slider data-ref="capLengthRatioSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgSliderSelector],
      {
        capsGroup: capsGroupParams,
        capLengthRatioSlider: capLengthRatioSliderParams
      }
    );
  }
};
var ConnectorLinePanel = class extends Component25 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const lineGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("connectorLine"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="lineGroup">
                <ag-color-picker data-ref="lineColorPicker"></ag-color-picker>
                <ag-slider data-ref="lineStrokeWidthSlider"></ag-slider>
                <ag-slider data-ref="lineOpacitySlider"></ag-slider>
                <ag-slider data-ref="lineDashSlider"></ag-slider>                
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgColorPickerSelector, AgSliderSelector],
      {
        lineGroup: lineGroupParams,
        lineColorPicker: this.chartMenuUtils.getDefaultColorPickerParams("line.stroke"),
        lineStrokeWidthSlider: this.getSliderParams("strokeWidth", 10, "line.strokeWidth"),
        lineDashSlider: this.getSliderParams("lineDash", 30, "line.lineDash", 1, true),
        lineOpacitySlider: this.getSliderParams("strokeOpacity", 1, "line.strokeOpacity", 0.05)
      }
    );
  }
  getSliderParams(labelKey, maxValue, seriesOptionKey, step = 1, isArray = false) {
    const params = this.chartMenuUtils.getDefaultSliderParams(seriesOptionKey, labelKey, maxValue, isArray);
    params.step = step;
    return params;
  }
};
function getShapeSelectOptions(chartTranslationService) {
  return ["square", "circle", "cross", "diamond", "plus", "triangle", "heart"].map((value) => ({
    value,
    text: chartTranslationService.translate(value)
  }));
}
var MarkersPanel = class extends Component26 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const seriesMarkersGroupParams = this.chartMenuUtils.addEnableParams("marker.enabled", {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("markers"),
      suppressEnabledCheckbox: true,
      useToggle: true,
      suppressOpenCloseIcons: true
    });
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="seriesMarkersGroup">
                <ag-select data-ref="seriesMarkerShapeSelect"></ag-select>
                <ag-slider data-ref="seriesMarkerSizeSlider"></ag-slider>
                <ag-slider data-ref="seriesMarkerStrokeWidthSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgSelectSelector3, AgSliderSelector],
      {
        seriesMarkersGroup: seriesMarkersGroupParams,
        seriesMarkerShapeSelect: this.chartMenuUtils.getDefaultSelectParams(
          "marker.shape",
          "shape",
          getShapeSelectOptions(this.chartTranslationService)
        ),
        seriesMarkerSizeSlider: this.chartMenuUtils.getDefaultSliderParams("marker.size", "size", 60),
        seriesMarkerStrokeWidthSlider: this.chartMenuUtils.getDefaultSliderParams(
          "marker.strokeWidth",
          "strokeWidth",
          10
        )
      }
    );
  }
};
var SeriesItemsPanel = class extends Component27 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.seriesItemsGroup = RefPlaceholder15;
    this.activePanels = [];
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const seriesItemsGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("seriesItems"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="seriesItemsGroup">
                <ag-select data-ref="seriesItemSelect"></ag-select>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgSelectSelector4],
      {
        seriesItemsGroup: seriesItemsGroupParams,
        seriesItemSelect: this.getSeriesItemsParams()
      }
    );
    this.initSeriesControls();
  }
  getSeriesItemsParams() {
    const options = [
      { value: "positive", text: this.chartTranslationService.translate("seriesItemPositive") },
      { value: "negative", text: this.chartTranslationService.translate("seriesItemNegative") }
    ];
    const seriesItemChangedCallback = (newValue) => {
      this.destroyActivePanels();
      this.initSeriesControls(newValue);
    };
    return this.chartMenuUtils.getDefaultSelectParamsWithoutValueParams(
      "seriesItemType",
      options,
      "positive",
      seriesItemChangedCallback
    );
  }
  initSeriesControls(itemType = "positive") {
    this.initSlider("strokeWidth", 10, `item.${itemType}.strokeWidth`);
    this.initSlider("lineDash", 30, `item.${itemType}.lineDash`, 1, true);
    this.initSlider("strokeOpacity", 1, `item.${itemType}.strokeOpacity`, 0.05, false);
    this.initSlider("fillOpacity", 1, `item.${itemType}.fillOpacity`, 0.05, false);
    this.initItemLabels(itemType);
  }
  initSlider(labelKey, maxValue, seriesOptionKey, step = 1, isArray = false) {
    const params = this.chartMenuUtils.getDefaultSliderParams(seriesOptionKey, labelKey, maxValue, isArray);
    params.step = step;
    const itemSlider = this.seriesItemsGroup.createManagedBean(new AgSlider(params));
    this.seriesItemsGroup.addItem(itemSlider);
    this.activePanels.push(itemSlider);
  }
  initItemLabels(itemType) {
    const sectorParams = this.chartMenuUtils.getDefaultFontPanelParams(
      `item.${itemType}.label`,
      "seriesItemLabels"
    );
    const labelPanelComp = this.createBean(new FontPanel(sectorParams));
    this.seriesItemsGroup.addItem(labelPanelComp);
    this.activePanels.push(labelPanelComp);
  }
  destroyActivePanels() {
    this.activePanels.forEach((panel) => {
      _removeFromParent5(panel.getGui());
      this.destroyBean(panel);
    });
  }
  destroy() {
    this.destroyActivePanels();
    super.destroy();
  }
};
var ShadowPanel = class extends Component28 {
  constructor(chartMenuUtils, propertyKey = "shadow") {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.propertyKey = propertyKey;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const propertyNamespace = this.propertyKey;
    const shadowGroupParams = this.chartMenuUtils.addEnableParams(
      `${propertyNamespace}.enabled`,
      {
        cssIdentifier: "charts-format-sub-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("shadow"),
        suppressEnabledCheckbox: true,
        useToggle: true
      }
    );
    const shadowColorPickerParams = this.chartMenuUtils.getDefaultColorPickerParams(`${propertyNamespace}.color`);
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="shadowGroup">
                <ag-color-picker data-ref="shadowColorPicker"></ag-color-picker>
                <ag-slider data-ref="shadowBlurSlider"></ag-slider>
                <ag-slider data-ref="shadowXOffsetSlider"></ag-slider>
                <ag-slider data-ref="shadowYOffsetSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgColorPickerSelector, AgSliderSelector],
      {
        shadowGroup: shadowGroupParams,
        shadowColorPicker: shadowColorPickerParams,
        shadowBlurSlider: this.getSliderParams("blur", 0, 20),
        shadowXOffsetSlider: this.getSliderParams("xOffset", -10, 10),
        shadowYOffsetSlider: this.getSliderParams("yOffset", -10, 10)
      }
    );
  }
  getSliderParams(property, minValue, defaultMaxValue) {
    const expression = `${this.propertyKey}.${property}`;
    const params = this.chartMenuUtils.getDefaultSliderParams(expression, property, defaultMaxValue);
    params.minValue = minValue;
    return params;
  }
};
var TileSpacingPanel = class extends Component29 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const groupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="groupSpacing">
                <ag-slider data-ref="groupPaddingSlider"></ag-slider>
                <ag-slider data-ref="groupSpacingSlider"></ag-slider>
            </ag-group-component>
            <ag-group-component data-ref="tileSpacing">
                <ag-slider data-ref="tilePaddingSlider"></ag-slider>
                <ag-slider data-ref="tileSpacingSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgSliderSelector],
      {
        groupSpacing: { ...groupParams, title: this.chartTranslationService.translate("group") },
        tileSpacing: { ...groupParams, title: this.chartTranslationService.translate("tile") },
        groupPaddingSlider: this.getSliderParams("padding", "group.padding"),
        groupSpacingSlider: this.getSliderParams("spacing", "group.gap"),
        tilePaddingSlider: this.getSliderParams("padding", "tile.padding"),
        tileSpacingSlider: this.getSliderParams("spacing", "tile.gap")
      }
    );
  }
  getSliderParams(labelKey, key) {
    return this.chartMenuUtils.getDefaultSliderParams(key, labelKey, 10);
  }
};
var WhiskersPanel = class extends Component30 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const whiskersGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("whisker"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="whiskersGroup">
                <ag-color-picker data-ref="whiskerColorPicker"></ag-color-picker>
                <ag-slider data-ref="whiskerThicknessSlider"></ag-slider>
                <ag-slider data-ref="whiskerOpacitySlider"></ag-slider>
                <ag-slider data-ref="whiskerLineDashSlider"></ag-slider>
                <ag-slider data-ref="whiskerLineDashOffsetSlider"></ag-slider>
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector, AgColorPickerSelector, AgSliderSelector],
      {
        whiskersGroup: whiskersGroupParams,
        whiskerColorPicker: this.chartMenuUtils.getDefaultColorPickerParams("whisker.stroke"),
        whiskerThicknessSlider: this.chartMenuUtils.getDefaultSliderParams(
          "whisker.strokeWidth",
          "strokeWidth",
          10
        ),
        whiskerOpacitySlider: this.chartMenuUtils.getDefaultSliderParams(
          "whisker.strokeOpacity",
          "strokeOpacity",
          1
        ),
        whiskerLineDashSlider: this.chartMenuUtils.getDefaultSliderParams(
          "whisker.lineDash",
          "lineDash",
          30,
          true
        ),
        whiskerLineDashOffsetSlider: this.chartMenuUtils.getDefaultSliderParams(
          "whisker.lineDashOffset",
          "lineDashOffset",
          30
        )
      }
    );
  }
};
var tooltips = "tooltips";
var strokeWidth = "strokeWidth";
var lineDash = "lineDash";
var lineOpacity = "lineOpacity";
var fillOpacity = "fillOpacity";
var labels = "labels";
var shadow = "shadow";
var SeriesPanel = class extends Component31 {
  constructor(options) {
    super();
    this.options = options;
    this.seriesGroup = RefPlaceholder16;
    this.activePanels = [];
    this.widgetFuncs = {
      lineWidth: () => this.initStrokeWidth("lineWidth"),
      [strokeWidth]: () => this.initStrokeWidth("strokeWidth"),
      lineColor: () => this.initLineColor(),
      [lineDash]: () => this.initLineDash(),
      [lineOpacity]: () => this.initOpacity("strokeOpacity"),
      [fillOpacity]: () => this.initOpacity("fillOpacity"),
      markers: () => new MarkersPanel(this.chartMenuUtils),
      [labels]: () => this.initLabels(),
      sectorLabels: () => this.initSectorLabels(),
      [shadow]: () => new ShadowPanel(this.chartMenuUtils),
      [tooltips]: () => this.initTooltips(),
      bins: () => this.initBins(),
      whiskers: () => new WhiskersPanel(this.chartMenuUtils),
      caps: () => new CapsPanel(this.chartMenuUtils),
      connectorLine: () => new ConnectorLinePanel(this.chartMenuUtils),
      seriesItems: () => new SeriesItemsPanel(this.chartMenuUtils),
      tileSpacing: () => new TileSpacingPanel(this.chartMenuUtils),
      shape: () => this.initShape(),
      size: () => this.initSize("size", "size"),
      minSize: () => this.initSize("size", "minSize"),
      maxSize: () => this.initSize("maxSize", "maxSize")
    };
    this.seriesWidgetMappings = {
      bar: [tooltips, strokeWidth, lineDash, lineOpacity, fillOpacity, labels, shadow],
      pie: [tooltips, strokeWidth, lineOpacity, fillOpacity, labels, "sectorLabels", shadow],
      donut: [tooltips, strokeWidth, lineOpacity, fillOpacity, labels, "sectorLabels", shadow],
      line: [tooltips, "lineWidth", lineDash, lineOpacity, "markers", labels],
      scatter: [tooltips, "shape", "size", strokeWidth, labels],
      bubble: [tooltips, "shape", "minSize", "maxSize", strokeWidth, labels],
      area: [tooltips, "lineWidth", lineDash, lineOpacity, fillOpacity, "markers", labels, shadow],
      histogram: [tooltips, "bins", strokeWidth, lineDash, lineOpacity, fillOpacity, labels, shadow],
      "radial-column": [tooltips, strokeWidth, lineDash, lineOpacity, fillOpacity, labels],
      "radial-bar": [tooltips, strokeWidth, lineDash, lineOpacity, fillOpacity, labels],
      "radar-line": [tooltips, strokeWidth, lineDash, lineOpacity, "markers", labels],
      "radar-area": [tooltips, strokeWidth, lineDash, lineOpacity, fillOpacity, "markers", labels],
      nightingale: [tooltips, strokeWidth, lineDash, lineOpacity, fillOpacity, labels],
      "box-plot": [tooltips, strokeWidth, lineDash, lineOpacity, fillOpacity, "whiskers", "caps"],
      "range-bar": [tooltips, strokeWidth, lineDash, lineOpacity, fillOpacity, labels],
      "range-area": [tooltips, "lineWidth", lineDash, lineOpacity, fillOpacity, "markers", labels, shadow],
      treemap: [tooltips, "tileSpacing"],
      sunburst: [tooltips],
      heatmap: [tooltips, labels, "lineColor", "lineWidth", lineOpacity],
      waterfall: [tooltips, "connectorLine", "seriesItems"]
    };
    this.seriesType = options.seriesType;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const {
      isExpandedOnInit: expanded,
      chartOptionsService,
      chartController,
      registerGroupComponent
    } = this.options;
    const seriesGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.translate("series"),
      expanded,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="seriesGroup">
            </ag-group-component>
        </div>`,
      [AgGroupComponentSelector],
      { seriesGroup: seriesGroupParams }
    );
    registerGroupComponent(this.seriesGroup);
    this.chartMenuUtils = this.createManagedBean(
      new ChartMenuParamsFactory(chartOptionsService.getSeriesOptionsProxy(() => this.seriesType))
    );
    this.addManagedListeners(chartController, { chartSeriesChartTypeChanged: this.refreshWidgets.bind(this) });
    this.refreshWidgets();
  }
  refreshWidgets() {
    const { chartController } = this.options;
    this.destroyActivePanels();
    const chart = chartController.getChartProxy().getChart();
    chart.waitForUpdate().then(() => {
      const componentWasRemoved = !this.isAlive();
      if (componentWasRemoved) {
        return;
      }
      if (chartController.isComboChart()) {
        this.updateSeriesType();
        this.initSeriesSelect();
      }
      (this.seriesWidgetMappings[this.seriesType] ?? []).forEach((w) => {
        const widgetFuncResult = this.widgetFuncs[w]();
        let widget;
        if (Array.isArray(widgetFuncResult)) {
          const comp = this.createBean(widgetFuncResult[0]);
          widget = comp;
          widgetFuncResult[1](comp);
        } else {
          widget = this.createBean(widgetFuncResult);
        }
        this.seriesGroup.addItem(widget);
        this.activePanels.push(widget);
      });
    }).catch((e) => _errorOnce(`chart rendering failed`, e));
  }
  initSeriesSelect() {
    const seriesSelect = this.createBean(
      new AgSelect8(
        this.chartMenuUtils.getDefaultSelectParamsWithoutValueParams(
          "seriesType",
          this.getSeriesSelectOptions(),
          `${this.seriesType}`,
          (newValue) => {
            this.seriesType = newValue;
            this.refreshWidgets();
          }
        )
      )
    );
    this.seriesGroup.addItem(seriesSelect);
    this.activePanels.push(seriesSelect);
  }
  initTooltips() {
    return new AgToggleButton4(
      this.chartMenuUtils.addValueParams("tooltip.enabled", {
        label: this.translate("tooltips"),
        labelAlignment: "left",
        labelWidth: "flex",
        inputWidth: "flex"
      })
    );
  }
  initLineColor() {
    return new AgColorPicker(this.chartMenuUtils.getDefaultColorPickerParams("stroke", "strokeColor"));
  }
  initStrokeWidth(labelKey) {
    return new AgSlider(this.chartMenuUtils.getDefaultSliderParams("strokeWidth", labelKey, 10));
  }
  initLineDash() {
    return new AgSlider(this.chartMenuUtils.getDefaultSliderParams("lineDash", "lineDash", 30, true));
  }
  initOpacity(type) {
    const params = this.chartMenuUtils.getDefaultSliderParams(type, type, 1);
    params.step = 0.05;
    return new AgSlider(params);
  }
  initLabels() {
    const isPieChart = isPieChartSeries(this.seriesType);
    const seriesOptionLabelProperty = isPieChart ? "calloutLabel" : "label";
    const labelKey = isPieChart ? "calloutLabels" : "labels";
    const labelParams = this.chartMenuUtils.getDefaultFontPanelParams(seriesOptionLabelProperty, labelKey);
    const fontPanel = new FontPanel(labelParams);
    const addItems = (labelPanelComp) => {
      if (isPieChart) {
        const calloutPanelComp = labelPanelComp.createManagedBean(new CalloutPanel(this.chartMenuUtils));
        labelPanelComp.addItem(calloutPanelComp);
        this.activePanels.push(calloutPanelComp);
      }
      if (this.seriesType === "range-bar") {
        const options = [
          { value: "inside", text: this.translate("inside") },
          { value: "outside", text: this.translate("outside") }
        ];
        const placementSelect = labelPanelComp.createManagedBean(
          new AgSelect8(
            this.chartMenuUtils.getDefaultSelectParams("label.placement", "labelPlacement", options)
          )
        );
        labelPanelComp.addItem(placementSelect);
        this.activePanels.push(placementSelect);
        const paddingSlider = labelPanelComp.createManagedBean(
          new AgSlider(this.chartMenuUtils.getDefaultSliderParams("label.padding", "padding", 200))
        );
        labelPanelComp.addItem(paddingSlider);
        this.activePanels.push(paddingSlider);
      }
    };
    return [fontPanel, addItems];
  }
  initSectorLabels() {
    const sectorParams = this.chartMenuUtils.getDefaultFontPanelParams("sectorLabel", "sectorLabels");
    const fontPanel = new FontPanel(sectorParams);
    const addItems = (sectorPanelComp) => {
      const positionRatioParams = this.chartMenuUtils.getDefaultSliderParams(
        "sectorLabel.positionRatio",
        "positionRatio",
        1
      );
      positionRatioParams.step = 0.05;
      const positionRatioComp = sectorPanelComp.createManagedBean(new AgSlider(positionRatioParams));
      sectorPanelComp.addItem(positionRatioComp);
    };
    return [fontPanel, addItems];
  }
  initBins() {
    const params = this.chartMenuUtils.getDefaultSliderParams("binCount", "histogramBinCount", 20);
    const chartOptions = this.chartMenuUtils.getChartOptions();
    const value = (chartOptions.getValue("bins") ?? chartOptions.getValue("calculatedBins", true)).length;
    params.value = `${value}`;
    params.maxValue = Math.max(value, 20);
    return new AgSlider(params);
  }
  initShape() {
    return new AgSelect8(
      this.chartMenuUtils.getDefaultSelectParams(
        "shape",
        "shape",
        getShapeSelectOptions(this.chartTranslationService)
      )
    );
  }
  initSize(expression, labelKey) {
    return new AgSlider(this.chartMenuUtils.getDefaultSliderParams(expression, labelKey, 60));
  }
  getSeriesSelectOptions() {
    const activeSeriesTypes = this.getActiveSeriesTypes();
    return ["area", "bar", "line"].filter((seriesType) => activeSeriesTypes.includes(seriesType)).map((value) => ({ value, text: this.translate(value) }));
  }
  updateSeriesType() {
    const activeSeriesTypes = this.getActiveSeriesTypes();
    const invalidSeriesType = !activeSeriesTypes.includes(this.seriesType);
    if (invalidSeriesType && activeSeriesTypes.length > 0) {
      this.seriesType = activeSeriesTypes[0];
    }
  }
  getActiveSeriesTypes() {
    return this.options.chartController.getActiveSeriesChartTypes().map((s) => getSeriesType(s.chartType));
  }
  translate(key) {
    return this.chartTranslationService.translate(key);
  }
  destroyActivePanels() {
    this.activePanels.forEach((panel) => {
      _removeFromParent6(panel.getGui());
      this.destroyBean(panel);
    });
  }
  destroy() {
    this.destroyActivePanels();
    super.destroy();
  }
};
var TitlePanel = class extends Component322 {
  constructor(chartMenuUtils, name, key) {
    super(
      /* html */
      `<div></div>`
    );
    this.chartMenuUtils = chartMenuUtils;
    this.name = name;
    this.key = key;
    this.chartOptions = chartMenuUtils.getChartOptions();
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    this.initFontPanel();
  }
  hasTitle() {
    const title = this.chartOptions.getValue(this.key);
    return title && title.enabled && title.text && title.text.length > 0;
  }
  initFontPanel() {
    const hasTitle = this.hasTitle();
    const fontPanelParams = {
      name: this.chartTranslationService.translate(this.name),
      enabled: hasTitle,
      suppressEnabledCheckbox: false,
      chartMenuParamsFactory: this.chartMenuUtils,
      keyMapper: (key) => `${this.key}.${key}`,
      onEnableChange: (enabled) => this.onEnableChange(enabled)
    };
    this.fontPanel = this.createManagedBean(new FontPanel(fontPanelParams));
    this.fontPanel.addItem(this.createBean(new AgInputTextField22(this.getTextInputParams())), true);
    this.fontPanel.addItem(this.createBean(new AgSlider(this.getSpacingSliderParams())));
    this.getGui().appendChild(this.fontPanel.getGui());
  }
  getTextInputParams() {
    return this.chartMenuUtils.addValueParams(`${this.key}.text`, {
      label: this.chartTranslationService.translate("title"),
      labelAlignment: "top"
    });
  }
  getSpacingSliderParams() {
    return this.chartMenuUtils.getDefaultSliderParams(`${this.key}.spacing`, "spacing", 100);
  }
  onEnableChange(enabled) {
    this.chartOptions.setValue(`${this.key}.enabled`, enabled);
  }
};
var ChartTitlePanel = class extends TitlePanel {
  wireBeans(beans) {
    super.wireBeans(beans);
    this.chartMenuService = beans.chartMenuService;
  }
  postConstruct() {
    this.titlePlaceholder = this.chartTranslationService.translate("titlePlaceholder");
    super.postConstruct();
    this.addManagedEventListeners({
      chartTitleEdit: () => {
        this.fontPanel.setEnabled(this.hasTitle());
      }
    });
  }
  getTextInputParams() {
    const params = super.getTextInputParams();
    if (this.shouldOverrideTextWithPlaceholder(params.value)) {
      params.value = this.titlePlaceholder;
    }
    return params;
  }
  getSpacingSliderParams() {
    const params = super.getSpacingSliderParams();
    params.value = "10";
    return params;
  }
  onEnableChange(enabled) {
    if (this.chartMenuService.doesChartToolbarExist()) {
      const topPadding = this.chartOptions.getValue("padding.top");
      this.chartOptions.setValue("padding.top", enabled ? topPadding - 20 : topPadding + 20);
    }
    this.chartOptions.setValue(`${this.key}.enabled`, enabled);
    const currentTitleText = this.chartOptions.getValue(`${this.key}.text`);
    if (enabled && this.shouldOverrideTextWithPlaceholder(currentTitleText)) {
      this.chartOptions.setValue(`${this.key}.text`, this.titlePlaceholder);
    }
  }
  shouldOverrideTextWithPlaceholder(currentTitleText) {
    return currentTitleText === "Title" || currentTitleText?.trim().length === 0;
  }
};
var TitlesPanel = class extends Component332 {
  constructor(options) {
    super();
    this.options = options;
    this.titleGroup = RefPlaceholder17;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    const {
      chartMenuParamsFactory,
      chartAxisMenuParamsFactory,
      chartOptionsService,
      seriesType,
      isExpandedOnInit: expanded = false,
      registerGroupComponent
    } = this.options;
    const axisTitlePanels = [];
    if (isCartesian(seriesType)) {
      const createAxisParamsFactory = (axisType) => this.createManagedBean(
        new ChartMenuParamsFactory(chartOptionsService.getCartesianAxisThemeOverridesProxy(axisType))
      );
      axisTitlePanels.push(
        this.createManagedBean(new TitlePanel(createAxisParamsFactory("xAxis"), "horizontalAxisTitle", "title"))
      );
      axisTitlePanels.push(
        this.createManagedBean(new TitlePanel(createAxisParamsFactory("yAxis"), "verticalAxisTitle", "title"))
      );
    } else if (isPolar(seriesType)) {
      axisTitlePanels.push(
        this.createManagedBean(new TitlePanel(chartAxisMenuParamsFactory, "polarAxisTitle", "title"))
      );
    }
    const titleGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("chartTitles"),
      expanded,
      suppressEnabledCheckbox: true,
      items: [
        this.createManagedBean(new ChartTitlePanel(chartMenuParamsFactory, "chartTitle", "title")),
        this.createManagedBean(new TitlePanel(chartMenuParamsFactory, "chartSubtitle", "subtitle")),
        ...axisTitlePanels
      ]
    };
    this.setTemplate(
      /* html */
      `<div>
            <ag-group-component data-ref="titleGroup"></ag-group-component>
        </div>`,
      [AgGroupComponentSelector],
      { titleGroup: titleGroupParams }
    );
    registerGroupComponent(this.titleGroup);
  }
};
var DefaultFormatPanelDef = {
  groups: [{ type: "chart" }, { type: "titles" }, { type: "legend" }, { type: "series" }, { type: "axis" }]
};
var FormatPanel = class extends Component34 {
  constructor(chartMenuContext) {
    super(
      /* html */
      `<div class="ag-chart-format-wrapper"></div>`
    );
    this.chartMenuContext = chartMenuContext;
  }
  postConstruct() {
    this.groupExpansionFeature = this.createManagedBean(new GroupExpansionFeature(this.getGui()));
    this.chartPanelFeature = this.createManagedBean(
      new ChartPanelFeature(
        this.chartMenuContext.chartController,
        this.getGui(),
        "ag-chart-format-section",
        (_chartType, seriesType) => this.createPanels(seriesType)
      )
    );
    this.chartPanelFeature.refreshPanels();
  }
  createPanels(seriesType) {
    let panelExpandedOnInit = false;
    this.getFormatPanelDef().groups?.forEach(({ type: group, isOpen: isExpandedOnInit = false }) => {
      if (!this.isGroupPanelShownInSeries(group, seriesType)) {
        return;
      }
      if (isExpandedOnInit) {
        if (panelExpandedOnInit) {
          _warnOnce10(
            `As of v32, only one charts customize panel group can be expanded at a time. '${group}' will not be expanded.`
          );
        }
        panelExpandedOnInit = true;
      }
      const registerGroupComponent = (groupComponent) => this.groupExpansionFeature.addGroupComponent(groupComponent);
      const opts = {
        ...this.chartMenuContext,
        isExpandedOnInit,
        seriesType,
        registerGroupComponent
      };
      switch (group) {
        case "chart":
          this.chartPanelFeature.addComponent(new ChartPanel(opts));
          break;
        case "titles":
          this.chartPanelFeature.addComponent(new TitlesPanel(opts));
          break;
        case "legend":
          this.chartPanelFeature.addComponent(new LegendPanel(opts));
          break;
        case "axis":
          if (isPolar(seriesType)) {
            this.chartPanelFeature.addComponent(new PolarAxisPanel(opts));
          } else if (isCartesian(seriesType)) {
            this.chartPanelFeature.addComponent(new CartesianAxisPanel("xAxis", opts));
            this.chartPanelFeature.addComponent(new CartesianAxisPanel("yAxis", opts));
          }
          break;
        case "horizontalAxis":
          this.chartPanelFeature.addComponent(new CartesianAxisPanel("xAxis", opts));
          break;
        case "verticalAxis":
          this.chartPanelFeature.addComponent(new CartesianAxisPanel("yAxis", opts));
          break;
        case "series":
          this.chartPanelFeature.addComponent(new SeriesPanel(opts));
          break;
        case "navigator":
          _warnOnce10(
            `'navigator' is now displayed in the charts advanced settings instead of the customize panel, and this setting will be ignored.`
          );
          break;
        default:
          _warnOnce10(`Invalid charts customize panel group name supplied: '${group}'`);
      }
    });
  }
  getFormatPanelDef() {
    const userProvidedFormatPanelDef = this.gos.get("chartToolPanelsDef")?.formatPanel;
    return userProvidedFormatPanelDef ? userProvidedFormatPanelDef : DefaultFormatPanelDef;
  }
  isGroupPanelShownInSeries(group, seriesType) {
    return ["chart", "titles", "legend", "series"].includes(group) || isCartesian(seriesType) && ["axis", "horizontalAxis", "verticalAxis"].includes(group) || isPolar(seriesType) && group === "axis";
  }
};
function createColumnRects(params) {
  const { stacked, size, padding, xScalePadding, xScaleDomain, yScaleDomain } = params;
  const xScale = new _Scene.BandScale();
  xScale.domain = xScaleDomain;
  xScale.range = [padding, size - padding];
  xScale.paddingInner = xScalePadding;
  xScale.paddingOuter = xScalePadding;
  const yScale = new _Scene.LinearScale();
  yScale.domain = yScaleDomain;
  yScale.range = [size - padding, padding];
  const createBars = (series, xScale2, yScale2) => {
    return series.map((datum, i) => {
      const top = yScale2.convert(datum);
      const rect = new _Scene.Rect();
      rect.x = xScale2.convert(i);
      rect.y = top;
      rect.width = xScale2.bandwidth;
      rect.height = yScale2.convert(0) - top;
      rect.strokeWidth = 0;
      rect.crisp = true;
      return rect;
    });
  };
  if (stacked) {
    return params.data.map((d) => createBars(d, xScale, yScale));
  }
  return createBars(params.data, xScale, yScale);
}
function createLinePaths(root, data, size, padding) {
  const xScale = new _Scene.LinearScale();
  xScale.domain = [0, 4];
  xScale.range = [padding, size - padding];
  const yScale = new _Scene.LinearScale();
  yScale.domain = [0, 10];
  yScale.range = [size - padding, padding];
  const lines = data.map((series) => {
    const line = new _Scene.Path();
    line.strokeWidth = 3;
    line.lineCap = "round";
    line.fill = void 0;
    series.forEach((datum, i) => {
      line.path[i > 0 ? "lineTo" : "moveTo"](xScale.convert(i), yScale.convert(datum));
    });
    return line;
  });
  const linesGroup = new _Scene.Group();
  linesGroup.setClipRect(new _Scene.BBox(padding, padding, size - padding * 2, size - padding * 2));
  linesGroup.append(lines);
  root.append(linesGroup);
  return lines;
}
function createPolarPaths(root, data, size, radius, innerRadius, markerSize = 0) {
  const angleScale = new _Scene.LinearScale();
  angleScale.domain = [0, 7];
  angleScale.range = [-Math.PI, Math.PI].map((angle) => angle + Math.PI / 2);
  const radiusScale = new _Scene.LinearScale();
  radiusScale.domain = [0, 10];
  radiusScale.range = [radius, innerRadius];
  const markers = [];
  const center = size / 2;
  const paths = data.map((series) => {
    const path = new _Scene.Path();
    path.strokeWidth = 1;
    path.strokeOpacity = 0.5;
    path.lineCap = "round";
    path.fill = void 0;
    path.fillOpacity = 0.8;
    series.forEach((datum, i) => {
      const angle = angleScale.convert(i);
      const r = radius + innerRadius - radiusScale.convert(datum);
      const x = r * Math.cos(angle) + center;
      const y = r * Math.sin(angle) + center;
      path.path[i > 0 ? "lineTo" : "moveTo"](x, y);
      if (markerSize > 0) {
        const marker = new _Scene.Circle();
        marker.x = x;
        marker.y = y;
        marker.size = markerSize;
        markers.push(marker);
      }
    });
    path.path.closePath();
    return path;
  });
  const group = new _Scene.Group();
  group.append([...paths, ...markers]);
  root.append(group);
  return { paths, markers };
}
function accumulateData(data) {
  let [min, max] = [Infinity, -Infinity];
  const processedData = data.reduce((acc, curr, currIndex) => {
    const previous = currIndex > 0 ? acc[currIndex - 1] : void 0;
    acc[currIndex] ?? (acc[currIndex] = []);
    const current = acc[currIndex];
    curr.forEach((datum, datumIndex) => {
      if (previous) {
        datum += previous[datumIndex];
      }
      current[datumIndex] = datum;
      if (current[datumIndex] < min) {
        min = current[datumIndex];
      }
      if (current[datumIndex] > max) {
        max = current[datumIndex];
      }
    });
    return acc;
  }, []);
  return { processedData, min, max };
}
var CANVAS_CLASS = "ag-chart-mini-thumbnail-canvas";
var ERROR_MESSAGE = "AG Grid - chart update failed";
var MiniChart = class extends Component35 {
  constructor(container, tooltipName) {
    super();
    this.tooltipName = tooltipName;
    this.size = 58;
    this.padding = 5;
    this.root = new _Scene2.Group();
    const scene = new _Scene2.Scene({
      width: this.size,
      height: this.size
    });
    scene.canvas.element.classList.add(CANVAS_CLASS);
    scene.setRoot(this.root);
    scene.setContainer(container);
    this.scene = scene;
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    this.scene.canvas.element.title = this.chartTranslationService.translate(this.tooltipName);
    this.scene.render().catch((e) => {
      _errorOnce2(`${ERROR_MESSAGE}`, e);
    });
  }
};
var MiniChartWithAxes = class extends MiniChart {
  constructor(container, tooltipName) {
    super(container, tooltipName);
    this.stroke = "gray";
    this.axisOvershoot = 3;
  }
  postConstruct() {
    const size = this.size;
    const padding = this.padding;
    const leftAxis = new _Scene3.Line();
    leftAxis.x1 = padding;
    leftAxis.y1 = padding;
    leftAxis.x2 = padding;
    leftAxis.y2 = size - padding + this.axisOvershoot;
    leftAxis.stroke = this.stroke;
    const bottomAxis = new _Scene3.Line();
    bottomAxis.x1 = padding - this.axisOvershoot + 1;
    bottomAxis.y1 = size - padding;
    bottomAxis.x2 = size - padding + 1;
    bottomAxis.y2 = size - padding;
    bottomAxis.stroke = this.stroke;
    const root = this.root;
    root.append(leftAxis);
    root.append(bottomAxis);
    super.postConstruct();
  }
};
var MiniColumn = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "groupedColumnTooltip");
    this.columnData = [2, 3, 4];
    const { root, columnData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1, 2],
      yScaleDomain: [0, 4],
      xScalePadding: 0.3
    });
    root.append(this.columns);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.columns.forEach((column, i) => {
      column.fill = fills[i];
      column.stroke = strokes[i];
    });
  }
};
MiniColumn.chartType = "groupedColumn";
var _MiniStackedColumn = class _MiniStackedColumn2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniStackedColumn2.data, yScaleDomain = [0, 16], tooltipName = "stackedColumnTooltip") {
    super(container, tooltipName);
    const { root, size, padding } = this;
    this.stackedColumns = createColumnRects({
      stacked: true,
      root,
      data,
      size,
      padding,
      xScaleDomain: [0, 1, 2],
      yScaleDomain,
      xScalePadding: 0.3
    });
    root.append([].concat.apply([], this.stackedColumns));
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.stackedColumns.forEach(
      (series, i) => series.forEach((column) => {
        column.fill = fills[i];
        column.stroke = strokes[i];
      })
    );
  }
};
_MiniStackedColumn.chartType = "stackedColumn";
_MiniStackedColumn.data = [
  [8, 12, 16],
  [6, 9, 12],
  [2, 3, 4]
];
var MiniStackedColumn = _MiniStackedColumn;
var _MiniNormalizedColumn = class _MiniNormalizedColumn2 extends MiniStackedColumn {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(
      container,
      fills,
      strokes,
      themeTemplateParameters,
      isCustomTheme,
      _MiniNormalizedColumn2.data,
      [0, 10],
      "normalizedColumnTooltip"
    );
  }
};
_MiniNormalizedColumn.chartType = "normalizedColumn";
_MiniNormalizedColumn.data = [
  [10, 10, 10],
  [6, 7, 8],
  [2, 4, 6]
];
var MiniNormalizedColumn = _MiniNormalizedColumn;
var MiniBar = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "groupedBarTooltip");
    const padding = this.padding;
    const size = this.size;
    const data = [2, 3, 4];
    const yScale = new _Scene4.BandScale();
    yScale.domain = [0, 1, 2];
    yScale.range = [padding, size - padding];
    yScale.paddingInner = 0.3;
    yScale.paddingOuter = 0.3;
    const xScale = new _Scene4.LinearScale();
    xScale.domain = [0, 4];
    xScale.range = [size - padding, padding];
    const bottom = xScale.convert(0);
    const height = yScale.bandwidth;
    this.bars = data.map((datum, i) => {
      const rect = new _Scene4.Rect();
      rect.x = padding;
      rect.y = yScale.convert(i);
      rect.width = bottom - xScale.convert(datum);
      rect.height = height;
      rect.strokeWidth = 0;
      rect.crisp = true;
      return rect;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.bars);
  }
  updateColors(fills, strokes) {
    this.bars.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
  }
};
MiniBar.chartType = "groupedBar";
var _MiniStackedBar = class _MiniStackedBar2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniStackedBar2.data, xScaleDomain = [0, 16], tooltipName = "stackedBarTooltip") {
    super(container, tooltipName);
    const size = this.size;
    const padding = this.padding;
    const yScale = new _Scene5.BandScale();
    yScale.domain = [0, 1, 2];
    yScale.range = [padding, size - padding];
    yScale.paddingInner = 0.3;
    yScale.paddingOuter = 0.3;
    const xScale = new _Scene5.LinearScale();
    xScale.domain = xScaleDomain;
    xScale.range = [size - padding, padding];
    const bottom = xScale.convert(0);
    const height = yScale.bandwidth;
    this.bars = data.map(
      (series) => series.map((datum, i) => {
        const rect = new _Scene5.Rect();
        rect.x = padding;
        rect.y = yScale.convert(i);
        rect.width = bottom - xScale.convert(datum);
        rect.height = height;
        rect.strokeWidth = 0;
        rect.crisp = true;
        return rect;
      })
    );
    this.updateColors(fills, strokes);
    this.root.append([].concat.apply([], this.bars));
  }
  updateColors(fills, strokes) {
    this.bars.forEach(
      (series, i) => series.forEach((bar) => {
        bar.fill = fills[i];
        bar.stroke = strokes[i];
      })
    );
  }
};
_MiniStackedBar.chartType = "stackedBar";
_MiniStackedBar.data = [
  [8, 12, 16],
  [6, 9, 12],
  [2, 3, 4]
];
var MiniStackedBar = _MiniStackedBar;
var _MiniNormalizedBar = class _MiniNormalizedBar2 extends MiniStackedBar {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(
      container,
      fills,
      strokes,
      themeTemplateParameters,
      isCustomTheme,
      _MiniNormalizedBar2.data,
      [0, 10],
      "normalizedBarTooltip"
    );
  }
};
_MiniNormalizedBar.chartType = "normalizedBar";
_MiniNormalizedBar.data = [
  [10, 10, 10],
  [6, 7, 8],
  [2, 4, 6]
];
var MiniNormalizedBar = _MiniNormalizedBar;
var toRadians = _Scene6.toRadians;
var MiniDonut = class extends MiniChart {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, centerRadiusScaler = 0.6, tooltipName = "donutTooltip") {
    super(container, tooltipName);
    const radius = (this.size - this.padding * 2) / 2;
    const center = radius + this.padding;
    const angles = [
      [toRadians(-90), toRadians(30)],
      [toRadians(30), toRadians(120)],
      [toRadians(120), toRadians(180)],
      [toRadians(180), toRadians(210)],
      [toRadians(210), toRadians(240)],
      [toRadians(240), toRadians(270)]
    ];
    this.sectors = angles.map(([startAngle, endAngle]) => {
      const sector = new _Scene6.Sector();
      sector.centerX = center;
      sector.centerY = center;
      sector.innerRadius = radius * centerRadiusScaler;
      sector.outerRadius = radius;
      sector.startAngle = startAngle;
      sector.endAngle = endAngle;
      sector.stroke = void 0;
      sector.strokeWidth = 0;
      sector.inset = 0.75;
      return sector;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.sectors);
  }
  updateColors(fills, strokes) {
    this.sectors.forEach((sector, i) => {
      sector.fill = fills[i % fills.length];
      sector.stroke = strokes[i % strokes.length];
    });
  }
};
MiniDonut.chartType = "donut";
var MiniPie = class extends MiniDonut {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(container, fills, strokes, themeTemplateParameters, isCustomTheme, 0, "pieTooltip");
  }
};
MiniPie.chartType = "pie";
var MiniLine = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "lineTooltip");
    this.data = [
      [9, 7, 8, 5, 6],
      [5, 6, 3, 4, 1],
      [1, 3, 4, 8, 7]
    ];
    this.lines = createLinePaths(this.root, this.data, this.size, this.padding);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.lines.forEach((line, i) => {
      line.stroke = fills[i];
    });
  }
};
MiniLine.chartType = "line";
var MiniScatter = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "scatterTooltip");
    const size = this.size;
    const padding = this.padding;
    const data = [
      [
        [0.3, 3],
        [1.1, 0.9],
        [2, 0.4],
        [3.4, 2.4]
      ],
      [
        [0, 0.3],
        [1, 2],
        [2.4, 1.4],
        [3, 0]
      ]
    ];
    const xScale = new _Scene7.LinearScale();
    xScale.domain = [-0.5, 4];
    xScale.range = [padding * 2, size - padding];
    const yScale = new _Scene7.LinearScale();
    yScale.domain = [-0.5, 3.5];
    yScale.range = [size - padding, padding];
    const points = [];
    data.forEach((series) => {
      series.forEach(([x, y]) => {
        const arc = new _Scene7.Arc();
        arc.strokeWidth = 0;
        arc.centerX = xScale.convert(x);
        arc.centerY = yScale.convert(y);
        arc.radius = 2.5;
        points.push(arc);
      });
    });
    this.points = points;
    this.updateColors(fills, strokes);
    const pointsGroup = new _Scene7.Group();
    pointsGroup.setClipRect(new _Scene7.BBox(padding, padding, size - padding * 2, size - padding * 2));
    pointsGroup.append(this.points);
    this.root.append(pointsGroup);
  }
  updateColors(fills, strokes) {
    this.points.forEach((line, i) => {
      line.stroke = strokes[i % strokes.length];
      line.fill = fills[i % fills.length];
    });
  }
};
MiniScatter.chartType = "scatter";
var MiniBubble = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "bubbleTooltip");
    const size = this.size;
    const padding = this.padding;
    const data = [
      [
        [0.1, 0.3, 5],
        [0.5, 0.4, 7],
        [0.2, 0.8, 7]
      ],
      [
        [0.8, 0.7, 5],
        [0.7, 0.3, 9]
      ]
    ];
    const xScale = new _Scene8.LinearScale();
    xScale.domain = [0, 1];
    xScale.range = [padding * 2, size - padding];
    const yScale = new _Scene8.LinearScale();
    yScale.domain = [0, 1];
    yScale.range = [size - padding, padding];
    const points = [];
    data.forEach((series) => {
      series.forEach(([x, y, radius]) => {
        const arc = new _Scene8.Arc();
        arc.strokeWidth = 0;
        arc.centerX = xScale.convert(x);
        arc.centerY = yScale.convert(y);
        arc.radius = radius;
        arc.fillOpacity = 0.7;
        points.push(arc);
      });
    });
    this.points = points;
    this.updateColors(fills, strokes);
    const pointsGroup = new _Scene8.Group();
    pointsGroup.setClipRect(new _Scene8.BBox(padding, padding, size - padding * 2, size - padding * 2));
    pointsGroup.append(this.points);
    this.root.append(pointsGroup);
  }
  updateColors(fills, strokes) {
    this.points.forEach((line, i) => {
      line.stroke = strokes[i % strokes.length];
      line.fill = fills[i % fills.length];
    });
  }
};
MiniBubble.chartType = "bubble";
var _MiniArea = class _MiniArea2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniArea2.data) {
    super(container, "groupedAreaTooltip");
    const size = this.size;
    const padding = this.padding;
    const xScale = new _Scene9.BandScale();
    xScale.domain = [0, 1, 2];
    xScale.paddingInner = 1;
    xScale.paddingOuter = 0;
    xScale.range = [padding + 0.5, size - padding - 0.5];
    const yScale = new _Scene9.LinearScale();
    yScale.domain = [0, 6];
    yScale.range = [size - padding + 0.5, padding];
    const xCount = data.length;
    const last = xCount * 2 - 1;
    const pathData = [];
    const bottomY = yScale.convert(0);
    data.forEach((datum, i) => {
      const x = xScale.convert(i);
      datum.forEach((yDatum, j) => {
        const y = yScale.convert(yDatum);
        const points = pathData[j] || (pathData[j] = []);
        points[i] = {
          x,
          y
        };
        points[last - i] = {
          x,
          y: bottomY
        };
      });
    });
    this.areas = pathData.reverse().map((points) => {
      const area = new _Scene9.Path();
      area.strokeWidth = 1;
      area.strokeOpacity = 0.75;
      area.fillOpacity = 0.7;
      const path = area.path;
      path.clear();
      points.forEach((point, i) => path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y));
      path.closePath();
      return area;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.areas);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
  }
};
_MiniArea.chartType = "area";
_MiniArea.data = [
  [1, 3, 5],
  [2, 6, 4],
  [5, 3, 1]
];
var MiniArea = _MiniArea;
var _MiniStackedArea = class _MiniStackedArea2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniStackedArea2.data, tooltipName = "stackedAreaTooltip") {
    super(container, tooltipName);
    const size = this.size;
    const padding = this.padding;
    const xScale = new _Scene10.BandScale();
    xScale.domain = [0, 1, 2];
    xScale.paddingInner = 1;
    xScale.paddingOuter = 0;
    xScale.range = [padding + 0.5, size - padding - 0.5];
    const yScale = new _Scene10.LinearScale();
    yScale.domain = [0, 16];
    yScale.range = [size - padding + 0.5, padding + 0.5];
    const xCount = data.length;
    const last = xCount * 2 - 1;
    const pathData = [];
    data.forEach((datum, i) => {
      const x = xScale.convert(i);
      let total = 0;
      datum.forEach((yDatum, j) => {
        const y = yScale.convert(total + yDatum);
        const points = pathData[j] || (pathData[j] = []);
        points[i] = {
          x,
          y
        };
        points[last - i] = {
          x,
          y: yScale.convert(total)
          // bottom y
        };
        total += yDatum;
      });
    });
    this.areas = pathData.map((points) => {
      const area = new _Scene10.Path();
      area.strokeWidth = 0;
      const path = area.path;
      path.clear();
      points.forEach((point, i) => path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y));
      path.closePath();
      return area;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.areas);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
  }
};
_MiniStackedArea.chartType = "stackedArea";
_MiniStackedArea.data = [
  [2, 3, 2],
  [3, 6, 5],
  [6, 2, 2]
];
var MiniStackedArea = _MiniStackedArea;
var _MiniNormalizedArea = class _MiniNormalizedArea2 extends MiniStackedArea {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme, data = _MiniNormalizedArea2.data) {
    super(container, fills, strokes, themeTemplateParameters, isCustomTheme, data, "normalizedAreaTooltip");
  }
};
_MiniNormalizedArea.chartType = "normalizedArea";
_MiniNormalizedArea.data = MiniStackedArea.data.map((stack) => {
  const sum = stack.reduce((p, c) => p + c, 0);
  return stack.map((v) => v / sum * 16);
});
var MiniNormalizedArea = _MiniNormalizedArea;
var MiniHistogram = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "histogramTooltip");
    const padding = this.padding;
    const size = this.size;
    const data = [2, 5, 11, 13, 10, 6, 1];
    const xScale = new _Scene11.LinearScale();
    xScale.domain = [0, data.length];
    xScale.range = [padding, size - padding];
    const yScale = new _Scene11.LinearScale();
    yScale.domain = [0, data.reduce((a, b) => Math.max(a, b), 0)];
    yScale.range = [size - padding, padding];
    const bottom = yScale.convert(0);
    this.bars = data.map((datum, i) => {
      const top = yScale.convert(datum);
      const left = xScale.convert(i);
      const right = xScale.convert(i + 1);
      const rect = new _Scene11.Rect();
      rect.x = left;
      rect.y = top;
      rect.width = right - left;
      rect.height = bottom - top;
      rect.strokeWidth = 1;
      rect.strokeOpacity = 0.75;
      rect.crisp = true;
      return rect;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.bars);
  }
  updateColors([fill], [stroke]) {
    this.bars.forEach((bar) => {
      bar.fill = fill;
      bar.stroke = stroke;
    });
  }
};
MiniHistogram.chartType = "histogram";
var MiniChartWithPolarAxes = class extends MiniChart {
  constructor(container, tooltipName) {
    super(container, tooltipName);
    this.stroke = "gray";
    this.showRadiusAxisLine = true;
    this.showAngleAxisLines = true;
  }
  postConstruct() {
    const size = this.size;
    const padding = this.padding;
    const combinedPadding = padding * 2;
    const axisLineRadius = (size - combinedPadding) / 2;
    const gridRadii = this.showAngleAxisLines ? [axisLineRadius, axisLineRadius * 0.8, axisLineRadius * 0.6, axisLineRadius * 0.4] : [];
    const radiusAxisLine = new _Scene12.Line();
    radiusAxisLine.x1 = size / 2;
    radiusAxisLine.y1 = padding;
    radiusAxisLine.x2 = size / 2;
    radiusAxisLine.y2 = size - padding - axisLineRadius - gridRadii[gridRadii.length - 1];
    radiusAxisLine.stroke = this.stroke;
    radiusAxisLine.strokeOpacity = 0.5;
    radiusAxisLine.fill = void 0;
    radiusAxisLine.visible = this.showRadiusAxisLine;
    const x = padding + axisLineRadius;
    this.gridLines = gridRadii.map((radius, index) => {
      const gridLine = new _Scene12.Path();
      gridLine.path.arc(x, x, radius, 0, 2 * Math.PI);
      gridLine.strokeWidth = 1;
      gridLine.stroke = this.stroke;
      gridLine.strokeOpacity = index === 0 ? 0.5 : 0.2;
      gridLine.fill = void 0;
      return gridLine;
    });
    const root = this.root;
    root.append(radiusAxisLine);
    if (this.gridLines.length > 0)
      root.append(this.gridLines);
    super.postConstruct();
  }
};
var MiniRadialColumn = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radialColumnTooltip");
    this.data = [
      [6, 8, 10, 2, 6, 5],
      [4, 4, 3, 6, 4, 4],
      [5, 4, 2, 9, 8, 9]
    ];
    this.showRadiusAxisLine = false;
    const { padding, size, data } = this;
    const radius = (size - padding * 2) / 2;
    const innerRadiusRatio = 0.4;
    const axisInnerRadius = radius * innerRadiusRatio;
    const angleScale = new _Scene13.BandScale();
    angleScale.domain = data[0].map((_, index) => index);
    angleScale.range = [0, 2 * Math.PI];
    angleScale.paddingInner = 0;
    angleScale.paddingOuter = 0;
    const bandwidth = angleScale.bandwidth * 0.7;
    const { processedData, max } = accumulateData(data);
    const radiusScale = new _Scene13.LinearScale();
    radiusScale.domain = [0, max];
    radiusScale.range = [axisInnerRadius, radius];
    const center = this.size / 2;
    this.series = processedData.map((series, seriesIndex) => {
      const firstSeries = seriesIndex === 0;
      const previousSeries = firstSeries ? void 0 : processedData[seriesIndex - 1];
      const seriesGroup = new _Scene13.TranslatableGroup({ zIndex: 1e6 });
      const seriesColumns = series.map((datum, i) => {
        const previousDatum = previousSeries?.[i];
        const outerRadius = radiusScale.convert(datum);
        const innerRadius = radiusScale.convert(previousDatum ?? 0);
        const startAngle = angleScale.convert(i);
        const endAngle = startAngle + bandwidth;
        const columnWidth = _Scene13.getRadialColumnWidth(startAngle, endAngle, radius, 0.5, 0.5);
        const column = new _Scene13.RadialColumnShape();
        column.columnWidth = columnWidth;
        column.innerRadius = innerRadius;
        column.outerRadius = outerRadius;
        column.startAngle = startAngle;
        column.endAngle = endAngle;
        column.isBeveled = true;
        column.axisInnerRadius = axisInnerRadius;
        column.axisOuterRadius = radius;
        column.stroke = void 0;
        column.strokeWidth = 0;
        return column;
      });
      seriesGroup.append(seriesColumns);
      seriesGroup.translationX = center;
      seriesGroup.translationY = center;
      return seriesGroup;
    });
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      for (const sector of group.children()) {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      }
    });
  }
};
MiniRadialColumn.chartType = "radialColumn";
var MiniRadialBar = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radialBarTooltip");
    this.data = [
      [6, 8, 10],
      [4, 4, 3],
      [5, 4, 2]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding) / 2;
    const innerRadiusRatio = 0.4;
    const innerRadius = radius * innerRadiusRatio;
    const radiusScale = new _Scene14.BandScale();
    radiusScale.domain = this.data[0].map((_, index) => index);
    radiusScale.range = [innerRadius, radius];
    radiusScale.paddingInner = 0.5;
    radiusScale.paddingOuter = 0;
    const bandwidth = radiusScale.bandwidth;
    const { processedData, max } = accumulateData(this.data);
    const angleScale = new _Scene14.LinearScale();
    angleScale.domain = [0, Math.ceil(max * 1.5)];
    const start = 3 / 2 * Math.PI;
    const end = start + 2 * Math.PI;
    angleScale.range = [start, end];
    const center = this.size / 2;
    this.series = processedData.map((series, index) => {
      const previousSeries = index < 0 ? void 0 : processedData[index - 1];
      const seriesGroup = new _Scene14.Group({ zIndex: 1e6 });
      const seriesSectors = series.map((datum, i) => {
        const previousDatum = previousSeries?.[i] ?? 0;
        const outerRadius = radiusScale.convert(i);
        const innerRadius2 = outerRadius - bandwidth;
        const startAngle = angleScale.convert(previousDatum);
        const endAngle = angleScale.convert(datum);
        const sector = new _Scene14.Sector();
        sector.centerX = center;
        sector.centerY = center;
        sector.innerRadius = innerRadius2;
        sector.outerRadius = outerRadius;
        sector.startAngle = startAngle;
        sector.endAngle = endAngle;
        sector.stroke = void 0;
        sector.strokeWidth = 0;
        return sector;
      });
      seriesGroup.append(seriesSectors);
      return seriesGroup;
    });
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      for (const sector of group.children()) {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      }
    });
  }
};
MiniRadialBar.chartType = "radialBar";
var MiniRadarLine = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radarLineTooltip");
    this.markerSize = 4;
    this.data = [
      [8, 7, 8, 7, 8, 8, 7, 8],
      [6, 8, 5, 10, 6, 7, 4, 6],
      [0, 3, 3, 5, 4, 4, 2, 0]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding * 2) / 2;
    const innerRadius = 0;
    const { paths, markers } = createPolarPaths(
      this.root,
      this.data,
      this.size,
      radius,
      innerRadius,
      this.markerSize
    );
    this.lines = paths;
    this.markers = markers;
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.lines.forEach((line, i) => {
      const n = this.data[i].length;
      line.stroke = fills[i];
      const startIdx = i * n;
      const endIdx = startIdx + n;
      const markers = this.markers.slice(startIdx, endIdx);
      markers.forEach((marker) => {
        marker.stroke = strokes[i];
        marker.fill = fills[i];
      });
    });
  }
};
MiniRadarLine.chartType = "radarLine";
var MiniRadarArea = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radarAreaTooltip");
    this.data = [
      [8, 10, 5, 7, 4, 1, 5, 8],
      [1, 1, 2, 7, 7, 8, 10, 1],
      [4, 5, 9, 9, 4, 2, 3, 4]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding * 2) / 2;
    const innerRadius = radius - this.size * 0.3;
    this.areas = createPolarPaths(this.root, this.data, this.size, radius, innerRadius).paths;
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
  }
};
MiniRadarArea.chartType = "radarArea";
var MiniNightingale = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "nightingaleTooltip");
    this.data = [
      [6, 10, 9, 8, 7, 8],
      [4, 6, 5, 4, 5, 5],
      [3, 5, 4, 3, 4, 7]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding * 2) / 2;
    const angleScale = new _Scene15.BandScale();
    angleScale.domain = this.data[0].map((_, index) => index);
    angleScale.range = [-Math.PI, Math.PI];
    angleScale.paddingInner = 0;
    angleScale.paddingOuter = 0;
    const bandwidth = angleScale.bandwidth * 0.7;
    const { processedData, max } = accumulateData(this.data);
    const radiusScale = new _Scene15.LinearScale();
    radiusScale.domain = [0, max];
    radiusScale.range = [0, radius];
    const center = this.size / 2;
    this.series = processedData.map((series, index) => {
      const previousSeries = index < 0 ? void 0 : processedData[index - 1];
      const seriesGroup = new _Scene15.Group({ zIndex: 1e6 });
      const seriesSectors = series.map((datum, i) => {
        const previousDatum = previousSeries?.[i];
        const outerRadius = radiusScale.convert(datum);
        const innerRadius = radiusScale.convert(previousDatum ?? 0);
        const startAngle = angleScale.convert(i);
        const endAngle = startAngle + bandwidth;
        const sector = new _Scene15.Sector();
        sector.centerX = center;
        sector.centerY = center;
        sector.innerRadius = innerRadius;
        sector.outerRadius = outerRadius;
        sector.startAngle = startAngle;
        sector.endAngle = endAngle;
        sector.stroke = void 0;
        sector.strokeWidth = 0;
        return sector;
      });
      seriesGroup.append(seriesSectors);
      return seriesGroup;
    });
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      for (const sector of group.children()) {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      }
    });
  }
};
MiniNightingale.chartType = "nightingale";
var MiniRangeBar = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "rangeBarTooltip");
    const data = [3, 3.5, 3];
    this.bars = this.createRangeBar(this.root, data, this.size, this.padding, "vertical");
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.bars.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
  }
  createRangeBar(root, data, size, padding, direction) {
    const barAlongX = direction === "horizontal";
    const scalePadding = 2 * padding;
    const xScale = new _Scene16.BandScale();
    xScale.domain = data.map((_, index) => index);
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.3;
    xScale.paddingOuter = 0.3;
    const lowRatio = 0.7;
    const highRatio = 1.3;
    const yScale = new _Scene16.LinearScale();
    yScale.domain = [
      data.reduce((a, b) => Math.min(a, b), Infinity) * lowRatio,
      data.reduce((a, b) => Math.max(a, b), 0) * highRatio
    ];
    yScale.range = [scalePadding, size - scalePadding];
    const width = xScale.bandwidth;
    const bars = data.map((datum, i) => {
      const [low, high] = [datum * lowRatio, datum * highRatio];
      const x = xScale.convert(i);
      const y = yScale.convert(low);
      const height = yScale.convert(high) - y;
      const rect = new _Scene16.Rect();
      rect.x = barAlongX ? y : x;
      rect.y = barAlongX ? x : y;
      rect.width = barAlongX ? height : width;
      rect.height = barAlongX ? width : height;
      rect.strokeWidth = 0;
      rect.crisp = true;
      return rect;
    });
    root.append(bars);
    return bars;
  }
};
MiniRangeBar.chartType = "rangeBar";
var MiniRangeArea = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "rangeAreaTooltip");
    const period = 4;
    const dataSeriesMidpoints = [
      zigzag({ offset: 0.375 * period, length: period, pattern: { low: 3, high: 5, period } }),
      zigzag({ offset: 0.375 * period, length: period, pattern: { low: 2.25, high: 4.25, period } }),
      zigzag({ offset: 0.75 * period, length: period, pattern: { low: 2.5, high: 4.5, period } })
    ];
    const dataSeriesWidth = 1.75;
    const data = dataSeriesMidpoints.map(
      (series) => series.map(([x, y]) => ({
        x,
        low: y - 0.5 * dataSeriesWidth,
        high: y + 0.5 * dataSeriesWidth
      }))
    );
    const { lines, areas } = this.createRangeArea(this.root, data, this.size, this.padding);
    this.lines = lines;
    this.areas = areas;
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    fills = swapArrayItems(fills, 1, 2);
    strokes = swapArrayItems(strokes, 1, 2);
    this.lines.forEach(([highLine, lowLine], i) => {
      highLine.fill = void 0;
      highLine.stroke = strokes[i];
      lowLine.fill = void 0;
      lowLine.stroke = strokes[i];
    });
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
    });
  }
  createRangeArea(root, data, size, padding) {
    const xMin = data.reduce((acc, series) => series.reduce((acc2, { x }) => Math.min(acc2, x), acc), Infinity);
    const xMax = data.reduce((acc, series) => series.reduce((acc2, { x }) => Math.max(acc2, x), acc), -Infinity);
    const yMin = data.reduce((acc, series) => series.reduce((acc2, { low }) => Math.min(acc2, low), acc), Infinity);
    const yMax = data.reduce(
      (acc, series) => series.reduce((acc2, { high }) => Math.max(acc2, high), acc),
      -Infinity
    );
    const xScale = new _Scene17.LinearScale();
    xScale.domain = [xMin, xMax];
    xScale.range = [padding, size - padding];
    const scalePadding = 2 * padding;
    const yScale = new _Scene17.LinearScale();
    yScale.domain = [yMin, yMax];
    yScale.range = [size - scalePadding, scalePadding];
    const lines = [];
    const areas = [];
    const lowPoints = data.map((series) => {
      const highLine = new _Scene17.Path();
      const lowLine = new _Scene17.Path();
      const area = new _Scene17.Path();
      lines.push([highLine, lowLine]);
      areas.push(area);
      highLine.strokeWidth = 0;
      lowLine.strokeWidth = 0;
      area.strokeWidth = 0;
      area.fillOpacity = 0.8;
      highLine.path.clear();
      lowLine.path.clear();
      area.path.clear();
      return series.map((datum, datumIndex) => {
        const { x, low, high } = datum;
        const scaledX = xScale.convert(x);
        const yLow = yScale.convert(low);
        const yHigh = yScale.convert(high);
        const command = datumIndex > 0 ? "lineTo" : "moveTo";
        highLine.path[command](scaledX, yHigh);
        lowLine.path[command](scaledX, yLow);
        area.path[command](scaledX, yHigh);
        return [scaledX, yLow];
      });
    });
    lowPoints.forEach((seriesLowPoints, seriesIndex) => {
      const n = seriesLowPoints.length - 1;
      const area = areas[seriesIndex];
      for (let datumIndex = n; datumIndex >= 0; datumIndex--) {
        const [x, y] = seriesLowPoints[datumIndex];
        area.path["lineTo"](x, y);
      }
    });
    root.append(areas.concat(...lines));
    return { lines, areas };
  }
};
MiniRangeArea.chartType = "rangeArea";
function zigzag(options) {
  const { offset, length, pattern } = options;
  const points = getZigzagInflectionPoints(offset, length, pattern);
  const xMin = 0;
  const xMax = length;
  if (points.length === 0 || points[0][0] !== xMin)
    points.unshift(getZigzagPoint(xMin, offset, pattern));
  if (points[points.length - 1][0] !== xMax)
    points.push(getZigzagPoint(xMax, offset, pattern));
  return points;
  function getZigzagInflectionPoints(offset2, length2, pattern2) {
    const { period } = pattern2;
    const scaledOffset = offset2 / period;
    const patternInflectionPoints = [0, 0.5];
    const inflectionPoints = patternInflectionPoints.map((x) => x - scaledOffset).map(getRemainderAbs).sort((a, b) => a - b);
    const repeatedPoints = Array.from(
      { length: Math.floor(inflectionPoints.length * (period / length2)) },
      (_, i) => inflectionPoints[i % inflectionPoints.length] + Math.floor(i / inflectionPoints.length)
    );
    return repeatedPoints.map((x) => x * period).map((x) => getZigzagPoint(x, offset2, pattern2));
  }
  function getZigzagPoint(x, offset2, pattern2) {
    return [x, getZigzagValue(offset2 + x, pattern2)];
  }
  function getZigzagValue(x, pattern2) {
    const { low, high, period } = pattern2;
    const scaledX = getRemainderAbs(x / period);
    const y = scaledX > 0.5 ? 1 - 2 * (scaledX - 0.5) : 2 * scaledX;
    return low + (high - low) * y;
  }
}
function getRemainderAbs(value) {
  const remainder = value % 1;
  return remainder < 0 ? remainder + 1 : remainder;
}
function swapArrayItems(items, leftIndex, rightIndex) {
  const results = [...items];
  const temp = results[leftIndex];
  results[leftIndex] = results[rightIndex];
  results[rightIndex] = temp;
  return results;
}
var MiniBoxPlot = class extends MiniChartWithAxes {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(container, "boxPlotTooltip");
    const padding = this.padding;
    const size = this.size;
    const data = [11, 11.5, 10.5];
    const maxRatio = 1.2;
    const q3Ratio = 1.1;
    const q1Ratio = 0.9;
    const minRatio = 0.8;
    const yScale = new _Scene18.LinearScale();
    yScale.domain = [
      data.reduce((a, b) => Math.min(a, b), Infinity) * minRatio,
      data.reduce((a, b) => Math.max(a, b), 0) * maxRatio
    ];
    yScale.range = [size - 1.5 * padding, padding];
    const xScale = new _Scene18.BandScale();
    xScale.domain = data.map((_, index) => index);
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.4;
    xScale.paddingOuter = 0.2;
    const bandwidth = Math.round(xScale.bandwidth);
    const halfBandWidth = Math.round(xScale.bandwidth / 2);
    this.boxPlotGroups = data.map((datum, i) => {
      const [minValue, q1Value, q3Value, maxValue] = [
        datum * minRatio,
        datum * q1Ratio,
        datum * q3Ratio,
        datum * maxRatio
      ];
      const top = Math.round(yScale.convert(q3Value));
      const left = Math.round(xScale.convert(i));
      const right = Math.round(left + bandwidth);
      const bottom = Math.round(yScale.convert(q1Value));
      const min = Math.round(yScale.convert(minValue));
      const mid = Math.round(yScale.convert(datum));
      const max = Math.round(yScale.convert(maxValue));
      const whiskerX = left + halfBandWidth;
      const boxPlotGroup = new _Scene18.Group();
      const box = new _Scene18.Rect();
      const median = new _Scene18.Line();
      const topWhisker = new _Scene18.Line();
      const bottomWhisker = new _Scene18.Line();
      const topCap = new _Scene18.Line();
      const bottomCap = new _Scene18.Line();
      box.x = left;
      box.y = top;
      box.width = bandwidth;
      box.height = bottom - top;
      box.strokeWidth = 1;
      box.strokeOpacity = 0.75;
      box.crisp = true;
      this.setLineProperties(median, left, right, mid, mid);
      this.setLineProperties(topWhisker, whiskerX, whiskerX, max, top);
      this.setLineProperties(bottomWhisker, whiskerX, whiskerX, min, bottom);
      this.setLineProperties(topCap, left, right, max, max);
      this.setLineProperties(bottomCap, left, right, min, min);
      boxPlotGroup.append([box, median, topWhisker, bottomWhisker, topCap, bottomCap]);
      return boxPlotGroup;
    });
    this.updateColors(fills, strokes, themeTemplateParameters, isCustomTheme);
    this.root.append(this.boxPlotGroups);
  }
  updateColors(fills, strokes, themeTemplateParameters, isCustomTheme) {
    const themeBackgroundColor = themeTemplateParameters?.get(_Theme4.DEFAULT_BACKGROUND_COLOUR);
    const backgroundFill = (Array.isArray(themeBackgroundColor) ? themeBackgroundColor[0] : themeBackgroundColor) ?? "white";
    this.boxPlotGroups.forEach((group, i) => {
      for (const node of group.children()) {
        const fill = fills[i % fills.length];
        node.fill = isCustomTheme ? fill : _Util5.interpolateColor(fill, backgroundFill)(0.7);
        node.stroke = strokes[i % strokes.length];
      }
    });
  }
  setLineProperties(line, x1, x2, y1, y2) {
    line.x1 = x1;
    line.x2 = x2;
    line.y1 = y1;
    line.y2 = y2;
    line.strokeOpacity = 0.75;
  }
};
MiniBoxPlot.chartType = "boxPlot";
var MiniTreemap = class extends MiniChart {
  constructor(container, fills, strokes, themeTemplate, isCustomTheme) {
    super(container, "treemapTooltip");
    const { size, padding } = this;
    const data = [
      [1, 1],
      [3, 2, 1]
    ];
    const treeSize = data.length;
    const treePadding = treeSize % 2 === 0 ? 0.3 : 0.2;
    const range = [padding, size - padding];
    const columns = data.length;
    const columnParts = columns * (columns + 1) / 2;
    const columnPadding = treePadding / (columns - 1);
    const availableRange = range[1] - range[0];
    const availableWidth = availableRange - treePadding;
    let previousX = range[0];
    this.rects = data.reduce((rects, d, columnIndex) => {
      rects ?? (rects = []);
      const widthRatio = (columns - columnIndex) / columnParts;
      const width = availableWidth * widthRatio;
      const rows = d.length;
      const rowParts = d.reduce((parts, ratio) => parts += ratio, 0);
      const rowPadding = treePadding / (rows - 1 || 1);
      const availableHeight = rows > 1 ? availableRange - treePadding : availableRange;
      let previousY = range[0];
      const xRects = d.map((ratio) => {
        const rect = new _Scene19.Rect();
        const height = availableHeight * ratio / rowParts;
        rect.x = previousX;
        rect.y = previousY;
        rect.width = width;
        rect.height = height;
        rect.strokeWidth = 0.75;
        rect.crisp = true;
        previousY += height + rowPadding;
        return rect;
      });
      previousX += width + columnPadding;
      rects.push(...xRects);
      return rects;
    }, []);
    this.updateColors(fills, strokes, themeTemplate, isCustomTheme);
    const rectGroup = new _Scene19.Group();
    rectGroup.setClipRect(new _Scene19.BBox(padding, padding, size - padding, size - padding));
    rectGroup.append(this.rects);
    this.root.append(rectGroup);
  }
  updateColors(fills, strokes, themeTemplate, isCustomTheme) {
    const defaultBackgroundColor = themeTemplate?.get(_Theme5.DEFAULT_BACKGROUND_COLOUR);
    const backgroundFill = (Array.isArray(defaultBackgroundColor) ? defaultBackgroundColor[0] : defaultBackgroundColor) ?? "white";
    this.rects.forEach((rect, i) => {
      rect.fill = fills[i % strokes.length];
      rect.stroke = isCustomTheme ? strokes[i % strokes.length] : backgroundFill;
    });
  }
};
MiniTreemap.chartType = "treemap";
var MiniSunburst = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "sunburstTooltip");
    this.data = [
      [[], []],
      [[], []],
      [[], []]
    ];
    this.angleOffset = -Math.PI / 2;
    this.innerRadiusRatio = 0;
    this.showRadiusAxisLine = false;
    this.showAngleAxisLines = false;
    const { data, size, padding, angleOffset, innerRadiusRatio } = this;
    const radius = (size - padding * 2) / 2;
    const angleRange = [angleOffset + 0, angleOffset + 2 * Math.PI];
    const angleExtent = Math.abs(angleRange[1] - angleRange[0]);
    const radiusRange = [radius * innerRadiusRatio, radius];
    const radiusExtent = Math.abs(radiusRange[1] - radiusRange[0]);
    let maxDepth = 0;
    const findMaxDepth = (data2, parentDepth) => {
      data2.forEach((child) => {
        const depth = parentDepth + 1;
        maxDepth = Math.max(maxDepth, depth);
        findMaxDepth(child, depth);
      });
    };
    findMaxDepth(data, 0);
    const radiusRatio = radiusExtent / maxDepth;
    const center = this.size / 2;
    const startAngle = angleRange[0];
    this.series = [];
    const createSectors = (data2, depth, startAngle2, availableAngle, group) => {
      const isArray = Array.isArray(data2);
      if (!isArray) {
        return;
      }
      const childDepth = depth + 1;
      let previousAngle = startAngle2;
      data2.forEach((child, childIndex, children) => {
        let childGroup = group;
        if (!childGroup) {
          childGroup = new _Scene20.Group();
          this.series.push(childGroup);
        }
        const innerRadius = radiusRange[0] + depth * radiusRatio;
        const outerRadius = radiusRange[0] + childDepth * radiusRatio;
        const angleRatio = 1 / children.length;
        const start = previousAngle;
        const end = start + availableAngle * angleRatio;
        const sector = new _Scene20.Sector();
        sector.centerX = center;
        sector.centerY = center;
        sector.innerRadius = innerRadius;
        sector.outerRadius = outerRadius;
        sector.startAngle = start;
        sector.endAngle = end;
        sector.stroke = void 0;
        sector.strokeWidth = 0;
        sector.inset = 0.75;
        previousAngle = end;
        childGroup.append(sector);
        createSectors(child, childDepth, start, Math.abs(end - start), childGroup);
      });
    };
    createSectors(data, 0, startAngle, angleExtent);
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      for (const sector of group.children()) {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      }
    });
  }
};
MiniSunburst.chartType = "sunburst";
var MiniHeatmap = class extends MiniChart {
  constructor(container, fills, strokes, themeTemplate, isCustomTheme) {
    super(container, "heatmapTooltip");
    const { size, padding } = this;
    const heatmapSize = 3;
    const data = Array.from(
      { length: heatmapSize },
      (_, __) => Array.from({ length: heatmapSize }, (_2, yIndex) => yIndex)
    );
    const domain = data.map((_, index) => index);
    const xScale = new _Scene21.BandScale();
    xScale.domain = domain;
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.01;
    xScale.paddingOuter = 0.1;
    const yScale = new _Scene21.BandScale();
    yScale.domain = domain;
    yScale.range = [padding, size - padding];
    yScale.paddingInner = 0.01;
    yScale.paddingOuter = 0.1;
    const width = xScale.bandwidth ?? 0;
    const height = yScale.bandwidth ?? 0;
    this.rects = data.reduce((rects, d, index) => {
      rects ?? (rects = []);
      const xRects = d.map((_, yIndex) => {
        const rect = new _Scene21.Rect();
        rect.x = xScale.convert(index);
        rect.y = yScale.convert(yIndex);
        rect.width = width;
        rect.height = height;
        rect.strokeWidth = 0;
        rect.crisp = true;
        return rect;
      });
      rects.push(...xRects);
      return rects;
    }, []);
    this.updateColors(fills, strokes, themeTemplate, isCustomTheme);
    const rectGroup = new _Scene21.Group();
    rectGroup.setClipRect(new _Scene21.BBox(padding, padding, size - padding, size - padding));
    rectGroup.append(this.rects);
    this.root.append(rectGroup);
  }
  updateColors(fills, strokes, themeTemplate, isCustomTheme) {
    const defaultColorRange = themeTemplate?.get(_Theme6.DEFAULT_DIVERGING_SERIES_COLOR_RANGE);
    const defaultBackgroundColor = themeTemplate?.get(_Theme6.DEFAULT_BACKGROUND_COLOUR);
    const backgroundFill = (Array.isArray(defaultBackgroundColor) ? defaultBackgroundColor[0] : defaultBackgroundColor) ?? "white";
    const colorRange = isCustomTheme ? [fills[0], fills[1]] : defaultColorRange;
    const stroke = isCustomTheme ? strokes[0] : backgroundFill;
    const fillFn = _Util6.interpolateColor(colorRange[0], colorRange[1]);
    this.rects.forEach((rect, i) => {
      rect.fill = fillFn(i * 0.2);
      rect.stroke = stroke;
    });
  }
};
MiniHeatmap.chartType = "heatmap";
var MiniWaterfall = class extends MiniChartWithAxes {
  constructor(container, fills, strokes, themeTemplate, isCustomTheme) {
    super(container, "waterfallTooltip");
    this.data = [4, 3, -3, 6, -3];
    this.bars = this.createWaterfall(this.root, this.data, this.size, this.padding, "vertical").bars;
    this.updateColors(fills, strokes, themeTemplate, isCustomTheme);
  }
  updateColors(fills, strokes, themeTemplate, isCustomTheme) {
    const { data } = this;
    const positive = {
      fill: isCustomTheme ? fills[0] : themeTemplate?.get(_Theme7.PALETTE_ALT_UP_FILL),
      stroke: isCustomTheme ? strokes[0] : themeTemplate?.get(_Theme7.PALETTE_ALT_UP_STROKE)
    };
    const negative = {
      fill: isCustomTheme ? fills[1] : themeTemplate?.get(_Theme7.PALETTE_ALT_DOWN_FILL),
      stroke: isCustomTheme ? strokes[1] : themeTemplate?.get(_Theme7.PALETTE_ALT_DOWN_STROKE)
    };
    this.bars.forEach((bar, i) => {
      const isPositive = data[i] >= 0;
      bar.fill = isPositive ? positive.fill : negative.fill;
      bar.stroke = isPositive ? positive.stroke : negative.stroke;
    });
  }
  createWaterfall(root, data, size, padding, direction) {
    const scalePadding = 2 * padding;
    const { processedData, min, max } = accumulateData(data.map((d) => [d]));
    const flatData = processedData.reduce((flat, d) => flat.concat(d), []);
    const yScale = new _Scene22.LinearScale();
    yScale.domain = [Math.min(min, 0), max];
    yScale.range = [size - scalePadding, scalePadding];
    const xScale = new _Scene22.BandScale();
    xScale.domain = data.map((_, index) => index);
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.2;
    xScale.paddingOuter = 0.3;
    const width = xScale.bandwidth;
    const connectorLine = new _Scene22.Path();
    connectorLine.stroke = "#575757";
    connectorLine.strokeWidth = 0;
    const pixelAlignmentOffset = Math.floor(connectorLine.strokeWidth) % 2 / 2;
    const connectorPath = connectorLine.path;
    connectorPath.clear();
    const barAlongX = direction === "horizontal";
    const bars = flatData.map((datum, i) => {
      const previousDatum = i > 0 ? flatData[i - 1] : 0;
      const rawValue = data[i];
      const isPositive = rawValue > 0;
      const currY = Math.round(yScale.convert(datum));
      const trailY = Math.round(yScale.convert(previousDatum));
      const y = (isPositive ? currY : trailY) - pixelAlignmentOffset;
      const bottomY = (isPositive ? trailY : currY) + pixelAlignmentOffset;
      const height = Math.abs(bottomY - y);
      const x = xScale.convert(i);
      const rect = new _Scene22.Rect();
      rect.x = barAlongX ? y : x;
      rect.y = barAlongX ? x : y;
      rect.width = barAlongX ? height : width;
      rect.height = barAlongX ? width : height;
      rect.strokeWidth = 0;
      rect.crisp = true;
      const moveTo = currY + pixelAlignmentOffset;
      const lineTo = trailY + pixelAlignmentOffset;
      if (i > 0) {
        const lineToX = barAlongX ? lineTo : rect.x;
        const lineToY = barAlongX ? rect.y : lineTo;
        connectorPath.lineTo(lineToX, lineToY);
      }
      const moveToX = barAlongX ? moveTo : rect.x;
      const moveToY = barAlongX ? rect.y : moveTo;
      connectorPath.moveTo(moveToX, moveToY);
      return rect;
    });
    root.append([connectorLine, ...bars]);
    return { bars };
  }
};
MiniWaterfall.chartType = "waterfall";
var MiniColumnLineCombo = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "columnLineComboTooltip");
    this.columnData = [3, 4];
    this.lineData = [[5, 4, 6, 5, 4]];
    const { root, columnData, lineData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1],
      yScaleDomain: [0, 4],
      xScalePadding: 0.5
    });
    root.append(this.columns);
    this.lines = createLinePaths(root, lineData, size, padding);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.columns.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
    this.lines.forEach((line, i) => {
      line.stroke = fills[i + 2];
    });
  }
};
MiniColumnLineCombo.chartType = "columnLineCombo";
var MiniAreaColumnCombo = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "areaColumnComboTooltip");
    this.columnData = [3, 4.5];
    this.areaData = [[5, 4, 6, 5, 4]];
    const { root, columnData, areaData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1],
      yScaleDomain: [0, 6],
      xScalePadding: 0.5
    });
    const xScale = new _Scene23.BandScale();
    xScale.range = [padding, size - padding];
    xScale.domain = [0, 1, 2, 3, 4];
    xScale.paddingInner = 1;
    xScale.paddingOuter = 0;
    const yScale = new _Scene23.LinearScale();
    yScale.range = [size - padding, padding];
    yScale.domain = [0, 6];
    const pathData = [];
    const yZero = yScale.convert(0);
    const firstX = xScale.convert(0);
    areaData.forEach((series, i) => {
      const points = pathData[i] || (pathData[i] = []);
      series.forEach((data, j) => {
        const yDatum = data;
        const xDatum = j;
        const x = xScale.convert(xDatum);
        const y = yScale.convert(yDatum);
        points[j] = { x, y };
      });
      const lastX = xScale.convert(series.length - 1);
      pathData[i].push(
        {
          x: lastX,
          y: yZero
        },
        {
          x: firstX,
          y: yZero
        }
      );
    });
    this.areas = pathData.map((points) => {
      const area = new _Scene23.Path();
      area.strokeWidth = 0;
      area.fillOpacity = 0.8;
      const path = area.path;
      points.forEach((point, i) => path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y));
      return area;
    });
    root.append(this.areas);
    root.append([].concat.apply([], this.columns));
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
    this.columns.forEach((bar, i) => {
      bar.fill = fills[i + 1];
      bar.stroke = strokes[i + 1];
    });
  }
};
MiniAreaColumnCombo.chartType = "areaColumnCombo";
var MiniCustomCombo = class extends MiniChart {
  constructor(container, fills, strokes) {
    super(container, "customComboTooltip");
    this.columnData = [3, 4];
    this.lineData = [[5, 4, 6, 5, 4]];
    const { root, columnData, lineData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1],
      yScaleDomain: [0, 4],
      xScalePadding: 0.5
    });
    root.append(this.columns);
    this.lines = createLinePaths(root, lineData, size, padding);
    const axisStroke = "grey";
    const axisOvershoot = 3;
    const leftAxis = new _Scene24.Line();
    leftAxis.x1 = padding;
    leftAxis.y1 = padding;
    leftAxis.x2 = padding;
    leftAxis.y2 = size - padding + axisOvershoot;
    leftAxis.stroke = axisStroke;
    const bottomAxis = new _Scene24.Line();
    bottomAxis.x1 = padding - axisOvershoot + 1;
    bottomAxis.y1 = size - padding;
    bottomAxis.x2 = size - padding + 1;
    bottomAxis.y2 = size - padding;
    bottomAxis.stroke = axisStroke;
    const penIcon = new _Scene24.Path();
    this.buildPenIconPath(penIcon);
    penIcon.fill = "whitesmoke";
    penIcon.stroke = "darkslategrey";
    penIcon.strokeWidth = 1;
    root.append([bottomAxis, leftAxis, penIcon]);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.columns.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
    this.lines.forEach((line, i) => {
      line.stroke = fills[i + 2];
    });
  }
  buildPenIconPath(penIcon) {
    const { path } = penIcon;
    path.moveTo(25.76, 43.46);
    path.lineTo(31.27, 48.53);
    path.moveTo(49.86, 22);
    path.lineTo(49.86, 22);
    path.cubicCurveTo(49.01994659053345, 21.317514933510974, 47.89593834348529, 21.09645997825817, 46.86, 21.41);
    path.lineTo(46.86, 21.41);
    path.cubicCurveTo(45.55460035985361, 21.77260167850787, 44.38777081121966, 22.517979360321792, 43.51, 23.55);
    path.lineTo(25.51, 43.8);
    path.lineTo(25.43, 43.89);
    path.lineTo(23.01, 51.89);
    path.lineTo(22.83, 52.46);
    path.lineTo(31.02, 48.86);
    path.lineTo(49.02, 28.52);
    path.lineTo(49.02, 28.52);
    path.cubicCurveTo(49.940716461596224, 27.521914221246085, 50.54302631059587, 26.2720342455763, 50.75, 24.93);
    path.lineTo(50.75, 24.93);
    path.cubicCurveTo(50.95363374988308, 23.866379846512814, 50.62080640232334, 22.77066734274871, 49.86, 22);
    path.closePath();
    path.moveTo(41.76, 25.5);
    path.lineTo(47.34, 30.5);
    path.moveTo(40.74, 26.65);
    path.lineTo(46.25, 31.71);
  }
};
MiniCustomCombo.chartType = "customCombo";
var miniChartMapping = {
  columnGroup: {
    column: { range: true, pivot: true, enterprise: false, icon: MiniColumn },
    stackedColumn: { range: true, pivot: true, enterprise: false, icon: MiniStackedColumn },
    normalizedColumn: { range: true, pivot: true, enterprise: false, icon: MiniNormalizedColumn }
  },
  barGroup: {
    bar: { range: true, pivot: true, enterprise: false, icon: MiniBar },
    stackedBar: { range: true, pivot: true, enterprise: false, icon: MiniStackedBar },
    normalizedBar: { range: true, pivot: true, enterprise: false, icon: MiniNormalizedBar }
  },
  pieGroup: {
    pie: { range: true, pivot: true, enterprise: false, icon: MiniPie },
    donut: { range: true, pivot: true, enterprise: false, icon: MiniDonut },
    doughnut: { range: true, pivot: true, enterprise: false, icon: MiniDonut }
  },
  lineGroup: { line: { range: true, pivot: true, enterprise: false, icon: MiniLine } },
  scatterGroup: {
    scatter: { range: true, pivot: true, enterprise: false, icon: MiniScatter },
    bubble: { range: true, pivot: true, enterprise: false, icon: MiniBubble }
  },
  areaGroup: {
    area: { range: true, pivot: true, enterprise: false, icon: MiniArea },
    stackedArea: { range: true, pivot: true, enterprise: false, icon: MiniStackedArea },
    normalizedArea: { range: true, pivot: true, enterprise: false, icon: MiniNormalizedArea }
  },
  polarGroup: {
    radarLine: { range: true, pivot: false, enterprise: true, icon: MiniRadarLine },
    radarArea: { range: true, pivot: false, enterprise: true, icon: MiniRadarArea },
    nightingale: { range: true, pivot: false, enterprise: true, icon: MiniNightingale },
    radialColumn: { range: true, pivot: false, enterprise: true, icon: MiniRadialColumn },
    radialBar: { range: true, pivot: false, enterprise: true, icon: MiniRadialBar }
  },
  statisticalGroup: {
    boxPlot: { range: true, pivot: false, enterprise: true, icon: MiniBoxPlot },
    histogram: { range: true, pivot: false, enterprise: false, icon: MiniHistogram },
    rangeBar: { range: true, pivot: false, enterprise: true, icon: MiniRangeBar },
    rangeArea: { range: true, pivot: false, enterprise: true, icon: MiniRangeArea }
  },
  hierarchicalGroup: {
    treemap: { range: true, pivot: true, enterprise: true, icon: MiniTreemap },
    sunburst: { range: true, pivot: true, enterprise: true, icon: MiniSunburst }
  },
  specializedGroup: {
    heatmap: { range: true, pivot: false, enterprise: true, icon: MiniHeatmap },
    waterfall: { range: true, pivot: false, enterprise: true, icon: MiniWaterfall }
  },
  combinationGroup: {
    columnLineCombo: { range: true, pivot: true, enterprise: false, icon: MiniColumnLineCombo },
    areaColumnCombo: { range: true, pivot: true, enterprise: false, icon: MiniAreaColumnCombo },
    customCombo: { range: true, pivot: true, enterprise: false, icon: MiniCustomCombo }
  }
};
var DEFAULT_CHART_GROUPS = {
  columnGroup: ["column", "stackedColumn", "normalizedColumn"],
  barGroup: ["bar", "stackedBar", "normalizedBar"],
  pieGroup: ["pie", "donut"],
  lineGroup: ["line"],
  scatterGroup: ["scatter", "bubble"],
  areaGroup: ["area", "stackedArea", "normalizedArea"],
  polarGroup: ["radarLine", "radarArea", "nightingale", "radialColumn", "radialBar"],
  statisticalGroup: ["boxPlot", "histogram", "rangeBar", "rangeArea"],
  hierarchicalGroup: ["treemap", "sunburst"],
  specializedGroup: ["heatmap", "waterfall"],
  combinationGroup: ["columnLineCombo", "areaColumnCombo", "customCombo"]
};
var MiniChartsContainer = class extends Component36 {
  constructor(chartController, fills, strokes, themeTemplateParameters, isCustomTheme, chartGroups = DEFAULT_CHART_GROUPS) {
    super(
      /* html */
      `<div class="ag-chart-settings-mini-wrapper"></div>`
    );
    this.wrappers = /* @__PURE__ */ new Map();
    this.chartController = chartController;
    this.fills = fills;
    this.strokes = strokes;
    this.themeTemplateParameters = themeTemplateParameters;
    this.isCustomTheme = isCustomTheme;
    this.chartGroups = { ...chartGroups };
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    if (!this.chartController.customComboExists() && this.chartGroups.combinationGroup) {
      this.chartGroups.combinationGroup = this.chartGroups.combinationGroup.filter(
        (chartType) => chartType !== "customCombo"
      );
    }
    const eGui = this.getGui();
    const isEnterprise = this.chartController.isEnterprise();
    const isPivotChart = this.chartController.isPivotChart();
    const isRangeChart = !isPivotChart;
    const displayedMenuGroups = Object.keys(this.chartGroups).map((group) => {
      const menuGroup = group in miniChartMapping ? miniChartMapping[group] : void 0;
      if (!menuGroup) {
        _warnOnce11(`invalid chartGroupsDef config '${group}'`);
        return null;
      }
      const chartGroupValues = this.chartGroups[group] ?? [];
      const menuItems = chartGroupValues.map((chartType) => {
        const menuItem = chartType in menuGroup ? menuGroup[chartType] : void 0;
        if (!menuItem) {
          _warnOnce11(`invalid chartGroupsDef config '${group}.${chartType}'`);
          return null;
        }
        if (!isEnterprise && menuItem.enterprise) {
          return null;
        }
        if (isRangeChart && menuItem.range)
          return menuItem;
        if (isPivotChart && menuItem.pivot)
          return menuItem;
        return null;
      }).filter((menuItem) => menuItem != null);
      if (menuItems.length === 0)
        return null;
      return {
        label: this.chartTranslationService.translate(group),
        items: menuItems
      };
    }).filter((menuGroup) => menuGroup != null);
    for (const { label, items } of displayedMenuGroups) {
      const groupComponent = this.createBean(
        new AgGroupComponent({
          title: label,
          suppressEnabledCheckbox: true,
          enabled: true,
          suppressOpenCloseIcons: true,
          cssIdentifier: "charts-settings",
          direction: "horizontal",
          suppressKeyboardNavigation: true
        })
      );
      for (const menuItem of items) {
        const MiniClass = menuItem.icon;
        const miniWrapper = document.createElement("div");
        miniWrapper.classList.add("ag-chart-mini-thumbnail");
        miniWrapper.setAttribute("tabindex", "0");
        miniWrapper.setAttribute("role", "button");
        const miniClassChartType = MiniClass.chartType;
        const listener = () => {
          this.chartController.setChartType(miniClassChartType);
          this.updateSelectedMiniChart();
        };
        this.addManagedListeners(miniWrapper, {
          click: listener,
          keydown: (event) => {
            if (event.key == KeyCode23.ENTER || event.key === KeyCode23.SPACE) {
              event.preventDefault();
              listener();
            }
          }
        });
        this.wrappers.set(miniClassChartType, miniWrapper);
        this.createBean(
          new MiniClass(
            miniWrapper,
            this.fills,
            this.strokes,
            this.themeTemplateParameters,
            this.isCustomTheme
          )
        );
        groupComponent.addItem(miniWrapper);
      }
      eGui.appendChild(groupComponent.getGui());
    }
    this.updateSelectedMiniChart();
  }
  updateSelectedMiniChart() {
    const selectedChartType = this.chartController.getChartType();
    this.wrappers.forEach((miniChart, miniChartType) => {
      const selected = miniChartType === selectedChartType;
      miniChart.classList.toggle("ag-selected", selected);
      const chartName = this.chartTranslationService.translate(getFullChartNameTranslationKey(miniChartType));
      const ariaLabel = selected ? `${chartName}. ${this.chartTranslationService.translate("ariaChartSelected")}` : chartName;
      _setAriaLabel7(miniChart, ariaLabel);
    });
  }
  destroy() {
    this.wrappers.clear();
    super.destroy();
  }
};
var ChartSettingsPanel = class extends Component37 {
  constructor(chartController) {
    super(
      /* html */
      `<div class="ag-chart-settings-wrapper">
            <div data-ref="eMiniChartsContainer" class="ag-chart-settings-mini-charts-container ag-scrollable-container"></div>
            <div data-ref="eNavBar" class="ag-chart-settings-nav-bar">
                <div data-ref="ePrevBtn" class="ag-chart-settings-prev">
                    <button type="button" class="ag-button ag-chart-settings-prev-button"></button>
                </div>
                <div data-ref="eCardSelector" class="ag-chart-settings-card-selector"></div>
                <div data-ref="eNextBtn" class="ag-chart-settings-next">
                    <button type="button" class="ag-button ag-chart-settings-next-button"></button>
                </div>
            </div>
        </div>`
    );
    this.chartController = chartController;
    this.eMiniChartsContainer = RefPlaceholder18;
    this.eNavBar = RefPlaceholder18;
    this.eCardSelector = RefPlaceholder18;
    this.ePrevBtn = RefPlaceholder18;
    this.eNextBtn = RefPlaceholder18;
    this.miniChartsContainers = [];
    this.cardItems = [];
    this.activePaletteIndex = 0;
    this.palettes = [];
    this.themes = [];
  }
  postConstruct() {
    this.resetPalettes();
    this.ePrevBtn.insertAdjacentElement("afterbegin", _createIconNoSpan32("previous", this.gos));
    this.eNextBtn.insertAdjacentElement("afterbegin", _createIconNoSpan32("next", this.gos));
    this.addManagedElementListeners(this.ePrevBtn, { click: () => this.setActivePalette(this.getPrev(), "left") });
    this.addManagedElementListeners(this.eNextBtn, { click: () => this.setActivePalette(this.getNext(), "right") });
    const reset = () => this.resetPalettes(true);
    this.addManagedListeners(this.chartController, {
      chartTypeChanged: reset,
      chartApiUpdate: reset
    });
    this.scrollSelectedIntoView();
  }
  scrollSelectedIntoView() {
    setTimeout(() => {
      const isMiniChartsContainerVisible = (miniChartsContainers) => {
        return !miniChartsContainers.getGui().classList.contains("ag-hidden");
      };
      const currentMiniChartContainer = this.miniChartsContainers.find(isMiniChartsContainerVisible);
      const currentChart = currentMiniChartContainer.getGui().querySelector(".ag-selected");
      if (currentChart) {
        const parent = currentChart.offsetParent;
        if (parent) {
          this.eMiniChartsContainer.scrollTo(0, parent.offsetTop);
        }
      }
    }, 250);
  }
  resetPalettes(forceReset) {
    const palettes = this.chartController.getPalettes();
    const themeTemplateParameters = this.chartController.getThemeTemplateParameters();
    const chartGroups = this.gos.get("chartToolPanelsDef")?.settingsPanel?.chartGroupsDef;
    if (_shallowCompare2(palettes, this.palettes) && !forceReset || this.isAnimating) {
      return;
    }
    this.palettes = palettes;
    this.themes = this.chartController.getThemeNames();
    this.activePaletteIndex = this.themes.findIndex((name) => name === this.chartController.getChartThemeName());
    this.cardItems = [];
    _clearElement32(this.eCardSelector);
    this.destroyMiniCharts();
    const { themes } = this;
    this.palettes.forEach((palette, index) => {
      const isActivePalette = this.activePaletteIndex === index;
      const { fills = [], strokes = [] } = palette;
      const themeName = themes[index];
      const isCustomTheme = !isStockTheme(themeName);
      const miniChartsContainer = this.createBean(
        new MiniChartsContainer(
          this.chartController,
          fills,
          strokes,
          themeTemplateParameters[index],
          isCustomTheme,
          chartGroups
        )
      );
      this.miniChartsContainers.push(miniChartsContainer);
      this.eMiniChartsContainer.appendChild(miniChartsContainer.getGui());
      this.addCardLink(index);
      if (isActivePalette) {
        miniChartsContainer.updateSelectedMiniChart();
      } else {
        miniChartsContainer.setDisplayed(false);
      }
    });
    _setDisplayed43(this.eNavBar, this.palettes.length > 1);
    _radioCssClass2(this.cardItems[this.activePaletteIndex], "ag-selected", "ag-not-selected");
  }
  addCardLink(index) {
    const link = document.createElement("div");
    link.classList.add("ag-chart-settings-card-item");
    this.addManagedElementListeners(link, {
      click: () => {
        this.setActivePalette(index, index < this.activePaletteIndex ? "left" : "right");
      }
    });
    this.eCardSelector.appendChild(link);
    this.cardItems.push(link);
  }
  getPrev() {
    let prev = this.activePaletteIndex - 1;
    if (prev < 0) {
      prev = this.palettes.length - 1;
    }
    return prev;
  }
  getNext() {
    let next = this.activePaletteIndex + 1;
    if (next >= this.palettes.length) {
      next = 0;
    }
    return next;
  }
  setActivePalette(index, animationDirection) {
    if (this.isAnimating || this.activePaletteIndex === index) {
      return;
    }
    _radioCssClass2(this.cardItems[index], "ag-selected", "ag-not-selected");
    const currentPalette = this.miniChartsContainers[this.activePaletteIndex];
    const currentGui = currentPalette.getGui();
    const futurePalette = this.miniChartsContainers[index];
    const nextGui = futurePalette.getGui();
    currentPalette.updateSelectedMiniChart();
    futurePalette.updateSelectedMiniChart();
    const multiplier = animationDirection === "left" ? -1 : 1;
    const final = nextGui.style.left = `${_getAbsoluteWidth3(this.getGui()) * multiplier}px`;
    this.activePaletteIndex = index;
    this.isAnimating = true;
    const animatingClass = "ag-animating";
    futurePalette.setDisplayed(true);
    currentPalette.addCssClass(animatingClass);
    futurePalette.addCssClass(animatingClass);
    this.chartController.setChartThemeName(this.themes[index]);
    window.setTimeout(() => {
      currentGui.style.left = `${-parseFloat(final)}px`;
      nextGui.style.left = "0px";
    }, 0);
    window.setTimeout(() => {
      this.isAnimating = false;
      currentPalette.removeCssClass(animatingClass);
      futurePalette.removeCssClass(animatingClass);
      currentPalette.setDisplayed(false);
    }, 300);
  }
  destroyMiniCharts() {
    _clearElement32(this.eMiniChartsContainer);
    this.miniChartsContainers = this.destroyBeans(this.miniChartsContainers);
  }
  destroy() {
    this.destroyMiniCharts();
    super.destroy();
  }
};
var _TabbedChartMenu = class _TabbedChartMenu2 extends Component38 {
  constructor(panels, chartMenuContext) {
    super();
    this.panels = panels;
    this.chartMenuContext = chartMenuContext;
    this.tabs = [];
  }
  wireBeans(beans) {
    this.chartTranslationService = beans.chartTranslationService;
  }
  postConstruct() {
    this.panels.forEach((panel) => {
      const panelType = panel.replace("chart", "").toLowerCase();
      const panelComp = this.createPanel(panelType);
      const tabItem = this.createTab(panel, panelType, panelComp);
      this.tabs.push(tabItem);
      this.addDestroyFunc(() => this.destroyBean(panelComp));
    });
    this.tabbedLayout = new TabbedLayout({
      items: this.tabs,
      cssClass: "ag-chart-tabbed-menu",
      keepScrollPosition: true,
      suppressFocusBodyOnOpen: true,
      suppressTrapFocus: true,
      enableCloseButton: true,
      closeButtonAriaLabel: this.chartTranslationService.translate("ariaChartMenuClose"),
      onCloseClicked: () => {
        this.eventSource?.focus({ preventScroll: true });
        this.dispatchLocalEvent({ type: "closed" });
      }
    });
    this.createBean(this.tabbedLayout);
  }
  createTab(name, title, panelComp) {
    const eWrapperDiv = document.createElement("div");
    eWrapperDiv.classList.add("ag-chart-tab", `ag-chart-${title}`);
    this.createBean(panelComp);
    eWrapperDiv.appendChild(panelComp.getGui());
    const titleEl = document.createElement("div");
    const translatedTitle = this.chartTranslationService.translate(title);
    titleEl.innerText = translatedTitle;
    return {
      title: titleEl,
      titleLabel: translatedTitle,
      bodyPromise: AgPromise3.resolve(eWrapperDiv),
      getScrollableContainer: () => {
        const scrollableContainer = eWrapperDiv.querySelector(".ag-scrollable-container");
        return scrollableContainer || eWrapperDiv;
      },
      name
    };
  }
  showTab(tab) {
    const tabItem = this.tabs[tab];
    this.tabbedLayout.showItem(tabItem);
  }
  getGui() {
    return this.tabbedLayout && this.tabbedLayout.getGui();
  }
  showMenu(eventSource, suppressFocus) {
    this.eventSource = eventSource;
    if (!suppressFocus) {
      this.tabbedLayout?.focusHeader(true);
    }
  }
  destroy() {
    if (this.parentComponent && this.parentComponent.isAlive()) {
      this.destroyBean(this.parentComponent);
    }
    super.destroy();
  }
  createPanel(panelType) {
    switch (panelType) {
      case _TabbedChartMenu2.TAB_DATA:
        return new ChartDataPanel(this.chartMenuContext);
      case _TabbedChartMenu2.TAB_FORMAT:
        return new FormatPanel(this.chartMenuContext);
      default:
        return new ChartSettingsPanel(this.chartMenuContext.chartController);
    }
  }
};
_TabbedChartMenu.TAB_DATA = "data";
_TabbedChartMenu.TAB_FORMAT = "format";
var TabbedChartMenu = _TabbedChartMenu;
var ChartMenu = class extends Component39 {
  constructor(eChartContainer, eMenuPanelContainer, chartMenuContext) {
    super(
      /* html */
      `<div class="ag-chart-menu-wrapper"></div>`
    );
    this.eChartContainer = eChartContainer;
    this.eMenuPanelContainer = eMenuPanelContainer;
    this.chartMenuContext = chartMenuContext;
    this.buttons = {
      chartLink: { iconName: "linked", callback: () => this.chartMenuService.toggleLinked(this.chartMenuContext) },
      chartUnlink: {
        iconName: "unlinked",
        callback: () => this.chartMenuService.toggleLinked(this.chartMenuContext)
      },
      chartDownload: { iconName: "save", callback: () => this.chartMenuService.downloadChart(this.chartMenuContext) },
      chartMenu: { iconName: "menuAlt", callback: (eventSource) => this.showMenuList(eventSource) }
    };
    this.panels = [];
    this.menuVisible = false;
    this.chartController = chartMenuContext.chartController;
  }
  wireBeans(beans) {
    this.chartMenuService = beans.chartMenuService;
    this.chartMenuListFactory = beans.chartMenuListFactory;
    this.environment = beans.environment;
  }
  postConstruct() {
    this.chartToolbar = this.createManagedBean(new ChartToolbar());
    this.getGui().appendChild(this.chartToolbar.getGui());
    this.refreshToolbarAndPanels();
    this.addManagedEventListeners({
      chartCreated: (e) => {
        if (e.chartId === this.chartController.getChartId()) {
          const showDefaultToolPanel = Boolean(this.gos.get("chartToolPanelsDef")?.defaultToolPanel);
          if (showDefaultToolPanel) {
            this.showMenu({ panel: this.defaultPanel, suppressFocus: true });
          }
        }
      }
    });
    this.addManagedListeners(this.chartController, {
      chartLinkedChanged: this.refreshToolbarAndPanels.bind(this)
    });
    this.refreshMenuClasses();
    this.addManagedListeners(this.chartController, { chartApiUpdate: this.refreshToolbarAndPanels.bind(this) });
  }
  isVisible() {
    return this.menuVisible;
  }
  getExtraPaddingDirections() {
    return ["chartMenu", "chartLink", "chartUnlink", "chartDownload"].some(
      (v) => this.chartToolbarOptions.includes(v)
    ) ? ["top"] : [];
  }
  refreshToolbarAndPanels() {
    this.initToolbarOptionsAndPanels();
    this.updateToolbar();
  }
  initToolbarOptionsAndPanels() {
    const { panels, defaultPanel } = this.chartMenuService.getChartToolPanels(this.chartController);
    this.panels = panels;
    this.defaultPanel = defaultPanel;
    this.chartToolbarOptions = this.chartMenuService.getChartToolbarOptions();
  }
  updateToolbar() {
    const buttons = this.chartToolbarOptions.map((buttonName) => {
      const { iconName, callback } = this.buttons[buttonName];
      return {
        buttonName,
        iconName,
        callback
      };
    });
    this.chartToolbar.updateParams({ buttons });
  }
  createMenuPanel(defaultTab) {
    const menuPanel = this.menuPanel = this.createBean(
      new AgPanel({
        height: "100%",
        closable: true,
        hideTitleBar: true,
        cssIdentifier: "chart-menu"
      })
    );
    menuPanel.setParentComponent(this);
    this.eMenuPanelContainer.appendChild(menuPanel.getGui());
    this.tabbedMenu = this.createBean(new TabbedChartMenu(this.panels, this.chartMenuContext));
    this.addManagedListeners(this.tabbedMenu, {
      closed: () => {
        this.hideMenu();
      }
    });
    this.addManagedListeners(menuPanel, { destroyed: () => this.destroyBean(this.tabbedMenu) });
    return new AgPromise2((res) => {
      window.setTimeout(() => {
        menuPanel.setBodyComponent(this.tabbedMenu);
        this.tabbedMenu.showTab(defaultTab);
        res(menuPanel);
      }, 100);
    });
  }
  showContainer(eventSource, suppressFocus) {
    if (!this.menuPanel) {
      return;
    }
    this.menuVisible = true;
    this.refreshMenuClasses();
    this.tabbedMenu.showMenu(eventSource, suppressFocus);
  }
  showMenu(params) {
    const { panel, eventSource, suppressFocus } = params ?? {};
    if (this.menuPanel && !panel) {
      this.showContainer(eventSource, suppressFocus);
    } else {
      const menuPanel = panel || this.defaultPanel;
      let tab = this.panels.indexOf(menuPanel);
      if (tab < 0) {
        _warnOnce12(`'${panel}' is not a valid Chart Tool Panel name`);
        tab = this.panels.indexOf(this.defaultPanel);
      }
      if (this.menuPanel) {
        this.tabbedMenu.showTab(tab);
        this.showContainer(eventSource, suppressFocus);
      } else {
        this.createMenuPanel(tab).then(() => this.showContainer(eventSource, suppressFocus));
      }
    }
  }
  hideMenu() {
    this.menuVisible = false;
    this.refreshMenuClasses();
  }
  refreshMenuClasses() {
    this.eChartContainer.classList.toggle("ag-chart-menu-visible", this.menuVisible);
    this.eChartContainer.classList.toggle("ag-chart-menu-hidden", !this.menuVisible);
  }
  showMenuList(eventSource) {
    this.chartMenuListFactory.showMenuList({
      eventSource,
      showMenu: () => this.showMenu({ eventSource }),
      chartMenuContext: this.chartMenuContext
    });
  }
  destroy() {
    super.destroy();
    if (this.menuPanel && this.menuPanel.isAlive()) {
      this.destroyBean(this.menuPanel);
    }
    if (this.tabbedMenu && this.tabbedMenu.isAlive()) {
      this.destroyBean(this.tabbedMenu);
    }
  }
};
var CARTESIAN_AXIS_TYPES = ["number", "category", "time", "grouped-category"];
var POLAR_AXIS_TYPES = ["angle-category", "angle-number", "radius-category", "radius-number"];
var VALID_AXIS_TYPES = [...CARTESIAN_AXIS_TYPES, ...POLAR_AXIS_TYPES];
var ChartOptionsService = class extends BeanStub14 {
  constructor(chartController) {
    super();
    this.chartController = chartController;
  }
  getChartThemeOverridesProxy() {
    return {
      getValue: (expression) => this.getChartOption(expression),
      setValue: (expression, value) => this.setChartThemeOverrides([{ expression, value }]),
      setValues: (properties) => this.setChartThemeOverrides(properties)
    };
  }
  getAxisThemeOverridesProxy() {
    return {
      getValue: (expression) => this.getAxisProperty(expression),
      setValue: (expression, value) => this.setAxisThemeOverrides([{ expression, value }]),
      setValues: (properties) => this.setAxisThemeOverrides(properties)
    };
  }
  getCartesianAxisOptionsProxy(axisType) {
    return {
      getValue: (expression) => this.getCartesianAxisProperty(axisType, expression),
      setValue: (expression, value) => this.setCartesianAxisOptions(axisType, [{ expression, value }]),
      setValues: (properties) => this.setCartesianAxisOptions(axisType, properties)
    };
  }
  getCartesianAxisThemeOverridesProxy(axisType) {
    return {
      getValue: (expression) => this.getCartesianAxisProperty(axisType, expression),
      setValue: (expression, value) => this.setCartesianAxisThemeOverrides(axisType, [{ expression, value }]),
      setValues: (properties) => this.setCartesianAxisThemeOverrides(axisType, properties)
    };
  }
  getCartesianAxisAppliedThemeOverridesProxy(axisType) {
    return {
      getValue: (expression) => this.getCartesianAxisThemeOverride(
        axisType,
        // Allow the caller to specify a wildcard expression to retrieve the whole set of overrides
        expression === "*" ? null : expression
      ),
      setValue: (expression, value) => this.setCartesianAxisThemeOverrides(
        axisType,
        // Allow the caller to specify a wildcard expression to set the whole set of overrides
        [{ expression: expression === "*" ? null : expression, value }]
      ),
      setValues: (properties) => this.setCartesianAxisThemeOverrides(axisType, properties)
    };
  }
  getSeriesOptionsProxy(getSelectedSeries) {
    return {
      getValue: (expression, calculated) => this.getSeriesOption(getSelectedSeries(), expression, calculated),
      setValue: (expression, value) => this.setSeriesOptions(getSelectedSeries(), [{ expression, value }]),
      setValues: (properties) => this.setSeriesOptions(getSelectedSeries(), properties)
    };
  }
  /**
   * Determine the set of theme overrides that should be retained when transitioning from one chart type to another.
   */
  getPersistedChartThemeOverrides(existingChartOptions, existingAxes, existingChartType, targetChartType) {
    const retainedThemeOverrideKeys = this.getRetainedChartThemeOverrideKeys(existingChartType, targetChartType);
    const retainedChartAxisThemeOverrideKeys = this.getRetainedChartAxisThemeOverrideKeys(
      null,
      existingChartType,
      targetChartType
    );
    const targetChartOptions = this.createChartOptions();
    for (const expression of retainedThemeOverrideKeys) {
      const value = this.retrieveChartOptionsThemeOverride(existingChartOptions, existingChartType, expression);
      if (value !== void 0) {
        this.assignChartOptionsThemeOverride(targetChartOptions, targetChartType, expression, value);
      }
    }
    if (existingAxes) {
      this.assignPersistedAxisOverrides({
        existingAxes,
        retainedChartAxisThemeOverrideKeys,
        existingChartOptions,
        targetChartOptions,
        existingChartType,
        targetChartType
      });
    }
    return targetChartOptions.theme.overrides;
  }
  assignPersistedAxisOverrides(params) {
    const {
      existingAxes,
      retainedChartAxisThemeOverrideKeys,
      existingChartOptions,
      targetChartOptions,
      existingChartType,
      targetChartType
    } = params;
    for (const { expression, targetAxisTypes } of retainedChartAxisThemeOverrideKeys) {
      for (const existingAxisType of existingAxes.map((axis) => axis.type)) {
        const value = this.retrieveChartOptionsThemeOverride(
          existingChartOptions,
          existingChartType,
          ["axes", existingAxisType, expression].join(".")
        );
        if (value !== void 0) {
          for (const targetAxisType of targetAxisTypes) {
            this.assignChartOptionsThemeOverride(
              targetChartOptions,
              targetChartType,
              ["axes", targetAxisType, expression].join("."),
              value
            );
          }
        }
      }
    }
  }
  getRetainedChartThemeOverrideKeys(existingChartType, targetChartType) {
    const UNIVERSAL_PERSISTED_THEME_OVERRIDES = ["animation"];
    const PERSISTED_CARTESIAN_CHART_THEME_OVERRIDES = ["zoom", "navigator"];
    const chartSpecificThemeOverrideKeys = ((previousChartType, updatedChartType) => {
      const expressions = new Array();
      if (isCartesian(getSeriesType(previousChartType)) && isCartesian(getSeriesType(updatedChartType))) {
        expressions.push(...PERSISTED_CARTESIAN_CHART_THEME_OVERRIDES);
      }
      return expressions;
    })(existingChartType, targetChartType);
    return [...UNIVERSAL_PERSISTED_THEME_OVERRIDES, ...chartSpecificThemeOverrideKeys];
  }
  getRetainedChartAxisThemeOverrideKeys(axisType, existingChartType, targetChartType) {
    if (isCartesian(getSeriesType(existingChartType)) && isCartesian(getSeriesType(targetChartType))) {
      const retainedKeys = this.getRetainedCartesianAxisThemeOverrideKeys(axisType);
      return retainedKeys.map((expression) => ({ expression, targetAxisTypes: CARTESIAN_AXIS_TYPES }));
    }
    return [];
  }
  getRetainedCartesianAxisThemeOverrideKeys(axisType) {
    const axisPositionSuffixes = axisType === "xAxis" ? ["", ".top", ".bottom"] : axisType === "yAxis" ? ["", ".left", ".right"] : ["", ".left", ".right", ".top", ".bottom"];
    const PERSISTED_CARTESIAN_AXIS_THEME_OVERRIDES = ["crosshair"];
    const expressions = new Array();
    for (const expression of PERSISTED_CARTESIAN_AXIS_THEME_OVERRIDES) {
      for (const axisPositionSuffix of axisPositionSuffixes) {
        expressions.push(`${expression}${axisPositionSuffix}`);
      }
    }
    return expressions;
  }
  getChartOption(expression) {
    return get(this.getChart(), expression, void 0);
  }
  setChartThemeOverrides(properties) {
    const chartType = this.getChartType();
    const chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      this.assignChartOptionsThemeOverride(chartOptions, chartType, expression, value);
    }
    this.applyChartOptions(chartOptions);
  }
  applyChartOptions(chartOptions, options) {
    if (Object.keys(chartOptions).length === 0)
      return;
    this.updateChart(chartOptions);
    const shouldRaiseEvent = !options?.silent;
    if (shouldRaiseEvent)
      this.raiseChartOptionsChangedEvent();
  }
  awaitChartOptionUpdate(func) {
    const chart = this.chartController.getChartProxy().getChart();
    chart.waitForUpdate().then(() => func()).catch((e) => _errorOnce3(`chart update failed`, e));
  }
  getAxisProperty(expression) {
    return get(this.getChart().axes?.[0], expression, void 0);
  }
  setAxisThemeOverrides(properties) {
    const chart = this.getChart();
    const chartType = this.getChartType();
    const chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      const relevantAxes = chart.axes?.filter((axis) => {
        const parts = expression.split(".");
        let current = axis;
        for (const part of parts) {
          if (!(part in current)) {
            return false;
          }
          current = current[part];
        }
        return true;
      });
      if (!relevantAxes)
        continue;
      for (const axis of relevantAxes) {
        if (!this.isValidAxisType(axis))
          continue;
        this.assignChartAxisThemeOverride(chartOptions, chartType, axis.type, null, expression, value);
      }
    }
    this.applyChartOptions(chartOptions);
  }
  getCartesianAxisProperty(axisType, expression) {
    const axes = this.getChartAxes();
    const axis = this.getCartesianAxis(axes, axisType);
    return get(axis, expression, void 0);
  }
  getCartesianAxisThemeOverride(axisType, expression) {
    const axes = this.getChartAxes();
    const chartAxis = this.getCartesianAxis(axes, axisType);
    if (!chartAxis || !this.isValidAxisType(chartAxis))
      return void 0;
    const chartType = this.getChartType();
    const chartOptions = this.getChart().getOptions();
    return this.retrieveChartAxisThemeOverride(
      chartOptions,
      chartType,
      chartAxis.type,
      axisType === "yAxis" ? ["left", "right"] : ["bottom", "top"],
      expression
    );
  }
  setCartesianAxisThemeOverrides(axisType, properties) {
    const axes = this.getChartAxes();
    const chartAxis = this.getCartesianAxis(axes, axisType);
    if (!chartAxis || !this.isValidAxisType(chartAxis))
      return;
    const chartType = this.getChartType();
    const chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      this.assignChartAxisThemeOverride(
        chartOptions,
        chartType,
        chartAxis.type,
        axisType === "yAxis" ? ["left", "right"] : ["bottom", "top"],
        expression,
        value
      );
    }
    this.applyChartOptions(chartOptions);
  }
  setCartesianAxisOptions(axisType, properties) {
    this.updateCartesianAxisOptions(axisType, (chartOptions, axes, chartAxis) => {
      const axisIndex = axes.indexOf(chartAxis);
      for (const { expression, value } of properties) {
        this.assignChartOption(chartOptions, `axes.${axisIndex}.${expression}`, value);
      }
    });
  }
  updateCartesianAxisOptions(axisType, updateFunc) {
    const existingChartOptions = this.getChart().getOptions();
    const axisOptions = "axes" in existingChartOptions ? existingChartOptions.axes : void 0;
    if (!existingChartOptions || !axisOptions)
      return;
    const axes = this.getChartAxes();
    const chartAxis = this.getCartesianAxis(axes, axisType);
    if (!chartAxis)
      return;
    const chartOptions = this.createChartOptions();
    chartOptions.axes = axisOptions;
    updateFunc(chartOptions, axes, chartAxis, existingChartOptions);
    this.applyChartOptions(chartOptions);
  }
  setCartesianCategoryAxisType(axisType, value) {
    this.updateCartesianAxisOptions(axisType, (chartOptions, _axes, chartAxis, existingChartOptions) => {
      const chartType = this.getChartType();
      this.assignPersistedAxisOverrides({
        existingAxes: [chartAxis],
        retainedChartAxisThemeOverrideKeys: this.getRetainedChartAxisThemeOverrideKeys(
          axisType,
          chartType,
          chartType
        ),
        existingChartOptions,
        targetChartOptions: chartOptions,
        existingChartType: chartType,
        targetChartType: chartType
      });
      this.assignChartOption(chartOptions, `axes.0.type`, value);
      this.chartController.setCategoryAxisType(value);
    });
  }
  getCartesianAxis(axes, axisType) {
    if (axes.length < 2) {
      return void 0;
    }
    switch (axisType) {
      case "xAxis":
        return axes[0].direction === "x" ? axes[0] : axes[1];
      case "yAxis":
        return axes[1].direction === "y" ? axes[1] : axes[0];
    }
  }
  getSeriesOption(seriesType, expression, calculated) {
    const series = this.getChart().series.find((s) => isMatchingSeries(seriesType, s));
    return get(calculated ? series : series?.properties.toJson(), expression, void 0);
  }
  setSeriesOptions(seriesType, properties) {
    const chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      this.assignChartOptionsSeriesThemeOverride(chartOptions, seriesType, `series.${expression}`, value);
    }
    this.applyChartOptions(chartOptions);
  }
  getPairedMode() {
    return this.chartController.getChartProxy().isPaired();
  }
  setPairedMode(paired) {
    this.chartController.getChartProxy().setPaired(paired);
  }
  getChartAxes() {
    const chart = this.getChart();
    return chart.axes ?? [];
  }
  retrieveChartAxisThemeOverride(chartOptions, chartType, axisType, axisPositions, expression) {
    if (axisPositions) {
      for (const axisPosition of axisPositions) {
        const value = this.retrieveChartOptionsThemeOverride(
          chartOptions,
          chartType,
          ["axes", axisType, axisPosition, ...expression ? [expression] : []].join(".")
        );
        if (value === void 0)
          continue;
        return value;
      }
    } else {
      return this.retrieveChartOptionsThemeOverride(
        chartOptions,
        chartType,
        ["axes", axisType, ...expression ? [expression] : []].join(".")
      );
    }
  }
  assignChartAxisThemeOverride(chartOptions, chartType, axisType, axisPositions, expression, value) {
    if (axisPositions) {
      for (const axisPosition of axisPositions) {
        this.assignChartOptionsThemeOverride(
          chartOptions,
          chartType,
          ["axes", axisType, axisPosition, ...expression ? [expression] : []].join("."),
          value
        );
      }
    } else {
      this.assignChartOptionsThemeOverride(
        chartOptions,
        chartType,
        ["axes", axisType, ...expression ? [expression] : []].join("."),
        value
      );
    }
  }
  isValidAxisType(chartAxis) {
    return VALID_AXIS_TYPES.includes(chartAxis.type);
  }
  getChartType() {
    return this.chartController.getChartType();
  }
  getChart() {
    return this.chartController.getChartProxy().getChart();
  }
  updateChart(chartOptions) {
    const chartRef = this.chartController.getChartProxy().getChartRef();
    chartRef.skipAnimations();
    chartRef.updateDelta(chartOptions);
  }
  createChartOptions() {
    const chartOptions = {
      theme: {
        overrides: {}
      }
    };
    return chartOptions;
  }
  retrieveChartOptionsThemeOverride(chartOptions, chartType, expression) {
    const chartSeriesTypes = this.getChartThemeOverridesSeriesTypeKeys(chartType);
    for (const seriesType of chartSeriesTypes) {
      const value = this.retrieveChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression);
      if (value === void 0)
        continue;
      return value;
    }
    return void 0;
  }
  assignChartOptionsThemeOverride(chartOptions, chartType, expression, value) {
    const chartSeriesTypes = this.getChartThemeOverridesSeriesTypeKeys(chartType);
    for (const seriesType of chartSeriesTypes) {
      this.assignChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression, value);
    }
  }
  retrieveChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression) {
    return this.retrieveChartOption(
      chartOptions,
      ["theme", "overrides", seriesType, ...expression ? [expression] : []].join(".")
    );
  }
  assignChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression, value) {
    this.assignChartOption(
      chartOptions,
      ["theme", "overrides", seriesType, ...expression ? [expression] : []].join("."),
      value
    );
  }
  getChartThemeOverridesSeriesTypeKeys(chartType) {
    const chartSeriesTypes = this.chartController.getChartSeriesTypes(chartType);
    if (this.chartController.isComboChart()) {
      chartSeriesTypes.push("common");
    }
    return chartSeriesTypes;
  }
  retrieveChartOption(chartOptions, expression) {
    return get(chartOptions, expression, void 0);
  }
  assignChartOption(chartOptions, expression, value) {
    set(chartOptions, expression, value);
  }
  raiseChartOptionsChangedEvent() {
    const chartModel = this.chartController.getChartModel();
    this.eventService.dispatchEvent({
      type: "chartOptionsChanged",
      chartId: chartModel.chartId,
      chartType: chartModel.chartType,
      chartThemeName: this.chartController.getChartThemeName(),
      chartOptions: chartModel.chartOptions
    });
  }
  destroy() {
    super.destroy();
  }
};
function isMatchingSeries(seriesType, series) {
  return isSeriesType(seriesType) && series.type === seriesType;
}
var GridChartComp = class _GridChartComp extends Component40 {
  constructor(params) {
    super(
      /* html */
      `<div class="ag-chart">
            <div data-ref="eChartContainer" class="ag-chart-components-wrapper ag-chart-menu-hidden">
                <div data-ref="eChart" class="ag-chart-canvas-wrapper"></div>
                <div data-ref="eEmpty" class="ag-chart-empty-text ag-unselectable"></div>
            </div>
            <div data-ref="eMenuContainer" class="ag-chart-docked-container"></div>
            </div>`
    );
    this.eChart = RefPlaceholder19;
    this.eChartContainer = RefPlaceholder19;
    this.eMenuContainer = RefPlaceholder19;
    this.eEmpty = RefPlaceholder19;
    this.params = params;
  }
  wireBeans(beans) {
    this.crossFilterService = beans.chartCrossFilterService;
    this.chartTranslationService = beans.chartTranslationService;
    this.chartMenuService = beans.chartMenuService;
    this.focusService = beans.focusService;
    this.popupService = beans.popupService;
  }
  postConstruct() {
    const modelParams = {
      ...this.params,
      chartType: getCanonicalChartType(this.params.chartType),
      chartThemeName: this.getThemeName()
    };
    const isRtl = this.gos.get("enableRtl");
    this.addCssClass(isRtl ? "ag-rtl" : "ag-ltr");
    const model = this.createBean(new ChartDataModel(modelParams));
    this.chartController = this.createManagedBean(new ChartController(model));
    this.chartOptionsService = this.createManagedBean(new ChartOptionsService(this.chartController));
    this.validateCustomThemes();
    this.createChart();
    if (this.params.insideDialog) {
      this.addDialog();
    }
    this.addMenu();
    this.addManagedElementListeners(this.getGui(), { focusin: this.setActiveChartCellRange.bind(this) });
    this.addManagedListeners(this.chartController, { chartModelUpdate: this.update.bind(this) });
    this.addManagedPropertyListeners(
      ["chartThemeOverrides", "chartThemes"],
      this.reactivePropertyUpdate.bind(this)
    );
    this.update();
    this.raiseChartCreatedEvent();
  }
  createChart() {
    let chartInstance = void 0;
    if (this.chartProxy) {
      chartInstance = this.chartProxy.destroy({ keepChartInstance: true });
    }
    const crossFilterCallback = (event, reset) => {
      const ctx = this.params.crossFilteringContext;
      ctx.lastSelectedChartId = reset ? "" : this.chartController.getChartId();
      if (reset) {
        this.params.crossFilteringResetCallback();
      }
      this.crossFilterService.filter(event, reset);
    };
    const chartType = this.chartController.getChartType();
    const chartProxyParams = {
      chartType,
      chartInstance,
      getChartThemeName: this.getChartThemeName.bind(this),
      getChartThemes: this.getChartThemes.bind(this),
      customChartThemes: this.gos.get("customChartThemes"),
      getGridOptionsChartThemeOverrides: () => this.getGridOptionsChartThemeOverrides(),
      getExtraPaddingDirections: () => this.chartMenu?.getExtraPaddingDirections() ?? [],
      apiChartThemeOverrides: this.params.chartThemeOverrides,
      crossFiltering: this.params.crossFiltering ?? false,
      crossFilterCallback,
      parentElement: this.eChart,
      grouping: this.chartController.isGrouping(),
      chartThemeToRestore: this.params.chartThemeName,
      chartOptionsToRestore: this.params.chartOptionsToRestore,
      chartPaletteToRestore: this.params.chartPaletteToRestore,
      seriesChartTypes: this.chartController.getSeriesChartTypes(),
      translate: (toTranslate) => this.chartTranslationService.translate(toTranslate)
    };
    this.params.chartOptionsToRestore = void 0;
    this.chartType = chartType;
    this.chartProxy = _GridChartComp.createChartProxy(chartProxyParams);
    if (!this.chartProxy) {
      _warnOnce13("invalid chart type supplied: " + chartProxyParams.chartType);
      return;
    }
    this.chartController.setChartProxy(this.chartProxy);
    this.createMenuContext();
  }
  createMenuContext() {
    if (this.chartMenuContext) {
      return;
    }
    const chartMenuParamsFactory = this.createManagedBean(
      new ChartMenuParamsFactory(this.chartOptionsService.getChartThemeOverridesProxy())
    );
    const chartAxisMenuParamsFactory = this.createManagedBean(
      new ChartMenuParamsFactory(this.chartOptionsService.getAxisThemeOverridesProxy())
    );
    this.chartMenuContext = {
      chartController: this.chartController,
      chartOptionsService: this.chartOptionsService,
      chartMenuParamsFactory,
      chartAxisMenuParamsFactory
    };
  }
  getChartThemeName() {
    return this.chartController.getChartThemeName();
  }
  getChartThemes() {
    return this.chartController.getThemeNames();
  }
  getGridOptionsChartThemeOverrides() {
    return this.gos.get("chartThemeOverrides");
  }
  static createChartProxy(chartProxyParams) {
    switch (chartProxyParams.chartType) {
      case "column":
      case "bar":
      case "groupedColumn":
      case "stackedColumn":
      case "normalizedColumn":
      case "groupedBar":
      case "stackedBar":
      case "normalizedBar":
        return new BarChartProxy(chartProxyParams);
      case "pie":
      case "donut":
      case "doughnut":
        return new PieChartProxy(chartProxyParams);
      case "area":
      case "stackedArea":
      case "normalizedArea":
        return new AreaChartProxy(chartProxyParams);
      case "line":
        return new LineChartProxy(chartProxyParams);
      case "scatter":
      case "bubble":
        return new ScatterChartProxy(chartProxyParams);
      case "histogram":
        return new HistogramChartProxy(chartProxyParams);
      case "radarLine":
      case "radarArea":
      case "nightingale":
      case "radialColumn":
      case "radialBar":
        return new PolarChartProxy(chartProxyParams);
      case "rangeBar":
      case "rangeArea":
        return new RangeChartProxy(chartProxyParams);
      case "boxPlot":
        return new BoxPlotChartProxy(chartProxyParams);
      case "treemap":
      case "sunburst":
        return new HierarchicalChartProxy(chartProxyParams);
      case "heatmap":
        return new HeatmapChartProxy(chartProxyParams);
      case "waterfall":
        return new WaterfallChartProxy(chartProxyParams);
      case "columnLineCombo":
      case "areaColumnCombo":
      case "customCombo":
        return new ComboChartProxy(chartProxyParams);
      default:
        throw `AG Grid: Unable to create chart as an invalid chartType = '${chartProxyParams.chartType}' was supplied.`;
    }
  }
  addDialog() {
    const title = this.chartTranslationService.translate(
      this.params.pivotChart ? "pivotChartTitle" : "rangeChartTitle"
    );
    const { width, height } = this.getBestDialogSize();
    const afterGuiAttached = this.params.focusDialogOnOpen ? () => setTimeout(() => this.focusService.focusInto(this.getGui())) : void 0;
    this.chartDialog = new AgDialog({
      resizable: true,
      movable: true,
      maximizable: true,
      title,
      width,
      height,
      component: this,
      centered: true,
      closable: true,
      afterGuiAttached,
      postProcessPopupParams: {
        type: "chart"
      }
    });
    this.createBean(this.chartDialog);
    this.chartDialog.addEventListener("destroyed", () => {
      this.destroy();
      this.chartMenuService.hideAdvancedSettings();
      const lastFocusedCell = this.focusService.getFocusedCell();
      setTimeout(() => {
        if (this.focusService.isAlive()) {
          if (lastFocusedCell) {
            this.focusService.setFocusedCell({ ...lastFocusedCell, forceBrowserFocus: true });
          } else {
            this.focusService.focusGridInnerElement();
          }
        }
      });
    });
  }
  getBestDialogSize() {
    const popupParent = this.popupService.getPopupParent();
    const maxWidth = _getAbsoluteWidth2(popupParent) * 0.75;
    const maxHeight = _getAbsoluteHeight2(popupParent) * 0.75;
    const ratio = 0.553;
    const chart = this.chartProxy.getChart();
    let width = this.params.insideDialog ? 850 : chart.width;
    let height = this.params.insideDialog ? 470 : chart.height;
    if (width > maxWidth || height > maxHeight) {
      width = Math.min(width, maxWidth);
      height = Math.round(width * ratio);
      if (height > maxHeight) {
        height = maxHeight;
        width = Math.min(width, Math.round(height / ratio));
      }
    }
    return { width, height };
  }
  addMenu() {
    if (!this.params.crossFiltering) {
      this.chartMenu = this.createBean(
        new ChartMenu(this.eChartContainer, this.eMenuContainer, this.chartMenuContext)
      );
      this.eChartContainer.appendChild(this.chartMenu.getGui());
    }
  }
  update(params) {
    if (params?.chartId) {
      const validUpdate = this.chartController.update(params);
      if (!validUpdate) {
        return;
      }
    }
    const updatedChartType = this.chartTypeChanged(params);
    const persistedThemeOverrides = updatedChartType || this.chartEmpty ? ((updatedChartType2) => {
      const currentChartType = this.chartType;
      const targetChartType = updatedChartType2;
      const existingChartInstance = this.chartProxy.getChart();
      const existingChartOptions = existingChartInstance?.getOptions();
      const existingAxes = existingChartInstance?.axes;
      return this.chartOptionsService.getPersistedChartThemeOverrides(
        existingChartOptions,
        existingAxes,
        currentChartType,
        targetChartType ?? currentChartType
      );
    })(updatedChartType) : void 0;
    if (updatedChartType)
      this.createChart();
    if (persistedThemeOverrides && params?.chartThemeOverrides) {
      _mergeDeep(persistedThemeOverrides, params.chartThemeOverrides);
    }
    const updatedThemeOverrides = persistedThemeOverrides ?? params?.chartThemeOverrides;
    this.updateChart(updatedThemeOverrides);
    if (params?.chartId) {
      this.chartProxy.getChart().waitForUpdate().then(() => {
        this.chartController.raiseChartApiUpdateEvent();
      });
    }
  }
  updateChart(updatedOverrides) {
    const { chartProxy } = this;
    const selectedCols = this.chartController.getSelectedValueColState();
    const data = this.chartController.getChartData();
    const chartEmpty = this.handleEmptyChart(data, selectedCols.length);
    this.chartEmpty = chartEmpty;
    if (chartEmpty) {
      if (updatedOverrides)
        this.chartController.updateThemeOverrides(updatedOverrides);
      return;
    }
    const chartUpdateParams = this.chartController.getChartUpdateParams(updatedOverrides);
    chartProxy.update(chartUpdateParams);
    this.chartProxy.getChart().waitForUpdate().then(() => {
      this.chartController.raiseChartUpdatedEvent();
    });
  }
  chartTypeChanged(updateParams) {
    const [currentType, updatedChartType] = [this.chartController.getChartType(), updateParams?.chartType];
    const targetChartType = updatedChartType ? getCanonicalChartType(updatedChartType) : void 0;
    if (this.chartType !== currentType)
      return targetChartType ?? currentType;
    if (targetChartType && currentType !== targetChartType)
      return targetChartType;
    return null;
  }
  getChartModel() {
    return this.chartController.getChartModel();
  }
  getChartImageDataURL(fileFormat) {
    return this.chartProxy.getChartImageDataURL(fileFormat);
  }
  handleEmptyChart(data, numFields) {
    const pivotModeDisabled = this.chartController.isPivotChart() && !this.chartController.isPivotMode();
    const chartType = this.chartController.getChartType();
    let minFieldsRequired = 1;
    if (this.chartController.isActiveXYChart()) {
      minFieldsRequired = chartType === "bubble" ? 3 : 2;
    } else if (isHierarchical(getSeriesType(chartType))) {
      minFieldsRequired = 0;
    }
    const isEmptyChart = numFields < minFieldsRequired || data.length === 0;
    if (this.eChart) {
      const isEmpty = pivotModeDisabled || isEmptyChart;
      _setDisplayed52(this.eChart, !isEmpty);
      _setDisplayed52(this.eEmpty, isEmpty);
    }
    if (pivotModeDisabled) {
      this.eEmpty.innerText = this.chartTranslationService.translate("pivotChartRequiresPivotMode");
      return true;
    }
    if (isEmptyChart) {
      this.eEmpty.innerText = this.chartTranslationService.translate("noDataToChart");
      return true;
    }
    return false;
  }
  downloadChart(dimensions, fileName, fileFormat) {
    this.chartProxy.downloadChart(dimensions, fileName, fileFormat);
  }
  openChartToolPanel(panel) {
    const menuPanel = panel ? CHART_TOOL_PANEL_MENU_OPTIONS[panel] : panel;
    this.chartMenu.showMenu({ panel: menuPanel });
  }
  closeChartToolPanel() {
    this.chartMenu.hideMenu();
  }
  getChartId() {
    return this.chartController.getChartId();
  }
  getUnderlyingChart() {
    return this.chartProxy.getChartRef();
  }
  crossFilteringReset() {
    this.chartProxy.crossFilteringReset();
  }
  setActiveChartCellRange(focusEvent) {
    if (this.getGui().contains(focusEvent.relatedTarget)) {
      return;
    }
    this.chartController.setChartRange(true);
    this.focusService.clearFocusedCell();
  }
  getThemeName() {
    const availableChartThemes = this.gos.get("chartThemes") || DEFAULT_THEMES;
    if (availableChartThemes.length === 0) {
      throw new Error("Cannot create chart: no chart themes available.");
    }
    const { chartThemeName } = this.params;
    return _includes7(availableChartThemes, chartThemeName) ? chartThemeName : availableChartThemes[0];
  }
  getAllKeysInObjects(objects) {
    const allValues = {};
    objects.filter((obj) => obj != null).forEach((obj) => {
      Object.keys(obj).forEach((key) => allValues[key] = null);
    });
    return Object.keys(allValues);
  }
  validateCustomThemes() {
    const suppliedThemes = this.getChartThemes();
    const customChartThemes = this.gos.get("customChartThemes");
    if (customChartThemes) {
      this.getAllKeysInObjects([customChartThemes]).forEach((customThemeName) => {
        if (!_includes7(suppliedThemes, customThemeName)) {
          _warnOnce13(
            "a custom chart theme with the name '" + customThemeName + "' has been supplied but not added to the 'chartThemes' list"
          );
        }
      });
    }
  }
  reactivePropertyUpdate() {
    this.chartController.setChartThemeName(this.getThemeName(), true);
    const chartId = this.getChartId();
    const modelType = this.chartController.isCrossFilterChart() ? "crossFilter" : this.getChartModel().modelType;
    const chartThemeOverrides = this.gos.get("chartThemeOverrides") || {};
    this.update({
      type: `${modelType}ChartUpdate`,
      chartId,
      chartThemeOverrides
    });
  }
  raiseChartCreatedEvent() {
    this.chartProxy.getChart().waitForUpdate().then(() => {
      this.eventService.dispatchEvent({
        type: "chartCreated",
        chartId: this.chartController.getChartId()
      });
    });
  }
  raiseChartDestroyedEvent() {
    this.eventService.dispatchEvent({
      type: "chartDestroyed",
      chartId: this.chartController.getChartId()
    });
  }
  destroy() {
    super.destroy();
    if (this.chartProxy) {
      this.chartProxy.destroy();
    }
    this.destroyBean(this.chartMenu);
    if (this.chartDialog && this.chartDialog.isAlive()) {
      this.destroyBean(this.chartDialog);
    }
    this.onDestroyColorSchemeChangeListener?.();
    const eGui = this.getGui();
    _clearElement42(eGui);
    _removeFromParent7(eGui);
    this.raiseChartDestroyedEvent();
  }
};
var DEBUG = false;
function upgradeChartModel(model) {
  const originalVersion = model.version;
  if (model.version == null) {
    model.version = heuristicVersionDetection(model);
  }
  model = migrateIfBefore("23.0.0", model, migrateV23);
  model = migrateIfBefore("24.0.0", model, migrateV24);
  model = migrateIfBefore("25.1.0", model, migrateV25_1);
  model = migrateIfBefore("26.0.0", model, migrateV26);
  model = migrateIfBefore("26.1.0", model, migrateV26_1);
  model = migrateIfBefore("26.2.0", model, migrateV26_2);
  model = migrateIfBefore("28.0.0", model, migrateV28);
  model = migrateIfBefore("28.2.0", model, migrateV28_2);
  model = migrateIfBefore("29.0.0", model, migrateV29);
  model = migrateIfBefore("29.1.0", model, migrateV29_1);
  model = migrateIfBefore("29.2.0", model, migrateV29_2);
  model = migrateIfBefore("30.0.0", model, migrateV30);
  model = migrateIfBefore("31.0.0", model, migrateV31);
  model = migrateIfBefore("32.0.0", model, migrateV32);
  model = cleanup(model);
  model = migrateIfBefore(VERSION4, model, (m) => m);
  if (DEBUG && originalVersion !== model.version) {
    console.log("AG Grid: ChartModel migration complete", { model });
  }
  return model;
}
function migrateV23(model) {
  model = jsonRename("chartOptions.legend.item.marker.type", "shape", model);
  model = jsonRename("chartOptions.seriesDefaults.marker.type", "shape", model);
  model = jsonRename("chartOptions.legend.padding", "spacing", model);
  return model;
}
function migrateV24(model) {
  model = jsonDelete("chartOptions.seriesDefaults.marker.minSize", model);
  const {
    chartType,
    chartPalette,
    // Migrate.
    chartOptions: { xAxis, yAxis, ...chartOptions },
    ...chartModel
  } = model;
  const axesTypes = getLegacyAxisType(chartType);
  const axes = axesTypes?.map((type, i) => ({
    type,
    ...i === 0 ? xAxis : yAxis
  }));
  const LEGACY_PALETTES = {
    borneo: "ag-default",
    material: "ag-material",
    bright: "ag-vivid"
  };
  return {
    chartType,
    chartThemeName: LEGACY_PALETTES[chartPalette] ?? "ag-default",
    chartOptions: {
      ...chartOptions,
      axes,
      xAxis,
      yAxis
    },
    ...chartModel
  };
}
function migrateV25_1(model) {
  model = jsonRename("chartOptions.seriesDefaults.label.minRequiredAngle", "minAngle", model);
  return model;
}
function migrateV26(model) {
  const highlightOptUpdate = ({ dimOpacity, ...opts }) => ({
    ...opts,
    ...dimOpacity != null ? { series: { dimOpacity } } : {}
  });
  model = jsonMutate("chartOptions.seriesDefaults.highlightStyle", model, highlightOptUpdate);
  model = jsonDelete("chart", model);
  model = jsonDelete("chartOptions.seriesDefaults.tooltipClass", model);
  model = jsonDelete("chartOptions.seriesDefaults.tooltipTracking", model);
  model = jsonDeleteDefault("chartOptions.axes[].label.rotation", 0, model);
  model = jsonDeleteDefault("chartOptions.axes[].label.rotation", 335, model);
  return model;
}
function migrateV26_1(model) {
  const highlightOptUpdate = ({ item, series, ...opts }) => ({
    item: { ...opts, ...item },
    ...series ? { series } : {}
  });
  model = jsonMutate("chartOptions.seriesDefaults.highlightStyle", model, highlightOptUpdate);
  model = jsonMutate("chartOptions.series[].highlightStyle", model, highlightOptUpdate);
  return model;
}
function migrateV26_2(model) {
  model = jsonMove("chartOptions.seriesDefaults.fill.opacity", "chartOptions.seriesDefaults.fillOpacity", model);
  model = jsonMove("chartOptions.seriesDefaults.stroke.opacity", "chartOptions.seriesDefaults.strokeOpacity", model);
  model = jsonMove("chartOptions.seriesDefaults.stroke.width", "chartOptions.seriesDefaults.strokeWidth", model);
  model = jsonDelete("chartOptions.seriesDefaults.fill", model);
  model = jsonDelete("chartOptions.seriesDefaults.stroke", model);
  model = jsonDelete("chartOptions.seriesDefaults.callout.colors", model);
  model = jsonDelete("chartOptions.xAxis", model);
  model = jsonDelete("chartOptions.yAxis", model);
  const {
    chartType: providedChartType,
    chartOptions: { axes, series, seriesDefaults, ...otherChartOptions },
    ...otherModelProps
  } = model;
  const chartType = getCanonicalChartType(providedChartType);
  const seriesType = getSeriesType(chartType);
  const seriesTypes = [seriesType];
  const chartTypeMixin = {};
  if (!isPieChartSeries(seriesType)) {
    const minimalAxis = { top: {}, bottom: {}, left: {}, right: {} };
    const updatedAxes = axes.map(({ type, ...axisProps }) => ({
      [type]: { ...minimalAxis, ...axisProps }
    })).reduce(merge, {});
    ALL_AXIS_TYPES.filter((v) => updatedAxes[v] == null).forEach((v) => {
      updatedAxes[v] = { ...minimalAxis };
    });
    chartTypeMixin.axes = updatedAxes;
  }
  const updatedChartOptions = seriesTypes.map((t) => ({
    [t]: {
      ...chartTypeMixin,
      series: seriesDefaults,
      ...otherChartOptions
    }
  })).reduce(merge, {});
  model = {
    ...otherModelProps,
    chartType,
    chartOptions: updatedChartOptions
  };
  return model;
}
function migrateV28(model) {
  model = jsonDelete("chartOptions.*.title.padding", model);
  model = jsonDelete("chartOptions.*.subtitle.padding", model);
  model = jsonDelete("chartOptions.*.axes.*.title.padding", model);
  model = jsonBackfill("chartOptions.*.axes.*.title.enabled", false, model);
  return model;
}
function migrateV28_2(model) {
  model = jsonRename("chartOptions.pie.series.callout", "calloutLine", model);
  model = jsonRename("chartOptions.pie.series.label", "calloutLabel", model);
  model = jsonRename("chartOptions.pie.series.labelKey", "sectorLabelKey", model);
  model = jsonRename("chartOptions.pie.series.labelName", "sectorLabelName", model);
  model = jsonRename("chartOptions.donut.series.callout", "calloutLine", model);
  model = jsonRename("chartOptions.donut.series.label", "calloutLabel", model);
  model = jsonRename("chartOptions.donut.series.labelKey", "sectorLabelKey", model);
  model = jsonRename("chartOptions.donut.series.labelName", "sectorLabelName", model);
  return model;
}
function migrateV29(model) {
  model = jsonMoveIfMissing("chartOptions.scatter.series.fill", "chartOptions.scatter.series.marker.fill", model);
  model = jsonMoveIfMissing(
    "chartOptions.scatter.series.fillOpacity",
    "chartOptions.scatter.series.marker.fillOpacity",
    model
  );
  model = jsonMoveIfMissing("chartOptions.scatter.series.stroke", "chartOptions.scatter.series.marker.stroke", model);
  model = jsonMoveIfMissing(
    "chartOptions.scatter.series.strokeOpacity",
    "chartOptions.scatter.series.marker.strokeOpacity",
    model
  );
  model = jsonMoveIfMissing(
    "chartOptions.scatter.series.strokeWidth",
    "chartOptions.scatter.series.marker.strokeWidth",
    model
  );
  model = jsonMove("chartOptions.scatter.series.paired", "chartOptions.scatter.paired", model);
  return model;
}
function migrateV29_1(model) {
  model = jsonDelete("chartOptions.axes[].tick.count", model);
  return model;
}
function migrateV29_2(model) {
  const tooltipOptUpdate = ({ tracking, ...opts }) => {
    const output = { ...opts };
    if (tracking === false) {
      output.position ?? (output.position = { type: "pointer" });
      output.range ?? (output.range = "nearest");
    } else if (tracking === true) {
      output.position ?? (output.position = { type: "node" });
      output.range ?? (output.range = "nearest");
    }
    return output;
  };
  model = jsonMutate("chartOptions.*.tooltip", model, tooltipOptUpdate);
  return model;
}
function migrateV30(model) {
  model = jsonRename("chartOptions.pie.series.labelKey", "sectorLabelKey", model);
  model = jsonRename("chartOptions.pie.series.labelName", "sectorLabelName", model);
  model = migrateV29_1(model);
  model = migrateV29_2(model);
  model = jsonDelete("chartOptions.*.series.flipXY", model);
  model = jsonAdd("chartOptions.common.legend.enabled", true, model);
  model = jsonBackfill("chartOptions.common.legend.position", "right", model);
  return model;
}
function migrateV31(model) {
  const V30_LEGACY_PALETTES = {
    "ag-pastel": "ag-sheets",
    "ag-solar": "ag-polychroma"
  };
  const updatedModel = jsonRename("chartOptions.column", "bar", model);
  const chartThemeName = V30_LEGACY_PALETTES[updatedModel.chartThemeName] || updatedModel.chartThemeName;
  return {
    ...updatedModel,
    chartThemeName
  };
}
function migrateV32(model) {
  model = jsonMutateProperty("chartOptions.*.autoSize", true, model, (parent, targetProp) => {
    if (parent[targetProp] === true) {
    } else if (parent[targetProp] === false) {
      parent["minHeight"] = 600;
      parent["minWidth"] = 300;
    }
    delete parent[targetProp];
  });
  return model;
}
function cleanup(model) {
  model = jsonDelete("chartOptions.*.width", model);
  model = jsonDelete("chartOptions.*.height", model);
  model = jsonBackfill("chartOptions.*.axes.category.label.autoRotate", true, model);
  return model;
}
function heuristicVersionDetection(model) {
  const modelAny = model;
  if (model.version != null) {
    return model.version;
  }
  const hasKey = (obj, ...keys) => {
    return Object.keys(obj || {}).some((k) => keys.includes(k));
  };
  const chartOptions = modelAny.chartOptions;
  const seriesOptions = hasKey(chartOptions, "seriesDefaults") ? chartOptions?.seriesDefaults : chartOptions?.[Object.keys(chartOptions)[0]];
  const hints = {
    "27.0.0": hasKey(modelAny, "seriesChartTypes"),
    "26.2.0": !hasKey(chartOptions, "seriesDefaults"),
    "26.1.0": hasKey(seriesOptions?.highlightStyle, "item"),
    "26.0.0": hasKey(seriesOptions?.highlightStyle, "series"),
    // '26.0.0': modelAny.chart === undefined,
    "25.1.0": hasKey(seriesOptions?.label, "minAngle"),
    "25.0.0": hasKey(modelAny, "modelType", "aggFunc", "unlinkChart", "suppressChartRanges") || hasKey(seriesOptions, "lineDash", "lineDashOffset"),
    "24.0.0": hasKey(modelAny, "chartThemeName", "chart") || hasKey(chartOptions, "series"),
    "23.2.0": hasKey(chartOptions, "navigator"),
    "23.0.0": hasKey(chartOptions?.legend?.item?.marker, "shape"),
    "22.1.0": hasKey(modelAny, "chartPalette", "chartType")
  };
  const defaultVersion = "27.1.0";
  const matchingHints = Object.entries(hints).filter(([_, match]) => match);
  if (DEBUG)
    console.log("AG Grid: ChartModel migration", { heuristicVersionCandidates: matchingHints });
  const [heuristicVersion = defaultVersion] = matchingHints[0];
  if (DEBUG)
    console.log("AG Grid: ChartModel migration", { heuristicVersion });
  return heuristicVersion;
}
function migrateIfBefore(maxVersion, model, migration) {
  if (versionNumber(maxVersion) > versionNumber(model.version)) {
    if (DEBUG)
      console.log("AG Grid: ChartModel migration", { migratingTo: maxVersion });
    const result = migration(model);
    result.version = maxVersion;
    if (DEBUG)
      console.log("AG Grid: ChartModel migration", { migratedTo: maxVersion, result });
    return result;
  }
  return model;
}
function versionParts(version) {
  const split = typeof version === "string" ? version.split(".").map((v) => Number(v)) : [];
  if (split.length !== 3 || split.some((v) => isNaN(v))) {
    throw new Error("AG Grid - Illegal version string: " + version);
  }
  return {
    major: split[0],
    minor: split[1],
    patch: split[2]
  };
}
function versionNumber(version) {
  const { major, minor, patch } = versionParts(version);
  return major * 1e4 + minor * 100 + patch;
}
function jsonDeleteDefault(path, defaultValue, json) {
  return jsonMutateProperty(path, true, json, (parent, prop) => {
    if (parent[prop] === defaultValue) {
      delete parent[prop];
    }
  });
}
function jsonBackfill(path, defaultValue, json) {
  return jsonMutateProperty(path, false, json, (parent, prop) => {
    if (parent[prop] == null) {
      parent[prop] = defaultValue;
    }
  });
}
function jsonAdd(path, value, json) {
  if (typeof path === "string") {
    path = path.split(".");
  }
  const nextPath = path[0];
  if (path.length > 1) {
    json[nextPath] = jsonAdd(path.slice(1), value, json[nextPath] ?? {});
  }
  const hasProperty = Object.keys(json).includes(nextPath);
  if (!hasProperty) {
    json[nextPath] = value;
  }
  return json;
}
function jsonMove(from, to, json) {
  let valueToMove = void 0;
  let valueFound = false;
  json = jsonMutateProperty(from, true, json, (parent, prop) => {
    valueFound = true;
    valueToMove = parent[prop];
    delete parent[prop];
  });
  if (!valueFound) {
    return json;
  }
  return jsonMutateProperty(to, false, json, (parent, prop) => {
    parent[prop] = valueToMove;
  });
}
function jsonMoveIfMissing(from, to, json) {
  let valueToMove = void 0;
  let valueFound = false;
  json = jsonMutateProperty(from, true, json, (parent, prop) => {
    valueFound = true;
    valueToMove = parent[prop];
    delete parent[prop];
  });
  if (!valueFound) {
    return json;
  }
  return jsonMutateProperty(to, false, json, (parent, prop) => {
    if (parent[prop] === void 0) {
      parent[prop] = valueToMove;
    }
  });
}
function jsonRename(path, renameTo, json) {
  return jsonMutateProperty(path, true, json, (parent, prop) => {
    parent[renameTo] = parent[prop];
    delete parent[prop];
  });
}
function jsonDelete(path, json) {
  return jsonMutateProperty(path, true, json, (parent, prop) => delete parent[prop]);
}
function jsonMutateProperty(path, skipMissing, json, mutator) {
  const pathElements = path instanceof Array ? path : path.split(".");
  const parentPathElements = pathElements.slice(0, pathElements.length - 1);
  const targetName = pathElements[pathElements.length - 1];
  return jsonMutate(parentPathElements, json, (parent) => {
    const hasProperty = Object.keys(parent).includes(targetName);
    if (skipMissing && !hasProperty) {
      return parent;
    }
    const result = { ...parent };
    mutator(result, targetName);
    return result;
  });
}
function jsonMutate(path, json, mutator) {
  const pathElements = path instanceof Array ? path : path.split(".");
  json = { ...json };
  if (pathElements.length === 0) {
    return mutator(json);
  } else if (pathElements[0].startsWith("{")) {
    const pathOptions = pathElements[0].substring(1, pathElements[0].lastIndexOf("}")).split(",");
    for (const pathOption of pathOptions) {
      if (json[pathOption] != null) {
        json[pathOption] = jsonMutate(pathElements.slice(1), json[pathOption], mutator);
      }
    }
  } else if (pathElements[0].endsWith("[]")) {
    const arrayName = pathElements[0].substring(0, path[0].indexOf("["));
    if (json[arrayName] instanceof Array) {
      json[arrayName] = json[arrayName].map((v) => jsonMutate(pathElements.slice(1), v, mutator));
    }
  } else if (pathElements[0] === "*") {
    for (const jsonProp in json) {
      json[jsonProp] = jsonMutate(pathElements.slice(1), json[jsonProp], mutator);
    }
  } else if (json[pathElements[0]] != null) {
    json[pathElements[0]] = jsonMutate(pathElements.slice(1), json[pathElements[0]], mutator);
  }
  return json;
}
var merge = (r, n) => ({ ...r, ...n });
var ChartService = class extends BeanStub15 {
  constructor() {
    super(...arguments);
    this.beanName = "chartService";
    this.activeCharts = /* @__PURE__ */ new Set();
    this.activeChartComps = /* @__PURE__ */ new Set();
    this.crossFilteringContext = {
      lastSelectedChartId: ""
    };
    this.isEnterprise = () => _ModuleSupport4.enterpriseModule.isEnterprise;
  }
  wireBeans(beans) {
    this.visibleColsService = beans.visibleColsService;
    this.rangeService = beans.rangeService;
    this.environment = beans.environment;
    this.focusService = beans.focusService;
  }
  updateChart(params) {
    if (this.activeChartComps.size === 0) {
      _warnOnce14(`No active charts to update.`);
      return;
    }
    const chartComp = [...this.activeChartComps].find((chartComp2) => chartComp2.getChartId() === params.chartId);
    if (!chartComp) {
      _warnOnce14(`Unable to update chart. No active chart found with ID: ${params.chartId}.`);
      return;
    }
    chartComp.update(params);
  }
  getChartModels() {
    const models = [];
    const versionedModel = (c) => {
      return { ...c, version: VERSION4 };
    };
    this.activeChartComps.forEach((c) => models.push(versionedModel(c.getChartModel())));
    return models;
  }
  getChartRef(chartId) {
    let chartRef;
    this.activeCharts.forEach((cr) => {
      if (cr.chartId === chartId) {
        chartRef = cr;
      }
    });
    return chartRef;
  }
  getChartComp(chartId) {
    let chartComp;
    this.activeChartComps.forEach((comp) => {
      if (comp.getChartId() === chartId) {
        chartComp = comp;
      }
    });
    return chartComp;
  }
  getChartImageDataURL(params) {
    let url;
    this.activeChartComps.forEach((c) => {
      if (c.getChartId() === params.chartId) {
        url = c.getChartImageDataURL(params.fileFormat);
      }
    });
    return url;
  }
  downloadChart(params) {
    const chartComp = Array.from(this.activeChartComps).find((c) => c.getChartId() === params.chartId);
    chartComp?.downloadChart(params.dimensions, params.fileName, params.fileFormat);
  }
  openChartToolPanel(params) {
    const chartComp = Array.from(this.activeChartComps).find((c) => c.getChartId() === params.chartId);
    chartComp?.openChartToolPanel(params.panel);
  }
  closeChartToolPanel(chartId) {
    const chartComp = Array.from(this.activeChartComps).find((c) => c.getChartId() === chartId);
    chartComp?.closeChartToolPanel();
  }
  createChartFromCurrentRange(chartType = "groupedColumn", fromApi) {
    const cellRange = this.getSelectedRange();
    return this.createChart({ cellRange, chartType, focusDialogOnOpen: !fromApi });
  }
  restoreChart(model, chartContainer) {
    if (!model) {
      _warnOnce14("unable to restore chart as no chart model is provided");
      return;
    }
    if (model.version !== VERSION4) {
      model = upgradeChartModel(model);
    }
    let cellRange;
    let pivotChart;
    let suppressChartRanges;
    let chartPaletteToRestore;
    if (model.modelType === "pivot") {
      this.gos.updateGridOptions({ options: { pivotMode: true }, source: "pivotChart" });
      cellRange = this.createCellRange(void 0, true);
      pivotChart = true;
      suppressChartRanges = true;
    } else {
      cellRange = this.createCellRange(model.cellRange);
      chartPaletteToRestore = model.chartPalette;
      suppressChartRanges = model.suppressChartRanges;
    }
    if (!cellRange) {
      return;
    }
    return this.createChart({
      ...model,
      cellRange,
      pivotChart,
      suppressChartRanges,
      chartContainer,
      chartOptionsToRestore: model.chartOptions,
      chartPaletteToRestore
    });
  }
  createRangeChart(params, fromApi) {
    const cellRange = this.createCellRange(params.cellRange);
    if (!cellRange) {
      return;
    }
    return this.createChart({
      ...params,
      cellRange,
      focusDialogOnOpen: !fromApi
    });
  }
  createPivotChart(params, fromApi) {
    this.gos.updateGridOptions({ options: { pivotMode: true }, source: "pivotChart" });
    const cellRange = this.createCellRange(void 0, true);
    if (!cellRange) {
      return;
    }
    return this.createChart({
      ...params,
      cellRange,
      pivotChart: true,
      suppressChartRanges: true,
      focusDialogOnOpen: !fromApi
    });
  }
  createCrossFilterChart(params, fromApi) {
    const cellRange = this.createCellRange(params.cellRange);
    if (!cellRange) {
      return;
    }
    const suppressChartRangesSupplied = typeof params.suppressChartRanges !== "undefined" && params.suppressChartRanges !== null;
    const suppressChartRanges = suppressChartRangesSupplied ? params.suppressChartRanges : true;
    return this.createChart({
      ...params,
      cellRange,
      suppressChartRanges,
      crossFiltering: true,
      focusDialogOnOpen: !fromApi
    });
  }
  createChart(params) {
    const validationResult = ChartParamsValidator.validateCreateParams(params);
    if (!validationResult) {
      return void 0;
    }
    params = validationResult === true ? params : validationResult;
    const { chartType, chartContainer } = params;
    const createChartContainerFunc = this.gos.getCallback("createChartContainer");
    const gridChartParams = {
      ...params,
      chartId: this.generateId(),
      chartType: getCanonicalChartType(chartType),
      insideDialog: !(chartContainer || createChartContainerFunc),
      crossFilteringContext: this.crossFilteringContext,
      crossFilteringResetCallback: () => this.activeChartComps.forEach((c) => c.crossFilteringReset())
    };
    const chartComp = new GridChartComp(gridChartParams);
    this.createBean(chartComp);
    const chartRef = this.createChartRef(chartComp);
    if (chartContainer) {
      chartContainer.appendChild(chartComp.getGui());
      this.environment.applyThemeClasses(chartContainer);
    } else if (createChartContainerFunc) {
      createChartContainerFunc(chartRef);
    } else {
      chartComp.addEventListener("destroyed", () => {
        this.activeChartComps.delete(chartComp);
        this.activeCharts.delete(chartRef);
      });
    }
    return chartRef;
  }
  createChartRef(chartComp) {
    const chartRef = {
      destroyChart: () => {
        if (this.activeCharts.has(chartRef)) {
          this.destroyBean(chartComp);
          this.activeChartComps.delete(chartComp);
          this.activeCharts.delete(chartRef);
        }
      },
      focusChart: () => {
        this.focusService.focusInto(chartComp.getGui());
      },
      chartElement: chartComp.getGui(),
      chart: chartComp.getUnderlyingChart(),
      chartId: chartComp.getChartModel().chartId
    };
    this.activeCharts.add(chartRef);
    this.activeChartComps.add(chartComp);
    return chartRef;
  }
  getSelectedRange() {
    const ranges = this.rangeService?.getCellRanges() ?? [];
    return ranges.length > 0 ? ranges[0] : { columns: [] };
  }
  generateId() {
    return `id-${Math.random().toString(36).substring(2, 18)}`;
  }
  createCellRange(cellRangeParams, allRange) {
    const rangeParams = allRange ? {
      rowStartIndex: null,
      rowStartPinned: void 0,
      rowEndIndex: null,
      rowEndPinned: void 0,
      columns: this.visibleColsService.getAllCols().map((col) => col.getColId())
    } : cellRangeParams;
    const cellRange = rangeParams && this.rangeService?.createPartialCellRangeFromRangeParams(rangeParams, true);
    if (!cellRange) {
      _warnOnce14(
        `unable to create chart as ${allRange ? "there are no columns in the grid" : "no range is selected"}.`
      );
    }
    return cellRange;
  }
  destroy() {
    this.activeCharts.forEach((chart) => chart.destroyChart());
    super.destroy();
  }
};
ChartService.CHARTS_VERSION = CHARTS_VERSION;
function getChartModels(beans) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.getChartModels());
}
function getChartRef(beans, chartId) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.getChartRef(chartId));
}
function getChartImageDataURL(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.getChartImageDataURL(params));
}
function downloadChart(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.downloadChart(params));
}
function openChartToolPanel(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.openChartToolPanel(params));
}
function closeChartToolPanel(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.closeChartToolPanel(params.chartId));
}
function createRangeChart(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.createRangeChart(params, true));
}
function createPivotChart(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.createPivotChart(params, true));
}
function createCrossFilterChart(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.createCrossFilterChart(params, true));
}
function updateChart(beans, params) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.updateChart(params));
}
function restoreChart(beans, chartModel, chartContainer) {
  return beans.frameworkOverrides.wrapIncoming(() => beans.chartService?.restoreChart(chartModel, chartContainer));
}
var VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION = 28;
var VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION = 6;
function isValidVersion(version) {
  return version && version.match(/\d+\.\d+\.\d+/);
}
function isValidMajorVersion({
  gridMajorVersion,
  chartsMajorVersion
}) {
  const gridMajor = parseInt(gridMajorVersion, 10);
  const chartsMajor = parseInt(chartsMajorVersion, 10);
  const gridMajorDifference = gridMajor - VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION;
  const chartsMajorDifference = chartsMajor - VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION;
  const isFirstOrAfterVersion = gridMajorDifference >= 0;
  return gridMajorDifference === chartsMajorDifference && isFirstOrAfterVersion;
}
function gridChartVersion(gridVersion) {
  if (!gridVersion || !isValidVersion(gridVersion)) {
    return void 0;
  }
  const [gridMajor, gridMinor] = gridVersion.split(".") || [];
  const gridMajorMinor = `${gridMajor}.${gridMinor}.x`;
  const gridMajorNumber = parseInt(gridMajor, 10);
  const chartsMajor = gridMajorNumber - VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION + VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION;
  if (chartsMajor < 0) {
    return void 0;
  }
  const chartsMinor = gridMinor;
  const chartsMajorMinor = `${chartsMajor}.${chartsMinor}.x`;
  return {
    gridMajorMinor,
    chartsMajorMinor
  };
}
function validGridChartsVersionErrorMessage({
  type,
  gridVersion,
  chartsVersion
}) {
  const invalidMessage = "AG Grid: AG Grid version is incompatible. Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.";
  if (!gridVersion) {
    return invalidMessage;
  }
  const version = gridChartVersion(gridVersion);
  if (!version) {
    return invalidMessage;
  }
  const { gridMajorMinor, chartsMajorMinor } = version;
  if (type === "incompatible") {
    return `AG Grid version ${gridVersion} and AG Charts version ${chartsVersion} is not supported. AG Grid version ${gridMajorMinor} should be used with AG Chart ${chartsMajorMinor}. Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.`;
  } else if (type === "invalidCharts") {
    return `AG Grid version ${gridMajorMinor} should be used with AG Chart ${chartsMajorMinor}. Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.`;
  }
  return invalidMessage;
}
function validGridChartsVersion({
  gridVersion,
  chartsVersion
}) {
  if (!isValidVersion(chartsVersion)) {
    return {
      isValid: false,
      message: validGridChartsVersionErrorMessage({ type: "invalidCharts", gridVersion, chartsVersion })
    };
  }
  if (!isValidVersion(gridVersion)) {
    return {
      isValid: false,
      message: validGridChartsVersionErrorMessage({ type: "invalidGrid", gridVersion, chartsVersion })
    };
  }
  const [gridMajor, gridMinor] = gridVersion.split(".") || [];
  const [chartsMajor, chartsMinor, chartsPatch] = chartsVersion.split(".") || [];
  const isValidMajor = isValidMajorVersion({
    gridMajorVersion: gridMajor,
    chartsMajorVersion: chartsMajor
  });
  if (isValidMajor && gridMinor === chartsMinor || chartsPatch.includes("beta")) {
    return {
      isValid: true
    };
  } else if (!isValidMajor || gridMinor !== chartsMinor) {
    return {
      isValid: false,
      message: validGridChartsVersionErrorMessage({ type: "incompatible", gridVersion, chartsVersion })
    };
  }
  return {
    isValid: false,
    message: validGridChartsVersionErrorMessage({ type: "invalid", gridVersion, chartsVersion })
  };
}
var GridChartsCoreModule = _defineModule4({
  version: VERSION4,
  validate: () => {
    return validGridChartsVersion({
      gridVersion: VERSION4,
      chartsVersion: ChartService.CHARTS_VERSION
    });
  },
  moduleName: `${ModuleNames22.GridChartsModule}-core`,
  beans: [
    ChartService,
    ChartTranslationService,
    ChartCrossFilterService,
    ChartMenuListFactory,
    ChartMenuService,
    AdvancedSettingsMenuFactory
  ],
  userComponents: [
    {
      name: "agMenuItem",
      classImp: AgMenuItemRenderer
    }
  ],
  dependantModules: [RangeSelectionModule, EnterpriseCoreModule]
});
var GridChartsApiModule = _defineModule4({
  version: VERSION4,
  moduleName: `${ModuleNames22.GridChartsModule}-api`,
  apiFunctions: {
    getChartModels,
    getChartRef,
    getChartImageDataURL,
    downloadChart,
    openChartToolPanel,
    closeChartToolPanel,
    createRangeChart,
    createPivotChart,
    createCrossFilterChart,
    updateChart,
    restoreChart
  },
  dependantModules: [GridChartsCoreModule]
});
var GridChartsModule = _defineModule4({
  version: VERSION4,
  moduleName: ModuleNames22.GridChartsModule,
  dependantModules: [GridChartsCoreModule, GridChartsApiModule]
});
var agCharts = {
  time,
  AgCharts: AgCharts2
};

// enterprise-modules/clipboard/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames5, _defineModule as _defineModule5 } from "ag-grid-community";
import { CsvExportModule } from "ag-grid-community";
import {
  BeanStub as BeanStub16,
  ChangedPath,
  _exists as _exists10,
  _getActiveDomElement as _getActiveDomElement8,
  _getDocument as _getDocument8,
  _isClientSideRowModel as _isClientSideRowModel3,
  _last as _last6,
  _removeFromArray,
  _warnOnce as _warnOnce15
} from "ag-grid-community";
function copyToClipboard(beans, params) {
  beans.clipboardService?.copyToClipboard(params);
}
function cutToClipboard(beans, params) {
  beans.clipboardService?.cutToClipboard(params);
}
function copySelectedRowsToClipboard(beans, params) {
  beans.clipboardService?.copySelectedRowsToClipboard(params);
}
function copySelectedRangeToClipboard(beans, params) {
  beans.clipboardService?.copySelectedRangeToClipboard(params);
}
function copySelectedRangeDown(beans) {
  beans.clipboardService?.copyRangeDown();
}
function pasteFromClipboard(beans) {
  beans.clipboardService?.pasteFromClipboard();
}
var SOURCE_PASTE = "paste";
var EXPORT_TYPE_DRAG_COPY = "dragCopy";
var EXPORT_TYPE_CLIPBOARD = "clipboard";
var apiError = (method) => `AG Grid: Unable to use the Clipboard API (navigator.clipboard.${method}()). The reason why it could not be used has been logged in the previous line. For this reason the grid has defaulted to using a workaround which doesn't perform as well. Either fix why Clipboard API is blocked, OR stop this message from appearing by setting grid property suppressClipboardApi=true (which will default the grid to using the workaround rather than the API.`;
var ClipboardService = class _ClipboardService extends BeanStub16 {
  constructor() {
    super(...arguments);
    this.beanName = "clipboardService";
    this.lastPasteOperationTime = 0;
    this.navigatorApiFailed = false;
  }
  wireBeans(beans) {
    this.csvCreator = beans.csvCreator;
    this.selectionService = beans.selectionService;
    this.rowModel = beans.rowModel;
    this.ctrlsService = beans.ctrlsService;
    this.valueService = beans.valueService;
    this.focusService = beans.focusService;
    this.rowRenderer = beans.rowRenderer;
    this.visibleColsService = beans.visibleColsService;
    this.funcColsService = beans.funcColsService;
    this.cellNavigationService = beans.cellNavigationService;
    this.cellPositionUtils = beans.cellPositionUtils;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.rangeService = beans.rangeService;
  }
  postConstruct() {
    if (_isClientSideRowModel3(this.gos)) {
      this.clientSideRowModel = this.rowModel;
    }
    this.ctrlsService.whenReady(this, (p) => {
      this.gridCtrl = p.gridCtrl;
    });
  }
  pasteFromClipboard() {
    const allowNavigator = !this.gos.get("suppressClipboardApi");
    if (allowNavigator && !this.navigatorApiFailed && navigator.clipboard && navigator.clipboard.readText) {
      navigator.clipboard.readText().then(this.processClipboardData.bind(this)).catch((e) => {
        _warnOnce15(`${e}
${apiError("readText")}`);
        this.navigatorApiFailed = true;
        this.pasteFromClipboardLegacy();
      });
    } else {
      this.pasteFromClipboardLegacy();
    }
  }
  pasteFromClipboardLegacy() {
    let defaultPrevented = false;
    const handlePasteEvent = (e) => {
      const currentPastOperationTime = (/* @__PURE__ */ new Date()).getTime();
      if (currentPastOperationTime - this.lastPasteOperationTime < 50) {
        defaultPrevented = true;
        e.preventDefault();
      }
      this.lastPasteOperationTime = currentPastOperationTime;
    };
    this.executeOnTempElement(
      (textArea) => {
        textArea.addEventListener("paste", handlePasteEvent);
        textArea.focus({ preventScroll: true });
      },
      (element) => {
        const data = element.value;
        if (!defaultPrevented) {
          this.processClipboardData(data);
        } else {
          this.refocusLastFocusedCell();
        }
        element.removeEventListener("paste", handlePasteEvent);
      }
    );
  }
  refocusLastFocusedCell() {
    const focusedCell = this.focusService.getFocusedCell();
    if (focusedCell) {
      this.focusService.setFocusedCell({
        rowIndex: focusedCell.rowIndex,
        column: focusedCell.column,
        rowPinned: focusedCell.rowPinned,
        forceBrowserFocus: true
      });
    }
  }
  getClipboardDelimiter() {
    const delimiter = this.gos.get("clipboardDelimiter");
    return _exists10(delimiter) ? delimiter : "	";
  }
  processClipboardData(data) {
    if (data == null) {
      return;
    }
    let parsedData = _ClipboardService.stringToArray(data, this.getClipboardDelimiter());
    const userFunc = this.gos.getCallback("processDataFromClipboard");
    if (userFunc) {
      parsedData = userFunc({ data: parsedData });
    }
    if (parsedData == null) {
      return;
    }
    if (this.gos.get("suppressLastEmptyLineOnPaste")) {
      this.removeLastLineIfBlank(parsedData);
    }
    const pasteOperation = (cellsToFlash, updatedRowNodes, focusedCell, changedPath) => {
      const rangeActive = this.rangeService?.isMoreThanOneCell();
      const pasteIntoRange = rangeActive && !this.hasOnlyOneValueToPaste(parsedData);
      if (pasteIntoRange) {
        this.pasteIntoActiveRange(this.rangeService, parsedData, cellsToFlash, updatedRowNodes, changedPath);
      } else {
        this.pasteStartingFromFocusedCell(parsedData, cellsToFlash, updatedRowNodes, focusedCell, changedPath);
      }
    };
    this.doPasteOperation(pasteOperation);
  }
  // This will parse a delimited string into an array of arrays.
  static stringToArray(strData, delimiter = ",") {
    const data = [];
    const isNewline = (char) => char === "\r" || char === "\n";
    let insideQuotedField = false;
    if (strData === "") {
      return [[""]];
    }
    for (let row = 0, column = 0, position = 0; position < strData.length; position++) {
      const previousChar = strData[position - 1];
      const currentChar = strData[position];
      const nextChar = strData[position + 1];
      const ensureDataExists = () => {
        if (!data[row]) {
          data[row] = [];
        }
        if (!data[row][column]) {
          data[row][column] = "";
        }
      };
      ensureDataExists();
      if (currentChar === '"') {
        if (insideQuotedField) {
          if (nextChar === '"') {
            data[row][column] += '"';
            position++;
          } else {
            insideQuotedField = false;
          }
        } else if (previousChar === void 0 || previousChar === delimiter || isNewline(previousChar)) {
          insideQuotedField = true;
        }
      }
      if (!insideQuotedField && currentChar !== '"') {
        if (currentChar === delimiter) {
          column++;
          ensureDataExists();
          continue;
        } else if (isNewline(currentChar)) {
          column = 0;
          row++;
          ensureDataExists();
          if (currentChar === "\r" && nextChar === "\n") {
            position++;
          }
          continue;
        }
      }
      data[row][column] += currentChar;
    }
    return data;
  }
  // common code to paste operations, e.g. paste to cell, paste to range, and copy range down
  doPasteOperation(pasteOperationFunc) {
    const source = "clipboard";
    this.eventService.dispatchEvent({
      type: "pasteStart",
      source
    });
    let changedPath;
    if (this.clientSideRowModel) {
      const onlyChangedColumns = this.gos.get("aggregateOnlyChangedColumns");
      changedPath = new ChangedPath(onlyChangedColumns, this.clientSideRowModel.getRootNode());
    }
    const cellsToFlash = {};
    const updatedRowNodes = [];
    const focusedCell = this.focusService.getFocusedCell();
    pasteOperationFunc(cellsToFlash, updatedRowNodes, focusedCell, changedPath);
    const nodesToRefresh = [...updatedRowNodes];
    if (changedPath) {
      this.clientSideRowModel.doAggregate(changedPath);
      changedPath.forEachChangedNodeDepthFirst((rowNode) => {
        nodesToRefresh.push(rowNode);
      });
    }
    this.rowRenderer.refreshCells({ rowNodes: nodesToRefresh });
    this.dispatchFlashCells(cellsToFlash);
    this.fireRowChanged(updatedRowNodes);
    this.refocusLastFocusedCell();
    this.eventService.dispatchEvent({
      type: "pasteEnd",
      source
    });
  }
  pasteIntoActiveRange(rangeService, clipboardData, cellsToFlash, updatedRowNodes, changedPath) {
    const abortRepeatingPasteIntoRows = this.getRangeSize(rangeService) % clipboardData.length != 0;
    let indexOffset = 0;
    let dataRowIndex = 0;
    const rowCallback = (currentRow, rowNode, columns, index) => {
      const atEndOfClipboardData = index - indexOffset >= clipboardData.length;
      if (atEndOfClipboardData) {
        if (abortRepeatingPasteIntoRows) {
          return;
        }
        indexOffset += dataRowIndex;
        dataRowIndex = 0;
      }
      const currentRowData = clipboardData[index - indexOffset];
      updatedRowNodes.push(rowNode);
      const processCellFromClipboardFunc = this.gos.getCallback("processCellFromClipboard");
      columns.forEach((column, idx) => {
        if (!column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
          return;
        }
        if (idx >= currentRowData.length) {
          idx = idx % currentRowData.length;
        }
        const newValue = this.processCell(
          rowNode,
          column,
          currentRowData[idx],
          EXPORT_TYPE_DRAG_COPY,
          processCellFromClipboardFunc,
          true
        );
        rowNode.setDataValue(column, newValue, SOURCE_PASTE);
        if (changedPath) {
          changedPath.addParentNode(rowNode.parent, [column]);
        }
        const { rowIndex, rowPinned } = currentRow;
        const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
        cellsToFlash[cellId] = true;
      });
      dataRowIndex++;
    };
    this.iterateActiveRanges(false, rowCallback);
  }
  getDisplayedColumnsStartingAt(column) {
    let currentColumn = column;
    const columns = [];
    while (currentColumn != null) {
      columns.push(currentColumn);
      currentColumn = this.visibleColsService.getColAfter(currentColumn);
    }
    return columns;
  }
  pasteStartingFromFocusedCell(parsedData, cellsToFlash, updatedRowNodes, focusedCell, changedPath) {
    if (!focusedCell) {
      return;
    }
    const currentRow = { rowIndex: focusedCell.rowIndex, rowPinned: focusedCell.rowPinned };
    const columnsToPasteInto = this.getDisplayedColumnsStartingAt(focusedCell.column);
    if (this.isPasteSingleValueIntoRange(parsedData)) {
      this.pasteSingleValueIntoRange(parsedData, updatedRowNodes, cellsToFlash, changedPath);
    } else {
      this.pasteMultipleValues(
        parsedData,
        currentRow,
        updatedRowNodes,
        columnsToPasteInto,
        cellsToFlash,
        EXPORT_TYPE_CLIPBOARD,
        changedPath
      );
    }
  }
  // if range is active, and only one cell, then we paste this cell into all cells in the active range.
  isPasteSingleValueIntoRange(parsedData) {
    return this.hasOnlyOneValueToPaste(parsedData) && this.rangeService != null && !this.rangeService.isEmpty();
  }
  pasteSingleValueIntoRange(parsedData, updatedRowNodes, cellsToFlash, changedPath) {
    const value = parsedData[0][0];
    const rowCallback = (currentRow, rowNode, columns) => {
      updatedRowNodes.push(rowNode);
      columns.forEach(
        (column) => this.updateCellValue(rowNode, column, value, cellsToFlash, EXPORT_TYPE_CLIPBOARD, changedPath)
      );
    };
    this.iterateActiveRanges(false, rowCallback);
  }
  hasOnlyOneValueToPaste(parsedData) {
    return parsedData.length === 1 && parsedData[0].length === 1;
  }
  copyRangeDown() {
    if (!this.rangeService || this.rangeService.isEmpty()) {
      return;
    }
    const firstRowValues = [];
    const pasteOperation = (cellsToFlash, updatedRowNodes, focusedCell, changedPath) => {
      const processCellForClipboardFunc = this.gos.getCallback("processCellForClipboard");
      const processCellFromClipboardFunc = this.gos.getCallback("processCellFromClipboard");
      const rowCallback = (currentRow, rowNode, columns) => {
        if (!firstRowValues.length) {
          columns.forEach((column) => {
            const value = this.processCell(
              rowNode,
              column,
              this.valueService.getValue(column, rowNode),
              EXPORT_TYPE_DRAG_COPY,
              processCellForClipboardFunc,
              false,
              true
            );
            firstRowValues.push(value);
          });
        } else {
          updatedRowNodes.push(rowNode);
          columns.forEach((column, index) => {
            if (!column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
              return;
            }
            const firstRowValue = this.processCell(
              rowNode,
              column,
              firstRowValues[index],
              EXPORT_TYPE_DRAG_COPY,
              processCellFromClipboardFunc,
              true
            );
            rowNode.setDataValue(column, firstRowValue, SOURCE_PASTE);
            if (changedPath) {
              changedPath.addParentNode(rowNode.parent, [column]);
            }
            const { rowIndex, rowPinned } = currentRow;
            const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
            cellsToFlash[cellId] = true;
          });
        }
      };
      this.iterateActiveRanges(true, rowCallback);
    };
    this.doPasteOperation(pasteOperation);
  }
  removeLastLineIfBlank(parsedData) {
    const lastLine = _last6(parsedData);
    const lastLineIsBlank = lastLine && lastLine.length === 1 && lastLine[0] === "";
    if (lastLineIsBlank) {
      if (parsedData.length === 1) {
        return;
      }
      _removeFromArray(parsedData, lastLine);
    }
  }
  fireRowChanged(rowNodes) {
    if (this.gos.get("editType") !== "fullRow") {
      return;
    }
    rowNodes.forEach((rowNode) => {
      this.eventService.dispatchEvent({
        type: "rowValueChanged",
        node: rowNode,
        data: rowNode.data,
        rowIndex: rowNode.rowIndex,
        rowPinned: rowNode.rowPinned
      });
    });
  }
  pasteMultipleValues(clipboardGridData, currentRow, updatedRowNodes, columnsToPasteInto, cellsToFlash, type, changedPath) {
    let rowPointer = currentRow;
    const skipGroupRows = this.clientSideRowModel != null && !this.gos.get("enableGroupEdit") && !this.gos.get("treeData");
    const getNextGoodRowNode = () => {
      while (true) {
        if (!rowPointer) {
          return null;
        }
        const res = this.rowPositionUtils.getRowNode(rowPointer);
        rowPointer = this.cellNavigationService.getRowBelow({
          rowPinned: rowPointer.rowPinned,
          rowIndex: rowPointer.rowIndex
        });
        if (res == null) {
          return null;
        }
        const skipRow = res.detail || res.footer || skipGroupRows && res.group;
        if (!skipRow) {
          return res;
        }
      }
    };
    clipboardGridData.forEach((clipboardRowData) => {
      const rowNode = getNextGoodRowNode();
      if (!rowNode) {
        return;
      }
      clipboardRowData.forEach(
        (value, index) => this.updateCellValue(rowNode, columnsToPasteInto[index], value, cellsToFlash, type, changedPath)
      );
      updatedRowNodes.push(rowNode);
    });
  }
  updateCellValue(rowNode, column, value, cellsToFlash, type, changedPath) {
    if (!rowNode || !column || !column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
      return;
    }
    const processedValue = this.processCell(
      rowNode,
      column,
      value,
      type,
      this.gos.getCallback("processCellFromClipboard"),
      true
    );
    rowNode.setDataValue(column, processedValue, SOURCE_PASTE);
    const { rowIndex, rowPinned } = rowNode;
    const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
    cellsToFlash[cellId] = true;
    if (changedPath) {
      changedPath.addParentNode(rowNode.parent, [column]);
    }
  }
  copyToClipboard(params = {}) {
    this.copyOrCutToClipboard(params);
  }
  cutToClipboard(params = {}, source = "api") {
    if (this.gos.get("suppressCutToClipboard")) {
      return;
    }
    this.eventService.dispatchEvent({
      type: "cutStart",
      source
    });
    this.copyOrCutToClipboard(params, true);
    this.eventService.dispatchEvent({
      type: "cutEnd",
      source
    });
  }
  copyOrCutToClipboard(params, cut) {
    let { includeHeaders, includeGroupHeaders } = params;
    if (includeHeaders == null) {
      includeHeaders = this.gos.get("copyHeadersToClipboard");
    }
    if (includeGroupHeaders == null) {
      includeGroupHeaders = this.gos.get("copyGroupHeadersToClipboard");
    }
    const copyParams = { includeHeaders, includeGroupHeaders };
    const rowSelection = this.gos.get("rowSelection");
    const cellSelection = this.gos.get("cellSelection");
    let cellClearType = null;
    if (this.shouldCopyCells(cellSelection, rowSelection)) {
      this.copySelectedRangeToClipboard(copyParams);
      cellClearType = 0;
    } else if (this.shouldCopyRows(rowSelection)) {
      this.copySelectedRowsToClipboard(copyParams);
      cellClearType = 1;
    } else if (this.focusService.isAnyCellFocused()) {
      this.copyFocusedCellToClipboard(copyParams);
      cellClearType = 2;
    }
    if (cut && cellClearType !== null) {
      this.clearCellsAfterCopy(cellClearType);
    }
  }
  shouldCopyCells(cellSelection, rowSelection) {
    if (!this.rangeService || this.rangeService.isEmpty()) {
      return false;
    }
    if (cellSelection) {
      const shouldCopyRowsInstead = typeof rowSelection === "object" && rowSelection.copySelectedRows && !this.selectionService.isEmpty();
      return !shouldCopyRowsInstead;
    } else {
      const suppressCopySingleCellRanges = this.gos.get("suppressCopySingleCellRanges");
      const shouldSkip = !this.rangeService.isMoreThanOneCell() && suppressCopySingleCellRanges;
      return !shouldSkip;
    }
  }
  shouldCopyRows(rowSelection) {
    if (this.selectionService.isEmpty()) {
      return false;
    }
    if (rowSelection && typeof rowSelection !== "string") {
      return rowSelection.copySelectedRows ?? false;
    } else {
      return !this.gos.get("suppressCopyRowsToClipboard");
    }
  }
  clearCellsAfterCopy(type) {
    this.eventService.dispatchEvent({ type: "keyShortcutChangedCellStart" });
    if (type === 0) {
      this.rangeService.clearCellRangeCellValues({ cellEventSource: "clipboardService" });
    } else if (type === 1) {
      this.clearSelectedRows();
    } else {
      const focusedCell = this.focusService.getFocusedCell();
      if (focusedCell == null) {
        return;
      }
      const rowNode = this.rowPositionUtils.getRowNode(focusedCell);
      if (rowNode) {
        this.clearCellValue(rowNode, focusedCell.column);
      }
    }
    this.eventService.dispatchEvent({ type: "keyShortcutChangedCellEnd" });
  }
  clearSelectedRows() {
    const selected = this.selectionService.getSelectedNodes();
    const columns = this.visibleColsService.getAllCols();
    for (const row of selected) {
      for (const col of columns) {
        this.clearCellValue(row, col);
      }
    }
  }
  clearCellValue(rowNode, column) {
    if (!column.isCellEditable(rowNode)) {
      return;
    }
    const emptyValue = this.valueService.getDeleteValue(column, rowNode);
    rowNode.setDataValue(column, emptyValue, "clipboardService");
  }
  iterateActiveRanges(onlyFirst, rowCallback, columnCallback) {
    if (!this.rangeService || this.rangeService.isEmpty()) {
      return;
    }
    const cellRanges = this.rangeService.getCellRanges();
    if (onlyFirst) {
      this.iterateActiveRange(cellRanges[0], rowCallback, columnCallback, true);
    } else {
      cellRanges.forEach(
        (range, idx) => this.iterateActiveRange(range, rowCallback, columnCallback, idx === cellRanges.length - 1)
      );
    }
  }
  iterateActiveRange(range, rowCallback, columnCallback, isLastRange) {
    if (!this.rangeService) {
      return;
    }
    let currentRow = this.rangeService.getRangeStartRow(range);
    const lastRow = this.rangeService.getRangeEndRow(range);
    if (columnCallback && range.columns) {
      columnCallback(range.columns);
    }
    let rangeIndex = 0;
    let isLastRow = false;
    while (!isLastRow && currentRow != null) {
      const rowNode = this.rowPositionUtils.getRowNode(currentRow);
      isLastRow = this.rowPositionUtils.sameRow(currentRow, lastRow);
      rowCallback(currentRow, rowNode, range.columns, rangeIndex++, isLastRow && isLastRange);
      currentRow = this.cellNavigationService.getRowBelow(currentRow);
    }
  }
  copySelectedRangeToClipboard(params = {}) {
    if (!this.rangeService || this.rangeService.isEmpty()) {
      return;
    }
    const allRangesMerge = this.rangeService.areAllRangesAbleToMerge();
    const { data, cellsToFlash } = allRangesMerge ? this.buildDataFromMergedRanges(this.rangeService, params) : this.buildDataFromRanges(this.rangeService, params);
    this.copyDataToClipboard(data);
    this.dispatchFlashCells(cellsToFlash);
  }
  buildDataFromMergedRanges(rangeService, params) {
    const columnsSet = /* @__PURE__ */ new Set();
    const ranges = rangeService.getCellRanges();
    const rowPositionsMap = /* @__PURE__ */ new Map();
    const allRowPositions = [];
    const allCellsToFlash = {};
    ranges.forEach((range) => {
      range.columns.forEach((col) => columnsSet.add(col));
      const { rowPositions, cellsToFlash } = this.getRangeRowPositionsAndCellsToFlash(rangeService, range);
      rowPositions.forEach((rowPosition) => {
        const rowPositionAsString = `${rowPosition.rowIndex}-${rowPosition.rowPinned || "null"}`;
        if (!rowPositionsMap.get(rowPositionAsString)) {
          rowPositionsMap.set(rowPositionAsString, true);
          allRowPositions.push(rowPosition);
        }
      });
      Object.assign(allCellsToFlash, cellsToFlash);
    });
    const allColumns = this.visibleColsService.getAllCols();
    const exportedColumns = Array.from(columnsSet);
    exportedColumns.sort((a, b) => {
      const posA = allColumns.indexOf(a);
      const posB = allColumns.indexOf(b);
      return posA - posB;
    });
    const data = this.buildExportParams({
      columns: exportedColumns,
      rowPositions: allRowPositions,
      includeHeaders: params.includeHeaders,
      includeGroupHeaders: params.includeGroupHeaders
    });
    return { data, cellsToFlash: allCellsToFlash };
  }
  buildDataFromRanges(rangeService, params) {
    const ranges = rangeService.getCellRanges();
    const data = [];
    const allCellsToFlash = {};
    ranges.forEach((range) => {
      const { rowPositions, cellsToFlash } = this.getRangeRowPositionsAndCellsToFlash(rangeService, range);
      Object.assign(allCellsToFlash, cellsToFlash);
      data.push(
        this.buildExportParams({
          columns: range.columns,
          rowPositions,
          includeHeaders: params.includeHeaders,
          includeGroupHeaders: params.includeGroupHeaders
        })
      );
    });
    return { data: data.join("\n"), cellsToFlash: allCellsToFlash };
  }
  getRangeRowPositionsAndCellsToFlash(rangeService, range) {
    const rowPositions = [];
    const cellsToFlash = {};
    const startRow = rangeService.getRangeStartRow(range);
    const lastRow = rangeService.getRangeEndRow(range);
    let node = startRow;
    while (node) {
      rowPositions.push(node);
      range.columns.forEach((column) => {
        const { rowIndex, rowPinned } = node;
        const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
        cellsToFlash[cellId] = true;
      });
      if (this.rowPositionUtils.sameRow(node, lastRow)) {
        break;
      }
      node = this.cellNavigationService.getRowBelow(node);
    }
    return { rowPositions, cellsToFlash };
  }
  getCellsToFlashFromRowNodes(rowNodes) {
    const allDisplayedColumns = this.visibleColsService.getAllCols();
    const cellsToFlash = {};
    for (let i = 0; i < rowNodes.length; i++) {
      const { rowIndex, rowPinned } = rowNodes[i];
      if (rowIndex == null) {
        continue;
      }
      for (let j = 0; j < allDisplayedColumns.length; j++) {
        const column = allDisplayedColumns[j];
        const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
        cellsToFlash[cellId] = true;
      }
    }
    return cellsToFlash;
  }
  copyFocusedCellToClipboard(params = {}) {
    const focusedCell = this.focusService.getFocusedCell();
    if (focusedCell == null) {
      return;
    }
    const cellId = this.cellPositionUtils.createId(focusedCell);
    const currentRow = { rowPinned: focusedCell.rowPinned, rowIndex: focusedCell.rowIndex };
    const column = focusedCell.column;
    const data = this.buildExportParams({
      columns: [column],
      rowPositions: [currentRow],
      includeHeaders: params.includeHeaders,
      includeGroupHeaders: params.includeGroupHeaders
    });
    this.copyDataToClipboard(data);
    this.dispatchFlashCells({ [cellId]: true });
  }
  copySelectedRowsToClipboard(params = {}) {
    const { columnKeys, includeHeaders, includeGroupHeaders } = params;
    const data = this.buildExportParams({
      columns: columnKeys,
      includeHeaders,
      includeGroupHeaders
    });
    this.copyDataToClipboard(data);
    const rowNodes = this.selectionService.getSelectedNodes() || [];
    this.dispatchFlashCells(this.getCellsToFlashFromRowNodes(rowNodes));
  }
  buildExportParams(params) {
    const { columns, rowPositions, includeHeaders = false, includeGroupHeaders = false } = params;
    const exportParams = {
      columnKeys: columns,
      rowPositions,
      skipColumnHeaders: !includeHeaders,
      skipColumnGroupHeaders: !includeGroupHeaders,
      suppressQuotes: true,
      columnSeparator: this.getClipboardDelimiter(),
      onlySelected: !rowPositions,
      processCellCallback: this.gos.getCallback("processCellForClipboard"),
      processRowGroupCallback: (params2) => this.processRowGroupCallback(params2),
      processHeaderCallback: this.gos.getCallback("processHeaderForClipboard"),
      processGroupHeaderCallback: this.gos.getCallback("processGroupHeaderForClipboard")
    };
    return this.csvCreator.getDataAsCsv(exportParams, true);
  }
  processRowGroupCallback(params) {
    const { node, column } = params;
    const isTreeData = this.gos.get("treeData");
    const isSuppressGroupMaintainValueType = this.gos.get("suppressGroupMaintainValueType");
    const getValueFromNode = () => {
      if (isTreeData || isSuppressGroupMaintainValueType || !column) {
        return node.key;
      }
      const value2 = node.groupData?.[column.getId()];
      if (!value2 || !node.rowGroupColumn || node.rowGroupColumn.getColDef().useValueFormatterForExport === false) {
        return value2;
      }
      return this.valueService.formatValue(node.rowGroupColumn, node, value2) ?? value2;
    };
    let value = getValueFromNode();
    if (params.node.footer) {
      let suffix = "";
      if (value && value.length) {
        suffix = ` ${value}`;
      }
      value = `Total${suffix}`;
    }
    const processCellForClipboard = this.gos.getCallback("processCellForClipboard");
    if (processCellForClipboard) {
      let column2 = node.rowGroupColumn;
      if (!column2 && node.footer && node.level === -1) {
        column2 = this.funcColsService.getRowGroupColumns()[0];
      }
      return processCellForClipboard({
        value,
        node,
        column: column2,
        type: "clipboard",
        formatValue: (valueToFormat) => this.valueService.formatValue(column2, node, valueToFormat) ?? valueToFormat,
        parseValue: (valueToParse) => this.valueService.parseValue(column2, node, valueToParse, this.valueService.getValue(column2, node))
      });
    }
    return value;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  dispatchFlashCells(cellsToFlash) {
    window.setTimeout(() => {
      this.eventService.dispatchEvent({
        type: "flashCells",
        cells: cellsToFlash
      });
    }, 0);
  }
  processCell(rowNode, column, value, type, func, canParse, canFormat) {
    if (func) {
      const params = {
        column,
        node: rowNode,
        value,
        type,
        formatValue: (valueToFormat) => this.valueService.formatValue(column, rowNode ?? null, valueToFormat) ?? valueToFormat,
        parseValue: (valueToParse) => this.valueService.parseValue(
          column,
          rowNode ?? null,
          valueToParse,
          this.valueService.getValue(column, rowNode)
        )
      };
      return func(params);
    }
    if (canParse && column.getColDef().useValueParserForImport !== false) {
      return this.valueService.parseValue(
        column,
        rowNode ?? null,
        value,
        this.valueService.getValue(column, rowNode)
      );
    }
    if (canFormat && column.getColDef().useValueFormatterForExport !== false) {
      return this.valueService.formatValue(column, rowNode ?? null, value) ?? value;
    }
    return value;
  }
  copyDataToClipboard(data) {
    const userProvidedFunc = this.gos.getCallback("sendToClipboard");
    if (userProvidedFunc) {
      userProvidedFunc({ data });
      return;
    }
    const allowNavigator = !this.gos.get("suppressClipboardApi");
    if (allowNavigator && navigator.clipboard) {
      navigator.clipboard.writeText(data).catch((e) => {
        _warnOnce15(`${e}
${apiError("writeText")}`);
        this.copyDataToClipboardLegacy(data);
      });
      return;
    }
    this.copyDataToClipboardLegacy(data);
  }
  copyDataToClipboardLegacy(data) {
    this.executeOnTempElement((element) => {
      const eDocument = _getDocument8(this.gos);
      const focusedElementBefore = _getActiveDomElement8(this.gos);
      element.value = data || " ";
      element.select();
      element.focus({ preventScroll: true });
      const result = eDocument.execCommand("copy");
      if (!result) {
        _warnOnce15(
          "Browser did not allow document.execCommand('copy'). Ensure api.copySelectedRowsToClipboard() is invoked via a user event, i.e. button click, otherwise the browser will prevent it for security reasons."
        );
      }
      if (focusedElementBefore != null && focusedElementBefore.focus != null) {
        focusedElementBefore.focus({ preventScroll: true });
      }
    });
  }
  executeOnTempElement(callbackNow, callbackAfter) {
    const eDoc = _getDocument8(this.gos);
    const eTempInput = eDoc.createElement("textarea");
    eTempInput.style.width = "1px";
    eTempInput.style.height = "1px";
    eTempInput.style.top = eDoc.documentElement.scrollTop + "px";
    eTempInput.style.left = eDoc.documentElement.scrollLeft + "px";
    eTempInput.style.position = "absolute";
    eTempInput.style.opacity = "0";
    const guiRoot = this.gridCtrl.getGui();
    guiRoot.appendChild(eTempInput);
    try {
      callbackNow(eTempInput);
    } catch (err) {
      _warnOnce15("Browser does not support document.execCommand('copy') for clipboard operations");
    }
    if (callbackAfter) {
      window.setTimeout(() => {
        callbackAfter(eTempInput);
        guiRoot.removeChild(eTempInput);
      }, 100);
    } else {
      guiRoot.removeChild(eTempInput);
    }
  }
  getRangeSize(rangeService) {
    const ranges = rangeService.getCellRanges();
    let startRangeIndex = 0;
    let endRangeIndex = 0;
    if (ranges.length > 0) {
      startRangeIndex = rangeService.getRangeStartRow(ranges[0]).rowIndex;
      endRangeIndex = rangeService.getRangeEndRow(ranges[0]).rowIndex;
    }
    return startRangeIndex - endRangeIndex + 1;
  }
};
var VERSION5 = "32.3.0";
var ClipboardCoreModule = _defineModule5({
  version: VERSION5,
  moduleName: `${ModuleNames5.ClipboardModule}-core`,
  beans: [ClipboardService],
  dependantModules: [EnterpriseCoreModule, CsvExportModule]
});
var ClipboardApiModule = _defineModule5({
  version: VERSION5,
  moduleName: `${ModuleNames5.ClipboardModule}-api`,
  apiFunctions: {
    copyToClipboard,
    cutToClipboard,
    copySelectedRowsToClipboard,
    copySelectedRangeToClipboard,
    copySelectedRangeDown,
    pasteFromClipboard
  },
  dependantModules: [ClipboardCoreModule]
});
var ClipboardModule = _defineModule5({
  version: VERSION5,
  moduleName: ModuleNames5.ClipboardModule,
  dependantModules: [ClipboardCoreModule, ClipboardApiModule]
});

// enterprise-modules/column-tool-panel/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames23, _defineModule as _defineModule8 } from "ag-grid-community";

// enterprise-modules/row-grouping/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames6, _ColumnFilterModule, _FloatingFilterModule, _defineModule as _defineModule6 } from "ag-grid-community";
import { BeanStub as BeanStub17, _exists as _exists11, _existsAndNotEmpty as _existsAndNotEmpty3, _includes as _includes8, _iterateObject, _last as _last7 } from "ag-grid-community";
import {
  BeanStub as BeanStub25,
  _errorOnce as _errorOnce4,
  _getGrandTotalRow as _getGrandTotalRow2,
  _getGroupAggFiltering,
  _missingOrEmpty as _missingOrEmpty3
} from "ag-grid-community";
import {
  AgColumn,
  BeanStub as BeanStub34,
  GROUP_AUTO_COLUMN_ID as GROUP_AUTO_COLUMN_ID2,
  _isColumnsSortingCoupledToGroup,
  _isGroupMultiAutoColumn,
  _mergeDeep as _mergeDeep2,
  _missing as _missing3,
  _warnOnce as _warnOnce16
} from "ag-grid-community";
import { BeanStub as BeanStub44 } from "ag-grid-community";
import { Component as Component210, _setAriaRole as _setAriaRole7 } from "ag-grid-community";
import { _createIconNoSpan as _createIconNoSpan11 } from "ag-grid-community";
import { DragSourceType as DragSourceType22 } from "ag-grid-community";
import {
  Component as Component41,
  DragSourceType as DragSourceType4,
  KeyCode as KeyCode15,
  RefPlaceholder as RefPlaceholder20,
  SortIndicatorSelector,
  _loadTemplate as _loadTemplate4
} from "ag-grid-community";
import { _createIconNoSpan as _createIconNoSpan24 } from "ag-grid-community";
import { BeanStub as BeanStub54, _getGroupAggFiltering as _getGroupAggFiltering2 } from "ag-grid-community";
import {
  AgPromise as AgPromise4,
  AgSelect as AgSelect9,
  FilterWrapperComp,
  RefPlaceholder as RefPlaceholder24,
  TabGuardComp as TabGuardComp7,
  _clearElement as _clearElement7,
  _loadTemplate as _loadTemplate22,
  _setDisplayed as _setDisplayed9,
  _warnOnce as _warnOnce24
} from "ag-grid-community";
import { AgInputTextField as AgInputTextField3, AgPromise as AgPromise22, Component as Component310, RefPlaceholder as RefPlaceholder34, _clearElement as _clearElement23 } from "ag-grid-community";
import { BeanStub as BeanStub82 } from "ag-grid-community";
import {
  BeanStub as BeanStub63,
  RowNode,
  _areEqual as _areEqual4,
  _exists as _exists24,
  _existsAndNotEmpty as _existsAndNotEmpty22,
  _removeFromArray as _removeFromArray2,
  _warnOnce as _warnOnce33
} from "ag-grid-community";
import { BeanStub as BeanStub73, _warnOnce as _warnOnce43 } from "ag-grid-community";
import { RowNode as RowNode2 } from "ag-grid-community";
import { BeanStub as BeanStub92, _cloneObject as _cloneObject2, _iterateObject as _iterateObject2 } from "ag-grid-community";
import { BeanStub as BeanStub102, _iterateObject as _iterateObject3, _missing as _missing22 } from "ag-grid-community";
import { BeanStub as BeanStub112 } from "ag-grid-community";
import { _createIconNoSpan as _createIconNoSpan33 } from "ag-grid-community";
var defaultAggFuncNames = {
  sum: "Sum",
  first: "First",
  last: "Last",
  min: "Min",
  max: "Max",
  count: "Count",
  avg: "Average"
};
var AggFuncService = class extends BeanStub17 {
  constructor() {
    super(...arguments);
    this.beanName = "aggFuncService";
    this.aggFuncsMap = {};
    this.initialised = false;
  }
  postConstruct() {
    this.init();
  }
  init() {
    if (this.initialised) {
      return;
    }
    this.initialiseWithDefaultAggregations();
    this.addAggFuncs(this.gos.get("aggFuncs"));
  }
  initialiseWithDefaultAggregations() {
    const aggMap = this.aggFuncsMap;
    aggMap["sum"] = aggSum;
    aggMap["first"] = aggFirst;
    aggMap["last"] = aggLast;
    aggMap["min"] = aggMin;
    aggMap["max"] = aggMax;
    aggMap["count"] = aggCount;
    aggMap["avg"] = aggAvg;
    this.initialised = true;
  }
  isAggFuncPossible(column, func) {
    const allKeys = this.getFuncNames(column);
    const allowed = _includes8(allKeys, func);
    const funcExists = _exists11(this.aggFuncsMap[func]);
    return allowed && funcExists;
  }
  getDefaultFuncLabel(fctName) {
    return defaultAggFuncNames[fctName] ?? fctName;
  }
  getDefaultAggFunc(column) {
    const defaultAgg = column.getColDef().defaultAggFunc;
    if (_exists11(defaultAgg) && this.isAggFuncPossible(column, defaultAgg)) {
      return defaultAgg;
    }
    if (this.isAggFuncPossible(column, "sum")) {
      return "sum";
    }
    const allKeys = this.getFuncNames(column);
    return _existsAndNotEmpty3(allKeys) ? allKeys[0] : null;
  }
  addAggFuncs(aggFuncs) {
    this.init();
    _iterateObject(aggFuncs, (key, aggFunc) => {
      this.aggFuncsMap[key] = aggFunc;
    });
  }
  getAggFunc(name) {
    this.init();
    return this.aggFuncsMap[name];
  }
  getFuncNames(column) {
    const userAllowedFuncs = column.getColDef().allowedAggFuncs;
    return userAllowedFuncs == null ? Object.keys(this.aggFuncsMap).sort() : userAllowedFuncs;
  }
  clear() {
    this.aggFuncsMap = {};
  }
};
function aggSum(params) {
  const { values } = params;
  let result = null;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (typeof value === "number") {
      if (result === null) {
        result = value;
      } else {
        result += typeof result === "number" ? value : BigInt(value);
      }
    } else if (typeof value === "bigint") {
      if (result === null) {
        result = value;
      } else {
        result = (typeof result === "bigint" ? result : BigInt(result)) + value;
      }
    }
  }
  return result;
}
function aggFirst(params) {
  return params.values.length > 0 ? params.values[0] : null;
}
function aggLast(params) {
  return params.values.length > 0 ? _last7(params.values) : null;
}
function aggMin(params) {
  const { values } = params;
  let result = null;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if ((typeof value === "number" || typeof value === "bigint") && (result === null || result > value)) {
      result = value;
    }
  }
  return result;
}
function aggMax(params) {
  const { values } = params;
  let result = null;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if ((typeof value === "number" || typeof value === "bigint") && (result === null || result < value)) {
      result = value;
    }
  }
  return result;
}
function aggCount(params) {
  const { values } = params;
  let result = 0;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    result += value != null && typeof value.value === "number" ? value.value : 1;
  }
  const existingAggData = params.rowNode?.aggData?.[params.column.getColId()];
  if (existingAggData && existingAggData.value === result) {
    return existingAggData;
  }
  return {
    value: result,
    toString: function() {
      return this.value.toString();
    },
    // used for sorting
    toNumber: function() {
      return this.value;
    }
  };
}
function aggAvg(params) {
  const { values } = params;
  let sum = 0;
  let count = 0;
  for (let i = 0; i < values.length; i++) {
    const currentValue = values[i];
    let valueToAdd = null;
    if (typeof currentValue === "number" || typeof currentValue === "bigint") {
      valueToAdd = currentValue;
      count++;
    } else if (currentValue != null && (typeof currentValue.value === "number" || typeof currentValue.value === "bigint") && typeof currentValue.count === "number") {
      valueToAdd = currentValue.value * (typeof currentValue.value === "number" ? currentValue.count : BigInt(currentValue.count));
      count += currentValue.count;
    }
    if (typeof valueToAdd === "number") {
      sum += typeof sum === "number" ? valueToAdd : BigInt(valueToAdd);
    } else if (typeof valueToAdd === "bigint") {
      sum = (typeof sum === "bigint" ? sum : BigInt(sum)) + valueToAdd;
    }
  }
  let value = null;
  if (count > 0) {
    value = sum / (typeof sum === "number" ? count : BigInt(count));
  }
  const existingAggData = params.rowNode?.aggData?.[params.column?.getColId()];
  if (existingAggData && existingAggData.count === count && existingAggData.value === value) {
    return existingAggData;
  }
  return {
    count,
    value,
    // the grid by default uses toString to render values for an object, so this
    // is a trick to get the default cellRenderer to display the avg value
    toString: function() {
      return typeof this.value === "number" || typeof this.value === "bigint" ? this.value.toString() : "";
    },
    // used for sorting
    toNumber: function() {
      return this.value;
    }
  };
}
var AggregationStage = class extends BeanStub25 {
  constructor() {
    super(...arguments);
    this.beanName = "aggregationStage";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.aggFuncService = beans.aggFuncService;
    this.funcColsService = beans.funcColsService;
    this.pivotResultColsService = beans.pivotResultColsService;
    this.valueService = beans.valueService;
  }
  // it's possible to recompute the aggregate without doing the other parts
  // + api.refreshClientSideRowModel('aggregate')
  execute(params) {
    const noValueColumns = _missingOrEmpty3(this.funcColsService.getValueColumns());
    const noUserAgg = !this.gos.getCallback("getGroupRowAgg");
    const changedPathActive = params.changedPath && params.changedPath.isActive();
    if (noValueColumns && noUserAgg && changedPathActive) {
      return;
    }
    const aggDetails = this.createAggDetails(params);
    this.recursivelyCreateAggData(aggDetails);
  }
  createAggDetails(params) {
    const pivotActive = this.columnModel.isPivotActive();
    const measureColumns = this.funcColsService.getValueColumns();
    const pivotColumns = pivotActive ? this.funcColsService.getPivotColumns() : [];
    const aggDetails = {
      alwaysAggregateAtRootLevel: this.gos.get("alwaysAggregateAtRootLevel"),
      groupIncludeTotalFooter: !!_getGrandTotalRow2(this.gos),
      changedPath: params.changedPath,
      valueColumns: measureColumns,
      pivotColumns,
      filteredOnly: !this.isSuppressAggFilteredOnly(),
      userAggFunc: this.gos.getCallback("getGroupRowAgg")
    };
    return aggDetails;
  }
  isSuppressAggFilteredOnly() {
    const isGroupAggFiltering = _getGroupAggFiltering(this.gos) !== void 0;
    return isGroupAggFiltering || this.gos.get("suppressAggFilteredOnly");
  }
  recursivelyCreateAggData(aggDetails) {
    const callback = (rowNode) => {
      const hasNoChildren = !rowNode.hasChildren();
      if (hasNoChildren) {
        if (rowNode.aggData) {
          rowNode.setAggData(null);
        }
        return;
      }
      const isRootNode = rowNode.level === -1;
      if (isRootNode && !aggDetails.groupIncludeTotalFooter) {
        const notPivoting = !this.columnModel.isPivotMode();
        if (!aggDetails.alwaysAggregateAtRootLevel && notPivoting) {
          rowNode.setAggData(null);
          return;
        }
      }
      this.aggregateRowNode(rowNode, aggDetails);
    };
    aggDetails.changedPath.forEachChangedNodeDepthFirst(callback, true);
  }
  aggregateRowNode(rowNode, aggDetails) {
    const measureColumnsMissing = aggDetails.valueColumns.length === 0;
    const pivotColumnsMissing = aggDetails.pivotColumns.length === 0;
    let aggResult;
    if (aggDetails.userAggFunc) {
      aggResult = aggDetails.userAggFunc({ nodes: rowNode.childrenAfterFilter });
    } else if (measureColumnsMissing) {
      aggResult = null;
    } else if (pivotColumnsMissing) {
      aggResult = this.aggregateRowNodeUsingValuesOnly(rowNode, aggDetails);
    } else {
      aggResult = this.aggregateRowNodeUsingValuesAndPivot(rowNode);
    }
    rowNode.setAggData(aggResult);
    if (rowNode.sibling) {
      rowNode.sibling.setAggData(aggResult);
    }
  }
  aggregateRowNodeUsingValuesAndPivot(rowNode) {
    const result = {};
    const secondaryColumns = this.pivotResultColsService.getPivotResultCols()?.list ?? [];
    let canSkipTotalColumns = true;
    for (let i = 0; i < secondaryColumns.length; i++) {
      const secondaryCol = secondaryColumns[i];
      const colDef = secondaryCol.getColDef();
      if (colDef.pivotTotalColumnIds != null) {
        canSkipTotalColumns = false;
        continue;
      }
      const keys = colDef.pivotKeys ?? [];
      let values;
      if (rowNode.leafGroup) {
        values = this.getValuesFromMappedSet(rowNode.childrenMapped, keys, colDef.pivotValueColumn);
      } else {
        values = this.getValuesPivotNonLeaf(rowNode, colDef.colId);
      }
      result[colDef.colId] = this.aggregateValues(
        values,
        colDef.pivotValueColumn.getAggFunc(),
        colDef.pivotValueColumn,
        rowNode,
        secondaryCol
      );
    }
    if (!canSkipTotalColumns) {
      for (let i = 0; i < secondaryColumns.length; i++) {
        const secondaryCol = secondaryColumns[i];
        const colDef = secondaryCol.getColDef();
        if (colDef.pivotTotalColumnIds == null || !colDef.pivotTotalColumnIds.length) {
          continue;
        }
        const aggResults = colDef.pivotTotalColumnIds.map(
          (currentColId) => result[currentColId]
        );
        result[colDef.colId] = this.aggregateValues(
          aggResults,
          colDef.pivotValueColumn.getAggFunc(),
          colDef.pivotValueColumn,
          rowNode,
          secondaryCol
        );
      }
    }
    return result;
  }
  aggregateRowNodeUsingValuesOnly(rowNode, aggDetails) {
    const result = {};
    const changedValueColumns = aggDetails.changedPath.isActive() ? aggDetails.changedPath.getValueColumnsForNode(rowNode, aggDetails.valueColumns) : aggDetails.valueColumns;
    const notChangedValueColumns = aggDetails.changedPath.isActive() ? aggDetails.changedPath.getNotValueColumnsForNode(rowNode, aggDetails.valueColumns) : null;
    const values2d = this.getValuesNormal(rowNode, changedValueColumns, aggDetails.filteredOnly);
    const oldValues = rowNode.aggData;
    changedValueColumns.forEach((valueColumn, index) => {
      result[valueColumn.getId()] = this.aggregateValues(
        values2d[index],
        valueColumn.getAggFunc(),
        valueColumn,
        rowNode
      );
    });
    if (notChangedValueColumns && oldValues) {
      notChangedValueColumns.forEach((valueColumn) => {
        result[valueColumn.getId()] = oldValues[valueColumn.getId()];
      });
    }
    return result;
  }
  getValuesPivotNonLeaf(rowNode, colId) {
    return rowNode.childrenAfterFilter.map((childNode) => childNode.aggData[colId]);
  }
  getValuesFromMappedSet(mappedSet, keys, valueColumn) {
    let mapPointer = mappedSet;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      mapPointer = mapPointer ? mapPointer[key] : null;
    }
    if (!mapPointer) {
      return [];
    }
    return mapPointer.map((rowNode) => this.valueService.getValue(valueColumn, rowNode));
  }
  getValuesNormal(rowNode, valueColumns, filteredOnly) {
    const values = [];
    valueColumns.forEach(() => values.push([]));
    const valueColumnCount = valueColumns.length;
    const nodeList = filteredOnly ? rowNode.childrenAfterFilter : rowNode.childrenAfterGroup;
    const rowCount = nodeList.length;
    for (let i = 0; i < rowCount; i++) {
      const childNode = nodeList[i];
      for (let j = 0; j < valueColumnCount; j++) {
        const valueColumn = valueColumns[j];
        const value = this.valueService.getValue(valueColumn, childNode);
        values[j].push(value);
      }
    }
    return values;
  }
  aggregateValues(values, aggFuncOrString, column, rowNode, pivotResultColumn) {
    const aggFunc = typeof aggFuncOrString === "string" ? this.aggFuncService.getAggFunc(aggFuncOrString) : aggFuncOrString;
    if (typeof aggFunc !== "function") {
      _errorOnce4(`unrecognised aggregation function ${aggFuncOrString}`);
      return null;
    }
    const aggFuncAny = aggFunc;
    const params = this.gos.addGridCommonParams({
      values,
      column,
      colDef: column ? column.getColDef() : void 0,
      pivotResultColumn,
      rowNode,
      data: rowNode ? rowNode.data : void 0
    });
    return aggFuncAny(params);
  }
};
var AutoColService = class extends BeanStub34 {
  constructor() {
    super(...arguments);
    this.beanName = "autoColService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.columnFactory = beans.columnFactory;
  }
  createAutoCols(rowGroupCols) {
    const autoCols = [];
    const doingTreeData = this.gos.get("treeData");
    let doingMultiAutoColumn = _isGroupMultiAutoColumn(this.gos);
    if (doingTreeData && doingMultiAutoColumn) {
      _warnOnce16(
        'you cannot mix groupDisplayType = "multipleColumns" with treeData, only one column can be used to display groups when doing tree data'
      );
      doingMultiAutoColumn = false;
    }
    if (doingMultiAutoColumn) {
      rowGroupCols.forEach((rowGroupCol, index) => {
        autoCols.push(this.createOneAutoCol(rowGroupCol, index));
      });
    } else {
      autoCols.push(this.createOneAutoCol());
    }
    return autoCols;
  }
  updateAutoCols(autoGroupCols, source) {
    autoGroupCols.forEach((col, index) => this.updateOneAutoCol(col, index, source));
  }
  // rowGroupCol and index are missing if groupDisplayType != "multipleColumns"
  createOneAutoCol(rowGroupCol, index) {
    let colId;
    if (rowGroupCol) {
      colId = `${GROUP_AUTO_COLUMN_ID2}-${rowGroupCol.getId()}`;
    } else {
      colId = GROUP_AUTO_COLUMN_ID2;
    }
    const colDef = this.createAutoColDef(colId, rowGroupCol, index);
    colDef.colId = colId;
    const newCol = new AgColumn(colDef, null, colId, true);
    this.createBean(newCol);
    return newCol;
  }
  /**
   * Refreshes an auto group col to load changes from defaultColDef or autoGroupColDef
   */
  updateOneAutoCol(colToUpdate, index, source) {
    const oldColDef = colToUpdate.getColDef();
    const underlyingColId = typeof oldColDef.showRowGroup == "string" ? oldColDef.showRowGroup : void 0;
    const underlyingColumn = underlyingColId != null ? this.columnModel.getColDefCol(underlyingColId) : void 0;
    const colDef = this.createAutoColDef(colToUpdate.getId(), underlyingColumn ?? void 0, index);
    colToUpdate.setColDef(colDef, null, source);
    this.columnFactory.applyColumnState(colToUpdate, colDef, source);
  }
  createAutoColDef(colId, underlyingColumn, index) {
    let res = this.createBaseColDef(underlyingColumn);
    const autoGroupColumnDef = this.gos.get("autoGroupColumnDef");
    _mergeDeep2(res, autoGroupColumnDef);
    res = this.columnFactory.addColumnDefaultAndTypes(res, colId);
    if (!this.gos.get("treeData")) {
      const noFieldOrValueGetter = _missing3(res.field) && _missing3(res.valueGetter) && _missing3(res.filterValueGetter) && res.filter !== "agGroupColumnFilter";
      if (noFieldOrValueGetter) {
        res.filter = false;
      }
    }
    if (index && index > 0) {
      res.headerCheckboxSelection = false;
    }
    const isSortingCoupled = _isColumnsSortingCoupledToGroup(this.gos);
    const hasOwnData = res.valueGetter || res.field != null;
    if (isSortingCoupled && !hasOwnData) {
      res.sortIndex = void 0;
      res.initialSort = void 0;
    }
    return res;
  }
  createBaseColDef(rowGroupCol) {
    const userDef = this.gos.get("autoGroupColumnDef");
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const res = {
      headerName: localeTextFunc("group", "Group")
    };
    const userHasProvidedGroupCellRenderer = userDef && (userDef.cellRenderer || userDef.cellRendererSelector);
    if (!userHasProvidedGroupCellRenderer) {
      res.cellRenderer = "agGroupCellRenderer";
    }
    if (rowGroupCol) {
      const colDef = rowGroupCol.getColDef();
      Object.assign(res, {
        headerName: this.columnNameService.getDisplayNameForColumn(rowGroupCol, "header"),
        headerValueGetter: colDef.headerValueGetter
      });
      if (colDef.cellRenderer) {
        Object.assign(res, {
          cellRendererParams: {
            innerRenderer: colDef.cellRenderer,
            innerRendererParams: colDef.cellRendererParams
          }
        });
      }
      res.showRowGroup = rowGroupCol.getColId();
    } else {
      res.showRowGroup = true;
    }
    return res;
  }
};
var DropZoneColumnComp = class extends PillDragComp {
  constructor(column, dragSourceDropTarget, ghost, dropZonePurpose, horizontal) {
    super(
      dragSourceDropTarget,
      ghost,
      horizontal,
      /* html */
      `
                <span role="option">
                    <span data-ref="eDragHandle" class="ag-drag-handle ag-column-drop-cell-drag-handle" role="presentation"></span>
                    <span data-ref="eText" class="ag-column-drop-cell-text" aria-hidden="true"></span>
                    <ag-sort-indicator data-ref="eSortIndicator"></ag-sort-indicator>
                    <span data-ref="eButton" class="ag-column-drop-cell-button" role="presentation"></span>
                </span>
            `,
      [SortIndicatorSelector]
    );
    this.column = column;
    this.dropZonePurpose = dropZonePurpose;
    this.eSortIndicator = RefPlaceholder20;
    this.popupShowing = false;
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.popupService = beans.popupService;
    this.sortController = beans.sortController;
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.funcColsService = beans.funcColsService;
    this.aggFuncService = beans.aggFuncService;
  }
  postConstruct() {
    this.displayName = this.columnNameService.getDisplayNameForColumn(this.column, "columnDrop");
    super.postConstruct();
    this.setupSort();
    this.addManagedEventListeners({
      sortChanged: () => {
        this.setupAria();
      }
    });
    if (this.isGroupingZone()) {
      this.addManagedPropertyListener("groupLockGroupColumns", () => {
        this.refreshRemove();
        this.refreshDraggable();
        this.setupAria();
      });
    }
  }
  getItem() {
    return this.column;
  }
  getDisplayName() {
    return this.displayName;
  }
  getTooltip() {
    return this.column.getColDef().headerTooltip;
  }
  addAdditionalAriaInstructions(ariaInstructions, translate) {
    const isSortSuppressed = this.gos.get("rowGroupPanelSuppressSort");
    const isFunctionsReadOnly = this.gos.get("functionsReadOnly");
    if (this.isAggregationZone() && !isFunctionsReadOnly) {
      const aggregationMenuAria = translate(
        "ariaDropZoneColumnValueItemDescription",
        "Press ENTER to change the aggregation type"
      );
      ariaInstructions.push(aggregationMenuAria);
    }
    if (this.isGroupingZone() && this.column.isSortable() && !isSortSuppressed) {
      const sortProgressAria = translate("ariaDropZoneColumnGroupItemDescription", "Press ENTER to sort");
      ariaInstructions.push(sortProgressAria);
    }
    super.addAdditionalAriaInstructions(ariaInstructions, translate);
  }
  isDraggable() {
    return this.isReadOnly();
  }
  isRemovable() {
    return this.isReadOnly();
  }
  isReadOnly() {
    return !this.isGroupingAndLocked() && !this.gos.get("functionsReadOnly");
  }
  getAriaDisplayName() {
    const translate = this.localeService.getLocaleTextFunc();
    const { name, aggFuncName } = this.getColumnAndAggFuncName();
    const aggSeparator = translate("ariaDropZoneColumnComponentAggFuncSeparator", " of ");
    const sortDirection = {
      asc: translate("ariaDropZoneColumnComponentSortAscending", "ascending"),
      desc: translate("ariaDropZoneColumnComponentSortDescending", "descending")
    };
    const columnSort = this.column.getSort();
    const isSortSuppressed = this.gos.get("rowGroupPanelSuppressSort");
    return [
      aggFuncName && `${aggFuncName}${aggSeparator}`,
      name,
      this.isGroupingZone() && !isSortSuppressed && columnSort && `, ${sortDirection[columnSort]}`
    ].filter((part) => !!part).join("");
  }
  getColumnAndAggFuncName() {
    const name = this.displayName;
    let aggFuncName = "";
    if (this.isAggregationZone()) {
      const aggFunc = this.column.getAggFunc();
      const aggFuncString = typeof aggFunc === "string" ? aggFunc : "agg";
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      aggFuncName = localeTextFunc(aggFuncString, aggFuncString);
    }
    return { name, aggFuncName };
  }
  setupSort() {
    const canSort = this.column.isSortable();
    const isGroupingZone = this.isGroupingZone();
    if (!canSort || !isGroupingZone) {
      return;
    }
    if (!this.gos.get("rowGroupPanelSuppressSort")) {
      this.eSortIndicator.setupSort(this.column, true);
      const performSort = (event) => {
        event.preventDefault();
        const sortUsingCtrl = this.gos.get("multiSortKey") === "ctrl";
        const multiSort = sortUsingCtrl ? event.ctrlKey || event.metaKey : event.shiftKey;
        this.sortController.progressSort(this.column, multiSort, "uiColumnSorted");
      };
      this.addGuiEventListener("click", performSort);
      this.addGuiEventListener("keydown", (e) => {
        const isEnter = e.key === KeyCode15.ENTER;
        if (isEnter && this.isGroupingZone()) {
          performSort(e);
        }
      });
    }
  }
  getDefaultIconName() {
    return "hide";
  }
  createGetDragItem() {
    const { column } = this;
    return () => {
      const visibleState = {};
      visibleState[column.getId()] = column.isVisible();
      return {
        columns: [column],
        visibleState
      };
    };
  }
  setupComponents() {
    super.setupComponents();
    if (this.isAggregationZone() && !this.gos.get("functionsReadOnly")) {
      this.addGuiEventListener("click", this.onShowAggFuncSelection.bind(this));
    }
  }
  onKeyDown(e) {
    super.onKeyDown(e);
    const isEnter = e.key === KeyCode15.ENTER;
    if (isEnter && this.isAggregationZone() && !this.gos.get("functionsReadOnly")) {
      e.preventDefault();
      this.onShowAggFuncSelection();
    }
  }
  getDisplayValue() {
    const { name, aggFuncName } = this.getColumnAndAggFuncName();
    return this.isAggregationZone() ? `${aggFuncName}(${name})` : name;
  }
  onShowAggFuncSelection() {
    if (this.popupShowing) {
      return;
    }
    this.popupShowing = true;
    const virtualList = new VirtualList({ cssIdentifier: "select-agg-func" });
    const rows = this.aggFuncService.getFuncNames(this.column);
    const eGui = this.getGui();
    const virtualListGui = virtualList.getGui();
    virtualList.setModel({
      getRow: function(index) {
        return rows[index];
      },
      getRowCount: function() {
        return rows.length;
      }
    });
    this.createBean(virtualList);
    const ePopup = _loadTemplate4(
      /* html*/
      `<div class="ag-select-agg-func-popup"></div>`
    );
    ePopup.style.top = "0px";
    ePopup.style.left = "0px";
    ePopup.appendChild(virtualListGui);
    ePopup.style.width = `${eGui.clientWidth}px`;
    const [focusoutListener] = this.addManagedElementListeners(ePopup, {
      focusout: (e) => {
        if (!ePopup.contains(e.relatedTarget) && addPopupRes) {
          addPopupRes.hideFunc();
        }
      }
    });
    const popupHiddenFunc = (callbackEvent) => {
      this.destroyBean(virtualList);
      this.popupShowing = false;
      if (callbackEvent?.key === "Escape") {
        eGui.focus();
      }
      if (focusoutListener) {
        focusoutListener();
      }
    };
    const translate = this.localeService.getLocaleTextFunc();
    const addPopupRes = this.popupService.addPopup({
      modal: true,
      eChild: ePopup,
      closeOnEsc: true,
      closedCallback: popupHiddenFunc,
      ariaLabel: translate("ariaLabelAggregationFunction", "Aggregation Function")
    });
    if (addPopupRes) {
      virtualList.setComponentCreator(this.createAggSelect.bind(this, addPopupRes.hideFunc));
    }
    virtualList.addGuiEventListener("keydown", (e) => {
      if (e.key === KeyCode15.ENTER || e.key === KeyCode15.SPACE) {
        const row = virtualList.getLastFocusedRow();
        if (row == null) {
          return;
        }
        const comp = virtualList.getComponentAt(row);
        if (comp) {
          comp.selectItem();
        }
      }
    });
    this.popupService.positionPopupByComponent({
      type: "aggFuncSelect",
      eventSource: eGui,
      ePopup,
      keepWithinBounds: true,
      column: this.column,
      position: "under"
    });
    virtualList.refresh();
    let rowToFocus = rows.findIndex((r) => r === this.column.getAggFunc());
    if (rowToFocus === -1) {
      rowToFocus = 0;
    }
    virtualList.focusRow(rowToFocus);
  }
  createAggSelect(hidePopup, value) {
    const itemSelected = () => {
      hidePopup();
      this.getGui().focus();
      this.funcColsService.setColumnAggFunc(this.column, value, "toolPanelDragAndDrop");
    };
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const aggFuncString = value.toString();
    const aggFuncStringTranslated = localeTextFunc(aggFuncString, aggFuncString);
    const comp = new AggItemComp(itemSelected, aggFuncStringTranslated);
    return comp;
  }
  isGroupingAndLocked() {
    return this.isGroupingZone() && this.columnModel.isColGroupLocked(this.column);
  }
  isAggregationZone() {
    return this.dropZonePurpose === "aggregation";
  }
  isGroupingZone() {
    return this.dropZonePurpose === "rowGroup";
  }
  getDragSourceType() {
    return DragSourceType4.ToolPanel;
  }
  destroy() {
    super.destroy();
    this.column = null;
  }
};
var AggItemComp = class extends Component41 {
  constructor(itemSelected, value) {
    super(
      /* html */
      `<div class="ag-select-agg-func-item"/>`
    );
    this.selectItem = itemSelected;
    this.getGui().innerText = value;
    this.addGuiEventListener("click", this.selectItem);
  }
};
var BaseDropZonePanel = class extends PillDropZonePanel {
  constructor(horizontal, dropZonePurpose) {
    super(horizontal);
    this.dropZonePurpose = dropZonePurpose;
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
  }
  init(params) {
    super.init(params);
    this.addManagedEventListeners({ newColumnsLoaded: this.refreshGui.bind(this) });
    this.addManagedPropertyListeners(
      ["functionsReadOnly", "rowGroupPanelSuppressSort", "groupLockGroupColumns"],
      this.refreshGui.bind(this)
    );
  }
  getItems(dragItem) {
    return dragItem.columns ?? [];
  }
  isInterestedIn(type) {
    return type === DragSourceType22.HeaderCell || type === DragSourceType22.ToolPanel;
  }
  minimumAllowedNewInsertIndex() {
    const numberOfLockedCols = this.gos.get("groupLockGroupColumns");
    const numberOfGroupCols = this.funcColsService.getRowGroupColumns().length;
    if (numberOfLockedCols === -1) {
      return numberOfGroupCols;
    }
    return Math.min(numberOfLockedCols, numberOfGroupCols);
  }
  showOrHideColumnOnExit(draggingEvent) {
    return this.isRowGroupPanel() && !this.gos.get("suppressRowGroupHidesColumns") && !draggingEvent.fromNudge;
  }
  handleDragEnterEnd(draggingEvent) {
    const hideColumnOnExit = this.showOrHideColumnOnExit(draggingEvent);
    if (hideColumnOnExit) {
      const dragItem = draggingEvent.dragSource.getDragItem();
      const columns = dragItem.columns;
      this.setColumnsVisible(columns, false, "uiColumnDragged");
    }
  }
  handleDragLeaveEnd(draggingEvent) {
    const showColumnOnExit = this.showOrHideColumnOnExit(draggingEvent);
    if (showColumnOnExit) {
      const dragItem = draggingEvent.dragSource.getDragItem();
      this.setColumnsVisible(dragItem.columns, true, "uiColumnDragged");
    }
  }
  setColumnsVisible(columns, visible, source) {
    if (columns) {
      const allowedCols = columns.filter((c) => !c.getColDef().lockVisible);
      this.columnModel.setColsVisible(allowedCols, visible, source);
    }
  }
  isRowGroupPanel() {
    return this.dropZonePurpose === "rowGroup";
  }
  createPillComponent(column, dropTarget, ghost, horizontal) {
    return new DropZoneColumnComp(column, dropTarget, ghost, this.dropZonePurpose, horizontal);
  }
};
var PivotDropZonePanel = class extends BaseDropZonePanel {
  constructor(horizontal) {
    super(horizontal, "pivot");
  }
  postConstruct() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const emptyMessage = localeTextFunc("pivotColumnsEmptyMessage", "Drag here to set column labels");
    const title = localeTextFunc("pivots", "Column Labels");
    super.init({
      icon: _createIconNoSpan11("pivotPanel", this.gos, null),
      emptyMessage,
      title
    });
    this.addManagedEventListeners({
      newColumnsLoaded: this.refresh.bind(this),
      columnPivotChanged: this.refresh.bind(this),
      columnPivotModeChanged: this.checkVisibility.bind(this)
    });
    this.refresh();
  }
  getAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const label = translate("ariaPivotDropZonePanelLabel", "Column Labels");
    return label;
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "pivotColumnsList";
    return res;
  }
  refresh() {
    this.checkVisibility();
    this.refreshGui();
  }
  checkVisibility() {
    const pivotMode = this.columnModel.isPivotMode();
    if (this.isHorizontal()) {
      switch (this.gos.get("pivotPanelShow")) {
        case "always":
          this.setDisplayed(pivotMode);
          break;
        case "onlyWhenPivoting": {
          const pivotActive = this.columnModel.isPivotActive();
          this.setDisplayed(pivotMode && pivotActive);
          break;
        }
        default:
          this.setDisplayed(false);
          break;
      }
    } else {
      this.setDisplayed(pivotMode);
    }
  }
  isItemDroppable(column, draggingEvent) {
    if (this.gos.get("functionsReadOnly") || !column.isPrimary()) {
      return false;
    }
    return column.isAllowPivot() && (!column.isPivotActive() || this.isSourceEventFromTarget(draggingEvent));
  }
  updateItems(columns) {
    this.funcColsService.setPivotColumns(columns, "toolPanelUi");
  }
  getIconName() {
    return this.isPotentialDndItems() ? "pivot" : "notAllowed";
  }
  getExistingItems() {
    return this.funcColsService.getPivotColumns();
  }
};
var RowGroupDropZonePanel = class extends BaseDropZonePanel {
  constructor(horizontal) {
    super(horizontal, "rowGroup");
  }
  postConstruct() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const emptyMessage = localeTextFunc("rowGroupColumnsEmptyMessage", "Drag here to set row groups");
    const title = localeTextFunc("groups", "Row Groups");
    super.init({
      icon: _createIconNoSpan24("rowGroupPanel", this.gos, null),
      emptyMessage,
      title
    });
    this.addManagedEventListeners({ columnRowGroupChanged: this.refreshGui.bind(this) });
  }
  getAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const label = translate("ariaRowGroupDropZonePanelLabel", "Row Groups");
    return label;
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "rowGroupColumnsList";
    return res;
  }
  isItemDroppable(column, draggingEvent) {
    if (this.gos.get("functionsReadOnly") || !column.isPrimary()) {
      return false;
    }
    return column.isAllowRowGroup() && (!column.isRowGroupActive() || this.isSourceEventFromTarget(draggingEvent));
  }
  updateItems(columns) {
    this.funcColsService.setRowGroupColumns(columns, "toolPanelUi");
  }
  getIconName() {
    return this.isPotentialDndItems() ? "group" : "notAllowed";
  }
  getExistingItems() {
    return this.funcColsService.getRowGroupColumns();
  }
};
var AgGridHeaderDropZones = class extends Component210 {
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
  }
  constructor() {
    super();
  }
  postConstruct() {
    this.setGui(this.createNorthPanel());
    const onRowGroupChanged = this.onRowGroupChanged.bind(this);
    this.addManagedEventListeners({
      columnRowGroupChanged: onRowGroupChanged,
      newColumnsLoaded: onRowGroupChanged
    });
    this.addManagedPropertyListener("rowGroupPanelShow", onRowGroupChanged);
    this.addManagedPropertyListener("pivotPanelShow", () => this.onPivotPanelShow());
    this.onRowGroupChanged();
  }
  createNorthPanel() {
    const topPanelGui = document.createElement("div");
    topPanelGui.classList.add("ag-column-drop-wrapper");
    _setAriaRole7(topPanelGui, "presentation");
    this.rowGroupComp = new RowGroupDropZonePanel(true);
    this.createManagedBean(this.rowGroupComp);
    this.pivotComp = new PivotDropZonePanel(true);
    this.createManagedBean(this.pivotComp);
    topPanelGui.appendChild(this.rowGroupComp.getGui());
    topPanelGui.appendChild(this.pivotComp.getGui());
    const listener = this.onDropPanelVisible.bind(this);
    this.addManagedListeners(this.rowGroupComp, {
      displayChanged: listener
    });
    this.addManagedListeners(this.pivotComp, {
      displayChanged: listener
    });
    this.onDropPanelVisible();
    return topPanelGui;
  }
  onDropPanelVisible() {
    const bothDisplayed = this.rowGroupComp.isDisplayed() && this.pivotComp.isDisplayed();
    const classStr = "ag-column-drop-horizontal-half-width";
    this.rowGroupComp.addOrRemoveCssClass(classStr, bothDisplayed);
    this.pivotComp.addOrRemoveCssClass(classStr, bothDisplayed);
  }
  onRowGroupChanged() {
    if (!this.rowGroupComp) {
      return;
    }
    const rowGroupPanelShow = this.gos.get("rowGroupPanelShow");
    if (rowGroupPanelShow === "always") {
      this.rowGroupComp.setDisplayed(true);
    } else if (rowGroupPanelShow === "onlyWhenGrouping") {
      const grouping = !this.funcColsService.isRowGroupEmpty();
      this.rowGroupComp.setDisplayed(grouping);
    } else {
      this.rowGroupComp.setDisplayed(false);
    }
  }
  onPivotPanelShow() {
    if (!this.pivotComp) {
      return;
    }
    const pivotPanelShow = this.gos.get("pivotPanelShow");
    if (pivotPanelShow === "always") {
      this.pivotComp.setDisplayed(true);
    } else if (pivotPanelShow === "onlyWhenPivoting") {
      const pivoting = this.columnModel.isPivotActive();
      this.pivotComp.setDisplayed(pivoting);
    } else {
      this.pivotComp.setDisplayed(false);
    }
  }
};
var AgGridHeaderDropZonesSelector = {
  selector: "AG-GRID-HEADER-DROP-ZONES",
  component: AgGridHeaderDropZones
};
var ColumnDropZoneService = class extends BeanStub44 {
  constructor() {
    super(...arguments);
    this.beanName = "columnDropZonesService";
  }
  getDropZoneSelector() {
    return AgGridHeaderDropZonesSelector;
  }
};
var FilterAggregatesStage = class extends BeanStub54 {
  constructor() {
    super(...arguments);
    this.beanName = "filterAggregatesStage";
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.columnModel = beans.columnModel;
  }
  execute(params) {
    const isPivotMode2 = this.columnModel.isPivotMode();
    const isAggFilterActive = this.filterManager?.isAggregateFilterPresent() || this.filterManager?.isAggregateQuickFilterPresent();
    const defaultPrimaryColumnPredicate = (params2) => !params2.node.group;
    const defaultSecondaryColumnPredicate = (params2) => params2.node.leafGroup;
    const applyFilterToNode = _getGroupAggFiltering2(this.gos) || (isPivotMode2 ? defaultSecondaryColumnPredicate : defaultPrimaryColumnPredicate);
    const { changedPath } = params;
    const preserveChildren = (node, recursive = false) => {
      if (node.childrenAfterFilter) {
        node.childrenAfterAggFilter = node.childrenAfterFilter;
        if (recursive) {
          node.childrenAfterAggFilter.forEach((child) => preserveChildren(child, recursive));
        }
        this.setAllChildrenCount(node);
      }
      if (node.sibling) {
        node.sibling.childrenAfterAggFilter = node.childrenAfterAggFilter;
      }
    };
    const filterChildren = (node) => {
      node.childrenAfterAggFilter = node.childrenAfterFilter?.filter((child) => {
        const shouldFilterRow = applyFilterToNode({ node: child });
        if (shouldFilterRow) {
          const doesNodePassFilter = this.filterManager.doesRowPassAggregateFilters({ rowNode: child });
          if (doesNodePassFilter) {
            preserveChildren(child, true);
            return true;
          }
        }
        const hasChildPassed = child.childrenAfterAggFilter?.length;
        return hasChildPassed;
      }) || null;
      this.setAllChildrenCount(node);
      if (node.sibling) {
        node.sibling.childrenAfterAggFilter = node.childrenAfterAggFilter;
      }
    };
    changedPath.forEachChangedNodeDepthFirst(isAggFilterActive ? filterChildren : preserveChildren, true);
  }
  /** for tree data, we include all children, groups and leafs */
  setAllChildrenCountTreeData(rowNode) {
    const childrenAfterAggFilter = rowNode.childrenAfterAggFilter;
    let allChildrenCount = 0;
    if (childrenAfterAggFilter) {
      const length = childrenAfterAggFilter.length;
      allChildrenCount = length;
      for (let i = 0; i < length; ++i) {
        allChildrenCount += childrenAfterAggFilter[i].allChildrenCount ?? 0;
      }
    }
    rowNode.setAllChildrenCount(
      // Maintain the historical behaviour:
      // - allChildrenCount is 0 in the root if there are no children
      // - allChildrenCount is null in any non-root row if there are no children
      allChildrenCount === 0 && rowNode.level >= 0 ? null : allChildrenCount
    );
  }
  /* for grid data, we only count the leafs */
  setAllChildrenCountGridGrouping(rowNode) {
    let allChildrenCount = 0;
    rowNode.childrenAfterAggFilter.forEach((child) => {
      if (child.group) {
        allChildrenCount += child.allChildrenCount;
      } else {
        allChildrenCount++;
      }
    });
    rowNode.setAllChildrenCount(allChildrenCount);
  }
  setAllChildrenCount(rowNode) {
    if (!rowNode.hasChildren()) {
      rowNode.setAllChildrenCount(null);
      return;
    }
    if (this.gos.get("treeData")) {
      this.setAllChildrenCountTreeData(rowNode);
    } else {
      this.setAllChildrenCountGridGrouping(rowNode);
    }
  }
};
var GroupFilter = class extends TabGuardComp7 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-group-filter">
                <div data-ref="eGroupField"></div>
                <div data-ref="eUnderlyingFilter"></div>
            </div>
        `
    );
    this.eGroupField = RefPlaceholder24;
    this.eUnderlyingFilter = RefPlaceholder24;
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.columnNameService = beans.columnNameService;
    this.funcColsService = beans.funcColsService;
  }
  postConstruct() {
    this.initialiseTabGuard({});
  }
  init(params) {
    return this.updateParams(params).then(() => {
      this.addManagedEventListeners({
        columnRowGroupChanged: () => this.onColumnRowGroupChanged(),
        filterDestroyed: (event) => this.onFilterDestroyed(event)
      });
    });
  }
  refresh(params) {
    this.updateParams(params);
    return true;
  }
  updateParams(params) {
    this.params = params;
    this.validateParams();
    return this.updateGroups();
  }
  validateParams() {
    const { colDef } = this.params;
    if (colDef.field) {
      _warnOnce24(
        'Group Column Filter does not work with the colDef property "field". This property will be ignored.'
      );
    }
    if (colDef.filterValueGetter) {
      _warnOnce24(
        'Group Column Filter does not work with the colDef property "filterValueGetter". This property will be ignored.'
      );
    }
    if (colDef.filterParams) {
      _warnOnce24(
        'Group Column Filter does not work with the colDef property "filterParams". This property will be ignored.'
      );
    }
  }
  updateGroups() {
    const sourceColumns = this.updateGroupField();
    return this.getUnderlyingFilters(sourceColumns);
  }
  getSourceColumns() {
    this.groupColumn = this.params.column;
    if (this.gos.get("treeData")) {
      _warnOnce24(
        "Group Column Filter does not work with Tree Data enabled. Please disable Tree Data, or use a different filter."
      );
      return [];
    }
    const sourceColumns = this.funcColsService.getSourceColumnsForGroupColumn(this.groupColumn);
    if (!sourceColumns) {
      _warnOnce24("Group Column Filter only works on group columns. Please use a different filter.");
      return [];
    }
    return sourceColumns;
  }
  updateGroupField() {
    _clearElement7(this.eGroupField);
    if (this.eGroupFieldSelect) {
      this.destroyBean(this.eGroupFieldSelect);
    }
    const allSourceColumns = this.getSourceColumns();
    const sourceColumns = allSourceColumns.filter((sourceColumn) => sourceColumn.isFilterAllowed());
    if (!sourceColumns.length) {
      this.selectedColumn = void 0;
      _setDisplayed9(this.eGroupField, false);
      return null;
    }
    if (allSourceColumns.length === 1) {
      this.selectedColumn = sourceColumns[0];
      _setDisplayed9(this.eGroupField, false);
    } else {
      if (!this.selectedColumn || !sourceColumns.some((column) => column.getId() === this.selectedColumn.getId())) {
        this.selectedColumn = sourceColumns[0];
      }
      this.createGroupFieldSelectElement(sourceColumns);
      this.eGroupField.appendChild(this.eGroupFieldSelect.getGui());
      this.eGroupField.appendChild(_loadTemplate22(
        /* html */
        `<div class="ag-filter-separator"></div>`
      ));
      _setDisplayed9(this.eGroupField, true);
    }
    return sourceColumns;
  }
  createGroupFieldSelectElement(sourceColumns) {
    this.eGroupFieldSelect = this.createManagedBean(new AgSelect9());
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.eGroupFieldSelect.setLabel(localeTextFunc("groupFilterSelect", "Select field:"));
    this.eGroupFieldSelect.setLabelAlignment("top");
    this.eGroupFieldSelect.addOptions(
      sourceColumns.map((sourceColumn) => ({
        value: sourceColumn.getId(),
        text: this.columnNameService.getDisplayNameForColumn(sourceColumn, "groupFilter", false) ?? void 0
      }))
    );
    this.eGroupFieldSelect.setValue(this.selectedColumn.getId());
    this.eGroupFieldSelect.onValueChange((newValue) => this.updateSelectedColumn(newValue));
    this.eGroupFieldSelect.addCssClass("ag-group-filter-field-select-wrapper");
    if (sourceColumns.length === 1) {
      this.eGroupFieldSelect.setDisabled(true);
    }
  }
  getUnderlyingFilters(sourceColumns) {
    if (!sourceColumns) {
      this.filterColumnPairs = void 0;
      this.selectedFilter = void 0;
      this.groupColumn.setFilterActive(false, "columnRowGroupChanged");
      return AgPromise4.resolve();
    }
    const filterPromises = [];
    const filterColumnPairs = [];
    sourceColumns.forEach((column) => {
      const filterWrapper = this.filterManager.getOrCreateFilterWrapper(column);
      if (filterWrapper?.filterPromise) {
        filterPromises.push(
          filterWrapper.filterPromise.then((filter) => {
            if (filter) {
              filterColumnPairs.push({
                filter,
                column
              });
            }
            if (column.getId() === this.selectedColumn.getId()) {
              this.selectedFilter = filter ?? void 0;
            }
            return filter;
          })
        );
      }
    });
    return AgPromise4.all(filterPromises).then(() => {
      this.filterColumnPairs = filterColumnPairs;
      this.groupColumn.setFilterActive(this.isFilterActive(), "columnRowGroupChanged");
    });
  }
  addUnderlyingFilterElement() {
    _clearElement7(this.eUnderlyingFilter);
    if (!this.selectedColumn) {
      return AgPromise4.resolve();
    }
    const comp = this.createManagedBean(new FilterWrapperComp(this.selectedColumn, "COLUMN_MENU"));
    this.filterWrapperComp = comp;
    if (!comp.hasFilter()) {
      return AgPromise4.resolve();
    }
    this.eUnderlyingFilter.appendChild(comp.getGui());
    return comp.getFilter()?.then(() => {
      comp.afterGuiAttached?.(this.afterGuiAttachedParams);
      if (!this.afterGuiAttachedParams?.suppressFocus && this.eGroupFieldSelect && !this.eGroupFieldSelect.isDisabled()) {
        this.eGroupFieldSelect.getFocusableElement().focus();
      }
    }) ?? AgPromise4.resolve();
  }
  updateSelectedColumn(columnId) {
    if (!columnId) {
      return;
    }
    this.filterWrapperComp?.afterGuiDetached();
    this.destroyBean(this.filterWrapperComp);
    const selectedFilterColumnPair = this.getFilterColumnPair(columnId);
    this.selectedColumn = selectedFilterColumnPair?.column;
    this.selectedFilter = selectedFilterColumnPair?.filter;
    this.dispatchLocalEvent({
      type: "selectedColumnChanged"
    });
    this.addUnderlyingFilterElement();
  }
  isFilterActive() {
    return !!this.filterColumnPairs?.some(({ filter }) => filter.isFilterActive());
  }
  doesFilterPass() {
    return true;
  }
  getModel() {
    return null;
  }
  setModel() {
    return AgPromise4.resolve();
  }
  afterGuiAttached(params) {
    this.afterGuiAttachedParams = params;
    this.addUnderlyingFilterElement();
  }
  afterGuiDetached() {
    _clearElement7(this.eUnderlyingFilter);
    this.selectedFilter?.afterGuiDetached?.();
  }
  onColumnRowGroupChanged() {
    this.updateGroups().then(() => {
      this.dispatchLocalEvent({
        type: "columnRowGroupChanged"
      });
    });
  }
  onFilterDestroyed({ column: eventColumn, source }) {
    if (source === "gridDestroyed") {
      return;
    }
    const colId = eventColumn.getColId();
    if (this.filterColumnPairs?.some(({ column }) => column.getColId() === colId)) {
      setTimeout(() => {
        if (this.isAlive()) {
          this.updateGroups();
        }
      });
    }
  }
  getFilterColumnPair(columnId) {
    if (!columnId) {
      return void 0;
    }
    return this.filterColumnPairs?.find(({ column }) => column.getId() === columnId);
  }
  getSelectedFilter() {
    return this.selectedFilter;
  }
  getSelectedColumn() {
    return this.selectedColumn;
  }
  isFilterAllowed() {
    return !!this.selectedColumn;
  }
  destroy() {
    super.destroy();
  }
};
var GroupFloatingFilterComp = class extends Component310 {
  constructor() {
    super(
      /* html */
      `
            <div data-ref="eFloatingFilter" class="ag-group-floating-filter ag-floating-filter-input" role="presentation"></div>
        `
    );
    this.eFloatingFilter = RefPlaceholder34;
    this.haveAddedColumnListeners = false;
  }
  wireBeans(beans) {
    this.columnNameService = beans.columnNameService;
    this.filterManager = beans.filterManager;
  }
  init(params) {
    this.params = params;
    const canShowUnderlyingFloatingFilter = this.gos.get("groupDisplayType") === "multipleColumns";
    return new AgPromise22((resolve) => {
      this.params.parentFilterInstance((parentFilterInstance) => {
        this.parentFilterInstance = parentFilterInstance;
        if (canShowUnderlyingFloatingFilter) {
          this.setupUnderlyingFloatingFilterElement().then(() => resolve());
        } else {
          this.setupReadOnlyFloatingFilterElement();
          resolve();
        }
      });
    }).then(() => {
      this.addManagedListeners(this.parentFilterInstance, {
        selectedColumnChanged: this.onSelectedColumnChanged.bind(this),
        columnRowGroupChanged: this.onColumnRowGroupChanged.bind(this)
      });
    });
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    this.setParams();
  }
  setParams() {
    const displayName = this.columnNameService.getDisplayNameForColumn(
      this.params.column,
      "header",
      true
    );
    const translate = this.localeService.getLocaleTextFunc();
    this.eFloatingFilterText?.setInputAriaLabel(`${displayName} ${translate("ariaFilterInput", "Filter Input")}`);
  }
  setupReadOnlyFloatingFilterElement() {
    if (!this.eFloatingFilterText) {
      this.eFloatingFilterText = this.createManagedBean(new AgInputTextField3());
      this.eFloatingFilterText.setDisabled(true).addGuiEventListener("click", () => this.params.showParentFilter());
      this.setParams();
    }
    this.updateDisplayedValue();
    this.eFloatingFilter.appendChild(this.eFloatingFilterText.getGui());
  }
  setupUnderlyingFloatingFilterElement() {
    this.showingUnderlyingFloatingFilter = false;
    this.underlyingFloatingFilter = void 0;
    _clearElement23(this.eFloatingFilter);
    const column = this.parentFilterInstance.getSelectedColumn();
    if (column && !column.isVisible()) {
      const compDetails = this.filterManager.getFloatingFilterCompDetails(column, this.params.showParentFilter);
      if (compDetails) {
        if (!this.haveAddedColumnListeners) {
          this.haveAddedColumnListeners = true;
          this.addManagedListeners(column, {
            visibleChanged: this.onColumnVisibleChanged.bind(this),
            colDefChanged: this.onColDefChanged.bind(this)
          });
        }
        return compDetails.newAgStackInstance().then((floatingFilter) => {
          this.underlyingFloatingFilter = floatingFilter;
          this.underlyingFloatingFilter?.onParentModelChanged(
            this.parentFilterInstance.getSelectedFilter()?.getModel()
          );
          this.appendChild(floatingFilter.getGui());
          this.showingUnderlyingFloatingFilter = true;
        });
      }
    }
    this.setupReadOnlyFloatingFilterElement();
    return AgPromise22.resolve();
  }
  onColumnVisibleChanged() {
    this.setupUnderlyingFloatingFilterElement();
  }
  onColDefChanged(event) {
    if (!event.column) {
      return;
    }
    const compDetails = this.filterManager.getFloatingFilterCompDetails(
      event.column,
      this.params.showParentFilter
    );
    if (compDetails) {
      if (this.underlyingFloatingFilter?.refresh) {
        this.underlyingFloatingFilter.refresh(compDetails.params);
      } else {
        this.underlyingFloatingFilter?.onParamsUpdated?.(compDetails.params);
      }
    }
  }
  onParentModelChanged(_model, event) {
    if (this.showingUnderlyingFloatingFilter) {
      this.underlyingFloatingFilter?.onParentModelChanged(
        this.parentFilterInstance.getSelectedFilter()?.getModel(),
        event
      );
    } else {
      this.updateDisplayedValue();
    }
  }
  updateDisplayedValue() {
    if (!this.parentFilterInstance || !this.eFloatingFilterText) {
      return;
    }
    const selectedFilter = this.parentFilterInstance.getSelectedFilter();
    if (!selectedFilter) {
      this.eFloatingFilterText.setValue("");
      this.eFloatingFilterText.setDisplayed(false);
      return;
    }
    this.eFloatingFilterText.setDisplayed(true);
    if (selectedFilter.getModelAsString) {
      const filterModel = selectedFilter.getModel();
      this.eFloatingFilterText.setValue(filterModel == null ? "" : selectedFilter.getModelAsString(filterModel));
    } else {
      this.eFloatingFilterText.setValue("");
    }
  }
  onSelectedColumnChanged() {
    if (!this.showingUnderlyingFloatingFilter) {
      this.updateDisplayedValue();
    }
  }
  onColumnRowGroupChanged() {
    if (!this.showingUnderlyingFloatingFilter) {
      this.updateDisplayedValue();
    }
  }
  destroy() {
    super.destroy();
  }
};
var BatchRemover = class {
  constructor() {
    this.allSets = {};
    this.allParents = [];
  }
  removeFromChildrenAfterGroup(parent, child) {
    const set2 = this.getSet(parent);
    set2.removeFromChildrenAfterGroup[child.id] = true;
  }
  isRemoveFromAllLeafChildren(parent, child) {
    const set2 = this.getSet(parent);
    return !!set2.removeFromAllLeafChildren[child.id];
  }
  preventRemoveFromAllLeafChildren(parent, child) {
    const set2 = this.getSet(parent);
    delete set2.removeFromAllLeafChildren[child.id];
  }
  removeFromAllLeafChildren(parent, child) {
    const set2 = this.getSet(parent);
    set2.removeFromAllLeafChildren[child.id] = true;
  }
  getSet(parent) {
    if (!this.allSets[parent.id]) {
      this.allSets[parent.id] = {
        removeFromAllLeafChildren: {},
        removeFromChildrenAfterGroup: {}
      };
      this.allParents.push(parent);
    }
    return this.allSets[parent.id];
  }
  getAllParents() {
    return this.allParents;
  }
  flush() {
    this.allParents.forEach((parent) => {
      const nodeDetails = this.allSets[parent.id];
      parent.childrenAfterGroup = parent.childrenAfterGroup.filter(
        (child) => !nodeDetails.removeFromChildrenAfterGroup[child.id]
      );
      parent.allLeafChildren = parent.allLeafChildren?.filter((child) => !nodeDetails.removeFromAllLeafChildren[child.id]) ?? null;
      parent.updateHasChildren();
      const sibling = parent.sibling;
      if (sibling) {
        sibling.childrenAfterGroup = parent.childrenAfterGroup;
        sibling.allLeafChildren = parent.allLeafChildren;
      }
    });
    this.allSets = {};
    this.allParents.length = 0;
  }
};
function sortGroupChildren(rowNodes) {
  if (!rowNodes) {
    return false;
  }
  const length = rowNodes.length;
  if (length < 2) {
    return false;
  }
  let atLeastOneOutOfOrder = false;
  for (let i = 1; i < length; i++) {
    if (compareGroupChildren(rowNodes[i - 1], rowNodes[i]) > 0) {
      atLeastOneOutOfOrder = true;
      break;
    }
  }
  if (!atLeastOneOutOfOrder) {
    return false;
  }
  rowNodes.sort(compareGroupChildren);
  return true;
}
function compareGroupChildren(nodeA, nodeB) {
  const positionA = nodeA.sourceRowIndex;
  const positionB = nodeB.sourceRowIndex;
  const aHasIndex = positionA >= 0;
  const bHasIndex = positionB >= 0;
  const bothNodesAreUserNodes = aHasIndex && bHasIndex;
  const bothNodesAreFillerNodes = !aHasIndex && !bHasIndex;
  if (bothNodesAreUserNodes) {
    return positionA - positionB;
  }
  if (bothNodesAreFillerNodes) {
    return nodeA.__objectId - nodeB.__objectId;
  }
  if (aHasIndex) {
    return 1;
  }
  return -1;
}
var GroupStrategy = class extends BeanStub63 {
  wireBeans(beans) {
    this.beans = beans;
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
    this.valueService = beans.valueService;
    this.selectionService = beans.selectionService;
    this.showRowGroupColsService = beans.showRowGroupColsService;
  }
  execute(params) {
    const details = this.createGroupingDetails(params);
    if (details.transactions) {
      this.handleTransaction(details);
    } else {
      const afterColsChanged = params.afterColumnsChanged === true;
      this.shotgunResetEverything(details, afterColsChanged);
    }
    this.positionLeafsAndGroups(params.changedPath);
    this.orderGroups(details);
  }
  positionLeafsAndGroups(changedPath) {
    changedPath.forEachChangedNodeDepthFirst((group) => {
      if (group.childrenAfterGroup) {
        const leafNodes = [];
        const groupNodes = [];
        let unbalancedNode;
        group.childrenAfterGroup.forEach((row) => {
          if (!row.childrenAfterGroup?.length) {
            leafNodes.push(row);
          } else {
            if (row.key === "" && !unbalancedNode) {
              unbalancedNode = row;
            } else {
              groupNodes.push(row);
            }
          }
        });
        if (unbalancedNode) {
          groupNodes.push(unbalancedNode);
        }
        group.childrenAfterGroup = [...leafNodes, ...groupNodes];
      }
    }, false);
  }
  createGroupingDetails(params) {
    const { rowNode, changedPath, rowNodeTransactions, rowNodesOrderChanged } = params;
    const groupedCols = this.funcColsService.getRowGroupColumns();
    const details = {
      expandByDefault: this.gos.get("groupDefaultExpanded"),
      groupedCols,
      rootNode: rowNode,
      pivotMode: this.columnModel.isPivotMode(),
      groupedColCount: groupedCols?.length ?? 0,
      transactions: rowNodeTransactions,
      rowNodesOrderChanged: !!rowNodesOrderChanged,
      // if no transaction, then it's shotgun, changed path would be 'not active' at this point anyway
      changedPath,
      groupAllowUnbalanced: this.gos.get("groupAllowUnbalanced"),
      isGroupOpenByDefault: this.gos.getCallback("isGroupOpenByDefault"),
      initialGroupOrderComparator: this.gos.getCallback("initialGroupOrderComparator"),
      suppressGroupMaintainValueType: this.gos.get("suppressGroupMaintainValueType"),
      keyCreators: groupedCols?.map((column) => column.getColDef().keyCreator) ?? []
    };
    return details;
  }
  handleTransaction(details) {
    details.transactions.forEach((tran) => {
      const batchRemover = new BatchRemover();
      if (_existsAndNotEmpty22(tran.remove)) {
        this.removeNodes(tran.remove, details, batchRemover);
      }
      if (_existsAndNotEmpty22(tran.update)) {
        this.moveNodesInWrongPath(tran.update, details, batchRemover);
      }
      if (_existsAndNotEmpty22(tran.add)) {
        this.insertNodes(tran.add, details);
      }
      const parentsWithChildrenRemoved = batchRemover.getAllParents().slice();
      batchRemover.flush();
      this.removeEmptyGroups(parentsWithChildrenRemoved, details);
    });
    if (details.rowNodesOrderChanged) {
      this.sortChildren(details);
    }
  }
  // this is used when doing delta updates, eg Redux, keeps nodes in right order
  sortChildren(details) {
    details.changedPath.forEachChangedNodeDepthFirst(
      (node) => {
        const didSort = sortGroupChildren(node.childrenAfterGroup);
        if (didSort) {
          details.changedPath.addParentNode(node);
        }
      },
      false,
      true
    );
  }
  orderGroups(details) {
    const comparator = details.initialGroupOrderComparator;
    if (_exists24(comparator)) {
      recursiveSort(details.rootNode);
    }
    function recursiveSort(rowNode) {
      const doSort = _exists24(rowNode.childrenAfterGroup) && // we only want to sort groups, so we do not sort leafs (a leaf group has leafs as children)
      !rowNode.leafGroup;
      if (doSort) {
        rowNode.childrenAfterGroup.sort((nodeA, nodeB) => comparator({ nodeA, nodeB }));
        rowNode.childrenAfterGroup.forEach((childNode) => recursiveSort(childNode));
      }
    }
  }
  getExistingPathForNode(node, details) {
    const res = [];
    let pointer = node.parent;
    while (pointer && pointer !== details.rootNode) {
      res.push({
        key: pointer.key,
        rowGroupColumn: pointer.rowGroupColumn,
        field: pointer.field
      });
      pointer = pointer.parent;
    }
    res.reverse();
    return res;
  }
  moveNodesInWrongPath(childNodes, details, batchRemover) {
    childNodes.forEach((childNode) => {
      if (details.changedPath.isActive()) {
        details.changedPath.addParentNode(childNode.parent);
      }
      const infoToKeyMapper = (item) => item.key;
      const oldPath = this.getExistingPathForNode(childNode, details).map(infoToKeyMapper);
      const newPath = this.getGroupInfo(childNode, details).map(infoToKeyMapper);
      const nodeInCorrectPath = _areEqual4(oldPath, newPath);
      if (!nodeInCorrectPath) {
        this.moveNode(childNode, details, batchRemover);
      }
    });
  }
  moveNode(childNode, details, batchRemover) {
    this.removeNodesFromParents([childNode], details, batchRemover);
    this.insertOneNode(childNode, details, batchRemover);
    childNode.setData(childNode.data);
    if (details.changedPath.isActive()) {
      const newParent = childNode.parent;
      details.changedPath.addParentNode(newParent);
    }
  }
  removeNodes(leafRowNodes, details, batchRemover) {
    this.removeNodesFromParents(leafRowNodes, details, batchRemover);
    if (details.changedPath.isActive()) {
      leafRowNodes.forEach((rowNode) => details.changedPath.addParentNode(rowNode.parent));
    }
  }
  forEachParentGroup(details, group, callback) {
    let pointer = group;
    while (pointer && pointer !== details.rootNode) {
      callback(pointer);
      pointer = pointer.parent;
    }
  }
  removeNodesFromParents(nodesToRemove, details, provided) {
    const batchRemoverIsLocal = provided == null;
    const batchRemoverToUse = provided ? provided : new BatchRemover();
    nodesToRemove.forEach((nodeToRemove) => {
      this.removeFromParent(nodeToRemove, batchRemoverToUse);
      this.forEachParentGroup(details, nodeToRemove.parent, (parentNode) => {
        batchRemoverToUse.removeFromAllLeafChildren(parentNode, nodeToRemove);
      });
    });
    if (batchRemoverIsLocal) {
      batchRemoverToUse.flush();
    }
  }
  removeEmptyGroups(possibleEmptyGroups, details) {
    let checkAgain = true;
    const groupShouldBeRemoved = (rowNode) => {
      const mapKey = this.getChildrenMappedKey(rowNode.key, rowNode.rowGroupColumn);
      const parentRowNode = rowNode.parent;
      const groupAlreadyRemoved = parentRowNode?.childrenMapped ? !parentRowNode.childrenMapped[mapKey] : true;
      if (groupAlreadyRemoved) {
        return false;
      }
      return !!rowNode.group && (rowNode.childrenAfterGroup?.length ?? 0) === 0;
    };
    while (checkAgain) {
      checkAgain = false;
      const batchRemover = new BatchRemover();
      possibleEmptyGroups.forEach((possibleEmptyGroup) => {
        this.forEachParentGroup(details, possibleEmptyGroup, (rowNode) => {
          if (groupShouldBeRemoved(rowNode)) {
            checkAgain = true;
            this.removeFromParent(rowNode, batchRemover);
            rowNode.setSelectedParams({ newValue: false, source: "rowGroupChanged" });
          }
        });
      });
      batchRemover.flush();
    }
  }
  // removes the node from the parent by:
  // a) removing from childrenAfterGroup (using batchRemover if present, otherwise immediately)
  // b) removing from childrenMapped (immediately)
  // c) setRowTop(null) - as the rowRenderer uses this to know the RowNode is no longer needed
  // d) setRowIndex(null) - as the rowNode will no longer be displayed.
  removeFromParent(child, batchRemover) {
    if (child.parent) {
      if (batchRemover) {
        batchRemover.removeFromChildrenAfterGroup(child.parent, child);
      } else {
        _removeFromArray2(child.parent.childrenAfterGroup, child);
        child.parent.updateHasChildren();
      }
    }
    const mapKey = this.getChildrenMappedKey(child.key, child.rowGroupColumn);
    if (child.parent?.childrenMapped) {
      delete child.parent.childrenMapped[mapKey];
    }
    child.setRowTop(null);
    child.setRowIndex(null);
  }
  /**
   * This is idempotent, but relies on the `key` field being the same throughout a RowNode's lifetime
   */
  addToParent(child, parent) {
    const mapKey = this.getChildrenMappedKey(child.key, child.rowGroupColumn);
    if (parent?.childrenMapped) {
      if (parent.childrenMapped[mapKey] !== child) {
        parent.childrenMapped[mapKey] = child;
        parent.childrenAfterGroup.push(child);
        parent.setGroup(true);
      }
    }
  }
  areGroupColsEqual(d1, d2) {
    if (d1 == null || d2 == null || d1.pivotMode !== d2.pivotMode) {
      return false;
    }
    return _areEqual4(d1.groupedCols, d2.groupedCols) && _areEqual4(d1.keyCreators, d2.keyCreators);
  }
  checkAllGroupDataAfterColsChanged(details) {
    const recurse = (rowNodes) => {
      if (!rowNodes) {
        return;
      }
      rowNodes.forEach((rowNode) => {
        const isLeafNode = !rowNode.group;
        if (isLeafNode) {
          return;
        }
        const groupInfo = {
          field: rowNode.field,
          key: rowNode.key,
          rowGroupColumn: rowNode.rowGroupColumn,
          leafNode: rowNode.allLeafChildren?.[0]
        };
        this.setGroupData(rowNode, groupInfo, details);
        recurse(rowNode.childrenAfterGroup);
      });
    };
    recurse(details.rootNode.childrenAfterGroup);
  }
  shotgunResetEverything(details, afterColumnsChanged) {
    if (this.noChangeInGroupingColumns(details, afterColumnsChanged)) {
      return;
    }
    this.selectionService.filterFromSelection((node) => node && !node.group);
    const { groupedCols } = details;
    const rootNode = details.rootNode;
    rootNode.leafGroup = groupedCols.length === 0;
    rootNode.childrenAfterGroup = [];
    rootNode.childrenMapped = {};
    rootNode.updateHasChildren();
    const sibling = rootNode.sibling;
    if (sibling) {
      sibling.childrenAfterGroup = rootNode.childrenAfterGroup;
      sibling.childrenMapped = rootNode.childrenMapped;
    }
    this.insertNodes(rootNode.allLeafChildren, details);
  }
  noChangeInGroupingColumns(details, afterColumnsChanged) {
    let noFurtherProcessingNeeded = false;
    const groupDisplayColumns = this.showRowGroupColsService.getShowRowGroupCols();
    const newGroupDisplayColIds = groupDisplayColumns ? groupDisplayColumns.map((c) => c.getId()).join("-") : "";
    if (afterColumnsChanged) {
      noFurtherProcessingNeeded = this.areGroupColsEqual(details, this.oldGroupingDetails);
      if (this.oldGroupDisplayColIds !== newGroupDisplayColIds) {
        this.checkAllGroupDataAfterColsChanged(details);
      }
    }
    this.oldGroupingDetails = details;
    this.oldGroupDisplayColIds = newGroupDisplayColIds;
    return noFurtherProcessingNeeded;
  }
  insertNodes(newRowNodes, details) {
    newRowNodes.forEach((rowNode) => {
      this.insertOneNode(rowNode, details);
      if (details.changedPath.isActive()) {
        details.changedPath.addParentNode(rowNode.parent);
      }
    });
  }
  insertOneNode(childNode, details, batchRemover) {
    const path = this.getGroupInfo(childNode, details);
    const parentGroup = this.findParentForNode(childNode, path, details, batchRemover);
    if (!parentGroup.group) {
      _warnOnce33(`duplicate group keys for row data, keys should be unique`, [parentGroup.data, childNode.data]);
    }
    childNode.parent = parentGroup;
    childNode.level = path.length;
    parentGroup.childrenAfterGroup.push(childNode);
    parentGroup.updateHasChildren();
  }
  findParentForNode(childNode, path, details, batchRemover) {
    let nextNode = details.rootNode;
    path.forEach((groupInfo, level) => {
      nextNode = this.getOrCreateNextNode(nextNode, groupInfo, level, details);
      if (!batchRemover?.isRemoveFromAllLeafChildren(nextNode, childNode)) {
        nextNode.allLeafChildren.push(childNode);
      } else {
        batchRemover?.preventRemoveFromAllLeafChildren(nextNode, childNode);
      }
    });
    return nextNode;
  }
  getOrCreateNextNode(parentGroup, groupInfo, level, details) {
    const key = this.getChildrenMappedKey(groupInfo.key, groupInfo.rowGroupColumn);
    let nextNode = parentGroup?.childrenMapped?.[key];
    if (!nextNode) {
      nextNode = this.createGroup(groupInfo, parentGroup, level, details);
      this.addToParent(nextNode, parentGroup);
    }
    return nextNode;
  }
  createGroup(groupInfo, parent, level, details) {
    const groupNode = new RowNode(this.beans);
    groupNode.group = true;
    groupNode.field = groupInfo.field;
    groupNode.rowGroupColumn = groupInfo.rowGroupColumn;
    this.setGroupData(groupNode, groupInfo, details);
    groupNode.key = groupInfo.key;
    groupNode.id = this.createGroupId(groupNode, parent, level);
    groupNode.level = level;
    groupNode.leafGroup = level === details.groupedColCount - 1;
    groupNode.allLeafChildren = [];
    groupNode.setAllChildrenCount(0);
    groupNode.rowGroupIndex = level;
    groupNode.childrenAfterGroup = [];
    groupNode.childrenMapped = {};
    groupNode.updateHasChildren();
    groupNode.parent = parent;
    this.setExpandedInitialValue(details, groupNode);
    return groupNode;
  }
  createGroupId(node, parent, level) {
    const createGroupId = (node2, parent2) => {
      if (!node2.rowGroupColumn) {
        return null;
      }
      const parentId = parent2 ? createGroupId(parent2, parent2.parent, 0) : null;
      return `${parentId == null ? "" : parentId + "-"}${node2.rowGroupColumn.getColId()}-${node2.key}`;
    };
    return RowNode.ID_PREFIX_ROW_GROUP + createGroupId(node, parent, level);
  }
  setGroupData(groupNode, groupInfo, details) {
    groupNode.groupData = {};
    const groupDisplayCols = this.showRowGroupColsService.getShowRowGroupCols();
    groupDisplayCols.forEach((col) => {
      const groupColumn = groupNode.rowGroupColumn;
      const isRowGroupDisplayed = groupColumn !== null && col.isRowGroupDisplayed(groupColumn.getId());
      if (isRowGroupDisplayed) {
        if (details.suppressGroupMaintainValueType) {
          groupNode.groupData[col.getColId()] = groupInfo.key;
        } else {
          groupNode.groupData[col.getColId()] = this.valueService.getValue(groupColumn, groupInfo.leafNode);
        }
      }
    });
  }
  getChildrenMappedKey(key, rowGroupColumn) {
    return rowGroupColumn ? rowGroupColumn.getId() + "-" + key : key;
  }
  setExpandedInitialValue(details, groupNode) {
    if (details.pivotMode && groupNode.leafGroup) {
      groupNode.expanded = false;
      return;
    }
    const userCallback = details.isGroupOpenByDefault;
    if (userCallback) {
      const params = {
        rowNode: groupNode,
        field: groupNode.field,
        key: groupNode.key,
        level: groupNode.level,
        rowGroupColumn: groupNode.rowGroupColumn
      };
      groupNode.expanded = userCallback(params) == true;
      return;
    }
    if (details.expandByDefault === -1) {
      groupNode.expanded = true;
      return;
    }
    groupNode.expanded = groupNode.level < details.expandByDefault;
  }
  getGroupInfo(rowNode, details) {
    const res = [];
    details.groupedCols.forEach((groupCol) => {
      let key = this.valueService.getKeyForNode(groupCol, rowNode);
      let keyExists = key !== null && key !== void 0 && key !== "";
      const createGroupForEmpty = details.pivotMode || !details.groupAllowUnbalanced;
      if (createGroupForEmpty && !keyExists) {
        key = "";
        keyExists = true;
      }
      if (keyExists) {
        const item = {
          key,
          field: groupCol.getColDef().field,
          rowGroupColumn: groupCol,
          leafNode: rowNode
        };
        res.push(item);
      }
    });
    return res;
  }
};
var treeNodePositionComparer = (a, b) => a.treeNode.oldSourceRowIndex - b.treeNode.oldSourceRowIndex;
var EMPTY_ARRAY = Object.freeze([]);
var EMPTY_CHILDREN = EMPTY_ARRAY.values();
var orphanRow = (row, root) => {
  row.parent = null;
  row.treeNode = null;
  if (root) {
    row.childrenAfterGroup = [];
  } else {
    row.level = 0;
    row.childrenAfterGroup = null;
    row.allLeafChildren = null;
  }
};
var TreeNode = class _TreeNode {
  constructor(parent, key, level) {
    this.parent = parent;
    this.key = key;
    this.level = level;
    this.children = null;
    this.invalidatedHead = null;
    this.invalidatedNext = void 0;
    this.row = null;
    this.oldRow = null;
    this.duplicateRows = null;
    this.childrenAfterGroup = EMPTY_ARRAY;
    this.allLeafChildren = EMPTY_ARRAY;
    this.childrenChanged = false;
    this.leafChildrenChanged = false;
    this.oldSourceRowIndex = -1;
  }
  isEmptyFillerNode() {
    return !this.row?.data && !this.children?.size;
  }
  /** Returns true if this tree node has children */
  hasChildren() {
    return !!this.children?.size;
  }
  /** Returns an iterator able to iterate all children in this node, in order of insertion */
  enumChildren() {
    return this.children?.values() ?? EMPTY_CHILDREN;
  }
  /**
   * Gets a node a key in the given parent. If the node does not exists, creates a filler node, with null row.
   * We cast to string just to be sure the user passed a string correctly and not a number or something else.
   * @returns the node at the given key, or a new filler node inserted there if it does not exist.
   */
  upsertKey(key) {
    if (typeof key !== "string") {
      key = String(key);
    }
    let node = this.children?.get(key);
    if (!node) {
      node = new _TreeNode(this, key, this.level + 1);
      (this.children ?? (this.children = /* @__PURE__ */ new Map()))?.set(node.key, node);
    }
    return node;
  }
  /** Removes this node from the parent, and free memory. This node cannot be used after this. */
  destroy() {
    const { row, parent } = this;
    if (parent === null) {
      return;
    }
    parent?.children?.delete(this.key);
    if (row !== null) {
      orphanRow(row, true);
    }
    this.parent = null;
  }
  /**
   * Sets the row for the TreeNode.
   * If the row is already set, it will be replaced with the new row, and the old row will be orphaned.
   * childrenAfterGroup and allLeafChildren will be reassigned.
   * @returns True if the row changed
   */
  setRow(newRow) {
    const { level, row: oldRow, childrenAfterGroup } = this;
    if (level < 0) {
      if (oldRow !== null && oldRow !== newRow) {
        orphanRow(oldRow, true);
      }
    } else {
      if (oldRow === newRow) {
        return false;
      }
      if (oldRow !== null) {
        newRow.allLeafChildren = oldRow.allLeafChildren ?? this.allLeafChildren ?? EMPTY_ARRAY;
        orphanRow(oldRow, false);
      } else {
        newRow.allLeafChildren = this.allLeafChildren ?? EMPTY_ARRAY;
      }
    }
    newRow.level = level;
    newRow.childrenAfterGroup = childrenAfterGroup;
    newRow.treeNode = this;
    this.row = newRow;
    return true;
  }
  /**
   * Removes a row from the tree node.
   * If the row is the main row, it will be replaced with the first row in the duplicate rows, if any.
   * If the row is a duplicate row, it will be removed from the duplicate rows.
   * @param rowToRemove - The row to be removed.
   * @returns `true` if the row was successfully removed, `false` if the row was not found.
   */
  removeRow(rowToRemove) {
    const { level, row, duplicateRows, childrenAfterGroup } = this;
    if (row === rowToRemove) {
      const duplicate = this.popDuplicateRow();
      if (duplicate) {
        this.row = duplicate;
        duplicate.childrenAfterGroup = childrenAfterGroup;
        if (level >= 0) {
          duplicate.allLeafChildren = row.allLeafChildren ?? this.allLeafChildren ?? EMPTY_ARRAY;
        }
      } else {
        this.row = null;
      }
    } else {
      if (!duplicateRows?.delete(rowToRemove)) {
        return false;
      }
      if (duplicateRows.size === 0) {
        this.duplicateRows = null;
      }
    }
    orphanRow(rowToRemove, level < 0);
    return true;
  }
  /**
   * Adds a duplicate row to the tree node.
   * @param newRow - The new row to be added.
   * @returns A boolean indicating whether the row was successfully added.
   */
  addDuplicateRow(newRow) {
    const { level } = this;
    let duplicateRows = this.duplicateRows;
    if (duplicateRows === null) {
      duplicateRows = /* @__PURE__ */ new Set();
      this.duplicateRows = duplicateRows;
    } else if (duplicateRows.has(newRow)) {
      return false;
    }
    duplicateRows.add(newRow);
    newRow.treeNode = this;
    newRow.level = level;
    if (level >= 0) {
      newRow.allLeafChildren = EMPTY_ARRAY;
    }
    newRow.childrenAfterGroup = EMPTY_ARRAY;
    return true;
  }
  /**
   * This is needed to be sure that the row is the duplicate row with the smallest sourceRowIndex, in O(n).
   * @returns this.row
   */
  sortFirstDuplicateRow() {
    const duplicateRows = this.duplicateRows;
    const oldRow = this.row;
    if (!oldRow || !duplicateRows) {
      return oldRow;
    }
    let newRow = oldRow;
    for (const row of duplicateRows) {
      if (row.sourceRowIndex < newRow.sourceRowIndex) {
        newRow = row;
      }
    }
    if (newRow !== oldRow) {
      newRow.childrenAfterGroup = this.childrenAfterGroup;
      newRow.allLeafChildren = oldRow.allLeafChildren ?? this.allLeafChildren ?? EMPTY_ARRAY;
      oldRow.childrenAfterGroup = EMPTY_ARRAY;
      oldRow.allLeafChildren = EMPTY_ARRAY;
      duplicateRows.delete(newRow);
      duplicateRows.add(oldRow);
      this.row = newRow;
    }
    return newRow;
  }
  /** Pops the first duplicate row from the list of duplicates */
  popDuplicateRow() {
    let result = null;
    const duplicateRows = this.duplicateRows;
    if (duplicateRows !== null) {
      result = duplicateRows.values().next().value;
      if (result !== null && duplicateRows.delete(result) && duplicateRows.size === 0) {
        this.duplicateRows = null;
      }
    }
    return result;
  }
  /**
   * Dequeues the next child invalidated node to be committed. Order is not deterministic.
   * @returns the next child node to be committed, or null if all children were already dequeued.
   */
  dequeueInvalidated() {
    const node = this.invalidatedHead;
    if (node !== null) {
      this.invalidatedHead = node.invalidatedNext ?? null;
      node.invalidatedNext = void 0;
    }
    return node;
  }
  /**
   * Invalidates this node and all its parents until the root is reached.
   * Order of invalidated nodes is not deterministic.
   * The root itself cannot be invalidated, as it has no parents.
   * If a node is already invalidated, it will stop the recursion.
   */
  invalidate() {
    let node = this;
    let parent = this.parent;
    while (parent !== null && node.invalidatedNext === void 0) {
      node.invalidatedNext = parent.invalidatedHead;
      parent.invalidatedHead = node;
      node = parent;
      parent = node.parent;
    }
  }
  /** Marks childrenChanged in the parent, so the childrenAfterGroup will be recomputed and invalidates the parent. */
  invalidateOrder() {
    const parent = this.parent;
    if (parent !== null && !parent.childrenChanged && ((this.children?.size ?? 0) > 1 || !parent.row?.data)) {
      parent.childrenChanged = true;
      parent.invalidate();
    }
  }
  /**
   * When we receive rowNodeOrder not undefined, we need to update the rowPosition of the node,
   * to ensure it will be sorted in the right order in childrenAfterGroup.
   * This function makes sense to be called only in the post-order commit DFS
   * as it assumes children's childrenAfterGroup is already updated.
   * @returns the rowPosition the node should have.
   */
  getRowPosition() {
    const row = this.row;
    if (row?.data) {
      return row.sourceRowIndex;
    }
    return this.childrenAfterGroup[0]?.treeNode?.oldSourceRowIndex ?? this.oldSourceRowIndex;
  }
  /**
   * This is called in post order during commit to update the childrenAfterGroup array.
   * It uses the rowNodeOrder map to sort the children in the right order, if is passed.
   * It assumes all children childrenAfterGroup are up to date and rows all created.
   *
   * It replaces the array with EMPTY_ARRAY if there are no children, to reduce memory usage and GC pressure.
   * It does sort the children only if strictly needed, to avoid unnecessary work.
   *
   * If the order changes, also the order in the children map will be updated,
   * so the next call to enumChildren() will return the children in the right order.
   */
  updateChildrenAfterGroup() {
    this.childrenChanged = false;
    const childrenCount = this.children?.size ?? 0;
    if (childrenCount === 0) {
      if (this.childrenAfterGroup.length === 0) {
        return false;
      }
      this.leafChildrenChanged = true;
      this.childrenAfterGroup = EMPTY_ARRAY;
      this.row.childrenAfterGroup = EMPTY_ARRAY;
      return true;
    }
    let nodesChanged = false;
    let childrenAfterGroup = this.childrenAfterGroup;
    if (childrenAfterGroup === EMPTY_ARRAY) {
      childrenAfterGroup = new Array(childrenCount);
      this.childrenAfterGroup = childrenAfterGroup;
      this.row.childrenAfterGroup = childrenAfterGroup;
      nodesChanged = true;
    } else if (childrenAfterGroup.length !== childrenCount) {
      childrenAfterGroup.length = childrenCount;
      nodesChanged = true;
    }
    let index = 0;
    let prevPosition = -1;
    let needSort = false;
    for (const child of this.enumChildren()) {
      const nextPosition = child.getRowPosition();
      if (nextPosition < prevPosition) {
        needSort = true;
      }
      prevPosition = nextPosition;
      child.oldSourceRowIndex = nextPosition;
      const row = child.row;
      if (childrenAfterGroup[index] !== row) {
        childrenAfterGroup[index] = row;
        nodesChanged = true;
      }
      ++index;
    }
    if (nodesChanged) {
      this.leafChildrenChanged = true;
    }
    if (needSort) {
      this.reorderChildrenList(childrenAfterGroup);
    }
    return nodesChanged || needSort;
  }
  /** This reorders the given array and rebuild the children map. */
  reorderChildrenList(childrenAfterGroup) {
    const childrenCount = childrenAfterGroup.length;
    const children = this.children;
    childrenAfterGroup.sort(treeNodePositionComparer);
    children.clear();
    for (let i = 0; i < childrenCount; ++i) {
      const node = childrenAfterGroup[i].treeNode;
      children.set(node.key, node);
    }
  }
  /**
   * Rebuild the allLeafChildren rows array of a node. It uses childrenAfterGroup, we assume to be already updated.
   * This is called in post order during commit, after the childrenAfterGroup are updated with updateChildrenAfterGroup().
   * It uses the childrenAfterGroup and allLeafChildren of all the children, we assume they are updated.
   */
  updateAllLeafChildren() {
    const { parent, row, childrenAfterGroup } = this;
    this.leafChildrenChanged = false;
    let nodesChanged = false;
    const childrenAfterGroupLen = childrenAfterGroup.length;
    if (childrenAfterGroupLen === 0) {
      nodesChanged = row.allLeafChildren?.length !== 0;
      row.allLeafChildren = EMPTY_ARRAY;
      this.allLeafChildren = EMPTY_ARRAY;
    } else if (childrenAfterGroupLen === 1 && childrenAfterGroup[0].allLeafChildren?.length) {
      row.allLeafChildren = childrenAfterGroup[0].allLeafChildren;
      this.allLeafChildren = null;
      nodesChanged = true;
    } else {
      let allLeafChildren = this.allLeafChildren;
      if (allLeafChildren === EMPTY_ARRAY || allLeafChildren === null) {
        allLeafChildren = [];
        this.allLeafChildren = allLeafChildren;
      }
      const oldAllLeafChildrenLength = allLeafChildren.length;
      let writeIdx = 0;
      for (let i = 0; i < childrenAfterGroupLen; ++i) {
        const childRow = childrenAfterGroup[i];
        const childAllLeafChildren = childRow.allLeafChildren;
        const childAllLeafChildrenLen = childAllLeafChildren.length;
        if (childAllLeafChildrenLen) {
          for (let j = 0; j < childAllLeafChildrenLen; ++j) {
            const leaf = childAllLeafChildren[j];
            if (writeIdx >= oldAllLeafChildrenLength || allLeafChildren[writeIdx] !== leaf) {
              allLeafChildren[writeIdx] = leaf;
              nodesChanged = true;
            }
            ++writeIdx;
          }
        } else {
          if ((writeIdx >= oldAllLeafChildrenLength || allLeafChildren[writeIdx] !== childRow) && childRow) {
            allLeafChildren[writeIdx] = childRow;
            nodesChanged = true;
          }
          ++writeIdx;
        }
      }
      if (oldAllLeafChildrenLength !== writeIdx) {
        allLeafChildren.length = writeIdx;
        nodesChanged = true;
      }
      if (row.allLeafChildren !== allLeafChildren) {
        row.allLeafChildren = allLeafChildren;
        nodesChanged = true;
      }
    }
    if (nodesChanged && parent) {
      parent.leafChildrenChanged = true;
    }
  }
};
var isTreeRowCommitted = (row) => (row.treeNodeFlags & 1) !== 0;
var isTreeRowExpandedInitialized = (row) => (row.treeNodeFlags & 2) !== 0;
var isTreeRowUpdated = (row) => (row.treeNodeFlags & 4) !== 0;
var isTreeRowKeyChanged = (row) => (row.treeNodeFlags & 8) !== 0;
var isTreeRowPathChanged = (row) => (row.treeNodeFlags & 16) !== 0;
var setTreeRowExpandedInitialized = (row, value) => {
  if (value) {
    row.treeNodeFlags |= 2;
  } else {
    row.treeNodeFlags &= ~2;
  }
};
var setTreeRowUpdated = (row) => {
  const flags = row.treeNodeFlags;
  if ((flags & 1) !== 0) {
    row.treeNodeFlags = flags | 4;
  }
};
var setTreeRowKeyChanged = (row) => {
  const flags = row.treeNodeFlags;
  if ((flags & 1) !== 0) {
    row.treeNodeFlags = flags | (8 | 4);
  }
};
var markTreeRowPathChanged = (row) => {
  row.treeNodeFlags |= 16;
};
var markTreeRowCommitted = (row) => {
  row.treeNodeFlags = 1 | row.treeNodeFlags & ~(4 | 8 | 16);
};
var clearTreeRowFlags = (row) => {
  row.treeNodeFlags = 0;
};
var TreeStrategy = class extends BeanStub73 {
  constructor() {
    super(...arguments);
    this.rowsPendingDestruction = null;
    this.root = new TreeNode(null, "", -1);
  }
  wireBeans(beans) {
    this.beans = beans;
    this.showRowGroupColsService = beans.showRowGroupColsService;
  }
  destroy() {
    const rootRow = this.root.row;
    if (rootRow !== null) {
      this.root.removeRow(rootRow);
      clearTreeRowFlags(rootRow);
    }
    this.destroyTree(this.root);
    this.commitDestroyedRows();
    super.destroy();
  }
  execute(params) {
    const { rowNodeTransactions, rowNodesOrderChanged, changedPath } = params;
    const rootRow = params.rowNode;
    const gos = this.gos;
    const details = {
      changedPath,
      expandByDefault: gos.get("groupDefaultExpanded"),
      suppressGroupMaintainValueType: gos.get("suppressGroupMaintainValueType"),
      getDataPath: gos.get("getDataPath"),
      isGroupOpenByDefault: gos.getCallback("isGroupOpenByDefault"),
      initialGroupOrderComparator: gos.getCallback("initialGroupOrderComparator")
    };
    const rootNode = this.root;
    rootNode.setRow(rootRow);
    rootRow.leafGroup = false;
    const sibling = rootRow.sibling;
    if (sibling) {
      sibling.childrenAfterGroup = rootRow.childrenAfterGroup;
      sibling.childrenMapped = rootRow.childrenMapped;
    }
    if (rowNodeTransactions) {
      this.handleTransaction(details, rowNodeTransactions, rowNodesOrderChanged);
    } else {
      this.handleRowData(details, rootRow, params.afterColumnsChanged === true);
    }
  }
  handleRowData(details, rootRow, afterColumnsChanged) {
    const root = this.root;
    if (afterColumnsChanged || this.oldGroupDisplayColIds === void 0) {
      const newGroupDisplayColIds = this.showRowGroupColsService?.getShowRowGroupCols()?.map((c) => c.getId()).join("-") ?? "";
      if (afterColumnsChanged) {
        if (this.oldGroupDisplayColIds !== newGroupDisplayColIds) {
          this.checkAllGroupDataAfterColsChanged(root.row?.childrenAfterGroup);
        }
        return;
      }
      this.oldGroupDisplayColIds = newGroupDisplayColIds;
    }
    this.clearTree(root);
    this.addOrUpdateRows(details, rootRow.allLeafChildren, false);
    this.commitTree(details);
  }
  handleTransaction(details, transactions, rowNodesOrderChanged) {
    for (const { remove, update, add } of transactions) {
      this.removeRows(remove);
      this.addOrUpdateRows(details, update, true);
      this.addOrUpdateRows(details, add, false);
    }
    if (rowNodesOrderChanged) {
      this.handleRowNodesOrderChanged();
    }
    this.commitTree(details);
  }
  handleRowNodesOrderChanged() {
    const rows = this.root.row?.allLeafChildren;
    if (rows) {
      for (let rowIdx = 0, rowsLen = rows.length; rowIdx < rowsLen; ++rowIdx) {
        const node = rows[rowIdx].treeNode;
        if (node && node.oldSourceRowIndex !== rowIdx) {
          node.invalidateOrder();
        }
      }
    }
  }
  checkAllGroupDataAfterColsChanged(rowNodes) {
    for (let i = 0, len = rowNodes?.length ?? 0; i < len; ++i) {
      const rowNode = rowNodes[i];
      this.setGroupData(rowNode, rowNode.treeNode?.key ?? rowNode.key);
      this.checkAllGroupDataAfterColsChanged(rowNode.childrenAfterGroup);
    }
  }
  /** Transactional add/update */
  addOrUpdateRows(details, rows, update) {
    for (let i = 0, len = rows?.length ?? 0; i < len; ++i) {
      const row = rows[i];
      const node = this.upsertPath(this.getDataPath(details, row));
      if (node) {
        this.addOrUpdateRow(node, row, update);
      }
    }
  }
  /** Transactional removal */
  removeRows(rows) {
    for (let i = 0, len = rows?.length ?? 0; i < len; ++i) {
      const row = rows[i];
      const node = row.treeNode;
      if (node !== null) {
        this.removeRow(node, row);
      }
    }
  }
  getDataPath({ getDataPath }, { data }) {
    const keys = getDataPath?.(data) || EMPTY_ARRAY;
    if (!keys.length) {
      _warnOnce43(`getDataPath() should not return an empty path`, [data]);
    }
    return keys;
  }
  /**
   * Gets the last node of a path. Inserts filler nodes where needed.
   * Note that invalidate() is not called, is up to the caller to call it if needed.
   */
  upsertPath(path) {
    let parent = this.root;
    const stop = path.length - 1;
    for (let level = 0; level <= stop; ++level) {
      const node = parent.upsertKey(path[level]);
      if (level >= stop) {
        node.invalidate();
        return node;
      }
      parent = node;
    }
    return null;
  }
  /** Add or updates the row to a non-root node, preparing the tree correctly for the commit. */
  addOrUpdateRow(node, newRow, update) {
    const { level, row: oldRow } = node;
    if (level < 0) {
      return;
    }
    let invalidate = false;
    if (oldRow !== newRow) {
      const prevNode = newRow.treeNode;
      if (prevNode !== null && prevNode !== node) {
        prevNode.removeRow(newRow);
        prevNode.invalidate();
      }
      if (oldRow === null) {
        node.setRow(newRow);
        invalidate = true;
      } else if (!oldRow.data) {
        node.setRow(newRow);
        this.destroyRow(oldRow, true);
        invalidate = true;
      } else {
        if (node.addDuplicateRow(newRow)) {
          invalidate = true;
        }
      }
    }
    if (update && !isTreeRowUpdated(newRow)) {
      setTreeRowUpdated(newRow);
      invalidate = true;
    }
    if (invalidate) {
      node.invalidate();
    }
    this.rowsPendingDestruction?.delete(newRow);
  }
  /**
   * Overwrites the row property of a non-root node to null.
   * @returns The previous row, if any, that was overwritten.
   */
  removeRow(node, oldRow) {
    const { parent, level } = node;
    if (level < 0) {
      return;
    }
    let invalidate = false;
    if (node.removeRow(oldRow)) {
      invalidate = true;
      if (parent) {
        parent.childrenChanged = true;
      }
      this.destroyRow(oldRow, !oldRow.data);
    }
    if (invalidate) {
      node.invalidate();
    }
  }
  /** Commit the changes performed to the tree */
  commitTree(details) {
    const root = this.root;
    this.commitInvalidatedChildren(details, root);
    const rootRow = root.row;
    if (root.childrenChanged) {
      if (root.updateChildrenAfterGroup()) {
        markTreeRowPathChanged(rootRow);
      }
    }
    rootRow.updateHasChildren();
    if (isTreeRowPathChanged(rootRow)) {
      if (details.changedPath?.isActive()) {
        details.changedPath.addParentNode(rootRow);
      }
    }
    markTreeRowCommitted(rootRow);
    this.commitDestroyedRows();
  }
  /** Calls commitChild for each invalidated child, recursively. We commit only the invalidated paths. */
  commitInvalidatedChildren(details, parent) {
    while (true) {
      const child = parent.dequeueInvalidated();
      if (child === null) {
        break;
      }
      if (child.parent === parent) {
        this.commitChild(details, parent, child);
      }
    }
  }
  /** Commit the changes performed to a node and its children */
  commitChild(details, parent, node) {
    if (node.isEmptyFillerNode()) {
      this.clearTree(node);
      return;
    }
    this.commitNodePreOrder(parent, node);
    this.commitInvalidatedChildren(details, node);
    this.commitNodePostOrder(details, parent, node);
  }
  commitNodePreOrder(parent, node) {
    let row = node.row;
    if (row === null) {
      row = this.createFillerRow(node);
      node.setRow(row);
    } else {
      row = node.sortFirstDuplicateRow();
      if (row.allChildrenCount === void 0) {
        row.allChildrenCount = null;
      }
    }
    row.parent = parent.row;
    if (node.oldRow !== row) {
      for (const child of node.enumChildren()) {
        const childRow = child.row;
        if (childRow !== null) {
          childRow.parent = row;
        }
      }
    }
    const key = node.key;
    if (row.key !== key) {
      row.key = key;
      setTreeRowKeyChanged(row);
      this.setGroupData(row, key);
    } else if (!row.groupData) {
      this.setGroupData(row, key);
    }
  }
  commitNodePostOrder(details, parent, node) {
    const row = node.row;
    const oldRow = node.oldRow;
    if (node.isEmptyFillerNode()) {
      this.clearTree(node);
      return;
    }
    if (node.childrenChanged) {
      if (node.updateChildrenAfterGroup()) {
        markTreeRowPathChanged(row);
      }
    }
    if (node.leafChildrenChanged) {
      node.updateAllLeafChildren();
    }
    const newRowPosition = node.getRowPosition();
    if (node.oldSourceRowIndex !== newRowPosition) {
      node.oldSourceRowIndex = newRowPosition;
      parent.childrenChanged = true;
    }
    const hasChildren = !!row.childrenAfterGroup?.length;
    const group = hasChildren || !row.data;
    const oldGroup = row.group;
    if (oldGroup !== group) {
      markTreeRowPathChanged(row);
      row.setGroup(group);
      if (!group && !row.expanded) {
        setTreeRowExpandedInitialized(row, false);
      }
    } else if (row.hasChildren() !== hasChildren) {
      markTreeRowPathChanged(row);
      row.updateHasChildren();
    }
    if (row.group && !isTreeRowExpandedInitialized(row)) {
      if (oldRow !== row && oldRow !== null && oldRow.group && isTreeRowExpandedInitialized(oldRow) && !details.isGroupOpenByDefault) {
        row.expanded = oldRow.expanded;
      } else {
        row.expanded = this.getExpandedInitialValue(details, row);
      }
      setTreeRowExpandedInitialized(row, true);
    }
    if (isTreeRowUpdated(row)) {
      markTreeRowPathChanged(parent.row);
      if (isTreeRowKeyChanged(row)) {
        row.setData(row.data);
      }
    }
    if (oldRow !== row) {
      node.oldRow = row;
      if (oldRow !== null && (oldGroup || node.hasChildren())) {
        markTreeRowPathChanged(row);
      }
      parent.childrenChanged = true;
      markTreeRowPathChanged(parent.row);
    }
    if (isTreeRowPathChanged(row)) {
      if (details.changedPath?.isActive()) {
        details.changedPath.addParentNode(row);
      }
    } else if (!isTreeRowCommitted(row)) {
      if (!row.childrenAfterFilter) {
        row.childrenAfterFilter = row.childrenAfterGroup.slice();
      }
      if (!row.childrenAfterAggFilter) {
        row.childrenAfterAggFilter = row.childrenAfterFilter.slice();
      }
      if (!row.childrenAfterSort) {
        row.childrenAfterSort = row.childrenAfterAggFilter.slice();
      }
    }
    markTreeRowCommitted(row);
    if (node.duplicateRows?.size && !node.duplicateRowsWarned) {
      node.duplicateRowsWarned = true;
      _warnOnce43(`duplicate group keys for row data, keys should be unique`, [
        row.id,
        row.data,
        ...Array.from(node.duplicateRows).map((r) => r.data)
      ]);
    }
  }
  createFillerRow(node) {
    const row = new RowNode2(this.beans);
    row.key = node.key;
    row.group = true;
    row.field = null;
    row.leafGroup = false;
    row.rowGroupIndex = null;
    row.allChildrenCount = null;
    let id = node.level + "-" + node.key;
    let p = node.parent;
    while (p !== null) {
      const parent = p.parent;
      if (parent === null) {
        break;
      }
      id = `${p.level}-${p.key}-${id}`;
      p = parent;
    }
    row.id = RowNode2.ID_PREFIX_ROW_GROUP + id;
    return row;
  }
  setGroupData(row, key) {
    const groupData = {};
    row.groupData = groupData;
    const groupDisplayCols = this.showRowGroupColsService?.getShowRowGroupCols();
    if (groupDisplayCols) {
      for (const col of groupDisplayCols) {
        groupData[col.getColId()] = key;
      }
    }
  }
  getExpandedInitialValue(details, row) {
    const userCallback = details.isGroupOpenByDefault;
    return userCallback ? userCallback({
      rowNode: row,
      field: row.field,
      key: row.key,
      level: row.level,
      rowGroupColumn: row.rowGroupColumn
    }) == true : details.expandByDefault === -1 || row.level < details.expandByDefault;
  }
  /** Called to clear a subtree. */
  clearTree(node) {
    const { parent, oldRow, row, level } = node;
    if (parent !== null && oldRow !== null) {
      parent.childrenChanged = true;
      if (parent.row !== null) {
        markTreeRowPathChanged(parent.row);
      }
    }
    if (row !== null) {
      if (level >= 0) {
        let row2 = node.row;
        while (row2 !== null && node.removeRow(row2)) {
          this.destroyRow(row2, !row2.data);
          row2 = node.row;
        }
      }
    }
    for (const child of node.enumChildren()) {
      this.clearTree(child);
    }
    node.destroy();
  }
  /** Called by the destructor, to the destroy the whole tree. */
  destroyTree(node) {
    const { row, level, duplicateRows } = node;
    if (row) {
      if (level >= 0 && !row.data) {
        this.destroyRow(row, true);
      } else {
        clearTreeRowFlags(row);
      }
    }
    if (duplicateRows) {
      for (const row2 of duplicateRows) {
        if (level >= 0 && !row2.data) {
          this.destroyRow(row2, true);
        } else {
          clearTreeRowFlags(row2);
        }
      }
    }
    for (const child of node.enumChildren()) {
      this.destroyTree(child);
    }
    node.destroy();
  }
  /**
   * Finalizes the deletion of a row.
   * @param immediate If true, the row is deleted immediately.
   * If false, the row is marked for deletion, and will be deleted later with this.deleteDeletedRows()
   */
  destroyRow(row, immediate) {
    if (!isTreeRowCommitted(row)) {
      clearTreeRowFlags(row);
      return;
    }
    if (!immediate) {
      (this.rowsPendingDestruction ?? (this.rowsPendingDestruction = /* @__PURE__ */ new Set())).add(row);
      return;
    }
    clearTreeRowFlags(row);
    row.setRowIndex(null);
    row.setRowTop(null);
    if (!row.data && row.isSelected()) {
      row.setSelectedParams({ newValue: false, source: "rowGroupChanged" });
    }
  }
  /**
   * destroyRow can defer the deletion to the end of the commit stage.
   * This method finalizes the deletion of rows that were marked for deletion.
   */
  commitDestroyedRows() {
    const { rowsPendingDestruction: rowsPendingDeletion } = this;
    if (rowsPendingDeletion !== null) {
      this.rowsPendingDestruction = null;
      for (const row of rowsPendingDeletion) {
        this.destroyRow(row, true);
      }
    }
  }
};
var GroupStage = class extends BeanStub82 {
  constructor() {
    super(...arguments);
    this.beanName = "groupStage";
  }
  wireBeans(beans) {
    this.selectableService = beans.selectableService;
  }
  execute(params) {
    const Strategy = this.gos.get("treeData") ? TreeStrategy : GroupStrategy;
    let strategy = this.strategy;
    if (strategy?.constructor !== Strategy) {
      this.destroyBean(strategy);
      strategy = this.createManagedBean(new Strategy());
      this.strategy = strategy;
    }
    strategy.execute(params);
    this.selectableService.updateSelectableAfterGrouping();
  }
  destroy() {
    this.destroyBean(this.strategy);
    this.strategy = void 0;
    super.destroy();
  }
};
var PIVOT_ROW_TOTAL_PREFIX = "PivotRowTotal_";
var PivotColDefService = class extends BeanStub92 {
  constructor() {
    super(...arguments);
    this.beanName = "pivotColDefService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
    this.columnNameService = beans.columnNameService;
  }
  postConstruct() {
    const getFieldSeparator = () => this.gos.get("serverSidePivotResultFieldSeparator") ?? "_";
    this.fieldSeparator = getFieldSeparator();
    this.addManagedPropertyListener("serverSidePivotResultFieldSeparator", () => {
      this.fieldSeparator = getFieldSeparator();
    });
    const getPivotDefaultExpanded = () => this.gos.get("pivotDefaultExpanded");
    this.pivotDefaultExpanded = getPivotDefaultExpanded();
    this.addManagedPropertyListener("pivotDefaultExpanded", () => {
      this.pivotDefaultExpanded = getPivotDefaultExpanded();
    });
  }
  createPivotColumnDefs(uniqueValues) {
    const pivotColumnGroupDefs = this.createPivotColumnsFromUniqueValues(uniqueValues);
    function extractColDefs(input, arr = []) {
      input.forEach((def) => {
        if (def.children !== void 0) {
          extractColDefs(def.children, arr);
        } else {
          arr.push(def);
        }
      });
      return arr;
    }
    const pivotColumnDefs = extractColDefs(pivotColumnGroupDefs);
    this.addRowGroupTotals(pivotColumnGroupDefs, pivotColumnDefs);
    this.addExpandablePivotGroups(pivotColumnGroupDefs, pivotColumnDefs);
    this.addPivotTotalsToGroups(pivotColumnGroupDefs, pivotColumnDefs);
    const pivotColumnDefsClone = pivotColumnDefs.map((colDef) => _cloneObject2(colDef));
    return {
      pivotColumnGroupDefs,
      pivotColumnDefs: pivotColumnDefsClone
    };
  }
  createPivotColumnsFromUniqueValues(uniqueValues) {
    const pivotColumns = this.funcColsService.getPivotColumns();
    const maxDepth = pivotColumns.length;
    const pivotColumnGroupDefs = this.recursivelyBuildGroup(
      0,
      uniqueValues,
      [],
      maxDepth,
      pivotColumns
    );
    return pivotColumnGroupDefs;
  }
  recursivelyBuildGroup(index, uniqueValue, pivotKeys, maxDepth, primaryPivotColumns) {
    const measureColumns = this.funcColsService.getValueColumns();
    if (index >= maxDepth) {
      return this.buildMeasureCols(pivotKeys);
    }
    const primaryPivotColumnDefs = primaryPivotColumns[index].getColDef();
    const comparator = this.headerNameComparator.bind(this, primaryPivotColumnDefs.pivotComparator);
    if (measureColumns.length === 1 && this.gos.get("removePivotHeaderRowWhenSingleValueColumn") && index === maxDepth - 1) {
      const leafCols = [];
      _iterateObject2(uniqueValue, (key) => {
        const newPivotKeys = [...pivotKeys, key];
        const colDef = this.createColDef(measureColumns[0], key, newPivotKeys);
        colDef.columnGroupShow = "open";
        leafCols.push(colDef);
      });
      leafCols.sort(comparator);
      return leafCols;
    }
    const groups = [];
    _iterateObject2(uniqueValue, (key, value) => {
      const openByDefault = this.pivotDefaultExpanded === -1 || index < this.pivotDefaultExpanded;
      const newPivotKeys = [...pivotKeys, key];
      groups.push({
        children: this.recursivelyBuildGroup(index + 1, value, newPivotKeys, maxDepth, primaryPivotColumns),
        headerName: key,
        pivotKeys: newPivotKeys,
        columnGroupShow: "open",
        openByDefault,
        groupId: this.generateColumnGroupId(newPivotKeys)
      });
    });
    groups.sort(comparator);
    return groups;
  }
  buildMeasureCols(pivotKeys) {
    const measureColumns = this.funcColsService.getValueColumns();
    if (measureColumns.length === 0) {
      return [this.createColDef(null, "-", pivotKeys)];
    }
    return measureColumns.map((measureCol) => {
      const columnName = this.columnNameService.getDisplayNameForColumn(measureCol, "header");
      return {
        ...this.createColDef(measureCol, columnName, pivotKeys),
        columnGroupShow: "open"
      };
    });
  }
  addExpandablePivotGroups(pivotColumnGroupDefs, pivotColumnDefs) {
    const isSuppressExpand = this.gos.get("suppressExpandablePivotGroups");
    if (isSuppressExpand || this.gos.get("pivotColumnGroupTotals")) {
      return;
    }
    const recursivelyAddSubTotals = (def, currentPivotColumnDefs, acc) => {
      if ("children" in def) {
        const childAcc = /* @__PURE__ */ new Map();
        def.children.forEach((grp) => {
          recursivelyAddSubTotals(grp, currentPivotColumnDefs, childAcc);
        });
        const leafGroup = !def.children.some((child) => child.children);
        this.funcColsService.getValueColumns().forEach((valueColumn) => {
          const columnName = this.columnNameService.getDisplayNameForColumn(
            valueColumn,
            "header"
          );
          const totalColDef = this.createColDef(valueColumn, columnName, def.pivotKeys);
          totalColDef.pivotTotalColumnIds = childAcc.get(valueColumn.getColId());
          totalColDef.columnGroupShow = !isSuppressExpand ? "closed" : "open";
          totalColDef.aggFunc = valueColumn.getAggFunc();
          if (!leafGroup) {
            const children = def.children;
            children.push(totalColDef);
            currentPivotColumnDefs.push(totalColDef);
          }
        });
        this.merge(acc, childAcc);
        return;
      }
      if (!def.pivotValueColumn) {
        return;
      }
      const pivotValueColId = def.pivotValueColumn.getColId();
      const exists = acc.has(pivotValueColId);
      if (exists) {
        const arr = acc.get(pivotValueColId);
        arr.push(def.colId);
      } else {
        acc.set(pivotValueColId, [def.colId]);
      }
    };
    pivotColumnGroupDefs.forEach((groupDef) => {
      recursivelyAddSubTotals(groupDef, pivotColumnDefs, /* @__PURE__ */ new Map());
    });
  }
  addPivotTotalsToGroups(pivotColumnGroupDefs, pivotColumnDefs) {
    if (!this.gos.get("pivotColumnGroupTotals")) {
      return;
    }
    const insertAfter = this.gos.get("pivotColumnGroupTotals") === "after";
    const valueCols = this.funcColsService.getValueColumns();
    const aggFuncs = valueCols.map((valueCol) => valueCol.getAggFunc());
    if (!aggFuncs || aggFuncs.length < 1 || !this.sameAggFuncs(aggFuncs)) {
      return;
    }
    const valueColumn = valueCols[0];
    pivotColumnGroupDefs.forEach((groupDef) => {
      this.recursivelyAddPivotTotal(groupDef, pivotColumnDefs, valueColumn, insertAfter);
    });
  }
  recursivelyAddPivotTotal(groupDef, pivotColumnDefs, valueColumn, insertAfter) {
    const group = groupDef;
    if (!group.children) {
      const def = groupDef;
      return def.colId ? [def.colId] : null;
    }
    let colIds = [];
    group.children.forEach((grp) => {
      const childColIds = this.recursivelyAddPivotTotal(grp, pivotColumnDefs, valueColumn, insertAfter);
      if (childColIds) {
        colIds = colIds.concat(childColIds);
      }
    });
    if (group.children.length > 1) {
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      const headerName = localeTextFunc("pivotColumnGroupTotals", "Total");
      const totalColDef = this.createColDef(valueColumn, headerName, groupDef.pivotKeys, true);
      totalColDef.pivotTotalColumnIds = colIds;
      totalColDef.aggFunc = valueColumn.getAggFunc();
      totalColDef.columnGroupShow = this.gos.get("suppressExpandablePivotGroups") ? "open" : void 0;
      const children = groupDef.children;
      insertAfter ? children.push(totalColDef) : children.unshift(totalColDef);
      pivotColumnDefs.push(totalColDef);
    }
    return colIds;
  }
  addRowGroupTotals(pivotColumnGroupDefs, pivotColumnDefs) {
    if (!this.gos.get("pivotRowTotals")) {
      return;
    }
    const insertAtEnd = this.gos.get("pivotRowTotals") === "after";
    const valueColumns = this.funcColsService.getValueColumns();
    const valueCols = valueColumns.slice();
    if (!insertAtEnd) {
      valueCols.reverse();
    }
    const isCreateTotalGroups = valueCols.length > 1 || !this.gos.get("removePivotHeaderRowWhenSingleValueColumn");
    for (let i = 0; i < valueCols.length; i++) {
      const valueCol = valueCols[i];
      const columnName = this.columnNameService.getDisplayNameForColumn(valueCol, "header");
      const colDef = this.createColDef(valueCol, columnName, []);
      const colIds = [];
      for (let i2 = 0; i2 < pivotColumnDefs.length; i2++) {
        const colDef2 = pivotColumnDefs[i2];
        if (colDef2.pivotValueColumn === valueCol) {
          colIds.push(colDef2.colId);
        }
      }
      colDef.pivotTotalColumnIds = colIds;
      colDef.colId = PIVOT_ROW_TOTAL_PREFIX + colDef.colId;
      const valueGroup = isCreateTotalGroups ? {
        children: [colDef],
        pivotKeys: [],
        groupId: `${PIVOT_ROW_TOTAL_PREFIX}_pivotGroup_${valueCol.getColId()}`
      } : colDef;
      pivotColumnDefs.push(colDef);
      insertAtEnd ? pivotColumnGroupDefs.push(valueGroup) : pivotColumnGroupDefs.unshift(valueGroup);
    }
  }
  createColDef(valueColumn, headerName, pivotKeys, totalColumn = false) {
    const colDef = {};
    if (valueColumn) {
      const colDefToCopy = valueColumn.getColDef();
      Object.assign(colDef, colDefToCopy);
      colDef.hide = false;
    }
    colDef.headerName = headerName;
    colDef.colId = this.generateColumnId(
      pivotKeys || [],
      valueColumn && !totalColumn ? valueColumn.getColId() : ""
    );
    colDef.field = colDef.colId;
    colDef.valueGetter = (params) => params.data?.[params.colDef.field];
    colDef.pivotKeys = pivotKeys;
    colDef.pivotValueColumn = valueColumn;
    if (colDef.filter === true) {
      colDef.filter = "agNumberColumnFilter";
    }
    return colDef;
  }
  sameAggFuncs(aggFuncs) {
    if (aggFuncs.length == 1) {
      return true;
    }
    for (let i = 1; i < aggFuncs.length; i++) {
      if (aggFuncs[i] !== aggFuncs[0]) {
        return false;
      }
    }
    return true;
  }
  headerNameComparator(userComparator, a, b) {
    if (userComparator) {
      return userComparator(a.headerName, b.headerName);
    } else {
      if (a.headerName && !b.headerName) {
        return 1;
      } else if (!a.headerName && b.headerName) {
        return -1;
      }
      if (!a.headerName || !b.headerName) {
        return 0;
      }
      if (a.headerName < b.headerName) {
        return -1;
      }
      if (a.headerName > b.headerName) {
        return 1;
      }
      return 0;
    }
  }
  merge(m1, m2) {
    m2.forEach((value, key) => {
      const existingList = m1.has(key) ? m1.get(key) : [];
      const updatedList = [...existingList, ...value];
      m1.set(key, updatedList);
    });
  }
  generateColumnGroupId(pivotKeys) {
    const pivotCols = this.funcColsService.getPivotColumns().map((col) => col.getColId());
    return `pivotGroup_${pivotCols.join("-")}_${pivotKeys.join("-")}`;
  }
  generateColumnId(pivotKeys, measureColumnId) {
    const pivotCols = this.funcColsService.getPivotColumns().map((col) => col.getColId());
    return `pivot_${pivotCols.join("-")}_${pivotKeys.join("-")}_${measureColumnId}`;
  }
  /**
   * Used by the SSRM to create secondary columns from provided fields
   * @param fields
   */
  createColDefsFromFields(fields) {
    const uniqueValues = {};
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const parts = field.split(this.fieldSeparator);
      let level = uniqueValues;
      for (let p = 0; p < parts.length; p++) {
        const part = parts[p];
        if (level[part] == null) {
          level[part] = {};
        }
        level = level[part];
      }
    }
    const uniqueValuesToGroups = (id, key, uniqueValues2, depth) => {
      const children = [];
      for (const key2 in uniqueValues2) {
        const item = uniqueValues2[key2];
        const child = uniqueValuesToGroups(`${id}${this.fieldSeparator}${key2}`, key2, item, depth + 1);
        children.push(child);
      }
      if (children.length === 0) {
        const potentialAggCol = this.columnModel.getColDefCol(key);
        if (potentialAggCol) {
          const headerName = this.columnNameService.getDisplayNameForColumn(potentialAggCol, "header") ?? key;
          const colDef = this.createColDef(potentialAggCol, headerName, void 0, false);
          colDef.colId = id;
          colDef.aggFunc = potentialAggCol.getAggFunc();
          colDef.valueGetter = (params) => params.data?.[id];
          return colDef;
        }
        const col = {
          colId: id,
          headerName: key,
          // this is to support using pinned rows, normally the data will be extracted from the aggData object using the colId
          // however pinned rows still access the data object by field, this prevents values with dots from being treated as complex objects
          valueGetter: (params) => params.data?.[id]
        };
        return col;
      }
      const collapseSingleChildren = this.gos.get("removePivotHeaderRowWhenSingleValueColumn");
      if (collapseSingleChildren && children.length === 1 && "colId" in children[0]) {
        children[0].headerName = key;
        return children[0];
      }
      const group = {
        openByDefault: this.pivotDefaultExpanded === -1 || depth < this.pivotDefaultExpanded,
        groupId: id,
        headerName: key,
        children
      };
      return group;
    };
    const res = [];
    for (const key in uniqueValues) {
      const item = uniqueValues[key];
      const col = uniqueValuesToGroups(key, key, item, 0);
      res.push(col);
    }
    return res;
  }
};
var EXCEEDED_MAX_UNIQUE_VALUES = "Exceeded maximum allowed pivot column count.";
var PivotStage = class extends BeanStub102 {
  constructor() {
    super(...arguments);
    this.beanName = "pivotStage";
    this.uniqueValues = {};
    this.lastTimeFailed = false;
    this.maxUniqueValues = -1;
    this.currentUniqueCount = 0;
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.columnModel = beans.columnModel;
    this.pivotResultColsService = beans.pivotResultColsService;
    this.funcColsService = beans.funcColsService;
    this.pivotColDefService = beans.pivotColDefService;
  }
  execute(params) {
    const changedPath = params.changedPath;
    if (this.columnModel.isPivotActive()) {
      this.executePivotOn(changedPath);
    } else {
      this.executePivotOff(changedPath);
    }
  }
  executePivotOff(changedPath) {
    this.aggregationColumnsHashLastTime = null;
    this.uniqueValues = {};
    if (this.pivotResultColsService.isPivotResultColsPresent()) {
      this.pivotResultColsService.setPivotResultCols(null, "rowModelUpdated");
      if (changedPath) {
        changedPath.setInactive();
      }
    }
  }
  executePivotOn(changedPath) {
    const numberOfAggregationColumns = this.funcColsService.getValueColumns().length ?? 1;
    const configuredMaxCols = this.gos.get("pivotMaxGeneratedColumns");
    this.maxUniqueValues = configuredMaxCols === -1 ? -1 : configuredMaxCols / numberOfAggregationColumns;
    let uniqueValues;
    try {
      uniqueValues = this.bucketUpRowNodes(changedPath);
    } catch (e) {
      if (e.message === EXCEEDED_MAX_UNIQUE_VALUES) {
        this.pivotResultColsService.setPivotResultCols([], "rowModelUpdated");
        this.eventService.dispatchEvent({
          type: "pivotMaxColumnsExceeded",
          message: e.message
        });
        this.lastTimeFailed = true;
        return;
      }
      throw e;
    }
    const uniqueValuesChanged = this.setUniqueValues(uniqueValues);
    const aggregationColumns = this.funcColsService.getValueColumns();
    const aggregationColumnsHash = aggregationColumns.map((column) => `${column.getId()}-${column.getColDef().headerName}`).join("#");
    const aggregationFuncsHash = aggregationColumns.map((column) => column.getAggFunc().toString()).join("#");
    const aggregationColumnsChanged = this.aggregationColumnsHashLastTime !== aggregationColumnsHash;
    const aggregationFuncsChanged = this.aggregationFuncsHashLastTime !== aggregationFuncsHash;
    this.aggregationColumnsHashLastTime = aggregationColumnsHash;
    this.aggregationFuncsHashLastTime = aggregationFuncsHash;
    const groupColumnsHash = this.funcColsService.getRowGroupColumns().map((column) => column.getId()).join("#");
    const groupColumnsChanged = groupColumnsHash !== this.groupColumnsHashLastTime;
    this.groupColumnsHashLastTime = groupColumnsHash;
    const pivotRowTotals = this.gos.get("pivotRowTotals");
    const pivotColumnGroupTotals = this.gos.get("pivotColumnGroupTotals");
    const suppressExpandablePivotGroups = this.gos.get("suppressExpandablePivotGroups");
    const removePivotHeaderRowWhenSingleValueColumn = this.gos.get("removePivotHeaderRowWhenSingleValueColumn");
    const anyGridOptionsChanged = pivotRowTotals !== this.pivotRowTotalsLastTime || pivotColumnGroupTotals !== this.pivotColumnGroupTotalsLastTime || suppressExpandablePivotGroups !== this.suppressExpandablePivotGroupsLastTime || removePivotHeaderRowWhenSingleValueColumn !== this.removePivotHeaderRowWhenSingleValueColumnLastTime;
    this.pivotRowTotalsLastTime = pivotRowTotals;
    this.pivotColumnGroupTotalsLastTime = pivotColumnGroupTotals;
    this.suppressExpandablePivotGroupsLastTime = suppressExpandablePivotGroups;
    this.removePivotHeaderRowWhenSingleValueColumnLastTime = removePivotHeaderRowWhenSingleValueColumn;
    if (this.lastTimeFailed || uniqueValuesChanged || aggregationColumnsChanged || groupColumnsChanged || aggregationFuncsChanged || anyGridOptionsChanged) {
      const { pivotColumnGroupDefs, pivotColumnDefs } = this.pivotColDefService.createPivotColumnDefs(
        this.uniqueValues
      );
      this.pivotColumnDefs = pivotColumnDefs;
      this.pivotResultColsService.setPivotResultCols(pivotColumnGroupDefs, "rowModelUpdated");
      if (changedPath) {
        changedPath.setInactive();
      }
    }
    this.lastTimeFailed = false;
  }
  setUniqueValues(newValues) {
    const json1 = JSON.stringify(newValues);
    const json2 = JSON.stringify(this.uniqueValues);
    const uniqueValuesChanged = json1 !== json2;
    if (uniqueValuesChanged) {
      this.uniqueValues = newValues;
      return true;
    } else {
      return false;
    }
  }
  bucketUpRowNodes(changedPath) {
    this.currentUniqueCount = 0;
    const uniqueValues = {};
    changedPath.forEachChangedNodeDepthFirst((node) => {
      if (node.leafGroup) {
        node.childrenMapped = null;
      }
    });
    const recursivelyBucketFilteredChildren = (node) => {
      if (node.leafGroup) {
        this.bucketRowNode(node, uniqueValues);
      } else {
        node.childrenAfterFilter?.forEach(recursivelyBucketFilteredChildren);
      }
    };
    changedPath.executeFromRootNode(recursivelyBucketFilteredChildren);
    return uniqueValues;
  }
  bucketRowNode(rowNode, uniqueValues) {
    const pivotColumns = this.funcColsService.getPivotColumns();
    if (pivotColumns.length === 0) {
      rowNode.childrenMapped = null;
    } else {
      rowNode.childrenMapped = this.bucketChildren(rowNode.childrenAfterFilter, pivotColumns, 0, uniqueValues);
    }
    if (rowNode.sibling) {
      rowNode.sibling.childrenMapped = rowNode.childrenMapped;
    }
  }
  bucketChildren(children, pivotColumns, pivotIndex, uniqueValues) {
    const mappedChildren = {};
    const pivotColumn = pivotColumns[pivotIndex];
    children.forEach((child) => {
      let key = this.valueService.getKeyForNode(pivotColumn, child);
      if (_missing22(key)) {
        key = "";
      }
      if (!uniqueValues[key]) {
        this.currentUniqueCount += 1;
        uniqueValues[key] = {};
        const doesGeneratedColMaxExist = this.maxUniqueValues !== -1;
        const hasExceededColMax = this.currentUniqueCount > this.maxUniqueValues;
        if (doesGeneratedColMaxExist && hasExceededColMax) {
          throw Error(EXCEEDED_MAX_UNIQUE_VALUES);
        }
      }
      if (!mappedChildren[key]) {
        mappedChildren[key] = [];
      }
      mappedChildren[key].push(child);
    });
    if (pivotIndex === pivotColumns.length - 1) {
      return mappedChildren;
    } else {
      const result = {};
      _iterateObject3(mappedChildren, (key, value) => {
        result[key] = this.bucketChildren(value, pivotColumns, pivotIndex + 1, uniqueValues[key]);
      });
      return result;
    }
  }
  getPivotColumnDefs() {
    return this.pivotColumnDefs;
  }
};
function addAggFunc(beans, key, aggFunc) {
  if (beans.aggFuncService) {
    beans.aggFuncService.addAggFuncs({ key: aggFunc });
  }
}
function addAggFuncs(beans, aggFuncs) {
  if (beans.aggFuncService) {
    beans.aggFuncService.addAggFuncs(aggFuncs);
  }
}
function clearAggFuncs(beans) {
  if (beans.aggFuncService) {
    beans.aggFuncService.clear();
  }
}
function setColumnAggFunc(beans, key, aggFunc) {
  beans.funcColsService.setColumnAggFunc(key, aggFunc, "api");
}
function isPivotMode(beans) {
  return beans.columnModel.isPivotMode();
}
function getPivotResultColumn(beans, pivotKeys, valueColKey) {
  return beans.pivotResultColsService.lookupPivotResultCol(pivotKeys, valueColKey);
}
function setValueColumns(beans, colKeys) {
  beans.funcColsService.setValueColumns(colKeys, "api");
}
function getValueColumns(beans) {
  return beans.funcColsService.getValueColumns();
}
function removeValueColumn(beans, colKey) {
  beans.funcColsService.removeValueColumns([colKey], "api");
}
function removeValueColumns(beans, colKeys) {
  beans.funcColsService.removeValueColumns(colKeys, "api");
}
function addValueColumn(beans, colKey) {
  beans.funcColsService.addValueColumns([colKey], "api");
}
function addValueColumns(beans, colKeys) {
  beans.funcColsService.addValueColumns(colKeys, "api");
}
function setRowGroupColumns(beans, colKeys) {
  beans.funcColsService.setRowGroupColumns(colKeys, "api");
}
function removeRowGroupColumn(beans, colKey) {
  beans.funcColsService.removeRowGroupColumns([colKey], "api");
}
function removeRowGroupColumns(beans, colKeys) {
  beans.funcColsService.removeRowGroupColumns(colKeys, "api");
}
function addRowGroupColumn(beans, colKey) {
  beans.funcColsService.addRowGroupColumns([colKey], "api");
}
function addRowGroupColumns(beans, colKeys) {
  beans.funcColsService.addRowGroupColumns(colKeys, "api");
}
function moveRowGroupColumn(beans, fromIndex, toIndex) {
  beans.funcColsService.moveRowGroupColumn(fromIndex, toIndex, "api");
}
function getRowGroupColumns(beans) {
  return beans.funcColsService.getRowGroupColumns();
}
function setPivotColumns(beans, colKeys) {
  beans.funcColsService.setPivotColumns(colKeys, "api");
}
function removePivotColumn(beans, colKey) {
  beans.funcColsService.removePivotColumns([colKey], "api");
}
function removePivotColumns(beans, colKeys) {
  beans.funcColsService.removePivotColumns(colKeys, "api");
}
function addPivotColumn(beans, colKey) {
  beans.funcColsService.addPivotColumns([colKey], "api");
}
function addPivotColumns(beans, colKeys) {
  beans.funcColsService.addPivotColumns(colKeys, "api");
}
function getPivotColumns(beans) {
  return beans.funcColsService.getPivotColumns();
}
function setPivotResultColumns(beans, colDefs) {
  beans.pivotResultColsService.setPivotResultCols(colDefs, "api");
}
function getPivotResultColumns(beans) {
  const pivotResultCols = beans.pivotResultColsService.getPivotResultCols();
  return pivotResultCols ? pivotResultCols.list : null;
}
var ShowRowGroupColsService = class extends BeanStub112 {
  constructor() {
    super(...arguments);
    this.beanName = "showRowGroupColsService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
  }
  refresh() {
    this.showRowGroupCols = [];
    this.showRowGroupColsMap = {};
    this.columnModel.getCols().forEach((col) => {
      const colDef = col.getColDef();
      const showRowGroup = colDef.showRowGroup;
      const isString3 = typeof showRowGroup === "string";
      const isTrue = showRowGroup === true;
      if (!isString3 && !isTrue) {
        return;
      }
      this.showRowGroupCols.push(col);
      if (isString3) {
        this.showRowGroupColsMap[showRowGroup] = col;
      } else {
        const rowGroupCols = this.funcColsService.getRowGroupColumns();
        rowGroupCols.forEach((rowGroupCol) => {
          this.showRowGroupColsMap[rowGroupCol.getId()] = col;
        });
      }
    });
  }
  getShowRowGroupCols() {
    return this.showRowGroupCols;
  }
  getShowRowGroupCol(id) {
    return this.showRowGroupColsMap[id];
  }
};
var VERSION6 = "32.3.0";
var RowGroupingCoreModule = _defineModule6({
  version: VERSION6,
  moduleName: `${ModuleNames6.RowGroupingModule}-core`,
  beans: [
    AggregationStage,
    FilterAggregatesStage,
    GroupStage,
    PivotColDefService,
    PivotStage,
    AggFuncService,
    AutoColService,
    ShowRowGroupColsService,
    ColumnDropZoneService
  ],
  userComponents: [
    {
      name: "agGroupRowRenderer",
      classImp: GroupCellRenderer
    },
    {
      name: "agGroupCellRenderer",
      classImp: GroupCellRenderer
    }
  ],
  controllers: [{ name: "groupCellRendererCtrl", classImp: GroupCellRendererCtrl }],
  dependantModules: [EnterpriseCoreModule]
});
var RowGroupingApiModule = _defineModule6({
  version: VERSION6,
  moduleName: `${ModuleNames6.RowGroupingModule}-api`,
  apiFunctions: {
    addAggFunc,
    addAggFuncs,
    clearAggFuncs,
    setColumnAggFunc,
    isPivotMode,
    getPivotResultColumn,
    setValueColumns,
    getValueColumns,
    removeValueColumn,
    removeValueColumns,
    addValueColumn,
    addValueColumns,
    setRowGroupColumns,
    removeRowGroupColumn,
    removeRowGroupColumns,
    addRowGroupColumn,
    addRowGroupColumns,
    getRowGroupColumns,
    moveRowGroupColumn,
    setPivotColumns,
    removePivotColumn,
    removePivotColumns,
    addPivotColumn,
    addPivotColumns,
    getPivotColumns,
    setPivotResultColumns,
    getPivotResultColumns
  },
  dependantModules: [RowGroupingCoreModule]
});
var GroupFilterModule = _defineModule6({
  version: VERSION6,
  moduleName: "@ag-grid-enterprise/group-filter",
  userComponents: [{ name: "agGroupColumnFilter", classImp: GroupFilter }],
  dependantModules: [RowGroupingCoreModule, _ColumnFilterModule]
});
var GroupFloatingFilterModule = _defineModule6({
  version: VERSION6,
  moduleName: "@ag-grid-enterprise/group-floating-filter",
  userComponents: [{ name: "agGroupColumnFloatingFilter", classImp: GroupFloatingFilterComp }],
  dependantModules: [GroupFilterModule, _FloatingFilterModule]
});
var RowGroupingModule = _defineModule6({
  version: VERSION6,
  moduleName: ModuleNames6.RowGroupingModule,
  dependantModules: [RowGroupingCoreModule, RowGroupingApiModule, GroupFilterModule, GroupFloatingFilterModule]
});
var ValuesDropZonePanel = class extends BaseDropZonePanel {
  constructor(horizontal) {
    super(horizontal, "aggregation");
  }
  postConstruct() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const emptyMessage = localeTextFunc("valueColumnsEmptyMessage", "Drag here to aggregate");
    const title = localeTextFunc("values", "Values");
    super.init({
      icon: _createIconNoSpan33("valuePanel", this.gos, null),
      emptyMessage,
      title
    });
    this.addManagedEventListeners({ columnValueChanged: this.refreshGui.bind(this) });
  }
  getAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const label = translate("ariaValuesDropZonePanelLabel", "Values");
    return label;
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "valueColumnsList";
    return res;
  }
  getIconName() {
    return this.isPotentialDndItems() ? "aggregate" : "notAllowed";
  }
  isItemDroppable(column, draggingEvent) {
    if (this.gos.get("functionsReadOnly") || !column.isPrimary()) {
      return false;
    }
    return column.isAllowValue() && (!column.isValueActive() || this.isSourceEventFromTarget(draggingEvent));
  }
  updateItems(columns) {
    this.funcColsService.setValueColumns(columns, "toolPanelUi");
  }
  getExistingItems() {
    return this.funcColsService.getValueColumns();
  }
};

// enterprise-modules/side-bar/src/sideBarModule.ts
import { ModuleNames as ModuleNames8, _defineModule as _defineModule7 } from "ag-grid-community";

// enterprise-modules/side-bar/src/sideBar/common/toolPanelColDefService.ts
import { AgProvidedColumnGroup, BeanStub as BeanStub18, _warnOnce as _warnOnce17, isProvidedColumnGroup } from "ag-grid-community";

// enterprise-modules/side-bar/src/sideBar/common/sideBarUtils.ts
import { _includes as _includes9, _last as _last8 } from "ag-grid-community";
function isColGroupDef(colDef) {
  return !!colDef && typeof colDef.children !== "undefined";
}
function getId(colDef) {
  return isColGroupDef(colDef) ? colDef.groupId : colDef.colId;
}
function addChildrenToGroup(tree, groupId, colDef) {
  const subGroupIsSplit = (currentSubGroup, currentSubGroupToAdd) => {
    const existingChildIds = currentSubGroup.children.map(getId);
    const childGroupAlreadyExists = _includes9(existingChildIds, getId(currentSubGroupToAdd));
    const lastChild = _last8(currentSubGroup.children);
    const lastChildIsDifferent = lastChild && getId(lastChild) !== getId(currentSubGroupToAdd);
    return childGroupAlreadyExists && lastChildIsDifferent;
  };
  if (!isColGroupDef(tree)) {
    return true;
  }
  const currentGroup = tree;
  const groupToAdd = colDef;
  if (subGroupIsSplit(currentGroup, groupToAdd)) {
    currentGroup.children.push(groupToAdd);
    return true;
  }
  if (currentGroup.groupId === groupId) {
    const existingChildIds = currentGroup.children.map(getId);
    const colDefAlreadyPresent = _includes9(existingChildIds, getId(groupToAdd));
    if (!colDefAlreadyPresent) {
      currentGroup.children.push(groupToAdd);
      return true;
    }
  }
  for (let i = currentGroup.children.length - 1; i >= 0; i--) {
    if (addChildrenToGroup(currentGroup.children[i], groupId, colDef)) {
      break;
    }
  }
  return false;
}
function mergeLeafPathTrees(leafPathTrees) {
  const matchingRootGroupIds = (pathA, pathB) => {
    const bothPathsAreGroups = isColGroupDef(pathA) && isColGroupDef(pathB);
    return bothPathsAreGroups && getId(pathA) === getId(pathB);
  };
  const mergeTrees = (treeA, treeB) => {
    if (!isColGroupDef(treeB)) {
      return treeA;
    }
    const mergeResult = treeA;
    const groupToMerge = treeB;
    if (groupToMerge.children && groupToMerge.groupId) {
      const added = addChildrenToGroup(mergeResult, groupToMerge.groupId, groupToMerge.children[0]);
      if (added) {
        return mergeResult;
      }
    }
    groupToMerge.children.forEach((child) => mergeTrees(mergeResult, child));
    return mergeResult;
  };
  const mergeColDefs = [];
  for (let i = 1; i <= leafPathTrees.length; i++) {
    const first = leafPathTrees[i - 1];
    const second = leafPathTrees[i];
    if (matchingRootGroupIds(first, second)) {
      leafPathTrees[i] = mergeTrees(first, second);
    } else {
      mergeColDefs.push(first);
    }
  }
  return mergeColDefs;
}

// enterprise-modules/side-bar/src/sideBar/common/toolPanelColDefService.ts
var ToolPanelColDefService = class extends BeanStub18 {
  constructor() {
    super(...arguments);
    this.beanName = "toolPanelColDefService";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
  }
  createColumnTree(colDefs) {
    const invalidColIds = [];
    const createDummyColGroup = (abstractColDef, depth) => {
      if (isColGroupDef(abstractColDef)) {
        const groupDef = abstractColDef;
        const groupId = typeof groupDef.groupId !== "undefined" ? groupDef.groupId : groupDef.headerName;
        const group = new AgProvidedColumnGroup(groupDef, groupId, false, depth);
        const children = [];
        groupDef.children.forEach((def) => {
          const child = createDummyColGroup(def, depth + 1);
          if (child) {
            children.push(child);
          }
        });
        group.setChildren(children);
        return group;
      } else {
        const colDef = abstractColDef;
        const key = colDef.colId ? colDef.colId : colDef.field;
        const column = this.columnModel.getColDefCol(key);
        if (!column) {
          invalidColIds.push(colDef);
        }
        return column;
      }
    };
    const mappedResults = [];
    colDefs.forEach((colDef) => {
      const result = createDummyColGroup(colDef, 0);
      if (result) {
        mappedResults.push(result);
      }
    });
    if (invalidColIds.length > 0) {
      _warnOnce17("unable to find grid columns for the supplied colDef(s):", invalidColIds);
    }
    return mappedResults;
  }
  syncLayoutWithGrid(syncLayoutCallback) {
    const leafPathTrees = this.getLeafPathTrees();
    const mergedColumnTrees = mergeLeafPathTrees(leafPathTrees);
    syncLayoutCallback(mergedColumnTrees);
  }
  getLeafPathTrees() {
    const getLeafPathTree = (node, childDef) => {
      let leafPathTree;
      if (isProvidedColumnGroup(node)) {
        if (node.isPadding()) {
          leafPathTree = childDef;
        } else {
          const groupDef = Object.assign({}, node.getColGroupDef());
          groupDef.groupId = node.getGroupId();
          groupDef.children = [childDef];
          leafPathTree = groupDef;
        }
      } else {
        const colDef = Object.assign({}, node.getColDef());
        colDef.colId = node.getColId();
        leafPathTree = colDef;
      }
      const parent = node.getOriginalParent();
      if (parent) {
        return getLeafPathTree(parent, leafPathTree);
      } else {
        return leafPathTree;
      }
    };
    const allGridColumns = this.columnModel.getCols();
    const allPrimaryGridColumns = allGridColumns.filter((column) => {
      const colDef = column.getColDef();
      return column.isPrimary() && !colDef.showRowGroup;
    });
    return allPrimaryGridColumns.map((col) => getLeafPathTree(col, col.getColDef()));
  }
};

// enterprise-modules/side-bar/src/sideBar/sideBarApi.ts
import { _unwrapUserComp } from "ag-grid-community";
function isSideBarVisible(beans) {
  return beans.sideBarService?.getSideBarComp().isDisplayed() ?? false;
}
function setSideBarVisible(beans, show) {
  beans.sideBarService?.getSideBarComp().setDisplayed(show);
}
function setSideBarPosition(beans, position) {
  beans.sideBarService?.getSideBarComp().setSideBarPosition(position);
}
function openToolPanel(beans, key) {
  beans.sideBarService?.getSideBarComp().openToolPanel(key, "api");
}
function closeToolPanel(beans) {
  beans.sideBarService?.getSideBarComp().close("api");
}
function getOpenedToolPanel(beans) {
  return beans.sideBarService?.getSideBarComp().openedItem() ?? null;
}
function refreshToolPanel(beans) {
  beans.sideBarService?.getSideBarComp().refresh();
}
function isToolPanelShowing(beans) {
  return beans.sideBarService?.getSideBarComp().isToolPanelShowing() ?? false;
}
function getToolPanelInstance(beans, id) {
  const comp = beans.sideBarService?.getSideBarComp().getToolPanelInstance(id);
  return _unwrapUserComp(comp);
}
function getSideBar(beans) {
  return beans.sideBarService?.getSideBarComp().getDef();
}

// enterprise-modules/side-bar/src/sideBar/sideBarService.ts
import { BeanStub as BeanStub19 } from "ag-grid-community";

// enterprise-modules/side-bar/src/sideBar/agSideBar.ts
import {
  Component as Component48,
  KeyCode as KeyCode17,
  ManagedFocusFeature as ManagedFocusFeature2,
  ModuleNames as ModuleNames7,
  RefPlaceholder as RefPlaceholder25,
  _addFocusableContainerListener,
  _getActiveDomElement as _getActiveDomElement9,
  _removeFromParent as _removeFromParent8,
  _setAriaControls as _setAriaControls2,
  _warnOnce as _warnOnce20
} from "ag-grid-community";

// enterprise-modules/side-bar/src/sideBar/agSideBarButtons.ts
import { Component as Component45, KeyCode as KeyCode16, _clearElement as _clearElement8, _stopPropagationForAgGrid as _stopPropagationForAgGrid6 } from "ag-grid-community";

// enterprise-modules/side-bar/src/sideBar/sideBarButtonComp.ts
import { Component as Component44, RefPlaceholder as RefPlaceholder21, _createIconNoSpan as _createIconNoSpan12, _setAriaExpanded as _setAriaExpanded6 } from "ag-grid-community";
var SideBarButtonComp = class extends Component44 {
  constructor(toolPanelDef) {
    super();
    this.eToggleButton = RefPlaceholder21;
    this.eIconWrapper = RefPlaceholder21;
    this.eLabel = RefPlaceholder21;
    this.toolPanelDef = toolPanelDef;
  }
  getToolPanelId() {
    return this.toolPanelDef.id;
  }
  postConstruct() {
    const template = this.createTemplate();
    this.setTemplate(template, []);
    this.setLabel();
    this.setIcon();
    this.addManagedElementListeners(this.eToggleButton, { click: this.onButtonPressed.bind(this) });
    this.eToggleButton.setAttribute("id", `ag-${this.getCompId()}-button`);
  }
  createTemplate() {
    const res = (
      /* html */
      `<div class="ag-side-button" role="presentation">
                <button type="button" data-ref="eToggleButton" tabindex="-1" role="tab" aria-expanded="false" class="ag-button ag-side-button-button">
                    <div data-ref="eIconWrapper" class="ag-side-button-icon-wrapper" aria-hidden="true"></div>
                    <span data-ref="eLabel" class="ag-side-button-label"></span>
                </button>
            </div>`
    );
    return res;
  }
  setLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const def = this.toolPanelDef;
    const label = translate(def.labelKey, def.labelDefault);
    this.eLabel.innerText = label;
  }
  setIcon() {
    this.eIconWrapper.insertAdjacentElement("afterbegin", _createIconNoSpan12(this.toolPanelDef.iconKey, this.gos));
  }
  onButtonPressed() {
    this.dispatchLocalEvent({ type: "toggleButtonClicked" });
  }
  setSelected(selected) {
    this.addOrRemoveCssClass("ag-selected", selected);
    _setAriaExpanded6(this.eToggleButton, selected);
  }
  getButtonElement() {
    return this.eToggleButton;
  }
};

// enterprise-modules/side-bar/src/sideBar/agSideBarButtons.ts
var AgSideBarButtons = class extends Component45 {
  constructor() {
    super(
      /* html */
      `<div class="ag-side-buttons" role="tablist"></div>`
    );
    this.buttonComps = [];
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.visibleColsService = beans.visibleColsService;
  }
  postConstruct() {
    this.addManagedElementListeners(this.getFocusableElement(), { keydown: this.handleKeyDown.bind(this) });
  }
  handleKeyDown(e) {
    if (e.key !== KeyCode16.TAB || !e.shiftKey) {
      return;
    }
    if (this.focusService.focusNextGridCoreContainer(true)) {
      e.preventDefault();
      return;
    }
    _stopPropagationForAgGrid6(e);
  }
  setActiveButton(id) {
    this.buttonComps.forEach((comp) => {
      comp.setSelected(id === comp.getToolPanelId());
    });
  }
  addButtonComp(def) {
    const buttonComp = this.createBean(new SideBarButtonComp(def));
    this.buttonComps.push(buttonComp);
    this.appendChild(buttonComp);
    buttonComp.addEventListener("toggleButtonClicked", () => {
      this.dispatchLocalEvent({
        type: "sideBarButtonClicked",
        toolPanelId: def.id
      });
    });
    return buttonComp;
  }
  clearButtons() {
    this.buttonComps = this.destroyBeans(this.buttonComps);
    _clearElement8(this.getGui());
    super.destroy();
  }
  destroy() {
    this.clearButtons();
    super.destroy();
  }
};
var AgSideBarButtonsSelector = {
  selector: "AG-SIDE-BAR-BUTTONS",
  component: AgSideBarButtons
};

// enterprise-modules/side-bar/src/sideBar/sideBarDefParser.ts
import { _warnOnce as _warnOnce18 } from "ag-grid-community";
var DEFAULT_COLUMN_COMP = {
  id: "columns",
  labelDefault: "Columns",
  labelKey: "columns",
  iconKey: "columns",
  toolPanel: "agColumnsToolPanel"
};
var DEFAULT_FILTER_COMP = {
  id: "filters",
  labelDefault: "Filters",
  labelKey: "filters",
  iconKey: "filter",
  toolPanel: "agFiltersToolPanel"
};
var DEFAULT_BY_KEY = {
  columns: DEFAULT_COLUMN_COMP,
  filters: DEFAULT_FILTER_COMP
};
function parseSideBarDef(toParse) {
  if (!toParse) {
    return void 0;
  }
  if (toParse === true) {
    return {
      toolPanels: [DEFAULT_COLUMN_COMP, DEFAULT_FILTER_COMP],
      defaultToolPanel: "columns"
    };
  }
  if (typeof toParse === "string") {
    return parseSideBarDef([toParse]);
  }
  if (Array.isArray(toParse)) {
    const comps = [];
    toParse.forEach((key) => {
      const lookupResult = DEFAULT_BY_KEY[key];
      if (!lookupResult) {
        logMissingKey(key);
        return;
      }
      comps.push(lookupResult);
    });
    if (comps.length === 0) {
      return void 0;
    }
    return {
      toolPanels: comps,
      defaultToolPanel: comps[0].id
    };
  }
  const result = {
    toolPanels: parseComponents(toParse.toolPanels),
    defaultToolPanel: toParse.defaultToolPanel,
    hiddenByDefault: toParse.hiddenByDefault,
    position: toParse.position
  };
  return result;
}
function logMissingKey(key) {
  _warnOnce18(
    `the key ${key} is not a valid key for specifying a tool panel, valid keys are: ${Object.keys(DEFAULT_BY_KEY).join(",")}`
  );
}
function parseComponents(from) {
  const result = [];
  if (!from) {
    return result;
  }
  from.forEach((it) => {
    let toAdd = null;
    if (typeof it === "string") {
      const lookupResult = DEFAULT_BY_KEY[it];
      if (!lookupResult) {
        logMissingKey(it);
        return;
      }
      toAdd = lookupResult;
    } else {
      toAdd = it;
    }
    result.push(toAdd);
  });
  return result;
}

// enterprise-modules/side-bar/src/sideBar/toolPanelWrapper.ts
import { Component as Component47, _warnOnce as _warnOnce19 } from "ag-grid-community";

// enterprise-modules/side-bar/src/sideBar/agHorizontalResize.ts
import { Component as Component46 } from "ag-grid-community";
var AgHorizontalResize = class extends Component46 {
  constructor() {
    super(
      /* html */
      `<div class="ag-tool-panel-horizontal-resize"></div>`
    );
    this.minWidth = 100;
    this.maxWidth = null;
  }
  wireBeans(beans) {
    this.horizontalResizeService = beans.horizontalResizeService;
  }
  setElementToResize(elementToResize) {
    this.elementToResize = elementToResize;
  }
  postConstruct() {
    const finishedWithResizeFunc = this.horizontalResizeService.addResizeBar({
      eResizeBar: this.getGui(),
      dragStartPixels: 1,
      onResizeStart: this.onResizeStart.bind(this),
      onResizing: this.onResizing.bind(this),
      onResizeEnd: this.onResizeEnd.bind(this)
    });
    this.addDestroyFunc(finishedWithResizeFunc);
    this.setInverted(this.gos.get("enableRtl"));
  }
  dispatchResizeEvent(start, end, width) {
    this.eventService.dispatchEvent({
      type: "toolPanelSizeChanged",
      width,
      started: start,
      ended: end
    });
  }
  onResizeStart() {
    this.startingWidth = this.elementToResize.offsetWidth;
    this.dispatchResizeEvent(true, false, this.startingWidth);
  }
  onResizeEnd(delta) {
    return this.onResizing(delta, true);
  }
  onResizing(delta, isEnd = false) {
    const direction = this.inverted ? -1 : 1;
    let newWidth = Math.max(this.minWidth, Math.floor(this.startingWidth - delta * direction));
    if (this.maxWidth != null) {
      newWidth = Math.min(this.maxWidth, newWidth);
    }
    this.elementToResize.style.width = `${newWidth}px`;
    this.dispatchResizeEvent(false, isEnd, newWidth);
  }
  setInverted(inverted) {
    this.inverted = inverted;
  }
  setMaxWidth(value) {
    this.maxWidth = value;
  }
  setMinWidth(value) {
    if (value != null) {
      this.minWidth = value;
    } else {
      this.minWidth = 100;
    }
  }
};

// enterprise-modules/side-bar/src/sideBar/toolPanelWrapper.ts
var ToolPanelWrapper = class extends Component47 {
  wireBeans(beans) {
    this.userComponentFactory = beans.userComponentFactory;
  }
  constructor() {
    super(
      /* html */
      `<div class="ag-tool-panel-wrapper" role="tabpanel"/>`
    );
  }
  postConstruct() {
    const eGui = this.getGui();
    const resizeBar = this.resizeBar = this.createManagedBean(new AgHorizontalResize());
    eGui.setAttribute("id", `ag-${this.getCompId()}`);
    resizeBar.setElementToResize(eGui);
    this.appendChild(resizeBar);
  }
  getToolPanelId() {
    return this.toolPanelId;
  }
  setToolPanelDef(toolPanelDef, params) {
    const { id, minWidth, maxWidth, width } = toolPanelDef;
    this.toolPanelId = id;
    this.width = width;
    const compDetails = this.userComponentFactory.getToolPanelCompDetails(toolPanelDef, params);
    const componentPromise = compDetails.newAgStackInstance();
    this.params = compDetails.params;
    if (componentPromise == null) {
      _warnOnce19(`error processing tool panel component ${id}. You need to specify 'toolPanel'`);
      return;
    }
    componentPromise.then(this.setToolPanelComponent.bind(this));
    if (minWidth != null) {
      this.resizeBar.setMinWidth(minWidth);
    }
    if (maxWidth != null) {
      this.resizeBar.setMaxWidth(maxWidth);
    }
  }
  setToolPanelComponent(compInstance) {
    this.toolPanelCompInstance = compInstance;
    this.appendChild(compInstance.getGui());
    this.addDestroyFunc(() => {
      this.destroyBean(compInstance);
    });
    if (this.width) {
      this.getGui().style.width = `${this.width}px`;
    }
  }
  getToolPanelInstance() {
    return this.toolPanelCompInstance;
  }
  setResizerSizerSide(side) {
    const isRtl = this.gos.get("enableRtl");
    const isLeft = side === "left";
    const inverted = isRtl ? isLeft : !isLeft;
    this.resizeBar.setInverted(inverted);
  }
  refresh() {
    this.toolPanelCompInstance?.refresh(this.params);
  }
};

// enterprise-modules/side-bar/src/sideBar/agSideBar.ts
var AgSideBar = class extends Component48 {
  constructor() {
    super(
      /* html */
      `<div class="ag-side-bar ag-unselectable">
            <ag-side-bar-buttons data-ref="sideBarButtons"></ag-side-bar-buttons>
        </div>`,
      [AgSideBarButtonsSelector]
    );
    this.sideBarButtons = RefPlaceholder25;
    this.toolPanelWrappers = [];
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.filterManager = beans.filterManager;
    this.sideBarService = beans.sideBarService;
  }
  postConstruct() {
    this.sideBarButtons.addEventListener("sideBarButtonClicked", this.onToolPanelButtonClicked.bind(this));
    const { sideBar: sideBarState } = this.gos.get("initialState") ?? {};
    this.setSideBarDef({
      sideBarDef: parseSideBarDef(this.gos.get("sideBar")),
      sideBarState
    });
    this.addManagedPropertyListener("sideBar", this.onSideBarUpdated.bind(this));
    this.sideBarService.registerSideBarComp(this);
    const eGui = this.getFocusableElement();
    this.createManagedBean(
      new ManagedFocusFeature2(eGui, {
        onTabKeyDown: this.onTabKeyDown.bind(this),
        handleKeyDown: this.handleKeyDown.bind(this)
      })
    );
    _addFocusableContainerListener(this, eGui, this.focusService);
  }
  onTabKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }
    const { focusService, sideBarButtons } = this;
    const eGui = this.getGui();
    const sideBarGui = sideBarButtons.getGui();
    const activeElement = _getActiveDomElement9(this.gos);
    const openPanel = eGui.querySelector(".ag-tool-panel-wrapper:not(.ag-hidden)");
    const target = e.target;
    if (!openPanel) {
      return focusService.focusNextGridCoreContainer(e.shiftKey, true);
    }
    if (sideBarGui.contains(activeElement)) {
      if (focusService.focusInto(openPanel, e.shiftKey)) {
        e.preventDefault();
      }
      return;
    }
    if (!e.shiftKey) {
      return;
    }
    let nextEl = null;
    if (openPanel.contains(activeElement)) {
      nextEl = this.focusService.findNextFocusableElement(openPanel, void 0, true);
    } else if (focusService.isTargetUnderManagedComponent(openPanel, target) && e.shiftKey) {
      nextEl = this.focusService.findFocusableElementBeforeTabGuard(openPanel, target);
    }
    if (!nextEl) {
      nextEl = sideBarGui.querySelector(".ag-selected button");
    }
    if (nextEl && nextEl !== e.target) {
      e.preventDefault();
      nextEl.focus();
    }
  }
  handleKeyDown(e) {
    const currentButton = _getActiveDomElement9(this.gos);
    if (!this.sideBarButtons.getGui().contains(currentButton)) {
      return;
    }
    const sideBarGui = this.sideBarButtons.getGui();
    const buttons = Array.prototype.slice.call(sideBarGui.querySelectorAll(".ag-side-button"));
    const currentPos = buttons.findIndex((button) => button.contains(currentButton));
    let nextPos = null;
    switch (e.key) {
      case KeyCode17.LEFT:
      case KeyCode17.UP:
        nextPos = Math.max(0, currentPos - 1);
        break;
      case KeyCode17.RIGHT:
      case KeyCode17.DOWN:
        nextPos = Math.min(currentPos + 1, buttons.length - 1);
        break;
    }
    if (nextPos === null) {
      return;
    }
    const innerButton = buttons[nextPos].querySelector("button");
    if (innerButton) {
      innerButton.focus();
      e.preventDefault();
    }
  }
  onToolPanelButtonClicked(event) {
    const id = event.toolPanelId;
    const openedItem = this.openedItem();
    if (openedItem === id) {
      this.openToolPanel(void 0, "sideBarButtonClicked");
    } else {
      this.openToolPanel(id, "sideBarButtonClicked");
    }
  }
  clearDownUi() {
    this.sideBarButtons.clearButtons();
    this.destroyToolPanelWrappers();
  }
  setSideBarDef({
    sideBarDef,
    sideBarState,
    existingToolPanelWrappers
  }) {
    this.setDisplayed(false);
    this.sideBar = sideBarDef;
    if (!!this.sideBar && !!this.sideBar.toolPanels) {
      const toolPanelDefs = this.sideBar.toolPanels;
      this.createToolPanelsAndSideButtons(toolPanelDefs, sideBarState, existingToolPanelWrappers);
      if (!this.toolPanelWrappers.length) {
        return;
      }
      const shouldDisplaySideBar = sideBarState ? sideBarState.visible : !this.sideBar.hiddenByDefault;
      this.setDisplayed(shouldDisplaySideBar);
      this.setSideBarPosition(sideBarState ? sideBarState.position : this.sideBar.position);
      if (shouldDisplaySideBar) {
        if (sideBarState) {
          const { openToolPanel: openToolPanel2 } = sideBarState;
          if (openToolPanel2) {
            this.openToolPanel(openToolPanel2, "sideBarInitializing");
          }
        } else {
          this.openToolPanel(this.sideBar.defaultToolPanel, "sideBarInitializing");
        }
      }
    }
  }
  getDef() {
    return this.sideBar;
  }
  setSideBarPosition(position) {
    if (!position) {
      position = "right";
    }
    this.position = position;
    const isLeft = position === "left";
    const resizerSide = isLeft ? "right" : "left";
    this.addOrRemoveCssClass("ag-side-bar-left", isLeft);
    this.addOrRemoveCssClass("ag-side-bar-right", !isLeft);
    this.toolPanelWrappers.forEach((wrapper) => {
      wrapper.setResizerSizerSide(resizerSide);
    });
    this.dispatchSideBarUpdated();
    return this;
  }
  setDisplayed(displayed, options) {
    super.setDisplayed(displayed, options);
    this.dispatchSideBarUpdated();
  }
  getState() {
    const toolPanels = {};
    this.toolPanelWrappers.forEach((wrapper) => {
      toolPanels[wrapper.getToolPanelId()] = wrapper.getToolPanelInstance()?.getState?.();
    });
    return {
      visible: this.isDisplayed(),
      position: this.position,
      openToolPanel: this.openedItem(),
      toolPanels
    };
  }
  createToolPanelsAndSideButtons(defs, sideBarState, existingToolPanelWrappers) {
    for (const def of defs) {
      this.createToolPanelAndSideButton(
        def,
        sideBarState?.toolPanels?.[def.id],
        existingToolPanelWrappers?.[def.id]
      );
    }
  }
  validateDef(def) {
    if (def.id == null) {
      _warnOnce20(
        `please review all your toolPanel components, it seems like at least one of them doesn't have an id`
      );
      return false;
    }
    if (def.toolPanel === "agColumnsToolPanel") {
      const moduleMissing = !this.gos.assertModuleRegistered(
        ModuleNames7.ColumnsToolPanelModule,
        "Column Tool Panel"
      );
      if (moduleMissing) {
        return false;
      }
    }
    if (def.toolPanel === "agFiltersToolPanel") {
      const moduleMissing = !this.gos.assertModuleRegistered(
        ModuleNames7.FiltersToolPanelModule,
        "Filters Tool Panel"
      );
      if (moduleMissing) {
        return false;
      }
      if (this.filterManager?.isAdvancedFilterEnabled()) {
        _warnOnce20(
          "Advanced Filter does not work with Filters Tool Panel. Filters Tool Panel has been disabled."
        );
        return false;
      }
    }
    return true;
  }
  createToolPanelAndSideButton(def, initialState, existingToolPanelWrapper) {
    if (!this.validateDef(def)) {
      return;
    }
    const button = this.sideBarButtons.addButtonComp(def);
    let wrapper;
    if (existingToolPanelWrapper) {
      wrapper = existingToolPanelWrapper;
    } else {
      wrapper = this.createBean(new ToolPanelWrapper());
      wrapper.setToolPanelDef(def, {
        initialState,
        onStateUpdated: () => this.dispatchSideBarUpdated()
      });
    }
    wrapper.setDisplayed(false);
    const wrapperGui = wrapper.getGui();
    this.appendChild(wrapperGui);
    this.toolPanelWrappers.push(wrapper);
    _setAriaControls2(button.getButtonElement(), wrapperGui);
  }
  refresh() {
    this.toolPanelWrappers.forEach((wrapper) => wrapper.refresh());
  }
  openToolPanel(key, source = "api") {
    const currentlyOpenedKey = this.openedItem();
    if (currentlyOpenedKey === key) {
      return;
    }
    this.toolPanelWrappers.forEach((wrapper) => {
      const show = key === wrapper.getToolPanelId();
      wrapper.setDisplayed(show);
    });
    const newlyOpenedKey = this.openedItem();
    const openToolPanelChanged = currentlyOpenedKey !== newlyOpenedKey;
    if (openToolPanelChanged) {
      this.sideBarButtons.setActiveButton(key);
      this.raiseToolPanelVisibleEvent(key, currentlyOpenedKey ?? void 0, source);
    }
  }
  getToolPanelInstance(key) {
    const toolPanelWrapper = this.toolPanelWrappers.filter((toolPanel) => toolPanel.getToolPanelId() === key)[0];
    if (!toolPanelWrapper) {
      _warnOnce20(`unable to lookup Tool Panel as invalid key supplied: ${key}`);
      return;
    }
    return toolPanelWrapper.getToolPanelInstance();
  }
  raiseToolPanelVisibleEvent(key, previousKey, source) {
    const switchingToolPanel = !!key && !!previousKey;
    if (previousKey) {
      this.eventService.dispatchEvent({
        type: "toolPanelVisibleChanged",
        source,
        key: previousKey,
        visible: false,
        switchingToolPanel
      });
    }
    if (key) {
      this.eventService.dispatchEvent({
        type: "toolPanelVisibleChanged",
        source,
        key,
        visible: true,
        switchingToolPanel
      });
    }
  }
  close(source = "api") {
    this.openToolPanel(void 0, source);
  }
  isToolPanelShowing() {
    return !!this.openedItem();
  }
  openedItem() {
    let activeToolPanel = null;
    this.toolPanelWrappers.forEach((wrapper) => {
      if (wrapper.isDisplayed()) {
        activeToolPanel = wrapper.getToolPanelId();
      }
    });
    return activeToolPanel;
  }
  onSideBarUpdated() {
    const sideBarDef = parseSideBarDef(this.gos.get("sideBar"));
    const existingToolPanelWrappers = {};
    if (sideBarDef && this.sideBar) {
      sideBarDef.toolPanels?.forEach((toolPanelDef) => {
        const { id } = toolPanelDef;
        if (!id) {
          return;
        }
        const existingToolPanelDef = this.sideBar.toolPanels?.find(
          (toolPanelDefToCheck) => toolPanelDefToCheck.id === id
        );
        if (!existingToolPanelDef || toolPanelDef.toolPanel !== existingToolPanelDef.toolPanel) {
          return;
        }
        const toolPanelWrapper = this.toolPanelWrappers.find((toolPanel) => toolPanel.getToolPanelId() === id);
        if (!toolPanelWrapper) {
          return;
        }
        const params = this.gos.addGridCommonParams({
          ...toolPanelDef.toolPanelParams ?? {},
          onStateUpdated: () => this.dispatchSideBarUpdated()
        });
        const hasRefreshed = toolPanelWrapper.getToolPanelInstance()?.refresh(params);
        if (hasRefreshed !== true) {
          return;
        }
        this.toolPanelWrappers = this.toolPanelWrappers.filter((toolPanel) => toolPanel !== toolPanelWrapper);
        _removeFromParent8(toolPanelWrapper.getGui());
        existingToolPanelWrappers[id] = toolPanelWrapper;
      });
    }
    this.clearDownUi();
    this.setSideBarDef({ sideBarDef, existingToolPanelWrappers });
  }
  dispatchSideBarUpdated() {
    this.eventService.dispatchEvent({ type: "sideBarUpdated" });
  }
  destroyToolPanelWrappers() {
    this.toolPanelWrappers.forEach((wrapper) => {
      _removeFromParent8(wrapper.getGui());
      this.destroyBean(wrapper);
    });
    this.toolPanelWrappers.length = 0;
  }
  destroy() {
    this.destroyToolPanelWrappers();
    super.destroy();
  }
};
var AgSideBarSelector = {
  selector: "AG-SIDE-BAR",
  component: AgSideBar
};

// enterprise-modules/side-bar/src/sideBar/sideBarService.ts
var SideBarService = class extends BeanStub19 {
  constructor() {
    super(...arguments);
    this.beanName = "sideBarService";
  }
  registerSideBarComp(sideBarComp) {
    this.sideBarComp = sideBarComp;
  }
  getSideBarComp() {
    return this.sideBarComp;
  }
  getSideBarSelector() {
    return AgSideBarSelector;
  }
};

// enterprise-modules/side-bar/src/version.ts
var VERSION7 = "32.3.0";

// enterprise-modules/side-bar/src/sideBarModule.ts
var SideBarCoreModule = _defineModule7({
  version: VERSION7,
  moduleName: `${ModuleNames8.SideBarModule}-core`,
  beans: [ToolPanelColDefService, SideBarService],
  dependantModules: [EnterpriseCoreModule]
});
var SideBarApiModule = _defineModule7({
  version: VERSION7,
  moduleName: `${ModuleNames8.SideBarModule}-api`,
  apiFunctions: {
    isSideBarVisible,
    setSideBarVisible,
    setSideBarPosition,
    openToolPanel,
    closeToolPanel,
    getOpenedToolPanel,
    refreshToolPanel,
    isToolPanelShowing,
    getToolPanelInstance,
    getSideBar
  },
  dependantModules: [SideBarCoreModule]
});
var SideBarModule = _defineModule7({
  version: VERSION7,
  moduleName: ModuleNames8.SideBarModule,
  dependantModules: [SideBarCoreModule, SideBarApiModule]
});

// enterprise-modules/column-tool-panel/dist/package/main.esm.mjs
import { Component as Component84, ModuleNames as ModuleNames9, _clearElement as _clearElement9, _last as _last9 } from "ag-grid-community";
import { Component as Component64, PositionableFeature as PositionableFeature3, RefPlaceholder as RefPlaceholder44 } from "ag-grid-community";
import {
  AgCheckboxSelector as AgCheckboxSelector4,
  AgInputTextFieldSelector as AgInputTextFieldSelector4,
  Component as Component49,
  KeyCode as KeyCode18,
  RefPlaceholder as RefPlaceholder26,
  _createIconNoSpan as _createIconNoSpan13,
  _debounce as _debounce2,
  _setDisplayed as _setDisplayed10
} from "ag-grid-community";
import {
  Component as Component54,
  _exists as _exists12,
  _includes as _includes10,
  _setAriaLabel as _setAriaLabel33,
  _setAriaLevel as _setAriaLevel3,
  _warnOnce as _warnOnce25,
  isProvidedColumnGroup as isProvidedColumnGroup3
} from "ag-grid-community";
import { LocalEventService } from "ag-grid-community";
import { BeanStub as BeanStub20, DragSourceType as DragSourceType23, isProvidedColumnGroup as isProvidedColumnGroup2 } from "ag-grid-community";
import {
  AgCheckboxSelector as AgCheckboxSelector23,
  Component as Component311,
  DragSourceType as DragSourceType5,
  KeyCode as KeyCode24,
  RefPlaceholder as RefPlaceholder27,
  TouchListener as TouchListener2,
  _createIcon as _createIcon2,
  _createIconNoSpan as _createIconNoSpan34,
  _getToolPanelClassesFromColDef,
  _setAriaDescribedBy as _setAriaDescribedBy2,
  _setAriaExpanded as _setAriaExpanded7,
  _setAriaLabel as _setAriaLabel8,
  _setDisplayed as _setDisplayed24
} from "ag-grid-community";
import { Component as Component211, _createIconNoSpan as _createIconNoSpan25, isColumn, isProvidedColumnGroup as isProvidedColumnGroup4 } from "ag-grid-community";
import {
  AgCheckboxSelector as AgCheckboxSelector32,
  Component as Component410,
  DragSourceType as DragSourceType32,
  KeyCode as KeyCode33,
  RefPlaceholder as RefPlaceholder35,
  _createIconNoSpan as _createIconNoSpan42,
  _escapeString as _escapeString6,
  _getToolPanelClassesFromColDef as _getToolPanelClassesFromColDef2,
  _setAriaDescribedBy as _setAriaDescribedBy22,
  _setAriaLabel as _setAriaLabel23,
  _setDisplayed as _setDisplayed34,
  _warnOnce as _warnOnce21
} from "ag-grid-community";
import { AgToggleButtonSelector, Component as Component74, RefPlaceholder as RefPlaceholder54 } from "ag-grid-community";
import { BeanStub as BeanStub26 } from "ag-grid-community";
var DEBOUNCE_DELAY = 300;
var AgPrimaryColsHeader = class extends Component49 {
  constructor() {
    super(
      /* html */
      `<div class="ag-column-select-header" role="presentation">
            <div data-ref="eExpand" class="ag-column-select-header-icon"></div>
            <ag-checkbox data-ref="eSelect" class="ag-column-select-header-checkbox"></ag-checkbox>
            <ag-input-text-field class="ag-column-select-header-filter-wrapper" data-ref="eFilterTextField"></ag-input-text-field>
        </div>`,
      [AgCheckboxSelector4, AgInputTextFieldSelector4]
    );
    this.eExpand = RefPlaceholder26;
    this.eSelect = RefPlaceholder26;
    this.eFilterTextField = RefPlaceholder26;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
  }
  postConstruct() {
    this.createExpandIcons();
    this.addManagedListeners(this.eExpand, {
      click: this.onExpandClicked.bind(this),
      keydown: (e) => {
        if (e.key === KeyCode18.SPACE) {
          e.preventDefault();
          this.onExpandClicked();
        }
      }
    });
    this.addManagedElementListeners(this.eSelect.getInputElement(), { click: this.onSelectClicked.bind(this) });
    this.addManagedPropertyListener("functionsReadOnly", () => this.onFunctionsReadOnlyPropChanged());
    this.eFilterTextField.setAutoComplete(false).onValueChange(() => this.onFilterTextChanged());
    this.addManagedElementListeners(this.eFilterTextField.getInputElement(), {
      keydown: this.onMiniFilterKeyDown.bind(this)
    });
    this.addManagedEventListeners({ newColumnsLoaded: this.showOrHideOptions.bind(this) });
    const translate = this.localeService.getLocaleTextFunc();
    this.eSelect.setInputAriaLabel(translate("ariaColumnSelectAll", "Toggle All Columns Visibility"));
    this.eFilterTextField.setInputAriaLabel(translate("ariaFilterColumnsInput", "Filter Columns Input"));
    this.activateTabIndex([this.eExpand]);
  }
  onFunctionsReadOnlyPropChanged() {
    const readOnly = this.gos.get("functionsReadOnly");
    this.eSelect.setReadOnly(readOnly);
    this.eSelect.addOrRemoveCssClass("ag-column-select-column-readonly", readOnly);
  }
  init(params) {
    this.params = params;
    const readOnly = this.gos.get("functionsReadOnly");
    this.eSelect.setReadOnly(readOnly);
    this.eSelect.addOrRemoveCssClass("ag-column-select-column-readonly", readOnly);
    if (this.columnModel.isReady()) {
      this.showOrHideOptions();
    }
  }
  createExpandIcons() {
    this.eExpand.appendChild(this.eExpandChecked = _createIconNoSpan13("columnSelectOpen", this.gos));
    this.eExpand.appendChild(this.eExpandUnchecked = _createIconNoSpan13("columnSelectClosed", this.gos));
    this.eExpand.appendChild(
      this.eExpandIndeterminate = _createIconNoSpan13("columnSelectIndeterminate", this.gos)
    );
    this.setExpandState(
      0
      /* EXPANDED */
    );
  }
  // we only show expand / collapse if we are showing columns
  showOrHideOptions() {
    const showFilter = !this.params.suppressColumnFilter;
    const showSelect = !this.params.suppressColumnSelectAll;
    const showExpand = !this.params.suppressColumnExpandAll;
    const groupsPresent = this.columnModel.isProvidedColGroupsPresent();
    const translate = this.localeService.getLocaleTextFunc();
    this.eFilterTextField.setInputPlaceholder(translate("searchOoo", "Search..."));
    _setDisplayed10(this.eFilterTextField.getGui(), showFilter);
    _setDisplayed10(this.eSelect.getGui(), showSelect);
    _setDisplayed10(this.eExpand, showExpand && groupsPresent);
  }
  onFilterTextChanged() {
    if (!this.onFilterTextChangedDebounced) {
      this.onFilterTextChangedDebounced = _debounce2(() => {
        const filterText = this.eFilterTextField.getValue();
        this.dispatchLocalEvent({ type: "filterChanged", filterText });
      }, DEBOUNCE_DELAY);
    }
    this.onFilterTextChangedDebounced();
  }
  onMiniFilterKeyDown(e) {
    if (e.key === KeyCode18.ENTER) {
      setTimeout(() => this.onSelectClicked(), DEBOUNCE_DELAY);
    }
  }
  onSelectClicked() {
    this.dispatchLocalEvent({ type: this.selectState ? "unselectAll" : "selectAll" });
  }
  onExpandClicked() {
    this.dispatchLocalEvent({ type: this.expandState === 0 ? "collapseAll" : "expandAll" });
  }
  setExpandState(state) {
    this.expandState = state;
    _setDisplayed10(
      this.eExpandChecked,
      this.expandState === 0
      /* EXPANDED */
    );
    _setDisplayed10(
      this.eExpandUnchecked,
      this.expandState === 1
      /* COLLAPSED */
    );
    _setDisplayed10(
      this.eExpandIndeterminate,
      this.expandState === 2
      /* INDETERMINATE */
    );
  }
  setSelectionState(state) {
    this.selectState = state;
    this.eSelect.setValue(this.selectState);
  }
};
var AgPrimaryColsHeaderSelector = {
  selector: "AG-PRIMARY-COLS-HEADER",
  component: AgPrimaryColsHeader
};
var ColumnModelItem = class {
  constructor(displayName, columnOrGroup, dept, group = false, expanded) {
    this.localEventService = new LocalEventService();
    this.displayName = displayName;
    this.dept = dept;
    this.group = group;
    if (group) {
      this.columnGroup = columnOrGroup;
      this.expanded = expanded;
      this.children = [];
    } else {
      this.column = columnOrGroup;
    }
  }
  isGroup() {
    return this.group;
  }
  getDisplayName() {
    return this.displayName;
  }
  getColumnGroup() {
    return this.columnGroup;
  }
  getColumn() {
    return this.column;
  }
  getDept() {
    return this.dept;
  }
  isExpanded() {
    return !!this.expanded;
  }
  getChildren() {
    return this.children;
  }
  isPassesFilter() {
    return this.passesFilter;
  }
  setExpanded(expanded) {
    if (expanded === this.expanded) {
      return;
    }
    this.expanded = expanded;
    this.localEventService.dispatchEvent({ type: "expandedChanged" });
  }
  setPassesFilter(passesFilter) {
    this.passesFilter = passesFilter;
  }
  addEventListener(eventType, listener) {
    this.localEventService.addEventListener(eventType, listener);
  }
  removeEventListener(eventType, listener) {
    this.localEventService.removeEventListener(eventType, listener);
  }
};
var ToolPanelContextMenu = class extends Component211 {
  constructor(column, mouseEvent, parentEl) {
    super(
      /* html */
      `<div class="ag-menu"></div>`
    );
    this.column = column;
    this.mouseEvent = mouseEvent;
    this.parentEl = parentEl;
    this.displayName = null;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.funcColsService = beans.funcColsService;
    this.popupService = beans.popupService;
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.initializeProperties(this.column);
    this.buildMenuItemMap();
    if (isColumn(this.column)) {
      this.displayName = this.columnNameService.getDisplayNameForColumn(this.column, "columnToolPanel");
    } else {
      this.displayName = this.columnNameService.getDisplayNameForProvidedColumnGroup(
        null,
        this.column,
        "columnToolPanel"
      );
    }
    if (this.isActive()) {
      this.mouseEvent.preventDefault();
      const menuItemsMapped = this.getMappedMenuItems();
      if (menuItemsMapped.length === 0) {
        return;
      }
      this.displayContextMenu(menuItemsMapped);
    }
  }
  initializeProperties(column) {
    if (isProvidedColumnGroup4(column)) {
      this.columns = column.getLeafColumns();
    } else {
      this.columns = [column];
    }
    this.allowGrouping = this.columns.some((col) => col.isPrimary() && col.isAllowRowGroup());
    this.allowValues = this.columns.some((col) => col.isPrimary() && col.isAllowValue());
    this.allowPivoting = this.columnModel.isPivotMode() && this.columns.some((col) => col.isPrimary() && col.isAllowPivot());
  }
  buildMenuItemMap() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.menuItemMap = /* @__PURE__ */ new Map();
    this.menuItemMap.set("rowGroup", {
      allowedFunction: (col) => col.isPrimary() && col.isAllowRowGroup() && !this.columnModel.isColGroupLocked(col),
      activeFunction: (col) => col.isRowGroupActive(),
      activateLabel: () => `${localeTextFunc("groupBy", "Group by")} ${this.displayName}`,
      deactivateLabel: () => `${localeTextFunc("ungroupBy", "Un-Group by")} ${this.displayName}`,
      activateFunction: () => {
        const groupedColumns = this.funcColsService.getRowGroupColumns();
        this.funcColsService.setRowGroupColumns(this.addColumnsToList(groupedColumns), "toolPanelUi");
      },
      deActivateFunction: () => {
        const groupedColumns = this.funcColsService.getRowGroupColumns();
        this.funcColsService.setRowGroupColumns(this.removeColumnsFromList(groupedColumns), "toolPanelUi");
      },
      addIcon: "menuAddRowGroup",
      removeIcon: "menuRemoveRowGroup"
    });
    this.menuItemMap.set("value", {
      allowedFunction: (col) => col.isPrimary() && col.isAllowValue(),
      activeFunction: (col) => col.isValueActive(),
      activateLabel: () => localeTextFunc("addToValues", `Add ${this.displayName} to values`, [this.displayName]),
      deactivateLabel: () => localeTextFunc("removeFromValues", `Remove ${this.displayName} from values`, [this.displayName]),
      activateFunction: () => {
        const valueColumns = this.funcColsService.getValueColumns();
        this.funcColsService.setValueColumns(this.addColumnsToList(valueColumns), "toolPanelUi");
      },
      deActivateFunction: () => {
        const valueColumns = this.funcColsService.getValueColumns();
        this.funcColsService.setValueColumns(this.removeColumnsFromList(valueColumns), "toolPanelUi");
      },
      addIcon: "valuePanel",
      removeIcon: "valuePanel"
    });
    this.menuItemMap.set("pivot", {
      allowedFunction: (col) => this.columnModel.isPivotMode() && col.isPrimary() && col.isAllowPivot(),
      activeFunction: (col) => col.isPivotActive(),
      activateLabel: () => localeTextFunc("addToLabels", `Add ${this.displayName} to labels`, [this.displayName]),
      deactivateLabel: () => localeTextFunc("removeFromLabels", `Remove ${this.displayName} from labels`, [this.displayName]),
      activateFunction: () => {
        const pivotColumns = this.funcColsService.getPivotColumns();
        this.funcColsService.setPivotColumns(this.addColumnsToList(pivotColumns), "toolPanelUi");
      },
      deActivateFunction: () => {
        const pivotColumns = this.funcColsService.getPivotColumns();
        this.funcColsService.setPivotColumns(this.removeColumnsFromList(pivotColumns), "toolPanelUi");
      },
      addIcon: "pivotPanel",
      removeIcon: "pivotPanel"
    });
  }
  addColumnsToList(columnList) {
    return [...columnList].concat(this.columns.filter((col) => columnList.indexOf(col) === -1));
  }
  removeColumnsFromList(columnList) {
    return columnList.filter((col) => this.columns.indexOf(col) === -1);
  }
  displayContextMenu(menuItemsMapped) {
    const eGui = this.getGui();
    const menuList = this.createBean(new AgMenuList());
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    let hideFunc = () => {
    };
    eGui.appendChild(menuList.getGui());
    menuList.addMenuItems(menuItemsMapped);
    menuList.addManagedListeners(menuList, {
      closeMenu: () => {
        this.parentEl.focus();
        hideFunc();
      }
    });
    const addPopupRes = this.popupService.addPopup({
      modal: true,
      eChild: eGui,
      closeOnEsc: true,
      afterGuiAttached: () => this.focusService.focusInto(menuList.getGui()),
      ariaLabel: localeTextFunc("ariaLabelContextMenu", "Context Menu"),
      closedCallback: (e) => {
        if (e instanceof KeyboardEvent) {
          this.parentEl.focus();
        }
        this.destroyBean(menuList);
      }
    });
    if (addPopupRes) {
      hideFunc = addPopupRes.hideFunc;
    }
    this.popupService.positionPopupUnderMouseEvent({
      type: "columnContextMenu",
      mouseEvent: this.mouseEvent,
      ePopup: eGui
    });
  }
  isActive() {
    return this.allowGrouping || this.allowValues || this.allowPivoting;
  }
  getMappedMenuItems() {
    const ret = [];
    for (const val of this.menuItemMap.values()) {
      const isInactive = this.columns.some((col) => val.allowedFunction(col) && !val.activeFunction(col));
      const isActive = this.columns.some((col) => val.allowedFunction(col) && val.activeFunction(col));
      if (isInactive) {
        ret.push({
          name: val.activateLabel(this.displayName),
          icon: _createIconNoSpan25(val.addIcon, this.gos, null),
          action: () => val.activateFunction()
        });
      }
      if (isActive) {
        ret.push({
          name: val.deactivateLabel(this.displayName),
          icon: _createIconNoSpan25(val.removeIcon, this.gos, null),
          action: () => val.deActivateFunction()
        });
      }
    }
    return ret;
  }
};
var ToolPanelColumnGroupComp = class extends Component311 {
  constructor(modelItem, allowDragging, eventType, focusWrapper) {
    super();
    this.modelItem = modelItem;
    this.allowDragging = allowDragging;
    this.eventType = eventType;
    this.focusWrapper = focusWrapper;
    this.cbSelect = RefPlaceholder27;
    this.eLabel = RefPlaceholder27;
    this.eGroupOpenedIcon = RefPlaceholder27;
    this.eGroupClosedIcon = RefPlaceholder27;
    this.eColumnGroupIcons = RefPlaceholder27;
    this.processingColumnStateChange = false;
    this.modelItem = modelItem;
    this.columnGroup = modelItem.getColumnGroup();
    this.columnDept = modelItem.getDept();
    this.displayName = modelItem.getDisplayName();
    this.allowDragging = allowDragging;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.dragAndDropService = beans.dragAndDropService;
    this.modelItemUtils = beans.modelItemUtils;
  }
  postConstruct() {
    this.setTemplate(
      /* html */
      `<div class="ag-column-select-column-group">
                <span class="ag-column-group-icons" data-ref="eColumnGroupIcons" >
                    <span class="ag-column-group-closed-icon" data-ref="eGroupClosedIcon"></span>
                    <span class="ag-column-group-opened-icon" data-ref="eGroupOpenedIcon"></span>
                </span>
                <ag-checkbox data-ref="cbSelect" class="ag-column-select-checkbox"></ag-checkbox>
                <span class="ag-column-select-column-label" data-ref="eLabel"></span>
            </div>`,
      [AgCheckboxSelector23]
    );
    this.eDragHandle = _createIconNoSpan34("columnDrag", this.gos);
    this.eDragHandle.classList.add("ag-drag-handle", "ag-column-select-column-group-drag-handle");
    const checkboxGui = this.cbSelect.getGui();
    const checkboxInput = this.cbSelect.getInputElement();
    checkboxGui.insertAdjacentElement("afterend", this.eDragHandle);
    checkboxInput.setAttribute("tabindex", "-1");
    this.eLabel.innerHTML = this.displayName ? this.displayName : "";
    this.setupExpandContract();
    this.addCssClass("ag-column-select-indent-" + this.columnDept);
    this.getGui().style.setProperty("--ag-indentation-level", String(this.columnDept));
    this.addManagedEventListeners({ columnPivotModeChanged: this.onColumnStateChanged.bind(this) });
    this.addManagedElementListeners(this.eLabel, { click: this.onLabelClicked.bind(this) });
    this.addManagedListeners(this.cbSelect, { fieldValueChanged: this.onCheckboxChanged.bind(this) });
    this.addManagedListeners(this.modelItem, { expandedChanged: this.onExpandChanged.bind(this) });
    this.addManagedListeners(this.focusWrapper, {
      keydown: this.handleKeyDown.bind(this),
      contextmenu: this.onContextMenu.bind(this)
    });
    this.setOpenClosedIcons();
    this.setupDragging();
    this.onColumnStateChanged();
    this.addVisibilityListenersToAllChildren();
    this.refreshAriaExpanded();
    this.refreshAriaLabel();
    this.setupTooltip();
    const classes = _getToolPanelClassesFromColDef(
      this.columnGroup.getColGroupDef(),
      this.gos,
      null,
      this.columnGroup
    );
    classes.forEach((c) => this.addOrRemoveCssClass(c, true));
  }
  getColumns() {
    return this.columnGroup.getLeafColumns();
  }
  setupTooltip() {
    const colGroupDef = this.columnGroup.getColGroupDef();
    if (!colGroupDef) {
      return;
    }
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    let shouldDisplayTooltip;
    if (isTooltipWhenTruncated) {
      shouldDisplayTooltip = () => this.eLabel.scrollWidth > this.eLabel.clientWidth;
    }
    const refresh = () => {
      const newTooltipText = colGroupDef.headerTooltip;
      this.setTooltip({ newTooltipText, location: "columnToolPanelColumnGroup", shouldDisplayTooltip });
    };
    refresh();
    this.addManagedEventListeners({ newColumnsLoaded: refresh });
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "columnToolPanelColumnGroup";
    return res;
  }
  handleKeyDown(e) {
    switch (e.key) {
      case KeyCode24.LEFT:
        e.preventDefault();
        this.modelItem.setExpanded(false);
        break;
      case KeyCode24.RIGHT:
        e.preventDefault();
        this.modelItem.setExpanded(true);
        break;
      case KeyCode24.SPACE:
        e.preventDefault();
        if (this.isSelectable()) {
          this.onSelectAllChanged(!this.isSelected());
        }
        break;
    }
  }
  onContextMenu(e) {
    const { columnGroup, gos } = this;
    if (gos.get("functionsReadOnly")) {
      return;
    }
    const contextMenu = this.createBean(new ToolPanelContextMenu(columnGroup, e, this.focusWrapper));
    this.addDestroyFunc(() => {
      if (contextMenu.isAlive()) {
        this.destroyBean(contextMenu);
      }
    });
  }
  addVisibilityListenersToAllChildren() {
    const listener = this.onColumnStateChanged.bind(this);
    this.columnGroup.getLeafColumns().forEach((column) => {
      this.addManagedListeners(column, {
        visibleChanged: listener,
        columnValueChanged: listener,
        columnPivotChanged: listener,
        columnRowGroupChanged: listener
      });
    });
  }
  setupDragging() {
    if (!this.allowDragging) {
      _setDisplayed24(this.eDragHandle, false);
      return;
    }
    let hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
    const dragSource = {
      type: DragSourceType5.ToolPanel,
      eElement: this.eDragHandle,
      dragItemName: this.displayName,
      getDefaultIconName: () => hideColumnOnExit ? "hide" : "notAllowed",
      getDragItem: () => this.createDragItem(),
      onDragStarted: () => {
        hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
        this.eventService.dispatchEvent({
          type: "columnPanelItemDragStart",
          column: this.columnGroup
        });
      },
      onDragStopped: () => {
        this.eventService.dispatchEvent({
          type: "columnPanelItemDragEnd"
        });
      },
      onGridEnter: (dragItem) => {
        if (hideColumnOnExit) {
          this.modelItemUtils.updateColumns({
            columns: this.columnGroup.getLeafColumns(),
            visibleState: dragItem?.visibleState,
            pivotState: dragItem?.pivotState,
            eventType: this.eventType
          });
        }
      },
      onGridExit: () => {
        if (hideColumnOnExit) {
          this.onChangeCommon(false);
        }
      }
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  createDragItem() {
    const columns = this.columnGroup.getLeafColumns();
    const visibleState = {};
    const pivotState = {};
    columns.forEach((col) => {
      const colId = col.getId();
      visibleState[colId] = col.isVisible();
      pivotState[colId] = this.modelItemUtils.createPivotState(col);
    });
    return {
      columns,
      visibleState,
      pivotState
    };
  }
  setupExpandContract() {
    this.eGroupClosedIcon.appendChild(_createIcon2("columnSelectClosed", this.gos, null));
    this.eGroupOpenedIcon.appendChild(_createIcon2("columnSelectOpen", this.gos, null));
    const listener = this.onExpandOrContractClicked.bind(this);
    this.addManagedElementListeners(this.eGroupClosedIcon, { click: listener });
    this.addManagedElementListeners(this.eGroupOpenedIcon, { click: listener });
    const touchListener = new TouchListener2(this.eColumnGroupIcons, true);
    this.addManagedListeners(touchListener, { tap: listener });
    this.addDestroyFunc(touchListener.destroy.bind(touchListener));
  }
  onLabelClicked() {
    const nextState = !this.cbSelect.getValue();
    this.onChangeCommon(nextState);
  }
  onCheckboxChanged(event) {
    this.onChangeCommon(event.selected);
  }
  getVisibleLeafColumns() {
    const childColumns = [];
    const extractCols = (children) => {
      children.forEach((child) => {
        if (!child.isPassesFilter()) {
          return;
        }
        if (child.isGroup()) {
          extractCols(child.getChildren());
        } else {
          childColumns.push(child.getColumn());
        }
      });
    };
    extractCols(this.modelItem.getChildren());
    return childColumns;
  }
  onChangeCommon(nextState) {
    this.refreshAriaLabel();
    if (this.processingColumnStateChange) {
      return;
    }
    this.modelItemUtils.selectAllChildren(this.modelItem.getChildren(), nextState, this.eventType);
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const columnLabel = translate("ariaColumnGroup", "Column Group");
    const checkboxValue = this.cbSelect.getValue();
    const state = checkboxValue === void 0 ? translate("ariaIndeterminate", "indeterminate") : checkboxValue ? translate("ariaVisible", "visible") : translate("ariaHidden", "hidden");
    const visibilityLabel = translate("ariaToggleVisibility", "Press SPACE to toggle visibility");
    _setAriaLabel8(this.focusWrapper, `${this.displayName} ${columnLabel}`);
    this.cbSelect.setInputAriaLabel(`${visibilityLabel} (${state})`);
    _setAriaDescribedBy2(this.focusWrapper, this.cbSelect.getInputElement().id);
  }
  onColumnStateChanged() {
    const selectedValue = this.workOutSelectedValue();
    const readOnlyValue = this.workOutReadOnlyValue();
    this.processingColumnStateChange = true;
    this.cbSelect.setValue(selectedValue);
    this.cbSelect.setReadOnly(readOnlyValue);
    this.addOrRemoveCssClass("ag-column-select-column-group-readonly", readOnlyValue);
    this.processingColumnStateChange = false;
  }
  workOutSelectedValue() {
    const pivotMode = this.columnModel.isPivotMode();
    const visibleLeafColumns = this.getVisibleLeafColumns();
    let checkedCount = 0;
    let uncheckedCount = 0;
    visibleLeafColumns.forEach((column) => {
      if (!pivotMode && column.getColDef().lockVisible) {
        return;
      }
      if (this.isColumnChecked(column, pivotMode)) {
        checkedCount++;
      } else {
        uncheckedCount++;
      }
    });
    if (checkedCount > 0 && uncheckedCount > 0) {
      return void 0;
    }
    return checkedCount > 0;
  }
  workOutReadOnlyValue() {
    const pivotMode = this.columnModel.isPivotMode();
    let colsThatCanAction = 0;
    this.columnGroup.getLeafColumns().forEach((col) => {
      if (pivotMode) {
        if (col.isAnyFunctionAllowed()) {
          colsThatCanAction++;
        }
      } else {
        if (!col.getColDef().lockVisible) {
          colsThatCanAction++;
        }
      }
    });
    return colsThatCanAction === 0;
  }
  isColumnChecked(column, pivotMode) {
    if (pivotMode) {
      const pivoted = column.isPivotActive();
      const grouped = column.isRowGroupActive();
      const aggregated = column.isValueActive();
      return pivoted || grouped || aggregated;
    }
    return column.isVisible();
  }
  onExpandOrContractClicked() {
    const oldState = this.modelItem.isExpanded();
    this.modelItem.setExpanded(!oldState);
  }
  onExpandChanged() {
    this.setOpenClosedIcons();
    this.refreshAriaExpanded();
  }
  setOpenClosedIcons() {
    const folderOpen = this.modelItem.isExpanded();
    _setDisplayed24(this.eGroupClosedIcon, !folderOpen);
    _setDisplayed24(this.eGroupOpenedIcon, folderOpen);
  }
  refreshAriaExpanded() {
    _setAriaExpanded7(this.focusWrapper, this.modelItem.isExpanded());
  }
  getDisplayName() {
    return this.displayName;
  }
  onSelectAllChanged(value) {
    const cbValue = this.cbSelect.getValue();
    const readOnly = this.cbSelect.isReadOnly();
    if (!readOnly && (value && !cbValue || !value && cbValue)) {
      this.cbSelect.toggle();
    }
  }
  isSelected() {
    return this.cbSelect.getValue();
  }
  isSelectable() {
    return !this.cbSelect.isReadOnly();
  }
  setSelected(selected) {
    this.cbSelect.setValue(selected, true);
  }
};
var PrimaryColsListPanelItemDragFeature = class extends BeanStub20 {
  constructor(comp, virtualList) {
    super();
    this.comp = comp;
    this.virtualList = virtualList;
  }
  wireBeans(beans) {
    this.columnMoveService = beans.columnMoveService;
    this.columnModel = beans.columnModel;
  }
  postConstruct() {
    this.createManagedBean(
      new VirtualListDragFeature(this.comp, this.virtualList, {
        dragSourceType: DragSourceType23.ToolPanel,
        listItemDragStartEvent: "columnPanelItemDragStart",
        listItemDragEndEvent: "columnPanelItemDragEnd",
        eventSource: this.eventService,
        getCurrentDragValue: (listItemDragStartEvent) => this.getCurrentDragValue(listItemDragStartEvent),
        isMoveBlocked: (currentDragValue) => this.isMoveBlocked(currentDragValue),
        getNumRows: (comp) => comp.getDisplayedColsList().length,
        moveItem: (currentDragValue, lastHoveredListItem) => this.moveItem(this.getCurrentColumnsBeingMoved(currentDragValue), lastHoveredListItem)
      })
    );
  }
  getCurrentDragValue(listItemDragStartEvent) {
    return listItemDragStartEvent.column;
  }
  getCurrentColumnsBeingMoved(column) {
    if (isProvidedColumnGroup2(column)) {
      return column.getLeafColumns();
    }
    return column ? [column] : [];
  }
  isMoveBlocked(currentDragValue) {
    const preventMoving = this.gos.get("suppressMovableColumns");
    if (preventMoving) {
      return true;
    }
    const currentColumns = this.getCurrentColumnsBeingMoved(currentDragValue);
    const hasNotMovable = currentColumns.find((col) => {
      const colDef = col.getColDef();
      return !!colDef.suppressMovable || !!colDef.lockPosition;
    });
    return !!hasNotMovable;
  }
  moveItem(currentColumns, lastHoveredListItem) {
    if (!lastHoveredListItem) {
      return;
    }
    const { component } = lastHoveredListItem;
    let lastHoveredColumn = null;
    let isBefore = lastHoveredListItem.position === "top";
    if (component instanceof ToolPanelColumnGroupComp) {
      const columns = component.getColumns();
      lastHoveredColumn = columns[0];
      isBefore = true;
    } else if (component) {
      lastHoveredColumn = component.getColumn();
    }
    if (!lastHoveredColumn) {
      return;
    }
    const targetIndex = this.getMoveTargetIndex({
      currentColumns,
      lastHoveredColumn,
      isBefore
    });
    if (targetIndex != null) {
      this.columnMoveService.moveColumns(currentColumns, targetIndex, "toolPanelUi");
    }
  }
  getMoveTargetIndex(params) {
    const { currentColumns, lastHoveredColumn, isBefore } = params;
    if (!lastHoveredColumn || !currentColumns) {
      return null;
    }
    const targetColumnIndex = this.columnModel.getCols().indexOf(lastHoveredColumn);
    const adjustedTarget = isBefore ? targetColumnIndex : targetColumnIndex + 1;
    const diff = this.getMoveDiff(currentColumns, adjustedTarget);
    return adjustedTarget - diff;
  }
  getMoveDiff(currentColumns, end) {
    const allColumns = this.columnModel.getCols();
    if (!currentColumns) {
      return 0;
    }
    const targetColumn = currentColumns[0];
    const span = currentColumns.length;
    const currentIndex = allColumns.indexOf(targetColumn);
    if (currentIndex < end) {
      return span;
    }
    return 0;
  }
};
var ToolPanelColumnComp = class extends Component410 {
  constructor(modelItem, allowDragging, groupsExist, focusWrapper) {
    super();
    this.allowDragging = allowDragging;
    this.groupsExist = groupsExist;
    this.focusWrapper = focusWrapper;
    this.eLabel = RefPlaceholder35;
    this.cbSelect = RefPlaceholder35;
    this.processingColumnStateChange = false;
    this.column = modelItem.getColumn();
    this.columnDept = modelItem.getDept();
    this.displayName = modelItem.getDisplayName();
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.dragAndDropService = beans.dragAndDropService;
    this.modelItemUtils = beans.modelItemUtils;
  }
  postConstruct() {
    this.setTemplate(
      /* html */
      `<div class="ag-column-select-column">
                <ag-checkbox data-ref="cbSelect" class="ag-column-select-checkbox"></ag-checkbox>
                <span class="ag-column-select-column-label" data-ref="eLabel"></span>
            </div>`,
      [AgCheckboxSelector32]
    );
    this.eDragHandle = _createIconNoSpan42("columnDrag", this.gos);
    this.eDragHandle.classList.add("ag-drag-handle", "ag-column-select-column-drag-handle");
    const checkboxGui = this.cbSelect.getGui();
    const checkboxInput = this.cbSelect.getInputElement();
    checkboxGui.insertAdjacentElement("afterend", this.eDragHandle);
    checkboxInput.setAttribute("tabindex", "-1");
    const displayNameSanitised = _escapeString6(this.displayName);
    this.eLabel.innerHTML = displayNameSanitised;
    const indent = this.columnDept;
    if (this.groupsExist) {
      this.addCssClass("ag-column-select-add-group-indent");
    }
    this.addCssClass(`ag-column-select-indent-${indent}`);
    this.getGui().style.setProperty("--ag-indentation-level", String(indent));
    this.setupDragging();
    const onColStateChanged = this.onColumnStateChanged.bind(this);
    this.addManagedEventListeners({ columnPivotModeChanged: onColStateChanged });
    this.addManagedListeners(this.column, {
      columnValueChanged: onColStateChanged,
      columnPivotChanged: onColStateChanged,
      columnRowGroupChanged: onColStateChanged,
      visibleChanged: onColStateChanged
    });
    this.addManagedListeners(this.focusWrapper, {
      keydown: this.handleKeyDown.bind(this),
      contextmenu: this.onContextMenu.bind(this)
    });
    this.addManagedPropertyListener("functionsReadOnly", this.onColumnStateChanged.bind(this));
    this.addManagedListeners(this.cbSelect, { fieldValueChanged: this.onCheckboxChanged.bind(this) });
    this.addManagedElementListeners(this.eLabel, { click: this.onLabelClicked.bind(this) });
    this.onColumnStateChanged();
    this.refreshAriaLabel();
    this.setupTooltip();
    const classes = _getToolPanelClassesFromColDef2(this.column.getColDef(), this.gos, this.column, null);
    classes.forEach((c) => this.addOrRemoveCssClass(c, true));
  }
  getColumn() {
    return this.column;
  }
  setupTooltip() {
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    let shouldDisplayTooltip;
    if (isTooltipWhenTruncated) {
      shouldDisplayTooltip = () => this.eLabel.scrollWidth > this.eLabel.clientWidth;
    }
    const refresh = () => {
      const newTooltipText = this.column.getColDef().headerTooltip;
      this.setTooltip({ newTooltipText, location: "columnToolPanelColumn", shouldDisplayTooltip });
    };
    refresh();
    this.addManagedEventListeners({ newColumnsLoaded: refresh });
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "columnToolPanelColumn";
    res.colDef = this.column.getColDef();
    return res;
  }
  onContextMenu(e) {
    const { column, gos } = this;
    if (gos.get("functionsReadOnly")) {
      return;
    }
    const contextMenu = this.createBean(new ToolPanelContextMenu(column, e, this.focusWrapper));
    this.addDestroyFunc(() => {
      if (contextMenu.isAlive()) {
        this.destroyBean(contextMenu);
      }
    });
  }
  handleKeyDown(e) {
    if (e.key === KeyCode33.SPACE) {
      e.preventDefault();
      if (this.isSelectable()) {
        this.onSelectAllChanged(!this.isSelected());
      }
    }
  }
  onLabelClicked() {
    if (this.gos.get("functionsReadOnly")) {
      return;
    }
    const nextState = !this.cbSelect.getValue();
    this.onChangeCommon(nextState);
  }
  onCheckboxChanged(event) {
    this.onChangeCommon(event.selected);
  }
  onChangeCommon(nextState) {
    if (this.cbSelect.isReadOnly()) {
      return;
    }
    this.refreshAriaLabel();
    if (this.processingColumnStateChange) {
      return;
    }
    this.modelItemUtils.setColumn(this.column, nextState, "toolPanelUi");
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const columnLabel = translate("ariaColumn", "Column");
    const state = this.cbSelect.getValue() ? translate("ariaVisible", "visible") : translate("ariaHidden", "hidden");
    const visibilityLabel = translate("ariaToggleVisibility", "Press SPACE to toggle visibility");
    _setAriaLabel23(this.focusWrapper, `${this.displayName} ${columnLabel}`);
    this.cbSelect.setInputAriaLabel(`${visibilityLabel} (${state})`);
    _setAriaDescribedBy22(this.focusWrapper, this.cbSelect.getInputElement().id);
  }
  setupDragging() {
    if (!this.allowDragging) {
      _setDisplayed34(this.eDragHandle, false);
      return;
    }
    let hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
    const dragSource = {
      type: DragSourceType32.ToolPanel,
      eElement: this.eDragHandle,
      dragItemName: this.displayName,
      getDefaultIconName: () => hideColumnOnExit ? "hide" : "notAllowed",
      getDragItem: () => this.createDragItem(),
      onDragStarted: () => {
        hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
        this.eventService.dispatchEvent({
          type: "columnPanelItemDragStart",
          column: this.column
        });
      },
      onDragStopped: () => {
        this.eventService.dispatchEvent({
          type: "columnPanelItemDragEnd"
        });
      },
      onGridEnter: (dragItem) => {
        if (hideColumnOnExit) {
          this.modelItemUtils.updateColumns({
            columns: [this.column],
            visibleState: dragItem?.visibleState,
            pivotState: dragItem?.pivotState,
            eventType: "toolPanelUi"
          });
        }
      },
      onGridExit: () => {
        if (hideColumnOnExit) {
          this.onChangeCommon(false);
        }
      }
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  createDragItem() {
    const colId = this.column.getColId();
    const visibleState = { [colId]: this.column.isVisible() };
    const pivotState = { [colId]: this.modelItemUtils.createPivotState(this.column) };
    return {
      columns: [this.column],
      visibleState,
      pivotState
    };
  }
  onColumnStateChanged() {
    this.processingColumnStateChange = true;
    const isPivotMode2 = this.columnModel.isPivotMode();
    if (isPivotMode2) {
      const anyFunctionActive = this.column.isAnyFunctionActive();
      this.cbSelect.setValue(anyFunctionActive);
    } else {
      this.cbSelect.setValue(this.column.isVisible());
    }
    let canBeToggled = true;
    let canBeDragged = true;
    if (isPivotMode2) {
      const functionsReadOnly = this.gos.get("functionsReadOnly");
      const noFunctionsAllowed = !this.column.isAnyFunctionAllowed();
      canBeToggled = !functionsReadOnly && !noFunctionsAllowed;
      canBeDragged = canBeToggled;
    } else {
      const { enableRowGroup, enableValue, lockPosition, suppressMovable, lockVisible } = this.column.getColDef();
      const forceDraggable = !!enableRowGroup || !!enableValue;
      const disableDraggable = !!lockPosition || !!suppressMovable;
      canBeToggled = !lockVisible;
      canBeDragged = forceDraggable || !disableDraggable;
    }
    this.cbSelect.setReadOnly(!canBeToggled);
    this.eDragHandle.classList.toggle("ag-column-select-column-readonly", !canBeDragged);
    this.addOrRemoveCssClass("ag-column-select-column-readonly", !canBeDragged && !canBeToggled);
    this.cbSelect.setPassive(false);
    this.processingColumnStateChange = false;
  }
  getDisplayName() {
    return this.displayName;
  }
  onSelectAllChanged(value) {
    if (value !== this.cbSelect.getValue()) {
      if (!this.cbSelect.isReadOnly()) {
        this.cbSelect.toggle();
      }
    }
  }
  isSelected() {
    return this.cbSelect.getValue();
  }
  isSelectable() {
    return !this.cbSelect.isReadOnly();
  }
  isExpandable() {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setExpanded(_value) {
    _warnOnce21("can not expand a column item that does not represent a column group header");
  }
};
var UIColumnModel = class {
  constructor(items) {
    this.items = items;
  }
  getRowCount() {
    return this.items.length;
  }
  getRow(index) {
    return this.items[index];
  }
};
var PRIMARY_COLS_LIST_PANEL_CLASS = "ag-column-select-list";
var AgPrimaryColsList = class extends Component54 {
  constructor() {
    super(
      /* html */
      `<div class="${PRIMARY_COLS_LIST_PANEL_CLASS}" role="presentation"></div>`
    );
    this.destroyColumnItemFuncs = [];
    this.hasLoadedInitialState = false;
    this.isInitialState = false;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.colDefService = beans.toolPanelColDefService;
    this.modelItemUtils = beans.modelItemUtils;
  }
  destroy() {
    this.destroyColumnTree();
    super.destroy();
  }
  destroyColumnTree() {
    this.allColsTree = [];
    this.destroyColumnItemFuncs.forEach((f) => f());
    this.destroyColumnItemFuncs = [];
  }
  init(params, allowDragging, eventType) {
    this.params = params;
    this.allowDragging = allowDragging;
    this.eventType = eventType;
    if (!this.params.suppressSyncLayoutWithGrid) {
      this.addManagedEventListeners({ columnMoved: this.onColumnsChanged.bind(this) });
    }
    this.addManagedEventListeners({
      newColumnsLoaded: this.onColumnsChanged.bind(this)
    });
    const listener = this.fireSelectionChangedEvent.bind(this);
    this.addManagedEventListeners({
      columnPivotChanged: listener,
      columnPivotModeChanged: listener,
      columnRowGroupChanged: listener,
      columnValueChanged: listener,
      columnVisible: listener,
      newColumnsLoaded: listener
    });
    this.expandGroupsByDefault = !this.params.contractColumnSelection;
    this.virtualList = this.createManagedBean(
      new VirtualList({
        cssIdentifier: "column-select",
        ariaRole: "tree"
      })
    );
    this.appendChild(this.virtualList.getGui());
    this.virtualList.setComponentCreator((item, listItemElement) => {
      _setAriaLevel3(listItemElement, item.getDept() + 1);
      return this.createComponentFromItem(item, listItemElement);
    });
    if (this.columnModel.isReady()) {
      this.onColumnsChanged();
    }
    if (this.params.suppressColumnMove) {
      return;
    }
    this.createManagedBean(new PrimaryColsListPanelItemDragFeature(this, this.virtualList));
  }
  createComponentFromItem(item, listItemElement) {
    if (item.isGroup()) {
      const renderedGroup = new ToolPanelColumnGroupComp(
        item,
        this.allowDragging,
        this.eventType,
        listItemElement
      );
      this.createBean(renderedGroup);
      return renderedGroup;
    }
    const columnComp = new ToolPanelColumnComp(item, this.allowDragging, this.groupsExist, listItemElement);
    this.createBean(columnComp);
    return columnComp;
  }
  onColumnsChanged() {
    if (!this.hasLoadedInitialState) {
      this.hasLoadedInitialState = true;
      this.isInitialState = !!this.params.initialState;
    }
    const expandedStates = this.getExpandedStates();
    const pivotModeActive = this.columnModel.isPivotMode();
    const shouldSyncColumnLayoutWithGrid = !this.params.suppressSyncLayoutWithGrid && !pivotModeActive;
    if (shouldSyncColumnLayoutWithGrid) {
      this.buildTreeFromWhatGridIsDisplaying();
    } else {
      this.buildTreeFromProvidedColumnDefs();
    }
    this.setExpandedStates(expandedStates);
    this.markFilteredColumns();
    this.flattenAndFilterModel();
    this.isInitialState = false;
  }
  getDisplayedColsList() {
    return this.displayedColsList;
  }
  getExpandedStates() {
    const res = {};
    if (this.isInitialState) {
      const { expandedGroupIds } = this.params.initialState;
      expandedGroupIds.forEach((id) => {
        res[id] = true;
      });
      return res;
    }
    if (!this.allColsTree) {
      return {};
    }
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      const colGroup = item.getColumnGroup();
      if (colGroup) {
        res[colGroup.getId()] = item.isExpanded();
      }
    });
    return res;
  }
  setExpandedStates(states) {
    if (!this.allColsTree) {
      return;
    }
    const { isInitialState } = this;
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      const colGroup = item.getColumnGroup();
      if (colGroup) {
        const expanded = states[colGroup.getId()];
        const groupExistedLastTime = expanded != null;
        if (groupExistedLastTime || isInitialState) {
          item.setExpanded(!!expanded);
        }
      }
    });
  }
  buildTreeFromWhatGridIsDisplaying() {
    this.colDefService.syncLayoutWithGrid(this.setColumnLayout.bind(this));
  }
  setColumnLayout(colDefs) {
    const columnTree = this.colDefService.createColumnTree(colDefs);
    this.buildListModel(columnTree);
    this.groupsExist = colDefs.some((colDef) => {
      return colDef && typeof colDef.children !== "undefined";
    });
    this.markFilteredColumns();
    this.flattenAndFilterModel();
  }
  buildTreeFromProvidedColumnDefs() {
    this.buildListModel(this.columnModel.getColDefColTree());
    this.groupsExist = this.columnModel.isProvidedColGroupsPresent();
  }
  buildListModel(columnTree) {
    const columnExpandedListener = this.onColumnExpanded.bind(this);
    const addListeners = (item) => {
      item.addEventListener("expandedChanged", columnExpandedListener);
      const removeFunc = item.removeEventListener.bind(item, "expandedChanged", columnExpandedListener);
      this.destroyColumnItemFuncs.push(removeFunc);
    };
    const recursivelyBuild = (tree, dept, parentList) => {
      tree.forEach((child) => {
        if (isProvidedColumnGroup3(child)) {
          createGroupItem(child, dept, parentList);
        } else {
          createColumnItem(child, dept, parentList);
        }
      });
    };
    const createGroupItem = (columnGroup, dept, parentList) => {
      const columnGroupDef = columnGroup.getColGroupDef();
      const skipThisGroup = columnGroupDef && columnGroupDef.suppressColumnsToolPanel;
      if (skipThisGroup) {
        return;
      }
      if (columnGroup.isPadding()) {
        recursivelyBuild(columnGroup.getChildren(), dept, parentList);
        return;
      }
      const displayName = this.columnNameService.getDisplayNameForProvidedColumnGroup(
        null,
        columnGroup,
        "columnToolPanel"
      );
      const item = new ColumnModelItem(
        displayName,
        columnGroup,
        dept,
        true,
        this.expandGroupsByDefault
      );
      parentList.push(item);
      addListeners(item);
      recursivelyBuild(columnGroup.getChildren(), dept + 1, item.getChildren());
    };
    const createColumnItem = (column, dept, parentList) => {
      const skipThisColumn = column.getColDef() && column.getColDef().suppressColumnsToolPanel;
      if (skipThisColumn) {
        return;
      }
      const displayName = this.columnNameService.getDisplayNameForColumn(column, "columnToolPanel");
      parentList.push(new ColumnModelItem(displayName, column, dept));
    };
    this.destroyColumnTree();
    recursivelyBuild(columnTree, 0, this.allColsTree);
  }
  onColumnExpanded() {
    this.flattenAndFilterModel();
  }
  flattenAndFilterModel() {
    this.displayedColsList = [];
    const recursiveFunc = (item) => {
      if (!item.isPassesFilter()) {
        return;
      }
      this.displayedColsList.push(item);
      if (item.isGroup() && item.isExpanded()) {
        item.getChildren().forEach(recursiveFunc);
      }
    };
    this.allColsTree.forEach(recursiveFunc);
    this.virtualList.setModel(new UIColumnModel(this.displayedColsList));
    const focusedRow = this.virtualList.getLastFocusedRow();
    this.virtualList.refresh();
    if (focusedRow != null) {
      this.focusRowIfAlive(focusedRow);
    }
    this.notifyListeners();
    this.refreshAriaLabel();
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const columnListName = translate("ariaColumnPanelList", "Column List");
    const localeColumns = translate("columns", "Columns");
    const items = this.displayedColsList.length;
    _setAriaLabel33(this.virtualList.getAriaElement(), `${columnListName} ${items} ${localeColumns}`);
  }
  focusRowIfAlive(rowIndex) {
    window.setTimeout(() => {
      if (this.isAlive()) {
        this.virtualList.focusRow(rowIndex);
      }
    }, 0);
  }
  forEachItem(callback) {
    const recursiveFunc = (items) => {
      items.forEach((item) => {
        callback(item);
        if (item.isGroup()) {
          recursiveFunc(item.getChildren());
        }
      });
    };
    if (!this.allColsTree) {
      return;
    }
    recursiveFunc(this.allColsTree);
  }
  doSetExpandedAll(value) {
    this.forEachItem((item) => {
      if (item.isGroup()) {
        item.setExpanded(value);
      }
    });
  }
  setGroupsExpanded(expand, groupIds) {
    if (!groupIds) {
      this.doSetExpandedAll(expand);
      return;
    }
    const expandedGroupIds = [];
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      const groupId = item.getColumnGroup().getId();
      if (groupIds.indexOf(groupId) >= 0) {
        item.setExpanded(expand);
        expandedGroupIds.push(groupId);
      }
    });
    const unrecognisedGroupIds = groupIds.filter((groupId) => !_includes10(expandedGroupIds, groupId));
    if (unrecognisedGroupIds.length > 0) {
      _warnOnce25("unable to find group(s) for supplied groupIds:", unrecognisedGroupIds);
    }
  }
  getExpandState() {
    let expandedCount = 0;
    let notExpandedCount = 0;
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      if (item.isExpanded()) {
        expandedCount++;
      } else {
        notExpandedCount++;
      }
    });
    if (expandedCount > 0 && notExpandedCount > 0) {
      return 2;
    }
    if (notExpandedCount > 0) {
      return 1;
    }
    return 0;
  }
  doSetSelectedAll(selectAllChecked) {
    this.modelItemUtils.selectAllChildren(this.allColsTree, selectAllChecked, this.eventType);
  }
  getSelectionState() {
    let checkedCount = 0;
    let uncheckedCount = 0;
    const pivotMode = this.columnModel.isPivotMode();
    this.forEachItem((item) => {
      if (item.isGroup()) {
        return;
      }
      if (!item.isPassesFilter()) {
        return;
      }
      const column = item.getColumn();
      const colDef = column.getColDef();
      let checked;
      if (pivotMode) {
        const noPivotModeOptionsAllowed = !column.isAllowPivot() && !column.isAllowRowGroup() && !column.isAllowValue();
        if (noPivotModeOptionsAllowed) {
          return;
        }
        checked = column.isValueActive() || column.isPivotActive() || column.isRowGroupActive();
      } else {
        if (colDef.lockVisible) {
          return;
        }
        checked = column.isVisible();
      }
      checked ? checkedCount++ : uncheckedCount++;
    });
    if (checkedCount > 0 && uncheckedCount > 0) {
      return void 0;
    }
    return !(checkedCount === 0 || uncheckedCount > 0);
  }
  setFilterText(filterText) {
    this.filterText = _exists12(filterText) ? filterText.toLowerCase() : null;
    this.markFilteredColumns();
    this.flattenAndFilterModel();
  }
  markFilteredColumns() {
    const passesFilter = (item) => {
      if (!_exists12(this.filterText)) {
        return true;
      }
      const displayName = item.getDisplayName();
      return displayName == null || displayName.toLowerCase().indexOf(this.filterText) !== -1;
    };
    const recursivelyCheckFilter = (item, parentPasses) => {
      let atLeastOneChildPassed = false;
      if (item.isGroup()) {
        const groupPasses = passesFilter(item);
        item.getChildren().forEach((child) => {
          const childPasses = recursivelyCheckFilter(child, groupPasses || parentPasses);
          if (childPasses) {
            atLeastOneChildPassed = childPasses;
          }
        });
      }
      const filterPasses = parentPasses || atLeastOneChildPassed ? true : passesFilter(item);
      item.setPassesFilter(filterPasses);
      return filterPasses;
    };
    this.allColsTree.forEach((item) => recursivelyCheckFilter(item, false));
  }
  notifyListeners() {
    this.fireGroupExpandedEvent();
    this.fireSelectionChangedEvent();
  }
  fireGroupExpandedEvent() {
    const expandState = this.getExpandState();
    this.dispatchLocalEvent({ type: "groupExpanded", state: expandState });
  }
  fireSelectionChangedEvent() {
    if (!this.allColsTree) {
      return;
    }
    const selectionState = this.getSelectionState();
    this.dispatchLocalEvent({ type: "selectionChanged", state: selectionState });
  }
  getExpandedGroups() {
    const expandedGroupIds = [];
    if (!this.allColsTree) {
      return expandedGroupIds;
    }
    this.forEachItem((item) => {
      if (item.isGroup() && item.isExpanded()) {
        expandedGroupIds.push(item.getColumnGroup().getId());
      }
    });
    return expandedGroupIds;
  }
};
var AgPrimaryColsListSelector = {
  selector: "AG-PRIMARY-COLS-LIST",
  component: AgPrimaryColsList
};
var AgPrimaryCols = class extends Component64 {
  constructor() {
    super(
      /* html */
      `<div class="ag-column-select">
            <ag-primary-cols-header data-ref="primaryColsHeaderPanel"></ag-primary-cols-header>
            <ag-primary-cols-list data-ref="primaryColsListPanel"></ag-primary-cols-list>
        </div>`,
      [AgPrimaryColsHeaderSelector, AgPrimaryColsListSelector]
    );
    this.primaryColsHeaderPanel = RefPlaceholder44;
    this.primaryColsListPanel = RefPlaceholder44;
  }
  // we allow dragging in the toolPanel, but not when this component appears in the column menu
  init(allowDragging, params, eventType) {
    this.allowDragging = allowDragging;
    this.params = params;
    this.eventType = eventType;
    this.primaryColsHeaderPanel.init(this.params);
    const hideFilter = this.params.suppressColumnFilter;
    const hideSelect = this.params.suppressColumnSelectAll;
    const hideExpand = this.params.suppressColumnExpandAll;
    if (hideExpand && hideFilter && hideSelect) {
      this.primaryColsHeaderPanel.setDisplayed(false);
    }
    this.addManagedListeners(this.primaryColsListPanel, {
      groupExpanded: this.onGroupExpanded.bind(this),
      selectionChanged: this.onSelectionChange.bind(this)
    });
    this.primaryColsListPanel.init(this.params, this.allowDragging, this.eventType);
    this.addManagedListeners(this.primaryColsHeaderPanel, {
      expandAll: this.onExpandAll.bind(this),
      collapseAll: this.onCollapseAll.bind(this),
      selectAll: this.onSelectAll.bind(this),
      unselectAll: this.onUnselectAll.bind(this),
      filterChanged: this.onFilterChanged.bind(this)
    });
    this.positionableFeature = new PositionableFeature3(this.getGui(), { minHeight: 100 });
    this.createManagedBean(this.positionableFeature);
  }
  toggleResizable(resizable) {
    this.positionableFeature.setResizable(resizable ? { bottom: true } : false);
  }
  onExpandAll() {
    this.primaryColsListPanel.doSetExpandedAll(true);
  }
  onCollapseAll() {
    this.primaryColsListPanel.doSetExpandedAll(false);
  }
  expandGroups(groupIds) {
    this.primaryColsListPanel.setGroupsExpanded(true, groupIds);
  }
  collapseGroups(groupIds) {
    this.primaryColsListPanel.setGroupsExpanded(false, groupIds);
  }
  setColumnLayout(colDefs) {
    this.primaryColsListPanel.setColumnLayout(colDefs);
  }
  onFilterChanged(event) {
    this.primaryColsListPanel.setFilterText(event.filterText);
  }
  syncLayoutWithGrid() {
    this.primaryColsListPanel.onColumnsChanged();
  }
  onSelectAll() {
    this.primaryColsListPanel.doSetSelectedAll(true);
  }
  onUnselectAll() {
    this.primaryColsListPanel.doSetSelectedAll(false);
  }
  onGroupExpanded(event) {
    this.primaryColsHeaderPanel.setExpandState(event.state);
    this.params.onStateUpdated();
  }
  onSelectionChange(event) {
    this.primaryColsHeaderPanel.setSelectionState(event.state);
  }
  getExpandedGroups() {
    return this.primaryColsListPanel.getExpandedGroups();
  }
};
var PivotModePanel = class extends Component74 {
  constructor() {
    super(...arguments);
    this.cbPivotMode = RefPlaceholder54;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.ctrlsService = beans.ctrlsService;
  }
  createTemplate() {
    return (
      /* html */
      `<div class="ag-pivot-mode-panel">
                <ag-toggle-button data-ref="cbPivotMode" class="ag-pivot-mode-select"></ag-toggle-button>
            </div>`
    );
  }
  postConstruct() {
    this.setTemplate(this.createTemplate(), [AgToggleButtonSelector]);
    this.cbPivotMode.setValue(this.columnModel.isPivotMode());
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.cbPivotMode.setLabel(localeTextFunc("pivotMode", "Pivot Mode"));
    this.addManagedListeners(this.cbPivotMode, { fieldValueChanged: this.onBtPivotMode.bind(this) });
    const listener = this.onPivotModeChanged.bind(this);
    this.addManagedEventListeners({
      newColumnsLoaded: listener,
      columnPivotModeChanged: listener
    });
  }
  onBtPivotMode() {
    const newValue = !!this.cbPivotMode.getValue();
    if (newValue !== this.columnModel.isPivotMode()) {
      this.gos.updateGridOptions({ options: { pivotMode: newValue }, source: "toolPanelUi" });
      this.ctrlsService.getHeaderRowContainerCtrls().forEach((c) => c.refresh());
    }
  }
  onPivotModeChanged() {
    const pivotModeActive = this.columnModel.isPivotMode();
    this.cbPivotMode.setValue(pivotModeActive);
  }
};
var ColumnToolPanel = class extends Component84 {
  constructor() {
    super(
      /* html */
      `<div class="ag-column-panel"></div>`
    );
    this.initialised = false;
    this.childDestroyFuncs = [];
  }
  // lazy initialise the panel
  setVisible(visible) {
    super.setDisplayed(visible);
    if (visible && !this.initialised) {
      this.init(this.params);
    }
  }
  init(params) {
    const defaultParams = this.gos.addGridCommonParams({
      suppressColumnMove: false,
      suppressColumnSelectAll: false,
      suppressColumnFilter: false,
      suppressColumnExpandAll: false,
      contractColumnSelection: false,
      suppressPivotMode: false,
      suppressRowGroups: false,
      suppressValues: false,
      suppressPivots: false,
      suppressSyncLayoutWithGrid: false
    });
    this.params = {
      ...defaultParams,
      ...params
    };
    if (this.isRowGroupingModuleLoaded() && !this.params.suppressPivotMode) {
      this.pivotModePanel = this.createBean(new PivotModePanel());
      this.childDestroyFuncs.push(() => this.destroyBean(this.pivotModePanel));
      this.appendChild(this.pivotModePanel);
    }
    this.primaryColsPanel = this.createBean(new AgPrimaryCols());
    this.childDestroyFuncs.push(() => this.destroyBean(this.primaryColsPanel));
    this.primaryColsPanel.init(true, this.params, "toolPanelUi");
    this.primaryColsPanel.addCssClass("ag-column-panel-column-select");
    this.appendChild(this.primaryColsPanel);
    if (this.isRowGroupingModuleLoaded()) {
      if (!this.params.suppressRowGroups) {
        this.rowGroupDropZonePanel = this.createBean(new RowGroupDropZonePanel(false));
        this.childDestroyFuncs.push(() => this.destroyBean(this.rowGroupDropZonePanel));
        this.appendChild(this.rowGroupDropZonePanel);
      }
      if (!this.params.suppressValues) {
        this.valuesDropZonePanel = this.createBean(new ValuesDropZonePanel(false));
        this.childDestroyFuncs.push(() => this.destroyBean(this.valuesDropZonePanel));
        this.appendChild(this.valuesDropZonePanel);
      }
      if (!this.params.suppressPivots) {
        this.pivotDropZonePanel = this.createBean(new PivotDropZonePanel(false));
        this.childDestroyFuncs.push(() => this.destroyBean(this.pivotDropZonePanel));
        this.appendChild(this.pivotDropZonePanel);
      }
      this.setLastVisible();
      const [pivotModeListener] = this.addManagedEventListeners({
        columnPivotModeChanged: () => {
          this.resetChildrenHeight();
          this.setLastVisible();
        }
      });
      this.childDestroyFuncs.push(() => pivotModeListener());
    }
    this.initialised = true;
  }
  setPivotModeSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.pivotModePanel) {
      this.pivotModePanel.setDisplayed(visible);
    } else if (visible) {
      this.pivotModePanel = this.createBean(new PivotModePanel());
      this.getGui().insertBefore(this.pivotModePanel.getGui(), this.getGui().firstChild);
      this.childDestroyFuncs.push(() => this.destroyBean(this.pivotModePanel));
    }
    this.setLastVisible();
  }
  setRowGroupsSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.rowGroupDropZonePanel) {
      this.rowGroupDropZonePanel.setDisplayed(visible);
    } else if (visible) {
      this.rowGroupDropZonePanel = this.createManagedBean(new RowGroupDropZonePanel(false));
      this.appendChild(this.rowGroupDropZonePanel);
    }
    this.setLastVisible();
  }
  setValuesSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.valuesDropZonePanel) {
      this.valuesDropZonePanel.setDisplayed(visible);
    } else if (visible) {
      this.valuesDropZonePanel = this.createManagedBean(new ValuesDropZonePanel(false));
      this.appendChild(this.valuesDropZonePanel);
    }
    this.setLastVisible();
  }
  setPivotSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.pivotDropZonePanel) {
      this.pivotDropZonePanel.setDisplayed(visible);
    } else if (visible) {
      this.pivotDropZonePanel = this.createManagedBean(new PivotDropZonePanel(false));
      this.appendChild(this.pivotDropZonePanel);
      this.pivotDropZonePanel.setDisplayed(visible);
    }
    this.setLastVisible();
  }
  setResizers() {
    [this.primaryColsPanel, this.rowGroupDropZonePanel, this.valuesDropZonePanel, this.pivotDropZonePanel].forEach(
      (panel) => {
        if (!panel) {
          return;
        }
        const eGui = panel.getGui();
        panel.toggleResizable(
          !eGui.classList.contains("ag-last-column-drop") && !eGui.classList.contains("ag-hidden")
        );
      }
    );
  }
  setLastVisible() {
    const eGui = this.getGui();
    const columnDrops = Array.prototype.slice.call(eGui.querySelectorAll(".ag-column-drop"));
    columnDrops.forEach((columnDrop) => columnDrop.classList.remove("ag-last-column-drop"));
    const columnDropEls = eGui.querySelectorAll(".ag-column-drop:not(.ag-hidden)");
    const lastVisible = _last9(columnDropEls);
    if (lastVisible) {
      lastVisible.classList.add("ag-last-column-drop");
    }
    this.setResizers();
  }
  resetChildrenHeight() {
    const eGui = this.getGui();
    const children = eGui.children;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      child.style.removeProperty("height");
      child.style.removeProperty("flex");
    }
  }
  isRowGroupingModuleLoaded() {
    return this.gos.assertModuleRegistered(ModuleNames9.RowGroupingModule, "Row Grouping");
  }
  expandColumnGroups(groupIds) {
    this.primaryColsPanel.expandGroups(groupIds);
  }
  collapseColumnGroups(groupIds) {
    this.primaryColsPanel.collapseGroups(groupIds);
  }
  setColumnLayout(colDefs) {
    this.primaryColsPanel.setColumnLayout(colDefs);
  }
  syncLayoutWithGrid() {
    this.primaryColsPanel.syncLayoutWithGrid();
  }
  destroyChildren() {
    this.childDestroyFuncs.forEach((func) => func());
    this.childDestroyFuncs.length = 0;
    _clearElement9(this.getGui());
  }
  refresh(params) {
    this.destroyChildren();
    this.init(params);
    return true;
  }
  getState() {
    return {
      expandedGroupIds: this.primaryColsPanel.getExpandedGroups()
    };
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so this must be public.
  destroy() {
    this.destroyChildren();
    super.destroy();
  }
};
var ModelItemUtils = class extends BeanStub26 {
  constructor() {
    super(...arguments);
    this.beanName = "modelItemUtils";
  }
  wireBeans(beans) {
    this.aggFuncService = beans.aggFuncService;
    this.columnModel = beans.columnModel;
    this.columnApplyStateService = beans.columnApplyStateService;
  }
  selectAllChildren(colTree, selectAllChecked, eventType) {
    const cols = this.extractAllLeafColumns(colTree);
    this.setAllColumns(cols, selectAllChecked, eventType);
  }
  setColumn(col, selectAllChecked, eventType) {
    this.setAllColumns([col], selectAllChecked, eventType);
  }
  setAllColumns(cols, selectAllChecked, eventType) {
    if (this.columnModel.isPivotMode()) {
      this.setAllPivot(cols, selectAllChecked, eventType);
    } else {
      this.setAllVisible(cols, selectAllChecked, eventType);
    }
  }
  extractAllLeafColumns(allItems) {
    const res = [];
    const recursiveFunc = (items) => {
      items.forEach((item) => {
        if (!item.isPassesFilter()) {
          return;
        }
        if (item.isGroup()) {
          recursiveFunc(item.getChildren());
        } else {
          res.push(item.getColumn());
        }
      });
    };
    recursiveFunc(allItems);
    return res;
  }
  setAllVisible(columns, visible, eventType) {
    const colStateItems = [];
    columns.forEach((col) => {
      if (col.getColDef().lockVisible) {
        return;
      }
      if (col.isVisible() != visible) {
        colStateItems.push({
          colId: col.getId(),
          hide: !visible
        });
      }
    });
    if (colStateItems.length > 0) {
      this.columnApplyStateService.applyColumnState({ state: colStateItems }, eventType);
    }
  }
  setAllPivot(columns, value, eventType) {
    this.setAllPivotActive(columns, value, eventType);
  }
  setAllPivotActive(columns, value, eventType) {
    const colStateItems = [];
    const turnOnAction = (col) => {
      if (col.isAnyFunctionActive()) {
        return;
      }
      if (col.isAllowValue()) {
        const aggFunc = typeof col.getAggFunc() === "string" ? col.getAggFunc() : this.aggFuncService?.getDefaultAggFunc(col);
        colStateItems.push({
          colId: col.getId(),
          aggFunc
        });
      } else if (col.isAllowRowGroup()) {
        colStateItems.push({
          colId: col.getId(),
          rowGroup: true
        });
      } else if (col.isAllowPivot()) {
        colStateItems.push({
          colId: col.getId(),
          pivot: true
        });
      }
    };
    const turnOffAction = (col) => {
      const isActive = col.isPivotActive() || col.isRowGroupActive() || col.isValueActive();
      if (isActive) {
        colStateItems.push({
          colId: col.getId(),
          pivot: false,
          rowGroup: false,
          aggFunc: null
        });
      }
    };
    const action = value ? turnOnAction : turnOffAction;
    columns.forEach(action);
    if (colStateItems.length > 0) {
      this.columnApplyStateService.applyColumnState({ state: colStateItems }, eventType);
    }
  }
  updateColumns(params) {
    const { columns, visibleState, pivotState, eventType } = params;
    const state = columns.map((column) => {
      const colId = column.getColId();
      if (this.columnModel.isPivotMode()) {
        const pivotStateForColumn = pivotState?.[colId];
        return {
          colId,
          pivot: pivotStateForColumn?.pivot,
          rowGroup: pivotStateForColumn?.rowGroup,
          aggFunc: pivotStateForColumn?.aggFunc
        };
      } else {
        return {
          colId,
          hide: !visibleState?.[colId]
        };
      }
    });
    this.columnApplyStateService.applyColumnState({ state }, eventType);
  }
  createPivotState(column) {
    return {
      pivot: column.isPivotActive(),
      rowGroup: column.isRowGroupActive(),
      aggFunc: column.isValueActive() ? column.getAggFunc() : void 0
    };
  }
};
var VERSION8 = "32.3.0";
var ColumnsToolPanelModule = _defineModule8({
  version: VERSION8,
  moduleName: ModuleNames23.ColumnsToolPanelModule,
  beans: [ModelItemUtils],
  userComponents: [
    { name: "agColumnsToolPanel", classImp: ColumnToolPanel },
    {
      name: "agMenuItem",
      classImp: AgMenuItemRenderer
    }
  ],
  dependantModules: [EnterpriseCoreModule, RowGroupingModule, SideBarModule]
});

// enterprise-modules/excel-export/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames10, _defineModule as _defineModule9 } from "ag-grid-community";
import { _CsvExportCoreModule } from "ag-grid-community";
import { _getHeaderClassesFromColDef, _warnOnce as _warnOnce34 } from "ag-grid-community";
import { BaseCreator, Downloader, RowType as RowType2, ZipContainer } from "ag-grid-community";
import { _last as _last10, _mergeDeep as _mergeDeep3, _warnOnce as _warnOnce26 } from "ag-grid-community";
import { BaseGridSerializingSession, RowType } from "ag-grid-community";
import { _escapeString as _escapeString7 } from "ag-grid-community";
import { XmlFactory } from "ag-grid-community";
import { ExcelFactoryMode, _escapeString as _escapeString52, _warnOnce as _warnOnce27 } from "ag-grid-community";
import { _escapeString as _escapeString22 } from "ag-grid-community";
import { _compose, _escapeString as _escapeString42, _iterateObject as _iterateObject4 } from "ag-grid-community";
import { _escapeString as _escapeString32 } from "ag-grid-community";
import { ExcelFactoryMode as ExcelFactoryMode2, _warnOnce as _warnOnce44 } from "ag-grid-community";
var INCH_TO_EMU = 9525;
var numberFormatMap = {
  "0": 1,
  "0.00": 2,
  "#,##0": 3,
  "#,##0.00": 4,
  "0%": 9,
  "0.00%": 10,
  "0.00E+00": 11,
  "# ?/?": 12,
  "# ??/??": 13,
  "mm-dd-yy": 14,
  "d-mmm-yy": 15,
  "d-mmm": 16,
  "mmm-yy": 17,
  "h:mm AM/PM": 18,
  "h:mm:ss AM/PM": 19,
  "h:mm": 20,
  "h:mm:ss": 21,
  "m/d/yy h:mm": 22,
  "#,##0 ;(#,##0)": 37,
  "#,##0 ;[Red](#,##0)": 38,
  "#,##0.00;(#,##0.00)": 39,
  "#,##0.00;[Red](#,##0.00)": 40,
  "mm:ss": 45,
  "[h]:mm:ss": 46,
  "mmss.0": 47,
  "##0.0E+0": 48,
  "@": 49
};
var pointsToPixel = (points) => {
  return Math.round(points * 96 / 72);
};
var pixelsToEMU = (value) => {
  return Math.ceil(value * INCH_TO_EMU);
};
var getFontFamilyId = (name) => {
  if (name === void 0) {
    return;
  }
  const families = ["Automatic", "Roman", "Swiss", "Modern", "Script", "Decorative"];
  const pos = families.indexOf(name || "Automatic");
  return Math.max(pos, 0);
};
var getHeightFromProperty = (rowIndex, height) => {
  if (!height) {
    return;
  }
  let finalHeight;
  if (typeof height === "number") {
    finalHeight = height;
  } else {
    const heightFunc = height;
    finalHeight = heightFunc({ rowIndex });
  }
  return finalHeight;
};
var setExcelImageTotalWidth = (image, columnsToExport) => {
  const { colSpan, column } = image.position;
  if (!image.width) {
    return;
  }
  if (colSpan) {
    const columnsInSpan = columnsToExport.slice(column - 1, column + colSpan - 1);
    let totalWidth = 0;
    for (let i = 0; i < columnsInSpan.length; i++) {
      const colWidth = columnsInSpan[i].getActualWidth();
      if (image.width < totalWidth + colWidth) {
        image.position.colSpan = i + 1;
        image.totalWidth = image.width;
        image.width = image.totalWidth - totalWidth;
        break;
      }
      totalWidth += colWidth;
    }
  } else {
    image.totalWidth = image.width;
  }
};
var setExcelImageTotalHeight = (image, rowHeight) => {
  const { rowSpan, row } = image.position;
  if (!image.height) {
    return;
  }
  if (rowSpan) {
    let totalHeight = 0;
    let counter = 0;
    for (let i = row; i < row + rowSpan; i++) {
      const nextRowHeight = pointsToPixel(getHeightFromProperty(i, rowHeight) || 20);
      if (image.height < totalHeight + nextRowHeight) {
        image.position.rowSpan = counter + 1;
        image.totalHeight = image.height;
        image.height = image.totalHeight - totalHeight;
        break;
      }
      totalHeight += nextRowHeight;
      counter++;
    }
  } else {
    image.totalHeight = image.height;
  }
};
var createXmlPart = (body, skipHeader) => {
  const header = XmlFactory.createHeader({
    encoding: "UTF-8",
    standalone: "yes"
  });
  const xmlBody = XmlFactory.createXml(body);
  if (skipHeader) {
    return xmlBody;
  }
  return `${header}${xmlBody}`;
};
var getExcelColumnName = (colIdx) => {
  const startCode = 65;
  const tableWidth = 26;
  const fromCharCode = String.fromCharCode;
  const pos = Math.floor(colIdx / tableWidth);
  const tableIdx = colIdx % tableWidth;
  if (!pos || colIdx === tableWidth) {
    return fromCharCode(startCode + colIdx - 1);
  }
  if (!tableIdx) {
    return getExcelColumnName(pos - 1) + "Z";
  }
  if (pos < tableWidth) {
    return fromCharCode(startCode + pos - 1) + fromCharCode(startCode + tableIdx - 1);
  }
  return getExcelColumnName(pos) + fromCharCode(startCode + tableIdx - 1);
};
var replaceInvisibleCharacters = (str) => {
  if (str == null) {
    return null;
  }
  let newString = "";
  for (let i = 0; i < str.length; i++) {
    const point = str.charCodeAt(i);
    if (point >= 0 && point <= 31 && point !== 10) {
      const convertedCode = point.toString(16).toUpperCase();
      const paddedCode = convertedCode.padStart(4, "0");
      const newValue = `_x${paddedCode}_`;
      newString += newValue;
    } else {
      newString += str[i];
    }
  }
  return newString;
};
var buildSharedString = (strMap) => {
  const ret = [];
  for (const key of strMap.keys()) {
    const textNode = key.toString();
    const child = {
      name: "t",
      textNode: _escapeString7(replaceInvisibleCharacters(textNode), false)
    };
    const preserveSpaces = textNode.trim().length !== textNode.length;
    if (preserveSpaces) {
      child.properties = {
        rawMap: {
          "xml:space": "preserve"
        }
      };
    }
    ret.push({
      name: "si",
      children: [child]
    });
  }
  return ret;
};
var contentTypeFactory = {
  getTemplate(config) {
    const { name, ContentType, Extension, PartName } = config;
    return {
      name,
      properties: {
        rawMap: {
          Extension,
          PartName,
          ContentType
        }
      }
    };
  }
};
var contentType_default = contentTypeFactory;
var _normaliseImageExtension = (ext) => ext === "jpg" ? "jpeg" : ext;
var contentTypesFactory = {
  getTemplate(sheetLen) {
    const worksheets = new Array(sheetLen).fill(void 0).map((v, i) => ({
      name: "Override",
      ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml",
      PartName: `/xl/worksheets/sheet${i + 1}.xml`
    }));
    const sheetsWithImages = ExcelXlsxFactory.worksheetImages.size;
    const headerFooterImages = ExcelXlsxFactory.worksheetHeaderFooterImages.size;
    const sheetsWithTables = ExcelXlsxFactory.worksheetDataTables.size;
    const imageTypesObject = {};
    ExcelXlsxFactory.workbookImageIds.forEach((v) => {
      imageTypesObject[_normaliseImageExtension(v.type)] = true;
    });
    const imageDocs = new Array(sheetsWithImages).fill(void 0).map((v, i) => ({
      name: "Override",
      ContentType: "application/vnd.openxmlformats-officedocument.drawing+xml",
      PartName: `/xl/drawings/drawing${i + 1}.xml`
    }));
    const tableDocs = new Array(sheetsWithTables).fill(void 0).map((v, i) => ({
      name: "Override",
      ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.table+xml",
      PartName: `/xl/tables/${ExcelXlsxFactory.getTableNameFromIndex(i)}.xml`
    }));
    const imageTypes = Object.keys(imageTypesObject).map((ext) => ({
      name: "Default",
      ContentType: `image/${ext}`,
      Extension: ext
    }));
    if (headerFooterImages) {
      imageTypes.push({
        name: "Default",
        Extension: "vml",
        ContentType: "application/vnd.openxmlformats-officedocument.vmlDrawing"
      });
    }
    const children = [
      ...imageTypes,
      {
        name: "Default",
        Extension: "rels",
        ContentType: "application/vnd.openxmlformats-package.relationships+xml"
      },
      {
        name: "Default",
        ContentType: "application/xml",
        Extension: "xml"
      },
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml",
        PartName: "/xl/workbook.xml"
      },
      ...worksheets,
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.theme+xml",
        PartName: "/xl/theme/theme1.xml"
      },
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml",
        PartName: "/xl/styles.xml"
      },
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml",
        PartName: "/xl/sharedStrings.xml"
      },
      ...imageDocs,
      ...tableDocs,
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-package.core-properties+xml",
        PartName: "/docProps/core.xml"
      }
    ].map((contentType) => contentType_default.getTemplate(contentType));
    return {
      name: "Types",
      properties: {
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/package/2006/content-types"
        }
      },
      children
    };
  }
};
var contentTypes_default = contentTypesFactory;
var coreFactory = {
  getTemplate(author) {
    const dt = /* @__PURE__ */ new Date();
    const jsonDate = dt.toJSON();
    return {
      name: "cp:coreProperties",
      properties: {
        prefixedAttributes: [
          {
            prefix: "xmlns:",
            map: {
              cp: "http://schemas.openxmlformats.org/package/2006/metadata/core-properties",
              dc: "http://purl.org/dc/elements/1.1/",
              dcterms: "http://purl.org/dc/terms/",
              dcmitype: "http://purl.org/dc/dcmitype/",
              xsi: "http://www.w3.org/2001/XMLSchema-instance"
            }
          }
        ]
      },
      children: [
        {
          name: "dc:creator",
          textNode: author
        },
        {
          name: "dc:title",
          textNode: "Workbook"
        },
        {
          name: "dcterms:created",
          properties: {
            rawMap: {
              "xsi:type": "dcterms:W3CDTF"
            }
          },
          textNode: jsonDate
        },
        {
          name: "dcterms:modified",
          properties: {
            rawMap: {
              "xsi:type": "dcterms:W3CDTF"
            }
          },
          textNode: jsonDate
        }
      ]
    };
  }
};
var core_default = coreFactory;
var getAnchor = (name, imageAnchor) => ({
  name: `xdr:${name}`,
  children: [
    {
      name: "xdr:col",
      textNode: imageAnchor.col.toString()
    },
    {
      name: "xdr:colOff",
      textNode: imageAnchor.offsetX.toString()
    },
    {
      name: "xdr:row",
      textNode: imageAnchor.row.toString()
    },
    {
      name: "xdr:rowOff",
      textNode: imageAnchor.offsetY.toString()
    }
  ]
});
var getExt = (image) => {
  const children = [
    {
      name: "a:ext",
      properties: {
        rawMap: {
          uri: "{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}"
        }
      },
      children: [
        {
          name: "a16:creationId",
          properties: {
            rawMap: {
              id: "{822E6D20-D7BC-2841-A643-D49A6EF008A2}",
              "xmlns:a16": "http://schemas.microsoft.com/office/drawing/2014/main"
            }
          }
        }
      ]
    }
  ];
  const recolor = image.recolor && image.recolor.toLowerCase();
  switch (recolor) {
    case "grayscale":
    case "sepia":
    case "washout":
      children.push({
        name: "a:ext",
        properties: {
          rawMap: {
            uri: "{C183D7F6-B498-43B3-948B-1728B52AA6E4}"
          }
        },
        children: [
          {
            name: "adec:decorative",
            properties: {
              rawMap: {
                val: "0",
                "xmlns:adec": "http://schemas.microsoft.com/office/drawing/2017/decorative"
              }
            }
          }
        ]
      });
  }
  return {
    name: "a:extLst",
    children
  };
};
var getNvPicPr = (image, index) => ({
  name: "xdr:nvPicPr",
  children: [
    {
      name: "xdr:cNvPr",
      properties: {
        rawMap: {
          id: index,
          name: image.id,
          descr: image.altText != null ? image.altText : void 0
        }
      },
      children: [getExt(image)]
    },
    {
      name: "xdr:cNvPicPr",
      properties: {
        rawMap: {
          preferRelativeResize: "0"
        }
      },
      children: [
        {
          name: "a:picLocks"
        }
      ]
    }
  ]
});
var getColorDetails = (color) => {
  if (!color.saturation && !color.tint) {
    return;
  }
  const ret = [];
  if (color.saturation) {
    ret.push({
      name: "a:satMod",
      properties: {
        rawMap: {
          val: color.saturation * 1e3
        }
      }
    });
  }
  if (color.tint) {
    ret.push({
      name: "a:tint",
      properties: {
        rawMap: {
          val: color.tint * 1e3
        }
      }
    });
  }
  return ret;
};
var getDuoTone = (primaryColor, secondaryColor) => {
  return {
    name: "a:duotone",
    children: [
      {
        name: "a:prstClr",
        properties: {
          rawMap: {
            val: primaryColor.color
          }
        },
        children: getColorDetails(primaryColor)
      },
      {
        name: "a:srgbClr",
        properties: {
          rawMap: {
            val: secondaryColor.color
          }
        },
        children: getColorDetails(secondaryColor)
      }
    ]
  };
};
var getBlipFill = (image, index) => {
  let blipChildren;
  if (image.transparency) {
    const transparency = Math.min(Math.max(image.transparency, 0), 100);
    blipChildren = [
      {
        name: "a:alphaModFix",
        properties: {
          rawMap: {
            amt: 1e5 - Math.round(transparency * 1e3)
          }
        }
      }
    ];
  }
  if (image.recolor) {
    if (!blipChildren) {
      blipChildren = [];
    }
    switch (image.recolor.toLocaleLowerCase()) {
      case "grayscale":
        blipChildren.push({ name: "a:grayscl" });
        break;
      case "sepia":
        blipChildren.push(getDuoTone({ color: "black" }, { color: "D9C3A5", tint: 50, saturation: 180 }));
        break;
      case "washout":
        blipChildren.push({
          name: "a:lum",
          properties: {
            rawMap: {
              bright: "70000",
              contrast: "-70000"
            }
          }
        });
        break;
      default:
    }
  }
  return {
    name: "xdr:blipFill",
    children: [
      {
        name: "a:blip",
        properties: {
          rawMap: {
            cstate: "print",
            "r:embed": `rId${index}`,
            "xmlns:r": "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
          }
        },
        children: blipChildren
      },
      {
        name: "a:stretch",
        children: [
          {
            name: "a:fillRect"
          }
        ]
      }
    ]
  };
};
var getSpPr = (image, imageBoxSize) => {
  const xfrm = {
    name: "a:xfrm",
    children: [
      {
        name: "a:off",
        properties: {
          rawMap: {
            x: 0,
            y: 0
          }
        }
      },
      {
        name: "a:ext",
        properties: {
          rawMap: {
            cx: imageBoxSize.width,
            cy: imageBoxSize.height
          }
        }
      }
    ]
  };
  if (image.rotation) {
    const rotation = image.rotation;
    xfrm.properties = {
      rawMap: {
        rot: Math.min(Math.max(rotation, 0), 360) * 6e4
      }
    };
  }
  const prstGeom = {
    name: "a:prstGeom",
    properties: {
      rawMap: {
        prst: "rect"
      }
    },
    children: [{ name: "a:avLst" }]
  };
  const ret = {
    name: "xdr:spPr",
    children: [xfrm, prstGeom]
  };
  return ret;
};
var getImageBoxSize = (image) => {
  image.fitCell = !!image.fitCell || !image.width || !image.height;
  const { position = {}, fitCell, width = 0, height = 0, totalHeight, totalWidth } = image;
  const { offsetX = 0, offsetY = 0, row = 1, rowSpan = 1, column = 1, colSpan = 1 } = position;
  return {
    from: {
      row: row - 1,
      col: column - 1,
      offsetX: pixelsToEMU(offsetX),
      offsetY: pixelsToEMU(offsetY)
    },
    to: {
      row: row - 1 + (fitCell ? 1 : rowSpan - 1),
      col: column - 1 + (fitCell ? 1 : colSpan - 1),
      offsetX: pixelsToEMU(width + offsetX),
      offsetY: pixelsToEMU(height + offsetY)
    },
    height: pixelsToEMU(totalHeight || height),
    width: pixelsToEMU(totalWidth || width)
  };
};
var getPicture = (image, currentIndex, worksheetImageIndex, imageBoxSize) => {
  return {
    name: "xdr:pic",
    children: [
      getNvPicPr(image, currentIndex + 1),
      getBlipFill(image, worksheetImageIndex + 1),
      getSpPr(image, imageBoxSize)
    ]
  };
};
var drawingFactory = {
  getTemplate(config) {
    const { sheetIndex } = config;
    const sheetImages = ExcelXlsxFactory.worksheetImages.get(sheetIndex);
    const sheetImageIds = ExcelXlsxFactory.worksheetImageIds.get(sheetIndex);
    const children = sheetImages.map((image, idx) => {
      const boxSize = getImageBoxSize(image);
      return {
        name: "xdr:twoCellAnchor",
        properties: {
          rawMap: {
            editAs: "absolute"
          }
        },
        children: [
          getAnchor("from", boxSize.from),
          getAnchor("to", boxSize.to),
          getPicture(image, idx, sheetImageIds.get(image.id).index, boxSize),
          { name: "xdr:clientData" }
        ]
      };
    });
    return {
      name: "xdr:wsDr",
      properties: {
        rawMap: {
          "xmlns:a": "http://schemas.openxmlformats.org/drawingml/2006/main",
          "xmlns:xdr": "http://schemas.openxmlformats.org/drawingml/2006/spreadsheetDrawing"
        }
      },
      children
    };
  }
};
var drawing_default = drawingFactory;
var relationshipFactory = {
  getTemplate(config) {
    const { Id, Type, Target } = config;
    return {
      name: "Relationship",
      properties: {
        rawMap: {
          Id,
          Type,
          Target
        }
      }
    };
  }
};
var relationship_default = relationshipFactory;
var relationshipsFactory = {
  getTemplate(c) {
    const children = c.map((relationship) => relationship_default.getTemplate(relationship));
    return {
      name: "Relationships",
      properties: {
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/package/2006/relationships"
        }
      },
      children
    };
  }
};
var relationships_default = relationshipsFactory;
var sharedStrings = {
  getTemplate(strings) {
    return {
      name: "sst",
      properties: {
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
          count: strings.size,
          uniqueCount: strings.size
        }
      },
      children: buildSharedString(strings)
    };
  }
};
var sharedStrings_default = sharedStrings;
var getWeightName = (value) => {
  switch (value) {
    case 1:
      return "thin";
    case 2:
      return "medium";
    case 3:
      return "thick";
    default:
      return "hair";
  }
};
var mappedBorderNames = {
  None: "None",
  Dot: "Dotted",
  Dash: "Dashed",
  Double: "Double",
  DashDot: "DashDot",
  DashDotDot: "DashDotDot",
  SlantDashDot: "SlantDashDot",
  Continuous: "Continuous"
};
var mediumBorders = ["Dashed", "DashDot", "DashDotDot"];
var colorMap = {
  None: "none",
  Solid: "solid",
  Gray50: "mediumGray",
  Gray75: "darkGray",
  Gray25: "lightGray",
  HorzStripe: "darkHorizontal",
  VertStripe: "darkVertical",
  ReverseDiagStripe: "darkDown",
  DiagStripe: "darkUp",
  DiagCross: "darkGrid",
  ThickDiagCross: "darkTrellis",
  ThinHorzStripe: "lightHorizontal",
  ThinVertStripe: "lightVertical",
  ThinReverseDiagStripe: "lightDown",
  ThinDiagStripe: "lightUp",
  ThinHorzCross: "lightGrid",
  ThinDiagCross: "lightTrellis",
  Gray125: "gray125",
  Gray0625: "gray0625"
};
var horizontalAlignmentMap = {
  Automatic: "general",
  Left: "left",
  Center: "center",
  Right: "right",
  Fill: "fill",
  Justify: "justify",
  CenterAcrossSelection: "centerContinuous",
  Distributed: "distributed",
  JustifyDistributed: "justify"
};
var verticalAlignmentMap = {
  Automatic: void 0,
  Top: "top",
  Bottom: "bottom",
  Center: "center",
  Justify: "justify",
  Distributed: "distributed",
  JustifyDistributed: "justify"
};
var convertLegacyPattern = (name) => {
  if (!name) {
    return "none";
  }
  return colorMap[name] || name;
};
var convertLegacyColor = (color) => {
  if (color == void 0) {
    return color;
  }
  if (color.charAt(0) === "#") {
    color = color.substring(1);
  }
  return color.length === 6 ? "FF" + color : color;
};
var convertLegacyBorder = (type, weight) => {
  if (!type) {
    return "thin";
  }
  const namedWeight = getWeightName(weight);
  if (type === "Continuous") {
    return namedWeight;
  }
  const mappedName = mappedBorderNames[type];
  if (namedWeight === "medium" && mediumBorders.some((type2) => type2 === mappedName)) {
    return `medium${mappedName}`;
  }
  return `${mappedName.charAt(0).toLowerCase()}${mappedName.substring(1)}`;
};
var convertLegacyHorizontalAlignment = (alignment) => {
  return horizontalAlignmentMap[alignment] || "general";
};
var convertLegacyVerticalAlignment = (alignment) => {
  return verticalAlignmentMap[alignment] || void 0;
};
var getBorderColor = (color) => {
  return {
    name: "color",
    properties: {
      rawMap: {
        rgb: convertLegacyColor(color || "#000000")
      }
    }
  };
};
var borderFactory = {
  getTemplate(border) {
    const { left, right, top, bottom, diagonal } = border;
    const leftChildren = left ? [getBorderColor(left.color)] : void 0;
    const rightChildren = right ? [getBorderColor(right.color)] : void 0;
    const topChildren = top ? [getBorderColor(top.color)] : void 0;
    const bottomChildren = bottom ? [getBorderColor(bottom.color)] : void 0;
    const diagonalChildren = diagonal ? [getBorderColor(diagonal.color)] : void 0;
    return {
      name: "border",
      children: [
        {
          name: "left",
          properties: { rawMap: { style: left && left.style } },
          children: leftChildren
        },
        {
          name: "right",
          properties: { rawMap: { style: right && right.style } },
          children: rightChildren
        },
        {
          name: "top",
          properties: { rawMap: { style: top && top.style } },
          children: topChildren
        },
        {
          name: "bottom",
          properties: { rawMap: { style: bottom && bottom.style } },
          children: bottomChildren
        },
        {
          name: "diagonal",
          properties: { rawMap: { style: diagonal && diagonal.style } },
          children: diagonalChildren
        }
      ]
    };
  }
};
var border_default = borderFactory;
var bordersFactory = {
  getTemplate(borders) {
    return {
      name: "borders",
      properties: {
        rawMap: {
          count: borders.length
        }
      },
      children: borders.map((border) => border_default.getTemplate(border))
    };
  }
};
var borders_default = bordersFactory;
var getReadingOrderId = (readingOrder) => {
  const order = ["Context", "LeftToRight", "RightToLeft"];
  const pos = order.indexOf(readingOrder);
  return Math.max(pos, 0);
};
var alignmentFactory = {
  getTemplate(alignment) {
    const { horizontal, indent, readingOrder, rotate, shrinkToFit, vertical, wrapText } = alignment;
    return {
      name: "alignment",
      properties: {
        rawMap: {
          horizontal: horizontal && convertLegacyHorizontalAlignment(horizontal),
          indent,
          readingOrder: readingOrder && getReadingOrderId(readingOrder),
          textRotation: rotate,
          shrinkToFit,
          vertical: vertical && convertLegacyVerticalAlignment(vertical),
          wrapText
        }
      }
    };
  }
};
var alignment_default = alignmentFactory;
var protectionFactory = {
  getTemplate(protection) {
    const locked = protection.protected === false ? 0 : 1;
    const hidden = protection.hideFormula === true ? 1 : 0;
    return {
      name: "protection",
      properties: {
        rawMap: {
          hidden,
          locked
        }
      }
    };
  }
};
var protection_default = protectionFactory;
var xfFactory = {
  getTemplate(xf) {
    const { alignment, borderId, fillId, fontId, numFmtId, protection, quotePrefix, xfId } = xf;
    const children = [];
    if (alignment) {
      children.push(alignment_default.getTemplate(alignment));
    }
    if (protection) {
      children.push(protection_default.getTemplate(protection));
    }
    return {
      name: "xf",
      properties: {
        rawMap: {
          applyAlignment: alignment ? 1 : void 0,
          applyProtection: protection ? 1 : void 0,
          applyBorder: borderId ? 1 : void 0,
          applyFill: fillId ? 1 : void 0,
          borderId,
          fillId,
          applyFont: fontId ? 1 : void 0,
          fontId,
          applyNumberFormat: numFmtId ? 1 : void 0,
          numFmtId,
          quotePrefix: quotePrefix ? 1 : void 0,
          xfId
        }
      },
      children: children.length ? children : void 0
    };
  }
};
var xf_default = xfFactory;
var cellStylesXfsFactory = {
  getTemplate(xfs) {
    return {
      name: "cellStyleXfs",
      properties: {
        rawMap: {
          count: xfs.length
        }
      },
      children: xfs.map((xf) => xf_default.getTemplate(xf))
    };
  }
};
var cellStyleXfs_default = cellStylesXfsFactory;
var borderFactory2 = {
  getTemplate(cellStyle) {
    const { builtinId, name, xfId } = cellStyle;
    return {
      name: "cellStyle",
      properties: {
        rawMap: {
          builtinId,
          name,
          xfId
        }
      }
    };
  }
};
var cellStyle_default = borderFactory2;
var cellStylesFactory = {
  getTemplate(cellStyles) {
    return {
      name: "cellStyles",
      properties: {
        rawMap: {
          count: cellStyles.length
        }
      },
      children: cellStyles.map((cellStyle) => cellStyle_default.getTemplate(cellStyle))
    };
  }
};
var cellStyles_default = cellStylesFactory;
var cellXfsFactory = {
  getTemplate(xfs) {
    return {
      name: "cellXfs",
      properties: {
        rawMap: {
          count: xfs.length
        }
      },
      children: xfs.map((xf) => xf_default.getTemplate(xf))
    };
  }
};
var cellXfs_default = cellXfsFactory;
var fillFactory = {
  getTemplate(fill) {
    const { patternType, fgTheme, fgTint, fgRgb, bgRgb, bgIndexed } = fill;
    const pf = {
      name: "patternFill",
      properties: {
        rawMap: {
          patternType
        }
      }
    };
    if (fgTheme || fgTint || fgRgb) {
      pf.children = [
        {
          name: "fgColor",
          properties: {
            rawMap: {
              theme: fgTheme,
              tint: fgTint,
              rgb: fgRgb
            }
          }
        }
      ];
    }
    if (bgIndexed || bgRgb) {
      if (!pf.children) {
        pf.children = [];
      }
      pf.children.push({
        name: "bgColor",
        properties: {
          rawMap: {
            indexed: bgIndexed,
            rgb: bgRgb
          }
        }
      });
    }
    return {
      name: "fill",
      children: [pf]
    };
  }
};
var fill_default = fillFactory;
var fillsFactory = {
  getTemplate(fills) {
    return {
      name: "fills",
      properties: {
        rawMap: {
          count: fills.length
        }
      },
      children: fills.map((fill) => fill_default.getTemplate(fill))
    };
  }
};
var fills_default = fillsFactory;
var fontFactory = {
  getTemplate(font) {
    const {
      size,
      colorTheme,
      color = "FF000000",
      fontName = "Calibri",
      family,
      scheme,
      italic,
      bold,
      strikeThrough,
      outline,
      shadow: shadow2,
      underline,
      verticalAlign
    } = font;
    const children = [
      { name: "sz", properties: { rawMap: { val: size } } },
      { name: "color", properties: { rawMap: { theme: colorTheme, rgb: color } } },
      { name: "name", properties: { rawMap: { val: fontName } } }
    ];
    if (family) {
      children.push({ name: "family", properties: { rawMap: { val: family } } });
    }
    if (scheme) {
      children.push({ name: "scheme", properties: { rawMap: { val: scheme } } });
    }
    if (italic) {
      children.push({ name: "i" });
    }
    if (bold) {
      children.push({ name: "b" });
    }
    if (strikeThrough) {
      children.push({ name: "strike" });
    }
    if (outline) {
      children.push({ name: "outline" });
    }
    if (shadow2) {
      children.push({ name: "shadow" });
    }
    if (underline) {
      children.push({ name: "u", properties: { rawMap: { val: underline } } });
    }
    if (verticalAlign) {
      children.push({ name: "vertAlign", properties: { rawMap: { val: verticalAlign } } });
    }
    return { name: "font", children };
  }
};
var font_default = fontFactory;
var fontsFactory = {
  getTemplate(fonts) {
    return {
      name: "fonts",
      properties: {
        rawMap: {
          count: fonts.length
        }
      },
      children: fonts.map((font) => font_default.getTemplate(font))
    };
  }
};
var fonts_default = fontsFactory;
function prepareString(str) {
  const split = str.split(/(\[[^\]]*\])/);
  for (let i = 0; i < split.length; i++) {
    let currentString = split[i];
    if (!currentString.length) {
      continue;
    }
    if (!currentString.startsWith("[")) {
      currentString = currentString.replace(/\$/g, '"$"');
    }
    split[i] = _escapeString22(currentString);
  }
  return split.join("");
}
var numberFormatFactory = {
  getTemplate(numberFormat) {
    let { formatCode, numFmtId } = numberFormat;
    if (formatCode.length) {
      formatCode = prepareString(formatCode);
    }
    return {
      name: "numFmt",
      properties: {
        rawMap: {
          formatCode,
          numFmtId
        }
      }
    };
  }
};
var numberFormat_default = numberFormatFactory;
var numberFormatsFactory = {
  getTemplate(numberFormats) {
    return {
      name: "numFmts",
      properties: {
        rawMap: {
          count: numberFormats.length
        }
      },
      children: numberFormats.map((numberFormat) => numberFormat_default.getTemplate(numberFormat))
    };
  }
};
var numberFormats_default = numberFormatsFactory;
var stylesMap;
var registeredNumberFmts;
var registeredFonts;
var registeredFills;
var registeredBorders;
var registeredCellStyleXfs;
var registeredCellXfs;
var registeredCellStyles;
var currentSheet;
var getStyleName = (name, currentSheet2) => {
  if (name.indexOf("mixedStyle") !== -1 && currentSheet2 > 1) {
    name += `_${currentSheet2}`;
  }
  return name;
};
var resetStylesheetValues = () => {
  stylesMap = { base: 0 };
  registeredNumberFmts = [];
  registeredFonts = [{ fontName: "Calibri", colorTheme: "1", family: "2", scheme: "minor" }];
  registeredFills = [{ patternType: "none" }, { patternType: "gray125" }];
  registeredBorders = [{ left: void 0, right: void 0, top: void 0, bottom: void 0, diagonal: void 0 }];
  registeredCellStyleXfs = [{ borderId: 0, fillId: 0, fontId: 0, numFmtId: 0 }];
  registeredCellXfs = [{ borderId: 0, fillId: 0, fontId: 0, numFmtId: 0, xfId: 0 }];
  registeredCellStyles = [{ builtinId: 0, name: "Normal", xfId: 0 }];
};
var registerFill = (fill) => {
  const convertedPattern = convertLegacyPattern(fill.pattern);
  const convertedFillColor = convertLegacyColor(fill.color);
  const convertedPatternColor = convertLegacyColor(fill.patternColor);
  let pos = registeredFills.findIndex((currentFill) => {
    const { patternType, fgRgb, bgRgb } = currentFill;
    if (patternType != convertedPattern || fgRgb != convertedFillColor || bgRgb != convertedPatternColor) {
      return false;
    }
    return true;
  });
  if (pos === -1) {
    pos = registeredFills.length;
    registeredFills.push({
      patternType: convertedPattern,
      fgRgb: convertedFillColor,
      bgRgb: convertedPatternColor
    });
  }
  return pos;
};
var registerNumberFmt = (format) => {
  if (numberFormatMap[format]) {
    return numberFormatMap[format];
  }
  let pos = registeredNumberFmts.findIndex((currentFormat) => currentFormat.formatCode === format);
  if (pos === -1) {
    pos = registeredNumberFmts.length + 164;
    registeredNumberFmts.push({ formatCode: format, numFmtId: pos });
  } else {
    pos = registeredNumberFmts[pos].numFmtId;
  }
  return pos;
};
var registerBorders = (borders) => {
  const { borderBottom, borderTop, borderLeft, borderRight } = borders;
  let bottomStyle;
  let topStyle;
  let leftStyle;
  let rightStyle;
  let bottomColor;
  let topColor;
  let leftColor;
  let rightColor;
  if (borderLeft) {
    leftStyle = convertLegacyBorder(borderLeft.lineStyle, borderLeft.weight);
    leftColor = convertLegacyColor(borderLeft.color);
  }
  if (borderRight) {
    rightStyle = convertLegacyBorder(borderRight.lineStyle, borderRight.weight);
    rightColor = convertLegacyColor(borderRight.color);
  }
  if (borderBottom) {
    bottomStyle = convertLegacyBorder(borderBottom.lineStyle, borderBottom.weight);
    bottomColor = convertLegacyColor(borderBottom.color);
  }
  if (borderTop) {
    topStyle = convertLegacyBorder(borderTop.lineStyle, borderTop.weight);
    topColor = convertLegacyColor(borderTop.color);
  }
  let pos = registeredBorders.findIndex((currentBorder) => {
    const { left, right, top, bottom } = currentBorder;
    if (!left && (leftStyle || leftColor)) {
      return false;
    }
    if (!right && (rightStyle || rightColor)) {
      return false;
    }
    if (!top && (topStyle || topColor)) {
      return false;
    }
    if (!bottom && (bottomStyle || bottomColor)) {
      return false;
    }
    const { style: clS, color: clC } = left || {};
    const { style: crS, color: crC } = right || {};
    const { style: ctS, color: ctC } = top || {};
    const { style: cbS, color: cbC } = bottom || {};
    if (clS != leftStyle || clC != leftColor) {
      return false;
    }
    if (crS != rightStyle || crC != rightColor) {
      return false;
    }
    if (ctS != topStyle || ctC != topColor) {
      return false;
    }
    if (cbS != bottomStyle || cbC != bottomColor) {
      return false;
    }
    return true;
  });
  if (pos === -1) {
    pos = registeredBorders.length;
    registeredBorders.push({
      left: {
        style: leftStyle,
        color: leftColor
      },
      right: {
        style: rightStyle,
        color: rightColor
      },
      top: {
        style: topStyle,
        color: topColor
      },
      bottom: {
        style: bottomStyle,
        color: bottomColor
      },
      diagonal: {
        style: void 0,
        color: void 0
      }
    });
  }
  return pos;
};
var registerFont = (font) => {
  const {
    fontName: name = "Calibri",
    color,
    size,
    bold,
    italic,
    outline,
    shadow: shadow2,
    strikeThrough,
    underline,
    family,
    verticalAlign
  } = font;
  const convertedColor = convertLegacyColor(color);
  const familyId = getFontFamilyId(family);
  const convertedUnderline = underline ? underline.toLocaleLowerCase() : void 0;
  const convertedVerticalAlign = verticalAlign ? verticalAlign.toLocaleLowerCase() : void 0;
  let pos = registeredFonts.findIndex((currentFont) => {
    if (currentFont.fontName != name || currentFont.color != convertedColor || currentFont.size != size || currentFont.bold != bold || currentFont.italic != italic || currentFont.outline != outline || currentFont.shadow != shadow2 || currentFont.strikeThrough != strikeThrough || currentFont.underline != convertedUnderline || currentFont.verticalAlign != convertedVerticalAlign || // @ts-ignore
    currentFont.family != familyId) {
      return false;
    }
    return true;
  });
  if (pos === -1) {
    pos = registeredFonts.length;
    registeredFonts.push({
      fontName: name,
      color: convertedColor,
      size,
      bold,
      italic,
      outline,
      shadow: shadow2,
      strikeThrough,
      underline: convertedUnderline,
      verticalAlign: convertedVerticalAlign,
      family: familyId != null ? familyId.toString() : void 0
    });
  }
  return pos;
};
var registerStyle = (config) => {
  const { alignment, borders, font, interior, numberFormat, protection, quotePrefix } = config;
  let { id } = config;
  let currentFill = 0;
  let currentBorder = 0;
  let currentFont = 0;
  let currentNumberFmt = 0;
  if (!id) {
    return;
  }
  id = getStyleName(id, currentSheet);
  if (stylesMap[id] != void 0) {
    return;
  }
  if (interior) {
    currentFill = registerFill(interior);
  }
  if (borders) {
    currentBorder = registerBorders(borders);
  }
  if (font) {
    currentFont = registerFont(font);
  }
  if (numberFormat) {
    currentNumberFmt = registerNumberFmt(numberFormat.format);
  }
  stylesMap[id] = registeredCellXfs.length;
  registeredCellXfs.push({
    alignment,
    borderId: currentBorder || 0,
    fillId: currentFill || 0,
    fontId: currentFont || 0,
    numFmtId: currentNumberFmt || 0,
    protection,
    quotePrefix,
    xfId: 0
  });
};
var stylesheetFactory = {
  getTemplate(defaultFontSize) {
    const numberFormats = numberFormats_default.getTemplate(registeredNumberFmts);
    const fonts = fonts_default.getTemplate(
      registeredFonts.map((font) => ({ ...font, size: font.size != null ? font.size : defaultFontSize }))
    );
    const fills = fills_default.getTemplate(registeredFills);
    const borders = borders_default.getTemplate(registeredBorders);
    const cellStylesXfs = cellStyleXfs_default.getTemplate(registeredCellStyleXfs);
    const cellXfs = cellXfs_default.getTemplate(registeredCellXfs);
    const cellStyles = cellStyles_default.getTemplate(registeredCellStyles);
    resetStylesheetValues();
    return {
      name: "styleSheet",
      properties: {
        rawMap: {
          "mc:Ignorable": "x14ac x16r2 xr",
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
          "xmlns:mc": "http://schemas.openxmlformats.org/markup-compatibility/2006",
          "xmlns:x14ac": "http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac",
          "xmlns:x16r2": "http://schemas.microsoft.com/office/spreadsheetml/2015/02/main",
          "xmlns:xr": "http://schemas.microsoft.com/office/spreadsheetml/2014/revision"
        }
      },
      children: [
        numberFormats,
        fonts,
        fills,
        borders,
        cellStylesXfs,
        cellXfs,
        cellStyles,
        {
          name: "tableStyles",
          properties: {
            rawMap: {
              count: 0,
              defaultPivotStyle: "PivotStyleLight16",
              defaultTableStyle: "TableStyleMedium2"
            }
          }
        }
      ]
    };
  }
};
var getStyleId = (name, currentSheet2) => {
  return stylesMap[getStyleName(name, currentSheet2)] || 0;
};
var registerStyles = (styles, _currentSheet) => {
  currentSheet = _currentSheet;
  if (currentSheet === 1) {
    resetStylesheetValues();
  }
  styles.forEach(registerStyle);
};
var stylesheet_default = stylesheetFactory;
var tableFactory = {
  getTemplate(dataTable, idx) {
    const {
      name,
      columns,
      rowCount,
      displayName,
      headerRowIndex,
      showRowStripes,
      showColumnStripes,
      showFilterButtons,
      highlightFirstColumn,
      highlightLastColumn
    } = dataTable || {};
    if (!dataTable || !name || !Array.isArray(columns) || !columns.length || !rowCount) {
      return { name: "table" };
    }
    const filterColumns = columns.map((col, idx2) => ({
      name: "filterColumn",
      properties: {
        rawMap: {
          colId: idx2.toString(),
          // For filters, this should start with 0
          hiddenButton: showFilterButtons[idx2] ? 0 : 1
        }
      }
    }));
    const firstRow = headerRowIndex + 1;
    const id = (idx + 1).toString();
    const firstCell = `A${firstRow}`;
    const lastCell = `${String.fromCharCode(64 + columns.length)}${firstRow + rowCount}`;
    const ref = `${firstCell}:${lastCell}`;
    const displayNameToUse = idx ? `${displayName}_${idx + 1}` : displayName;
    return {
      name: "table",
      properties: {
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
          "xmlns:mc": "http://schemas.openxmlformats.org/markup-compatibility/2006",
          "mc:Ignorable": "xr xr3",
          "xmlns:xr": "http://schemas.microsoft.com/office/spreadsheetml/2014/revision",
          "xmlns:xr3": "http://schemas.microsoft.com/office/spreadsheetml/2016/revision3",
          name,
          displayName: displayNameToUse,
          ref,
          totalsRowShown: 0,
          id
        }
      },
      children: [
        {
          name: "autoFilter",
          properties: {
            rawMap: {
              ref
            }
          },
          children: filterColumns
        },
        {
          name: "tableColumns",
          properties: {
            rawMap: {
              count: columns.length
            }
          },
          children: columns.map((col, idx2) => ({
            name: "tableColumn",
            properties: {
              rawMap: {
                id: (idx2 + 1).toString(),
                name: col,
                dataCellStyle: "Normal"
              }
            }
          }))
        },
        {
          name: "tableStyleInfo",
          properties: {
            rawMap: {
              name: "TableStyleLight1",
              showFirstColumn: highlightFirstColumn ? 1 : 0,
              showLastColumn: highlightLastColumn ? 1 : 0,
              showRowStripes: showRowStripes ? 1 : 0,
              showColumnStripes: showColumnStripes ? 1 : 0
            }
          }
        }
      ]
    };
  }
};
var table_default = tableFactory;
var getColorChildren = (props) => {
  const [type, innerType, val, lastClr] = props;
  return {
    name: `a:${type}`,
    children: [
      {
        name: `a:${innerType}`,
        properties: {
          rawMap: {
            val,
            lastClr
          }
        }
      }
    ]
  };
};
var colorScheme = {
  getTemplate() {
    return {
      name: "a:clrScheme",
      properties: {
        rawMap: {
          name: "Office"
        }
      },
      children: [
        getColorChildren(["dk1", "sysClr", "windowText", "000000"]),
        getColorChildren(["lt1", "sysClr", "window", "FFFFFF"]),
        getColorChildren(["dk2", "srgbClr", "44546A"]),
        getColorChildren(["lt2", "srgbClr", "E7E6E6"]),
        getColorChildren(["accent1", "srgbClr", "4472C4"]),
        getColorChildren(["accent2", "srgbClr", "ED7D31"]),
        getColorChildren(["accent3", "srgbClr", "A5A5A5"]),
        getColorChildren(["accent4", "srgbClr", "FFC000"]),
        getColorChildren(["accent5", "srgbClr", "5B9BD5"]),
        getColorChildren(["accent6", "srgbClr", "70AD47"]),
        getColorChildren(["hlink", "srgbClr", "0563C1"]),
        getColorChildren(["folHlink", "srgbClr", "954F72"])
      ]
    };
  }
};
var colorScheme_default = colorScheme;
var getFont = (props) => {
  const [type, typeface, script, panose] = props;
  return {
    name: `a:${type}`,
    properties: {
      rawMap: {
        script,
        typeface,
        panose
      }
    }
  };
};
var fontScheme = {
  getTemplate() {
    return {
      name: "a:fontScheme",
      properties: {
        rawMap: {
          name: "Office"
        }
      },
      children: [
        {
          name: "a:majorFont",
          children: [
            getFont(["latin", "Calibri Light", void 0, "020F0302020204030204"]),
            getFont(["ea", ""]),
            getFont(["cs", ""]),
            getFont(["font", "\u6E38\u30B4\u30B7\u30C3\u30AF Light", "Jpan"]),
            getFont(["font", "\uB9D1\uC740 \uACE0\uB515", "Hang"]),
            getFont(["font", "\u7B49\u7EBF Light", "Hans"]),
            getFont(["font", "\u65B0\u7D30\u660E\u9AD4", "Hant"]),
            getFont(["font", "Times New Roman", "Arab"]),
            getFont(["font", "Times New Roman", "Hebr"]),
            getFont(["font", "Tahoma", "Thai"]),
            getFont(["font", "Nyala", "Ethi"]),
            getFont(["font", "Vrinda", "Beng"]),
            getFont(["font", "Shruti", "Gujr"]),
            getFont(["font", "MoolBoran", "Khmr"]),
            getFont(["font", "Tunga", "Knda"]),
            getFont(["font", "Raavi", "Guru"]),
            getFont(["font", "Euphemia", "Cans"]),
            getFont(["font", "Plantagenet Cherokee", "Cher"]),
            getFont(["font", "Microsoft Yi Baiti", "Yiii"]),
            getFont(["font", "Microsoft Himalaya", "Tibt"]),
            getFont(["font", "MV Boli", "Thaa"]),
            getFont(["font", "Mangal", "Deva"]),
            getFont(["font", "Gautami", "Telu"]),
            getFont(["font", "Latha", "Taml"]),
            getFont(["font", "Estrangelo Edessa", "Syrc"]),
            getFont(["font", "Kalinga", "Orya"]),
            getFont(["font", "Kartika", "Mlym"]),
            getFont(["font", "DokChampa", "Laoo"]),
            getFont(["font", "Iskoola Pota", "Sinh"]),
            getFont(["font", "Mongolian Baiti", "Mong"]),
            getFont(["font", "Times New Roman", "Viet"]),
            getFont(["font", "Microsoft Uighur", "Uigh"]),
            getFont(["font", "Sylfaen", "Geor"]),
            getFont(["font", "Arial", "Armn"]),
            getFont(["font", "Leelawadee UI", "Bugi"]),
            getFont(["font", "Microsoft JhengHei", "Bopo"]),
            getFont(["font", "Javanese Text", "Java"]),
            getFont(["font", "Segoe UI", "Lisu"]),
            getFont(["font", "Myanmar Text", "Mymr"]),
            getFont(["font", "Ebrima", "Nkoo"]),
            getFont(["font", "Nirmala UI", "Olck"]),
            getFont(["font", "Ebrima", "Osma"]),
            getFont(["font", "Phagspa", "Phag"]),
            getFont(["font", "Estrangelo Edessa", "Syrn"]),
            getFont(["font", "Estrangelo Edessa", "Syrj"]),
            getFont(["font", "Estrangelo Edessa", "Syre"]),
            getFont(["font", "Nirmala UI", "Sora"]),
            getFont(["font", "Microsoft Tai Le", "Tale"]),
            getFont(["font", "Microsoft New Tai Lue", "Talu"]),
            getFont(["font", "Ebrima", "Tfng"])
          ]
        },
        {
          name: "a:minorFont",
          children: [
            getFont(["latin", "Calibri", void 0, "020F0502020204030204"]),
            getFont(["ea", ""]),
            getFont(["cs", ""]),
            getFont(["font", "\u6E38\u30B4\u30B7\u30C3\u30AF", "Jpan"]),
            getFont(["font", "\uB9D1\uC740 \uACE0\uB515", "Hang"]),
            getFont(["font", "\u7B49\u7EBF", "Hans"]),
            getFont(["font", "\u65B0\u7D30\u660E\u9AD4", "Hant"]),
            getFont(["font", "Arial", "Arab"]),
            getFont(["font", "Arial", "Hebr"]),
            getFont(["font", "Tahoma", "Thai"]),
            getFont(["font", "Nyala", "Ethi"]),
            getFont(["font", "Vrinda", "Beng"]),
            getFont(["font", "Shruti", "Gujr"]),
            getFont(["font", "DaunPenh", "Khmr"]),
            getFont(["font", "Tunga", "Knda"]),
            getFont(["font", "Raavi", "Guru"]),
            getFont(["font", "Euphemia", "Cans"]),
            getFont(["font", "Plantagenet Cherokee", "Cher"]),
            getFont(["font", "Microsoft Yi Baiti", "Yiii"]),
            getFont(["font", "Microsoft Himalaya", "Tibt"]),
            getFont(["font", "MV Boli", "Thaa"]),
            getFont(["font", "Mangal", "Deva"]),
            getFont(["font", "Gautami", "Telu"]),
            getFont(["font", "Latha", "Taml"]),
            getFont(["font", "Estrangelo Edessa", "Syrc"]),
            getFont(["font", "Kalinga", "Orya"]),
            getFont(["font", "Kartika", "Mlym"]),
            getFont(["font", "DokChampa", "Laoo"]),
            getFont(["font", "Iskoola Pota", "Sinh"]),
            getFont(["font", "Mongolian Baiti", "Mong"]),
            getFont(["font", "Arial", "Viet"]),
            getFont(["font", "Microsoft Uighur", "Uigh"]),
            getFont(["font", "Sylfaen", "Geor"]),
            getFont(["font", "Arial", "Armn"]),
            getFont(["font", "Leelawadee UI", "Bugi"]),
            getFont(["font", "Microsoft JhengHei", "Bopo"]),
            getFont(["font", "Javanese Text", "Java"]),
            getFont(["font", "Segoe UI", "Lisu"]),
            getFont(["font", "Myanmar Text", "Mymr"]),
            getFont(["font", "Ebrima", "Nkoo"]),
            getFont(["font", "Nirmala UI", "Olck"]),
            getFont(["font", "Ebrima", "Osma"]),
            getFont(["font", "Phagspa", "Phag"]),
            getFont(["font", "Estrangelo Edessa", "Syrn"]),
            getFont(["font", "Estrangelo Edessa", "Syrj"]),
            getFont(["font", "Estrangelo Edessa", "Syre"]),
            getFont(["font", "Nirmala UI", "Sora"]),
            getFont(["font", "Microsoft Tai Le", "Tale"]),
            getFont(["font", "Microsoft New Tai Lue", "Talu"]),
            getFont(["font", "Ebrima", "Tfng"])
          ]
        }
      ]
    };
  }
};
var fontScheme_default = fontScheme;
var getPropertyVal = (name, val, children) => ({
  name: `a:${name}`,
  properties: {
    rawMap: {
      val
    }
  },
  children
});
var getGs = (props) => {
  const [pos, schemeColor, satMod, lumMod, tint, shade] = props;
  const children = [];
  children.push(getPropertyVal("satMod", satMod));
  if (lumMod) {
    children.push(getPropertyVal("lumMod", lumMod));
  }
  if (tint) {
    children.push(getPropertyVal("tint", tint));
  }
  if (shade) {
    children.push(getPropertyVal("shade", shade));
  }
  return {
    name: "a:gs",
    properties: {
      rawMap: {
        pos
      }
    },
    children: [
      {
        name: "a:schemeClr",
        properties: {
          rawMap: {
            val: schemeColor
          }
        },
        children
      }
    ]
  };
};
var getSolidFill = (val, children) => ({
  name: "a:solidFill",
  children: [getPropertyVal("schemeClr", val, children)]
});
var getGradFill = (props) => {
  const [rotWithShape, gs1, gs2, gs3, lin] = props;
  const [ang, scaled] = lin;
  return {
    name: "a:gradFill",
    properties: {
      rawMap: {
        rotWithShape
      }
    },
    children: [
      {
        name: "a:gsLst",
        children: [getGs(gs1), getGs(gs2), getGs(gs3)]
      },
      {
        name: "a:lin",
        properties: {
          rawMap: {
            ang,
            scaled
          }
        }
      }
    ]
  };
};
var getLine = (props) => {
  const [w, cap, cmpd, algn] = props;
  return {
    name: "a:ln",
    properties: {
      rawMap: { w, cap, cmpd, algn }
    },
    children: [
      getSolidFill("phClr"),
      getPropertyVal("prstDash", "solid"),
      {
        name: "a:miter",
        properties: {
          rawMap: {
            lim: "800000"
          }
        }
      }
    ]
  };
};
var getEffectStyle = (shadow2) => {
  const children = [];
  if (shadow2) {
    const [blurRad, dist, dir, algn, rotWithShape] = shadow2;
    children.push({
      name: "a:outerShdw",
      properties: {
        rawMap: { blurRad, dist, dir, algn, rotWithShape }
      },
      children: [getPropertyVal("srgbClr", "000000", [getPropertyVal("alpha", "63000")])]
    });
  }
  return {
    name: "a:effectStyle",
    children: [
      Object.assign(
        {},
        {
          name: "a:effectLst"
        },
        children.length ? { children } : {}
      )
    ]
  };
};
var getFillStyleList = () => ({
  name: "a:fillStyleLst",
  children: [
    getSolidFill("phClr"),
    getGradFill([
      "1",
      ["0", "phClr", "105000", "110000", "67000"],
      ["50000", "phClr", "103000", "105000", "73000"],
      ["100000", "phClr", "109000", "105000", "81000"],
      ["5400000", "0"]
    ]),
    getGradFill([
      "1",
      ["0", "phClr", "103000", "102000", "94000"],
      ["50000", "phClr", "110000", "100000", void 0, "100000"],
      ["100000", "phClr", "120000", "99000", void 0, "78000"],
      ["5400000", "0"]
    ])
  ]
});
var getLineStyleList = () => ({
  name: "a:lnStyleLst",
  children: [
    getLine(["6350", "flat", "sng", "ctr"]),
    getLine(["12700", "flat", "sng", "ctr"]),
    getLine(["19050", "flat", "sng", "ctr"])
  ]
});
var getEffectStyleList = () => ({
  name: "a:effectStyleLst",
  children: [getEffectStyle(), getEffectStyle(), getEffectStyle(["57150", "19050", "5400000", "ctr", "0"])]
});
var getBgFillStyleList = () => ({
  name: "a:bgFillStyleLst",
  children: [
    getSolidFill("phClr"),
    getSolidFill("phClr", [getPropertyVal("tint", "95000"), getPropertyVal("satMod", "170000")]),
    getGradFill([
      "1",
      ["0", "phClr", "150000", "102000", "93000", "98000"],
      ["50000", "phClr", "130000", "103000", "98000", "90000"],
      ["100000", "phClr", "120000", void 0, void 0, "63000"],
      ["5400000", "0"]
    ])
  ]
});
var formatScheme = {
  getTemplate() {
    return {
      name: "a:fmtScheme",
      properties: {
        rawMap: {
          name: "Office"
        }
      },
      children: [getFillStyleList(), getLineStyleList(), getEffectStyleList(), getBgFillStyleList()]
    };
  }
};
var formatScheme_default = formatScheme;
var themeElements = {
  getTemplate() {
    return {
      name: "a:themeElements",
      children: [colorScheme_default.getTemplate(), fontScheme_default.getTemplate(), formatScheme_default.getTemplate()]
    };
  }
};
var themeElements_default = themeElements;
var officeTheme = {
  getTemplate() {
    return {
      name: "a:theme",
      properties: {
        prefixedAttributes: [
          {
            prefix: "xmlns:",
            map: {
              a: "http://schemas.openxmlformats.org/drawingml/2006/main"
            }
          }
        ],
        rawMap: {
          name: "Office Theme"
        }
      },
      children: [
        themeElements_default.getTemplate(),
        {
          name: "a:objectDefaults"
        },
        {
          name: "a:extraClrSchemeLst"
        }
      ]
    };
  }
};
var office_default = officeTheme;
var getShapeLayout = () => ({
  name: "o:shapelayout",
  properties: {
    prefixedAttributes: [
      {
        prefix: "v:",
        map: {
          ext: "edit"
        }
      }
    ]
  },
  children: [
    {
      name: "o:idmap",
      properties: {
        prefixedAttributes: [
          {
            prefix: "v:",
            map: {
              ext: "edit"
            }
          }
        ],
        rawMap: {
          data: "1"
        }
      }
    }
  ]
});
var getStroke = () => ({
  name: "v:stroke",
  properties: {
    rawMap: {
      joinstyle: "miter"
    }
  }
});
var getFormulas = (formulas) => ({
  name: "v:formulas",
  children: formulas.map((formula) => ({
    name: "v:f",
    properties: {
      rawMap: {
        eqn: formula
      }
    }
  }))
});
var getPath = () => ({
  name: "v:path",
  properties: {
    prefixedAttributes: [
      {
        prefix: "o:",
        map: {
          connecttype: "rect",
          extrusionok: "f"
        }
      }
    ],
    rawMap: {
      gradientshapeok: "t"
    }
  }
});
var getLock = (params) => {
  const { aspectratio, rotation } = params || {};
  const rawMap = {};
  if (aspectratio) {
    rawMap.aspectratio = "t";
  }
  if (rotation) {
    rawMap.rotation = "t";
  }
  return {
    name: "o:lock",
    properties: {
      prefixedAttributes: [
        {
          prefix: "v:",
          map: {
            ext: "edit"
          }
        }
      ],
      rawMap
    }
  };
};
function mapNumber(value, startSource, endSource, startTarget, endTarget) {
  return (value - startSource) / (endSource - startSource) * (endTarget - startTarget) + startTarget;
}
var getImageData = (image, idx) => {
  let rawMap;
  const { recolor, brightness, contrast, id } = image;
  if (recolor) {
    rawMap = {};
    if (recolor === "Washout" || recolor === "Grayscale") {
      rawMap.gain = "19661f";
      rawMap.blacklevel = "22938f";
    }
    if (recolor === "Black & White" || recolor === "Grayscale") {
      rawMap.grayscale = "t";
      if (recolor === "Black & White") {
        rawMap.bilevel = "t";
      }
    }
  }
  if (!recolor || recolor === "Grayscale") {
    if (!rawMap) {
      rawMap = {};
    }
    if (contrast != null && contrast !== 50) {
      let gain = "1";
      if (contrast >= 0) {
        if (contrast < 50) {
          gain = String(contrast / 50);
        } else if (contrast < 100) {
          gain = String(50 / (100 - contrast));
        } else if (contrast === 100) {
          gain = "2147483647f";
        }
      }
      rawMap.gain = gain;
    }
    if (brightness != null && brightness !== 50) {
      rawMap.blacklevel = mapNumber(brightness, 0, 100, -0.5, 0.5).toString();
    }
  }
  return {
    name: "v:imagedata",
    properties: {
      prefixedAttributes: [
        {
          prefix: "o:",
          map: {
            relid: `rId${idx}`,
            title: id
          }
        }
      ],
      rawMap
    }
  };
};
var getShapeType = () => {
  const formulas = [
    "if lineDrawn pixelLineWidth 0",
    "sum @0 1 0",
    "sum 0 0 @1",
    "prod @2 1 2",
    "prod @3 21600 pixelWidth",
    "prod @3 21600 pixelHeight",
    "sum @0 0 1",
    "prod @6 1 2",
    "prod @7 21600 pixelWidth",
    "sum @8 21600 0",
    "prod @7 21600 pixelHeight",
    "sum @10 21600 0"
  ];
  return {
    name: "v:shapetype",
    properties: {
      prefixedAttributes: [
        {
          prefix: "o:",
          map: {
            spt: "75",
            preferrelative: "t"
          }
        }
      ],
      rawMap: {
        coordsize: "21600,21600",
        filled: "f",
        id: "_x0000_t75",
        path: "m@4@5l@4@11@9@11@9@5xe",
        stroked: "f"
      }
    },
    children: [getStroke(), getFormulas(formulas), getPath(), getLock({ aspectratio: true })]
  };
};
var pixelToPoint = (value) => Math.floor((value ?? 0) * 0.74999943307122);
var getShape = (image, idx) => {
  const { width = 0, height = 0, altText } = image;
  const imageWidth = pixelToPoint(width);
  const imageHeight = pixelToPoint(height);
  return {
    name: "v:shape",
    properties: {
      rawMap: {
        id: image.headerFooterPosition,
        "o:spid": "_x0000_s1025",
        style: `position: absolute; margin-left: 0; margin-top: 10in; margin-bottom: 0; margin-right: 0; width: ${imageWidth}pt; height: ${imageHeight}pt; z-index: ${idx + 1}`,
        type: "#_x0000_t75",
        alt: altText
      }
    },
    children: [getImageData(image, idx + 1), getLock({ rotation: true })]
  };
};
var vmlDrawingFactory = {
  getTemplate(params) {
    const headerFooterImages = ExcelXlsxFactory.worksheetHeaderFooterImages.get(params.sheetIndex) || [];
    const children = [
      getShapeLayout(),
      getShapeType(),
      ...headerFooterImages.map((img, idx) => getShape(img, idx))
    ];
    return {
      name: "xml",
      properties: {
        prefixedAttributes: [
          {
            prefix: "xmlns:",
            map: {
              v: "urn:schemas-microsoft-com:vml",
              o: "urn:schemas-microsoft-com:office:office",
              x: "urn:schemas-microsoft-com:office:excel"
            }
          }
        ]
      },
      children
    };
  }
};
var vmlDrawing_default = vmlDrawingFactory;
var sheetFactory = {
  getTemplate(name, idx) {
    const sheetId = (idx + 1).toString();
    return {
      name: "sheet",
      properties: {
        rawMap: {
          name,
          sheetId,
          "r:id": `rId${sheetId}`
        }
      }
    };
  }
};
var sheet_default = sheetFactory;
var sheetsFactory = {
  getTemplate(names) {
    return {
      name: "sheets",
      children: names.map((sheet, idx) => sheet_default.getTemplate(sheet, idx))
    };
  }
};
var sheets_default = sheetsFactory;
var workbookFactory = {
  getTemplate(names, activeTab) {
    return {
      name: "workbook",
      properties: {
        prefixedAttributes: [
          {
            prefix: "xmlns:",
            map: {
              r: "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
            }
          }
        ],
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main"
        }
      },
      children: [
        {
          name: "bookViews",
          children: [
            {
              name: "workbookView",
              properties: {
                rawMap: {
                  activeTab
                }
              }
            }
          ]
        },
        sheets_default.getTemplate(names)
      ]
    };
  }
};
var workbook_default = workbookFactory;
var getExcelCellWidth = (width) => Math.ceil((width - 12) / 7 + 1);
var columnFactory = {
  getTemplate(config) {
    const { min, max, outlineLevel, s, width, hidden, bestFit } = config;
    let excelWidth = 1;
    let customWidth = "0";
    if (width > 1) {
      excelWidth = getExcelCellWidth(width);
      customWidth = "1";
    }
    return {
      name: "col",
      properties: {
        rawMap: {
          min,
          max,
          outlineLevel: outlineLevel != null ? outlineLevel : void 0,
          width: excelWidth,
          style: s,
          hidden: hidden ? "1" : "0",
          bestFit: bestFit ? "1" : "0",
          customWidth
        }
      }
    };
  }
};
var column_default = columnFactory;
var mergeCellFactory = {
  getTemplate(ref) {
    return {
      name: "mergeCell",
      properties: {
        rawMap: {
          ref
        }
      }
    };
  }
};
var mergeCell_default = mergeCellFactory;
var convertLegacyType = (type) => {
  const t = type.charAt(0).toLowerCase();
  return t === "s" ? "inlineStr" : t;
};
var cellFactory = {
  getTemplate(config, idx, currentSheet2) {
    const { ref, data, styleId } = config;
    const { type, value } = data || { type: "empty", value: null };
    let convertedType = type;
    if (type === "f") {
      convertedType = "str";
    } else if (type.charAt(0) === type.charAt(0).toUpperCase()) {
      convertedType = convertLegacyType(type);
    }
    const obj = {
      name: "c",
      properties: {
        rawMap: {
          r: ref,
          t: convertedType === "empty" ? void 0 : convertedType,
          s: styleId ? getStyleId(styleId, currentSheet2) : void 0
        }
      }
    };
    if (convertedType === "empty") {
      return obj;
    }
    let children;
    if (convertedType === "str" && type === "f") {
      children = [
        {
          name: "f",
          textNode: _escapeString32(replaceInvisibleCharacters(value), false)
        }
      ];
    } else if (convertedType === "inlineStr") {
      children = [
        {
          name: "is",
          children: [
            {
              name: "t",
              textNode: _escapeString32(replaceInvisibleCharacters(value), false)
            }
          ]
        }
      ];
    } else {
      children = [
        {
          name: "v",
          textNode: value
        }
      ];
    }
    return Object.assign({}, obj, { children });
  }
};
var cell_default = cellFactory;
var addEmptyCells = (cells, rowIdx) => {
  const mergeMap = [];
  let posCounter = 0;
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    if (cell.mergeAcross) {
      mergeMap.push({
        pos: i,
        excelPos: posCounter
      });
      posCounter += cell.mergeAcross;
    }
    posCounter++;
  }
  if (mergeMap.length) {
    for (let i = mergeMap.length - 1; i >= 0; i--) {
      const mergedCells = [];
      const cell = cells[mergeMap[i].pos];
      for (let j = 1; j <= cell.mergeAcross; j++) {
        mergedCells.push({
          ref: `${getExcelColumnName(mergeMap[i].excelPos + 1 + j)}${rowIdx + 1}`,
          styleId: cell.styleId,
          data: { type: "empty", value: null }
        });
      }
      if (mergedCells.length) {
        cells.splice(mergeMap[i].pos + 1, 0, ...mergedCells);
      }
    }
  }
};
var shouldDisplayCell = (cell) => cell.data?.value !== "" || cell.styleId !== void 0;
var rowFactory = {
  getTemplate(config, idx, currentSheet2) {
    const { collapsed, hidden, height, outlineLevel, cells = [] } = config;
    addEmptyCells(cells, idx);
    const children = cells.filter(shouldDisplayCell).map((cell, idx2) => cell_default.getTemplate(cell, idx2, currentSheet2));
    return {
      name: "row",
      properties: {
        rawMap: {
          r: idx + 1,
          collapsed: collapsed ? "1" : "0",
          hidden: hidden ? "1" : "0",
          ht: height,
          customHeight: height != null ? "1" : "0",
          spans: "1:1",
          outlineLevel: outlineLevel || void 0
        }
      },
      children
    };
  }
};
var row_default = rowFactory;
var getMergedCellsAndAddColumnGroups = (rows, cols, suppressColumnOutline) => {
  const mergedCells = [];
  const cellsWithCollapsibleGroups = [];
  rows.forEach((currentRow, rowIdx) => {
    const cells = currentRow.cells;
    let merges = 0;
    let lastCol;
    cells.forEach((currentCell, cellIdx) => {
      const min = cellIdx + merges + 1;
      const start = getExcelColumnName(min);
      const outputRow = rowIdx + 1;
      if (currentCell.mergeAcross) {
        merges += currentCell.mergeAcross;
        const end = getExcelColumnName(cellIdx + merges + 1);
        mergedCells.push(`${start}${outputRow}:${end}${outputRow}`);
      }
      if (!cols[min - 1]) {
        cols[min - 1] = {};
      }
      const { collapsibleRanges } = currentCell;
      if (collapsibleRanges) {
        collapsibleRanges.forEach((range) => {
          cellsWithCollapsibleGroups.push([min + range[0], min + range[1]]);
        });
      }
      lastCol = cols[min - 1];
      lastCol.min = min;
      lastCol.max = min;
      currentCell.ref = `${start}${outputRow}`;
    });
  });
  cellsWithCollapsibleGroups.sort((a, b) => {
    if (a[0] !== b[0]) {
      return a[0] - b[0];
    }
    return b[1] - a[1];
  });
  const rangeMap = /* @__PURE__ */ new Map();
  const outlineLevel = /* @__PURE__ */ new Map();
  cellsWithCollapsibleGroups.filter((currentRange) => {
    const rangeString = currentRange.toString();
    const inMap = rangeMap.get(rangeString);
    if (inMap) {
      return false;
    }
    rangeMap.set(rangeString, true);
    return true;
  }).forEach((range) => {
    const refCol = cols.find((col) => col.min == range[0] && col.max == range[1]);
    const currentOutlineLevel = outlineLevel.get(range[0]);
    cols.push({
      min: range[0],
      max: range[1],
      outlineLevel: suppressColumnOutline ? void 0 : currentOutlineLevel || 1,
      width: (refCol || { width: 100 }).width
    });
    outlineLevel.set(range[0], (currentOutlineLevel || 0) + 1);
  });
  return mergedCells;
};
var getPageOrientation = (orientation) => {
  if (!orientation || orientation !== "Portrait" && orientation !== "Landscape") {
    return "portrait";
  }
  return orientation.toLocaleLowerCase();
};
var getPageSize = (pageSize) => {
  if (pageSize == null) {
    return 1;
  }
  const positions = [
    "Letter",
    "Letter Small",
    "Tabloid",
    "Ledger",
    "Legal",
    "Statement",
    "Executive",
    "A3",
    "A4",
    "A4 Small",
    "A5",
    "A6",
    "B4",
    "B5",
    "Folio",
    "Envelope",
    "Envelope DL",
    "Envelope C5",
    "Envelope B5",
    "Envelope C3",
    "Envelope C4",
    "Envelope C6",
    "Envelope Monarch",
    "Japanese Postcard",
    "Japanese Double Postcard"
  ];
  const pos = positions.indexOf(pageSize);
  return pos === -1 ? 1 : pos + 1;
};
var replaceHeaderFooterTokens = (value) => {
  const map = {
    "&[Page]": "&P",
    "&[Pages]": "&N",
    "&[Date]": "&D",
    "&[Time]": "&T",
    "&[Tab]": "&A",
    "&[Path]": "&Z",
    "&[File]": "&F",
    "&[Picture]": "&G"
  };
  _iterateObject4(map, (key, val) => {
    value = value.replace(key, val);
  });
  return value;
};
var getHeaderPosition = (position) => {
  if (position === "Center") {
    return "C";
  }
  if (position === "Right") {
    return "R";
  }
  return "L";
};
var applyHeaderFontStyle = (headerString, font) => {
  if (!font) {
    return headerString;
  }
  headerString += "&amp;&quot;";
  headerString += font.fontName || "Calibri";
  if (font.bold !== font.italic) {
    headerString += font.bold ? ",Bold" : ",Italic";
  } else if (font.bold) {
    headerString += ",Bold Italic";
  } else {
    headerString += ",Regular";
  }
  headerString += "&quot;";
  if (font.size) {
    headerString += `&amp;${font.size}`;
  }
  if (font.strikeThrough) {
    headerString += "&amp;S";
  }
  if (font.underline) {
    headerString += `&amp;${font.underline === "Double" ? "E" : "U"}`;
  }
  if (font.color) {
    headerString += `&amp;K${font.color.replace("#", "").toUpperCase()}`;
  }
  return headerString;
};
var processHeaderFooterContent = (content, location, rule) => content.reduce((prev, curr, idx) => {
  const pos = getHeaderPosition(curr.position);
  const output = applyHeaderFontStyle(`${prev}&amp;${pos}`, curr.font);
  const PositionMap = ["Left", "Center", "Right"];
  if (!curr.position) {
    curr.position = PositionMap[idx];
  }
  const { image } = curr;
  if (curr.value === "&[Picture]" && image) {
    const imagePosition = `${pos}${location}${rule}`;
    ExcelXlsxFactory.addHeaderFooterImageToMap(image, imagePosition);
  }
  return `${output}${_escapeString42(replaceHeaderFooterTokens(curr.value))}`;
}, "");
var buildHeaderFooter = (headerFooterConfig) => {
  const rules = ["all", "first", "even"];
  const headersAndFooters = [];
  rules.forEach((rule) => {
    const headerFooter = headerFooterConfig[rule];
    const namePrefix = rule === "all" ? "odd" : rule;
    if (!headerFooter) {
      return;
    }
    for (const [key, value] of Object.entries(headerFooter)) {
      const nameSuffix = `${key.charAt(0).toUpperCase()}${key.slice(1)}`;
      const location = key[0].toUpperCase();
      if (value) {
        const normalizedRule = rule === "all" ? "" : rule.toUpperCase();
        headersAndFooters.push({
          name: `${namePrefix}${nameSuffix}`,
          properties: {
            rawMap: { "xml:space": "preserve" }
          },
          textNode: processHeaderFooterContent(value, location, normalizedRule)
        });
      }
    }
  });
  return headersAndFooters;
};
var addColumns = (columns) => {
  return (params) => {
    if (columns.length) {
      params.children.push({
        name: "cols",
        children: columns.map((column) => column_default.getTemplate(column))
      });
    }
    return params;
  };
};
var addSheetData = (rows, sheetNumber) => {
  return (params) => {
    if (rows.length) {
      params.children.push({
        name: "sheetData",
        children: rows.map((row, idx) => row_default.getTemplate(row, idx, sheetNumber))
      });
    }
    return params;
  };
};
var addMergeCells = (mergeCells) => {
  return (params) => {
    if (mergeCells.length) {
      params.children.push({
        name: "mergeCells",
        properties: {
          rawMap: {
            count: mergeCells.length
          }
        },
        children: mergeCells.map((mergedCell) => mergeCell_default.getTemplate(mergedCell))
      });
    }
    return params;
  };
};
var addPageMargins = (margins) => {
  return (params) => {
    const { top = 0.75, right = 0.7, bottom = 0.75, left = 0.7, header = 0.3, footer = 0.3 } = margins;
    params.children.push({
      name: "pageMargins",
      properties: {
        rawMap: { bottom, footer, header, left, right, top }
      }
    });
    return params;
  };
};
var addPageSetup = (pageSetup) => {
  return (params) => {
    if (pageSetup) {
      params.children.push({
        name: "pageSetup",
        properties: {
          rawMap: {
            horizontalDpi: 0,
            verticalDpi: 0,
            orientation: getPageOrientation(pageSetup.orientation),
            paperSize: getPageSize(pageSetup.pageSize)
          }
        }
      });
    }
    return params;
  };
};
var addHeaderFooter = (headerFooterConfig) => {
  return (params) => {
    if (!headerFooterConfig) {
      return params;
    }
    const differentFirst = headerFooterConfig.first != null ? 1 : 0;
    const differentOddEven = headerFooterConfig.even != null ? 1 : 0;
    params.children.push({
      name: "headerFooter",
      properties: {
        rawMap: {
          differentFirst,
          differentOddEven
        }
      },
      children: buildHeaderFooter(headerFooterConfig)
    });
    return params;
  };
};
var addExcelTableRel = (excelTable) => {
  return (params) => {
    if (excelTable) {
      params.children.push({
        name: "tableParts",
        properties: {
          rawMap: {
            count: "1"
          }
        },
        children: [
          {
            name: "tablePart",
            properties: {
              rawMap: {
                "r:id": `rId${++params.rIdCounter}`
              }
            }
          }
        ]
      });
    }
    return params;
  };
};
var addDrawingRel = (currentSheet2) => {
  return (params) => {
    const worksheetImages = ExcelXlsxFactory.worksheetImages.get(currentSheet2);
    if (worksheetImages?.length) {
      params.children.push({
        name: "drawing",
        properties: {
          rawMap: {
            "r:id": `rId${++params.rIdCounter}`
          }
        }
      });
    }
    return params;
  };
};
var addVmlDrawingRel = (currentSheet2) => {
  return (params) => {
    if (ExcelXlsxFactory.worksheetHeaderFooterImages.get(currentSheet2)) {
      params.children.push({
        name: "legacyDrawingHF",
        properties: {
          rawMap: {
            "r:id": `rId${++params.rIdCounter}`
          }
        }
      });
    }
    return params;
  };
};
var getPane = (xSplit = 0, ySplit = 0) => {
  const shouldSplit = xSplit > 0 || ySplit > 0;
  return shouldSplit ? [
    {
      name: "pane",
      properties: {
        rawMap: {
          state: shouldSplit ? "frozen" : void 0,
          topLeftCell: shouldSplit ? `${getExcelColumnName(xSplit + 1)}${ySplit + 1}` : void 0,
          xSplit: xSplit === 0 ? void 0 : xSplit,
          ySplit: ySplit === 0 ? void 0 : ySplit
        }
      }
    }
  ] : void 0;
};
var addSheetViews = (rtl = false, xSplit, ySplit) => {
  return (params) => {
    params.children.push({
      name: "sheetViews",
      children: [
        {
          name: "sheetView",
          properties: {
            rawMap: {
              rightToLeft: rtl === true ? "1" : "0",
              workbookViewId: "0"
            }
          },
          children: getPane(xSplit, ySplit)
        }
      ]
    });
    return params;
  };
};
var addSheetPr = () => {
  return (params) => {
    params.children.push({
      name: "sheetPr",
      children: [
        {
          name: "outlinePr",
          properties: {
            rawMap: {
              summaryBelow: 0
            }
          }
        }
      ]
    });
    return params;
  };
};
var addSheetFormatPr = (rows) => {
  return (params) => {
    const maxOutline = rows.reduce((prev, row) => {
      if (row.outlineLevel && row.outlineLevel > prev) {
        return row.outlineLevel;
      }
      return prev;
    }, 0);
    params.children.push({
      name: "sheetFormatPr",
      properties: {
        rawMap: {
          baseColWidth: 10,
          defaultRowHeight: 16,
          outlineLevelRow: maxOutline ? maxOutline : void 0
        }
      }
    });
    return params;
  };
};
var worksheetFactory = {
  getTemplate(params) {
    const { worksheet, currentSheet: currentSheet2, config } = params;
    const {
      margins = {},
      pageSetup,
      headerFooterConfig,
      suppressColumnOutline,
      rightToLeft,
      frozenRowCount,
      frozenColumnCount
    } = config;
    const { table } = worksheet;
    const { rows, columns } = table;
    const mergedCells = columns && columns.length ? getMergedCellsAndAddColumnGroups(rows, columns, !!suppressColumnOutline) : [];
    const { worksheetDataTables } = ExcelXlsxFactory;
    const worksheetExcelTables = worksheetDataTables.get(currentSheet2);
    const createWorksheetChildren = _compose(
      addSheetPr(),
      addSheetViews(rightToLeft, frozenColumnCount, frozenRowCount),
      addSheetFormatPr(rows),
      addColumns(columns),
      addSheetData(rows, currentSheet2 + 1),
      addMergeCells(mergedCells),
      addPageMargins(margins),
      addPageSetup(pageSetup),
      addHeaderFooter(headerFooterConfig),
      addDrawingRel(currentSheet2),
      addVmlDrawingRel(currentSheet2),
      addExcelTableRel(worksheetExcelTables)
    );
    const { children } = createWorksheetChildren({ children: [], rIdCounter: 0 });
    return {
      name: "worksheet",
      properties: {
        prefixedAttributes: [
          {
            prefix: "xmlns:",
            map: {
              r: "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
            }
          }
        ],
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main"
        }
      },
      children
    };
  }
};
var worksheet_default = worksheetFactory;
var _ExcelXlsxFactory = class _ExcelXlsxFactory2 {
  static createExcel(styles, worksheet, config) {
    this.addSheetName(worksheet);
    registerStyles(styles, this.sheetNames.length);
    const newConfig = Object.assign({}, config);
    if (config.exportAsExcelTable) {
      if (config.columnModel.isPivotActive()) {
        this.showExcelTableNonCompatibleFeaturesWarning("pivot mode");
        newConfig.exportAsExcelTable = false;
      }
      if (config.gos.get("masterDetail")) {
        this.showExcelTableNonCompatibleFeaturesWarning("master/detail");
        newConfig.exportAsExcelTable = false;
      }
    }
    this.processTableConfig(worksheet, newConfig);
    return this.createWorksheet(worksheet, newConfig);
  }
  static showExcelTableNonCompatibleFeaturesWarning(featureName) {
    _warnOnce27(
      `Excel table export does not work with ${featureName}. The exported Excel file will not contain any Excel tables.
Please turn off ${featureName} to enable Excel table exports.`
    );
  }
  static getTableNameFromIndex(idx) {
    return `table${idx + 1}`;
  }
  static getSanitizedTableName(name) {
    return name.replace(/^[^a-zA-Z_]+/, "_").replace(/\s/g, "_").replace(/[^a-zA-Z0-9_]/g, "_");
  }
  static addTableToSheet(sheetIndex, table) {
    if (this.worksheetDataTables.has(sheetIndex)) {
      _warnOnce27("Unable to add data table to Excel sheet: A table already exists.");
      return;
    }
    this.worksheetDataTables.set(sheetIndex, table);
  }
  static processTableConfig(worksheet, config) {
    if (!config.exportAsExcelTable) {
      return;
    }
    const tableConfig = typeof config.exportAsExcelTable === "boolean" ? {} : config.exportAsExcelTable;
    const {
      name: nameFromConfig,
      showColumnStripes,
      showRowStripes,
      showFilterButton,
      highlightFirstColumn,
      highlightLastColumn
    } = tableConfig;
    const tableName = this.getSanitizedTableName(nameFromConfig || _ExcelXlsxFactory2.defaultTableDisplayName);
    const sheetIndex = this.sheetNames.length - 1;
    const { table } = worksheet;
    const { rows, columns } = table;
    const headerRowCount = config.columnModel.getHeaderRowCount();
    const tableHeaderRowIndex = headerRowCount - 1;
    const tableRowCount = rows.length;
    const tableColCount = columns.length;
    const tableColumns = [];
    const showFilterButtons = [];
    for (let i = 0; i < tableColCount; i++) {
      const col = columns[i];
      tableColumns.push(col.displayName || "");
      showFilterButtons.push(
        showFilterButton === "match" || showFilterButton === void 0 ? col.filterAllowed ?? false : showFilterButton
      );
    }
    if (!tableColumns || !tableColumns.length || !tableRowCount || !tableName) {
      _warnOnce27("Unable to add data table to Excel sheet: Missing required parameters.");
      return;
    }
    this.addTableToSheet(sheetIndex, {
      name: this.getTableNameFromIndex(sheetIndex),
      displayName: tableName,
      columns: tableColumns,
      showFilterButtons,
      headerRowIndex: tableHeaderRowIndex,
      rowCount: tableRowCount - headerRowCount,
      showRowStripes: showRowStripes ?? true,
      showColumnStripes: showColumnStripes ?? false,
      highlightFirstColumn: highlightFirstColumn ?? false,
      highlightLastColumn: highlightLastColumn ?? false
    });
  }
  static addHeaderFooterImageToMap(image, position) {
    const sheetIndex = this.sheetNames.length - 1;
    const headerFooterImage = image;
    headerFooterImage.headerFooterPosition = position;
    this.buildImageMap({ imageToAdd: headerFooterImage, idx: sheetIndex });
    let headerFooterImagesForSheet = this.worksheetHeaderFooterImages.get(sheetIndex);
    if (!headerFooterImagesForSheet) {
      headerFooterImagesForSheet = [];
      this.worksheetHeaderFooterImages.set(sheetIndex, headerFooterImagesForSheet);
    }
    if (!headerFooterImagesForSheet.find((img) => img.id === image.id)) {
      headerFooterImagesForSheet.push(image);
    }
  }
  static addBodyImageToMap(image, rowIndex, col, columnsToExport, rowHeight) {
    const sheetIndex = this.sheetNames.length;
    const { row, column } = image.position || {};
    const calculatedImage = image;
    if (columnsToExport) {
      if (rowIndex != null && col != null && (!row || !column)) {
        if (!image.position) {
          image.position = {};
        }
        image.position = Object.assign({}, image.position, {
          row: rowIndex,
          column: columnsToExport.indexOf(col) + 1
        });
      }
      setExcelImageTotalWidth(calculatedImage, columnsToExport);
      setExcelImageTotalHeight(calculatedImage, rowHeight);
    }
    this.buildImageMap({ imageToAdd: calculatedImage, idx: sheetIndex });
    let worksheetImageIdMap = this.worksheetImageIds.get(sheetIndex);
    if (!worksheetImageIdMap) {
      worksheetImageIdMap = /* @__PURE__ */ new Map();
      this.worksheetImageIds.set(sheetIndex, worksheetImageIdMap);
    }
    const sheetImages = this.worksheetImages.get(sheetIndex);
    if (!sheetImages) {
      this.worksheetImages.set(sheetIndex, [calculatedImage]);
    } else {
      sheetImages.push(calculatedImage);
    }
    if (!worksheetImageIdMap.get(image.id)) {
      worksheetImageIdMap.set(image.id, { index: worksheetImageIdMap.size, type: image.imageType });
    }
  }
  static buildImageMap(params) {
    const { imageToAdd, idx } = params;
    const mappedImagesToSheet = this.images.get(imageToAdd.id);
    if (mappedImagesToSheet) {
      const currentSheetImages = mappedImagesToSheet.find((currentImage) => currentImage.sheetId === idx);
      if (currentSheetImages) {
        currentSheetImages.image.push(imageToAdd);
      } else {
        mappedImagesToSheet.push({
          sheetId: idx,
          image: [imageToAdd]
        });
      }
    } else {
      this.images.set(imageToAdd.id, [{ sheetId: idx, image: [imageToAdd] }]);
      this.workbookImageIds.set(imageToAdd.id, { type: imageToAdd.imageType, index: this.workbookImageIds.size });
    }
  }
  static addSheetName(worksheet) {
    const name = _escapeString52(worksheet.name) || "";
    let append = "";
    while (this.sheetNames.indexOf(`${name}${append}`) !== -1) {
      if (append === "") {
        append = "_1";
      } else {
        const curr = parseInt(append.slice(1), 10);
        append = `_${curr + 1}`;
      }
    }
    worksheet.name = `${name}${append}`;
    this.sheetNames.push(worksheet.name);
  }
  static getStringPosition(str) {
    if (this.sharedStrings.has(str)) {
      return this.sharedStrings.get(str);
    }
    this.sharedStrings.set(str, this.sharedStrings.size);
    return this.sharedStrings.size - 1;
  }
  static resetFactory() {
    this.sharedStrings = /* @__PURE__ */ new Map();
    this.images = /* @__PURE__ */ new Map();
    this.worksheetImages = /* @__PURE__ */ new Map();
    this.worksheetHeaderFooterImages = /* @__PURE__ */ new Map();
    this.workbookImageIds = /* @__PURE__ */ new Map();
    this.worksheetImageIds = /* @__PURE__ */ new Map();
    this.worksheetDataTables = /* @__PURE__ */ new Map();
    this.sheetNames = [];
    this.factoryMode = ExcelFactoryMode.SINGLE_SHEET;
  }
  static createWorkbook(currentSheet2) {
    return createXmlPart(workbook_default.getTemplate(this.sheetNames, currentSheet2));
  }
  static createStylesheet(defaultFontSize) {
    return createXmlPart(stylesheet_default.getTemplate(defaultFontSize));
  }
  static createSharedStrings() {
    return createXmlPart(sharedStrings_default.getTemplate(this.sharedStrings));
  }
  static createCore(author) {
    return createXmlPart(core_default.getTemplate(author));
  }
  static createContentTypes(sheetLen) {
    return createXmlPart(contentTypes_default.getTemplate(sheetLen));
  }
  static createRels() {
    const rs = relationships_default.getTemplate([
      {
        Id: "rId1",
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument",
        Target: "xl/workbook.xml"
      },
      {
        Id: "rId2",
        Type: "http://schemas.openxmlformats.org/package/2006/relationships/metadata/core-properties",
        Target: "docProps/core.xml"
      }
    ]);
    return createXmlPart(rs);
  }
  static createTheme() {
    return createXmlPart(office_default.getTemplate());
  }
  static createTable(dataTable, index) {
    return createXmlPart(table_default.getTemplate(dataTable, index));
  }
  static createWorkbookRels(sheetLen) {
    const worksheets = new Array(sheetLen).fill(void 0).map((v, i) => ({
      Id: `rId${i + 1}`,
      Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet",
      Target: `worksheets/sheet${i + 1}.xml`
    }));
    const rs = relationships_default.getTemplate([
      ...worksheets,
      {
        Id: `rId${sheetLen + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/theme",
        Target: "theme/theme1.xml"
      },
      {
        Id: `rId${sheetLen + 2}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles",
        Target: "styles.xml"
      },
      {
        Id: `rId${sheetLen + 3}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/sharedStrings",
        Target: "sharedStrings.xml"
      }
    ]);
    return createXmlPart(rs);
  }
  static createDrawing(sheetIndex) {
    return createXmlPart(drawing_default.getTemplate({ sheetIndex }));
  }
  static createDrawingRel(sheetIndex) {
    const worksheetImageIds = this.worksheetImageIds.get(sheetIndex) || [];
    const XMLArr = [];
    for (const [key, value] of worksheetImageIds) {
      const { index, type } = value;
      XMLArr.push({
        Id: `rId${index + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/image",
        Target: `../media/image${this.workbookImageIds.get(key).index + 1}.${_normaliseImageExtension(type)}`
      });
    }
    return createXmlPart(relationships_default.getTemplate(XMLArr));
  }
  static createVmlDrawing(sheetIndex) {
    return createXmlPart(vmlDrawing_default.getTemplate({ sheetIndex }), true);
  }
  static createVmlDrawingRel(sheetIndex) {
    const worksheetHeaderFooterImages = this.worksheetHeaderFooterImages.get(sheetIndex) || [];
    const XMLArr = [];
    for (let i = 0; i < worksheetHeaderFooterImages.length; i++) {
      const headerFooterImage = worksheetHeaderFooterImages[i];
      const workbookImage = this.workbookImageIds.get(headerFooterImage.id);
      if (!workbookImage) {
        continue;
      }
      const { index, type } = workbookImage;
      XMLArr.push({
        Id: `rId${i + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/image",
        Target: `../media/image${index + 1}.${_normaliseImageExtension(type)}`
      });
    }
    return createXmlPart(relationships_default.getTemplate(XMLArr));
  }
  static createRelationships({
    drawingIndex,
    vmlDrawingIndex,
    tableIndex
  } = {}) {
    if (drawingIndex === void 0 && vmlDrawingIndex === void 0 && tableIndex === void 0) {
      return "";
    }
    const config = [];
    if (drawingIndex != null) {
      config.push({
        Id: `rId${config.length + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/drawing",
        Target: `../drawings/drawing${drawingIndex + 1}.xml`
      });
    }
    if (vmlDrawingIndex != null) {
      config.push({
        Id: `rId${config.length + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/vmlDrawing",
        Target: `../drawings/vmlDrawing${vmlDrawingIndex + 1}.vml`
      });
    }
    if (tableIndex != null) {
      config.push({
        Id: `rId${config.length + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/table",
        Target: `../tables/${this.getTableNameFromIndex(tableIndex)}.xml`
      });
    }
    const rs = relationships_default.getTemplate(config);
    return createXmlPart(rs);
  }
  static createWorksheet(worksheet, config) {
    return createXmlPart(
      worksheet_default.getTemplate({
        worksheet,
        currentSheet: this.sheetNames.length - 1,
        config
      })
    );
  }
};
_ExcelXlsxFactory.sharedStrings = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.sheetNames = [];
_ExcelXlsxFactory.images = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetImages = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetHeaderFooterImages = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.workbookImageIds = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetImageIds = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetDataTables = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.defaultTableDisplayName = "AG-GRID-TABLE";
_ExcelXlsxFactory.factoryMode = ExcelFactoryMode.SINGLE_SHEET;
var ExcelXlsxFactory = _ExcelXlsxFactory;
var ExcelSerializingSession = class extends BaseGridSerializingSession {
  constructor(config) {
    super(config);
    this.mixedStyles = {};
    this.mixedStyleCounter = 0;
    this.rows = [];
    this.frozenRowCount = 0;
    this.skipFrozenRows = false;
    this.frozenColumnCount = 0;
    this.skipFrozenColumns = false;
    this.config = Object.assign({}, config);
    this.stylesByIds = {};
    this.config.baseExcelStyles.forEach((style) => {
      this.stylesByIds[style.id] = style;
    });
    this.excelStyles = [...this.config.baseExcelStyles, { id: "_quotePrefix", quotePrefix: 1 }];
  }
  addCustomContent(customContent) {
    customContent.forEach((row) => {
      const rowLen = this.rows.length + 1;
      let outlineLevel;
      if (!this.config.suppressRowOutline && row.outlineLevel != null) {
        outlineLevel = row.outlineLevel;
      }
      const rowObj = {
        height: getHeightFromProperty(rowLen, row.height || this.config.rowHeight),
        cells: (row.cells || []).map((cell, idx) => {
          const image = this.addImage(rowLen, this.columnsToExport[idx], cell.data?.value);
          let excelStyles = null;
          if (cell.styleId) {
            excelStyles = typeof cell.styleId === "string" ? [cell.styleId] : cell.styleId;
          }
          const excelStyleId = this.getStyleId(excelStyles);
          if (image) {
            return this.createCell(
              excelStyleId,
              this.getDataTypeForValue(image.value),
              image.value == null ? "" : image.value
            );
          }
          const value = cell.data?.value ?? "";
          const type = this.getDataTypeForValue(value);
          if (cell.mergeAcross) {
            return this.createMergedCell(excelStyleId, type, value, cell.mergeAcross);
          }
          return this.createCell(excelStyleId, type, value);
        }),
        outlineLevel
      };
      if (row.collapsed != null) {
        rowObj.collapsed = row.collapsed;
      }
      if (row.hidden != null) {
        rowObj.hidden = row.hidden;
      }
      this.rows.push(rowObj);
    });
  }
  onNewHeaderGroupingRow() {
    const currentCells = [];
    const { freezeRows, headerRowHeight } = this.config;
    this.rows.push({
      cells: currentCells,
      height: getHeightFromProperty(this.rows.length + 1, headerRowHeight)
    });
    if (freezeRows) {
      this.frozenRowCount++;
    }
    return {
      onColumn: (columnGroup, header, index, span, collapsibleRanges) => {
        const styleIds = this.config.styleLinker({
          rowType: RowType.HEADER_GROUPING,
          rowIndex: 1,
          value: `grouping-${header}`,
          columnGroup
        });
        currentCells.push({
          ...this.createMergedCell(
            this.getStyleId(styleIds),
            this.getDataTypeForValue("string"),
            header,
            span
          ),
          collapsibleRanges
        });
      }
    };
  }
  onNewHeaderRow() {
    const { freezeRows, headerRowHeight } = this.config;
    if (freezeRows) {
      this.frozenRowCount++;
    }
    return this.onNewRow(this.onNewHeaderColumn, headerRowHeight);
  }
  onNewBodyRow(node) {
    const { freezeRows, rowHeight } = this.config;
    if (!this.skipFrozenRows) {
      if (freezeRows === "headersAndPinnedRows" && node?.rowPinned === "top") {
        this.frozenRowCount++;
      } else if (typeof freezeRows === "function") {
        if (freezeRows({ ...this.gos.getGridCommonParams(), node })) {
          this.frozenRowCount++;
        } else {
          this.skipFrozenRows = true;
        }
      } else {
        this.skipFrozenRows = true;
      }
    }
    const rowAccumulator = this.onNewRow(this.onNewBodyColumn, rowHeight);
    if (node) {
      this.addRowOutlineIfNecessary(node);
    }
    return rowAccumulator;
  }
  prepare(columnsToExport) {
    super.prepare(columnsToExport);
    this.columnsToExport = [...columnsToExport];
    this.cols = columnsToExport.map((col, i) => this.convertColumnToExcel(col, i));
  }
  parse() {
    const longestRow = this.rows.reduce((a, b) => Math.max(a, b.cells.length), 0);
    while (this.cols.length < longestRow) {
      this.cols.push(this.convertColumnToExcel(null, this.cols.length + 1));
    }
    const { config } = this;
    let name;
    if (config.sheetName != null) {
      const { sheetName } = config;
      const sheetNameValue = typeof sheetName === "function" ? sheetName(this.gos.getGridCommonParams()) : sheetName;
      name = String(sheetNameValue).substring(0, 31);
    } else {
      name = "ag-grid";
    }
    const data = {
      name,
      table: {
        columns: this.cols,
        rows: this.rows
      }
    };
    return this.createExcel(data);
  }
  addRowOutlineIfNecessary(node) {
    const { gos, suppressRowOutline, rowGroupExpandState = "expanded" } = this.config;
    const isGroupHideOpenParents = gos.get("groupHideOpenParents");
    if (isGroupHideOpenParents || suppressRowOutline || node.level == null) {
      return;
    }
    const padding = node.footer ? 1 : 0;
    const currentRow = _last10(this.rows);
    const outlineLevel = Math.min(node.level + padding, 7);
    currentRow.outlineLevel = outlineLevel;
    if (rowGroupExpandState === "expanded") {
      return;
    }
    const collapseAll = rowGroupExpandState === "collapsed";
    if (node.isExpandable()) {
      const isExpanded = !collapseAll && node.expanded;
      currentRow.collapsed = !isExpanded;
    }
    currentRow.hidden = // always show the node if there is no parent to be expanded
    !!node.parent && // or if it is a child of the root node
    node.parent.level !== -1 && (collapseAll || this.isAnyParentCollapsed(node.parent));
  }
  isAnyParentCollapsed(node) {
    while (node && node.level !== -1) {
      if (!node.expanded) {
        return true;
      }
      node = node.parent;
    }
    return false;
  }
  convertColumnToExcel(column, index) {
    const columnWidth = this.config.columnWidth;
    const headerValue = column ? this.extractHeaderValue(column) : void 0;
    const displayName = headerValue ?? "";
    const filterAllowed = column ? column.isFilterAllowed() : false;
    if (columnWidth) {
      if (typeof columnWidth === "number") {
        return { width: columnWidth, displayName, filterAllowed };
      }
      return { width: columnWidth({ column, index }), displayName, filterAllowed };
    }
    if (column) {
      const smallestUsefulWidth = 75;
      return { width: Math.max(column.getActualWidth(), smallestUsefulWidth), displayName, filterAllowed };
    }
    return {
      displayName,
      filterAllowed
    };
  }
  onNewHeaderColumn(rowIndex, currentCells) {
    return (column) => {
      const nameForCol = this.extractHeaderValue(column);
      const styleIds = this.config.styleLinker({
        rowType: RowType.HEADER,
        rowIndex,
        value: nameForCol,
        column
      });
      currentCells.push(
        this.createCell(this.getStyleId(styleIds), this.getDataTypeForValue("string"), nameForCol)
      );
    };
  }
  onNewBodyColumn(rowIndex, currentCells) {
    let skipCols = 0;
    const { freezeColumns, rightToLeft } = this.config;
    return (column, index, node) => {
      if (skipCols > 0) {
        skipCols -= 1;
        return;
      }
      if (!this.skipFrozenColumns) {
        const pinned = column.getPinned();
        const isPinnedLeft = pinned === true || pinned === "left";
        if (freezeColumns === "pinned" && pinned && isPinnedLeft !== rightToLeft) {
          this.frozenColumnCount++;
        } else if (typeof freezeColumns === "function" && freezeColumns({ ...this.gos.getGridCommonParams(), column })) {
          this.frozenColumnCount++;
        } else {
          this.skipFrozenColumns = true;
        }
      }
      const { value: valueForCell, valueFormatted } = this.extractRowCellValue(
        column,
        index,
        rowIndex,
        "excel",
        node
      );
      const styleIds = this.config.styleLinker({
        rowType: RowType.BODY,
        rowIndex,
        value: valueForCell,
        column,
        node
      });
      const excelStyleId = this.getStyleId(styleIds);
      const colSpan = column.getColSpan(node);
      const addedImage = this.addImage(rowIndex, column, valueForCell);
      if (addedImage) {
        currentCells.push(
          this.createCell(
            excelStyleId,
            this.getDataTypeForValue(addedImage.value),
            addedImage.value == null ? "" : addedImage.value
          )
        );
      } else if (colSpan > 1) {
        skipCols = colSpan - 1;
        currentCells.push(
          this.createMergedCell(
            excelStyleId,
            this.getDataTypeForValue(valueForCell),
            valueForCell,
            colSpan - 1
          )
        );
      } else {
        currentCells.push(
          this.createCell(excelStyleId, this.getDataTypeForValue(valueForCell), valueForCell, valueFormatted)
        );
      }
    };
  }
  onNewRow(onNewColumnAccumulator, height) {
    const currentCells = [];
    this.rows.push({
      cells: currentCells,
      height: getHeightFromProperty(this.rows.length + 1, height)
    });
    return {
      onColumn: onNewColumnAccumulator.bind(this, this.rows.length, currentCells)()
    };
  }
  createExcel(data) {
    const { excelStyles, config } = this;
    if (this.frozenColumnCount) {
      config.frozenColumnCount = this.frozenColumnCount;
    }
    if (this.frozenRowCount) {
      config.frozenRowCount = this.frozenRowCount;
    }
    return ExcelXlsxFactory.createExcel(excelStyles, data, config);
  }
  getDataTypeForValue(valueForCell) {
    if (valueForCell === void 0) {
      return "empty";
    }
    return this.isNumerical(valueForCell) ? "n" : "s";
  }
  getTypeFromStyle(style, value) {
    if (this.isFormula(value)) {
      return "f";
    }
    if (style && style.dataType) {
      switch (style.dataType.toLocaleLowerCase()) {
        case "formula":
          return "f";
        case "string":
          return "s";
        case "number":
          return "n";
        case "datetime":
          return "d";
        case "error":
          return "e";
        case "boolean":
          return "b";
        default:
          _warnOnce26(`Unrecognized data type for excel export [${style.id}.dataType=${style.dataType}]`);
      }
    }
    return null;
  }
  addImage(rowIndex, column, value) {
    if (!this.config.addImageToCell) {
      return;
    }
    const addedImage = this.config.addImageToCell(rowIndex, column, value);
    if (!addedImage) {
      return;
    }
    ExcelXlsxFactory.addBodyImageToMap(
      addedImage.image,
      rowIndex,
      column,
      this.columnsToExport,
      this.config.rowHeight
    );
    return addedImage;
  }
  createCell(styleId, type, value, valueFormatted) {
    const actualStyle = this.getStyleById(styleId);
    if (!actualStyle?.dataType && type === "s" && valueFormatted) {
      value = valueFormatted;
    }
    const processedType = this.getTypeFromStyle(actualStyle, value) || type;
    const { value: processedValue, escaped } = this.getCellValue(processedType, value);
    const styles = [];
    if (actualStyle) {
      styles.push(styleId);
    }
    if (escaped) {
      styles.push("_quotePrefix");
    }
    styleId = this.getStyleId(styles) || void 0;
    return {
      styleId,
      data: {
        type: processedType,
        value: processedValue
      }
    };
  }
  createMergedCell(styleId, type, value, numOfCells) {
    const valueToUse = value == null ? "" : value;
    return {
      styleId: this.getStyleById(styleId) ? styleId : void 0,
      data: {
        type,
        value: type === "s" ? ExcelXlsxFactory.getStringPosition(valueToUse).toString() : value
      },
      mergeAcross: numOfCells
    };
  }
  getCellValue(type, value) {
    let escaped = false;
    if (value == null || type === "s" && value === "") {
      return { value: "", escaped: false };
    }
    if (type === "s") {
      if (value && value[0] === "'") {
        escaped = true;
        value = value.slice(1);
      }
      value = ExcelXlsxFactory.getStringPosition(value).toString();
    } else if (type === "f") {
      value = value.slice(1);
    } else if (type === "n") {
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        value = "";
      } else if (value !== "") {
        value = numberValue.toString();
      }
    }
    return { value, escaped };
  }
  getStyleId(styleIds) {
    if (!styleIds || !styleIds.length) {
      return null;
    }
    if (styleIds.length === 1) {
      return styleIds[0];
    }
    const key = styleIds.join("-");
    if (!this.mixedStyles[key]) {
      this.addNewMixedStyle(styleIds);
    }
    return this.mixedStyles[key].excelID;
  }
  deepCloneObject(object) {
    return JSON.parse(JSON.stringify(object));
  }
  addNewMixedStyle(styleIds) {
    this.mixedStyleCounter += 1;
    const excelId = `mixedStyle${this.mixedStyleCounter}`;
    const resultantStyle = {};
    for (const styleId of styleIds) {
      for (const excelStyle of this.excelStyles) {
        if (excelStyle.id === styleId) {
          _mergeDeep3(resultantStyle, this.deepCloneObject(excelStyle));
        }
      }
    }
    resultantStyle.id = excelId;
    resultantStyle.name = excelId;
    const key = styleIds.join("-");
    this.mixedStyles[key] = {
      excelID: excelId,
      key,
      result: resultantStyle
    };
    this.excelStyles.push(resultantStyle);
    this.stylesByIds[excelId] = resultantStyle;
  }
  isFormula(value) {
    if (value == null) {
      return false;
    }
    return this.config.autoConvertFormulas && value.toString().startsWith("=");
  }
  isNumerical(value) {
    if (typeof value === "bigint") {
      return true;
    }
    return isFinite(value) && value !== "" && !isNaN(parseFloat(value));
  }
  getStyleById(styleId) {
    if (styleId == null) {
      return null;
    }
    return this.stylesByIds[styleId] || null;
  }
};
var createExcelXMLCoreFolderStructure = () => {
  ZipContainer.addFolders(["_rels/", "docProps/", "xl/", "xl/theme/", "xl/_rels/", "xl/worksheets/"]);
  const { images } = ExcelXlsxFactory;
  if (!images.size) {
    return;
  }
  ZipContainer.addFolders(["xl/worksheets/_rels", "xl/drawings/", "xl/drawings/_rels", "xl/media/"]);
  let imgCounter = 0;
  images.forEach((value) => {
    const firstImage = value[0].image[0];
    const { base64, imageType } = firstImage;
    ZipContainer.addFile(`xl/media/image${++imgCounter}.${_normaliseImageExtension(imageType)}`, base64, true);
  });
};
var createExcelXmlWorksheets = (data) => {
  let imageRelationCounter = 0;
  let headerFooterImageCounter = 0;
  let tableRelationCounter = 0;
  const { images, worksheetDataTables, worksheetImages, worksheetHeaderFooterImages } = ExcelXlsxFactory;
  for (let i = 0; i < data.length; i++) {
    const value = data[i];
    ZipContainer.addFile(`xl/worksheets/sheet${i + 1}.xml`, value, false);
    const hasImages = images.size > 0 && worksheetImages.has(i);
    const hasTables = worksheetDataTables.size > 0 && worksheetDataTables.has(i);
    const hasHeaderFooterImages = images.size && worksheetHeaderFooterImages.has(i);
    if (!hasImages && !hasTables && !hasHeaderFooterImages) {
      continue;
    }
    let tableIndex;
    let drawingIndex;
    let vmlDrawingIndex;
    if (hasImages) {
      createExcelXmlDrawings(i, imageRelationCounter);
      drawingIndex = imageRelationCounter;
      imageRelationCounter++;
    }
    if (hasHeaderFooterImages) {
      createExcelVmlDrawings(i, headerFooterImageCounter);
      vmlDrawingIndex = headerFooterImageCounter;
      headerFooterImageCounter++;
    }
    if (hasTables) {
      tableIndex = tableRelationCounter++;
    }
    const worksheetRelFile = `xl/worksheets/_rels/sheet${i + 1}.xml.rels`;
    ZipContainer.addFile(
      worksheetRelFile,
      ExcelXlsxFactory.createRelationships({
        tableIndex,
        drawingIndex,
        vmlDrawingIndex
      })
    );
  }
};
var createExcelXmlDrawings = (sheetIndex, drawingIndex) => {
  const drawingFolder = "xl/drawings";
  const drawingFileName = `${drawingFolder}/drawing${drawingIndex + 1}.xml`;
  const relFileName = `${drawingFolder}/_rels/drawing${drawingIndex + 1}.xml.rels`;
  ZipContainer.addFile(relFileName, ExcelXlsxFactory.createDrawingRel(sheetIndex));
  ZipContainer.addFile(drawingFileName, ExcelXlsxFactory.createDrawing(sheetIndex));
};
var createExcelVmlDrawings = (sheetIndex, drawingIndex) => {
  const drawingFolder = "xl/drawings";
  const drawingFileName = `${drawingFolder}/vmlDrawing${drawingIndex + 1}.vml`;
  const relFileName = `${drawingFolder}/_rels/vmlDrawing${drawingIndex + 1}.vml.rels`;
  ZipContainer.addFile(drawingFileName, ExcelXlsxFactory.createVmlDrawing(sheetIndex));
  ZipContainer.addFile(relFileName, ExcelXlsxFactory.createVmlDrawingRel(sheetIndex));
};
var createExcelXmlTables = () => {
  const { worksheetDataTables } = ExcelXlsxFactory;
  const tablesDataByWorksheet = worksheetDataTables;
  const worksheetKeys = Array.from(tablesDataByWorksheet.keys());
  for (let i = 0; i < worksheetKeys.length; i++) {
    const sheetIndex = worksheetKeys[i];
    const dataTable = tablesDataByWorksheet.get(sheetIndex);
    if (!dataTable) {
      continue;
    }
    ZipContainer.addFile(`xl/tables/${dataTable.name}.xml`, ExcelXlsxFactory.createTable(dataTable, i));
  }
};
var createExcelXmlCoreSheets = (fontSize, author, sheetLen, activeTab) => {
  ZipContainer.addFile("xl/workbook.xml", ExcelXlsxFactory.createWorkbook(activeTab));
  ZipContainer.addFile("xl/styles.xml", ExcelXlsxFactory.createStylesheet(fontSize));
  ZipContainer.addFile("xl/sharedStrings.xml", ExcelXlsxFactory.createSharedStrings());
  ZipContainer.addFile("xl/theme/theme1.xml", ExcelXlsxFactory.createTheme());
  ZipContainer.addFile("xl/_rels/workbook.xml.rels", ExcelXlsxFactory.createWorkbookRels(sheetLen));
  ZipContainer.addFile("docProps/core.xml", ExcelXlsxFactory.createCore(author));
  ZipContainer.addFile("[Content_Types].xml", ExcelXlsxFactory.createContentTypes(sheetLen));
  ZipContainer.addFile("_rels/.rels", ExcelXlsxFactory.createRels());
};
var createExcelFileForExcel = (data, options = {}) => {
  if (!data || data.length === 0) {
    _warnOnce34("Invalid params supplied to createExcelFileForExcel() - `ExcelExportParams.data` is empty.");
    ExcelXlsxFactory.resetFactory();
    return false;
  }
  const { fontSize = 11, author = "AG Grid", activeTab = 0 } = options;
  const len = data.length;
  const activeTabWithinBounds = Math.max(Math.min(activeTab, len - 1), 0);
  createExcelXMLCoreFolderStructure();
  createExcelXmlTables();
  createExcelXmlWorksheets(data);
  createExcelXmlCoreSheets(fontSize, author, len, activeTabWithinBounds);
  ExcelXlsxFactory.resetFactory();
  return true;
};
var getMultipleSheetsAsExcelCompressed = (params) => {
  const { data, fontSize, author, activeSheetIndex } = params;
  const mimeType = params.mimeType || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  if (!createExcelFileForExcel(data, {
    author,
    fontSize,
    activeTab: activeSheetIndex
  })) {
    return Promise.resolve(void 0);
  }
  return ZipContainer.getZipFile(mimeType);
};
var getMultipleSheetsAsExcel = (params) => {
  const { data, fontSize, author, activeSheetIndex } = params;
  const mimeType = params.mimeType || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  if (!createExcelFileForExcel(data, {
    author,
    fontSize,
    activeTab: activeSheetIndex
  })) {
    return;
  }
  return ZipContainer.getUncompressedZipFile(mimeType);
};
var exportMultipleSheetsAsExcel = (params) => {
  const { fileName = "export.xlsx" } = params;
  getMultipleSheetsAsExcelCompressed(params).then((contents) => {
    if (contents) {
      const downloadFileName = typeof fileName === "function" ? fileName() : fileName;
      Downloader.download(downloadFileName, contents);
    }
  });
};
var ExcelCreator = class extends BaseCreator {
  constructor() {
    super(...arguments);
    this.beanName = "excelCreator";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.funcColsService = beans.funcColsService;
    this.valueService = beans.valueService;
    this.stylingService = beans.stylingService;
    this.gridSerializer = beans.gridSerializer;
    this.gos = beans.gos;
  }
  postConstruct() {
    this.setBeans({
      gridSerializer: this.gridSerializer,
      gos: this.gos
    });
  }
  getMergedParams(params) {
    const baseParams = this.gos.get("defaultExcelExportParams");
    return Object.assign({}, baseParams, params);
  }
  export(userParams) {
    if (this.isExportSuppressed()) {
      _warnOnce34(`Export cancelled. Export is not allowed as per your configuration.`);
      return;
    }
    const mergedParams = this.getMergedParams(userParams);
    const data = this.getData(mergedParams);
    const exportParams = {
      data: [data],
      fontSize: mergedParams.fontSize,
      author: mergedParams.author,
      mimeType: mergedParams.mimeType
    };
    this.packageCompressedFile(exportParams).then((packageFile) => {
      if (packageFile) {
        const { fileName } = mergedParams;
        const providedFileName = typeof fileName === "function" ? fileName(this.gos.getGridCommonParams()) : fileName;
        Downloader.download(this.getFileName(providedFileName), packageFile);
      }
    });
  }
  exportDataAsExcel(params) {
    this.export(params);
  }
  getDataAsExcel(params) {
    const mergedParams = this.getMergedParams(params);
    const data = this.getData(mergedParams);
    const exportParams = {
      data: [data],
      fontSize: mergedParams.fontSize,
      author: mergedParams.author,
      mimeType: mergedParams.mimeType
    };
    return this.packageFile(exportParams);
  }
  setFactoryMode(factoryMode) {
    ExcelXlsxFactory.factoryMode = factoryMode;
  }
  getFactoryMode() {
    return ExcelXlsxFactory.factoryMode;
  }
  getSheetDataForExcel(params) {
    const mergedParams = this.getMergedParams(params);
    return this.getData(mergedParams);
  }
  getMultipleSheetsAsExcel(params) {
    return getMultipleSheetsAsExcel(params);
  }
  exportMultipleSheetsAsExcel(params) {
    exportMultipleSheetsAsExcel(params);
  }
  getDefaultFileExtension() {
    return "xlsx";
  }
  createSerializingSession(params) {
    const { columnModel, columnNameService, funcColsService, valueService, gos } = this;
    const config = {
      ...params,
      columnModel,
      columnNameService,
      funcColsService,
      valueService,
      gos,
      suppressRowOutline: params.suppressRowOutline || params.skipRowGroups,
      headerRowHeight: params.headerRowHeight || params.rowHeight,
      baseExcelStyles: this.gos.get("excelStyles") || [],
      rightToLeft: params.rightToLeft ?? this.gos.get("enableRtl"),
      styleLinker: this.styleLinker.bind(this)
    };
    return new ExcelSerializingSession(config);
  }
  styleLinker(params) {
    const { rowType, rowIndex, value, column, columnGroup, node } = params;
    const isHeader = rowType === RowType2.HEADER;
    const isGroupHeader = rowType === RowType2.HEADER_GROUPING;
    const col = isHeader ? column : columnGroup;
    let headerClasses = [];
    if (isHeader || isGroupHeader) {
      headerClasses.push("header");
      if (isGroupHeader) {
        headerClasses.push("headerGroup");
      }
      if (col) {
        headerClasses = headerClasses.concat(
          _getHeaderClassesFromColDef(
            col.getDefinition(),
            this.gos,
            column || null,
            columnGroup || null
          )
        );
      }
      return headerClasses;
    }
    const styles = this.gos.get("excelStyles");
    const applicableStyles = ["cell"];
    if (!styles || !styles.length) {
      return applicableStyles;
    }
    const styleIds = styles.map((it) => {
      return it.id;
    });
    const colDef = column.getDefinition();
    this.stylingService.processAllCellClasses(
      colDef,
      this.gos.addGridCommonParams({
        value,
        data: node.data,
        node,
        colDef,
        column,
        rowIndex
      }),
      (className) => {
        if (styleIds.indexOf(className) > -1) {
          applicableStyles.push(className);
        }
      }
    );
    return applicableStyles.sort((left, right) => {
      return styleIds.indexOf(left) < styleIds.indexOf(right) ? -1 : 1;
    });
  }
  isExportSuppressed() {
    return this.gos.get("suppressExcelExport");
  }
  packageCompressedFile(params) {
    return getMultipleSheetsAsExcelCompressed(params);
  }
  packageFile(params) {
    return getMultipleSheetsAsExcel(params);
  }
};
function assertNotExcelMultiSheet(beans) {
  if (beans.excelCreator?.getFactoryMode() === ExcelFactoryMode2.MULTI_SHEET) {
    _warnOnce44(
      "The Excel Exporter is currently on Multi Sheet mode. End that operation by calling 'api.getMultipleSheetAsExcel()' or 'api.exportMultipleSheetsAsExcel()'"
    );
    return false;
  }
  return true;
}
function getDataAsExcel(beans, params) {
  if (assertNotExcelMultiSheet(beans)) {
    return beans.excelCreator?.getDataAsExcel(params);
  }
  return void 0;
}
function exportDataAsExcel(beans, params) {
  if (assertNotExcelMultiSheet(beans)) {
    beans.excelCreator?.exportDataAsExcel(params);
  }
}
function getSheetDataForExcel(beans, params) {
  beans.excelCreator?.setFactoryMode(ExcelFactoryMode2.MULTI_SHEET);
  return beans.excelCreator?.getSheetDataForExcel(params);
}
function getMultipleSheetsAsExcel2(beans, params) {
  return beans.excelCreator?.getMultipleSheetsAsExcel(params);
}
function exportMultipleSheetsAsExcel2(beans, params) {
  beans.excelCreator?.exportMultipleSheetsAsExcel(params);
}
var VERSION9 = "32.3.0";
var ExcelExportCoreModule = _defineModule9({
  version: VERSION9,
  moduleName: `${ModuleNames10.ExcelExportModule}-core`,
  beans: [ExcelCreator],
  dependantModules: [_CsvExportCoreModule, EnterpriseCoreModule]
});
var ExcelExportApiModule = _defineModule9({
  version: VERSION9,
  moduleName: `${ModuleNames10.ExcelExportModule}-api`,
  apiFunctions: {
    getDataAsExcel,
    exportDataAsExcel,
    getSheetDataForExcel,
    getMultipleSheetsAsExcel: getMultipleSheetsAsExcel2,
    exportMultipleSheetsAsExcel: exportMultipleSheetsAsExcel2
  },
  dependantModules: [ExcelExportCoreModule]
});
var ExcelExportModule = _defineModule9({
  version: VERSION9,
  moduleName: ModuleNames10.ExcelExportModule,
  dependantModules: [ExcelExportCoreModule, ExcelExportApiModule]
});

// enterprise-modules/filter-tool-panel/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames11, _ColumnFilterModule as _ColumnFilterModule2, _defineModule as _defineModule10 } from "ag-grid-community";
import { Component as Component55, RefPlaceholder as RefPlaceholder45 } from "ag-grid-community";
import {
  AgInputTextFieldSelector as AgInputTextFieldSelector5,
  Component as Component50,
  RefPlaceholder as RefPlaceholder28,
  _createIconNoSpan as _createIconNoSpan14,
  _debounce as _debounce3,
  _setDisplayed as _setDisplayed11
} from "ag-grid-community";
import {
  Component as Component411,
  _clearElement as _clearElement33,
  _exists as _exists13,
  _flatten,
  _getActiveDomElement as _getActiveDomElement10,
  _includes as _includes11,
  _mergeDeep as _mergeDeep4,
  _setAriaLabel as _setAriaLabel9,
  _warnOnce as _warnOnce28,
  isProvidedColumnGroup as isProvidedColumnGroup22
} from "ag-grid-community";
import {
  Component as Component212,
  FilterWrapperComp as FilterWrapperComp2,
  KeyCode as KeyCode19,
  RefPlaceholder as RefPlaceholder29,
  _clearElement as _clearElement10,
  _createIconNoSpan as _createIconNoSpan26,
  _loadTemplate as _loadTemplate5,
  _setAriaExpanded as _setAriaExpanded8,
  _setDisplayed as _setDisplayed25
} from "ag-grid-community";
import {
  Component as Component312,
  RefPlaceholder as RefPlaceholder36,
  _clearElement as _clearElement24,
  _createIconNoSpan as _createIconNoSpan35,
  isProvidedColumnGroup as isProvidedColumnGroup5
} from "ag-grid-community";
var AgFiltersToolPanelHeader = class extends Component50 {
  constructor() {
    super(...arguments);
    this.eExpand = RefPlaceholder28;
    this.eFilterTextField = RefPlaceholder28;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
  }
  postConstruct() {
    this.setTemplate(
      /* html */
      `<div class="ag-filter-toolpanel-search" role="presentation">
                <div data-ref="eExpand" class="ag-filter-toolpanel-expand"></div>
                <ag-input-text-field data-ref="eFilterTextField" class="ag-filter-toolpanel-search-input"></ag-input-text-field>
            </div>`,
      [AgInputTextFieldSelector5]
    );
    const translate = this.localeService.getLocaleTextFunc();
    this.eFilterTextField.setAutoComplete(false).setInputAriaLabel(translate("ariaFilterColumnsInput", "Filter Columns Input")).onValueChange(this.onSearchTextChanged.bind(this));
    this.createExpandIcons();
    this.setExpandState(
      0
      /* EXPANDED */
    );
    this.addManagedElementListeners(this.eExpand, { click: this.onExpandClicked.bind(this) });
    this.addManagedEventListeners({ newColumnsLoaded: this.showOrHideOptions.bind(this) });
  }
  init(params) {
    this.params = params;
    if (this.columnModel.isReady()) {
      this.showOrHideOptions();
    }
  }
  createExpandIcons() {
    this.eExpand.appendChild(this.eExpandChecked = _createIconNoSpan14("columnSelectOpen", this.gos));
    this.eExpand.appendChild(this.eExpandUnchecked = _createIconNoSpan14("columnSelectClosed", this.gos));
    this.eExpand.appendChild(
      this.eExpandIndeterminate = _createIconNoSpan14("columnSelectIndeterminate", this.gos)
    );
  }
  // we only show expand / collapse if we are showing filters
  showOrHideOptions() {
    const showFilterSearch = !this.params.suppressFilterSearch;
    const showExpand = !this.params.suppressExpandAll;
    const translate = this.localeService.getLocaleTextFunc();
    this.eFilterTextField.setInputPlaceholder(translate("searchOoo", "Search..."));
    const isFilterGroupPresent = (col) => col.getOriginalParent() && col.isFilterAllowed();
    const filterGroupsPresent = this.columnModel.getCols().some(isFilterGroupPresent);
    _setDisplayed11(this.eFilterTextField.getGui(), showFilterSearch);
    _setDisplayed11(this.eExpand, showExpand && filterGroupsPresent);
  }
  onSearchTextChanged() {
    if (!this.onSearchTextChangedDebounced) {
      this.onSearchTextChangedDebounced = _debounce3(() => {
        this.dispatchLocalEvent({ type: "searchChanged", searchText: this.eFilterTextField.getValue() });
      }, 300);
    }
    this.onSearchTextChangedDebounced();
  }
  onExpandClicked() {
    const event = this.currentExpandState === 0 ? { type: "collapseAll" } : { type: "expandAll" };
    this.dispatchLocalEvent(event);
  }
  setExpandState(state) {
    this.currentExpandState = state;
    _setDisplayed11(
      this.eExpandChecked,
      this.currentExpandState === 0
      /* EXPANDED */
    );
    _setDisplayed11(
      this.eExpandUnchecked,
      this.currentExpandState === 1
      /* COLLAPSED */
    );
    _setDisplayed11(
      this.eExpandIndeterminate,
      this.currentExpandState === 2
      /* INDETERMINATE */
    );
  }
};
var AgFiltersToolPanelHeaderSelector = {
  selector: "AG-FILTERS-TOOL-PANEL-HEADER",
  component: AgFiltersToolPanelHeader
};
var ToolPanelFilterComp = class extends Component212 {
  constructor(hideHeader, expandedCallback) {
    super(
      /* html */
      `
            <div class="ag-filter-toolpanel-instance">
                <div class="ag-filter-toolpanel-header ag-filter-toolpanel-instance-header" data-ref="eFilterToolPanelHeader" role="button" aria-expanded="false">
                    <div data-ref="eExpand" class="ag-filter-toolpanel-expand"></div>
                    <span data-ref="eFilterName" class="ag-header-cell-text"></span>
                    <span data-ref="eFilterIcon" class="ag-header-icon ag-filter-icon ag-filter-toolpanel-instance-header-icon" aria-hidden="true"></span>
                </div>
                <div class="ag-filter-toolpanel-instance-body ag-filter" data-ref="agFilterToolPanelBody"></div>
            </div>`
    );
    this.expandedCallback = expandedCallback;
    this.eFilterToolPanelHeader = RefPlaceholder29;
    this.eFilterName = RefPlaceholder29;
    this.agFilterToolPanelBody = RefPlaceholder29;
    this.eFilterIcon = RefPlaceholder29;
    this.eExpand = RefPlaceholder29;
    this.expanded = false;
    this.hideHeader = hideHeader;
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.columnNameService = beans.columnNameService;
  }
  postConstruct() {
    this.eExpandChecked = _createIconNoSpan26("columnSelectOpen", this.gos);
    this.eExpandUnchecked = _createIconNoSpan26("columnSelectClosed", this.gos);
    this.eExpand.appendChild(this.eExpandChecked);
    this.eExpand.appendChild(this.eExpandUnchecked);
  }
  setColumn(column) {
    this.column = column;
    this.eFilterName.innerText = this.columnNameService.getDisplayNameForColumn(this.column, "filterToolPanel", false) || "";
    this.addManagedListeners(this.eFilterToolPanelHeader, {
      click: this.toggleExpanded.bind(this),
      keydown: this.onKeyDown.bind(this)
    });
    this.addManagedEventListeners({ filterOpened: this.onFilterOpened.bind(this) });
    this.addInIcon("filter", this.eFilterIcon, this.column);
    _setDisplayed25(this.eFilterIcon, this.isFilterActive(), { skipAriaHidden: true });
    _setDisplayed25(this.eExpandChecked, false);
    if (this.hideHeader) {
      _setDisplayed25(this.eFilterToolPanelHeader, false);
      this.eFilterToolPanelHeader.removeAttribute("tabindex");
    } else {
      this.eFilterToolPanelHeader.setAttribute("tabindex", "0");
    }
    this.addManagedListeners(this.column, { filterChanged: this.onFilterChanged.bind(this) });
  }
  onKeyDown(e) {
    const { key } = e;
    const { ENTER, SPACE, LEFT, RIGHT } = KeyCode19;
    if (key !== ENTER && key !== SPACE && key !== LEFT && key !== RIGHT) {
      return;
    }
    e.preventDefault();
    if (key === ENTER || key === SPACE) {
      this.toggleExpanded();
    } else if (key === KeyCode19.LEFT) {
      this.collapse();
    } else {
      this.expand();
    }
  }
  getColumn() {
    return this.column;
  }
  getColumnFilterName() {
    return this.columnNameService.getDisplayNameForColumn(this.column, "filterToolPanel", false);
  }
  addCssClassToTitleBar(cssClass) {
    this.eFilterToolPanelHeader.classList.add(cssClass);
  }
  addInIcon(iconName, eParent, column) {
    if (eParent == null) {
      return;
    }
    const eIcon = _createIconNoSpan26(iconName, this.gos, column);
    eParent.appendChild(eIcon);
  }
  isFilterActive() {
    return !!this.filterManager?.isFilterActive(this.column);
  }
  onFilterChanged() {
    _setDisplayed25(this.eFilterIcon, this.isFilterActive(), { skipAriaHidden: true });
    this.dispatchLocalEvent({ type: "filterChanged" });
  }
  toggleExpanded() {
    this.expanded ? this.collapse() : this.expand();
  }
  expand() {
    if (this.expanded) {
      return;
    }
    this.expanded = true;
    _setAriaExpanded8(this.eFilterToolPanelHeader, true);
    _setDisplayed25(this.eExpandChecked, true);
    _setDisplayed25(this.eExpandUnchecked, false);
    this.addFilterElement();
    this.expandedCallback();
  }
  addFilterElement(suppressFocus) {
    const filterPanelWrapper = _loadTemplate5(
      /* html */
      `<div class="ag-filter-toolpanel-instance-filter"></div>`
    );
    const comp = this.createManagedBean(new FilterWrapperComp2(this.column, "TOOLBAR"));
    this.filterWrapperComp = comp;
    if (!comp.hasFilter()) {
      return;
    }
    comp.getFilter()?.then((filter) => {
      this.underlyingFilter = filter;
      if (!filter) {
        return;
      }
      filterPanelWrapper.appendChild(comp.getGui());
      this.agFilterToolPanelBody.appendChild(filterPanelWrapper);
      comp.afterGuiAttached({ container: "toolPanel", suppressFocus });
    });
  }
  collapse() {
    if (!this.expanded) {
      return;
    }
    this.expanded = false;
    _setAriaExpanded8(this.eFilterToolPanelHeader, false);
    this.removeFilterElement();
    _setDisplayed25(this.eExpandChecked, false);
    _setDisplayed25(this.eExpandUnchecked, true);
    this.filterWrapperComp?.afterGuiDetached();
    this.destroyBean(this.filterWrapperComp);
    this.expandedCallback();
  }
  removeFilterElement() {
    _clearElement10(this.agFilterToolPanelBody);
  }
  isExpanded() {
    return this.expanded;
  }
  refreshFilter(isDisplayed) {
    if (!this.expanded) {
      return;
    }
    const filter = this.underlyingFilter;
    if (!filter) {
      return;
    }
    if (isDisplayed) {
      if (typeof filter.refreshVirtualList === "function") {
        filter.refreshVirtualList();
      }
    } else {
      filter.afterGuiDetached?.();
    }
  }
  onFilterOpened(event) {
    if (event.source !== "COLUMN_MENU") {
      return;
    }
    if (event.column !== this.column) {
      return;
    }
    if (!this.expanded) {
      return;
    }
    this.collapse();
  }
};
var ToolPanelFilterGroupComp = class _ToolPanelFilterGroupComp extends Component312 {
  constructor(columnGroup, childFilterComps, expandedCallback, depth, showingColumn) {
    super();
    this.filterGroupComp = RefPlaceholder36;
    this.columnGroup = columnGroup;
    this.childFilterComps = childFilterComps;
    this.depth = depth;
    this.expandedCallback = expandedCallback;
    this.showingColumn = showingColumn;
  }
  wireBeans(beans) {
    this.columnNameService = beans.columnNameService;
  }
  postConstruct() {
    const groupParams = {
      cssIdentifier: "filter-toolpanel",
      direction: "vertical"
    };
    this.setTemplate(
      /* html */
      `<div class="ag-filter-toolpanel-group-wrapper">
            <ag-group-component data-ref="filterGroupComp"></ag-group-component>
        </div>`,
      [AgGroupComponentSelector],
      { filterGroupComp: groupParams }
    );
    this.setGroupTitle();
    this.filterGroupComp.setAlignItems("stretch");
    this.filterGroupComp.addCssClass(`ag-filter-toolpanel-group-level-${this.depth}`);
    this.filterGroupComp.getGui().style.setProperty("--ag-indentation-level", String(this.depth));
    this.filterGroupComp.addCssClassToTitleBar(`ag-filter-toolpanel-group-level-${this.depth}-header`);
    this.childFilterComps.forEach((filterComp) => {
      this.filterGroupComp.addItem(filterComp);
      filterComp.addCssClassToTitleBar(`ag-filter-toolpanel-group-level-${this.depth + 1}-header`);
      filterComp.getGui().style.setProperty("--ag-indentation-level", String(this.depth + 1));
    });
    this.refreshFilterClass();
    this.addExpandCollapseListeners();
    this.addFilterChangedListeners();
    this.setupTooltip();
    this.addInIcon("filter");
  }
  setupTooltip() {
    if (!this.showingColumn) {
      return;
    }
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    let shouldDisplayTooltip;
    if (isTooltipWhenTruncated) {
      shouldDisplayTooltip = () => {
        const eGui = this.filterGroupComp.getGui();
        const eTitle = eGui.querySelector(".ag-group-title");
        if (!eTitle) {
          return true;
        }
        return eTitle.scrollWidth > eTitle.clientWidth;
      };
    }
    const refresh = () => {
      const newTooltipText = this.columnGroup.getColDef().headerTooltip;
      this.setTooltip({ newTooltipText, location: "filterToolPanelColumnGroup", shouldDisplayTooltip });
    };
    refresh();
    this.addManagedEventListeners({ newColumnsLoaded: refresh });
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "filterToolPanelColumnGroup";
    return res;
  }
  addCssClassToTitleBar(cssClass) {
    this.filterGroupComp.addCssClassToTitleBar(cssClass);
  }
  refreshFilters(isDisplayed) {
    this.childFilterComps.forEach((filterComp) => {
      if (filterComp instanceof _ToolPanelFilterGroupComp) {
        filterComp.refreshFilters(isDisplayed);
      } else {
        filterComp.refreshFilter(isDisplayed);
      }
    });
  }
  isColumnGroup() {
    return isProvidedColumnGroup5(this.columnGroup);
  }
  isExpanded() {
    return this.filterGroupComp.isExpanded();
  }
  getChildren() {
    return this.childFilterComps;
  }
  getFilterGroupName() {
    return this.filterGroupName ? this.filterGroupName : "";
  }
  getFilterGroupId() {
    return this.columnGroup.getId();
  }
  hideGroupItem(hide, index) {
    this.filterGroupComp.hideItem(hide, index);
  }
  hideGroup(hide) {
    this.setDisplayed(!hide);
  }
  addInIcon(iconName) {
    const eIcon = _createIconNoSpan35(iconName, this.gos);
    if (eIcon) {
      eIcon.classList.add("ag-filter-toolpanel-group-instance-header-icon");
    }
    this.filterGroupComp.addTitleBarWidget(eIcon);
  }
  forEachToolPanelFilterChild(action) {
    this.childFilterComps.forEach((filterComp) => {
      if (filterComp instanceof ToolPanelFilterComp) {
        action(filterComp);
      }
    });
  }
  addExpandCollapseListeners() {
    const expandListener = this.isColumnGroup() ? () => this.expandedCallback() : () => this.forEachToolPanelFilterChild((filterComp) => filterComp.expand());
    const collapseListener = this.isColumnGroup() ? () => this.expandedCallback() : () => this.forEachToolPanelFilterChild((filterComp) => filterComp.collapse());
    this.addManagedListeners(this.filterGroupComp, {
      expanded: expandListener,
      collapsed: collapseListener
    });
  }
  getColumns() {
    if (isProvidedColumnGroup5(this.columnGroup)) {
      return this.columnGroup.getLeafColumns();
    }
    return [this.columnGroup];
  }
  addFilterChangedListeners() {
    this.getColumns().forEach((column) => {
      this.addManagedListeners(column, { filterChanged: () => this.refreshFilterClass() });
    });
    if (!isProvidedColumnGroup5(this.columnGroup)) {
      this.addManagedEventListeners({ filterOpened: this.onFilterOpened.bind(this) });
    }
  }
  refreshFilterClass() {
    const columns = this.getColumns();
    const anyChildFiltersActive = () => columns.some((col) => col.isFilterActive());
    this.filterGroupComp.addOrRemoveCssClass("ag-has-filter", anyChildFiltersActive());
  }
  onFilterOpened(event) {
    if (event.source !== "COLUMN_MENU") {
      return;
    }
    if (event.column !== this.columnGroup) {
      return;
    }
    if (!this.isExpanded()) {
      return;
    }
    this.collapse();
  }
  expand() {
    this.filterGroupComp.toggleGroupExpand(true);
  }
  collapse() {
    this.filterGroupComp.toggleGroupExpand(false);
  }
  setGroupTitle() {
    this.filterGroupName = isProvidedColumnGroup5(this.columnGroup) ? this.getColumnGroupName(this.columnGroup) : this.getColumnName(this.columnGroup);
    this.filterGroupComp.setTitle(this.filterGroupName || "");
  }
  getColumnGroupName(columnGroup) {
    return this.columnNameService.getDisplayNameForProvidedColumnGroup(null, columnGroup, "filterToolPanel");
  }
  getColumnName(column) {
    return this.columnNameService.getDisplayNameForColumn(column, "filterToolPanel", false);
  }
  destroyFilters() {
    this.childFilterComps = this.destroyBeans(this.childFilterComps);
    _clearElement24(this.getGui());
  }
  destroy() {
    this.destroyFilters();
    super.destroy();
  }
};
var AgFiltersToolPanelList = class extends Component411 {
  constructor() {
    super(
      /* html */
      `<div class="ag-filter-list-panel"></div>`
    );
    this.initialised = false;
    this.hasLoadedInitialState = false;
    this.isInitialState = false;
    this.filterGroupComps = [];
    this.suppressOnColumnsChanged = false;
    this.onColumnsChangedPending = false;
  }
  wireBeans(beans) {
    this.toolPanelColDefService = beans.toolPanelColDefService;
    this.columnModel = beans.columnModel;
  }
  init(params) {
    this.initialised = true;
    const defaultParams = this.gos.addGridCommonParams({
      suppressExpandAll: false,
      suppressFilterSearch: false,
      suppressSyncLayoutWithGrid: false
    });
    _mergeDeep4(defaultParams, params);
    this.params = defaultParams;
    if (!this.params.suppressSyncLayoutWithGrid) {
      this.addManagedEventListeners({ columnMoved: () => this.onColumnsChanged() });
    }
    this.addManagedEventListeners({
      newColumnsLoaded: () => this.onColumnsChanged(),
      toolPanelVisibleChanged: (event) => {
        if (event.key === "filters") {
          this.refreshFilters(event.visible);
        }
      },
      dragStarted: () => {
        this.suppressOnColumnsChanged = true;
      },
      dragStopped: () => {
        this.suppressOnColumnsChanged = false;
        if (this.onColumnsChangedPending) {
          this.onColumnsChangedPending = false;
          this.onColumnsChanged();
        }
      }
    });
    if (this.columnModel.isReady()) {
      this.onColumnsChanged();
    }
  }
  onColumnsChanged() {
    if (this.suppressOnColumnsChanged) {
      this.onColumnsChangedPending = true;
      return;
    }
    const pivotModeActive = this.columnModel.isPivotMode();
    const shouldSyncColumnLayoutWithGrid = !this.params.suppressSyncLayoutWithGrid && !pivotModeActive;
    shouldSyncColumnLayoutWithGrid ? this.syncFilterLayout() : this.buildTreeFromProvidedColumnDefs();
    this.refreshAriaLabel();
  }
  syncFilterLayout() {
    this.toolPanelColDefService.syncLayoutWithGrid(this.setFiltersLayout.bind(this));
    this.refreshAriaLabel();
  }
  buildTreeFromProvidedColumnDefs() {
    const columnTree = this.columnModel.getColDefColTree();
    this.recreateFilters(columnTree);
  }
  setFiltersLayout(colDefs) {
    const columnTree = this.toolPanelColDefService.createColumnTree(colDefs);
    this.recreateFilters(columnTree);
  }
  recreateFilters(columnTree) {
    const activeElement = _getActiveDomElement10(this.gos);
    if (!this.hasLoadedInitialState) {
      this.hasLoadedInitialState = true;
      this.isInitialState = !!this.params.initialState;
    }
    const expansionState = this.getExpansionState();
    this.destroyFilters();
    this.filterGroupComps = this.recursivelyAddComps(columnTree, 0, expansionState);
    const len = this.filterGroupComps.length;
    if (len) {
      this.filterGroupComps.forEach((comp) => this.appendChild(comp));
      this.setFirstAndLastVisible(0, len - 1);
    }
    if (_exists13(this.searchFilterText)) {
      this.searchFilters(this.searchFilterText);
    }
    this.fireExpandedEvent();
    if (this.getGui().contains(activeElement)) {
      activeElement.focus();
    }
    this.isInitialState = false;
    this.refreshAriaLabel();
  }
  recursivelyAddComps(tree, depth, expansionState) {
    return _flatten(
      tree.map((child) => {
        if (isProvidedColumnGroup22(child)) {
          return _flatten(this.recursivelyAddFilterGroupComps(child, depth, expansionState));
        }
        const column = child;
        if (!this.shouldDisplayFilter(column)) {
          return [];
        }
        const hideFilterCompHeader = depth === 0;
        const filterComp = new ToolPanelFilterComp(hideFilterCompHeader, () => this.onFilterExpanded());
        this.createBean(filterComp);
        filterComp.setColumn(column);
        if (expansionState.get(column.getId())) {
          filterComp.expand();
        }
        if (depth > 0) {
          return filterComp;
        }
        const filterGroupComp = this.createBean(
          new ToolPanelFilterGroupComp(column, [filterComp], this.onGroupExpanded.bind(this), depth, true)
        );
        filterGroupComp.addCssClassToTitleBar("ag-filter-toolpanel-header");
        if (!expansionState.get(filterGroupComp.getFilterGroupId())) {
          filterGroupComp.collapse();
        }
        return filterGroupComp;
      })
    );
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const filterListName = translate("ariaFilterPanelList", "Filter List");
    const localeFilters = translate("filters", "Filters");
    const eGui = this.getGui();
    const groupSelector = ".ag-filter-toolpanel-group-wrapper";
    const itemSelector = ".ag-filter-toolpanel-group-item";
    const hiddenSelector = ".ag-hidden";
    const visibleItems = eGui.querySelectorAll(`${itemSelector}:not(${groupSelector}, ${hiddenSelector})`);
    const totalVisibleItems = visibleItems.length;
    _setAriaLabel9(this.getAriaElement(), `${filterListName} ${totalVisibleItems} ${localeFilters}`);
  }
  recursivelyAddFilterGroupComps(columnGroup, depth, expansionState) {
    if (!this.filtersExistInChildren(columnGroup.getChildren())) {
      return;
    }
    const colGroupDef = columnGroup.getColGroupDef();
    if (colGroupDef && colGroupDef.suppressFiltersToolPanel) {
      return [];
    }
    const newDepth = columnGroup.isPadding() ? depth : depth + 1;
    const childFilterComps = _flatten(
      this.recursivelyAddComps(columnGroup.getChildren(), newDepth, expansionState)
    );
    if (columnGroup.isPadding()) {
      return childFilterComps;
    }
    const filterGroupComp = new ToolPanelFilterGroupComp(
      columnGroup,
      childFilterComps,
      this.onGroupExpanded.bind(this),
      depth,
      false
    );
    this.createBean(filterGroupComp);
    filterGroupComp.addCssClassToTitleBar("ag-filter-toolpanel-header");
    const expansionStateValue = expansionState.get(filterGroupComp.getFilterGroupId());
    if (this.isInitialState && !expansionStateValue || expansionStateValue === false) {
      filterGroupComp.collapse();
    }
    return [filterGroupComp];
  }
  filtersExistInChildren(tree) {
    return tree.some((child) => {
      if (isProvidedColumnGroup22(child)) {
        return this.filtersExistInChildren(child.getChildren());
      }
      return this.shouldDisplayFilter(child);
    });
  }
  shouldDisplayFilter(column) {
    const suppressFiltersToolPanel = column.getColDef() && column.getColDef().suppressFiltersToolPanel;
    return column.isFilterAllowed() && !suppressFiltersToolPanel;
  }
  getExpansionState() {
    const expansionState = /* @__PURE__ */ new Map();
    if (this.isInitialState) {
      const { expandedColIds, expandedGroupIds } = this.params.initialState;
      expandedColIds.forEach((id) => expansionState.set(id, true));
      expandedGroupIds.forEach((id) => expansionState.set(id, true));
      return expansionState;
    }
    const recursiveGetExpansionState = (filterGroupComp) => {
      expansionState.set(filterGroupComp.getFilterGroupId(), filterGroupComp.isExpanded());
      filterGroupComp.getChildren().forEach((child) => {
        if (child instanceof ToolPanelFilterGroupComp) {
          recursiveGetExpansionState(child);
        } else {
          expansionState.set(child.getColumn().getId(), child.isExpanded());
        }
      });
    };
    this.filterGroupComps.forEach(recursiveGetExpansionState);
    return expansionState;
  }
  // we don't support refreshing, but must implement because it's on the tool panel interface
  refresh() {
  }
  // lazy initialise the panel
  setVisible(visible) {
    super.setDisplayed(visible);
    if (visible && !this.initialised) {
      this.init(this.params);
    }
  }
  expandFilterGroups(expand, groupIds) {
    const updatedGroupIds = [];
    const updateGroupExpandState = (filterGroup) => {
      const groupId = filterGroup.getFilterGroupId();
      const shouldExpandOrCollapse = !groupIds || _includes11(groupIds, groupId);
      if (shouldExpandOrCollapse) {
        if (expand && filterGroup.isColumnGroup()) {
          filterGroup.expand();
        } else {
          filterGroup.collapse();
        }
        updatedGroupIds.push(groupId);
      }
      filterGroup.getChildren().forEach((child) => {
        if (child instanceof ToolPanelFilterGroupComp) {
          updateGroupExpandState(child);
        }
      });
    };
    this.filterGroupComps.forEach(updateGroupExpandState);
    this.onGroupExpanded();
    if (groupIds) {
      const unrecognisedGroupIds = groupIds.filter((groupId) => updatedGroupIds.indexOf(groupId) < 0);
      if (unrecognisedGroupIds.length > 0) {
        _warnOnce28("unable to find groups for these supplied groupIds:", unrecognisedGroupIds);
      }
    }
  }
  expandFilters(expand, colIds) {
    const updatedColIds = [];
    const updateGroupExpandState = (filterComp) => {
      if (filterComp instanceof ToolPanelFilterGroupComp) {
        let anyChildrenChanged = false;
        filterComp.getChildren().forEach((child) => {
          const childUpdated = updateGroupExpandState(child);
          if (childUpdated) {
            if (expand) {
              filterComp.expand();
              anyChildrenChanged = true;
            } else if (!filterComp.isColumnGroup()) {
              filterComp.collapse();
            }
          }
        });
        return anyChildrenChanged;
      }
      const colId = filterComp.getColumn().getColId();
      const updateFilterExpandState = !colIds || _includes11(colIds, colId);
      if (updateFilterExpandState) {
        expand ? filterComp.expand() : filterComp.collapse();
        updatedColIds.push(colId);
      }
      return updateFilterExpandState;
    };
    this.filterGroupComps.forEach(updateGroupExpandState);
    this.onGroupExpanded();
    if (colIds) {
      const unrecognisedColIds = colIds.filter((colId) => updatedColIds.indexOf(colId) < 0);
      if (unrecognisedColIds.length > 0) {
        _warnOnce28("unable to find columns for these supplied colIds:" + unrecognisedColIds);
      }
    }
  }
  onGroupExpanded() {
    this.fireExpandedEvent();
  }
  onFilterExpanded() {
    this.dispatchLocalEvent({ type: "filterExpanded" });
  }
  fireExpandedEvent() {
    let expandedCount = 0;
    let notExpandedCount = 0;
    const updateExpandCounts = (filterGroup) => {
      if (!filterGroup.isColumnGroup()) {
        return;
      }
      filterGroup.isExpanded() ? expandedCount++ : notExpandedCount++;
      filterGroup.getChildren().forEach((child) => {
        if (child instanceof ToolPanelFilterGroupComp) {
          updateExpandCounts(child);
        }
      });
    };
    this.filterGroupComps.forEach(updateExpandCounts);
    let state;
    if (expandedCount > 0 && notExpandedCount > 0) {
      state = 2;
    } else if (notExpandedCount > 0) {
      state = 1;
    } else {
      state = 0;
    }
    this.dispatchLocalEvent({ type: "groupExpanded", state });
  }
  performFilterSearch(searchText) {
    this.searchFilterText = _exists13(searchText) ? searchText.toLowerCase() : null;
    this.searchFilters(this.searchFilterText);
  }
  searchFilters(searchFilter) {
    const passesFilter = (groupName) => {
      return !_exists13(searchFilter) || groupName.toLowerCase().indexOf(searchFilter) !== -1;
    };
    const recursivelySearch = (filterItem, parentPasses) => {
      if (!(filterItem instanceof ToolPanelFilterGroupComp)) {
        return passesFilter(filterItem.getColumnFilterName() || "");
      }
      const children = filterItem.getChildren();
      const groupNamePasses = passesFilter(filterItem.getFilterGroupName());
      const alreadyPassed = parentPasses || groupNamePasses;
      if (alreadyPassed) {
        filterItem.hideGroup(false);
        for (let i = 0; i < children.length; i++) {
          recursivelySearch(children[i], alreadyPassed);
          filterItem.hideGroupItem(false, i);
        }
        return true;
      }
      let anyChildPasses = false;
      children.forEach((child, index) => {
        const childPasses = recursivelySearch(child, parentPasses);
        filterItem.hideGroupItem(!childPasses, index);
        if (childPasses) {
          anyChildPasses = true;
        }
      });
      filterItem.hideGroup(!anyChildPasses);
      return anyChildPasses;
    };
    let firstVisible;
    let lastVisible;
    this.filterGroupComps.forEach((filterGroup, idx) => {
      recursivelySearch(filterGroup, false);
      if (firstVisible === void 0) {
        if (!filterGroup.containsCssClass("ag-hidden")) {
          firstVisible = idx;
          lastVisible = idx;
        }
      } else if (!filterGroup.containsCssClass("ag-hidden") && lastVisible !== idx) {
        lastVisible = idx;
      }
    });
    this.setFirstAndLastVisible(firstVisible, lastVisible);
    this.refreshAriaLabel();
  }
  setFirstAndLastVisible(firstIdx, lastIdx) {
    this.filterGroupComps.forEach((filterGroup, idx) => {
      filterGroup.removeCssClass("ag-first-group-visible");
      filterGroup.removeCssClass("ag-last-group-visible");
      if (idx === firstIdx) {
        filterGroup.addCssClass("ag-first-group-visible");
      }
      if (idx === lastIdx) {
        filterGroup.addCssClass("ag-last-group-visible");
      }
    });
  }
  refreshFilters(isDisplayed) {
    this.filterGroupComps.forEach((filterGroupComp) => filterGroupComp.refreshFilters(isDisplayed));
  }
  getExpandedFiltersAndGroups() {
    const expandedGroupIds = [];
    const expandedColIds = /* @__PURE__ */ new Set();
    const getExpandedFiltersAndGroups = (filterComp) => {
      if (filterComp instanceof ToolPanelFilterGroupComp) {
        filterComp.getChildren().forEach((child) => getExpandedFiltersAndGroups(child));
        const groupId = filterComp.getFilterGroupId();
        if (filterComp.isExpanded() && !expandedColIds.has(groupId)) {
          expandedGroupIds.push(groupId);
        }
      } else {
        if (filterComp.isExpanded()) {
          expandedColIds.add(filterComp.getColumn().getColId());
        }
      }
    };
    this.filterGroupComps.forEach(getExpandedFiltersAndGroups);
    return { expandedGroupIds, expandedColIds: Array.from(expandedColIds) };
  }
  destroyFilters() {
    this.filterGroupComps = this.destroyBeans(this.filterGroupComps);
    _clearElement33(this.getGui());
  }
  destroy() {
    this.destroyFilters();
    super.destroy();
  }
};
var AgFiltersToolPanelListSelector = {
  selector: "AG-FILTERS-TOOL-PANEL-LIST",
  component: AgFiltersToolPanelList
};
var FiltersToolPanel = class extends Component55 {
  constructor() {
    super(
      /* html */
      `<div class="ag-filter-toolpanel">
            <ag-filters-tool-panel-header data-ref="filtersToolPanelHeaderPanel"></ag-filters-tool-panel-header>
            <ag-filters-tool-panel-list data-ref="filtersToolPanelListPanel"></ag-filters-tool-panel-list>
         </div>`,
      [AgFiltersToolPanelHeaderSelector, AgFiltersToolPanelListSelector]
    );
    this.filtersToolPanelHeaderPanel = RefPlaceholder45;
    this.filtersToolPanelListPanel = RefPlaceholder45;
    this.initialised = false;
    this.listenerDestroyFuncs = [];
  }
  init(params) {
    if (this.initialised) {
      this.listenerDestroyFuncs.forEach((func) => func());
      this.listenerDestroyFuncs = [];
    }
    this.initialised = true;
    const defaultParams = this.gos.addGridCommonParams({
      suppressExpandAll: false,
      suppressFilterSearch: false,
      suppressSyncLayoutWithGrid: false
    });
    this.params = {
      ...defaultParams,
      ...params
    };
    this.filtersToolPanelHeaderPanel.init(this.params);
    this.filtersToolPanelListPanel.init(this.params);
    const hideExpand = this.params.suppressExpandAll;
    const hideSearch = this.params.suppressFilterSearch;
    if (hideExpand && hideSearch) {
      this.filtersToolPanelHeaderPanel.setDisplayed(false);
    }
    this.listenerDestroyFuncs.push(
      ...this.addManagedListeners(this.filtersToolPanelHeaderPanel, {
        expandAll: this.onExpandAll.bind(this),
        collapseAll: this.onCollapseAll.bind(this),
        searchChanged: this.onSearchChanged.bind(this)
      }),
      ...this.addManagedListeners(this.filtersToolPanelListPanel, {
        filterExpanded: this.onFilterExpanded.bind(this),
        groupExpanded: this.onGroupExpanded.bind(this)
      })
    );
  }
  // lazy initialise the panel
  setVisible(visible) {
    super.setDisplayed(visible);
    if (visible && !this.initialised) {
      this.init(this.params);
    }
  }
  onExpandAll() {
    this.filtersToolPanelListPanel.expandFilterGroups(true);
  }
  onCollapseAll() {
    this.filtersToolPanelListPanel.expandFilterGroups(false);
  }
  onSearchChanged(event) {
    this.filtersToolPanelListPanel.performFilterSearch(event.searchText);
  }
  setFilterLayout(colDefs) {
    this.filtersToolPanelListPanel.setFiltersLayout(colDefs);
  }
  onFilterExpanded() {
    this.params.onStateUpdated();
  }
  onGroupExpanded(event) {
    this.filtersToolPanelHeaderPanel.setExpandState(event.state);
    this.params.onStateUpdated();
  }
  expandFilterGroups(groupIds) {
    this.filtersToolPanelListPanel.expandFilterGroups(true, groupIds);
  }
  collapseFilterGroups(groupIds) {
    this.filtersToolPanelListPanel.expandFilterGroups(false, groupIds);
  }
  expandFilters(colIds) {
    this.filtersToolPanelListPanel.expandFilters(true, colIds);
  }
  collapseFilters(colIds) {
    this.filtersToolPanelListPanel.expandFilters(false, colIds);
  }
  syncLayoutWithGrid() {
    this.filtersToolPanelListPanel.syncFilterLayout();
  }
  refresh(params) {
    this.init(params);
    return true;
  }
  getState() {
    return this.filtersToolPanelListPanel.getExpandedFiltersAndGroups();
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};
var VERSION10 = "32.3.0";
var FiltersToolPanelModule = _defineModule10({
  version: VERSION10,
  moduleName: ModuleNames11.FiltersToolPanelModule,
  beans: [],
  userComponents: [{ name: "agFiltersToolPanel", classImp: FiltersToolPanel }],
  dependantModules: [SideBarModule, EnterpriseCoreModule, _ColumnFilterModule2]
});

// enterprise-modules/master-detail/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames12, _defineModule as _defineModule11 } from "ag-grid-community";
import { Component as Component51, ModuleRegistry, RefPlaceholder as RefPlaceholder30, _missing as _missing23, _warnOnce as _warnOnce29, createGrid } from "ag-grid-community";
import { BeanStub as BeanStub21, _missing as _missing4, _warnOnce as _warnOnce30 } from "ag-grid-community";
import { BeanStub as BeanStub27, _exists as _exists14, _iterateObject as _iterateObject5 } from "ag-grid-community";
var DetailCellRendererCtrl = class extends BeanStub21 {
  constructor() {
    super(...arguments);
    this.loadRowDataVersion = 0;
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.environment = beans.environment;
  }
  init(comp, params) {
    this.params = params;
    this.comp = comp;
    const doNothingBecauseInsidePinnedSection = params.pinned != null;
    if (doNothingBecauseInsidePinnedSection) {
      return;
    }
    this.setAutoHeightClasses();
    this.setupRefreshStrategy();
    this.addThemeToDetailGrid();
    this.createDetailGrid();
    this.loadRowData();
    this.addManagedEventListeners({ fullWidthRowFocused: this.onFullWidthRowFocused.bind(this) });
  }
  onFullWidthRowFocused(e) {
    const params = this.params;
    const row = { rowIndex: params.node.rowIndex, rowPinned: params.node.rowPinned };
    const eventRow = { rowIndex: e.rowIndex, rowPinned: e.rowPinned };
    const isSameRow = this.rowPositionUtils.sameRow(row, eventRow);
    if (!isSameRow) {
      return;
    }
    this.focusService.focusInto(this.comp.getGui(), e.fromBelow);
  }
  setAutoHeightClasses() {
    const autoHeight = this.gos.get("detailRowAutoHeight");
    const parentClass = autoHeight ? "ag-details-row-auto-height" : "ag-details-row-fixed-height";
    const detailClass = autoHeight ? "ag-details-grid-auto-height" : "ag-details-grid-fixed-height";
    this.comp.addOrRemoveCssClass(parentClass, true);
    this.comp.addOrRemoveDetailGridCssClass(detailClass, true);
  }
  setupRefreshStrategy() {
    const providedStrategy = this.params.refreshStrategy;
    const validSelection = providedStrategy == "everything" || providedStrategy == "nothing" || providedStrategy == "rows";
    if (validSelection) {
      this.refreshStrategy = providedStrategy;
      return;
    }
    if (providedStrategy != null) {
      _warnOnce30(
        "invalid cellRendererParams.refreshStrategy = '" + providedStrategy + "' supplied, defaulting to refreshStrategy = 'rows'."
      );
    }
    this.refreshStrategy = "rows";
  }
  addThemeToDetailGrid() {
    for (const themeClass of this.environment.getThemeClasses()) {
      this.comp.addOrRemoveDetailGridCssClass(themeClass, true);
    }
  }
  createDetailGrid() {
    if (_missing4(this.params.detailGridOptions)) {
      _warnOnce30(
        "could not find detail grid options for master detail, please set gridOptions.detailCellRendererParams.detailGridOptions"
      );
      return;
    }
    const gridOptions = { ...this.params.detailGridOptions };
    const autoHeight = this.gos.get("detailRowAutoHeight");
    if (autoHeight) {
      gridOptions.domLayout = "autoHeight";
    }
    gridOptions.theme || (gridOptions.theme = this.gos.get("theme"));
    this.comp.setDetailGrid(gridOptions);
  }
  registerDetailWithMaster(api) {
    const rowId = this.params.node.id;
    const masterGridApi = this.params.api;
    const gridInfo = {
      id: rowId,
      api
    };
    const rowNode = this.params.node;
    if (masterGridApi.isDestroyed()) {
      return;
    }
    masterGridApi.addDetailGridInfo(rowId, gridInfo);
    rowNode.detailGridInfo = gridInfo;
    this.addDestroyFunc(() => {
      if (rowNode.detailGridInfo !== gridInfo) {
        return;
      }
      if (!masterGridApi.isDestroyed()) {
        masterGridApi.removeDetailGridInfo(rowId);
      }
      rowNode.detailGridInfo = null;
    });
  }
  loadRowData() {
    this.loadRowDataVersion++;
    const versionThisCall = this.loadRowDataVersion;
    if (this.params.detailGridOptions?.rowModelType === "serverSide") {
      const node = this.params.node;
      node.detailGridInfo?.api?.refreshServerSide({ purge: true });
      return;
    }
    const userFunc = this.params.getDetailRowData;
    if (!userFunc) {
      _warnOnce30(
        "could not find getDetailRowData for master / detail, please set gridOptions.detailCellRendererParams.getDetailRowData"
      );
      return;
    }
    const successCallback = (rowData) => {
      const mostRecentCall = this.loadRowDataVersion === versionThisCall;
      if (mostRecentCall) {
        this.comp.setRowData(rowData);
      }
    };
    const funcParams = {
      node: this.params.node,
      // we take data from node, rather than params.data
      // as the data could have been updated with new instance
      data: this.params.node.data,
      successCallback,
      context: this.gos.getGridCommonParams().context
    };
    userFunc(funcParams);
  }
  refresh() {
    const GET_GRID_TO_REFRESH = false;
    const GET_GRID_TO_DO_NOTHING = true;
    switch (this.refreshStrategy) {
      case "nothing":
        return GET_GRID_TO_DO_NOTHING;
      case "everything":
        return GET_GRID_TO_REFRESH;
    }
    this.loadRowData();
    return GET_GRID_TO_DO_NOTHING;
  }
};
var DetailFrameworkComponentWrapper = class {
  constructor(parentWrapper) {
    this.parentWrapper = parentWrapper;
  }
  wrap(frameworkComponent, methodList, optionalMethodList, componentType) {
    return this.parentWrapper.wrap(frameworkComponent, methodList, optionalMethodList, componentType);
  }
};
var DetailCellRenderer = class extends Component51 {
  constructor() {
    super(...arguments);
    this.eDetailGrid = RefPlaceholder30;
  }
  wireBeans(beans) {
    this.context = beans.context;
  }
  init(params) {
    this.params = params;
    this.selectAndSetTemplate();
    const compProxy = {
      addOrRemoveCssClass: (cssClassName, on) => this.addOrRemoveCssClass(cssClassName, on),
      addOrRemoveDetailGridCssClass: (cssClassName, on) => this.eDetailGrid.classList.toggle(cssClassName, on),
      setDetailGrid: (gridOptions) => this.setDetailGrid(gridOptions),
      setRowData: (rowData) => this.setRowData(rowData),
      getGui: () => this.eDetailGrid
    };
    this.ctrl = this.createManagedBean(new DetailCellRendererCtrl());
    this.ctrl.init(compProxy, params);
  }
  refresh() {
    return this.ctrl && this.ctrl.refresh();
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  selectAndSetTemplate() {
    if (this.params.pinned) {
      this.setTemplate(
        /* html*/
        `<div class="ag-details-row"></div>`
      );
      return;
    }
    const setDefaultTemplate = () => {
      this.setTemplate(
        /* html */
        `<div class="ag-details-row" role="gridcell">
                <div data-ref="eDetailGrid" class="ag-details-grid" role="presentation"></div>
            </div>`
      );
    };
    if (_missing23(this.params.template)) {
      setDefaultTemplate();
    } else {
      if (typeof this.params.template === "string") {
        this.setTemplate(this.params.template, []);
      } else if (typeof this.params.template === "function") {
        const templateFunc = this.params.template;
        const template = templateFunc(this.params);
        this.setTemplate(template, []);
      } else {
        _warnOnce29("detailCellRendererParams.template should be function or string");
        setDefaultTemplate();
      }
    }
    if (this.eDetailGrid == null) {
      _warnOnce29(
        'Reference to eDetailGrid was missing from the details template. Please add data-ref="eDetailGrid" to the template.'
      );
    }
  }
  setDetailGrid(gridOptions) {
    if (!this.eDetailGrid) {
      return;
    }
    const parentFrameworkComponentWrapper = this.context.getBean("frameworkComponentWrapper");
    const frameworkComponentWrapper = new DetailFrameworkComponentWrapper(parentFrameworkComponentWrapper);
    const frameworkOverrides = this.getFrameworkOverrides();
    const api = createGrid(this.eDetailGrid, gridOptions, {
      frameworkOverrides,
      providedBeanInstances: {
        frameworkComponentWrapper
      },
      modules: ModuleRegistry.__getGridRegisteredModules(this.params.api.getGridId())
    });
    this.detailApi = api;
    this.ctrl.registerDetailWithMaster(api);
    this.addDestroyFunc(() => {
      api?.destroy();
    });
  }
  setRowData(rowData) {
    this.detailApi && this.detailApi.setGridOption("rowData", rowData);
  }
};
var DetailGridApiService = class extends BeanStub27 {
  constructor() {
    super(...arguments);
    this.beanName = "detailGridApiService";
    this.detailGridInfoMap = {};
  }
  addDetailGridInfo(id, gridInfo) {
    this.detailGridInfoMap[id] = gridInfo;
  }
  removeDetailGridInfo(id) {
    delete this.detailGridInfoMap[id];
  }
  getDetailGridInfo(id) {
    return this.detailGridInfoMap[id];
  }
  forEachDetailGridInfo(callback) {
    let index = 0;
    _iterateObject5(this.detailGridInfoMap, (id, gridInfo) => {
      if (_exists14(gridInfo)) {
        callback(gridInfo, index);
        index++;
      }
    });
  }
  destroy() {
    this.detailGridInfoMap = {};
    super.destroy();
  }
};
function addDetailGridInfo(beans, id, gridInfo) {
  beans.detailGridApiService?.addDetailGridInfo(id, gridInfo);
}
function removeDetailGridInfo(beans, id) {
  beans.detailGridApiService?.removeDetailGridInfo(id);
}
function getDetailGridInfo(beans, id) {
  return beans.detailGridApiService?.getDetailGridInfo(id);
}
function forEachDetailGridInfo(beans, callback) {
  beans.detailGridApiService?.forEachDetailGridInfo(callback);
}
var VERSION11 = "32.3.0";
var MasterDetailCoreModule = _defineModule11({
  version: VERSION11,
  moduleName: `${ModuleNames12.MasterDetailModule}-core`,
  userComponents: [
    {
      name: "agGroupRowRenderer",
      classImp: GroupCellRenderer
    },
    {
      name: "agGroupCellRenderer",
      classImp: GroupCellRenderer
    },
    { name: "agDetailCellRenderer", classImp: DetailCellRenderer }
  ],
  controllers: [
    { name: "detailCellRenderer", classImp: DetailCellRendererCtrl },
    { name: "groupCellRendererCtrl", classImp: GroupCellRendererCtrl }
  ],
  dependantModules: [EnterpriseCoreModule]
});
var MasterDetailApiModule = _defineModule11({
  version: VERSION11,
  moduleName: `${ModuleNames12.MasterDetailModule}-api`,
  beans: [DetailGridApiService],
  apiFunctions: {
    addDetailGridInfo,
    removeDetailGridInfo,
    getDetailGridInfo,
    forEachDetailGridInfo
  },
  dependantModules: [MasterDetailCoreModule]
});
var MasterDetailModule = _defineModule11({
  version: VERSION11,
  moduleName: ModuleNames12.MasterDetailModule,
  dependantModules: [MasterDetailCoreModule, MasterDetailApiModule]
});

// enterprise-modules/menu/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames52, _CommunityMenuApiModule, _defineModule as _defineModule12 } from "ag-grid-community";
import { BeanStub as BeanStub28, ModuleNames as ModuleNames13, _createIconNoSpan as _createIconNoSpan15, _warnOnce as _warnOnce31 } from "ag-grid-community";
import { BeanStub as BeanStub29 } from "ag-grid-community";
import { BeanStub as BeanStub35, _isClientSideRowModel as _isClientSideRowModel4, _removeRepeatsFromArray } from "ag-grid-community";
import {
  BeanStub as BeanStub45,
  Component as Component56,
  ModuleNames as ModuleNames24,
  _exists as _exists15,
  _isIOSUserAgent,
  _isNothingFocused as _isNothingFocused4,
  _missingOrEmpty as _missingOrEmpty4
} from "ag-grid-community";
import {
  AgPromise as AgPromise5,
  BeanStub as BeanStub55,
  Component as Component213,
  FilterWrapperComp as FilterWrapperComp3,
  ModuleNames as ModuleNames32,
  RefPlaceholder as RefPlaceholder31,
  _createIconNoSpan as _createIconNoSpan27,
  _warnOnce as _warnOnce210,
  isColumn as isColumn2
} from "ag-grid-community";
import { BeanStub as BeanStub64, ModuleNames as ModuleNames42, _createIconNoSpan as _createIconNoSpan36, _escapeString as _escapeString8, _exists as _exists25, _warnOnce as _warnOnce35 } from "ag-grid-community";
import { BeanStub as BeanStub74, _getActiveDomElement as _getActiveDomElement11, _isNothingFocused as _isNothingFocused22, _isVisible as _isVisible4, _last as _last11 } from "ag-grid-community";
var ChartMenuItemMapper = class _ChartMenuItemMapper extends BeanStub28 {
  constructor() {
    super(...arguments);
    this.beanName = "chartMenuItemMapper";
  }
  wireBeans(beans) {
    this.chartService = beans.chartService;
  }
  getChartItems(key) {
    if (!this.chartService) {
      this.gos.assertModuleRegistered(ModuleNames13.GridChartsModule, `the Context Menu key "${key}"`);
      return void 0;
    }
    const builder = key === "pivotChart" ? new PivotMenuItemMapper(this.gos, this.chartService, this.localeService) : new RangeMenuItemMapper(this.gos, this.chartService, this.localeService);
    const isEnterprise = this.chartService.isEnterprise();
    let topLevelMenuItem = builder.getMenuItem();
    if (topLevelMenuItem && topLevelMenuItem.subMenu && !isEnterprise) {
      const filterEnterpriseItems = (m) => ({
        ...m,
        subMenu: m.subMenu?.filter((menu) => !menu._enterprise).map((menu) => filterEnterpriseItems(menu))
      });
      topLevelMenuItem = filterEnterpriseItems(topLevelMenuItem);
    }
    const chartGroupsDef = this.gos.get("chartToolPanelsDef")?.settingsPanel?.chartGroupsDef;
    if (chartGroupsDef) {
      topLevelMenuItem = _ChartMenuItemMapper.filterAndOrderChartMenu(
        topLevelMenuItem,
        chartGroupsDef,
        builder.getConfigLookup()
      );
    }
    return this.cleanInternals(topLevelMenuItem);
  }
  // Remove our internal _key and _enterprise properties so this does not leak out of the class on the menu items.
  cleanInternals(menuItem) {
    if (!menuItem) {
      return menuItem;
    }
    const removeKeys = (m) => {
      delete m?._key;
      delete m?._enterprise;
      m?.subMenu?.forEach((s) => removeKeys(s));
      return m;
    };
    return removeKeys(menuItem);
  }
  static buildLookup(menuItem) {
    const itemLookup = {};
    const addItem = (item) => {
      itemLookup[item._key] = item;
      if (item.subMenu) {
        item.subMenu.forEach((s) => addItem(s));
      }
    };
    addItem(menuItem);
    return itemLookup;
  }
  /**
   * Make the MenuItem match the charts provided and their ordering on the ChartGroupsDef config object as provided by the user.
   */
  static filterAndOrderChartMenu(topLevelMenuItem, chartGroupsDef, configLookup) {
    const menuItemLookup = this.buildLookup(topLevelMenuItem);
    const orderedAndFiltered = { ...topLevelMenuItem, subMenu: [] };
    Object.entries(chartGroupsDef).forEach(([group, chartTypes]) => {
      const chartConfigGroup = configLookup[group];
      if (chartConfigGroup === null)
        return;
      if (chartConfigGroup == void 0) {
        _warnOnce31(`invalid chartGroupsDef config '${group}'`);
        return void 0;
      }
      const menuItem = menuItemLookup[chartConfigGroup._key];
      if (menuItem) {
        if (menuItem.subMenu) {
          const subMenus = chartTypes.map((chartType) => {
            const itemKey = chartConfigGroup[chartType];
            if (itemKey == void 0) {
              _warnOnce31(`invalid chartGroupsDef config '${group}.${chartType}'`);
              return void 0;
            }
            return menuItemLookup[itemKey];
          }).filter((s) => s !== void 0);
          if (subMenus.length > 0) {
            menuItem.subMenu = subMenus;
            orderedAndFiltered.subMenu?.push(menuItem);
          }
        } else {
          orderedAndFiltered.subMenu?.push(menuItem);
        }
      }
    });
    if (orderedAndFiltered.subMenu?.length == 0) {
      return void 0;
    }
    return orderedAndFiltered;
  }
};
var PivotMenuItemMapper = class {
  constructor(gos, chartService, localeService) {
    this.gos = gos;
    this.chartService = chartService;
    this.localeService = localeService;
  }
  getMenuItem() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const getMenuItem = (localeKey, defaultText, chartType, key, enterprise = false) => {
      return {
        name: localeTextFunc(localeKey, defaultText),
        action: () => this.chartService.createPivotChart({ chartType }),
        _key: key,
        _enterprise: enterprise
      };
    };
    return {
      name: localeTextFunc("pivotChart", "Pivot Chart"),
      _key: "pivotChart",
      subMenu: [
        {
          _key: "pivotColumnChart",
          name: localeTextFunc("columnChart", "Column"),
          subMenu: [
            getMenuItem("groupedColumn", "Grouped&lrm;", "groupedColumn", "pivotGroupedColumn"),
            getMenuItem("stackedColumn", "Stacked&lrm;", "stackedColumn", "pivotStackedColumn"),
            getMenuItem(
              "normalizedColumn",
              "100% Stacked&lrm;",
              "normalizedColumn",
              "pivotNormalizedColumn"
            )
          ]
        },
        {
          _key: "pivotBarChart",
          name: localeTextFunc("barChart", "Bar"),
          subMenu: [
            getMenuItem("groupedBar", "Grouped&lrm;", "groupedBar", "pivotGroupedBar"),
            getMenuItem("stackedBar", "Stacked&lrm;", "stackedBar", "pivotStackedBar"),
            getMenuItem("normalizedBar", "100% Stacked&lrm;", "normalizedBar", "pivotNormalizedBar")
          ]
        },
        {
          _key: "pivotPieChart",
          name: localeTextFunc("pieChart", "Pie"),
          subMenu: [
            getMenuItem("pie", "Pie&lrm;", "pie", "pivotPie"),
            getMenuItem("donut", "Donut&lrm;", "donut", "pivotDonut")
          ]
        },
        getMenuItem("line", "Line&lrm;", "line", "pivotLineChart"),
        {
          _key: "pivotXYChart",
          name: localeTextFunc("xyChart", "X Y (Scatter)"),
          subMenu: [
            getMenuItem("scatter", "Scatter&lrm;", "scatter", "pivotScatter"),
            getMenuItem("bubble", "Bubble&lrm;", "bubble", "pivotBubble")
          ]
        },
        {
          _key: "pivotAreaChart",
          name: localeTextFunc("areaChart", "Area"),
          subMenu: [
            getMenuItem("area", "Area&lrm;", "area", "pivotArea"),
            getMenuItem("stackedArea", "Stacked&lrm;", "stackedArea", "pivotStackedArea"),
            getMenuItem("normalizedArea", "100% Stacked&lrm;", "normalizedArea", "pivotNormalizedArea")
          ]
        },
        {
          _key: "pivotStatisticalChart",
          _enterprise: false,
          // histogram chart is available in both community and enterprise distributions
          name: localeTextFunc("statisticalChart", "Statistical"),
          subMenu: [getMenuItem("histogramChart", "Histogram&lrm;", "histogram", "pivotHistogram", false)]
        },
        {
          _key: "pivotHierarchicalChart",
          _enterprise: true,
          name: localeTextFunc("hierarchicalChart", "Hierarchical"),
          subMenu: [
            getMenuItem("treemapChart", "Treemap&lrm;", "treemap", "pivotTreemap", true),
            getMenuItem("sunburstChart", "Sunburst&lrm;", "sunburst", "pivotSunburst", true)
          ]
        },
        {
          _key: "pivotCombinationChart",
          name: localeTextFunc("combinationChart", "Combination"),
          subMenu: [
            getMenuItem("columnLineCombo", "Column & Line&lrm;", "columnLineCombo", "pivotColumnLineCombo"),
            getMenuItem("AreaColumnCombo", "Area & Column&lrm;", "areaColumnCombo", "pivotAreaColumnCombo")
          ]
        }
      ],
      icon: _createIconNoSpan15("chart", this.gos, void 0)
    };
  }
  getConfigLookup() {
    return {
      columnGroup: {
        _key: "pivotColumnChart",
        column: "pivotGroupedColumn",
        stackedColumn: "pivotStackedColumn",
        normalizedColumn: "pivotNormalizedColumn"
      },
      barGroup: {
        _key: "pivotBarChart",
        bar: "pivotGroupedBar",
        stackedBar: "pivotStackedBar",
        normalizedBar: "pivotNormalizedBar"
      },
      pieGroup: {
        _key: "pivotPieChart",
        pie: "pivotPie",
        donut: "pivotDonut",
        doughnut: "pivotDonut"
      },
      lineGroup: {
        _key: "pivotLineChart",
        line: "pivotLineChart"
      },
      scatterGroup: {
        _key: "pivotXYChart",
        bubble: "pivotBubble",
        scatter: "pivotScatter"
      },
      areaGroup: {
        _key: "pivotAreaChart",
        area: "pivotArea",
        stackedArea: "pivotStackedArea",
        normalizedArea: "pivotNormalizedArea"
      },
      combinationGroup: {
        _key: "pivotCombinationChart",
        columnLineCombo: "pivotColumnLineCombo",
        areaColumnCombo: "pivotAreaColumnCombo",
        customCombo: null
        // Not currently supported
      },
      hierarchicalGroup: {
        _key: "pivotHierarchicalChart",
        treemap: "pivotTreemap",
        sunburst: "pivotSunburst"
      },
      statisticalGroup: {
        _key: "pivotStatisticalChart",
        histogram: "pivotHistogram",
        // Some statistical charts do not currently support pivot mode
        rangeBar: null,
        rangeArea: null,
        boxPlot: null
      },
      // Polar charts do not support pivot mode
      polarGroup: null,
      // Specialized charts do not currently support pivot mode
      specializedGroup: null
    };
  }
};
var RangeMenuItemMapper = class {
  constructor(gos, chartService, localeService) {
    this.gos = gos;
    this.chartService = chartService;
    this.localeService = localeService;
  }
  getMenuItem() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const getMenuItem = (localeKey, defaultText, chartType, key, enterprise = false) => {
      return {
        name: localeTextFunc(localeKey, defaultText),
        action: () => this.chartService.createChartFromCurrentRange(chartType),
        _key: key,
        _enterprise: enterprise
      };
    };
    return {
      name: localeTextFunc("chartRange", "Chart Range"),
      _key: "chartRange",
      subMenu: [
        {
          name: localeTextFunc("columnChart", "Column"),
          subMenu: [
            getMenuItem("groupedColumn", "Grouped&lrm;", "groupedColumn", "rangeGroupedColumn"),
            getMenuItem("stackedColumn", "Stacked&lrm;", "stackedColumn", "rangeStackedColumn"),
            getMenuItem(
              "normalizedColumn",
              "100% Stacked&lrm;",
              "normalizedColumn",
              "rangeNormalizedColumn"
            )
          ],
          _key: "rangeColumnChart"
        },
        {
          name: localeTextFunc("barChart", "Bar"),
          subMenu: [
            getMenuItem("groupedBar", "Grouped&lrm;", "groupedBar", "rangeGroupedBar"),
            getMenuItem("stackedBar", "Stacked&lrm;", "stackedBar", "rangeStackedBar"),
            getMenuItem("normalizedBar", "100% Stacked&lrm;", "normalizedBar", "rangeNormalizedBar")
          ],
          _key: "rangeBarChart"
        },
        {
          name: localeTextFunc("pieChart", "Pie"),
          subMenu: [
            getMenuItem("pie", "Pie&lrm;", "pie", "rangePie"),
            getMenuItem("donut", "Donut&lrm;", "donut", "rangeDonut")
          ],
          _key: "rangePieChart"
        },
        getMenuItem("line", "Line&lrm;", "line", "rangeLineChart"),
        {
          name: localeTextFunc("xyChart", "X Y (Scatter)"),
          subMenu: [
            getMenuItem("scatter", "Scatter&lrm;", "scatter", "rangeScatter"),
            getMenuItem("bubble", "Bubble&lrm;", "bubble", "rangeBubble")
          ],
          _key: "rangeXYChart"
        },
        {
          name: localeTextFunc("areaChart", "Area"),
          subMenu: [
            getMenuItem("area", "Area&lrm;", "area", "rangeArea"),
            getMenuItem("stackedArea", "Stacked&lrm;", "stackedArea", "rangeStackedArea"),
            getMenuItem("normalizedArea", "100% Stacked&lrm;", "normalizedArea", "rangeNormalizedArea")
          ],
          _key: "rangeAreaChart"
        },
        {
          name: localeTextFunc("polarChart", "Polar"),
          subMenu: [
            getMenuItem("radarLine", "Radar Line&lrm;", "radarLine", "rangeRadarLine"),
            getMenuItem("radarArea", "Radar Area&lrm;", "radarArea", "rangeRadarArea"),
            getMenuItem("nightingale", "Nightingale&lrm;", "nightingale", "rangeNightingale"),
            getMenuItem("radialColumn", "Radial Column&lrm;", "radialColumn", "rangeRadialColumn"),
            getMenuItem("radialBar", "Radial Bar&lrm;", "radialBar", "rangeRadialBar")
          ],
          _key: "rangePolarChart",
          _enterprise: true
        },
        {
          name: localeTextFunc("statisticalChart", "Statistical"),
          subMenu: [
            getMenuItem("boxPlot", "Box Plot&lrm;", "boxPlot", "rangeBoxPlot", true),
            getMenuItem("histogramChart", "Histogram&lrm;", "histogram", "rangeHistogram", false),
            getMenuItem("rangeBar", "Range Bar&lrm;", "rangeBar", "rangeRangeBar", true),
            getMenuItem("rangeArea", "Range Area&lrm;", "rangeArea", "rangeRangeArea", true)
          ],
          _key: "rangeStatisticalChart",
          _enterprise: false
          // histogram chart is available in both community and enterprise distributions
        },
        {
          name: localeTextFunc("hierarchicalChart", "Hierarchical"),
          subMenu: [
            getMenuItem("treemap", "Treemap&lrm;", "treemap", "rangeTreemap"),
            getMenuItem("sunburst", "Sunburst&lrm;", "sunburst", "rangeSunburst")
          ],
          _key: "rangeHierarchicalChart",
          _enterprise: true
        },
        {
          name: localeTextFunc("specializedChart", "Specialized"),
          subMenu: [
            getMenuItem("heatmap", "Heatmap&lrm;", "heatmap", "rangeHeatmap"),
            getMenuItem("waterfall", "Waterfall&lrm;", "waterfall", "rangeWaterfall")
          ],
          _key: "rangeSpecializedChart",
          _enterprise: true
        },
        {
          name: localeTextFunc("combinationChart", "Combination"),
          subMenu: [
            getMenuItem("columnLineCombo", "Column & Line&lrm;", "columnLineCombo", "rangeColumnLineCombo"),
            getMenuItem("AreaColumnCombo", "Area & Column&lrm;", "areaColumnCombo", "rangeAreaColumnCombo")
          ],
          _key: "rangeCombinationChart"
        }
      ],
      icon: _createIconNoSpan15("chart", this.gos, void 0)
    };
  }
  getConfigLookup() {
    return {
      columnGroup: {
        _key: "rangeColumnChart",
        column: "rangeGroupedColumn",
        stackedColumn: "rangeStackedColumn",
        normalizedColumn: "rangeNormalizedColumn"
      },
      barGroup: {
        _key: "rangeBarChart",
        bar: "rangeGroupedBar",
        stackedBar: "rangeStackedBar",
        normalizedBar: "rangeNormalizedBar"
      },
      pieGroup: {
        _key: "rangePieChart",
        pie: "rangePie",
        donut: "rangeDonut",
        doughnut: "rangeDonut"
      },
      lineGroup: {
        _key: "rangeLineChart",
        line: "rangeLineChart"
      },
      scatterGroup: {
        _key: "rangeXYChart",
        bubble: "rangeBubble",
        scatter: "rangeScatter"
      },
      areaGroup: {
        _key: "rangeAreaChart",
        area: "rangeArea",
        stackedArea: "rangeStackedArea",
        normalizedArea: "rangeNormalizedArea"
      },
      polarGroup: {
        _key: "rangePolarChart",
        radarLine: "rangeRadarLine",
        radarArea: "rangeRadarArea",
        nightingale: "rangeNightingale",
        radialColumn: "rangeRadialColumn",
        radialBar: "rangeRadialBar"
      },
      statisticalGroup: {
        _key: "rangeStatisticalChart",
        boxPlot: "rangeBoxPlot",
        histogram: "rangeHistogram",
        rangeBar: "rangeRangeBar",
        rangeArea: "rangeRangeArea"
      },
      hierarchicalGroup: {
        _key: "rangeHierarchicalChart",
        treemap: "rangeTreemap",
        sunburst: "rangeSunburst"
      },
      specializedGroup: {
        _key: "rangeSpecializedChart",
        heatmap: "rangeHeatmap",
        waterfall: "rangeWaterfall"
      },
      combinationGroup: {
        _key: "rangeCombinationChart",
        columnLineCombo: "rangeColumnLineCombo",
        areaColumnCombo: "rangeAreaColumnCombo",
        customCombo: null
        // Not currently supported
      }
    };
  }
};
var ColumnChooserFactory = class extends BeanStub29 {
  constructor() {
    super(...arguments);
    this.beanName = "columnChooserFactory";
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.menuUtils = beans.menuUtils;
    this.visibleColsService = beans.visibleColsService;
  }
  createColumnSelectPanel(parent, column, draggable, params) {
    const columnSelectPanel = parent.createManagedBean(new AgPrimaryCols());
    const columnChooserParams = params ?? column?.getColDef().columnChooserParams ?? column?.getColDef().columnsMenuParams ?? {};
    const {
      contractColumnSelection,
      suppressColumnExpandAll,
      suppressColumnFilter,
      suppressColumnSelectAll,
      suppressSyncLayoutWithGrid,
      columnLayout
    } = columnChooserParams;
    columnSelectPanel.init(
      !!draggable,
      this.gos.addGridCommonParams({
        suppressColumnMove: false,
        suppressValues: false,
        suppressPivots: false,
        suppressRowGroups: false,
        suppressPivotMode: false,
        contractColumnSelection: !!contractColumnSelection,
        suppressColumnExpandAll: !!suppressColumnExpandAll,
        suppressColumnFilter: !!suppressColumnFilter,
        suppressColumnSelectAll: !!suppressColumnSelectAll,
        suppressSyncLayoutWithGrid: !!columnLayout || !!suppressSyncLayoutWithGrid,
        onStateUpdated: () => {
        }
      }),
      "columnMenu"
    );
    if (columnLayout) {
      columnSelectPanel.setColumnLayout(columnLayout);
    }
    return columnSelectPanel;
  }
  showColumnChooser({ column, chooserParams, eventSource }) {
    this.hideActiveColumnChooser();
    const columnSelectPanel = this.createColumnSelectPanel(this, column, true, chooserParams);
    const translate = this.localeService.getLocaleTextFunc();
    const columnIndex = this.visibleColsService.getAllCols().indexOf(column);
    const headerPosition = column ? this.focusService.getFocusedHeader() : null;
    this.activeColumnChooserDialog = this.createBean(
      new AgDialog({
        title: translate("chooseColumns", "Choose Columns"),
        component: columnSelectPanel,
        width: 300,
        height: 300,
        resizable: true,
        movable: true,
        centered: true,
        closable: true,
        afterGuiAttached: () => {
          this.focusService.findNextFocusableElement(columnSelectPanel.getGui())?.focus({
            preventScroll: true
          });
          this.dispatchVisibleChangedEvent(true, column);
        },
        closedCallback: (event) => {
          const eComp = this.activeColumnChooser.getGui();
          this.destroyBean(this.activeColumnChooser);
          this.activeColumnChooser = void 0;
          this.activeColumnChooserDialog = void 0;
          this.dispatchVisibleChangedEvent(false, column);
          if (column) {
            this.menuUtils.restoreFocusOnClose(
              { column, headerPosition, columnIndex, eventSource },
              eComp,
              event,
              true
            );
          }
        },
        postProcessPopupParams: {
          type: "columnChooser",
          column,
          eventSource
        }
      })
    );
    this.activeColumnChooser = columnSelectPanel;
  }
  hideActiveColumnChooser() {
    if (this.activeColumnChooserDialog) {
      this.destroyBean(this.activeColumnChooserDialog);
    }
  }
  dispatchVisibleChangedEvent(visible, column) {
    this.eventService.dispatchEvent({
      type: "columnMenuVisibleChanged",
      visible,
      switchingTab: false,
      key: "columnChooser",
      column: column ?? null
    });
  }
};
var MENU_ITEM_SEPARATOR = "separator";
var ColumnMenuFactory = class extends BeanStub35 {
  constructor() {
    super(...arguments);
    this.beanName = "columnMenuFactory";
  }
  wireBeans(beans) {
    this.menuItemMapper = beans.menuItemMapper;
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
    this.menuService = beans.menuService;
  }
  createMenu(parent, menuItems, column, sourceElement) {
    const menuList = parent.createManagedBean(
      new AgMenuList(0, {
        column: column ?? null,
        node: null,
        value: null
      })
    );
    const menuItemsMapped = this.menuItemMapper.mapWithStockItems(
      menuItems,
      column ?? null,
      sourceElement,
      "columnMenu"
    );
    menuList.addMenuItems(menuItemsMapped);
    return menuList;
  }
  // columnGroup to be added
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMenuItems(column, columnGroup) {
    const defaultItems = this.getDefaultMenuOptions(column);
    let result;
    const columnMainMenuItems = column?.getColDef().mainMenuItems;
    if (Array.isArray(columnMainMenuItems)) {
      result = columnMainMenuItems;
    } else if (typeof columnMainMenuItems === "function") {
      result = columnMainMenuItems(
        this.gos.addGridCommonParams({
          column,
          defaultItems
        })
      );
    } else {
      const userFunc = this.gos.getCallback("getMainMenuItems");
      if (userFunc && column) {
        result = userFunc({
          column,
          defaultItems
        });
      } else {
        result = defaultItems;
      }
    }
    _removeRepeatsFromArray(result, MENU_ITEM_SEPARATOR);
    return result;
  }
  getDefaultMenuOptions(column) {
    const result = [];
    const isLegacyMenuEnabled = this.menuService.isLegacyMenuEnabled();
    if (!column) {
      if (!isLegacyMenuEnabled) {
        result.push("columnChooser");
      }
      result.push("resetColumns");
      return result;
    }
    const allowPinning = !column.getColDef().lockPinned;
    const rowGroupCount = this.funcColsService.getRowGroupColumns().length;
    const doingGrouping = rowGroupCount > 0;
    const allowValue = column.isAllowValue();
    const allowRowGroup = column.isAllowRowGroup();
    const isPrimary = column.isPrimary();
    const pivotModeOn = this.columnModel.isPivotMode();
    const isInMemoryRowModel = _isClientSideRowModel4(this.gos);
    const usingTreeData = this.gos.get("treeData");
    const allowValueAgg = (
      // if primary, then only allow aggValue if grouping and it's a value columns
      isPrimary && doingGrouping && allowValue || // secondary columns can always have aggValue, as it means it's a pivot value column
      !isPrimary
    );
    if (!isLegacyMenuEnabled && column.isSortable()) {
      const sort = column.getSort();
      if (sort !== "asc") {
        result.push("sortAscending");
      }
      if (sort !== "desc") {
        result.push("sortDescending");
      }
      if (sort) {
        result.push("sortUnSort");
      }
      result.push(MENU_ITEM_SEPARATOR);
    }
    if (this.menuService.isFilterMenuItemEnabled(column)) {
      result.push("columnFilter");
      result.push(MENU_ITEM_SEPARATOR);
    }
    if (allowPinning) {
      result.push("pinSubMenu");
    }
    if (allowValueAgg) {
      result.push("valueAggSubMenu");
    }
    if (allowPinning || allowValueAgg) {
      result.push(MENU_ITEM_SEPARATOR);
    }
    result.push("autoSizeThis");
    result.push("autoSizeAll");
    result.push(MENU_ITEM_SEPARATOR);
    const showRowGroup = column.getColDef().showRowGroup;
    if (showRowGroup) {
      result.push("rowUnGroup");
    } else if (allowRowGroup && column.isPrimary()) {
      if (column.isRowGroupActive()) {
        const groupLocked = this.columnModel.isColGroupLocked(column);
        if (!groupLocked) {
          result.push("rowUnGroup");
        }
      } else {
        result.push("rowGroup");
      }
    }
    result.push(MENU_ITEM_SEPARATOR);
    if (!isLegacyMenuEnabled) {
      result.push("columnChooser");
    }
    result.push("resetColumns");
    const allowExpandAndContract = isInMemoryRowModel && (usingTreeData || rowGroupCount > (pivotModeOn ? 1 : 0));
    if (allowExpandAndContract) {
      result.push("expandAll");
      result.push("contractAll");
    }
    return result;
  }
};
var CSS_MENU = "ag-menu";
var CSS_CONTEXT_MENU_OPEN = "ag-context-menu-open";
var ContextMenuFactory = class extends BeanStub45 {
  constructor() {
    super(...arguments);
    this.beanName = "contextMenuFactory";
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.ctrlsService = beans.ctrlsService;
    this.columnModel = beans.columnModel;
    this.menuUtils = beans.menuUtils;
    this.rangeService = beans.rangeService;
    this.focusService = beans.focusService;
  }
  hideActiveMenu() {
    this.destroyBean(this.activeMenu);
  }
  getMenuItems(node, column, value) {
    const defaultMenuOptions = [];
    if (_exists15(node) && this.gos.isModuleRegistered(ModuleNames24.ClipboardModule)) {
      if (column) {
        if (!this.gos.get("suppressCutToClipboard")) {
          defaultMenuOptions.push("cut");
        }
        defaultMenuOptions.push("copy", "copyWithHeaders", "copyWithGroupHeaders", "paste", "separator");
      }
    }
    if (this.gos.get("enableCharts") && this.gos.isModuleRegistered(ModuleNames24.GridChartsModule)) {
      if (this.columnModel.isPivotMode()) {
        defaultMenuOptions.push("pivotChart");
      }
      if (this.rangeService && !this.rangeService.isEmpty()) {
        defaultMenuOptions.push("chartRange");
      }
    }
    if (_exists15(node)) {
      const csvModuleMissing = !this.gos.isModuleRegistered(ModuleNames24.CsvExportModule);
      const excelModuleMissing = !this.gos.isModuleRegistered(ModuleNames24.ExcelExportModule);
      const suppressExcel = this.gos.get("suppressExcelExport") || excelModuleMissing;
      const suppressCsv = this.gos.get("suppressCsvExport") || csvModuleMissing;
      const onIPad = _isIOSUserAgent();
      const anyExport = !onIPad && (!suppressExcel || !suppressCsv);
      if (anyExport) {
        defaultMenuOptions.push("export");
      }
    }
    const defaultItems = defaultMenuOptions.length ? defaultMenuOptions : void 0;
    const columnContextMenuItems = column?.getColDef().contextMenuItems;
    if (Array.isArray(columnContextMenuItems)) {
      return columnContextMenuItems;
    }
    if (typeof columnContextMenuItems === "function") {
      return columnContextMenuItems(
        this.gos.addGridCommonParams({
          column,
          node,
          value,
          defaultItems
        })
      );
    }
    const userFunc = this.gos.getCallback("getContextMenuItems");
    if (userFunc) {
      return userFunc({ column, node, value, defaultItems });
    }
    return defaultMenuOptions;
  }
  onContextMenu(mouseEvent, touchEvent, rowNode, column, value, anchorToElement) {
    this.menuUtils.onContextMenu(
      mouseEvent,
      touchEvent,
      (eventOrTouch) => this.showMenu(rowNode, column, value, eventOrTouch, anchorToElement)
    );
  }
  showMenu(node, column, value, mouseEvent, anchorToElement) {
    const menuItems = this.getMenuItems(node, column, value);
    const eGridBodyGui = this.ctrlsService.getGridBodyCtrl().getGui();
    if (menuItems === void 0 || _missingOrEmpty4(menuItems)) {
      return false;
    }
    const menu = new ContextMenu(menuItems, column, node, value);
    this.createBean(menu);
    const eMenuGui = menu.getGui();
    if (!column) {
      this.focusService.clearFocusedCell();
    }
    const positionParams = {
      column,
      rowNode: node,
      type: "contextMenu",
      mouseEvent,
      ePopup: eMenuGui,
      // move one pixel away so that accidentally double clicking
      // won't show the browser's contextmenu
      nudgeY: 1
    };
    const translate = this.localeService.getLocaleTextFunc();
    const addPopupRes = this.popupService.addPopup({
      modal: true,
      eChild: eMenuGui,
      closeOnEsc: true,
      closedCallback: (e) => {
        eGridBodyGui.classList.remove(CSS_CONTEXT_MENU_OPEN);
        this.destroyBean(menu);
        this.dispatchVisibleChangedEvent(false, e === void 0 ? "api" : "ui");
      },
      click: mouseEvent,
      positionCallback: () => {
        const isRtl = this.gos.get("enableRtl");
        this.popupService.positionPopupUnderMouseEvent({
          ...positionParams,
          nudgeX: isRtl ? (eMenuGui.offsetWidth + 1) * -1 : 1
        });
      },
      // so when browser is scrolled down, or grid is scrolled, context menu stays with cell
      anchorToElement,
      ariaLabel: translate("ariaLabelContextMenu", "Context Menu")
    });
    if (addPopupRes) {
      eGridBodyGui.classList.add(CSS_CONTEXT_MENU_OPEN);
      menu.afterGuiAttached({ container: "contextMenu", hidePopup: addPopupRes.hideFunc });
    }
    if (this.activeMenu) {
      this.hideActiveMenu();
    }
    this.activeMenu = menu;
    menu.addEventListener("destroyed", () => {
      if (this.activeMenu === menu) {
        this.activeMenu = null;
      }
    });
    if (addPopupRes) {
      menu.addEventListener(
        "closeMenu",
        (e) => addPopupRes.hideFunc({
          mouseEvent: e.mouseEvent ?? void 0,
          keyboardEvent: e.keyboardEvent ?? void 0,
          forceHide: true
        })
      );
    }
    const isApi = mouseEvent && mouseEvent instanceof MouseEvent && mouseEvent.type === "mousedown";
    this.dispatchVisibleChangedEvent(true, isApi ? "api" : "ui");
    return true;
  }
  dispatchVisibleChangedEvent(visible, source = "ui") {
    this.eventService.dispatchEvent({
      type: "contextMenuVisibleChanged",
      visible,
      source
    });
  }
};
var ContextMenu = class extends Component56 {
  constructor(menuItems, column, node, value) {
    super(
      /* html */
      `<div class="${CSS_MENU}" role="presentation"></div>`
    );
    this.menuItems = menuItems;
    this.column = column;
    this.node = node;
    this.value = value;
    this.menuList = null;
    this.focusedCell = null;
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.menuItemMapper = beans.menuItemMapper;
    this.cellPositionUtils = beans.cellPositionUtils;
  }
  postConstruct() {
    const menuList = this.createManagedBean(
      new AgMenuList(0, {
        column: this.column,
        node: this.node,
        value: this.value
      })
    );
    const menuItemsMapped = this.menuItemMapper.mapWithStockItems(
      this.menuItems,
      null,
      () => this.getGui(),
      "contextMenu"
    );
    menuList.addMenuItems(menuItemsMapped);
    this.appendChild(menuList);
    this.menuList = menuList;
    menuList.addEventListener("closeMenu", (e) => this.dispatchLocalEvent(e));
  }
  afterGuiAttached(params) {
    if (params.hidePopup) {
      this.addDestroyFunc(params.hidePopup);
    }
    this.focusedCell = this.focusService.getFocusedCell();
    if (this.menuList) {
      this.focusService.focusInto(this.menuList.getGui());
    }
  }
  restoreFocusedCell() {
    const currentFocusedCell = this.focusService.getFocusedCell();
    if (currentFocusedCell && this.focusedCell && this.cellPositionUtils.equals(currentFocusedCell, this.focusedCell)) {
      const { rowIndex, rowPinned, column } = this.focusedCell;
      if (_isNothingFocused4(this.gos)) {
        this.focusService.setFocusedCell({
          rowIndex,
          column,
          rowPinned,
          forceBrowserFocus: true,
          preventScrollOnBrowserFocus: !this.focusService.isKeyboardMode()
        });
      }
    }
  }
  destroy() {
    this.restoreFocusedCell();
    super.destroy();
  }
};
var TAB_FILTER = "filterMenuTab";
var TAB_GENERAL = "generalMenuTab";
var TAB_COLUMNS = "columnsMenuTab";
var TABS_DEFAULT = [TAB_GENERAL, TAB_FILTER, TAB_COLUMNS];
var EnterpriseMenuFactory = class extends BeanStub55 {
  constructor() {
    super(...arguments);
    this.beanName = "enterpriseMenuFactory";
  }
  wireBeans(beans) {
    this.popupService = beans.popupService;
    this.focusService = beans.focusService;
    this.ctrlsService = beans.ctrlsService;
    this.visibleColsService = beans.visibleColsService;
    this.filterManager = beans.filterManager;
    this.menuUtils = beans.menuUtils;
    this.menuService = beans.menuService;
    this.columnMenuFactory = beans.columnMenuFactory;
  }
  hideActiveMenu() {
    this.destroyBean(this.activeMenu);
  }
  showMenuAfterMouseEvent(columnOrGroup, mouseEvent, containerType, filtersOnly) {
    const { column, columnGroup } = this.splitColumnOrGroup(columnOrGroup);
    const defaultTab = filtersOnly ? "filterMenuTab" : void 0;
    this.showMenu(
      column,
      columnGroup,
      (menu) => {
        const ePopup = menu.getGui();
        this.popupService.positionPopupUnderMouseEvent({
          type: containerType,
          column,
          mouseEvent,
          ePopup
        });
        if (defaultTab) {
          menu.showTab?.(defaultTab);
        }
        this.dispatchVisibleChangedEvent(true, false, column, columnGroup, defaultTab);
      },
      containerType,
      defaultTab,
      void 0,
      mouseEvent.target
    );
  }
  splitColumnOrGroup(columnOrGroup) {
    const colIsColumn = columnOrGroup && isColumn2(columnOrGroup);
    const column = colIsColumn ? columnOrGroup : void 0;
    const columnGroup = colIsColumn ? void 0 : columnOrGroup;
    return { column, columnGroup };
  }
  showMenuAfterButtonClick(columnOrGroup, eventSource, containerType, filtersOnly) {
    let multiplier = -1;
    let alignSide = "left";
    if (this.gos.get("enableRtl")) {
      multiplier = 1;
      alignSide = "right";
    }
    const defaultTab = filtersOnly ? "filterMenuTab" : void 0;
    const restrictToTabs = defaultTab ? [defaultTab] : void 0;
    const isLegacyMenuEnabled = this.menuService.isLegacyMenuEnabled();
    const nudgeX = (isLegacyMenuEnabled ? 9 : 4) * multiplier;
    const nudgeY = isLegacyMenuEnabled ? -23 : 4;
    const { column, columnGroup } = this.splitColumnOrGroup(columnOrGroup);
    this.showMenu(
      column,
      columnGroup,
      (menu) => {
        const ePopup = menu.getGui();
        this.popupService.positionPopupByComponent({
          type: containerType,
          column,
          eventSource,
          ePopup,
          alignSide,
          nudgeX,
          nudgeY,
          position: "under",
          keepWithinBounds: true
        });
        if (defaultTab) {
          menu.showTab?.(defaultTab);
        }
        this.dispatchVisibleChangedEvent(true, false, column, columnGroup, defaultTab);
      },
      containerType,
      defaultTab,
      restrictToTabs,
      eventSource
    );
  }
  showMenu(column, columnGroup, positionCallback, containerType, defaultTab, restrictToTabs, eventSource) {
    const menuParams = this.getMenuParams(column, columnGroup, restrictToTabs, eventSource);
    if (!menuParams) {
      return;
    }
    const { menu, eMenuGui, anchorToElement, restoreFocusParams } = menuParams;
    const closedFuncs = [];
    closedFuncs.push((e) => {
      const eComp = menu.getGui();
      this.destroyBean(menu);
      if (column) {
        column.setMenuVisible(false, "contextMenu");
        this.menuUtils.restoreFocusOnClose(restoreFocusParams, eComp, e);
      }
    });
    const translate = this.localeService.getLocaleTextFunc();
    this.popupService.addPopup({
      modal: true,
      eChild: eMenuGui,
      closeOnEsc: true,
      closedCallback: (e) => {
        closedFuncs.forEach((f) => f(e));
        this.dispatchVisibleChangedEvent(false, false, column, columnGroup, defaultTab);
      },
      afterGuiAttached: (params) => menu.afterGuiAttached(Object.assign({}, { container: containerType }, params)),
      // if defaultTab is not present, positionCallback will be called
      // after `showTabBasedOnPreviousSelection` is called.
      positionCallback: defaultTab ? () => positionCallback(menu) : void 0,
      ariaLabel: translate("ariaLabelColumnMenu", "Column Menu")
    });
    if (!defaultTab) {
      menu.showTabBasedOnPreviousSelection?.();
      positionCallback(menu);
    }
    if (this.menuService.isColumnMenuAnchoringEnabled()) {
      const stopAnchoringPromise = this.popupService.setPopupPositionRelatedToElement(eMenuGui, anchorToElement);
      if (stopAnchoringPromise && column) {
        this.addStopAnchoring(stopAnchoringPromise, column, closedFuncs);
      }
    }
    menu.addEventListener("tabSelected", (event) => {
      this.dispatchVisibleChangedEvent(false, true, column);
      this.lastSelectedTab = event.key;
      this.dispatchVisibleChangedEvent(true, true, column);
    });
    column?.setMenuVisible(true, "contextMenu");
    this.activeMenu = menu;
    menu.addEventListener("destroyed", () => {
      if (this.activeMenu === menu) {
        this.activeMenu = null;
      }
    });
  }
  addStopAnchoring(stopAnchoringPromise, column, closedFuncsArr) {
    stopAnchoringPromise.then((stopAnchoringFunc) => {
      column.addEventListener("leftChanged", stopAnchoringFunc);
      column.addEventListener("visibleChanged", stopAnchoringFunc);
      closedFuncsArr.push(() => {
        column.removeEventListener("leftChanged", stopAnchoringFunc);
        column.removeEventListener("visibleChanged", stopAnchoringFunc);
      });
    });
  }
  getMenuParams(column, columnGroup, restrictToTabs, eventSource) {
    const restoreFocusParams = {
      column,
      headerPosition: this.focusService.getFocusedHeader(),
      columnIndex: this.visibleColsService.getAllCols().indexOf(column),
      eventSource
    };
    const menu = this.createMenu(column, columnGroup, restoreFocusParams, restrictToTabs, eventSource);
    return menu ? {
      menu,
      eMenuGui: menu.getGui(),
      anchorToElement: eventSource || this.ctrlsService.getGridBodyCtrl().getGui(),
      restoreFocusParams
    } : void 0;
  }
  createMenu(column, columnGroup, restoreFocusParams, restrictToTabs, eventSource) {
    if (this.menuService.isLegacyMenuEnabled()) {
      return this.createBean(
        new TabbedColumnMenu(column, restoreFocusParams, this.lastSelectedTab, restrictToTabs, eventSource)
      );
    } else {
      const menuItems = this.columnMenuFactory.getMenuItems(column, columnGroup);
      return menuItems.length ? this.createBean(new ColumnContextMenu(menuItems, column, restoreFocusParams, eventSource)) : void 0;
    }
  }
  dispatchVisibleChangedEvent(visible, switchingTab, column, columnGroup, defaultTab) {
    this.eventService.dispatchEvent({
      type: "columnMenuVisibleChanged",
      visible,
      switchingTab,
      key: this.lastSelectedTab ?? defaultTab ?? (this.menuService.isLegacyMenuEnabled() ? TAB_GENERAL : "columnMenu"),
      column: column ?? null,
      columnGroup: columnGroup ?? null
    });
  }
  isMenuEnabled(column) {
    if (!this.menuService.isLegacyMenuEnabled()) {
      return true;
    }
    const isFilterDisabled = !this.filterManager?.isFilterAllowed(column);
    const tabs = column.getColDef().menuTabs ?? TABS_DEFAULT;
    const numActiveTabs = isFilterDisabled && tabs.includes(TAB_FILTER) ? tabs.length - 1 : tabs.length;
    return numActiveTabs > 0;
  }
  showMenuAfterContextMenuEvent(column, mouseEvent, touchEvent) {
    this.menuUtils.onContextMenu(mouseEvent, touchEvent, (eventOrTouch) => {
      this.showMenuAfterMouseEvent(column, eventOrTouch, "columnMenu");
      return true;
    });
  }
};
var TabbedColumnMenu = class extends BeanStub55 {
  constructor(column, restoreFocusParams, initialSelection, restrictTo, sourceElement) {
    super();
    this.column = column;
    this.restoreFocusParams = restoreFocusParams;
    this.initialSelection = initialSelection;
    this.restrictTo = restrictTo;
    this.sourceElement = sourceElement;
    this.tabFactories = {};
    this.includeChecks = {};
    this.tabFactories[TAB_GENERAL] = this.createMainPanel.bind(this);
    this.tabFactories[TAB_FILTER] = this.createFilterPanel.bind(this);
    this.tabFactories[TAB_COLUMNS] = this.createColumnsPanel.bind(this);
    this.includeChecks[TAB_GENERAL] = () => true;
    this.includeChecks[TAB_FILTER] = () => column ? !!this.filterManager?.isFilterAllowed(column) : false;
    this.includeChecks[TAB_COLUMNS] = () => true;
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.columnChooserFactory = beans.columnChooserFactory;
    this.columnMenuFactory = beans.columnMenuFactory;
    this.menuUtils = beans.menuUtils;
  }
  postConstruct() {
    const tabs = this.getTabsToCreate().map((name) => this.createTab(name));
    this.tabbedLayout = new TabbedLayout({
      items: tabs,
      cssClass: "ag-menu",
      onActiveItemClicked: this.onHidePopup.bind(this),
      onItemClicked: this.onTabItemClicked.bind(this)
    });
    this.createBean(this.tabbedLayout);
    if (this.mainMenuList) {
      this.mainMenuList.setParentComponent(this.tabbedLayout);
    }
    this.addDestroyFunc(() => this.destroyBean(this.tabbedLayout));
  }
  getTabsToCreate() {
    if (this.restrictTo) {
      return this.restrictTo;
    }
    return (this.column?.getColDef().menuTabs ?? TABS_DEFAULT).filter((tabName) => this.isValidMenuTabItem(tabName)).filter((tabName) => this.isNotSuppressed(tabName)).filter((tabName) => this.isModuleLoaded(tabName));
  }
  isModuleLoaded(menuTabName) {
    if (menuTabName === TAB_COLUMNS) {
      return this.gos.isModuleRegistered(ModuleNames32.ColumnsToolPanelModule);
    }
    return true;
  }
  isValidMenuTabItem(menuTabName) {
    let isValid = true;
    let itemsToConsider = TABS_DEFAULT;
    if (this.restrictTo != null) {
      isValid = this.restrictTo.indexOf(menuTabName) > -1;
      itemsToConsider = this.restrictTo;
    }
    isValid = isValid && TABS_DEFAULT.indexOf(menuTabName) > -1;
    if (!isValid) {
      _warnOnce210(
        `Trying to render an invalid menu item '${menuTabName}'. Check that your 'menuTabs' contains one of [${itemsToConsider}]`
      );
    }
    return isValid;
  }
  isNotSuppressed(menuTabName) {
    return this.includeChecks[menuTabName]();
  }
  createTab(name) {
    return this.tabFactories[name]();
  }
  showTabBasedOnPreviousSelection() {
    this.showTab(this.initialSelection);
  }
  showTab(toShow) {
    if (this.tabItemColumns && toShow === TAB_COLUMNS) {
      this.tabbedLayout.showItem(this.tabItemColumns);
    } else if (this.tabItemFilter && toShow === TAB_FILTER) {
      this.tabbedLayout.showItem(this.tabItemFilter);
    } else if (this.tabItemGeneral && toShow === TAB_GENERAL) {
      this.tabbedLayout.showItem(this.tabItemGeneral);
    } else {
      this.tabbedLayout.showFirstItem();
    }
  }
  onTabItemClicked(event) {
    let key = null;
    switch (event.item) {
      case this.tabItemColumns:
        key = TAB_COLUMNS;
        break;
      case this.tabItemFilter:
        key = TAB_FILTER;
        break;
      case this.tabItemGeneral:
        key = TAB_GENERAL;
        break;
    }
    if (key) {
      this.activateTab(key);
    }
  }
  activateTab(tab) {
    const ev = {
      type: "tabSelected",
      key: tab
    };
    this.dispatchLocalEvent(ev);
  }
  createMainPanel() {
    this.mainMenuList = this.columnMenuFactory.createMenu(
      this,
      this.columnMenuFactory.getMenuItems(this.column),
      this.column,
      () => this.sourceElement ?? this.getGui()
    );
    this.mainMenuList.addEventListener("closeMenu", this.onHidePopup.bind(this));
    this.tabItemGeneral = {
      title: _createIconNoSpan27("menu", this.gos, this.column),
      titleLabel: TAB_GENERAL.replace("MenuTab", ""),
      bodyPromise: AgPromise5.resolve(this.mainMenuList.getGui()),
      name: TAB_GENERAL
    };
    return this.tabItemGeneral;
  }
  onHidePopup(event) {
    this.menuUtils.closePopupAndRestoreFocusOnSelect(this.hidePopupFunc, this.restoreFocusParams, event);
  }
  createFilterPanel() {
    const comp = this.column ? this.createBean(new FilterWrapperComp3(this.column, "COLUMN_MENU")) : null;
    this.filterComp = comp;
    if (!comp?.hasFilter()) {
      throw new Error("AG Grid - Unable to instantiate filter");
    }
    const afterAttachedCallback = (params) => comp.afterGuiAttached(params);
    const afterDetachedCallback = () => comp.afterGuiDetached();
    this.tabItemFilter = {
      title: _createIconNoSpan27("filter", this.gos, this.column),
      titleLabel: TAB_FILTER.replace("MenuTab", ""),
      bodyPromise: AgPromise5.resolve(comp?.getGui()),
      afterAttachedCallback,
      afterDetachedCallback,
      name: TAB_FILTER
    };
    return this.tabItemFilter;
  }
  createColumnsPanel() {
    const eWrapperDiv = document.createElement("div");
    eWrapperDiv.classList.add("ag-menu-column-select-wrapper");
    const columnSelectPanel = this.columnChooserFactory.createColumnSelectPanel(this, this.column);
    const columnSelectPanelGui = columnSelectPanel.getGui();
    columnSelectPanelGui.classList.add("ag-menu-column-select");
    eWrapperDiv.appendChild(columnSelectPanelGui);
    this.tabItemColumns = {
      title: _createIconNoSpan27("columns", this.gos, this.column),
      //createColumnsIcon(),
      titleLabel: TAB_COLUMNS.replace("MenuTab", ""),
      bodyPromise: AgPromise5.resolve(eWrapperDiv),
      name: TAB_COLUMNS
    };
    return this.tabItemColumns;
  }
  afterGuiAttached(params) {
    const { container, hidePopup } = params;
    this.tabbedLayout.setAfterAttachedParams({ container, hidePopup });
    if (hidePopup) {
      this.hidePopupFunc = hidePopup;
      this.addDestroyFunc(hidePopup);
    }
  }
  getGui() {
    return this.tabbedLayout.getGui();
  }
  destroy() {
    super.destroy();
    this.destroyBean(this.filterComp);
  }
};
var ColumnContextMenu = class extends Component213 {
  constructor(menuItems, column, restoreFocusParams, sourceElement) {
    super(
      /* html */
      `
            <div data-ref="eColumnMenu" role="presentation" class="ag-menu ag-column-menu"></div>
        `
    );
    this.menuItems = menuItems;
    this.column = column;
    this.restoreFocusParams = restoreFocusParams;
    this.sourceElement = sourceElement;
    this.eColumnMenu = RefPlaceholder31;
  }
  wireBeans(beans) {
    this.columnMenuFactory = beans.columnMenuFactory;
    this.menuUtils = beans.menuUtils;
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.mainMenuList = this.columnMenuFactory.createMenu(
      this,
      this.menuItems,
      this.column,
      () => this.sourceElement ?? this.getGui()
    );
    this.mainMenuList.addEventListener("closeMenu", this.onHidePopup.bind(this));
    this.eColumnMenu.appendChild(this.mainMenuList.getGui());
  }
  onHidePopup(event) {
    this.menuUtils.closePopupAndRestoreFocusOnSelect(this.hidePopupFunc, this.restoreFocusParams, event);
  }
  afterGuiAttached({ hidePopup }) {
    if (hidePopup) {
      this.hidePopupFunc = hidePopup;
      this.addDestroyFunc(hidePopup);
    }
    this.focusService.focusInto(this.mainMenuList.getGui());
  }
};
function showContextMenu(beans, params) {
  const { rowNode, column, value, x, y } = params || {};
  let { x: clientX, y: clientY } = beans.menuService.getContextMenuPosition(rowNode, column);
  if (x != null) {
    clientX = x;
  }
  if (y != null) {
    clientY = y;
  }
  beans.menuService.showContextMenu({
    mouseEvent: new MouseEvent("mousedown", { clientX, clientY }),
    rowNode,
    column,
    value
  });
}
function showColumnChooser(beans, params) {
  beans.menuService.showColumnChooser({ chooserParams: params });
}
function hideColumnChooser(beans) {
  beans.menuService.hideColumnChooser();
}
var MenuItemMapper = class extends BeanStub64 {
  constructor() {
    super(...arguments);
    this.beanName = "menuItemMapper";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.columnApplyStateService = beans.columnApplyStateService;
    this.funcColsService = beans.funcColsService;
    this.focusService = beans.focusService;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.chartMenuItemMapper = beans.chartMenuItemMapper;
    this.menuService = beans.menuService;
    this.sortController = beans.sortController;
    this.columnAutosizeService = beans.columnAutosizeService;
    this.expansionService = beans.expansionService;
    this.clipboardService = beans.clipboardService;
    this.aggFuncService = beans.aggFuncService;
    this.csvCreator = beans.csvCreator;
    this.excelCreator = beans.excelCreator;
  }
  mapWithStockItems(originalList, column, sourceElement, source) {
    if (!originalList) {
      return [];
    }
    const resultList = [];
    originalList.forEach((menuItemOrString) => {
      let result;
      if (typeof menuItemOrString === "string") {
        result = this.getStockMenuItem(menuItemOrString, column, sourceElement, source);
      } else {
        result = { ...menuItemOrString };
      }
      if (!result) {
        return;
      }
      const resultDef = result;
      const { subMenu } = resultDef;
      if (subMenu && subMenu instanceof Array) {
        resultDef.subMenu = this.mapWithStockItems(subMenu, column, sourceElement, source);
      }
      if (result != null) {
        resultList.push(result);
      }
    });
    return resultList;
  }
  getStockMenuItem(key, column, sourceElement, source) {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const skipHeaderOnAutoSize = this.gos.get("skipHeaderOnAutoSize");
    switch (key) {
      case "pinSubMenu":
        return {
          name: localeTextFunc("pinColumn", "Pin Column"),
          icon: _createIconNoSpan36("menuPin", this.gos, null),
          subMenu: ["clearPinned", "pinLeft", "pinRight"]
        };
      case "pinLeft":
        return {
          name: localeTextFunc("pinLeft", "Pin Left"),
          action: () => this.columnModel.setColsPinned([column], "left", source),
          checked: !!column && column.isPinnedLeft()
        };
      case "pinRight":
        return {
          name: localeTextFunc("pinRight", "Pin Right"),
          action: () => this.columnModel.setColsPinned([column], "right", source),
          checked: !!column && column.isPinnedRight()
        };
      case "clearPinned":
        return {
          name: localeTextFunc("noPin", "No Pin"),
          action: () => this.columnModel.setColsPinned([column], null, source),
          checked: !!column && !column.isPinned()
        };
      case "valueAggSubMenu":
        if (this.gos.assertModuleRegistered(ModuleNames42.RowGroupingModule, "Aggregation from Menu")) {
          if (!column?.isPrimary() && !column?.getColDef().pivotValueColumn) {
            return null;
          }
          return {
            name: localeTextFunc("valueAggregation", "Value Aggregation"),
            icon: _createIconNoSpan36("menuValue", this.gos, null),
            subMenu: this.createAggregationSubMenu(column, this.aggFuncService),
            disabled: this.gos.get("functionsReadOnly")
          };
        } else {
          return null;
        }
      case "autoSizeThis":
        return {
          name: localeTextFunc("autosizeThisColumn", "Autosize This Column"),
          action: () => this.columnAutosizeService.autoSizeColumn(column, source, skipHeaderOnAutoSize)
        };
      case "autoSizeAll":
        return {
          name: localeTextFunc("autosizeAllColumns", "Autosize All Columns"),
          action: () => this.columnAutosizeService.autoSizeAllColumns(source, skipHeaderOnAutoSize)
        };
      case "rowGroup":
        return {
          name: localeTextFunc("groupBy", "Group by") + " " + _escapeString8(this.columnNameService.getDisplayNameForColumn(column, "header")),
          disabled: this.gos.get("functionsReadOnly") || column?.isRowGroupActive() || !column?.getColDef().enableRowGroup,
          action: () => this.funcColsService.addRowGroupColumns([column], source),
          icon: _createIconNoSpan36("menuAddRowGroup", this.gos, null)
        };
      case "rowUnGroup": {
        const icon = _createIconNoSpan36("menuRemoveRowGroup", this.gos, null);
        const showRowGroup = column?.getColDef().showRowGroup;
        const lockedGroups = this.gos.get("groupLockGroupColumns");
        if (showRowGroup === true) {
          return {
            name: localeTextFunc("ungroupAll", "Un-Group All"),
            disabled: this.gos.get("functionsReadOnly") || lockedGroups === -1 || lockedGroups >= this.funcColsService.getRowGroupColumns().length,
            action: () => this.funcColsService.setRowGroupColumns(
              this.funcColsService.getRowGroupColumns().slice(0, lockedGroups),
              source
            ),
            icon
          };
        }
        if (typeof showRowGroup === "string") {
          const underlyingColumn = this.columnModel.getColDefCol(showRowGroup);
          const ungroupByName = underlyingColumn != null ? _escapeString8(this.columnNameService.getDisplayNameForColumn(underlyingColumn, "header")) : showRowGroup;
          return {
            name: localeTextFunc("ungroupBy", "Un-Group by") + " " + ungroupByName,
            disabled: this.gos.get("functionsReadOnly") || underlyingColumn != null && this.columnModel.isColGroupLocked(underlyingColumn),
            action: () => this.funcColsService.removeRowGroupColumns([showRowGroup], source),
            icon
          };
        }
        return {
          name: localeTextFunc("ungroupBy", "Un-Group by") + " " + _escapeString8(this.columnNameService.getDisplayNameForColumn(column, "header")),
          disabled: this.gos.get("functionsReadOnly") || !column?.isRowGroupActive() || !column?.getColDef().enableRowGroup || this.columnModel.isColGroupLocked(column),
          action: () => this.funcColsService.removeRowGroupColumns([column], source),
          icon
        };
      }
      case "resetColumns":
        return {
          name: localeTextFunc("resetColumns", "Reset Columns"),
          action: () => this.columnApplyStateService.resetColumnState(source)
        };
      case "expandAll":
        return {
          name: localeTextFunc("expandAll", "Expand All Row Groups"),
          action: () => this.expansionService.expandAll(true)
        };
      case "contractAll":
        return {
          name: localeTextFunc("collapseAll", "Collapse All Row Groups"),
          action: () => this.expansionService.expandAll(false)
        };
      case "copy":
        if (this.gos.assertModuleRegistered(ModuleNames42.ClipboardModule, "Copy from Menu")) {
          return {
            name: localeTextFunc("copy", "Copy"),
            shortcut: localeTextFunc("ctrlC", "Ctrl+C"),
            icon: _createIconNoSpan36("clipboardCopy", this.gos, null),
            action: () => this.clipboardService.copyToClipboard()
          };
        } else {
          return null;
        }
      case "copyWithHeaders":
        if (this.gos.assertModuleRegistered(ModuleNames42.ClipboardModule, "Copy with Headers from Menu")) {
          return {
            name: localeTextFunc("copyWithHeaders", "Copy with Headers"),
            // shortcut: localeTextFunc('ctrlC','Ctrl+C'),
            icon: _createIconNoSpan36("clipboardCopy", this.gos, null),
            action: () => this.clipboardService.copyToClipboard({ includeHeaders: true })
          };
        } else {
          return null;
        }
      case "copyWithGroupHeaders":
        if (this.gos.assertModuleRegistered(ModuleNames42.ClipboardModule, "Copy with Group Headers from Menu")) {
          return {
            name: localeTextFunc("copyWithGroupHeaders", "Copy with Group Headers"),
            // shortcut: localeTextFunc('ctrlC','Ctrl+C'),
            icon: _createIconNoSpan36("clipboardCopy", this.gos, null),
            action: () => this.clipboardService.copyToClipboard({ includeHeaders: true, includeGroupHeaders: true })
          };
        } else {
          return null;
        }
      case "cut":
        if (this.gos.assertModuleRegistered(ModuleNames42.ClipboardModule, "Cut from Menu")) {
          const focusedCell = this.focusService.getFocusedCell();
          const rowNode = focusedCell ? this.rowPositionUtils.getRowNode(focusedCell) : null;
          const isEditable = rowNode ? focusedCell?.column.isCellEditable(rowNode) : false;
          return {
            name: localeTextFunc("cut", "Cut"),
            shortcut: localeTextFunc("ctrlX", "Ctrl+X"),
            icon: _createIconNoSpan36("clipboardCut", this.gos, null),
            disabled: !isEditable || this.gos.get("suppressCutToClipboard"),
            action: () => this.clipboardService.cutToClipboard(void 0, "contextMenu")
          };
        } else {
          return null;
        }
      case "paste":
        if (this.gos.assertModuleRegistered(ModuleNames42.ClipboardModule, "Paste from Clipboard")) {
          return {
            name: localeTextFunc("paste", "Paste"),
            shortcut: localeTextFunc("ctrlV", "Ctrl+V"),
            disabled: true,
            icon: _createIconNoSpan36("clipboardPaste", this.gos, null),
            action: () => this.clipboardService.pasteFromClipboard()
          };
        } else {
          return null;
        }
      case "export": {
        const exportSubMenuItems = [];
        const csvModuleLoaded = this.gos.isModuleRegistered(ModuleNames42.CsvExportModule);
        const excelModuleLoaded = this.gos.isModuleRegistered(ModuleNames42.ExcelExportModule);
        if (!this.gos.get("suppressCsvExport") && csvModuleLoaded) {
          exportSubMenuItems.push("csvExport");
        }
        if (!this.gos.get("suppressExcelExport") && excelModuleLoaded) {
          exportSubMenuItems.push("excelExport");
        }
        return {
          name: localeTextFunc("export", "Export"),
          subMenu: exportSubMenuItems,
          icon: _createIconNoSpan36("save", this.gos, null)
        };
      }
      case "csvExport":
        return {
          name: localeTextFunc("csvExport", "CSV Export"),
          icon: _createIconNoSpan36("csvExport", this.gos, null),
          action: () => this.csvCreator?.exportDataAsCsv()
        };
      case "excelExport":
        return {
          name: localeTextFunc("excelExport", "Excel Export"),
          icon: _createIconNoSpan36("excelExport", this.gos, null),
          action: () => this.excelCreator?.exportDataAsExcel()
        };
      case "separator":
        return "separator";
      case "pivotChart":
      case "chartRange":
        return this.chartMenuItemMapper.getChartItems(key) ?? null;
      case "columnFilter":
        if (column) {
          return {
            name: localeTextFunc("columnFilter", "Column Filter"),
            icon: _createIconNoSpan36("filter", this.gos, null),
            action: () => this.menuService.showFilterMenu({
              column,
              buttonElement: sourceElement(),
              containerType: "columnFilter",
              positionBy: "button"
            })
          };
        } else {
          return null;
        }
      case "columnChooser":
        if (this.gos.isModuleRegistered(ModuleNames42.ColumnsToolPanelModule)) {
          return {
            name: localeTextFunc("columnChooser", "Choose Columns"),
            icon: _createIconNoSpan36("columns", this.gos, null),
            action: () => this.menuService.showColumnChooser({ column, eventSource: sourceElement() })
          };
        } else {
          return null;
        }
      case "sortAscending":
        return {
          name: localeTextFunc("sortAscending", "Sort Ascending"),
          icon: _createIconNoSpan36("sortAscending", this.gos, null),
          action: () => this.sortController.setSortForColumn(column, "asc", false, source)
        };
      case "sortDescending":
        return {
          name: localeTextFunc("sortDescending", "Sort Descending"),
          icon: _createIconNoSpan36("sortDescending", this.gos, null),
          action: () => this.sortController.setSortForColumn(column, "desc", false, source)
        };
      case "sortUnSort":
        return {
          name: localeTextFunc("sortUnSort", "Clear Sort"),
          icon: _createIconNoSpan36("sortUnSort", this.gos, null),
          action: () => this.sortController.setSortForColumn(column, null, false, source)
        };
      default: {
        _warnOnce35(`unknown menu item type ${key}`);
        return null;
      }
    }
  }
  createAggregationSubMenu(column, aggFuncService) {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    let columnToUse;
    if (column.isPrimary()) {
      columnToUse = column;
    } else {
      const pivotValueColumn = column.getColDef().pivotValueColumn;
      columnToUse = _exists25(pivotValueColumn) ? pivotValueColumn : void 0;
    }
    const result = [];
    if (columnToUse) {
      const columnIsAlreadyAggValue = columnToUse.isValueActive();
      const funcNames = aggFuncService.getFuncNames(columnToUse);
      result.push({
        name: localeTextFunc("noAggregation", "None"),
        action: () => {
          this.funcColsService.removeValueColumns([columnToUse], "contextMenu");
          this.funcColsService.setColumnAggFunc(columnToUse, void 0, "contextMenu");
        },
        checked: !columnIsAlreadyAggValue
      });
      funcNames.forEach((funcName) => {
        result.push({
          name: localeTextFunc(funcName, aggFuncService.getDefaultFuncLabel(funcName)),
          action: () => {
            this.funcColsService.setColumnAggFunc(columnToUse, funcName, "contextMenu");
            this.funcColsService.addValueColumns([columnToUse], "contextMenu");
          },
          checked: columnIsAlreadyAggValue && columnToUse.getAggFunc() === funcName
        });
      });
    }
    return result;
  }
};
var MenuUtils = class extends BeanStub74 {
  constructor() {
    super(...arguments);
    this.beanName = "menuUtils";
  }
  wireBeans(beans) {
    this.focusService = beans.focusService;
    this.headerNavigationService = beans.headerNavigationService;
    this.visibleColsService = beans.visibleColsService;
  }
  restoreFocusOnClose(restoreFocusParams, eComp, e, restoreIfMouseEvent) {
    const { eventSource } = restoreFocusParams;
    const isKeyboardEvent = e instanceof KeyboardEvent;
    if (!restoreIfMouseEvent && !isKeyboardEvent || !eventSource) {
      return;
    }
    const activeEl = _getActiveDomElement11(this.gos);
    if (
      // focus is outside of comp
      !eComp.contains(activeEl) && // something else has focus
      !_isNothingFocused22(this.gos)
    ) {
      return;
    }
    this.focusHeaderCell(restoreFocusParams);
  }
  closePopupAndRestoreFocusOnSelect(hidePopupFunc, restoreFocusParams, event) {
    let keyboardEvent;
    if (event && event.keyboardEvent) {
      keyboardEvent = event.keyboardEvent;
    }
    hidePopupFunc(keyboardEvent && { keyboardEvent });
    const focusedCell = this.focusService.getFocusedCell();
    if (_isNothingFocused22(this.gos)) {
      if (focusedCell) {
        const { rowIndex, rowPinned, column } = focusedCell;
        this.focusService.setFocusedCell({
          rowIndex,
          column,
          rowPinned,
          forceBrowserFocus: true,
          preventScrollOnBrowserFocus: true
        });
      } else {
        this.focusHeaderCell(restoreFocusParams);
      }
    }
  }
  onContextMenu(mouseEvent, touchEvent, showMenuCallback) {
    if (!this.gos.get("allowContextMenuWithControlKey")) {
      if (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey)) {
        return;
      }
    }
    if (mouseEvent) {
      this.blockMiddleClickScrollsIfNeeded(mouseEvent);
    }
    if (this.gos.get("suppressContextMenu")) {
      return;
    }
    const eventOrTouch = mouseEvent ?? touchEvent.touches[0];
    if (showMenuCallback(eventOrTouch)) {
      const event = mouseEvent ?? touchEvent;
      if (event && event.cancelable) {
        event.preventDefault();
      }
    }
  }
  // make this async for react
  async focusHeaderCell(restoreFocusParams) {
    const { column, columnIndex, headerPosition, eventSource } = restoreFocusParams;
    const isColumnStillVisible = await this.visibleColsService.getAllCols().some((col) => col === column);
    if (column && !column.isAlive()) {
      return;
    }
    if (isColumnStillVisible && eventSource && _isVisible4(eventSource)) {
      const focusableEl = this.focusService.findTabbableParent(eventSource);
      if (focusableEl) {
        if (column) {
          this.headerNavigationService.scrollToColumn(column);
        }
        focusableEl.focus();
      }
    } else if (headerPosition && columnIndex !== -1) {
      const allColumns = this.visibleColsService.getAllCols();
      const columnToFocus = allColumns[columnIndex] || _last11(allColumns);
      if (columnToFocus) {
        this.focusService.focusHeaderPosition({
          headerPosition: {
            headerRowIndex: headerPosition.headerRowIndex,
            column: columnToFocus
          }
        });
      }
    }
  }
  blockMiddleClickScrollsIfNeeded(mouseEvent) {
    if (this.gos.get("suppressMiddleClickScrolls") && mouseEvent.which === 2) {
      mouseEvent.preventDefault();
    }
  }
};
var VERSION12 = "32.3.0";
var MenuCoreModule = _defineModule12({
  version: VERSION12,
  moduleName: `${ModuleNames52.MenuModule}-core`,
  beans: [
    EnterpriseMenuFactory,
    ContextMenuFactory,
    MenuItemMapper,
    ChartMenuItemMapper,
    ColumnChooserFactory,
    ColumnMenuFactory,
    MenuUtils
  ],
  dependantModules: [EnterpriseCoreModule],
  userComponents: [
    {
      name: "agMenuItem",
      classImp: AgMenuItemRenderer
    }
  ]
});
var MenuApiModule = _defineModule12({
  version: VERSION12,
  moduleName: `${ModuleNames52.MenuModule}-api`,
  apiFunctions: {
    showContextMenu,
    showColumnChooser,
    hideColumnChooser
  },
  dependantModules: [MenuCoreModule, _CommunityMenuApiModule]
});
var MenuModule = _defineModule12({
  version: VERSION12,
  moduleName: ModuleNames52.MenuModule,
  dependantModules: [MenuCoreModule, MenuApiModule]
});

// enterprise-modules/multi-filter/dist/package/main.esm.mjs
import {
  ModuleNames as ModuleNames14,
  _ColumnFilterModule as _ColumnFilterModule3,
  _ReadOnlyFloatingFilterModule,
  _defineModule as _defineModule13
} from "ag-grid-community";
import {
  AgPromise as AgPromise6,
  KeyCode as KeyCode20,
  ProvidedFilter,
  TabGuardComp as TabGuardComp8,
  _forEachReverse,
  _getActiveDomElement as _getActiveDomElement12,
  _isNothingFocused as _isNothingFocused5,
  _loadTemplate as _loadTemplate6,
  _removeFromArray as _removeFromArray3
} from "ag-grid-community";
import {
  AgPromise as AgPromise23,
  Component as Component57,
  _clearElement as _clearElement11,
  _mergeDeep as _mergeDeep5,
  _setDisplayed as _setDisplayed12,
  getDefaultFloatingFilterType
} from "ag-grid-community";
var MultiFilter = class _MultiFilter extends TabGuardComp8 {
  constructor() {
    super(
      /* html */
      `<div class="ag-multi-filter ag-menu-list-compact"></div>`
    );
    this.filterDefs = [];
    this.filters = [];
    this.guiDestroyFuncs = [];
    this.filterGuis = [];
    this.activeFilterIndices = [];
    this.lastActivatedMenuItem = null;
    this.afterFiltersReadyFuncs = [];
  }
  wireBeans(beans) {
    this.filterManager = beans.filterManager;
    this.userComponentFactory = beans.userComponentFactory;
    this.focusService = beans.focusService;
  }
  postConstruct() {
    this.initialiseTabGuard({
      onFocusIn: (e) => this.onFocusIn(e)
    });
  }
  static getFilterDefs(params) {
    const { filters } = params;
    return filters && filters.length > 0 ? filters : [{ filter: "agTextColumnFilter" }, { filter: "agSetColumnFilter" }];
  }
  init(params) {
    this.params = params;
    this.filterDefs = _MultiFilter.getFilterDefs(params);
    const { column, filterChangedCallback } = params;
    this.column = column;
    this.filterChangedCallback = filterChangedCallback;
    const filterPromises = [];
    this.filterDefs.forEach((filterDef, index) => {
      const filterPromise = this.createFilter(filterDef, index);
      if (filterPromise != null) {
        filterPromises.push(filterPromise);
      }
    });
    return new AgPromise6((resolve) => {
      AgPromise6.all(filterPromises).then((filters) => {
        this.filters = filters;
        this.refreshGui("columnMenu").then(() => {
          resolve();
        });
      });
    }).then(() => {
      this.afterFiltersReadyFuncs.forEach((f) => f());
      this.afterFiltersReadyFuncs.length = 0;
    });
  }
  refreshGui(container) {
    if (container === this.lastOpenedInContainer) {
      return AgPromise6.resolve();
    }
    this.tabGuardFeature.removeAllChildrenExceptTabGuards();
    this.destroyChildren();
    return AgPromise6.all(
      this.filters.map((filter, index) => {
        const filterDef = this.filterDefs[index];
        const filterTitle = this.getFilterTitle(filter, filterDef);
        let filterGuiPromise;
        if (filterDef.display === "subMenu" && container !== "toolPanel") {
          filterGuiPromise = this.insertFilterMenu(filter, filterTitle).then(
            (menuItem) => menuItem.getGui()
          );
        } else if (filterDef.display === "subMenu" || filterDef.display === "accordion") {
          const group = this.insertFilterGroup(filter, filterTitle);
          filterGuiPromise = AgPromise6.resolve(group.getGui());
        } else {
          filterGuiPromise = AgPromise6.resolve(filter.getGui());
        }
        return filterGuiPromise;
      })
    ).then((filterGuis) => {
      filterGuis.forEach((filterGui, index) => {
        if (index > 0) {
          this.appendChild(_loadTemplate6(
            /* html */
            `<div class="ag-filter-separator"></div>`
          ));
        }
        this.appendChild(filterGui);
      });
      this.filterGuis = filterGuis;
      this.lastOpenedInContainer = container;
    });
  }
  getFilterTitle(filter, filterDef) {
    if (filterDef.title != null) {
      return filterDef.title;
    }
    return filter instanceof ProvidedFilter ? filter.getFilterTitle() : "Filter";
  }
  destroyChildren() {
    this.guiDestroyFuncs.forEach((func) => func());
    this.guiDestroyFuncs.length = 0;
    this.filterGuis.length = 0;
  }
  insertFilterMenu(filter, name) {
    const menuItem = this.createBean(new AgMenuItemComponent());
    return menuItem.init({
      menuItemDef: {
        name,
        subMenu: [],
        cssClasses: ["ag-multi-filter-menu-item"],
        menuItem: AgMenuItemRenderer,
        menuItemParams: {
          cssClassPrefix: "ag-compact-menu-option",
          isCompact: true
        }
      },
      level: 0,
      isAnotherSubMenuOpen: () => false,
      childComponent: filter,
      contextParams: {
        column: null,
        node: null,
        value: null
      }
    }).then(() => {
      menuItem.setParentComponent(this);
      this.guiDestroyFuncs.push(() => this.destroyBean(menuItem));
      this.addManagedListeners(menuItem, {
        menuItemActivated: (event) => {
          if (this.lastActivatedMenuItem && this.lastActivatedMenuItem !== event.menuItem) {
            this.lastActivatedMenuItem.deactivate();
          }
          this.lastActivatedMenuItem = event.menuItem;
        }
      });
      const menuItemGui = menuItem.getGui();
      menuItem.addManagedElementListeners(menuItemGui, {
        // `AgMenuList` normally handles keyboard navigation, so need to do here
        keydown: (e) => {
          const { key } = e;
          switch (key) {
            case KeyCode20.UP:
            case KeyCode20.RIGHT:
            case KeyCode20.DOWN:
            case KeyCode20.LEFT:
              e.preventDefault();
              if (key === KeyCode20.RIGHT) {
                menuItem.openSubMenu(true);
              }
              break;
          }
        },
        focusin: () => menuItem.activate(),
        focusout: () => {
          if (!menuItem.isSubMenuOpen() && !menuItem.isSubMenuOpening()) {
            menuItem.deactivate();
          }
        }
      });
      return menuItem;
    });
  }
  insertFilterGroup(filter, title) {
    const group = this.createBean(
      new AgGroupComponent({
        title,
        cssIdentifier: "multi-filter"
      })
    );
    this.guiDestroyFuncs.push(() => this.destroyBean(group));
    group.addItem(filter.getGui());
    group.toggleGroupExpand(false);
    if (filter.afterGuiAttached) {
      group.addManagedListeners(group, {
        expanded: () => filter.afterGuiAttached({
          container: this.lastOpenedInContainer,
          suppressFocus: true,
          hidePopup: this.hidePopup
        })
      });
    }
    return group;
  }
  isFilterActive() {
    return this.filters.some((filter) => filter.isFilterActive());
  }
  getLastActiveFilterIndex() {
    return this.activeFilterIndices.length > 0 ? this.activeFilterIndices[this.activeFilterIndices.length - 1] : null;
  }
  doesFilterPass(params, filterToSkip) {
    let rowPasses = true;
    this.filters.forEach((filter) => {
      if (!rowPasses || filter === filterToSkip || !filter.isFilterActive()) {
        return;
      }
      rowPasses = filter.doesFilterPass(params);
    });
    return rowPasses;
  }
  getFilterType() {
    return "multi";
  }
  getModelFromUi() {
    const model = {
      filterType: this.getFilterType(),
      filterModels: this.filters.map((filter) => {
        const providedFilter = filter;
        if (typeof providedFilter.getModelFromUi === "function") {
          return providedFilter.getModelFromUi();
        }
        return null;
      })
    };
    return model;
  }
  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    const model = {
      filterType: this.getFilterType(),
      filterModels: this.filters.map((filter) => {
        if (filter.isFilterActive()) {
          return filter.getModel();
        }
        return null;
      })
    };
    return model;
  }
  setModel(model) {
    const setFilterModel = (filter, filterModel) => {
      return new AgPromise6((resolve) => {
        const promise = filter.setModel(filterModel);
        promise ? promise.then(() => resolve()) : resolve();
      });
    };
    let promises = [];
    if (model == null) {
      promises = this.filters.map((filter, index) => {
        const res = setFilterModel(filter, null).then(() => {
          this.updateActiveList(index);
        });
        return res;
      });
    } else {
      this.filters.forEach((filter, index) => {
        const filterModel = model.filterModels.length > index ? model.filterModels[index] : null;
        const res = setFilterModel(filter, filterModel).then(() => {
          this.updateActiveList(index);
        });
        promises.push(res);
      });
    }
    return AgPromise6.all(promises).then(() => {
    });
  }
  applyModel(source = "api") {
    let result = false;
    this.filters.forEach((filter) => {
      if (filter instanceof ProvidedFilter) {
        result = filter.applyModel(source) || result;
      }
    });
    return result;
  }
  getChildFilterInstance(index) {
    return this.filters[index];
  }
  afterGuiAttached(params) {
    let refreshPromise;
    if (params) {
      this.hidePopup = params.hidePopup;
      refreshPromise = this.refreshGui(params.container);
    } else {
      this.hidePopup = void 0;
      refreshPromise = AgPromise6.resolve();
    }
    const suppressFocus = params?.suppressFocus;
    refreshPromise.then(() => {
      const { filterDefs } = this;
      let hasFocused = !!suppressFocus;
      if (filterDefs) {
        _forEachReverse(filterDefs, (filterDef, index) => {
          const isFirst = index === 0;
          const notInlineDisplayType = filterDef.display && filterDef.display !== "inline";
          const suppressFocusForFilter = suppressFocus || !isFirst || notInlineDisplayType;
          const afterGuiAttachedParams = { ...params ?? {}, suppressFocus: suppressFocusForFilter };
          const filter = this.filters?.[index];
          if (filter) {
            this.executeFunctionIfExistsOnFilter(filter, "afterGuiAttached", afterGuiAttachedParams);
            if (isFirst && !suppressFocusForFilter) {
              hasFocused = true;
            }
          }
          if (!suppressFocus && isFirst && notInlineDisplayType) {
            const filterGui = this.filterGuis[index];
            if (filterGui) {
              if (!this.focusService.focusInto(filterGui)) {
                filterGui.focus();
              }
              hasFocused = true;
            }
          }
        });
      }
      const activeEl = _getActiveDomElement12(this.gos);
      if (!hasFocused && (_isNothingFocused5(this.gos) || this.getGui().contains(activeEl))) {
        this.forceFocusOutOfContainer(true);
      }
    });
  }
  afterGuiDetached() {
    this.executeFunctionIfExists("afterGuiDetached");
  }
  onAnyFilterChanged() {
    this.executeFunctionIfExists("onAnyFilterChanged");
  }
  onNewRowsLoaded() {
    this.executeFunctionIfExists("onNewRowsLoaded");
  }
  destroy() {
    this.filters.forEach((filter) => this.destroyBean(filter));
    this.filters.length = 0;
    this.destroyChildren();
    this.hidePopup = void 0;
    super.destroy();
  }
  executeFunctionIfExists(name, ...params) {
    _forEachReverse(this.filters, (filter) => {
      this.executeFunctionIfExistsOnFilter(filter, name, params);
    });
  }
  executeFunctionIfExistsOnFilter(filter, name, ...params) {
    const func = filter[name];
    if (typeof func === "function") {
      func.apply(filter, params);
    }
  }
  createFilter(filterDef, index) {
    const { filterModifiedCallback, doesRowPassOtherFilter } = this.params;
    let filterInstance;
    const filterParams = {
      ...this.filterManager.createFilterParams(this.column, this.column.getColDef()),
      filterModifiedCallback,
      filterChangedCallback: (additionalEventAttributes) => {
        this.executeWhenAllFiltersReady(() => this.filterChanged(index, additionalEventAttributes));
      },
      doesRowPassOtherFilter: (node) => doesRowPassOtherFilter(node) && this.doesFilterPass({ node, data: node.data }, filterInstance)
    };
    const compDetails = this.userComponentFactory.getFilterDetails(filterDef, filterParams, "agTextColumnFilter");
    if (!compDetails) {
      return null;
    }
    const filterPromise = compDetails.newAgStackInstance();
    if (filterPromise) {
      filterPromise.then((filter) => filterInstance = filter);
    }
    return filterPromise;
  }
  executeWhenAllFiltersReady(action) {
    if (this.filters && this.filters.length > 0) {
      action();
    } else {
      this.afterFiltersReadyFuncs.push(action);
    }
  }
  updateActiveList(index) {
    const changedFilter = this.filters[index];
    _removeFromArray3(this.activeFilterIndices, index);
    if (changedFilter.isFilterActive()) {
      this.activeFilterIndices.push(index);
    }
  }
  filterChanged(index, additionalEventAttributes) {
    this.updateActiveList(index);
    this.filterChangedCallback(additionalEventAttributes);
    const changedFilter = this.filters[index];
    this.filters.forEach((filter) => {
      if (filter === changedFilter) {
        return;
      }
      if (typeof filter.onAnyFilterChanged === "function") {
        filter.onAnyFilterChanged();
      }
    });
  }
  onFocusIn(e) {
    if (this.lastActivatedMenuItem != null && !this.lastActivatedMenuItem.getGui().contains(e.target)) {
      this.lastActivatedMenuItem.deactivate();
      this.lastActivatedMenuItem = null;
    }
  }
  getModelAsString(model) {
    if (!this.filters || !model?.filterModels?.length) {
      return "";
    }
    const lastActiveIndex = this.getLastActiveFilterIndex() ?? 0;
    const activeFilter = this.filters[lastActiveIndex];
    return activeFilter.getModelAsString?.(model.filterModels[lastActiveIndex]) ?? "";
  }
};
var MultiFloatingFilterComp = class extends Component57 {
  constructor() {
    super(
      /* html */
      `<div class="ag-multi-floating-filter ag-floating-filter-input"></div>`
    );
    this.floatingFilters = [];
    this.compDetailsList = [];
  }
  wireBeans(beans) {
    this.userComponentFactory = beans.userComponentFactory;
    this.filterManager = beans.filterManager;
  }
  init(params) {
    this.params = params;
    const { compDetailsList } = this.getCompDetailsList(params);
    return this.setParams(compDetailsList);
  }
  setParams(compDetailsList) {
    const floatingFilterPromises = [];
    compDetailsList.forEach((compDetails) => {
      const floatingFilterPromise = compDetails?.newAgStackInstance();
      if (floatingFilterPromise != null) {
        this.compDetailsList.push(compDetails);
        floatingFilterPromises.push(floatingFilterPromise);
      }
    });
    return AgPromise23.all(floatingFilterPromises).then((floatingFilters) => {
      floatingFilters.forEach((floatingFilter, index) => {
        this.floatingFilters.push(floatingFilter);
        const gui = floatingFilter.getGui();
        this.appendChild(gui);
        if (index > 0) {
          _setDisplayed12(gui, false);
        }
      });
    });
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    const { compDetailsList: newCompDetailsList, floatingFilterParamsList } = this.getCompDetailsList(params);
    const allFloatingFilterCompsUnchanged = newCompDetailsList.length === this.compDetailsList.length && newCompDetailsList.every(
      (newCompDetails, index) => !this.filterManager?.areFilterCompsDifferent(this.compDetailsList[index], newCompDetails)
    );
    if (allFloatingFilterCompsUnchanged) {
      floatingFilterParamsList.forEach((floatingFilterParams, index) => {
        const floatingFilter = this.floatingFilters[index];
        let hasRefreshed = false;
        if (floatingFilter.refresh) {
          const result = floatingFilter.refresh(floatingFilterParams);
          if (result !== null) {
            hasRefreshed = true;
          }
        }
        if (!hasRefreshed) {
          floatingFilter.onParamsUpdated?.(floatingFilterParams);
        }
      });
    } else {
      _clearElement11(this.getGui());
      this.destroyBeans(this.floatingFilters);
      this.floatingFilters = [];
      this.compDetailsList = [];
      this.setParams(newCompDetailsList);
    }
  }
  getCompDetailsList(params) {
    const compDetailsList = [];
    const floatingFilterParamsList = [];
    const filterParams = params.filterParams;
    MultiFilter.getFilterDefs(filterParams).forEach((filterDef, index) => {
      const floatingFilterParams = {
        ...params,
        // set the parent filter instance for each floating filter to the relevant child filter instance
        parentFilterInstance: (callback) => {
          this.parentMultiFilterInstance((parent) => {
            const child = parent.getChildFilterInstance(index);
            if (child == null) {
              return;
            }
            callback(child);
          });
        }
      };
      _mergeDeep5(floatingFilterParams.filterParams, filterDef.filterParams);
      const compDetails = this.getCompDetails(filterDef, floatingFilterParams);
      if (compDetails) {
        compDetailsList.push(compDetails);
        floatingFilterParamsList.push(floatingFilterParams);
      }
    });
    return { compDetailsList, floatingFilterParamsList };
  }
  onParentModelChanged(model, event) {
    if (event && event.afterFloatingFilter) {
      return;
    }
    this.parentMultiFilterInstance((parent) => {
      if (model == null) {
        this.floatingFilters.forEach((filter, i) => {
          filter.onParentModelChanged(null, event);
          _setDisplayed12(filter.getGui(), i === 0);
        });
      } else {
        const lastActiveFloatingFilterIndex = parent.getLastActiveFilterIndex();
        this.floatingFilters.forEach((filter, i) => {
          const filterModel = model.filterModels.length > i ? model.filterModels[i] : null;
          filter.onParentModelChanged(filterModel, event);
          const shouldShow = lastActiveFloatingFilterIndex == null ? i === 0 : i === lastActiveFloatingFilterIndex;
          _setDisplayed12(filter.getGui(), shouldShow);
        });
      }
    });
  }
  destroy() {
    this.destroyBeans(this.floatingFilters);
    this.floatingFilters.length = 0;
    super.destroy();
  }
  getCompDetails(filterDef, params) {
    const defaultComponentName = getDefaultFloatingFilterType(
      this.frameworkOverrides,
      filterDef,
      () => this.filterManager.getDefaultFloatingFilter(this.params.column)
    ) ?? "agReadOnlyFloatingFilter";
    return this.userComponentFactory.getFloatingFilterCompDetails(filterDef, params, defaultComponentName);
  }
  parentMultiFilterInstance(cb) {
    this.params.parentFilterInstance((parent) => {
      if (!(parent instanceof MultiFilter)) {
        throw new Error("AG Grid - MultiFloatingFilterComp expects MultiFilter as its parent");
      }
      cb(parent);
    });
  }
};
var VERSION13 = "32.3.0";
var MultiFilterCoreModule = _defineModule13({
  version: VERSION13,
  moduleName: `${ModuleNames14.MultiFilterModule}-core`,
  userComponents: [
    { name: "agMultiColumnFilter", classImp: MultiFilter },
    {
      name: "agMenuItem",
      classImp: AgMenuItemRenderer
    }
  ],
  dependantModules: [EnterpriseCoreModule, _ColumnFilterModule3]
});
var MultiFloatingFilterModule = _defineModule13({
  version: VERSION13,
  moduleName: "@ag-grid-enterprise/multi-floating-filter",
  userComponents: [{ name: "agMultiColumnFloatingFilter", classImp: MultiFloatingFilterComp }],
  dependantModules: [MultiFilterCoreModule, _ReadOnlyFloatingFilterModule]
});
var MultiFilterModule = _defineModule13({
  version: VERSION13,
  moduleName: ModuleNames14.MultiFilterModule,
  dependantModules: [MultiFilterCoreModule, MultiFloatingFilterModule]
});

// enterprise-modules/rich-select/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames15, _EditCoreModule, _defineModule as _defineModule14 } from "ag-grid-community";
import { PopupComponent as PopupComponent2, _missing as _missing5, _warnOnce as _warnOnce36 } from "ag-grid-community";
var RichSelectCellEditor = class extends PopupComponent2 {
  constructor() {
    super(
      /* html */
      `<div class="ag-cell-edit-wrapper"></div>`
    );
  }
  init(params) {
    this.params = params;
    const { cellStartedEdit, values } = params;
    if (_missing5(values)) {
      _warnOnce36("agRichSelectCellEditor requires cellEditorParams.values to be set");
    }
    const { params: richSelectParams, valuesPromise } = this.buildRichSelectParams();
    this.richSelect = this.createManagedBean(new AgRichSelect(richSelectParams));
    this.richSelect.addCssClass("ag-cell-editor");
    this.appendChild(this.richSelect);
    if (valuesPromise) {
      valuesPromise.then((values2) => {
        this.richSelect.setValueList({ valueList: values2, refresh: true });
        const searchStringCallback = this.getSearchStringCallback(values2);
        if (searchStringCallback) {
          this.richSelect.setSearchStringCreator(searchStringCallback);
        }
      });
    }
    this.addManagedListeners(this.richSelect, {
      fieldPickerValueSelected: this.onEditorPickerValueSelected.bind(this)
    });
    this.focusAfterAttached = cellStartedEdit;
  }
  onEditorPickerValueSelected(e) {
    setTimeout(() => this.params.stopEditing(!e.fromEnterKey));
  }
  buildRichSelectParams() {
    const {
      cellRenderer,
      cellHeight,
      value,
      values,
      formatValue,
      searchDebounceDelay,
      valueListGap,
      valueListMaxHeight,
      valueListMaxWidth,
      allowTyping,
      filterList,
      searchType,
      highlightMatch,
      valuePlaceholder,
      eventKey,
      multiSelect,
      suppressDeselectAll,
      suppressMultiSelectPillRenderer
    } = this.params;
    const ret = {
      value,
      cellRenderer,
      cellRowHeight: cellHeight,
      searchDebounceDelay,
      valueFormatter: formatValue,
      pickerAriaLabelKey: "ariaLabelRichSelectField",
      pickerAriaLabelValue: "Rich Select Field",
      pickerType: "virtual-list",
      pickerGap: valueListGap,
      allowTyping,
      filterList,
      searchType,
      highlightMatch,
      maxPickerHeight: valueListMaxHeight,
      maxPickerWidth: valueListMaxWidth,
      placeholder: valuePlaceholder,
      initialInputValue: eventKey?.length === 1 ? eventKey : void 0,
      multiSelect,
      suppressDeselectAll,
      suppressMultiSelectPillRenderer
    };
    let valuesResult;
    let valuesPromise;
    if (typeof values === "function") {
      valuesResult = values(this.params);
    } else {
      valuesResult = values ?? [];
    }
    if (Array.isArray(valuesResult)) {
      ret.valueList = valuesResult;
      ret.searchStringCreator = this.getSearchStringCallback(valuesResult);
    } else {
      valuesPromise = valuesResult;
    }
    if (multiSelect && allowTyping) {
      this.params.allowTyping = ret.allowTyping = false;
      _warnOnce36(
        "agRichSelectCellEditor cannot have `multiSelect` and `allowTyping` set to `true`. AllowTyping has been turned off."
      );
    }
    return { params: ret, valuesPromise };
  }
  getSearchStringCallback(values) {
    const { colDef } = this.params;
    if (typeof values[0] !== "object" || !colDef.keyCreator) {
      return;
    }
    return (values2) => values2.map((value) => {
      const keyParams = this.gos.addGridCommonParams({
        value,
        colDef: this.params.colDef,
        column: this.params.column,
        node: this.params.node,
        data: this.params.data
      });
      return colDef.keyCreator(keyParams);
    });
  }
  // we need to have the gui attached before we can draw the virtual rows, as the
  // virtual row logic needs info about the gui state
  afterGuiAttached() {
    const { focusAfterAttached, params } = this;
    setTimeout(() => {
      if (!this.isAlive()) {
        return;
      }
      if (focusAfterAttached) {
        const focusableEl = this.richSelect.getFocusableElement();
        focusableEl.focus();
        const { allowTyping, eventKey: eventKey2 } = this.params;
        if (allowTyping && (!eventKey2 || eventKey2.length !== 1)) {
          focusableEl.select();
        }
      }
      this.richSelect.showPicker();
      const { eventKey } = params;
      if (eventKey) {
        if (eventKey?.length === 1) {
          this.richSelect.searchTextFromString(eventKey);
        }
      }
    });
  }
  focusIn() {
    this.richSelect.getFocusableElement().focus();
  }
  getValue() {
    return this.richSelect.getValue();
  }
  isPopup() {
    return false;
  }
};
var VERSION14 = "32.3.0";
var RichSelectModule = _defineModule14({
  version: VERSION14,
  moduleName: ModuleNames15.RichSelectModule,
  beans: [],
  userComponents: [
    { name: "agRichSelect", classImp: RichSelectCellEditor },
    { name: "agRichSelectCellEditor", classImp: RichSelectCellEditor }
  ],
  dependantModules: [EnterpriseCoreModule, _EditCoreModule]
});

// enterprise-modules/server-side-row-model/dist/package/main.esm.mjs
import {
  ModuleNames as ModuleNames25,
  RowModelHelperService,
  _CsrmSsrmSharedApiModule,
  _RowNodeBlockModule,
  _SsrmInfiniteSharedApiModule,
  _defineModule as _defineModule15
} from "ag-grid-community";
import {
  BeanStub as BeanStub30,
  RowNode as RowNode3,
  _doOnce,
  _exists as _exists16,
  _getGroupTotalRowCallback,
  _getRowHeightAsNumber,
  _getRowHeightForNode,
  _missing as _missing6,
  _warnOnce as _warnOnce37
} from "ag-grid-community";
import {
  BeanStub as BeanStub210,
  RowNode as RowNode22,
  _exists as _exists26,
  _getRowHeightForNode as _getRowHeightForNode2,
  _isServerSideRowModel as _isServerSideRowModel3,
  _missing as _missing24
} from "ag-grid-community";
import { BeanStub as BeanStub36, _isServerSideRowModel as _isServerSideRowModel22 } from "ag-grid-community";
import { BeanStub as BeanStub46 } from "ag-grid-community";
import { BeanStub as BeanStub56, _isServerSideRowModel as _isServerSideRowModel32 } from "ag-grid-community";
import { BeanStub as BeanStub65, _warnOnce as _warnOnce211 } from "ag-grid-community";
import {
  BeanStub as BeanStub103,
  ModuleNames as ModuleNames16,
  NumberSequence as NumberSequence3,
  RowNode as RowNode32,
  _debounce as _debounce4,
  _errorOnce as _errorOnce22,
  _getRowHeightAsNumber as _getRowHeightAsNumber4,
  _getRowHeightForNode as _getRowHeightForNode3,
  _isGetRowHeightFunction,
  _isRowSelection,
  _jsonEquals,
  _warnOnce as _warnOnce63
} from "ag-grid-community";
import {
  NumberSequence,
  RowNodeBlock,
  ServerSideTransactionResultStatus,
  _errorOnce as _errorOnce5,
  _getAllValuesInObject,
  _getRowIdCallback,
  _insertIntoArray,
  _missing as _missing32,
  _missingOrEmpty as _missingOrEmpty5,
  _warnOnce as _warnOnce38
} from "ag-grid-community";
import {
  BeanStub as BeanStub93,
  NumberSequence as NumberSequence2,
  ServerSideTransactionResultStatus as ServerSideTransactionResultStatus2,
  _getGroupTotalRowCallback as _getGroupTotalRowCallback2,
  _getRowHeightAsNumber as _getRowHeightAsNumber3,
  _getRowIdCallback as _getRowIdCallback3,
  _warnOnce as _warnOnce53
} from "ag-grid-community";
import { BeanStub as BeanStub83, _getRowHeightAsNumber as _getRowHeightAsNumber2, _getRowIdCallback as _getRowIdCallback2, _warnOnce as _warnOnce45 } from "ag-grid-community";
import { BeanStub as BeanStub75 } from "ag-grid-community";
import { _warnOnce as _warnOnce73 } from "ag-grid-community";
import { ExpansionService } from "ag-grid-community";
import {
  BeanStub as BeanStub132,
  _getGroupSelectsDescendants,
  _getRowSelectionMode,
  _isUsingNewRowSelectionAPI as _isUsingNewRowSelectionAPI2,
  _warnOnce as _warnOnce102
} from "ag-grid-community";
import {
  BeanStub as BeanStub113,
  _errorOnce as _errorOnce32,
  _isMultiRowSelection,
  _isUsingNewRowSelectionAPI,
  _last as _last12,
  _warnOnce as _warnOnce82,
  isSelectionUIEvent
} from "ag-grid-community";
import {
  BeanStub as BeanStub122,
  _errorOnce as _errorOnce42,
  _isMultiRowSelection as _isMultiRowSelection2,
  _last as _last23,
  _warnOnce as _warnOnce92,
  isSelectionUIEvent as isSelectionUIEvent2
} from "ag-grid-community";
import { BeanStub as BeanStub142, _warnOnce as _warnOnce112 } from "ag-grid-community";
import { BeanStub as BeanStub152, _isServerSideRowModel as _isServerSideRowModel4, _missingOrEmpty as _missingOrEmpty22, _warnOnce as _warnOnce122 } from "ag-grid-community";
import { BeanStub as BeanStub162, ServerSideTransactionResultStatus as ServerSideTransactionResultStatus3, _isServerSideRowModel as _isServerSideRowModel5 } from "ag-grid-community";
var GROUP_MISSING_KEY_ID = "ag-Grid-MissingKey";
var BlockUtils = class extends BeanStub30 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmBlockUtils";
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.showRowGroupColsService = beans.showRowGroupColsService;
    this.nodeManager = beans.ssrmNodeManager;
    this.beans = beans;
    this.expansionService = beans.expansionService;
    this.serverSideRowModel = beans.rowModel;
    this.storeFactory = beans.ssrmStoreFactory;
  }
  createRowNode(params) {
    const rowNode = new RowNode3(this.beans);
    const rowHeight = params.rowHeight != null ? params.rowHeight : _getRowHeightAsNumber(this.gos);
    rowNode.setRowHeight(rowHeight);
    rowNode.group = params.group;
    rowNode.leafGroup = params.leafGroup;
    rowNode.level = params.level;
    rowNode.uiLevel = params.level;
    rowNode.parent = params.parent;
    rowNode.stub = true;
    rowNode.__needsRefreshWhenVisible = false;
    if (rowNode.group) {
      rowNode.expanded = false;
      rowNode.field = params.field;
      rowNode.rowGroupColumn = params.rowGroupColumn;
    }
    return rowNode;
  }
  destroyRowNodes(rowNodes) {
    if (rowNodes) {
      rowNodes.forEach((row) => this.destroyRowNode(row));
    }
  }
  destroyRowNode(rowNode, preserveStore = false) {
    if (rowNode.childStore && !preserveStore) {
      this.destroyBean(rowNode.childStore);
      rowNode.childStore = null;
    }
    if (rowNode.sibling && !rowNode.footer) {
      this.destroyRowNode(rowNode.sibling, false);
    }
    rowNode.clearRowTopAndRowIndex();
    if (rowNode.id != null) {
      this.nodeManager.removeNode(rowNode);
    }
  }
  setTreeGroupInfo(rowNode) {
    rowNode.updateHasChildren();
    const getKeyFunc = this.gos.get("getServerSideGroupKey");
    if (rowNode.hasChildren() && getKeyFunc != null) {
      rowNode.key = getKeyFunc(rowNode.data);
    }
    if (!rowNode.hasChildren() && rowNode.childStore != null) {
      this.destroyBean(rowNode.childStore);
      rowNode.childStore = null;
      rowNode.expanded = false;
    }
  }
  setRowGroupInfo(rowNode) {
    rowNode.key = this.valueService.getValue(rowNode.rowGroupColumn, rowNode);
    if (rowNode.key === null || rowNode.key === void 0) {
      _doOnce(() => {
        _warnOnce37(`null and undefined values are not allowed for server side row model keys`);
        if (rowNode.rowGroupColumn) {
          _warnOnce37(`column = ${rowNode.rowGroupColumn.getId()}`);
        }
        _warnOnce37(`data is ` + rowNode.data);
      }, "ServerSideBlock-CannotHaveNullOrUndefinedForKey");
    }
    const isUnbalancedGroup = this.gos.get("groupAllowUnbalanced") && rowNode.key === "";
    if (isUnbalancedGroup) {
      const storeParams = this.serverSideRowModel.getParams();
      rowNode.childStore = this.createBean(this.storeFactory.createStore(storeParams, rowNode));
    }
    const getGroupIncludeFooter = _getGroupTotalRowCallback(this.beans.gos);
    const doesRowShowFooter = getGroupIncludeFooter({ node: rowNode });
    if (doesRowShowFooter) {
      rowNode.createFooter();
      if (rowNode.sibling) {
        rowNode.sibling.uiLevel = rowNode.uiLevel + 1;
      }
    }
  }
  setMasterDetailInfo(rowNode) {
    const isMasterFunc = this.gos.get("isRowMaster");
    if (isMasterFunc != null) {
      rowNode.master = isMasterFunc(rowNode.data);
    } else {
      rowNode.master = true;
    }
  }
  updateDataIntoRowNode(rowNode, data) {
    rowNode.updateData(data);
    if (this.gos.get("treeData")) {
      this.setTreeGroupInfo(rowNode);
      this.setChildCountIntoRowNode(rowNode);
    } else if (rowNode.group) {
      this.setChildCountIntoRowNode(rowNode);
      if (!rowNode.footer) {
        const getGroupIncludeFooter = _getGroupTotalRowCallback(this.beans.gos);
        const doesRowShowFooter = getGroupIncludeFooter({ node: rowNode });
        if (doesRowShowFooter) {
          if (rowNode.sibling) {
            rowNode.sibling.updateData(data);
          } else {
            rowNode.createFooter();
          }
        } else if (rowNode.sibling) {
          rowNode.destroyFooter();
        }
      }
    } else if (this.gos.get("masterDetail")) {
    }
  }
  setDataIntoRowNode(rowNode, data, defaultId, cachedRowHeight) {
    rowNode.stub = false;
    const treeData = this.gos.get("treeData");
    if (_exists16(data)) {
      rowNode.setDataAndId(data, defaultId);
      if (treeData) {
        this.setTreeGroupInfo(rowNode);
      } else if (rowNode.group) {
        this.setRowGroupInfo(rowNode);
      } else if (this.gos.get("masterDetail")) {
        this.setMasterDetailInfo(rowNode);
      }
    } else {
      rowNode.setDataAndId(void 0, void 0);
      rowNode.key = null;
    }
    if (treeData || rowNode.group) {
      this.setGroupDataIntoRowNode(rowNode);
      this.setChildCountIntoRowNode(rowNode);
    }
    if (_exists16(data)) {
      rowNode.setRowHeight(_getRowHeightForNode(this.gos, rowNode, false, cachedRowHeight).height);
      rowNode.sibling?.setRowHeight(
        _getRowHeightForNode(this.gos, rowNode.sibling, false, cachedRowHeight).height
      );
    }
  }
  setChildCountIntoRowNode(rowNode) {
    const getChildCount = this.gos.get("getChildCount");
    if (getChildCount) {
      rowNode.setAllChildrenCount(getChildCount(rowNode.data));
    }
  }
  setGroupDataIntoRowNode(rowNode) {
    const groupDisplayCols = this.showRowGroupColsService?.getShowRowGroupCols() ?? [];
    const usingTreeData = this.gos.get("treeData");
    groupDisplayCols.forEach((col) => {
      if (rowNode.groupData == null) {
        rowNode.groupData = {};
      }
      if (usingTreeData) {
        rowNode.groupData[col.getColId()] = rowNode.key;
      } else if (col.isRowGroupDisplayed(rowNode.rowGroupColumn.getId())) {
        const groupValue = this.valueService.getValue(rowNode.rowGroupColumn, rowNode);
        rowNode.groupData[col.getColId()] = groupValue;
      }
    });
  }
  clearDisplayIndex(rowNode) {
    rowNode.clearRowTopAndRowIndex();
    const hasChildStore = rowNode.hasChildren() && _exists16(rowNode.childStore);
    if (hasChildStore) {
      const childStore = rowNode.childStore;
      childStore.clearDisplayIndexes();
    }
    const hasDetailNode = rowNode.master && rowNode.detailNode;
    if (hasDetailNode) {
      rowNode.detailNode.clearRowTopAndRowIndex();
    }
  }
  setDisplayIndex(rowNode, displayIndexSeq, nextRowTop, uiLevel) {
    const isUnbalancedGroup = this.gos.get("groupAllowUnbalanced") && rowNode.group && rowNode.key === "";
    const isHiddenOpenGroup = this.gos.get("groupHideOpenParents") && rowNode.group && rowNode.expanded;
    if (isHiddenOpenGroup || isUnbalancedGroup) {
      rowNode.setRowIndex(null);
      rowNode.setRowTop(null);
    } else {
      rowNode.setRowIndex(displayIndexSeq.next());
      rowNode.setRowTop(nextRowTop.value);
      nextRowTop.value += rowNode.rowHeight;
    }
    rowNode.setUiLevel(uiLevel);
    if (rowNode.footer) {
      return;
    }
    const hasDetailRow = rowNode.master;
    if (hasDetailRow) {
      if (rowNode.expanded && rowNode.detailNode) {
        rowNode.detailNode.setRowIndex(displayIndexSeq.next());
        rowNode.detailNode.setRowTop(nextRowTop.value);
        nextRowTop.value += rowNode.detailNode.rowHeight;
      } else if (rowNode.detailNode) {
        rowNode.detailNode.clearRowTopAndRowIndex();
      }
    }
    const hasChildStore = rowNode.hasChildren() && _exists16(rowNode.childStore);
    if (hasChildStore) {
      const childStore = rowNode.childStore;
      if (rowNode.expanded || isUnbalancedGroup) {
        childStore.setDisplayIndexes(displayIndexSeq, nextRowTop, isUnbalancedGroup ? uiLevel : uiLevel + 1);
      } else {
        childStore.clearDisplayIndexes();
      }
    }
  }
  binarySearchForDisplayIndex(displayRowIndex, rowNodes) {
    let bottomPointer = 0;
    let topPointer = rowNodes.length - 1;
    if (_missing6(topPointer) || _missing6(bottomPointer)) {
      _warnOnce37(`error: topPointer = ${topPointer}, bottomPointer = ${bottomPointer}`);
      return void 0;
    }
    while (true) {
      const midPointer = Math.floor((bottomPointer + topPointer) / 2);
      const currentRowNode = rowNodes[midPointer];
      if (currentRowNode.rowIndex === displayRowIndex) {
        return currentRowNode;
      }
      const expandedMasterRow = currentRowNode.master && currentRowNode.expanded;
      const detailNode = currentRowNode.detailNode;
      if (expandedMasterRow && detailNode && detailNode.rowIndex === displayRowIndex) {
        return currentRowNode.detailNode;
      }
      const childStore = currentRowNode.childStore;
      if (currentRowNode.expanded && childStore && childStore.isDisplayIndexInStore(displayRowIndex)) {
        return childStore.getRowUsingDisplayIndex(displayRowIndex);
      }
      if (currentRowNode.rowIndex < displayRowIndex) {
        bottomPointer = midPointer + 1;
      } else if (currentRowNode.rowIndex > displayRowIndex) {
        topPointer = midPointer - 1;
      } else {
        _warnOnce37(`error: unable to locate rowIndex = ${displayRowIndex} in cache`);
        return void 0;
      }
    }
  }
  extractRowBounds(rowNode, index) {
    const extractRowBounds = (currentRowNode) => ({
      rowHeight: currentRowNode.rowHeight,
      rowTop: currentRowNode.rowTop
    });
    if (rowNode.rowIndex === index) {
      return extractRowBounds(rowNode);
    }
    if (rowNode.hasChildren() && rowNode.expanded && _exists16(rowNode.childStore)) {
      const childStore = rowNode.childStore;
      if (childStore.isDisplayIndexInStore(index)) {
        return childStore.getRowBounds(index);
      }
    } else if (rowNode.master && rowNode.expanded && _exists16(rowNode.detailNode)) {
      if (rowNode.detailNode.rowIndex === index) {
        return extractRowBounds(rowNode.detailNode);
      }
    }
  }
  isPixelInNodeRange(node, pixel) {
    if (!_exists16(node.rowTop) || !_exists16(node.rowHeight)) {
      return false;
    }
    return pixel >= node.rowTop && pixel < node.rowTop + node.rowHeight;
  }
  getIndexAtPixel(rowNode, pixel) {
    if (this.isPixelInNodeRange(rowNode, pixel)) {
      return rowNode.rowIndex;
    }
    const expandedMasterRow = rowNode.master && rowNode.expanded;
    const detailNode = rowNode.detailNode;
    if (expandedMasterRow && detailNode && this.isPixelInNodeRange(detailNode, pixel)) {
      return rowNode.detailNode.rowIndex;
    }
    if (rowNode.hasChildren() && rowNode.expanded && _exists16(rowNode.childStore)) {
      const childStore = rowNode.childStore;
      if (childStore.isPixelInRange(pixel)) {
        return childStore.getRowIndexAtPixel(pixel);
      }
    }
    return null;
  }
  createNodeIdPrefix(parentRowNode) {
    const parts = [];
    let rowNode = parentRowNode;
    while (rowNode && rowNode.level >= 0) {
      if (rowNode.key === "") {
        parts.push(GROUP_MISSING_KEY_ID);
      } else {
        parts.push(rowNode.key);
      }
      rowNode = rowNode.parent;
    }
    if (parts.length > 0) {
      return parts.reverse().join("-");
    }
    return void 0;
  }
  checkOpenByDefault(rowNode) {
    return this.expansionService.checkOpenByDefault(rowNode);
  }
};
var ExpandListener = class extends BeanStub210 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmExpandListener";
  }
  wireBeans(beans) {
    this.serverSideRowModel = beans.rowModel;
    this.storeFactory = beans.ssrmStoreFactory;
    this.beans = beans;
  }
  postConstruct() {
    if (!_isServerSideRowModel3(this.gos)) {
      return;
    }
    this.addManagedEventListeners({ rowGroupOpened: this.onRowGroupOpened.bind(this) });
  }
  onRowGroupOpened(event) {
    const rowNode = event.node;
    if (rowNode.expanded) {
      if (rowNode.master) {
        this.createDetailNode(rowNode);
      } else if (_missing24(rowNode.childStore)) {
        const storeParams = this.serverSideRowModel.getParams();
        rowNode.childStore = this.createBean(this.storeFactory.createStore(storeParams, rowNode));
      }
    } else if (this.gos.get("purgeClosedRowNodes") && _exists26(rowNode.childStore)) {
      rowNode.childStore = this.destroyBean(rowNode.childStore);
    }
    this.eventService.dispatchEvent({ type: "storeUpdated" });
  }
  createDetailNode(masterNode) {
    if (_exists26(masterNode.detailNode)) {
      return masterNode.detailNode;
    }
    const detailNode = new RowNode22(this.beans);
    detailNode.detail = true;
    detailNode.selectable = false;
    detailNode.parent = masterNode;
    if (_exists26(masterNode.id)) {
      detailNode.id = "detail_" + masterNode.id;
    }
    detailNode.data = masterNode.data;
    detailNode.level = masterNode.level + 1;
    const defaultDetailRowHeight = 200;
    const rowHeight = _getRowHeightForNode2(this.gos, detailNode).height;
    detailNode.rowHeight = rowHeight ? rowHeight : defaultDetailRowHeight;
    masterNode.detailNode = detailNode;
    return detailNode;
  }
};
var FilterListener = class extends BeanStub36 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmFilterListener";
  }
  wireBeans(beans) {
    this.serverSideRowModel = beans.rowModel;
    this.filterManager = beans.filterManager;
    this.listenerUtils = beans.ssrmListenerUtils;
  }
  postConstruct() {
    if (!_isServerSideRowModel22(this.gos)) {
      return;
    }
    this.addManagedEventListeners({
      advancedFilterEnabledChanged: () => this.onFilterChanged(true),
      filterChanged: () => this.onFilterChanged()
    });
  }
  onFilterChanged(advancedFilterEnabledChanged) {
    const storeParams = this.serverSideRowModel.getParams();
    if (!storeParams) {
      return;
    }
    const oldModel = storeParams.filterModel;
    let newModel;
    let changedColumns;
    if (this.filterManager?.isAdvancedFilterEnabled()) {
      newModel = this.filterManager.getAdvancedFilterModel();
      const oldColumns = advancedFilterEnabledChanged ? Object.keys(oldModel ?? {}) : this.getAdvancedFilterColumns(oldModel);
      const newColumns = this.getAdvancedFilterColumns(newModel);
      oldColumns.forEach((column) => newColumns.add(column));
      changedColumns = Array.from(newColumns);
    } else {
      newModel = this.filterManager?.getFilterModel() ?? {};
      if (advancedFilterEnabledChanged) {
        const oldColumns = this.getAdvancedFilterColumns(oldModel);
        Object.keys(newModel).forEach((column) => oldColumns.add(column));
        changedColumns = Array.from(oldColumns);
      } else {
        changedColumns = this.findChangedColumns(oldModel, newModel);
      }
    }
    const valueColChanged = this.listenerUtils.isSortingWithValueColumn(changedColumns);
    const secondaryColChanged = this.listenerUtils.isSortingWithSecondaryColumn(changedColumns);
    const params = {
      valueColChanged,
      secondaryColChanged,
      changedColumns
    };
    this.serverSideRowModel.refreshAfterFilter(newModel, params);
  }
  findChangedColumns(oldModel, newModel) {
    const allColKeysMap = {};
    Object.keys(oldModel).forEach((key) => allColKeysMap[key] = true);
    Object.keys(newModel).forEach((key) => allColKeysMap[key] = true);
    const res = [];
    Object.keys(allColKeysMap).forEach((key) => {
      const oldJson = JSON.stringify(oldModel[key]);
      const newJson = JSON.stringify(newModel[key]);
      const filterChanged = oldJson != newJson;
      if (filterChanged) {
        res.push(key);
      }
    });
    return res;
  }
  getAdvancedFilterColumns(model) {
    const columns = /* @__PURE__ */ new Set();
    if (!model) {
      return columns;
    }
    const processAdvancedFilterModel = (filterModel) => {
      if (filterModel.filterType === "join") {
        filterModel.conditions.forEach((condition) => processAdvancedFilterModel(condition));
      } else {
        columns.add(filterModel.colId);
      }
    };
    processAdvancedFilterModel(model);
    return columns;
  }
};
var ListenerUtils = class extends BeanStub46 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmListenerUtils";
  }
  wireBeans(beans) {
    this.pivotResultColsService = beans.pivotResultColsService;
    this.funcColsService = beans.funcColsService;
  }
  isSortingWithValueColumn(changedColumnsInSort) {
    const valueColIds = this.funcColsService.getValueColumns().map((col) => col.getColId());
    for (let i = 0; i < changedColumnsInSort.length; i++) {
      if (valueColIds.indexOf(changedColumnsInSort[i]) > -1) {
        return true;
      }
    }
    return false;
  }
  isSortingWithSecondaryColumn(changedColumnsInSort) {
    const pivotResultCols = this.pivotResultColsService.getPivotResultCols();
    if (!pivotResultCols) {
      return false;
    }
    const secondaryColIds = pivotResultCols.list.map((col) => col.getColId());
    for (let i = 0; i < changedColumnsInSort.length; i++) {
      if (secondaryColIds.indexOf(changedColumnsInSort[i]) > -1) {
        return true;
      }
    }
    return false;
  }
};
var SortListener = class extends BeanStub56 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmSortService";
  }
  wireBeans(beans) {
    this.sortController = beans.sortController;
    this.serverSideRowModel = beans.rowModel;
    this.listenerUtils = beans.ssrmListenerUtils;
  }
  postConstruct() {
    if (!_isServerSideRowModel32(this.gos)) {
      return;
    }
    this.addManagedEventListeners({ sortChanged: this.onSortChanged.bind(this) });
  }
  onSortChanged() {
    const storeParams = this.serverSideRowModel.getParams();
    if (!storeParams) {
      return;
    }
    const newSortModel = this.sortController.getSortModel();
    const oldSortModel = storeParams.sortModel;
    const changedColumns = this.findChangedColumnsInSort(newSortModel, oldSortModel);
    const valueColChanged = this.listenerUtils.isSortingWithValueColumn(changedColumns);
    const secondaryColChanged = this.listenerUtils.isSortingWithSecondaryColumn(changedColumns);
    const params = {
      valueColChanged,
      secondaryColChanged,
      changedColumns
    };
    this.serverSideRowModel.refreshAfterSort(newSortModel, params);
  }
  // returns back all the cols that were effected by the sorting. eg if we were sorting by col A,
  // and now we are sorting by col B, the list of impacted cols should be A and B. so if a cache
  // is impacted by sorting on A or B then it needs to be refreshed. this includes where the cache
  // was previously sorted by A and then the A sort now needs to be cleared.
  findChangedColumnsInSort(newSortModel, oldSortModel) {
    let allColsInBothSorts = [];
    [newSortModel, oldSortModel].forEach((sortModel) => {
      if (sortModel) {
        const ids = sortModel.map((sm) => sm.colId);
        allColsInBothSorts = allColsInBothSorts.concat(ids);
      }
    });
    const differentSorts = (oldSortItem, newSortItem) => {
      const oldSort = oldSortItem ? oldSortItem.sort : null;
      const newSort = newSortItem ? newSortItem.sort : null;
      return oldSort !== newSort;
    };
    const differentIndexes = (oldSortItem, newSortItem) => {
      const oldIndex = oldSortItem ? oldSortModel.indexOf(oldSortItem) : -1;
      const newIndex = newSortItem ? newSortModel.indexOf(newSortItem) : -1;
      return oldIndex !== newIndex;
    };
    return allColsInBothSorts.filter((colId) => {
      const oldSortItem = oldSortModel.find((sm) => sm.colId === colId);
      const newSortItem = newSortModel.find((sm) => sm.colId === colId);
      return differentSorts(oldSortItem, newSortItem) || differentIndexes(oldSortItem, newSortItem);
    });
  }
};
var NodeManager = class extends BeanStub65 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmNodeManager";
    this.rowNodes = {};
  }
  addRowNode(rowNode) {
    const id = rowNode.id;
    if (this.rowNodes[id]) {
      _warnOnce211(
        `Duplicate node id ${rowNode.id}. Row IDs are provided via the getRowId() callback. Please modify the getRowId() callback code to provide unique row id values.`
      );
      _warnOnce211("first instance", this.rowNodes[id].data);
      _warnOnce211("second instance", rowNode.data);
    }
    this.rowNodes[id] = rowNode;
  }
  removeNode(rowNode) {
    const id = rowNode.id;
    if (this.rowNodes[id]) {
      this.rowNodes[id] = void 0;
    }
  }
  destroy() {
    this.clear();
    super.destroy();
  }
  clear() {
    this.rowNodes = {};
    super.destroy();
  }
};
var FullStore = class extends RowNodeBlock {
  constructor(ssrmParams, storeParams, parentRowNode) {
    super(0);
    this.nodeIdSequence = new NumberSequence();
    this.info = {};
    this.ssrmParams = ssrmParams;
    this.parentRowNode = parentRowNode;
    this.level = parentRowNode.level + 1;
    this.groupLevel = ssrmParams.rowGroupCols ? this.level < ssrmParams.rowGroupCols.length : void 0;
    this.leafGroup = ssrmParams.rowGroupCols ? this.level === ssrmParams.rowGroupCols.length - 1 : false;
  }
  wireBeans(beans) {
    this.storeUtils = beans.ssrmStoreUtils;
    this.blockUtils = beans.ssrmBlockUtils;
    this.funcColsService = beans.funcColsService;
    this.rowNodeBlockLoader = beans.rowNodeBlockLoader;
    this.rowNodeSorter = beans.rowNodeSorter;
    this.sortController = beans.sortController;
    this.selectionService = beans.selectionService;
    this.nodeManager = beans.ssrmNodeManager;
    this.filterManager = beans.filterManager;
    this.transactionManager = beans.ssrmTransactionManager;
    this.serverSideRowModel = beans.rowModel;
  }
  postConstruct() {
    this.usingTreeData = this.gos.get("treeData");
    this.nodeIdPrefix = this.blockUtils.createNodeIdPrefix(this.parentRowNode);
    if (!this.usingTreeData && this.groupLevel) {
      const groupColVo = this.ssrmParams.rowGroupCols[this.level];
      this.groupField = groupColVo.field;
      this.rowGroupColumn = this.funcColsService.getRowGroupColumns()[this.level];
    }
    let initialRowCount = 1;
    const isRootStore = this.parentRowNode.level === -1;
    const userInitialRowCount = this.storeUtils.getServerSideInitialRowCount();
    if (isRootStore && userInitialRowCount != null) {
      initialRowCount = userInitialRowCount;
    }
    this.initialiseRowNodes(initialRowCount);
    this.rowNodeBlockLoader.addBlock(this);
    this.addDestroyFunc(() => this.rowNodeBlockLoader.removeBlock(this));
    this.postSortFunc = this.gos.getCallback("postSortRows");
    if (userInitialRowCount != null) {
      this.eventService.dispatchEventOnce({
        type: "rowCountReady"
      });
    }
  }
  destroy() {
    this.destroyRowNodes();
    super.destroy();
  }
  destroyRowNodes() {
    this.blockUtils.destroyRowNodes(this.allRowNodes);
    this.allRowNodes = [];
    this.nodesAfterSort = [];
    this.nodesAfterFilter = [];
    this.allNodesMap = {};
  }
  initialiseRowNodes(loadingRowsCount, failedLoad = false) {
    this.destroyRowNodes();
    for (let i = 0; i < loadingRowsCount; i++) {
      const loadingRowNode = this.blockUtils.createRowNode({
        field: this.groupField,
        group: this.groupLevel,
        leafGroup: this.leafGroup,
        level: this.level,
        parent: this.parentRowNode,
        rowGroupColumn: this.rowGroupColumn
      });
      if (failedLoad) {
        loadingRowNode.failedLoad = true;
      }
      this.allRowNodes.push(loadingRowNode);
      this.nodesAfterFilter.push(loadingRowNode);
      this.nodesAfterSort.push(loadingRowNode);
    }
  }
  getBlockStateJson() {
    return {
      id: this.nodeIdPrefix ? this.nodeIdPrefix : "",
      state: this.getState()
    };
  }
  loadFromDatasource() {
    this.storeUtils.loadFromDatasource({
      startRow: void 0,
      endRow: void 0,
      parentBlock: this,
      parentNode: this.parentRowNode,
      storeParams: this.ssrmParams,
      success: this.success.bind(this, this.getVersion()),
      fail: this.pageLoadFailed.bind(this, this.getVersion())
    });
  }
  getStartRow() {
    return 0;
  }
  getEndRow() {
    return this.nodesAfterSort.length;
  }
  createDataNode(data, index) {
    const rowNode = this.blockUtils.createRowNode({
      field: this.groupField,
      group: this.groupLevel,
      leafGroup: this.leafGroup,
      level: this.level,
      parent: this.parentRowNode,
      rowGroupColumn: this.rowGroupColumn
    });
    if (index != null) {
      _insertIntoArray(this.allRowNodes, rowNode, index);
    } else {
      this.allRowNodes.push(rowNode);
    }
    const defaultId = this.prefixId(this.nodeIdSequence.next());
    this.blockUtils.setDataIntoRowNode(rowNode, data, defaultId, void 0);
    this.nodeManager.addRowNode(rowNode);
    this.blockUtils.checkOpenByDefault(rowNode);
    this.allNodesMap[rowNode.id] = rowNode;
    return rowNode;
  }
  prefixId(id) {
    if (this.nodeIdPrefix) {
      return this.nodeIdPrefix + "-" + id;
    } else {
      return id.toString();
    }
  }
  processServerFail() {
    this.initialiseRowNodes(1, true);
    this.fireStoreUpdatedEvent();
    this.flushAsyncTransactions();
  }
  processServerResult(params) {
    if (!this.isAlive()) {
      return;
    }
    const info = params.groupLevelInfo;
    if (info) {
      Object.assign(this.info, info);
    }
    if (params.pivotResultFields) {
      this.serverSideRowModel.generateSecondaryColumns(params.pivotResultFields);
    }
    const nodesToRecycle = this.allRowNodes.length > 0 ? this.allNodesMap : void 0;
    this.allRowNodes = [];
    this.nodesAfterSort = [];
    this.nodesAfterFilter = [];
    this.allNodesMap = {};
    if (!params.rowData) {
      _warnOnce38(
        '"params.data" is missing from Server-Side Row Model success() callback. Please use the "data" attribute. If no data is returned, set an empty list.'
      );
    }
    this.createOrRecycleNodes(nodesToRecycle, params.rowData);
    if (nodesToRecycle) {
      this.blockUtils.destroyRowNodes(_getAllValuesInObject(nodesToRecycle));
    }
    if (this.level === 0) {
      this.eventService.dispatchEventOnce({
        type: "rowCountReady"
      });
    }
    this.filterAndSortNodes();
    this.fireStoreUpdatedEvent();
    this.flushAsyncTransactions();
  }
  createOrRecycleNodes(nodesToRecycle, rowData) {
    if (!rowData) {
      return;
    }
    const lookupNodeToRecycle = (data) => {
      if (!nodesToRecycle) {
        return void 0;
      }
      const getRowIdFunc = _getRowIdCallback(this.gos);
      if (!getRowIdFunc) {
        return void 0;
      }
      const parentKeys = this.parentRowNode.getRoute() ?? [];
      const level = this.level;
      const id = getRowIdFunc({
        data,
        parentKeys: parentKeys.length > 0 ? parentKeys : void 0,
        level
      });
      const foundNode = nodesToRecycle[id];
      if (!foundNode) {
        return void 0;
      }
      delete nodesToRecycle[id];
      return foundNode;
    };
    const recycleNode = (rowNode, dataItem) => {
      this.allNodesMap[rowNode.id] = rowNode;
      this.blockUtils.updateDataIntoRowNode(rowNode, dataItem);
      this.allRowNodes.push(rowNode);
    };
    rowData.forEach((dataItem) => {
      const nodeToRecycle = lookupNodeToRecycle(dataItem);
      if (nodeToRecycle) {
        recycleNode(nodeToRecycle, dataItem);
      } else {
        this.createDataNode(dataItem);
      }
    });
  }
  flushAsyncTransactions() {
    window.setTimeout(() => this.transactionManager.flushAsyncTransactions(), 0);
  }
  filterAndSortNodes() {
    this.filterRowNodes();
    this.sortRowNodes();
  }
  sortRowNodes() {
    const serverIsSorting = this.storeUtils.isServerSideSortAllLevels() || this.storeUtils.isServerSideSortOnServer();
    const sortOptions = this.sortController.getSortOptions();
    const noSortApplied = !sortOptions || sortOptions.length == 0;
    if (serverIsSorting || noSortApplied) {
      this.nodesAfterSort = this.nodesAfterFilter;
      return;
    }
    this.nodesAfterSort = this.rowNodeSorter.doFullSort(this.nodesAfterFilter, sortOptions);
    if (this.postSortFunc) {
      const params = { nodes: this.nodesAfterSort };
      this.postSortFunc(params);
    }
  }
  filterRowNodes() {
    const serverIsFiltering = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups() || this.storeUtils.isServerSideFilterOnServer();
    const groupLevel = this.groupLevel;
    if (serverIsFiltering || groupLevel) {
      this.nodesAfterFilter = this.allRowNodes;
      return;
    }
    this.nodesAfterFilter = this.filterManager ? this.allRowNodes.filter((rowNode) => this.filterManager.doesRowPassFilter({ rowNode })) : this.allRowNodes;
  }
  clearDisplayIndexes() {
    this.displayIndexStart = void 0;
    this.displayIndexEnd = void 0;
    this.allRowNodes.forEach((rowNode) => this.blockUtils.clearDisplayIndex(rowNode));
  }
  getDisplayIndexEnd() {
    return this.displayIndexEnd;
  }
  isDisplayIndexInStore(displayIndex) {
    if (this.getRowCount() === 0) {
      return false;
    }
    return displayIndex >= this.displayIndexStart && displayIndex < this.displayIndexEnd;
  }
  setDisplayIndexes(displayIndexSeq, nextRowTop, uiLevel) {
    this.displayIndexStart = displayIndexSeq.peek();
    this.topPx = nextRowTop.value;
    const visibleNodeIds = {};
    this.nodesAfterSort.forEach((rowNode) => {
      this.blockUtils.setDisplayIndex(rowNode, displayIndexSeq, nextRowTop, uiLevel);
      visibleNodeIds[rowNode.id] = true;
    });
    this.allRowNodes.forEach((rowNode) => {
      if (!visibleNodeIds[rowNode.id]) {
        this.blockUtils.clearDisplayIndex(rowNode);
      }
    });
    this.displayIndexEnd = displayIndexSeq.peek();
    this.heightPx = nextRowTop.value - this.topPx;
  }
  forEachStoreDeep(callback, sequence = new NumberSequence()) {
    callback(this, sequence.next());
    this.allRowNodes.forEach((rowNode) => {
      const childCache = rowNode.childStore;
      if (childCache) {
        childCache.forEachStoreDeep(callback, sequence);
      }
    });
  }
  forEachNodeDeep(callback, sequence = new NumberSequence()) {
    this.allRowNodes.forEach((rowNode) => {
      callback(rowNode, sequence.next());
      const childCache = rowNode.childStore;
      if (childCache) {
        childCache.forEachNodeDeep(callback, sequence);
      }
    });
  }
  forEachNodeDeepAfterFilterAndSort(callback, sequence = new NumberSequence(), includeFooterNodes = false) {
    this.nodesAfterSort.forEach((rowNode) => {
      callback(rowNode, sequence.next());
      const childCache = rowNode.childStore;
      if (childCache) {
        childCache.forEachNodeDeepAfterFilterAndSort(callback, sequence, includeFooterNodes);
      }
    });
    if (includeFooterNodes && this.parentRowNode.sibling) {
      callback(this.parentRowNode.sibling, sequence.next());
    }
  }
  getRowUsingDisplayIndex(displayRowIndex) {
    if (!this.isDisplayIndexInStore(displayRowIndex)) {
      return void 0;
    }
    const res = this.blockUtils.binarySearchForDisplayIndex(displayRowIndex, this.nodesAfterSort);
    return res;
  }
  getRowBounds(index) {
    for (let i = 0; i < this.nodesAfterSort.length; i++) {
      const rowNode = this.nodesAfterSort[i];
      const res = this.blockUtils.extractRowBounds(rowNode, index);
      if (res) {
        return res;
      }
    }
    return null;
  }
  isPixelInRange(pixel) {
    return pixel >= this.topPx && pixel < this.topPx + this.heightPx;
  }
  getRowIndexAtPixel(pixel) {
    const pixelBeforeThisStore = pixel <= this.topPx;
    if (pixelBeforeThisStore) {
      const firstNode = this.nodesAfterSort[0];
      return firstNode.rowIndex;
    }
    const pixelAfterThisStore = pixel >= this.topPx + this.heightPx;
    if (pixelAfterThisStore) {
      const lastRowNode = this.nodesAfterSort[this.nodesAfterSort.length - 1];
      if (!lastRowNode) {
        return null;
      }
      const lastRowNodeBottomPx = lastRowNode.rowTop + lastRowNode.rowHeight;
      if (pixel >= lastRowNodeBottomPx && lastRowNode.expanded) {
        if (lastRowNode.childStore && lastRowNode.childStore.getRowCount() > 0) {
          return lastRowNode.childStore.getRowIndexAtPixel(pixel);
        }
        if (lastRowNode.detailNode) {
          return lastRowNode.detailNode.rowIndex;
        }
      }
      return lastRowNode.rowIndex;
    }
    let res = null;
    this.nodesAfterSort.forEach((rowNode) => {
      const res2 = this.blockUtils.getIndexAtPixel(rowNode, pixel);
      if (res2 != null) {
        res = res2;
      }
    });
    const pixelIsPastLastRow = res == null;
    if (pixelIsPastLastRow) {
      return this.displayIndexEnd - 1;
    }
    return res;
  }
  getChildStore(keys) {
    return this.storeUtils.getChildStore(keys, this, (key) => {
      const rowNode = this.allRowNodes.find((currentRowNode) => {
        return currentRowNode.key == key;
      });
      return rowNode;
    });
  }
  forEachChildStoreShallow(callback) {
    this.allRowNodes.forEach((rowNode) => {
      const childStore = rowNode.childStore;
      if (childStore) {
        callback(childStore);
      }
    });
  }
  refreshAfterFilter(params) {
    const serverIsFiltering = this.storeUtils.isServerSideFilterOnServer();
    const storeIsImpacted = this.storeUtils.isServerRefreshNeeded(
      this.parentRowNode,
      this.ssrmParams.rowGroupCols,
      params
    );
    const serverIsFilteringAllLevels = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups();
    if (serverIsFilteringAllLevels || serverIsFiltering && storeIsImpacted) {
      this.refreshStore(true);
      this.sortRowNodes();
      return;
    }
    this.filterRowNodes();
    this.sortRowNodes();
    this.forEachChildStoreShallow((store) => store.refreshAfterFilter(params));
  }
  refreshAfterSort(params) {
    const serverIsSorting = this.storeUtils.isServerSideSortOnServer();
    const storeIsImpacted = this.storeUtils.isServerRefreshNeeded(
      this.parentRowNode,
      this.ssrmParams.rowGroupCols,
      params
    );
    const serverIsSortingAllLevels = this.storeUtils.isServerSideSortAllLevels();
    if (serverIsSortingAllLevels || serverIsSorting && storeIsImpacted) {
      this.refreshStore(true);
      this.filterRowNodes();
      return;
    }
    this.filterRowNodes();
    this.sortRowNodes();
    this.forEachChildStoreShallow((store) => store.refreshAfterSort(params));
  }
  applyTransaction(transaction) {
    switch (this.getState()) {
      case "failed":
        return { status: ServerSideTransactionResultStatus.StoreLoadingFailed };
      case "loading":
        return { status: ServerSideTransactionResultStatus.StoreLoading };
      case "needsLoading":
        return { status: ServerSideTransactionResultStatus.StoreWaitingToLoad };
    }
    const applyCallback = this.gos.getCallback("isApplyServerSideTransaction");
    if (applyCallback) {
      const params = {
        transaction,
        parentNode: this.parentRowNode,
        groupLevelInfo: this.info
      };
      const apply = applyCallback(params);
      if (!apply) {
        return { status: ServerSideTransactionResultStatus.Cancelled };
      }
    }
    const res = {
      status: ServerSideTransactionResultStatus.Applied,
      remove: [],
      update: [],
      add: []
    };
    const nodesToUnselect = [];
    this.executeAdd(transaction, res);
    this.executeRemove(transaction, res, nodesToUnselect);
    this.executeUpdate(transaction, res, nodesToUnselect);
    this.filterAndSortNodes();
    this.updateSelection(nodesToUnselect);
    return res;
  }
  updateSelection(nodesToUnselect) {
    const selectionChanged = nodesToUnselect.length > 0;
    if (selectionChanged) {
      this.selectionService.setNodesSelected({
        newValue: false,
        nodes: nodesToUnselect,
        suppressFinishActions: true,
        clearSelection: false,
        source: "rowDataChanged"
      });
      this.eventService.dispatchEvent({
        type: "selectionChanged",
        source: "rowDataChanged"
      });
    }
  }
  executeAdd(rowDataTran, rowNodeTransaction) {
    const { add, addIndex } = rowDataTran;
    if (_missingOrEmpty5(add)) {
      return;
    }
    const useIndex = typeof addIndex === "number" && addIndex >= 0;
    if (useIndex) {
      add.reverse().forEach((item) => {
        const newRowNode = this.createDataNode(item, addIndex);
        rowNodeTransaction.add.push(newRowNode);
      });
    } else {
      add.forEach((item) => {
        const newRowNode = this.createDataNode(item);
        rowNodeTransaction.add.push(newRowNode);
      });
    }
  }
  executeRemove(rowDataTran, rowNodeTransaction, nodesToUnselect) {
    const { remove } = rowDataTran;
    if (remove == null) {
      return;
    }
    const rowIdsRemoved = {};
    remove.forEach((item) => {
      const rowNode = this.lookupRowNode(item);
      if (!rowNode) {
        return;
      }
      if (rowNode.isSelected()) {
        nodesToUnselect.push(rowNode);
      }
      rowNode.clearRowTopAndRowIndex();
      rowIdsRemoved[rowNode.id] = true;
      delete this.allNodesMap[rowNode.id];
      rowNodeTransaction.remove.push(rowNode);
      this.nodeManager.removeNode(rowNode);
    });
    this.allRowNodes = this.allRowNodes.filter((rowNode) => !rowIdsRemoved[rowNode.id]);
  }
  executeUpdate(rowDataTran, rowNodeTransaction, nodesToUnselect) {
    const { update } = rowDataTran;
    if (update == null) {
      return;
    }
    update.forEach((item) => {
      const rowNode = this.lookupRowNode(item);
      if (!rowNode) {
        return;
      }
      this.blockUtils.updateDataIntoRowNode(rowNode, item);
      if (!rowNode.selectable && rowNode.isSelected()) {
        nodesToUnselect.push(rowNode);
      }
      rowNodeTransaction.update.push(rowNode);
    });
  }
  lookupRowNode(data) {
    const getRowIdFunc = _getRowIdCallback(this.gos);
    if (getRowIdFunc != null) {
      const parentKeys = this.parentRowNode.getRoute() ?? [];
      const id = getRowIdFunc({
        data,
        parentKeys: parentKeys.length > 0 ? parentKeys : void 0,
        level: this.level
      });
      const rowNode = this.allNodesMap[id];
      if (!rowNode) {
        _errorOnce5(`could not find row id=${id}, data item was not found for this id`);
        return null;
      }
      return rowNode;
    } else {
      const rowNode = this.allRowNodes.find((currentRowNode) => currentRowNode.data === data);
      if (!rowNode) {
        _errorOnce5(`could not find data item as object was not found`, data);
        return null;
      }
      return rowNode;
    }
  }
  addStoreStates(result) {
    result.push({
      suppressInfiniteScroll: true,
      route: this.parentRowNode.getRoute() ?? [],
      rowCount: this.allRowNodes.length,
      info: this.info
    });
    this.forEachChildStoreShallow((childStore) => childStore.addStoreStates(result));
  }
  refreshStore(purge) {
    if (purge) {
      const loadingRowsToShow = this.nodesAfterSort ? this.nodesAfterSort.length : 1;
      this.initialiseRowNodes(loadingRowsToShow);
    }
    this.scheduleLoad();
    this.fireStoreUpdatedEvent();
  }
  retryLoads() {
    if (this.getState() === "failed") {
      this.initialiseRowNodes(1);
      this.scheduleLoad();
    }
    this.forEachChildStoreShallow((store) => store.retryLoads());
  }
  scheduleLoad() {
    this.setStateWaitingToLoad();
    this.rowNodeBlockLoader.checkBlockToLoad();
  }
  // gets called 1) row count changed 2) cache purged 3) items inserted
  fireStoreUpdatedEvent() {
    this.eventService.dispatchEvent({
      type: "storeUpdated"
    });
  }
  getRowCount() {
    return this.nodesAfterSort.length;
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const rowNode = this.nodesAfterSort[topLevelIndex];
    return rowNode.rowIndex;
  }
  isLastRowIndexKnown() {
    return this.getState() == "loaded";
  }
  getRowNodesInRange(firstInRange, lastInRange) {
    const result = [];
    let inActiveRange = false;
    if (_missing32(firstInRange)) {
      inActiveRange = true;
    }
    this.nodesAfterSort.forEach((rowNode) => {
      const hitFirstOrLast = rowNode === firstInRange || rowNode === lastInRange;
      if (inActiveRange || hitFirstOrLast) {
        result.push(rowNode);
      }
      if (hitFirstOrLast) {
        inActiveRange = !inActiveRange;
      }
    });
    const invalidRange = inActiveRange;
    return invalidRange ? [] : result;
  }
  getStoreBounds() {
    return {
      topPx: this.topPx,
      heightPx: this.heightPx
    };
  }
};
var LazyBlockLoadingService = class extends BeanStub75 {
  constructor() {
    super(...arguments);
    this.beanName = "lazyBlockLoadingService";
    this.cacheLoadingNodesMap = /* @__PURE__ */ new Map();
    this.isCheckQueued = false;
    this.nextBlockToLoad = void 0;
  }
  wireBeans(beans) {
    this.rowNodeBlockLoader = beans.rowNodeBlockLoader;
    this.rowRenderer = beans.rowRenderer;
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.addManagedListeners(this.rowNodeBlockLoader, { blockLoaded: () => this.queueLoadCheck() });
  }
  subscribe(cache) {
    this.cacheLoadingNodesMap.set(cache, /* @__PURE__ */ new Set());
  }
  unsubscribe(cache) {
    this.cacheLoadingNodesMap.delete(cache);
  }
  /**
   * Queues a microtask to check if any blocks need to be loaded.
   */
  queueLoadCheck() {
    if (this.isCheckQueued) {
      return;
    }
    this.isCheckQueued = true;
    window.queueMicrotask(() => {
      this.queueLoadAction();
      this.isCheckQueued = false;
    });
  }
  queueLoadAction() {
    const nextBlockToLoad = this.getBlockToLoad();
    if (!nextBlockToLoad) {
      return;
    }
    const isSameBlock = this.nextBlockToLoad && this.nextBlockToLoad.cache === nextBlockToLoad.cache && this.nextBlockToLoad.index === nextBlockToLoad.index;
    if (isSameBlock) {
      return;
    }
    if (!this.nextBlockToLoad || !isSameBlock) {
      this.nextBlockToLoad = nextBlockToLoad;
      window.clearTimeout(this.loaderTimeout);
      const startRow = Number(this.nextBlockToLoad.index);
      const cache = this.nextBlockToLoad.cache;
      const endRow = nextBlockToLoad.index + nextBlockToLoad.cache.getBlockSize();
      this.loaderTimeout = window.setTimeout(() => {
        if (!cache.isAlive()) {
          return;
        }
        this.loaderTimeout = void 0;
        this.attemptLoad(cache, startRow, endRow);
        this.nextBlockToLoad = void 0;
      }, this.gos.get("blockLoadDebounceMillis"));
    }
  }
  attemptLoad(cache, start, end) {
    const availableLoadingCount = this.rowNodeBlockLoader.getAvailableLoadingCount();
    if (availableLoadingCount != null && availableLoadingCount === 0) {
      return;
    }
    this.rowNodeBlockLoader.registerLoads(1);
    this.executeLoad(cache, start, end);
    this.queueLoadCheck();
  }
  executeLoad(cache, startRow, endRow) {
    const ssrmParams = cache.getSsrmParams();
    const request = {
      startRow,
      endRow,
      rowGroupCols: ssrmParams.rowGroupCols,
      valueCols: ssrmParams.valueCols,
      pivotCols: ssrmParams.pivotCols,
      pivotMode: ssrmParams.pivotMode,
      groupKeys: cache.store.getParentNode().getRoute() ?? [],
      filterModel: ssrmParams.filterModel,
      sortModel: ssrmParams.sortModel
    };
    const loadingNodes = this.cacheLoadingNodesMap.get(cache);
    const removeNodesFromLoadingMap = () => {
      for (let i = 0; i < endRow - startRow; i++) {
        loadingNodes.delete(startRow + i);
      }
    };
    const addNodesToLoadingMap = () => {
      for (let i = 0; i < endRow - startRow; i++) {
        loadingNodes.add(startRow + i);
      }
    };
    const success = (params2) => {
      this.rowNodeBlockLoader.loadComplete();
      cache.onLoadSuccess(startRow, endRow - startRow, params2);
      removeNodesFromLoadingMap();
    };
    const fail = () => {
      this.rowNodeBlockLoader.loadComplete();
      cache.onLoadFailed(startRow, endRow - startRow);
      removeNodesFromLoadingMap();
    };
    const params = this.gos.addGridCommonParams({
      request,
      success,
      fail,
      parentNode: cache.store.getParentNode()
    });
    addNodesToLoadingMap();
    cache.getSsrmParams().datasource?.getRows(params);
  }
  getBlockToLoad() {
    const firstRowInViewport = this.rowRenderer.getFirstVirtualRenderedRow();
    const lastRowInViewport = this.rowRenderer.getLastVirtualRenderedRow();
    for (let i = firstRowInViewport; i <= lastRowInViewport; i++) {
      const row = this.rowModel.getRow(i);
      if (!row) {
        continue;
      }
      const store = row.parent && row.parent.childStore;
      if (!store || !(store instanceof LazyStore)) {
        continue;
      }
      const cache = store.getCache();
      const lazyNode = cache.getNodes().getBy("node", row);
      if (!lazyNode) {
        continue;
      }
      const loadingNodes = this.cacheLoadingNodesMap.get(cache);
      if (loadingNodes?.has(lazyNode.index)) {
        continue;
      }
      if (row.__needsRefreshWhenVisible || row.stub && !row.failedLoad) {
        return {
          cache,
          index: cache.getBlockStartIndex(lazyNode.index)
        };
      }
    }
    let cacheToRefresh = null;
    let nodeToRefresh = null;
    let nodeToRefreshDist = Number.MAX_SAFE_INTEGER;
    for (const cache of this.cacheLoadingNodesMap.keys()) {
      const nodesToRefresh = cache.getNodesToRefresh();
      nodesToRefresh.forEach((node) => {
        if (node.rowIndex == null) {
          nodeToRefresh = node;
          cacheToRefresh = cache;
          return;
        }
        const lazyNode = cache.getNodes().getBy("node", node);
        if (!lazyNode) {
          return;
        }
        const loadingNodes = this.cacheLoadingNodesMap.get(cache);
        if (loadingNodes?.has(lazyNode.index)) {
          return;
        }
        const distToViewportTop = Math.abs(firstRowInViewport - node.rowIndex);
        const distToViewportBottom = Math.abs(node.rowIndex - lastRowInViewport);
        if (distToViewportTop < nodeToRefreshDist) {
          nodeToRefresh = node;
          nodeToRefreshDist = distToViewportTop;
          cacheToRefresh = cache;
        }
        if (distToViewportBottom < nodeToRefreshDist) {
          nodeToRefresh = node;
          nodeToRefreshDist = distToViewportBottom;
          cacheToRefresh = cache;
        }
      });
    }
    if (!cacheToRefresh) {
      return void 0;
    }
    const lazyCache = cacheToRefresh;
    const lazyIndex = lazyCache.getNodes().getBy("node", nodeToRefresh)?.index;
    return lazyIndex == null ? void 0 : {
      cache: lazyCache,
      index: lazyCache.getBlockStartIndex(lazyIndex)
    };
  }
  isRowLoading(cache, index) {
    return this.cacheLoadingNodesMap.get(cache)?.has(index) ?? false;
  }
};
LazyBlockLoadingService.DEFAULT_BLOCK_SIZE = 100;
var MultiIndexMap = class {
  constructor(...indexes) {
    if (indexes.length < 1) {
      throw new Error("AG Grid: At least one index must be provided.");
    }
    this.indexes = indexes;
    this.maps = new Map(this.indexes.map((index) => [index, /* @__PURE__ */ new Map()]));
  }
  getSize() {
    return this.maps.get(this.indexes[0]).size;
  }
  getBy(index, key) {
    const map = this.maps.get(index);
    if (!map) {
      throw new Error(`AG Grid: ${String(index)} not found`);
    }
    return map.get(key);
  }
  set(item) {
    this.indexes.forEach((index) => {
      const map = this.maps.get(index);
      if (!map) {
        throw new Error(`AG Grid: ${String(index)} not found`);
      }
      map.set(item[index], item);
    });
  }
  delete(item) {
    this.indexes.forEach((index) => {
      const map = this.maps.get(index);
      if (!map) {
        throw new Error(`AG Grid: ${String(index)} not found`);
      }
      map.delete(item[index]);
    });
  }
  clear() {
    this.maps.forEach((map) => map.clear());
  }
  getIterator(index) {
    const map = this.maps.get(index);
    if (!map) {
      throw new Error(`AG Grid: ${String(index)} not found`);
    }
    return map.values();
  }
  forEach(callback) {
    const iterator = this.getIterator(this.indexes[0]);
    let pointer;
    while (pointer = iterator.next()) {
      if (pointer.done)
        break;
      callback(pointer.value);
    }
  }
  find(callback) {
    const iterator = this.getIterator(this.indexes[0]);
    let pointer;
    while (pointer = iterator.next()) {
      if (pointer.done)
        break;
      if (callback(pointer.value)) {
        return pointer.value;
      }
    }
  }
  filter(predicate) {
    const iterator = this.getIterator(this.indexes[0]);
    let pointer;
    const result = [];
    while (pointer = iterator.next()) {
      if (pointer.done)
        break;
      if (predicate(pointer.value)) {
        result.push(pointer.value);
      }
    }
    return result;
  }
};
var LazyCache = class extends BeanStub83 {
  constructor(store, numberOfRows, isLastRowKnown, storeParams) {
    super();
    this.live = true;
    this.removedNodeCache = /* @__PURE__ */ new Map();
    this.store = store;
    this.numberOfRows = numberOfRows;
    this.isLastRowKnown = isLastRowKnown;
    this.storeParams = storeParams;
  }
  wireBeans(beans) {
    this.rowRenderer = beans.rowRenderer;
    this.blockUtils = beans.ssrmBlockUtils;
    this.focusService = beans.focusService;
    this.nodeManager = beans.ssrmNodeManager;
    this.serverSideRowModel = beans.rowModel;
    this.rowNodeSorter = beans.rowNodeSorter;
    this.sortController = beans.sortController;
    this.lazyBlockLoadingService = beans.lazyBlockLoadingService;
  }
  postConstruct() {
    this.lazyBlockLoadingService.subscribe(this);
    this.nodeMap = new MultiIndexMap("index", "id", "node");
    this.nodeDisplayIndexMap = /* @__PURE__ */ new Map();
    this.nodesToRefresh = /* @__PURE__ */ new Set();
    this.defaultNodeIdPrefix = this.blockUtils.createNodeIdPrefix(this.store.getParentNode());
    this.getRowIdFunc = _getRowIdCallback2(this.gos);
    this.isMasterDetail = this.gos.get("masterDetail");
  }
  destroy() {
    this.lazyBlockLoadingService.unsubscribe(this);
    this.numberOfRows = 0;
    this.nodeMap.forEach((node) => this.blockUtils.destroyRowNode(node.node));
    this.nodeMap.clear();
    this.nodeDisplayIndexMap.clear();
    this.nodesToRefresh.clear();
    this.live = false;
    super.destroy();
  }
  /**
   * Get the row node for a specific display index from this store
   * @param displayIndex the display index of the node to find
   * @returns undefined if the node is not in the store bounds, otherwise will always return a node
   */
  getRowByDisplayIndex(displayIndex) {
    if (!this.store.isDisplayIndexInStore(displayIndex)) {
      return void 0;
    }
    const node = this.nodeDisplayIndexMap.get(displayIndex);
    if (node) {
      if (node.stub || node.__needsRefreshWhenVisible) {
        this.lazyBlockLoadingService.queueLoadCheck();
      }
      return node;
    }
    const hideOpenGroups = this.gos.get("groupHideOpenParents") || this.gos.get("groupAllowUnbalanced");
    if (hideOpenGroups) {
      const nextParent = this.nodeMap.find(
        (lazyNode) => !!lazyNode.node.childStore?.isDisplayIndexInStore(displayIndex)
      );
      if (nextParent) {
        return nextParent.node.childStore?.getRowUsingDisplayIndex(displayIndex);
      }
    }
    if (displayIndex === this.store.getDisplayIndexStart()) {
      return this.createStubNode(0, displayIndex);
    }
    const contiguouslyPreviousNode = this.nodeDisplayIndexMap.get(displayIndex - 1);
    if (contiguouslyPreviousNode) {
      if (this.isMasterDetail && contiguouslyPreviousNode.master && contiguouslyPreviousNode.expanded) {
        return contiguouslyPreviousNode.detailNode;
      }
      if (contiguouslyPreviousNode.expanded && contiguouslyPreviousNode.childStore?.isDisplayIndexInStore(displayIndex)) {
        return contiguouslyPreviousNode.childStore?.getRowUsingDisplayIndex(displayIndex);
      }
      const lazyCacheNode = this.nodeMap.getBy("node", contiguouslyPreviousNode);
      return this.createStubNode(lazyCacheNode.index + 1, displayIndex);
    }
    const adjacentNodes = this.getSurroundingNodesByDisplayIndex(displayIndex);
    if (adjacentNodes == null) {
      const storeIndexFromEndIndex2 = this.store.getRowCount() - (this.store.getDisplayIndexEnd() - displayIndex);
      return this.createStubNode(storeIndexFromEndIndex2, displayIndex);
    }
    const { previousNode, nextNode } = adjacentNodes;
    if (previousNode && previousNode.node.expanded && previousNode.node.childStore?.isDisplayIndexInStore(displayIndex)) {
      return previousNode.node.childStore?.getRowUsingDisplayIndex(displayIndex);
    }
    if (nextNode) {
      const displayIndexDiff = nextNode.node.rowIndex - displayIndex;
      const newStoreIndex = nextNode.index - displayIndexDiff;
      return this.createStubNode(newStoreIndex, displayIndex);
    }
    const storeIndexFromEndIndex = this.store.getRowCount() - (this.store.getDisplayIndexEnd() - displayIndex);
    return this.createStubNode(storeIndexFromEndIndex, displayIndex);
  }
  /**
   * Used for creating and positioning a stub node without firing a store updated event
   */
  createStubNode(storeIndex, displayIndex) {
    const rowBounds = this.store.getRowBounds(displayIndex);
    const newNode = this.createRowAtIndex(storeIndex, null, (node) => {
      node.setRowIndex(displayIndex);
      node.setRowTop(rowBounds.rowTop);
      this.nodeDisplayIndexMap.set(displayIndex, node);
    });
    if (storeIndex === 0 && this.gos.get("groupHideOpenParents")) {
      const parentGroupData = this.store.getParentNode().groupData;
      for (const key in parentGroupData) {
        newNode.setGroupValue(key, parentGroupData[key]);
      }
    }
    this.lazyBlockLoadingService.queueLoadCheck();
    return newNode;
  }
  /**
   * @param index The row index relative to this store
   * @returns A rowNode at the given store index
   */
  getRowByStoreIndex(index) {
    return this.nodeMap.getBy("index", index)?.node;
  }
  /**
   * Given a number of rows, skips through the given sequence & row top reference (using default row height)
   * @param numberOfRowsToSkip number of rows to skip over in the given sequence
   * @param displayIndexSeq the sequence in which to skip
   * @param nextRowTop the row top reference in which to skip
   */
  skipDisplayIndexes(numberOfRowsToSkip, displayIndexSeq, nextRowTop) {
    if (numberOfRowsToSkip === 0) {
      return;
    }
    const defaultRowHeight = _getRowHeightAsNumber2(this.gos);
    displayIndexSeq.skip(numberOfRowsToSkip);
    nextRowTop.value += numberOfRowsToSkip * defaultRowHeight;
  }
  /**
   * @param displayIndexSeq the number sequence for generating the display index of each row
   * @param nextRowTop an object containing the next row top value intended to be modified by ref per row
   */
  setDisplayIndexes(displayIndexSeq, nextRowTop, uiLevel) {
    this.nodeDisplayIndexMap.clear();
    const orderedMap = {};
    this.nodeMap.forEach((lazyNode) => {
      orderedMap[lazyNode.index] = lazyNode.node;
    });
    let lastIndex = -1;
    for (const stringIndex in orderedMap) {
      const node = orderedMap[stringIndex];
      const numericIndex = Number(stringIndex);
      const numberOfRowsToSkip2 = numericIndex - 1 - lastIndex;
      this.skipDisplayIndexes(numberOfRowsToSkip2, displayIndexSeq, nextRowTop);
      const isFirstChild = numericIndex === 0;
      node.setFirstChild(isFirstChild);
      if (isFirstChild && this.gos.get("groupHideOpenParents")) {
        const parentGroupData = this.store.getParentNode().groupData;
        for (const key in parentGroupData) {
          node.setGroupValue(key, isFirstChild ? parentGroupData[key] : void 0);
        }
      }
      this.blockUtils.setDisplayIndex(node, displayIndexSeq, nextRowTop, uiLevel);
      if (node.rowIndex != null) {
        this.nodeDisplayIndexMap.set(node.rowIndex, node);
      }
      lastIndex = numericIndex;
    }
    const numberOfRowsToSkip = this.numberOfRows - 1 - lastIndex;
    this.skipDisplayIndexes(numberOfRowsToSkip, displayIndexSeq, nextRowTop);
    this.purgeExcessRows();
  }
  getRowCount() {
    return this.numberOfRows;
  }
  setRowCount(rowCount, isLastRowIndexKnown) {
    if (rowCount < 0) {
      throw new Error("AG Grid: setRowCount can only accept a positive row count.");
    }
    this.numberOfRows = rowCount;
    if (isLastRowIndexKnown != null) {
      this.isLastRowKnown = isLastRowIndexKnown;
      if (isLastRowIndexKnown === false) {
        this.numberOfRows += 1;
      }
    }
    this.fireStoreUpdatedEvent();
  }
  getNodes() {
    return this.nodeMap;
  }
  getNodeCachedByDisplayIndex(displayIndex) {
    return this.nodeDisplayIndexMap.get(displayIndex) ?? null;
  }
  getNodesToRefresh() {
    return this.nodesToRefresh;
  }
  /**
   * @returns the previous and next loaded row nodes surrounding the given display index
   */
  getSurroundingNodesByDisplayIndex(displayIndex) {
    let nextNode;
    let previousNode;
    this.nodeMap.forEach((lazyNode) => {
      if (displayIndex > lazyNode.node.rowIndex) {
        if (previousNode == null || previousNode.node.rowIndex < lazyNode.node.rowIndex) {
          previousNode = lazyNode;
        }
        return;
      }
      if (nextNode == null || nextNode.node.rowIndex > lazyNode.node.rowIndex) {
        nextNode = lazyNode;
        return;
      }
    });
    if (!previousNode && !nextNode)
      return null;
    return { previousNode, nextNode };
  }
  /**
   * Get or calculate the display index for a given store index
   * @param storeIndex the rows index within this store
   * @returns the rows visible display index relative to the grid
   */
  getDisplayIndexFromStoreIndex(storeIndex) {
    const nodeAtIndex = this.nodeMap.getBy("index", storeIndex);
    if (nodeAtIndex) {
      return nodeAtIndex.node.rowIndex;
    }
    let nextNode;
    let previousNode;
    this.nodeMap.forEach((lazyNode) => {
      if (storeIndex > lazyNode.index) {
        if (previousNode == null || previousNode.index < lazyNode.index) {
          previousNode = lazyNode;
        }
        return;
      }
      if (nextNode == null || nextNode.index > lazyNode.index) {
        nextNode = lazyNode;
        return;
      }
    });
    if (!nextNode) {
      return this.store.getDisplayIndexEnd() - (this.numberOfRows - storeIndex);
    }
    if (!previousNode) {
      return this.store.getDisplayIndexStart() + storeIndex;
    }
    const storeIndexDiff = storeIndex - previousNode.index;
    const previousDisplayIndex = previousNode.node.childStore?.getDisplayIndexEnd() ?? previousNode.node.rowIndex;
    return previousDisplayIndex + storeIndexDiff;
  }
  /**
   * Creates a new row and inserts it at the given index
   * @param atStoreIndex the node index relative to this store
   * @param data the data object to populate the node with
   * @returns the new row node
   */
  createRowAtIndex(atStoreIndex, data, createNodeCallback) {
    const lazyNode = this.nodeMap.getBy("index", atStoreIndex);
    if (lazyNode) {
      const { node } = lazyNode;
      node.__needsRefreshWhenVisible = false;
      if (this.doesNodeMatch(data, node)) {
        this.blockUtils.updateDataIntoRowNode(node, data);
        this.nodesToRefresh.delete(node);
        return node;
      }
      if (this.getRowIdFunc == null && node.hasChildren() && node.expanded) {
        this.nodesToRefresh.delete(node);
        return node;
      }
      this.destroyRowAtIndex(atStoreIndex);
    }
    if (data && this.getRowIdFunc != null) {
      const id = this.getRowId(data);
      const deletedNode = id && this.removedNodeCache?.get(id);
      if (deletedNode) {
        this.removedNodeCache?.delete(id);
        this.blockUtils.updateDataIntoRowNode(deletedNode, data);
        this.nodeMap.set({
          id: deletedNode.id,
          node: deletedNode,
          index: atStoreIndex
        });
        this.nodesToRefresh.delete(deletedNode);
        deletedNode.__needsRefreshWhenVisible = false;
        return deletedNode;
      }
      const lazyNode2 = this.nodeMap.getBy("id", id);
      if (lazyNode2) {
        this.nodeMap.delete(lazyNode2);
        const { node, index } = lazyNode2;
        this.blockUtils.updateDataIntoRowNode(node, data);
        this.nodeMap.set({
          id: node.id,
          node,
          index: atStoreIndex
        });
        this.nodesToRefresh.delete(node);
        node.__needsRefreshWhenVisible = false;
        if (this.getBlockStartIndex(index) === this.getBlockStartIndex(atStoreIndex)) {
          return node;
        }
        this.markBlockForVerify(index);
        return node;
      }
    }
    const newNode = this.blockUtils.createRowNode(this.store.getRowDetails());
    if (data != null) {
      const defaultId = this.getPrefixedId(this.store.getIdSequence().next());
      this.blockUtils.setDataIntoRowNode(newNode, data, defaultId, void 0);
      this.serverSideRowModel.setPaused(true);
      this.blockUtils.checkOpenByDefault(newNode);
      this.serverSideRowModel.setPaused(false);
      this.nodeManager.addRowNode(newNode);
    }
    this.nodeMap.set({
      id: newNode.id,
      node: newNode,
      index: atStoreIndex
    });
    if (createNodeCallback) {
      createNodeCallback(newNode);
    }
    return newNode;
  }
  getBlockStates() {
    const blockCounts = {};
    const blockStates = {};
    this.nodeMap.forEach(({ node, index }) => {
      const blockStart = this.getBlockStartIndex(index);
      if (!node.stub && !node.failedLoad) {
        blockCounts[blockStart] = (blockCounts[blockStart] ?? 0) + 1;
      }
      let rowState = "loaded";
      if (node.failedLoad) {
        rowState = "failed";
      } else if (this.lazyBlockLoadingService.isRowLoading(this, blockStart)) {
        rowState = "loading";
      } else if (this.nodesToRefresh.has(node) || node.stub) {
        rowState = "needsLoading";
      }
      if (!blockStates[blockStart]) {
        blockStates[blockStart] = /* @__PURE__ */ new Set();
      }
      blockStates[blockStart].add(rowState);
    });
    const statePriorityMap = {
      loading: 4,
      failed: 3,
      needsLoading: 2,
      loaded: 1
    };
    const blockPrefix = this.blockUtils.createNodeIdPrefix(this.store.getParentNode());
    const results = {};
    Object.entries(blockStates).forEach(([blockStart, uniqueStates]) => {
      const sortedStates = [...uniqueStates].sort(
        (a, b) => (statePriorityMap[a] ?? 0) - (statePriorityMap[b] ?? 0)
      );
      const priorityState = sortedStates[0];
      const blockNumber = Number(blockStart) / this.getBlockSize();
      const blockId = blockPrefix ? `${blockPrefix}-${blockNumber}` : String(blockNumber);
      results[blockId] = {
        blockNumber,
        startRow: Number(blockStart),
        endRow: Number(blockStart) + this.getBlockSize(),
        pageStatus: priorityState,
        loadedRowCount: blockCounts[blockStart] ?? 0
      };
    });
    return results;
  }
  destroyRowAtIndex(atStoreIndex) {
    const lazyNode = this.nodeMap.getBy("index", atStoreIndex);
    if (!lazyNode) {
      return;
    }
    this.nodeMap.delete(lazyNode);
    this.nodeDisplayIndexMap.delete(lazyNode.node.rowIndex);
    if (this.nodesToRefresh.size > 0) {
      this.removedNodeCache.set(lazyNode.node.id, lazyNode.node);
    } else {
      this.blockUtils.destroyRowNode(lazyNode.node);
    }
    this.nodesToRefresh.delete(lazyNode.node);
  }
  getSsrmParams() {
    return this.store.getSsrmParams();
  }
  /**
   * @param id the base id to be prefixed
   * @returns a node id with prefix if required
   */
  getPrefixedId(id) {
    if (this.defaultNodeIdPrefix) {
      return this.defaultNodeIdPrefix + "-" + id;
    } else {
      return id.toString();
    }
  }
  markBlockForVerify(rowIndex) {
    const [start, end] = this.getBlockBounds(rowIndex);
    const lazyNodesInRange = this.nodeMap.filter((lazyNode) => lazyNode.index >= start && lazyNode.index < end);
    lazyNodesInRange.forEach(({ node }) => {
      node.__needsRefreshWhenVisible = true;
    });
  }
  doesNodeMatch(data, node) {
    if (node.stub) {
      return false;
    }
    const id = this.getRowId(data);
    return id === null ? node.data === data : node.id === id;
  }
  /**
   * Deletes any stub nodes not within the given range
   */
  purgeStubsOutsideOfViewport() {
    const firstRow = this.rowRenderer.getFirstVirtualRenderedRow();
    const lastRow = this.rowRenderer.getLastVirtualRenderedRow();
    const firstRowBlockStart = this.getBlockStartIndex(firstRow);
    const [, lastRowBlockEnd] = this.getBlockBounds(lastRow);
    this.nodeMap.forEach((lazyNode) => {
      if (this.lazyBlockLoadingService.isRowLoading(this, lazyNode.index) || lazyNode.node.failedLoad) {
        return;
      }
      if (lazyNode.node.stub && (lazyNode.index < firstRowBlockStart || lazyNode.index > lastRowBlockEnd)) {
        this.destroyRowAtIndex(lazyNode.index);
      }
    });
  }
  getBlocksDistanceFromRow(nodes, otherDisplayIndex) {
    const blockDistanceToMiddle = {};
    nodes.forEach(({ node, index }) => {
      const [blockStart, blockEnd] = this.getBlockBounds(index);
      if (blockStart in blockDistanceToMiddle) {
        return;
      }
      const distStart = Math.abs(node.rowIndex - otherDisplayIndex);
      let distEnd;
      const lastLazyNode = this.nodeMap.getBy("index", [blockEnd - 1]);
      if (lastLazyNode)
        distEnd = Math.abs(lastLazyNode.node.rowIndex - otherDisplayIndex);
      const farthest = distEnd == null || distStart < distEnd ? distStart : distEnd;
      blockDistanceToMiddle[blockStart] = farthest;
    });
    return Object.entries(blockDistanceToMiddle);
  }
  purgeExcessRows() {
    this.purgeStubsOutsideOfViewport();
    if (this.store.getDisplayIndexEnd() == null || this.storeParams.maxBlocksInCache == null) {
      return;
    }
    const firstRowInViewport = this.rowRenderer.getFirstVirtualRenderedRow();
    const lastRowInViewport = this.rowRenderer.getLastVirtualRenderedRow();
    const allLoadedBlocks = /* @__PURE__ */ new Set();
    const blocksInViewport = /* @__PURE__ */ new Set();
    this.nodeMap.forEach(({ index, node }) => {
      const blockStart = this.getBlockStartIndex(index);
      allLoadedBlocks.add(blockStart);
      const isInViewport = node.rowIndex >= firstRowInViewport && node.rowIndex <= lastRowInViewport;
      if (isInViewport) {
        blocksInViewport.add(blockStart);
      }
    });
    const numberOfBlocksToRetain = Math.max(blocksInViewport.size, this.storeParams.maxBlocksInCache ?? 0);
    const loadedBlockCount = allLoadedBlocks.size;
    const blocksToRemove = loadedBlockCount - numberOfBlocksToRetain;
    if (blocksToRemove <= 0) {
      return;
    }
    let firstRowBlockStart = Number.MAX_SAFE_INTEGER;
    let lastRowBlockStart = Number.MIN_SAFE_INTEGER;
    blocksInViewport.forEach((blockStart) => {
      if (firstRowBlockStart > blockStart) {
        firstRowBlockStart = blockStart;
      }
      if (lastRowBlockStart < blockStart) {
        lastRowBlockStart = blockStart;
      }
    });
    const disposableNodes = this.nodeMap.filter(({ node, index }) => {
      const rowBlockStart = this.getBlockStartIndex(index);
      const rowBlockInViewport = rowBlockStart >= firstRowBlockStart && rowBlockStart <= lastRowBlockStart;
      return !rowBlockInViewport && !this.isNodeCached(node);
    });
    if (disposableNodes.length === 0) {
      return;
    }
    const midViewportRow = firstRowInViewport + (lastRowInViewport - firstRowInViewport) / 2;
    const blockDistanceArray = this.getBlocksDistanceFromRow(disposableNodes, midViewportRow);
    const blockSize = this.getBlockSize();
    blockDistanceArray.sort((a, b) => Math.sign(b[1] - a[1]));
    for (let i = 0; i < Math.min(blocksToRemove, blockDistanceArray.length); i++) {
      const blockStart = Number(blockDistanceArray[i][0]);
      for (let x = blockStart; x < blockStart + blockSize; x++) {
        const lazyNode = this.nodeMap.getBy("index", x);
        if (!lazyNode || this.isNodeCached(lazyNode.node)) {
          continue;
        }
        this.destroyRowAtIndex(x);
      }
    }
  }
  isNodeFocused(node) {
    const focusedCell = this.focusService.getFocusCellToUseAfterRefresh();
    if (!focusedCell) {
      return false;
    }
    if (focusedCell.rowPinned != null) {
      return false;
    }
    const hasFocus = focusedCell.rowIndex === node.rowIndex;
    return hasFocus;
  }
  isNodeCached(node) {
    const isUnbalancedNode = this.gos.get("groupAllowUnbalanced") && node.key === "";
    return node.isExpandable() && node.expanded || this.isNodeFocused(node) || isUnbalancedNode;
  }
  extractDuplicateIds(rows) {
    if (this.getRowIdFunc == null) {
      return [];
    }
    const newIds = /* @__PURE__ */ new Set();
    const duplicates = /* @__PURE__ */ new Set();
    rows.forEach((data) => {
      const id = this.getRowId(data);
      if (newIds.has(id)) {
        duplicates.add(id);
        return;
      }
      newIds.add(id);
    });
    return [...duplicates];
  }
  onLoadSuccess(firstRowIndex, numberOfRowsExpected, response) {
    if (!this.live)
      return;
    const info = response.groupLevelInfo;
    this.store.setStoreInfo(info);
    if (this.getRowIdFunc != null) {
      const duplicates = this.extractDuplicateIds(response.rowData);
      if (duplicates.length > 0) {
        const duplicateIdText = duplicates.join(", ");
        _warnOnce45(
          `Unable to display rows as duplicate row ids (${duplicateIdText}) were returned by the getRowId callback. Please modify the getRowId callback to provide unique ids.`
        );
        this.onLoadFailed(firstRowIndex, numberOfRowsExpected);
        return;
      }
    }
    if (response.pivotResultFields) {
      this.serverSideRowModel.generateSecondaryColumns(response.pivotResultFields);
    }
    const wasRefreshing = this.nodesToRefresh.size > 0;
    response.rowData.forEach((data, responseRowIndex) => {
      const rowIndex = firstRowIndex + responseRowIndex;
      const nodeFromCache = this.nodeMap.getBy("index", rowIndex);
      if (nodeFromCache?.node?.stub) {
        this.createRowAtIndex(rowIndex, data);
        return;
      }
      if (nodeFromCache && this.doesNodeMatch(data, nodeFromCache.node)) {
        this.blockUtils.updateDataIntoRowNode(nodeFromCache.node, data);
        this.nodesToRefresh.delete(nodeFromCache.node);
        nodeFromCache.node.__needsRefreshWhenVisible = false;
        return;
      }
      this.createRowAtIndex(rowIndex, data);
    });
    if (response.rowCount != void 0 && response.rowCount !== -1) {
      this.numberOfRows = response.rowCount;
      this.isLastRowKnown = true;
    } else if (numberOfRowsExpected > response.rowData.length) {
      this.numberOfRows = firstRowIndex + response.rowData.length;
      this.isLastRowKnown = true;
    } else if (!this.isLastRowKnown) {
      const lastInferredRow = firstRowIndex + response.rowData.length + 1;
      if (lastInferredRow > this.numberOfRows) {
        this.numberOfRows = lastInferredRow;
      }
    }
    if (this.isLastRowKnown) {
      const lazyNodesAfterStoreEnd = this.nodeMap.filter((lazyNode) => lazyNode.index >= this.numberOfRows);
      lazyNodesAfterStoreEnd.forEach((lazyNode) => this.destroyRowAtIndex(lazyNode.index));
    }
    this.fireStoreUpdatedEvent();
    const finishedRefreshing = this.nodesToRefresh.size === 0;
    if (wasRefreshing && finishedRefreshing) {
      this.fireRefreshFinishedEvent();
    }
  }
  fireRefreshFinishedEvent() {
    const finishedRefreshing = this.nodesToRefresh.size === 0;
    if (!finishedRefreshing) {
      return;
    }
    this.removedNodeCache.forEach((node) => {
      this.blockUtils.destroyRowNode(node);
    });
    this.removedNodeCache = /* @__PURE__ */ new Map();
    this.store.fireRefreshFinishedEvent();
  }
  /**
   * @returns true if all rows are loaded
   */
  isStoreFullyLoaded() {
    const knowsSize = this.isLastRowKnown;
    const hasCorrectRowCount = this.nodeMap.getSize() === this.numberOfRows;
    if (!knowsSize || !hasCorrectRowCount) {
      return;
    }
    if (this.nodesToRefresh.size > 0) {
      return;
    }
    let index = -1;
    const firstOutOfPlaceNode = this.nodeMap.find((lazyNode) => {
      index += 1;
      if (lazyNode.index !== index) {
        return true;
      }
      if (lazyNode.node.__needsRefreshWhenVisible) {
        return true;
      }
      if (lazyNode.node.stub) {
        return true;
      }
      return false;
    });
    return firstOutOfPlaceNode == null;
  }
  isLastRowIndexKnown() {
    return this.isLastRowKnown;
  }
  onLoadFailed(firstRowIndex, numberOfRowsExpected) {
    if (!this.live)
      return;
    const wasRefreshing = this.nodesToRefresh.size > 0;
    for (let i = firstRowIndex; i < firstRowIndex + numberOfRowsExpected && i < this.getRowCount(); i++) {
      let { node } = this.nodeMap.getBy("index", i) ?? {};
      if (node) {
        this.nodesToRefresh.delete(node);
      }
      if (!node || !node.stub) {
        if (node && !node.stub) {
          this.destroyRowAtIndex(i);
        }
        node = this.createRowAtIndex(i);
      }
      node.__needsRefreshWhenVisible = false;
      node.failedLoad = true;
    }
    const finishedRefreshing = this.nodesToRefresh.size === 0;
    if (wasRefreshing && finishedRefreshing) {
      this.fireRefreshFinishedEvent();
    }
    this.fireStoreUpdatedEvent();
  }
  markNodesForRefresh() {
    this.nodeMap.forEach((lazyNode) => {
      if (lazyNode.node.stub && !lazyNode.node.failedLoad) {
        return;
      }
      this.nodesToRefresh.add(lazyNode.node);
    });
    this.lazyBlockLoadingService.queueLoadCheck();
    if (this.isLastRowKnown && this.numberOfRows === 0) {
      this.numberOfRows = 1;
      this.isLastRowKnown = false;
      this.fireStoreUpdatedEvent();
    }
  }
  isNodeInCache(id) {
    return !!this.nodeMap.getBy("id", id);
  }
  // gets called 1) row count changed 2) cache purged 3) items inserted
  fireStoreUpdatedEvent() {
    if (!this.live) {
      return;
    }
    this.store.fireStoreUpdatedEvent();
  }
  getRowId(data) {
    if (this.getRowIdFunc == null) {
      return null;
    }
    const { level } = this.store.getRowDetails();
    const parentKeys = this.store.getParentNode().getRoute() ?? [];
    return this.getRowIdFunc({
      data,
      parentKeys: parentKeys.length > 0 ? parentKeys : void 0,
      level
    });
  }
  getOrderedNodeMap() {
    const obj = {};
    this.nodeMap.forEach((node) => obj[node.index] = node);
    return obj;
  }
  clearDisplayIndexes() {
    this.nodeDisplayIndexMap.clear();
  }
  /**
   * Client side sorting
   */
  clientSideSortRows() {
    const sortOptions = this.sortController.getSortOptions();
    const isAnySort = sortOptions.some((opt) => opt.sort != null);
    if (!isAnySort) {
      return;
    }
    const allNodes = new Array(this.nodeMap.getSize());
    this.nodeMap.forEach((lazyNode) => allNodes[lazyNode.index] = lazyNode.node);
    this.nodeMap.clear();
    const sortedNodes = this.rowNodeSorter.doFullSort(allNodes, sortOptions);
    sortedNodes.forEach((node, index) => {
      this.nodeMap.set({
        id: node.id,
        node,
        index
      });
    });
  }
  /**
   * Transaction Support here
   */
  updateRowNodes(updates) {
    if (this.getRowIdFunc == null) {
      throw new Error("AG Grid: Transactions can only be applied when row ids are supplied.");
    }
    const updatedNodes = [];
    updates.forEach((data) => {
      const id = this.getRowId(data);
      const lazyNode = this.nodeMap.getBy("id", id);
      if (lazyNode) {
        this.blockUtils.updateDataIntoRowNode(lazyNode.node, data);
        updatedNodes.push(lazyNode.node);
      }
    });
    return updatedNodes;
  }
  insertRowNodes(inserts, indexToAdd) {
    const realRowCount = this.store.getRowCount() - (this.store.getParentNode().sibling ? 1 : 0);
    const addIndex = indexToAdd == null && this.isLastRowKnown ? realRowCount : indexToAdd;
    if (addIndex == null || realRowCount < addIndex) {
      return [];
    }
    if (this.getRowIdFunc == null) {
      throw new Error("AG Grid: Transactions can only be applied when row ids are supplied.");
    }
    const uniqueInsertsMap = {};
    inserts.forEach((data) => {
      const dataId = this.getRowId(data);
      if (dataId && this.isNodeInCache(dataId)) {
        return;
      }
      uniqueInsertsMap[dataId] = data;
    });
    const uniqueInserts = Object.values(uniqueInsertsMap);
    const numberOfInserts = uniqueInserts.length;
    if (numberOfInserts === 0) {
      return [];
    }
    const nodesToMove = this.nodeMap.filter((node) => node.index >= addIndex);
    nodesToMove.forEach((lazyNode) => this.nodeMap.delete(lazyNode));
    nodesToMove.forEach((lazyNode) => {
      this.nodeMap.set({
        node: lazyNode.node,
        index: lazyNode.index + numberOfInserts,
        id: lazyNode.id
      });
    });
    this.numberOfRows += numberOfInserts;
    return uniqueInserts.map(
      (data, uniqueInsertOffset) => this.createRowAtIndex(addIndex + uniqueInsertOffset, data)
    );
  }
  removeRowNodes(idsToRemove) {
    if (this.getRowIdFunc == null) {
      throw new Error("AG Grid: Transactions can only be applied when row ids are supplied.");
    }
    const removedNodes = [];
    const nodesToVerify = [];
    let deletedNodeCount = 0;
    const remainingIdsToRemove = [...idsToRemove];
    const allNodes = this.getOrderedNodeMap();
    let contiguousIndex = -1;
    for (const stringIndex in allNodes) {
      contiguousIndex += 1;
      const node = allNodes[stringIndex];
      const matchIndex = remainingIdsToRemove.findIndex((idToRemove) => idToRemove === node.id);
      if (matchIndex !== -1) {
        remainingIdsToRemove.splice(matchIndex, 1);
        this.destroyRowAtIndex(Number(stringIndex));
        removedNodes.push(node.node);
        deletedNodeCount += 1;
        continue;
      }
      if (deletedNodeCount === 0) {
        continue;
      }
      const numericStoreIndex = Number(stringIndex);
      if (contiguousIndex !== numericStoreIndex) {
        nodesToVerify.push(node.node);
      }
      this.nodeMap.delete(allNodes[stringIndex]);
      this.nodeMap.set({
        id: node.id,
        node: node.node,
        index: numericStoreIndex - deletedNodeCount
      });
    }
    this.numberOfRows -= this.isLastRowIndexKnown() ? idsToRemove.length : deletedNodeCount;
    if (remainingIdsToRemove.length > 0 && nodesToVerify.length > 0) {
      nodesToVerify.forEach((node) => node.__needsRefreshWhenVisible = true);
      this.lazyBlockLoadingService.queueLoadCheck();
    }
    return removedNodes;
  }
  /**
   * Return the block size configured for this cache
   */
  getBlockSize() {
    return this.storeParams.cacheBlockSize || LazyBlockLoadingService.DEFAULT_BLOCK_SIZE;
  }
  /**
   * Get the start index of the loading block for a given index
   */
  getBlockStartIndex(storeIndex) {
    const blockSize = this.getBlockSize();
    return storeIndex - storeIndex % blockSize;
  }
  /**
   * Get the start and end index of a block, given a row store index
   */
  getBlockBounds(storeIndex) {
    const startOfBlock = this.getBlockStartIndex(storeIndex);
    const blockSize = this.getBlockSize();
    return [startOfBlock, startOfBlock + blockSize];
  }
};
var LazyStore = class extends BeanStub93 {
  constructor(ssrmParams, storeParams, parentRowNode) {
    super();
    this.idSequence = new NumberSequence2();
    this.ssrmParams = ssrmParams;
    this.parentRowNode = parentRowNode;
    this.storeParams = storeParams;
    this.level = parentRowNode.level + 1;
    this.group = ssrmParams.rowGroupCols ? this.level < ssrmParams.rowGroupCols.length : false;
    this.leafGroup = ssrmParams.rowGroupCols ? this.level === ssrmParams.rowGroupCols.length - 1 : false;
    this.info = {};
  }
  wireBeans(beans) {
    this.blockUtils = beans.ssrmBlockUtils;
    this.storeUtils = beans.ssrmStoreUtils;
    this.selectionService = beans.selectionService;
    this.funcColsService = beans.funcColsService;
  }
  postConstruct() {
    let numberOfRows = 1;
    if (this.level === 0) {
      numberOfRows = this.storeUtils.getServerSideInitialRowCount() ?? 1;
      this.eventService.dispatchEventOnce({
        type: "rowCountReady"
      });
    }
    this.cache = this.createManagedBean(new LazyCache(this, numberOfRows, false, this.storeParams));
    const usingTreeData = this.gos.get("treeData");
    if (!usingTreeData && this.group) {
      const groupColVo = this.ssrmParams.rowGroupCols[this.level];
      this.groupField = groupColVo.field;
      this.rowGroupColumn = this.funcColsService.getRowGroupColumns()[this.level];
    }
  }
  destroy() {
    this.displayIndexStart = void 0;
    this.displayIndexEnd = void 0;
    this.destroyBean(this.cache);
    super.destroy();
  }
  /**
   * Given a server response, ingest the rows outside of the data source lifecycle.
   *
   * @param rowDataParams the server response containing the rows to ingest
   * @param startRow the index to start ingesting rows
   * @param expectedRows the expected number of rows in the response (used to determine if the last row index is known)
   */
  applyRowData(rowDataParams, startRow, expectedRows) {
    this.cache.onLoadSuccess(startRow, expectedRows, rowDataParams);
  }
  /**
   * Applies a given transaction to the data set within this store
   *
   * @param transaction an object containing delta instructions determining the changes to apply to this store
   * @returns an object determining the status of this transaction and effected nodes
   */
  applyTransaction(transaction) {
    const idFunc = _getRowIdCallback3(this.gos);
    if (!idFunc) {
      _warnOnce53("getRowId callback must be implemented for transactions to work. Transaction was ignored.");
      return {
        status: ServerSideTransactionResultStatus2.Cancelled
      };
    }
    const applyCallback = this.gos.getCallback("isApplyServerSideTransaction");
    if (applyCallback) {
      const params = {
        transaction,
        parentNode: this.parentRowNode,
        groupLevelInfo: this.info
      };
      const apply = applyCallback(params);
      if (!apply) {
        return { status: ServerSideTransactionResultStatus2.Cancelled };
      }
    }
    const allRowsLoaded = this.cache.isStoreFullyLoaded();
    let updatedNodes = void 0;
    if (transaction.update?.length) {
      updatedNodes = this.cache.updateRowNodes(transaction.update);
    }
    let insertedNodes = void 0;
    if (transaction.add?.length) {
      let addIndex = transaction.addIndex;
      if (addIndex != null && addIndex < 0) {
        addIndex = void 0;
      }
      insertedNodes = this.cache.insertRowNodes(transaction.add, addIndex);
    }
    let removedNodes = void 0;
    if (transaction.remove?.length) {
      const allIdsToRemove = transaction.remove.map(
        (data) => idFunc({ level: this.level, parentKeys: this.parentRowNode.getRoute() ?? [], data })
      );
      const allUniqueIdsToRemove = [...new Set(allIdsToRemove)];
      removedNodes = this.cache.removeRowNodes(allUniqueIdsToRemove);
    }
    const isClientSideSortingEnabled = this.gos.get("serverSideEnableClientSideSort");
    const isUpdateOrAdd = updatedNodes?.length || insertedNodes?.length;
    const isClientSideSort = allRowsLoaded && isClientSideSortingEnabled;
    if (isClientSideSort && isUpdateOrAdd) {
      this.cache.clientSideSortRows();
    }
    this.updateSelectionAfterTransaction(updatedNodes, removedNodes);
    return {
      status: ServerSideTransactionResultStatus2.Applied,
      update: updatedNodes,
      add: insertedNodes,
      remove: removedNodes
    };
  }
  updateSelectionAfterTransaction(updatedNodes, removedNodes) {
    const nodesToDeselect = [];
    updatedNodes?.forEach((node) => {
      if (node.isSelected() && !node.selectable) {
        nodesToDeselect.push(node);
      }
    });
    removedNodes?.forEach((node) => {
      if (node.isSelected()) {
        nodesToDeselect.push(node);
      }
    });
    if (nodesToDeselect.length) {
      this.selectionService.setNodesSelected({
        newValue: false,
        clearSelection: false,
        nodes: nodesToDeselect,
        source: "rowDataChanged"
      });
    }
  }
  /**
   * Clear the display indexes, used for fading rows out when stores are not being destroyed
   */
  clearDisplayIndexes() {
    this.displayIndexStart = void 0;
    this.displayIndexEnd = void 0;
    this.cache.getNodes().forEach((lazyNode) => this.blockUtils.clearDisplayIndex(lazyNode.node));
    if (this.parentRowNode.sibling) {
      this.blockUtils.clearDisplayIndex(this.parentRowNode.sibling);
    }
    this.cache.clearDisplayIndexes();
  }
  /**
   * @returns an index representing the last sequentially displayed row in the grid for this store
   */
  getDisplayIndexStart() {
    return this.displayIndexStart;
  }
  /**
   * @returns the index representing one after the last sequentially displayed row in the grid for this store
   */
  getDisplayIndexEnd() {
    return this.displayIndexEnd;
  }
  /**
   * @returns the virtual size of this store
   */
  getRowCount() {
    if (this.parentRowNode.sibling) {
      return this.cache.getRowCount() + 1;
    }
    return this.cache.getRowCount();
  }
  /**
   * Sets the current row count of the store, and whether the last row index is known
   */
  setRowCount(rowCount, isLastRowIndexKnown) {
    this.cache.setRowCount(rowCount, isLastRowIndexKnown);
  }
  /**
   * Given a display index, returns whether that row is within this store or a child store of this store
   *
   * @param displayIndex the visible index of a row
   * @returns whether or not the row exists within this store
   */
  isDisplayIndexInStore(displayIndex) {
    if (this.cache.getRowCount() === 0)
      return false;
    return this.displayIndexStart <= displayIndex && displayIndex < this.getDisplayIndexEnd();
  }
  /**
   * Recursively sets up the display indexes and top position of every node belonging to this store.
   *
   * Called after a row height changes, or a store updated event.
   *
   * @param displayIndexSeq the number sequence for generating the display index of each row
   * @param nextRowTop an object containing the next row top value intended to be modified by ref per row
   */
  setDisplayIndexes(displayIndexSeq, nextRowTop, uiLevel) {
    this.displayIndexStart = displayIndexSeq.peek();
    this.topPx = nextRowTop.value;
    const footerNode = this.parentRowNode.level > -1 && _getGroupTotalRowCallback2(this.gos)({ node: this.parentRowNode });
    if (!footerNode) {
      this.parentRowNode.destroyFooter();
    }
    if (footerNode === "top") {
      this.parentRowNode.createFooter();
      this.blockUtils.setDisplayIndex(this.parentRowNode.sibling, displayIndexSeq, nextRowTop, uiLevel);
    }
    this.cache.setDisplayIndexes(displayIndexSeq, nextRowTop, uiLevel);
    if (footerNode === "bottom") {
      this.parentRowNode.createFooter();
      this.blockUtils.setDisplayIndex(this.parentRowNode.sibling, displayIndexSeq, nextRowTop, uiLevel);
    }
    this.displayIndexEnd = displayIndexSeq.peek();
    this.heightPx = nextRowTop.value - this.topPx;
  }
  /**
   * Recursively applies a provided function to every node
   *
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
   */
  forEachStoreDeep(callback, sequence = new NumberSequence2()) {
    callback(this, sequence.next());
    this.cache.getNodes().forEach((lazyNode) => {
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachStoreDeep(callback, sequence);
      }
    });
  }
  /**
   * Recursively applies a provided function to every node
   *
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
   */
  forEachNodeDeep(callback, sequence = new NumberSequence2()) {
    this.cache.getNodes().forEach((lazyNode) => {
      callback(lazyNode.node, sequence.next());
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachNodeDeep(callback, sequence);
      }
    });
  }
  /**
   * Recursively applies a provided function to every node
   *
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeep
   */
  forEachNodeDeepAfterFilterAndSort(callback, sequence = new NumberSequence2(), includeFooterNodes = false) {
    const footerNode = this.parentRowNode.level > -1 && _getGroupTotalRowCallback2(this.gos)({ node: this.parentRowNode });
    if (footerNode === "top") {
      callback(this.parentRowNode.sibling, sequence.next());
    }
    const orderedNodes = this.cache.getOrderedNodeMap();
    for (const key in orderedNodes) {
      const lazyNode = orderedNodes[key];
      callback(lazyNode.node, sequence.next());
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachNodeDeepAfterFilterAndSort(callback, sequence, includeFooterNodes);
      }
    }
    if (footerNode === "bottom") {
      callback(this.parentRowNode.sibling, sequence.next());
    }
  }
  /**
   * Removes the failed status from all nodes, and marks them as stub to encourage reloading
   */
  retryLoads() {
    this.cache.getNodes().forEach(({ node }) => {
      if (node.failedLoad) {
        node.failedLoad = false;
        node.__needsRefreshWhenVisible = true;
        node.stub = true;
      }
    });
    this.forEachChildStoreShallow((store) => store.retryLoads());
    this.fireStoreUpdatedEvent();
  }
  /**
   * Given a display index, returns the row at that location.
   *
   * @param displayRowIndex the displayed index within the grid to search for
   * @returns the row node if the display index falls within the store, if it didn't exist this will create a new stub to return
   */
  getRowUsingDisplayIndex(displayRowIndex) {
    if (this.parentRowNode.sibling && displayRowIndex === this.parentRowNode.sibling.rowIndex) {
      return this.parentRowNode.sibling;
    }
    return this.cache.getRowByDisplayIndex(displayRowIndex);
  }
  /**
   * Given a display index, returns the row top and height for the row at that index.
   *
   * @param displayIndex the display index of the node
   * @returns an object containing the rowTop and rowHeight of the node at the given displayIndex
   */
  getRowBounds(displayIndex) {
    if (!this.isDisplayIndexInStore(displayIndex)) {
      return null;
    }
    const thisNode = this.cache.getNodeCachedByDisplayIndex(displayIndex);
    if (thisNode) {
      const boundsFromRow = this.blockUtils.extractRowBounds(thisNode, displayIndex);
      if (boundsFromRow) {
        return boundsFromRow;
      }
    }
    const { previousNode, nextNode } = this.cache.getSurroundingNodesByDisplayIndex(displayIndex) ?? {};
    if (previousNode) {
      const boundsFromRow = this.blockUtils.extractRowBounds(previousNode.node, displayIndex);
      if (boundsFromRow != null) {
        return boundsFromRow;
      }
    }
    const defaultRowHeight = _getRowHeightAsNumber3(this.gos);
    if (nextNode) {
      const numberOfRowDiff2 = (nextNode.node.rowIndex - displayIndex) * defaultRowHeight;
      return {
        rowTop: nextNode.node.rowTop - numberOfRowDiff2,
        rowHeight: defaultRowHeight
      };
    }
    const lastTop = this.topPx + this.heightPx;
    const numberOfRowDiff = (this.getDisplayIndexEnd() - displayIndex) * defaultRowHeight;
    return {
      rowTop: lastTop - numberOfRowDiff,
      rowHeight: defaultRowHeight
    };
  }
  /**
   * Given a vertical pixel, determines whether this store contains a row at that pixel
   *
   * @param pixel a vertical pixel position from the grid
   * @returns whether that pixel points to a virtual space belonging to this store
   */
  isPixelInRange(pixel) {
    return pixel >= this.topPx && pixel < this.topPx + this.heightPx;
  }
  /**
   * Given a vertical pixel, returns the row existing at that pixel location
   *
   * @param pixel a vertical pixel position from the grid
   * @returns the display index at the given pixel location
   */
  getRowIndexAtPixel(pixel) {
    if (pixel < this.topPx) {
      return this.getDisplayIndexStart();
    }
    if (pixel >= this.topPx + this.heightPx) {
      return this.getDisplayIndexEnd() - 1;
    }
    if (this.parentRowNode.sibling && pixel > this.parentRowNode.sibling.rowTop && pixel < this.parentRowNode.sibling.rowTop + this.parentRowNode.sibling.rowHeight) {
      return this.parentRowNode.sibling.rowIndex;
    }
    let distToPreviousNodeTop = Number.MAX_SAFE_INTEGER;
    let previousNode = null;
    let distToNextNodeTop = Number.MAX_SAFE_INTEGER;
    let nextNode = null;
    this.cache.getNodes().forEach(({ node }) => {
      const distBetween = Math.abs(pixel - node.rowTop);
      if (node.rowTop < pixel) {
        if (distBetween < distToPreviousNodeTop) {
          distToPreviousNodeTop = distBetween;
          previousNode = node;
        }
        return;
      }
      if (distBetween < distToNextNodeTop) {
        distToNextNodeTop = distBetween;
        nextNode = node;
      }
    });
    previousNode = previousNode;
    nextNode = nextNode;
    if (previousNode) {
      const indexOfRow = this.blockUtils.getIndexAtPixel(previousNode, pixel);
      if (indexOfRow != null) {
        return indexOfRow;
      }
    }
    const defaultRowHeight = _getRowHeightAsNumber3(this.gos);
    if (nextNode) {
      const nextTop2 = nextNode.rowTop;
      const numberOfRowDiff2 = Math.ceil((nextTop2 - pixel) / defaultRowHeight);
      return nextNode.rowIndex - numberOfRowDiff2;
    }
    const nextTop = this.topPx + this.heightPx;
    const numberOfRowDiff = Math.floor((nextTop - pixel) / defaultRowHeight);
    return this.getDisplayIndexEnd() - numberOfRowDiff;
  }
  /**
   * Given a path of group keys, returns the child store for that group.
   *
   * @param keys the grouping path to the desired store
   * @returns the child store for the given keys, or null if not found
   */
  getChildStore(keys) {
    return this.storeUtils.getChildStore(keys, this, (key) => {
      const lazyNode = this.cache.getNodes().find((lazyNode2) => lazyNode2.node.key == key);
      if (!lazyNode) {
        return null;
      }
      return lazyNode.node;
    });
  }
  /**
   * Executes a provided callback on each child store belonging to this store
   *
   * @param cb the callback to execute
   */
  forEachChildStoreShallow(cb) {
    this.cache.getNodes().forEach(({ node }) => {
      if (node.childStore) {
        cb(node.childStore);
      }
    });
  }
  /**
   * Executes after a change to sorting, determines recursively whether this store or a child requires refreshed.
   *
   * If a purge refresh occurs, the row count is preserved.
   *
   * @param params a set of properties pertaining to the sort changes
   */
  refreshAfterSort(params) {
    const serverSortsAllLevels = this.storeUtils.isServerSideSortAllLevels();
    if (serverSortsAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
      const allRowsLoaded = this.cache.isStoreFullyLoaded();
      const isClientSideSortingEnabled = this.gos.get("serverSideEnableClientSideSort");
      const isClientSideSort = allRowsLoaded && isClientSideSortingEnabled;
      if (!isClientSideSort) {
        const oldCount = this.cache.getRowCount();
        const lastKnown = this.cache.isLastRowIndexKnown();
        this.destroyBean(this.cache);
        this.cache = this.createManagedBean(new LazyCache(this, oldCount, lastKnown, this.storeParams));
        return;
      }
      this.cache.clientSideSortRows();
    }
    this.forEachChildStoreShallow((store) => store.refreshAfterSort(params));
  }
  /**
   * Executes after a change to filtering, determines recursively whether this store or a child requires refreshed.
   *
   * If a refresh occurs, the row count is reset.
   *
   * @param params a set of properties pertaining to the filter changes
   */
  refreshAfterFilter(params) {
    const serverFiltersAllLevels = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups();
    if (serverFiltersAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
      this.refreshStore(true);
      return;
    }
    this.forEachChildStoreShallow((store) => store.refreshAfterFilter(params));
  }
  /**
   * Marks all existing nodes as requiring reloaded, and triggers a load check
   *
   * @param purge whether to remove all nodes and data in favour of stub nodes
   */
  refreshStore(purge) {
    if (purge) {
      this.destroyBean(this.cache);
      this.cache = this.createManagedBean(new LazyCache(this, 1, false, this.storeParams));
      this.fireStoreUpdatedEvent();
      return;
    }
    this.cache.markNodesForRefresh();
  }
  /**
   * Used for pagination, given a local/store index, returns the display index of that row
   *
   * @param topLevelIndex the store index of a row
   * @returns the display index for the given store index
   */
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const displayIndex = this.cache.getDisplayIndexFromStoreIndex(topLevelIndex);
    return displayIndex ?? topLevelIndex;
  }
  /**
   * Used for pagination to determine if the last page is known, and for aria to determine if the last grid row is known
   *
   * @returns whether the last index of this store is known, or if lazy loading still required
   */
  isLastRowIndexKnown() {
    return this.cache.isLastRowIndexKnown();
  }
  /**
   * Used by the selection service to select a range of nodes
   *
   * @param firstInRange the first node in the range to find
   * @param lastInRange the last node in the range to find
   * @returns a range of nodes between firstInRange and lastInRange inclusive
   */
  getRowNodesInRange(firstInRange, lastInRange) {
    return this.cache.getNodes().filter(({ node }) => {
      return node.rowIndex >= firstInRange.rowIndex && node.rowIndex <= lastInRange.rowIndex;
    }).map(({ node }) => node);
  }
  /**
   * Mutates a given array to add this stores state, and recursively add all the children store states.
   *
   * @param result a mutable results array
   */
  addStoreStates(result) {
    result.push({
      suppressInfiniteScroll: false,
      route: this.parentRowNode.getRoute() ?? [],
      rowCount: this.getRowCount(),
      lastRowIndexKnown: this.isLastRowIndexKnown(),
      info: this.info,
      maxBlocksInCache: this.storeParams.maxBlocksInCache,
      cacheBlockSize: this.storeParams.cacheBlockSize
    });
    this.forEachChildStoreShallow((childStore) => childStore.addStoreStates(result));
  }
  getIdSequence() {
    return this.idSequence;
  }
  getParentNode() {
    return this.parentRowNode;
  }
  getRowDetails() {
    return {
      field: this.groupField,
      group: this.group,
      leafGroup: this.leafGroup,
      level: this.level,
      parent: this.parentRowNode,
      rowGroupColumn: this.rowGroupColumn
    };
  }
  getSsrmParams() {
    return this.ssrmParams;
  }
  setStoreInfo(info) {
    if (info) {
      Object.assign(this.info, info);
    }
  }
  // gets called 1) row count changed 2) cache purged
  fireStoreUpdatedEvent() {
    this.eventService.dispatchEvent({
      type: "storeUpdated"
    });
  }
  // gets called when row data updated, and no more refreshing needed
  fireRefreshFinishedEvent() {
    this.eventService.dispatchEvent({
      type: "storeRefreshed",
      route: this.parentRowNode.getRoute()
    });
  }
  getBlockStates() {
    return this.cache.getBlockStates();
  }
  getStoreBounds() {
    return {
      topPx: this.topPx,
      heightPx: this.heightPx
    };
  }
  getCache() {
    return this.cache;
  }
};
var ServerSideRowModel = class extends BeanStub103 {
  constructor() {
    super(...arguments);
    this.beanName = "rowModel";
    this.onRowHeightChanged_debounced = _debounce4(this.onRowHeightChanged.bind(this), 100);
    this.pauseStoreUpdateListening = false;
    this.started = false;
    this.managingPivotResultColumns = false;
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.columnNameService = beans.columnNameService;
    this.pivotResultColsService = beans.pivotResultColsService;
    this.funcColsService = beans.funcColsService;
    this.filterManager = beans.filterManager;
    this.sortController = beans.sortController;
    this.rowRenderer = beans.rowRenderer;
    this.nodeManager = beans.ssrmNodeManager;
    this.storeFactory = beans.ssrmStoreFactory;
    this.beans = beans;
    this.pivotColDefService = beans.pivotColDefService;
  }
  // we don't implement as lazy row heights is not supported in this row model
  ensureRowHeightsValid() {
    return false;
  }
  start() {
    this.started = true;
    this.updateDatasource();
  }
  destroyDatasource() {
    if (!this.datasource) {
      return;
    }
    if (this.datasource.destroy) {
      this.datasource.destroy();
    }
    this.rowRenderer.datasourceChanged();
    this.datasource = void 0;
  }
  postConstruct() {
    const resetListener = this.resetRootStore.bind(this);
    this.addManagedEventListeners({
      newColumnsLoaded: this.onColumnEverything.bind(this),
      storeUpdated: this.onStoreUpdated.bind(this),
      columnValueChanged: resetListener,
      columnPivotChanged: resetListener,
      columnRowGroupChanged: resetListener,
      columnPivotModeChanged: resetListener
    });
    this.addManagedPropertyListeners(
      [
        /**
         * Following properties omitted as they are likely to come with undesired  side effects.
         * 'getRowId', 'isRowMaster', 'getRowHeight', 'isServerSideGroup', 'getServerSideGroupKey',
         * */
        "masterDetail",
        "treeData",
        "removePivotHeaderRowWhenSingleValueColumn",
        "suppressServerSideInfiniteScroll",
        "cacheBlockSize"
      ],
      resetListener
    );
    this.addManagedPropertyListener("groupAllowUnbalanced", () => this.onStoreUpdated());
    this.addManagedPropertyListener("rowHeight", () => this.resetRowHeights());
    this.verifyProps();
    this.addManagedPropertyListener("serverSideDatasource", () => this.updateDatasource());
  }
  updateDatasource() {
    const datasource = this.gos.get("serverSideDatasource");
    if (datasource) {
      this.setDatasource(datasource);
    }
  }
  verifyProps() {
    if (this.gos.exists("initialGroupOrderComparator")) {
      _warnOnce63(`initialGroupOrderComparator cannot be used with Server Side Row Model.`);
    }
    if (_isRowSelection(this.gos) && !this.gos.exists("getRowId")) {
      _warnOnce63(`getRowId callback must be provided for Server Side Row Model selection to work correctly.`);
    }
  }
  setDatasource(datasource) {
    if (!this.started) {
      return;
    }
    this.destroyDatasource();
    this.datasource = datasource;
    this.resetRootStore();
  }
  applyRowData(rowDataParams, startRow, route) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    const storeToExecuteOn = rootStore.getChildStore(route);
    if (!storeToExecuteOn) {
      return;
    }
    if (storeToExecuteOn instanceof LazyStore) {
      storeToExecuteOn.applyRowData(rowDataParams, startRow, rowDataParams.rowData.length);
    } else if (storeToExecuteOn instanceof FullStore) {
      storeToExecuteOn.processServerResult(rowDataParams);
    }
  }
  isLastRowIndexKnown() {
    const cache = this.getRootStore();
    if (!cache) {
      return false;
    }
    return cache.isLastRowIndexKnown();
  }
  onColumnEverything() {
    if (!this.storeParams) {
      this.resetRootStore();
      return;
    }
    const rowGroupColumnVos = this.columnsToValueObjects(this.funcColsService.getRowGroupColumns());
    const valueColumnVos = this.columnsToValueObjects(this.funcColsService.getValueColumns());
    const pivotColumnVos = this.columnsToValueObjects(this.funcColsService.getPivotColumns());
    const areColsSame = (params) => {
      const oldColsMap = {};
      params.oldCols.forEach((col) => oldColsMap[col.id] = col);
      const allColsUnchanged = params.newCols.every((col) => {
        const equivalentCol = oldColsMap[col.id];
        if (equivalentCol) {
          delete oldColsMap[col.id];
        }
        return equivalentCol && equivalentCol.field === col.field && equivalentCol.aggFunc === col.aggFunc;
      });
      const missingCols = !params.allowRemovedColumns && !!Object.values(oldColsMap).length;
      return allColsUnchanged && !missingCols;
    };
    const sortModelDifferent = !_jsonEquals(this.storeParams.sortModel, this.sortController.getSortModel());
    const rowGroupDifferent = !areColsSame({
      oldCols: this.storeParams.rowGroupCols,
      newCols: rowGroupColumnVos
    });
    const pivotDifferent = !areColsSame({
      oldCols: this.storeParams.pivotCols,
      newCols: pivotColumnVos
    });
    const valuesDifferent = !!rowGroupColumnVos?.length && !areColsSame({
      oldCols: this.storeParams.valueCols,
      newCols: valueColumnVos,
      allowRemovedColumns: true
    });
    const resetRequired = sortModelDifferent || rowGroupDifferent || pivotDifferent || valuesDifferent;
    if (resetRequired) {
      this.resetRootStore();
    } else {
      const newParams = this.createStoreParams();
      this.storeParams.rowGroupCols = newParams.rowGroupCols;
      this.storeParams.pivotCols = newParams.pivotCols;
      this.storeParams.valueCols = newParams.valueCols;
    }
  }
  destroyRootStore() {
    if (!this.rootNode || !this.rootNode.childStore) {
      return;
    }
    this.rootNode.childStore = this.destroyBean(this.rootNode.childStore);
    this.nodeManager.clear();
  }
  refreshAfterSort(newSortModel, params) {
    if (this.storeParams) {
      this.storeParams.sortModel = newSortModel;
    }
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.refreshAfterSort(params);
    this.onStoreUpdated();
  }
  generateSecondaryColumns(pivotFields) {
    if (!this.pivotColDefService) {
      this.gos.assertModuleRegistered(ModuleNames16.RowGroupingModule, "pivotResultFields");
      return;
    }
    const pivotColumnGroupDefs = this.pivotColDefService.createColDefsFromFields(pivotFields);
    this.managingPivotResultColumns = true;
    this.pivotResultColsService.setPivotResultCols(pivotColumnGroupDefs, "rowModelUpdated");
  }
  resetRowHeights() {
    const atLeastOne = this.resetRowHeightsForAllRowNodes();
    const rootNodeHeight = _getRowHeightForNode3(this.gos, this.rootNode);
    this.rootNode.setRowHeight(rootNodeHeight.height, rootNodeHeight.estimated);
    if (this.rootNode.sibling) {
      const rootNodeSibling = _getRowHeightForNode3(this.gos, this.rootNode.sibling);
      this.rootNode.sibling.setRowHeight(rootNodeSibling.height, rootNodeSibling.estimated);
    }
    if (atLeastOne) {
      this.onRowHeightChanged();
    }
  }
  resetRowHeightsForAllRowNodes() {
    let atLeastOne = false;
    this.forEachNode((rowNode) => {
      const rowHeightForNode = _getRowHeightForNode3(this.gos, rowNode);
      rowNode.setRowHeight(rowHeightForNode.height, rowHeightForNode.estimated);
      const detailNode = rowNode.detailNode;
      if (detailNode) {
        const detailRowHeight = _getRowHeightForNode3(this.gos, detailNode);
        detailNode.setRowHeight(detailRowHeight.height, detailRowHeight.estimated);
      }
      if (rowNode.sibling) {
        const siblingRowHeight = _getRowHeightForNode3(this.gos, rowNode.sibling);
        detailNode.setRowHeight(siblingRowHeight.height, siblingRowHeight.estimated);
      }
      atLeastOne = true;
    });
    return atLeastOne;
  }
  resetRootStore() {
    this.destroyRootStore();
    this.rootNode = new RowNode32(this.beans);
    this.rootNode.group = true;
    this.rootNode.level = -1;
    if (this.datasource) {
      this.storeParams = this.createStoreParams();
      this.rootNode.childStore = this.createBean(this.storeFactory.createStore(this.storeParams, this.rootNode));
      this.updateRowIndexesAndBounds();
    }
    if (this.managingPivotResultColumns) {
      this.pivotResultColsService.setPivotResultCols(null, "api");
      this.managingPivotResultColumns = false;
    }
    this.dispatchModelUpdated(true);
  }
  columnsToValueObjects(columns) {
    return columns.map(
      (col) => ({
        id: col.getId(),
        aggFunc: col.getAggFunc(),
        displayName: this.columnNameService.getDisplayNameForColumn(col, "model"),
        field: col.getColDef().field
      })
    );
  }
  createStoreParams() {
    const rowGroupColumnVos = this.columnsToValueObjects(this.funcColsService.getRowGroupColumns());
    const valueColumnVos = this.columnsToValueObjects(this.funcColsService.getValueColumns());
    const pivotColumnVos = this.columnsToValueObjects(this.funcColsService.getPivotColumns());
    const dynamicRowHeight = _isGetRowHeightFunction(this.gos);
    const params = {
      // the columns the user has grouped and aggregated by
      valueCols: valueColumnVos,
      rowGroupCols: rowGroupColumnVos,
      pivotCols: pivotColumnVos,
      pivotMode: this.columnModel.isPivotMode(),
      // sort and filter model
      filterModel: this.filterManager?.isAdvancedFilterEnabled() ? this.filterManager?.getAdvancedFilterModel() : this.filterManager?.getFilterModel() ?? {},
      sortModel: this.sortController.getSortModel(),
      datasource: this.datasource,
      lastAccessedSequence: new NumberSequence3(),
      // blockSize: blockSize == null ? 100 : blockSize,
      dynamicRowHeight
    };
    return params;
  }
  getParams() {
    return this.storeParams;
  }
  dispatchModelUpdated(reset = false) {
    this.eventService.dispatchEvent({
      type: "modelUpdated",
      animate: !reset,
      keepRenderedRows: !reset,
      newPage: false,
      newData: false
    });
  }
  onStoreUpdated() {
    if (this.pauseStoreUpdateListening) {
      return;
    }
    this.updateRowIndexesAndBounds();
    this.dispatchModelUpdated();
  }
  /** This method is debounced. It is used for row auto-height. If we don't debounce,
   * then the Row Models will end up recalculating each row position
   * for each row height change and result in the Row Renderer laying out rows.
   * This is particularly bad if using print layout, and showing eg 1,000 rows,
   * each row will change it's height, causing Row Model to update 1,000 times.
   */
  onRowHeightChangedDebounced() {
    this.onRowHeightChanged_debounced();
  }
  onRowHeightChanged() {
    this.updateRowIndexesAndBounds();
    this.dispatchModelUpdated();
  }
  updateRowIndexesAndBounds() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.setDisplayIndexes(new NumberSequence3(), { value: 0 }, 0);
  }
  retryLoads() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.retryLoads();
    this.onStoreUpdated();
  }
  getRow(index) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return void 0;
    }
    return rootStore.getRowUsingDisplayIndex(index);
  }
  /**
   * Pauses the store, to prevent it updating the UI. This is used when doing batch updates to the store.
   */
  setPaused(paused) {
    this.pauseStoreUpdateListening = paused;
  }
  expandAll(value) {
    this.pauseStoreUpdateListening = true;
    this.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      if (node.hasChildren()) {
        node.setExpanded(value);
      }
    });
    this.pauseStoreUpdateListening = false;
    this.onStoreUpdated();
  }
  refreshAfterFilter(newFilterModel, params) {
    if (this.storeParams) {
      this.storeParams.filterModel = newFilterModel;
    }
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.refreshAfterFilter(params);
    this.onStoreUpdated();
  }
  getRootStore() {
    if (this.rootNode && this.rootNode.childStore) {
      return this.rootNode.childStore;
    }
  }
  getRowCount() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return 0;
    }
    return rootStore.getDisplayIndexEnd();
  }
  getTopLevelRowCount() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return 1;
    }
    return rootStore.getRowCount();
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return topLevelIndex;
    }
    return rootStore.getTopLevelRowDisplayedIndex(topLevelIndex);
  }
  getRowBounds(index) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      const rowHeight = _getRowHeightAsNumber4(this.gos);
      return {
        rowTop: 0,
        rowHeight
      };
    }
    return rootStore.getRowBounds(index);
  }
  getBlockStates() {
    const root = this.getRootStore();
    if (!root) {
      return void 0;
    }
    const states = {};
    root.forEachStoreDeep((store) => {
      if (store instanceof FullStore) {
        const { id, state } = store.getBlockStateJson();
        states[id] = state;
      } else if (store instanceof LazyStore) {
        Object.entries(store.getBlockStates()).forEach(([block, state]) => {
          states[block] = state;
        });
      } else {
        throw new Error("AG Grid: Unsupported store type");
      }
    });
    return states;
  }
  getRowIndexAtPixel(pixel) {
    const rootStore = this.getRootStore();
    if (pixel <= 0 || !rootStore) {
      return 0;
    }
    return rootStore.getRowIndexAtPixel(pixel);
  }
  isEmpty() {
    return false;
  }
  isRowsToRender() {
    return this.getRootStore() != null && this.getRowCount() > 0;
  }
  getType() {
    return "serverSide";
  }
  forEachNode(callback) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.forEachNodeDeep(callback);
  }
  forEachNodeAfterFilterAndSort(callback, includeFooterNodes = false) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.forEachNodeDeepAfterFilterAndSort(callback, void 0, includeFooterNodes);
  }
  /** @return false if store hasn't started */
  executeOnStore(route, callback) {
    if (!this.started) {
      return false;
    }
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return true;
    }
    const storeToExecuteOn = rootStore.getChildStore(route);
    if (storeToExecuteOn) {
      callback(storeToExecuteOn);
    }
    return true;
  }
  refreshStore(params = {}) {
    const route = params.route ? params.route : [];
    this.executeOnStore(route, (store) => store.refreshStore(params.purge == true));
  }
  getStoreState() {
    const res = [];
    const rootStore = this.getRootStore();
    if (rootStore) {
      rootStore.addStoreStates(res);
    }
    return res;
  }
  getNodesInRangeForSelection(firstInRange, lastInRange) {
    const startIndex = firstInRange.rowIndex;
    const endIndex = lastInRange.rowIndex;
    if (startIndex === null && endIndex === null) {
      return [];
    }
    if (endIndex === null) {
      return firstInRange ? [firstInRange] : [];
    }
    if (startIndex === null) {
      return [lastInRange];
    }
    const nodeRange = [];
    const [firstIndex, lastIndex] = [startIndex, endIndex].sort((a, b) => a - b);
    this.forEachNode((node) => {
      const thisRowIndex = node.rowIndex;
      if (thisRowIndex == null || node.stub) {
        return;
      }
      if (thisRowIndex >= firstIndex && thisRowIndex <= lastIndex) {
        nodeRange.push(node);
      }
    });
    if (nodeRange.length !== lastIndex - firstIndex + 1) {
      return firstInRange ? [firstInRange, lastInRange] : [];
    }
    return nodeRange;
  }
  getRowNode(id) {
    let result;
    this.forEachNode((rowNode) => {
      if (rowNode.id === id) {
        result = rowNode;
      }
      if (rowNode.detailNode && rowNode.detailNode.id === id) {
        result = rowNode.detailNode;
      }
    });
    return result;
  }
  isRowPresent(rowNode) {
    const foundRowNode = this.getRowNode(rowNode.id);
    return !!foundRowNode;
  }
  setRowCount(rowCount, lastRowIndexKnown) {
    const rootStore = this.getRootStore();
    if (rootStore) {
      if (rootStore instanceof LazyStore) {
        rootStore.setRowCount(rowCount, lastRowIndexKnown);
        return;
      }
      _errorOnce22("Infinite scrolling must be enabled in order to set the row count.");
    }
  }
  destroy() {
    this.destroyDatasource();
    this.destroyRootStore();
    super.destroy();
  }
};
function getServerSideSelectionState(beans) {
  return beans.selectionService.getSelectionState();
}
function setServerSideSelectionState(beans, state) {
  beans.selectionService.setSelectionState(state, "api");
}
function applyServerSideTransaction(beans, transaction) {
  return beans.ssrmTransactionManager?.applyTransaction(transaction);
}
function applyServerSideRowData(beans, params) {
  const startRow = params.startRow ?? 0;
  const route = params.route ?? [];
  if (startRow < 0) {
    _warnOnce73(`invalid value ${params.startRow} for startRow, the value should be >= 0`);
    return;
  }
  beans.rowModelHelperService?.getServerSideRowModel()?.applyRowData(params.successParams, startRow, route);
}
function applyServerSideTransactionAsync(beans, transaction, callback) {
  return beans.ssrmTransactionManager?.applyTransactionAsync(transaction, callback);
}
function retryServerSideLoads(beans) {
  beans.rowModelHelperService?.getServerSideRowModel()?.retryLoads();
}
function flushServerSideAsyncTransactions(beans) {
  return beans.ssrmTransactionManager?.flushAsyncTransactions();
}
function refreshServerSide(beans, params) {
  beans.rowModelHelperService?.getServerSideRowModel()?.refreshStore(params);
}
function getServerSideGroupLevelState(beans) {
  return beans.rowModelHelperService?.getServerSideRowModel()?.getStoreState() ?? [];
}
var ServerSideExpansionService = class extends ExpansionService {
  constructor() {
    super(...arguments);
    this.beanName = "expansionService";
    this.queuedRowIds = /* @__PURE__ */ new Set();
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.serverSideRowModel = beans.rowModel;
  }
  postConstruct() {
    super.postConstruct();
    this.addManagedEventListeners({
      columnRowGroupChanged: () => {
        this.queuedRowIds.clear();
      }
    });
  }
  checkOpenByDefault(rowNode) {
    if (!rowNode.isExpandable()) {
      return;
    }
    if (this.queuedRowIds.has(rowNode.id)) {
      this.queuedRowIds.delete(rowNode.id);
      rowNode.setExpanded(true);
      return;
    }
    const userFunc = this.gos.getCallback("isServerSideGroupOpenByDefault");
    if (!userFunc) {
      return;
    }
    const params = {
      data: rowNode.data,
      rowNode
    };
    const userFuncRes = userFunc(params);
    if (userFuncRes) {
      rowNode.setExpanded(true);
    }
  }
  expandRows(rowIds) {
    rowIds.forEach((rowId) => {
      const rowNode = this.serverSideRowModel.getRowNode(rowId);
      if (rowNode) {
        rowNode.setExpanded(true);
      } else {
        this.queuedRowIds.add(rowId);
      }
    });
  }
  expandAll(value) {
    this.serverSideRowModel.expandAll(value);
  }
  onGroupExpandedOrCollapsed() {
  }
};
var ServerSideRowRangeSelectionContext = class {
  constructor() {
    this.root = null;
    this.end = null;
    this.cachedRange = [];
  }
  init(rowModel) {
    this.rowModel = rowModel;
  }
  reset() {
    this.root = null;
    this.end = null;
    this.cachedRange.length = 0;
  }
  setRoot(node) {
    this.root = node;
    this.end = null;
    this.cachedRange.length = 0;
  }
  setEndRange(end) {
    this.end = end;
    this.cachedRange.length = 0;
  }
  getRoot() {
    return this.root;
  }
  getRange() {
    if (this.cachedRange.length === 0) {
      const root = this.root ? this.rowModel.getRowNode(this.root) : void 0;
      const end = this.end ? this.rowModel.getRowNode(this.end) : void 0;
      if (root == null || end == null) {
        return this.cachedRange;
      }
      this.cachedRange = this.rowModel.getNodesInRangeForSelection(root, end);
    }
    return this.cachedRange;
  }
  isInRange(node) {
    if (this.root === null) {
      return false;
    }
    return this.getRange().some((nodeInRange) => nodeInRange.id === node);
  }
  /**
   * Truncates the range to the given node (assumed to be within the current range).
   * Returns nodes that remain in the current range and those that should be removed
   *
   * @param node - Node at which to truncate the range
   * @returns Object of nodes to either keep or discard (i.e. deselect) from the range
   */
  truncate(node) {
    const range = this.getRange();
    if (range.length === 0) {
      return { keep: [], discard: [] };
    }
    const discardAfter = range[0].id === this.root;
    const idx = range.findIndex((rowNode) => rowNode.id === node);
    if (idx > -1) {
      const above = range.slice(0, idx);
      const below = range.slice(idx + 1);
      this.setEndRange(node);
      return discardAfter ? { keep: above, discard: below } : { keep: below, discard: above };
    } else {
      return { keep: range, discard: [] };
    }
  }
  /**
   * Extends the range to the given node. Returns nodes that remain in the current range
   * and those that should be removed.
   *
   * @param node - Node marking the new end of the range
   * @returns Object of nodes to either keep or discard (i.e. deselect) from the range
   */
  extend(node, groupSelectsChildren = false) {
    if (this.root == null) {
      const keep = this.getRange().slice();
      const rowNode2 = this.rowModel.getRowNode(node);
      if (rowNode2) {
        if (groupSelectsChildren) {
          rowNode2.depthFirstSearch((node2) => !node2.group && keep.push(node2));
        }
        keep.push(rowNode2);
      }
      this.setRoot(node);
      return { keep, discard: [] };
    }
    const rowNode = this.rowModel.getRowNode(node);
    const rootNode = this.rowModel.getRowNode(this.root);
    if (rowNode == null) {
      return { keep: this.getRange(), discard: [] };
    }
    if (rootNode == null) {
      return { keep: this.getRange().concat(rowNode), discard: [] };
    }
    const newRange = this.rowModel.getNodesInRangeForSelection(rootNode, rowNode);
    if (newRange.find((newRangeNode) => newRangeNode.id === this.end)) {
      this.setEndRange(node);
      return { keep: this.getRange(), discard: [] };
    } else {
      const discard = this.getRange().slice();
      this.setEndRange(node);
      return { keep: this.getRange(), discard };
    }
  }
};
var DefaultStrategy = class extends BeanStub113 {
  constructor() {
    super(...arguments);
    this.selectionCtx = new ServerSideRowRangeSelectionContext();
    this.selectedState = { selectAll: false, toggledNodes: /* @__PURE__ */ new Set() };
    this.selectAllUsed = false;
    this.selectedNodes = {};
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.selectionCtx.init(this.rowModel);
  }
  getSelectedState() {
    return {
      selectAll: this.selectedState.selectAll,
      toggledNodes: [...this.selectedState.toggledNodes]
    };
  }
  setSelectedState(state) {
    const newState = {
      selectAll: false,
      toggledNodes: /* @__PURE__ */ new Set()
    };
    if (typeof state !== "object") {
      return _errorOnce32("The provided selection state should be an object.");
    }
    if (!("selectAll" in state)) {
      return _errorOnce32("Invalid selection state. The state must conform to `IServerSideSelectionState`.");
    }
    if (typeof state.selectAll === "boolean") {
      newState.selectAll = state.selectAll;
    } else {
      return _errorOnce32("selectAll must be of boolean type.");
    }
    if ("toggledNodes" in state && Array.isArray(state.toggledNodes)) {
      state.toggledNodes.forEach((key) => {
        if (typeof key === "string") {
          newState.toggledNodes.add(key);
        } else {
          _warnOnce82(`Provided ids must be of string type. Invalid id provided: ${key}`);
        }
      });
    } else {
      return _warnOnce82("`toggledNodes` must be an array of string ids.");
    }
    const isSelectingMultipleRows = newState.selectAll || newState.toggledNodes.size > 1;
    if (_isUsingNewRowSelectionAPI(this.gos) && !_isMultiRowSelection(this.gos) && isSelectingMultipleRows) {
      return _warnOnce82(`cannot multi select unless selection mode is 'multiRow'`);
    }
    this.selectedState = newState;
  }
  deleteSelectionStateFromParent(parentPath, removedNodeIds) {
    if (this.selectedState.toggledNodes.size === 0) {
      return false;
    }
    let anyNodesToggled = false;
    removedNodeIds.forEach((id) => {
      if (this.selectedState.toggledNodes.delete(id)) {
        anyNodesToggled = true;
      }
    });
    return anyNodesToggled;
  }
  overrideSelectionValue(newValue, source) {
    if (!isSelectionUIEvent(source)) {
      return newValue;
    }
    const root = this.selectionCtx.getRoot();
    const node = root ? this.rowModel.getRowNode(root) : null;
    return node ? node.isSelected() ?? false : true;
  }
  setNodesSelected(params) {
    const { nodes, clearSelection, newValue, rangeSelect, source } = params;
    if (nodes.length === 0)
      return 0;
    const onlyThisNode = clearSelection && newValue && !rangeSelect;
    if (!_isMultiRowSelection(this.gos) || onlyThisNode) {
      if (nodes.length > 1) {
        throw new Error("AG Grid: cannot select multiple rows when rowSelection.mode is set to 'singleRow'");
      }
      const node = nodes[0];
      if (newValue && node.selectable) {
        this.selectedNodes = { [node.id]: node };
        this.selectedState = {
          selectAll: false,
          toggledNodes: /* @__PURE__ */ new Set([node.id])
        };
      } else {
        this.selectedNodes = {};
        this.selectedState = {
          selectAll: false,
          toggledNodes: /* @__PURE__ */ new Set()
        };
      }
      if (node.selectable) {
        this.selectionCtx.setRoot(node.id);
      }
      return 1;
    }
    const updateNodeState = (node, value = newValue) => {
      if (value && node.selectable) {
        this.selectedNodes[node.id] = node;
      } else {
        delete this.selectedNodes[node.id];
      }
      const doesNodeConform = value === this.selectedState.selectAll;
      if (doesNodeConform || !node.selectable) {
        this.selectedState.toggledNodes.delete(node.id);
      } else {
        this.selectedState.toggledNodes.add(node.id);
      }
    };
    if (rangeSelect) {
      if (nodes.length > 1) {
        throw new Error("AG Grid: cannot select multiple rows when using rangeSelect");
      }
      const node = nodes[0];
      const newSelectionValue = this.overrideSelectionValue(newValue, source);
      if (this.selectionCtx.isInRange(node.id)) {
        const partition = this.selectionCtx.truncate(node.id);
        if (newSelectionValue) {
          partition.discard.forEach((node2) => updateNodeState(node2, false));
        }
        partition.keep.forEach((node2) => updateNodeState(node2, newSelectionValue));
      } else {
        const fromNode = this.selectionCtx.getRoot();
        const toNode = node;
        if (fromNode !== toNode.id) {
          const partition = this.selectionCtx.extend(node.id);
          if (newSelectionValue) {
            partition.discard.forEach((node2) => updateNodeState(node2, false));
          }
          partition.keep.forEach((node2) => updateNodeState(node2, newSelectionValue));
        }
      }
      return 1;
    }
    nodes.forEach((node) => updateNodeState(node));
    this.selectionCtx.setRoot(_last12(nodes).id);
    return 1;
  }
  processNewRow(node) {
    if (this.selectedNodes[node.id]) {
      this.selectedNodes[node.id] = node;
    }
  }
  isNodeSelected(node) {
    const isToggled = this.selectedState.toggledNodes.has(node.id);
    return this.selectedState.selectAll ? !isToggled : isToggled;
  }
  getSelectedNodes() {
    if (this.selectAllUsed) {
      _warnOnce82(
        `getSelectedNodes and getSelectedRows functions cannot be used with select all functionality with the server-side row model.
                Use \`api.getServerSideSelectionState()\` instead.`
      );
    }
    return Object.values(this.selectedNodes);
  }
  getSelectedRows() {
    return this.getSelectedNodes().map((node) => node.data);
  }
  getSelectionCount() {
    if (this.selectedState.selectAll) {
      return -1;
    }
    return this.selectedState.toggledNodes.size;
  }
  clearOtherNodes(rowNodeToKeepSelected, source) {
    const clearedRows = this.selectedState.selectAll ? 1 : this.selectedState.toggledNodes.size - 1;
    this.selectedState = {
      selectAll: false,
      toggledNodes: /* @__PURE__ */ new Set([rowNodeToKeepSelected.id])
    };
    this.rowModel.forEachNode((node) => {
      if (node !== rowNodeToKeepSelected) {
        node.selectThisNode(false, void 0, source);
      }
    });
    this.eventService.dispatchEvent({
      type: "selectionChanged",
      source
    });
    return clearedRows;
  }
  isEmpty() {
    return !this.selectedState.selectAll && !this.selectedState.toggledNodes?.size;
  }
  selectAllRowNodes() {
    this.selectedState = { selectAll: true, toggledNodes: /* @__PURE__ */ new Set() };
    this.selectedNodes = {};
    this.selectAllUsed = true;
    this.selectionCtx.reset();
  }
  deselectAllRowNodes() {
    this.selectedState = { selectAll: false, toggledNodes: /* @__PURE__ */ new Set() };
    this.selectedNodes = {};
    this.selectionCtx.reset();
  }
  getSelectAllState() {
    if (this.selectedState.selectAll) {
      if (this.selectedState.toggledNodes.size > 0) {
        return null;
      }
      return true;
    }
    if (this.selectedState.toggledNodes.size > 0) {
      return null;
    }
    return false;
  }
};
var GroupSelectsChildrenStrategy = class extends BeanStub122 {
  constructor() {
    super(...arguments);
    this.selectionCtx = new ServerSideRowRangeSelectionContext();
    this.selectedState = { selectAllChildren: false, toggledNodes: /* @__PURE__ */ new Map() };
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
    this.funcColsService = beans.funcColsService;
    this.filterManager = beans.filterManager;
    this.selectionService = beans.selectionService;
  }
  postConstruct() {
    this.addManagedEventListeners({
      // if model has updated, a store may now be fully loaded to clean up indeterminate states
      modelUpdated: () => this.removeRedundantState(),
      // when the grouping changes, the state no longer makes sense, so reset the state.
      columnRowGroupChanged: () => this.selectionService.reset("rowGroupChanged")
    });
    this.selectionCtx.init(this.rowModel);
  }
  getSelectedState() {
    const treeData = this.gos.get("treeData");
    const recursivelySerializeState = (state, level, nodeId) => {
      const normalisedState = {
        nodeId
      };
      if (treeData || level <= this.funcColsService.getRowGroupColumns().length) {
        normalisedState.selectAllChildren = state.selectAllChildren;
      }
      if (state.toggledNodes.size) {
        const toggledNodes = [];
        state.toggledNodes.forEach((value, key) => {
          const newState = recursivelySerializeState(value, level + 1, key);
          toggledNodes.push(newState);
        });
        normalisedState.toggledNodes = toggledNodes;
      }
      return normalisedState;
    };
    return recursivelySerializeState(this.selectedState, 0);
  }
  setSelectedState(state) {
    if ("selectAll" in state) {
      return _errorOnce42(
        "Invalid selection state. When `groupSelectsChildren` is enabled, the state must conform to `IServerSideGroupSelectionState`."
      );
    }
    const recursivelyDeserializeState = (normalisedState, parentSelected) => {
      if (typeof normalisedState !== "object") {
        throw new Error("Each provided state object must be an object.");
      }
      if ("selectAllChildren" in normalisedState && typeof normalisedState.selectAllChildren !== "boolean") {
        throw new Error("`selectAllChildren` must be a boolean value or undefined.");
      }
      if ("toggledNodes" in normalisedState) {
        if (!Array.isArray(normalisedState.toggledNodes)) {
          throw new Error("`toggledNodes` must be an array.");
        }
        const allHaveIds = normalisedState.toggledNodes.every(
          (innerState) => typeof innerState === "object" && "nodeId" in innerState && typeof innerState.nodeId === "string"
        );
        if (!allHaveIds) {
          throw new Error("Every `toggledNode` requires an associated string id.");
        }
      }
      const isThisNodeSelected = normalisedState.selectAllChildren ?? !parentSelected;
      const convertedChildren = normalisedState.toggledNodes?.map((innerState) => [
        innerState.nodeId,
        recursivelyDeserializeState(innerState, isThisNodeSelected)
      ]);
      const doesRedundantStateExist = convertedChildren?.some(
        ([, innerState]) => isThisNodeSelected === innerState.selectAllChildren && innerState.toggledNodes.size === 0
      );
      if (doesRedundantStateExist) {
        throw new Error(`
                    Row selection state could not be parsed due to invalid data. Ensure all child state has toggledNodes or does not conform with the parent rule.
                    Please rebuild the selection state and reapply it.
                `);
      }
      return {
        selectAllChildren: isThisNodeSelected,
        toggledNodes: new Map(convertedChildren)
      };
    };
    try {
      this.selectedState = recursivelyDeserializeState(state, !!state.selectAllChildren);
    } catch (e) {
      _errorOnce42(e.message);
    }
  }
  deleteSelectionStateFromParent(parentRoute, removedNodeIds) {
    let parentState = this.selectedState;
    const remainingRoute = [...parentRoute];
    while (parentState && remainingRoute.length) {
      parentState = parentState.toggledNodes.get(remainingRoute.pop());
    }
    if (!parentState) {
      return false;
    }
    let anyStateChanged = false;
    removedNodeIds.forEach((id) => {
      if (parentState?.toggledNodes.delete(id)) {
        anyStateChanged = true;
      }
    });
    if (anyStateChanged) {
      this.removeRedundantState();
    }
    return anyStateChanged;
  }
  overrideSelectionValue(newValue, source) {
    if (!isSelectionUIEvent2(source)) {
      return newValue;
    }
    const root = this.selectionCtx.getRoot();
    const node = root ? this.rowModel.getRowNode(root) : null;
    return node ? node.isSelected() ?? false : true;
  }
  setNodesSelected({ nodes, newValue, rangeSelect, clearSelection, source }) {
    if (nodes.length === 0)
      return 0;
    if (rangeSelect) {
      if (nodes.length > 1) {
        throw new Error("AG Grid: cannot select multiple rows when using rangeSelect");
      }
      const node = nodes[0];
      const newSelectionValue = this.overrideSelectionValue(newValue, source);
      if (this.selectionCtx.isInRange(node.id)) {
        const partition = this.selectionCtx.truncate(node.id);
        if (newSelectionValue) {
          this.selectRange(partition.discard, false);
        }
        this.selectRange(partition.keep, newSelectionValue);
        return 1;
      } else {
        const fromNode = this.selectionCtx.getRoot();
        const toNode = node;
        if (fromNode !== toNode.id) {
          const partition = this.selectionCtx.extend(node.id, true);
          if (newSelectionValue) {
            this.selectRange(partition.discard, false);
          }
          this.selectRange(partition.keep, newSelectionValue);
          return 1;
        }
      }
      return 1;
    }
    const onlyThisNode = clearSelection && newValue && !rangeSelect;
    if (!_isMultiRowSelection2(this.gos) || onlyThisNode) {
      if (nodes.length > 1) {
        throw new Error("AG Grid: cannot select multiple rows when rowSelection.mode is set to 'singleRow'");
      }
      this.deselectAllRowNodes();
    }
    nodes.forEach((node) => {
      const idPathToNode = this.getRouteToNode(node);
      this.recursivelySelectNode(idPathToNode, this.selectedState, newValue);
    });
    this.removeRedundantState();
    this.selectionCtx.setRoot(_last23(nodes).id);
    return 1;
  }
  selectRange(nodes, newValue) {
    const routes = nodes.map(this.getRouteToNode).sort((a, b) => b.length - a.length);
    const seen = /* @__PURE__ */ new Set();
    routes.forEach((route) => {
      if (seen.has(_last23(route))) {
        return;
      }
      route.forEach((part) => seen.add(part));
      this.recursivelySelectNode(route, this.selectedState, newValue);
    });
    this.removeRedundantState();
  }
  isNodeSelected(node) {
    const path = this.getRouteToNode(node);
    return this.isNodePathSelected(path, this.selectedState);
  }
  isNodePathSelected([nextNode, ...nodes], state) {
    if (nodes.length === 0) {
      const isToggled = state.toggledNodes.has(nextNode.id);
      if (nextNode.hasChildren()) {
        const groupState = state.toggledNodes.get(nextNode.id);
        if (groupState && groupState.toggledNodes.size) {
          return void 0;
        }
      }
      return state.selectAllChildren ? !isToggled : isToggled;
    }
    if (state.toggledNodes.has(nextNode.id)) {
      const nextState = state.toggledNodes.get(nextNode.id);
      if (nextState) {
        return this.isNodePathSelected(nodes, nextState);
      }
    }
    return state.selectAllChildren;
  }
  getRouteToNode(node) {
    const pathToNode = [];
    let tempNode = node;
    while (tempNode.parent) {
      pathToNode.push(tempNode);
      tempNode = tempNode.parent;
    }
    return pathToNode.reverse();
  }
  removeRedundantState() {
    if (this.filterManager?.isAnyFilterPresent()) {
      return;
    }
    const forEachNodeStateDepthFirst = (state = this.selectedState, thisKey, parentState) => {
      state.toggledNodes.forEach((value, key) => {
        forEachNodeStateDepthFirst(value, key, state);
      });
      if (thisKey) {
        const thisRow = this.rowModel.getRowNode(thisKey);
        const thisRowStore = thisRow?.childStore;
        const isStoreSizeKnown = thisRowStore?.isLastRowIndexKnown();
        if (isStoreSizeKnown) {
          const possibleAllNodesToggled = state.toggledNodes.size >= thisRowStore.getRowCount();
          if (possibleAllNodesToggled) {
            for (const childState of state.toggledNodes.entries()) {
              const [key, value] = childState;
              if (value.toggledNodes.size > 0) {
                return;
              }
              const rowDoesNotExist = !this.rowModel.getRowNode(key);
              if (rowDoesNotExist) {
                return;
              }
            }
            state.selectAllChildren = !state.selectAllChildren;
            state.toggledNodes.clear();
          }
        }
      }
      const hasNoToggledRows = state.toggledNodes.size === 0;
      const isIdenticalToParent = parentState?.selectAllChildren === state.selectAllChildren;
      if (hasNoToggledRows && isIdenticalToParent) {
        parentState?.toggledNodes.delete(thisKey);
      }
    };
    forEachNodeStateDepthFirst();
  }
  recursivelySelectNode([nextNode, ...nodes], selectedState, newValue) {
    if (!nextNode) {
      return;
    }
    const isLastNode = !nodes.length;
    if (isLastNode) {
      const isNodeSelectable = nextNode.selectable;
      const doesNodeConform = selectedState.selectAllChildren === newValue;
      if (doesNodeConform || !isNodeSelectable) {
        selectedState.toggledNodes.delete(nextNode.id);
        return;
      }
      const newState = {
        selectAllChildren: newValue,
        toggledNodes: /* @__PURE__ */ new Map()
      };
      selectedState.toggledNodes.set(nextNode.id, newState);
      return;
    }
    const doesStateAlreadyExist = selectedState.toggledNodes.has(nextNode.id);
    const childState = selectedState.toggledNodes.get(nextNode.id) ?? {
      selectAllChildren: selectedState.selectAllChildren,
      toggledNodes: /* @__PURE__ */ new Map()
    };
    if (!doesStateAlreadyExist) {
      selectedState.toggledNodes.set(nextNode.id, childState);
    }
    this.recursivelySelectNode(nodes, childState, newValue);
    if (selectedState.selectAllChildren === childState.selectAllChildren && childState.toggledNodes.size === 0) {
      selectedState.toggledNodes.delete(nextNode.id);
    }
  }
  getSelectedNodes() {
    _warnOnce92(
      `\`getSelectedNodes\` and \`getSelectedRows\` functions cannot be used with \`groupSelectsChildren\` and the server-side row model.
            Use \`api.getServerSideSelectionState()\` instead.`
    );
    const selectedNodes = [];
    this.rowModel.forEachNode((node) => {
      if (node.isSelected()) {
        selectedNodes.push(node);
      }
    });
    return selectedNodes;
  }
  processNewRow() {
  }
  getSelectedRows() {
    return this.getSelectedNodes().map((node) => node.data);
  }
  getSelectionCount() {
    return -1;
  }
  isEmpty() {
    return !this.selectedState.selectAllChildren && !this.selectedState.toggledNodes?.size;
  }
  selectAllRowNodes() {
    this.selectedState = { selectAllChildren: true, toggledNodes: /* @__PURE__ */ new Map() };
    this.selectionCtx.reset();
  }
  deselectAllRowNodes() {
    this.selectedState = { selectAllChildren: false, toggledNodes: /* @__PURE__ */ new Map() };
    this.selectionCtx.reset();
  }
  getSelectAllState() {
    if (this.selectedState.selectAllChildren) {
      if (this.selectedState.toggledNodes.size > 0) {
        return null;
      }
      return true;
    }
    if (this.selectedState.toggledNodes.size > 0) {
      return null;
    }
    return false;
  }
};
var ServerSideSelectionService = class extends BeanStub132 {
  constructor() {
    super(...arguments);
    this.beanName = "selectionService";
  }
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.addManagedPropertyListeners(["groupSelectsChildren", "rowSelection"], () => {
      const groupSelectsChildren2 = _getGroupSelectsDescendants(this.gos);
      const Strategy2 = groupSelectsChildren2 && this.selectionStrategy instanceof DefaultStrategy ? GroupSelectsChildrenStrategy : !groupSelectsChildren2 && this.selectionStrategy instanceof GroupSelectsChildrenStrategy ? DefaultStrategy : void 0;
      if (Strategy2) {
        this.destroyBean(this.selectionStrategy);
        this.selectionStrategy = this.createManagedBean(new Strategy2());
        this.shotgunResetNodeSelectionState();
        this.dispatchSelectionChanged("api");
      }
    });
    this.addManagedPropertyListeners(["rowSelection"], () => {
      const rowSelection = _getRowSelectionMode(this.gos);
      if (rowSelection !== this.selectionMode) {
        this.selectionMode = rowSelection;
        this.deselectAllRowNodes({ source: "api" });
      }
    });
    this.selectionMode = _getRowSelectionMode(this.gos);
    const groupSelectsChildren = _getGroupSelectsDescendants(this.gos);
    const Strategy = !groupSelectsChildren ? DefaultStrategy : GroupSelectsChildrenStrategy;
    this.selectionStrategy = this.createManagedBean(new Strategy());
  }
  getSelectionState() {
    return this.selectionStrategy.getSelectedState();
  }
  setSelectionState(state, source) {
    if (Array.isArray(state)) {
      return;
    }
    this.selectionStrategy.setSelectedState(state);
    this.shotgunResetNodeSelectionState();
    this.dispatchSelectionChanged(source);
  }
  setNodesSelected(params) {
    const { nodes, ...otherParams } = params;
    if (nodes.length > 1 && this.selectionMode !== "multiRow") {
      _warnOnce102(`cannot multi select unless selection mode is 'multiRow'`);
      return 0;
    }
    if (nodes.length > 1 && params.rangeSelect) {
      _warnOnce102(`cannot use range selection when multi selecting rows`);
      return 0;
    }
    const adjustedParams = {
      nodes: nodes.filter((node) => node.selectable),
      ...otherParams
    };
    if (!adjustedParams.nodes.length) {
      return 0;
    }
    const changedNodes = this.selectionStrategy.setNodesSelected(adjustedParams);
    this.shotgunResetNodeSelectionState(adjustedParams.source);
    this.dispatchSelectionChanged(adjustedParams.source);
    return changedNodes;
  }
  /**
   * Deletes the selection state for a set of nodes, for use after deleting nodes via
   * transaction. As this is designed for transactions, all nodes should belong to the same group.
   */
  deleteSelectionStateFromParent(storeRoute, removedNodeIds) {
    const stateChanged = this.selectionStrategy.deleteSelectionStateFromParent(storeRoute, removedNodeIds);
    if (!stateChanged) {
      return;
    }
    this.shotgunResetNodeSelectionState();
    this.dispatchSelectionChanged("api");
  }
  shotgunResetNodeSelectionState(source) {
    this.rowModel.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      const isNodeSelected = this.selectionStrategy.isNodeSelected(node);
      if (isNodeSelected !== node.isSelected()) {
        node.selectThisNode(isNodeSelected, void 0, source);
      }
    });
  }
  getSelectedNodes() {
    return this.selectionStrategy.getSelectedNodes();
  }
  getSelectedRows() {
    return this.selectionStrategy.getSelectedRows();
  }
  getSelectionCount() {
    return this.selectionStrategy.getSelectionCount();
  }
  syncInRowNode(rowNode) {
    this.selectionStrategy.processNewRow(rowNode);
    const isNodeSelected = this.selectionStrategy.isNodeSelected(rowNode);
    if (isNodeSelected != false && !rowNode.selectable) {
      this.selectionStrategy.setNodesSelected({
        nodes: [rowNode],
        newValue: false,
        source: "api"
      });
      this.shotgunResetNodeSelectionState();
      this.dispatchSelectionChanged("api");
      return;
    }
    rowNode.setSelectedInitialValue(isNodeSelected);
  }
  reset() {
    this.selectionStrategy.deselectAllRowNodes({ source: "api" });
  }
  isEmpty() {
    return this.selectionStrategy.isEmpty();
  }
  hasNodesToSelect() {
    return true;
  }
  selectAllRowNodes(params) {
    validateSelectionParameters(params);
    if (_isUsingNewRowSelectionAPI2(this.gos) && _getRowSelectionMode(this.gos) !== "multiRow") {
      return _warnOnce102("cannot multi select unless selection mode is 'multiRow'");
    }
    this.selectionStrategy.selectAllRowNodes(params);
    this.rowModel.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      node.selectThisNode(true, void 0, params.source);
    });
    this.dispatchSelectionChanged(params.source);
  }
  deselectAllRowNodes(params) {
    validateSelectionParameters(params);
    this.selectionStrategy.deselectAllRowNodes(params);
    this.rowModel.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      node.selectThisNode(false, void 0, params.source);
    });
    this.dispatchSelectionChanged(params.source);
  }
  getSelectAllState(justFiltered, justCurrentPage) {
    return this.selectionStrategy.getSelectAllState(justFiltered, justCurrentPage);
  }
  // used by CSRM
  updateGroupsFromChildrenSelections() {
    return false;
  }
  // used by CSRM
  getBestCostNodeSelection() {
    _warnOnce102("calling gridApi.getBestCostNodeSelection() is only possible when using rowModelType=`clientSide`.");
    return void 0;
  }
  // used by CSRM
  filterFromSelection() {
    return;
  }
  dispatchSelectionChanged(source) {
    this.eventService.dispatchEvent({
      type: "selectionChanged",
      source
    });
  }
};
function validateSelectionParameters({
  justCurrentPage,
  justFiltered
}) {
  if (justCurrentPage || justFiltered) {
    _warnOnce102(
      `selecting just ${justCurrentPage ? "current page" : "filtered"} only works when gridOptions.rowModelType='clientSide'`
    );
  }
}
var StoreFactory = class extends BeanStub142 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmStoreFactory";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.funcColsService = beans.funcColsService;
  }
  createStore(ssrmParams, parentNode) {
    const storeParams = this.getStoreParams(ssrmParams, parentNode);
    const CacheClass = storeParams.suppressInfiniteScroll ? FullStore : LazyStore;
    return new CacheClass(ssrmParams, storeParams, parentNode);
  }
  getStoreParams(ssrmParams, parentNode) {
    const userStoreParams = this.getLevelSpecificParams(parentNode);
    const infiniteScroll = this.isInfiniteScroll(userStoreParams);
    const cacheBlockSize = this.getBlockSize(infiniteScroll, userStoreParams);
    const maxBlocksInCache = this.getMaxBlocksInCache(infiniteScroll, ssrmParams, userStoreParams);
    const storeParams = {
      suppressInfiniteScroll: !infiniteScroll,
      cacheBlockSize,
      maxBlocksInCache
    };
    return storeParams;
  }
  getMaxBlocksInCache(infiniteScroll, ssrmParams, userStoreParams) {
    if (!infiniteScroll) {
      return void 0;
    }
    const maxBlocksInCache = userStoreParams && userStoreParams.maxBlocksInCache != null ? userStoreParams.maxBlocksInCache : this.gos.get("maxBlocksInCache");
    const maxBlocksActive = maxBlocksInCache != null && maxBlocksInCache >= 0;
    if (!maxBlocksActive) {
      return void 0;
    }
    if (ssrmParams.dynamicRowHeight) {
      const message = "Server Side Row Model does not support Dynamic Row Height and Cache Purging. Either a) remove getRowHeight() callback or b) remove maxBlocksInCache property. Purging has been disabled.";
      _warnOnce112(message);
      return void 0;
    }
    if (this.columnModel.isAutoRowHeightActive()) {
      const message = "Server Side Row Model does not support Auto Row Height and Cache Purging. Either a) remove colDef.autoHeight or b) remove maxBlocksInCache property. Purging has been disabled.";
      _warnOnce112(message);
      return void 0;
    }
    return maxBlocksInCache;
  }
  getBlockSize(infiniteScroll, userStoreParams) {
    if (!infiniteScroll) {
      return void 0;
    }
    const blockSize = userStoreParams && userStoreParams.cacheBlockSize != null ? userStoreParams.cacheBlockSize : this.gos.get("cacheBlockSize");
    if (blockSize != null && blockSize > 0) {
      return blockSize;
    } else {
      return 100;
    }
  }
  getLevelSpecificParams(parentNode) {
    const callback = this.gos.getCallback("getServerSideGroupLevelParams");
    if (!callback) {
      return void 0;
    }
    const params = {
      level: parentNode.level + 1,
      parentRowNode: parentNode.level >= 0 ? parentNode : void 0,
      rowGroupColumns: this.funcColsService.getRowGroupColumns(),
      pivotColumns: this.funcColsService.getPivotColumns(),
      pivotMode: this.columnModel.isPivotMode()
    };
    const res = callback(params);
    return res;
  }
  isInfiniteScroll(storeParams) {
    const res = storeParams && storeParams.suppressInfiniteScroll != null ? storeParams.suppressInfiniteScroll : this.isSuppressServerSideInfiniteScroll();
    return !res;
  }
  isSuppressServerSideInfiniteScroll() {
    return this.gos.get("suppressServerSideInfiniteScroll");
  }
};
var StoreUtils = class extends BeanStub152 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmStoreUtils";
  }
  wireBeans(beans) {
    this.columnModel = beans.columnModel;
    this.serverSideRowModel = beans.rowModel;
    this.storeFactory = beans.ssrmStoreFactory;
  }
  loadFromDatasource(p) {
    const { storeParams, parentBlock, parentNode } = p;
    const groupKeys = parentNode.getRoute() ?? [];
    if (!storeParams.datasource) {
      return;
    }
    const request = {
      startRow: p.startRow,
      endRow: p.endRow,
      rowGroupCols: storeParams.rowGroupCols,
      valueCols: storeParams.valueCols,
      pivotCols: storeParams.pivotCols,
      pivotMode: storeParams.pivotMode,
      groupKeys,
      filterModel: storeParams.filterModel,
      sortModel: storeParams.sortModel
    };
    const getRowsParams = this.gos.addGridCommonParams({
      success: p.success,
      fail: p.fail,
      request,
      parentNode: p.parentNode
    });
    window.setTimeout(() => {
      if (!storeParams.datasource || !parentBlock.isAlive()) {
        p.fail();
        return;
      }
      storeParams.datasource.getRows(getRowsParams);
    }, 0);
  }
  getChildStore(keys, currentCache, findNodeFunc) {
    if (_missingOrEmpty22(keys)) {
      return currentCache;
    }
    const nextKey = keys[0];
    const nextNode = findNodeFunc(nextKey);
    if (nextNode) {
      if (keys.length === 1 && !nextNode.childStore) {
        const storeParams = this.serverSideRowModel.getParams();
        nextNode.childStore = this.createBean(this.storeFactory.createStore(storeParams, nextNode));
      }
      const keyListForNextLevel = keys.slice(1, keys.length);
      const nextStore = nextNode.childStore;
      return nextStore ? nextStore.getChildStore(keyListForNextLevel) : null;
    }
    return null;
  }
  isServerRefreshNeeded(parentRowNode, rowGroupCols, params) {
    if (params.valueColChanged || params.secondaryColChanged) {
      return true;
    }
    const level = parentRowNode.level + 1;
    const grouping = level < rowGroupCols.length;
    const leafNodes = !grouping;
    if (leafNodes) {
      return true;
    }
    const colIdThisGroup = rowGroupCols[level].id;
    const actionOnThisGroup = params.changedColumns.indexOf(colIdThisGroup) > -1;
    if (actionOnThisGroup) {
      return true;
    }
    const allCols = this.columnModel.getCols();
    const affectedGroupCols = allCols.filter((col) => col.getColDef().showRowGroup && params.changedColumns.includes(col.getId())).map((col) => col.getColDef().showRowGroup).some((group) => group === true || group === colIdThisGroup);
    return affectedGroupCols;
  }
  getServerSideInitialRowCount() {
    return this.gos.get("serverSideInitialRowCount");
  }
  assertRowModelIsServerSide(key) {
    if (!_isServerSideRowModel4(this.gos)) {
      _warnOnce122(`The '${key}' property can only be used with the Server Side Row Model.`);
      return false;
    }
    return true;
  }
  assertNotTreeData(key) {
    if (this.gos.get("treeData")) {
      _warnOnce122(`The '${key}' property cannot be used while using tree data.`);
      return false;
    }
    return true;
  }
  isServerSideSortAllLevels() {
    return this.gos.get("serverSideSortAllLevels") && this.assertRowModelIsServerSide("serverSideSortAllLevels");
  }
  isServerSideOnlyRefreshFilteredGroups() {
    return this.gos.get("serverSideOnlyRefreshFilteredGroups") && this.assertRowModelIsServerSide("serverSideOnlyRefreshFilteredGroups");
  }
  isServerSideSortOnServer() {
    return this.gos.get("serverSideSortOnServer") && this.assertRowModelIsServerSide("serverSideSortOnServer") && this.assertNotTreeData("serverSideSortOnServer");
  }
  isServerSideFilterOnServer() {
    return this.gos.get("serverSideFilterOnServer") && this.assertRowModelIsServerSide("serverSideFilterOnServer") && this.assertNotTreeData("serverSideFilterOnServer");
  }
};
var TransactionManager = class extends BeanStub162 {
  constructor() {
    super(...arguments);
    this.beanName = "ssrmTransactionManager";
    this.asyncTransactions = [];
  }
  wireBeans(beans) {
    this.valueCache = beans.valueCache;
    this.serverSideRowModel = beans.rowModel;
    this.selectionService = beans.selectionService;
  }
  postConstruct() {
    if (!_isServerSideRowModel5(this.gos)) {
      return;
    }
  }
  applyTransactionAsync(transaction, callback) {
    if (this.asyncTransactionsTimeout == null) {
      this.scheduleExecuteAsync();
    }
    this.asyncTransactions.push({ transaction, callback });
  }
  scheduleExecuteAsync() {
    const waitMillis = this.gos.get("asyncTransactionWaitMillis");
    this.asyncTransactionsTimeout = window.setTimeout(() => {
      this.executeAsyncTransactions();
    }, waitMillis);
  }
  executeAsyncTransactions() {
    if (!this.asyncTransactions) {
      return;
    }
    const resultFuncs = [];
    const resultsForEvent = [];
    const transactionsToRetry = [];
    let atLeastOneTransactionApplied = false;
    this.asyncTransactions.forEach((txWrapper) => {
      let result;
      const hasStarted = this.serverSideRowModel.executeOnStore(txWrapper.transaction.route, (cache) => {
        result = cache.applyTransaction(txWrapper.transaction);
      });
      if (!hasStarted) {
        result = { status: ServerSideTransactionResultStatus3.StoreNotStarted };
      } else if (result == void 0) {
        result = { status: ServerSideTransactionResultStatus3.StoreNotFound };
      }
      resultsForEvent.push(result);
      const retryTransaction = result.status == ServerSideTransactionResultStatus3.StoreLoading;
      if (retryTransaction) {
        transactionsToRetry.push(txWrapper);
        return;
      }
      if (txWrapper.callback) {
        resultFuncs.push(() => txWrapper.callback(result));
      }
      if (result.status === ServerSideTransactionResultStatus3.Applied) {
        atLeastOneTransactionApplied = true;
      }
    });
    if (resultFuncs.length > 0) {
      window.setTimeout(() => {
        resultFuncs.forEach((func) => func());
      }, 0);
    }
    this.asyncTransactionsTimeout = void 0;
    this.asyncTransactions = transactionsToRetry;
    if (atLeastOneTransactionApplied) {
      this.valueCache.onDataChanged();
      this.eventService.dispatchEvent({ type: "storeUpdated" });
    }
    if (resultsForEvent.length > 0) {
      this.eventService.dispatchEvent({
        type: "asyncTransactionsFlushed",
        results: resultsForEvent
      });
    }
  }
  flushAsyncTransactions() {
    if (this.asyncTransactionsTimeout != null) {
      clearTimeout(this.asyncTransactionsTimeout);
    }
    this.executeAsyncTransactions();
  }
  applyTransaction(transaction) {
    let res;
    const hasStarted = this.serverSideRowModel.executeOnStore(transaction.route, (store) => {
      res = store.applyTransaction(transaction);
    });
    if (!hasStarted) {
      return { status: ServerSideTransactionResultStatus3.StoreNotStarted };
    } else if (res) {
      this.valueCache.onDataChanged();
      if (res.remove) {
        const removedRowIds = res.remove.map((row) => row.id);
        this.selectionService.deleteSelectionStateFromParent(transaction.route || [], removedRowIds);
      }
      this.eventService.dispatchEvent({ type: "storeUpdated" });
      return res;
    } else {
      return { status: ServerSideTransactionResultStatus3.StoreNotFound };
    }
  }
};
var VERSION15 = "32.3.0";
var ServerSideRowModelCoreModule = _defineModule15({
  version: VERSION15,
  moduleName: `${ModuleNames25.ServerSideRowModelModule}-core`,
  rowModel: "serverSide",
  beans: [
    ServerSideRowModel,
    ExpandListener,
    SortListener,
    StoreUtils,
    BlockUtils,
    NodeManager,
    TransactionManager,
    FilterListener,
    StoreFactory,
    ListenerUtils,
    ServerSideSelectionService,
    ServerSideExpansionService,
    LazyBlockLoadingService
  ],
  dependantModules: [EnterpriseCoreModule, _RowNodeBlockModule]
});
var ServerSideRowModelApiModule = _defineModule15({
  version: VERSION15,
  moduleName: `${ModuleNames25.ServerSideRowModelModule}-api`,
  beans: [RowModelHelperService],
  apiFunctions: {
    getServerSideSelectionState,
    setServerSideSelectionState,
    applyServerSideTransaction,
    applyServerSideTransactionAsync,
    applyServerSideRowData,
    retryServerSideLoads,
    flushServerSideAsyncTransactions,
    refreshServerSide,
    getServerSideGroupLevelState
  },
  dependantModules: [ServerSideRowModelCoreModule, _CsrmSsrmSharedApiModule, _SsrmInfiniteSharedApiModule]
});
var ServerSideRowModelModule = _defineModule15({
  version: VERSION15,
  moduleName: ModuleNames25.ServerSideRowModelModule,
  dependantModules: [ServerSideRowModelCoreModule, ServerSideRowModelApiModule]
});

// enterprise-modules/set-filter/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames17, _ColumnFilterModule as _ColumnFilterModule4, _FloatingFilterModule as _FloatingFilterModule2, _defineModule as _defineModule16 } from "ag-grid-community";
import {
  AgInputTextFieldSelector as AgInputTextFieldSelector6,
  AgPromise as AgPromise32,
  GROUP_AUTO_COLUMN_ID as GROUP_AUTO_COLUMN_ID3,
  KeyCode as KeyCode21,
  ProvidedFilter as ProvidedFilter2,
  RefPlaceholder as RefPlaceholder210,
  _areEqual as _areEqual5,
  _getActiveDomElement as _getActiveDomElement13,
  _last as _last24,
  _makeNull as _makeNull4,
  _setDisplayed as _setDisplayed26,
  _toStringOrNull as _toStringOrNull3,
  _warnOnce as _warnOnce46
} from "ag-grid-community";
import {
  AgCheckboxSelector as AgCheckboxSelector5,
  Component as Component58,
  RefPlaceholder as RefPlaceholder37,
  _createIcon as _createIcon3,
  _setAriaChecked,
  _setAriaDescribedBy as _setAriaDescribedBy3,
  _setAriaExpanded as _setAriaExpanded9,
  _setAriaLabel as _setAriaLabel10,
  _setAriaLabelledBy as _setAriaLabelledBy3,
  _setAriaLevel as _setAriaLevel4,
  _setDisplayed as _setDisplayed13,
  _toStringOrNull as _toStringOrNull4,
  _warnOnce as _warnOnce39
} from "ag-grid-community";
import { _last as _last13, _makeNull as _makeNull5, _toStringOrNull as _toStringOrNull22 } from "ag-grid-community";
import {
  AgPromise as AgPromise24,
  LocalEventService as LocalEventService2,
  _defaultComparator,
  _errorOnce as _errorOnce6,
  _exists as _exists17,
  _isClientSideRowModel as _isClientSideRowModel5,
  _makeNull as _makeNull32,
  _warnOnce as _warnOnce310
} from "ag-grid-community";
import { AgPromise as AgPromise7, _makeNull as _makeNull22 } from "ag-grid-community";
import { _warnOnce as _warnOnce212 } from "ag-grid-community";
import { AgInputTextFieldSelector as AgInputTextFieldSelector23, Component as Component214, RefPlaceholder as RefPlaceholder38 } from "ag-grid-community";
var SetFilterDisplayValue = class {
};
SetFilterDisplayValue.SELECT_ALL = "__AG_SELECT_ALL__";
SetFilterDisplayValue.ADD_SELECTION_TO_FILTER = "__AG_ADD_SELECTION_TO_FILTER__";
var DEFAULT_LOCALE_TEXT = {
  loadingOoo: "Loading...",
  blanks: "(Blanks)",
  searchOoo: "Search...",
  selectAll: "(Select All)",
  selectAllSearchResults: "(Select All Search Results)",
  addCurrentSelectionToFilter: "Add current selection to filter",
  noMatches: "No matches."
};
var SetFilterListItem = class extends Component58 {
  constructor(params) {
    super(
      params.isGroup ? (
        /* html */
        `
            <div class="ag-set-filter-item" aria-hidden="true">
                <span class="ag-set-filter-group-icons">
                    <span class="ag-set-filter-group-closed-icon" data-ref="eGroupClosedIcon"></span>
                    <span class="ag-set-filter-group-opened-icon" data-ref="eGroupOpenedIcon"></span>
                    <span class="ag-set-filter-group-indeterminate-icon" data-ref="eGroupIndeterminateIcon"></span>
                </span>
                <ag-checkbox data-ref="eCheckbox" class="ag-set-filter-item-checkbox"></ag-checkbox>
            </div>`
      ) : (
        /* html */
        `
            <div class="ag-set-filter-item">
                <ag-checkbox data-ref="eCheckbox" class="ag-set-filter-item-checkbox"></ag-checkbox>
            </div>`
      ),
      [AgCheckboxSelector5]
    );
    this.eCheckbox = RefPlaceholder37;
    this.eGroupOpenedIcon = RefPlaceholder37;
    this.eGroupClosedIcon = RefPlaceholder37;
    this.eGroupIndeterminateIcon = RefPlaceholder37;
    this.focusWrapper = params.focusWrapper;
    this.value = params.value;
    this.params = params.params;
    this.translate = params.translate;
    this.valueFormatter = params.valueFormatter;
    this.item = params.item;
    this.isSelected = params.isSelected;
    this.isTree = params.isTree;
    this.depth = params.depth ?? 0;
    this.isGroup = params.isGroup;
    this.groupsExist = params.groupsExist;
    this.isExpanded = params.isExpanded;
    this.hasIndeterminateExpandState = params.hasIndeterminateExpandState;
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.userComponentFactory = beans.userComponentFactory;
  }
  postConstruct() {
    this.addDestroyFunc(() => this.destroyCellRendererComponent?.());
    this.render();
    this.eCheckbox.setLabelEllipsis(true).setValue(this.isSelected, true).setDisabled(!!this.params.readOnly).getInputElement().setAttribute("tabindex", "-1");
    this.refreshVariableAriaLabels();
    if (this.isTree) {
      if (this.depth > 0) {
        this.addCssClass("ag-set-filter-indent-" + this.depth);
        this.getGui().style.setProperty("--ag-indentation-level", String(this.depth));
      }
      if (this.isGroup) {
        this.setupExpansion();
      } else {
        if (this.groupsExist) {
          this.addCssClass("ag-set-filter-add-group-indent");
        }
      }
      _setAriaLevel4(this.getAriaElement(), this.depth + 1);
    }
    this.refreshAriaChecked();
    if (this.params.readOnly) {
      return;
    }
    this.eCheckbox.onValueChange((value) => this.onCheckboxChanged(!!value));
  }
  getFocusableElement() {
    return this.focusWrapper;
  }
  setupExpansion() {
    this.eGroupClosedIcon.appendChild(_createIcon3("setFilterGroupClosed", this.gos, null));
    this.eGroupOpenedIcon.appendChild(_createIcon3("setFilterGroupOpen", this.gos, null));
    const listener = this.onExpandOrContractClicked.bind(this);
    this.addManagedElementListeners(this.eGroupClosedIcon, { click: listener });
    this.addManagedElementListeners(this.eGroupOpenedIcon, { click: listener });
    if (this.hasIndeterminateExpandState) {
      this.eGroupIndeterminateIcon.appendChild(_createIcon3("setFilterGroupIndeterminate", this.gos, null));
      this.addManagedElementListeners(this.eGroupIndeterminateIcon, {
        click: listener
      });
    }
    this.setExpandedIcons();
    this.refreshAriaExpanded();
  }
  onExpandOrContractClicked() {
    this.setExpanded(!this.isExpanded);
  }
  setExpanded(isExpanded, silent) {
    if (this.isGroup && isExpanded !== this.isExpanded) {
      this.isExpanded = isExpanded;
      const event = {
        type: "expandedChanged",
        isExpanded: !!isExpanded,
        item: this.item
      };
      if (!silent) {
        this.dispatchLocalEvent(event);
      }
      this.setExpandedIcons();
      this.refreshAriaExpanded();
    }
  }
  setExpandedIcons() {
    _setDisplayed13(
      this.eGroupClosedIcon,
      this.hasIndeterminateExpandState ? this.isExpanded === false : !this.isExpanded
    );
    _setDisplayed13(this.eGroupOpenedIcon, this.isExpanded === true);
    if (this.hasIndeterminateExpandState) {
      _setDisplayed13(this.eGroupIndeterminateIcon, this.isExpanded === void 0);
    }
  }
  onCheckboxChanged(isSelected) {
    this.isSelected = isSelected;
    const event = {
      type: "selectionChanged",
      isSelected,
      item: this.item
    };
    this.dispatchLocalEvent(event);
    this.refreshVariableAriaLabels();
    this.refreshAriaChecked();
  }
  toggleSelected() {
    if (this.params.readOnly) {
      return;
    }
    this.setSelected(!this.isSelected);
  }
  setSelected(isSelected, silent) {
    this.isSelected = isSelected;
    this.eCheckbox.setValue(isSelected, silent);
    this.refreshAriaChecked();
  }
  refreshVariableAriaLabels() {
    if (!this.isTree) {
      return;
    }
    const translate = this.localeService.getLocaleTextFunc();
    const checkboxValue = this.eCheckbox.getValue();
    const state = checkboxValue === void 0 ? translate("ariaIndeterminate", "indeterminate") : checkboxValue ? translate("ariaVisible", "visible") : translate("ariaHidden", "hidden");
    const visibilityLabel = translate("ariaToggleVisibility", "Press SPACE to toggle visibility");
    _setAriaLabelledBy3(this.eCheckbox.getInputElement(), void 0);
    this.eCheckbox.setInputAriaLabel(`${visibilityLabel} (${state})`);
  }
  setupFixedAriaLabels(value) {
    if (!this.isTree) {
      return;
    }
    const translate = this.localeService.getLocaleTextFunc();
    const itemLabel = translate("ariaFilterValue", "Filter Value");
    const ariaEl = this.getAriaElement();
    _setAriaLabel10(ariaEl, `${value} ${itemLabel}`);
    _setAriaDescribedBy3(ariaEl, this.eCheckbox.getInputElement().id);
  }
  refreshAriaChecked() {
    const ariaEl = this.getAriaElement();
    _setAriaChecked(ariaEl, this.eCheckbox.getValue());
  }
  refreshAriaExpanded() {
    _setAriaExpanded9(this.getAriaElement(), !!this.isExpanded);
  }
  refresh(item, isSelected, isExpanded) {
    this.item = item;
    if (isSelected !== this.isSelected) {
      this.setSelected(isSelected, true);
    }
    this.setExpanded(isExpanded, true);
    if (this.valueFunction) {
      const value = this.valueFunction();
      this.setTooltipAndCellRendererParams(value, value);
      if (!this.cellRendererComponent) {
        this.renderCellWithoutCellRenderer();
      }
    }
    if (this.cellRendererComponent) {
      const success = this.cellRendererComponent.refresh?.(this.cellRendererParams);
      if (!success) {
        const oldComponent = this.cellRendererComponent;
        this.renderCell();
        this.destroyBean(oldComponent);
      }
    }
  }
  render() {
    const {
      params: { column }
    } = this;
    let { value } = this;
    let formattedValue = null;
    if (typeof value === "function") {
      this.valueFunction = value;
      formattedValue = this.valueFunction();
      value = formattedValue;
    } else if (this.isTree) {
      formattedValue = _toStringOrNull4(value);
    } else {
      formattedValue = this.getFormattedValue(column, value);
    }
    this.setTooltipAndCellRendererParams(value, formattedValue);
    this.renderCell();
  }
  setTooltipAndCellRendererParams(value, formattedValue) {
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    if (this.params.showTooltips && (!isTooltipWhenTruncated || !this.params.cellRenderer)) {
      const newTooltipText = formattedValue != null ? formattedValue : _toStringOrNull4(value);
      let shouldDisplayTooltip;
      if (isTooltipWhenTruncated) {
        shouldDisplayTooltip = () => {
          const el = this.eCheckbox.getGui().querySelector(".ag-label");
          if (!el) {
            return true;
          }
          return el.scrollWidth > el.clientWidth;
        };
      }
      this.setTooltip({
        newTooltipText,
        location: "setFilterValue",
        getColDef: () => this.params.colDef,
        getColumn: () => this.params.column,
        shouldDisplayTooltip
      });
    }
    this.cellRendererParams = this.gos.addGridCommonParams({
      value,
      valueFormatted: formattedValue,
      colDef: this.params.colDef,
      column: this.params.column,
      setTooltip: (value2, shouldDisplayTooltip) => {
        this.setTooltip({
          newTooltipText: value2,
          getColDef: () => this.params.colDef,
          getColumn: () => this.params.column,
          location: "setFilterValue",
          shouldDisplayTooltip
        });
      }
    });
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "setFilterValue";
    res.colDef = this.getComponentHolder();
    if (this.isTree) {
      res.level = this.depth;
    }
    return res;
  }
  getFormattedValue(column, value) {
    return this.valueService.formatValue(column, null, value, this.valueFormatter, false);
  }
  renderCell() {
    const compDetails = this.userComponentFactory.getSetFilterCellRendererDetails(
      this.params,
      this.cellRendererParams
    );
    const cellRendererPromise = compDetails ? compDetails.newAgStackInstance() : void 0;
    if (cellRendererPromise == null) {
      this.renderCellWithoutCellRenderer();
      return;
    }
    cellRendererPromise.then((component) => {
      if (component) {
        this.cellRendererComponent = component;
        this.eCheckbox.setLabel(component.getGui());
        this.destroyCellRendererComponent = () => this.destroyBean(component);
      }
    });
  }
  renderCellWithoutCellRenderer() {
    let valueToRender = (this.cellRendererParams.valueFormatted == null ? this.cellRendererParams.value : this.cellRendererParams.valueFormatted) ?? this.translate("blanks");
    if (typeof valueToRender !== "string") {
      _warnOnce39(
        `Set Filter Value Formatter must return string values. Please ensure the Set Filter Value Formatter returns string values for complex objects. See ${this.getFrameworkOverrides().getDocLink("filter-set-filter-list/#filter-value-types")}`
      );
      valueToRender = "";
    }
    this.eCheckbox.setLabel(valueToRender);
    this.setupFixedAriaLabels(valueToRender);
  }
  getComponentHolder() {
    return this.params.column.getColDef();
  }
};
var SetFilterModelFormatter = class {
  getModelAsString(model, setFilter) {
    const { values } = model || setFilter.getModel() || {};
    const valueModel = setFilter.getValueModel();
    if (values == null || valueModel == null) {
      return "";
    }
    const availableKeys = values.filter((v) => valueModel.isKeyAvailable(v));
    const numValues = availableKeys.length;
    const formattedValues = availableKeys.slice(0, 10).map((key) => setFilter.getFormattedValue(key));
    return `(${numValues}) ${formattedValues.join(",")}${numValues > 10 ? ",..." : ""}`;
  }
};
function processDataPath(dataPath, treeData, groupAllowUnbalanced) {
  let processedDataPath = dataPath;
  if (!processedDataPath) {
    return null;
  }
  processedDataPath = processedDataPath.map((treeKey) => _toStringOrNull22(_makeNull5(treeKey)));
  if (!treeData && groupAllowUnbalanced && processedDataPath.some((treeKey) => treeKey == null)) {
    if (_last13(processedDataPath) == null) {
      return null;
    }
    return processedDataPath.filter((treeKey) => treeKey != null);
  }
  return processedDataPath;
}
var ClientSideValuesExtractor = class {
  constructor(rowModel, filterParams, createKey, caseFormat, funcColsService, valueService, treeDataOrGrouping, treeData, getDataPath, groupAllowUnbalanced, addManagedEventListeners) {
    this.rowModel = rowModel;
    this.filterParams = filterParams;
    this.createKey = createKey;
    this.caseFormat = caseFormat;
    this.funcColsService = funcColsService;
    this.valueService = valueService;
    this.treeDataOrGrouping = treeDataOrGrouping;
    this.treeData = treeData;
    this.getDataPath = getDataPath;
    this.groupAllowUnbalanced = groupAllowUnbalanced;
    this.addManagedEventListeners = addManagedEventListeners;
  }
  extractUniqueValuesAsync(predicate, existingValues) {
    return new AgPromise7((resolve) => {
      if (this.rowModel.isRowDataLoaded()) {
        resolve(this.extractUniqueValues(predicate, existingValues));
      } else {
        const [destroyFunc] = this.addManagedEventListeners({
          rowCountReady: () => {
            destroyFunc?.();
            resolve(this.extractUniqueValues(predicate, existingValues));
          }
        });
      }
    });
  }
  extractUniqueValues(predicate, existingValues) {
    const values = /* @__PURE__ */ new Map();
    const existingFormattedKeys = this.extractExistingFormattedKeys(existingValues);
    const formattedKeys = /* @__PURE__ */ new Set();
    const treeData = this.treeData && !!this.getDataPath;
    const groupedCols = this.funcColsService.getRowGroupColumns();
    const addValue = (unformattedKey, value) => {
      const formattedKey = this.caseFormat(unformattedKey);
      if (!formattedKeys.has(formattedKey)) {
        formattedKeys.add(formattedKey);
        let keyToAdd = unformattedKey;
        let valueToAdd = _makeNull22(value);
        const existingUnformattedKey = existingFormattedKeys?.get(formattedKey);
        if (existingUnformattedKey != null) {
          keyToAdd = existingUnformattedKey;
          valueToAdd = existingValues.get(existingUnformattedKey);
        }
        values.set(keyToAdd, valueToAdd);
      }
    };
    this.rowModel.forEachLeafNode((node) => {
      if (!node.data || !predicate(node)) {
        return;
      }
      if (this.treeDataOrGrouping) {
        this.addValueForTreeDataOrGrouping(node, treeData, groupedCols, addValue);
        return;
      }
      const value = this.getValue(node);
      if (value != null && Array.isArray(value)) {
        value.forEach((x) => {
          addValue(this.createKey(x, node), x);
        });
        if (value.length === 0) {
          addValue(null, null);
        }
      } else {
        addValue(this.createKey(value, node), value);
      }
    });
    return values;
  }
  addValueForTreeDataOrGrouping(node, treeData, groupedCols, addValue) {
    let dataPath;
    if (treeData) {
      if (node.childrenAfterGroup?.length) {
        return;
      }
      dataPath = this.getDataPath(node.data);
    } else {
      dataPath = groupedCols.map((groupCol) => this.valueService.getKeyForNode(groupCol, node));
      dataPath.push(this.getValue(node));
    }
    const processedDataPath = processDataPath(dataPath, treeData, this.groupAllowUnbalanced);
    addValue(this.createKey(processedDataPath), processedDataPath);
  }
  getValue(node) {
    return this.filterParams.getValue(node);
  }
  extractExistingFormattedKeys(existingValues) {
    if (!existingValues) {
      return null;
    }
    const existingFormattedKeys = /* @__PURE__ */ new Map();
    existingValues.forEach((_value, key) => {
      existingFormattedKeys.set(this.caseFormat(key), key);
    });
    return existingFormattedKeys;
  }
};
var SetValueModelFilteringKeys = class {
  constructor({ caseFormat }) {
    this.filteringKeys = null;
    this.filteringKeysCaseFormatted = null;
    this.hasNoAppliedFilteringKeys = false;
    this.caseFormat = caseFormat;
  }
  allFilteringKeys() {
    return this.filteringKeys;
  }
  allFilteringKeysCaseFormatted() {
    return this.filteringKeysCaseFormatted;
  }
  noAppliedFilteringKeys() {
    return this.hasNoAppliedFilteringKeys;
  }
  setFilteringKeys(filteringKeys) {
    this.filteringKeys = new Set(filteringKeys);
    this.hasNoAppliedFilteringKeys = !this.filteringKeys || this.filteringKeys.size === 0;
    this.filteringKeysCaseFormatted = /* @__PURE__ */ new Set();
    this.filteringKeys.forEach((key) => this.filteringKeysCaseFormatted.add(this.caseFormat(key)));
  }
  addFilteringKey(key) {
    if (this.filteringKeys == null) {
      this.filteringKeys = /* @__PURE__ */ new Set();
      this.filteringKeysCaseFormatted = /* @__PURE__ */ new Set();
    }
    this.filteringKeys.add(key);
    this.filteringKeysCaseFormatted.add(this.caseFormat(key));
    if (this.hasNoAppliedFilteringKeys) {
      this.hasNoAppliedFilteringKeys = false;
    }
  }
  hasCaseFormattedFilteringKey(key) {
    return this.filteringKeysCaseFormatted.has(this.caseFormat(key));
  }
  hasFilteringKey(key) {
    return this.filteringKeys.has(key);
  }
  reset() {
    this.filteringKeys = null;
    this.filteringKeysCaseFormatted = null;
    this.hasNoAppliedFilteringKeys = false;
  }
};
var FlatSetDisplayValueModel = class {
  constructor(valueService, valueFormatter, formatter, column) {
    this.valueService = valueService;
    this.valueFormatter = valueFormatter;
    this.formatter = formatter;
    this.column = column;
    this.displayedKeys = [];
  }
  updateDisplayedValuesToAllAvailable(_getValue, _allKeys, availableKeys) {
    this.displayedKeys = Array.from(availableKeys);
  }
  updateDisplayedValuesToMatchMiniFilter(getValue, _allKeys, availableKeys, matchesFilter, nullMatchesFilter) {
    this.displayedKeys = [];
    for (const key of availableKeys) {
      if (key == null) {
        if (nullMatchesFilter) {
          this.displayedKeys.push(key);
        }
      } else {
        const value = getValue(key);
        const valueFormatterValue = this.valueService.formatValue(
          this.column,
          null,
          value,
          this.valueFormatter,
          false
        );
        const textFormatterValue = this.formatter(valueFormatterValue);
        if (matchesFilter(textFormatterValue)) {
          this.displayedKeys.push(key);
        }
      }
    }
  }
  getDisplayedValueCount() {
    return this.displayedKeys.length;
  }
  getDisplayedItem(index) {
    return this.displayedKeys[index];
  }
  getSelectAllItem() {
    return SetFilterDisplayValue.SELECT_ALL;
  }
  getAddSelectionToFilterItem() {
    return SetFilterDisplayValue.ADD_SELECTION_TO_FILTER;
  }
  getDisplayedKeys() {
    return this.displayedKeys;
  }
  forEachDisplayedKey(func) {
    this.displayedKeys.forEach(func);
  }
  someDisplayedKey(func) {
    return this.displayedKeys.some(func);
  }
  hasGroups() {
    return false;
  }
  refresh() {
  }
};
var DATE_TREE_LIST_PATH_GETTER = (date) => date ? [String(date.getFullYear()), String(date.getMonth() + 1), String(date.getDate())] : null;
var TreeSetDisplayValueModel = class {
  constructor(formatter, treeListPathGetter, treeListFormatter, treeDataOrGrouping) {
    this.formatter = formatter;
    this.treeListPathGetter = treeListPathGetter;
    this.treeListFormatter = treeListFormatter;
    this.treeDataOrGrouping = treeDataOrGrouping;
    this.allDisplayedItemsTree = [];
    this.activeDisplayedItemsFlat = [];
    this.selectAllItem = {
      depth: 0,
      filterPasses: true,
      available: true,
      treeKey: SetFilterDisplayValue.SELECT_ALL,
      children: this.allDisplayedItemsTree,
      expanded: true,
      key: SetFilterDisplayValue.SELECT_ALL,
      parentTreeKeys: []
    };
    this.addSelectionToFilterItem = {
      depth: 0,
      filterPasses: true,
      available: true,
      treeKey: SetFilterDisplayValue.ADD_SELECTION_TO_FILTER,
      expanded: true,
      key: SetFilterDisplayValue.ADD_SELECTION_TO_FILTER,
      parentTreeKeys: []
    };
  }
  updateOnParamsChange(treeListFormatter) {
    this.treeListFormatter = treeListFormatter;
  }
  updateDisplayedValuesToAllAvailable(getValue, allKeys, availableKeys, source) {
    if (source === "reload") {
      this.generateItemTree(getValue, allKeys, availableKeys);
    } else if (source === "otherFilter") {
      this.updateAvailable(availableKeys);
      this.updateExpandAll();
    } else if (source === "miniFilter") {
      this.resetFilter();
      this.updateExpandAll();
    }
    this.flattenItems();
  }
  updateDisplayedValuesToMatchMiniFilter(getValue, allKeys, availableKeys, matchesFilter, nullMatchesFilter, source) {
    if (source === "reload") {
      this.generateItemTree(getValue, allKeys, availableKeys);
    } else if (source === "otherFilter") {
      this.updateAvailable(availableKeys);
    }
    this.updateFilter(matchesFilter, nullMatchesFilter);
    this.updateExpandAll();
    this.flattenItems();
  }
  generateItemTree(getValue, allKeys, availableKeys) {
    this.allDisplayedItemsTree = [];
    this.groupsExist = false;
    const treeListPathGetter = this.getTreeListPathGetter(getValue, availableKeys);
    for (const key of allKeys) {
      const value = getValue(key);
      const dataPath = treeListPathGetter(value) ?? [null];
      if (dataPath.length > 1) {
        this.groupsExist = true;
      }
      const available = availableKeys.has(key);
      let children = this.allDisplayedItemsTree;
      let item;
      let parentTreeKeys = [];
      dataPath.forEach((treeKey, depth) => {
        if (!children) {
          children = [];
          item.children = children;
        }
        item = children.find((child) => child.treeKey?.toUpperCase() === treeKey?.toUpperCase());
        if (!item) {
          item = { treeKey, depth, filterPasses: true, expanded: false, available, parentTreeKeys };
          if (depth === dataPath.length - 1) {
            item.key = key;
          }
          children.push(item);
        }
        children = item.children;
        parentTreeKeys = [...parentTreeKeys, treeKey];
      });
    }
    this.updateAvailable(availableKeys);
    this.selectAllItem.children = this.allDisplayedItemsTree;
    this.selectAllItem.expanded = false;
  }
  getTreeListPathGetter(getValue, availableKeys) {
    if (this.treeListPathGetter) {
      return this.treeListPathGetter;
    }
    if (this.treeDataOrGrouping) {
      return (value) => value;
    }
    let isDate2 = false;
    for (const availableKey of availableKeys) {
      const value = getValue(availableKey);
      if (value instanceof Date) {
        isDate2 = true;
        break;
      } else if (value != null) {
        break;
      }
    }
    if (isDate2) {
      return DATE_TREE_LIST_PATH_GETTER;
    }
    _warnOnce212(
      "property treeList=true for Set Filter params, but you did not provide a treeListPathGetter or values of type Date."
    );
    return (value) => [String(value)];
  }
  flattenItems() {
    this.activeDisplayedItemsFlat = [];
    const recursivelyFlattenDisplayedItems = (items) => {
      items.forEach((item) => {
        if (!item.filterPasses || !item.available) {
          return;
        }
        this.activeDisplayedItemsFlat.push(item);
        if (item.children && item.expanded) {
          recursivelyFlattenDisplayedItems(item.children);
        }
      });
    };
    recursivelyFlattenDisplayedItems(this.allDisplayedItemsTree);
  }
  resetFilter() {
    const recursiveFilterReset = (item) => {
      if (item.children) {
        item.children.forEach((child) => {
          recursiveFilterReset(child);
        });
      }
      item.filterPasses = true;
    };
    this.allDisplayedItemsTree.forEach((item) => recursiveFilterReset(item));
  }
  updateFilter(matchesFilter, nullMatchesFilter) {
    const passesFilter = (item) => {
      if (!item.available) {
        return false;
      }
      if (item.treeKey == null) {
        return nullMatchesFilter;
      }
      return matchesFilter(
        this.formatter(
          this.treeListFormatter ? this.treeListFormatter(item.treeKey, item.depth, item.parentTreeKeys) : item.treeKey
        )
      );
    };
    this.allDisplayedItemsTree.forEach(
      (item) => this.recursiveItemCheck(item, false, passesFilter, "filterPasses")
    );
  }
  getDisplayedValueCount() {
    return this.activeDisplayedItemsFlat.length;
  }
  getDisplayedItem(index) {
    return this.activeDisplayedItemsFlat[index];
  }
  getSelectAllItem() {
    return this.selectAllItem;
  }
  getAddSelectionToFilterItem() {
    return this.addSelectionToFilterItem;
  }
  getDisplayedKeys() {
    const displayedKeys = [];
    this.forEachDisplayedKey((key) => displayedKeys.push(key));
    return displayedKeys;
  }
  forEachDisplayedKey(func) {
    const recursiveForEachItem = (item, topParentExpanded) => {
      if (item.children) {
        if (!item.expanded || !topParentExpanded) {
          item.children.forEach((child) => {
            if (child.filterPasses) {
              recursiveForEachItem(child, false);
            }
          });
        }
      } else {
        func(item.key);
      }
    };
    this.activeDisplayedItemsFlat.forEach((item) => recursiveForEachItem(item, true));
  }
  someDisplayedKey(func) {
    const recursiveSomeItem = (item, topParentExpanded) => {
      if (item.children) {
        if (!item.expanded || !topParentExpanded) {
          return item.children.some((child) => {
            if (child.filterPasses) {
              return recursiveSomeItem(child, false);
            }
            return false;
          });
        }
      } else {
        return func(item.key);
      }
      return false;
    };
    return this.activeDisplayedItemsFlat.some((item) => recursiveSomeItem(item, true));
  }
  hasGroups() {
    return this.groupsExist;
  }
  refresh() {
    this.updateExpandAll();
    this.flattenItems();
  }
  updateExpandAll() {
    const recursiveExpansionCheck = (items, someTrue, someFalse) => {
      for (const item2 of items) {
        if (!item2.filterPasses || !item2.available || !item2.children) {
          continue;
        }
        someTrue = someTrue || !!item2.expanded;
        someFalse = someFalse || !item2.expanded;
        if (someTrue && someFalse) {
          return void 0;
        }
        const childExpanded = recursiveExpansionCheck(item2.children, someTrue, someFalse);
        if (childExpanded === void 0) {
          return void 0;
        } else if (childExpanded) {
          someTrue = true;
        } else {
          someFalse = true;
        }
      }
      return someTrue && someFalse ? void 0 : someTrue;
    };
    const item = this.getSelectAllItem();
    item.expanded = recursiveExpansionCheck(item.children, false, false);
  }
  recursiveItemCheck(item, parentPasses, checkFunction, itemProp) {
    let atLeastOneChildPassed = false;
    if (item.children) {
      item.children.forEach((child) => {
        const childPasses = this.recursiveItemCheck(
          child,
          parentPasses || checkFunction(item),
          checkFunction,
          itemProp
        );
        atLeastOneChildPassed = atLeastOneChildPassed || childPasses;
      });
    }
    const itemPasses = parentPasses || atLeastOneChildPassed || checkFunction(item);
    item[itemProp] = itemPasses;
    return itemPasses;
  }
  updateAvailable(availableKeys) {
    const isAvailable = (item) => availableKeys.has(item.key);
    this.allDisplayedItemsTree.forEach((item) => this.recursiveItemCheck(item, false, isAvailable, "available"));
  }
};
var SetValueModel = class {
  constructor(params) {
    this.localEventService = new LocalEventService2();
    this.miniFilterText = null;
    this.addCurrentSelectionToFilter = false;
    this.providedValues = null;
    this.allValues = /* @__PURE__ */ new Map();
    this.availableKeys = /* @__PURE__ */ new Set();
    this.selectedKeys = /* @__PURE__ */ new Set();
    this.initialised = false;
    const {
      usingComplexObjects,
      funcColsService,
      valueService,
      treeDataTreeList,
      groupingTreeList,
      filterParams,
      gos,
      valueFormatter,
      addManagedEventListeners
    } = params;
    const {
      column,
      colDef,
      textFormatter,
      doesRowPassOtherFilter,
      suppressSorting,
      comparator,
      rowModel,
      values,
      caseSensitive,
      treeList,
      treeListPathGetter,
      treeListFormatter
    } = filterParams;
    this.filterParams = filterParams;
    this.gos = gos;
    this.setIsLoading = params.setIsLoading;
    this.translate = params.translate;
    this.caseFormat = params.caseFormat;
    this.createKey = params.createKey;
    this.usingComplexObjects = !!params.usingComplexObjects;
    this.formatter = textFormatter ?? ((value) => value ?? null);
    this.doesRowPassOtherFilters = doesRowPassOtherFilter;
    this.suppressSorting = suppressSorting || false;
    this.filteringKeys = new SetValueModelFilteringKeys({ caseFormat: this.caseFormat });
    const keyComparator = comparator ?? colDef.comparator;
    const treeDataOrGrouping = !!treeDataTreeList || !!groupingTreeList;
    this.compareByValue = !!(usingComplexObjects && keyComparator || treeDataOrGrouping || treeList && !treeListPathGetter);
    if (treeDataOrGrouping && !keyComparator) {
      this.entryComparator = this.createTreeDataOrGroupingComparator();
    } else if (treeList && !treeListPathGetter && !keyComparator) {
      this.entryComparator = ([_aKey, aValue], [_bKey, bValue]) => _defaultComparator(aValue, bValue);
    } else {
      this.entryComparator = ([_aKey, aValue], [_bKey, bValue]) => keyComparator(aValue, bValue);
    }
    this.keyComparator = keyComparator ?? _defaultComparator;
    this.caseSensitive = !!caseSensitive;
    const getDataPath = gos.get("getDataPath");
    const groupAllowUnbalanced = gos.get("groupAllowUnbalanced");
    if (_isClientSideRowModel5(gos)) {
      this.clientSideValuesExtractor = new ClientSideValuesExtractor(
        rowModel,
        this.filterParams,
        this.createKey,
        this.caseFormat,
        funcColsService,
        valueService,
        treeDataOrGrouping,
        !!treeDataTreeList,
        getDataPath,
        groupAllowUnbalanced,
        addManagedEventListeners
      );
    }
    if (values == null) {
      this.valuesType = 2;
    } else {
      this.valuesType = Array.isArray(values) ? 0 : 1;
      this.providedValues = values;
    }
    this.displayValueModel = treeList ? new TreeSetDisplayValueModel(
      this.formatter,
      treeListPathGetter,
      treeListFormatter,
      treeDataTreeList || groupingTreeList
    ) : new FlatSetDisplayValueModel(
      valueService,
      valueFormatter,
      this.formatter,
      column
    );
    this.updateAllValues().then((updatedKeys) => this.resetSelectionState(updatedKeys || []));
  }
  addEventListener(eventType, listener, async) {
    this.localEventService.addEventListener(eventType, listener, async);
  }
  removeEventListener(eventType, listener, async) {
    this.localEventService.removeEventListener(eventType, listener, async);
  }
  updateOnParamsChange(filterParams) {
    return new AgPromise24((resolve) => {
      const { values, textFormatter, suppressSorting, treeListFormatter } = filterParams;
      const currentProvidedValues = this.providedValues;
      const currentSuppressSorting = this.suppressSorting;
      this.filterParams = filterParams;
      this.formatter = textFormatter ?? ((value) => value ?? null);
      this.suppressSorting = suppressSorting || false;
      this.providedValues = values ?? null;
      if (this.displayValueModel instanceof TreeSetDisplayValueModel) {
        this.displayValueModel.updateOnParamsChange(treeListFormatter);
      }
      if (this.providedValues !== currentProvidedValues || this.suppressSorting !== currentSuppressSorting) {
        if (!values || values.length === 0) {
          this.valuesType = 2;
          this.providedValues = null;
        } else {
          this.valuesType = Array.isArray(values) ? 0 : 1;
        }
        const currentModel = this.getModel();
        this.updateAllValues().then(() => {
          this.setModel(currentModel).then(() => resolve());
        });
      } else {
        resolve();
      }
    });
  }
  /**
   * Re-fetches the values used in the filter from the value source.
   * If keepSelection is false, the filter selection will be reset to everything selected,
   * otherwise the current selection will be preserved.
   */
  refreshValues() {
    return new AgPromise24((resolve) => {
      this.allValuesPromise.then(() => {
        const currentModel = this.getModel();
        this.updateAllValues();
        this.setModel(currentModel).then(() => resolve());
      });
    });
  }
  /**
   * Overrides the current values being used for the set filter.
   * If keepSelection is false, the filter selection will be reset to everything selected,
   * otherwise the current selection will be preserved.
   */
  overrideValues(valuesToUse) {
    return new AgPromise24((resolve) => {
      this.allValuesPromise.then(() => {
        this.valuesType = 0;
        this.providedValues = valuesToUse;
        this.refreshValues().then(() => resolve());
      });
    });
  }
  /** @return has anything been updated */
  refreshAfterAnyFilterChanged() {
    if (this.showAvailableOnly()) {
      return this.allValuesPromise.then((keys) => {
        this.updateAvailableKeys(keys ?? [], "otherFilter");
        return true;
      });
    }
    return AgPromise24.resolve(false);
  }
  isInitialised() {
    return this.initialised;
  }
  updateAllValues() {
    this.allValuesPromise = new AgPromise24((resolve) => {
      switch (this.valuesType) {
        case 2:
          this.getValuesFromRowsAsync(false).then((values) => resolve(this.processAllValues(values)));
          break;
        case 0: {
          resolve(
            this.processAllValues(
              this.uniqueValues(this.validateProvidedValues(this.providedValues))
            )
          );
          break;
        }
        case 1: {
          this.setIsLoading(true);
          const callback = this.providedValues;
          const { column, colDef } = this.filterParams;
          const params = this.gos.addGridCommonParams({
            success: (values) => {
              this.setIsLoading(false);
              resolve(this.processAllValues(this.uniqueValues(this.validateProvidedValues(values))));
            },
            colDef,
            column
          });
          window.setTimeout(() => callback(params), 0);
          break;
        }
        default:
          throw new Error("Unrecognised valuesType");
      }
    });
    this.allValuesPromise.then((values) => this.updateAvailableKeys(values || [], "reload")).then(() => this.initialised = true);
    return this.allValuesPromise;
  }
  processAllValues(values) {
    const sortedKeys = this.sortKeys(values);
    this.allValues = values ?? /* @__PURE__ */ new Map();
    return sortedKeys;
  }
  validateProvidedValues(values) {
    if (this.usingComplexObjects && values?.length) {
      const firstValue = values[0];
      if (firstValue && typeof firstValue !== "object" && typeof firstValue !== "function") {
        const firstKey = this.createKey(firstValue);
        if (firstKey == null) {
          _warnOnce310(
            "Set Filter Key Creator is returning null for provided values and provided values are primitives. Please provide complex objects. See https://www.ag-grid.com/javascript-data-grid/filter-set-filter-list/#filter-value-types"
          );
        } else {
          _warnOnce310(
            "Set Filter has a Key Creator, but provided values are primitives. Did you mean to provide complex objects?"
          );
        }
      }
    }
    return values;
  }
  setValuesType(value) {
    this.valuesType = value;
  }
  getValuesType() {
    return this.valuesType;
  }
  isKeyAvailable(key) {
    return this.availableKeys.has(key);
  }
  showAvailableOnly() {
    return this.valuesType === 2;
  }
  updateAvailableKeys(allKeys, source) {
    const availableKeys = this.showAvailableOnly() ? this.sortKeys(this.getValuesFromRows(true)) : allKeys;
    this.availableKeys = new Set(availableKeys);
    this.localEventService.dispatchEvent({ type: "availableValuesChanged" });
    this.updateDisplayedValues(source, allKeys);
  }
  sortKeys(nullableValues) {
    const values = nullableValues ?? /* @__PURE__ */ new Map();
    if (this.suppressSorting) {
      return Array.from(values.keys());
    }
    let sortedKeys;
    if (this.compareByValue) {
      sortedKeys = Array.from(values.entries()).sort(this.entryComparator).map(([key]) => key);
    } else {
      sortedKeys = Array.from(values.keys()).sort(this.keyComparator);
    }
    if (this.filterParams.excelMode && values.has(null)) {
      sortedKeys = sortedKeys.filter((v) => v != null);
      sortedKeys.push(null);
    }
    return sortedKeys;
  }
  getParamsForValuesFromRows(removeUnavailableValues = false) {
    if (!this.clientSideValuesExtractor) {
      _errorOnce6(
        "Set Filter cannot initialise because you are using a row model that does not contain all rows in the browser. Either use a different filter type, or configure Set Filter such that you provide it with values"
      );
      return null;
    }
    const predicate = (node) => !removeUnavailableValues || this.doesRowPassOtherFilters(node);
    const existingValues = removeUnavailableValues && !this.caseSensitive ? this.allValues : void 0;
    return { predicate, existingValues };
  }
  getValuesFromRows(removeUnavailableValues = false) {
    const params = this.getParamsForValuesFromRows(removeUnavailableValues);
    if (!params) {
      return null;
    }
    return this.clientSideValuesExtractor.extractUniqueValues(params.predicate, params.existingValues);
  }
  getValuesFromRowsAsync(removeUnavailableValues = false) {
    const params = this.getParamsForValuesFromRows(removeUnavailableValues);
    if (!params) {
      return AgPromise24.resolve(null);
    }
    return this.clientSideValuesExtractor.extractUniqueValuesAsync(params.predicate, params.existingValues);
  }
  /** Sets mini filter value. Returns true if it changed from last value, otherwise false. */
  setMiniFilter(value) {
    value = _makeNull32(value);
    if (this.miniFilterText === value) {
      return false;
    }
    if (value === null) {
      this.setAddCurrentSelectionToFilter(false);
    }
    this.miniFilterText = value;
    this.updateDisplayedValues("miniFilter");
    return true;
  }
  getMiniFilter() {
    return this.miniFilterText;
  }
  updateDisplayedValues(source, allKeys) {
    if (source === "expansion") {
      this.displayValueModel.refresh();
      return;
    }
    if (this.miniFilterText == null) {
      this.displayValueModel.updateDisplayedValuesToAllAvailable(
        (key) => this.getValue(key),
        allKeys,
        this.availableKeys,
        source
      );
      return;
    }
    const formattedFilterText = this.caseFormat(this.formatter(this.miniFilterText) || "");
    const matchesFilter = (valueToCheck) => valueToCheck != null && this.caseFormat(valueToCheck).indexOf(formattedFilterText) >= 0;
    const nullMatchesFilter = !!this.filterParams.excelMode && matchesFilter(this.translate("blanks"));
    this.displayValueModel.updateDisplayedValuesToMatchMiniFilter(
      (key) => this.getValue(key),
      allKeys,
      this.availableKeys,
      matchesFilter,
      nullMatchesFilter,
      source
    );
  }
  getDisplayedValueCount() {
    return this.displayValueModel.getDisplayedValueCount();
  }
  getDisplayedItem(index) {
    return this.displayValueModel.getDisplayedItem(index);
  }
  getSelectAllItem() {
    return this.displayValueModel.getSelectAllItem();
  }
  getAddSelectionToFilterItem() {
    return this.displayValueModel.getAddSelectionToFilterItem();
  }
  hasSelections() {
    return this.filterParams.defaultToNothingSelected ? this.selectedKeys.size > 0 : this.allValues.size !== this.selectedKeys.size;
  }
  getKeys() {
    return Array.from(this.allValues.keys());
  }
  getValues() {
    return Array.from(this.allValues.values());
  }
  getValue(key) {
    return this.allValues.get(key);
  }
  setAddCurrentSelectionToFilter(value) {
    this.addCurrentSelectionToFilter = value;
  }
  isInWindowsExcelMode() {
    return this.filterParams.excelMode === "windows";
  }
  isAddCurrentSelectionToFilterChecked() {
    return this.isInWindowsExcelMode() && this.addCurrentSelectionToFilter;
  }
  showAddCurrentSelectionToFilter() {
    return this.isInWindowsExcelMode() && _exists17(this.miniFilterText) && this.miniFilterText.length > 0;
  }
  selectAllMatchingMiniFilter(clearExistingSelection = false) {
    if (this.miniFilterText == null) {
      this.selectedKeys = new Set(this.allValues.keys());
    } else {
      if (clearExistingSelection) {
        this.selectedKeys.clear();
      }
      this.displayValueModel.forEachDisplayedKey((key) => this.selectedKeys.add(key));
    }
  }
  deselectAllMatchingMiniFilter() {
    if (this.miniFilterText == null) {
      this.selectedKeys.clear();
    } else {
      this.displayValueModel.forEachDisplayedKey((key) => this.selectedKeys.delete(key));
    }
  }
  selectKey(key) {
    this.selectedKeys.add(key);
  }
  deselectKey(key) {
    if (this.filterParams.excelMode && this.isEverythingVisibleSelected()) {
      this.resetSelectionState(this.displayValueModel.getDisplayedKeys());
    }
    this.selectedKeys.delete(key);
  }
  isKeySelected(key) {
    return this.selectedKeys.has(key);
  }
  isEverythingVisibleSelected() {
    return !this.displayValueModel.someDisplayedKey((it) => !this.isKeySelected(it));
  }
  isNothingVisibleSelected() {
    return !this.displayValueModel.someDisplayedKey((it) => this.isKeySelected(it));
  }
  getModel() {
    if (!this.hasSelections()) {
      return null;
    }
    const filteringKeys = this.isAddCurrentSelectionToFilterChecked() ? this.filteringKeys.allFilteringKeys() : null;
    if (filteringKeys && filteringKeys.size > 0) {
      if (this.selectedKeys) {
        const modelKeys = /* @__PURE__ */ new Set([
          ...Array.from(filteringKeys),
          ...Array.from(this.selectedKeys).filter((key) => !filteringKeys.has(key))
        ]);
        return Array.from(modelKeys);
      }
      return Array.from(filteringKeys);
    }
    return Array.from(this.selectedKeys);
  }
  setModel(model) {
    return this.allValuesPromise.then((keys) => {
      if (model == null) {
        this.resetSelectionState(keys ?? []);
      } else {
        this.selectedKeys.clear();
        const existingFormattedKeys = /* @__PURE__ */ new Map();
        this.allValues.forEach((_value, key) => {
          existingFormattedKeys.set(this.caseFormat(key), key);
        });
        model.forEach((unformattedKey) => {
          const formattedKey = this.caseFormat(_makeNull32(unformattedKey));
          const existingUnformattedKey = existingFormattedKeys.get(formattedKey);
          if (existingUnformattedKey !== void 0) {
            this.selectKey(existingUnformattedKey);
          }
        });
      }
    });
  }
  uniqueValues(values) {
    const uniqueValues = /* @__PURE__ */ new Map();
    const formattedKeys = /* @__PURE__ */ new Set();
    (values ?? []).forEach((value) => {
      const valueToUse = _makeNull32(value);
      const unformattedKey = this.createKey(valueToUse);
      const formattedKey = this.caseFormat(unformattedKey);
      if (!formattedKeys.has(formattedKey)) {
        formattedKeys.add(formattedKey);
        uniqueValues.set(unformattedKey, valueToUse);
      }
    });
    return uniqueValues;
  }
  resetSelectionState(keys) {
    if (this.filterParams.defaultToNothingSelected) {
      this.selectedKeys.clear();
    } else {
      this.selectedKeys = new Set(keys);
    }
  }
  hasGroups() {
    return this.displayValueModel.hasGroups();
  }
  createTreeDataOrGroupingComparator() {
    return ([_aKey, aValue], [_bKey, bValue]) => {
      if (aValue == null) {
        return bValue == null ? 0 : -1;
      } else if (bValue == null) {
        return 1;
      }
      for (let i = 0; i < aValue.length; i++) {
        if (i >= bValue.length) {
          return 1;
        }
        const diff = _defaultComparator(aValue[i], bValue[i]);
        if (diff !== 0) {
          return diff;
        }
      }
      return 0;
    };
  }
  setAppliedModelKeys(appliedModelKeys) {
    this.filteringKeys.setFilteringKeys(appliedModelKeys);
  }
  addToAppliedModelKeys(appliedModelKey) {
    this.filteringKeys.addFilteringKey(appliedModelKey);
  }
  getAppliedModelKeys() {
    return this.filteringKeys.allFilteringKeys();
  }
  getCaseFormattedAppliedModelKeys() {
    return this.filteringKeys.allFilteringKeysCaseFormatted();
  }
  hasAppliedModelKey(appliedModelKey) {
    return this.filteringKeys.hasCaseFormattedFilteringKey(appliedModelKey);
  }
  hasAnyAppliedModelKey() {
    return !this.filteringKeys.noAppliedFilteringKeys();
  }
};
var SetFilter = class extends ProvidedFilter2 {
  constructor() {
    super("setFilter");
    this.eMiniFilter = RefPlaceholder210;
    this.eFilterLoading = RefPlaceholder210;
    this.eSetFilterList = RefPlaceholder210;
    this.eFilterNoMatches = RefPlaceholder210;
    this.valueModel = null;
    this.setFilterParams = null;
    this.virtualList = null;
    this.caseSensitive = false;
    this.treeDataTreeList = false;
    this.groupingTreeList = false;
    this.hardRefreshVirtualList = false;
    this.noValueFormatterSupplied = false;
    this.filterModelFormatter = new SetFilterModelFormatter();
    this.updateSetFilterOnParamsChange = (newParams) => {
      this.setFilterParams = newParams;
      this.caseSensitive = !!newParams.caseSensitive;
      const keyCreator = newParams.keyCreator ?? newParams.colDef.keyCreator;
      this.setValueFormatter(newParams.valueFormatter, keyCreator, !!newParams.treeList, !!newParams.colDef.refData);
      const isGroupCol = newParams.column.getId().startsWith(GROUP_AUTO_COLUMN_ID3);
      this.treeDataTreeList = this.gos.get("treeData") && !!newParams.treeList && isGroupCol;
      this.getDataPath = this.gos.get("getDataPath");
      this.groupingTreeList = !!this.funcColsService.getRowGroupColumns().length && !!newParams.treeList && isGroupCol;
      this.createKey = this.generateCreateKey(keyCreator, this.treeDataTreeList || this.groupingTreeList);
    };
  }
  wireBeans(beans) {
    super.wireBeans(beans);
    this.funcColsService = beans.funcColsService;
    this.valueService = beans.valueService;
    this.dataTypeService = beans.dataTypeService;
  }
  postConstruct() {
    super.postConstruct();
  }
  // unlike the simple filters, nothing in the set filter UI shows/hides.
  // maybe this method belongs in abstractSimpleFilter???
  updateUiVisibility() {
  }
  createBodyTemplate() {
    return (
      /* html */
      `
            <div class="ag-set-filter">
                <div data-ref="eFilterLoading" class="ag-filter-loading ag-hidden">${this.translateForSetFilter("loadingOoo")}</div>
                <ag-input-text-field class="ag-mini-filter" data-ref="eMiniFilter"></ag-input-text-field>
                <div data-ref="eFilterNoMatches" class="ag-filter-no-matches ag-hidden">${this.translateForSetFilter("noMatches")}</div>
                <div data-ref="eSetFilterList" class="ag-set-filter-list" role="presentation"></div>
            </div>`
    );
  }
  getAgComponents() {
    return [AgInputTextFieldSelector6];
  }
  handleKeyDown(e) {
    super.handleKeyDown(e);
    if (e.defaultPrevented) {
      return;
    }
    switch (e.key) {
      case KeyCode21.SPACE:
        this.handleKeySpace(e);
        break;
      case KeyCode21.ENTER:
        this.handleKeyEnter(e);
        break;
      case KeyCode21.LEFT:
        this.handleKeyLeft(e);
        break;
      case KeyCode21.RIGHT:
        this.handleKeyRight(e);
        break;
    }
  }
  handleKeySpace(e) {
    this.getComponentForKeyEvent(e)?.toggleSelected();
  }
  handleKeyEnter(e) {
    if (!this.setFilterParams) {
      return;
    }
    const { excelMode, readOnly } = this.setFilterParams || {};
    if (!excelMode || !!readOnly) {
      return;
    }
    e.preventDefault();
    this.onBtApply(false, false, e);
    if (this.setFilterParams.excelMode === "mac") {
      this.eMiniFilter.getInputElement().select();
    }
  }
  handleKeyLeft(e) {
    this.getComponentForKeyEvent(e)?.setExpanded(false);
  }
  handleKeyRight(e) {
    this.getComponentForKeyEvent(e)?.setExpanded(true);
  }
  getComponentForKeyEvent(e) {
    if (!this.eSetFilterList.contains(_getActiveDomElement13(this.gos)) || !this.virtualList) {
      return;
    }
    const currentItem = this.virtualList.getLastFocusedRow();
    if (currentItem == null) {
      return;
    }
    const component = this.virtualList.getComponentAt(currentItem);
    if (component == null) {
      return;
    }
    e.preventDefault();
    const { readOnly } = this.setFilterParams ?? {};
    if (readOnly) {
      return;
    }
    return component;
  }
  getCssIdentifier() {
    return "set-filter";
  }
  setModel(model) {
    if (model == null && this.valueModel?.getModel() == null) {
      this.setMiniFilter(null);
      return AgPromise32.resolve();
    }
    return super.setModel(model);
  }
  refresh(params) {
    this.applyExcelModeOptions(params);
    if (!super.refresh(params)) {
      return false;
    }
    const oldParams = this.setFilterParams;
    const paramsThatForceReload = [
      "treeList",
      "treeListPathGetter",
      "caseSensitive",
      "comparator",
      "excelMode"
    ];
    if (paramsThatForceReload.some((param) => params[param] !== oldParams?.[param])) {
      return false;
    }
    if (this.haveColDefParamsChanged(params)) {
      return false;
    }
    super.updateParams(params);
    this.updateSetFilterOnParamsChange(params);
    this.updateMiniFilter();
    if (params.suppressSelectAll !== oldParams?.suppressSelectAll) {
      this.createVirtualListModel(params);
    }
    this.valueModel?.updateOnParamsChange(params).then(() => {
      this.refreshFilterValues();
    });
    return true;
  }
  haveColDefParamsChanged(params) {
    const { colDef, keyCreator } = params;
    const { colDef: existingColDef, keyCreator: existingKeyCreator } = this.setFilterParams ?? {};
    const currentKeyCreator = keyCreator ?? colDef.keyCreator;
    const previousKeyCreator = existingKeyCreator ?? existingColDef?.keyCreator;
    const filterValueGetterChanged = colDef.filterValueGetter !== existingColDef?.filterValueGetter;
    const keyCreatorChanged = currentKeyCreator !== previousKeyCreator;
    const valueFormatterIsKeyCreatorAndHasChanged = !!this.dataTypeService && !!currentKeyCreator && this.dataTypeService.getFormatValue(colDef.cellDataType) === currentKeyCreator && colDef.valueFormatter !== existingColDef?.valueFormatter;
    return filterValueGetterChanged || keyCreatorChanged || valueFormatterIsKeyCreatorAndHasChanged;
  }
  setModelAndRefresh(values) {
    return this.valueModel ? this.valueModel.setModel(values).then(() => {
      if (this.isAlive()) {
        this.checkAndRefreshVirtualList();
      }
    }) : AgPromise32.resolve();
  }
  resetUiToDefaults() {
    this.setMiniFilter(null);
    return this.setModelAndRefresh(null);
  }
  setModelIntoUi(model) {
    this.setMiniFilter(null);
    const values = model == null ? null : model.values;
    return this.setModelAndRefresh(values);
  }
  getModelFromUi() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const values = this.valueModel.getModel();
    if (!values) {
      return null;
    }
    return { values, filterType: this.getFilterType() };
  }
  getFilterType() {
    return "set";
  }
  getValueModel() {
    return this.valueModel;
  }
  areModelsEqual(a, b) {
    if (a == null && b == null) {
      return true;
    }
    return a != null && b != null && _areEqual5(a.values, b.values);
  }
  setParams(params) {
    this.applyExcelModeOptions(params);
    super.setParams(params);
    this.updateSetFilterOnParamsChange(params);
    const keyCreator = params.keyCreator ?? params.colDef.keyCreator;
    this.valueModel = new SetValueModel({
      filterParams: params,
      setIsLoading: (loading) => this.setIsLoading(loading),
      translate: (key) => this.translateForSetFilter(key),
      caseFormat: (v) => this.caseFormat(v),
      createKey: this.createKey,
      valueFormatter: this.valueFormatter,
      usingComplexObjects: !!keyCreator,
      gos: this.gos,
      funcColsService: this.funcColsService,
      valueService: this.valueService,
      treeDataTreeList: this.treeDataTreeList,
      groupingTreeList: this.groupingTreeList,
      addManagedEventListeners: (handlers) => this.addManagedEventListeners(handlers)
    });
    this.initialiseFilterBodyUi();
    this.addEventListenersForDataChanges();
  }
  onAddCurrentSelectionToFilterChange(newValue) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    this.valueModel.setAddCurrentSelectionToFilter(newValue);
  }
  setValueFormatter(providedValueFormatter, keyCreator, treeList, isRefData) {
    let valueFormatter = providedValueFormatter;
    if (!valueFormatter) {
      if (keyCreator && !treeList) {
        throw new Error("AG Grid: Must supply a Value Formatter in Set Filter params when using a Key Creator");
      }
      this.noValueFormatterSupplied = true;
      if (!isRefData) {
        valueFormatter = (params) => _toStringOrNull3(params.value);
      }
    }
    this.valueFormatter = valueFormatter;
  }
  generateCreateKey(keyCreator, treeDataOrGrouping) {
    if (treeDataOrGrouping && !keyCreator) {
      throw new Error(
        "AG Grid: Must supply a Key Creator in Set Filter params when `treeList = true` on a group column, and Tree Data or Row Grouping is enabled."
      );
    }
    if (keyCreator) {
      return (value, node = null) => {
        const params = this.getKeyCreatorParams(value, node);
        return _makeNull4(keyCreator(params));
      };
    }
    return (value) => _makeNull4(_toStringOrNull3(value));
  }
  getFormattedValue(key) {
    let value = this.valueModel.getValue(key);
    if (this.noValueFormatterSupplied && (this.treeDataTreeList || this.groupingTreeList) && Array.isArray(value)) {
      value = _last24(value);
    }
    const formattedValue = this.valueService.formatValue(
      this.setFilterParams.column,
      null,
      value,
      this.valueFormatter,
      false
    );
    return (formattedValue == null ? _toStringOrNull3(value) : formattedValue) ?? this.translateForSetFilter("blanks");
  }
  applyExcelModeOptions(params) {
    if (params.excelMode === "windows") {
      if (!params.buttons) {
        params.buttons = ["apply", "cancel"];
      }
      if (params.closeOnApply == null) {
        params.closeOnApply = true;
      }
    } else if (params.excelMode === "mac") {
      if (!params.buttons) {
        params.buttons = ["reset"];
      }
      if (params.applyMiniFilterWhileTyping == null) {
        params.applyMiniFilterWhileTyping = true;
      }
      if (params.debounceMs == null) {
        params.debounceMs = 500;
      }
    }
    if (params.excelMode && params.defaultToNothingSelected) {
      params.defaultToNothingSelected = false;
      _warnOnce46(
        'The Set Filter Parameter "defaultToNothingSelected" value was ignored because it does not work when "excelMode" is used.'
      );
    }
  }
  addEventListenersForDataChanges() {
    if (!this.isValuesTakenFromGrid()) {
      return;
    }
    this.addManagedEventListeners({
      cellValueChanged: (event) => {
        if (this.setFilterParams && event.column === this.setFilterParams.column) {
          this.syncAfterDataChange();
        }
      }
    });
    this.addManagedPropertyListeners(["treeData", "getDataPath", "groupAllowUnbalanced"], () => {
      this.syncAfterDataChange();
    });
  }
  syncAfterDataChange() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const promise = this.valueModel.refreshValues();
    return promise.then(() => {
      this.checkAndRefreshVirtualList();
      if (!this.applyActive || this.areModelsEqual(this.getModel(), this.getModelFromUi())) {
        this.onBtApply(false, true);
      }
    });
  }
  setIsLoading(isLoading) {
    _setDisplayed26(this.eFilterLoading, isLoading);
    if (!isLoading) {
      this.hardRefreshVirtualList = true;
    }
  }
  initialiseFilterBodyUi() {
    this.initVirtualList();
    this.initMiniFilter();
  }
  initVirtualList() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const translate = this.localeService.getLocaleTextFunc();
    const filterListName = translate("ariaFilterList", "Filter List");
    const isTree = !!this.setFilterParams.treeList;
    const virtualList = this.virtualList = this.createBean(
      new VirtualList({
        cssIdentifier: "filter",
        ariaRole: isTree ? "tree" : "listbox",
        listName: filterListName
      })
    );
    const eSetFilterList = this.eSetFilterList;
    if (isTree) {
      eSetFilterList.classList.add("ag-set-filter-tree-list");
    }
    if (eSetFilterList) {
      eSetFilterList.appendChild(virtualList.getGui());
    }
    const { cellHeight } = this.setFilterParams;
    if (cellHeight != null) {
      virtualList.setRowHeight(cellHeight);
    }
    const componentCreator = (item, listItemElement) => this.createSetListItem(item, isTree, listItemElement);
    virtualList.setComponentCreator(componentCreator);
    const componentUpdater = (item, component) => this.updateSetListItem(item, component);
    virtualList.setComponentUpdater(componentUpdater);
    this.createVirtualListModel(this.setFilterParams);
  }
  createVirtualListModel(params) {
    if (!this.valueModel) {
      return;
    }
    let model;
    if (params.suppressSelectAll) {
      model = new ModelWrapper(this.valueModel);
    } else {
      model = new ModelWrapperWithSelectAll(this.valueModel, () => this.isSelectAllSelected());
    }
    if (params.treeList) {
      model = new TreeModelWrapper(model);
    }
    this.virtualList?.setModel(model);
  }
  getSelectAllLabel() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const key = this.valueModel.getMiniFilter() == null || !this.setFilterParams.excelMode ? "selectAll" : "selectAllSearchResults";
    return this.translateForSetFilter(key);
  }
  getAddSelectionToFilterLabel() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    return this.translateForSetFilter("addCurrentSelectionToFilter");
  }
  createSetListItem(item, isTree, focusWrapper) {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const groupsExist = this.valueModel.hasGroups();
    const { isSelected, isExpanded } = this.isSelectedExpanded(item);
    const { value, depth, isGroup, hasIndeterminateExpandState, selectedListener, expandedListener } = this.newSetListItemAttributes(item);
    const itemParams = {
      focusWrapper,
      value,
      params: this.setFilterParams,
      translate: (translateKey) => this.translateForSetFilter(translateKey),
      valueFormatter: this.valueFormatter,
      item,
      isSelected,
      isTree,
      depth,
      groupsExist,
      isGroup,
      isExpanded,
      hasIndeterminateExpandState
    };
    const listItem = this.createBean(new SetFilterListItem(itemParams));
    listItem.addEventListener("selectionChanged", selectedListener);
    if (expandedListener) {
      listItem.addEventListener("expandedChanged", expandedListener);
    }
    return listItem;
  }
  newSetTreeItemAttributes(item) {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const groupsExist = this.valueModel.hasGroups();
    if (item.key === SetFilterDisplayValue.SELECT_ALL) {
      return {
        value: () => this.getSelectAllLabel(),
        isGroup: groupsExist,
        depth: item.depth,
        hasIndeterminateExpandState: true,
        selectedListener: (e) => this.onSelectAll(e.isSelected),
        expandedListener: (e) => this.onExpandAll(e.item, e.isExpanded)
      };
    }
    if (item.key === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
      return {
        value: () => this.getAddSelectionToFilterLabel(),
        depth: item.depth,
        isGroup: false,
        hasIndeterminateExpandState: false,
        selectedListener: (e) => {
          this.onAddCurrentSelectionToFilterChange(e.isSelected);
        }
      };
    }
    if (item.children) {
      return {
        value: this.setFilterParams.treeListFormatter?.(item.treeKey, item.depth, item.parentTreeKeys) ?? item.treeKey,
        depth: item.depth,
        isGroup: true,
        selectedListener: (e) => this.onGroupItemSelected(e.item, e.isSelected),
        expandedListener: (e) => this.onExpandedChanged(e.item, e.isExpanded)
      };
    }
    return {
      value: this.setFilterParams.treeListFormatter?.(item.treeKey, item.depth, item.parentTreeKeys) ?? item.treeKey,
      depth: item.depth,
      selectedListener: (e) => this.onItemSelected(e.item.key, e.isSelected)
    };
  }
  newSetListItemAttributes(item) {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (this.isSetFilterModelTreeItem(item)) {
      return this.newSetTreeItemAttributes(item);
    }
    if (item === SetFilterDisplayValue.SELECT_ALL) {
      return {
        value: () => this.getSelectAllLabel(),
        selectedListener: (e) => this.onSelectAll(e.isSelected)
      };
    }
    if (item === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
      return {
        value: () => this.getAddSelectionToFilterLabel(),
        selectedListener: (e) => {
          this.onAddCurrentSelectionToFilterChange(e.isSelected);
        }
      };
    }
    return {
      value: this.valueModel.getValue(item),
      selectedListener: (e) => this.onItemSelected(e.item, e.isSelected)
    };
  }
  updateSetListItem(item, component) {
    const { isSelected, isExpanded } = this.isSelectedExpanded(item);
    component.refresh(item, isSelected, isExpanded);
  }
  isSelectedExpanded(item) {
    let isSelected;
    let isExpanded;
    if (this.isSetFilterModelTreeItem(item)) {
      isExpanded = item.expanded;
      if (item.key === SetFilterDisplayValue.SELECT_ALL) {
        isSelected = this.isSelectAllSelected();
      } else if (item.key === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
        isSelected = this.valueModel.isAddCurrentSelectionToFilterChecked();
      } else if (item.children) {
        isSelected = this.areAllChildrenSelected(item);
      } else {
        isSelected = this.valueModel.isKeySelected(item.key);
      }
    } else {
      if (item === SetFilterDisplayValue.SELECT_ALL) {
        isSelected = this.isSelectAllSelected();
      } else if (item === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
        isSelected = this.valueModel.isAddCurrentSelectionToFilterChecked();
      } else {
        isSelected = this.valueModel.isKeySelected(item);
      }
    }
    return { isSelected, isExpanded };
  }
  isSetFilterModelTreeItem(item) {
    return item?.treeKey !== void 0;
  }
  initMiniFilter() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const { eMiniFilter, localeService } = this;
    const translate = localeService.getLocaleTextFunc();
    eMiniFilter.setDisplayed(!this.setFilterParams.suppressMiniFilter);
    eMiniFilter.setValue(this.valueModel.getMiniFilter());
    eMiniFilter.onValueChange(() => this.onMiniFilterInput());
    eMiniFilter.setInputAriaLabel(translate("ariaSearchFilterValues", "Search filter values"));
    this.addManagedElementListeners(eMiniFilter.getInputElement(), {
      keydown: (e) => this.onMiniFilterKeyDown(e)
    });
  }
  updateMiniFilter() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const { eMiniFilter } = this;
    if (eMiniFilter.isDisplayed() !== !this.setFilterParams.suppressMiniFilter) {
      eMiniFilter.setDisplayed(!this.setFilterParams.suppressMiniFilter);
    }
    const miniFilterValue = this.valueModel.getMiniFilter();
    if (eMiniFilter.getValue() !== miniFilterValue) {
      eMiniFilter.setValue(miniFilterValue);
    }
  }
  // we need to have the GUI attached before we can draw the virtual rows, as the
  // virtual row logic needs info about the GUI state
  afterGuiAttached(params) {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    super.afterGuiAttached(params);
    this.resetExpansion();
    this.refreshVirtualList();
    const { eMiniFilter } = this;
    eMiniFilter.setInputPlaceholder(this.translateForSetFilter("searchOoo"));
    if (!params || !params.suppressFocus) {
      if (eMiniFilter.isDisplayed()) {
        eMiniFilter.getFocusableElement().focus();
      } else {
        this.virtualList?.awaitStable(() => this.virtualList?.focusRow(0));
      }
    }
  }
  afterGuiDetached() {
    super.afterGuiDetached();
    if (this.setFilterParams?.excelMode) {
      this.resetMiniFilter();
    }
    const appliedModel = this.getModel();
    if (this.setFilterParams?.excelMode || !this.areModelsEqual(appliedModel, this.getModelFromUi())) {
      this.resetUiToActiveModel(appliedModel);
      this.showOrHideResults();
    }
  }
  applyModel(source = "api") {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (this.setFilterParams.excelMode && source !== "rowDataUpdated" && this.valueModel.isEverythingVisibleSelected()) {
      this.valueModel.selectAllMatchingMiniFilter();
    }
    const shouldKeepCurrentSelection = this.valueModel.showAddCurrentSelectionToFilter() && this.valueModel.isAddCurrentSelectionToFilterChecked();
    if (shouldKeepCurrentSelection && !this.getModel()) {
      return false;
    }
    const result = super.applyModel(source);
    const appliedModel = this.getModel();
    if (appliedModel) {
      if (!shouldKeepCurrentSelection) {
        this.valueModel.setAppliedModelKeys(/* @__PURE__ */ new Set());
      }
      appliedModel.values.forEach((key) => {
        this.valueModel.addToAppliedModelKeys(key);
      });
    } else {
      if (!shouldKeepCurrentSelection) {
        this.valueModel.setAppliedModelKeys(null);
      }
    }
    return result;
  }
  isModelValid(model) {
    return this.setFilterParams && this.setFilterParams.excelMode ? model == null || model.values.length > 0 : true;
  }
  doesFilterPass(params) {
    if (!this.setFilterParams || !this.valueModel || !this.valueModel.getCaseFormattedAppliedModelKeys()) {
      return true;
    }
    if (!this.valueModel.hasAnyAppliedModelKey()) {
      return false;
    }
    const { node, data } = params;
    if (this.treeDataTreeList) {
      return this.doesFilterPassForTreeData(node, data);
    }
    if (this.groupingTreeList) {
      return this.doesFilterPassForGrouping(node);
    }
    const value = this.getValueFromNode(node);
    if (value != null && Array.isArray(value)) {
      if (value.length === 0) {
        return this.valueModel.hasAppliedModelKey(null);
      }
      return value.some((v) => this.isInAppliedModel(this.createKey(v, node)));
    }
    return this.isInAppliedModel(this.createKey(value, node));
  }
  doesFilterPassForTreeData(node, data) {
    if (node.childrenAfterGroup?.length) {
      return false;
    }
    return this.isInAppliedModel(
      this.createKey(
        processDataPath(this.getDataPath(data), true, this.gos.get("groupAllowUnbalanced"))
      )
    );
  }
  doesFilterPassForGrouping(node) {
    const dataPath = this.funcColsService.getRowGroupColumns().map((groupCol) => this.valueService.getKeyForNode(groupCol, node));
    dataPath.push(this.getValueFromNode(node));
    return this.isInAppliedModel(
      this.createKey(processDataPath(dataPath, false, this.gos.get("groupAllowUnbalanced")))
    );
  }
  isInAppliedModel(key) {
    return this.valueModel.hasAppliedModelKey(key);
  }
  getValueFromNode(node) {
    return this.setFilterParams.getValue(node);
  }
  getKeyCreatorParams(value, node = null) {
    return {
      value,
      colDef: this.setFilterParams.colDef,
      column: this.setFilterParams.column,
      node,
      data: node?.data,
      api: this.setFilterParams.api,
      context: this.setFilterParams.context
    };
  }
  onNewRowsLoaded() {
    if (!this.isValuesTakenFromGrid()) {
      return;
    }
    this.syncAfterDataChange();
  }
  isValuesTakenFromGrid() {
    if (!this.valueModel) {
      return false;
    }
    const valuesType = this.valueModel.getValuesType();
    return valuesType === 2;
  }
  //noinspection JSUnusedGlobalSymbols
  /**
   * Public method provided so the user can change the value of the filter once
   * the filter has been already started
   * @param values The values to use.
   */
  setFilterValues(values) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    this.valueModel.overrideValues(values).then(() => {
      this.checkAndRefreshVirtualList();
      this.onUiChanged();
    });
  }
  //noinspection JSUnusedGlobalSymbols
  /**
   * Public method provided so the user can reset the values of the filter once that it has started.
   */
  resetFilterValues() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    this.valueModel.setValuesType(
      2
      /* TAKEN_FROM_GRID_VALUES */
    );
    this.syncAfterDataChange();
  }
  refreshFilterValues() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.valueModel.isInitialised()) {
      return;
    }
    this.valueModel.refreshValues().then(() => {
      this.hardRefreshVirtualList = true;
      this.checkAndRefreshVirtualList();
      this.onUiChanged();
    });
  }
  onAnyFilterChanged() {
    setTimeout(() => {
      if (!this.isAlive()) {
        return;
      }
      if (!this.valueModel) {
        throw new Error("Value model has not been created.");
      }
      this.valueModel.refreshAfterAnyFilterChanged().then((refresh) => {
        if (refresh) {
          this.checkAndRefreshVirtualList();
          this.showOrHideResults();
        }
      });
    }, 0);
  }
  onMiniFilterInput() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.valueModel.setMiniFilter(this.eMiniFilter.getValue())) {
      return;
    }
    const { applyMiniFilterWhileTyping, readOnly } = this.setFilterParams || {};
    if (!readOnly && applyMiniFilterWhileTyping) {
      this.filterOnAllVisibleValues(false);
    } else {
      this.updateUiAfterMiniFilterChange();
    }
  }
  updateUiAfterMiniFilterChange() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const { excelMode, readOnly } = this.setFilterParams || {};
    if (excelMode == null || !!readOnly) {
      this.checkAndRefreshVirtualList();
    } else if (this.valueModel.getMiniFilter() == null) {
      this.resetUiToActiveModel(this.getModel());
    } else {
      this.valueModel.selectAllMatchingMiniFilter(true);
      this.checkAndRefreshVirtualList();
      this.onUiChanged();
    }
    this.showOrHideResults();
  }
  showOrHideResults() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const hideResults = this.valueModel.getMiniFilter() != null && this.valueModel.getDisplayedValueCount() < 1;
    _setDisplayed26(this.eFilterNoMatches, hideResults);
    _setDisplayed26(this.eSetFilterList, !hideResults);
  }
  resetMiniFilter() {
    this.eMiniFilter.setValue(null, true);
    this.valueModel?.setMiniFilter(null);
  }
  resetUiToActiveModel(currentModel, afterUiUpdatedFunc) {
    this.setModelAndRefresh(currentModel == null ? null : currentModel.values).then(() => {
      this.onUiChanged(false, "prevent");
      afterUiUpdatedFunc?.();
    });
  }
  handleCancelEnd(e) {
    this.setMiniFilter(null);
    super.handleCancelEnd(e);
  }
  onMiniFilterKeyDown(e) {
    const { excelMode, readOnly } = this.setFilterParams || {};
    if (e.key === KeyCode21.ENTER && !excelMode && !readOnly) {
      this.filterOnAllVisibleValues();
    }
  }
  filterOnAllVisibleValues(applyImmediately = true) {
    const { readOnly } = this.setFilterParams || {};
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (readOnly) {
      throw new Error("Unable to filter in readOnly mode.");
    }
    this.valueModel.selectAllMatchingMiniFilter(true);
    this.checkAndRefreshVirtualList();
    this.onUiChanged(false, applyImmediately ? "immediately" : "debounce");
    this.showOrHideResults();
  }
  focusRowIfAlive(rowIndex) {
    if (rowIndex == null) {
      return;
    }
    window.setTimeout(() => {
      if (!this.virtualList) {
        throw new Error("Virtual list has not been created.");
      }
      if (this.isAlive()) {
        this.virtualList.focusRow(rowIndex);
      }
    }, 0);
  }
  onSelectAll(isSelected) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.virtualList) {
      throw new Error("Virtual list has not been created.");
    }
    if (isSelected) {
      this.valueModel.selectAllMatchingMiniFilter();
    } else {
      this.valueModel.deselectAllMatchingMiniFilter();
    }
    this.refreshAfterSelection();
  }
  onGroupItemSelected(item, isSelected) {
    const recursiveGroupSelection = (i) => {
      if (i.children) {
        i.children.forEach((childItem) => recursiveGroupSelection(childItem));
      } else {
        this.selectItem(i.key, isSelected);
      }
    };
    recursiveGroupSelection(item);
    this.refreshAfterSelection();
  }
  onItemSelected(key, isSelected) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.virtualList) {
      throw new Error("Virtual list has not been created.");
    }
    this.selectItem(key, isSelected);
    this.refreshAfterSelection();
  }
  selectItem(key, isSelected) {
    if (isSelected) {
      this.valueModel.selectKey(key);
    } else {
      this.valueModel.deselectKey(key);
    }
  }
  onExpandAll(item, isExpanded) {
    const recursiveExpansion = (i) => {
      if (i.filterPasses && i.available && i.children) {
        i.children.forEach((childItem) => recursiveExpansion(childItem));
        i.expanded = isExpanded;
      }
    };
    recursiveExpansion(item);
    this.refreshAfterExpansion();
  }
  onExpandedChanged(item, isExpanded) {
    item.expanded = isExpanded;
    this.refreshAfterExpansion();
  }
  refreshAfterExpansion() {
    const focusedRow = this.virtualList.getLastFocusedRow();
    this.valueModel.updateDisplayedValues("expansion");
    this.checkAndRefreshVirtualList();
    this.focusRowIfAlive(focusedRow);
  }
  refreshAfterSelection() {
    const focusedRow = this.virtualList.getLastFocusedRow();
    this.checkAndRefreshVirtualList();
    this.onUiChanged();
    this.focusRowIfAlive(focusedRow);
  }
  setMiniFilter(newMiniFilter) {
    this.eMiniFilter.setValue(newMiniFilter);
    this.onMiniFilterInput();
  }
  getMiniFilter() {
    return this.valueModel ? this.valueModel.getMiniFilter() : null;
  }
  checkAndRefreshVirtualList() {
    if (!this.virtualList) {
      throw new Error("Virtual list has not been created.");
    }
    this.virtualList.refresh(!this.hardRefreshVirtualList);
    if (this.hardRefreshVirtualList) {
      this.hardRefreshVirtualList = false;
    }
  }
  getFilterKeys() {
    return this.valueModel ? this.valueModel.getKeys() : [];
  }
  getFilterValues() {
    return this.valueModel ? this.valueModel.getValues() : [];
  }
  getValues() {
    return this.getFilterKeys();
  }
  refreshVirtualList() {
    if (this.setFilterParams && this.setFilterParams.refreshValuesOnOpen) {
      this.refreshFilterValues();
    } else {
      this.checkAndRefreshVirtualList();
    }
  }
  translateForSetFilter(key) {
    const translate = this.localeService.getLocaleTextFunc();
    return translate(key, DEFAULT_LOCALE_TEXT[key]);
  }
  isSelectAllSelected() {
    if (!this.setFilterParams || !this.valueModel) {
      return false;
    }
    if (!this.setFilterParams.defaultToNothingSelected) {
      if (this.valueModel.hasSelections() && this.valueModel.isNothingVisibleSelected()) {
        return false;
      }
      if (this.valueModel.isEverythingVisibleSelected()) {
        return true;
      }
    } else {
      if (this.valueModel.hasSelections() && this.valueModel.isEverythingVisibleSelected()) {
        return true;
      }
      if (this.valueModel.isNothingVisibleSelected()) {
        return false;
      }
    }
    return void 0;
  }
  areAllChildrenSelected(item) {
    const recursiveChildSelectionCheck = (i) => {
      if (i.children) {
        let someTrue = false;
        let someFalse = false;
        const mixed = i.children.some((child) => {
          if (!child.filterPasses || !child.available) {
            return false;
          }
          const childSelected = recursiveChildSelectionCheck(child);
          if (childSelected === void 0) {
            return true;
          }
          if (childSelected) {
            someTrue = true;
          } else {
            someFalse = true;
          }
          return someTrue && someFalse;
        });
        return mixed ? void 0 : someTrue;
      } else {
        return this.valueModel.isKeySelected(i.key);
      }
    };
    if (!this.setFilterParams.defaultToNothingSelected) {
      return recursiveChildSelectionCheck(item);
    } else {
      return this.valueModel.hasSelections() && recursiveChildSelectionCheck(item);
    }
  }
  destroy() {
    if (this.virtualList != null) {
      this.virtualList.destroy();
      this.virtualList = null;
    }
    super.destroy();
  }
  caseFormat(valueToFormat) {
    if (valueToFormat == null || typeof valueToFormat !== "string") {
      return valueToFormat;
    }
    return this.caseSensitive ? valueToFormat : valueToFormat.toUpperCase();
  }
  resetExpansion() {
    if (!this.setFilterParams?.treeList) {
      return;
    }
    const selectAllItem = this.valueModel?.getSelectAllItem();
    if (this.isSetFilterModelTreeItem(selectAllItem)) {
      const recursiveCollapse = (i) => {
        if (i.children) {
          i.children.forEach((childItem) => recursiveCollapse(childItem));
          i.expanded = false;
        }
      };
      recursiveCollapse(selectAllItem);
      this.valueModel.updateDisplayedValues("expansion");
    }
  }
  getModelAsString(model) {
    return this.filterModelFormatter.getModelAsString(model, this);
  }
  getPositionableElement() {
    return this.eSetFilterList;
  }
};
var ModelWrapper = class {
  constructor(model) {
    this.model = model;
  }
  getRowCount() {
    return this.model.getDisplayedValueCount();
  }
  getRow(index) {
    return this.model.getDisplayedItem(index);
  }
  areRowsEqual(oldRow, newRow) {
    return oldRow === newRow;
  }
};
var ModelWrapperWithSelectAll = class {
  constructor(model, isSelectAllSelected) {
    this.model = model;
    this.isSelectAllSelected = isSelectAllSelected;
  }
  getRowCount() {
    const showAddCurrentSelectionToFilter = this.model.showAddCurrentSelectionToFilter();
    const outboundItems = showAddCurrentSelectionToFilter ? 2 : 1;
    return this.model.getDisplayedValueCount() + outboundItems;
  }
  getRow(index) {
    if (index === 0) {
      return this.model.getSelectAllItem();
    }
    const showAddCurrentSelectionToFilter = this.model.showAddCurrentSelectionToFilter();
    const outboundItems = showAddCurrentSelectionToFilter ? 2 : 1;
    if (index === 1 && showAddCurrentSelectionToFilter) {
      return this.model.getAddSelectionToFilterItem();
    }
    return this.model.getDisplayedItem(index - outboundItems);
  }
  areRowsEqual(oldRow, newRow) {
    return oldRow === newRow;
  }
};
var TreeModelWrapper = class {
  constructor(model) {
    this.model = model;
  }
  getRowCount() {
    return this.model.getRowCount();
  }
  getRow(index) {
    return this.model.getRow(index);
  }
  areRowsEqual(oldRow, newRow) {
    if (oldRow == null && newRow == null) {
      return true;
    }
    return oldRow != null && newRow != null && oldRow.treeKey === newRow.treeKey && oldRow.depth === newRow.depth;
  }
};
var SetFloatingFilterComp = class extends Component214 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-floating-filter-input ag-set-floating-filter-input" role="presentation">
                <ag-input-text-field data-ref="eFloatingFilterText"></ag-input-text-field>
            </div>`,
      [AgInputTextFieldSelector23]
    );
    this.eFloatingFilterText = RefPlaceholder38;
    this.availableValuesListenerAdded = false;
    this.filterModelFormatter = new SetFilterModelFormatter();
  }
  wireBeans(beans) {
    this.columnNameService = beans.columnNameService;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  init(params) {
    this.params = params;
    this.eFloatingFilterText.setDisabled(true).addGuiEventListener("click", () => this.params.showParentFilter());
    this.setParams(params);
  }
  setParams(params) {
    const displayName = this.columnNameService.getDisplayNameForColumn(params.column, "header", true);
    const translate = this.localeService.getLocaleTextFunc();
    this.eFloatingFilterText.setInputAriaLabel(`${displayName} ${translate("ariaFilterInput", "Filter Input")}`);
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    this.setParams(params);
  }
  onParentModelChanged(parentModel) {
    this.updateFloatingFilterText(parentModel);
  }
  parentSetFilterInstance(cb) {
    this.params.parentFilterInstance((filter) => {
      if (!(filter instanceof SetFilter)) {
        throw new Error("AG Grid - SetFloatingFilter expects SetFilter as its parent");
      }
      cb(filter);
    });
  }
  addAvailableValuesListener() {
    this.parentSetFilterInstance((setFilter) => {
      const setValueModel = setFilter.getValueModel();
      if (!setValueModel) {
        return;
      }
      this.addManagedListeners(setValueModel, { availableValuesChanged: () => this.updateFloatingFilterText() });
    });
    this.availableValuesListenerAdded = true;
  }
  updateFloatingFilterText(parentModel) {
    if (!this.availableValuesListenerAdded) {
      this.addAvailableValuesListener();
    }
    this.parentSetFilterInstance((setFilter) => {
      this.eFloatingFilterText.setValue(this.filterModelFormatter.getModelAsString(parentModel, setFilter));
    });
  }
};
var VERSION16 = "32.3.0";
var SetFilterCoreModule = _defineModule16({
  version: VERSION16,
  moduleName: `${ModuleNames17.SetFilterModule}-core`,
  userComponents: [{ name: "agSetColumnFilter", classImp: SetFilter }],
  dependantModules: [EnterpriseCoreModule, _ColumnFilterModule4]
});
var SetFloatingFilterModule = _defineModule16({
  version: VERSION16,
  moduleName: "@ag-grid-enterprise/set-floating-filter",
  userComponents: [{ name: "agSetColumnFloatingFilter", classImp: SetFloatingFilterComp }],
  dependantModules: [SetFilterCoreModule, _FloatingFilterModule2]
});
var SetFilterModule = _defineModule16({
  version: VERSION16,
  moduleName: ModuleNames17.SetFilterModule,
  dependantModules: [SetFilterCoreModule, SetFloatingFilterModule]
});

// enterprise-modules/sparklines/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames18, _defineModule as _defineModule17 } from "ag-grid-community";
import { Component as Component59, RefPlaceholder as RefPlaceholder39 } from "ag-grid-community";
import { _warnOnce as _warnOnce213 } from "ag-grid-community";
import { _Util as _Util7 } from "ag-charts-community";
import { _Scale as _Scale2, _Scene as _Scene32, _Util as _Util22 } from "ag-charts-community";
import { _warnOnce as _warnOnce40 } from "ag-grid-community";
import { _Scene as _Scene25 } from "ag-charts-community";
import { _errorOnce as _errorOnce7 } from "ag-grid-community";
import { _Scale, _Scene as _Scene26, _Util as _Util8 } from "ag-charts-community";
import { _ModuleSupport as _ModuleSupport5, _Scale as _Scale3, _Util as _Util42 } from "ag-charts-community";
import { _Scene as _Scene42, _Util as _Util32 } from "ag-charts-community";
import { _ModuleSupport as _ModuleSupport22, _Scale as _Scale4, _Util as _Util52 } from "ag-charts-community";
import { _Scale as _Scale5, _Scene as _Scene52, _Util as _Util62 } from "ag-charts-community";
import { BeanStub as BeanStub31 } from "ag-grid-community";
function getLineDash(lineCap, lineDash2 = "solid") {
  const buttOrNull = {
    solid: [],
    dash: [4, 3],
    dot: [1, 3],
    dashDot: [4, 3, 1, 3],
    dashDotDot: [4, 3, 1, 3, 1, 3],
    shortDot: [1, 1],
    shortDash: [3, 1],
    shortDashDot: [3, 1, 1, 1],
    shortDashDotDot: [3, 1, 1, 1, 1, 1],
    longDash: [8, 3],
    longDashDot: [8, 3, 1, 3],
    longDashDotDot: [8, 3, 1, 3, 1, 3]
  };
  const roundOrSquare = {
    solid: [],
    dash: [3, 3],
    dot: [0, 3],
    dashDot: [3, 3, 0, 3],
    dashDotDot: [3, 3, 0, 3, 0, 3],
    shortDot: [0, 2],
    shortDash: [2, 2],
    shortDashDot: [2, 2, 0, 2],
    shortDashDotDot: [2, 2, 0, 2, 0, 2],
    longDash: [7, 3],
    longDashDot: [7, 3, 0, 3],
    longDashDotDot: [7, 3, 0, 3, 0, 3]
  };
  if (lineCap === "round" || lineCap === "square") {
    if (roundOrSquare[lineDash2] == void 0) {
      warnInvalid(lineDash2);
      return roundOrSquare.solid;
    }
    return roundOrSquare[lineDash2];
  }
  if (buttOrNull[lineDash2] == void 0) {
    warnInvalid(lineDash2);
    return buttOrNull.solid;
  }
  return buttOrNull[lineDash2];
}
function warnInvalid(lineDash2) {
  _warnOnce40(`'${lineDash2}' is not a valid 'lineDash' option.`);
}
function getMarker(shape) {
  switch (shape) {
    case "circle":
      return _Scene25.Circle;
    case "square":
      return _Scene25.Square;
    case "diamond":
      return _Scene25.Diamond;
    default:
      return _Scene25.Circle;
  }
}
var defaultTooltipCss = `
.ag-sparkline-tooltip-wrapper {
    position: absolute;
    user-select: none;
    pointer-events: none;
}

.ag-sparkline-tooltip {
    position: relative;
    font: 12px arial,sans-serif;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%), 0 1px 1px rgb(0 0 0 / 14%);
    line-height: 1.7em;
    overflow: hidden;
    white-space: nowrap;
    z-index: 99999;
    background-color: rgb(255, 255, 255);
    color: rgba(0,0,0, 0.67);
}

.ag-sparkline-tooltip-content {
    padding: 0 7px;
    opacity: 1;
}

.ag-sparkline-tooltip-title {
    padding-left: 7px;
    opacity: 1;
}

.ag-sparkline-tooltip-wrapper-hidden {
    top: -10000px !important;
}

.ag-sparkline-wrapper {
    box-sizing: border-box;
    overflow: hidden;
}
`;
var { extent, isNumber, isString: isString2, isStringObject, isDate, createId, Padding } = _Util8;
var { LinearScale, BandScale, TimeScale } = _Scale;
var { Transformable } = _Scene26;
var SparklineAxis = class {
  constructor() {
    this.type = "category";
    this.stroke = "rgb(204, 214, 235)";
    this.strokeWidth = 1;
  }
};
var _Sparkline = class _Sparkline2 {
  constructor() {
    this.id = createId(this);
    this.seriesRect = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    this._context = void 0;
    this._container = void 0;
    this._data = void 0;
    this.padding = new Padding(3);
    this.xKey = "x";
    this.yKey = "y";
    this.dataType = void 0;
    this.xData = [];
    this.yData = [];
    this.min = void 0;
    this.max = void 0;
    this.yScale = new LinearScale();
    this.axis = new SparklineAxis();
    this.highlightStyle = {
      size: 6,
      fill: "yellow",
      stroke: "silver",
      strokeWidth: 1
    };
    this._width = 100;
    this._height = 100;
    this.smallestInterval = void 0;
    this.layoutId = 0;
    this.defaultDateFormatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    });
    this._onMouseMove = this.onMouseMove.bind(this);
    this._onMouseOut = this.onMouseOut.bind(this);
    const root = new _Scene26.TranslatableGroup();
    this.rootGroup = root;
    const element = document.createElement("div");
    element.setAttribute("class", "ag-sparkline-wrapper");
    const scene = new _Scene26.Scene({});
    this.scene = scene;
    this.canvasElement = scene.canvas.element;
    scene.setRoot(root);
    scene.setContainer(element);
    this.resizeAndSetDimensions(this.width, this.height);
    if (!_Sparkline2.tooltipDocuments.includes(document)) {
      this.initialiseTooltipStyles();
    }
    this.setupDomEventListeners(this.canvasElement);
  }
  set context(value) {
    if (this._context !== value) {
      this._context = value;
    }
  }
  get context() {
    return this._context;
  }
  set container(value) {
    if (this._container !== value) {
      const { parentNode } = this.canvasElement;
      if (parentNode != null) {
        parentNode.removeChild(this.canvasElement);
      }
      if (value) {
        value.appendChild(this.canvasElement);
      }
      this._container = value;
    }
  }
  get container() {
    return this._container;
  }
  set data(value) {
    if (this._data !== value) {
      this._data = value;
      this.processData();
      if (this.mouseMoveEvent && this.highlightedDatum) {
        this.updateHitPoint(this.mouseMoveEvent);
      }
    }
  }
  get data() {
    return this._data;
  }
  resizeAndSetDimensions(width, height) {
    this.scene.resize(width, height);
    this.seriesRect.width = width;
    this.seriesRect.height = height;
  }
  initialiseTooltipStyles() {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = defaultTooltipCss;
    document.head.insertBefore(styleElement, document.head.querySelector("style"));
    _Sparkline2.tooltipDocuments.push(document);
  }
  set width(value) {
    if (this._width !== value) {
      this._width = value;
      this.scene.resize(value, this.height);
      this.scheduleLayout();
    }
  }
  get width() {
    return this._width;
  }
  set height(value) {
    if (this._height !== value) {
      this._height = value;
      this.scene.resize(this.width, value);
      this.scheduleLayout();
    }
  }
  get height() {
    return this._height;
  }
  /**
   * Generate node data from processed data.
   * Produce data joins.
   * Update selection's nodes using node data.
   */
  update() {
  }
  // Update y scale based on processed data.
  updateYScale() {
    this.updateYScaleRange();
    this.updateYScaleDomain();
  }
  // Update y scale domain based on processed data.
  updateYScaleDomain() {
  }
  // Update y scale range based on height and padding (seriesRect).
  updateYScaleRange() {
    const { yScale, seriesRect } = this;
    yScale.range = [seriesRect.height, 0];
  }
  // Update x scale based on processed data.
  updateXScale() {
    const { type } = this.axis;
    this.xScale = this.getXScale(type);
    this.updateXScaleRange();
    this.updateXScaleDomain();
  }
  // Update x scale range based on width and padding (seriesRect).
  updateXScaleRange() {
    this.xScale.range = [0, this.seriesRect.width];
  }
  // Update x scale domain based on processed data and type of scale.
  updateXScaleDomain() {
    const { xData, xScale } = this;
    let xMinMax;
    if (xScale instanceof LinearScale || xScale instanceof TimeScale) {
      xMinMax = extent(xData);
    }
    this.xScale.domain = xMinMax ? xMinMax.slice() : xData;
  }
  /**
   * Return xScale instance based on the provided type or return a `BandScale` by default.
   * The default type is `category`.
   * @param type
   */
  getXScale(type = "category") {
    switch (type) {
      case "number":
        return new LinearScale();
      case "time":
        return new TimeScale();
      case "category":
      default:
        return new BandScale();
    }
  }
  // Update axis line.
  updateAxisLine() {
  }
  // Update X and Y scales and the axis line.
  updateAxes() {
    this.updateYScale();
    this.updateXScale();
    this.updateAxisLine();
  }
  // Update horizontal and vertical crosshair lines.
  updateCrosshairs() {
    this.updateXCrosshairLine();
    this.updateYCrosshairLine();
  }
  // Using processed data, generate data that backs visible nodes.
  generateNodeData() {
    return [];
  }
  // Returns persisted node data associated with the sparkline's data.
  getNodeData() {
    return [];
  }
  // Update the selection's nodes.
  updateNodes() {
  }
  // Update the vertical crosshair line.
  updateXCrosshairLine() {
  }
  // Update the horizontal crosshair line.
  updateYCrosshairLine() {
  }
  highlightDatum(closestDatum) {
    this.updateNodes();
  }
  dehighlightDatum() {
    this.highlightedDatum = void 0;
    this.updateNodes();
    this.updateCrosshairs();
  }
  /**
   * Highlight closest datum and display tooltip if enabled.
   * Only update if necessary, i.e. only update if the highlighted datum is different from previously highlighted datum,
   * or if there is no previously highlighted datum.
   * @param event
   */
  onMouseMove(event) {
    this.mouseMoveEvent = event;
    this.updateHitPoint(event);
  }
  renderScene() {
    this.scene.render().catch((e) => _errorOnce7(`chart rendering failed`, e));
  }
  updateHitPoint(event) {
    const closestDatum = this.pickClosestSeriesNodeDatum(event.offsetX, event.offsetY);
    if (!closestDatum) {
      return;
    }
    const oldHighlightedDatum = this.highlightedDatum;
    this.highlightedDatum = closestDatum;
    if (this.highlightedDatum && !oldHighlightedDatum || this.highlightedDatum && oldHighlightedDatum && this.highlightedDatum !== oldHighlightedDatum) {
      this.highlightDatum(closestDatum);
      this.updateCrosshairs();
      this.renderScene();
    }
    const tooltipEnabled = this.processedOptions?.tooltip?.enabled ?? true;
    if (tooltipEnabled) {
      this.handleTooltip(event, closestDatum);
    }
  }
  /**
   * Dehighlight all nodes and remove tooltip.
   * @param event
   */
  onMouseOut(event) {
    this.dehighlightDatum();
    this.tooltip.toggle(false);
    this.renderScene();
  }
  // Fetch required values from the data object and process them.
  processData() {
    const { data, yData, xData } = this;
    if (!data || this.invalidData(this.data)) {
      return;
    }
    yData.length = 0;
    xData.length = 0;
    const n = data.length;
    const dataType = this.getDataType(data);
    this.dataType = dataType;
    const { type: xValueType } = this.axis;
    const xType = xValueType !== "number" && xValueType !== "time" ? "category" : xValueType;
    const isContinuousX = xType === "number" || xType === "time";
    const setSmallestXInterval = (curr, prev) => {
      if (this.smallestInterval == void 0) {
        this.smallestInterval = { x: Infinity, y: Infinity };
      }
      const { x } = this.smallestInterval;
      const interval = Math.abs(curr - prev);
      if (interval > 0 && interval < x) {
        this.smallestInterval.x = interval;
      }
    };
    let prevX;
    if (dataType === "number") {
      for (let i = 0; i < n; i++) {
        const xDatum = i;
        const yDatum = data[i];
        const x = this.getDatum(xDatum, xType);
        const y = this.getDatum(yDatum, "number");
        if (isContinuousX) {
          setSmallestXInterval(x, prevX);
        }
        xData.push(x);
        yData.push(y);
        prevX = x;
      }
    } else if (dataType === "array") {
      for (let i = 0; i < n; i++) {
        const datum = data[i];
        if (Array.isArray(datum)) {
          const xDatum = datum[0];
          const yDatum = datum[1];
          const x = this.getDatum(xDatum, xType);
          const y = this.getDatum(yDatum, "number");
          if (x == void 0) {
            continue;
          }
          if (isContinuousX) {
            setSmallestXInterval(x, prevX);
          }
          xData.push(x);
          yData.push(y);
          prevX = x;
        }
      }
    } else if (dataType === "object") {
      const { yKey, xKey } = this;
      for (let i = 0; i < n; i++) {
        const datum = data[i];
        if (typeof datum === "object" && !Array.isArray(datum)) {
          const xDatum = datum[xKey];
          const yDatum = datum[yKey];
          const x = this.getDatum(xDatum, xType);
          const y = this.getDatum(yDatum, "number");
          if (x == void 0) {
            continue;
          }
          if (isContinuousX) {
            setSmallestXInterval(x, prevX);
          }
          xData.push(x);
          yData.push(y);
          prevX = x;
        }
      }
    }
    this.updateAxes();
    this.immediateLayout();
  }
  /**
   * Return the type of data provided to the sparkline based on the first truthy value in the data array.
   * If the value is not a number, array or object, return `undefined`.
   * @param data
   */
  getDataType(data) {
    for (const datum of data) {
      if (datum != void 0) {
        if (isNumber(datum)) {
          return "number";
        } else if (Array.isArray(datum)) {
          return "array";
        } else if (typeof datum === "object") {
          return "object";
        }
      }
    }
  }
  /**
   * Return the given value depending on the type of axis.
   * Return `undefined` if the value is invalid for the given axis type.
   * @param value
   */
  getDatum(value, type) {
    if (type === "number" && isNumber(value) || type === "time" && (isNumber(value) || isDate(value))) {
      return value;
    } else if (type === "category") {
      if (isString2(value) || isDate(value) || isNumber(value)) {
        return { toString: () => String(value) };
      } else if (isStringObject(value)) {
        return value;
      }
    }
  }
  /**
   * Only `true` while we are waiting for the layout to start.
   * This will be `false` if the layout has already started and is ongoing.
   */
  get layoutScheduled() {
    return !!this.layoutId;
  }
  /**
   * Execute update method on the next available screen repaint to make changes to the canvas.
   * If we are waiting for a layout to start and a new layout is requested,
   * cancel the previous layout using the non 0 integer (this.layoutId) returned from requestAnimationFrame.
   */
  scheduleLayout() {
    if (this.layoutId) {
      cancelAnimationFrame(this.layoutId);
    }
    this.layoutId = requestAnimationFrame(() => {
      this.immediateLayout();
      this.layoutId = 0;
    });
  }
  immediateLayout() {
    this.setSparklineDimensions();
    if (this.invalidData(this.data)) {
      return;
    }
    this.updateXScaleRange();
    this.updateYScaleRange();
    this.updateAxisLine();
    this.update();
    this.renderScene();
  }
  setSparklineDimensions() {
    const { width, height, padding, seriesRect, rootGroup } = this;
    const shrunkWidth = width - padding.left - padding.right;
    const shrunkHeight = height - padding.top - padding.bottom;
    seriesRect.width = shrunkWidth;
    seriesRect.height = shrunkHeight;
    seriesRect.x = padding.left;
    seriesRect.y = padding.top;
    rootGroup.translationX = seriesRect.x;
    rootGroup.translationY = seriesRect.y;
  }
  /**
   * Return the closest data point to x/y canvas coordinates.
   * @param x
   * @param y
   */
  pickClosestSeriesNodeDatum(x, y) {
    let minDistance = Infinity;
    let closestDatum;
    const hitPoint = Transformable.fromCanvasPoint(this.rootGroup, x, y);
    const nodeData = this.getNodeData();
    for (let i = 0; i < nodeData.length; i++) {
      const datum = nodeData[i];
      if (!datum.point) {
        return;
      }
      const distance = this.getDistance(hitPoint, datum.point);
      if (distance <= minDistance) {
        minDistance = distance;
        closestDatum = datum;
      }
    }
    return closestDatum;
  }
  /**
   * Return the relevant distance between two points.
   * The distance will be calculated based on the x value of the points for all sparklines except bar sparkline, where the distance is based on the y values.
   * @param x
   * @param y
   */
  getDistance(p1, p2) {
    return Math.abs(p1.x - p2.x);
  }
  /**
   * calculate x/y coordinates for tooltip based on coordinates of highlighted datum, position of canvas and page offset.
   * @param datum
   */
  handleTooltip(event, datum) {
    const { seriesDatum } = datum;
    const { canvasElement } = this;
    const { clientX, clientY } = event;
    const tooltipOptions = this.processedOptions?.tooltip;
    const meta = {
      pageX: clientX,
      pageY: clientY,
      position: {
        xOffset: tooltipOptions?.xOffset,
        yOffset: tooltipOptions?.yOffset
      },
      container: tooltipOptions?.container
    };
    if (meta.container == void 0) {
      meta.container = canvasElement;
    }
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    let enabled = tooltipOptions?.enabled ?? true;
    const tooltipRenderer = tooltipOptions?.renderer;
    if (tooltipRenderer) {
      const tooltipRendererResult = tooltipRenderer({
        context: this.context,
        datum: seriesDatum,
        yValue,
        xValue
      });
      enabled = typeof tooltipRendererResult !== "string" && tooltipRendererResult.enabled !== void 0 ? tooltipRendererResult.enabled : enabled;
    }
    const html = enabled && seriesDatum.y !== void 0 && this.getTooltipHtml(datum);
    if (html) {
      this.tooltip.show(meta, html);
    }
  }
  formatNumericDatum(datum) {
    return String(Math.round(datum * 10) / 10);
  }
  // locale.format('%m/%d/%y, %H:%M:%S');
  formatDatum(datum) {
    const type = this.axis.type || "category";
    if (type === "number" && typeof datum === "number") {
      return this.formatNumericDatum(datum);
    } else if (type === "time" && (datum instanceof Date || isNumber(datum))) {
      return this.defaultDateFormatter.format(datum);
    } else {
      return String(datum);
    }
  }
  setupDomEventListeners(chartElement) {
    chartElement.addEventListener("mousemove", this._onMouseMove);
    chartElement.addEventListener("mouseout", this._onMouseOut);
  }
  cleanupDomEventListeners(chartElement) {
    chartElement.removeEventListener("mousemove", this._onMouseMove);
    chartElement.removeEventListener("mouseout", this._onMouseOut);
  }
  invalidData(data) {
    return !data || !Array.isArray(data);
  }
  /**
   * Cleanup and remove canvas element from the DOM.
   */
  destroy() {
    this.cleanupDomEventListeners(this.canvasElement);
    this.scene.destroy();
    this.container = void 0;
  }
};
_Sparkline.tooltipDocuments = [];
var Sparkline = _Sparkline;
function toTooltipHtml(input, defaults) {
  if (typeof input === "string") {
    return input;
  }
  defaults = defaults ?? {};
  const {
    content = defaults.content ?? "",
    title = defaults.title ?? void 0,
    color = defaults.color,
    backgroundColor = defaults.backgroundColor,
    opacity = defaults.opacity ?? 1
  } = input;
  let titleHtml;
  let contentHtml;
  if (color) {
    titleHtml = title ? `<span class="${TooltipClass}-title"; style="color: ${color}">${title}</span>` : "";
    contentHtml = `<span class="${TooltipClass}-content" style="color: ${color}">${content}</span>`;
  } else {
    titleHtml = title ? `<span class="${TooltipClass}-title">${title}</span>` : "";
    contentHtml = `<span class="${TooltipClass}-content">${content}</span>`;
  }
  let style = `opacity: ${opacity}`;
  if (backgroundColor) {
    style += `; background-color: ${backgroundColor.toLowerCase()}`;
  }
  return `<div class="${TooltipClass}" style="${style}">
                ${titleHtml}
                ${contentHtml}
            </div>`;
}
var TooltipClass = "ag-sparkline-tooltip";
var SparklineTooltip = class {
  constructor() {
    this.created = false;
    this.element = document.createElement("div");
  }
  isVisible() {
    const { element } = this;
    if (element.classList) {
      return !element.classList.contains(`${TooltipClass}-wrapper-hidden`);
    }
    const classes = element.getAttribute("class");
    if (classes) {
      return classes.split(" ").indexOf(`${TooltipClass}-wrapper-hidden`) < 0;
    }
    return false;
  }
  updateClass(visible) {
    if (!this.created) {
      const tooltipRoot = document.body;
      tooltipRoot.appendChild(this.element);
      this.created = true;
    }
    const classList = [`${TooltipClass}-wrapper`];
    if (visible !== true) {
      classList.push(`${TooltipClass}-wrapper-hidden`);
    }
    this.element.setAttribute("class", classList.join(" "));
  }
  show(meta, html) {
    this.toggle(false);
    const { element } = this;
    if (html !== void 0) {
      element.innerHTML = html;
    } else if (!element.innerHTML) {
      return;
    }
    const xOffset = meta.position?.xOffset ?? 10;
    const yOffset = meta.position?.yOffset ?? 0;
    let left = meta.pageX + xOffset;
    let top = meta.pageY + yOffset;
    const tooltipRect = element.getBoundingClientRect();
    let maxLeft = window.innerWidth - tooltipRect.width;
    if (meta.container) {
      const containerRect = meta.container.getBoundingClientRect();
      maxLeft = containerRect.left + (containerRect.width - tooltipRect.width);
    }
    if (left > maxLeft) {
      left = meta.pageX - element.clientWidth - xOffset;
    }
    if (typeof scrollX !== "undefined") {
      left += scrollX;
    }
    if (typeof scrollY !== "undefined") {
      top += scrollY;
    }
    element.style.left = `${Math.round(left)}px`;
    element.style.top = `${Math.round(top)}px`;
    this.toggle(true);
  }
  toggle(visible) {
    this.updateClass(visible);
  }
  destroy() {
    const { parentNode } = this.element;
    if (parentNode) {
      parentNode.removeChild(this.element);
    }
  }
};
var { extent: extent2 } = _Util22;
var { BandScale: BandScale2 } = _Scale2;
var SparklineMarker = class {
  constructor() {
    this.enabled = true;
    this.shape = "circle";
    this.size = 0;
    this.fill = "rgb(124, 181, 236)";
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
    this.formatter = void 0;
  }
};
var SparklineLine = class {
  constructor() {
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
  }
};
var SparklineCrosshairs = class {
  constructor() {
    this.xLine = {
      enabled: true,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
    this.yLine = {
      enabled: false,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
  }
};
var AreaSparkline = class extends Sparkline {
  constructor() {
    super();
    this.fill = "rgba(124, 181, 236, 0.25)";
    this.strokePath = new _Scene32.Path();
    this.fillPath = new _Scene32.Path();
    this.xCrosshairLine = new _Scene32.Line();
    this.yCrosshairLine = new _Scene32.Line();
    this.areaSparklineGroup = new _Scene32.Group();
    this.xAxisLine = new _Scene32.Line();
    this.markers = new _Scene32.Group();
    this.markerSelection = _Scene32.Selection.select(
      this.markers,
      () => this.markerFactory()
    );
    this.markerSelectionData = [];
    this.marker = new SparklineMarker();
    this.line = new SparklineLine();
    this.crosshairs = new SparklineCrosshairs();
    this.rootGroup.append(this.areaSparklineGroup);
    this.xAxisLine.zIndex = 500;
    this.fillPath.zIndex = 50;
    this.strokePath.zIndex = 1e3;
    this.xCrosshairLine.zIndex = 2e3;
    this.yCrosshairLine.zIndex = 2e3;
    this.markers.zIndex = 2500;
    this.areaSparklineGroup.append([
      this.fillPath,
      this.xAxisLine,
      this.strokePath,
      this.xCrosshairLine,
      this.yCrosshairLine,
      this.markers
    ]);
  }
  markerFactory() {
    const { shape } = this.marker;
    const MarkerShape = getMarker(shape);
    return new MarkerShape();
  }
  getNodeData() {
    return this.markerSelectionData;
  }
  update() {
    const data = this.generateNodeData();
    if (!data) {
      return;
    }
    const { nodeData, fillData, strokeData } = data;
    this.markerSelectionData = nodeData;
    this.updateSelection(nodeData);
    this.updateNodes();
    this.updateStroke(strokeData);
    this.updateFill(fillData);
  }
  updateYScaleDomain() {
    const { yData, yScale } = this;
    const yMinMax = extent2(yData);
    let yMin = 0;
    let yMax = 1;
    if (yMinMax != null) {
      yMin = this.min = yMinMax[0];
      yMax = this.max = yMinMax[1];
    }
    yMin = yMin < 0 ? yMin : 0;
    yMax = yMax < 0 ? 0 : yMax;
    yScale.domain = [yMin, yMax];
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale } = this;
    if (!data) {
      return;
    }
    const continuous = !(xScale instanceof BandScale2);
    const offsetX = !continuous ? xScale.bandwidth / 2 : 0;
    const n = yData.length;
    const nodeData = [];
    const fillData = [];
    const strokeData = [];
    let firstValidX;
    let lastValidX;
    let previousX;
    let nextX;
    const yZero = yScale.convert(0);
    for (let i = 0; i < n; i++) {
      const yDatum = yData[i];
      const xDatum = xData[i];
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
      const y = yDatum === void 0 ? NaN : yScale.convert(yDatum);
      if (i + 1 < n) {
        nextX = xScale.convert(continuous ? xScale.toDomain(xData[i + 1]) : xData[i + 1]) + offsetX;
      }
      strokeData.push({
        seriesDatum: { x: xDatum, y: yDatum },
        point: { x, y }
      });
      if (yDatum === void 0 && previousX !== void 0) {
        fillData.push({ seriesDatum: void 0, point: { x: previousX, y: yZero } });
        if (nextX !== void 0) {
          fillData.push({ seriesDatum: void 0, point: { x: nextX, y: yZero } });
        }
      } else if (yDatum !== void 0) {
        fillData.push({
          seriesDatum: { x: xDatum, y: yDatum },
          point: { x, y }
        });
        nodeData.push({
          seriesDatum: { x: xDatum, y: yDatum },
          point: { x, y }
        });
        firstValidX = firstValidX !== void 0 ? firstValidX : x;
        lastValidX = x;
      }
      previousX = x;
    }
    fillData.push(
      { seriesDatum: void 0, point: { x: lastValidX, y: yZero } },
      { seriesDatum: void 0, point: { x: firstValidX, y: yZero } }
    );
    return { nodeData, fillData, strokeData };
  }
  updateAxisLine() {
    const {
      xScale,
      yScale,
      axis: { stroke, strokeWidth: strokeWidth2 },
      xAxisLine
    } = this;
    const yZero = yScale.convert(0);
    xAxisLine.x1 = xScale.range[0];
    xAxisLine.x2 = xScale.range[1];
    xAxisLine.y1 = xAxisLine.y2 = yZero;
    xAxisLine.stroke = stroke;
    xAxisLine.strokeWidth = strokeWidth2;
  }
  updateSelection(selectionData) {
    this.markerSelection.update(selectionData);
  }
  updateNodes() {
    const { highlightedDatum, highlightStyle, marker } = this;
    const {
      size: highlightSize,
      fill: highlightFill,
      stroke: highlightStroke,
      strokeWidth: highlightStrokeWidth
    } = highlightStyle;
    const markerFormatter = marker.formatter;
    this.markerSelection.each((node, datum, index) => {
      const { point, seriesDatum } = datum;
      if (!point) {
        return;
      }
      const highlighted = datum === highlightedDatum;
      const markerFill = highlighted && highlightFill !== void 0 ? highlightFill : marker.fill;
      const markerStroke = highlighted && highlightStroke !== void 0 ? highlightStroke : marker.stroke;
      const markerStrokeWidth = highlighted && highlightStrokeWidth !== void 0 ? highlightStrokeWidth : marker.strokeWidth;
      const markerSize = highlighted && highlightSize !== void 0 ? highlightSize : marker.size;
      let markerFormat;
      if (markerFormatter) {
        const first = index === 0;
        const last = index === this.markerSelectionData.length - 1;
        const min = seriesDatum.y === this.min;
        const max = seriesDatum.y === this.max;
        markerFormat = markerFormatter({
          datum,
          xValue: seriesDatum.x,
          yValue: seriesDatum.y,
          min,
          max,
          first,
          last,
          fill: markerFill,
          stroke: markerStroke,
          strokeWidth: markerStrokeWidth,
          size: markerSize,
          highlighted
        });
      }
      node.size = markerFormat && markerFormat.size != void 0 ? markerFormat.size : markerSize;
      node.fill = markerFormat && markerFormat.fill != void 0 ? markerFormat.fill : markerFill;
      node.stroke = markerFormat && markerFormat.stroke != void 0 ? markerFormat.stroke : markerStroke;
      node.strokeWidth = markerFormat && markerFormat.strokeWidth != void 0 ? markerFormat.strokeWidth : markerStrokeWidth;
      node.translationX = point.x;
      node.translationY = point.y;
      node.visible = markerFormat && markerFormat.enabled != void 0 ? markerFormat.enabled : marker.enabled && node.size > 0;
    });
  }
  updateStroke(strokeData) {
    const { strokePath, yData, line } = this;
    const path = strokePath.path;
    path.clear();
    if (yData.length < 2) {
      return;
    }
    const n = strokeData.length;
    let moveTo = true;
    for (let i = 0; i < n; i++) {
      const { point, seriesDatum } = strokeData[i];
      const x = point.x;
      const y = point.y;
      if (seriesDatum.y == void 0) {
        moveTo = true;
      } else {
        if (moveTo) {
          path.moveTo(x, y);
          moveTo = false;
        } else {
          path.lineTo(x, y);
        }
      }
    }
    strokePath.lineJoin = strokePath.lineCap = "round";
    strokePath.fill = void 0;
    strokePath.stroke = line.stroke;
    strokePath.strokeWidth = line.strokeWidth;
  }
  updateFill(areaData) {
    const { fillPath, yData, fill } = this;
    const path = fillPath.path;
    const n = areaData.length;
    path.clear();
    if (yData.length < 2) {
      return;
    }
    for (let i = 0; i < n; i++) {
      const { point } = areaData[i];
      const x = point.x;
      const y = point.y;
      if (i > 0) {
        path.lineTo(x, y);
      } else {
        path.moveTo(x, y);
      }
    }
    path.closePath();
    fillPath.lineJoin = "round";
    fillPath.stroke = void 0;
    fillPath.fill = fill;
  }
  updateXCrosshairLine() {
    const {
      yScale,
      xCrosshairLine,
      highlightedDatum,
      crosshairs: { xLine }
    } = this;
    if (!xLine.enabled || highlightedDatum == void 0) {
      xCrosshairLine.strokeWidth = 0;
      return;
    }
    xCrosshairLine.y1 = yScale.range[0];
    xCrosshairLine.y2 = yScale.range[1];
    xCrosshairLine.x1 = xCrosshairLine.x2 = highlightedDatum.point.x;
    xCrosshairLine.stroke = xLine.stroke;
    xCrosshairLine.strokeWidth = xLine.strokeWidth ?? 1;
    xCrosshairLine.lineCap = xLine.lineCap === "round" || xLine.lineCap === "square" ? xLine.lineCap : void 0;
    const { lineDash: lineDash2 } = xLine;
    xCrosshairLine.lineDash = Array.isArray(lineDash2) ? lineDash2 : getLineDash(xCrosshairLine.lineCap, xLine.lineDash);
  }
  updateYCrosshairLine() {
    const {
      xScale,
      yCrosshairLine,
      highlightedDatum,
      crosshairs: { yLine }
    } = this;
    if (!yLine.enabled || highlightedDatum == void 0) {
      yCrosshairLine.strokeWidth = 0;
      return;
    }
    yCrosshairLine.x1 = xScale.range[0];
    yCrosshairLine.x2 = xScale.range[1];
    yCrosshairLine.y1 = yCrosshairLine.y2 = highlightedDatum.point.y;
    yCrosshairLine.stroke = yLine.stroke;
    yCrosshairLine.strokeWidth = yLine.strokeWidth ?? 1;
    yCrosshairLine.lineCap = yLine.lineCap === "round" || yLine.lineCap === "square" ? yLine.lineCap : void 0;
    const { lineDash: lineDash2 } = yLine;
    yCrosshairLine.lineDash = Array.isArray(lineDash2) ? lineDash2 : getLineDash(yCrosshairLine.lineCap, yLine.lineDash);
  }
  getTooltipHtml(datum) {
    const { dataType } = this;
    const { seriesDatum } = datum;
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    const content = this.formatNumericDatum(yValue);
    const title = dataType === "array" || dataType === "object" ? this.formatDatum(xValue) : void 0;
    const defaults = {
      content,
      title
    };
    const tooltipRenderer = this.processedOptions?.tooltip?.renderer;
    if (tooltipRenderer) {
      return toTooltipHtml(
        tooltipRenderer({
          context: this.context,
          datum: seriesDatum,
          yValue,
          xValue
        }),
        defaults
      );
    }
    return toTooltipHtml(defaults);
  }
};
var Label = class {
  constructor() {
    this.enabled = true;
    this.fontSize = 8;
    this.fontFamily = "Verdana, sans-serif";
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.color = "rgba(70, 70, 70, 1)";
  }
};
var { extent: extent3 } = _Util32;
var BarColumnLabel = class extends Label {
  constructor() {
    super(...arguments);
    this.formatter = void 0;
    this.placement = "insideEnd";
  }
};
var BarColumnSparkline = class extends Sparkline {
  constructor() {
    super();
    this.fill = "rgb(124, 181, 236)";
    this.stroke = "silver";
    this.strokeWidth = 0;
    this.paddingInner = 0.1;
    this.paddingOuter = 0.2;
    this.valueAxisDomain = void 0;
    this.formatter = void 0;
    this.axisLine = new _Scene42.Line();
    this.bandWidth = 0;
    this.sparklineGroup = new _Scene42.Group();
    this.rectGroup = new _Scene42.Group();
    this.labelGroup = new _Scene42.Group();
    this.rectSelection = _Scene42.Selection.select(
      this.rectGroup,
      _Scene42.Rect
    );
    this.labelSelection = _Scene42.Selection.select(
      this.labelGroup,
      _Scene42.Text
    );
    this.nodeSelectionData = [];
    this.label = new BarColumnLabel();
    this.rootGroup.append(this.sparklineGroup);
    this.rectGroup.zIndex = 50;
    this.axisLine.zIndex = 500;
    this.labelGroup.zIndex = 1500;
    this.sparklineGroup.append([this.rectGroup, this.axisLine, this.labelGroup]);
    this.axisLine.lineCap = "round";
    this.label.enabled = false;
  }
  getNodeData() {
    return this.nodeSelectionData;
  }
  update() {
    this.updateSelections();
    this.updateNodes();
  }
  updateSelections() {
    const nodeData = this.generateNodeData();
    if (!nodeData) {
      return;
    }
    this.nodeSelectionData = nodeData;
    this.updateRectSelection(nodeData);
    this.updateLabelSelection(nodeData);
  }
  updateNodes() {
    this.updateRectNodes();
    this.updateLabelNodes();
  }
  calculateStep(range) {
    const { xScale, paddingInner, paddingOuter, smallestInterval } = this;
    const domainLength = xScale.domain[1] - xScale.domain[0];
    const intervals = domainLength / (smallestInterval?.x ?? 1) + 1;
    const maxBands = 50;
    const bands = Math.min(intervals, maxBands);
    const gaps = bands - 1;
    const step = range / Math.max(1, 2 * paddingOuter + gaps * paddingInner + bands);
    return step;
  }
  updateYScaleDomain() {
    const { yScale, yData, valueAxisDomain } = this;
    const yMinMax = extent3(yData);
    let yMin = 0;
    let yMax = 1;
    if (yMinMax != null) {
      yMin = this.min = yMinMax[0];
      yMax = this.max = yMinMax[1];
    }
    yMin = yMin < 0 ? yMin : 0;
    yMax = yMax < 0 ? 0 : yMax;
    if (valueAxisDomain) {
      if (valueAxisDomain[1] < yMax) {
        valueAxisDomain[1] = yMax;
      }
      if (valueAxisDomain[0] > yMin) {
        valueAxisDomain[0] = yMin;
      }
    }
    yScale.domain = valueAxisDomain ? valueAxisDomain : [yMin, yMax];
  }
  updateRectSelection(selectionData) {
    this.rectSelection.update(selectionData);
  }
  updateRectNodes() {
    const { highlightedDatum, formatter: nodeFormatter, fill, stroke, strokeWidth: strokeWidth2 } = this;
    const { fill: highlightFill, stroke: highlightStroke, strokeWidth: highlightStrokeWidth } = this.highlightStyle;
    this.rectSelection.each((node, datum, index) => {
      const highlighted = datum === highlightedDatum;
      const nodeFill = highlighted && highlightFill !== void 0 ? highlightFill : fill;
      const nodeStroke = highlighted && highlightStroke !== void 0 ? highlightStroke : stroke;
      const nodeStrokeWidth = highlighted && highlightStrokeWidth !== void 0 ? highlightStrokeWidth : strokeWidth2;
      let nodeFormat;
      const { x, y, width, height, seriesDatum } = datum;
      if (nodeFormatter) {
        const first = index === 0;
        const last = index === this.nodeSelectionData.length - 1;
        const min = seriesDatum.y === this.min;
        const max = seriesDatum.y === this.max;
        nodeFormat = nodeFormatter({
          datum,
          xValue: seriesDatum.x,
          yValue: seriesDatum.y,
          width,
          height,
          min,
          max,
          first,
          last,
          fill: nodeFill,
          stroke: nodeStroke,
          strokeWidth: nodeStrokeWidth,
          highlighted
        });
      }
      node.fill = nodeFormat && nodeFormat.fill || nodeFill;
      node.stroke = nodeFormat && nodeFormat.stroke || nodeStroke;
      node.strokeWidth = nodeFormat && nodeFormat.strokeWidth || nodeStrokeWidth;
      node.x = x;
      node.y = y;
      node.width = width;
      node.height = height;
      node.visible = node.height > 0;
    });
  }
  updateLabelSelection(selectionData) {
    this.labelSelection.update(selectionData, (text) => {
      text.tag = 1;
      text.pointerEvents = _Scene42.PointerEvents.None;
    });
  }
  updateLabelNodes() {
    const {
      label: { enabled: labelEnabled, fontStyle, fontWeight, fontSize, fontFamily, color }
    } = this;
    this.labelSelection.each((text, datum) => {
      const label = datum.label;
      if (label && labelEnabled) {
        text.fontStyle = fontStyle;
        text.fontWeight = fontWeight;
        text.fontSize = fontSize;
        text.fontFamily = fontFamily;
        text.textAlign = label.textAlign;
        text.textBaseline = label.textBaseline;
        text.text = label.text;
        text.x = label.x;
        text.y = label.y;
        text.fill = color;
        text.visible = true;
      } else {
        text.visible = false;
      }
    });
  }
  getTooltipHtml(datum) {
    const { dataType } = this;
    const { seriesDatum } = datum;
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    const content = this.formatNumericDatum(yValue);
    const title = dataType === "array" || dataType === "object" ? this.formatDatum(xValue) : void 0;
    const defaults = {
      content,
      title
    };
    const tooltipRenderer = this.processedOptions?.tooltip?.renderer;
    if (tooltipRenderer) {
      return toTooltipHtml(
        tooltipRenderer({
          context: this.context,
          datum: seriesDatum,
          yValue,
          xValue
        }),
        defaults
      );
    }
    return toTooltipHtml(defaults);
  }
  formatLabelValue(value) {
    return value % 1 !== 0 ? value.toFixed(1) : value.toFixed(0);
  }
};
var { isNumber: isNumber2 } = _Util42;
var { BandScale: BandScale3 } = _Scale3;
var BarSparkline = class extends BarColumnSparkline {
  updateYScaleRange() {
    const { seriesRect, yScale } = this;
    yScale.range = [0, seriesRect.width];
  }
  updateXScaleRange() {
    const { xScale, seriesRect, paddingOuter, paddingInner } = this;
    if (xScale instanceof BandScale3) {
      xScale.range = [0, seriesRect.height];
      xScale.paddingInner = paddingInner;
      xScale.paddingOuter = paddingOuter;
    } else {
      const step = this.calculateStep(seriesRect.height);
      const padding = step * paddingOuter;
      this.bandWidth = step * (1 - paddingInner);
      xScale.range = [padding, seriesRect.height - padding - this.bandWidth];
    }
  }
  updateAxisLine() {
    const {
      yScale,
      axis: { stroke, strokeWidth: strokeWidth2 },
      axisLine,
      seriesRect
    } = this;
    const yZero = yScale.convert(0);
    axisLine.x1 = axisLine.x2 = yZero;
    axisLine.y1 = 0;
    axisLine.y2 = seriesRect.height;
    axisLine.stroke = stroke;
    axisLine.strokeWidth = strokeWidth2 + (strokeWidth2 % 2 === 1 ? 1 : 0);
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale, fill, stroke, strokeWidth: strokeWidth2, label } = this;
    if (!data) {
      return;
    }
    const {
      fontStyle: labelFontStyle,
      fontWeight: labelFontWeight,
      fontSize: labelFontSize,
      fontFamily: labelFontFamily,
      color: labelColor,
      formatter: labelFormatter,
      placement: labelPlacement
    } = label;
    const nodeData = [];
    const yZero = yScale.convert(0);
    const continuous = !(xScale instanceof BandScale3);
    for (let i = 0, n = yData.length; i < n; i++) {
      let yDatum = yData[i];
      const xDatum = xData[i];
      const invalidDatum = yDatum === void 0;
      if (invalidDatum) {
        yDatum = 0;
      }
      const y = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum);
      const x = Math.min(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const bottom = Math.max(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const height = !continuous ? xScale.bandwidth : this.bandWidth;
      const width = bottom - x;
      const midPoint = {
        x: yZero,
        y
      };
      let labelText;
      if (labelFormatter) {
        labelText = labelFormatter({ value: yDatum });
      } else {
        labelText = yDatum !== void 0 && isNumber2(yDatum) ? this.formatLabelValue(yDatum) : "";
      }
      const labelY = y + height / 2;
      let labelX;
      const labelTextBaseline = "middle";
      let labelTextAlign;
      const isPositiveY = yDatum !== void 0 && yDatum >= 0;
      const labelPadding = 4;
      if (labelPlacement === "center") {
        labelX = x + width / 2;
        labelTextAlign = "center";
      } else if (labelPlacement === "outsideEnd") {
        labelX = x + (isPositiveY ? width + labelPadding : -labelPadding);
        labelTextAlign = isPositiveY ? "start" : "end";
      } else if (labelPlacement === "insideEnd") {
        labelX = x + (isPositiveY ? width - labelPadding : labelPadding);
        labelTextAlign = isPositiveY ? "end" : "start";
        const textSize = _ModuleSupport5.CachedTextMeasurerPool.measureText(labelText, {
          font: labelFontFamily
        });
        const textWidth = textSize.width || 20;
        const positiveBoundary = yZero + textWidth;
        const negativeBoundary = yZero - textWidth;
        const exceedsBoundaries = isPositiveY && labelX < positiveBoundary || !isPositiveY && labelX > negativeBoundary;
        if (exceedsBoundaries) {
          labelX = yZero + labelPadding * (isPositiveY ? 1 : -1);
          labelTextAlign = isPositiveY ? "start" : "end";
        }
      } else {
        labelX = yZero + labelPadding * (isPositiveY ? 1 : -1);
        labelTextAlign = isPositiveY ? "start" : "end";
      }
      nodeData.push({
        x,
        y,
        width,
        height,
        fill,
        stroke,
        strokeWidth: strokeWidth2,
        seriesDatum: { x: xDatum, y: invalidDatum ? void 0 : yDatum },
        point: midPoint,
        label: {
          x: labelX,
          y: labelY,
          text: labelText,
          fontStyle: labelFontStyle,
          fontWeight: labelFontWeight,
          fontSize: labelFontSize,
          fontFamily: labelFontFamily,
          textAlign: labelTextAlign,
          textBaseline: labelTextBaseline,
          fill: labelColor
        }
      });
    }
    return nodeData;
  }
  getDistance(p1, p2) {
    return Math.abs(p1.y - p2.y);
  }
};
var { isNumber: isNumber3 } = _Util52;
var { BandScale: BandScale4 } = _Scale4;
var ColumnSparkline = class extends BarColumnSparkline {
  updateYScaleRange() {
    const { seriesRect, yScale } = this;
    yScale.range = [seriesRect.height, 0];
  }
  updateXScaleRange() {
    const { xScale, seriesRect, paddingOuter, paddingInner } = this;
    if (xScale instanceof BandScale4) {
      xScale.range = [0, seriesRect.width];
      xScale.paddingInner = paddingInner;
      xScale.paddingOuter = paddingOuter;
    } else {
      const step = this.calculateStep(seriesRect.width);
      const padding = step * paddingOuter;
      this.bandWidth = step * (1 - paddingInner);
      xScale.range = [padding, seriesRect.width - padding - this.bandWidth];
    }
  }
  updateAxisLine() {
    const {
      yScale,
      axis: { stroke, strokeWidth: strokeWidth2 },
      axisLine,
      seriesRect
    } = this;
    const yZero = yScale.convert(0);
    axisLine.x1 = 0;
    axisLine.x2 = seriesRect.width;
    axisLine.y1 = axisLine.y2 = yZero;
    axisLine.stroke = stroke;
    axisLine.strokeWidth = strokeWidth2 + (strokeWidth2 % 2 === 1 ? 1 : 0);
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale, fill, stroke, strokeWidth: strokeWidth2, label } = this;
    if (!data) {
      return;
    }
    const {
      fontStyle: labelFontStyle,
      fontWeight: labelFontWeight,
      fontSize: labelFontSize,
      fontFamily: labelFontFamily,
      color: labelColor,
      formatter: labelFormatter,
      placement: labelPlacement
    } = label;
    const nodeData = [];
    const yZero = yScale.convert(0);
    const continuous = !(xScale instanceof BandScale4);
    for (let i = 0, n = yData.length; i < n; i++) {
      let yDatum = yData[i];
      const xDatum = xData[i];
      const invalidDatum = yDatum === void 0;
      if (invalidDatum) {
        yDatum = 0;
      }
      const y = Math.min(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum);
      const bottom = Math.max(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const width = !continuous ? xScale.bandwidth : this.bandWidth;
      const height = bottom - y;
      const midPoint = {
        x: x + width / 2,
        y: yZero
      };
      let labelText;
      if (labelFormatter) {
        labelText = labelFormatter({ value: yDatum });
      } else {
        labelText = yDatum !== void 0 && isNumber3(yDatum) ? this.formatLabelValue(yDatum) : "";
      }
      const labelX = x + width / 2;
      let labelY;
      const labelTextAlign = "center";
      let labelTextBaseline;
      const isPositiveY = yDatum !== void 0 && yDatum >= 0;
      const labelPadding = 2;
      if (labelPlacement === "center") {
        labelY = y + height / 2;
        labelTextBaseline = "middle";
      } else if (labelPlacement === "outsideEnd") {
        labelY = y + (isPositiveY ? -labelPadding : height + labelPadding);
        labelTextBaseline = isPositiveY ? "bottom" : "top";
      } else if (labelPlacement === "insideEnd") {
        labelY = y + (isPositiveY ? labelPadding : height - labelPadding);
        labelTextBaseline = isPositiveY ? "top" : "bottom";
        const textSize = _ModuleSupport22.CachedTextMeasurerPool.measureText(labelText, {
          font: labelFontFamily
        });
        const textHeight = textSize.height || 10;
        const positiveBoundary = yZero - textHeight;
        const negativeBoundary = yZero + textHeight;
        const exceedsBoundaries = isPositiveY && labelY > positiveBoundary || !isPositiveY && labelY < negativeBoundary;
        if (exceedsBoundaries) {
          labelY = yZero + labelPadding * (isPositiveY ? -1 : 1);
          labelTextBaseline = isPositiveY ? "bottom" : "top";
        }
      } else {
        labelY = yZero + labelPadding * (isPositiveY ? -1 : 1);
        labelTextBaseline = isPositiveY ? "bottom" : "top";
      }
      nodeData.push({
        x,
        y,
        width,
        height,
        fill,
        stroke,
        strokeWidth: strokeWidth2,
        seriesDatum: { x: xDatum, y: invalidDatum ? void 0 : yDatum },
        point: midPoint,
        label: {
          x: labelX,
          y: labelY,
          text: labelText,
          fontStyle: labelFontStyle,
          fontWeight: labelFontWeight,
          fontSize: labelFontSize,
          fontFamily: labelFontFamily,
          textAlign: labelTextAlign,
          textBaseline: labelTextBaseline,
          fill: labelColor
        }
      });
    }
    return nodeData;
  }
};
var { extent: extent4 } = _Util62;
var { BandScale: BandScale5 } = _Scale5;
var SparklineMarker2 = class {
  constructor() {
    this.enabled = true;
    this.shape = "circle";
    this.size = 0;
    this.fill = "rgb(124, 181, 236)";
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
    this.formatter = void 0;
  }
};
var SparklineLine2 = class {
  constructor() {
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
  }
};
var SparklineCrosshairs2 = class {
  constructor() {
    this.xLine = {
      enabled: true,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
    this.yLine = {
      enabled: false,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
  }
};
var LineSparkline = class extends Sparkline {
  constructor() {
    super();
    this.linePath = new _Scene52.Path();
    this.xCrosshairLine = new _Scene52.Line();
    this.yCrosshairLine = new _Scene52.Line();
    this.lineSparklineGroup = new _Scene52.Group();
    this.markers = new _Scene52.Group();
    this.markerSelection = _Scene52.Selection.select(
      this.markers,
      () => this.markerFactory()
    );
    this.markerSelectionData = [];
    this.marker = new SparklineMarker2();
    this.line = new SparklineLine2();
    this.crosshairs = new SparklineCrosshairs2();
    this.rootGroup.append(this.lineSparklineGroup);
    this.linePath.zIndex = 1e3;
    this.xCrosshairLine.zIndex = 2e3;
    this.yCrosshairLine.zIndex = 2e3;
    this.markers.zIndex = 2500;
    this.lineSparklineGroup.append([this.linePath, this.xCrosshairLine, this.yCrosshairLine, this.markers]);
  }
  getNodeData() {
    return this.markerSelectionData;
  }
  markerFactory() {
    const { shape } = this.marker;
    const MarkerShape = getMarker(shape);
    return new MarkerShape();
  }
  /**
   * If marker shape is changed, this method should be called to remove the previous marker nodes selection.
   */
  onMarkerShapeChange() {
    this.markerSelection = this.markerSelection.clear();
    this.scheduleLayout();
  }
  update() {
    const nodeData = this.generateNodeData();
    if (!nodeData) {
      return;
    }
    this.markerSelectionData = nodeData;
    this.updateSelection(nodeData);
    this.updateNodes();
    this.updateLine();
  }
  updateYScaleDomain() {
    const { yData, yScale } = this;
    const yMinMax = extent4(yData);
    let yMin = 0;
    let yMax = 1;
    if (yMinMax != null) {
      yMin = this.min = yMinMax[0];
      yMax = this.max = yMinMax[1];
    }
    if (yMin === yMax) {
      const padding = Math.abs(yMin * 0.01);
      yMin -= padding;
      yMax += padding;
    }
    yScale.domain = [yMin, yMax];
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale } = this;
    if (!data) {
      return;
    }
    const continuous = !(xScale instanceof BandScale5);
    const offsetX = !continuous ? xScale.bandwidth / 2 : 0;
    const nodeData = [];
    for (let i = 0; i < yData.length; i++) {
      const yDatum = yData[i];
      const xDatum = xData[i];
      if (yDatum == void 0) {
        continue;
      }
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
      const y = yDatum === void 0 ? NaN : yScale.convert(yDatum);
      nodeData.push({
        seriesDatum: { x: xDatum, y: yDatum },
        point: { x, y }
      });
    }
    return nodeData;
  }
  updateSelection(selectionData) {
    this.markerSelection.update(selectionData);
  }
  updateNodes() {
    const { highlightedDatum, highlightStyle, marker } = this;
    const {
      size: highlightSize,
      fill: highlightFill,
      stroke: highlightStroke,
      strokeWidth: highlightStrokeWidth
    } = highlightStyle;
    const markerFormatter = marker.formatter;
    this.markerSelection.each((node, datum, index) => {
      const highlighted = datum === highlightedDatum;
      const markerFill = highlighted && highlightFill !== void 0 ? highlightFill : marker.fill;
      const markerStroke = highlighted && highlightStroke !== void 0 ? highlightStroke : marker.stroke;
      const markerStrokeWidth = highlighted && highlightStrokeWidth !== void 0 ? highlightStrokeWidth : marker.strokeWidth;
      const markerSize = highlighted && highlightSize !== void 0 ? highlightSize : marker.size;
      let markerFormat;
      const { seriesDatum, point } = datum;
      if (markerFormatter) {
        const first = index === 0;
        const last = index === this.markerSelectionData.length - 1;
        const min = seriesDatum.y === this.min;
        const max = seriesDatum.y === this.max;
        markerFormat = markerFormatter({
          datum,
          xValue: seriesDatum.x,
          yValue: seriesDatum.y,
          min,
          max,
          first,
          last,
          fill: markerFill,
          stroke: markerStroke,
          strokeWidth: markerStrokeWidth,
          size: markerSize,
          highlighted
        });
      }
      node.size = markerFormat && markerFormat.size != void 0 ? markerFormat.size : markerSize;
      node.fill = markerFormat && markerFormat.fill != void 0 ? markerFormat.fill : markerFill;
      node.stroke = markerFormat && markerFormat.stroke != void 0 ? markerFormat.stroke : markerStroke;
      node.strokeWidth = markerFormat && markerFormat.strokeWidth != void 0 ? markerFormat.strokeWidth : markerStrokeWidth;
      node.translationX = point.x;
      node.translationY = point.y;
      node.visible = markerFormat && markerFormat.enabled != void 0 ? markerFormat.enabled : marker.enabled && node.size > 0;
    });
  }
  updateLine() {
    const { linePath, yData, xData, xScale, yScale, line } = this;
    const path = linePath.path;
    path.clear();
    if (yData.length < 2) {
      return;
    }
    const continuous = !(xScale instanceof BandScale5);
    const n = yData.length;
    const offsetX = !continuous ? xScale.bandwidth / 2 : 0;
    let moveTo = true;
    for (let i = 0; i < n; i++) {
      const xDatum = xData[i];
      const yDatum = yData[i];
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
      const y = yDatum === void 0 ? NaN : yScale.convert(yDatum);
      if (yDatum == void 0) {
        moveTo = true;
      } else {
        if (moveTo) {
          path.moveTo(x, y);
          moveTo = false;
        } else {
          path.lineTo(x, y);
        }
      }
    }
    linePath.fill = void 0;
    linePath.stroke = line.stroke;
    linePath.strokeWidth = line.strokeWidth;
  }
  updateXCrosshairLine() {
    const {
      yScale,
      xCrosshairLine,
      highlightedDatum,
      crosshairs: { xLine }
    } = this;
    if (!xLine.enabled || highlightedDatum == void 0) {
      xCrosshairLine.strokeWidth = 0;
      return;
    }
    xCrosshairLine.y1 = yScale.range[0];
    xCrosshairLine.y2 = yScale.range[1];
    xCrosshairLine.x1 = xCrosshairLine.x2 = highlightedDatum.point.x;
    xCrosshairLine.stroke = xLine.stroke;
    xCrosshairLine.strokeWidth = xLine.strokeWidth ?? 1;
    xCrosshairLine.lineCap = xLine.lineCap === "round" || xLine.lineCap === "square" ? xLine.lineCap : void 0;
    const { lineDash: lineDash2 } = xLine;
    xCrosshairLine.lineDash = Array.isArray(lineDash2) ? lineDash2 : getLineDash(xCrosshairLine.lineCap, xLine.lineDash);
  }
  updateYCrosshairLine() {
    const {
      xScale,
      yCrosshairLine,
      highlightedDatum,
      crosshairs: { yLine }
    } = this;
    if (!yLine.enabled || highlightedDatum == void 0) {
      yCrosshairLine.strokeWidth = 0;
      return;
    }
    yCrosshairLine.x1 = xScale.range[0];
    yCrosshairLine.x2 = xScale.range[1];
    yCrosshairLine.y1 = yCrosshairLine.y2 = highlightedDatum.point.y;
    yCrosshairLine.stroke = yLine.stroke;
    yCrosshairLine.strokeWidth = yLine.strokeWidth ?? 1;
    yCrosshairLine.lineCap = yLine.lineCap === "round" || yLine.lineCap === "square" ? yLine.lineCap : void 0;
    const { lineDash: lineDash2 } = yLine;
    yCrosshairLine.lineDash = Array.isArray(lineDash2) ? lineDash2 : getLineDash(yCrosshairLine.lineCap, yLine.lineDash);
  }
  getTooltipHtml(datum) {
    const { dataType } = this;
    const { seriesDatum } = datum;
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    const content = this.formatNumericDatum(yValue);
    const title = dataType === "array" || dataType === "object" ? this.formatDatum(xValue) : void 0;
    const defaults = {
      content,
      title
    };
    const tooltipRenderer = this.processedOptions?.tooltip?.renderer;
    if (tooltipRenderer) {
      return toTooltipHtml(
        tooltipRenderer({
          context: this.context,
          datum: seriesDatum,
          yValue,
          xValue
        }),
        defaults
      );
    }
    return toTooltipHtml(defaults);
  }
};
var { isNumber: isNumber4 } = _Util7;
var AgSparkline = class {
  static create(options, tooltip) {
    options = _Util7.deepClone(options);
    const sparkline = getSparklineInstance(options.type);
    if (tooltip) {
      sparkline.tooltip = tooltip;
    }
    initSparkline(sparkline, options);
    initSparklineByType(sparkline, options);
    if (options.data) {
      sparkline.data = options.data;
    }
    sparkline.processedOptions = options;
    return sparkline;
  }
};
function getSparklineInstance(type = "line") {
  switch (type) {
    case "column":
      return new ColumnSparkline();
    case "bar":
      return new BarSparkline();
    case "area":
      return new AreaSparkline();
    case "line":
    default:
      return new LineSparkline();
  }
}
function initSparklineByType(sparkline, options) {
  switch (options.type) {
    case "bar":
      initBarColumnSparkline(sparkline, options);
      break;
    case "column":
      initBarColumnSparkline(sparkline, options);
      break;
    case "area":
      initAreaSparkline(sparkline, options);
      break;
    case "line":
    default:
      initLineSparkline(sparkline, options);
      break;
  }
}
function initSparkline(sparkline, options) {
  setValueIfPropertyExists(sparkline, "context", options.context, options);
  setValueIfPropertyExists(sparkline, "width", options.width, options);
  setValueIfPropertyExists(sparkline, "height", options.height, options);
  setValueIfPropertyExists(sparkline, "container", options.container, options);
  setValueIfPropertyExists(sparkline, "xKey", options.xKey, options);
  setValueIfPropertyExists(sparkline, "yKey", options.yKey, options);
  if (options.padding) {
    initPaddingOptions(sparkline.padding, options.padding);
  }
  if (options.axis) {
    initAxisOptions(sparkline.axis, options.axis);
  }
  if (options.highlightStyle) {
    initHighlightStyleOptions(sparkline.highlightStyle, options.highlightStyle);
  }
}
function initLineSparkline(sparkline, options) {
  if (options.marker) {
    initMarkerOptions(sparkline.marker, options.marker);
  }
  if (options.line) {
    initLineOptions(sparkline.line, options.line);
  }
  if (options.crosshairs) {
    initCrosshairsOptions(sparkline.crosshairs, options.crosshairs);
  }
}
function initAreaSparkline(sparkline, options) {
  setValueIfPropertyExists(sparkline, "fill", options.fill, options);
  if (options.marker) {
    initMarkerOptions(sparkline.marker, options.marker);
  }
  if (options.line) {
    initLineOptions(sparkline.line, options.line);
  }
  if (options.crosshairs) {
    initCrosshairsOptions(sparkline.crosshairs, options.crosshairs);
  }
}
function initBarColumnSparkline(sparkline, options) {
  setValueIfPropertyExists(sparkline, "valueAxisDomain", options.valueAxisDomain, options);
  setValueIfPropertyExists(sparkline, "fill", options.fill, options);
  setValueIfPropertyExists(sparkline, "stroke", options.stroke, options);
  setValueIfPropertyExists(sparkline, "strokeWidth", options.strokeWidth, options);
  setValueIfPropertyExists(sparkline, "paddingInner", options.paddingInner, options);
  setValueIfPropertyExists(sparkline, "paddingOuter", options.paddingOuter, options);
  setValueIfPropertyExists(sparkline, "formatter", options.formatter, options);
  if (options.label) {
    initLabelOptions(sparkline.label, options.label);
  }
}
function initPaddingOptions(target, options) {
  setValueIfPropertyExists(target, "top", options.top, options);
  setValueIfPropertyExists(target, "right", options.right, options);
  setValueIfPropertyExists(target, "bottom", options.bottom, options);
  setValueIfPropertyExists(target, "left", options.left, options);
}
function initMarkerOptions(target, options) {
  setValueIfPropertyExists(target, "enabled", options.enabled, options);
  setValueIfPropertyExists(target, "size", options.size, options);
  setValueIfPropertyExists(target, "shape", options.shape, options);
  setValueIfPropertyExists(target, "fill", options.fill, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
  setValueIfPropertyExists(target, "formatter", options.formatter, options);
}
function initLabelOptions(target, options) {
  setValueIfPropertyExists(target, "enabled", options.enabled, options);
  setValueIfPropertyExists(target, "fontStyle", options.fontStyle, options);
  setValueIfPropertyExists(target, "fontWeight", options.fontWeight, options);
  setValueIfPropertyExists(target, "fontSize", options.fontSize, options);
  setValueIfPropertyExists(target, "fontFamily", options.fontFamily, options);
  setValueIfPropertyExists(target, "textAlign", options.textAlign, options);
  setValueIfPropertyExists(target, "textBaseline", options.textBaseline, options);
  setValueIfPropertyExists(target, "color", options.color, options);
  setValueIfPropertyExists(target, "formatter", options.formatter, options);
  setValueIfPropertyExists(target, "placement", options.placement, options);
}
function initLineOptions(target, options) {
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
}
function initAxisOptions(target, options) {
  setValueIfPropertyExists(target, "type", options.type, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
}
function initHighlightStyleOptions(target, options) {
  setValueIfPropertyExists(target, "fill", options.fill, options);
  setValueIfPropertyExists(target, "size", options.size, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
}
function initCrosshairsOptions(target, options) {
  if (target.xLine && options.xLine) {
    initCrosshairLineOptions(target.xLine, options.xLine);
  }
  if (target.yLine && options.yLine) {
    initCrosshairLineOptions(target.yLine, options.yLine);
  }
}
function initCrosshairLineOptions(target, options) {
  setValueIfPropertyExists(target, "enabled", options.enabled, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
  setValueIfPropertyExists(target, "lineDash", options.lineDash, options);
  setValueIfPropertyExists(target, "lineCap", options.lineCap, options);
}
var offsetValidator = (property, value, defaultOffset) => {
  if (isNumber4(value)) {
    return true;
  }
  _warnOnce213(
    `${property} must be a number, the value you provided is not a valid number. Using the default of ${defaultOffset}px.`
  );
  return false;
};
var validators = {
  xOffset: offsetValidator,
  yOffset: offsetValidator
};
function setValueIfPropertyExists(target, property, value, options) {
  if (property in options) {
    if (property in target) {
      const validator = validators[property];
      const isValid = validator ? validator(property, value, target[property]) : true;
      if (isValid && target[property] !== value) {
        target[property] = value;
      }
    } else {
      _warnOnce213(`Property ${property} does not exist on the target object.`);
    }
  }
}
var SparklineCellRenderer = class extends Component59 {
  constructor() {
    super(
      /* html */
      `<div class="ag-sparkline-wrapper">
            <span data-ref="eSparkline"></span>
        </div>`
    );
    this.eSparkline = RefPlaceholder39;
  }
  wireBeans(beans) {
    this.resizeObserverService = beans.resizeObserverService;
    this.sparklineTooltipSingleton = beans.sparklineTooltipSingleton;
  }
  init(params) {
    let firstTimeIn = true;
    const updateSparkline = () => {
      const { clientWidth, clientHeight } = this.getGui();
      if (clientWidth === 0 || clientHeight === 0) {
        return;
      }
      if (firstTimeIn) {
        const options = {
          data: params.value,
          width: clientWidth,
          height: clientHeight,
          context: {
            data: params.data
          },
          ...params.sparklineOptions
        };
        this.sparkline = AgSparkline.create(options, this.sparklineTooltipSingleton.getSparklineTooltip());
        this.eSparkline.appendChild(this.sparkline.canvasElement);
        firstTimeIn = false;
      } else {
        this.sparkline.width = clientWidth;
        this.sparkline.height = clientHeight;
      }
    };
    const unsubscribeFromResize = this.resizeObserverService.observeResize(this.getGui(), updateSparkline);
    this.addDestroyFunc(() => unsubscribeFromResize());
  }
  refresh(params) {
    if (this.sparkline) {
      this.sparkline.data = params.value;
      this.sparkline.context = {
        data: params.data
      };
      return true;
    }
    return false;
  }
  destroy() {
    if (this.sparkline) {
      this.sparkline.destroy();
    }
    super.destroy();
  }
};
var SparklineTooltipSingleton = class extends BeanStub31 {
  constructor() {
    super(...arguments);
    this.beanName = "sparklineTooltipSingleton";
  }
  postConstruct() {
    this.tooltip = new SparklineTooltip();
  }
  getSparklineTooltip() {
    return this.tooltip;
  }
  destroy() {
    if (this.tooltip) {
      this.tooltip.destroy();
    }
    super.destroy();
  }
};
var VERSION17 = "32.3.0";
var SparklinesModule = _defineModule17({
  version: VERSION17,
  moduleName: ModuleNames18.SparklinesModule,
  beans: [SparklineTooltipSingleton],
  userComponents: [{ name: "agSparklineCellRenderer", classImp: SparklineCellRenderer }],
  dependantModules: [EnterpriseCoreModule]
});

// enterprise-modules/status-bar/src/statusBarModule.ts
import { ModuleNames as ModuleNames19, _defineModule as _defineModule18 } from "ag-grid-community";

// enterprise-modules/status-bar/src/statusBar/providedPanels/aggregationComp.ts
import {
  Component as Component61,
  RefPlaceholder as RefPlaceholder41,
  _exists as _exists18,
  _formatNumberTwoDecimalPlacesAndCommas,
  _isClientSideRowModel as _isClientSideRowModel6,
  _isServerSideRowModel as _isServerSideRowModel6,
  _missing as _missing7,
  _missingOrEmpty as _missingOrEmpty6,
  _warnOnce as _warnOnce41
} from "ag-grid-community";

// enterprise-modules/status-bar/src/statusBar/providedPanels/agNameValue.ts
import { Component as Component60, RefPlaceholder as RefPlaceholder40 } from "ag-grid-community";
var AgNameValue = class extends Component60 {
  constructor() {
    super(
      /* html */
      `<div class="ag-status-name-value">
            <span data-ref="eLabel"></span>:&nbsp;
            <span data-ref="eValue" class="ag-status-name-value-value"></span>
        </div>`
    );
    this.eLabel = RefPlaceholder40;
    this.eValue = RefPlaceholder40;
  }
  setLabel(key, defaultValue) {
    this.setDisplayed(false);
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.eLabel.innerHTML = localeTextFunc(key, defaultValue);
  }
  setValue(value) {
    this.eValue.innerHTML = value;
  }
};
var AgNameValueSelector = {
  selector: "AG-NAME-VALUE",
  component: AgNameValue
};

// enterprise-modules/status-bar/src/statusBar/providedPanels/aggregationComp.ts
var AggregationComp = class extends Component61 {
  constructor() {
    super(
      /* html */
      `<div class="ag-status-panel ag-status-panel-aggregations">
            <ag-name-value data-ref="avgAggregationComp"></ag-name-value>
            <ag-name-value data-ref="countAggregationComp"></ag-name-value>
            <ag-name-value data-ref="minAggregationComp"></ag-name-value>
            <ag-name-value data-ref="maxAggregationComp"></ag-name-value>
            <ag-name-value data-ref="sumAggregationComp"></ag-name-value>
        </div>`,
      [AgNameValueSelector]
    );
    this.sumAggregationComp = RefPlaceholder41;
    this.countAggregationComp = RefPlaceholder41;
    this.minAggregationComp = RefPlaceholder41;
    this.maxAggregationComp = RefPlaceholder41;
    this.avgAggregationComp = RefPlaceholder41;
  }
  wireBeans(beans) {
    this.valueService = beans.valueService;
    this.cellNavigationService = beans.cellNavigationService;
    this.cellPositionUtils = beans.cellPositionUtils;
    this.rowPositionUtils = beans.rowPositionUtils;
    this.rangeService = beans.rangeService;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  postConstruct() {
    if (!_isClientSideRowModel6(this.gos) && !_isServerSideRowModel6(this.gos)) {
      _warnOnce41(`agAggregationComponent should only be used with the client and server side row model.`);
      return;
    }
    this.avgAggregationComp.setLabel("avg", "Average");
    this.countAggregationComp.setLabel("count", "Count");
    this.minAggregationComp.setLabel("min", "Min");
    this.maxAggregationComp.setLabel("max", "Max");
    this.sumAggregationComp.setLabel("sum", "Sum");
    this.addManagedEventListeners({
      cellSelectionChanged: this.onCellSelectionChanged.bind(this),
      modelUpdated: this.onCellSelectionChanged.bind(this)
    });
  }
  init(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    this.onCellSelectionChanged();
    return true;
  }
  setAggregationComponentValue(aggFuncName, value, visible) {
    const statusBarValueComponent = this.getAllowedAggregationValueComponent(aggFuncName);
    if (_exists18(statusBarValueComponent) && statusBarValueComponent) {
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      const thousandSeparator = localeTextFunc("thousandSeparator", ",");
      const decimalSeparator = localeTextFunc("decimalSeparator", ".");
      statusBarValueComponent.setValue(
        _formatNumberTwoDecimalPlacesAndCommas(value, thousandSeparator, decimalSeparator)
      );
      statusBarValueComponent.setDisplayed(visible);
    } else {
      this.getAggregationValueComponent(aggFuncName)?.setDisplayed(false);
    }
  }
  getAllowedAggregationValueComponent(aggFuncName) {
    const { aggFuncs } = this.params;
    if (!aggFuncs || aggFuncs.includes(aggFuncName)) {
      return this.getAggregationValueComponent(aggFuncName);
    }
    return null;
  }
  getAggregationValueComponent(aggFuncName) {
    const refComponentName = `${aggFuncName}AggregationComp`;
    return this[refComponentName];
  }
  onCellSelectionChanged() {
    const cellRanges = this.rangeService?.getCellRanges();
    let sum = 0;
    let count = 0;
    let numberCount = 0;
    let min = null;
    let max = null;
    const cellsSoFar = {};
    if (cellRanges && !_missingOrEmpty6(cellRanges) && this.rangeService) {
      for (let i = 0; i < cellRanges.length; i++) {
        const cellRange = cellRanges[i];
        let currentRow = this.rangeService.getRangeStartRow(cellRange);
        const lastRow = this.rangeService.getRangeEndRow(cellRange);
        while (true) {
          const finishedAllRows = _missing7(currentRow) || !currentRow || this.rowPositionUtils.before(lastRow, currentRow);
          if (finishedAllRows || !currentRow || !cellRange.columns) {
            break;
          }
          cellRange.columns.forEach((col) => {
            if (currentRow === null) {
              return;
            }
            const cellId = this.cellPositionUtils.createId({
              rowPinned: currentRow.rowPinned,
              column: col,
              rowIndex: currentRow.rowIndex
            });
            if (cellsSoFar[cellId]) {
              return;
            }
            cellsSoFar[cellId] = true;
            const rowNode = this.rowPositionUtils.getRowNode(currentRow);
            if (_missing7(rowNode)) {
              return;
            }
            let value = this.valueService.getValue(col, rowNode);
            if (_missing7(value) || value === "") {
              return;
            }
            count++;
            if (typeof value === "object" && "value" in value) {
              value = value.value;
              if (value === "") {
                return;
              }
            }
            if (typeof value === "string") {
              value = Number(value);
            }
            if (typeof value === "number" && !isNaN(value)) {
              sum += value;
              if (max === null || value > max) {
                max = value;
              }
              if (min === null || value < min) {
                min = value;
              }
              numberCount++;
            }
          });
          currentRow = this.cellNavigationService.getRowBelow(currentRow);
        }
      }
    }
    const gotResult = count > 1;
    const gotNumberResult = numberCount > 1;
    this.setAggregationComponentValue("count", count, gotResult);
    this.setAggregationComponentValue("sum", sum, gotNumberResult);
    this.setAggregationComponentValue("min", min, gotNumberResult);
    this.setAggregationComponentValue("max", max, gotNumberResult);
    this.setAggregationComponentValue("avg", sum / numberCount, gotNumberResult);
  }
};

// enterprise-modules/status-bar/src/statusBar/providedPanels/filteredRowsComp.ts
import { _formatNumberCommas, _isClientSideRowModel as _isClientSideRowModel7, _warnOnce as _warnOnce47 } from "ag-grid-community";
var FilteredRowsComp = class extends AgNameValue {
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.setLabel("filteredRows", "Filtered");
    if (!_isClientSideRowModel7(this.gos)) {
      _warnOnce47(`agFilteredRowCountComponent should only be used with the client side row model.`);
      return;
    }
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-filtered-row-count");
    this.setDisplayed(true);
    const listener = this.onDataChanged.bind(this);
    this.addManagedEventListeners({ modelUpdated: listener });
    listener();
  }
  onDataChanged() {
    const totalRowCountValue = this.getTotalRowCountValue();
    const filteredRowCountValue = this.getFilteredRowCountValue();
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    this.setValue(_formatNumberCommas(filteredRowCountValue, thousandSeparator, decimalSeparator));
    this.setDisplayed(totalRowCountValue !== filteredRowCountValue);
  }
  getTotalRowCountValue() {
    let totalRowCount = 0;
    this.rowModel.forEachNode(() => totalRowCount += 1);
    return totalRowCount;
  }
  getFilteredRowCountValue() {
    let filteredRowCount = 0;
    this.rowModel.forEachNodeAfterFilter((node) => {
      if (!node.group) {
        filteredRowCount += 1;
      }
    });
    return filteredRowCount;
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};

// enterprise-modules/status-bar/src/statusBar/providedPanels/selectedRowsComp.ts
import { _formatNumberCommas as _formatNumberCommas2, _isClientSideRowModel as _isClientSideRowModel8, _isServerSideRowModel as _isServerSideRowModel7, _warnOnce as _warnOnce48 } from "ag-grid-community";
var SelectedRowsComp = class extends AgNameValue {
  wireBeans(beans) {
    this.selectionService = beans.selectionService;
  }
  postConstruct() {
    if (!_isClientSideRowModel8(this.gos) && !_isServerSideRowModel7(this.gos)) {
      _warnOnce48(`agSelectedRowCountComponent should only be used with the client and server side row model.`);
      return;
    }
    this.setLabel("selectedRows", "Selected");
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-selected-row-count");
    this.onRowSelectionChanged();
    const eventListener = this.onRowSelectionChanged.bind(this);
    this.addManagedEventListeners({ modelUpdated: eventListener, selectionChanged: eventListener });
  }
  onRowSelectionChanged() {
    const selectedRowCount = this.selectionService.getSelectionCount();
    if (selectedRowCount < 0) {
      this.setValue("?");
      this.setDisplayed(true);
      return;
    }
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    this.setValue(_formatNumberCommas2(selectedRowCount, thousandSeparator, decimalSeparator));
    this.setDisplayed(selectedRowCount > 0);
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};

// enterprise-modules/status-bar/src/statusBar/providedPanels/totalAndFilteredRowsComp.ts
import { _formatNumberCommas as _formatNumberCommas3, _isClientSideRowModel as _isClientSideRowModel9, _warnOnce as _warnOnce49 } from "ag-grid-community";
var TotalAndFilteredRowsComp = class extends AgNameValue {
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    if (!_isClientSideRowModel9(this.gos)) {
      _warnOnce49(`agTotalAndFilteredRowCountComponent should only be used with the client side row model.`);
      return;
    }
    this.setLabel("totalAndFilteredRows", "Rows");
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-total-and-filtered-row-count");
    this.setDisplayed(true);
    this.addManagedEventListeners({ modelUpdated: this.onDataChanged.bind(this) });
    this.onDataChanged();
  }
  onDataChanged() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    const rowCount = _formatNumberCommas3(this.getFilteredRowCountValue(), thousandSeparator, decimalSeparator);
    const totalRowCount = _formatNumberCommas3(this.getTotalRowCount(), thousandSeparator, decimalSeparator);
    if (rowCount === totalRowCount) {
      this.setValue(rowCount);
    } else {
      const localeTextFunc2 = this.localeService.getLocaleTextFunc();
      this.setValue(`${rowCount} ${localeTextFunc2("of", "of")} ${totalRowCount}`);
    }
  }
  getFilteredRowCountValue() {
    let filteredRowCount = 0;
    this.rowModel.forEachNodeAfterFilter((node) => {
      if (!node.group) {
        filteredRowCount++;
      }
    });
    return filteredRowCount;
  }
  getTotalRowCount() {
    let totalRowCount = 0;
    this.rowModel.forEachNode((node) => {
      if (!node.group) {
        totalRowCount++;
      }
    });
    return totalRowCount;
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};

// enterprise-modules/status-bar/src/statusBar/providedPanels/totalRowsComp.ts
import { _formatNumberCommas as _formatNumberCommas4, _isClientSideRowModel as _isClientSideRowModel10, _warnOnce as _warnOnce50 } from "ag-grid-community";
var TotalRowsComp = class extends AgNameValue {
  wireBeans(beans) {
    this.rowModel = beans.rowModel;
  }
  postConstruct() {
    this.setLabel("totalRows", "Total Rows");
    if (!_isClientSideRowModel10(this.gos)) {
      _warnOnce50("agTotalRowCountComponent should only be used with the client side row model.");
      return;
    }
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-total-row-count");
    this.setDisplayed(true);
    this.addManagedEventListeners({ modelUpdated: this.onDataChanged.bind(this) });
    this.onDataChanged();
  }
  onDataChanged() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    this.setValue(_formatNumberCommas4(this.getRowCountValue(), thousandSeparator, decimalSeparator));
  }
  getRowCountValue() {
    let totalRowCount = 0;
    this.rowModel.forEachLeafNode(() => totalRowCount += 1);
    return totalRowCount;
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};

// enterprise-modules/status-bar/src/statusBar/statusBarApi.ts
import { _unwrapUserComp as _unwrapUserComp2 } from "ag-grid-community";
function getStatusPanel(beans, key) {
  const comp = beans.statusBarService.getStatusPanel(key);
  return _unwrapUserComp2(comp);
}

// enterprise-modules/status-bar/src/statusBar/statusBarService.ts
import { BeanStub as BeanStub37 } from "ag-grid-community";

// enterprise-modules/status-bar/src/statusBar/agStatusBar.ts
import { AgPromise as AgPromise8, Component as Component65, RefPlaceholder as RefPlaceholder46, _removeFromParent as _removeFromParent9 } from "ag-grid-community";
var AgStatusBar = class extends Component65 {
  constructor() {
    super(
      /* html */
      `<div class="ag-status-bar">
            <div data-ref="eStatusBarLeft" class="ag-status-bar-left" role="status"></div>
            <div data-ref="eStatusBarCenter" class="ag-status-bar-center" role="status"></div>
            <div data-ref="eStatusBarRight" class="ag-status-bar-right" role="status"></div>
        </div>`
    );
    this.updateQueued = false;
    this.panelsPromise = AgPromise8.resolve();
    this.eStatusBarLeft = RefPlaceholder46;
    this.eStatusBarCenter = RefPlaceholder46;
    this.eStatusBarRight = RefPlaceholder46;
    this.compDestroyFunctions = {};
  }
  wireBeans(beans) {
    this.userComponentFactory = beans.userComponentFactory;
    this.statusBarService = beans.statusBarService;
  }
  postConstruct() {
    this.processStatusPanels(/* @__PURE__ */ new Map());
    this.addManagedPropertyListeners(["statusBar"], this.handleStatusBarChanged.bind(this));
  }
  processStatusPanels(existingStatusPanelsToReuse) {
    const statusPanels = this.gos.get("statusBar")?.statusPanels;
    if (statusPanels) {
      const leftStatusPanelComponents = statusPanels.filter(
        (componentConfig) => componentConfig.align === "left"
      );
      const centerStatusPanelComponents = statusPanels.filter(
        (componentConfig) => componentConfig.align === "center"
      );
      const rightStatusPanelComponents = statusPanels.filter(
        (componentConfig) => !componentConfig.align || componentConfig.align === "right"
      );
      this.panelsPromise = AgPromise8.all([
        this.createAndRenderComponents(
          leftStatusPanelComponents,
          this.eStatusBarLeft,
          existingStatusPanelsToReuse
        ),
        this.createAndRenderComponents(
          centerStatusPanelComponents,
          this.eStatusBarCenter,
          existingStatusPanelsToReuse
        ),
        this.createAndRenderComponents(
          rightStatusPanelComponents,
          this.eStatusBarRight,
          existingStatusPanelsToReuse
        )
      ]);
    } else {
      this.setDisplayed(false);
    }
  }
  handleStatusBarChanged() {
    if (this.updateQueued) {
      return;
    }
    this.updateQueued = true;
    this.panelsPromise.then(() => {
      this.updateStatusBar();
      this.updateQueued = false;
    });
  }
  updateStatusBar() {
    const statusPanels = this.gos.get("statusBar")?.statusPanels;
    const validStatusBarPanelsProvided = Array.isArray(statusPanels) && statusPanels.length > 0;
    this.setDisplayed(validStatusBarPanelsProvided);
    const existingStatusPanelsToReuse = /* @__PURE__ */ new Map();
    if (validStatusBarPanelsProvided) {
      statusPanels.forEach((statusPanelConfig) => {
        const key = statusPanelConfig.key ?? statusPanelConfig.statusPanel;
        const existingStatusPanel = this.statusBarService.getStatusPanel(key);
        if (existingStatusPanel?.refresh) {
          const newParams = this.gos.addGridCommonParams(statusPanelConfig.statusPanelParams ?? {});
          const hasRefreshed = existingStatusPanel.refresh(newParams);
          if (hasRefreshed) {
            existingStatusPanelsToReuse.set(key, existingStatusPanel);
            delete this.compDestroyFunctions[key];
            _removeFromParent9(existingStatusPanel.getGui());
          }
        }
      });
    }
    this.resetStatusBar();
    if (validStatusBarPanelsProvided) {
      this.processStatusPanels(existingStatusPanelsToReuse);
    }
  }
  resetStatusBar() {
    this.eStatusBarLeft.innerHTML = "";
    this.eStatusBarCenter.innerHTML = "";
    this.eStatusBarRight.innerHTML = "";
    this.destroyComponents();
    this.statusBarService.unregisterAllComponents();
  }
  destroy() {
    this.destroyComponents();
    super.destroy();
  }
  destroyComponents() {
    Object.values(this.compDestroyFunctions).forEach((func) => func());
    this.compDestroyFunctions = {};
  }
  createAndRenderComponents(statusBarComponents, ePanelComponent, existingStatusPanelsToReuse) {
    const componentDetails = [];
    statusBarComponents.forEach((componentConfig) => {
      const key = componentConfig.key || componentConfig.statusPanel;
      const existingStatusPanel = existingStatusPanelsToReuse.get(key);
      let promise;
      if (existingStatusPanel) {
        promise = AgPromise8.resolve(existingStatusPanel);
      } else {
        const params = {};
        const compDetails = this.userComponentFactory.getStatusPanelCompDetails(componentConfig, params);
        promise = compDetails.newAgStackInstance();
        if (promise == null) {
          return;
        }
      }
      componentDetails.push({
        key,
        promise
      });
    });
    return AgPromise8.all(componentDetails.map((details) => details.promise)).then(() => {
      componentDetails.forEach((componentDetail) => {
        componentDetail.promise.then((component) => {
          const destroyFunc = () => {
            this.destroyBean(component);
          };
          if (this.isAlive()) {
            this.statusBarService.registerStatusPanel(componentDetail.key, component);
            ePanelComponent.appendChild(component.getGui());
            this.compDestroyFunctions[componentDetail.key] = destroyFunc;
          } else {
            destroyFunc();
          }
        });
      });
    });
  }
};
var AgStatusBarSelector = {
  selector: "AG-STATUS-BAR",
  component: AgStatusBar
};

// enterprise-modules/status-bar/src/statusBar/statusBarService.ts
var StatusBarService = class extends BeanStub37 {
  // tslint:disable-next-line
  constructor() {
    super();
    this.beanName = "statusBarService";
    this.allComponents = /* @__PURE__ */ new Map();
  }
  registerStatusPanel(key, component) {
    this.allComponents.set(key, component);
  }
  unregisterStatusPanel(key) {
    this.allComponents.delete(key);
  }
  unregisterAllComponents() {
    this.allComponents.clear();
  }
  getStatusPanel(key) {
    return this.allComponents.get(key);
  }
  getStatusPanelSelector() {
    return AgStatusBarSelector;
  }
  destroy() {
    this.unregisterAllComponents();
    super.destroy();
  }
};

// enterprise-modules/status-bar/src/version.ts
var VERSION18 = "32.3.0";

// enterprise-modules/status-bar/src/statusBarModule.ts
var StatusBarCoreModule = _defineModule18({
  version: VERSION18,
  moduleName: `${ModuleNames19.StatusBarModule}-core`,
  beans: [StatusBarService],
  userComponents: [
    { name: "agAggregationComponent", classImp: AggregationComp },
    { name: "agSelectedRowCountComponent", classImp: SelectedRowsComp },
    { name: "agTotalRowCountComponent", classImp: TotalRowsComp },
    { name: "agFilteredRowCountComponent", classImp: FilteredRowsComp },
    { name: "agTotalAndFilteredRowCountComponent", classImp: TotalAndFilteredRowsComp }
  ],
  dependantModules: [EnterpriseCoreModule]
});
var StatusBarApiModule = _defineModule18({
  version: VERSION18,
  moduleName: `${ModuleNames19.StatusBarModule}-api`,
  apiFunctions: {
    getStatusPanel
  },
  dependantModules: [StatusBarCoreModule]
});
var StatusBarModule = _defineModule18({
  version: VERSION18,
  moduleName: ModuleNames19.StatusBarModule,
  dependantModules: [StatusBarCoreModule, StatusBarApiModule]
});

// enterprise-modules/viewport-row-model/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames20, _defineModule as _defineModule19 } from "ag-grid-community";
import { BeanStub as BeanStub38, RowNode as RowNode4, _getRowHeightAsNumber as _getRowHeightAsNumber5, _iterateObject as _iterateObject6, _missing as _missing8, _warnOnce as _warnOnce51 } from "ag-grid-community";
var VERSION19 = "32.3.0";
var ViewportRowModel = class extends BeanStub38 {
  constructor() {
    super(...arguments);
    this.beanName = "rowModel";
    this.firstRow = -1;
    this.lastRow = -1;
    this.rowCount = -1;
    this.rowNodesByIndex = {};
  }
  wireBeans(beans) {
    this.rowRenderer = beans.rowRenderer;
    this.focusService = beans.focusService;
    this.beans = beans;
  }
  // we don't implement as lazy row heights is not supported in this row model
  ensureRowHeightsValid(startPixel, endPixel, startLimitIndex, endLimitIndex) {
    return false;
  }
  postConstruct() {
    this.rowHeight = _getRowHeightAsNumber5(this.gos);
    this.addManagedEventListeners({ viewportChanged: this.onViewportChanged.bind(this) });
    this.addManagedPropertyListener("viewportDatasource", () => this.updateDatasource());
    this.addManagedPropertyListener("rowHeight", () => {
      this.rowHeight = _getRowHeightAsNumber5(this.gos);
      this.updateRowHeights();
    });
  }
  start() {
    this.updateDatasource();
  }
  isLastRowIndexKnown() {
    return true;
  }
  destroy() {
    this.destroyDatasource();
    super.destroy();
  }
  destroyDatasource() {
    if (!this.viewportDatasource) {
      return;
    }
    if (this.viewportDatasource.destroy) {
      this.viewportDatasource.destroy();
    }
    this.rowRenderer.datasourceChanged();
    this.firstRow = -1;
    this.lastRow = -1;
  }
  updateDatasource() {
    const datasource = this.gos.get("viewportDatasource");
    if (datasource) {
      this.setViewportDatasource(datasource);
    }
  }
  getViewportRowModelPageSize() {
    return this.gos.get("viewportRowModelPageSize");
  }
  getViewportRowModelBufferSize() {
    return this.gos.get("viewportRowModelBufferSize");
  }
  calculateFirstRow(firstRenderedRow) {
    const bufferSize = this.getViewportRowModelBufferSize();
    const pageSize = this.getViewportRowModelPageSize();
    const afterBuffer = firstRenderedRow - bufferSize;
    if (afterBuffer < 0) {
      return 0;
    }
    return Math.floor(afterBuffer / pageSize) * pageSize;
  }
  calculateLastRow(lastRenderedRow) {
    if (lastRenderedRow === -1) {
      return lastRenderedRow;
    }
    const bufferSize = this.getViewportRowModelBufferSize();
    const pageSize = this.getViewportRowModelPageSize();
    const afterBuffer = lastRenderedRow + bufferSize;
    const result = Math.ceil(afterBuffer / pageSize) * pageSize;
    const lastRowIndex = this.rowCount - 1;
    return Math.min(result, lastRowIndex);
  }
  onViewportChanged(event) {
    const newFirst = this.calculateFirstRow(event.firstRow);
    const newLast = this.calculateLastRow(event.lastRow);
    if (this.firstRow !== newFirst || this.lastRow !== newLast) {
      this.firstRow = newFirst;
      this.lastRow = newLast;
      this.purgeRowsNotInViewport();
      if (this.viewportDatasource) {
        this.viewportDatasource.setViewportRange(this.firstRow, this.lastRow);
      }
    }
  }
  purgeRowsNotInViewport() {
    Object.keys(this.rowNodesByIndex).forEach((indexStr) => {
      const index = parseInt(indexStr, 10);
      if (index < this.firstRow || index > this.lastRow) {
        if (this.isRowFocused(index)) {
          return;
        }
        delete this.rowNodesByIndex[index];
      }
    });
  }
  isRowFocused(rowIndex) {
    const focusedCell = this.focusService.getFocusCellToUseAfterRefresh();
    if (!focusedCell) {
      return false;
    }
    if (focusedCell.rowPinned != null) {
      return false;
    }
    const hasFocus = focusedCell.rowIndex === rowIndex;
    return hasFocus;
  }
  setViewportDatasource(viewportDatasource) {
    this.destroyDatasource();
    this.viewportDatasource = viewportDatasource;
    this.rowCount = -1;
    if (!viewportDatasource.init) {
      _warnOnce51("viewport is missing init method.");
    } else {
      viewportDatasource.init({
        setRowCount: this.setRowCount.bind(this),
        setRowData: this.setRowData.bind(this),
        getRow: this.getRow.bind(this)
      });
    }
  }
  getType() {
    return "viewport";
  }
  getRow(rowIndex) {
    if (!this.rowNodesByIndex[rowIndex]) {
      this.rowNodesByIndex[rowIndex] = this.createBlankRowNode(rowIndex);
    }
    return this.rowNodesByIndex[rowIndex];
  }
  getRowNode(id) {
    let result;
    this.forEachNode((rowNode) => {
      if (rowNode.id === id) {
        result = rowNode;
      }
    });
    return result;
  }
  getRowCount() {
    return this.rowCount === -1 ? 0 : this.rowCount;
  }
  getRowIndexAtPixel(pixel) {
    if (this.rowHeight !== 0) {
      return Math.floor(pixel / this.rowHeight);
    }
    return 0;
  }
  getRowBounds(index) {
    return {
      rowHeight: this.rowHeight,
      rowTop: this.rowHeight * index
    };
  }
  updateRowHeights() {
    this.forEachNode((node) => {
      node.setRowHeight(this.rowHeight);
      node.setRowTop(this.rowHeight * node.rowIndex);
    });
    this.eventService.dispatchEvent({
      type: "modelUpdated",
      newData: false,
      newPage: false,
      keepRenderedRows: true,
      animate: false
    });
  }
  getTopLevelRowCount() {
    return this.getRowCount();
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    return topLevelIndex;
  }
  isEmpty() {
    return this.rowCount > 0;
  }
  isRowsToRender() {
    return this.rowCount > 0;
  }
  getNodesInRangeForSelection(firstInRange, lastInRange) {
    const firstIndex = firstInRange.rowIndex;
    const lastIndex = lastInRange.rowIndex;
    const firstNodeOutOfRange = firstIndex < this.firstRow || firstIndex > this.lastRow;
    const lastNodeOutOfRange = lastIndex < this.firstRow || lastIndex > this.lastRow;
    if (firstNodeOutOfRange || lastNodeOutOfRange) {
      return [];
    }
    const result = [];
    const startIndex = firstIndex <= lastIndex ? firstIndex : lastIndex;
    const endIndex = firstIndex <= lastIndex ? lastIndex : firstIndex;
    for (let i = startIndex; i <= endIndex; i++) {
      result.push(this.rowNodesByIndex[i]);
    }
    return result;
  }
  forEachNode(callback) {
    let callbackCount = 0;
    Object.keys(this.rowNodesByIndex).forEach((indexStr) => {
      const index = parseInt(indexStr, 10);
      const rowNode = this.rowNodesByIndex[index];
      callback(rowNode, callbackCount);
      callbackCount++;
    });
  }
  setRowData(rowData) {
    _iterateObject6(rowData, (indexStr, dataItem) => {
      const index = parseInt(indexStr, 10);
      if (index >= this.firstRow && index <= this.lastRow) {
        let rowNode = this.rowNodesByIndex[index];
        if (_missing8(rowNode)) {
          rowNode = this.createBlankRowNode(index);
          this.rowNodesByIndex[index] = rowNode;
        }
        rowNode.setDataAndId(dataItem, index.toString());
      }
    });
  }
  createBlankRowNode(rowIndex) {
    const rowNode = new RowNode4(this.beans);
    rowNode.setRowHeight(this.rowHeight);
    rowNode.setRowTop(this.rowHeight * rowIndex);
    rowNode.setRowIndex(rowIndex);
    return rowNode;
  }
  setRowCount(rowCount, keepRenderedRows = false) {
    if (rowCount === this.rowCount) {
      return;
    }
    this.rowCount = rowCount;
    this.eventService.dispatchEventOnce({
      type: "rowCountReady"
    });
    this.eventService.dispatchEvent({
      type: "modelUpdated",
      newData: false,
      newPage: false,
      keepRenderedRows,
      animate: false
    });
  }
  isRowPresent(rowNode) {
    const foundRowNode = this.getRowNode(rowNode.id);
    return !!foundRowNode;
  }
};
var ViewportRowModelModule = _defineModule19({
  version: VERSION19,
  moduleName: ModuleNames20.ViewportRowModelModule,
  rowModel: "viewport",
  beans: [ViewportRowModel],
  dependantModules: [EnterpriseCoreModule]
});

// packages/ag-grid-enterprise/src/main.ts
__reExport(main_exports, main_esm_exports);
export * from "ag-grid-community";
export * from "ag-grid-community";
ModuleRegistry2.__registerModules(
  [
    CommunityFeaturesModule,
    ClientSideRowModelModule,
    InfiniteRowModelModule,
    CsvExportModule2,
    AdvancedFilterModule,
    GridChartsModule,
    ClipboardModule,
    ColumnsToolPanelModule,
    ExcelExportModule,
    FiltersToolPanelModule,
    MasterDetailModule,
    MenuModule,
    MultiFilterModule,
    RangeSelectionModule,
    RichSelectModule,
    RowGroupingModule,
    ServerSideRowModelModule,
    SetFilterModule,
    SideBarModule,
    SparklinesModule,
    StatusBarModule,
    ViewportRowModelModule
  ],
  false,
  void 0
);
export {
  AdvancedFilterModule,
  AgDialog,
  AgGroupComponent,
  AgGroupComponentSelector,
  AgMenuItemComponent,
  AgMenuItemRenderer,
  AgMenuList,
  AgMenuPanel,
  AgPanel,
  AgPrimaryCols,
  AgRichSelect,
  ClipboardModule,
  ColumnsToolPanelModule,
  EnterpriseCoreModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GridChartsModule,
  GroupCellRenderer,
  GroupCellRendererCtrl,
  GridLicenseManager as LicenseManager,
  MasterDetailModule,
  MenuModule,
  MultiFilter,
  MultiFilterModule,
  PillDragComp,
  PillDropZonePanel,
  PivotDropZonePanel,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupDropZonePanel,
  RowGroupingModule,
  ServerSideRowModelModule,
  SetFilter,
  SetFilterModule,
  SideBarModule,
  SparklinesModule,
  StatusBarModule,
  TabbedLayout,
  ToolPanelColDefService,
  ValuesDropZonePanel,
  ViewportRowModelModule,
  VirtualList,
  VirtualListDragFeature,
  agCharts,
  exportMultipleSheetsAsExcel,
  getMultipleSheetsAsExcel
};
