import { ColDef } from 'ag-grid-community';
import Member from '../../../ts/models/Member.model';
import { formatTimeLocal } from '../../../utils/time';
import { getStatusName } from '../../../utils/methods';
import { DEFAULT_DATE_FILTER_OPS } from '../../../utils/agGrid/options';

const COUNTRY_FIELD = 'country';
const LOCATION_STATE_FIELD = 'locationState';
export const MATCH_FILTER_FIELDS = [COUNTRY_FIELD, LOCATION_STATE_FIELD];
export type MemberGridRow = Member & { id: string };
const columnDefs: ColDef<MemberGridRow>[] = [
  {
    headerName: 'Clearing Member Type',
    field: 'clearingMemberType',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Firm Code',
    field: 'firmCode',
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
  {
    headerName: 'Name',
    field: 'name',
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
  {
    headerName: 'E-Market Organization Name',
    field: 'emarketOrganizationName',
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
  {
    headerName: 'State',
    field: LOCATION_STATE_FIELD,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
  {
    headerName: 'Country',
    field: COUNTRY_FIELD,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
  {
    headerName: 'Created Date',
    field: 'createdAt',
    valueFormatter: ({ value }) => formatTimeLocal(value),
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    valueFormatter: ({ value }) => getStatusName(value),
    filter: 'agTextColumnFilter',
    floatingFilter: false,
    suppressHeaderFilterButton: true,
  },
  {
    field: 'id',
    hide: true,
    filter: 'agTextColumnFilter',
    suppressColumnsToolPanel: true,
    floatingFilter: false,
  },
  {
    headerName: 'Status Date',
    field: 'statusDate',
    valueFormatter: ({ value }) => formatTimeLocal(value),
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: DEFAULT_DATE_FILTER_OPS,
    },
  },
];

export default columnDefs;
