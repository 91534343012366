function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { camelCase as camelCaseString } from "camel-case";
import { snakeCase as snakeCaseString } from "snake-case";
import { headerCase as headerCaseString } from "header-case";
import { preserveArrayBrackets, preserveSpecificKeys } from "./decorators";
import { isFormData, isTransformable } from "./util";

var transformRecursive = function transformRecursive(data, fn, overwrite) {
  var _prototype$entries;

  if (!isTransformable(data)) {
    return data;
  }
  /* eslint-disable no-console */


  if (isFormData(data) && !data.entries) {
    if (navigator.product === "ReactNative") {
      console.warn("Be careful that FormData cannot be transformed on React Native. If you intentionally implemented, ignore this kind of warning: https://facebook.github.io/react-native/docs/debugging.html");
    } else {
      console.warn("You must use polyfill of FormData.prototype.entries() on Internet Explorer or Safari: https://github.com/jimmywarting/FormData");
    }

    return data;
  }
  /* eslint-enable no-console */


  var prototype = Object.getPrototypeOf(data);
  var store = overwrite ? data : !prototype ? Object.create(null) : new prototype.constructor();

  var _iterator = _createForOfIteratorHelper((prototype === null || prototype === void 0 ? void 0 : (_prototype$entries = prototype.entries) === null || _prototype$entries === void 0 ? void 0 : _prototype$entries.call(data)) || Object.entries(data)),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
          key = _step$value[0],
          value = _step$value[1];

      if (prototype === null || prototype === void 0 ? void 0 : prototype.append) {
        prototype.append.call(store, typeof key === "string" ? fn(key) : key, transformRecursive(value, fn, overwrite));
      } else if (key !== "__proto__") {
        store[fn(typeof key === "string" ? key : "".concat(key))] = transformRecursive(value, fn, overwrite);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return store;
};

var transform = function transform(data, fn, options) {
  var _options, _options2;

  // Backward compatibility
  options = typeof options === "boolean" ? {
    overwrite: options
  } : options;
  var composedFn = preserveSpecificKeys(preserveArrayBrackets(fn), ((_options = options) === null || _options === void 0 ? void 0 : _options.preservedKeys) || []);
  return transformRecursive(data, composedFn, ((_options2 = options) === null || _options2 === void 0 ? void 0 : _options2.overwrite) || false);
};

export var createTransform = function createTransform(fn) {
  return function (data, options) {
    return transform(data, fn, options);
  };
};
export var snake = createTransform(snakeCaseString);
export var camel = createTransform(camelCaseString);
export var header = createTransform(headerCaseString);
export var createTransformOf = function createTransformOf(functionName, options) {
  var fn = options === null || options === void 0 ? void 0 : options[functionName];
  return fn ? createTransform(fn) : {
    snake: snake,
    camel: camel,
    header: header
  }[functionName];
};
export var createTransforms = function createTransforms(options) {
  var transforms = {
    snake: snake,
    camel: camel,
    header: header
  };
  var functionNames = Object.keys(transforms);

  for (var _i2 = 0, _functionNames = functionNames; _i2 < _functionNames.length; _i2++) {
    var functionName = _functionNames[_i2];
    transforms[functionName] = createTransformOf(functionName, options);
  }

  return transforms;
};
export default transform;