import { CustomFilterProps } from 'ag-grid-react';
import React from 'react';
import InputFilter from '../../../utils/agGrid/components/InputFilter';

type Props = CustomFilterProps;

const MemberNameFilter = (props: Props) => {
  return (
    <div
      className="ag-simple-filter-body-wrapper"
      style={{ minWidth: '230px' }}
    >
      <InputFilter
        field="firstName"
        placeholder="Search First Name..."
        {...props}
      />
      <InputFilter
        field="middleName"
        placeholder="Search Middle Name..."
        {...props}
      />
      <InputFilter
        field="lastName"
        placeholder="Search Last Name..."
        {...props}
      />
    </div>
  );
};

export default MemberNameFilter;
