import _ from 'lodash';
import { combineReducers } from 'redux';
import { arrayToObject } from 'erisxkit/client';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';
import {
  fetchApprovalLogs,
  approve,
  fetchApprovalReqs,
} from '../actions/approvalReqsActions';

export const REJECT = 'REJECT';
export const reject = createRoutine(REJECT);
export const rejectPromiseCreator = promisifyRoutine(reject);

export const selectedApprovalReqReducer = (state = '', action) => {
  switch (action.type) {
    case actionTypes.SELECT_APPROVAL:
      return action.uuid;
    default:
      return state;
  }
};

export const approvalReqsReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchApprovalReqs.SUCCESS:
      return {
        ...state,
        approvalReqs: arrayToObject(action.payload.approvalReqs, 'uuid'),
      };
    case fetchApprovalLogs.SUCCESS:
      return {
        ...state,
        approvalLogs: arrayToObject(action.payload.approvalLogs, 'uuid'),
      };
    case approve.SUCCESS:
    case reject.SUCCESS:
      const result = action.payload || action.data[action.uuid];
      const uuid = result.uuid || action.uuid;
      const { [uuid]: omit, ...approvalReqs } = state.approvalReqs;
      return {
        ...state,
        approvalLogs: { [uuid]: result, ...state.approvalLogs },
        approvalReqs,
      };
    default:
      return state;
  }
};

export const approvalReqsPayloadReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.APPROVAL_REQ_PAYLOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

function count(state = 0, action) {
  switch (action.type) {
    case fetchApprovalReqs.SUCCESS:
      return { ...state, pendingApprovalReqsCount: action.payload.count };
    case fetchApprovalLogs.SUCCESS:
      return { ...state, doneApprovalReqsCount: action.payload.count };
    default:
      return state;
  }
}

export default combineReducers({
  selectedApprovalReq: selectedApprovalReqReducer,
  byId: approvalReqsReducer,
  payload: approvalReqsPayloadReducer,
  count,
});

/* Selectors */
export const getApprovalReqsList = (approvalState) => (state) => {
  if (approvalState === 'done') {
    return Object.values(
      _.get(state, ['approvalReqs', 'byId', 'approvalLogs'], []),
    );
  }
  return Object.values(
    _.get(state, ['approvalReqs', 'byId', 'approvalReqs'], []),
  );
};

export const getApprovalReqsCount = (approvalState) => (state) => {
  if (approvalState === 'done') {
    return _.get(state, ['approvalReqs', 'count', 'doneApprovalReqsCount']);
  }
  return _.get(state, ['approvalReqs', 'count', 'pendingApprovalReqsCount']);
};

export const getSelectedApprovalReqId = (state) =>
  _.get(state, ['approvalReqs', 'selectedApprovalReq'], '');

export const getApprovalReqPayload = (state) =>
  _.get(state, ['approvalReqs', 'payload'], '');
