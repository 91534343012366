import _ from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  states,
  renderDropdown,
  renderField,
  renderCheckbox,
  renderDateTime,
  rules,
} from 'erisxkit/client';
import { Button, Divider, Form, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import * as memberTypes from '../../../constants/memberTypes';
import { entitlementDetails } from '../../../constants/entitlements';
import * as dsro from '../../../constants/dsro';
import {
  hasEntitlements,
  getCodeFromMemberType,
  capitalizeCamelCase,
} from '../../../utils/methods';
import * as statuses from '../../../constants/statuses';
import WarningMessage from '../../../common/components/WarningMessage';
import SubExchangeEntitlementsContainer from '../../../common/containers/SubExchangeEntitlementsContainer';
import { quickHttpPost } from '../../../utils/quickHttp';
import { MEMBERS_API_ENDPOINT } from '../../../api';
import AllowedExchanges from './AllowedExchanges';

const maxLength4 = rules.maxLength(4);
const maxLength9 = rules.maxLength(9);

const futuresMarginMemberIdValidation = (value) =>
  value && !/^[a-zA-Z0-9]{3,4}$/i.test(value)
    ? 'Max 3-4 characters'
    : undefined;

const entitlementDates = (entitlement) => {
  //Remove SpaceOrHyphen from entitlement?.name
  const entitlementNameWithoutSpaceOrHyphen = capitalizeCamelCase(
    entitlement?.name?.replace(/[\s-]/g, ''),
  );
  return (
    <Fragment>
      <label
        className="field nowrap"
        htmlFor={`onboardingStarted${entitlementNameWithoutSpaceOrHyphen}`}
      >
        {`Onboarding Started (${entitlementNameWithoutSpaceOrHyphen})`}
        <Field
          className="ui date-time"
          clearable
          component={renderDateTime}
          inputProps={{
            placeholder: 'MM/DD/YYYY',
            readOnly: false,
          }}
          name={`onboardingStarted${entitlementNameWithoutSpaceOrHyphen}`}
        />
      </label>
      <label
        className="field nowrap"
        htmlFor={`onboardingComplete${entitlementNameWithoutSpaceOrHyphen}`}
      >
        {`Documents Complete (${entitlementNameWithoutSpaceOrHyphen})`}
        <Field
          className="ui date-time"
          clearable
          component={renderDateTime}
          inputProps={{
            placeholder: 'MM/DD/YYYY',
            readOnly: false,
          }}
          name={`documentsComplete${entitlementNameWithoutSpaceOrHyphen}`}
        />
      </label>
    </Fragment>
  );
};

const futuresMarginMemberId = (name, label) => (
  <Fragment>
    <label className="field" htmlFor={name}>
      {label}
      <Field
        name={name}
        component={renderField}
        validate={[rules.required, futuresMarginMemberIdValidation]}
      />
    </label>
  </Fragment>
);

const PersonalInformation = ({
  allowFutures,
  allowSpot,
  update,
  initialValues = {},
  createEmarketOrganization,
  formValues,
  loading,
  warnings,
  change,
}) => {
  const statusOptions = useMemo(() => {
    return Object.values(statuses).map((s) => ({
      key: s.code,
      value: s.code,
      text: s.name,
    }));
  }, [statuses]);

  const [existingFirmCode, setExistingFirmCode] = useState(false);
  const handleFirmCodeChangeDebounceFn = async (firmCode) => {
    if (firmCode === 'DM') {
      setExistingFirmCode(false);
      return;
    }
    const payload = {
      filter: [{ attr: 'firm_code', op: 'eq', value: firmCode }],
      limit: 1,
    };
    const response = await quickHttpPost(MEMBERS_API_ENDPOINT, payload);
    setExistingFirmCode(response.count > 0);
  };
  const firmCodeChangeDebounceFn = useCallback(
    _.debounce(handleFirmCodeChangeDebounceFn, 500),
    [],
  );

  return (
    <Form autoComplete="off" loading={loading}>
      <div className="xwizard-form-group">
        <div className="xwizard-form-group">
          <h4>Member Details</h4>
          <Divider />
          {update && (
            <Form.Group>
              <Field
                clearable={false}
                name="status"
                component={renderDropdown}
                options={statusOptions}
                required
                label="Status"
                validate={[rules.required]}
              />
              <Form.Field required>
                <label htmlFor="statusDate">Status Date</label>
                <Field
                  name="statusDate"
                  className="show-input"
                  component={renderDateTime}
                  placeholder="MM/DD/YYYY"
                  required
                  validate={[rules.required]}
                  inputProps={{ name: 'statusDate' }}
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Group>
            <label className="field" htmlFor="onboardingStartedSpot">
              Please select the type of Clearing Member you would like to
              create.
              <Field
                name="type"
                component={renderDropdown}
                options={Object.values(memberTypes).map((memberType) => ({
                  key: memberType.type,
                  value: memberType.type,
                  text: memberType.name,
                }))}
                required
                disabled={update}
                validate={[rules.required]}
                onChange={(e, value) => {
                  const clearingMemberTypeValue = getCodeFromMemberType(value);
                  change('clearingMemberType', clearingMemberTypeValue || '');
                  if (value === memberTypes.DIRECT_MEMBER.type) {
                    change('taxId', '');
                  }
                  if (
                    value !== memberTypes.DIRECT_MEMBER.type &&
                    value !== memberTypes.FIRM_MEMBER.type
                  ) {
                    change('ecp', false);
                  }
                }}
              />
            </label>
            <div
              style={{
                display: `${
                  _.get(formValues, 'type') ===
                    memberTypes.DIRECT_MEMBER.type ||
                  _.get(formValues, 'type') === memberTypes.FIRM_MEMBER.type ||
                  _.get(formValues, 'type') === memberTypes.FCM.type
                    ? 'flex'
                    : 'none'
                }`,
                alignItems: 'center',
                paddingTop: '15px',
              }}
            >
              <div style={{ margin: '0px 15px 0px 10px' }}>
                <Field
                  component={renderCheckbox}
                  name="ecp"
                  label="Eligible Contract Participant"
                  value={_.get(formValues, 'type') === memberTypes.FCM.type}
                />
              </div>
              <Field
                component={renderCheckbox}
                name="edm"
                label="Eligible Delivery Member"
                value={_.get(formValues, 'type') === memberTypes.FCM.type}
              />
            </div>
            <Field
              component={renderField}
              name="clearingMemberType"
              style={{ visibility: 'hidden' }}
            />
            <div
              style={{
                display: `${_.get(formValues, 'type') === memberTypes.FCM.type ? 'flex' : 'none'}`,
                alignItems: 'center',
              }}
            >
              <Field
                name="dsro"
                component={renderDropdown}
                options={Object.values(dsro).map((dsroOption) => ({
                  key: dsroOption.type,
                  value: dsroOption.type,
                  text: dsroOption.name,
                }))}
                required
                label="DSRO"
                validate={
                  _.get(formValues, 'type') === memberTypes.FCM.type
                    ? [rules.required]
                    : []
                }
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Field
              name="name"
              component={renderField}
              label="Member Name"
              validate={[rules.required]}
              required
            />
            <Field
              name="firmCode"
              component={renderField}
              label="Firm Code"
              validate={[
                maxLength4,
                rules.required,
                (value) => {
                  return existingFirmCode
                    ? 'Firm Code already exists'
                    : undefined;
                },
              ]}
              required
              onChange={(e, value) => {
                firmCodeChangeDebounceFn(value);
              }}
            />
            {_.get(formValues, 'type') !== memberTypes.DIRECT_MEMBER.type && (
              <Field
                name="taxId"
                component={renderField}
                label="Tax ID"
                validate={
                  _.get(formValues, 'type') === memberTypes.FCM.type
                    ? [maxLength9, rules.required]
                    : [maxLength9]
                }
                required={_.get(formValues, 'type') === memberTypes.FCM.type}
              />
            )}
          </Form.Group>
          <Form.Group>
            <Field
              name="isFuturesMarginExchangeMember"
              component={renderCheckbox}
              label="Futures Margin Exchange Member"
              onChange={(evt) => {
                if (!evt) {
                  change('exchangeMemberId', '');
                }
              }}
            />
            {_.get(formValues, 'isFuturesMarginExchangeMember')
              ? futuresMarginMemberId(
                  'exchangeMemberId',
                  'Futures Margin Exchange Member ID',
                )
              : null}
          </Form.Group>
          <Form.Group>
            <Field
              name="isFuturesMarginClearingMember"
              component={renderCheckbox}
              label="Futures Margin Clearing Member"
              onChange={(evt) => {
                if (!evt) {
                  change('clearingMemberId', '');
                }
              }}
            />
            {_.get(formValues, 'isFuturesMarginClearingMember')
              ? futuresMarginMemberId(
                  'clearingMemberId',
                  'Futures Margin Clearing Member ID',
                )
              : null}
          </Form.Group>
        </div>
        <div className="xwizard-form-group">
          <h4>Address</h4>
          <Divider />
          <Field
            name="address1"
            component={renderField}
            label="Street Address 1"
            warn={[rules.warning]}
          />
          <Field
            name="address2"
            component={renderField}
            label="Street Address 2"
            placeholder="(optional)"
          />
          <Form.Group>
            <Field
              name="city"
              component={renderField}
              label="City"
              warn={[rules.warning]}
            />
            <Field
              name="locationState"
              component={renderDropdown}
              label="State"
              search
              clearable
              options={states.map((state) => ({
                key: state,
                value: state,
                text: state,
              }))}
              warn={[rules.warning]}
            />
            <Field
              name="postalCode"
              component={renderField}
              label="Postal Code"
              warn={[rules.warning]}
            />
          </Form.Group>
          <Form.Group>
            <Field
              name="country"
              component={renderField}
              label="Country"
              warn={[rules.warning]}
            />
          </Form.Group>
          <Form.Group>
            <Field
              name="phoneNumber"
              component={renderField}
              label="Phone Number"
              warn={[rules.warning]}
            />
          </Form.Group>
        </div>
        <div className="xwizard-form-group">
          <h4>Entitlements</h4>
          <Divider />
          {entitlementDetails.map((entitlement) => (
            <Form.Group>
              <Field
                name={entitlement.value}
                component={renderCheckbox}
                label={entitlement.label || _.startCase(entitlement.value)}
              />
              {_.get(formValues, entitlement.value) &&
                entitlement.onboardingDates &&
                entitlementDates(entitlement)}
            </Form.Group>
          ))}

          <Message
            warning
            icon="warning sign"
            compact
            className={`${
              loading ||
              _.get(formValues, 'type') === memberTypes.VENDOR_MEMBER.type ||
              hasEntitlements(formValues)
                ? 'hide'
                : 'show'
            }`}
            header="Member currently has no entitlements. Please consider granting entitlement(s) before submitting."
          />
        </div>
      </div>
      <AllowedExchanges />
      <div className="xwizard-form-group">
        <h4>CFTC Information</h4>
        <Divider />
        <Form.Group>
          <Field
            name="lei"
            component={renderField}
            label="LEI"
            warn={allowSpot || allowFutures ? [rules.warning] : undefined}
          />
          <Field
            component={renderField}
            label="CFTC Member ID"
            name="cftcMemberId"
            placeholder="Unique three-character code"
            required={
              allowFutures || _.get(formValues, 'type') === memberTypes.FCM.type
            }
            validate={_.compact([
              rules.maxLength(3),
              (allowFutures ||
                _.get(formValues, 'type') === memberTypes.FCM.type) &&
                rules.required,
            ])}
            warn={allowSpot && !allowFutures ? [rules.warning] : undefined}
          />
        </Form.Group>
      </div>
      {_.get(formValues, 'type') !==
        memberTypes.EXECUTION_BROKER_MEMBER.type && (
        <div className="xwizard-form-group">
          <h4>E-Market</h4>
          <Divider />
          {!update ? (
            <Form.Group>
              <Field
                name="createEmarketOrganization"
                component={renderCheckbox}
                label="Create E-Market Organization"
                warn={[rules.warning]}
              />
            </Form.Group>
          ) : (
            <Fragment>
              <Form.Group>
                <Button
                  disabled={initialValues.emarketOrganizationId}
                  onClick={() =>
                    createEmarketOrganization({
                      memberId: initialValues.memberId,
                    })
                  }
                >
                  Create E-Market Organization
                </Button>
              </Form.Group>
              <Form.Group>
                <Field
                  name="emarketOrganizationName"
                  component={renderField}
                  label="E-Market Organization Name"
                />
                <Field
                  name="emarketOrganizationId"
                  component={renderField}
                  label="E-Market Organization Id"
                />
              </Form.Group>
            </Fragment>
          )}
        </div>
      )}
      {_.get(formValues, 'type') === memberTypes.INTERMEDIARY.type && (
        <div className="xwizard-form-group">
          <h4>IP AUTHORIZATION</h4>
          <Divider />
          <Fragment>
            <Form.Group>
              <Field
                component={renderCheckbox}
                name="ipAuthorizationEnabled"
                label="Allow IP Address Authorization"
              />
            </Form.Group>
          </Fragment>
        </div>
      )}
      <div className="xwizard-form-group">
        <WarningMessage
          warnings={warnings}
          labels={{
            address1: 'Street Address 1',
            cftcMemberId: 'CFTC Member ID',
            lei: 'LEI',
            locationState: 'State',
          }}
        />
      </div>
    </Form>
  );
};

PersonalInformation.propTypes = {
  update: PropTypes.bool,
};

PersonalInformation.defaultProps = {
  update: false,
};

export const MEMBER_PERSONAL_FORM_NAME = 'member_personal'
export default reduxForm({
  form: MEMBER_PERSONAL_FORM_NAME, // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true,
})(PersonalInformation);
