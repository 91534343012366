import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef } from 'react';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { commonPaginationOptions } from 'utils/agGrid/options';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
} from 'ag-grid-community';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { ServerSideQuery } from 'utils/agGrid/types';
import { fetchRiskAlertsPromise } from 'reducers/riskReducer';
import get from 'lodash/get';
import { RiskAlert } from 'ts/models/RiskAlerts.model';

export const RISK_ALERTS_GRID_NAME = 'risk-alerts';

type FetchRiskAlertRequest = ServerSideQuery & {
  withLatestComment: boolean;
  withLatestStatus: boolean;
};

type FetchRiskAlertResponse = {
  count: number;
  riskAlerts: RiskAlert[];
};

export const transformRowData = (
  response: FetchRiskAlertResponse,
): RiskAlert[] => {
  return [...get(response, 'riskAlerts', []).map((alert: RiskAlert) => alert)];
};

const gridOptions: GridOptions = {
  ...commonPaginationOptions,
  autoSizeStrategy: {
    type: 'fitCellContents',
    colIds: columnDefs
      .filter((col) => col.field !== 'description')
      .map((col) => col.field ?? ''),
  },
};

const RiskAlertsGrid = () => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } =
    useAgGridCommon({
      gridName: RISK_ALERTS_GRID_NAME,
      gridRef,
    });

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success }) => {
          const parsed = parseAGRequest(request, { transformAttrs: true });
          const body: FetchRiskAlertRequest = {
            ...parsed,
            withLatestComment: true,
            withLatestStatus: true,
          };
          fetchRiskAlertsPromise(body, dispatch).then((response) => {
            success({
              rowData: transformRowData(response),
            });
          });
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  return (
    <>
      {gridVisible ? (
        <div className="ag-theme-quartz">
          {gridButtons}
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            onGridReady={onGridReady}
          />
        </div>
      ) : null}
    </>
  );
};

export default RiskAlertsGrid;
