import React, { useCallback } from 'react';
import { GridApi, IRowNode } from 'ag-grid-community';
import { Button } from 'semantic-ui-react';
import get from 'lodash/get';

export enum GRID_ACTION {
  EDIT = 'edit',
  UPDATE = 'update',
  DELETE = 'delete',
  CANCEL = 'cancel',
}

type Props = {
  api: GridApi;
  node: IRowNode;
};

const Actions = ({ api, node }: Props) => {
  // These variables need to be recomputed on every render.
  const editingCells = api?.getEditingCells() || [];
  const isEditing = editingCells.some((cell) => {
    return cell.rowIndex === node?.rowIndex;
  });

  const onEditClicked = useCallback(() => {
    if (node.rowIndex !== null && api !== null) {
      // Put the focus on the leftmost cell
      const firstColKey = get(api.getDisplayedCenterColumns(), ['0', 'colId']);
      if (firstColKey) {
        api.setFocusedCell(node.rowIndex, firstColKey);
        api.startEditingCell({
          rowIndex: node.rowIndex,
          colKey: firstColKey,
        });
      }
    }
  }, [api, node.rowIndex]);

  return (
    <div>
      <div>
        <>
          {!isEditing ? (
            <Button
              basic
              color="blue"
              className="grid-action-btn"
              data-action={GRID_ACTION.EDIT}
              data-testid={`ag-grid-edit-btn-row-${node.rowIndex}`}
              onClick={onEditClicked}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                basic
                color="green"
                className="grid-action-btn"
                data-action={GRID_ACTION.UPDATE}
                data-testid={`ag-grid-update-btn-row-${node.rowIndex}`}
              >
                Update
              </Button>
              <Button
                basic
                color="grey"
                className="grid-action-btn"
                data-action={GRID_ACTION.CANCEL}
                data-testid={`ag-grid-cancel-btn-row-${node.rowIndex}`}
              >
                Cancel
              </Button>
            </>
          )}
        </>
        {!isEditing && (
          <Button
            basic
            color="red"
            className="grid-action-btn"
            data-action={GRID_ACTION.DELETE}
            data-testid={`ag-grid-delete-btn-row-${node.rowIndex}`}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export const GRID_ACTION_COL_ID = 'action';
export const GRID_ACTIONS_COL_DEF = {
  headerName: 'Actions',
  colId: GRID_ACTION_COL_ID,
  enableCellChangeFlash: false,
  cellRenderer: ({ api, node }: { api: GridApi; node: IRowNode }) => {
    return <Actions api={api} node={node} />;
  },
};

export default Actions;
