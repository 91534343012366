import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Modal, Form, Button } from 'semantic-ui-react';
import { titleCase } from 'change-case';
import PropTypes from 'prop-types';
import FuturesProductFields from '../../common/forms/FuturesProduct';
import { getExchangeList } from '../../reducers/exchangeReducer';

const mapStateToProps = (state) => ({
  exchanges: getExchangeList(state),
});

class EditFuturesProduct extends Component {
  componentDidMount() {
    this.checkUpdateForm();
  }

  checkUpdateForm = () => {
    const {
      initialize,
      type,
      baseAssetType,
      cftcReportingLimit,
      contractSize,
      delivery,
      deliveryFee,
      deliveryFeeType,
      exchangeMicCode,
      description,
      exchangeReportableLimit,
      maximumReportableLimit,
      quotedAssetType,
      settlementPeriod,
      symbol,
      tickSize,
      allowBlockTrades,
      delistedAt,
      minimumBlockSize,
      subExchangeId,
      marginRoundingOutright,
      marginRoundingSpreads,
      spanId,
      cftc_102bLimit,
    } = this.props;
    if (type === 'update') {
      // on update, only initialize with given form fields, not redux-form scaffolding
      initialize(
        {
          baseAssetType,
          cftcReportingLimit,
          contractSize,
          delivery,
          description,
          exchangeReportableLimit,
          maximumReportableLimit,
          quotedAssetType,
          settlementPeriod,
          symbol,
          tickSize,
          type,
          allowBlockTrades,
          delistedAt,
          minimumBlockSize,
          subExchangeId,
          deliveryFee,
          deliveryFeeType,
          exchangeMicCode,
          marginRoundingOutright,
          marginRoundingSpreads,
          spanId,
          cftc_102bLimit,
        },
        { keepDirty: true },
      ); // flip pristine to false after initialize
    }
  };

  render() {
    const {
      change,
      confirmAction,
      handleSubmit,
      deliveryFormValue,
      hideModal,
      symbolFormValue,
      allowBlockTradesFormValue,
      pristine,
      type,
      valid,
    } = this.props;
    return (
      <Fragment>
        <Modal.Header>
          {titleCase(type)} Futures Product{' '}
          <Button
            className="pull-right"
            circular
            size="mini"
            icon="times"
            onClick={() => hideModal()}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit(confirmAction)}>
            <FuturesProductFields
              change={change}
              deliveryFormValue={deliveryFormValue}
              symbolFormValue={symbolFormValue}
              allowBlockTradesFormValue={allowBlockTradesFormValue}
            />
            <Modal.Actions className="modal-form-actions">
              <Form.Button onClick={() => hideModal()}>Cancel</Form.Button>
              <Form.Button primary disabled={!valid || pristine} id={type}>
                {titleCase(type)} Futures Product
              </Form.Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Fragment>
    );
  }
}

EditFuturesProduct.propTypes = {
  allowBlockTrades: PropTypes.bool,
  delistedAt: PropTypes.bool,
  change: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  deliveryFormValue: PropTypes.string,
  symbolFormValue: PropTypes.string,
  allowBlockTradesFormValue: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  type: PropTypes.string,
  valid: PropTypes.bool,
  baseAssetType: PropTypes.string,
  cftcReportingLimit: PropTypes.string,
  contractSize: PropTypes.string,
  delivery: PropTypes.string,
  deliveryFee: PropTypes.string,
  deliveryFeeType: PropTypes.string,
  exchangeMicCode: PropTypes.string,
  description: PropTypes.string,
  exchangeReportableLimit: PropTypes.string,
  maximumReportableLimit: PropTypes.string,
  minimumBlockSize: PropTypes.string,
  quotedAssetType: PropTypes.string,
  settlementPeriod: PropTypes.string,
  exchanges: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  subExchangeId: PropTypes.string,
  symbol: PropTypes.string,
  tickSize: PropTypes.string,
  marginRoundingOutright: PropTypes.number,
  marginRoundingSpreads: PropTypes.number,
  spanId: PropTypes.number,
  cftc_102bLimit: PropTypes.string,
};

EditFuturesProduct.defaultProps = {
  allowBlockTrades: false,
  delistedAt: false,
  deliveryFormValue: '',
  symbolFormValue: '',
  allowBlockTradesFormValue: '',
  pristine: false,
  type: 'create',
  valid: false,
  baseAssetType: '',
  cftcReportingLimit: '',
  contractSize: '',
  delivery: '',
  deliveryFee: '',
  deliveryFeeType: '',
  exchangeMicCode: '',
  description: '',
  exchangeReportableLimit: '',
  maximumReportableLimit: '',
  minimumBlockSize: '',
  quotedAssetType: '',
  settlementPeriod: '',
  subExchangeId: '',
  exchanges: [],
  symbol: '',
  tickSize: '',
  marginRoundingOutright: '',
  marginRoundingSpreads: '',
  spanId: '',
  cftc_102bLimit: '',
};

export default reduxForm({
  form: 'CreateFuturesProduct',
})(connect(mapStateToProps)(EditFuturesProduct));
