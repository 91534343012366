import { get, values } from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';
import { handleActions, combineActions } from 'redux-actions';
import {
  createById,
  createCount,
  createPages,
  createCurrentPage,
  arrayToObject,
  getItemsPaged,
} from 'erisxkit/client';
import { combineReducers } from 'redux';
import * as actionTypes from '../constants/actionTypes';
import { getAccountIdFromURL } from '../utils/methods';
import { getSelectedAccount } from './accountsReducer';
import { createEmarketOrganization } from './manualOnboardingReducer';
import { createAccount } from '../actions/accountsActions';

export const fetchMembers = createRoutine(actionTypes.MEMBERS);
export const fetchMembersPromise = promisifyRoutine(fetchMembers);

export const updateMember = createRoutine(actionTypes.UPDATE_MEMBER);
export const addMemberToLedgerAccounts = createRoutine(
  actionTypes.ADD_MEMBER_TO_LEDGER_ACCOUNTS,
);
export const removeMemberFromLedgerAccounts = createRoutine(
  actionTypes.REMOVE_MEMBER_FROM_LEDGER_ACCOUNTS,
);
export const addMemberToUsers = createRoutine(actionTypes.ADD_MEMBER_TO_USERS);
export const addMemberToUsersPromiseCreator =
  promisifyRoutine(addMemberToUsers);

export const removeMemberFromUsers = createRoutine(
  actionTypes.REMOVE_MEMBER_FROM_USERS,
);
export const removeMemberPromiseCreator = promisifyRoutine(
  removeMemberFromUsers,
);

export const createFirmMember = createRoutine(actionTypes.CREATE_FIRM_MEMBER);
export const createFirmMemberPromiseCreator =
  promisifyRoutine(createFirmMember);

export const createVendorMember = createRoutine(
  actionTypes.CREATE_VENDOR_MEMBER,
);
export const createVendorMemberPromiseCreator =
  promisifyRoutine(createVendorMember);

export const createDirectMember = createRoutine(
  actionTypes.CREATE_DIRECT_MEMBER,
);
export const createDirectMemberPromiseCreator =
  promisifyRoutine(createDirectMember);

export const createExecutionBrokerMember = createRoutine(
  actionTypes.CREATE_EXECUTION_BROKER_MEMBER,
);
export const createExecutionBrokerMemberPromiseCreator = promisifyRoutine(
  createExecutionBrokerMember,
);

export const createInvestmentManagerMember = createRoutine(
  actionTypes.CREATE_INVESTMENT_MANAGER_MEMBER,
);
export const createInvestmentManagerMemberPromiseCreator = promisifyRoutine(
  createInvestmentManagerMember,
);

export const createFcmMember = createRoutine(actionTypes.CREATE_FCM_MEMBER);
export const createFcmMemberPromiseCreator = promisifyRoutine(createFcmMember);

export const createIntermediaryMember = createRoutine(
  actionTypes.CREATE_INTERMEDIARY_MEMBER,
);
export const createIntermediaryMemberPromiseCreator = promisifyRoutine(
  createIntermediaryMember,
);

export const addUltimateBeneficialOwner = createRoutine(
  actionTypes.ADD_ULTIMATE_BENEFICIAL_OWNER,
);
export const removeUltimateBeneficialOwner = createRoutine(
  actionTypes.REMOVE_ULTIMATE_BENEFICIAL_OWNER,
);
export const updateUltimateBeneficialOwner = createRoutine(
  actionTypes.UPDATE_ULTIMATE_BENEFICIAL_OWNER,
);

export const selectMember = (memberId) => ({
  type: actionTypes.SELECT_MEMBER,
  payload: memberId,
});

export const getMemberCollection = (state) => get(state.members, 'byId', {});

export const getSelectedMemberId = (state, props) => {
  if (!get(state.members, 'selectedMemberId')) {
    return get(props, ['params', 'memberId'], getAccountIdFromURL());
  }
  return get(state.members, 'selectedMemberId');
};

export const getSelectedMember = createSelector(
  [getMemberCollection, getSelectedMemberId],
  (members, selectedMemberId) => get(members, selectedMemberId, {}),
);

const selectedMemberId = (state = '', action) => {
  switch (action.type) {
    case actionTypes.SELECT_MEMBER:
      return action.payload;
    default:
      return state;
  }
};

export const getMemberEntitlementsForSelectedAccount = createSelector(
  [getMemberCollection, getSelectedAccount],
  (member, account = {}) => ({
    allowCollateral: get(member, [account.memberId, 'allowCollateral']),
    allowFutures: get(member, [account.memberId, 'allowFutures']),
    allowSpot: get(member, [account.memberId, 'allowSpot']),
  }),
);

export const getMemberListOptions = createSelector(
  [getMemberCollection],
  (members) =>
    Object.values(members).map((member) => ({
      key: member?.memberId,
      description: member?.memberId,
      value: member?.memberId,
      text: member?.name || '-',
    })),
);

const routine = fetchMembers;
const slice = 'members';
const id = 'memberId';

const fetchMemberById = createById(routine, slice, id);

const updateMemberReducer = handleActions(
  {
    [createAccount.SUCCESS]: (state, action) => {
      const newState = { ...state };
      // when an account is created, and it has a memberId, add that ledger account to that member.
      if (get(action, 'data')) {
        const account = Object.values(get(action, 'data'))[0];
        if (account.memberId) {
          newState[account.memberId] = {
            ...state[account.memberId],
            ledgerAccounts: [
              ...state[account.memberId].ledgerAccounts,
              account.accountId,
            ],
          };
        }
      }

      return newState;
    },
    [fetchMembers.SUCCESS]: (state, action) => fetchMemberById(state, action),
    [combineActions(
      updateMember.SUCCESS,
      addUltimateBeneficialOwner.SUCCESS,
      updateUltimateBeneficialOwner.SUCCESS,
      removeUltimateBeneficialOwner.SUCCESS,
    )]: (state, action) => ({
      ...state,
      ...arrayToObject([get(action, ['payload'])], id),
    }),
    [createEmarketOrganization.SUCCESS]: (state, action) => ({
      ...state,
      [get(action, ['payload', 'memberId'])]: {
        ...state[get(action, ['payload', 'memberId'])],
        ...get(action, ['payload'], {}),
      },
    }),
  },
  {},
);

export const getActiveMemberFirmCode = (state) => {
  const selectedMember = getSelectedMember(state);
  return selectedMember?.firmCode;
};

export default combineReducers({
  byId: updateMemberReducer,
  count: createCount(routine),
  pages: createPages(routine, slice, id),
  currentPage: createCurrentPage(routine),
  selectedMemberId,
});
