import _ from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { arrayToObject } from 'erisxkit/client';
import {
  TASKS,
  TASK_INFO,
  TASK_LOG,
  TRIGGER_TASK,
} from '../constants/actionTypes';

export const fetchTasks = createRoutine(TASKS);
export const fetchTaskLog = createRoutine(TASK_LOG);
export const fetchTaskInfo = createRoutine(TASK_INFO);
export const triggerTask = createRoutine(TRIGGER_TASK);
export const fetchTasksPromise = promisifyRoutine(fetchTasks);

function tasksById(state = {}, action) {
  switch (action.type) {
    case fetchTasks.SUCCESS:
      return {
        ...state,
        ...arrayToObject(action.payload.tasks, 'taskId'),
      };
    default:
      return state;
  }
}

function count(state = 0, action) {
  switch (action.type) {
    case fetchTasks.SUCCESS:
      return action.payload.count;
    default:
      return state;
  }
}

const pages = (state = {}, action) => {
  let pageIds;
  switch (action.type) {
    case fetchTasks.SUCCESS:
      pageIds = action.payload.tasks.map((item) => item.taskId);
      return {
        ...state,
        [action.payload.page]: pageIds,
      };
    default:
      return state;
  }
};

const currentPage = (state = 0, action) => {
  switch (action.type) {
    case fetchTasks.SUCCESS:
      return _.isNumber(action.payload.page) ? action.payload.page : state;
    default:
      return state;
  }
};

function taskLogByTaskId(state = {}, { payload, type }) {
  switch (type) {
    case fetchTaskLog.SUCCESS:
      return {
        ...state,
        [payload.taskId]: payload.taskLog,
      };
    default:
      return state;
  }
}

const activeTask = (state = {}, { payload, type }) => {
  switch (type) {
    case triggerTask.SUCCESS:
      return payload.taskId;
    default:
      return state;
  }
};

const taskInfo = (state = {}, action) => {
  switch (action.type) {
    case fetchTaskInfo.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  tasksById,
  count,
  pages,
  currentPage,
  taskLogByTaskId,
  activeTask,
  taskInfo,
});

export const getAllTasks = (state) => _.get(state, ['tasks', 'tasksById'], {});
export const getTasksCount = (state) => _.get(state, ['tasks', 'count'], {});

const getTaskIdsPaged = (state) => {
  const page = _.get(state, ['tasks', 'currentPage'], 0);
  const pageIds = _.get(state, ['tasks', 'pages'], [])[page];
  if (pageIds === undefined) {
    return [];
  }
  return pageIds;
};

export const getTasksPaged = createSelector(
  [getAllTasks, getTaskIdsPaged],
  (aById, aIds) => aIds.map((o) => aById[o]),
);

export const getActiveTask = (state) => _.get(state, ['tasks', 'activeTask']);
export const getAllTaskLogs = (state) =>
  _.get(state, ['tasks', 'taskLogByTaskId'], []);
export const getActiveTaskLog = createSelector(
  [getActiveTask, getAllTaskLogs],
  (taskId, taskLog) => _.get(taskLog, taskId, []),
);

export const getTaskLog = (id) => (state) =>
  _.get(state, ['tasks', 'taskLogByTaskId', id], []);
export const getTaskInfo = (state) => _.get(state, ['tasks', 'taskInfo'], {});
export const getTaskNames = (state) =>
  _.get(state, ['tasks', 'taskInfo', 'names'], []);
export const getTaskOptionsByName = (taskName) => (state) =>
  _.chain(state)
    .get(['tasks', 'taskInfo', 'tasks'], [])
    .find(({ name }) => name === taskName)
    .get('options', {})
    .value();
