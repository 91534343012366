import React from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { renderField } from '../../../common/forms/component';
import * as rules from '../../../common/forms/rules';

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;

export const ADD_EXCHANGE_FORM_NAME = 'addExchangeForm';

const AddExchangeForm = () => {
  return (
    <StyledForm>
      <Field
        component={renderField}
        name="name"
        id="name"
        type="text"
        label="Name"
        placeholder="Name"
        style={{ height: '38px' }}
        minimal
        required
        validate={[rules.required]}
      />
      <Field
        component={renderField}
        name="Exchange Code"
        id="Exchange Code"
        type="text"
        label="Exchange Code"
        placeholder="Exchange Code"
        style={{ height: '38px' }}
        minimal
        required
        validate={[rules.required]}
      />
      <Field
        component={renderField}
        name="micCode"
        id="micCode"
        type="text"
        label="MIC Code"
        placeholder="MIC Code"
        style={{ height: '38px' }}
        minimal
        required
        validate={[rules.required]}
      />
      <Field
        component={renderField}
        name="spanIdentifier"
        id="spanIdentifier"
        type="text"
        label="SPAN Identifier"
        placeholder="SPAN Identifier"
        style={{ height: '38px' }}
        minimal
        required
        validate={[rules.required]}
      />
    </StyledForm>
  );
};

export default reduxForm({
  form: ADD_EXCHANGE_FORM_NAME,
})(AddExchangeForm);
