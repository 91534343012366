import { IServerSideGetRowsRequest } from 'ag-grid-community';
import snakeCase from 'lodash/snakeCase';
import { ServerSideQuery } from '../types';
import { parseAGFilters } from './parseAGFilters';
import parseAGSort from './parseAGSort';
import parseAGPagination from './parseAGPagination';

export type ParseAGRequestOptions = {
  transformAttrs?: boolean;
  transformAttrFunc?: (value: string) => string;
};
const parseAGRequest = (
  request: IServerSideGetRowsRequest,
  options: ParseAGRequestOptions = {},
): ServerSideQuery => {
  if (!request || !options) return {};
  const { transformAttrs = false, transformAttrFunc = snakeCase } = options;
  const { filterModel, sortModel, endRow, startRow } = request;
  const filter = parseAGFilters(filterModel);
  const sort = parseAGSort(sortModel);
  const pagination = parseAGPagination(startRow, endRow);
  if (transformAttrs) {
    return {
      filter: filter.map((f) => ({
        ...f,
        attr: transformAttrFunc(f.attr),
      })),
      sort: sort.map((s) => ({ ...s, attr: transformAttrFunc(s.attr) })),
      ...pagination,
    };
  }
  return { filter, sort, ...pagination };
};

export default parseAGRequest;
