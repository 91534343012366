import { ServerSidePagination } from '../types';
import isNumber from 'lodash/isNumber';

/**
 * @param startRow - row value as received from getRows's request object
 * @param endRow - row value as received from getRows's request object
 * @returns object with limit and offset keys, compatible with our server side pagination
 */
export const parseAGPagination = (
  startRow: number | undefined,
  endRow: number | undefined,
): ServerSidePagination | {} => {
  if (!isNumber(startRow) || !isNumber(endRow)) return {};
  const limit = endRow - startRow;
  if (limit === 0) {
    return {
      limit: 0,
      offset: startRow,
      page: Math.floor(startRow / (endRow || 1)),
    };
  }
  const offset = startRow;
  const page = Math.floor(startRow / limit);
  return { limit, offset, page };
};

export default parseAGPagination;
