import _ from 'lodash';
import { createSelector } from 'reselect';
import { promisifyRoutine } from 'redux-saga-routines';
import createRoutine from 'redux-saga-routines/dist/createRoutine';
import * as actionTypes from '../constants/actionTypes';
import AccountHistoryMetadata from '../metadata/accountHistoryMetadata';
import { getAccountIdFromURL } from '../utils/methods';

export const fetchRequestHistory = createRoutine('REQUEST_HISTORY');
export const fetchRequestHistoryPromise = promisifyRoutine(fetchRequestHistory);

// function to create the table metadata based on the asset_types in the latest transaction
const determineAssetTypes = (data) => {
  // return null if balances are not returned by API
  if (!data.length) {
    return null;
  }
  const columns = _.keys(data[0].balances).map((key) => ({
    Header: key.toUpperCase(),
    id: key,
    accessor: (row) => row.balances[key],
    className: 'text-right',
  }));

  return {
    Header: 'Balance',
    columns,
  };
};

export default function accountHistoryReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ACCOUNT_HISTORY_SUCCESS: {
      return { ...state, journalHistory: { ...action.payload } };
    }
    case fetchRequestHistory.SUCCESS: {
      var _history = undefined;
      if (action.payload.history) {
        _history = action.payload.history.map(function (rh) {
          if (rh['payMethod'] && rh['txType']) {
            rh['txType'] = rh['payMethod'] + ' ' + rh['txType'];
          }
          return rh;
        });
      }
      return {
        ...state,
        requestHistory: { ...action.payload, history: _history },
      };
    }
    default:
      return state;
  }
}

export const getAccountHistory = (state) =>
  _.get(state, ['history', 'journalHistory', 'history'], []);
export const getAccountHistoryCount = (state) =>
  _.get(state, ['history', 'journalHistory', 'count'], '');

export const getRequestHistory = (state) =>
  _.get(state, ['history', 'requestHistory', 'history'], []);
export const getRequestHistoryCount = (state) =>
  _.get(state, ['history', 'requestHistory', 'count'], '');

// TODO: need to refactor the getSelectedAccountId logic (maybe remove it)
// this will check url FIRST then check if the state has the selected account id.
export const getSelectedAccountId = (state) => {
  const urlAccountId = getAccountIdFromURL();
  const stateAccountId = _.get(state, ['accounts', 'selectedAccountId'], '');
  return urlAccountId || stateAccountId;
};

// need this here to not have a cyclical dependency
export const getAllAccounts = (state) => _.get(state, ['accounts', 'byId'], {});
export const getSelectedAccount = createSelector(
  [getAllAccounts, getSelectedAccountId],
  (accounts, selectedAccountId) => _.get(accounts, selectedAccountId, {}),
);

const getTypedMemberUsers = (memberUsers) => {
  if (memberUsers && Array.isArray(memberUsers)) {
    // if the user ids is an array of objects, convert it to an array of strings.
    // this is needed because accountV2 call and accounts call don't have the same shape for member_users
    return memberUsers.map((id) => {
      if (typeof id === 'string') {
        return id;
      }
      if (typeof id === 'object') {
        return _.get(id, 'userId', '');
      }
    });
  }
  return memberUsers;
};

const getSelectedAccountMemberUsers = createSelector(
  [getSelectedAccount],
  (selectedAccount) => selectedAccount?.memberUsers,
);
export const getSelectedAccountTypedMemberUsers = createSelector(
  [getSelectedAccountMemberUsers],
  (memberUsers = []) => getTypedMemberUsers(memberUsers),
);

export const getMetadataForSelectedAccount = () => AccountHistoryMetadata;
