import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from 'common/table/agGrid/AgGridCommon';
import columnDefs from './columnDefs';
import { commonPaginationOptions, mergeGridOpts } from 'utils/agGrid/options';
import parseAGRequest from 'utils/agGrid/ssrm/parseAGRequest';
import { rejectedJournalsPromise } from 'reducers/journalsReducer';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { RejectedJournals } from 'ts/models/RejectedJournals.model';
import { RejectedJournalsResponse } from 'ts/api/rejected_journals';
import useExternalFilter from 'utils/agGrid/hooks/useExternalFilter';
import ExternalFilters from './ExternalFilters';
import { ServerSideQuery } from 'utils/agGrid/types';

interface RejectedJournalsGridProps {
  isJest?: boolean;
}

export const REJECTED_JOURNALS_GRID_NAME = 'rejected-journals';

const RejectedJournalsGrid: React.FC<RejectedJournalsGridProps> = ({
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName: REJECTED_JOURNALS_GRID_NAME,
  });

  const onGridReadyCallback = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: ({ request, success, context }: IServerSideGetRowsParams) => {
          const body = parseAGRequest(request, { transformAttrs: true });
          const filters = body?.filter || [];
          if (context?.time) {
            filters.push({
              attr: 'time',
              op: 'gte',
              value: context?.time,
            });
          }
          const reqBody: ServerSideQuery = {
            ...body,
            filter: filters,
          };
          rejectedJournalsPromise(reqBody, dispatch).then(
            (response: RejectedJournalsResponse) => {
              success({
                rowData: response?.journals,
              });
            },
          );
        },
      };
      params.api?.setGridOption('serverSideDatasource', dataSource);
    },
    [dispatch],
  );

  const options = useMemo(
    () =>
      mergeGridOpts(commonPaginationOptions, {
        onGridReady: onGridReadyCallback,
      }),
    [onGridReadyCallback],
  );
  const { onGridReady, gridOptions, clearFilters, ...externalFilterProps } =
    useExternalFilter({
      gridRef,
      options,
    });

  const [externalFilters, setExternalFilters] = useState({});
  const context = useMemo(() => externalFilters, [externalFilters]);

  const onDateChange = useCallback(
    (value: Record<string, string>) => {
      setExternalFilters({ ...externalFilters, ...value });
    },
    [externalFilters],
  );
  const clearAllFilters = useCallback(() => {
    setExternalFilters({});
    clearFilters();
  }, [clearFilters]);

  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshServerSide({ purge: true });
    }
  }, [externalFilters]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <>
          <ExternalFilters
            {...externalFilterProps}
            onDateChange={onDateChange}
            context={context}
            clearFilters={clearAllFilters}
          />
          <AgGridReact<RejectedJournals>
            ref={gridRef}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            rowModelType="serverSide"
            groupDisplayType={'groupRows'}
            context={context}
          />
        </>
      )}
    </div>
  );
};

export default RejectedJournalsGrid;
